<template>
  <div>
    <v-switch
    class="toggle-cell-input"
    color="primary"
    v-model="checked">
    </v-switch>
  </div>
</template>

<script>
import Vue from 'vue';
import AgGridParentCellEditor from './mixins/AgGridParentCellEditor.vue';

export default Vue.extend({
  mixins: [AgGridParentCellEditor],
  components: {
  },
  data() {
    return {
      checked: false,
    };
  },
  methods: {
    getValue() {
      return this.checked;
    },
  },

  created() {
    this.checked = !this.params.value;
  },
});
</script>

<style scoped>
  .toggle-cell-input {
    margin: 0;
    padding-left: 5px;
  }
</style>
