<template>
  <div>
    <v-tooltip bottom>
      <v-switch
        slot="activator"
        color="white"
        :label="label"
        v-model="filter.onlyRemoteParkings"
        >
      </v-switch>
      <span>{{ tooltip }}</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  name: 'ramp-flights-list-filter-parking',
  computed: {
    filter() {
      return this.$store.state.ramp.filter;
    },
    label() {
      if (this.filter.onlyRemoteParkings) {
        return 'Only remote parkings';
      }
      return 'Any parkings';
    },
    tooltip() {
      if (this.filter.onlyRemoteParkings) {
        return 'Click to disable parking filter';
      }
      return 'Click to enable parking filter';
    },
  },
};
</script>

<style>
  /* TODO esta aplicando a este componente y al switch de desk por lo menos */
  .v-input--selection-controls .v-input__slot {
    margin-top: 5px;
    margin-bottom: 0px;
  }
  .theme--dark.v-label {
    color: white;
  }
</style>
