<template>
  <v-form ref="formOptions">
    <v-container fluid>
      <v-layout row>
        <v-flex>
          <v-radio-group v-model="importOptions.fileType" :label="this.$i18n.t('schedulingImport.fileType')">
            <v-radio :label="this.$i18n.t('schedulingImport.csvDaily')" value="CSV_DAILY"></v-radio>
            <v-radio :label="this.$i18n.t('schedulingImport.csvSeasonal')" value="CSV_SEASONAL"></v-radio>
          </v-radio-group>
        </v-flex>
        <v-flex>
          <v-radio-group v-model="importOptions.delimitierCharacter" :label="this.$i18n.t('schedulingImport.fileDelimitier')">
            <v-radio :label="this.$i18n.t('schedulingImport.semicolon')" value=";"></v-radio>
            <v-radio :label="this.$i18n.t('schedulingImport.comma')" value=","></v-radio>
          </v-radio-group>
        </v-flex>
        <v-flex>
          <v-radio-group v-model="importOptions.timeZoneMode" :label="this.$i18n.t('schedulingImport.timeZoneMode')">
            <v-radio label="UTC" value="UTC"></v-radio>
            <v-radio label="LOCAL" :value="localTimezone"></v-radio>
          </v-radio-group>
        </v-flex>
        <v-flex>
          <v-text-field type="number" v-model="importOptions.linesToPreview" :label="this.$i18n.t('schedulingImport.linesToPreview')" min="1" max="50" :rules="linesToPreviewRules"></v-text-field>
        </v-flex>
      </v-layout>
    </v-container>
  </v-form>
</template>
<script>

import SchedulingImportOptions from '../model/schedulingImportOptions';
import TimezoneConfigurable from '../../mixins/TimezoneConfigurable.vue';

export default {
  name: 'scheduling-import-step-options',
  mixins: [TimezoneConfigurable],
  components: {
  },
  props: {
    importOptions: {
      type: SchedulingImportOptions,
      required: true,
    },
  },
  data() {
    return {
      linesToPreviewRules: [
        v => !!v || this.$i18n.t('common.fieldRequired'),
        v => v >= 1 || 'Min: 1',
        v => v <= 50 || 'Max: 50',
      ],
    };
  },
};
</script>
<style>
</style>
