<template>
  <div>
    <billing-list-filter @changeShowFilter="changeShowFilter"/>

    <billing-list-table :items="items" :isFilterDisplayed="filterDisplayed" />

    <billing-flights-list-options @click-new-aircraft-ground="newAircraftGround" @click-delete-aircraft-ground="deleteAircraftGround" />
    <aircraft-ground-new-modal v-if="dialogAircraftGround" :dialog="dialogAircraftGround" @aircraft-ground-new-reject="newAircraftGroundReject" @aircraft-ground-new-confirm="newAircraftGroundConfirm" @aircraft-ground-new-service="newAircraftGroundServices"/>
    <aircraft-ground-new-services-modal v-if="dialogAircraftGroundServices" :dialog="dialogAircraftGroundServices" :aircraft-ground="aircraftGround" @aircraft-ground-service-new-reject="newAircraftGroundServiceReject" @aircraft-ground-service-new-confirm="newAircraftGroundServiceConfirm"/>
    <aircraft-ground-delete-modal v-if="dialogDeleteAircraftGround" :dialog="dialogDeleteAircraftGround" :items="selectedItemsToDelete" @delete-reject="deleteAircraftGroundReject" @delete-confirm="deleteAircraftGroundConfirm" />
    <pagination-bar
    class="pagination-bar"
    :paginationProperties="paginationProperties"
    :mutationNameToSetAgGridGridApi="mutationNameToSetAgGridGridApi"
    @setNewPageSize="setNewPageSize" @setNewCurrentPage="setNewCurrentPage"/>
  </div>
</template>
<script>
import Breadcrumbs from '@/components/Breadcrumbs.vue';

import BillingListFilter from '../components/billing/list/BillingListFilter.vue';
import BillingListTable from '../components/billing/list/BillingListTable.vue';
import BillingFlightsListOptions from '../components/billing/list/BillingFlightsListOptions.vue';
import AircraftGroundNewModal from '../components/aircraftGround/AircraftGroundNewModal.vue';
import AircraftGroundDeleteModal from '../components/aircraftGround/AircraftGroundDeleteModal.vue';
import AircraftGroundNewServicesModal
from '../components/aircraftGround/AircraftGroundNewServicesModal.vue';
import {
  BILLING_DELETE_AIRCRAFT_GROUND,
  BILLING_FIND_FLIGHTS,
  BILLING_UPDATE_FILTER_PAGINATION_PAGE_SIZE,
  BILLING_UPDATE_FILTER_PAGINATION_CURRENT_PAGE,
  BILLING_CHANGE_GRID_API,
} from '../store/modules/invoicing/billing';
import PaginationBar from '../components/ui/pagination/PaginationBar.vue';

export default {
  components: {
    BillingListFilter,
    BillingListTable,
    BillingFlightsListOptions,
    AircraftGroundNewModal,
    AircraftGroundDeleteModal,
    AircraftGroundNewServicesModal,
    Breadcrumbs,
    PaginationBar,
  },
  data() {
    return {
      filterDisplayed: true,
      selectedItemsToDelete: [],
      dialogAircraftGround: false,
      dialogDeleteAircraftGround: false,
      dialogAircraftGroundServices: false,
      aircraftGround: null,
      mutationNameToSetAgGridGridApi: BILLING_CHANGE_GRID_API,
    };
  },
  computed: {
    items() {
      // eslint-disable-next-line
      return this.$store.state.billing.flights;
    },
    selectedRowsAircraftGround() {
      return this.$store.getters.getSelectedAircraftGroundBilling;
    },
    paginationProperties() {
      // eslint-disable-next-line
      return this.$store.getters.billingPaginationResponseProperties;
    },
  },
  methods: {
    changeShowFilter(dispayled) {
      this.filterDisplayed = dispayled;
    },
    newAircraftGround() {
      this.dialogAircraftGround = true;
    },
    newAircraftGroundReject() {
      this.dialogAircraftGround = false;
    },
    newAircraftGroundConfirm() {
      this.$store.dispatch(BILLING_FIND_FLIGHTS);
      this.dialogAircraftGround = false;
    },
    newAircraftGroundServices(aircraftGround) {
      this.dialogAircraftGround = false;
      this.aircraftGround = aircraftGround;
      this.dialogAircraftGroundServices = true;
    },
    newAircraftGroundServiceReject() {
      this.dialogAircraftGroundServices = false;
    },
    newAircraftGroundServiceConfirm() {
      this.$store.dispatch(BILLING_FIND_FLIGHTS);
      this.dialogAircraftGroundServices = false;
    },
    deleteAircraftGround() {
      this.selectedItemsToDelete = [];
      this.selectedItemsToDelete = this.selectedRowsAircraftGround;
      this.dialogDeleteAircraftGround = this.selectedItemsToDelete.length > 0;
    },
    deleteAircraftGroundReject() {
      this.dialogDeleteAircraftGround = false;
    },
    deleteAircraftGroundConfirm(ids) {
      this.selectedItemsToDelete = [];
      this.$store.dispatch(BILLING_DELETE_AIRCRAFT_GROUND, { ids });
      this.dialogDeleteAircraftGround = false;
    },
    setNewPageSize(newPageSize) {
      this.$store.dispatch(BILLING_UPDATE_FILTER_PAGINATION_PAGE_SIZE, Number(newPageSize));
    },
    setNewCurrentPage(newCurrentPage) {
      this.$store.dispatch(BILLING_UPDATE_FILTER_PAGINATION_CURRENT_PAGE, Number(newCurrentPage));
    },
  },
};
</script>
<style>
  .pagination-bar {
    position: absolute;
    bottom: 0;
  }
</style>
