<template>
  <div class="mobileView">
    <info-mobile-flight-header :backArrow="backArrow" :flightMobile="flightMobile" :filterDate="filterDate" />
    <info-mobile-parking-header :parkingCode="flightMobile.parkingCode" :gateCode="flightMobile.gateCode" />
    <info-mobile-plane-header :flightMobile="flightMobile" />
    <div>
      <v-flex xs12 sm12 md12 lg12 xl12 v-if="flightMobile.bound">
        <info-mobile-messages :flightId="flightId" :flightBound="flightMobile.bound"
          :flightMobileMail="flightMobileMail" />
      </v-flex>
    </div>
  </div>
</template>

<script>
import StoreSubscribable from '../../../components/mixins/StoreSubscribable.vue';
import { MOBILE_GET_FLIGHT, MOBILE_GET_MAIL } from '../../../store/modules/mobile';
import RolePermissions from '../../../components/mixins/RolePermissions.vue';
import infoMobileFlightHeader from '../../../components/mobile/infoMobileFlightHeader.vue';
import infoMobileParkingHeader from '../../../components/mobile/infoMobileParkingHeader.vue';
import infoMobilePlaneHeader from '../../../components/mobile/infoMobilePlaneHeader.vue';
import infoMobileMessages from '../../../components/mobile/infoMobileMessages.vue';

export default {
  name: 'mobile-messages',
  props: {
    flightId: {
      required: true,
    },
    filterDate: String,
    flightMobileProps: Object,
  },
  mixins: [StoreSubscribable, RolePermissions],
  components: {
    infoMobileFlightHeader,
    infoMobileParkingHeader,
    infoMobilePlaneHeader,
    infoMobileMessages,
  },
  data() {
    return {
      bottomNav: 'all',
      infoPanel: [true],
    };
  },
  computed: {
    rolePermission() {
      return `${this.$route.meta.role}_WR`;
    },
    // when flightMobile is loaded, check if user has permission to see the flight
    flightMobile() {
      return this.$store.state.mobile.flightMobile;
    },
    flightMobileMail() {
      return this.$store.state.mobile.flightMobileMail;
    },
  },
  methods: {
    backArrow() {
      this.$router.push({ name: 'info', params: { flightId: this.flightId, flightMobileProps: this.flightMobile } });
    },
  },
  watch: {
    // when flightMobile is loaded, check if user has permission to see the flight
    flightMobile: {
      handler() {
        if (!this.hasPermissions('AIRPORT_SERVICE_AGENT_ROLE_FILTER_BY_AGENT')) {
          if (this.flightMobile.operationAgent !== this.user.email) {
            this.$router.push({ name: 'mobile' });
          }
        }
      },
      deep: true,
    },
  },
  created() {
    if (!this.flightId) {
      this.$router.push({ name: 'mobile' });
    } else if (!this.flightMobileProps) {
      this.$store.dispatch(MOBILE_GET_FLIGHT, this.flightId);
    }
    this.$store.dispatch(MOBILE_GET_MAIL, this.flightId);
  },
};
</script>
