<template>
  <div>
    <ag-grid-vue
      class="ag-theme-material connected-flights-table operational-table"
      :columnDefs="columnDefs"
      :defaultColDef="defaultColDef"
      :rowData="items"
      :rowSelection="'multiple'"
      :suppressRowClickSelection="true"
      :rowMultiSelectWithClick="false"
      @grid-ready="onGridReady"
      @first-data-rendered="firstDataRendered"
      @selection-changed="onSelectionChanged"
      :frameworkComponents="frameworkComponents"
      :gridOptions="gridOptions"
      :multiSortKey="multiSortKey"
      :overlayNoRowsTemplate="customNoRowsOverlay"
      :overlayLoadingTemplate="customLoadingOverlay"
      v-bind:style="`width: 100%; height: calc(100vh - ${this.sizeFilter});`">
    </ag-grid-vue>
  </div>
</template>
<script>
import { AgGridVue } from 'ag-grid-vue';
import AgGridFlightStatusCellRenderer from '../../ag-grid/cell-renderers/AgGridFlightStatusCellRenderer.vue';
import AgGridIconTooltipOperationCellRenderer
from '../../ag-grid/cell-renderers/AgGridIconTooltipOperationCellRenderer.vue';
import AgGridConnectedFlightsAircraftTypeSelectCellEditor
from '../../ag-grid/cell-editors/connectedFlights/AgGridConnectedFlightsAircraftTypeSelectCellEditor.vue';
import AgGridConnectedFlightsTimeCellEditor
from '../../ag-grid/cell-editors/connectedFlights/AgGridConnectedFlightsTimeCellEditor.vue';
import AgGridConnectedFlightsStringCellEditor
from '../../ag-grid/cell-editors/connectedFlights/AgGridConnectedFlightsStringCellEditor.vue';
import AgGridValueAndSupCellRenderer from '../../ag-grid/cell-renderers/AgGridValueAndSupCellRenderer.vue';
import AgGridValueBaseCellRenderer from '../../ag-grid/cell-renderers/AgGridValueBaseCellRenderer.vue';
import AgGridTimeCellRenderer from '../../ag-grid/cell-renderers/AgGridTimeCellRenderer.vue';
import AgGridConnectedFlights from '../../ag-grid/mixins/AgGridConnectedFlights.vue';
import FlightsDifferences from '../../mixins/FlightsDifferences.vue';

import { FlightBound } from '../../shared/model/flightBound';
import {
  FIT_COLUMNS_TO_VIEW,
  PASSAGE_INBOUND_CHANGE_FLIGHT_SELECTION,
  PASSAGE_INBOUND_ON_EXTERNAL_CHANGE,
  PASSAGE_INBOUND_SET_CHANGE,
  PASSAGE_INBOUND_SET_GRID_API,
} from '../../../store/modules/passageInbound';
import { cellClassAgGridRules } from '../../ag-grid/mixins/cellClassAgGridRules';
import { WEBSOCKET_CONNECTION } from '../../../store/modules/websocket';
import AgGridCustomService from '../../shared/services/agGridCustomService';
import AgGridValueWithAsteriskCellRenderer
from '../../ag-grid/cell-renderers/AgGridValueWithAsteriskCellRenderer.vue';
import AgGridConnectedFlightsAirportParkingSelectCellEditor
from '../../ag-grid/cell-editors/connectedFlights/AgGridConnectedFlightsAirportParkingSelectCellEditor.vue';
import AgGridConnectedFlightsAirportGateSelectCellEditor
from '../../ag-grid/cell-editors/connectedFlights/AgGridConnectedFlightsAirportGateSelectCellEditor.vue';
import AgGridConnectedFlightsAirportBeltSelectCellEditor
from '../../ag-grid/cell-editors/connectedFlights/AgGridConnectedFlightsAirportBeltSelectCellEditor.vue';
import { FisModule } from '../../ag-grid/cell-editors/model/fisModule';
import AgGridSchengenCellRenderer from '../../ag-grid/cell-renderers/AgGridSchengenCellRenderer.vue';
import AgGridConnectedFlightsNumberCellEditor from '../../ag-grid/cell-editors/connectedFlights/AgGridConnectedFlightsNumberCellEditor.vue';
import agGridTextCustomFilterComparator from '../../ag-grid/filters/agGridTextCustomFilterComparator';
import agGridTextCustomFilterFormatter from '../../ag-grid/filters/agGridTextCustomFilterFormatter';
import RolePermissions from '../../mixins/RolePermissions.vue';
import AgGridConnectedFlightsDialogMessageCellEditor
from '../../ag-grid/cell-editors/connectedFlights/dialog/AgGridConnectedFlightsDialogMessageCellEditor.vue';
import AgGridConnectedFlightsDialogFlightStatusIntegrationCellEditor from '../../ag-grid/cell-editors/connectedFlights/dialog/AgGridConnectedFlightsDialogFlightStatusIntegrationCellEditor.vue';
import AgGridConnectedFlightsDialogPassageCellEditor
from '../../ag-grid/cell-editors/connectedFlights/dialog/AgGridConnectedFlightsDialogPassageCellEditor.vue';
import AgGridValueAndSupFieldPossibleBlockedCellRenderer from '../../ag-grid/cell-renderers/AgGridValueAndSupFieldPossibleBlockedCellRenderer.vue';
import AgGridValueAndIconAndSupFieldPossibleBlockedCellRenderer from '../../ag-grid/cell-renderers/AgGridValueAndIconAndSupFieldPossibleBlockedCellRenderer.vue';
import AgGridTimeFieldPossibleBlockedCellRenderer from '../../ag-grid/cell-renderers/AgGridTimeFieldPossibleBlockedCellRenderer.vue';
import AgGridNoRowsOverlay from '../../ag-grid/mixins/AgGridNoRowsOverlay.vue';

export default {
  name: 'passage-inbound-flight-list-table',
  mixins: [AgGridConnectedFlights, FlightsDifferences, RolePermissions, AgGridNoRowsOverlay],
  components: {
    AgGridVue,
  },
  props: {
    items: { type: Array, required: true },
    isFilterDisplayed: { type: Boolean, required: false, default: false },
  },
  mounted() {
    this.$store.dispatch(WEBSOCKET_CONNECTION, this.$store.state.tenant.currentTenant.name.toLowerCase());
  },
  computed: {
    sizeFilter() {
      return this.isFilterDisplayed ? '250px' : '180px';
    },
    fitColumnsOnResize() {
      return this.$store.state.operation.fitColumnsOnResize;
    },
    dateFilter() {
      return this.$store.state.passageInbound.filter.dateFrom;
    },
  },
  data() {
    const that = this;
    const rolePermission = this.getRoles();
    return {
      dialog: false,
      scheduledTimeRequired: true,
      gridOptions: {
        onFilterChanged: this.uncheckAllOnFilter,
        getRowNodeId: this.getRowNodeId,
        getRowClass: this.getRowClass,
        floatingFilter: true,
        groupHeaderHeight: 35,
        headerHeight: 35,
        floatingFiltersHeight: 20,
        rowHeight: 30,
        context: {
          module: FisModule.PASSAGE_INBOUND,
        },
      },
      multiSortKey: 'ctrl',
      frameworkComponents: {
        AgGridConnectedFlightsAircraftTypeSelectCellEditor,
        AgGridConnectedFlightsAirportBeltSelectCellEditor,
        AgGridConnectedFlightsAirportGateSelectCellEditor,
        AgGridConnectedFlightsAirportParkingSelectCellEditor,
        AgGridConnectedFlightsTimeCellEditor,
        AgGridConnectedFlightsStringCellEditor,
        AgGridConnectedFlightsNumberCellEditor,
        AgGridConnectedFlightsDialogMessageCellEditor,
        AgGridConnectedFlightsDialogFlightStatusIntegrationCellEditor,
        AgGridConnectedFlightsDialogPassageCellEditor,
      },
      defaultColDef: {
        resizable: true,
        sortable: true,
        minWidth: 30,
        suppressMenu: true,
        filter: 'agTextColumnFilter',
        filterParams: {
          textCustomComparator: agGridTextCustomFilterComparator,
          textFormatter: agGridTextCustomFilterFormatter,
        },
        colCustomConfig: {
          flightBound: FlightBound.INBOUND,
        },
        cellClassRules: cellClassAgGridRules,
        comparator: this.objectWithValueComparator,
        editable: false,
      },
      columnDefs: [
        {
          headerName: '',
          children: [
            {
              headerTooltip: this.$i18n.t('common.checkUnCheck'),
              hide: !this.hasPermissions(rolePermission),
              checkboxSelection: true,
              width: 21,
              minWidth: 20,
              headerCheckboxSelection: true,
              headerCheckboxSelectionFilteredOnly: true,
              suppressMenu: true,
              sortable: false,
              suppressMovable: true,
              suppressSizeToFit: true,
              filter: false,
              resizable: false,
              colCustomConfig: {
                exportCsvDisabled: true,
              },
              editable: this.editable(true, rolePermission),
            },
            {
              headerName: 'SortDate',
              field: 'sortingTime',
              hide: true,
              sort: 'asc',
            },
          ],
        },
        {
          headerValueGetter() {
            return that.$i18n.t('commonForm.flightInformation');
          },
          headerTooltip: this.$i18n.t('commonForm.flightInformation'),
          children: [
            {
              colId: 'inbound.rampExtended.file',
              headerValueGetter() {
                return that.$i18n.t('ramp.file');
              },
              headerTooltip: this.$i18n.t('ramp.file'),
              width: 40,
              minWidth: 40,
              cellClass: 'aircraft-type-cell separator',
              comparator: this.objectWithValueComparator,
              colCustomConfig: {
                flightBound: FlightBound.INBOUND,
                fieldsRelated: ['inbound.rampExtended.file'],
              },
              valueGetter(params) {
                return params.data.getChildPropertyValue(FlightBound.ANY, ['rampExtended', 'file']);
              },
              cellRendererFramework: AgGridValueBaseCellRenderer,
            },
            {
              colId: 'inbound.common.flightNumber',
              headerValueGetter() {
                return that.getTextResizable(that.$i18n.t('operations.flightNumberShort'), that.$i18n.t('operations.flightNumber'));
              },
              headerTooltip: this.$i18n.t('operations.flightNumber'),
              width: 47,
              minWidth: 45,
              cellClass: 'flight-number-cell',
              comparator: this.objectWithValueComparator,
              cellEditor: 'AgGridConnectedFlightsFlightNumberCellEditor',
              colCustomConfig: {
                fieldPaths: ['common.flightNumber'],
                flightBound: FlightBound.INBOUND,
                fieldsRelated: ['inbound.common.flightNumber', 'inbound.common.flagAirlineOperation'],
              },
              flight: FlightBound.INBOUND,
              valueGetter(params) {
                return {
                  value: params.data.getChildPropertyValue(FlightBound.INBOUND, ['common', 'flightNumber']),
                  asterisk: params.data.getChildPropertyValue(FlightBound.INBOUND, ['common', 'flagAirlineOperation']),
                  tooltip: params.data.getChildPropertyValue(FlightBound.INBOUND, ['common', 'airlineOperationMessage']),
                };
              },
              cellRendererFramework: AgGridValueWithAsteriskCellRenderer,
            },
            {
              colId: 'inbound.common.aircraftTypeCode',
              headerValueGetter() {
                return that.$i18n.t('operations.aircraftType');
              },
              headerTooltip: this.$i18n.t('operations.aircraftType'),
              width: 60,
              cellClass: 'aircraft-type-cell',
              comparator: this.objectWithValueComparator,
              cellEditor: 'AgGridConnectedFlightsAircraftTypeSelectCellEditor',
              colCustomConfig: {
                fieldPaths: ['common.aircraftId', 'common.aircraftTypeId'],
                flightBound: FlightBound.INBOUND,
                fieldsRelated: ['inbound.common.aircraftTypeIataCode', 'inbound.common.aircraftTypeIcaoCode', 'inbound.common.aircraftCargo', 'inbound.blockedFieldsIntegration.manualOverwriteAircraftType'],
              },
              valueGetter(params) {
                return {
                  value: params.data.getChildPropertyValue(FlightBound.INBOUND, ['common', 'aircraftTypeCode']),
                  sup: params.data.getChildPropertyValue(FlightBound.INBOUND, ['common', 'aircraftCargo']) ? 'P' : null,
                  tooltip: params.data.getChildPropertyValue(FlightBound.INBOUND, ['common', 'aircraftTypeComments']),
                  fieldBlockedIntegration: params.data.getChildPropertyValue(FlightBound.INBOUND, ['blockedFieldsIntegration', 'manualOverwriteAircraftType']),
                };
              },
              cellRendererFramework: AgGridValueAndSupFieldPossibleBlockedCellRenderer,
            },
            {
              colId: 'inbound.common.aircraftRegisterNumber',
              headerValueGetter() {
                return that.$i18n.t('operations.aircraftRegistration');
              },
              headerTooltip: this.$i18n.t('operations.aircraftRegistration'),
              width: 70,
              colCustomConfig: {
                fieldPaths: ['common.aircraftId', 'common.aircraftTypeId'],
                flightBound: FlightBound.INBOUND,
                fieldsRelated: ['inbound.common.aircraftRegisterNumber', 'inbound.common.aircraftAcars', 'inbound.blockedFieldsIntegration.manualOverwriteAircraft'],
              },
              valueGetter(params) {
                return {
                  value: params.data.getChildPropertyValue(FlightBound.INBOUND, ['common', 'aircraftRegisterNumber']),
                  sup: params.data.getChildPropertyValue(FlightBound.INBOUND, ['common', 'aircraftAcars']) ? 'A' : null,
                  fieldBlockedIntegration: params.data.getChildPropertyValue(FlightBound.INBOUND, ['blockedFieldsIntegration', 'manualOverwriteAircraft']),
                };
              },
              cellRendererFramework: AgGridValueAndSupFieldPossibleBlockedCellRenderer,
            },
            {
              colId: 'inbound.common.airport1Code',
              headerValueGetter() {
                return that.$i18n.t('operations.airportOfDeparture');
              },
              headerTooltip: this.$i18n.t('operations.airportOfDeparture'),
              width: 50,
              colCustomConfig: {
                flightBound: FlightBound.INBOUND,
                fieldsRelated: ['inbound.common.airport1Code', 'inbound.common.hasSeveralAirports'],
              },
              valueGetter(params) {
                return {
                  value: params.data.getChildPropertyValue(FlightBound.INBOUND, ['common', 'airport1Code']),
                  asterisk: params.data.getChildPropertyValue(FlightBound.INBOUND, ['common', 'hasSeveralAirports']),
                  tooltip: params.data.getChildPropertyValue(FlightBound.INBOUND, ['common', 'hasSeveralAirports']) ? params.data.getChildPropertyValue(FlightBound.INBOUND, ['common', 'airportsIata']) : undefined,
                };
              },
              cellRendererFramework: AgGridValueWithAsteriskCellRenderer,
            },
            {
              colId: 'inbound.extended.airport1Schengen',
              headerValueGetter() {
                return that.$i18n.t('passage.airport1Schengen');
              },
              headerTooltip: this.$i18n.t('passage.airport1Schengen'),
              width: 20,
              colCustomConfig: {
                flightBound: FlightBound.INBOUND,
                fieldsRelated: ['inbound.extended.airport1Schengen'],
              },
              valueGetter(params) {
                return params.data.getChildPropertyValue(FlightBound.INBOUND, ['extended', 'airport1Schengen']);
              },
              cellRendererFramework: AgGridSchengenCellRenderer,
            },
            {
              colId: 'inbound.common.scheduledTime',
              headerValueGetter() {
                return that.$i18n.t('operations.scheduledTimeArrival');
              },
              headerTooltip: this.$i18n.t('operations.scheduledTimeArrival'),
              width: 50,
              colCustomConfig: {
                flightBound: FlightBound.INBOUND,
                fieldsRelated: ['inbound.common.scheduledTime'],
              },
              valueGetter(params) {
                return that.agGridTimeGetter(params.data.getChildPropertyValue(FlightBound.INBOUND, ['common', 'scheduledTime']), params.data.getChildPropertyValue(FlightBound.INBOUND, ['common', 'scheduledDate']), null, null, that.scheduledTimeRequired);
              },
              cellRendererFramework: AgGridTimeCellRenderer,
            },
            {
              colId: 'inbound.times.estimatedTime',
              headerValueGetter() {
                return that.$i18n.t('flightInformation.estimatedTimeArrival');
              },
              headerTooltip: this.$i18n.t('flightInformation.estimatedTimeArrival'),
              width: 50,
              colCustomConfig: {
                flightBound: FlightBound.INBOUND,
                fieldsRelated: ['inbound.times.estimatedTime', 'inbound.blockedFieldsIntegration.manualOverwriteEstimatedTime'],
              },
              valueGetter(params) {
                const value = that.agGridTimeGetter(params.data.getChildPropertyValue(FlightBound.INBOUND, ['times', 'estimatedTime']), params.data.getChildPropertyValue(FlightBound.INBOUND, ['common', 'scheduledDate']));
                value.fieldBlockedIntegration = params.data.getChildPropertyValue(FlightBound.INBOUND, ['blockedFieldsIntegration', 'manualOverwriteEstimatedTime']);
                return value;
              },
              cellRendererFramework: AgGridTimeFieldPossibleBlockedCellRenderer,
            },
            {
              colId: 'inbound.common.status',
              headerValueGetter() {
                return that.getTextResizable(that.$i18n.t('operations.statusShort'), that.$i18n.t('operations.status'));
              },
              headerTooltip: this.$i18n.t('operations.status'),
              width: 50,
              colCustomConfig: {
                flightBound: FlightBound.INBOUND,
                fieldsRelated: ['inbound.common.status'],
              },
              valueGetter(params) {
                return params.data.getChildPropertyValue(FlightBound.INBOUND, ['common', 'status']);
              },
              cellRendererFramework: AgGridFlightStatusCellRenderer,
            },
            {
              colId: 'inbound.integration.flightStatusIntegration',
              headerValueGetter() {
                return that.getTextResizable(that.$i18n.t('operations.airportStatusShort'), that.$i18n.t('operations.airportStatus'));
              },
              headerTooltip: (() => this.$i18n.t('operations.airportStatus'))(),
              width: 48,
              minWidth: 39,
              editable: this.isInboundAndNotAircraftGround,
              cellEditor: 'AgGridConnectedFlightsDialogFlightStatusIntegrationCellEditor',
              comparator: this.objectWithValueComparator,
              cellClass: 'airport-status-cell',
              colCustomConfig: {
                flightBound: FlightBound.INBOUND,
                fieldsRelated: ['inbound.integration.flightStatusIntegration'],
              },
              flight: FlightBound.INBOUND,
              valueGetter(params) {
                return {
                  value: params.data.getChildPropertyValue(FlightBound.INBOUND, ['integration', 'statusIntegration']),
                  tooltip: params.data.getChildPropertyValue(FlightBound.INBOUND, ['integration', 'statusIntegration']) != null ? that.$i18n.t(`flightStatusIntegration.${params.data.getChildPropertyValue(FlightBound.INBOUND, ['integration', 'statusIntegration'])}`) : null,
                };
              },
              cellRendererFramework: AgGridFlightStatusCellRenderer,
            },
            {
              colId: 'inbound.extended.msg',
              headerValueGetter() {
                return that.$i18n.t('operations.messages');
              },
              headerTooltip: this.$i18n.t('operations.messages'),
              width: 50,
              editable: true,
              cellEditor: 'AgGridConnectedFlightsDialogMessageCellEditor',
              colCustomConfig: {
                flightBound: FlightBound.INBOUND,
                fieldsRelated: ['inbound.extended.messageStatus'],
              },
              valueGetter(params) {
                const messageValue = params.data.getChildPropertyValue(FlightBound.INBOUND, ['extended', 'messageStatus']);
                return that.checkBoundAndSetMsgTooltipObject(messageValue);
              },
              cellRendererFramework: AgGridIconTooltipOperationCellRenderer,
            },
            {
              colId: 'inbound.times.landTime',
              headerValueGetter() {
                return that.$i18n.t('flightInformation.landTimeArrival');
              },
              headerTooltip: this.$i18n.t('flightInformation.landTimeArrival'),
              width: 50,
              colCustomConfig: {
                flightBound: FlightBound.INBOUND,
                fieldsRelated: ['inbound.times.landTime', 'inbound.blockedFieldsIntegration.manualOverwriteLandTime'],
              },
              valueGetter(params) {
                const value = that.agGridTimeGetter(params.data.getChildPropertyValue(FlightBound.INBOUND, ['times', 'landTime']), params.data.getChildPropertyValue(FlightBound.INBOUND, ['common', 'scheduledDate']));
                value.fieldBlockedIntegration = params.data.getChildPropertyValue(FlightBound.INBOUND, ['blockedFieldsIntegration', 'manualOverwriteLandTime']);
                return value;
              },
              cellRendererFramework: AgGridTimeFieldPossibleBlockedCellRenderer,
            },
            {
              colId: 'inbound.times.blockTime',
              headerValueGetter() {
                return that.$i18n.t('passage.blockTimeArrival');
              },
              headerTooltip: this.$i18n.t('passage.blockTimeArrival'),
              width: 50,
              cellClass: 'separator',
              editable: this.editable(true, rolePermission),
              cellEditor: 'AgGridConnectedFlightsTimeCellEditor',
              colCustomConfig: {
                fieldPaths: ['times.blockTime'],
                flightBound: FlightBound.INBOUND,
                fieldsRelated: ['inbound.times.blockTime', 'inbound.blockedFieldsIntegration.manualOverwriteBlockTime'],
              },
              valueGetter(params) {
                const value = that.agGridTimeGetter(params.data.getChildPropertyValue(FlightBound.INBOUND, ['times', 'blockTime']), params.data.getChildPropertyValue(FlightBound.INBOUND, ['common', 'scheduledDate']));
                value.fieldBlockedIntegration = params.data.getChildPropertyValue(FlightBound.INBOUND, ['blockedFieldsIntegration', 'manualOverwriteBlockTime']);
                return value;
              },
              cellRendererFramework: AgGridTimeFieldPossibleBlockedCellRenderer,
            },
          ],
        },
        {
          headerValueGetter() {
            return that.$i18n.t('passage.passengersInfo');
          },
          headerTooltip: this.$i18n.t('passage.passengersInfo'),
          children: [
            {
              colId: 'inbound.passageSummary.localPaxExpected',
              headerValueGetter() {
                return that.$i18n.t('operations.prevPax');
              },
              headerTooltip: this.$i18n.t('operations.prevPax'),
              width: 50,
              editable: this.editable(true, rolePermission),
              cellEditor: 'AgGridConnectedFlightsNumberCellEditor',
              colCustomConfig: {
                fieldPaths: ['passageSummary.localPaxExpected'],
                flightBound: FlightBound.INBOUND,
                fieldsRelated: ['inbound.passageSummary.localPaxExpected'],
              },
              valueGetter(params) {
                return params.data.getChildPropertyValue(FlightBound.INBOUND, ['passageSummary', 'localPaxExpected']);
              },
              cellRendererFramework: AgGridValueBaseCellRenderer,
            },
            {
              colId: 'inbound.passageSummary.localPaxCheckInCounters',
              headerValueGetter() {
                return that.$i18n.t('operations.flightPassengers');
              },
              headerTooltip: this.$i18n.t('operations.flightPassengers'),
              width: 50,
              editable: this.editable(true, rolePermission),
              cellEditor: 'AgGridConnectedFlightsDialogPassageCellEditor',
              field: 'inbound.passageIn.localPaxCheckInCounters',
              colCustomConfig: {
                flightBound: FlightBound.INBOUND,
                fieldsRelated: ['inbound.passageSummary.passengersNumber', 'inbound.passageSummary.passengersChildNumber', 'inbound.passageSummary.passengersSpecial'],
              },
              valueGetter(params) {
                const isRealNumber = params.data.getChildPropertyValue(FlightBound.INBOUND, ['passageSummary', 'passengersNumber']) > 0 || params.data.getChildPropertyValue(FlightBound.INBOUND, ['passageSummary', 'passengersChildNumber']) > 0;
                let text = params.data.getChildPropertyValue(FlightBound.INBOUND, ['passageSummary', 'passengersNumber']) == null ? '' : params.data.getChildPropertyValue(FlightBound.INBOUND, ['passageSummary', 'passengersNumber']);
                let tooltip = '';
                let styleContent = {};
                text += params.data.getChildPropertyValue(FlightBound.INBOUND, ['passageSummary', 'passengersChildNumber']) == null ? '' : `+${params.data.getChildPropertyValue(FlightBound.INBOUND, ['passageSummary', 'passengersChildNumber'])}`;
                if ((params.data.getChildPropertyValue(FlightBound.INBOUND, ['passageSummary', 'passengersEstimated']) > 0) && !isRealNumber) {
                  text = params.data.getChildPropertyValue(FlightBound.INBOUND, ['passageSummary', 'passengersEstimated']);
                  styleContent = { color: 'darkslategray', 'font-weight': 'bold', 'font-style': 'italic' };
                }
                // when handling type is P, the total of passengers must be (P)
                if (params.data.getChildPropertyValue(FlightBound.INBOUND, ['common', 'handlingTypeCode']) === 'P') {
                  text = '(P)';
                  tooltip = that.$i18n.t('operations.emptyFlight');
                }
                return {
                  value: text,
                  asterisk: params.data.getChildPropertyValue(FlightBound.INBOUND, ['passageSummary', 'passengersSpecial']),
                  styleObject: {
                    asteriskColor: 'red',
                    content: styleContent,
                  },
                  tooltip,
                };
              },
              cellRendererFramework: AgGridValueWithAsteriskCellRenderer,
            },
            {
              colId: 'inbound.passageSummary.connectionPax',
              headerValueGetter() {
                return that.getTextResizable(that.$i18n.t('passage.connectionPaxShort'), that.$i18n.t('passage.connectionPax'));
              },
              headerTooltip: this.$i18n.t('passage.connectionPax'),
              width: 50,
              editable: this.editable(true, rolePermission),
              cellEditor: 'AgGridConnectedFlightsNumberCellEditor',
              colCustomConfig: {
                fieldPaths: ['passageSummary.connectionPax'],
                flightBound: FlightBound.INBOUND,
                fieldsRelated: ['inbound.passageSummary.connectionPax'],
              },
              valueGetter(params) {
                return params.data.getChildPropertyValue(FlightBound.INBOUND, ['passageSummary', 'connectionPax']);
              },
              cellRendererFramework: AgGridValueBaseCellRenderer,
            },
            {
              colId: 'inbound.common.belt',
              headerValueGetter() {
                return that.$i18n.t('ramp.belt');
              },
              headerTooltip: this.$i18n.t('ramp.belt'),
              width: 50,
              editable: this.editable(true, rolePermission),
              cellClass: 'aircraft-config-cell',
              comparator: this.objectWithValueComparator,
              cellEditor: 'AgGridConnectedFlightsAirportBeltSelectCellEditor',
              colCustomConfig: {
                fieldPaths: ['common.beltId'],
                flightBound: FlightBound.INBOUND,
                fieldsRelated: ['inbound.common.beltId', 'inbound.common.beltCode', 'inbound.blockedFieldsIntegration.manualOverwriteBelt'],
              },
              valueGetter(params) {
                return {
                  value: params.data.getChildPropertyValue(FlightBound.INBOUND, ['common', 'beltCode']),
                  fieldBlockedIntegration: params.data.getChildPropertyValue(FlightBound.INBOUND, ['blockedFieldsIntegration', 'manualOverwriteBelt']),
                };
              },
              cellRendererFramework: AgGridValueAndSupFieldPossibleBlockedCellRenderer,
            },
            {
              colId: 'inbound.common.pkn',
              headerValueGetter() {
                return that.$i18n.t('operations.parking');
              },
              headerTooltip: this.$i18n.t('operations.parking'),
              width: 38,
              minWidth: 38,
              cellClass: 'aircraft-config-cell',
              comparator: this.objectWithValueComparator,
              editable: this.editable(true, rolePermission),
              cellEditor: 'AgGridConnectedFlightsAirportParkingSelectCellEditor',
              colCustomConfig: {
                fieldPaths: ['common.parkingId'],
                flightBound: FlightBound.INBOUND,
                fieldsRelated: ['passageIn.common.parkingCode', 'inbound.common.parkingCode', 'inbound.common.parkingRemote', 'inbound.common.parkingPushback', 'inbound.common.parkingInop400Hz', 'inbound.blockedFieldsIntegration.manualOverwriteParking'],
              },
              valueGetter(params) {
                let superindice = params.data.getChildPropertyValue(FlightBound.INBOUND, ['common', 'parkingRemote']) ? 'R' : '';
                superindice += params.data.getChildPropertyValue(FlightBound.INBOUND, ['common', 'parkingPushback']) ? 'P' : '';
                return {
                  value: params.data.getChildPropertyValue(FlightBound.INBOUND, ['common', 'parkingCode']),
                  sup: superindice,
                  fieldBlockedIntegration: params.data.getChildPropertyValue(FlightBound.INBOUND, ['blockedFieldsIntegration', 'manualOverwriteParking']),
                  icon: params.data.getChildPropertyValue(FlightBound.INBOUND, ['common', 'parkingInop400Hz']) ? 'flash_off' : null,
                  colorIcon: params.data.getChildPropertyValue(FlightBound.INBOUND, ['common', 'parkingInop400Hz']) ? 'red' : null,
                };
              },
              cellRendererFramework: AgGridValueAndIconAndSupFieldPossibleBlockedCellRenderer,
            },
            {
              colId: 'passageIn.remarks.passageRemarks',
              headerValueGetter() {
                return that.$i18n.t('passage.passageRemarks');
              },
              headerTooltip: this.$i18n.t('passage.passageRemarks'),
              width: 70,
              editable: this.editable(true, rolePermission),
              cellEditor: 'AgGridConnectedFlightsStringCellEditor',
              colCustomConfig: {
                fieldPaths: ['remarks.passageRemarks'],
                flightBound: FlightBound.INBOUND,
                fieldsRelated: ['inbound.remarks.passageRemarks'],
              },
              valueGetter(params) {
                return params.data.getChildPropertyValue(FlightBound.INBOUND, ['remarks', 'passageRemarks']);
              },
              cellRendererFramework: AgGridValueBaseCellRenderer,
            },
            {
              colId: 'inbound.common.gat',
              headerValueGetter() {
                return that.$i18n.t('operations.gate');
              },
              headerTooltip: this.$i18n.t('operations.gate'),
              width: 50,
              cellClass: 'aircraft-config-cell',
              comparator: this.objectWithValueComparator,
              editable: this.editable(true, rolePermission),
              cellEditor: 'AgGridConnectedFlightsAirportGateSelectCellEditor',
              colCustomConfig: {
                fieldPaths: ['common.gateId'],
                flightBound: FlightBound.INBOUND,
                fieldsRelated: ['inbound.common.gateCode', 'inbound.common.gateRemote'],
              },
              valueGetter(params) {
                return {
                  value: params.data.getChildPropertyValue(FlightBound.INBOUND, ['common', 'gateCode']),
                  sup: params.data.getChildPropertyValue(FlightBound.INBOUND, ['common', 'gateRemote']) ? 'R' : null,
                };
              },
              cellRendererFramework: AgGridValueAndSupCellRenderer,
            },
          ],
        },
        {
          headerValueGetter() {
            return that.$i18n.t('sidebar.Passage');
          },
          headerTooltip: this.$i18n.t('sidebar.Passage'),
          children: [
            {
              colId: 'inbound.passageSummary.paxUMNR',
              headerValueGetter() {
                return that.$i18n.t('passage.umnr');
              },
              headerTooltip: this.$i18n.t('passage.umnr'),
              width: 30,
              colCustomConfig: {
                flightBound: FlightBound.INBOUND,
                fieldsRelated: ['inbound.passageSummary.paxUMNR'],
              },
              valueGetter(params) {
                return params.data.getChildPropertyValue(FlightBound.INBOUND, ['passageSummary', 'paxUMNR']);
              },
              cellRendererFramework: AgGridValueBaseCellRenderer,
            },
            {
              colId: 'inbound.passageSummary.numberOfWC',
              headerValueGetter() {
                return that.$i18n.t('passage.wc');
              },
              headerTooltip: this.$i18n.t('passage.wc'),
              width: 30,
              colCustomConfig: {
                flightBound: FlightBound.INBOUND,
                fieldsRelated: ['inbound.passageSummary.wchc'],
              },
              valueGetter(params) {
                return params.data.getChildPropertyValue(FlightBound.INBOUND, ['passageSummary', 'wchc']);
              },
              cellRendererFramework: AgGridValueBaseCellRenderer,
            },
            {
              colId: 'inbound.passageSummary.numberOfWR',
              headerValueGetter() {
                return that.$i18n.t('passage.wr');
              },
              headerTooltip: this.$i18n.t('passage.wr'),
              width: 30,
              colCustomConfig: {
                flightBound: FlightBound.INBOUND,
                fieldsRelated: ['inbound.passageSummary.wchr'],
              },
              valueGetter(params) {
                return params.data.getChildPropertyValue(FlightBound.INBOUND, ['passageSummary', 'wchr']);
              },
              cellRendererFramework: AgGridValueBaseCellRenderer,
            },
            {
              colId: 'inbound.passageSummary.numberOfWS',
              headerValueGetter() {
                return that.$i18n.t('passage.ws');
              },
              headerTooltip: this.$i18n.t('passage.ws'),
              width: 30,
              colCustomConfig: {
                flightBound: FlightBound.INBOUND,
                fieldsRelated: ['inbound.passageSummary.wchs'],
              },
              valueGetter(params) {
                return params.data.getChildPropertyValue(FlightBound.INBOUND, ['passageSummary', 'wchs']);
              },
              cellRendererFramework: AgGridValueBaseCellRenderer,
            },
          ],
        },
      ],
    };
  },
  methods: {
    onGetChanges(changes) {
      const rowNode = this.gridApi.getRowNode(changes.payload.id);

      if (!rowNode) {
        return;
      }
      const oldConnectedFlight = rowNode.data.clone();
      const newConnectedFlight = changes.payload;
      this.assignInboundOrOutboundIfDontExist(newConnectedFlight, oldConnectedFlight);
      const differencies = this.getDifferenciesAndCreateOldValuesProperties(oldConnectedFlight, newConnectedFlight);

      this.setOldValuesDifferencesFromOldConnectedFlight(rowNode.data, newConnectedFlight);

      if (differencies && differencies.length > 0) {
        rowNode.setData(newConnectedFlight);
        if (this.$store.state.passageInbound.flashOnChanges) {
          this.renderNeededDiffs(differencies, rowNode); // TODO only shows flash
        }
        differencies.forEach(dif => this.$store.commit(PASSAGE_INBOUND_SET_CHANGE, { diff: dif, connectedFlights: newConnectedFlight, user: changes.origin }));
      }
    },
    onGridReady(params) {
      this.setAgGridApi(params);
      this.showLoading();
      this.sizeColumnsToFit();
      this.$store.commit(PASSAGE_INBOUND_SET_GRID_API, params); // TODO eliminar api del store

      this.refreshCellsOnMutation();
      this.refreshCellsOnChangeLanguage();
      const that = this;
      this.subscribeMutation(PASSAGE_INBOUND_ON_EXTERNAL_CHANGE, (mutationPayload) => {
        that.onGetChanges(mutationPayload);
      });
    },
    firstDataRendered() {
      this.$nextTick(() => {
        this.resized();
      });
    },
    resized() {
      if (this.gridApi) {
        this.gridApi.sizeColumnsToFit();
      }
    },
    sizeColumnsToFit() {
      if (this.gridApi) {
        this.$store.dispatch(FIT_COLUMNS_TO_VIEW);
      }
    },
    onSelectionChanged(event) {
      const selectedNodes = event.api.getSelectedNodes();
      const selectedData = selectedNodes.map(node => node.data);
      this.$store.commit(PASSAGE_INBOUND_CHANGE_FLIGHT_SELECTION, selectedData);
    },
    deleteItem(item) {
      console.log(`delete ${item}`); // eslint-disable-line no-console
    },
    editItem(item) {
      console.log(`edit ${item}`); // eslint-disable-line no-console
    },
    isInbound(params) {
      return params.data.hasInbound();
    },
    isOutbound(params) {
      return params.data.hasOutbound();
    },
    bestTimeGetter(bestTime, scheduledTime, referenceDateTime, flightStatus) {
      const result = this.agGridTimeGetter(bestTime, referenceDateTime);
      const scheduledTimeAux = scheduledTime ? this.$moment(scheduledTime) : null;
      if (result.value && result.value.isValid()) {
        if (scheduledTimeAux && scheduledTimeAux.isValid() && result.value.startOf('minute').isAfter(scheduledTimeAux.startOf('minute'))) {
          result.color = 'red';
        }
      }
      if (flightStatus) {
        result.cssClass = `status-op status-${flightStatus}`;
        result.tooltip = flightStatus;
      }
      return result;
    },
    renderNeededDiffs(differences, rowNode) {
      const { gridApi } = this.$store.state.operation;

      const agGridCustomService = new AgGridCustomService(this.columnDefs); // TODO one time
      const colIds = agGridCustomService.getColIdByMatchAnyfieldsRelated(this.joinAllPropertyPath(differences));

      if (colIds && colIds.length > 0) {
        colIds.forEach((colId) => {
          if (gridApi.getColumnDef(colId)) {
            gridApi.refreshCells({
              rowNodes: [rowNode],
              columns: [colId],
              force: true,
            });

            gridApi.flashCells({
              rowNodes: [rowNode],
              columns: [colId],
              force: true,
            });
          }
        });
      }
    },
    checkBoundAndSetMsgTooltipObject(messageValue) {
      if (messageValue === null || typeof (messageValue) === 'undefined') {
        return null;
      }
      if (messageValue === 'WARNING_LDM') {
        return {
          value: messageValue,
          icon: 'warning',
          color: 'red',
          tooltip: this.$t('messages.warningMessageLDM'),
        };
      }

      return {
        value: messageValue,
        icon: messageValue !== 'EMPTY' ? 'sms' : '',
        color: messageValue === 'UNREAD' ? 'success' : 'bggreydark',
        tooltip: messageValue === 'UNREAD' ? this.$t('messages.messagesUnread') : this.$t('messages.messagesRead'),
      };
    },
    checkBoundAndSetRemTooltipObject(remarkValue) {
      if (remarkValue === null || typeof (remarkValue) === 'undefined') {
        return null;
      }

      return {
        value: remarkValue,
        icon: remarkValue !== 'EMPTY' ? 'info' : '',
        color: remarkValue === 'UNREAD' ? 'primary' : 'bggreydark',
        tooltip: remarkValue === 'UNREAD' ? 'There are remarks unread' : 'All remarks are read',
      };
    },
  },
};

</script>
