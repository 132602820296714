<template>
  <div>
    <v-flex v-flex-none>
      <duration-time-input :ref="refTimeInput" v-model="time"></duration-time-input>
    </v-flex>
  </div>
</template>

<script>
import Vue from 'vue';
import AgGridParentCellEditor from './mixins/AgGridParentCellEditor.vue';
import DurationTimeInput from '../../ui/DurationTimeInput.vue';

export default Vue.extend({
  mixins: [AgGridParentCellEditor],
  components: {
    DurationTimeInput,
  },
  props: {
    refTimeInput: {
      type: String,
      required: false,
      default: 'refTimeInput',
    },
  },
  data() {
    return {
      time: null,
    };
  },
  methods: {
    getValue() {
      this.beforeReturnGetValue();
      return this.time ? Number(this.time) / 60000 : null;
    },
  },
  created() {
    this.time = this.params.value ? Number(this.params.value) * 60000 : null;
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$refs.refTimeInput) {
        this.$refs.refTimeInput.$refs['time-input-ref'].focus();
      }
    });
  },
});
</script>

<style>
  .scheduled-time-cell.ag-cell-inline-editing {
    min-width: 60px;
  }
</style>
