<template>
  <v-container class="infoMobileMessages pt-4">
    <v-card :dark="this.$vuetify.dark">
      <v-card-title>
        <div class="font-weight-bold">
          <v-icon>message</v-icon>
          {{ $t('dispatcher.messages') }}
        </div>
        <v-spacer></v-spacer>
        <v-card-actions v-if="enableSend()">
          <v-btn icon @click="sendNewMessage" color="primary" v-if="!newMessage">
            <v-icon>add</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card-title>
      <v-divider></v-divider>
        <mobile-new-message
        v-if="flightComposition()"
        :flight-composition="flightComposition()"
        :flightId="flightId" @close="newMessage = false" :visible="newMessage" />
      <v-list class="pa-0">
        <div v-for="(message, index) in flightMobileMail" :key="message.id" :class="message.typeCode">
          <v-list-tile>
            <v-list-tile-content>
              <v-list-tile-title>
                <v-icon class="mr-2" v-if="message.bound === 'INBOUND'">mail</v-icon>
                <v-icon class="mr-2" v-if="message.bound === 'OUTBOUND'">send</v-icon>
                {{ formatDate(message.ts) }}
              </v-list-tile-title>
            </v-list-tile-content>
            <v-list-tile-action class="full-width">
              <v-chip :color="$vuetify.dark ? 'dark' :'light'"
              :text-color="$vuetify.dark ? 'white' :'black'"
               v-if="message.typeCode" class="text-uppercase font-weight-bold">{{ message.typeCode }}</v-chip>
            </v-list-tile-action>
          </v-list-tile>
          <v-divider />
          <v-list-tile-action>
            <v-list-tile-content>
              <code name="content" id="content" class="full-width">{{ message.content.trim() }}</code>
            </v-list-tile-content>
          </v-list-tile-action>
          <v-divider v-if="index < flightMobileMail.length - 1"></v-divider>
        </div>
      </v-list>
      <!-- no messages -->
      <v-card-actions v-if="isEmptyMessages()">
        <v-spacer></v-spacer>
        <div class="text-xs-center">
          <v-icon x-large color="grey lighten-1">message</v-icon>
          <div class="grey--text">{{ $t('dispatcher.noMessages') }}</div>
        </div>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
    <scroll-buttons />
  </v-container>
</template>
<script>

import { VLayout } from 'vuetify/lib';
import scrollButtons from './scroll/scrollButtons.vue';
import RolePermissions from '../mixins/RolePermissions.vue';
import MobileNewMessage from './messages/MobileNewMessage.vue';
import { MOBILE_GET_FLIGHT_BY_ID } from '../../store/modules/mobile';
import { FlightBound } from '../shared/model/flightBound';
import ConnectedFlightsFactory from '../shared/model/connectedFlightsFactory';
import { TIME_TIMEZONE_CHANGED } from '../../store/modules/time';
import StoreSubscribable from '../mixins/StoreSubscribable.vue';

export default {
  name: 'info-mobile-messages',
  props: {
    flightId: {
      required: true,
    },
    flightMobileMail: {
      required: true,
    },
    flightBound: {
      required: true,
      type: String,
    },
  },
  mixins: [StoreSubscribable, RolePermissions],
  components: {
    scrollButtons,
    VLayout,
    MobileNewMessage,
  },
  methods: {
    isEmptyMessages() {
      return this.flightMobileMail.length === 0 || this.flightMobileMail === null;
    },
    formatDate(date) {
      return this.$moment(date).tz(this.localTimezone).format('DD/MM/YYYY HH:mm');
    },
    sendNewMessage() {
      this.newMessage = true;
    },
    currentFlight() {
      return this.$store.state.mobile.flightMobileById;
    },
    flightComposition() {
      const flightBound = FlightBound[this.flightBound];
      const connectedFlights = ConnectedFlightsFactory.mapConnectedFlights(this.$store.state.mobile.flightMobileById);
      return connectedFlights.getBoundComposition(flightBound);
    },
    enableSend() {
      if (this.hasPermissions('AIRPORT_SERVICE_AGENT_ROLE_MAIL_PERMISSION_WR')) {
        return true;
      }
      return false;
    },
  },
  data() {
    return {
      newMessage: false,
      message: null,
      localTimezone: this.$store.state.time.timezone,
    };
  },
  created() {
    this.subscribeMutation(TIME_TIMEZONE_CHANGED, () => {
      this.localTimezone = this.$store.state.time.timezone;
    });
    if (!this.flightId) {
      this.$router.push({ name: 'mobile' });
    } else {
      this.$store.dispatch(MOBILE_GET_FLIGHT_BY_ID, this.flightId);
    }
  },
};
</script>
<style>
.infoMobileMessages {
  font-size: 16px;
}

.infoMobileMessages code.full-width {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    text-wrap: nowrap;
    overflow-x: auto;
}

.infoMobileMessages code.full-width:after,  .infoMobileMessages code.full-width:before {
    content: "";
    letter-spacing: unset;
}
.infoMobileMessages span.v-chip__content {
    font-size: 15px;
}

.dark-mode .infoMobileMessages code {
  background-color: #525252;
  color: lightpink;
}

</style>
