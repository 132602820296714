<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :nudge-width="200"
    offset-x
  >
    <template v-slot:activator="{ on }">
      <v-tooltip left>
        <v-btn
          dark
          icon
          v-on="on"
          slot="activator"
        >
          <v-icon>more_vert</v-icon>
        </v-btn>
        <span>{{$t("tableOptions.tableOptionsTitle")}}</span>
      </v-tooltip>
    </template>

    <LostAndFoundListTableOptions />
  </v-menu>
</template>
<script>
import LostAndFoundListTableOptions from './LostAndFoundListTableOptions.vue';

export default {
  name: 'LostAndFoundListTableOptionsFilterButton',
  components: { LostAndFoundListTableOptions },
  data() {
    return {
      menu: false,
    };
  },
};
</script>
