/* eslint no-param-reassign: "off" */
/* eslint no-confusing-arrow: "off" */
/* eslint no-nested-ternary: "off" */

import { HTTP } from '../../../http';
import { REQUEST_ERROR, REQUEST_START, REQUEST_SUCCESS } from '../../store';
import { NOTIFICATION_SHOW } from '../notifications';
import i18n from '../../../plugins/i18n';

// actions names
export const GET_MILESTONES = 'GET_MILESTONES';
export const MILESTONE_DELETE = 'MILESTONE_DELETE';
export const MILESTONE_CREATE = 'MILESTONE_CREATE';
export const MILESTONE_UPDATE = 'MILESTONE_UPDATE';


// mutations
export const CHANGE_MILESTONE_SELECTION = 'CHANGE_MILESTONE_SELECTION';
export const GET_MILESTONES_SUCCESS = 'GET_MILESTONES_SUCCESS';
export const GET_MILESTONES_REQUESTED = 'GET_MILESTONES_REQUESTED';
export const UPDATE_MILESTONE_SUCCESS = 'UPDATE_MILESTONE_SUCCESS';

// initial state
const storeState = {
  milestones: [],
  milestoneRequested: false,
  selectedMilestone: [],
};

// getters
const storeGetters = {
  getMilestones: state => state.milestones.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)),
  getActiveMilestones: state => state.milestones.filter(elem => !elem.deleted).filter(elem => !!elem.code).sort((a, b) => (a.code > b.code) ? 1 : ((b.code > a.code) ? -1 : 0)),
  getMilestoneSelected: state => state.selectedMilestone,
  hasSelectedMilestone: state => state.selectedMilestone.length > 0,
  getMilestoneById: state => id => state.milestones.find(a => a.id === id),
};

// actions
const storeActions = {
  [GET_MILESTONES]: ({ commit, state }) => {
    if (state.milestoneRequested) {
      return;
    }
    commit(REQUEST_START);
    commit(GET_MILESTONES_REQUESTED);
    HTTP.get('milestones')
      .then((resp) => {
        commit(REQUEST_SUCCESS);
        // if rigid is true disable edit and delete
        resp.data.forEach((milestone) => {
          if (milestone.rigid) {
            milestone.disableEdit = true;
            milestone.disableDelete = true;
          }
        });
        commit(GET_MILESTONES_SUCCESS, resp.data);
      })
      .catch((err) => {
        commit(REQUEST_ERROR, err);
      });
  },

  [MILESTONE_CREATE]: ({ commit, dispatch }, milestone) => {
    commit(REQUEST_START);
    return HTTP.post('milestone', milestone)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(UPDATE_MILESTONE_SUCCESS);
        dispatch(GET_MILESTONES);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.milestoneCreate'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },

  [MILESTONE_UPDATE]: ({ commit, dispatch }, milestone) => {
    commit(REQUEST_START);
    return HTTP.put('milestone', milestone)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(UPDATE_MILESTONE_SUCCESS);
        dispatch(GET_MILESTONES);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.milestoneUpdate'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
  [MILESTONE_DELETE]: ({ commit, dispatch }, id) => {
    commit(REQUEST_START);
    return HTTP.patch(`milestone/logical-delete/${id}`)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(UPDATE_MILESTONE_SUCCESS);
        dispatch(GET_MILESTONES);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.milestoneDelete'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
};

// mutations
const storeMutations = {
  [GET_MILESTONES_SUCCESS]: (state, milestones) => {
    state.milestones = milestones;
  },
  [GET_MILESTONES_REQUESTED]: (state) => {
    state.milestoneRequested = true;
  },
  [UPDATE_MILESTONE_SUCCESS]: (state) => {
    state.milestoneRequested = false;
  },
  [CHANGE_MILESTONE_SELECTION]: (state, selection) => {
    state.selectedMilestone = selection;
  },
};

export default {
  state: storeState,
  getters: storeGetters,
  actions: storeActions,
  mutations: storeMutations,
};
