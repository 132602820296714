<template>
  <div :style="`width: 100%; height: calc(100vh - ${this.sizeFilter});`">
    <ag-grid-vue :dark="this.$vuetify.dark" class="ag-theme-material connected-flights-table operational-table"
      :columnDefs="columnDefs" :defaultColDef="defaultColDef" :rowData="items" :rowSelection="'multiple'"
      :suppressRowClickSelection="true" :rowMultiSelectWithClick="false" @grid-ready="onGridReady"
      @first-data-rendered="firstDataRendered" @selection-changed="onSelectionChanged"
      :frameworkComponents="frameworkComponents" :gridOptions="gridOptions" :multiSortKey="multiSortKey"
      :isRowSelectable="isRowSelectable" :overlayNoRowsTemplate="customNoRowsOverlay"
      :overlayLoadingTemplate="customLoadingOverlay"
      v-bind:style="`width: 100%; height: calc(100vh - ${this.sizeFilter});`">
    </ag-grid-vue>
  </div>
</template>

<script>
import { AgGridVue } from 'ag-grid-vue';
import AgGridStatusOperationCellRenderer from '../../ag-grid/cell-renderers/AgGridStatusOperationCellRenderer.vue';
import AgGridIconTooltipOperationCellRenderer from '../../ag-grid/cell-renderers/AgGridIconTooltipOperationCellRenderer.vue';
import AgGridMultilineStringsCellRenderer from '../../ag-grid/cell-renderers/AgGridMultilineStringsCellRenderer.vue';
import AgGridConnectedFlightsAircraftTypeSelectCellEditor from '../../ag-grid/cell-editors/connectedFlights/AgGridConnectedFlightsAircraftTypeSelectCellEditor.vue';
import AgGridConnectedFlightsAircraftSelectCellEditor from '../../ag-grid/cell-editors/connectedFlights/AgGridConnectedFlightsAircraftSelectCellEditor.vue';
import AgGridConnectedFlightsTimeCellEditor from '../../ag-grid/cell-editors/connectedFlights/AgGridConnectedFlightsTimeCellEditor.vue';
import AgGridConnectedFlightsStringCellEditor from '../../ag-grid/cell-editors/connectedFlights/AgGridConnectedFlightsStringCellEditor.vue';
import AgGridConnectedFlightsDialogDelayCellEditor from '../../ag-grid/cell-editors/connectedFlights/dialog/AgGridConnectedFlightsDialogDelayCellEditor.vue';
import AgGridConnectedFlightsDialogStatusCellEditor from '../../ag-grid/cell-editors/connectedFlights/dialog/AgGridConnectedFlightsDialogStatusCellEditor.vue';
import AgGridConnectedFlightsRemarksCellEditor from '../../ag-grid/cell-editors/connectedFlights/dialog/AgGridConnectedFlightsRemarksCellEditor.vue';
import AgGridConnectedFlightsDialogMessageCellEditor from '../../ag-grid/cell-editors/connectedFlights/dialog/AgGridConnectedFlightsDialogMessageCellEditor.vue';
import AgGridConnectedFlightsDialogRampStatusCellEditor from '../../ag-grid/cell-editors/connectedFlights/dialog/AgGridConnectedFlightsDialogRampStatusCellEditor.vue';
import AgGridConnectedFlightsDialogPaxStatusCellEditor from '../../ag-grid/cell-editors/connectedFlights/dialog/AgGridConnectedFlightsDialogPaxStatusCellEditor.vue';
import AgGridConnectedFlightsDialogOpeStatusCellEditor from '../../ag-grid/cell-editors/connectedFlights/dialog/AgGridConnectedFlightsDialogOpeStatusCellEditor.vue';
import AgGridConnectedFlightsDialogFlightStatusIntegrationCellEditor from '../../ag-grid/cell-editors/connectedFlights/dialog/AgGridConnectedFlightsDialogFlightStatusIntegrationCellEditor.vue';
import AgGridConnectedFlightsToggleCellEditor from '../../ag-grid/cell-editors/connectedFlights/AgGridConnectedFlightsToggleCellEditor.vue';
import AgGridConnectedFlightsCheckInCountersCellEditor from '../../ag-grid/cell-editors/connectedFlights/AgGridConnectedFlightsCheckInCountersCellEditor.vue';
import AgGridValueAndSupCellRenderer from '../../ag-grid/cell-renderers/AgGridValueAndSupCellRenderer.vue';
import AgGridValueWithAsteriskAndSupCellRenderer from '../../ag-grid/cell-renderers/AgGridValueWithAsteriskAndSupCellRenderer.vue';
import AgGridValueBaseCellRenderer from '../../ag-grid/cell-renderers/AgGridValueBaseCellRenderer.vue';
import AgGridTimeCellRenderer from '../../ag-grid/cell-renderers/AgGridTimeCellRenderer.vue';
import AgGridConnectedFlights from '../../ag-grid/mixins/AgGridConnectedFlights.vue';
import FlightsDifferences from '../../mixins/FlightsDifferences.vue';

import './OperationFlightsListTable.scss';
import OperationsUtils from '../OperationsUtils';

import { FlightBound } from '../../shared/model/flightBound';
import {
  FIT_COLUMNS_TO_VIEW,
  OPERATION_CHANGE_FLIGHT_SELECTION,
  OPERATION_ON_EXTERNAL_CHANGE,
  OPERATION_SET_CHANGE,
  OPERATION_SET_GRID_API,
} from '../../../store/modules/operation';
import { cellClassAgGridRules } from '../../ag-grid/mixins/cellClassAgGridRules';
import { WEBSOCKET_CONNECTION } from '../../../store/modules/websocket';
import AgGridCustomService from '../../shared/services/agGridCustomService';
import AgGridValueWithAsteriskCellRenderer from '../../ag-grid/cell-renderers/AgGridValueWithAsteriskCellRenderer.vue';
import AgGridFlightStatusCellRenderer from '../../ag-grid/cell-renderers/AgGridFlightStatusCellRenderer.vue';
import AgGridValueWithColorCellRenderer from '../../ag-grid/cell-renderers/AgGridValueWithColorCellRenderer.vue';
import AgGridConnectedFlightsAirportParkingSelectCellEditor from '../../ag-grid/cell-editors/connectedFlights/AgGridConnectedFlightsAirportParkingSelectCellEditor.vue';
import AgGridConnectedFlightsAirportGateSelectCellEditor from '../../ag-grid/cell-editors/connectedFlights/AgGridConnectedFlightsAirportGateSelectCellEditor.vue';
import agGridTimeFormatter from '../../ag-grid/formatters/agGridTimeFormatter';
import { FisModule } from '../../ag-grid/cell-editors/model/fisModule';
import agGridTextCustomFilterComparator from '../../ag-grid/filters/agGridTextCustomFilterComparator';
import agGridTextCustomFilterFormatter from '../../ag-grid/filters/agGridTextCustomFilterFormatter';
import AgGridConnectedFlightsDialogPassageCellEditor from '../../ag-grid/cell-editors/connectedFlights/dialog/AgGridConnectedFlightsDialogPassageCellEditor.vue';
import AgGridNumberCellEditor from '../../ag-grid/cell-editors/AgGridNumberCellEditor.vue';
import AgGridConnectedFlightsDialogRealPaxCellEditor from '../../ag-grid/cell-editors/connectedFlights/dialog/AgGridConnectedFlightsDialogRealPaxCellEditor.vue';
import AgGridConnectedFlightsDialogLoadHoldingCellEditor from '../../ag-grid/cell-editors/connectedFlights/dialog/AgGridConnectedFlightsDialogLoadHoldingCellEditor.vue';
import AgGridConnectedFlightsDialogPaxTimesCellEditor from '../../ag-grid/cell-editors/connectedFlights/dialog/paxTimes/AgGridConnectedFlightsDialogPaxTimesCellEditor.vue';
import AgGridConnectedFlightsNumberCellEditor from '../../ag-grid/cell-editors/connectedFlights/AgGridConnectedFlightsNumberCellEditor.vue';
import AgGridConnectedFlightsFlightNumberCellEditor from '../../ag-grid/cell-editors/connectedFlights/AgGridConnectedFlightsFlightNumberCellEditor.vue';
import AgGridConnectedFlightsAirlineSelectCellEditor from '../../ag-grid/cell-editors/connectedFlights/AgGridConnectedFlightsAirlineSelectCellEditor.vue';
import AgGridConnectedFlightsAirportBeltSelectCellEditor from '../../ag-grid/cell-editors/connectedFlights/AgGridConnectedFlightsAirportBeltSelectCellEditor.vue';
import RolePermissions from '../../mixins/RolePermissions.vue';
import AgGridConnectedFlightsFlightNumberServiceTypeCellEditor from '../../ag-grid/cell-editors/connectedFlights/AgGridConnectedFlightsFlightNumberServiceTypeCellEditor.vue';
import AgGridValueAndSupFieldPossibleBlockedCellRenderer from '../../ag-grid/cell-renderers/AgGridValueAndSupFieldPossibleBlockedCellRenderer.vue';
import AgGridValueAndIconAndSupFieldPossibleBlockedCellRenderer from '../../ag-grid/cell-renderers/AgGridValueAndIconAndSupFieldPossibleBlockedCellRenderer.vue';
import AgGridNoRowsOverlay from '../../ag-grid/mixins/AgGridNoRowsOverlay.vue';
import AgGridConnectedFlightsDialogAgent from '../../ag-grid/cell-editors/connectedFlights/dialog/AgGridConnectedFlightsDialogAgent.vue';
import AgGridValueBaseWithTooltipCellRenderer from '../../ag-grid/cell-renderers/AgGridValueBaseWithTooltipCellRenderer.vue';

export default {
  name: 'operation-flight-list-table',
  mixins: [AgGridConnectedFlights, FlightsDifferences, RolePermissions, AgGridNoRowsOverlay],
  components: {
    AgGridVue,
  },
  props: {
    items: { type: Array, required: true },
    isFilterDisplayed: { type: Boolean, required: false, default: false },
  },
  mounted() {
    this.$store.dispatch(WEBSOCKET_CONNECTION, this.$store.state.tenant.currentTenant.name.toLowerCase());
  },
  computed: {
    sizeFilter() {
      return this.isFilterDisplayed ? '315px' : '200px';
    },
    fitColumnsOnResize() {
      return this.$store.state.operation.fitColumnsOnResize;
    },
    selectedRowsFlights() {
      return this.$store.getters.getSelectedFlights;
    },
    selectedRowsAircraftGround() {
      return this.$store.getters.getSelectedAircraftGround;
    },
    dateFilter() {
      return this.$store.state.operation.filter.dateFrom;
    },
  },
  data() {
    const that = this;
    const rolePermission = this.getRoles();
    return {
      dialog: false,
      scheduledTimeRequired: true,
      gridOptions: {
        onFilterChanged: this.uncheckAllOnFilter,
        getRowNodeId: this.getRowNodeId,
        getRowClass: this.getRowClass,
        floatingFilter: true,
        groupHeaderHeight: 22,
        headerHeight: 35,
        floatingFiltersHeight: 20,
        rowHeight: 30,
        context: {
          module: FisModule.OPERATION,
        },
        defaultColDef: {
          filter: true,
        },
      },
      isRowSelectable(rowNode) {
        if (that.selectedRowsFlights.length > 0 && that.selectedRowsAircraftGround.length === 0) {
          return !that.isAircraftGround(rowNode.data);
        }
        if (that.selectedRowsFlights.length === 0 && that.selectedRowsAircraftGround.length > 0) {
          return that.isAircraftGround(rowNode.data);
        }
        return true;
      },
      multiSortKey: 'ctrl',
      frameworkComponents: {
        AgGridConnectedFlightsAircraftSelectCellEditor,
        AgGridConnectedFlightsAircraftTypeSelectCellEditor,
        AgGridConnectedFlightsAirportGateSelectCellEditor,
        AgGridConnectedFlightsAirportParkingSelectCellEditor,
        AgGridConnectedFlightsTimeCellEditor,
        AgGridConnectedFlightsDialogDelayCellEditor,
        AgGridConnectedFlightsDialogMessageCellEditor,
        AgGridConnectedFlightsDialogOpeStatusCellEditor,
        AgGridConnectedFlightsStringCellEditor,
        AgGridConnectedFlightsDialogPaxStatusCellEditor,
        AgGridConnectedFlightsDialogRampStatusCellEditor,
        AgGridConnectedFlightsRemarksCellEditor,
        AgGridConnectedFlightsDialogStatusCellEditor,
        AgGridConnectedFlightsToggleCellEditor,
        AgGridConnectedFlightsCheckInCountersCellEditor,
        AgGridConnectedFlightsDialogPassageCellEditor,
        AgGridNumberCellEditor,
        AgGridConnectedFlightsDialogRealPaxCellEditor,
        AgGridConnectedFlightsDialogLoadHoldingCellEditor,
        AgGridConnectedFlightsDialogPaxTimesCellEditor,
        AgGridConnectedFlightsNumberCellEditor,
        AgGridConnectedFlightsFlightNumberCellEditor,
        AgGridConnectedFlightsAirlineSelectCellEditor,
        AgGridConnectedFlightsDialogFlightStatusIntegrationCellEditor,
        AgGridConnectedFlightsAirportBeltSelectCellEditor,
        AgGridConnectedFlightsFlightNumberServiceTypeCellEditor,
        AgGridConnectedFlightsDialogAgent,
        AgGridValueBaseWithTooltipCellRenderer,
      },
      defaultColDef: {
        resizable: true,
        sortable: true,
        minWidth: 30,
        suppressMenu: true,
        filter: 'agTextColumnFilter',
        filterParams: {
          textCustomComparator: agGridTextCustomFilterComparator,
          textFormatter: agGridTextCustomFilterFormatter,
        },
        colCustomConfig: {
          flightBound: FlightBound.ANY,
        },
        cellClassRules: cellClassAgGridRules,
        editable: false,
      },
      columnDefs: [
        {
          headerName: '',
          children: [
            {
              headerTooltip: 'Check/uncheck all',
              field: 'checkbox',
              hide: !this.hasPermissions(rolePermission),
              checkboxSelection: true,
              width: 21,
              minWidth: 20,
              headerCheckboxSelection: true,
              headerCheckboxSelectionFilteredOnly: true,
              suppressMenu: true,
              sortable: false,
              suppressMovable: true,
              suppressSizeToFit: true,
              filter: false,
              resizable: false,
              colCustomConfig: {
                exportCsvDisabled: true,
              },
              editable: this.hasPermissions(rolePermission),
            },
            {
              headerName: 'SortDate',
              field: 'sortingTime',
              hide: true,
              sort: 'asc',
            },
            {
              colId: 'anybound.operation.operationDesk',
              field: 'operation.operationDesk',
              headerComponentParams: { template: this.generateHeaderTemplate('desktop_mac') },
              headerTooltip: that.$i18n.t('operations.desk'),
              width: 25,
              minWidth: 25,
              editable: this.hasPermissions(rolePermission) && this.isNotAircraftGround,
              cellEditor: 'AgGridConnectedFlightsStringCellEditor',
              cellRendererFramework: AgGridValueBaseCellRenderer,
              cellClass: 'aircraft-type-cell',
              comparator: this.objectWithValueComparator,
              colCustomConfig: {
                fieldPaths: ['operation.operationDesk'],
                flightBound: FlightBound.ANY,
                fieldsRelated: ['inbound.operation.operationDesk', 'outbound.operation.operationDesk'],
              },
              valueGetter(params) {
                return params.data.getChildPropertyValue(FlightBound.ANY, ['operation', 'operationDesk']);
              },
            },
          ],
        },
        {
          headerTooltip: `${this.$i18n.t('common.aircraftInformation')}`,
          headerClass: 'aircraft-header',
          field: 'aircraftInformation',
          headerValueGetter() {
            return that.$i18n.t('common.aircraftInformation');
          },
          children: [
            {
              colId: 'anybound.common.aircraftRegisterNumber',
              headerComponentParams: { template: this.generateHeaderTemplate('fingerprint') },
              headerTooltip: 'Aircraft registration',
              width: 54,
              minWidth: 52,
              editable: this.hasPermissions(rolePermission),
              cellEditor: 'AgGridConnectedFlightsAircraftSelectCellEditor',
              colCustomConfig: {
                fieldPaths: ['common.aircraftId', 'common.aircraftTypeId'],
                flightBound: FlightBound.ANY,
                fieldsRelated: ['inbound.common.aircraftRegisterNumber', 'outbound.common.aircraftRegisterNumber',
                  'inbound.common.aircraftAcars', 'outbound.common.aircraftAcars',
                  'inbound.blockedFieldsIntegration.manualOverwriteAircraft', 'outbound.blockedFieldsIntegration.manualOverwriteAircraft'],
                isAircraftGround(connectedFlights) {
                  return that.isAircraftGround(connectedFlights);
                },
              },
              cellClass: 'aircraft-cell',
              comparator: this.objectWithValueComparator,
              valueGetter(params) {
                return {
                  value: params.data.getChildPropertyValue(FlightBound.ANY, ['common', 'aircraftRegisterNumber']),
                  sup: params.data.getChildPropertyValue(FlightBound.ANY, ['common', 'aircraftAcars']) ? 'A' : null,
                  fieldBlockedIntegration: params.data.getChildPropertyValue(FlightBound.ANY, ['blockedFieldsIntegration', 'manualOverwriteAircraft']),
                };
              },
              cellRendererFramework: AgGridValueAndSupFieldPossibleBlockedCellRenderer,
            },
            {
              colId: 'anybound.common.aircraftTypeCode',
              headerComponentParams: {
                template: this.generateHeaderTemplate('category'),
              },
              headerTooltip: 'Aircraft type',
              width: 31,
              minWidth: 30,
              editable: this.hasPermissions(rolePermission) && this.isNotAircraftGround,
              cellClass: 'aircraft-type-cell',
              comparator: this.objectWithValueComparator,
              cellEditor: 'AgGridConnectedFlightsAircraftTypeSelectCellEditor',
              colCustomConfig: {
                fieldPaths: ['common.aircraftId', 'common.aircraftTypeId'],
                flightBound: FlightBound.ANY,
                fieldsRelated: ['inbound.common.aircraftTypeIataCode', 'inbound.common.aircraftTypeIcaoCode',
                  'outbound.common.aircraftTypeIataCode', 'outbound.common.aircraftTypeIcaoCode',
                  'inbound.common.aircraftCargo', 'outbound.common.aircraftCargo',
                  'inbound.blockedFieldsIntegration.manualOverwriteAircraftType', 'blockedFieldsIntegration.common.manualOverwriteAircraftType'],
              },
              valueGetter(params) {
                return {
                  value: params.data.getChildPropertyValue(FlightBound.ANY, ['common', 'aircraftTypeCode']),
                  sup: params.data.getChildPropertyValue(FlightBound.ANY, ['common', 'aircraftCargo']) ? 'P' : null,
                  tooltip: params.data.getChildPropertyValue(FlightBound.ANY, ['common', 'aircraftTypeComments']),
                  fieldBlockedIntegration: params.data.getChildPropertyValue(FlightBound.ANY, ['blockedFieldsIntegration', 'manualOverwriteAircraftType']),
                };
              },
              cellRendererFramework: AgGridValueAndSupFieldPossibleBlockedCellRenderer,
            },
          ],
        },
        {
          headerTooltip: `${this.$i18n.t('common.arrivalFlight')}`,
          headerClass: 'inbound-header',
          field: 'inbound',
          headerValueGetter() {
            return that.$i18n.t('common.arrivalFlight');
          },
          children: [
            {
              colId: 'inbound.common.flightNumber',
              field: 'inbound.common.flightNumber',
              headerClass: 'inbound-header',
              headerComponentParams: { template: this.generateHeaderTemplate('flight') },
              headerTooltip: 'Flight number',
              width: 68,
              minWidth: 68,
              editable: this.hasPermissions(rolePermission) && this.isInboundAndNotAircraftGround,
              cellClass: 'flight-number-cell inbound-cell',
              comparator: this.objectWithValueComparator,
              cellEditor: 'AgGridConnectedFlightsFlightNumberServiceTypeCellEditor',
              colCustomConfig: {
                fieldPaths: ['common.serviceTypeId'],
                flightBound: FlightBound.INBOUND,
                fieldsRelated: ['inbound.common.flightNumber', 'inbound.common.flagAirlineOperation'],
                isAircraftGround(connectedFlights) {
                  return that.isAircraftGround(connectedFlights);
                },
                editedOnlyServiceType(connectedFlights) {
                  return !that.isAircraftGround(connectedFlights);
                },
              },
              flight: FlightBound.INBOUND,
              valueGetter(params) {
                if (that.isAircraftGround(params)) {
                  return {
                    value: params.data.getChildPropertyValue(FlightBound.INBOUND, ['common', 'airlineCode']),
                  };
                }
                return {
                  value: params.data.getChildPropertyValue(FlightBound.INBOUND, ['common', 'flightNumber']),
                  asterisk: params.data.getChildPropertyValue(FlightBound.INBOUND, ['common', 'flagAirlineOperation']),
                  tooltip: params.data.getChildPropertyValue(FlightBound.INBOUND, ['common', 'airlineOperationMessage']),
                  sup: params.data.getChildPropertyValue(FlightBound.INBOUND, ['common', 'serviceTypeCode']),
                };
              },
              cellRendererFramework: AgGridValueWithAsteriskAndSupCellRenderer,
            },
            {
              colId: 'inbound.common.airport1Code',
              field: 'inbound.common.airport1Code',
              headerClass: 'inbound-header',
              headerComponentParams: { template: this.generateHeaderTemplate('apartment') },
              headerTooltip: 'Airport of departure',
              width: 31,
              minWidth: 31,
              cellClass: 'airport-from-cell inbound-cell',
              comparator: this.objectWithValueComparator,
              cellEditor: 'agGridFromCellEditor inbound-cell',
              colCustomConfig: {
                fieldPaths: ['common.airport1Id', 'common.airport2Id', 'common.airport3Id'],
                flightBound: FlightBound.INBOUND,
                fieldsRelated: ['inbound.common.airport1Code', 'inbound.common.hasSeveralAirports'],
              },
              flight: FlightBound.INBOUND,
              valueGetter(params) {
                return {
                  value: params.data.getChildPropertyValue(FlightBound.INBOUND, ['common', 'airport1Code']),
                  asterisk: params.data.getChildPropertyValue(FlightBound.INBOUND, ['common', 'hasSeveralAirports']),
                  tooltip: params.data.getChildPropertyValue(FlightBound.INBOUND, ['common', 'hasSeveralAirports']) ? params.data.getChildPropertyValue(FlightBound.INBOUND, ['common', 'airportsIata']) : undefined,
                };
              },
              cellRendererFramework: AgGridValueWithAsteriskCellRenderer,
            },
            {
              colId: 'inbound.common.pkn',
              field: 'inbound.common.pkn',
              headerClass: 'inbound-header',
              headerComponentParams: { template: this.generateHeaderTemplate('local_parking') },

              headerTooltip: 'Parking',
              width: 45,
              minWidth: 45,
              editable: this.hasPermissions(rolePermission) && this.isInboundAndNotAircraftGround,
              cellClass: 'aircraft-config-cell inbound-cell',
              comparator: this.objectWithValueComparator,
              cellEditor: 'AgGridConnectedFlightsAirportParkingSelectCellEditor',
              colCustomConfig: {
                fieldPaths: ['common.parkingId'],
                flightBound: FlightBound.INBOUND,
                fieldsRelated: ['inbound.common.parkingCode', 'inbound.common.parkingRemote', 'inbound.common.parkingPushback', 'inbound.common.parkingInop400Hz', 'inbound.blockedFieldsIntegration.manualOverwriteParking'],
              },
              flight: FlightBound.INBOUND,
              valueGetter(params) {
                let superindice = params.data.getChildPropertyValue(FlightBound.INBOUND, ['common', 'parkingRemote']) ? 'R' : '';
                superindice += params.data.getChildPropertyValue(FlightBound.INBOUND, ['common', 'parkingPushback']) ? 'P' : '';
                return {
                  value: params.data.getChildPropertyValue(FlightBound.INBOUND, ['common', 'parkingCode']),
                  sup: superindice,
                  fieldBlockedIntegration: params.data.getChildPropertyValue(FlightBound.INBOUND, ['blockedFieldsIntegration', 'manualOverwriteParking']),
                  icon: params.data.getChildPropertyValue(FlightBound.INBOUND, ['common', 'parkingInop400Hz']) ? 'flash_off' : null,
                  colorIcon: params.data.getChildPropertyValue(FlightBound.INBOUND, ['common', 'parkingInop400Hz']) ? 'red' : null,
                };
              },
              cellRendererFramework: AgGridValueAndIconAndSupFieldPossibleBlockedCellRenderer,
            },
            {
              colId: 'inbound.common.gat',
              field: 'inbound.common.gat',
              headerClass: 'inbound-header',
              headerComponentParams: { template: this.generateHeaderTemplate('meeting_room') },
              headerTooltip: 'Gate',
              width: 25,
              minWidth: 25,
              editable: this.hasPermissions(rolePermission) && this.isInboundAndNotAircraftGround,
              cellClass: 'aircraft-config-cell inbound-cell',
              comparator: this.objectWithValueComparator,
              cellEditor: 'AgGridConnectedFlightsAirportGateSelectCellEditor',
              colCustomConfig: {
                fieldPaths: ['common.gateId'],
                flightBound: FlightBound.INBOUND,
                fieldsRelated: ['inbound.common.gateCode', 'inbound.common.gateRemote'],
              },
              flight: FlightBound.INBOUND,
              valueGetter(params) {
                return {
                  value: params.data.getChildPropertyValue(FlightBound.INBOUND, ['common', 'gateCode']),
                  sup: params.data.getChildPropertyValue(FlightBound.INBOUND, ['common', 'gateRemote']) ? 'R' : null,
                };
              },
              cellRendererFramework: AgGridValueAndSupCellRenderer,
            },
            {
              colId: 'inbound.common.scheduledTime',
              field: 'inbound.common.scheduledTime',
              headerClass: 'inbound-header',
              headerComponentParams: { template: this.generateHeaderTemplate('alarm') },
              headerTooltip: 'Scheduled Time of Arrival',
              width: 35,
              minWidth: 35,
              editable: this.hasPermissions(rolePermission) && this.isInboundAndNotAircraftGround,
              cellClass: 'airport-sta-cell inbound-cell',
              comparator: this.objectWithValueComparator,
              cellEditor: 'AgGridConnectedFlightsTimeCellEditor',
              colCustomConfig: {
                fieldPaths: ['common.scheduledTime'],
                flightBound: FlightBound.INBOUND,
                fieldsRelated: ['inbound.common.scheduledTime'],
                oldValueFormatter: agGridTimeFormatter,
              },
              flight: FlightBound.INBOUND,
              valueGetter(params) {
                return that.agGridTimeGetter(params.data.getChildPropertyValue(FlightBound.INBOUND, ['common', 'scheduledTime']), that.dateFilter, null, null, that.scheduledTimeRequired);
              },
              cellRendererFramework: AgGridTimeCellRenderer,
            },
            {
              colId: 'inbound.times.bestTime',
              field: 'inbound.times.bestTime',
              headerClass: 'inbound-header',
              headerComponentParams: { template: this.generateHeaderTemplate('alarm_add') },
              headerTooltip: 'Best known time',
              width: 35,
              minWidth: 35,
              cellClass: 'airport-best-cell inbound-cell',
              comparator: this.objectWithValueComparator,
              colCustomConfig: {
                flightBound: FlightBound.INBOUND,
                fieldsRelated: ['inbound.times.bestTime'],
                oldValueFormatter: agGridTimeFormatter,
              },
              flight: FlightBound.INBOUND,
              valueGetter(params) {
                return that.bestTimeGetter(params.data.getChildPropertyValue(FlightBound.INBOUND, ['times', 'bestTime']), params.data.getChildPropertyValue(FlightBound.INBOUND, ['common', 'scheduledTime']), that.dateFilter);
              },
              cellRendererFramework: AgGridTimeCellRenderer,
            },
            {
              colId: 'inbound.times.status',
              field: 'inbound.times.status',
              headerClass: 'inbound-header',
              headerComponentParams: { template: this.generateHeaderTemplate('fact_check') },
              headerTooltip: (() => this.$i18n.t('operations.status'))(),
              width: 51,
              minWidth: 45,
              editable: this.hasPermissions(rolePermission) && this.isInboundAndNotAircraftGround,
              cellEditor: 'AgGridConnectedFlightsDialogStatusCellEditor',
              comparator: this.objectWithValueComparator,
              cellClass: 'status-cell inbound-cell',
              colCustomConfig: {
                flightBound: FlightBound.INBOUND,
                fieldsRelated: ['inbound.common.status'],
                referenceDate() {
                  return that.dateFilter;
                },
              },
              flight: FlightBound.INBOUND,
              valueGetter(params) {
                if (that.isAircraftGround(params)) {
                  return 'GROUND';
                }
                return params.data.getChildPropertyValue(FlightBound.INBOUND, ['common', 'status']);
              },
              cellRendererFramework: AgGridFlightStatusCellRenderer,
            },
            {
              colId: 'inbound.integration.flightStatusIntegration',
              field: 'inbound.integration.flightStatusIntegration',
              headerClass: 'inbound-header',
              headerComponentParams: { template: this.generateHeaderTemplate('playlist_add_check_circle') },
              headerTooltip: (() => this.$i18n.t('operations.airportStatus'))(),
              width: 48,
              minWidth: 39,
              editable: this.hasPermissions(rolePermission) && this.isInboundAndNotAircraftGround,
              cellEditor: 'AgGridConnectedFlightsDialogFlightStatusIntegrationCellEditor',
              comparator: this.objectWithValueComparator,
              cellClass: 'airport-status-cell inbound-cell',
              colCustomConfig: {
                flightBound: FlightBound.INBOUND,
                fieldsRelated: ['inbound.integration.flightStatusIntegration'],
              },
              flight: FlightBound.INBOUND,
              valueGetter(params) {
                return {
                  value: params.data.getChildPropertyValue(FlightBound.INBOUND, ['integration', 'statusIntegration']),
                  tooltip: params.data.getChildPropertyValue(FlightBound.INBOUND, ['integration', 'statusIntegration']) != null ? that.$i18n.t(`flightStatusIntegration.${params.data.getChildPropertyValue(FlightBound.INBOUND, ['integration', 'statusIntegration'])}`) : null,
                };
              },
              cellRendererFramework: AgGridFlightStatusCellRenderer,
            },
            {
              colId: 'inbound.common.belt',
              field: 'inbound.common.belt',
              headerClass: 'inbound-header',
              headerComponentParams: { template: this.generateHeaderTemplate('stacked_line_chart') },
              headerTooltip: that.$i18n.t('ramp.belt'),
              width: 50,
              editable: this.hasPermissions(rolePermission) && this.isInboundAndNotAircraftGround,
              cellClass: 'aircraft-config-cell inbound-cell',
              comparator: this.objectWithValueComparator,
              cellEditor: 'AgGridConnectedFlightsAirportBeltSelectCellEditor',
              colCustomConfig: {
                fieldPaths: ['common.beltId'],
                flightBound: FlightBound.INBOUND,
                fieldsRelated: ['inbound.common.beltId', 'inbound.common.beltCode', 'inbound.blockedFieldsIntegration.manualOverwriteBelt'],
              },
              valueGetter(params) {
                return {
                  value: params.data.getChildPropertyValue(FlightBound.INBOUND, ['common', 'beltCode']),
                  fieldBlockedIntegration: params.data.getChildPropertyValue(FlightBound.INBOUND, ['blockedFieldsIntegration', 'manualOverwriteBelt']),
                };
              },
              cellRendererFramework: AgGridValueAndSupFieldPossibleBlockedCellRenderer,
            },
            {
              colId: 'inbound.passageSummary.pax',
              field: 'inbound.passageSummary.pax',
              headerClass: 'inbound-header',
              headerValueGetter() {
                return that.$i18n.t('operations.flightPassengers');
              },
              headerComponentParams: { template: this.generateHeaderTemplate('airline_seat_recline_extra') },
              headerTooltip: that.$i18n.t('operations.flightPassengers'),
              width: 40,
              minWidth: 40,
              editable: this.hasPermissions(rolePermission) && this.isInboundAndNotAircraftGround,
              cellEditor: 'AgGridConnectedFlightsDialogPassageCellEditor',
              cellClass: 'airport-pax-cell inbound-cell',
              comparator: this.objectWithValueComparator,
              colCustomConfig: {
                flightBound: FlightBound.INBOUND,
                fieldsRelated: ['inbound.passageSummary.passengersNumber', 'inbound.passageSummary.passengersChildNumber', 'inbound.passageSummary.passengersSpecial'],
              },
              valueGetter(params) {
                const isRealNumber = params.data.getChildPropertyValue(FlightBound.INBOUND, ['passageSummary', 'passengersNumber']) > 0 || params.data.getChildPropertyValue(FlightBound.INBOUND, ['passageSummary', 'passengersChildNumber']) > 0;
                let text = params.data.getChildPropertyValue(FlightBound.INBOUND, ['passageSummary', 'passengersNumber']) == null ? '' : params.data.getChildPropertyValue(FlightBound.INBOUND, ['passageSummary', 'passengersNumber']);
                let tooltip = '';
                let styleContent = {};
                text += params.data.getChildPropertyValue(FlightBound.INBOUND, ['passageSummary', 'passengersChildNumber']) == null ? '' : `+${params.data.getChildPropertyValue(FlightBound.INBOUND, ['passageSummary', 'passengersChildNumber'])}`;
                if ((params.data.getChildPropertyValue(FlightBound.INBOUND, ['passageSummary', 'passengersEstimated']) > 0) && !isRealNumber) {
                  text = params.data.getChildPropertyValue(FlightBound.INBOUND, ['passageSummary', 'passengersEstimated']);
                  styleContent = { color: 'darkslategray', 'font-weight': 'bold', 'font-style': 'italic' };
                }
                // when handling type is P, the total of passengers must be (P)
                if (params.data.getChildPropertyValue(FlightBound.INBOUND, ['common', 'handlingTypeCode']) === 'P') {
                  text = '(P)';
                  tooltip = that.$i18n.t('operations.emptyFlight');
                }
                return {
                  value: text,
                  asterisk: params.data.getChildPropertyValue(FlightBound.INBOUND, ['passageSummary', 'passengersSpecial']),
                  styleObject: {
                    asteriskColor: 'red',
                    content: styleContent,
                  },
                  tooltip,
                };
              },
              cellRendererFramework: AgGridValueWithAsteriskCellRenderer,
            },
            {
              colId: 'inbound.operationExtended.loadHoldingWeightCell',
              field: 'inbound.operationExtended.loadHoldingWeightCell',
              headerClass: 'inbound-header',
              headerComponentParams: { template: this.generateHeaderTemplate('work') },
              headerTooltip: that.$i18n.t('operations.flightLoad'),
              width: 43,
              minWidth: 43,
              editable: this.hasPermissions(rolePermission) && this.isInboundAndNotAircraftGround,
              cellEditor: 'AgGridConnectedFlightsDialogLoadHoldingCellEditor',
              cellClass: 'airport-pax-cell inbound-cell',
              comparator: this.objectWithValueComparator,
              colCustomConfig: {
                flightBound: FlightBound.INBOUND,
                fieldsRelated: ['inbound.operationExtended.load', 'inbound.operationExtended.loadUnit', 'inbound.operationExtended.loadSpecial'],
              },
              flight: FlightBound.INBOUND,
              valueGetter(params) {
                return OperationsUtils.getValueLoadHolding(params, FlightBound.INBOUND);
              },
              cellRendererFramework: AgGridValueWithAsteriskCellRenderer,
            },
            {
              colId: 'inbound.operation.rem',
              field: 'inbound.operation.rem',
              headerClass: 'inbound-header',
              headerComponentParams: { template: this.generateHeaderTemplate('comment') },
              headerTooltip: that.$i18n.t('operations.remarksExtended'),
              width: 18,
              minWidth: 18,
              editable: this.hasPermissions(rolePermission) && this.isInboundAndNotAircraftGround,
              cellEditor: 'AgGridConnectedFlightsRemarksCellEditor',
              cellClass: 'ag-grid-tooltip-cell inbound-cell',
              comparator: this.objectWithValueComparator,
              colCustomConfig: {
                flightBound: FlightBound.INBOUND,
                fieldsRelated: ['inbound.operation.remarksStatus'],
              },
              flight: FlightBound.INBOUND,
              valueGetter(params) {
                const remarkValue = params.data.getChildPropertyValue(FlightBound.INBOUND, ['operation', 'remarksStatus']);
                const missingPaxAlert = params.data.getChildPropertyValue(FlightBound.INBOUND, ['remarks', 'missingPaxAlert']);
                return that.checkBoundAndSetRemTooltipObject(remarkValue, missingPaxAlert);
              },
              cellRendererFramework: AgGridIconTooltipOperationCellRenderer,
            },
            {
              colId: 'inbound.extended.msg',
              field: 'inbound.extended.msg',
              headerClass: 'inbound-header',
              headerComponentParams: { template: this.generateHeaderTemplate('print') },
              headerTooltip: that.$i18n.t('operations.messagesModalTableMessage'),
              width: 18,
              minWidth: 18,
              editable: this.hasPermissions(rolePermission) && this.isInboundAndNotAircraftGround,
              cellEditor: 'AgGridConnectedFlightsDialogMessageCellEditor',
              cellClass: 'ag-grid-popover-cell inbound-cell',
              comparator: this.objectWithValueComparator,
              colCustomConfig: {
                flightBound: FlightBound.INBOUND,
                fieldsRelated: ['inbound.extended.messageStatus'],
              },
              flight: FlightBound.INBOUND,
              valueGetter(params) {
                const messageValue = params.data.getChildPropertyValue(FlightBound.INBOUND, ['extended', 'messageStatus']);
                return that.checkBoundAndSetMsgTooltipObject(messageValue);
              },
              cellRendererFramework: AgGridIconTooltipOperationCellRenderer,
            },
          ],
        },
        {
          headerTooltip: `${this.$i18n.t('common.departureFlight')}`,
          headerClass: 'outbound-header',
          field: 'outbound',
          headerValueGetter() {
            return that.$i18n.t('common.departureFlight');
          },
          children: [
            {
              colId: 'outbound.common.flightNumber',
              field: 'outbound.common.flightNumber',
              headerClass: 'outbound-header',
              headerComponentParams: { template: this.generateHeaderTemplate('flight') },
              headerTooltip: 'Flight number',
              width: 68,
              minWidth: 68,
              cellClass: 'flight-number-cell separator outbound-cell',
              comparator: this.objectWithValueComparator,
              cellEditor: 'AgGridConnectedFlightsFlightNumberServiceTypeCellEditor',
              colCustomConfig: {
                fieldPaths: ['common.serviceTypeId'],
                flightBound: FlightBound.OUTBOUND,
                fieldsRelated: ['outbound.common.flightNumber', 'outbound.common.flagAirlineOperation'],
                editedOnlyServiceType() {
                  return true;
                },
              },
              flight: FlightBound.OUTBOUND,
              valueGetter(params) {
                return {
                  value: params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'flightNumber']),
                  asterisk: params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'flagAirlineOperation']),
                  tooltip: params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'airlineOperationMessage']),
                  sup: params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'serviceTypeCode']),
                };
              },
              cellRendererFramework: AgGridValueWithAsteriskAndSupCellRenderer,
            },
            {
              colId: 'outbound.common.airport1Code',
              field: 'outbound.common.airport1Code',
              headerClass: 'outbound-header',
              headerComponentParams: { template: this.generateHeaderTemplate('apartment') },
              headerTooltip: 'Airport of destination',
              width: 31,
              minWidth: 31,
              cellClass: 'airport-from-cell outbound-cell',
              comparator: this.objectWithValueComparator,
              cellEditor: 'agGridFromCellEditor',
              colCustomConfig: {
                flightBound: FlightBound.OUTBOUND,
                fieldsRelated: ['outbound.common.airport1Code', 'outbound.common.hasSeveralAirports'],
              },
              flight: FlightBound.OUTBOUND,
              valueGetter(params) {
                return {
                  value: params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'airport1Code']),
                  asterisk: params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'hasSeveralAirports']),
                  tooltip: params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'hasSeveralAirports']) ? params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'airportsIata']) : undefined,
                };
              },
              cellRendererFramework: AgGridValueWithAsteriskCellRenderer,
            },
            {
              colId: 'outbound.common.pkn',
              field: 'outbound.common.pkn',
              headerClass: 'outbound-header',
              headerComponentParams: { template: this.generateHeaderTemplate('local_parking') },
              headerTooltip: 'Parking',
              width: 38,
              minWidth: 38,
              editable: this.hasPermissions(rolePermission) && this.isOutboundAndNotAircraftGround,
              cellClass: 'aircraft-config-cell outbound-cell',
              comparator: this.objectWithValueComparator,
              cellEditor: 'AgGridConnectedFlightsAirportParkingSelectCellEditor',
              colCustomConfig: {
                fieldPaths: ['common.parkingId'],
                flightBound: FlightBound.OUTBOUND,
                fieldsRelated: ['outbound.common.parkingCode', 'outbound.common.parkingRemote', 'outbound.common.parkingPushback', 'outbound.common.parkingInop400Hz', 'outbound.blockedFieldsIntegration.manualOverwriteParking'],
              },
              flight: FlightBound.OUTBOUND,
              valueGetter(params) {
                let superindice = params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'parkingRemote']) ? 'R' : '';
                superindice += params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'parkingPushback']) ? 'P' : '';
                return {
                  value: params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'parkingCode']),
                  sup: superindice,
                  fieldBlockedIntegration: params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['blockedFieldsIntegration', 'manualOverwriteParking']),
                  icon: params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'parkingInop400Hz']) ? 'flash_off' : null,
                  colorIcon: params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'parkingInop400Hz']) ? 'red' : null,
                };
              },
              cellRendererFramework: AgGridValueAndIconAndSupFieldPossibleBlockedCellRenderer,
            },
            {
              colId: 'outbound.common.gat',
              field: 'outbound.common.gat',
              headerClass: 'outbound-header',
              headerComponentParams: { template: this.generateHeaderTemplate('meeting_room') },
              headerTooltip: 'Gate',
              width: 25,
              minWidth: 25,
              editable: this.hasPermissions(rolePermission) && this.isOutboundAndNotAircraftGround,
              cellClass: 'aircraft-config-cell outbound-cell',
              comparator: this.objectWithValueComparator,
              cellEditor: 'AgGridConnectedFlightsAirportGateSelectCellEditor',
              colCustomConfig: {
                fieldPaths: ['common.gateId'],
                flightBound: FlightBound.OUTBOUND,
                fieldsRelated: ['outbound.common.gateCode'],
              },
              flight: FlightBound.OUTBOUND,
              valueGetter(params) {
                return {
                  value: params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'gateCode']),
                  sup: params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'gateRemote']) ? 'R' : null,
                };
              },
              cellRendererFramework: AgGridValueAndSupCellRenderer,
            },
            {
              colId: 'outbound.passageCheckIn.ckin',
              field: 'outbound.passageCheckIn.ckin',
              headerClass: 'outbound-header',
              headerComponentParams: { template: this.generateHeaderTemplate('desk') },
              headerTooltip: 'Check in counters',
              width: 50,
              minWidth: 50,
              editable: this.hasPermissions(rolePermission) && this.isOutboundAndNotAircraftGround,
              cellEditor: 'AgGridConnectedFlightsCheckInCountersCellEditor',
              colCustomConfig: {
                fieldPaths: ['passageCheckIn.checkInCounterFirstId', 'passageCheckIn.checkInCounterLastId'],
                flightBound: FlightBound.OUTBOUND,
                fieldsRelated: ['outbound.passageCheckIn.checkInCounterFirstCode', 'outbound.passageCheckIn.checkInCounterLastCode'],
              },
              cellClass: 'cki-flight-cell outbound-cell',
              valueGetter(params) {
                let val = '';
                if (params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['passageCheckIn', 'checkInCounterFirstCode']) != null) {
                  val += params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['passageCheckIn', 'checkInCounterFirstCode']);
                }
                if (params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['passageCheckIn', 'checkInCounterLastCode']) != null) {
                  val += val !== '' ? '-' : '';
                  val += params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['passageCheckIn', 'checkInCounterLastCode']);
                }
                return val;
              },
              cellRendererFramework: AgGridValueBaseCellRenderer,
            },
            {
              colId: 'outbound.common.scheduledTime',
              field: 'outbound.common.scheduledTime',
              headerClass: 'outbound-header',
              headerComponentParams: { template: this.generateHeaderTemplate('alarm') },
              headerTooltip: 'Scheduled Time of Departure',
              width: 35,
              minWidth: 35,
              editable: this.hasPermissions(rolePermission) && this.isOutboundAndNotAircraftGround,
              cellClass: 'airport-sta-cell outbound-cell',
              comparator: this.objectWithValueComparator,
              cellEditor: 'AgGridConnectedFlightsTimeCellEditor',
              colCustomConfig: {
                fieldPaths: ['common.scheduledTime'],
                flightBound: FlightBound.OUTBOUND,
                fieldsRelated: ['outbound.common.scheduledTime'],
                oldValueFormatter: agGridTimeFormatter,
              },
              flight: FlightBound.OUTBOUND,
              valueGetter(params) {
                return that.agGridTimeGetter(params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'scheduledTime']), that.dateFilter, null, null, that.scheduledTimeRequired);
              },
              valueFormatter: agGridTimeFormatter,
              cellRendererFramework: AgGridTimeCellRenderer,
            },
            {
              colId: 'outbound.times.bestTime',
              field: 'outbound.times.bestTime',
              headerClass: 'outbound-header',
              headerComponentParams: { template: this.generateHeaderTemplate('alarm_add') },
              headerTooltip: 'Best known time',
              width: 35,
              minWidth: 35,
              cellClass: 'airport-best-cell outbound-cell',
              comparator: this.objectWithValueComparator,
              colCustomConfig: {
                flightBound: FlightBound.OUTBOUND,
                fieldsRelated: ['outbound.times.bestTime'],
                oldValueFormatter: agGridTimeFormatter,
              },
              flight: FlightBound.OUTBOUND,
              valueGetter(params) {
                return that.bestTimeGetter(
                  params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['times', 'bestTime']),
                  params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'scheduledTime']),
                  that.dateFilter,
                  params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'status']),
                );
              },
              cellRendererFramework: AgGridTimeCellRenderer,
            },
            {
              colId: 'outbound.times.groundTime',
              field: 'outbound.times.groundTime',
              headerClass: 'outbound-header',
              headerComponentParams: { template: this.generateHeaderTemplate('timelapse') },
              headerTooltip: 'Ground Time',
              width: 25,
              minWidth: 25,
              cellClass: 'airport-ground-cell outbound-cell',
              comparator: this.objectWithValueComparator,
              colCustomConfig: {
                flightBound: FlightBound.OUTBOUND,
                fieldsRelated: ['inbound.times.blockTime', 'outbound.times.blockTime'],
              },
              valueGetter(params) {
                let value;
                if (!params.data.hasInbound() || !params.data.hasOutbound()) {
                  value = '-';
                } else {
                  const blockTimeIn = params.data.getChildPropertyValue(FlightBound.INBOUND, ['times', 'blockTime']);
                  const blockTimeOut = params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['times', 'blockTime']);
                  if (!blockTimeIn || !blockTimeOut) {
                    value = '-';
                  } else {
                    value = that.$moment(blockTimeOut).diff(that.$moment(blockTimeIn), 'minutes');
                  }
                }
                return {
                  value,
                  color: !!value && value < 0 ? 'red' : '',
                };
              },
              cellRendererFramework: AgGridValueWithColorCellRenderer,
            },
            {
              colId: 'outbound.times.status',
              field: 'outbound.times.status',
              headerClass: 'outbound-header',
              headerComponentParams: { template: this.generateHeaderTemplate('fact_check') },
              headerTooltip: (() => this.$i18n.t('operations.status'))(),
              width: 51,
              minWidth: 45,
              editable: this.isOutboundAndNotAircraftGround,
              cellEditor: 'AgGridConnectedFlightsDialogStatusCellEditor',
              cellClass: 'status-cell outbound-cell',
              comparator: this.objectWithValueComparator,
              colCustomConfig: {
                flightBound: FlightBound.OUTBOUND,
                fieldsRelated: ['outbound.common.status'],
                referenceDate() {
                  return that.dateFilter;
                },
              },
              flight: FlightBound.OUTBOUND,
              valueGetter(params) {
                return params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'status']);
              },
              cellRendererFramework: AgGridFlightStatusCellRenderer,
            },
            {
              colId: 'outbound.integration.flightStatusIntegration',
              field: 'outbound.integration.flightStatusIntegration',
              headerClass: 'outbound-header',
              headerComponentParams: { template: this.generateHeaderTemplate('playlist_add_check_circle') },
              headerTooltip: (() => this.$i18n.t('operations.airportStatus'))(),
              width: 48,
              minWidth: 39,
              editable: this.isOutboundAndNotAircraftGround,
              cellEditor: 'AgGridConnectedFlightsDialogFlightStatusIntegrationCellEditor',
              comparator: this.objectWithValueComparator,
              cellClass: 'airport-status-cell outbound-cell',
              colCustomConfig: {
                flightBound: FlightBound.OUTBOUND,
                fieldsRelated: ['outbound.integration.flightStatusIntegration'],
              },
              flight: FlightBound.OUTBOUND,
              valueGetter(params) {
                return {
                  value: params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['integration', 'statusIntegration']),
                  tooltip: params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['integration', 'statusIntegration']) != null ? that.$i18n.t(`flightStatusIntegration.${params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['integration', 'statusIntegration'])}`) : null,
                };
              },
              cellRendererFramework: AgGridFlightStatusCellRenderer,
            },
            {
              colId: 'outbound.times.ctotTime',
              field: 'outbound.times.ctotTime',
              headerClass: 'outbound-header',
              headerComponentParams: { template: this.generateHeaderTemplate('flight_takeoff') },
              headerTooltip: this.$i18n.t('operations.calculatedTakeOffTimeTooltip'),
              width: 43,
              minWidth: 43,
              editable: this.hasPermissions(rolePermission) && this.isOutboundAndNotAircraftGround,
              cellClass: 'airport-ctot-cell outbound-cell',
              comparator: this.objectWithValueComparator,
              cellEditor: 'AgGridConnectedFlightsTimeCellEditor',
              colCustomConfig: {
                fieldPaths: ['times.ctotTime'],
                flightBound: FlightBound.OUTBOUND,
                fieldsRelated: ['outbound.times.ctotTime'],
                oldValueFormatter: agGridTimeFormatter,
              },
              flight: FlightBound.OUTBOUND,
              valueGetter(params) {
                return that.agGridTimeGetter(params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['times', 'ctotTime']), that.dateFilter);
              },
              valueFormatter: agGridTimeFormatter,
              cellRendererFramework: AgGridTimeCellRenderer,
            },
            {
              colId: 'outbound.extended.delay',
              field: 'outbound.extended.delay',
              headerClass: 'outbound-header',
              headerComponentParams: { template: this.generateHeaderTemplate('warning') },
              headerTooltip: that.$i18n.t('operations.flightDelay'),
              width: 20,
              minWidth: 20,
              editable: this.isOutboundAndNotAircraftGround,
              cellEditor: 'AgGridConnectedFlightsDialogDelayCellEditor',
              cellClass: 'ag-grid-tooltip-cell outbound-cell',
              comparator: this.objectWithValueComparator,
              colCustomConfig: {
                flightBound: FlightBound.OUTBOUND,
                fieldsRelated: ['outbound.extended.delay'],
                valueGetterExportCsv(params) {
                  return that.getInformationDelay(params, FlightBound.OUTBOUND);
                },
              },
              flight: FlightBound.OUTBOUND,
              valueGetter(params) {
                const delayValue = params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['extended', 'delay']);
                return {
                  icon: delayValue ? 'chevron_right' : '',
                  color: delayValue ? 'bggreydark' : '',
                  tooltip: delayValue ? that.getInformationDelay(params, FlightBound.OUTBOUND) : 'There are not delays',
                };
              },
              cellRendererFramework: AgGridIconTooltipOperationCellRenderer,
            },
            {
              colId: 'outbound.passageSummary.localPaxExpected',
              field: 'outbound.passageSummary.localPaxExpected',
              headerClass: 'outbound-header',
              headerComponentParams: { template: this.generateHeaderTemplate('group_add') },
              headerTooltip: that.$i18n.t('operations.prevPax'),
              width: 50,
              cellClass: 'airport-pax-cell outbound-cell',
              editable: this.hasPermissions(rolePermission) && this.isOutboundAndNotAircraftGround,
              cellEditor: 'AgGridConnectedFlightsNumberCellEditor',
              colCustomConfig: {
                fieldPaths: ['passageSummary.localPaxExpected'],
                flightBound: FlightBound.OUTBOUND,
                fieldsRelated: ['outbound.passageSummary.localPaxExpected'],
              },
              valueGetter(params) {
                return params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['passageSummary', 'localPaxExpected']);
              },
              cellRendererFramework: AgGridValueBaseCellRenderer,
            },
            {
              colId: 'outbound.passageSummary.pax',
              field: 'outbound.passageSummary.pax',
              headerClass: 'outbound-header',
              headerComponentParams: { template: this.generateHeaderTemplate('group') },
              headerTooltip: that.$i18n.t('operations.totalPax'),
              width: 50,
              cellClass: 'airport-pax-cell outbound-cell',
              editable: this.isOutboundAndNotAircraftGround,
              cellEditor: 'AgGridConnectedFlightsDialogPassageCellEditor',
              colCustomConfig: {
                flightBound: FlightBound.OUTBOUND,
                fieldsRelated: ['outbound.passageSummary.passengersNumber', 'outbound.passageSummary.passengersChildNumber', 'outbound.passageSummary.passengersSpecial'],
              },
              valueGetter(params) {
                const isRealNumber = params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['passageSummary', 'passengersNumber']) > 0 || params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['passageSummary', 'passengersChildNumber']) > 0;
                let text = params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['passageSummary', 'passengersNumber']) == null ? '' : params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['passageSummary', 'passengersNumber']);
                let tooltip = '';
                let styleContent = {};
                text += params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['passageSummary', 'passengersChildNumber']) == null ? '' : `+${params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['passageSummary', 'passengersChildNumber'])}`;
                if ((params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['passageSummary', 'passengersEstimated']) > 0) && !isRealNumber) {
                  text = params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['passageSummary', 'passengersEstimated']);
                  styleContent = { color: 'darkslategray', 'font-weight': 'bold', 'font-style': 'italic' };
                }
                // when handling type is P, the total of passengers must be (P)
                if (params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'handlingTypeCode']) === 'P') {
                  text = '(P)';
                  tooltip = that.$i18n.t('operations.emptyFlight');
                }
                return {
                  value: text,
                  asterisk: params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['passageSummary', 'passengersSpecial']),
                  styleObject: {
                    asteriskColor: 'red',
                    content: styleContent,
                  },
                  tooltip,
                };
              },
              cellRendererFramework: AgGridValueWithAsteriskCellRenderer,
            },
            {
              colId: 'outbound.operationExtended.loadHoldingWeightCell',
              field: 'outbound.operationExtended.loadHoldingWeightCell',
              headerClass: 'outbound-header',
              headerComponentParams: { template: this.generateHeaderTemplate('work') },
              headerTooltip: that.$i18n.t('operations.flightLoad'),
              width: 43,
              minWidth: 43,
              editable: this.isOutboundAndNotAircraftGround,
              cellEditor: 'AgGridConnectedFlightsDialogLoadHoldingCellEditor',
              cellClass: 'airport-pax-cell outbound-cell',
              comparator: this.objectWithValueComparator,
              colCustomConfig: {
                flightBound: FlightBound.OUTBOUND,
                fieldsRelated: ['outbound.operationExtended.load', 'outbound.operationExtended.loadSpecial'],
              },
              flight: FlightBound.OUTBOUND,
              valueGetter(params) {
                return OperationsUtils.getValueLoadHolding(params, FlightBound.OUTBOUND);
              },
              cellRendererFramework: AgGridValueWithAsteriskCellRenderer,
            },
            {
              colId: 'outbound.operation.rem',
              field: 'outbound.operation.rem',
              headerClass: 'outbound-header',
              headerComponentParams: { template: this.generateHeaderTemplate('comment') },
              headerTooltip: that.$i18n.t('operations.remarksExtended'),
              width: 18,
              minWidth: 18,
              editable: this.hasPermissions(rolePermission) && this.isOutboundAndNotAircraftGround,
              cellEditor: 'AgGridConnectedFlightsRemarksCellEditor',
              cellClass: 'ag-grid-tooltip-cell outbound-cell',
              comparator: this.objectWithValueComparator,
              colCustomConfig: {
                flightBound: FlightBound.OUTBOUND,
                fieldsRelated: ['outbound.operation.remarksStatus'],
              },
              flight: FlightBound.OUTBOUND,
              valueGetter(params) {
                const remarkValue = params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['operation', 'remarksStatus']);
                const missingPaxAlert = params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['remarks', 'missingPaxAlert']);
                return that.checkBoundAndSetRemTooltipObject(remarkValue, missingPaxAlert);
              },
              cellRendererFramework: AgGridIconTooltipOperationCellRenderer,
            },
            {
              colId: 'outbound.extended.msg',
              field: 'outbound.extended.msg',
              headerClass: 'outbound-header',
              headerComponentParams: { template: this.generateHeaderTemplate('print') },
              headerTooltip: that.$i18n.t('operations.messagesModalTableMessage'),
              width: 18,
              minWidth: 18,
              editable: this.isOutboundAndNotAircraftGround,
              cellEditor: 'AgGridConnectedFlightsDialogMessageCellEditor',
              cellClass: 'ag-grid-popover-cell outbound-cell',
              comparator: this.objectWithValueComparator,
              colCustomConfig: {
                flightBound: FlightBound.OUTBOUND,
                fieldsRelated: ['outbound.extended.messageStatus'],
              },
              flight: FlightBound.OUTBOUND,
              valueGetter(params) {
                const messageValue = params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['extended', 'messageStatus']);
                return that.checkBoundAndSetMsgTooltipObject(messageValue);
              },
              cellRendererFramework: AgGridIconTooltipOperationCellRenderer,
            },

          ],
        },
        {
          headerTooltip: 'Operations organization related information',
          headerClass: 'operations-header header-top',
          headerValueGetter() {
            return that.$i18n.t('sidebar.Operations');
          },
          children: [
            {
              colId: 'outbound.passageOutbound.boardingRefuelingRequested',
              headerComponentParams: { template: this.generateHeaderTemplate('warning') },
              headerTooltip: this.$i18n.t('passage.alert'),
              width: 50,
              editable: this.editable(true, rolePermission),
              cellEditor: 'AgGridConnectedFlightsToggleCellEditor',
              headerClass: 'operations-header',
              colCustomConfig: {
                fieldPaths: ['passageOutbound.boardingRefuelingRequested'],
                flightBound: FlightBound.OUTBOUND,
                fieldsRelated: ['outbound.passageOutbound.boardingRefuelingRequested'],
              },
              valueGetter(params) {
                const boardingRefuelingRequested = !!params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['passageOutbound', 'boardingRefuelingRequested']);
                return {
                  value: boardingRefuelingRequested,
                  color: boardingRefuelingRequested ? 'grey' : '',
                  icon: boardingRefuelingRequested ? 'check' : '',
                };
              },
              cellRendererFramework: AgGridIconTooltipOperationCellRenderer,

            },
            {
              colId: 'operation.operationAgent',
              field: 'operation.operationAgent',
              headerClass: 'operations-header',
              headerComponentParams: { template: this.generateHeaderTemplate('support_agent') },
              headerTooltip: that.$i18n.t('operations.agent'),
              width: 45,
              minWidth: 45,
              editable: this.hasPermissions(rolePermission) && this.isNotAircraftGround,
              cellEditor: 'AgGridConnectedFlightsDialogAgent',
              cellClass: 'aircraft-type-cell  separatorAgGridConnectedFlightsDialogAgent ',
              comparator: this.objectWithValueComparator,
              colCustomConfig: {
                fieldPaths: ['operation.operationAgent'],
                flightBound: FlightBound.ANY,
                fieldsRelated: ['inbound.operation.operationAgent', 'outbound.operation.operationAgent'],
              },
              // set value from inbound or outbound
              valueSetter(params) {
                const flightBound = params.data.getChildPropertyValue(FlightBound.INBOUND, ['common', 'flightNumber']) ? FlightBound.INBOUND : FlightBound.OUTBOUND;
                params.data.setChildPropertyValue(flightBound, ['operation', 'operationAgent'], params.newValue);
                return true;
              },
              valueGetter(params) {
                return {
                  value: params.data.getChildPropertyValue(FlightBound.ANY, ['operation', 'operationAgent']),
                  tooltip: params.data.getChildPropertyValue(FlightBound.ANY, ['operation', 'operationAgent']),
                };
              },
              cellRendererFramework: AgGridValueBaseWithTooltipCellRenderer,
            },

            {
              colId: 'outbound.times.scheduledBoardingTime',
              field: 'outbound.times.scheduledBoardingTime',
              headerClass: 'operations-header',
              headerComponentParams: { template: this.generateHeaderTemplate('play_circle_outline') },
              headerTooltip: that.$i18n.t('operations.scheduledBoardingStartTimeLong'),
              width: 35,
              minWidth: 35,
              editable: this.hasPermissions(rolePermission) && this.isOutboundAndNotAircraftGround,
              cellClass: 'airport-sta-cell',
              comparator: this.objectWithValueComparator,
              cellEditor: 'AgGridConnectedFlightsTimeCellEditor',
              colCustomConfig: {
                fieldPaths: ['times.scheduledBoardingTime'],
                flightBound: FlightBound.OUTBOUND,
                fieldsRelated: ['outbound.times.scheduledBoardingTime'],
                oldValueFormatter: agGridTimeFormatter,
              },
              flight: FlightBound.OUTBOUND,
              valueGetter(params) {
                return that.agGridTimeGetter(params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['times', 'scheduledBoardingTime']), that.dateFilter);
              },
              valueFormatter: agGridTimeFormatter,
              cellRendererFramework: AgGridTimeCellRenderer,
            },
            {
              colId: 'outbound.times.scheduledBoardingEndTime',
              field: 'outbound.times.scheduledBoardingEndTime',
              headerClass: 'operations-header',
              headerComponentParams: { template: this.generateHeaderTemplate('stop_circle') },
              headerTooltip: that.$i18n.t('operations.scheduledBoardingEndTimeLong'),
              width: 35,
              minWidth: 35,
              editable: this.hasPermissions(rolePermission) && this.isOutboundAndNotAircraftGround,
              cellClass: 'airport-sta-cell',
              comparator: this.objectWithValueComparator,
              cellEditor: 'AgGridConnectedFlightsTimeCellEditor',
              colCustomConfig: {
                fieldPaths: ['times.scheduledBoardingEndTime'],
                flightBound: FlightBound.OUTBOUND,
                fieldsRelated: ['outbound.times.scheduledBoardingEndTime'],
                oldValueFormatter: agGridTimeFormatter,
              },
              flight: FlightBound.OUTBOUND,
              valueGetter(params) {
                return that.agGridTimeGetter(params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['times', 'scheduledBoardingEndTime']), that.dateFilter);
              },
              valueFormatter: agGridTimeFormatter,
              cellRendererFramework: AgGridTimeCellRenderer,
            },
            {
              colId: 'operation.operationsActionsStatus',
              field: 'operation.operationsActionsStatus',
              headerClass: 'operations-header',
              headerComponentParams: { template: this.generateHeaderTemplate('fact_check') },
              headerTooltip: that.$i18n.t('operations.actionsStatus'),
              width: 18,
              minWidth: 18,
              editable: this.isOutboundAndNotAircraftGround,
              cellClass: 'ag-grid-popover-cell',
              comparator: this.objectWithValueComparator,
              cellEditor: 'AgGridConnectedFlightsDialogOpeStatusCellEditor',
              colCustomConfig: {
                flightBound: FlightBound.OUTBOUND,
                fieldsRelated: ['outbound.operation.operationsActionsStatus'],
              },
              flight: FlightBound.OUTBOUND,
              valueGetter(params) {
                return params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['operation', 'operationsActionsStatus']);
              },
              cellRendererFramework: AgGridStatusOperationCellRenderer,
            },
          ],
        },
        {
          field: 'PassageOrganization',
          headerClass: 'passage-header header-top',
          headerTooltip: 'Passage organization related information',
          headerValueGetter() {
            return that.$i18n.t('common.paxService');
          },
          children: [
            {
              colId: 'outbound.times.startedBoardingTime',
              field: 'outbound.times.startedBoardingTime',
              headerClass: 'passage-header',
              headerComponentParams: { template: this.generateHeaderTemplate('play_circle_outline') },
              headerTooltip: that.$i18n.t('operations.realBoardingStartTime'),
              width: 48,
              minWidth: 48,
              editable: this.hasPermissions(rolePermission) && this.isOutboundAndNotAircraftGround,
              colCustomConfig: {
                fieldPaths: ['times.startedBoardingTime'],
                flightBound: FlightBound.OUTBOUND,
                fieldsRelated: ['outbound.times.startedBoardingTime'],
                oldValueFormatter: agGridTimeFormatter,
              },
              cellEditor: 'AgGridConnectedFlightsTimeCellEditor',
              cellClass: 'aircraft-type-cell  separator ',
              comparator: this.objectWithValueComparator,
              valueFormatter: agGridTimeFormatter,
              valueGetter(params) {
                return that.agGridTimeGetter(params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['times', 'startedBoardingTime']), that.dateFilter);
              },
              cellRendererFramework: AgGridTimeCellRenderer,
            },
            {
              colId: 'outbound.times.completedBoardingTime',
              field: 'outbound.times.completedBoardingTime',
              headerClass: 'passage-header',
              headerComponentParams: { template: this.generateHeaderTemplate('stop_circle') },
              headerTooltip: that.$i18n.t('operations.realBoardingEndTime'),
              width: 35,
              minWidth: 35,
              editable: this.hasPermissions(rolePermission) && this.isOutboundAndNotAircraftGround,
              colCustomConfig: {
                fieldPaths: ['times.completedBoardingTime'],
                flightBound: FlightBound.OUTBOUND,
                fieldsRelated: ['outbound.times.completedBoardingTime'],
                oldValueFormatter: agGridTimeFormatter,
              },
              cellEditor: 'AgGridConnectedFlightsTimeCellEditor',
              cellClass: 'aircraft-type-cell',
              comparator: this.objectWithValueComparator,
              valueFormatter: agGridTimeFormatter,
              valueGetter(params) {
                return that.agGridTimeGetter(params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['times', 'completedBoardingTime']), that.dateFilter);
              },
              cellRendererFramework: AgGridTimeCellRenderer,
            },
            {
              colId: 'operation.passengersActionsStatus',
              field: 'operation.passengersActionsStatus',
              headerClass: 'passage-header',
              headerComponentParams: { template: this.generateHeaderTemplate('fact_check') },
              headerTooltip: that.$i18n.t('operations.passengerActionsStatus'),
              width: 18,
              minWidth: 18,
              editable: this.isOutboundAndNotAircraftGround,
              cellEditor: 'AgGridConnectedFlightsDialogPaxTimesCellEditor',
              cellClass: 'ag-grid-popover-cell',
              comparator: this.objectWithValueComparator,
              colCustomConfig: {
                flightBound: FlightBound.OUTBOUND,
                fieldsRelated: ['outbound.operation.passengersActionsStatus'],
              },
              flight: FlightBound.OUTBOUND,
              valueGetter(params) {
                return params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['operation', 'passengersActionsStatus']);
              },
              cellRendererFramework: AgGridStatusOperationCellRenderer,
            },
          ],
        },
        {
          field: 'RampOrganization',
          headerClass: 'ramp-header',
          headerTooltip: 'Ramp organization related information',
          headerValueGetter() {
            return that.$i18n.t('sidebar.Ramp');
          },
          children: [
            {
              colId: 'services.rampServices',
              field: 'services.rampServices',
              headerComponentParams: { template: this.generateHeaderTemplate('settings_suggest') },
              headerTooltip: that.$i18n.t('operations.specialServicesLong'),
              width: 40,
              minWidth: 40,
              cellClass: 'ag-grid-popover-cell  separator ',
              comparator: this.objectWithValueComparator,
              cellEditor: 'agGridAircraftTypeCellEditor',
              colCustomConfig: {
                flightBound: FlightBound.ANY,
                fieldsRelated: ['inbound.services.rampServices', 'outbound.services.rampServices'],
              },
              valueGetter(params) {
                const rampServices = [];
                if (params.data.getChildPropertyValue(FlightBound.INBOUND, ['services', 'rampServices']) != null) {
                  params.data.getChildPropertyValue(FlightBound.INBOUND, ['services', 'rampServices']).forEach(rs => rampServices.push(rs));
                }
                if (params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['services', 'rampServices']) != null) {
                  params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['services', 'rampServices']).forEach(rs => rampServices.push(rs));
                }
                return Array.from(new Set(rampServices));
              },
              cellRendererFramework: AgGridMultilineStringsCellRenderer,
            },
            {
              colId: 'services.rampServicesStatus',
              field: 'services.rampServicesStatus',
              headerComponentParams: { template: this.generateHeaderTemplate('fact_check') },
              headerTooltip: that.$i18n.t('operations.rampServicesStatus'),
              width: 18,
              minWidth: 18,
              editable: true,
              cellClass: 'ag-grid-popover-cell',
              comparator: this.objectWithValueComparator,
              cellEditor: 'AgGridConnectedFlightsDialogRampStatusCellEditor',
              colCustomConfig: {
                flightBound: FlightBound.ANY,
                fieldsRelated: ['inbound.services.rampServicesStatus', 'outbound.services.rampServicesStatus'],
                isAircraftGround(connectedFlights) {
                  return that.isAircraftGround(connectedFlights);
                },
              },
              flight: FlightBound.ANY,
              valueGetter(params) {
                return params.data.getChildPropertyValue(FlightBound.ANY, ['services', 'rampServicesStatus']);
              },
              cellRendererFramework: AgGridStatusOperationCellRenderer,
            },
          ],
        },
      ],
    };
  },
  methods: {
    onGetChanges(changes) {
      const rowNode = this.gridApi.getRowNode(changes.payload.id);

      if (!rowNode) {
        return;
      }
      const oldConnectedFlight = rowNode.data.clone();
      const newConnectedFlight = changes.payload;
      this.assignInboundOrOutboundIfDontExist(newConnectedFlight, oldConnectedFlight);
      const differencies = this.getDifferenciesAndCreateOldValuesProperties(oldConnectedFlight, newConnectedFlight);

      this.setOldValuesDifferencesFromOldConnectedFlight(rowNode.data, newConnectedFlight);

      if (differencies && differencies.length > 0) {
        rowNode.setData(newConnectedFlight);
        if (this.$store.state.operation.flashOnChanges) {
          this.renderNeededDiffs(differencies, rowNode); // TODO only shows flash
        }
        differencies.forEach(dif => this.$store.commit(OPERATION_SET_CHANGE, { diff: dif, connectedFlights: newConnectedFlight, user: changes.origin }));
      }
    },
    onGridReady(params) {
      this.setAgGridApi(params);
      this.showLoading();
      this.sizeColumnsToFit();

      this.$store.commit(OPERATION_SET_GRID_API, params); // TODO eliminar api del store

      this.refreshCellsOnMutation();
      this.refreshCellsOnChangeLanguage();
      const that = this;
      this.subscribeMutation(OPERATION_ON_EXTERNAL_CHANGE, (mutationPayload) => {
        that.onGetChanges(mutationPayload);
      });
    },
    firstDataRendered() {
      this.$nextTick(() => {
        this.resized();
      });
    },
    resized() {
      if (this.fitColumnsOnResize) {
        this.sizeColumnsToFit();
      }
    },
    sizeColumnsToFit() {
      if (this.gridApi) {
        this.$store.dispatch(FIT_COLUMNS_TO_VIEW);
      }
    },
    onSelectionChanged(event) {
      const selectedNodes = event.api.getSelectedNodes();
      const selectedData = selectedNodes.map(node => node.data);
      this.$store.commit(OPERATION_CHANGE_FLIGHT_SELECTION, selectedData);
    },
    deleteItem(item) {
      console.log(`delete ${item}`); // eslint-disable-line no-console
    },
    editItem(item) {
      console.log(`edit ${item}`); // eslint-disable-line no-console
    },
    isInbound(params) {
      return params.data.hasInbound();
    },
    isOutbound(params) {
      return params.data.hasOutbound();
    },
    bestTimeGetter(bestTime, scheduledTime, referenceDateTime, status = null) {
      const result = this.agGridTimeGetter(bestTime, referenceDateTime);
      const scheduledTimeAux = scheduledTime ? this.$moment(scheduledTime) : null;
      if (status === 'OFFBLOCK' && result.value && result.value.isValid()) {
        if (scheduledTimeAux && scheduledTimeAux.isValid() && result.value.startOf('minute').isAfter(scheduledTimeAux.startOf('minute'))) {
          result.color = 'red';
        }
      }
      return result;
    },
    renderNeededDiffs(differences, rowNode) {
      const { gridApi } = this.$store.state.operation;

      const agGridCustomService = new AgGridCustomService(this.columnDefs); // TODO one time
      const colIds = agGridCustomService.getColIdByMatchAnyfieldsRelated(this.joinAllPropertyPath(differences));

      if (colIds && colIds.length > 0) {
        colIds.forEach((colId) => {
          if (gridApi.getColumnDef(colId)) {
            gridApi.refreshCells({
              rowNodes: [rowNode],
              columns: [colId],
              force: true,
            });

            gridApi.flashCells({
              rowNodes: [rowNode],
              columns: [colId],
              force: true,
            });
          }
        });
      }
    },
    checkBoundAndSetMsgTooltipObject(messageValue) {
      if (messageValue === null || typeof (messageValue) === 'undefined') {
        return null;
      }
      if (messageValue === 'WARNING_LDM') {
        return {
          value: messageValue,
          icon: 'warning',
          color: 'red',
          tooltip: this.$t('messages.warningMessageLDM'),
        };
      }

      return {
        value: messageValue,
        icon: messageValue !== 'EMPTY' ? 'sms' : '',
        color: messageValue === 'UNREAD' ? 'success' : 'bggreydark',
        tooltip: messageValue === 'UNREAD' ? this.$t('messages.messagesUnread') : this.$t('messages.messagesRead'),
      };
    },
    checkBoundAndSetRemTooltipObject(remarkValue, missingPaxAlert) {
      if (remarkValue === null || typeof (remarkValue) === 'undefined') {
        return null;
      }
      if (missingPaxAlert) {
        return {
          value: remarkValue,
          icon: 'info',
          color: 'orange',
          tooltip: this.$t('remarks.missingPaxAlert'),
        };
      }
      return {
        value: remarkValue,
        icon: remarkValue !== 'EMPTY' ? 'info' : '',
        color: remarkValue === 'UNREAD' ? 'primary' : 'bggreydark',
        tooltip: remarkValue === 'UNREAD' ? this.$t('remarks.remarksUnread') : this.$t('remarks.remarksRead'),
      };
    },
    getInformationDelay(params, bound) {
      const amount1 = this.getAmountFormat(params.data.getChildPropertyValue(bound, ['delays', 'flightDelayAmount1']));
      const delayCode1 = params.data.getChildPropertyValue(bound, ['delays', 'flightDelayCode1']) ? `(${params.data.getChildPropertyValue(bound, ['delays', 'flightDelayCode1'])})` : '';
      const delayRemark1 = params.data.getChildPropertyValue(bound, ['delays', 'flightDelayRemark1']) ? ` - ${params.data.getChildPropertyValue(bound, ['delays', 'flightDelayRemark1'])}` : '';

      const delay1 = delayCode1 !== '' || amount1 != null ? `${delayCode1} ${amount1} ${delayRemark1}` : '';

      const amount2 = this.getAmountFormat(params.data.getChildPropertyValue(bound, ['delays', 'flightDelayAmount2']));
      const delayCode2 = params.data.getChildPropertyValue(bound, ['delays', 'flightDelayCode2']) ? `(${params.data.getChildPropertyValue(bound, ['delays', 'flightDelayCode2'])})` : '';
      const delayRemark2 = params.data.getChildPropertyValue(bound, ['delays', 'flightDelayRemark2']) ? ` - ${params.data.getChildPropertyValue(bound, ['delays', 'flightDelayRemark2'])}` : '';

      const delay2 = delayCode2 !== '' || amount2 != null ? `<br>${delayCode2} ${amount2} ${delayRemark2}` : '';

      const amount3 = this.getAmountFormat(params.data.getChildPropertyValue(bound, ['delays', 'flightDelayAmount3']));
      const delayCode3 = params.data.getChildPropertyValue(bound, ['delays', 'flightDelayCode3']) ? `(${params.data.getChildPropertyValue(bound, ['delays', 'flightDelayCode3'])})` : '';
      const delayRemark3 = params.data.getChildPropertyValue(bound, ['delays', 'flightDelayRemark3']) ? ` - ${params.data.getChildPropertyValue(bound, ['delays', 'flightDelayRemark3'])}` : '';

      const delay3 = delayCode3 !== '' || amount3 != null ? `<br>${delayCode3} ${amount3} ${delayRemark3}` : '';

      return `${delay1} ${delay2} ${delay3}`;
    },
    getAmountFormat(amount) {
      if (amount) {
        const milliseconds = amount ? Number(amount) * 60000 : 0;
        let hours = (Number(this.$moment.duration(milliseconds).days()) * 24) + Number(this.$moment.duration(milliseconds).hours());
        if (hours === 0) {
          hours = '00';
        } else if (hours.toString().length === 1) {
          hours = `0${hours}`;
        }
        let minutes = this.$moment.duration(milliseconds).minutes();
        if (minutes === 0) {
          minutes = '00';
        } else if (minutes.toString().length === 1) {
          minutes = `0${minutes}`;
        }
        return `${hours}:${minutes}`;
      }
      return null;
    },
    isAircraftGround(params) {
      if (params.data) {
        return params.data.getChildPropertyValue(FlightBound.INBOUND, ['common', 'id']) != null && params.data.getChildPropertyValue(FlightBound.INBOUND, ['common', 'tripNumber']) == null;
      }
      return params.getChildPropertyValue(FlightBound.INBOUND, ['common', 'id']) != null && params.getChildPropertyValue(FlightBound.INBOUND, ['common', 'tripNumber']) == null;
    },
    isNotAircraftGround(params) {
      return !this.isAircraftGround(params);
    },
    isInboundAndNotAircraftGround(params) {
      return this.isInbound(params) && !this.isAircraftGround(params);
    },
    isOutboundAndNotAircraftGround(params) {
      return this.isOutbound(params) && !this.isAircraftGround(params);
    },
    generateHeaderTemplate(iconName) {
      return `<div class="ag-cell-label-container" role="presentation">
              <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>
              <span ref="eFilterButton" class="ag-header-icon ag-header-cell-filter-button"></span>
              <div ref="eLabel" class="ag-header-cell-label" role="presentation">
                <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>
                <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>
                <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>
                <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>
                <i class="material-icons header-icon">${iconName}</i>
                <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>
              </div>
            </div>`;
    },
  },
};

</script>
