<template>
  <div>
    <entity-configuration-list
      :entity-name="this.$i18n.t('common.airline')"
      entity-icon="local_airport"
      :framework-components="frameworkComponents" :column-defs="columnDefs" :items="airlines"
      @confirm-edit-item="editConfirm"
      @confirm-delete-item="deleteConfirm"
      @confirm-enable-disable-item="enableDisableConfirm"
    >
      <template v-slot:modal-content="{ itemEdit }">
        <airline-edit-card-content :item="itemEdit" />
      </template>
    </entity-configuration-list>
  </div>
</template>
<script>
import {
  GET_AIRLINE,
  AIRLINE_CREATE,
  AIRLINE_DELETE,
  AIRLINE_UPDATE,
  AIRLINE_DISABLE,
  AIRLINE_ENABLE,
} from '@/store/modules/airline/airlineCatalog';
import EntityConfigurationList from '@/components/configuration/common/EntityConfigurationList.vue';
import AirlineEditCardContent from './AirlineEditCardContent.vue';
import AgGridIconCellRenderer from '../../ag-grid/cell-renderers/AgGridIconCellRenderer.vue';

export default {
  name: 'AirlineList',
  components: {
    EntityConfigurationList,
    AirlineEditCardContent,
  },
  data() {
    const that = this;
    return {
      frameworkComponents: {
        AgGridIconCellRenderer,
      },
      columnDefs: [
        {
          field: 'iataCode',
          headerValueGetter() {
            return that.$i18n.t('configuration.iataCode');
          },
        },
        {
          field: 'icaoCode',
          headerValueGetter() {
            return that.$i18n.t('configuration.icaoCode');
          },
        },
        {
          field: 'code',
          headerValueGetter() {
            return that.$i18n.t('configuration.defaultCode');
          },

        },
        {
          field: 'name',
          headerValueGetter() {
            return that.$i18n.t('configuration.name');
          },
        },
        {
          field: 'comments',
          headerValueGetter() {
            return that.$i18n.t('configuration.description');
          },
        },
        {
          field: 'ghmIntegrationName',
          headerValueGetter() {
            return that.$i18n.t('configuration.ghmIntegrationName');
          },
        },
        {
          field: 'ghmIntegrationExtraCode',
          headerValueGetter() {
            return that.$i18n.t('configuration.ghmIntegrationExtraCode');
          },
        },
        {
          headerValueGetter() {
            return that.$i18n.t('configuration.disabled');
          },
          field: 'deleted',
          cellRendererFramework: AgGridIconCellRenderer,
          cellRendererParams: {
            icon: 'mdi-cancel',
            tooltip: params => (params.data.deleted ? this.$i18n.t('configuration.disabled') : null),
          },
        },
      ],
    };
  },
  computed: {
    airlines() {
      return this.$store.getters.getAirlines;
    },
  },
  methods: {
    getAirlines() {
      this.$store.dispatch(GET_AIRLINE);
    },
    deleteConfirm(item) {
      this.$store.dispatch(AIRLINE_DELETE, item.id);
    },
    editConfirm(item) {
      this.$store.dispatch(item.id ? AIRLINE_UPDATE : AIRLINE_CREATE, item);
    },
    enableDisableConfirm(item) {
      this.$store.dispatch(item.deleted ? AIRLINE_ENABLE : AIRLINE_DISABLE, item.id);
    },

  },
  created() {
    this.getAirlines();
  },
};
</script>
