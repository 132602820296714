<template>
  <v-container fluid pa-0>
    <v-layout column>
      <v-flex xs12>
        <ag-grid-vue
          class="ag-theme-material"
          @grid-ready="setAgGridApi"
          :gridOptions="gridOptions"
          :frameworkComponents="frameworkComponentsComputed"
          :defaultColDef="defaultColDef"
          :columnDefs="columnDefsComputed"
          suppressHorizontalScroll="true"
          :rowData="items"
          :deltaRowDataMode="true"
          v-bind:style="`width: 100%; height: 80vh;`">
        </ag-grid-vue>
        <div class="export-n-import-buttons">
          <!-- EXPORT BUTTON -->
          <v-btn color="primary" small flat v-on:click.stop="exportCSV">
            <v-icon>mdi-download</v-icon>
            {{$t("common.export")}}
          </v-btn>
          <!-- IMPORT BUTTON -->
          <CSVImporter v-if="importEnabled" @onCSVProcessed="importCSV"/>
        </div>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import { AgGridVue } from 'ag-grid-vue';
import { cellClassAgGridRules } from '@/components/ag-grid/mixins/cellClassAgGridRules';
import AgGridApiCsvExportable from '@/components/ag-grid/mixins/AgGridApiCsvExportable.vue';
import AgGridOptionsConfigurable from '@/components/ag-grid/mixins/AgGridOptionsConfigurable.vue';
import AgGridRowActionButtonsCatalogsCellRenderer from '@/components/ag-grid/cell-renderers/AgGridRowActionButtonsCatalogsCellRenderer.vue';
import agGridTextCustomFilterComparator from '../../ag-grid/filters/agGridTextCustomFilterComparator';
import agGridTextCustomFilterFormatter from '../../ag-grid/filters/agGridTextCustomFilterFormatter';
import { CURRENT_LANGUAGE_CHANGED } from '../../../store/modules/i18n';
import RolePermissions from '../../mixins/RolePermissions.vue';
import CSVImporter from '../../ui/CSVImporter.vue';

export default {
  name: 'entity-configuration-list-table',
  mixins: [AgGridApiCsvExportable, AgGridOptionsConfigurable, RolePermissions],
  components: {
    AgGridVue, CSVImporter,
  },
  props: {
    entityName: { type: String, required: true },
    columnDefs: { type: Array, required: true },
    frameworkComponents: { type: Object },
    items: { type: Array, required: true },
    importEnabled: { type: Boolean, default: false },
  },
  data() {
    const that = this;
    const rolePermission = this.getRoles();
    return {
      frameworkComponentsBase: {
        AgGridRowActionButtonsCatalogsCellRenderer,
      },
      defaultColDef: {
        cellClassRules: cellClassAgGridRules,
        resizable: true,
        sortable: true,
        filter: true,
        filterParams: {
          textCustomComparator: agGridTextCustomFilterComparator,
          textFormatter: agGridTextCustomFilterFormatter,
        },
      },
      gridOptions: {
        getRowClass: this.getRowClass,
        getRowNodeId: this.getRowNodeId,
      },
      columnDefsBase: [
        {
          headerValueGetter() {
            return that.$i18n.t('common.actions');
          },
          width: 120,
          cellClass: 'text-center',
          suppressMenu: true,
          sortable: false,
          suppressMovable: true,
          suppressSizeToFit: true,
          filter: false,
          resizable: false,
          suppressNavigable: true,
          colCustomConfig: {
            exportCsvDisabled: true,
          },
          valueGetter(params) {
            return params.data;
          },
          cellRendererFramework: AgGridRowActionButtonsCatalogsCellRenderer,
          cellRendererParams: {
            isEditable: that.hasPermissions(rolePermission),
            editItem: this.editItem,
            enableItem: this.enableItem,
            enableDisableItem: this.enableDisableItem,
            deleteItem: this.deleteItem,
          },
        },
      ],
    };
  },
  computed: {
    columnDefsComputed() {
      return this.columnDefs.concat(this.columnDefsBase);
    },
    frameworkComponentsComputed() {
      return Object.assign({}, this.frameworkComponentsBase, this.frameworkComponents ? this.frameworkComponents : {});
    },
  },
  methods: {
    deleteItem(item) {
      this.$emit('click-delete-item', item);
    },
    editItem(item) {
      this.$emit('click-edit-item', item);
    },
    enableDisableItem(item) {
      this.$emit('click-enable-disable-item', item);
    },
    exportCSV() {
      this.exportAgGridCsv(this.entityName.replace(/[^a-z0-9]/gi, '_').toLowerCase());
    },
    getRowNodeId(data) {
      return data.id;
    },
    handleWindowSizeChange() {
      this.gridOptions.api.sizeColumnsToFit();
    },
    importCSV(csvData) {
      this.$emit('click-import-csv-items', csvData);
    },
  },
  mounted() {
    window.addEventListener('resize', this.handleWindowSizeChange);
    this.subscribeMutation(CURRENT_LANGUAGE_CHANGED, () => {
      this.gridApi.refreshHeader();
    });
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  },
};
</script>
<style>
  .export-n-import-buttons {
    display: flex;
    align-items: center;
  }
</style>
