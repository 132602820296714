import PaginationRequestProperties from './paginationRequestProperties';

export default class PaginationResponseProperties {
  constructor(first, number, size, numberOfElements, totalResults, totalPages, offset, last) {
    this.first = first;
    this.currentPage = number;
    this.pageSize = size;
    this.currentPageNumberOfResults = numberOfElements;
    this.totalResults = totalResults;
    this.totalPages = totalPages;
    this.offset = offset;
    this.last = last;
  }

  static get createDefaultPaginationResponseProperties() {
    return new PaginationResponseProperties(
      true,
      PaginationRequestProperties.DEFAULT_CURRENT_PAGE,
      PaginationRequestProperties.MINIMUM_PAGE_SIZE, 0, 0, 0, 0,
      true,
    );
  }

  static createFromPagedResult(pagedResult) {
    if (!pagedResult) {
      return this.createDefaultPaginationResponseProperties;
    }
    const previousPage = pagedResult.number - 1;
    const lastRegistryOfThePreviousPage = previousPage * pagedResult.size;
    const offset = lastRegistryOfThePreviousPage + 1;

    const currentPageNumberOfResults = lastRegistryOfThePreviousPage + pagedResult.numberOfElements;

    return new PaginationResponseProperties(
      pagedResult.first,
      pagedResult.number,
      pagedResult.size,
      currentPageNumberOfResults,
      pagedResult.totalElements,
      pagedResult.totalPages,
      offset,
      pagedResult.last,
    );
  }
}
