import moment from 'moment';
import { intToHex } from 'vuetify/lib/util/colorUtils';
import ConnectedFlightsFactory from '../shared/model/connectedFlightsFactory';
import ConnectedFlights from '../shared/model/connectedFlights';

export default class GanttFlightsMapping {
  static mapConnectedFlights(connectedFlightsList, ganttOptions) {
    let mapped = connectedFlightsList.map(connectedFlights => this.populateNotConnectingBound(connectedFlights, ganttOptions));
    mapped = mapped.sort((connectedFlights1, connectedFlights2) => moment(connectedFlights1.inbound.times.bestTime) - moment(connectedFlights2.inbound.times.bestTime));
    mapped = mapped.map(connectedFlights => this.mapConnectedFlightsToGanttTask(connectedFlights));
    const groups = this.groupTasksByTime(mapped);
    return groups;
  }

  static populateNotConnectingBound(connectedFlights, ganttOptions) {
    if (connectedFlights.inbound && !connectedFlights.outbound) {
      return new ConnectedFlights(connectedFlights.inbound, this.createFlightCompositionWithReverseBound(connectedFlights.inbound, ganttOptions));
    } if (!connectedFlights.inbound && connectedFlights.outbound) {
      return new ConnectedFlights(this.createFlightCompositionWithReverseBound(connectedFlights.outbound, ganttOptions), connectedFlights.outbound);
    }
    return connectedFlights;
  }

  static createFlightCompositionWithReverseBound(flightComposition, ganttOptions) {
    const scheduledTime = moment(flightComposition.times.bestTime).add((flightComposition.common.bound === 'INBOUND' ? 1 : -1) * ganttOptions.unlinkedFlightsDurationMinutes, 'minutes');
    const reverseBound = ConnectedFlightsFactory.createFlightCompositionFromData({
      common: {
        bound: flightComposition.common.bound === 'INBOUND' ? 'OUTBOUND' : 'INBOUND',
        tripNumber: '***',
        airlineCode: flightComposition.common.airlineCode,
        airport1Code: '***',
        flightDate: flightComposition.common.flightDate,
        scheduledTime,
      },
      times: {
        bestTime: scheduledTime,
        status: 'SCHEDULED',
      },
    });
    return reverseBound;
  }

  static mapConnectedFlightsToGanttTask(connectedFlights) {
    let clipPath = '';
    let color = '#ff4021';
    if (connectedFlights.inbound.common.tripNumber === '***') {
      clipPath = 'polygon(100% 0, 100% 50%, 100% 100%, 0% 100%, 20% 50%, 0% 0%)';
      color = intToHex(this.airlineHashCode(`${connectedFlights.outbound.common.airlineCode}${connectedFlights.outbound.common.aircraftTypeId}`));
    } else if (connectedFlights.outbound.common.tripNumber === '***') {
      clipPath = 'polygon(100% 0%, 80% 50%, 100% 100%, 0 100%, 0% 50%, 0 0)';
      color = intToHex(this.airlineHashCode(`${connectedFlights.inbound.common.airlineCode}${connectedFlights.inbound.common.aircraftTypeId}`));
    } else {
      color = intToHex(this.airlineHashCode(`${connectedFlights.inbound.common.airlineCode}${connectedFlights.inbound.common.aircraftTypeId}`));
    }
    const start = moment(connectedFlights.inbound.times.bestTime).local(connectedFlights.inbound.times.bestTime);
    const end = moment(connectedFlights.outbound.times.bestTime).local(connectedFlights.outbound.times.bestTime);
    if (end - start > 7200000) {
      clipPath = 'polygon(0 0, 15% 0, 15% 40%, 85% 40%, 85% 0, 100% 0, 100% 100%, 85% 100%, 85% 60%, 15% 60%, 15% 100%, 0 100%)';
    }
    return {
      id: connectedFlights.inbound.common.id,
      label: `<strong>${connectedFlights.inbound.common.airlineCode}${connectedFlights.inbound.common.tripNumber}/${connectedFlights.outbound.common.tripNumber}</strong>`,
      start,
      end,
      type: 'task',
      connectedFlights,
      style: {
        base: {
          fill: color,
          stroke: color,
          clipPath,
        },
      },
    };
  }

  static groupTasksByTime(tasks) {
    const groups = [];
    tasks.forEach((task) => {
      const availableGroup = groups.find(g => moment(task.start).isAfter(moment(g.end)));
      if (availableGroup) {
        availableGroup.end = task.end;
        availableGroup.tasks.push(task);
      } else {
        groups.push({
          id: groups.length + 1,
          label: `Position ${groups.length + 1}`,
          // user: '<a href="https://www.google.com/search?q=Clark+Kent" target="_blank" style="color:#0077c0;">Clark Kent</a>',
          start: task.start,
          end: task.end,
          // duration: moment(c.outbound.times.bestTime).diff(moment(c.inbound.times.bestTime)),
          // percent: 50,
          type: 'group',
          tasks: [task],
        });
      }
    });
    return groups;
  }

  static airlineHashCode(airlineCode) {
    let hash = 0;
    for (let i = 0; i < airlineCode.length; i += 1) {
      hash = Math.imul(31, hash) + airlineCode.charCodeAt(i) || 0;
    }
    return hash;
  }
}
