<template>
  <div class="ticketing list-filter">
    <v-card :dark="this.$vuetify.dark" :color="this.$vuetify.dark ? 'bggrey_dark' : 'bggrey'">
      <v-toolbar dark dense color="primary lighten-1">
        <v-btn v-on:click="displayedFilter" small flat>
          <v-icon>filter_list</v-icon>
          <v-icon v-if="!showFilter">keyboard_arrow_down</v-icon>
          <v-icon v-if="showFilter">keyboard_arrow_up</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-flex class="ml-4" grow="false" shrink="true">
          <scheduling-flights-saved-queries />
        </v-flex>
        <TicketingListTableOptionsFilterButton />
      </v-toolbar>
      <v-container fluid v-if="showFilter" class="pa-2 pb-2">
        <v-layout class="filter-inline" align-center justify-start>
          <v-flex class="mr-2" align-center justify-start>
          <date-time-input
            v-model="filter.dateFrom"
            :locale="$i18n.locale"
            :label="$t('common.fromDate')"
            @change="changeDateFrom"
          ></date-time-input>
          <date-time-input
            v-model="filter.dateTo"
            :locale="$i18n.locale"
            :label="$t('common.toDate')"
            @change="changeDateTo"
          ></date-time-input>
        </v-flex>
        <v-flex class="text-no-wrap" align-center justify-start>
          <ticketing-status-select
            :label="$t('common.status')"
            v-model="filter.status"
            @input="filterChanged"
          />
          <airline-fare-select
            :label="$t('ticketing.fare')"
            v-model="filter.airlineFare"
            @input="filterChanged"
          />
        </v-flex>
        <v-flex class="text-no-wrap" align-center justify-start style="max-width:200px;min-width: 200px;">
          <airline-select
            class="ml-4"
            v-char-length.clearable="2"
            :label="$t('common.airlineOp')"
            style="min-width: 300px"
            v-model="filter.airline"
            @input="filterChanged"
            :filter-handling-active="true"
          ></airline-select>
          <airport-select
            class="ml-4"
            v-char-length.clearable="3"
            :label="$t('operations.loadsTableFromTo')"
            v-model="filter.airport"
            style="min-width: 300px"
            @input="filterChanged"
          ></airport-select>
        </v-flex>
          <v-spacer style="width: 25%"></v-spacer>
          <v-flex class="ml-2 text-no-wrap" align-center justify-start>
            <v-btn color="secondary" small flat round v-on:click.stop="exportCSV" block class="mb-4">
              <v-icon>mdi-download</v-icon>
              {{ $t("common.export") }}
            </v-btn>
            <v-btn color="primary" round v-on:click.stop="find" block>
              <v-icon>search</v-icon>
              {{ $t("common.find") }}
            </v-btn>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card>
  </div>
</template>
<script>
import AirlineSelect from '../../airline/AirlineSelect.vue';
import AirportSelect from '../../airport/AirportSelect.vue';
import DateTimeInput from '../../ui/DateTimeInput.vue';
import DaysOfWeek from '../../ui/DaysOfWeek.vue';
import AgGridApiCsvExportable from '../../ag-grid/mixins/AgGridApiCsvExportable.vue';
import StoreSubscribable from '../../mixins/StoreSubscribable.vue';
import { TIME_TIMEZONE_CHANGED } from '../../../store/modules/time';
import SchedulingFlightsSavedQueries from '../../scheduling/list/SchedulingFlightsSavedQueries.vue';
import FlightState from '../../ui/FlightState.vue';
import FlightService from '../../ui/FlightService.vue';
import TicketingListTableOptionsFilterButton from './TicketingListTableOptionsFilterButton.vue';
import RampServiceSelect from '../../rampService/RampServiceSelect.vue';
import RampServiceMultipleSelect from '../../rampService/RampServiceMultipleSelect.vue';
import FileSerializable from '../../mixins/FileSerializable.vue';
import TicketingStatusSelect from '../TicketingStatusSelect.vue';
import AirlineFareSelect from '../../ui/airlineFareSelector/AirlineFareSelect.vue';
import {
  TICKETING_CHANGE_GRID_API,
  TICKETING_FILTER_SET_TENANT,
  TICKETING_FILTER_SET_UNIT_TIME,
  TICKETING_FLIGHTS_FIND,
  TICKETING_RECALCULATE_FILTER,
} from '../../../store/modules/ticketing';
import { TENANT_CURRENT_SET_SUCCESS } from '../../../store/modules/tenant';
import { ROLE_CASHIER_TICKETING } from '../../../constants/roles';

export default {
  name: 'ticketing-list-filter',
  mixins: [AgGridApiCsvExportable, StoreSubscribable, FileSerializable],
  components: {
    RampServiceSelect,
    RampServiceMultipleSelect,
    SchedulingFlightsSavedQueries,
    DaysOfWeek,
    AirlineSelect,
    AirportSelect,
    DateTimeInput,
    FlightState,
    FlightService,
    TicketingListTableOptionsFilterButton,
    TicketingStatusSelect,
    AirlineFareSelect,
  },
  props: ['value'],
  data() {
    return {
      showFilter: true,
      filterSummary: null,
    };
  },
  computed: {
    filter() {
      return this.$store.state.ticketing.filter;
    },
    unitOfTime: {
      get() {
        return this.filter.unitOfTime;
      },
      set(value) {
        this.$store.commit(TICKETING_FILTER_SET_UNIT_TIME, value);
      },
    },
    isTenantSetted() {
      return this.$store.getters.isTenantSetted;
    },
    tenantId() {
      return this.isTenantSetted
        ? this.$store.state.tenant.currentTenant.id
        : null;
    },
    authUser() {
      return this.$store.state.auth.authUser;
    },
    ticketingUserRole() {
      return this.$store.state.ticketing.ticketingUserRole;
    },
  },
  methods: {
    filterChanged() {
      //  this.find();
    },
    find() {
      this.showLoading();
      this.$store.dispatch(TICKETING_FLIGHTS_FIND);
    },
    exportCSV() {
      this.exportAgGridCsv('TicketingFlights');
    },
    changeDateFrom() {
      this.unitOfTime = null;
      this.filterChanged();
    },
    changeDateTo() {
      this.unitOfTime = null;
      this.filterChanged();
    },
    displayedFilter() {
      this.showFilter = !this.showFilter;
      this.$emit('changeShowFilter', this.showFilter);
    },
    checkIfUserIsCashier() {
      if (this.ticketingUserRole === ROLE_CASHIER_TICKETING) {
        this.filter.createdBy = { equals: this.authUser.login };
      } else {
        this.filter.createdBy = null;
      }
    },
  },
  watch: {
    ticketingUserRole() {
      this.checkIfUserIsCashier();
      this.find();
    },
  },
  mounted() {
    const that = this;
    this.subscribeMutation(TICKETING_CHANGE_GRID_API, (mutationPayload) => {
      that.setAgGridApi(mutationPayload);
    });
    this.subscribeMutation(TIME_TIMEZONE_CHANGED, () => {
      this.$store.dispatch(TICKETING_RECALCULATE_FILTER);
      this.find();
    });
    this.subscribeMutation(TENANT_CURRENT_SET_SUCCESS, () => {
      this.$store.commit(TICKETING_FILTER_SET_TENANT, this.tenantId);
      this.find();
    });
    this.$store.dispatch(TICKETING_RECALCULATE_FILTER);
    this.$store.commit(TICKETING_FILTER_SET_TENANT, this.tenantId);
    this.checkIfUserIsCashier();
    this.find();
  },
};
</script>
<style>
.text-filter {
  margin: 1em;
  font-weight: bold;
}
</style>
