<template>
  <v-layout justify-center>
    <v-dialog v-model="dialog" persistent max-width="600" >
      <v-card>
        <v-card-title class="headline">
            <v-icon large class="mr-2" color="primary">info</v-icon>
            {{ title }}</v-card-title>
        <v-card-text class="mt-3 mb-2 title" v-if="message">{{ message }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="title" large flat color="primary" @click="reject">{{ $t('common.cancel') }}</v-btn>
          <v-btn class="title" large color="primary" depressed @click="confirm">{{ $t('common.confirm') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
export default {
  name: 'mobile-confirmation-dialog',
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    message: {
      type: String,
    },
  },
  methods: {
    confirm() {
      this.$emit('confirm');
    },
    reject() {
      this.$emit('reject');
    },
  },
};
</script>
