/* eslint no-param-reassign: "off" */
import { createIntegrationEventFromData } from '../../../components/shared/model/integration/integrationEvent';
import { ADMIN_AVINET_READ_RESPONSE } from './avinet';

// action
export const INTEGRATION_DO_EXTERNAL_CHANGE = 'INTEGRATION_DO_EXTERNAL_CHANGE';

// mutations
export const INTEGRATION_ON_EXTERNAL_CHANGE = 'INTEGRATION_ON_EXTERNAL_CHANGE';

// initial state
const storeState = {

};

// getters
const storeGetters = {

};

// actions
const storeActions = {
  [INTEGRATION_DO_EXTERNAL_CHANGE]: ({ commit }, change) => {
    const integrationResult = createIntegrationEventFromData(change);
    commit(INTEGRATION_ON_EXTERNAL_CHANGE, integrationResult);
    if (integrationResult && integrationResult.type === 'AVINET') {
      commit(ADMIN_AVINET_READ_RESPONSE, change.payload);
    }
  },
};

// mutations
const storeMutations = {
  [INTEGRATION_ON_EXTERNAL_CHANGE]: (change) => {
    console.log(`mutations external change ${change}`); // eslint-disable-line no-console
  },
};

export default {
  state: storeState,
  getters: storeGetters,
  actions: storeActions,
  mutations: storeMutations,
};
