<template>
  <div class="mobile-select-input" v-if="options && options.length > 0">
    <v-select :name="name" :id="name" :items="options" item-text="text" item-value="id" v-model="selectedValue"
      return-object :disabled="!editable" @input="emitValue($event)"
      :menu-props="{ contentClass: 'mobile-select-input', maxHeight: '600px', minWidth: '800px', maxWidth: '100%' }">
      <template v-slot:item="{ item }">
        <div class="mobile-select-input">
          <span>{{ item.text }}</span>
          <span v-if="item.subscript" class="subscript">{{ item.subscript }}</span>
          <span v-if="item.superscript" class="superscript">{{ item.superscript }}</span>
          <span v-if="item.icon">
            <v-icon>{{ item.icon }}</v-icon>
          </span>
          <span v-if="item.description && item.description !== '' && item.description !== item.text"
            class="description">{{ item.description }}</span>
        </div>
      </template>
      <template v-slot:selection="{ item }">
        <div class="mobile-select-input">
          <span>{{ item.text }}</span>
          <span v-if="item.subscript" class="subscript">{{ item.subscript }}</span>
          <span v-if="item.superscript" class="superscript">{{ item.superscript }}</span>
          <span v-if="item.icon">
            <v-icon>{{ item.icon }}</v-icon>
          </span>
        </div>
      </template>
    </v-select>
  </div>
</template>

<script>
export default {
  name: 'mobile-select-input',
  props: {
    name: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    optionValue: {
      type: [String, Number, Object],
    },
    editable: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedValue: this.optionValue,
    };
  },
  watch: {
    optionValue(newValue) {
      this.selectedValue = newValue;
    },
  },
  methods: {
    emitValue(newValue) {
      if (this.editable) {
        this.$emit('input', newValue);
      }
    },
  },
  created() {
    this.selectedValue = this.optionValue;
    // add an empty option at the beginning of the options
    if (this.clearable && this.options.length > 0) {
      this.options.unshift({ text: this.$t('common.empty'), id: null });
    }
  },
};
</script>

<style>
.mobile-select-input .v-select__selections {
  font-size: 24px !important;
  padding: 2px 10px;
}

.mobile-select-input .subscript {
  font-size: 0.7em;
  vertical-align: sub;
}

.mobile-select-input .superscript {
  font-size: 0.7em;
  vertical-align: super;
}

.mobile-select-input .v-select-list .v-list__tile {
  font-size: 24px;
  font-weight: 400;
  height: 68px;
  margin: 0;
  padding: 0 16px;
}

.mobile-select-input .description {
  color: grey;
  display: block;
  margin-top: -9px;
  margin-left: 10px;
}

.mobile-select-input i.v-icon.material-icons {
  font-size: 20px;
  vertical-align: super;
}

.mobile-select-input .v-select .v-select__selections {
  color: rgba(0, 0, 0, 0.87);
  background: #eee;
  min-width: 90px;
}
.theme--dark .mobile-select-input .v-select .v-select__selections {
  color: rgba(255, 255, 255, 0.87);
  background: #333333;
}


.mobile-select-input .v-select__selection--comma {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin: 0;
  width: 100%;
  padding: 0 10px;
  /* text-align: center; */
}
</style>
