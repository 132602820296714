<template>
  <div>
    <ag-grid-vue
      class="ag-theme-material connected-flights-table operational-table"
      :columnDefs="columnDefs"
      :defaultColDef="defaultColDef"
      :rowData="items"
      :rowSelection="'multiple'"
      :suppressRowClickSelection="true"
      :rowMultiSelectWithClick="false"
      @grid-ready="onGridReady"
      @first-data-rendered="firstDataRendered"
      @selection-changed="onSelectionChanged"
      :suppressHorizontalScroll="true"
      :frameworkComponents="frameworkComponents"
      :gridOptions="gridOptions"
      :multiSortKey="multiSortKey"
      :overlayNoRowsTemplate="customNoRowsOverlay"
      :overlayLoadingTemplate="customLoadingOverlay"
      v-bind:style="`width: 100%; height: calc(100vh - ${this.sizeFilter});`">
    </ag-grid-vue>
  </div>
</template>
<script>
import { AgGridVue } from 'ag-grid-vue';
import AgGridValueBaseCellRenderer from '../../../ag-grid/cell-renderers/AgGridValueBaseCellRenderer.vue';
import AgGridConnectedFlights from '../../../ag-grid/mixins/AgGridConnectedFlights.vue';
import FlightsDifferences from '../../../mixins/FlightsDifferences.vue';

import { FlightBound } from '../../../shared/model/flightBound';
import { cellClassAgGridRules } from '../../../ag-grid/mixins/cellClassAgGridRules';
import { WEBSOCKET_CONNECTION } from '../../../../store/modules/websocket';
import { FisModule } from '../../../ag-grid/cell-editors/model/fisModule';
import agGridTextCustomFilterComparator from '../../../ag-grid/filters/agGridTextCustomFilterComparator';
import agGridTextCustomFilterFormatter from '../../../ag-grid/filters/agGridTextCustomFilterFormatter';
import AgGridFlightDeliveryBaggageStringCellEditor
from '../../../ag-grid/cell-editors/flightDeliveryBaggage/AgGridFlightDeliveryBaggageStringCellEditor.vue';
import {
  CHANGE_DELIVERY_SELECTION,
  DELIVERY_FIT_COLUMNS_TO_VIEW,
  DELIVERY_ON_EXTERNAL_CHANGE,
  DELIVERY_SET_GRID_API,
} from '../../../../store/modules/lostAndFound/delivery';
import AgGridFlightDeliveryBaggageFlightNumberCellEditor
from '../../../ag-grid/cell-editors/flightDeliveryBaggage/AgGridFlightDeliveryBaggageFlightNumberCellEditor.vue';
import AgGridFlightDeliveryBaggageDateCellEditor
from '../../../ag-grid/cell-editors/flightDeliveryBaggage/AgGridFlightDeliveryBaggageDateCellEditor.vue';
import AgGridRowActionDeleteButtonCellRenderer
from '../../../ag-grid/cell-renderers/AgGridRowActionDeleteButtonCellRenderer.vue';
import AgGridIconCellRenderer from '../../../ag-grid/cell-renderers/AgGridIconCellRenderer.vue';
import AgGridCustomService from '../../../shared/services/agGridCustomService';
import AgGridCurrencyCellRenderer
from '../../../ag-grid/cell-renderers/AgGridCurrencyCellRenderer.vue';
import AgGridFlightDeliveryBaggageCurrencyCellEditor
from '../../../ag-grid/cell-editors/flightDeliveryBaggage/AgGridFlightDeliveryBaggageCurrencyCellEditor.vue';
import AgGridFlightDeliveryBaggageCurrencySelectCellEditor
from '../../../ag-grid/cell-editors/flightDeliveryBaggage/AgGridFlightDeliveryBaggageCurrencySelectCellEditor.vue';
import AgGridFlightDeliveryBaggageNumberCellEditor
from '../../../ag-grid/cell-editors/flightDeliveryBaggage/AgGridFlightDeliveryBaggageNumberCellEditor.vue';
import AgGridFlightDeliveryBaggageFSSelectCellEditor
from '../../../ag-grid/cell-editors/flightDeliveryBaggage/AgGridFlightDeliveryBaggageFSSelectCellEditor.vue';
import RolePermissions from '../../../mixins/RolePermissions.vue';
import AgGridNoRowsOverlay from '../../../ag-grid/mixins/AgGridNoRowsOverlay.vue';

export default {
  name: 'lost-and-found-flights-deliveries-list-table',
  mixins: [AgGridConnectedFlights, FlightsDifferences, RolePermissions, AgGridNoRowsOverlay],
  components: {
    AgGridVue,
  },
  props: {
    items: { type: Array, required: true },
    isFilterDisplayed: { type: Boolean, required: false, default: false },
  },
  mounted() {
    this.$store.dispatch(WEBSOCKET_CONNECTION, this.$store.state.tenant.currentTenant.name.toLowerCase());
  },
  computed: {
    sizeFilter() {
      return this.isFilterDisplayed ? '250px' : '180px';
    },
    fitColumnsOnResize() {
      return this.$store.state.delivery.fitColumnsOnResize;
    },
  },
  data() {
    const that = this;
    const rolePermission = this.getRoles();
    return {
      dialog: false,
      gridOptions: {
        onFilterChanged: this.uncheckAllOnFilter,
        getRowNodeId: this.getRowNodeId,
        getRowClass: this.getRowClass,
        floatingFilter: true,
        groupHeaderHeight: 35,
        headerHeight: 35,
        floatingFiltersHeight: 20,
        rowHeight: 30,
        context: {
          module: FisModule.LOST_AND_FOUND,
        },
      },
      multiSortKey: 'ctrl',
      frameworkComponents: {
        AgGridValueBaseCellRenderer,
        AgGridFlightDeliveryBaggageStringCellEditor,
        AgGridRowActionDeleteButtonCellRenderer,
        AgGridFlightDeliveryBaggageFlightNumberCellEditor,
        AgGridFlightDeliveryBaggageDateCellEditor,
        AgGridFlightDeliveryBaggageNumberCellEditor,
        AgGridCurrencyCellRenderer,
        AgGridFlightDeliveryBaggageCurrencyCellEditor,
        AgGridFlightDeliveryBaggageCurrencySelectCellEditor,
        AgGridFlightDeliveryBaggageFSSelectCellEditor,
      },
      defaultColDef: {
        resizable: true,
        sortable: true,
        minWidth: 30,
        suppressMenu: true,
        filter: 'agTextColumnFilter',
        filterParams: {
          textCustomComparator: agGridTextCustomFilterComparator,
          textFormatter: agGridTextCustomFilterFormatter,
        },
        colCustomConfig: {
          flightBound: FlightBound.ANY,
        },
        cellClassRules: cellClassAgGridRules,
        editable: false,
      },
      columnDefs: [
        {
          headerName: '',
          children: [
            {
              headerTooltip: 'Check/uncheck all',
              hide: !this.hasPermissions(rolePermission),
              checkboxSelection: true,
              width: 21,
              minWidth: 20,
              headerCheckboxSelection: true,
              headerCheckboxSelectionFilteredOnly: true,
              suppressMenu: true,
              sortable: false,
              suppressMovable: true,
              suppressSizeToFit: true,
              filter: false,
              resizable: false,
              colCustomConfig: {
                exportCsvDisabled: true,
              },
              editable: this.editable(true, rolePermission),
            },
            {
              headerName: 'SortDate',
              field: 'requestTs',
              hide: true,
              sort: 'asc',
            },
          ],
        },
        {
          headerValueGetter() {
            return that.$i18n.t('commonForm.flightInformation');
          },
          children: [
            {
              colId: 'flight.flightId',
              headerName: '',
              width: 30,
              field: 'flightId',
              cellClass: 'flight-number-cell separator',
              valueGetter(params) {
                return !params.data.flightId;
              },
              cellRendererFramework: AgGridIconCellRenderer,
              cellRendererParams: {
                icon: 'explicit',
                tooltip: params => (params.data.flightId ? 'Internal' : 'External'),
              },
            },
            {
              colId: 'flight.flightTripNumber',
              headerValueGetter() {
                return that.$i18n.t('common.flight');
              },
              editable: this.editable(true, rolePermission),
              cellEditor: 'AgGridFlightDeliveryBaggageFlightNumberCellEditor',
              cellClass: 'flight-number-cell separator',
              valueGetter(params) {
                return params.data.flightNo();
              },
              cellRendererFramework: AgGridValueBaseCellRenderer,
            },
            {
              colId: 'flight.flightDate',
              headerValueGetter() {
                return that.$i18n.t('common.date');
              },
              field: 'flightDate',
              cellClass: 'date-cell separator',
              valueFormatter(params) {
                return that.$moment(params.value).format('DD/MM/YYYY');
              },
            },
            {
              colId: 'flight.airlineCode',
              headerValueGetter() {
                return that.$i18n.t('deliveries.airlineCode');
              },
              field: 'airlineCode',
              cellClass: 'airline-from-cell',
              cellRendererFramework: AgGridValueBaseCellRenderer,
            },
          ],
        },
        {
          headerValueGetter() {
            return that.$i18n.t('lostAndFound.deliveryInformation');
          },
          children: [
            {
              colId: 'delivery.deliveryDate',
              headerValueGetter() {
                return that.$i18n.t('common.date');
              },
              field: 'date',
              editable: this.editable(true, rolePermission),
              cellEditor: 'AgGridFlightDeliveryBaggageDateCellEditor',
              cellClass: 'delivery-date-cell separator',
              valueFormatter(params) {
                return that.$moment(params.data.date).format('DD/MM/YYYY');
              },
            },
            {
              colId: 'delivery.fileNumber',
              headerValueGetter() {
                return that.$i18n.t('lostAndFound.fileNumber');
              },
              field: 'fileNumber',
              editable: this.editable(true, rolePermission),
              cellEditor: 'AgGridFlightDeliveryBaggageStringCellEditor',
              cellClass: 'file-number-cell separator',
              cellRendererFramework: AgGridValueBaseCellRenderer,
            },
            {
              colId: 'delivery.numberBag',
              headerValueGetter() {
                return that.$i18n.t('lostAndFound.numberOfBags');
              },
              field: 'numberBag',
              cellEditor: 'AgGridFlightDeliveryBaggageNumberCellEditor',
              editable: this.editable(true, rolePermission),
              cellClass: 'number-bag-cell',
              cellRendererFramework: AgGridValueBaseCellRenderer,
            },
            {
              colId: 'delivery.loadBag',
              headerValueGetter() {
                return that.$i18n.t('deliveries.kgs');
              },
              field: 'loadBag',
              editable: this.editable(true, rolePermission),
              cellEditor: 'AgGridFlightDeliveryBaggageNumberCellEditor',
              cellClass: 'load-bag-cell',
              cellRendererFramework: AgGridValueBaseCellRenderer,
            },
            {
              colId: 'delivery.type',
              headerValueGetter() {
                return that.$i18n.t('common.type');
              },
              field: 'type',
              editable: this.editable(true, rolePermission),
              cellEditor: 'AgGridFlightDeliveryBaggageStringCellEditor',
              cellClass: 'type-cell',
              cellRendererFramework: AgGridValueBaseCellRenderer,
            },
            {
              colId: 'delivery.passengerName',
              headerValueGetter() {
                return that.$i18n.t('deliveries.passengersNameFileReference');
              },
              field: 'passengerName',
              width: 350,
              editable: this.editable(true, rolePermission),
              cellEditor: 'AgGridFlightDeliveryBaggageStringCellEditor',
              cellClass: 'passenger-name-cell',
              cellRendererFramework: AgGridValueBaseCellRenderer,
            },
            {
              colId: 'delivery.address',
              headerValueGetter() {
                return that.$i18n.t('deliveries.address');
              },
              width: 450,
              field: 'address',
              editable: this.editable(true, rolePermission),
              cellEditor: 'AgGridFlightDeliveryBaggageStringCellEditor',
              cellClass: 'address-cell',
              cellRendererFramework: AgGridValueBaseCellRenderer,
            },
            {
              colId: 'delivery.city',
              headerValueGetter() {
                return that.$i18n.t('deliveries.city');
              },
              field: 'city',
              editable: this.editable(true, rolePermission),
              cellEditor: 'AgGridFlightDeliveryBaggageStringCellEditor',
              cellClass: 'city-cell separator',
              cellRendererFramework: AgGridValueBaseCellRenderer,
            },
            {
              colId: 'delivery.postalCode',
              headerValueGetter() {
                return that.$i18n.t('deliveries.postalCode');
              },
              field: 'postalCode',
              editable: this.editable(true, rolePermission),
              cellEditor: 'AgGridFlightDeliveryBaggageStringCellEditor',
              cellClass: 'postal-code-cell',
              cellRendererFramework: AgGridValueBaseCellRenderer,
            },
            {
              colId: 'delivery.fs',
              headerValueGetter() {
                return that.$i18n.t('deliveries.fs');
              },
              field: 'fsIataCode',
              editable: this.editable(true, rolePermission),
              cellEditor: 'AgGridFlightDeliveryBaggageFSSelectCellEditor',
              cellClass: 'load-bag-cell',
              cellRendererFramework: AgGridValueBaseCellRenderer,
            },
            {
              colId: 'delivery.ir',
              headerValueGetter() {
                return that.$i18n.t('deliveries.ir');
              },
              field: 'ir',
              editable: this.editable(true, rolePermission),
              cellEditor: 'AgGridFlightDeliveryBaggageNumberCellEditor',
              colCustomConfig: {
                rules: [
                  value => (!value || (value && value.length <= 2)) || that.$i18n.t('validation.twoDigits'),
                ],
              },
              cellClass: 'type-cell',
              cellRendererFramework: AgGridValueBaseCellRenderer,
            },
            {
              colId: 'delivery.pnr',
              headerValueGetter() {
                return that.$i18n.t('deliveries.pnr');
              },
              field: 'pnr',
              width: 350,
              editable: this.editable(true, rolePermission),
              cellEditor: 'AgGridFlightDeliveryBaggageStringCellEditor',
              cellClass: 'passenger-name-cell',
              cellRendererFramework: AgGridValueBaseCellRenderer,
            },
            {
              colId: 'delivery.shippingCost',
              field: 'shippingCost',
              headerValueGetter() {
                return that.$i18n.t('deliveries.cost');
              },
              editable: this.editable(true, rolePermission),
              valueGetter(params) {
                return {
                  value: params.data.shippingCost,
                  currency: params.data.currency,
                };
              },
              cellRendererFramework: AgGridCurrencyCellRenderer,
              cellEditor: 'AgGridFlightDeliveryBaggageCurrencyCellEditor',
              cellClass: 'currency-cell',
            },
            {
              colId: 'delivery.currency',
              field: 'currency',
              headerValueGetter() {
                return that.$i18n.t('contingencies.currency');
              },
              editable: this.editable(true, rolePermission),
              cellEditor: 'AgGridFlightDeliveryBaggageCurrencySelectCellEditor',
            },
            {
              colId: 'delivery.bdo',
              headerValueGetter() {
                return that.$i18n.t('deliveries.bdo');
              },
              field: 'bdo',
              editable: this.editable(true, rolePermission),
              cellEditor: 'AgGridFlightDeliveryBaggageNumberCellEditor',
              cellClass: 'city-cell separator',
              cellRendererFramework: AgGridValueBaseCellRenderer,
            },
            {
              colId: 'delivery.provider',
              headerValueGetter() {
                return that.$i18n.t('deliveries.provider');
              },
              field: 'provider',
              editable: this.editable(true, rolePermission),
              cellEditor: 'AgGridFlightDeliveryBaggageStringCellEditor',
              cellClass: 'postal-code-cell',
              cellRendererFramework: AgGridValueBaseCellRenderer,
            },
            {
              colId: 'delivery.remarks',
              headerValueGetter() {
                return that.$i18n.t('deliveries.remarks');
              },
              field: 'remarks',
              width: 250,
              editable: this.editable(true, rolePermission),
              cellEditor: 'AgGridFlightDeliveryBaggageStringCellEditor',
              cellClass: 'remarks-cell separator',
              cellRendererFramework: AgGridValueBaseCellRenderer,
            },
          ],
        },
        {
          headerName: '',
          hide: !this.hasPermissions(rolePermission),
          width: 35,
          cellClass: 'text-center',
          field: 'id',
          suppressMenu: true,
          sortable: false,
          suppressMovable: true,
          suppressSizeToFit: true,
          filter: false,
          resizable: false,
          suppressNavigable: true,
          colCustomConfig: {
            exportCsvDisabled: true,
          },
          cellRendererFramework: AgGridRowActionDeleteButtonCellRenderer,
          cellRendererParams: {
            deleteItem: this.deleteItem,
          },
        },
      ],
    };
  },
  methods: {
    onGridReady(params) {
      this.setAgGridApi(params);
      this.showLoading();
      this.$store.commit(DELIVERY_SET_GRID_API, params); // TODO eliminar api del store

      this.refreshCellsOnMutation();
      this.refreshCellsOnChangeLanguage();
      const that = this;
      this.subscribeMutation(DELIVERY_ON_EXTERNAL_CHANGE, (mutationPayload) => {
        that.onGetChanges(mutationPayload);
      });
    },
    resized() {
      if (this.gridApi) {
        this.gridApi.sizeColumnsToFit();
      }
    },
    onSelectionChanged(event) {
      const selectedNodes = event.api.getSelectedNodes();
      const selectedData = selectedNodes.map(node => node.data);
      this.$store.commit(CHANGE_DELIVERY_SELECTION, selectedData);
    },
    deleteItem(item) {
      this.$emit('delete-item', item);
    },
    editItem(item) {
      console.log(`edit ${item}`); // eslint-disable-line no-console
    },
    isInbound(params) {
      return params.data.hasInbound();
    },
    isOutbound(params) {
      return params.data.hasOutbound();
    },
    checkBoundAndSetMsgTooltipObject(messageValue) {
      if (messageValue === null || typeof (messageValue) === 'undefined') {
        return null;
      }
      if (messageValue === 'WARNING_LDM') {
        return {
          value: messageValue,
          icon: 'warning',
          color: 'red',
          tooltip: this.$t('messages.warningMessageLDM'),
        };
      }

      return {
        value: messageValue,
        icon: messageValue !== 'EMPTY' ? 'sms' : '',
        color: messageValue === 'UNREAD' ? 'success' : 'bggreydark',
        tooltip: messageValue === 'UNREAD' ? this.$t('messages.messagesUnread') : this.$t('messages.messagesRead'),
      };
    },
    renderNeededDiffs(differences, rowNode) {
      const { gridApi } = this.$store.state.delivery;

      const agGridCustomService = new AgGridCustomService(this.columnDefs); // TODO one time
      const colIds = agGridCustomService.getColIdByMatchAnyfieldsRelated(this.joinAllPropertyPath(differences));

      if (colIds && colIds.length > 0) {
        colIds.forEach((colId) => {
          if (gridApi.getColumnDef(colId)) {
            gridApi.refreshCells({
              rowNodes: [rowNode],
              columns: [colId],
              force: true,
            });

            gridApi.flashCells({
              rowNodes: [rowNode],
              columns: [colId],
              force: true,
            });
          }
        });
      }
    },
    sizeColumnsToFit() {
      if (this.gridApi) {
        this.$store.dispatch(DELIVERY_FIT_COLUMNS_TO_VIEW);
      }
    },
    firstDataRendered() {
      this.$nextTick(() => {
        this.resized();
      });
    },
  },
};

</script>
