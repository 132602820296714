export default class FlightPassageOutboundInformation {
  constructor(flightId, cabin0A, cabin0B, cabin0C, cabin0D, cabin0E, cabin0F, comail, jumpseats, lastMinuteChangesPax, lastMinuteChangesInf, lastMinuteChangesBag, avih, umsOut, stcr, totalPriority, localPaxCheckInCounters, localPaxCheckInWeb, localPaxCheckInKiosks, connectionPax, remarks, flightPassengers, totalTransits, totalCheckInPax, totalPaxOnBoard) {
    this.flightId = flightId;
    this.cabin0A = cabin0A;
    this.cabin0B = cabin0B;
    this.cabin0C = cabin0C;
    this.cabin0D = cabin0D;
    this.cabin0E = cabin0E;
    this.cabin0F = cabin0F;
    this.comail = comail;
    this.jumpseats = jumpseats;
    this.lastMinuteChangesPax = lastMinuteChangesPax;
    this.lastMinuteChangesInf = lastMinuteChangesInf;
    this.lastMinuteChangesBag = lastMinuteChangesBag;
    this.avih = avih;
    this.stcr = stcr;
    this.totalPriority = totalPriority;
    this.localPaxCheckInCounters = localPaxCheckInCounters;
    this.localPaxCheckInWeb = localPaxCheckInWeb;
    this.localPaxCheckInKiosks = localPaxCheckInKiosks;
    this.connectionPax = connectionPax;
    this.remarks = remarks;
    this.flightPassengers = flightPassengers;
    this.totalTransits = totalTransits;
    this.totalCheckInPax = totalCheckInPax;
    this.totalPaxOnBoard = totalPaxOnBoard;
  }

  static createFlightPassageOutboundInformation(flightId) {
    return new FlightPassageOutboundInformation(flightId);
  }
}
