<template>
  <v-form ref="form" v-model="valid">
    <v-container>
      <v-layout row>
        <v-flex xs4 class="date-input">
          <v-flex xs8 class="date-input">
            <date-input :ref="'refComp'" v-on:keydown.native="keyEvent1" v-model="value.aircraftGroundDate" :rules="[rules.required]" :locale="$i18n.locale" :label="this.$i18n.t('common.date')"/>
          </v-flex>
        </v-flex>

        <v-flex xs6>
          <aircraft-select v-char-length.clearable="7" v-model="value.aircraftId" :label="this.$i18n.t('common.aircraftRegistration')" @change="onChangeAircraft" :rules="[rules.required]" />
        </v-flex>

        <v-flex xs6>
          <aircraft-type-select v-char-length.clearable="7" v-model="value.aircraftTypeId" :label="this.$i18n.t('common.type')" @change="onChangeAircraftType" :rules="[rules.required]" :disabled="true"/>
        </v-flex>

        <v-flex xs6>
          <airline-select v-char-length.clearable="7" v-model="value.airlineId" :label="this.$i18n.t('common.airline')" :rules="[rules.required]" />
        </v-flex>
      </v-layout>
    </v-container>
  </v-form>
</template>

<script>


import DateInput from '../ui/DateInput.vue';
import AircraftSelect from '../aircraft/AircraftSelect.vue';
import AircraftTypeSelect from '../aircraft/AircraftTypeSelect.vue';
import AirlineSelect from '../airline/AirlineSelect.vue';
import AircraftGround from '../operations/model/aircraftGround';

export default {
  name: 'aircraft-ground-form-container',
  components: {
    DateInput,
    AircraftSelect,
    AircraftTypeSelect,
    AirlineSelect,
  },
  props: {
    value: { type: AircraftGround, required: true },
  },
  data: () => ({
    valid: false,
    rules: {
      required: value => !!value || 'Required.',
    },
  }),
  computed: {

  },
  watch: {
    valid: {
      handler(changeValid) {
        this.$emit('form-update', changeValid);
      },
      deep: true,
    },
  },
  methods: {
    onChangeAircraft(aircraft) {
      if (aircraft) {
        this.value.aircraftTypeId = aircraft.typeId;
      }
    },
    onChangeAircraftType(aircraft) {
      if (aircraft) {
        this.value.aircraftId = null;
        this.value.aircraftRegisterNumber = null;
      }
    },
  },
  created() {

  },
};
</script>
<style scoped>
  .date-input {
    display: inline-block !important;
    margin: auto 0;
  }
  .date-input .v-btn {
    min-width: 44px;
  }
</style>
