<template>
  <v-container v-if="!isEmptyDelays()" class="infoMobileDelays">
      <v-card :dark="this.$vuetify.dark">
        <v-card-text>
          <div class="font-weight-bold ms-2 mb-2">
            {{ $t('operations.flightDelay') }}
          </div>
          <v-timeline align="start" dense>
            <v-timeline-item v-for="(delay) in flightMobileDelays" :key="delay.id"
              :color="delay.responsibleType === 'HANDLING' ? 'red' : 'orange'" small>
              <div class="mb-0">
                <div class="font-weight-normal">
                  <strong> {{ delay.typeCode }} - {{ delay.typeDescription }}</strong>
                </div>
                <div>{{ formattedTime(delay.amount) }}{{ delay.remarks ? ' - '+delay.remarks : '' }}</div>
              </div>
            </v-timeline-item>
          </v-timeline>
        </v-card-text>
      </v-card>
  </v-container>
</template>

<script>

import FlightStatus from '../flights/FlightStatus.vue';
import { MOBILE_GET_FLIGHT_DELAYS } from '../../store/modules/mobile';

export default {
  name: 'info-mobile-delays',
  props: {
    flightId: {
      required: true,
    },
  },
  components: {
    FlightStatus,
  },
  methods: {
    formattedTime(minutes) {
      if (minutes) {
        const time = new Date(minutes * 60000);
        return this.$moment(time).format('HH:mm');
      }
      return '';
    },
    isEmptyDelays() {
      return this.flightMobileDelays.length === 0;
    },
  },
  computed: {
    flightMobileDelays() {
      return this.$store.state.mobile.flightMobileDelays;
    },
  },
  watch: {
    flightId() {
      if (this.flightId) {
        this.$store.dispatch(MOBILE_GET_FLIGHT_DELAYS, this.flightId);
      }
    },
  },
  created() {
    if (this.flightId) {
      this.$store.dispatch(MOBILE_GET_FLIGHT_DELAYS, this.flightId);
    }
  },
};
</script>
<style>
.infoMobileDelays {
  font-size: 16px;
}
</style>
