/* eslint no-param-reassign: "off" */
/* eslint no-nested-ternary: "off" */
/* eslint no-confusing-arrow: "off" */

import { HTTP } from '../../../http';
import { REQUEST_ERROR, REQUEST_START, REQUEST_SUCCESS } from '../../store';
import { NOTIFICATION_SHOW } from '../notifications';
import i18n from '../../../plugins/i18n';

// actions names
export const GET_AIRLINE = 'GET_AIRLINE';
export const AIRLINE_DELETE = 'AIRLINE_DELETE';
export const AIRLINE_CREATE = 'AIRLINE_CREATE';
export const AIRLINE_UPDATE = 'AIRLINE_UPDATE';
export const AIRLINE_DISABLE = 'AIRLINE_DISABLE';
export const AIRLINE_ENABLE = 'AIRLINE_ENABLE';
export const GET_AIRLINE_FILTERED_BY_HANDLING_AGENT = 'GET_AIRLINE_FILTERED_BY_HANDLING_AGENT';


// mutations
export const CHANGE_AIRLINE_SELECTION = 'CHANGE_AIRLINE_SELECTION';
export const GET_AIRLINE_SUCCESS = 'GET_AIRLINE_SUCCESS';
export const GET_AIRLINE_REQUESTED = 'GET_AIRLINE_REQUESTED';
export const UPDATE_AIRLINE_SUCCESS = 'UPDATE_AIRLINE_SUCCESS';
export const GET_AIRLINE_FILTERED_BY_HANDLING_AGENT_SUCCESS = 'GET_AIRLINE_FILTERED_BY_HANDLING_AGENT_SUCCESS';
export const GET_AIRLINE_FILTERED_BY_HANDLING_AGENT_REQUESTED = 'GET_AIRLINE_FILTERED_BY_HANDLING_AGENT_REQUESTED';

// initial state
const storeState = {
  airline: [],
  airlinesFilteredByHandlingAgent: [],
  airlineRequested: false,
  airlineFilteredByHandlingAgentRequested: false,
  selectedAirline: [],
};

// getters
const storeGetters = {
  getAirlines: state => state.airline.sort((a, b) => (a.code > b.code) ? 1 : ((b.code > a.code) ? -1 : 0)),
  getActiveAirlines: state => state.airline.filter(elem => !elem.deleted),
  getActiveAirlinesFilteredByHandlingAgent: state => state.airlinesFilteredByHandlingAgent.filter(elem => !elem.deleted),
  getAirlineById: state => id => state.airline.find(a => a.id === id),
  getAirlineSelected: state => state.selectedAirline,
  hasSelectedAirline: state => state.selectedAirline.length > 0,
};

// actions
const storeActions = {
  [GET_AIRLINE]: ({ commit, state }) => {
    if (state.airlineRequested) {
      return;
    }
    commit(REQUEST_START);
    commit(GET_AIRLINE_REQUESTED);
    HTTP.get('airlines')
      .then((resp) => {
        commit(REQUEST_SUCCESS);
        commit(GET_AIRLINE_SUCCESS, resp.data);
      })
      .catch((err) => {
        commit(REQUEST_ERROR, err);
      });
  },
  [GET_AIRLINE_FILTERED_BY_HANDLING_AGENT]: ({ commit, state }) => {
    if (state.airlineFilteredByHandlingAgentRequested) {
      return;
    }
    commit(REQUEST_START);
    commit(GET_AIRLINE_FILTERED_BY_HANDLING_AGENT_REQUESTED);
    const params = {
      handlingAgentActive: true,
    };
    HTTP.post('airlines/find', params)
      .then((resp) => {
        commit(REQUEST_SUCCESS);
        commit(GET_AIRLINE_FILTERED_BY_HANDLING_AGENT_SUCCESS, resp.data);
      })
      .catch((err) => {
        commit(REQUEST_ERROR, err);
      });
  },

  [AIRLINE_CREATE]: ({ commit, dispatch }, airline) => {
    commit(REQUEST_START);
    return HTTP.post('airlines', airline)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(UPDATE_AIRLINE_SUCCESS);
        dispatch(GET_AIRLINE);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.airlineCreate'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },

  [AIRLINE_UPDATE]: ({ commit, dispatch }, airline) => {
    commit(REQUEST_START);
    return HTTP.put('airlines', airline)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(UPDATE_AIRLINE_SUCCESS);
        dispatch(GET_AIRLINE);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.airlineUpdate'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
  [AIRLINE_DELETE]: ({ commit, dispatch }, id) => {
    commit(REQUEST_START);
    return HTTP.delete(`airlines/${id}`)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(UPDATE_AIRLINE_SUCCESS);
        dispatch(GET_AIRLINE);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.airlineDelete'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
  [AIRLINE_DISABLE]: ({ commit, dispatch }, id) => {
    commit(REQUEST_START);
    return HTTP.patch(`/airlines/disable/${id}`)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(UPDATE_AIRLINE_SUCCESS);
        dispatch(GET_AIRLINE);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.airlineDisable'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
  [AIRLINE_ENABLE]: ({ commit, dispatch }, id) => {
    commit(REQUEST_START);
    return HTTP.patch(`/airlines/enable/${id}`)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(UPDATE_AIRLINE_SUCCESS);
        dispatch(GET_AIRLINE);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.airlineEnable'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
};

// mutations
const storeMutations = {
  [GET_AIRLINE_SUCCESS]: (state, airline) => {
    state.airline = airline;
  },
  [GET_AIRLINE_REQUESTED]: (state) => {
    state.airlineRequested = true;
  },
  [GET_AIRLINE_FILTERED_BY_HANDLING_AGENT_SUCCESS]: (state, airline) => {
    state.airlinesFilteredByHandlingAgent = airline;
  },
  [GET_AIRLINE_FILTERED_BY_HANDLING_AGENT_REQUESTED]: (state) => {
    state.airlineFilteredByHandlingAgentRequested = true;
  },
  [UPDATE_AIRLINE_SUCCESS]: (state) => {
    state.airlineRequested = false;
  },
  [CHANGE_AIRLINE_SELECTION]: (state, selection) => {
    state.selectedAirline = selection;
  },
};

export default {
  state: storeState,
  getters: storeGetters,
  actions: storeActions,
  mutations: storeMutations,
};
