<template>
    <div>
    <v-autocomplete
      placeholder="..."
      :value="value"
      :label="label"
      :items="items"
      :filter="customFilter"
      :clearable="!readonly"
      item-text="name"
      item-value="id"
      :rules="rules"
      :disabled="disabled"
      @input="emitInput"
      :ref="refComp"
      :readonly="readonly"
    >
      <template
        slot="item"
        slot-scope="{ item, tile }"
      >
        <v-list-tile-content>
          <v-list-tile-title v-text="item.name"></v-list-tile-title>
          <v-list-tile-sub-title v-text="item.description"></v-list-tile-sub-title>
        </v-list-tile-content>
      </template>
    </v-autocomplete>
    </div>
</template>

<script>
import { GET_SERVICE_AGENTS, GET_AIRPORT_SERVICE_AGENTS_CANDIDATES } from '../../store/modules/serviceAgent/serviceAgent';

export default {
  name: 'service-agent-select',
  props: {
    value: { required: true },
    label: { type: String },
    rules: { type: Array },
    disabled: { type: Boolean },
    readonly: { type: Boolean },
    refComp: {
      type: String,
      required: false,
      default: 'refComp',
    },
    showJustCandidates: {
      required: false,
      type: Boolean,
      default: false,
    },
    airportServiceAgentId: {
      required: false,
      type: Number,
    },
  },
  computed: {
    items: {
      get() {
        if (this.showJustCandidates) {
          return this.$store.getters.getAirportServiceAgentCandidates;
        }
        return this.$store.getters.getActiveServiceAgents;
      },
    },
  },
  methods: {
    getServiceAgents() {
      if (this.showJustCandidates) {
        this.$store.dispatch(GET_AIRPORT_SERVICE_AGENTS_CANDIDATES, this.airportServiceAgentId);
      } else {
        this.$store.dispatch(GET_SERVICE_AGENTS);
      }
    },
    customFilter(item, queryText) {
      const textOne = item.name ? item.name.toLowerCase() : '';
      const textTwo = item.description ? item.description.toLowerCase() : '';
      const searchText = queryText.toLowerCase();

      return textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1;
    },
    emitInput($event) {
      // FIXME así no rompe al limpiar el select pero hay que validar que no se pueda guardar el tipo de mensaje como NULL
      if (typeof $event !== 'undefined') {
        this.$emit('input', $event);
      } else {
        this.$emit('input', null);
      }
    },
  },
  mounted() {
    this.getServiceAgents();
  },
};
</script>

<style lang="scss" scoped>

</style>
