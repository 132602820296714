
export default class RampServiceImportOptions {
  constructor(timeZoneMode, fromDate, toDate, linesToPreview) {
    this.timeZoneMode = timeZoneMode || 'LOCAL';
    this.fromDate = fromDate || null;
    this.toDate = toDate || null;
    this.linesToPreview = linesToPreview || 10;
  }

  isValidOptionsFilter() {
    return !!this.fromDate && !!this.toDate;
  }
}
