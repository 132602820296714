import { JSUtils } from '../../shared/js/JSUtils';

export default class FlightPassageCheckInInformation {
  constructor(
    id,
    checkInCounterFirstId,
    checkInCounterFirstCode,
    checkInCounterLastId,
    checkInCounterLastCode,
  ) {
    this.id = id;
    this.checkInCounterFirstId = checkInCounterFirstId;
    this.checkInCounterFirstCode = checkInCounterFirstCode;
    this.checkInCounterLastId = checkInCounterLastId;
    this.checkInCounterLastCode = checkInCounterLastCode;
  }

  ckiCounters() {
    let val = '';
    if (this.checkInCounterFirstCode) {
      val += this.checkInCounterFirstCode;
    }
    val += '-';
    if (this.checkInCounterLastCode) {
      val += this.checkInCounterLastCode;
    }
    return val;
  }

  static get createEmptyFlightPassageCheckInInformation() {
    return new FlightPassageCheckInInformation();
  }

  static createFlightPassageCheckInInformationFromData(o) {
    if (!o) {
      return this.createEmptyFlightPassageCheckInInformation;
    }

    return JSUtils.cast(o, FlightPassageCheckInInformation);
  }
}
