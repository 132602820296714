<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div v-if="tenants">
    <entity-configuration-list
      :entity-name="this.$i18n.t('configuration.handlingAgent')"
      entity-icon="person"
      :framework-components="frameworkComponents" :column-defs="columnDefs" :items="handlingAgents"
      @confirm-edit-item="editConfirm"
      @confirm-delete-item="deleteConfirm"
      @confirm-enable-disable-item="enableDisableConfirm"
    >
      <template v-slot:modal-content="{ itemEdit }">
        <handling-agent-edit-card-content :item="itemEdit" />
      </template>
    </entity-configuration-list>
  </div>
</template>
<script>
import EntityConfigurationList from '../common/EntityConfigurationList.vue';
import HandlingAgentEditCardContent from './HandlingAgentEditCardContent.vue';
import { TENANT_LIST } from '../../../store/modules/tenant';
import AgGridIconCellRenderer from '../../ag-grid/cell-renderers/AgGridIconCellRenderer.vue';
import AgGridTenantsChipsCellRenderer from '../../ag-grid/cell-renderers/AgGridTenantsChipsCellRenderer.vue';
import AgGridAirlinesChipsCellRenderer from '../../ag-grid/cell-renderers/AgGridAirlinesChipsCellRenderer.vue';
import {
  GET_HANDLING_AGENTS,
  HANDLING_AGENT_CREATE,
  HANDLING_AGENT_DELETE,
  HANDLING_AGENT_DISABLE,
  HANDLING_AGENT_ENABLE,
  HANDLING_AGENT_UPDATE,
} from '../../../store/modules/handlingAgent/handlingAgent';


export default {
  name: 'handling-agent-list',
  components: {
    EntityConfigurationList,
    HandlingAgentEditCardContent,
  },
  data() {
    const that = this;
    return {
      frameworkComponents: {
        AgGridIconCellRenderer,
        AgGridTenantsChipsCellRenderer,
        AgGridAirlinesChipsCellRenderer,
      },
      columnDefs: [
        {
          field: 'name',
          width: 55,
          headerValueGetter() {
            return that.$i18n.t('configuration.name');
          },
          sort: 'asc',
        },
        {
          field: 'description',
          width: 75,
          headerValueGetter() {
            return that.$i18n.t('configuration.description');
          },
        },
        {
          field: 'tenantAirlines',
          width: 90,
          headerValueGetter() {
            return that.$i18n.t('configuration.airports');
          },
          valueGetter(params) {
            const tenants = Array.from(new Map(Object.entries(params.data.tenantAirlines)).keys());
            if (params.data.airlines.length > 0) {
              tenants.unshift('GLOBAL');
            }
            return tenants;
          },
          cellRendererFramework: AgGridTenantsChipsCellRenderer,
          cellRendererParams: {
            tenants: () => this.tenants,
          },
        },
        {
          field: 'tenantAirlines',
          width: 250,
          headerValueGetter() {
            return that.$i18n.t('configuration.airlines');
          },
          valueGetter(params) {
            const tenantAirlines = Array.from(new Map(Object.entries(params.data.tenantAirlines)).values());

            const airlines = new Set();

            tenantAirlines.forEach(tenants => tenants.forEach(a => airlines.add(a.code)));


            if (params.data.airlines.length > 0) {
              params.data.airlines.forEach(a => airlines.add(a.code));
            }
            if (airlines.size === 0) {
              airlines.add('ALL');
            }
            return airlines;
          },
          cellRendererFramework: AgGridAirlinesChipsCellRenderer,
        },
        {
          headerValueGetter() {
            return that.$i18n.t('configuration.disabled');
          },
          field: 'deleted',
          width: 35,
          cellRendererFramework: AgGridIconCellRenderer,
          cellRendererParams: {
            icon: 'mdi-cancel',
            tooltip: params => (params.data.deleted ? 'Disabled' : null),
          },
        },
      ],
    };
  },
  computed: {
    handlingAgents() {
      return this.$store.getters.getHandlingAgents;
    },
    tenants() {
      return this.$store.getters.getTenant;
    },
  },
  methods: {
    getHandlingAgents() {
      this.$store.dispatch(GET_HANDLING_AGENTS);
    },
    deleteConfirm(item) {
      this.$store.dispatch(HANDLING_AGENT_DELETE, item.id);
    },
    editConfirm(item) {
      this.$store.dispatch(item.id ? HANDLING_AGENT_UPDATE : HANDLING_AGENT_CREATE, item);
    },
    enableDisableConfirm(item) {
      this.$store.dispatch(item.deleted ? HANDLING_AGENT_ENABLE : HANDLING_AGENT_DISABLE, item.id);
    },
    getTenants() {
      this.$store.dispatch(TENANT_LIST);
    },
  },
  created() {
    this.getTenants();
    this.getHandlingAgents();
  },
};
</script>
