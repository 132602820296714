<script>
import Vue from 'vue';
import { SCHED_FLIGHTS_WRITE_CHANGES } from '../../../../store/modules/scheduling';
import { JSUtils } from '../../../shared/js/JSUtils';
import { REQUEST_ERROR, REQUEST_START, REQUEST_SUCCESS } from '../../../../store/store';
import { NOTIFICATION_SHOW } from '../../../../store/modules/notifications';

import Arrowable from '../../../mixins/Arrowable.vue';
import AgGridParentCellEditor from './AgGridParentCellEditor.vue';
import { RAMP_FLIGHTS_WRITE_CHANGES } from '../../../../store/modules/ramp/ramp';
import { FlightBound } from '../../../shared/model/flightBound';
import { FisModule } from '../model/fisModule';
import { OPERATION_FLIGHTS_WRITE_CHANGES } from '../../../../store/modules/operation';
import { WATER_TOILET_FLIGHTS_WRITE_CHANGES } from '../../../../store/modules/ramp/waterToilet';
import { WATER_TOILET_AIRCRAFT_GROUND_UPDATE } from '../../../../store/modules/ramp/waterToiletAircraftGround';
import { PASSAGE_FLIGHTS_WRITE_CHANGES } from '../../../../store/modules/passageOutbound';
import { PASSAGE_INBOUND_FLIGHTS_WRITE_CHANGES } from '../../../../store/modules/passageInbound';
import { LOST_AND_FOUND_FLIGHTS_WRITE_CHANGES } from '../../../../store/modules/lostAndFound/lostAndFound';
import { AIRCRAFT_GROUND_UPDATE } from '../../../../store/modules/aircraftGround';

export default Vue.extend({
  mixins: [AgGridParentCellEditor, Arrowable],
  data() {
    return {
      connectedFlights: null,
      module: null,
      changes: {},
    };
  },
  methods: {
    getConnectedFlightsPropertyName() {
      return this.params.column.colId.split('.').slice(-2).join('.');
    },
    saveForValueOrAllSelectedFlights(action, field, callback) {
      const selectedRows = this.getSelectedRowsOrCurrentRow();
      selectedRows.forEach((s) => {
        action(s);
      });

      const connectedFlights = selectedRows.filter(c => c.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'id']) != null || (c.getChildPropertyValue(FlightBound.INBOUND, ['common', 'id']) != null && c.getChildPropertyValue(FlightBound.INBOUND, ['common', 'tripNumber']) != null));
      if (connectedFlights.length > 0) {
        this.saveFlights(connectedFlights, field, callback);
      }

      const aircraftGround = selectedRows.filter(c => c.getChildPropertyValue(FlightBound.INBOUND, ['common', 'id']) != null && c.getChildPropertyValue(FlightBound.INBOUND, ['common', 'tripNumber']) == null);
      if (aircraftGround.length > 0) {
        this.saveAircraftGround(aircraftGround, field, callback);
      }
    },
    saveChangesForValueOrAllSelectedFlights(action, field, callback, changes) {
      const selectedRows = this.getSelectedRowsOrCurrentRow();
      selectedRows.forEach((s) => {
        action(s);
      });

      const connectedFlights = selectedRows.filter(c => c.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'id']) != null || (c.getChildPropertyValue(FlightBound.INBOUND, ['common', 'id']) != null && c.getChildPropertyValue(FlightBound.INBOUND, ['common', 'tripNumber']) != null));
      if (connectedFlights.length > 0) {
        this.writeFlightChanges(connectedFlights, field, callback, changes);
      }

      const aircraftGrounds = selectedRows.filter(c => c.getChildPropertyValue(FlightBound.INBOUND, ['common', 'id']) != null && c.getChildPropertyValue(FlightBound.INBOUND, ['common', 'tripNumber']) == null);
      if (aircraftGrounds.length > 0) {
        this.saveAircraftGround(aircraftGrounds, field, callback);
      }
    },
    saveFlightChangesFromValue(action, field, oldValue, value, callback) {
      const valueCell = [];
      this.getFieldPaths().forEach(fieldPath => valueCell.push({ id: fieldPath, value, initialValue: oldValue }));

      if (valueCell.length > 0) {
        this.saveFlightChangesFromCellValue(action, field, valueCell, callback);
      }
    },
    saveFlightChangesFromCellValue(action, field, valueCell, callback) {
      const fieldPaths = this.getFieldPaths();
      fieldPaths.forEach((fieldPath) => {
        const value = this.getValueCellInformation(fieldPath, valueCell);
        if (value) {
          this.addChange(fieldPath, value.value, value.initialValue);
        }
      });

      if (Object.keys(this.changes).length !== 0) {
        this.saveChangesForValueOrAllSelectedFlights(action, field, callback, this.changes);
      }
    },
    getBoundFlightIds(connectedFlights, inboundFlightIds, outboundFlightIds) {
      const { flightBound } = this.params.column.colDef.colCustomConfig;

      connectedFlights.forEach((connectedFlight) => {
        if (connectedFlight.inbound && this.isInboundField(flightBound)) {
          inboundFlightIds.push(connectedFlight.inbound.common.id);
        }

        if (connectedFlight.outbound && this.isOutboundField(flightBound)) {
          outboundFlightIds.push(connectedFlight.outbound.common.id);
        }
      });
    },
    getFlightChanges(connectedFlights, changes) {
      const flightChanges = [];
      const inboundFlightIds = [];
      const outboundFlightIds = [];
      this.getBoundFlightIds(connectedFlights, inboundFlightIds, outboundFlightIds);

      inboundFlightIds.forEach((flightId) => {
        const inboundChanges = {};
        Object.entries(changes).forEach((entry) => {
          const [key, value] = entry;
          if (!key.includes('outbound')) {
            inboundChanges[key.replaceAll('inbound.', '')] = value || null;
          }
        });

        if (Object.keys(inboundChanges).length !== 0) {
          flightChanges.push({ flightId, changes: inboundChanges });
        }
      });

      outboundFlightIds.forEach((flightId) => {
        const outboundChanges = {};
        Object.entries(changes).forEach((entry) => {
          const [key, value] = entry;
          if (!key.includes('inbound')) {
            outboundChanges[key.replaceAll('outbound.', '')] = value || null;
          }
        });

        if (Object.keys(outboundChanges).length !== 0) {
          flightChanges.push({ flightId, changes: outboundChanges });
        }
      });

      return flightChanges;
    },
    getAircraftGroundChanges(aircraftGrounds, changes) {
      return aircraftGrounds.map(aircraftGround => ({
        aircraftGroundId: aircraftGround.inbound.common.id,
        changes,
      }));
    },
    isInboundField(flightBound) {
      if (flightBound === FlightBound.INBOUND || flightBound === FlightBound.ANY) {
        return true;
      }

      return false;
    },
    isOutboundField(flightBound) {
      if (flightBound === FlightBound.OUTBOUND || flightBound === FlightBound.ANY) {
        return true;
      }

      return false;
    },
    saveFlights(selectedRows, field, callback) {
      this.$store.dispatch(this.saveFlightAction, {
        connectedFlightsList: selectedRows,
        field,
      }).then(() => {
        this.params.api.refreshCells({
          force: true,
          columns: [this.params.column],
        });
        if (callback && JSUtils.isFunction(callback)) {
          callback();
        }
      }).catch(error => this.requestErrorCallback(this, error));
    },
    writeFlightChanges(selectedRows, f, callback, changes) {
      const fc = this.getFlightChanges(selectedRows, changes);
      if (fc.length > 0) {
        this.$store.dispatch(this.saveFlightAction, {
          flightChanges: fc,
          field: f,
        }).then(() => {
          this.params.api.refreshCells({
            force: true,
            columns: [this.params.column],
          });
          if (callback && JSUtils.isFunction(callback)) {
            callback();
          }
        }).catch(error => this.requestErrorCallback(this, error));
      }
    },
    saveAircraftGround(selectedRows, field, callback) {
      this.$store.dispatch(this.saveAircraftGroundAction, {
        connectedFlightsList: selectedRows,
        field,
      }).then(() => {
        this.params.api.refreshCells({
          force: true,
          columns: [this.params.column],
        });
        if (callback && JSUtils.isFunction(callback)) {
          callback();
        }
      }).catch(error => this.requestErrorCallback(this, error));
    },
    requestInit() {
      this.$store.commit(REQUEST_START);
    },
    // eslint-disable-next-line no-unused-vars
    requestSuccessCallback(context, resp) {
      const i18n = this.i18n();
      this.$store.commit(REQUEST_SUCCESS);
      this.$store.commit(NOTIFICATION_SHOW, {
        message: i18n.t('common.messageFlightUpdate'),
        color: 'success',
      });
    },
    requestErrorCallback(context, err) {
      const i18n = this.i18n();
      this.$store.commit(REQUEST_ERROR, err);
      this.$store.commit(NOTIFICATION_SHOW, {
        message: i18n.t('notifications.errorNotification'),
        color: 'error',
      });
    },
    i18n() {
      if (this.$i18n) {
        return this.$i18n;
      }
      if (this.$root && this.$root.$i18n) {
        return this.$root.$i18n;
      }
      return this.$i18n;
    },
    getValueCellInformation(fieldPath, valueCell) {
      if (valueCell && Array.isArray(valueCell)) {
        const value = valueCell.filter((i) => {
          if (typeof i === 'object') {
            return i.id === fieldPath;
          }
          if (typeof i === 'string') {
            return i === fieldPath;
          }

          return false;
        });

        return value.length > 0 ? value[0] : null;
      }

      if (valueCell && typeof valueCell === 'object') {
        return valueCell.id === fieldPath ? valueCell : null;
      }

      return null;
    },
    getFieldPaths() {
      if (this.params.column.colDef.colCustomConfig.fieldPaths) {
        if (typeof this.params.column.colDef.colCustomConfig.fieldPaths === 'string') {
          return [this.params.column.colDef.colCustomConfig.fieldPaths];
        } if (Array.isArray(this.params.column.colDef.colCustomConfig.fieldPaths)) {
          return this.params.column.colDef.colCustomConfig.fieldPaths;
        }
      }

      return [];
    },
    addChange(fieldPath, updatedValue, initialValue = null) {
      if (this.checkFieldChange(initialValue, updatedValue)) {
        this.changes[fieldPath] = updatedValue;
      }
    },
    checkFieldChange(initialValue, updatedValue) {
      return initialValue !== updatedValue;
    },
  },
  computed: {
    flightBound() {
      return this.params.column.colDef.colCustomConfig.flightBound;
    },
    flightId() {
      if (this.flightBound && typeof (this.flightBound) === 'function') {
        return this.connectedFlights.getChildPropertyValue(this.params.column.colDef.colCustomConfig.flightBound(this.connectedFlights), ['common', 'id']);
      }
      return this.connectedFlights.getChildPropertyValue(this.flightBound ? this.flightBound : FlightBound.ANY, ['common', 'id']);
    },
    flightNumber() {
      if (this.flightBound && typeof (this.flightBound) === 'function') {
        return this.connectedFlights.getChildPropertyValue(this.params.column.colDef.colCustomConfig.flightBound(this.connectedFlights), ['common', 'flightNo']);
      }
      return this.connectedFlights.getChildPropertyValue(this.flightBound ? this.flightBound : FlightBound.ANY, ['common', 'flightNo']);
    },
    saveFlightAction() {
      switch (this.module) {
        case FisModule.OPERATION:
          return OPERATION_FLIGHTS_WRITE_CHANGES;
        case FisModule.PASSAGE_OUTBOUND:
          return PASSAGE_FLIGHTS_WRITE_CHANGES;
        case FisModule.PASSAGE_INBOUND:
          return PASSAGE_INBOUND_FLIGHTS_WRITE_CHANGES;
        case FisModule.RAMP:
          return RAMP_FLIGHTS_WRITE_CHANGES;
        case FisModule.SCHEDULING:
          return SCHED_FLIGHTS_WRITE_CHANGES;
        case FisModule.WATER_TOILET:
          return WATER_TOILET_FLIGHTS_WRITE_CHANGES;
        case FisModule.LOST_AND_FOUND:
          return LOST_AND_FOUND_FLIGHTS_WRITE_CHANGES;
        default:
          return SCHED_FLIGHTS_WRITE_CHANGES;
      }
    },
    saveAircraftGroundAction() {
      switch (this.module) {
        case FisModule.WATER_TOILET:
          return WATER_TOILET_AIRCRAFT_GROUND_UPDATE;
        default:
          return AIRCRAFT_GROUND_UPDATE;
      }
    },
  },
  created() {
    this.module = this.params.context.module;
    if (JSUtils.isFunction(this.params.node.data.clone)) {
      this.connectedFlights = this.params.node.data.clone();
    } else {
      // TODO Clone value if it's not a ConnectedFlight
      this.connectedFlights = this.params.node.data;
      console.warn(`The following object should be cloned: ${this.params.node.data.constructor.name}`); // eslint-disable-line no-console
    }
  },
});
</script>
