<template>
  <ag-grid-template-cell-renderer :params="params">
    <span class="mr-2">{{currency}}</span>
  </ag-grid-template-cell-renderer>
</template>

<script>
import Vue from 'vue';
import AgGridTemplateCellRenderer from './templates/AgGridTemplateCellRenderer.vue';

export default Vue.extend({
  components: {
    AgGridTemplateCellRenderer,
  },
  computed: {
    currency() {
      if (this.params && this.params.value.value != null && this.params.value.currency) {
        return this.formatNumber(this.params.value.currency, this.params.value.value);
      }
      if (this.params.value && Object.hasOwnProperty.call(this.params.value, 'value')) {
        return this.params.value.value;
      }
      return this.params.value;
    },
  },
  methods: {
    formatNumber(currency, value) {
      return new Intl.NumberFormat(this.$store.getters.getCurrencies.find(p => p.id === currency).locale, { style: 'currency', currency }).format(value);
    },
  },
});
</script>
