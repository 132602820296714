<template>
  <div>
    <airport-gate-select :bound="flightBound" :ref="'refComp'" v-on:keydown.native="keyEvent" v-char-length.clearable="4" v-model="itemId" @input-code="setItemCode($event)" @input-remote="setItemRemote($event)"></airport-gate-select>
  </div>
</template>

<script>
import Vue from 'vue';
import AirportGateSelect from '../../../airport/AirportGateSelect.vue';
import AgGridParentConnectedFlightsCellEditor from '../mixins/AgGridParentConnectedFlightsCellEditor.vue';

export default Vue.extend({
  mixins: [AgGridParentConnectedFlightsCellEditor],
  components: {
    AirportGateSelect,
  },
  data() {
    return {
      itemId: null,
      itemIdOriginal: null,
      itemCode: null,
      itemRemote: null,
    };
  },
  methods: {
    getValue() {
      this.unmarkCellsSelectedAsEdit();

      if (this.itemIdOriginal !== this.itemId) {
        const that = this;

        this.saveFlightChangesFromValue((s) => {
          s.setChildPropertyValueByBound(that.flightBound, 'common.gateId', that.itemId);
          s.setChildPropertyValueByBound(that.flightBound, 'common.gateCode', that.itemCode);
          s.setChildPropertyValueByBound(that.flightBound, 'common.gateRemote', that.itemRemote);
        }, 'Gate', this.itemIdOriginal, this.itemId);

        return that.itemCode;
      }
      return this.params.node.data;
    },
    setItemCode(code) {
      this.itemCode = code;
    },
    setItemRemote(remote) {
      this.itemRemote = remote;
    },
  },
  created() {
    const connectedFlights = this.params.node.data.clone();
    this.itemIdOriginal = connectedFlights.getChildPropertyValue(this.params.column.colDef.colCustomConfig.flightBound, ['common', 'gateId']);
    this.itemId = this.itemIdOriginal;

    this.markCellsSelectedAsEdit();
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$refs.refComp) {
        this.$refs.refComp.$refs.refComp.focus();
      }
    });
  },
});
</script>

<style>
  .aircraft-config-cell.ag-cell-inline-editing {
    min-width: 95px;
  }
</style>
