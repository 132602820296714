export class WindowUtils {
  /** *
   * InnerWidth of window browser object is greater than 1920 px
   */
  static isXLWidth() {
    return window && window.innerWidth > 1920;
  }

  /** *
   * InnerWidth of window browser object is lesser than 1600 px
   */
  static isXSWidth() {
    return window && window.innerWidth < 1600;
  }
}
