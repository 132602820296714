<template class="mobileView">
  <div v-if="visibilityControl && flightMobile" class="mobile-service-milestones">
    <info-mobile-flight-header :backArrow="backArrow" :flightMobile="flightMobile"
      :filterDate="flightMobile.flightDate" />
    <info-mobile-parking-header :parkingCode="flightMobile.parkingCode" :gateCode="flightMobile.gateCode" />
    <div class="additionalInfo">
      <v-flex xs12 sm12 md12 lg12 xl12 class="info-mobile-connection">
        <info-mobile-connection :connectionId="flightMobile.connectionId" :originId="flightMobile.originId"
          url-destination="serviceMilestones"></info-mobile-connection>
      </v-flex>
      <v-flex xs12 sm12 md12 lg12 xl12>
        <info-mobile-milestones :flight-id="flightId" />
      </v-flex>
      <v-flex xs12 sm12 md12 lg12 xl12 class="pb-5">
        <info-mobile-ramp-services :flight-id="flightId" />
      </v-flex>
      <scroll-buttons />
    </div>
  </div>
</template>

<script>
import { TIME_TIMEZONE_CHANGED } from '../../../store/modules/time';
import StoreSubscribable from '../../../components/mixins/StoreSubscribable.vue';
import { MOBILE_GET_FLIGHT } from '../../../store/modules/mobile';
import RolePermissions from '../../../components/mixins/RolePermissions.vue';
import infoMobileFlightHeader from '../../../components/mobile/infoMobileFlightHeader.vue';
import infoMobileParkingHeader from '../../../components/mobile/infoMobileParkingHeader.vue';
import infoMobileConnection from '../../../components/mobile/infoMobileConnection.vue';
import infoMobileMilestones from '../../../components/mobile/infoMobileMilestones.vue';
import infoMobileRampServices from '../../../components/mobile/infoMobileRampServices.vue';
import scrollButtons from '../../../components/mobile/scroll/scrollButtons.vue';

export default {
  name: 'mobile-service-milestones',
  props: {
    flightId: {
      required: true,
    },
    flightMobileProps: Object,
  },
  mixins: [StoreSubscribable, RolePermissions],
  components: {
    infoMobileFlightHeader,
    infoMobileParkingHeader,
    infoMobileConnection,
    infoMobileMilestones,
    infoMobileRampServices,
    scrollButtons,
  },
  data() {
    return {
      bottomNav: 'all',
      infoPanel: [true],
      visibilityControl: true,
    };
  },
  computed: {
    rolePermission() {
      return `${this.$route.meta.role}_WR`;
    },
    flightMobile() {
      return this.$store.getters.getFlightMobile;
    },
  },
  methods: {
    backArrow() {
      this.$router.push({ name: 'info', params: { flightId: this.flightId, flightMobileProps: this.flightMobile } });
    },
  },
  watch: {
    // when flightMobile is loaded, check if user has permission to see the flight
    flightMobile: {
      handler() {
        if (!this.hasPermissions('AIRPORT_SERVICE_AGENT_ROLE_FILTER_BY_AGENT')) {
          if (this.flightMobile.operationAgent !== this.user.email) {
            this.$router.push({ name: 'mobile' });
          }
        }
      },
      deep: true,
    },
    flightId() {
      if (this.flightId !== this.flightMobile.flightId) {
        this.$store.dispatch(MOBILE_GET_FLIGHT, this.flightId);
      }
    },
  },
  created() {
    this.subscribeMutation(TIME_TIMEZONE_CHANGED, () => {
      this.visibilityControl = false;
      this.visibilityControl = true;
    });
    if (!this.flightId) {
      this.$router.push({ name: 'mobile' });
    } else if (!this.flightMobileProps) {
      this.$store.dispatch(MOBILE_GET_FLIGHT, this.flightId);
    }
  },
};
</script>
<style lang="scss" scoped>
.mobile-service-milestones {
  .additionalInfo {
    margin-top: 20px;
  }
}
</style>
