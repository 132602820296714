<template>
  <div>
    <rush-baggage-delete-modal v-if="dialogDelete" :dialog="dialogDelete" :items="selectedItemsToDelete" @delete-reject="deleteReject" @delete-confirm="deleteConfirm" />
    <rush-baggage-edit-modal v-if="dialog" :dialog="dialog" :isExternal="rushBaggageExternal" :edited-rush-baggage="editedRushBaggage" @close="close" />
    <rush-baggage-list-filter @changeShowFilter="changeShowFilter"/>
    <rush-baggage-list-container :isFilterDisplayed="filterDisplayed" @delete-item="deleteItem"/>

    <rush-baggage-list-options @clickInternalFlight="newInternalFlight" @clickExternalFlight="newExternalFlight" @clickDeleteRushBaggage="deleteItems" />
  </div>
</template>
<script>
import RushBaggageListContainer from '../components/lostAndFound/rushBaggage/list/RushBaggageListContainer.vue';
import RushBaggageListFilter from '../components/lostAndFound/rushBaggage/list/RushBaggageListFilter.vue';
import RushBaggageListOptions from '../components/lostAndFound/rushBaggage/list/RushBaggageListOptions.vue';
import RushBaggageEditModal from '../components/lostAndFound/rushBaggage/edit/RushBaggageEditModal.vue';
import { FIND_RUSHES_BAGGAGE, RUSH_BAGGAGE_DELETE } from '../store/modules/lostAndFound/rushBaggage';
import RushBaggageDeleteModal from '../components/lostAndFound/rushBaggage/delete/RushBaggageDeleteModal.vue';
import RushBaggage from '../components/lostAndFound/rushBaggage/model/rushBaggage';

export default {
  components: {
    RushBaggageListContainer,
    RushBaggageListFilter,
    RushBaggageListOptions,
    RushBaggageEditModal,
    RushBaggageDeleteModal,
  },
  data() {
    return {
      filterDisplayed: true,
      dialog: false,
      dialogDelete: false,
      rushBaggageExternal: false,
      selectedItemsToDelete: [],
      editedRushBaggage: RushBaggage.createEmptyRushBaggage(),
    };
  },
  computed: {
    selectedRowsItems() {
      return this.$store.state.rushBaggage.selectedRushesBaggage;
    },
  },
  methods: {
    changeShowFilter(dispayled) {
      this.filterDisplayed = dispayled;
    },
    newInternalFlight() {
      this.dialog = true;
      this.editedRushBaggage = RushBaggage.createEmptyRushBaggage();
      this.rushBaggageExternal = false;
    },
    newExternalFlight() {
      this.dialog = true;
      this.editedRushBaggage = RushBaggage.createEmptyRushBaggage();
      this.rushBaggageExternal = true;
    },
    close(result) {
      this.dialog = false;
      if (result && result.doFind) {
        this.$store.dispatch(FIND_RUSHES_BAGGAGE);
      }
    },
    deleteItem(item) {
      this.selectedItemsToDelete = [];
      this.selectedItemsToDelete.push(item);
      this.dialogDelete = true;
    },
    deleteItems() {
      this.selectedItemsToDelete = [];
      this.selectedItemsToDelete = this.selectedRowsItems;
      this.dialogDelete = true;
    },
    deleteConfirm(ids) {
      this.$store.dispatch(RUSH_BAGGAGE_DELETE, { ids })
        .finally(() => {
          this.selectedItemsToDelete = [];
          this.dialogDelete = false;
        });
    },
    deleteReject() {
      this.selectedItemsToDelete = [];
      this.dialogDelete = false;
    },
  },
};
</script>
