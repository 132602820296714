<template>
  <div>
    <v-tooltip left>
      <v-btn
        slot="activator"
        small
        flat
        icon
        v-on:click="decreaseDay">
        <v-icon>keyboard_arrow_left</v-icon>
      </v-btn>
      <span>{{$t("commonFilter.prevDay")}}</span>
    </v-tooltip>
    <span>{{ formattedDate }}</span>
    <v-tooltip right>
      <v-btn
        slot="activator"
        small
        flat
        icon
        v-on:click="increaseDay">
        <v-icon>keyboard_arrow_right</v-icon>
      </v-btn>
      <span>{{$t("commonFilter.nextDay")}}</span>
    </v-tooltip>
    <v-tooltip bottom>
      <v-btn
        small
        flat
        icon
        v-on:click="today" slot="activator">
        <v-icon>today</v-icon>
      </v-btn>
      <span>{{$t("commonFilter.today")}}</span>
    </v-tooltip>
  </div>
</template>
<script>
import {
  RAMP_GANTT_FILTER_DECREASE_DAY,
  RAMP_GANTT_FILTER_INCREASE_DAY, RAMP_GANTT_FILTER_RESET_DATES,
  RAMP_GANTT_FIND_FLIGHTS,
} from '../../store/modules/ramp/rampGantt';

export default {
  name: 'GanttFlightsToolbarDateSelector',
  methods: {
    find() {
      this.$store.dispatch(RAMP_GANTT_FIND_FLIGHTS);
    },
    increaseDay() {
      this.$store.commit(RAMP_GANTT_FILTER_INCREASE_DAY);
      this.find();
    },
    decreaseDay() {
      this.$store.commit(RAMP_GANTT_FILTER_DECREASE_DAY);
      this.find();
    },
    today() {
      this.$store.commit(RAMP_GANTT_FILTER_RESET_DATES);
      this.find();
    },
  },
  computed: {
    formattedDate() {
      return this.$moment(this.$store.state.rampGantt.filter.dateFrom).locale(this.$i18n.locale).format('DD/MM/YY (ddd)');
    },
  },
};
</script>
