<template>
  <div>
    <airport-income-list-filter @changeShowFilter="changeShowFilter"/>

    <airport-income-list-table :items="items" :isFilterDisplayed="filterDisplayed" />

  </div>
</template>
<script>
import Breadcrumbs from '@/components/Breadcrumbs.vue';

import AirportIncomeListFilter
from '../components/ticketingReports/airportIncome/AirportIncomeListFilter.vue';
import AirportIncomeListTable
from '../components/ticketingReports/airportIncome/AirportIncomeListTable.vue';

export default {
  name: 'airport-income-ticketing-report',
  components: {
    AirportIncomeListFilter,
    AirportIncomeListTable,
    Breadcrumbs,
  },
  data() {
    return {
      filterDisplayed: true,
    };
  },
  computed: {
    items() {
      // eslint-disable-next-line
      return this.$store.getters.getSortedTenantAirlinesTicketing;
    },
  },
  methods: {
    changeShowFilter(dispayled) {
      this.filterDisplayed = dispayled;
    },
  },
};
</script>
