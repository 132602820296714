/* eslint no-param-reassign: "off" */

// initial state
const storeState = {
  currencies: [
    {
      name: 'ARS ($)',
      id: 'ARS',
      locale: 'es-AR',
      replacementRule: /[^,0-9]/g,
    },
    {
      name: 'CLP ($)',
      id: 'CLP',
      locale: 'es-CL',
      replacementRule: /[^,0-9]/g,
    },
    {
      name: 'EUR (€)',
      id: 'EUR',
      locale: 'es-ES',
      replacementRule: /[^,0-9]/g,
    },
    {
      name: 'GBP (£)',
      id: 'GBP',
      locale: 'en-GB',
      replacementRule: /[^0-9.]/g,
    },
    {
      name: 'USD ($)',
      id: 'USD',
      locale: 'en-US',
      replacementRule: /[^0-9.]/g,
    },
  ],
};

// getters
const storeGetters = {
  getCurrencies: state => state.currencies,
};

// actions
const storeActions = {

};

// mutations
const storeMutations = {

};

export default {
  state: storeState,
  getters: storeGetters,
  actions: storeActions,
  mutations: storeMutations,
};
