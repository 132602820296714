<template>
  <v-container grid-list-md text-xs-center fluid>
    <v-layout row wrap>
      <v-flex v-if="!fileSelected">
        <input type="file" ref="importFile" @change="loadFile($event)">
      </v-flex>
      <v-flex v-if="!!fileSelected">
        {{$t("schedulingImport.selectedFile")}} {{fileSelected}}
        <v-btn flat color="primary" @click="clear">{{$t("common.clear")}}</v-btn>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>

import { NOTIFICATION_SHOW } from '../../../store/modules/notifications';
import SchedulingImportFile from '../model/schedulingImportFile';

export default {
  name: 'scheduling-import-step-file-selection',
  components: {
  },
  props: {
    value: SchedulingImportFile,
  },
  data() {
    return {
      fileSelected: null,
      inputRef: null,
    };
  },
  methods: {
    emitFileLoaded(file) {
      this.$emit('input', file);
    },
    loadFile(e) {
      this.fileSelected = null;
      if (!e.target.files || !e.target.files[0]) {
        return;
      }
      if (window.FileReader) {
        const reader = new FileReader();
        const file = e.target.files[0];
        reader.readAsText(file);
        reader.onload = (event) => {
          if (event.target.result) {
            this.emitFileLoaded(new SchedulingImportFile(event.target.result, e.target));
          }
          this.fileSelected = file.name;
        };
        this.inputRef = e.target;
        reader.onerror = (evt) => {
          if (evt.target.error.name === 'NotReadableError') {
            this.$store.commit(NOTIFICATION_SHOW, {
              message: this.$i18n.t('schedulingImport.errorRead'),
              color: 'error',
            });
          }
        };
      } else {
        this.$store.commit(NOTIFICATION_SHOW, {
          message: this.$i18n.t('schedulingImport.errorFileReader'),
          color: 'error',
        });
      }
    },
    clear() {
      const input = this.inputRef;
      input.value = '';
      input.type = 'text';
      input.type = 'file';
      this.fileSelected = null;
      this.emitFileLoaded(null);
    },
  },
};
</script>
<style>
</style>
