import Vue from 'vue';
import VueI18n from 'vue-i18n';
import es from '@/lang/es.json';
import en from '@/lang/en.json';
import { DEFAULT_LANGUAGE, FALLBACK_LANGUAGE } from '@/constants/i18n';

Vue.use(VueI18n);

// Create VueI18n instance with options
const i18n = new VueI18n({
  locale: DEFAULT_LANGUAGE, // set locale
  fallbackLocale: FALLBACK_LANGUAGE,
  messages: { es, en }, // set locale messages
});

// Update the locale language with the stored one
i18n.locale = localStorage.getItem('currentLanguage') || 'en';

export default i18n;
