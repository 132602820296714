<template>
  <div>
    <v-flex v-flex-none>
      <time-input :ref="refTimeInput" v-model="updatedTime" :referenceDate="referenceDate"></time-input>
    </v-flex>
  </div>
</template>

<script>
import Vue from 'vue';
import TimeInput from '../../../ui/TimeInput.vue';
import AgGridParentRushBaggageCellEditor from '../mixins/AgGridParentRushBaggageCellEditor.vue';

export default Vue.extend({
  mixins: [AgGridParentRushBaggageCellEditor],
  components: {
    TimeInput,
  },
  props: {
    refTimeInput: {
      type: String,
      required: false,
      default: 'refTimeInput',
    },
  },
  data() {
    return {
      originalTime: null,
      updatedTime: null,
    };
  },
  methods: {
    getValue() {
      if (this.originalTime !== this.updatedTime) {
        const that = this;
        const rushBaggagePropertyName = this.getRushBaggagePropertyName();
        this.saveForValueOrRushesBaggage((s) => {
          s.setChildPropertyValue(rushBaggagePropertyName, that.updatedTime);
        }, rushBaggagePropertyName);
      }
      return this.updatedTime;
    },
  },
  computed: {
    referenceDate() {
      if (this.params.column.colDef.cellEditorCustomConfig && this.params.column.colDef.cellEditorCustomConfig.referenceDate) {
        return this.params.column.colDef.cellEditorCustomConfig.referenceDate;
      }
      return null;
    },
  },
  created() {
    this.originalTime = this.params.value.value ? this.params.value.value : this.params.value;
    this.updatedTime = this.originalTime;
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$refs.refTimeInput) {
        this.$refs.refTimeInput.$refs['time-input-ref'].focus();
      }
    });
  },
});
</script>

<style>

</style>
