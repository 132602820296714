<script>
import Vue from 'vue';

export default Vue.extend({
  methods: {
    getArchivableFlights(flights) {
      return flights.filter(flight => this.checkMandatoryArchiveFields(flight.inbound) && this.checkMandatoryArchiveFields(flight.outbound));
    },
    checkMandatoryArchiveFields(flightComposition) {
      if (!flightComposition) { return true; }

      if (flightComposition.common && flightComposition.common.status === 'CANCELLED') {
        return true;
      }

      if (flightComposition.common && flightComposition.common.parkingId && flightComposition.common.aircraftRegisterNumber && flightComposition.times.blockTime) {
        return true;
      }

      return false;
    },
  },
});
</script>
