<template>
  <div>
    <ag-grid-vue
      class="ag-theme-material connected-flights-table operational-table"
      :columnDefs="columnDefs"
      :defaultColDef="defaultColDef"
      :rowData="items"
      rowSelection="multiple"
      :rowMultiSelectWithClick="true"
      :suppressRowClickSelection="true"
      :onGridReady="onGridReady"
      @first-data-rendered="firstDataRendered"
      :gridOptions="gridOptions"
      @selection-changed="onSelectionChanged"
      :suppressHorizontalScroll="true"
      :frameworkComponents="frameworkComponents"
      :overlayNoRowsTemplate="customNoRowsOverlay"
      :overlayLoadingTemplate="customLoadingOverlay"
      v-bind:style="`width: 100%; height: calc(109vh - ${this.sizeFilter} - 8vh);`">
    </ag-grid-vue>
  </div>
</template>
<script>
import { AgGridVue } from 'ag-grid-vue';
import AgGridTimeCellRenderer from '../../ag-grid/cell-renderers/AgGridTimeCellRenderer.vue';
import AgGridConnectedFlights from '../../ag-grid/mixins/AgGridConnectedFlights.vue';
import {
  BILLING_CHANGE_FLIGHT_SELECTION,
  BILLING_CHANGE_GRID_API,
  BILLING_FIT_COLUMNS_TO_VIEW,
} from '../../../store/modules/invoicing/billing';
import { FlightBound } from '../../shared/model/flightBound';
import { cellClassAgGridRules } from '../../ag-grid/mixins/cellClassAgGridRules';
import { FisModule } from '../../ag-grid/cell-editors/model/fisModule';
import agGridTextCustomFilterComparator from '../../ag-grid/filters/agGridTextCustomFilterComparator';
import agGridTextCustomFilterFormatter from '../../ag-grid/filters/agGridTextCustomFilterFormatter';
import { JSUtils as TimeUtils } from '../../shared/js/TimeUtils';
import AgGridValueBaseWithTooltipCellRenderer from '../../ag-grid/cell-renderers/AgGridValueBaseWithTooltipCellRenderer.vue';
import AgGridCustomService from '../../shared/services/agGridCustomService';
import AgGridIconCellRenderer from '../../ag-grid/cell-renderers/AgGridIconCellRenderer.vue';
import RolePermissions from '../../mixins/RolePermissions.vue';
import AgGridNoRowsOverlay from '../../ag-grid/mixins/AgGridNoRowsOverlay.vue';
import AgGridValueWithAsteriskCellRenderer from '../../ag-grid/cell-renderers/AgGridValueWithAsteriskCellRenderer.vue';

export default {
  name: 'billing-list-table',
  mixins: [AgGridConnectedFlights, RolePermissions, AgGridNoRowsOverlay],
  components: {
    AgGridVue,
    AgGridValueWithAsteriskCellRenderer,
  },
  props: {
    items: { type: Array, required: true },
    isFilterDisplayed: { type: Boolean, required: false, default: false },
  },
  computed: {
    sizeFilter() {
      return this.isFilterDisplayed ? '320px' : '200px';
    },
    fitColumnsOnResize() {
      return this.$store.state.billing.fitColumnsOnResize;
    },
    airportBase() {
      return this.$store.state.tenant.currentTenant.name;
    },
  },
  data() {
    const that = this;
    const rolePermission = this.getRoles();
    return {
      frameworkComponents: {
        AgGridValueBaseWithTooltipCellRenderer,
        AgGridIconCellRenderer,
      },
      defaultColDef: {
        cellClassRules: cellClassAgGridRules,
        minWidth: 30,
        suppressMenu: true,
        sortable: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        filterParams: {
          textCustomComparator: agGridTextCustomFilterComparator,
          textFormatter: agGridTextCustomFilterFormatter,
        },
        editable: false,
      },
      gridOptions: {
        onFilterChanged: this.uncheckAllOnFilter,
        getRowNodeId: this.getRowNodeId,
        getRowClass: this.getRowClass,
        floatingFilter: true,
        groupHeaderHeight: 35,
        headerHeight: 35,
        floatingFiltersHeight: 20,
        rowHeight: 30,
        context: {
          module: FisModule.BILLING,
        },
        alignedGrids: [],
      },
      columnDefs: [
        {
          headerName: '',
          children: [
            {
              headerTooltip: 'Check/uncheck all',
              hide: !this.hasPermissions(rolePermission),
              checkboxSelection: true,
              width: 21,
              minWidth: 20,
              headerCheckboxSelection: true,
              headerCheckboxSelectionFilteredOnly: true,
              suppressMenu: true,
              sortable: false,
              suppressMovable: true,
              suppressSizeToFit: true,
              filter: false,
              resizable: false,
              colCustomConfig: {
                exportCsvDisabled: true,
              },
              editable: this.editable(true, rolePermission),
            },
            {
              headerName: 'SortDate',
              field: 'sortingTime',
              hide: true,
              sort: 'asc',
              colCustomConfig: {
                exportCsvDisabled: true,
              },
            },
            {
              headerName: 'Airport',
              colId: 'anybound.common.airport',
              hide: true,
              valueGetter() {
                return that.airportBase;
              },
            },
          ],
        },
        {
          headerName: '',
          children: [
            {
              colId: 'anybound.common.scheduledDate',
              headerValueGetter() {
                return that.$i18n.t('common.date');
              },
              width: 65,
              type: 'date',
              valueGetter(params) {
                return params.data.connectedFlights.getChildPropertyValue(FlightBound.ANY, ['common', 'scheduledDate']);
              },
              valueFormatter(params) {
                return that.$moment(params.value).format('DD/MM/YYYY');
              },
            },
          ],
        },
        {
          headerValueGetter() {
            return that.$i18n.t('common.aircraftInformation');
          },
          children: [
            {
              colId: 'anybound.common.aircraftRegisterNumber',
              headerValueGetter() {
                return that.$i18n.t('common.aircraftRegistration');
              },
              width: 60,
              cellClass: 'aircraft-cell',
              valueGetter(params) {
                return params.data.connectedFlights.getChildPropertyValue(FlightBound.ANY, ['common', 'aircraftRegisterNumber']);
              },
            },
            {
              colId: 'anybound.common.aircraftTypeCode',
              headerValueGetter() {
                return that.$i18n.t('common.type');
              },
              width: 50,
              cellClass: 'aircraft-type-cell',
              valueGetter(params) {
                return params.data.connectedFlights.getChildPropertyValue(FlightBound.ANY, ['common', 'aircraftTypeCode']);
              },
            },
            {
              colId: 'anybound.common.aircraftConfigCode',
              headerValueGetter() {
                return that.$i18n.t('common.config');
              },
              width: 60,
              cellClass: 'aircraft-config-cell',
              valueGetter(params) {
                return params.data.connectedFlights.getChildPropertyValue(FlightBound.ANY, ['common', 'aircraftConfigCode']);
              },
            },
          ],
        },
        {
          headerValueGetter() {
            return that.$i18n.t('common.arrivalFlight');
          },
          children: [
            {
              colId: 'inbound.common.flightNo',
              headerValueGetter() {
                return that.$i18n.t('common.flight');
              },
              width: 50,
              cellClass: 'flight-number-cell separator',
              colCustomConfig: {
                flightBound: FlightBound.INBOUND,
              },
              valueGetter(params) {
                return {
                  value: params.data.connectedFlights.getChildPropertyValue(FlightBound.INBOUND, ['common', 'flightNo']),
                  asterisk: params.data.connectedFlights.getChildPropertyValue(FlightBound.INBOUND, ['common', 'flagAirlineOperation']),
                  tooltip: params.data.connectedFlights.getChildPropertyValue(FlightBound.INBOUND, ['common', 'airlineOperationMessage']),
                };
              },
              cellRendererFramework: AgGridValueWithAsteriskCellRenderer,
            },
            {
              headerValueGetter() {
                return that.$i18n.t('common.airline');
              },
              colId: 'inbound.common.airlineCode',
              hide: true,
              valueGetter(params) {
                return params.data.connectedFlights.getChildPropertyValue(FlightBound.INBOUND, ['common', 'airlineCode']);
              },
            },
            {
              colId: 'inbound.common.airlineOperationIataCode',
              headerValueGetter() {
                return that.$i18n.t('common.airlineOperation');
              },
              width: 40,
              cellClass: 'airline-cell separator',
              colCustomConfig: {
                flightBound: FlightBound.INBOUND,
              },
              valueGetter(params) {
                return params.data.connectedFlights.getChildPropertyValue(FlightBound.INBOUND, ['common', 'airlineOperationIataCode']);
              },
            },
            {
              colId: 'inbound.common.scheduledTime',
              headerValueGetter() {
                return that.$i18n.t('common.scheduledTimeArrival');
              },
              width: 50,
              cellClass: 'scheduled-time-cell',
              comparator: this.objectWithValueComparator,
              colCustomConfig: {
                flightBound: FlightBound.INBOUND,
              },
              valueGetter(params) {
                return that.agGridTimeGetter(params.data.connectedFlights.getChildPropertyValue(FlightBound.INBOUND, ['common', 'scheduledTime']), params.data.connectedFlights.getChildPropertyValue(FlightBound.INBOUND, ['common', 'scheduledDate']));
              },
              cellRendererFramework: AgGridTimeCellRenderer,
            },
            {
              colId: 'inbound.times.blockTime',
              headerValueGetter() {
                return that.$i18n.t('flightInformation.onBlockTime');
              },
              width: 50,
              cellClass: 'scheduled-time-cell',
              comparator: this.objectWithValueComparator,
              colCustomConfig: {
                flightBound: FlightBound.INBOUND,
              },
              valueGetter(params) {
                return that.agGridTimeGetter(params.data.connectedFlights.getChildPropertyValue(FlightBound.INBOUND, ['times', 'blockTime']), params.data.connectedFlights.getChildPropertyValue(FlightBound.INBOUND, ['times', 'blockTime']));
              },
              cellRendererFramework: AgGridTimeCellRenderer,
            },
            {
              colId: 'inbound.times.cancellationTime',
              headerValueGetter() {
                return that.$i18n.t('invoicing.cancellation');
              },
              width: 52,
              cellClass: 'scheduled-time-cell',
              comparator: this.objectWithValueComparator,
              colCustomConfig: {
                flightBound: FlightBound.INBOUND,
              },
              valueGetter(params) {
                return {
                  value: params.data.connectedFlights.getChildPropertyValue(FlightBound.INBOUND, ['times', 'cancellationTime']) ? params.data.connectedFlights.getChildPropertyValue(FlightBound.INBOUND, ['times', 'cancellationTime']) : null,
                  referenceDate: params.data.connectedFlights.getChildPropertyValue(FlightBound.INBOUND, ['times', 'cancellationTime']) ? params.data.connectedFlights.getChildPropertyValue(FlightBound.INBOUND, ['times', 'cancellationTime']) : null,
                  tooltip: params.data.connectedFlights.getChildPropertyValue(FlightBound.INBOUND, ['times', 'cancellationTime']) ? that.$moment(params.data.connectedFlights.getChildPropertyValue(FlightBound.INBOUND, ['times', 'cancellationTime'])).format('DD/MM/YYYY HH:mm') : null,
                };
              },
              cellRendererFramework: AgGridTimeCellRenderer,
            },
            {
              colId: 'inbound.common.airportsIata',
              headerValueGetter() {
                return that.$i18n.t('common.from');
              },
              width: 90,
              cellClass: 'airport-from-cell',
              colCustomConfig: {
                flightBound: FlightBound.INBOUND,
              },
              valueGetter(params) {
                return params.data.connectedFlights.getChildPropertyValue(FlightBound.INBOUND, ['common', 'airportsIata']);
              },
            },
            {
              colId: 'inbound.common.handlingTypeCode',
              headerValueGetter() {
                return that.$i18n.t('common.handlingTypeShort');
              },
              width: 40,
              cellClass: 'handling-type-cell',
              colCustomConfig: {
                flightBound: FlightBound.INBOUND,
              },
              valueGetter(params) {
                return params.data.connectedFlights.getChildPropertyValue(FlightBound.INBOUND, ['common', 'handlingTypeCode']);
              },
            },
          ],
        },
        {
          headerValueGetter() {
            return that.$i18n.t('common.departureFlight');
          },
          children: [
            {
              colId: 'outbound.common.flightNo',
              headerValueGetter() {
                return that.$i18n.t('common.flight');
              },
              width: 50,
              cellClass: 'flight-number-cell separator',
              colCustomConfig: {
                flightBound: FlightBound.OUTBOUND,
              },
              valueGetter(params) {
                return {
                  value: params.data.connectedFlights.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'flightNo']),
                  asterisk: params.data.connectedFlights.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'flagAirlineOperation']),
                  tooltip: params.data.connectedFlights.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'airlineOperationMessage']),
                };
              },
              cellRendererFramework: AgGridValueWithAsteriskCellRenderer,
            },
            {
              headerValueGetter() {
                return that.$i18n.t('common.airline');
              },
              colId: 'inbound.common.airlineCode',
              hide: true,
              valueGetter(params) {
                return params.data.connectedFlights.getChildPropertyValue(FlightBound.INBOUND, ['common', 'airlineCode']);
              },
            },
            {
              colId: 'outbound.common.airlineOperationIataCode',
              headerValueGetter() {
                return that.$i18n.t('common.airlineOperation');
              },
              width: 40,
              cellClass: 'airline-cell separator',
              colCustomConfig: {
                flightBound: FlightBound.OUTBOUND,
              },
              valueGetter(params) {
                return params.data.connectedFlights.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'airlineOperationIataCode']);
              },
            },
            {
              colId: 'outbound.common.scheduledTime',
              headerValueGetter() {
                return that.$i18n.t('common.scheduledTimeOfDeparture');
              },
              width: 50,
              cellClass: 'scheduled-time-cell',
              comparator: this.objectWithValueComparator,
              colCustomConfig: {
                flightBound: FlightBound.OUTBOUND,
              },
              valueGetter(params) {
                return that.agGridTimeGetter(params.data.connectedFlights.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'scheduledTime']), params.data.connectedFlights.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'scheduledDate']));
              },
              cellRendererFramework: AgGridTimeCellRenderer,
            },
            {
              colId: 'outbound.times.blockTime',
              headerValueGetter() {
                return that.$i18n.t('flightInformation.ofBlockTime');
              },
              width: 50,
              cellClass: 'scheduled-time-cell',
              comparator: this.objectWithValueComparator,
              colCustomConfig: {
                flightBound: FlightBound.OUTBOUND,
              },
              valueGetter(params) {
                return that.agGridTimeGetter(params.data.connectedFlights.getChildPropertyValue(FlightBound.OUTBOUND, ['times', 'blockTime']), params.data.connectedFlights.getChildPropertyValue(FlightBound.OUTBOUND, ['times', 'blockTime']));
              },
              cellRendererFramework: AgGridTimeCellRenderer,
            },
            {
              colId: 'outbound.times.cancellationTime',
              headerValueGetter() {
                return that.$i18n.t('invoicing.cancellation');
              },
              width: 52,
              cellClass: 'scheduled-time-cell',
              comparator: this.objectWithValueComparator,
              colCustomConfig: {
                flightBound: FlightBound.OUTBOUND,
              },
              valueGetter(params) {
                return {
                  value: params.data.connectedFlights.getChildPropertyValue(FlightBound.OUTBOUND, ['times', 'cancellationTime']) ? params.data.connectedFlights.getChildPropertyValue(FlightBound.OUTBOUND, ['times', 'cancellationTime']) : null,
                  referenceDate: params.data.connectedFlights.getChildPropertyValue(FlightBound.OUTBOUND, ['times', 'cancellationTime']) ? params.data.connectedFlights.getChildPropertyValue(FlightBound.OUTBOUND, ['times', 'cancellationTime']) : null,
                  tooltip: params.data.connectedFlights.getChildPropertyValue(FlightBound.OUTBOUND, ['times', 'cancellationTime']) ? that.$moment(params.data.connectedFlights.getChildPropertyValue(FlightBound.OUTBOUND, ['times', 'cancellationTime'])).format('DD/MM/YYYY HH:mm') : null,
                };
              },
              cellRendererFramework: AgGridTimeCellRenderer,
            },
            {
              colId: 'outbound.common.airportsIata',
              headerValueGetter() {
                return that.$i18n.t('common.to');
              },
              width: 90,
              cellClass: 'airport-from-cell',
              colCustomConfig: {
                flightBound: FlightBound.OUTBOUND,
              },
              valueGetter(params) {
                return params.data.connectedFlights.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'airportsIata']);
              },
            },
            {
              colId: 'outbound.common.handlingTypeCode',
              headerValueGetter() {
                return that.$i18n.t('common.handlingTypeShort');
              },
              width: 40,
              cellClass: 'handling-type-cell',
              colCustomConfig: {
                flightBound: FlightBound.OUTBOUND,
              },
              valueGetter(params) {
                return params.data.connectedFlights.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'handlingTypeCode']);
              },
            },
          ],
        },
        {
          headerValueGetter() {
            return that.$i18n.t('configuration.weighting');
          },
          children: [
            {
              colId: 'weighting.realWeight',
              headerValueGetter() {
                return that.$i18n.t('scheduling.realWeight');
              },
              headerName: `${this.$i18n.t('scheduling.realWeight')}`,
              width: 55,
              cellClass: 'separator',
              comparator: this.objectWithValueComparator,
              valueGetter(params) {
                return params.data.connectedFlights.weighting ? params.data.connectedFlights.weighting.real : null;
              },
            },
          ],
        },
        {
          headerValueGetter() {
            return that.$i18n.t('common.services');
          },
          children: [
            {
              colId: 'services.name',
              headerValueGetter() {
                return that.$i18n.t('invoicing.service');
              },
              width: 80,
              colCustomConfig: {
                flightBound: FlightBound.ANY,
              },
              valueGetter(params) {
                return params.data.service.name;
              },
            },
            {
              colId: 'services.inbound',
              headerValueGetter() {
                return that.$i18n.t('invoicing.arrivalService');
              },
              width: 80,
              colCustomConfig: {
                flightBound: FlightBound.ANY,
              },
              valueGetter(params) {
                return params.data.service.bound === 'INBOUND';
              },
              cellRendererFramework: AgGridIconCellRenderer,
              cellRendererParams: {
                icon: 'clear',
              },
            },
            {
              colId: 'services.outbound',
              headerValueGetter() {
                return that.$i18n.t('invoicing.departureService');
              },
              width: 80,
              colCustomConfig: {
                flightBound: FlightBound.ANY,
              },
              valueGetter(params) {
                return params.data.service.bound === 'OUTBOUND';
              },
              cellRendererFramework: AgGridIconCellRenderer,
              cellRendererParams: {
                icon: 'clear',
              },
            },
            {
              colId: 'services.minutes',
              headerValueGetter() {
                return that.$i18n.t('invoicing.minutes');
              },
              width: 60,
              colCustomConfig: {
                flightBound: FlightBound.ANY,
              },
              valueGetter(params) {
                return params.data.service.minutes;
              },
            },
            {
              colId: 'services.units',
              headerValueGetter() {
                return that.$i18n.t('configuration.units');
              },
              width: 60,
              colCustomConfig: {
                flightBound: FlightBound.ANY,
              },
              valueGetter(params) {
                return params.data.service.units;
              },
            },
            {
              colId: 'services.requester',
              headerValueGetter() {
                return that.$i18n.t('common.requester');
              },
              width: 60,
              colCustomConfig: {
                flightBound: FlightBound.ANY,
              },
              valueGetter(params) {
                return params.data.service.requester;
              },
            },
          ],
        },
      ],
    };
  },
  methods: {
    onGridReady(params) {
      this.setAgGridApi(params);
      this.showLoading();
      this.$store.commit(BILLING_CHANGE_GRID_API, params); // MOVE TO MIXIN?
      this.refreshCellsOnMutation();
      this.refreshCellsOnChangeLanguage();
    },
    onSelectionChanged(event) {
      const selectedNodes = event.api.getSelectedNodes();
      const selectedData = selectedNodes.map(node => node.data);
      this.$store.commit(BILLING_CHANGE_FLIGHT_SELECTION, selectedData);
    },
    isInbound(params) {
      return params.data.hasInbound();
    },
    isOutbound(params) {
      return params.data.hasOutbound();
    },
    resized() {
      if (this.gridApi) {
        this.gridApi.sizeColumnsToFit();
      }
    },
    getDataServiceRamp(startTime, finishTime) {
      if (!startTime || !finishTime) {
        return {
          value: '-',
          tooltip: null,
        };
      }
      return {
        value: TimeUtils.getDatesInMinutesDiff(finishTime, startTime),
        tooltip: `Start time: ${this.$moment(startTime).format('DD/MM/YYYY HH:mm')} - Finish time: ${this.$moment(finishTime).format('DD/MM/YYYY HH:mm')}`,
      };
    },
    renderNeededDiffs(differences, rowNode) {
      const { gridApi } = this.$store.state.billing;

      const agGridCustomService = new AgGridCustomService(this.columnDefs); // TODO one time
      const colIds = agGridCustomService.getColIdByMatchAnyfieldsRelated(this.joinAllPropertyPath(differences));

      if (colIds && colIds.length > 0) {
        colIds.forEach((colId) => {
          if (gridApi.getColumnDef(colId)) {
            gridApi.refreshCells({
              rowNodes: [rowNode],
              columns: [colId],
              force: true,
            });

            gridApi.flashCells({
              rowNodes: [rowNode],
              columns: [colId],
              force: true,
            });
          }
        });
      }
    },
    sizeColumnsToFit() {
      if (this.gridApi) {
        this.$store.dispatch(BILLING_FIT_COLUMNS_TO_VIEW);
      }
    },
    firstDataRendered() {
      this.$nextTick(() => {
        this.resized();
      });
    },
  },
};
</script>
<style lang="scss">
.row-total {
  font-weight: bold;
}

</style>
