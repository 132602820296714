<template>
  <v-layout row justify-center>
    <v-dialog v-model="dialog" persistent max-width="80%"
      :content-class="isContentVisible ? 'modal-common' : 'modal-common hide-title'">
      <v-card :dark="this.$vuetify.dark" :color="this.$vuetify.dark ? 'bggrey_dark' : 'bggrey'">
        <v-toolbar dark color="primary">
          <v-icon>{{ icon }}</v-icon>
          <v-toolbar-title>{{ element }} editor</v-toolbar-title>
          <v-spacer></v-spacer>
          <!-- Botón para contraer/expandir el contenido -->
          <v-btn icon @click="toggleContentVisibility">
            <v-icon>{{ isContentVisible ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
          </v-btn>
        </v-toolbar>
        <div v-show="isContentVisible">

        <loading-component/>
        <slot>
          <v-flex xs7 offset-xs5 offset-md5 offset-lg5>
            No content
          </v-flex>
        </slot>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn flat color="primary" @click.native="reject()">CANCEL</v-btn>
          <v-btn color="error" @click.native="confirm()" depressed :disabled="loading">SAVE</v-btn>
        </v-card-actions>
        </div>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import LoadingComponent from '@/components/layout/LoadingComponent.vue';

export default {
  name: 'DirectoryModalEdit',
  components: {
    LoadingComponent,
  },
  props: {
    icon: { type: String, required: true },
    element: { type: String, required: true },
    dialog: { type: Boolean, required: true },
    item: { type: Object, required: true },
  },
  data() {
    return {
      isContentVisible: true,
    };
  },
  computed: {
    loading() {
      return this.$store.state.loading;
    },
  },
  methods: {
    reject() {
      this.$emit('edit-reject');
    },
    confirm() {
      this.$emit('edit-confirm', this.item);
    },
    toggleContentVisibility() {
      this.isContentVisible = !this.isContentVisible;
    },
  },
};
</script>
