<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div class="ticketing-report-container">
    <div class="header">
      <v-toolbar dark color="primary lighten-1">
        <v-toolbar-title>
          <v-icon>confirmation_number</v-icon>
          {{ ticketing.airlineFareName }}
        </v-toolbar-title>
        <v-spacer class="hidden-md-and-up" />
        <v-toolbar-title class="hidden-md-and-up">
          {{ ticketing.serialNumber }}
          </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-title class="hidden-sm-and-down">
          {{$t("ticketing.ticketingInform")}} {{ ticketing.authorizationNumber ? `- Exp. ${ticketing.authorizationNumber}` : '' }}
          <v-chip light v-if="isEmitted || isCancelled">{{ticketing.status}}</v-chip>
        </v-toolbar-title>
        <v-spacer></v-spacer>
          <v-toolbar-title class="hidden-sm-and-down">
            {{this.$moment(ticketing.ticketingDate).format('DD/MM/YYYY')}}
          </v-toolbar-title>

        <v-menu v-if="isEmitted || isCancelled" left offset-y>
          <template v-slot:activator="{ on }">
            <v-btn flat icon v-on="on"
            ><v-icon>file_download</v-icon></v-btn
            >
          </template>
          <v-list>
            <v-list-tile-content>
              <v-list-tile-sub-title>
                <v-btn color="secondary" small flat v-on:click.stop="downloadPassengerTicket">
                  {{$t("ticketing.downloadTicketPax")}}
                </v-btn>
              </v-list-tile-sub-title>
              <v-list-tile-sub-title>
                <v-btn color="secondary" small flat v-on:click.stop="downloadAirlineTicket">
                  {{$t("ticketing.downloadAirlineTicket")}}
                </v-btn>
              </v-list-tile-sub-title>
            </v-list-tile-content>
          </v-list>
        </v-menu>
        <v-menu v-if="isEmitted || isCancelled" left offset-y>
          <template v-slot:activator="{ on }">
            <v-btn flat icon v-on="on"
            ><v-icon>print</v-icon></v-btn
            >
          </template>
          <v-list>
            <v-list-tile-content>
              <v-list-tile-sub-title>
                <v-btn color="secondary" small flat v-on:click.stop="print">
                  {{$t("ticketing.printTicketPax")}}
                </v-btn>
              </v-list-tile-sub-title>
              <v-list-tile-sub-title>
                <v-btn color="secondary" small flat v-on:click.stop="printAirlineTicket">
                  {{$t("ticketing.printAirlineTicket")}}
                </v-btn>
              </v-list-tile-sub-title>
            </v-list-tile-content>
          </v-list>
        </v-menu>
        <v-tooltip bottom v-if="isEmitted || isCancelled">
          <template v-slot:activator="{ on }">
            <v-btn flat icon v-on="on" v-on:click.stop="sendMail"
              ><v-icon>send</v-icon></v-btn
            >
          </template>
          <span>{{ $t('ticketing.mailTicket') }}</span>
        </v-tooltip>
      </v-toolbar>
    </div>
    <div class="content">
      <ticketing-report-form @save="save" @close="close" @save-and-emit="saveAndEmit" :ticketingEdited="ticketing"/>
    </div>
  </div>
</template>

<script>
import { saveAs } from 'file-saver';
// eslint-disable-next-line
import print from 'print-js';
import RolePermissions from '../../mixins/RolePermissions.vue';
import Resizable from '../../mixins/Resizable.vue';
import {
  TICKETING_EDITED_SAVED_INITIALIZED, TICKETING_EMIT,
  TICKETING_FLIGHT_FIND,
  TICKETING_FLIGHT_FIND_SUCCESS,
  TICKETING_FLIGHT_UPDATE, TICKETING_UPDATE_FAILURE, TICKETING_UPDATE_SUCCESS,
} from '../../../store/modules/ticketing';
import TicketingReportForm from './TicketingReportForm.vue';
import FileSerializable from '../../mixins/FileSerializable.vue';
import { REQUEST_ERROR, REQUEST_START, REQUEST_SUCCESS } from '../../../store/store';
import { NOTIFICATION_SHOW } from '../../../store/modules/notifications';
import { TicketingEmitTypes } from '../model/ticketingEmitTypes';
import { TicketingStatus } from '../model/ticketingStatus';

export default {
  name: 'ticketing-report-container',
  mixins: [Resizable, RolePermissions, FileSerializable],
  data: () => ({

  }),
  components: {
    TicketingReportForm,
  },
  computed: {
    ticketing() {
      return this.$store.state.ticketing.ticketingEdited;
    },
    rolePermission() {
      return `${this.$route.meta.role}_WR`;
    },
    isEmitted() {
      return this.ticketing.status === TicketingStatus.OK.description;
    },
    isCancelled() {
      return this.ticketing.status === TicketingStatus.CANCELLED.description;
    },
  },
  watch: {
    '$route.params.ticketingId': {
      handler() {
        this.getTicketing();
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    print() {
      this.$store.commit(REQUEST_START);
      this.$http
        .post(`flight-ticketing/export-pdf-ticket/${this.ticketing.id}`)
        .then((resp) => {
          const reader = new FileReader();
          this.$store.commit(REQUEST_SUCCESS);
          const blob = new Blob([this.s2ab(atob(resp.data.content))], {
            type: resp.data.contentType,
          });
          reader.readAsDataURL(blob);
          reader.onloadend = () => {
            const base64pdf = reader.result.split(',')[1];

            print({
              printable: base64pdf,
              type: 'pdf',
              base64: true,
              showModal: true,
            });
          };
        })
        .catch((err) => {
          this.$store.commit(REQUEST_ERROR, err);
          this.$store.commit(NOTIFICATION_SHOW, {
            message:
              err.response.data.title
              || this.$i18n.t('notifications.errorNotification'),
            color: 'error',
          });
        });
    },
    printAirlineTicket() {
      this.$store.commit(REQUEST_START);
      this.$http
        .post(`flight-ticketing/export-pdf-ticket-airline/${this.ticketing.id}`)
        .then((resp) => {
          this.$store.commit(REQUEST_SUCCESS);
          const blob = new Blob([this.s2ab(atob(resp.data.content))], {
            type: resp.data.contentType,
          });
          const reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = () => {
            const base64pdf = reader.result.split(',')[1];

            print({
              printable: base64pdf,
              type: 'pdf',
              base64: true,
              showModal: true,
            });
          };
        })
        .catch((err) => {
          this.$store.commit(REQUEST_ERROR, err);
          this.$store.commit(NOTIFICATION_SHOW, {
            message:
              err.response.data.title
              || this.$i18n.t('notifications.errorNotification'),
            color: 'error',
          });
        });
    },
    save() {
      this.resetTpvAndCashQuantity();
      this.$store.dispatch(TICKETING_FLIGHT_UPDATE, { ticketing: this.ticketing.serializeTicketing() });
    },
    resetTpvAndCashQuantity() {
      if (this.ticketing.paymentType === 'CASH') {
        this.ticketing.cashQuantity = this.ticketing.realQuantity;
        this.ticketing.cardQuantity = 0;
        this.ticketing.tpvType = null;
      } else if (this.ticketing.paymentType === 'CREDIT_CARD') {
        this.ticketing.cashQuantity = 0;
        this.ticketing.cardQuantity = this.ticketing.realQuantity;
      }
    },
    close() {
      this.$router.push({ name: this.$store.state.ticketing.previousPage });
      this.$store.commit(TICKETING_FLIGHT_FIND_SUCCESS, null);
    },
    saveAndEmit(emitType) {
      this.resetTpvAndCashQuantity();
      this.$store.commit(REQUEST_START);
      this.$store.commit(TICKETING_EDITED_SAVED_INITIALIZED);
      this.$http
        .post('flight-ticketing/emit', { flightTicketingDTO: this.ticketing.serializeTicketing(), flightTicketingEmitType: emitType })
        .then((resp) => {
          this.$store.commit(REQUEST_SUCCESS);
          this.$store.commit(TICKETING_UPDATE_SUCCESS);
          this.$store.dispatch(TICKETING_EMIT, resp.data.ticketing);
          if (emitType.includes(TicketingEmitTypes.PRINT)) {
            const reader = new FileReader();
            const blob = new Blob([this.s2ab(atob(resp.data.file.content))], {
              type: resp.data.contentType,
            });
            reader.readAsDataURL(blob);
            reader.onloadend = () => {
              const base64pdf = reader.result.split(',')[1];
              print({
                printable: base64pdf,
                type: 'pdf',
                base64: true,
                showModal: true,
              });
            };
          }
          if (emitType.includes(TicketingEmitTypes.MAIL)) {
            this.$store.commit(NOTIFICATION_SHOW, {
              message: this.$i18n.t('ticketing.mailSentSuccessfully'),
              color: 'success',
            });
          }
        })
        .catch((err) => {
          this.$store.commit(REQUEST_ERROR, err);
          this.$store.commit(TICKETING_UPDATE_FAILURE);
          this.$store.commit(NOTIFICATION_SHOW, {
            message:
              err.response.data.title
              || this.$i18n.t('notifications.errorNotification'),
            color: 'error',
          });
        });
    },
    getTicketing() {
      if (this.$route.params.ticketingId) {
        this.$store.dispatch(TICKETING_FLIGHT_FIND, this.$route.params.ticketingId);
      }
    },
    downloadPassengerTicket() {
      this.$store.commit(REQUEST_START);
      this.$http
        .post(`flight-ticketing/export-pdf-ticket/${this.ticketing.id}`)
        .then((resp) => {
          this.$store.commit(REQUEST_SUCCESS);
          const blob = new Blob([this.s2ab(atob(resp.data.content))], {
            type: resp.data.contentType,
          });
          saveAs(blob, resp.data.name);
        })
        .catch((err) => {
          this.$store.commit(REQUEST_ERROR, err);
          this.$store.commit(NOTIFICATION_SHOW, {
            message:
              err.response.data.title
              || this.$i18n.t('notifications.errorNotification'),
            color: 'error',
          });
        });
    },
    downloadAirlineTicket() {
      this.$store.commit(REQUEST_START);
      this.$http
        .post(`flight-ticketing/export-pdf-ticket-airline/${this.ticketing.id}`)
        .then((resp) => {
          this.$store.commit(REQUEST_SUCCESS);
          const blob = new Blob([this.s2ab(atob(resp.data.content))], {
            type: resp.data.contentType,
          });
          saveAs(blob, resp.data.name);
        })
        .catch((err) => {
          this.$store.commit(REQUEST_ERROR, err);
          this.$store.commit(NOTIFICATION_SHOW, {
            message:
              err.response.data.title
              || this.$i18n.t('notifications.errorNotification'),
            color: 'error',
          });
        });
    },
    sendMail() {
      this.$store.commit(REQUEST_START);
      this.$http
        .post(`flight-ticketing/send-mail/${this.ticketing.id}`)
        .then(() => {
          this.confirm();
          this.$store.commit(REQUEST_SUCCESS);
          this.$store.commit(NOTIFICATION_SHOW, {
            message: this.$i18n.t('ticketing.mailSentSuccessfully'),
            color: 'success',
          });
        })
        .catch((err) => {
          this.$store.commit(REQUEST_ERROR, err);
          this.$store.commit(NOTIFICATION_SHOW, {
            message:
              err.response.data.title
              || this.$i18n.t('notifications.errorNotification'),
            color: 'error',
          });
        });
    },
  },
  mounted() {
    this.getTicketing();
  },
};
</script>

<style lang="scss" scoped>
.ticketing-report-container .header {
  position: fixed;
  width: 100%;
  z-index: 1;
}
.ticketing-report-container .content {
  overflow: auto;
  padding-top: 7vh;
}
.ticketing-report-container .backButton {
  height: 0 !important;
}
</style>
