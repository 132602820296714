/* eslint no-param-reassign: "off" */
import moment from 'moment/moment';
import { HTTP } from '../../../http';
import { REQUEST_ERROR, REQUEST_START, REQUEST_SUCCESS } from '../../store';
import contingencyFilter from '../../../components/contingencyManagement/model/contingencyFilter';
import FlightContingencyFactory from '../../../components/shared/model/flightContingencyFactory';
import { NOTIFICATION_SHOW } from '../notifications';
import { createFlightContingencyEventFromData } from '../../../components/shared/model/flightContingencyEvent';
import FlightContingency from '../../../components/contingencyManagement/model/contingency';
import { JSUtils } from '../../../components/shared/js/JSUtils';
import i18n from '../../../plugins/i18n';
import { FLIGHT_CONTINGENCY_INFORMATION_CRUD_INIT, FLIGHT_CONTINGENCY_INFORMATION_FIND } from './contingencyInformation';
import { FLIGHT_CONTINGENCY_DETAIL_FIND } from './contingencyDetail';
import {
  FLIGHT_CONTINGENCY_BASIC_SERVICE_CRUD_INIT,
  FLIGHT_CONTINGENCY_BASIC_SERVICE_FIND,
} from './contingencyBasicService';
import { FLIGHT_CONTINGENCY_PASSAGE_STATE_FIND } from './contingencyPassageState';
import {
  FLIGHT_CONTINGENCY_HTL_TRANSPORT_CRUD_INIT,
  FLIGHT_CONTINGENCY_HTL_TRANSPORT_FIND,
} from './contingencyHtlTransport';

// actions names
export const FLIGHT_CONTINGENCIES_FIND = 'FLIGHT_CONTINGENCIES_FIND';
export const FLIGHT_CONTINGENCY_UPDATE = 'FLIGHT_CONTINGENCY_UPDATE';
export const FLIGHT_CONTINGENCY_DELETE = 'FLIGHT_CONTINGENCY_DELETE';
export const FLIGHT_CONTINGENCY_DO_EXTERNAL_CHANGE = 'FLIGHT_CONTINGENCY_DO_EXTERNAL_CHANGE';
export const FLIGHT_CONTINGENCY_FIND = 'FLIGHT_CONTINGENCY_FIND';
export const FLIGHT_CONTINGENCY_CLOSE = 'FLIGHT_CONTINGENCY_CLOSE';
export const FLIGHT_CONTINGENCY_OPEN = 'FLIGHT_CONTINGENCY_OPEN';
export const FLIGHT_CONTINGENCY_FIT_COLUMNS_TO_VIEW = 'FLIGHT_CONTINGENCY_FIT_COLUMNS_TO_VIEW';
export const FLIGHT_CONTINGENCY_FIT_COLUMNS_TO_BEST = 'FLIGHT_CONTINGENCY_FIT_COLUMNS_TO_BEST';
export const PRINT_FLIGHT_CONTINGENCY = 'PRINT_FLIGHT_CONTINGENCY';
export const FLIGHT_CONTINGENCY_INVOICE = 'FLIGHT_CONTINGENCY_INVOICE';
export const FLIGHT_CONTINGENCY_NOT_INVOICE = 'FLIGHT_CONTINGENCY_NOT_INVOICE';
export const FLIGHT_CONTINGENCY_RECALCULATE_FILTER = 'FLIGHT_CONTINGENCY_RECALCULATE_FILTER';
export const CONTINGENCY_COMPOSITION_UPDATE = 'CONTINGENCY_COMPOSITION_UPDATE';

// mutations
export const FLIGHT_CONTINGENCIES_SET_GRID_API = 'FLIGHT_CONTINGENCIES_SET_GRID_API';
export const FLIGHT_CONTINGENCIES_FIND_SUCCESS = 'FLIGHT_CONTINGENCIES_FIND_SUCCESS';
export const FLIGHT_CHANGE_CONTINGENCIES_SELECTION = 'FLIGHT_CHANGE_CONTINGENCIES_SELECTION';
export const FLIGHT_CONTINGENCY_FILTER_RESET = 'FLIGHT_CONTINGENCY_FILTER_RESET';
export const FLIGHT_CONTINGENCY_FILTER_RESET_TIMEZONE = 'FLIGHT_CONTINGENCY_FILTER_RESET_TIMEZONE';
export const FLIGHT_CONTINGENCY_FILTER_INCREASE_DAY = 'FLIGHT_CONTINGENCY_FILTER_INCREASE_DAY';
export const FLIGHT_CONTINGENCY_FILTER_DECREASE_DAY = 'FLIGHT_CONTINGENCY_FILTER_DECREASE_DAY';
export const FLIGHT_CONTINGENCY_FILTER_SET_UNIT_TIME = 'FLIGHT_CONTINGENCY_FILTER_SET_UNIT_TIME';
export const UPDATE_SHOW_OLD_VALUES = 'UPDATE_SHOW_OLD_VALUES';
export const UPDATE_FLASH_ON_CHANGES = 'UPDATE_FLASH_ON_CHANGES';
export const FLIGHT_CONTINGENCY_ON_EXTERNAL_CHANGE = 'FLIGHT_CONTINGENCY_ON_EXTERNAL_CHANGE';
export const FLIGHT_CONTINGENCY_SET_CHANGE = 'FLIGHT_CONTINGENCY_SET_CHANGE';
export const FLIGHT_CONTINGENCY_FIND_SUCCESS = 'FLIGHT_CONTINGENCY_FIND_SUCCESS';
export const FLIGHT_CONTINGENCY_UPDATE_FIT_COLUMNS_ON_RESIZE = 'FLIGHT_CONTINGENCY_UPDATE_FIT_COLUMNS_ON_RESIZE';


// initial state
const storeState = {
  gridApi: null,
  filter: contingencyFilter.createEmptyFilter,
  contingencies: [],
  selectedContingencies: [],
  contingenciesChanges: [],
  showOldValues: true,
  flashOnChanges: false,
  contingencyEdited: FlightContingency.createEmptyContingency(),
  fitColumnsOnResize: true,
};

// getters
const storeGetters = {
  hasSelectedContingencies: state => state.selectedContingencies.length > 0,
  isSelectedContingency: state => id => !!state.selectedContingencies.find(s => s.getId() === id),
};

// actions
const storeActions = {
  [FLIGHT_CONTINGENCIES_FIND]: ({ commit, state }) => {
    commit(REQUEST_START);
    commit(FLIGHT_CHANGE_CONTINGENCIES_SELECTION, []);
    return HTTP.post('flight-contingencies/find', state.filter)
      .then((resp) => {
        commit(REQUEST_SUCCESS);
        commit(
          FLIGHT_CONTINGENCIES_FIND_SUCCESS,
          FlightContingencyFactory.createFlightContingencyFromData(resp.data),
        );
        commit(FLIGHT_CHANGE_CONTINGENCIES_SELECTION, []);
      })
      .catch((err) => {
        commit(FLIGHT_CONTINGENCIES_FIND_SUCCESS, []);
        commit(REQUEST_ERROR, err);
      });
  },
  // eslint-disable-next-line
  [FLIGHT_CONTINGENCY_UPDATE]: ({ commit, dispatch }, { contingency }) => {
    commit(REQUEST_START);
    return HTTP.put('flight-contingencies', contingency)
      .then(() => {
        commit(REQUEST_SUCCESS);
        dispatch(FLIGHT_CONTINGENCIES_FIND);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.contingencyUpdate'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(REQUEST_ERROR, err);
      });
  },
  [FLIGHT_CONTINGENCY_DELETE]: ({ commit, dispatch }, ids) => {
    commit(REQUEST_START);
    return HTTP.post('flight-contingencies/batch-delete', ids)
      .then(() => {
        commit(REQUEST_SUCCESS);
        dispatch(FLIGHT_CONTINGENCIES_FIND);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.contingencyDelete'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
  [FLIGHT_CONTINGENCY_DO_EXTERNAL_CHANGE]: ({ commit }, change) => {
    commit(FLIGHT_CONTINGENCY_ON_EXTERNAL_CHANGE, createFlightContingencyEventFromData(change));
  },
  [FLIGHT_CONTINGENCY_FIND]: ({ commit }, id) => {
    commit(REQUEST_START);
    return HTTP.get(`flight-contingencies/${id}`)
      .then((resp) => {
        commit(REQUEST_SUCCESS);
        commit(FLIGHT_CONTINGENCY_FIND_SUCCESS, FlightContingencyFactory.createFlightContingencyFromData(resp.data));
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
  [FLIGHT_CONTINGENCY_CLOSE]: ({ commit, dispatch }, ids) => {
    commit(REQUEST_START);
    const lastIteration = (index, array) => index === array.length - 1;

    if (ids && ids.length > 0) {
      ids.forEach((s, index, array) => HTTP.patch(`flight-contingencies/close/${s}`)
        .then((resp) => {
          if (lastIteration(index, array)) {
            commit(REQUEST_SUCCESS);
            dispatch(FLIGHT_CONTINGENCIES_FIND);
            commit(FLIGHT_CONTINGENCY_FIND_SUCCESS, FlightContingencyFactory.createFlightContingencyFromData(resp.data));
            commit(NOTIFICATION_SHOW, {
              message: i18n.t('notifications.contingencyUpdate'),
              color: 'success',
            });
          }
        })
        .catch((err) => {
          commit(REQUEST_ERROR, err);
        }));
    }
  },
  [FLIGHT_CONTINGENCY_OPEN]: ({ commit, dispatch }, ids) => {
    commit(REQUEST_START);
    const lastIteration = (index, array) => index === array.length - 1;

    if (ids && ids.length > 0) {
      ids.forEach((s, index, array) => HTTP.patch(`flight-contingencies/open/${s}`)
        .then((resp) => {
          if (lastIteration(index, array)) {
            commit(REQUEST_SUCCESS);
            dispatch(FLIGHT_CONTINGENCIES_FIND);
            commit(FLIGHT_CONTINGENCY_FIND_SUCCESS, FlightContingencyFactory.createFlightContingencyFromData(resp.data));
            commit(NOTIFICATION_SHOW, {
              message: i18n.t('notifications.contingencyUpdate'),
              color: 'success',
            });
          }
        })
        .catch((err) => {
          commit(REQUEST_ERROR, err);
        }));
    }
  },
  [FLIGHT_CONTINGENCY_FIT_COLUMNS_TO_VIEW]: ({ state }) => {
    if (state.gridApi) {
      state.gridApi.sizeColumnsToFit();
    }
  },
  [FLIGHT_CONTINGENCY_FIT_COLUMNS_TO_BEST]: ({ state }) => {
    if (state.gridApi && state.gridApi.columnController && state.gridApi.columnController.columnApi) {
      state.gridApi.columnController.columnApi.autoSizeAllColumns();
    }
  },

  [PRINT_FLIGHT_CONTINGENCY]: ({ commit }, contingency) => {
    commit(REQUEST_START);
    return HTTP.post('flight-contingencies/print', contingency, { responseType: 'blob' })
      .then((response) => {
        commit(REQUEST_SUCCESS);
        const filename = JSUtils.getFilenameFromContentDisposition(response.headers['content-disposition']);
        JSUtils.downloadFile(response.data, filename);
      })
      .catch((err) => {
        commit(REQUEST_ERROR, err);
      });
  },
  [FLIGHT_CONTINGENCY_INVOICE]: ({ commit, dispatch }, ids) => {
    commit(REQUEST_START);
    const lastIteration = (index, array) => index === array.length - 1;

    if (ids && ids.length > 0) {
      ids.forEach((s, index, array) => HTTP.patch(`flight-contingencies/invoice/${s}`)
        .then((resp) => {
          if (lastIteration(index, array)) {
            commit(REQUEST_SUCCESS);
            dispatch(FLIGHT_CONTINGENCIES_FIND);
            commit(FLIGHT_CONTINGENCY_FIND_SUCCESS, FlightContingencyFactory.createFlightContingencyFromData(resp.data));
            commit(NOTIFICATION_SHOW, {
              message: i18n.t('notifications.contingencyUpdate'),
              color: 'success',
            });
          }
        })
        .catch((err) => {
          commit(REQUEST_ERROR, err);
        }));
    }
  },
  [FLIGHT_CONTINGENCY_NOT_INVOICE]: ({ commit, dispatch }, ids) => {
    commit(REQUEST_START);
    const lastIteration = (index, array) => index === array.length - 1;

    if (ids && ids.length > 0) {
      ids.forEach((s, index, array) => HTTP.patch(`flight-contingencies/not-invoice/${s}`)
        .then((resp) => {
          if (lastIteration(index, array)) {
            commit(REQUEST_SUCCESS);
            dispatch(FLIGHT_CONTINGENCIES_FIND);
            commit(FLIGHT_CONTINGENCY_FIND_SUCCESS, FlightContingencyFactory.createFlightContingencyFromData(resp.data));
            commit(NOTIFICATION_SHOW, {
              message: i18n.t('notifications.contingencyUpdate'),
              color: 'success',
            });
          }
        })
        .catch((err) => {
          commit(REQUEST_ERROR, err);
        }));
    }
  },
  [FLIGHT_CONTINGENCY_RECALCULATE_FILTER]: ({ commit, state }) => {
    if (state.filter.dateFrom === null && state.filter.dateTo === null) {
      commit(FLIGHT_CONTINGENCY_FILTER_RESET);
    } else {
      commit(FLIGHT_CONTINGENCY_FILTER_RESET_TIMEZONE, state.filter.unitOfTime);
    }
  },
  [CONTINGENCY_COMPOSITION_UPDATE]: ({ commit, dispatch }, contingencyComposition) => {
    commit(REQUEST_START);
    return HTTP.put('flight-contingencies/update', contingencyComposition)
      .then((resp) => {
        const contingency = FlightContingencyFactory.createContingencyCompositionFromData(resp.data);
        commit(REQUEST_SUCCESS);
        dispatch(FLIGHT_CONTINGENCIES_FIND);
        dispatch(FLIGHT_CONTINGENCY_INFORMATION_FIND, contingency.contingency.id);
        dispatch(FLIGHT_CONTINGENCY_DETAIL_FIND, contingency.contingency.id);
        dispatch(FLIGHT_CONTINGENCY_BASIC_SERVICE_FIND, contingency.contingency.id);
        dispatch(FLIGHT_CONTINGENCY_PASSAGE_STATE_FIND, contingency.contingency.id);
        dispatch(FLIGHT_CONTINGENCY_HTL_TRANSPORT_FIND, contingency.contingency.id);
        dispatch(FLIGHT_CONTINGENCY_BASIC_SERVICE_CRUD_INIT);
        dispatch(FLIGHT_CONTINGENCY_HTL_TRANSPORT_CRUD_INIT);
        dispatch(FLIGHT_CONTINGENCY_INFORMATION_CRUD_INIT);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.contingencyUpdate'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(REQUEST_ERROR, err);
      });
  },
};

// mutations
const storeMutations = {
  [FLIGHT_CONTINGENCIES_SET_GRID_API]: (state, gridApi) => {
    state.gridApi = gridApi.api;
  },
  [FLIGHT_CONTINGENCIES_FIND_SUCCESS]: (state, contingencies) => {
    state.contingencies = contingencies !== null ? contingencies : {};
  },
  [FLIGHT_CHANGE_CONTINGENCIES_SELECTION]: (state, selection) => {
    state.selectedContingencies = selection;
  },
  [FLIGHT_CONTINGENCY_FILTER_RESET]: (state) => {
    state.filter.dateFrom = moment().startOf('day');
    state.filter.dateTo = moment().endOf('day');
  },
  [FLIGHT_CONTINGENCY_FILTER_RESET_TIMEZONE]: (state, unitOfTime) => {
    state.filter.unitOfTime = unitOfTime != null ? unitOfTime : 'day';
    state.filter.dateFrom = moment().startOf(state.filter.unitOfTime)
      .year(state.filter.dateFrom.year())
      .month(state.filter.dateFrom.month())
      .date(state.filter.dateFrom.date());
    state.filter.dateTo = moment().endOf(state.filter.unitOfTime)
      .year(state.filter.dateTo.year())
      .month(state.filter.dateTo.month())
      .date(state.filter.dateTo.date());
  },
  [FLIGHT_CONTINGENCY_FILTER_INCREASE_DAY]: (state) => {
    state.filter.dateFrom = moment().startOf('day')
      .year(state.filter.dateFrom.year())
      .month(state.filter.dateFrom.month())
      .date(state.filter.dateFrom.date())
      .add(1, 'days')
      .startOf('day');

    state.filter.dateTo = moment().endOf('day')
      .year(state.filter.dateTo.year())
      .month(state.filter.dateTo.month())
      .date(state.filter.dateTo.date())
      .add(1, 'days');
  },
  [FLIGHT_CONTINGENCY_FILTER_DECREASE_DAY]: (state) => {
    state.filter.dateFrom = moment().startOf('day')
      .year(state.filter.dateFrom.year())
      .month(state.filter.dateFrom.month())
      .date(state.filter.dateFrom.date())
      .subtract(1, 'days');

    state.filter.dateTo = moment().endOf('day')
      .year(state.filter.dateTo.year())
      .month(state.filter.dateTo.month())
      .date(state.filter.dateTo.date())
      .subtract(1, 'days')
      .endOf('day');
  },
  [FLIGHT_CONTINGENCY_FILTER_SET_UNIT_TIME]: (state, unitOfTime) => {
    state.filter.unitOfTime = unitOfTime;
    if (unitOfTime) {
      state.filter.dateFrom = moment().startOf(unitOfTime);
      state.filter.dateTo = moment().endOf(unitOfTime);
    }
  },
  [UPDATE_SHOW_OLD_VALUES]: (state, showOldValues) => {
    state.showOldValues = showOldValues;
  },
  [UPDATE_FLASH_ON_CHANGES]: (state, flashOnChanges) => {
    state.flashOnChanges = flashOnChanges;
  },
  [FLIGHT_CONTINGENCY_ON_EXTERNAL_CHANGE]: (state, change) => {
    console.log(`mutations external change ${change}`); // eslint-disable-line no-console
  },
  [FLIGHT_CONTINGENCY_SET_CHANGE]: (state, change) => {
    change.ts = moment();
    state.contingenciesChanges.unshift(change);
    state.contingenciesChanges = state.contingenciesChanges.slice(0, 10);
  },
  [FLIGHT_CONTINGENCY_FIND_SUCCESS]: (state, contingency) => {
    state.contingencyEdited = contingency !== null ? contingency : FlightContingency.createEmptyContingency();
  },
  [FLIGHT_CONTINGENCY_UPDATE_FIT_COLUMNS_ON_RESIZE]: (state, fitColumnsOnResize) => {
    state.fitColumnsOnResize = fitColumnsOnResize;
  },
};

export default {
  state: storeState,
  getters: storeGetters,
  actions: storeActions,
  mutations: storeMutations,
};
