import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,[_c(VLayout,{attrs:{"row":"","align-center":""}},[_c(VFlex,{attrs:{"xs6":""}},[_c(VCardTitle,{attrs:{"primary-title":""}},[_c('div',[_c('div',{staticClass:"headline"},[_vm._v(_vm._s(_vm.$t("administration.importFileRampServiceTitle")))]),_c('div',{staticClass:"grey--text text--darken-1"},[_vm._v(_vm._s(_vm.$t("administration.importFileRampServiceSubTitle")))]),_c('div',{staticClass:"grey--text text--darken-1"},[_vm._v(_vm._s(_vm.$t("administration.importFileRampServiceText")))])])])],1),_c(VFlex,{attrs:{"xs4":""}},[_c(VDialog,{attrs:{"persistent":"","max-width":"1370px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VBtn,{attrs:{"slot":"activator","color":"primary","disabled":_vm.loading},slot:"activator"},[_vm._v(_vm._s(_vm.$t("common.importFile")))]),_c(VCard,{attrs:{"dark":this.$vuetify.dark,"color":this.$vuetify.dark ? 'bggrey_dark' : 'bggrey'}},[_c(VToolbar,{attrs:{"dark":"","color":"primary"}},[_c(VIcon,[_vm._v("attach_file")]),_c(VToolbarTitle,[_vm._v(_vm._s(_vm.$t("rampServiceImport.importRampServicesFromFile")))])],1),_c('loading-component'),_c(VCardText,[_c('ramp-service-import-stepper',{on:{"import-stepper-cancel":_vm.reject,"import-stepper-confirm":_vm.confirm}})],1)],1)],1)],1),_c(VFlex,{attrs:{"xs1":""}},[_c(VBtn,{staticStyle:{"margin":"0 auto"},attrs:{"color":"primary","disabled":_vm.loading},on:{"click":_vm.downloadTemplate}},[_vm._v(_vm._s(_vm.$t("common.downloadTemplate")))])],1)],1),_c(VDivider,{attrs:{"light":""}}),_c('AdminResult',{attrs:{"is-error":_vm.isError,"is-success":_vm.isSuccess,"loading":_vm.loading,"time":_vm.time,"traces":_vm.traces}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }