<template>
  <v-expansion-panel v-model="panel" expand class="exp-header">
    <delete-confirmation-dialog v-if="showConfirmDialog" :dialog="showConfirmDialog" text="" @delete-confirm="departmentDelete" @delete-reject="deleteDepartmentReject"/>

    <template>
      <directory-modal-edit v-if="departmentDialogEdit" :dialog="departmentDialogEdit" :item="itemDepartmentEdit" @edit-confirm="directoryFileEditConfirm" @edit-reject="departmentEditReject" element="Department" icon="filter_center_focus">
        <department-edit-modal-content :item="itemDepartmentEdit"/>
      </directory-modal-edit>
    </template>

    <template>
      <directory-modal-edit v-if="memberDialogEdit" :dialog="memberDialogEdit" :item="itemMemberEdit" @edit-confirm="memberFileEditConfirm" @edit-reject="memberEditReject" icon="account_circle" element="Member">
        <member-edit-modal-content :member="itemMemberEdit"/>
      </directory-modal-edit>
    </template>
    <v-expansion-panel-content v-for="(department, index) in departments" :key="index" expand-icon="mdi-menu-down">
      <template v-slot:header>
        <div class="pl-4 py-3">
          <h4 class="headline font-weight-light"><v-icon left dark>bookmarks</v-icon>{{ department.title }}</h4>
        </div>
      </template>
      <v-card>
        <v-card-text pt-0>
          <v-container py-0>
            <v-layout row wrap>
              <v-layout mb-2>
                <v-flex xs7>
                  <card-info-two-column v-if="!isEmpty(department)" :item="department"/>
                  <v-container v-if="isEmpty(department)" fill-height>
                    <v-layout row align-center>
                      <v-flex xs12 text-xs-center>
                        <h3 class="subheading">There is no department info</h3>
                        <v-btn @click="departmentEdit(department)" flat color="primary">
                          <v-icon left>folder</v-icon>Edit department
                        </v-btn>
                      </v-flex>
                    </v-layout>
                  </v-container>
                </v-flex>
                <v-flex xs5>
                  <v-img :src="cardImage(department)" height="300px"/>
                    <v-flex>
                      <p>{{ department.description }}</p>
                    </v-flex>
                </v-flex>
              </v-layout>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-tooltip bottom>
          <span>Edit directory</span>
        </v-tooltip>
      <v-card-actions>
        <v-container fluid>
          <v-layout row justify-space-between>
            <v-flex xs2>
            </v-flex>
            <v-flex xs2 class="text-xs-center">
              <v-btn icon @click="show = !show">
                <v-icon x-large>{{ show ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}</v-icon>
              </v-btn>
            </v-flex>
            <v-flex xs2 class="text-xs-right">
              <v-menu bottom left>
                <template v-slot:activator="{ on }">
                  <v-btn icon v-on="on" >
                    <v-icon>more_vert</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-tile @click="departmentEdit(department)">
                    <v-list-tile-title>
                      <v-icon left>edit</v-icon>Edit
                    </v-list-tile-title>
                  </v-list-tile>
                  <v-list-tile @click="showDeleteDialog(department)">
                    <v-list-tile-title>
                      <v-icon left>delete</v-icon>Delete
                    </v-list-tile-title>
                  </v-list-tile>
                  <v-list-tile @click="addNewMember(department)">
                    <v-list-tile-title>
                      <v-icon left>person_add</v-icon>Add member
                    </v-list-tile-title>
                  </v-list-tile>
                </v-list>
              </v-menu>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-actions>
        <v-slide-y-transition>
          <v-card-text v-show="show">
            <empty-state v-if="!department.members.length" @add-item="addNewMember(department)" msg="There is no members associated to this department" btnIcon="person_add" btnText="Add member" />
            <v-container v-if="department.members" fluid grid-list-sm py-0>
              <v-layout row wrap>
                <v-flex py-0>
                  <member-card :department="department" @click-edit-item="memberEdit" @click-delete-item="memberDelete"/>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
        </v-slide-y-transition>
      </v-card>
    </v-expansion-panel-content>
    <directory-speed-dial @click-new-item="addNewDepartment" @click-collapse-all="toggleCollapseAll"/>
  </v-expansion-panel>
</template>
<script>
import {
  CREATE_DIRECTORY_FILE,
  UPDATE_DIRECTORY_FILE,
  DELETE_DIRECTORY_FILE,
  CREATE_DIRECTORY_MEMBER,
  UPDATE_DIRECTORY_MEMBER,
  DELETE_DIRECTORY_MEMBER,
} from '@/store/modules/directory';
import { BASE_URL } from '@/http';
import CardInfoSingleColumn from './CardInfoSingleColumn.vue';
import CardInfoTwoColumn from './CardInfoTwoColumn.vue';
import CardDescriptionText from './CardDescriptionText.vue';
import MemberCard from './MemberCard.vue';
import DirectoryModalEdit from './common/DirectoryModalEdit.vue';
import MemberEditModalContent from './common/MemberEditModalContent.vue';
import DepartmentEditModalContent from './common/DepartmentEditModalContent.vue';
import EmptyState from './common/EmptyState.vue';
import DirectorySpeedDial from './common/DirectorySpeedDial.vue';
import DeleteConfirmationDialog from './common/DeleteConfirmationDialog.vue';

export default {
  name: 'ExpansionPanel',
  props: {
    departments: {
      type: Array,
      required: true,
    },
  },
  components: {
    CardInfoSingleColumn,
    CardInfoTwoColumn,
    CardDescriptionText,
    MemberCard,
    DirectoryModalEdit,
    MemberEditModalContent,
    DepartmentEditModalContent,
    EmptyState,
    DirectorySpeedDial,
    DeleteConfirmationDialog,
  },
  data: () => ({
    panel: [],
    show: false,
    collapsed: false,
    departmentDialogEdit: false,
    memberDialogEdit: false,
    itemDepartmentEdit: null,
    itemMemberEdit: null,
    itemDelete: null,
    showConfirmDialog: false,
    department: null,
    member: null,
    icon: null,
    element: null,
  }),
  methods: {
    cardImage(department) {
      return `${BASE_URL}directory-files/images/${department.order}/${department.id}`;
    },
    toggleCollapseAll() {
      this.show = false;
      this.collapsed = !this.collapsed;
      this.panel = Array(this.departments.length).fill(this.collapsed);
    },
    addNewMember(department) {
      if (!department.members) {
        this.itemDepartmentEdit = { ...department, members: [] };
      } else {
        this.itemDepartmentEdit = department;
      }

      this.itemMemberEdit = {
        contact: {},
      };
      this.memberDialogEdit = true;
    },
    memberEdit(department, member) {
      this.itemDepartmentEdit = department;
      this.itemMemberEdit = member;
      this.memberDialogEdit = true;
    },
    addNewDepartment() {
      this.itemDepartmentEdit = {
        contact: {},
      };
      this.departmentDialogEdit = true;
    },
    departmentEdit(department) {
      this.itemDepartmentEdit = department;
      this.departmentDialogEdit = true;
    },
    showDeleteDialog(item) {
      this.itemDelete = item;
      this.showConfirmDialog = true;
    },
    directoryFileEditConfirm(item) {
      this.$store.dispatch(item.id ? UPDATE_DIRECTORY_FILE : CREATE_DIRECTORY_FILE, item);
      this.departmentDialogEdit = false;
    },
    departmentDelete() {
      if (this.itemDelete) {
        this.$store.dispatch(DELETE_DIRECTORY_FILE, this.itemDelete.id);
      }
      this.itemDelete = null;
      this.showConfirmDialog = false;
    },
    deleteDepartmentReject() {
      this.itemDelete = null;
      this.showConfirmDialog = false;
    },
    departmentEditReject() {
      this.department = null;
      this.departmentDialogEdit = false;
    },
    memberFileEditConfirm() {
      this.$store.dispatch(this.itemMemberEdit.id ? UPDATE_DIRECTORY_MEMBER : CREATE_DIRECTORY_MEMBER, {
        ...{ directoryFile: this.itemDepartmentEdit },
        ...{ directoryMember: this.itemMemberEdit },
      });
      this.memberDialogEdit = false;
    },
    memberDelete(item) {
      this.$store.dispatch(DELETE_DIRECTORY_MEMBER, item.id);
      this.memberDialogEdit = false;
    },
    memberEditReject() {
      this.member = null;
      this.memberDialogEdit = false;
    },
    isEmpty(item) {
      const clone = Object.assign({}, item);
      delete clone.title;
      delete clone.id;
      delete clone.order;
      delete clone.map;
      delete clone.members;
      return Object.values(clone).every(i => (i === null || i === ''));
    },
  },
};
</script>

<style scoped>
.card-title-background {
  background-color: black;
  opacity: 0.6;
  filter: alpha(opacity=60); /* For IE8 and earlier */
}

.exp-header li {
  background-color: #fe0900 !important;
  color: white !important;
  border-bottom: 1px solid white;
}
</style>

<style>
#app > div.application--wrap > main > div > div.container > div > div > div > div.flex.xs8 > div > ul > li > div.v-expansion-panel__header > div.v-expansion-panel__header__icon > i {
  color: white !important;
}
</style>
