<template>
  <div class="ag-grid-connected-flights">
    <ag-grid-dialog-template-editor :is-dialog-open="isDialogOpen" @save="save" @cancel="cancel"
      :saveDisabled="invalidData || !validForm" modalMaxWidth="100%">
      <template v-slot:header>
        {{ $t("operations.dialogPassageOutBoundTitle") }} {{ flightNumber }}
      </template>

      <template v-slot:default>
        <ag-grid-vue class="ag-theme-material ag-grid-connected-flights" :columnDefs="columnDefs"
          :defaultColDef="defaultColDef" @grid-ready="setAgGridApi" :gridOptions="gridOptions"
          :frameworkComponents="frameworkComponents" :rowData="rowData">
        </ag-grid-vue>
        <v-form ref="form" v-model="validForm">
          <v-container fluid grid-list-sm class="form-container">
            <v-layout align-space-between justify-center column fill-height>
              <label class="primary--text subheading">{{ $t("operations.cabinDistributionLabel") }}</label>
              <v-layout align-center justify-space-between row fill-height>
                <v-flex xs12 sm6 md3>
                  <v-text-field label="0A" v-model="flightPassageInformation.cabin0A" type="number"
                    :rules="[rules.max500]" :background-color="errorCabinDistribution" box
                    @change="validateCabinDistribution" :readonly="!hasPermissions(rolePermission)" />
                </v-flex>
                <v-flex xs12 sm6 md3>
                  <v-text-field label="0B" v-model="flightPassageInformation.cabin0B" type="number"
                    :rules="[rules.max500]" :background-color="errorCabinDistribution" box
                    @change="validateCabinDistribution" :readonly="!hasPermissions(rolePermission)" />
                </v-flex>
                <v-flex xs12 sm6 md3>
                  <v-text-field label="0C" v-model="flightPassageInformation.cabin0C" type="number"
                    :rules="[rules.max500]" :background-color="errorCabinDistribution" box
                    @change="validateCabinDistribution" :readonly="!hasPermissions(rolePermission)" />
                </v-flex>
                <v-flex xs12 sm6 md3>
                  <v-text-field label="0D" v-model="flightPassageInformation.cabin0D" type="number"
                    :rules="[rules.max500]" :background-color="errorCabinDistribution" box
                    @change="validateCabinDistribution" :readonly="!hasPermissions(rolePermission)" />
                </v-flex>
                <v-flex xs12 sm6 md3>
                  <v-text-field label="0E" v-model="flightPassageInformation.cabin0E" type="number"
                    :rules="[rules.max500]" :background-color="errorCabinDistribution" box
                    @change="validateCabinDistribution" :readonly="!hasPermissions(rolePermission)" />
                </v-flex>
                <v-flex xs12 sm6 md3>
                  <v-text-field label="0F" v-model="flightPassageInformation.cabin0F" type="number"
                    :rules="[rules.max500]" :background-color="errorCabinDistribution" box
                    @change="validateCabinDistribution" :readonly="!hasPermissions(rolePermission)" />
                </v-flex>
                <v-flex xs12 sm6 md3>
                  <v-text-field label="Conmail" v-model="flightPassageInformation.comail" type="number"
                    :rules="[rules.max500]" :background-color="errorCabinDistribution" box
                    @change="validateCabinDistribution" :readonly="!hasPermissions(rolePermission)" />
                </v-flex>
                <v-flex xs12 sm6 md3>
                  <v-text-field v-model="flightPassageInformation.jumpseats" label="JMP" type="number"
                    :rules="[rules.max100]" box :readonly="!hasPermissions(rolePermission)" />
                </v-flex>
              </v-layout>
            </v-layout>
            <v-layout align-space-between justify-center column fill-height>
              <label class="primary--text subheading">{{ $t("operations.moreInformationLabel") }}</label>
              <v-layout align-center justify-space-between row fill-height>
                <v-flex xs12 sm6 md3>
                  <v-text-field v-model="flightPassageInformation.over" label="OVER" type="number" box />
                </v-flex>
                <v-flex xs12 sm6 md3>
                  <v-text-field v-model="flightPassageInformation.lastMinuteChangesPax" label="LMC" type="number" box />
                </v-flex>
                <v-flex xs12 sm6 md3>
                  <v-text-field v-model="flightPassageInformation.lastMinuteChangesInf" label="LMC Inf" type="number"
                    box />
                </v-flex>
                <v-flex xs12 sm6 md3>
                  <v-text-field v-model="flightPassageInformation.lastMinuteChangesBag" label="LMC Bags" type="number"
                    box />
                </v-flex>
                <v-flex xs12 sm6 md3>
                  <v-text-field v-model="flightPassageInformation.avih" label="AVIH" type="number" box
                    :readonly="!hasPermissions(rolePermission)" />
                </v-flex>
                <v-flex xs12 sm6 md3>
                  <v-text-field v-model="flightPassageInformation.stcr" label="STCR" type="number"
                    :rules="[rules.max100]" box :readonly="!hasPermissions(rolePermission)" />
                </v-flex>
                <v-flex xs12 sm6 md3>
                  <v-text-field v-model="flightPassageInformation.totalPriority" label="PRIORITY" type="number"
                    :rules="[rules.max500]" box :readonly="!hasPermissions(rolePermission)" />
                </v-flex>
                <v-flex xs12 sm6 md3>
                  <v-text-field v-model="flightPassageInformation.connectionPax" label="Connecting PAX" type="number"
                    :rules="[rules.max500]" box :readonly="!hasPermissions(rolePermission)" />
                </v-flex>
                <v-flex xs12 sm6 md3>
                  <v-text-field v-model="flightPassageInformation.localPaxCheckInCounters" label="CKIN" type="number"
                    :rules="[rules.max500]" box :readonly="!hasPermissions(rolePermission)" />
                </v-flex>
                <v-flex xs12 sm6 md3>
                  <v-text-field v-model="flightPassageInformation.localPaxCheckInWeb" label="WWW" type="number"
                    :rules="[rules.max500]" box :readonly="!hasPermissions(rolePermission)" />
                </v-flex>
                <v-flex xs12 sm6 md3>
                  <v-text-field v-model="flightPassageInformation.localPaxCheckInKiosks" label="SSK" type="number"
                    :rules="[rules.max500]" box :readonly="!hasPermissions(rolePermission)" />
                </v-flex>
              </v-layout>
            </v-layout>
            <v-layout align-center justify-space-between row fill-height>
              <v-flex>
                <label class="primary--text subheading">{{ $t("operations.remarksExtended") }}</label>
                <v-textarea box class="mt-1 remarks-form" rows="1" v-model="flightPassageInformation.remarks"
                  :readonly="!hasPermissions(rolePermission)"></v-textarea>
              </v-flex>
            </v-layout>
            <v-layout align-center justify-space-between row fill-height v-if="isInbound">
              <v-flex class="mt-2">
                <label class="primary--text subheading">{{
      $t("operations.dialogPassageOutBoundArrivalPassengerOutboundAgent") }}</label>
                <v-text-field box v-model="flightPassageInformation.arrivalServicesAgent"
                  :readonly="!hasPermissions(rolePermission)"></v-text-field>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </template>
    </ag-grid-dialog-template-editor>
  </div>
</template>

<script>
import Vue from 'vue';
import { AgGridVue } from 'ag-grid-vue';
import AgGridDialogParentCellEditor from '../../mixins/AgGridDialogParentCellEditor.vue';
import AgGridDialogTemplateEditor from '../../templates/AgGridDialogTemplateEditor.vue';
import AgGridNumberCellEditor from '../../AgGridNumberCellEditor.vue';
import AgGridOptionsConfigurable from '../../../mixins/AgGridOptionsConfigurable.vue';
import { cellClassAgGridRules } from '../../../mixins/cellClassAgGridRules';
import AgGridValueWithBackgroundCellRenderer
from '../../../cell-renderers/AgGridValueWithBackgroundCellRenderer.vue';
import FlightPassageFactory from '../../../../shared/model/FlightPassageFactory';
import { FlightBound } from '../../../../shared/model/flightBound';

export default Vue.extend({
  mixins: [AgGridDialogParentCellEditor, AgGridOptionsConfigurable],
  components: {
    AgGridDialogTemplateEditor,
    AgGridVue,
  },
  data() {
    const that = this;
    return {
      frameworkComponents: {
        AgGridNumberCellEditor,
        AgGridValueWithBackgroundCellRenderer,
      },
      defaultColDef: {
        cellClassRules: cellClassAgGridRules,
      },
      gridOptions: {
        headerHeight: 30,
        // eslint-disable-next-line
        getRowStyle: function (params) {
          // if dark mode is enabled, return the dark mode style
          if (that.$vuetify.dark) {
            if (params.data.isTotalPaxOnBoardedPerAirport) {
              return { background: '#2d2d2d' };
            } if (params.data.isTotalPaxOnBoardedPerAirport == null) {
              return { background: '#3c3c3c' };
            }
          }
          if (params.data.isTotalPaxOnBoardedPerAirport) {
            return { background: '#cfcfcf' };
          } if (params.data.isTotalPaxOnBoardedPerAirport == null) {
            return { background: '#ececec' };
          }
          return { background: 'none' };
        },
      },
      flightPassageInformation: [],
      rowData: [],
      invalidData: true,
      errorMessagesValidate: [],
      totalPaxOnBoard: 0,
      errorCabinDistribution: that.$vuetify.dark ? 'grey darken-3' : 'grey lighten-3',
      validForm: true,
      rules: {
        max100: value => value <= 100 || this.$i18n.t('validation.fieldMax100'),
        max500: value => value <= 500 || this.$i18n.t('validation.fieldMax500'),

      },
      columnDefs: [
        {
          headerName: '',
          children: [
            {
              colId: 'dest',
              field: 'destLabel',
              headerValueGetter() {
                return that.$i18n.t('operations.destination');
              },
              editable: false,
              width: 130,
              minWidth: 130,
              cellClass(params) {
                return that.isDataAirport(params) ? 'airport-data' : null;
              },
            },
            {
              colId: 'male',
              field: 'male',
              headerValueGetter() {
                return that.$i18n.t('operations.passengersMale');
              },
              width: 45,
              minWidth: 45,
              editable: this.isCellEditable,
              cellEditor: 'AgGridNumberCellEditor',
              onCellValueChanged: this.sumTotal,
              valueGetter(params) {
                return {
                  value: params.data.male,
                  error: params.data.messageValidate.male.errorExist,
                };
              },
              cellRendererFramework: AgGridValueWithBackgroundCellRenderer,
              cellClass(params) {
                return that.isDataAirport(params) ? 'airport-data' : null;
              },
            },
            {
              colId: 'female',
              field: 'female',
              headerValueGetter() {
                return that.$i18n.t('operations.passengersFemale');
              },
              width: 45,
              minWidth: 45,
              editable: this.isCellEditable,
              cellEditor: 'AgGridNumberCellEditor',
              onCellValueChanged: this.sumTotal,
              valueGetter(params) {
                return {
                  value: params.data.female,
                  error: params.data.messageValidate.female.errorExist,
                };
              },
              cellRendererFramework: AgGridValueWithBackgroundCellRenderer,
              cellClass(params) {
                return that.isDataAirport(params) ? 'airport-data' : null;
              },
            },
            {
              colId: 'child',
              field: 'child',
              headerValueGetter() {
                return that.$i18n.t('operations.passengersChild');
              },
              width: 45,
              minWidth: 45,
              editable: this.isCellEditable,
              cellEditor: 'AgGridNumberCellEditor',
              onCellValueChanged: this.sumTotal,
              valueGetter(params) {
                return {
                  value: params.data.child,
                  error: params.data.messageValidate.child.errorExist,
                };
              },
              cellRendererFramework: AgGridValueWithBackgroundCellRenderer,
              cellClass(params) {
                return that.isDataAirport(params) ? 'airport-data' : null;
              },
            },
            {
              colId: 'infant',
              field: 'infant',
              headerValueGetter() {
                return that.$i18n.t('operations.passengersInfant');
              },
              width: 45,
              minWidth: 45,
              editable: this.isCellEditable,
              cellEditor: 'AgGridNumberCellEditor',
              onCellValueChanged: this.sumTotal,
              valueGetter(params) {
                return {
                  value: params.data.infant,
                  error: params.data.messageValidate.infant.errorExist,
                };
              },
              cellRendererFramework: AgGridValueWithBackgroundCellRenderer,
              cellClass(params) {
                return that.isDataAirport(params) ? 'airport-data' : null;
              },
            },
          ],
        },
        {
          headerName: 'PAX',
          children: [
            {
              colId: 'paxFirstClass',
              field: 'paxFirstClass',
              headerName: 'Class 1',
              width: 45,
              minWidth: 45,
              editable: this.isCellEditable,
              cellEditor: 'AgGridNumberCellEditor',
              onCellValueChanged: this.sumTotal,
              valueGetter(params) {
                return {
                  value: params.data.paxFirstClass,
                  error: params.data.messageValidate.paxFirstClass.errorExist,
                };
              },
              cellRendererFramework: AgGridValueWithBackgroundCellRenderer,
              cellClass(params) {
                return that.isDataAirport(params) ? 'airport-data' : null;
              },
            },
            {
              colId: 'paxSecondClass',
              field: 'paxSecondClass',
              headerName: 'Class 2',
              width: 45,
              minWidth: 45,
              editable: this.isCellEditable,
              cellEditor: 'AgGridNumberCellEditor',
              onCellValueChanged: this.sumTotal,
              valueGetter(params) {
                return {
                  value: params.data.paxSecondClass,
                  error: params.data.messageValidate.paxSecondClass.errorExist,
                };
              },
              cellRendererFramework: AgGridValueWithBackgroundCellRenderer,
              cellClass(params) {
                return that.isDataAirport(params) ? 'airport-data' : null;
              },
            },
            {
              colId: 'paxThirdClass',
              field: 'paxThirdClass',
              headerName: 'Class 3',
              width: 45,
              minWidth: 45,
              editable: this.isCellEditable,
              cellEditor: 'AgGridNumberCellEditor',
              onCellValueChanged: this.sumTotal,
              valueGetter(params) {
                return {
                  value: params.data.paxThirdClass,
                  error: params.data.messageValidate.paxThirdClass.errorExist,
                };
              },
              cellRendererFramework: AgGridValueWithBackgroundCellRenderer,
              cellClass(params) {
                return that.isDataAirport(params) ? 'airport-data' : null;
              },
            },
          ],
        },
        {
          headerName: 'PAD',
          children: [
            {
              colId: 'padFirstClass',
              field: 'padFirstClass',
              headerName: 'Class 1',
              width: 45,
              minWidth: 45,
              editable: this.isCellEditable,
              cellEditor: 'AgGridNumberCellEditor',
              onCellValueChanged: this.sumTotal,
              valueGetter(params) {
                return {
                  value: params.data.padFirstClass,
                  error: params.data.messageValidate.padFirstClass.errorExist,
                };
              },
              cellRendererFramework: AgGridValueWithBackgroundCellRenderer,
              cellClass(params) {
                return that.isDataAirport(params) ? 'airport-data' : null;
              },
            },
            {
              colId: 'padSecondClass',
              field: 'padSecondClass',
              headerName: 'Class 2',
              width: 45,
              minWidth: 45,
              editable: this.isCellEditable,
              cellEditor: 'AgGridNumberCellEditor',
              onCellValueChanged: this.sumTotal,
              valueGetter(params) {
                return {
                  value: params.data.padSecondClass,
                  error: params.data.messageValidate.padSecondClass.errorExist,
                };
              },
              cellRendererFramework: AgGridValueWithBackgroundCellRenderer,
              cellClass(params) {
                return that.isDataAirport(params) ? 'airport-data' : null;
              },
            },
            {
              colId: 'padThirdClass',
              field: 'padThirdClass',
              headerName: 'Class 3',
              width: 45,
              minWidth: 45,
              editable: this.isCellEditable,
              cellEditor: 'AgGridNumberCellEditor',
              onCellValueChanged: this.sumTotal,
              valueGetter(params) {
                return {
                  value: params.data.padThirdClass,
                  error: params.data.messageValidate.padThirdClass.errorExist,
                };
              },
              cellRendererFramework: AgGridValueWithBackgroundCellRenderer,
              cellClass(params) {
                return that.isDataAirport(params) ? 'airport-data' : null;
              },
            },
          ],
        },
        {
          headerValueGetter() {
            return that.$i18n.t('operations.totalPaxOnBoardColumn');
          },
          children: [
            {
              colId: 'totalPaxOnBoardAdult',
              field: 'totalPaxOnBoardAdult',
              headerName: 'A',
              width: 55,
              minWidth: 55,
              editable: this.isCellEditable,
              cellEditor: 'AgGridNumberCellEditor',
              onCellValueChanged: this.sumTotal,
              valueGetter(params) {
                return {
                  value: params.data.totalPaxOnBoardAdult,
                  error: params.data.messageValidate.totalPaxOnBoardAdult.errorExist,
                };
              },
              cellRendererFramework: AgGridValueWithBackgroundCellRenderer,
              cellClass(params) {
                return that.isDataAirport(params) ? 'airport-data' : null;
              },
            },
            {
              colId: 'totalPaxOnBoardInfant',
              field: 'totalPaxOnBoardInfant',
              headerValueGetter() {
                return that.$i18n.t('operations.passengersInfant');
              },
              width: 55,
              minWidth: 55,
              editable: this.isCellEditable,
              cellEditor: 'AgGridNumberCellEditor',
              onCellValueChanged: this.sumTotal,
              valueGetter(params) {
                return {
                  value: params.data.totalPaxOnBoardInfant,
                  error: params.data.messageValidate.totalPaxOnBoardInfant.errorExist,
                };
              },
              cellRendererFramework: AgGridValueWithBackgroundCellRenderer,
              cellClass(params) {
                return that.isDataAirport(params) ? 'airport-data' : null;
              },
            },
          ],
        },
        {
          headerValueGetter() {
            return that.$i18n.t('operations.bags');
          },
          children: [
            {
              colId: 'bagsFirstClass',
              field: 'bagsFirstClass',
              headerName: 'Class 1',
              width: 45,
              minWidth: 45,
              editable: this.isCellEditable,
              cellEditor: 'AgGridNumberCellEditor',
              onCellValueChanged: this.sumTotal,
              valueGetter(params) {
                return {
                  value: params.data.bagsFirstClass,
                  error: params.data.messageValidate.bagsFirstClass.errorExist,
                };
              },
              cellRendererFramework: AgGridValueWithBackgroundCellRenderer,
              cellClass(params) {
                return that.isDataAirport(params) ? 'airport-data' : null;
              },
            },
            {
              colId: 'bagsSecondClass',
              field: 'bagsSecondClass',
              headerName: 'Class 2',
              width: 45,
              minWidth: 45,
              editable: this.isCellEditable,
              cellEditor: 'AgGridNumberCellEditor',
              onCellValueChanged: this.sumTotal,
              valueGetter(params) {
                return {
                  value: params.data.bagsSecondClass,
                  error: params.data.messageValidate.bagsSecondClass.errorExist,
                };
              },
              cellRendererFramework: AgGridValueWithBackgroundCellRenderer,
              cellClass(params) {
                return that.isDataAirport(params) ? 'airport-data' : null;
              },
            },
            {
              colId: 'bagsThirdClass',
              field: 'bagsThirdClass',
              headerName: 'Class 3',
              width: 45,
              minWidth: 45,
              editable: this.isCellEditable,
              cellEditor: 'AgGridNumberCellEditor',
              onCellValueChanged: this.sumTotal,
              valueGetter(params) {
                return {
                  value: params.data.bagsThirdClass,
                  error: params.data.messageValidate.bagsThirdClass.errorExist,
                };
              },
              cellRendererFramework: AgGridValueWithBackgroundCellRenderer,
              cellClass(params) {
                return that.isDataAirport(params) ? 'airport-data' : null;
              },
            },
            {
              colId: 'totalBags',
              field: 'totalBags',
              headerValueGetter() {
                return that.$i18n.t('operations.totalBagsLabel');
              },
              width: 60,
              minWidth: 60,
              editable: this.isCellEditable,
              cellEditor: 'AgGridNumberCellEditor',
              onCellValueChanged: this.sumTotal,
              valueGetter(params) {
                return {
                  value: params.data.totalBags,
                  error: params.data.messageValidate.totalBags.errorExist,
                };
              },
              cellRendererFramework: AgGridValueWithBackgroundCellRenderer,
              cellClass(params) {
                return that.isDataAirport(params) ? 'airport-data' : null;
              },
            },
            {
              colId: 'realWeightBags',
              field: 'realWeightBags',
              headerValueGetter() {
                return that.$i18n.t('operations.realWeightLabel');
              },
              width: 70,
              minWidth: 70,
              editable: this.isCellEditable,
              cellEditor: 'AgGridNumberCellEditor',
              onCellValueChanged: this.sumTotal,
              valueGetter(params) {
                return {
                  value: params.data.realWeightBags,
                  error: params.data.messageValidate.realWeightBags.errorExist,
                };
              },
              cellRendererFramework: AgGridValueWithBackgroundCellRenderer,
              cellClass(params) {
                return that.isDataAirport(params) ? 'airport-data' : null;
              },
            },
          ],
        },
        {
          headerValueGetter() {
            return that.$i18n.t('operations.wheelchairs');
          },
          children: [
            {
              colId: 'wchc',
              field: 'wchc',
              headerName: 'WCHC',
              width: 40,
              minWidth: 40,
              editable: this.isCellEditable,
              cellEditor: 'AgGridNumberCellEditor',
              onCellValueChanged: this.sumTotal,
              valueGetter(params) {
                return {
                  value: params.data.wchc,
                  error: params.data.messageValidate.wchc.errorExist,
                };
              },
              cellRendererFramework: AgGridValueWithBackgroundCellRenderer,
              cellClass(params) {
                return that.isDataAirport(params) ? 'airport-data' : null;
              },
            },
            {
              colId: 'wchs',
              field: 'wchs',
              headerName: 'WCHS',
              width: 40,
              minWidth: 40,
              editable: this.isCellEditable,
              cellEditor: 'AgGridNumberCellEditor',
              onCellValueChanged: this.sumTotal,
              valueGetter(params) {
                return {
                  value: params.data.wchs,
                  error: params.data.messageValidate.wchs.errorExist,
                };
              },
              cellRendererFramework: AgGridValueWithBackgroundCellRenderer,
              cellClass(params) {
                return that.isDataAirport(params) ? 'airport-data' : null;
              },
            },
            {
              colId: 'wchr',
              field: 'wchr',
              headerName: 'WHCR',
              width: 40,
              minWidth: 40,
              editable: this.isCellEditable,
              cellEditor: 'AgGridNumberCellEditor',
              onCellValueChanged: this.sumTotal,
              valueGetter(params) {
                return {
                  value: params.data.wchr,
                  error: params.data.messageValidate.wchr.errorExist,
                };
              },
              cellRendererFramework: AgGridValueWithBackgroundCellRenderer,
              cellClass(params) {
                return that.isDataAirport(params) ? 'airport-data' : null;
              },
            },
            {
              colId: 'totalWheelchairs',
              field: 'totalWheelchairs',
              headerName: 'Total',
              width: 35,
              minWidth: 35,
              editable: this.isCellEditable,
              cellEditor: 'AgGridNumberCellEditor',
              onCellValueChanged: this.sumTotal,
              valueGetter(params) {
                return {
                  value: params.data.totalWheelchairs,
                  error: params.data.messageValidate.totalWheelchairs.errorExist,
                };
              },
              cellRendererFramework: AgGridValueWithBackgroundCellRenderer,
              cellClass(params) {
                return that.isDataAirport(params) ? 'airport-data' : null;
              },
            },
            {
              colId: 'wcbd',
              field: 'wcbd',
              headerName: 'WCBD',
              width: 40,
              minWidth: 40,
              editable: this.isCellEditable,
              cellEditor: 'AgGridNumberCellEditor',
              onCellValueChanged: this.sumTotal,
              valueGetter(params) {
                return {
                  value: params.data.wcbd,
                  error: params.data.messageValidate.wcbd.errorExist,
                };
              },
              cellRendererFramework: AgGridValueWithBackgroundCellRenderer,
              cellClass(params) {
                return that.isDataAirport(params) ? 'airport-data' : null;
              },
            },
            {
              colId: 'wcbw',
              field: 'wcbw',
              headerName: 'WCBW',
              width: 40,
              minWidth: 40,
              editable: this.isCellEditable,
              cellEditor: 'AgGridNumberCellEditor',
              onCellValueChanged: this.sumTotal,
              valueGetter(params) {
                return {
                  value: params.data.wcbw,
                  error: params.data.messageValidate.wcbw.errorExist,
                };
              },
              cellRendererFramework: AgGridValueWithBackgroundCellRenderer,
              cellClass(params) {
                return that.isDataAirport(params) ? 'airport-data' : null;
              },
            },
            {
              colId: 'wclb',
              field: 'wclb',
              headerName: 'WCLB',
              width: 40,
              minWidth: 40,
              editable: this.isCellEditable,
              cellEditor: 'AgGridNumberCellEditor',
              onCellValueChanged: this.sumTotal,
              valueGetter(params) {
                return {
                  value: params.data.wclb,
                  error: params.data.messageValidate.wclb.errorExist,
                };
              },
              cellRendererFramework: AgGridValueWithBackgroundCellRenderer,
              cellClass(params) {
                return that.isDataAirport(params) ? 'airport-data' : null;
              },
            },
          ],
        },
        {
          headerName: '',
          children: [
            {
              colId: 'umnr',
              field: 'umnr',
              headerName: 'UMs',
              width: 35,
              minWidth: 35,
              editable: this.isCellEditable,
              cellEditor: 'AgGridNumberCellEditor',
              onCellValueChanged: this.sumTotal,
              valueGetter(params) {
                return {
                  value: params.data.umnr,
                  error: params.data.messageValidate.umnr.errorExist,
                };
              },
              cellRendererFramework: AgGridValueWithBackgroundCellRenderer,
              cellClass(params) {
                return that.isDataAirport(params) ? 'airport-data' : null;
              },
            },
          ],
        },
        {
          headerName: '',
          children: [
            {
              colId: 'petc',
              field: 'petc',
              headerName: 'PETC',
              width: 35,
              minWidth: 35,
              editable: this.isCellEditable,
              cellEditor: 'AgGridNumberCellEditor',
              onCellValueChanged: this.sumTotal,
              valueGetter(params) {
                return {
                  value: params.data.petc,
                  error: params.data.messageValidate.petc.errorExist,
                };
              },
              cellRendererFramework: AgGridValueWithBackgroundCellRenderer,
              cellClass(params) {
                return that.isDataAirport(params) ? 'airport-data' : null;
              },
            },
          ],
        },
      ],
    };
  },
  computed: {
    flightNumber() {
      return this.connectedFlights.getChildPropertyValue(this.flightBound, ['common', 'flightNo']);
    },
    isInbound() {
      return this.flightBound === FlightBound.INBOUND;
    },
    rolePermission() {
      return `${this.$route.meta.role}_WR`;
    },
  },
  methods: {
    sumTotal(params) {
      if (params.newValue.value !== params.oldValue.value) {
        // Not dynamic rows (same number of rows and in the same position)
        const totalTransits = this.rowData[0];
        const totalPaxOnBoard = this.rowData[this.rowData.length - 1];
        const totalCheckInPax = this.rowData[this.rowData.length - 2];
        const actualRealWeightBagsTotalCheckInPax = totalCheckInPax.realWeightBags;
        // Dynamic rows (the number of rows depends on the number of stops on the flight)
        const paxPerAirportAndTotalTransits = this.rowData.filter(it => it.isTotalPaxOnBoardedPerAirport != null && !it.isTotalPaxOnBoardedPerAirport);
        paxPerAirportAndTotalTransits.push(totalTransits);
        const boardedPaxPerAirportAndTotalTransits = this.rowData.filter(it => it.isTotalPaxOnBoardedPerAirport != null && it.isTotalPaxOnBoardedPerAirport);

        if (params.data.isTotalPaxOnBoardedPerAirport === false) {
          /*
            Cuando las filas de pasajeros por aeropuerto (this.rowData.filter(it => it.isTotalPaxOnBoardedPerAirport != null && !it.isTotalPaxOnBoardedPerAirport))
            se modifican las filas de boarded en dicho aeropuerto deben rellenarse para agilizar y mejorar la experiencia de usuario.
          */
          boardedPaxPerAirportAndTotalTransits.filter(boardedPaxPerAirport => boardedPaxPerAirport.airport === params.data.airport)
            .forEach((boardedPaxPerAirport) => {
              // eslint-disable-next-line no-param-reassign
              boardedPaxPerAirport[params.colDef.colId] = params.newValue.value;
            });
        }
        boardedPaxPerAirportAndTotalTransits.push(totalTransits);
        const paxTriggerCells = ['male', 'female', 'child'];
        const infantTriggerCells = ['infant'];
        const bagTriggerCells = ['bagsFirstClass', 'bagsSecondClass', 'bagsThirdClass'];
        const wheelchairTriggerCells = ['realWeightBags', 'wchc', 'wchs', 'wchr', 'wcbd', 'wcbw', 'wclb', 'umnr'];
        this.sumTotalPropertiesTotalCheckInPax(paxPerAirportAndTotalTransits, totalCheckInPax);
        this.sumTotalPropertiesTotalPaxOnBoard(boardedPaxPerAirportAndTotalTransits, totalPaxOnBoard, params.colDef.colId, actualRealWeightBagsTotalCheckInPax);

        if (paxTriggerCells.some(colId => colId === params.colDef.colId)) {
          this.calculateTotalPaxOnBoardColumn(totalTransits);
          this.calculateTotalPaxOnBoardColumn(totalCheckInPax);
          this.calculateTotalPaxOnBoardColumn(totalPaxOnBoard);
          this.rowData.slice(1, this.rowData.length - 2).forEach(p => this.calculateTotalPaxOnBoardColumn(p));
        }
        if (infantTriggerCells.some(colId => colId === params.colDef.colId)) {
          this.calculateTotalPaxOnBoardInfantColumn(totalTransits);
          this.calculateTotalPaxOnBoardInfantColumn(totalCheckInPax);
          this.calculateTotalPaxOnBoardInfantColumn(totalPaxOnBoard);
          this.rowData.slice(1, this.rowData.length - 2).forEach(p => this.calculateTotalPaxOnBoardInfantColumn(p));
        }
        if (bagTriggerCells.some(colId => colId === params.colDef.colId)) {
          this.calculateTotalBagsColumn(totalTransits);
          this.calculateTotalBagsColumn(totalCheckInPax);
          this.calculateTotalBagsColumn(totalPaxOnBoard);
          this.rowData.slice(1, this.rowData.length - 2).forEach(p => this.calculateTotalBagsColumn(p));
        }
        if (wheelchairTriggerCells.some(colId => colId === params.colDef.colId)) {
          this.calculateTotalWheelchairsColumn(totalTransits);
          this.calculateTotalWheelchairsColumn(totalCheckInPax);
          this.calculateTotalWheelchairsColumn(totalPaxOnBoard);
          this.rowData.slice(1, this.rowData.length - 2).forEach(p => this.calculateTotalWheelchairsColumn(p));
        }

        this.totalPaxOnBoard = totalPaxOnBoard.totalPaxOnBoardAdult;

        this.calculateLMC(totalCheckInPax, totalPaxOnBoard);
        this.validateData(params);
      }
    },
    sumTotalPropertiesTotalCheckInPax(passengers, total) {
      this.sumTotalProperty(total, passengers, 'male');
      this.sumTotalProperty(total, passengers, 'female');
      this.sumTotalProperty(total, passengers, 'child');
      this.sumTotalProperty(total, passengers, 'infant');
      this.sumTotalProperty(total, passengers, 'paxFirstClass');
      this.sumTotalProperty(total, passengers, 'paxSecondClass');
      this.sumTotalProperty(total, passengers, 'paxThirdClass');
      this.sumTotalProperty(total, passengers, 'padFirstClass');
      this.sumTotalProperty(total, passengers, 'padSecondClass');
      this.sumTotalProperty(total, passengers, 'padThirdClass');
      this.sumTotalProperty(total, passengers, 'bagsFirstClass');
      this.sumTotalProperty(total, passengers, 'bagsSecondClass');
      this.sumTotalProperty(total, passengers, 'bagsThirdClass');
      this.sumTotalProperty(total, passengers, 'totalPaxOnBoardAdult');
      this.sumTotalProperty(total, passengers, 'totalPaxOnBoardInfant');
      this.sumTotalProperty(total, passengers, 'totalBags');
      this.sumTotalProperty(total, passengers, 'realWeightBags');
      this.sumTotalProperty(total, passengers, 'wchc');
      this.sumTotalProperty(total, passengers, 'wchr');
      this.sumTotalProperty(total, passengers, 'wchs');
      this.sumTotalProperty(total, passengers, 'wcbd');
      this.sumTotalProperty(total, passengers, 'wcbw');
      this.sumTotalProperty(total, passengers, 'wclb');
      this.sumTotalProperty(total, passengers, 'totalWheelchairs');
      this.sumTotalProperty(total, passengers, 'umnr');
      this.sumTotalProperty(total, passengers, 'petc');
    },
    sumTotalPropertiesTotalPaxOnBoard(passengers, total, colId, prevRealWeightBags = null) {
      if (colId === 'realWeightBags') {
        if (prevRealWeightBags === Number(total.realWeightBags)) {
          this.sumTotalProperty(total, passengers, colId);
        }
      } else this.sumTotalProperty(total, passengers, colId);


      if (colId === 'male' || colId === 'female' || colId === 'child') {
        this.sumTotalProperty(total, passengers, 'totalPaxOnBoardAdult');
      }
      if (colId === 'infant') {
        this.sumTotalProperty(total, passengers, 'totalPaxOnBoardInfant');
      }
      if (colId === 'paxFirstClass' || colId === 'paxSecondClass' || colId === 'paxThirdClass') {
        this.sumTotalProperty(total, passengers, 'totalPaxOnBoardAdult');
      }
      if (colId === 'padFirstClass' || colId === 'padSecondClass' || colId === 'padThirdClass') {
        this.sumTotalProperty(total, passengers, 'totalPaxOnBoardAdult');
      }
      if (colId === 'bagsFirstClass' || colId === 'bagsSecondClass' || colId === 'bagsThirdClass') {
        this.sumTotalProperty(total, passengers, 'totalBags');
      }
      if (colId === 'wchc' || colId === 'wchr' || colId === 'wchs') {
        this.sumTotalProperty(total, passengers, 'totalWheelchairs');
      }
    },
    sumTotalProperty(total, passengers, property) {
      // eslint-disable-next-line no-param-reassign
      total[property] = this.getTotalProperty(passengers, property);
    },
    getTotalProperty(passengers, property) {
      const passengersProperty = passengers.map(p => p[property]).filter(a => a != null);
      return passengersProperty.length > 0 ? this.sumProperty(passengersProperty) : null;
    },
    sumProperty(passengersPropertyValue) {
      const reducer = (accumulator, currentValue) => (parseInt(accumulator, 10) + parseInt(currentValue, 10));
      return passengersPropertyValue.reduce(reducer);
    },
    calculateTotalPaxOnBoardColumn(row) {
      if (row.male != null || row.female != null || row.child != null) {
        // eslint-disable-next-line no-param-reassign
        row.totalPaxOnBoardAdult = Number(row.male) + Number(row.female) + Number(row.child);
      }
    },
    calculateTotalPaxOnBoardInfantColumn(row) {
      // eslint-disable-next-line no-param-reassign
      row.totalPaxOnBoardInfant = row.infant;
    },
    calculateTotalBagsColumn(row) {
      if (row.bagsFirstClass != null || row.bagsSecondClass != null || row.bagsThirdClass != null) {
        // eslint-disable-next-line no-param-reassign
        row.totalBags = Number(row.bagsFirstClass) + Number(row.bagsSecondClass) + Number(row.bagsThirdClass);
      }
    },
    calculateTotalWheelchairsColumn(row) {
      if (row.wchc != null || row.wchr != null || row.wchs != null) {
        // eslint-disable-next-line no-param-reassign
        row.totalWheelchairs = Number(row.wchc) + Number(row.wchr) + Number(row.wchs);
      }
    },
    calculateLMC(totalCheckInPax, totalPaxOnBoard) {
      this.flightPassageInformation.lastMinuteChangesPax = (totalCheckInPax.totalPaxOnBoardAdult - totalPaxOnBoard.totalPaxOnBoardAdult) * -1;
      this.flightPassageInformation.lastMinuteChangesInf = (totalCheckInPax.totalPaxOnBoardInfant - totalPaxOnBoard.totalPaxOnBoardInfant) * -1;
      this.flightPassageInformation.lastMinuteChangesBag = (totalCheckInPax.totalBags - totalPaxOnBoard.totalBags) * -1;
    },
    validateData(params) {
      this.invalidData = false;
      // Not dynamic rows (same number of rows and in the same position)
      const totalTransits = this.rowData[0];
      const totalPaxOnBoard = this.rowData[this.rowData.length - 1];
      const totalCheckInPax = this.rowData[this.rowData.length - 2];
      // Dynamic rows (the number of rows depends on the number of stops on the flight)
      const paxPerAirportAndTotalTransits = this.rowData.filter(it => it.isTotalPaxOnBoardedPerAirport != null && !it.isTotalPaxOnBoardedPerAirport);
      paxPerAirportAndTotalTransits.push(totalTransits);
      const boardedPaxPerAirportAndTotalTransits = this.rowData.filter(it => it.isTotalPaxOnBoardedPerAirport != null && it.isTotalPaxOnBoardedPerAirport);
      boardedPaxPerAirportAndTotalTransits.push(totalTransits);

      const totalCheckInPaxError = [];
      const totalPaxOnBoardError = [];

      this.checkErrorMsg(paxPerAirportAndTotalTransits, totalCheckInPax, totalCheckInPaxError);
      this.checkErrorMsg(boardedPaxPerAirportAndTotalTransits, totalPaxOnBoard, totalPaxOnBoardError);

      this.columnDefs.flatMap(h => h.children).map(colDef => colDef.field).forEach((colField) => {
        this.rowData.forEach((data) => {
          this.validateColFieldOfRow(colField, data, totalCheckInPaxError, totalPaxOnBoardError);
        });
      });
      this.validateCabinDistribution();

      if (params) {
        params.api.refreshCells({ force: true });
      }
    },
    checkErrorMsg(passengers, total, propertiesError) {
      this.validateTotalProperty(this.getTotalProperty(passengers, 'male'), total.male, propertiesError, 'male');
      this.validateTotalProperty(this.getTotalProperty(passengers, 'female'), total.female, propertiesError, 'female');
      this.validateTotalProperty(this.getTotalProperty(passengers, 'child'), total.child, propertiesError, 'child');
      this.validateTotalProperty(this.getTotalProperty(passengers, 'infant'), total.infant, propertiesError, 'infant');
      this.validateTotalProperty(this.getTotalProperty(passengers, 'paxFirstClass'), total.paxFirstClass, propertiesError, 'paxFirstClass');
      this.validateTotalProperty(this.getTotalProperty(passengers, 'paxSecondClass'), total.paxSecondClass, propertiesError, 'paxSecondClass');
      this.validateTotalProperty(this.getTotalProperty(passengers, 'paxThirdClass'), total.paxThirdClass, propertiesError, 'paxThirdClass');
      this.validateTotalProperty(this.getTotalProperty(passengers, 'padFirstClass'), total.padFirstClass, propertiesError, 'padFirstClass');
      this.validateTotalProperty(this.getTotalProperty(passengers, 'padSecondClass'), total.padSecondClass, propertiesError, 'padSecondClass');
      this.validateTotalProperty(this.getTotalProperty(passengers, 'padThirdClass'), total.padThirdClass, propertiesError, 'padThirdClass');
      this.validateTotalProperty(this.getTotalProperty(passengers, 'bagsFirstClass'), total.bagsFirstClass, propertiesError, 'bagsFirstClass');
      this.validateTotalProperty(this.getTotalProperty(passengers, 'bagsSecondClass'), total.bagsSecondClass, propertiesError, 'bagsSecondClass');
      this.validateTotalProperty(this.getTotalProperty(passengers, 'bagsThirdClass'), total.bagsThirdClass, propertiesError, 'bagsThirdClass');
      this.validateTotalProperty(this.getTotalProperty(passengers, 'totalPaxOnBoardAdult'), total.totalPaxOnBoardAdult, propertiesError, 'totalPaxOnBoardAdult');
      this.validateTotalProperty(this.getTotalProperty(passengers, 'totalPaxOnBoardInfant'), total.totalPaxOnBoardInfant, propertiesError, 'totalPaxOnBoardInfant');
      this.validateTotalProperty(this.getTotalProperty(passengers, 'totalBags'), total.totalBags, propertiesError, 'totalBags');
      this.validateTotalProperty(this.getTotalProperty(passengers, 'realWeightBags'), total.realWeightBags, propertiesError, 'realWeightBags');
      this.validateTotalProperty(this.getTotalProperty(passengers, 'wchc'), total.wchc, propertiesError, 'wchc');
      this.validateTotalProperty(this.getTotalProperty(passengers, 'wchr'), total.wchr, propertiesError, 'wchr');
      this.validateTotalProperty(this.getTotalProperty(passengers, 'wchs'), total.wchs, propertiesError, 'wchs');
      this.validateTotalProperty(this.getTotalProperty(passengers, 'wcbd'), total.wcbd, propertiesError, 'wcbd');
      this.validateTotalProperty(this.getTotalProperty(passengers, 'wcbw'), total.wcbw, propertiesError, 'wcbw');
      this.validateTotalProperty(this.getTotalProperty(passengers, 'wclb'), total.wclb, propertiesError, 'wclb');
      this.validateTotalProperty(this.getTotalProperty(passengers, 'totalWheelchairs'), total.totalWheelchairs, propertiesError, 'totalWheelchairs');
      this.validateTotalProperty(this.getTotalProperty(passengers, 'umnr'), total.umnr, propertiesError, 'umnr');
      this.validateTotalProperty(this.getTotalProperty(passengers, 'petc'), total.petc, propertiesError, 'petc');
    },
    validateTotalProperty(passengersPropertyTotalValue, totalPropertyValue, propertiesError, propertyError) {
      if (passengersPropertyTotalValue != null && Number(passengersPropertyTotalValue) !== Number(totalPropertyValue)) {
        propertiesError.push(propertyError);
      }
    },
    validateColFieldOfRow(colField, rowData, totalCheckInPaxError, totalPaxOnBoardError) {
      const data = rowData;
      const errorValidate = {};

      errorValidate.errorExist = false;

      if (colField === 'totalPaxOnBoardAdult') {
        if (Number(data.male) + Number(data.female) + Number(data.child) !== Number(data.totalPaxOnBoardAdult)) errorValidate.errorExist = true;
        if (Number(data.paxFirstClass) + Number(data.paxSecondClass) + Number(data.paxThirdClass) !== Number(data.totalPaxOnBoardAdult)) errorValidate.errorExist = true;
      }

      if (colField === 'totalPaxOnBoardInfant') {
        if (Number(data.infant) !== Number(data.totalPaxOnBoardInfant)) errorValidate.errorExist = true;
      }
      if (colField === 'totalBags') {
        if (Number(data.bagsFirstClass) + Number(data.bagsSecondClass) + Number(data.bagsThirdClass) !== Number(data.totalBags)) errorValidate.errorExist = true;
      }
      if (colField === 'totalWheelchairs') {
        if (Number(data.wchc) + Number(data.wchr) + Number(data.wchs) !== Number(data.totalWheelchairs)) errorValidate.errorExist = true;
      }

      if (data[colField] < 0) {
        this.invalidData = true;
        errorValidate.errorExist = true;
      }

      if (data.messageValidate == null) {
        data.messageValidate = {};
      }

      data.messageValidate[colField] = errorValidate;

      if (totalCheckInPaxError.length !== 0 && data.isTotalCheckInPax) {
        const [colError] = totalCheckInPaxError.filter(col => col === colField);
        if (colError) data.messageValidate[colError].errorExist = true;
      }

      if (totalPaxOnBoardError.length !== 0 && data.isTotalPaxOnBoard) {
        const [colError] = totalPaxOnBoardError.filter(col => col === colField);
        if (colError) data.messageValidate[colError].errorExist = true;
      }

      if (colField === 'male' || colField === 'female' || colField === 'child' || colField === 'infant'
        || colField === 'paxFirstClass' || colField === 'paxSecondClass' || colField === 'paxThirdClass'
        || colField === 'padFirstClass' || colField === 'padSecondClass' || colField === 'padThirdClass'
        || colField === 'bagsFirstClass' || colField === 'bagsSecondClass' || colField === 'bagsThirdClass') {
        if (Number(data[colField]) > 500 || Number(data[colField]) < 0) {
          errorValidate.errorExist = true;
          data.messageValidate[colField] = errorValidate;
          this.invalidData = true;
        }
      }
      if (colField === 'wchc' || colField === 'wchr' || colField === 'wchs' || colField === 'wcbd'
        || colField === 'wcbw' || colField === 'wclb' || colField === 'umnr' || colField === 'petc') {
        if (Number(data[colField]) > 100 || Number(data[colField]) < 0) {
          errorValidate.errorExist = true;
          data.messageValidate[colField] = errorValidate;
          this.invalidData = true;
        }
      }
    },
    validateCabinDistribution() {
      const totalPassageCabinDistribution = Number(this.flightPassageInformation.cabin0A) + Number(this.flightPassageInformation.cabin0B) + Number(this.flightPassageInformation.cabin0C) + Number(this.flightPassageInformation.cabin0D) + Number(this.flightPassageInformation.cabin0E) + Number(this.flightPassageInformation.cabin0F) + Number(this.flightPassageInformation.comail);

      if (Number(totalPassageCabinDistribution) !== Number(this.totalPaxOnBoard)) {
        this.errorCabinDistribution = this.$vuetify.dark ? 'darken(#E80027, 50)' : 'lighten(#E80027, 50)';
      } else {
        this.errorCabinDistribution = this.$vuetify.dark ? 'black' : 'white';
      }
    },
    getValue() {
      if (this.isSaving) {
        [this.flightPassageInformation.totalTransits] = this.rowData.filter(r => r.isTotalTransient);
        [this.flightPassageInformation.totalCheckInPax] = this.rowData.filter(r => r.isTotalCheckInPax);
        [this.flightPassageInformation.totalPaxOnBoard] = this.rowData.filter(r => r.isTotalPaxOnBoard);
        this.flightPassageInformation.flightPassengersPerAirport = this.rowData.slice(1, this.rowData.length - 2);
        this.requestInit();
        this.$http.put(`flights/${this.flightId}/passage`, this.flightPassageInformation)
          .then(resp => this.dialogRequestSuccessCallback(this, resp))
          .catch(error => this.requestErrorCallback(this, error));
      }

      return this.params.value;
    },
    isCellEditable(params) {
      return this.isNotTotalCheckInPax(params) && this.isNotTotalPaxOnBoard(params) && this.hasPermissions(this.rolePermission);
    },
    isNotTotalCheckInPax(params) {
      return (params.data.isTotalCheckInPax) ? !params.data.isTotalCheckInPax : true;
    },
    isNotTotalPaxOnBoard(params) {
      return (params.data.isTotalPaxOnBoard) ? !params.data.isTotalPaxOnBoard : true;
    },
    isDataAirport(params) {
      return !params.data.isTotalCheckInPax && !params.data.isTotalPaxOnBoard && !params.data.isTotalTransient;
    },
  },
  created() {
    this.$http.get(`flights/${this.flightId}/passage`)
      .then((resp) => {
        this.flightPassageInformation = FlightPassageFactory.createFlightPassageOutboundInformationFromData(resp.data);
        const totalTransits = FlightPassageFactory.createFlightPassageFromData(this.flightPassageInformation.totalTransits);
        totalTransits.isTotalTransient = true;
        this.rowData.push(totalTransits);
        this.flightPassageInformation.flightPassengersPerAirport.forEach((f) => {
          this.rowData.push(FlightPassageFactory.createFlightPassageFromData(f, this.flightPassageInformation));
        });
        const totalCheckInPax = FlightPassageFactory.createFlightPassageFromData(this.flightPassageInformation.totalCheckInPax);
        totalCheckInPax.isTotalCheckInPax = true;
        this.rowData.push(totalCheckInPax);
        const totalPaxOnBoard = FlightPassageFactory.createFlightPassageFromData(this.flightPassageInformation.totalPaxOnBoard);
        totalPaxOnBoard.isTotalPaxOnBoard = true;
        this.rowData.push(totalPaxOnBoard);
        this.totalPaxOnBoard = totalPaxOnBoard.totalPaxOnBoardAdult;
        this.validateData();
      });
  },
});
</script>
<style lang="scss" scoped>
.container.grid-list-sm *:not(:only-child) .layout:last-child {
  margin-bottom: -7px;
}

.remarks-form {
  margin-bottom: -30px;
}

@media (max-width: 1800px) {
  .container.grid-list-sm *:not(:only-child) .layout:last-child {
    margin-bottom: -10px;
  }

  .form-container {
    padding: 6px;
  }
}

@media (max-width: 1600px) {
  .container.grid-list-sm *:not(:only-child) .layout:last-child {
    margin-bottom: -17px;
  }
}

@media (max-width: 1550px) {
  .container.grid-list-sm *:not(:only-child) .layout:last-child {
    margin-bottom: -29px;
  }

  .form-container {
    padding: 1.5px;
  }
}

@media (max-width: 1500px) {
  .container.grid-list-sm *:not(:only-child) .layout:last-child {
    margin-bottom: -25px;
  }

  .remarks-form {
    margin-top: -2px !important;
  }
}
</style>
<style lang="scss">
.ag-grid-connected-flights {
  .ag-header .ag-header-group-cell {
    line-height: 28px !important;
  }

  .ag-cell {
    line-height: 28px;
  }

  .cell-border .ag-cell {
    line-height: 45px;
  }

  .ag-header .ag-header-cell {
    line-height: 28px !important;
  }

  .ag-header .ag-header-cell .ag-floating-filter-input {
    height: 18px;
    padding-bottom: 0px;
  }

  .ag-header .header-icon {
    font-size: 20px;
    position: relative;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .ag-header .ag-header-cell .ag-header-cell-label {
    width: 100%;
  }

  .airport-data {
    font-weight: bold;
  }
}
</style>
