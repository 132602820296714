<template>
  <div>
    <v-autocomplete
      placeholder="..."
      :value="value"
      :label="label"
      :items="items"
      :filter="customFilter"
      :clearable="true"
      item-text="name"
      item-value="id"
      :rules="rules"
      :disabled="disabled"
      @input="$emit('input', $event)"
      :ref="refComp"
      @change="onChangeSelection"
    >
      <template
        slot="item"
        slot-scope="{ item, tile }"
      >
        <v-list-tile-content>
          <v-list-tile-title v-text="item.name"></v-list-tile-title>
        </v-list-tile-content>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>

export default {
  props: {
    value: { required: true },
    label: { type: String },
    charLenght: { type: Number },
    rules: { type: Array },
    disabled: { type: Boolean },
    refComp: {
      type: String,
      required: false,
      default: 'refComp',
    },
  },
  computed: {
    items() {
      return [
        {
          id: 'IATA_CODE',
          name: this.$i18n.t('configuration.iataCode'),
        },
        {
          id: 'ICAO_CODE',
          name: this.$i18n.t('configuration.icaoCode'),
        },
      ];
    },
  },
  methods: {
    customFilter(item, queryText) {
      const textOne = item.iataCode ? item.iataCode.toLowerCase() : '';
      const textTwo = item.comments ? item.comments.toLowerCase() : '';
      const searchText = queryText.toLowerCase();

      return textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1;
    },
    onChangeSelection(selectionId) {
      const defaultCode = this.items.filter(a => a.id === selectionId)[0];
      this.$emit('change', defaultCode);
    },
  },
};
</script>
