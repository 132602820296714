export default class GanttFlightsFilter {
  constructor(dateFrom, dateTo, airline) {
    this.dateFrom = dateFrom;
    this.dateTo = dateTo;
    this.airline = airline;
    this.showFlights = true;
  }

  static createEmpty() {
    return new GanttFlightsFilter();
  }
}
