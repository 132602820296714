/* eslint no-param-reassign: "off" */
/* eslint no-confusing-arrow: "off" */
/* eslint no-nested-ternary: "off" */

import { REQUEST_ERROR, REQUEST_START, REQUEST_SUCCESS } from '../../store';
import { HTTP } from '../../../http';
import { NOTIFICATION_SHOW } from '../notifications';
import i18n from '../../../plugins/i18n';

// actions names
export const GET_DELAY_REASONS = 'GET_DELAY_REASONS';
export const CREATE_DELAY_REASON = 'CREATE_DELAY_REASON';
export const UPDATE_DELAY_REASON = 'UPDATE_DELAY_REASON';
export const DISABLE_DELAY_REASON = 'DISABLE_DELAY_REASON';
export const ENABLE_DELAY_REASON = 'ENABLE_DELAY_REASON';

// mutations
export const SET_DELAY_REASONS = 'SET_DELAY_REASONS';
export const ADD_DELAY_REASON = 'ADD_DELAY_REASON';
export const REMOVE_DELAY_REASON = 'REMOVE_DELAY_REASON';
export const DELAY_REASONS_REQUESTED = 'DELAY_REASONS_REQUESTED';
export const DELAY_REASONS_OBTAINED = 'DELAY_REASONS_OBTAINED';


// initial state
const storeState = {
  delayReasons: [],
  delayReasonsRequested: false,
  selectedDelayReasons: [],
};

// getters
const storeGetters = {
  getDelayReasons: state => state.delayReasons
    .map(delayReason => ({ ...delayReason, disableDelete: true })) // La eliminación física está deshabilida en estas entidades, y debería estarlo en algunas más
    .sort((a, b) => (a.reason > b.reason) ? 1 : ((b.reason > a.reason) ? -1 : 0)),
  getDelayReasonSelected: state => state.selectedDelayType,
};

// actions
const storeActions = {
  [GET_DELAY_REASONS]: ({ commit, state }) => {
    if (state.delayReasonsRequested) {
      return;
    }
    commit(REQUEST_START);
    commit(DELAY_REASONS_REQUESTED);
    HTTP.get('/flights/check-in-and-boarding-times/sla/breaking-reasons')
      .then((resp) => {
        commit(REQUEST_SUCCESS);
        commit(SET_DELAY_REASONS, resp.data);
        commit(DELAY_REASONS_OBTAINED);
      })
      .catch((err) => {
        commit(REQUEST_ERROR, err);
      });
  },
  [CREATE_DELAY_REASON]: ({ commit }, delayReason) => {
    commit(REQUEST_START);
    return HTTP.post('/flights/check-in-and-boarding-times/sla/breaking-reason', delayReason)
      .then((resp) => {
        commit(REQUEST_SUCCESS);
        commit(ADD_DELAY_REASON, resp.data);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.delayReasonsCreate'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
  [UPDATE_DELAY_REASON]: ({ commit }, delayReason) => {
    commit(REQUEST_START);
    return HTTP.put('/flights/check-in-and-boarding-times/sla/breaking-reason', delayReason)
      .then((resp) => {
        commit(REQUEST_SUCCESS);
        commit(REMOVE_DELAY_REASON, resp.data.id);
        commit(ADD_DELAY_REASON, resp.data);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.delayReasonUpdate'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
  [DISABLE_DELAY_REASON]: ({ commit }, id) => {
    commit(REQUEST_START);
    return HTTP.patch(`/flights/check-in-and-boarding-times/sla/breaking-reason/disable/${id}`)
      .then((resp) => {
        commit(REQUEST_SUCCESS);
        commit(REMOVE_DELAY_REASON, resp.data.id);
        commit(ADD_DELAY_REASON, resp.data);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.delayReasonDisabled'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
  [ENABLE_DELAY_REASON]: ({ commit }, id) => {
    commit(REQUEST_START);
    return HTTP.patch(`/flights/check-in-and-boarding-times/sla/breaking-reason/enable/${id}`)
      .then((resp) => {
        commit(REQUEST_SUCCESS);
        commit(REMOVE_DELAY_REASON, resp.data.id);
        commit(ADD_DELAY_REASON, resp.data);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.delayReasonDisabled'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
};

// mutations
const storeMutations = {
  [SET_DELAY_REASONS]: (state, delayReasons) => {
    state.delayReasons = delayReasons;
  },
  [ADD_DELAY_REASON]: (state, delayReason) => {
    state.delayReasons.push(delayReason);
  },
  [REMOVE_DELAY_REASON]: (state, id) => {
    state.delayReasons = state.delayReasons
      .filter(delayReason => delayReason.id !== id);
  },
  [DELAY_REASONS_REQUESTED]: (state) => {
    state.delayReasonsRequested = true;
  },
  [DELAY_REASONS_OBTAINED]: (state) => {
    state.delayReasonsRequested = false;
  },
};

export default {
  state: storeState,
  getters: storeGetters,
  actions: storeActions,
  mutations: storeMutations,
};
