<template>
  <div>
    <message-type-select v-char-length.clearable="4" v-on:keydown.native="keyEvent" label="Type" v-model="itemId" @input-code="setItemCode($event)"></message-type-select>
  </div>
</template>

<script>
import Vue from 'vue';
import MessageTypeSelect from '../../messages/MessageTypeSelect.vue';
import AgGridParentCellEditor from './mixins/AgGridParentCellEditor.vue';

export default Vue.extend({
  mixins: [AgGridParentCellEditor],
  components: {
    MessageTypeSelect,
  },
  data() {
    return {
      itemId: null,
      itemCode: null,
    };
  },
  methods: {
    getValue() {
      if (this.params.column.colDef && this.params.column.colDef.cellEditorCustomConfig && this.params.column.colDef.cellEditorCustomConfig.fieldId) {
        this.params.node.data[this.params.column.colDef.cellEditorCustomConfig.fieldId] = this.itemId;
      }
      this.beforeReturnGetValue();
      return this.itemCode;
    },
    setItemCode(code) {
      this.itemCode = code;
    },
  },
  created() {
    if (this.params.column.colDef && this.params.column.colDef.cellEditorCustomConfig && this.params.column.colDef.cellEditorCustomConfig.fieldId) {
      this.itemId = this.params.node.data[this.params.column.colDef.cellEditorCustomConfig.fieldId];
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$refs.refComp) {
        this.$refs.refComp.$refs.refComp.focus();
      }
    });
  },
});
</script>

<style>

</style>
