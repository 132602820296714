<template>
  <ag-grid-template-cell-renderer :params="params">
    <div>
      <v-tooltip bottom v-if="params.value && params.value.tooltip">
        <v-icon v-if="params.value && params.value.icon"
                :class="'-'+params.value.icon"
                slot="activator"
                :color="params.value.color"
                small
                dark
        >{{params.value.icon}}
        </v-icon>
        <span v-html="params.value.tooltip"></span>
      </v-tooltip>
      <span v-if="params.value && !params.value.tooltip">
        <v-icon v-if="params.value && params.value.icon"
                :class="'-'+params.value.icon"
                slot="activator"
                :color="params.value.color"
                small
                dark
        >{{params.value.icon}}
        </v-icon>
      </span>
    </div>
  </ag-grid-template-cell-renderer>
</template>

<script>
import Vue from 'vue';
import AgGridTemplateCellRenderer from './templates/AgGridTemplateCellRenderer.vue';

export default Vue.extend({
  components: {
    AgGridTemplateCellRenderer,
  },
  computed: {

  },
});
</script>

<style scoped>
  .v-icon.-sms {
    margin-top: 13px;
  }
</style>
