/* eslint no-param-reassign: "off" */

import moment from 'moment';
import { HTTP } from '../../http';
import { REQUEST_ERROR, REQUEST_START, REQUEST_SUCCESS } from '../store';
import { NOTIFICATION_SHOW } from './notifications';
import TicketingFilter from '../../components/ticketing/model/ticketingFilter';
import i18n from '../../plugins/i18n';
import FlightTicketingInformation from '../../components/ticketing/model/flightTicketingInformation';
import {
  ROLE_ADMINISTRATOR_TICKETING,
  ROLE_CASHIER_TICKETING,
  ROLE_SUPERVISOR_TICKETING,
} from '../../constants/roles';


// action
export const TICKETING_GRID_FIT = 'TICKETING_GRID_FIT';
export const TICKETING_GRID_REFRESH_CELLS = 'TICKETING_GRID_REFRESH_CELLS';
export const TICKETING_CHANGE_GRID_API = 'TICKETING_CHANGE_GRID_API';

export const TICKETING_FLIGHTS_FIND = 'TICKETING_FLIGHTS_FIND';
export const TICKETING_FLIGHT_FIND = 'TICKETING_FLIGHT_FIND';
export const TICKETING_FLIGHT_UPDATE = 'TICKETING_FLIGHT_UPDATE';
export const TICKETING_FLIGHTS_DELETE = 'TICKETING_FLIGHTS_DELETE';
export const TICKETING_FLIGHTS_CANCEL = 'TICKETING_FLIGHTS_CANCEL';
export const TICKETING_FIT_COLUMNS_TO_VIEW = 'TICKETING_FIT_COLUMNS_TO_VIEW';
export const TICKETING_FIT_COLUMNS_TO_BEST = 'TICKETING_FIT_COLUMNS_TO_BEST';

export const TICKETING_RECALCULATE_FILTER = 'TICKETING_RECALCULATE_FILTER';
export const TICKETING_ROLE_CALCULATE = 'TICKETING_ROLE_CALCULATE';

export const TICKETING_EMIT = 'TICKETING_EMIT';

// mutations
export const TICKETING_FLIGHTS_FIND_SUCCESS = 'TICKETING_FLIGHTS_FIND_SUCCESS';
export const TICKETING_FLIGHT_FIND_SUCCESS = 'TICKETING_FLIGHT_FIND_SUCCESS';
export const TICKETING_CHANGE_FLIGHT_SELECTION = 'TICKETING_CHANGE_FLIGHT_SELECTION';

export const TICKETING_FILTER_SET = 'TICKETING_FILTER_SET';
export const TICKETING_FILTER_SET_UNIT_TIME = 'TICKETING_FILTER_SET_UNIT_TIME';
export const TICKETING_FILTER_SET_TENANT = 'TICKETING_FILTER_SET_TENANT';
export const TICKETING_UPDATE_FIT_COLUMNS_ON_RESIZE = 'TICKETING_UPDATE_FIT_COLUMNS_ON_RESIZE';
export const TICKETING_FILTER_RESET = 'TICKETING_FILTER_RESET';
export const TICKETING_FILTER_RESET_TIMEZONE = 'TICKETING_FILTER_RESET_TIMEZONE';
export const TICKETING_ROLE_SET = 'TICKETING_ROLE_SET';
export const TICKETING_PREVIOUS_PAGE_SET = 'TICKETING_PREVIOUS_PAGE_SET';
export const TICKETING_PREVIOUS_PAGE_SET_TICKETING = 'TICKETING_PREVIOUS_PAGE_SET_TICKETING';
export const TICKETING_PREVIOUS_PAGE_SET_MOBILE = 'TICKETING_PREVIOUS_PAGE_SET_MOBILE';
export const TICKETING_UPDATE_SUCCESS = 'TICKETING_UPDATE_SUCCESS';
export const TICKETING_UPDATE_FAILURE = 'TICKETING_UPDATE_FAILURE';
export const TICKETING_EDITED_SAVED_INITIALIZED = 'TICKETING_EDITED_SAVED_INITIALIZED';
export const TICKETING_FLIGHT_UPDATE_SUCCESS = 'TICKETING_FLIGHT_UPDATE_SUCCESS';


// initial state
const storeState = {
  gridApi: null,
  filter: TicketingFilter.createEmptyFilter,
  flightsTicketing: [],
  selectedFlightsTicketing: [],
  fitColumnsOnResize: true,
  ticketingEdited: FlightTicketingInformation.createEmptyFlightTicketingInformation,
  ticketingUserRole: null,
  previousPage: 'ticketing',
  ticketingEditedSaved: true,
};

// getters
const storeGetters = {
  hasSelectedTicketingFlights: state => state.selectedFlightsTicketing.length > 0,
  getSelectedTicketing: state => state.selectedFlightsTicketing,
  isSelectedTicketingFlights: state => id => !!state.selectedFlightsTicketing.find(s => s.getId() === id),
};

// actions
const storeActions = {
  [TICKETING_FLIGHTS_FIND]: ({ commit, state }) => {
    commit(REQUEST_START);
    commit(TICKETING_CHANGE_FLIGHT_SELECTION, []);
    return HTTP.post('flight-ticketing/find', state.filter)
      .then((resp) => {
        commit(REQUEST_SUCCESS);
        commit(TICKETING_FLIGHTS_FIND_SUCCESS, resp.data);
        commit(TICKETING_CHANGE_FLIGHT_SELECTION, []);
      })
      .catch((err) => {
        commit(TICKETING_FLIGHTS_FIND_SUCCESS, []);
        commit(REQUEST_ERROR, err);
      });
  },
  [TICKETING_FLIGHT_FIND]: ({ commit }, id) => {
    commit(REQUEST_START);
    return HTTP.get(`flight-ticketing/${id}`)
      .then((resp) => {
        commit(REQUEST_SUCCESS);
        commit(TICKETING_FLIGHT_FIND_SUCCESS, FlightTicketingInformation.createFlightTicketingInformationFromData(resp.data));
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
  [TICKETING_GRID_FIT]: ({ state }) => {
    if (state.gridApi) {
      state.gridApi.sizeColumnsToFit();
    }
  },
  [TICKETING_GRID_REFRESH_CELLS]: ({ state }) => {
    if (state.gridApi) {
      state.gridApi.refreshCells({ force: true });
    }
  },
  [TICKETING_FLIGHT_UPDATE]: ({ commit }, { ticketing }) => {
    commit(REQUEST_START);
    commit(TICKETING_EDITED_SAVED_INITIALIZED);
    return HTTP.put('flight-ticketing', ticketing)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(TICKETING_UPDATE_SUCCESS);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.ticketingUpdate'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(REQUEST_ERROR, err);
        commit(TICKETING_UPDATE_FAILURE);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
      });
  },
  [TICKETING_FLIGHTS_DELETE]: ({ commit, dispatch }, ids) => {
    commit(REQUEST_START);
    return HTTP.post('flight-ticketing/batch-delete', ids)
      .then(() => {
        commit(REQUEST_SUCCESS);
        dispatch(TICKETING_FLIGHTS_FIND);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.ticketingDeleteSuccess'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
  [TICKETING_FLIGHTS_CANCEL]: ({ commit, dispatch }, flightTicketingCancellation) => {
    commit(REQUEST_START);
    return HTTP.put('flight-ticketing/cancel', flightTicketingCancellation)
      .then(() => {
        commit(REQUEST_SUCCESS);
        dispatch(TICKETING_FLIGHTS_FIND);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.ticketingCancelSuccess'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
  [TICKETING_FIT_COLUMNS_TO_VIEW]: ({ state }) => {
    if (state.gridApi) {
      state.gridApi.sizeColumnsToFit();
    }
  },
  [TICKETING_FIT_COLUMNS_TO_BEST]: ({ state }) => {
    if (state.gridApi && state.gridApi.columnController && state.gridApi.columnController.columnApi) {
      state.gridApi.columnController.columnApi.autoSizeAllColumns();
    }
  },
  [TICKETING_RECALCULATE_FILTER]: ({ commit, state }) => {
    if (state.filter.dateFrom == null && state.filter.dateTo == null) {
      commit(TICKETING_FILTER_RESET);
    } else {
      commit(TICKETING_FILTER_RESET_TIMEZONE, state.filter.unitOfTime);
    }
  },
  [TICKETING_ROLE_CALCULATE]: ({ commit }, authorities) => {
    if (authorities.includes(ROLE_ADMINISTRATOR_TICKETING) || authorities.includes(`${ROLE_ADMINISTRATOR_TICKETING}_WR`)) {
      commit(TICKETING_ROLE_SET, ROLE_ADMINISTRATOR_TICKETING);
    } else if (authorities.includes(ROLE_SUPERVISOR_TICKETING) || authorities.includes(`${ROLE_SUPERVISOR_TICKETING}_WR`)) {
      commit(TICKETING_ROLE_SET, ROLE_SUPERVISOR_TICKETING);
    } else if (authorities.includes(ROLE_CASHIER_TICKETING) || authorities.includes(`${ROLE_CASHIER_TICKETING}_WR`)) {
      commit(TICKETING_ROLE_SET, ROLE_CASHIER_TICKETING);
    }
  },
  [TICKETING_PREVIOUS_PAGE_SET_TICKETING]: ({ commit }) => {
    commit(TICKETING_PREVIOUS_PAGE_SET, 'ticketing');
  },
  [TICKETING_PREVIOUS_PAGE_SET_MOBILE]: ({ commit }) => {
    commit(TICKETING_PREVIOUS_PAGE_SET, 'ticketingMobile');
  },
  [TICKETING_EMIT]: ({ commit }, ticketing) => {
    commit(TICKETING_FLIGHT_UPDATE_SUCCESS, ticketing);
  },
};

// mutations
const storeMutations = {
  [TICKETING_CHANGE_GRID_API]: (state, gridApi) => {
    state.gridApi = gridApi.api;
  },
  [TICKETING_FLIGHTS_FIND_SUCCESS]: (state, flightsTicketing) => {
    state.flightsTicketing = FlightTicketingInformation.createFlightTicketingInformationFromData(flightsTicketing);
  },
  [TICKETING_FLIGHT_FIND_SUCCESS]: (state, ticketing) => {
    state.ticketingEdited = ticketing !== null ? FlightTicketingInformation.createFlightTicketingInformationFromData(ticketing) : FlightTicketingInformation.createEmptyFlightTicketingInformation;
  },
  [TICKETING_FLIGHT_UPDATE_SUCCESS]: (state, ticketing) => {
    state.ticketingEdited = ticketing !== null ? FlightTicketingInformation.createFlightTicketingInformationFromData(ticketing) : FlightTicketingInformation.createEmptyFlightTicketingInformation;
  },
  [TICKETING_CHANGE_FLIGHT_SELECTION]: (state, selection) => {
    state.selectedFlightsTicketing = selection;
  },
  [TICKETING_FILTER_SET]: (state, filter) => {
    if (filter) {
      state.filter = filter;
    } else {
      state.filter = TicketingFilter.createEmptyFilter;
    }
  },
  [TICKETING_FILTER_SET_UNIT_TIME]: (state, unitOfTime) => {
    state.filter.unitOfTime = unitOfTime;
    if (unitOfTime) {
      state.filter.dateFrom = moment().startOf(unitOfTime);
      state.filter.dateTo = moment().endOf(unitOfTime);
    }
  },
  [TICKETING_FILTER_SET_TENANT]: (state, tenant) => {
    state.filter.tenants = tenant ? [tenant] : null;
  },
  [TICKETING_UPDATE_FIT_COLUMNS_ON_RESIZE]: (state, fitColumnsOnResize) => {
    state.fitColumnsOnResize = fitColumnsOnResize;
  },
  [TICKETING_FILTER_RESET]: (state) => {
    state.filter.dateFrom = moment().startOf('day');
    state.filter.dateTo = moment().endOf('day');
  },
  [TICKETING_FILTER_RESET_TIMEZONE]: (state, unitOfTime) => {
    state.filter.unitOfTime = unitOfTime != null ? unitOfTime : 'day';
    state.filter.dateFrom = moment().startOf(state.filter.unitOfTime)
      .year(state.filter.dateFrom.year())
      .month(state.filter.dateFrom.month())
      .date(state.filter.dateFrom.date());
    state.filter.dateTo = moment().endOf(state.filter.unitOfTime)
      .year(state.filter.dateTo.year())
      .month(state.filter.dateTo.month())
      .date(state.filter.dateTo.date());
  },
  [TICKETING_ROLE_SET]: (state, role) => {
    state.ticketingUserRole = role;
  },
  [TICKETING_PREVIOUS_PAGE_SET]: (state, previousPage) => {
    state.previousPage = previousPage;
  },
  [TICKETING_UPDATE_SUCCESS]: (state) => {
    state.ticketingEditedSaved = true;
  },
  [TICKETING_UPDATE_FAILURE]: (state) => {
    state.ticketingEditedSaved = false;
  },
  [TICKETING_EDITED_SAVED_INITIALIZED]: (state) => {
    state.ticketingEditedSaved = null;
  },
};

export default {
  state: storeState,
  getters: storeGetters,
  actions: storeActions,
  mutations: storeMutations,
};
