import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_vm._t("default",function(){return [_vm._v(" "+_vm._s(_vm.value)+" ")]}),(_vm.ischanged)?_c(VTooltip,{staticClass:"tooltip-changes",attrs:{"bottom":"","min-width":250}},[_c(VIcon,{staticClass:"change-mark",attrs:{"slot":"activator","disabled":_vm.oldValuesRelatedFieldsList.length === 0,"color":"secondary","small":""},slot:"activator"},[_vm._v("signal_cellular_4_bar")]),_c(VContainer,{staticClass:"tooltip-changes-container",attrs:{"nudge-width":"250","pa-1":"","fluid":"","grid-list-md":""}},[_c('h4',{attrs:{"text-xs-center":""}},[_vm._v("Old values")]),_c(VDivider),_vm._l((_vm.oldValuesRelatedFieldsList),function(oldItemValue){return _c(VLayout,{key:oldItemValue.label,attrs:{"row":""}},[_c(VFlex,{attrs:{"xs6":""}},[_vm._v(_vm._s(oldItemValue.label)+" "),_c('br'),_c('span',{staticClass:"font-italic"},[_vm._v("(at "+_vm._s(oldItemValue.ts)+")")])]),_c(VFlex,{attrs:{"xs6":"","text-xs-right":""}},[_vm._v(_vm._s(oldItemValue.value))])],1)})],2)],1):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }