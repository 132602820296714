<template>
  <v-flex>
    <input type="text" id="authorites-filter" class="authorities-filter" :placeholder="$t('roles.authoritiesFilter')" v-on:input="onAuthoritiesFilterChanged()">
    <v-flex class="d-flex ">
      <ag-grid-vue
        class="ag-theme-material"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        @grid-ready="onGridReady"
        :gridOptions="gridOptions"
        :enableFilter="false"
        :suppressHorizontalScroll="true"
        :suppressScrollOnNewData="true"
        rowSelection="single"
        :frameworkComponents="frameworkComponents"
        :rowData="this.userAuthorities"
        @first-data-rendered="onFirstDataRendered"
        :style="`width: 100%; height: 40vh`">
      </ag-grid-vue>
    </v-flex>
  </v-flex>
</template>

<script>


import { AgGridVue } from 'ag-grid-vue';
import { GET_AUTHORITIES } from '../../store/modules/user/userCatalog';
import AgGridOptionsConfigurable from '../ag-grid/mixins/AgGridOptionsConfigurable.vue';
import AgGridApiHoldable from '../ag-grid/mixins/AgGridApiHoldable.vue';
import RolePermissions from '../mixins/RolePermissions.vue';
import { cellClassAgGridRules } from '../ag-grid/mixins/cellClassAgGridRules';
import CrudOperationsDTO from '../shared/model/crudOperationsDTO';
import AgGridRolePermissionSelectCellEditor from '../ag-grid/cell-editors/AgGridRolePermissionSelectCellEditor.vue';
import { ROLE_NONE, ROLE_READ, ROLE_WRITE } from '../../constants/roles';
import AgGridIconAndTextCellRenderer from '../ag-grid/cell-renderers/AgGridIconAndTextCellRenderer.vue';

export default {
  name: 'authority-table',
  mixins: [AgGridOptionsConfigurable, AgGridApiHoldable, RolePermissions],
  components: {
    AgGridVue,
  },
  props: {
    itemAuthorities: { required: true },
    label: { type: String },
    charLenght: { type: Number },
    rules: { type: Array },
    disabled: { type: Boolean },
    multiple: {
      type: Boolean,
      default: false,
    },
    refComp: {
      type: String,
      required: false,
      default: 'refComp',
    },
  },
  computed: {
    authorities() {
      return this.$store.getters.getAuthorities;
    },
    userAuthorities() {
      const that = this;
      return this.authorities.filter(item => !item.includes('_WR')).map((a) => {
        let status = ROLE_NONE;
        if (Array.isArray(this.itemAuthorities)) {
          if (this.itemAuthorities.find(element => element.name === `${a}_WR`)) {
            status = ROLE_WRITE;
          } else if (this.itemAuthorities.find(element => element.name === a)) {
            status = ROLE_READ;
          }
        }
        return { rol: a, status };
      }).sort((current, next) => that.$i18n.t(`roles.${current.rol}`).localeCompare(that.$i18n.t(`roles.${next.rol}`)));
    },
  },
  data() {
    const that = this;
    const rolePermission = this.getRoles();
    return {
      module: null,
      frameworkComponents: {
        AgGridRolePermissionSelectCellEditor,
        AgGridIconAndTextCellRenderer,
      },
      defaultColDef: {
        resizable: false,
        sortable: true,
        cellClassRules: cellClassAgGridRules,
        suppressMovable: true,
      },
      gridOptions: {
        getRowClass: this.getRowClass,
      },
      validData: true,
      columnDefs: [
        {
          field: 'rol',
          getQuickFilterText: params => that.$i18n.t(`roles.${params.data.rol}`),
          headerValueGetter() {
            return that.$i18n.t('roles.authority');
          },
          editable: false,
          cellClass(params) {
            return params.data.status === ROLE_NONE ? '' : 'role-active';
          },
          valueFormatter(params) {
            return that.$i18n.t(`roles.${params.data.rol}`);
          },
        },
        {
          field: 'status',
          getQuickFilterText: params => params.data.status,
          headerValueGetter() {
            return that.$i18n.t('roles.role');
          },
          editable: this.editable(true, rolePermission),
          cellClass(params) {
            return params.data.status === ROLE_NONE ? '' : 'role-active';
          },
          cellEditor: 'AgGridRolePermissionSelectCellEditor',
          cellEditorParams() {
            return {
              itemAuthorities: that.itemAuthorities,
            };
          },
        },
      ],
      contingencyHtlTransportSelected: [],
      crudOperationsDto: new CrudOperationsDTO(),
    };
  },
  methods: {
    onAuthoritiesFilterChanged() {
      this.gridApi.filterManager.setQuickFilter(document.getElementById('authorites-filter').value);
    },
    getAuthorities() {
      this.$store.dispatch(GET_AUTHORITIES);
    },
    onGridReady(params) {
      this.setAgGridApi(params);
      this.gridApi.sizeColumnsToFit();
      this.refreshCellsOnChangeLanguage();
    },
    resized() {
      if (this.gridApi) {
        this.gridApi.sizeColumnsToFit();
      }
    },
    onFirstDataRendered(params) {
      params.api.sizeColumnsToFit();
    },
  },
  mounted() {
    this.getAuthorities();
  },
};
</script>
<style lang="scss">
  .role-active {
    font-weight: bold;
  }
  .authorities-filter {
    width: 100%;
    height: 30px;
    padding: 0 0 0 5px;
    border: 1px solid grey;
  }
</style>
