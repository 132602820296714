<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>

    <ag-grid-dialog-template-editor :is-dialog-open="isDialogOpen" @save="save" @cancel="cancel" :saveDisabled="invalidDataLoads || !validData">

      <template v-slot:header>
        {{$t("operations.editLoadsModalTitle")}} {{flightNumber}}
      </template>

      <template v-slot:default>
        <FlightLoadsTable v-if="loads" :items="loads" :invalidData.sync="invalidDataLoads" :totalLoad.sync="totalLoadValue"/>
      </template>
    </ag-grid-dialog-template-editor>
  </div>
</template>

<script>
import Vue from 'vue';
import AgGridDialogParentCellEditor from '../../mixins/AgGridDialogParentCellEditor.vue';
import AgGridDialogTemplateEditor from '../../templates/AgGridDialogTemplateEditor.vue';
import FlightLoads from '../../model/flightLoads';
import { JSUtils } from '../../../../shared/js/JSUtils';
import FlightLoadsTable from './FlightLoadsTable.vue';

export default Vue.extend({
  mixins: [AgGridDialogParentCellEditor],
  components: {
    AgGridDialogTemplateEditor,
    FlightLoadsTable,
  },
  data() {
    return {
      loads: null,
      invalidDataLoads: true,
      totalLoadValue: 0,
      validData: true,
    };
  },
  computed: {
    flightNumber() {
      return this.connectedFlights.getChildPropertyValue(this.flightBound, ['common', 'flightNo']);
    },
  },
  methods: {
    getValue() {
      if (this.isSaving) {
        this.requestInit();
        const data = {
          loads: this.loads.filter(r => !r.isTotalRow),
          totalLoad: this.loads.filter(r => !!r.isTotalRow)[0],
        };
        this.$http.put(`flights/${this.flightId}/loads`, data)
          .then(resp => this.dialogRequestSuccessCallback(this, resp))
          .catch(error => this.requestErrorCallback(this, error));
      }

      return this.params.value;
    },
  },
  created() {
    this.$http.get(`flights/${this.flightId}/loads`)
      .then((resp) => {
        const rowData = JSUtils.cast(resp.data.loads, FlightLoads);
        for (let i = 0; i < rowData.length; i += 1) {
          rowData[i].airportCode = this.connectedFlights.getChildPropertyValue(this.flightBound, ['common', `airport${rowData[i].airportOrder}IataCode`]);
        }
        const totalLoad = JSUtils.cast(resp.data.totalLoad, FlightLoads);
        totalLoad.isTotalRow = true;
        rowData.push(totalLoad);
        this.loads = rowData;
      });
  },
});
</script>
<style lang="scss">
.loads-table {
  padding: 0;
}

.radio-unit-label {
  padding-top: 1.5vh;
  font-weight: bold;
  margin-right: 1vw;
}
</style>
