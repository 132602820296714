<script>
import Vue from 'vue';
import AgGridApiHoldable from './AgGridApiHoldable.vue';
import { JSUtils } from '../../shared/js/JSUtils';

export default Vue.extend({
  mixins: [AgGridApiHoldable],
  methods: {
    exportAgGridCsv(fileNamePrefix, customExportOptions) {
      const that = this;
      if (this.gridApi) {
        const columnKeys = [];
        if (this.columnApi) {
          this.columnApi.getAllDisplayedColumns().forEach((column) => {
            if (!column.colDef.colCustomConfig || !column.colDef.colCustomConfig.exportCsvDisabled) {
              columnKeys.push(column.colId);
            }
          });
        }

        const defaultExportOptions = {
          fileName: `${fileNamePrefix || 'AccionaFlightsExported'}_${that.$moment().format('YYMMDDHHmm')}.csv`,
          onlySelected: this.gridApi.getSelectedNodes().length > 0,
          columnGroups: true,
          processHeaderCallback(params) {
            if (params.column.colDef && params.column.colDef.colCustomConfig && params.column.colDef.colCustomConfig.headerGetterExportCsv) {
              return params.column.colDef.colCustomConfig.headerGetterExportCsv(params.node).replace(/<br>/g, '\n');
            }
            if (params.column.colDef && params.column.colDef.headerValueGetter) {
              return params.column.colDef.headerValueGetter();
            }
            if (params.column.colDef && params.column.colDef.headerName) {
              return params.column.colDef.headerName;
            }
            return null;
          },
          processCellCallback(params) {
            if (params.column.colDef && params.column.colDef.colCustomConfig && params.column.colDef.colCustomConfig.valueGetterExportCsv) {
              return params.column.colDef.colCustomConfig.valueGetterExportCsv(params.node).replace(/<br>/g, '\n');
            }
            if (params.value && JSUtils.isObject(params.value)) {
              if (Array.isArray(params.value.value)) {
                return params.value.value.filter(v => !!v).map(v => (v.value ? v.value : v)).join(' ');
              }
              return params.value.value;
            }
            return params.value;
          },
        };
        if (this.columnApi) {
          defaultExportOptions.columnKeys = columnKeys;
        }
        const exportOptions = Object.assign({}, defaultExportOptions, customExportOptions);
        this.gridApi.exportDataAsCsv(exportOptions);
      }
    },
    exportAgGridCsvWithHiddenColumns(fileNamePrefix, customExportOptions) {
      const that = this;
      if (this.gridApi) {
        const columnKeys = [];
        if (this.columnApi) {
          this.columnApi.getAllGridColumns().forEach((column) => {
            if (!column.colDef.colCustomConfig || !column.colDef.colCustomConfig.exportCsvDisabled) {
              columnKeys.push(column.colId);
            }
          });
        }

        const defaultExportOptions = {
          fileName: `${fileNamePrefix || 'AccionaFlightsExported'}_${that.$moment().format('YYMMDDHHmm')}.csv`,
          onlySelected: this.gridApi.getSelectedNodes().length > 0,
          columnGroups: true,
          processCellCallback(params) {
            if (params.column.colDef && params.column.colDef.colCustomConfig && params.column.colDef.colCustomConfig.valueGetterExportCsv) {
              return params.column.colDef.colCustomConfig.valueGetterExportCsv(params.node).replace(/<br>/g, '\n');
            }
            if (params.value && JSUtils.isObject(params.value)) {
              if (Array.isArray(params.value.value)) {
                return params.value.value.filter(v => !!v).map(v => (v.value ? v.value : v)).join(' ');
              }
              return params.value.value;
            }
            return params.value;
          },
        };
        if (this.columnApi) {
          defaultExportOptions.columnKeys = columnKeys;
        }
        const exportOptions = Object.assign({}, defaultExportOptions, customExportOptions);
        this.gridApi.exportDataAsCsv(exportOptions);
      }
    },
  },
});
</script>
