<template>
  <v-card class="admin-scheduling-imports-file-card pa-0 mr-3 my-1" :dark="this.$vuetify.dark">
    <v-container class="pa-0" fluid style="overflow: hidden;">
      <v-img :class="'background-image ' + schedulingImportFile.status">
        <v-container fill-height fluid>
          <v-layout fill-height>
            <v-flex xs12 align-end flexbox>
              <div class="status-import">{{ fileStatusLabel }}
                <v-tooltip bottom open-delay="500">
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on" class="file-name">{{ schedulingImportFile.name ?
    schedulingImportFile.name : $t('adminImports.adminSchedulingImports.fileInfo.unknown') }}</div>
                  </template>
                  <span>{{ schedulingImportFile.name }}</span>
                </v-tooltip>
              </div>
              <v-img class="background-icon"
                :src="require(`@/assets/svg-icons/admin-imports/file-${schedulingImportFile.status}.svg`)"
                contain></v-img>
            </v-flex>
          </v-layout>
        </v-container>
      </v-img>
    </v-container>
    <v-card-text class="pa-2">
      <div><v-icon class="mr-2" small>mdi-account-circle-outline</v-icon>{{ schedulingImportFile.author }}
      </div>
      <div><v-icon class="mr-2" small>mdi-upload</v-icon><strong>{{
    $t('adminImports.adminSchedulingImports.fileInfo.fileUploadDate')
  }}</strong> {{ uploadDateTimeLabel }}</div>
      <div v-if="schedulingImportFile.status !== 'PROCESSING'"><v-icon class="mr-2"
          small>mdi-calendar-check</v-icon><strong>{{
    $t('adminImports.adminSchedulingImports.fileInfo.fileImportedDate') }}</strong> {{ importedDateTimeLabel }}
      </div>
      <div class="lapsed-time"><v-icon small>mdi-timer-sand</v-icon><strong> {{ fileTimeTaken }}
        </strong></div>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  name: 'AdminSchedulingImportsFileCard',
  props: {
    schedulingImportFile: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      shouldDisplayDetailsModal: false,
      detailsModalPosition: {},
    };
  },
  computed: {
    uploadDateTimeLabel() {
      return this.$moment(this.schedulingImportFile.uploadDate).format('DD/MM/YYYY HH:mm');
    },
    importedDateTimeLabel() {
      return this.$moment(this.schedulingImportFile.importedDate).format('DD/MM/YYYY HH:mm');
    },
    fileStatusLabel() {
      if (this.schedulingImportFile.status === 'IMPORTED') {
        return this.$i18n.t('adminImports.adminSchedulingImports.status.IMPORTED');
      }
      if (this.schedulingImportFile.status === 'PROCESSING') {
        return this.$i18n.t('adminImports.adminSchedulingImports.status.PROCESSING');
      }

      return this.$i18n.t('adminImports.adminSchedulingImports.status.NOTSTARTED'); // by default : NOT_STARTED
    },
    fileTimeTaken() {
      let timeTaken = 0;
      if (this.schedulingImportFile.status === 'PROCESSING') {
        // Si el estado es PROCESSING, calcula el tiempo desde la fecha de subida hasta ahora
        timeTaken = this.$moment().diff(this.$moment(this.schedulingImportFile.uploadDate));
      } else {
        // Para cualquier otro estado, calcula el tiempo entre la fecha de subida y la fecha de importación
        timeTaken = this.$moment(this.schedulingImportFile.importedDate).diff(this.$moment(this.schedulingImportFile.uploadDate));
      }
      if (timeTaken < 0) {
        return this.$t('adminImports.adminSchedulingImports.fileInfo.invalid');
      }

      const duration = this.$moment.duration(timeTaken);

      // Days difference
      const days = Math.floor(duration.asDays());
      duration.subtract(this.$moment.duration(days, 'days'));

      // Hours difference
      const hours = duration.hours();
      duration.subtract(this.$moment.duration(hours, 'hours'));

      // Minutes difference
      const minutes = duration.minutes();
      duration.subtract(this.$moment.duration(minutes, 'minutes'));

      // Days difference
      const seconds = duration.seconds();

      // Fill zeroes to single digit values
      const daysStr = String(days);
      const hoursStr = String(hours).padStart(2, '0');
      const minutesStr = String(minutes).padStart(2, '0');
      const secondsStr = String(seconds).padStart(2, '0');

      const daysText = days > 1 ? this.$t('adminImports.adminSchedulingImports.fileInfo.days') : this.$t('adminImports.adminSchedulingImports.fileInfo.day');

      if (days > 0) {
        return `${daysStr} ${daysText}  ${hoursStr}:${minutesStr}:${secondsStr}"`;
      }
      return `${hoursStr}:${minutesStr}:${secondsStr}"`;
    },
  },
};
</script>

<style lang="scss">
.admin-scheduling-imports-file-card {
  width: 100%;

  .status-import {
    text-align: left;
    font-weight: bold;
    font-size: 1.2em;
    margin-left: 50px;
    position: absolute;
    margin-top: -10px;
    line-height: 1.1em;
    color: white;

    /* ellipsis */
    /* .file-name {
      font-size: 0.9em;
      height: 80px;
      font-weight: normal;
      font-style: italic;
      max-width: 200px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      }*/

    /* without ellipsis */
    .file-name {
      font-size: 0.9em;
      height: 80px;
      font-weight: normal;
      font-style: italic;
      padding-right: 5px;
      line-break: anywhere;
    }
  }

  .background-image {
    &.IMPORTED {
      background-color: #288b21;
    }

    &.PROCESSING {
      background-color: #b69b00;
    }

    &.NOT_STARTED {
      background-color: rgb(182, 0, 0);
    }
  }

  .background-image {
    /* oversize and alpha to make the image darker */
    height: 75px;
    overflow: visible;
  }

  .background-icon {
    margin-top: -13px;
    margin-left: -15px;
    width: 55px;
    height: 55px !important;
    float: left;
    border-radius: 50%;
    opacity: 0.65;
    box-shadow: inset 0 0 6px 0px rgba(0, 0, 0, 0.85);
  }

  .v-card__text {
    min-height: 100px;
    height: 100%;
  }

  .lapsed-time {
    bottom: 4px;
    right: 10px;
    position: absolute;
  }
}

.admin-scheduling-imports-file-card {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.admin-scheduling-imports-file-card-text {
  overflow: hidden;
  max-width: 100%;
  word-wrap: break-word;
  white-space: nowrap;
}

.admin-scheduling-imports-file-card-details {
  position: absolute;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  border: 2px black solid;
  width: inherit;
  padding: 5px;
  background-color: #eee;
  z-index: 2;
  word-wrap: break-all;
  white-space: nowrap;
  overflow: hidden;
}
</style>
