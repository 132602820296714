<template>
    <v-layout style="max-width: 170px">
    <v-flex class="date-input">
      <date-input :ref="'refComp'" :label="label" :locale="locale" :disabled="disabled" :rules="rules" :char-length="charLenght" :value="date" @input="changeDate" ></date-input>
    </v-flex>
    <v-flex class="time-input">
      <time-input :ref="'refTimeInput'" :disabled="disabled" :rules="rules" v-model="time" @onBlur="changeTime" ></time-input>
    </v-flex>
    </v-layout>
</template>

<script>
import Arrowable from '../mixins/Arrowable.vue';
import DateInput from './DateInput.vue';
import TimeInput from './TimeInput.vue';
import KeyEventsUtils, { keyEvents } from '../keyEvents';

export default {
  mixins: [Arrowable],
  components: {
    DateInput,
    TimeInput,
  },
  props: {
    value: { required: true },
    label: { type: String },
    rules: { type: Array },
    disabled: { type: Boolean },
    locale: { type: String },
    charLenght: { type: Number, default: 10 },
  },
  data() {
    return {
      date: null,
      time: null,
    };
  },
  computed: {
  },
  watch: {
    value(newValue) {
      this.date = newValue ? this.$moment(newValue) : null;
      this.time = newValue ? this.$moment(newValue) : null;
    },
  },
  methods: {
    emitInput() {
      let requestDate = null;
      if (this.date != null) {
        requestDate = this.$moment(this.date);
      }
      if (this.time != null) {
        if (requestDate == null) {
          requestDate = this.$moment();
        }
        requestDate.set('hour', this.$moment(this.time).get('hour'));
        requestDate.set('minute', this.$moment(this.time).get('minute'));
      }

      this.$emit('input', requestDate);
      this.$emit('change', requestDate);
    },
    changeDate(newDateValue) {
      this.date = newDateValue ? this.$moment(newDateValue) : null;
      this.emitInput();
    },
    changeTime(newTimeValue) {
      this.time = newTimeValue ? this.$moment(newTimeValue) : null;
      this.emitInput();
    },

    keyEvent1($event) {
      if (KeyEventsUtils.isArrowEvent($event) || $event.code === keyEvents.ENTER || $event.code === keyEvents.TAB) {
        $event.stopPropagation();
      }
    },
    keyEvent2($event) {
      if (KeyEventsUtils.isArrowEvent($event)) {
        $event.stopPropagation();
      }
      if ($event.code === keyEvents.TAB && $event.shiftKey) {
        $event.stopPropagation();
      }
    },
  },
  mounted() {
    this.date = this.value ? this.$moment(this.value) : null;
    this.time = this.value ? this.$moment(this.value) : null;
  },
};
</script>

<style scoped>
  .date-input {
    max-width: 150px;
  }
  .time-input {
    max-width: 7ch;
  }
</style>
