<template>
  <ag-grid-template-cell-renderer :params="params">
    <span :style="styleObject">{{params.value.value}}</span>
  </ag-grid-template-cell-renderer>
</template>

<script>
import Vue from 'vue';
import AgGridTemplateCellRenderer from './templates/AgGridTemplateCellRenderer.vue';

export default Vue.extend({
  components: {
    AgGridTemplateCellRenderer,
  },
  computed: {
    styleObject() {
      return {
        color: (this.params && this.params.value && this.params.value.color) ? this.params.value.color : '',
      };
    },
  },
});
</script>
