import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"persistent":"","max-width":"600px"},model:{value:(_vm.versionChanged),callback:function ($$v) {_vm.versionChanged=$$v},expression:"versionChanged"}},[_c(VCard,{staticClass:"pa-2"},[_c(VCardTitle,[_c('h5',{staticClass:"headline red--text"},[_vm._v(" "+_vm._s(_vm.$t('messages.newVersion'))+" ")])]),_c(VCardText,{staticClass:"subheading"},[_vm._v(" "+_vm._s(_vm.$t('messages.newVersionDescription'))+" "),_c('br'),_c('strong',[_vm._v("v"+_vm._s(_vm.newVersion))]),_c('br'),_c('br'),(_vm.$i18n.locale === 'es')?[_c('p',[_vm._v("Si al recargar la página no se actualiza la versión siga los siguientes pasos:")]),_c('ol',[_c('li',[_vm._v("Presione "),_c('kbd',[_vm._v("Ctrl")]),_vm._v(" + "),_c('kbd',[_vm._v("Shift")]),_vm._v(" + "),_c('kbd',[_vm._v("R")]),_vm._v(" para recargar la página forzando la actualización de la caché.")]),_c('li',[_vm._v("Si el paso anterior no funciona, limpie la caché de su navegador.")])])]:[_c('p',[_vm._v("If reloading the page does not update the version follow these steps:")]),_c('ol',[_c('li',[_vm._v("Press "),_c('kbd',[_vm._v("Ctrl")]),_vm._v(" + "),_c('kbd',[_vm._v("Shift")]),_vm._v(" + "),_c('kbd',[_vm._v("R")]),_vm._v(" to reload the page forcing the cache update. ")]),_c('li',[_vm._v("If the previous step does not work, clear your browser cache.")])])]],2),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","text":""},on:{"click":_vm.reloadPage}},[_vm._v(_vm._s(_vm.$t('common.reload')))]),_c(VBtn,{attrs:{"color":"primary","outline":"","text":""},on:{"click":_vm.cancel}},[_vm._v(_vm._s(_vm.$t('common.cancel')))])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }