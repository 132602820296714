/* eslint no-param-reassign: "off" */
import moment from 'moment';
import { HTTP } from '../../http';
import { REQUEST_ERROR, REQUEST_START, REQUEST_SUCCESS } from '../store';
import MobileFilter from '../../components/mobile/model/mobileFilter';
import { NOTIFICATION_SHOW } from './notifications';
import i18n from '../../plugins/i18n';
import { ACTION_SET_NO_FLIGHTS_TO_SHOW_MESSAGE } from './noFlightsToShowMessages';


// action
export const MOBILE_FIND_FLIGHTS = 'MOBILE_FIND_FLIGHTS';
export const MOBILE_GET_FLIGHT = 'MOBILE_GET_FLIGHT';
export const MOBILE_GET_FLIGHT_CONNECTION = 'MOBILE_GET_FLIGHT_CONNECTION';
export const MOBILE_GET_FLIGHT_DELAYS = 'MOBILE_GET_FLIGHT_DELAYS';
export const MOBILE_GET_FLIGHT_LOADS = 'MOBILE_GET_FLIGHT_LOADS';
export const MOBILE_GET_FLIGHT_REMARKS = 'MOBILE_GET_FLIGHT_REMARKS';
export const MOBILE_GET_FLIGHT_MILESTONES = 'MOBILE_GET_FLIGHT_MILESTONES';
export const MOBILE_GET_FLIGHT_RAMP_SERVICES = 'MOBILE_GET_FLIGHT_RAMP_SERVICES';
export const MOBILE_UPDATE_FLIGHT_RAMP_SERVICES = 'MOBILE_UPDATE_FLIGHT_RAMP_SERVICES';
export const MOBILE_CREATE_EMPTY_FLIGHT_RAMP_SERVICE = 'MOBILE_CREATE_EMPTY_FLIGHT_RAMP_SERVICE';
export const MOBILE_UPDATE_FLIGHT_MILESTONE = 'MOBILE_UPDATE_FLIGHT_MILESTONE';
export const MOBILE_GET_MAIL = 'MOBILE_GET_MAIL';
export const MOBILE_GET_FLIGHT_BY_ID = 'MOBILE_GET_FLIGHT_BY_ID';
export const MOBILE_START_FLIGHT_SEARCH = 'MOBILE_START_FLIGHT_SEARCH';
export const MOBILE_END_FLIGHT_SEARCH = 'MOBILE_END_FLIGHT_SEARCH';
export const MOBILE_GET_AIRPORT_REQUESTERS = 'MOBILE_GET_AIRPORT_REQUESTERS';
export const MOBILE_GET_AIRPORT_PARKINGS = 'MOBILE_GET_AIRPORT_PARKINGS';

// mutations
export const MOBILE_FIND_FLIGHTS_SUCCESS = 'MOBILE_FIND_FLIGHTS_SUCCESS';
export const MOBILE_GET_FLIGHT_CONNECTION_SUCCESS = 'MOBILE_GET_FLIGHT_CONNECTION_SUCCESS';
export const MOBILE_GET_FLIGHT_SUCCESS = 'MOBILE_GET_FLIGHT_SUCCESS';
export const MOBILE_GET_FLIGHT_DELAYS_SUCCESS = 'MOBILE_GET_FLIGHT_DELAYS_SUCCESS';
export const MOBILE_GET_FLIGHT_LOADS_SUCCESS = 'MOBILE_GET_FLIGHT_LOADS_SUCCESS';
export const MOBILE_FILTER_RESET_TIMEZONE = 'MOBILE_FILTER_RESET_TIMEZONE';
export const MOBILE_GET_FLIGHT_REMARKS_SUCCESS = 'MOBILE_GET_FLIGHT_REMARKS_SUCCESS';
export const MOBILE_GET_FLIGHT_MILESTONES_SUCCESS = 'MOBILE_GET_FLIGHT_MILESTONES_SUCCESS';
export const MOBILE_GET_FLIGHT_RAMP_SERVICES_SUCCESS = 'MOBILE_GET_FLIGHT_RAMP_SERVICES_SUCCESS';
export const MOBILE_UPDATE_FLIGHT_RAMP_SERVICES_SUCCESS = 'MOBILE_UPDATE_FLIGHT_RAMP_SERVICES_SUCCESS';
export const MOBILE_CREATE_EMPTY_FLIGHT_RAMP_SERVICE_SUCCESS = 'MOBILE_CREATE_EMPTY_FLIGHT_RAMP_SERVICE_SUCCESS';
export const MOBILE_PUT_FLIGHT_LOADS = 'MOBILE_PUT_FLIGHT_LOADS';
export const MOBILE_GET_MAIL_SUCCESS = 'MOBILE_GET_MAIL_SUCCESS';
export const MOBILE_GET_FLIGHT_BY_ID_SUCCESS = 'MOBILE_GET_FLIGHT_BY_ID_SUCCESS';
export const MOBILE_GET_FLIGHT_SEARCH_DATA = 'MOBILE_GET_FLIGHT_SEARCH_DATA';
export const MOBILE_GET_FLIGHT_SEARCH_DATA_SUCCESS = 'MOBILE_GET_FLIGHT_SEARCH_DATA_SUCCESS';
export const MOBILE_GET_AIRPORT_REQUESTERS_SUCCESS = 'MOBILE_GET_AIRPORT_REQUESTERS_SUCCESS';
export const MOBILE_GET_AIRPORT_PARKINGS_SUCCESS = 'MOBILE_GET_AIRPORT_PARKINGS_SUCCESS';


// initial state
const storeState = {
  filter: MobileFilter.createEmptyFilter,
  flightsMobile: [],
  flightMobile: [],
  flightMobileConnection: [],
  flightMobileDelays: [],
  flightMobileLoads: [],
  flightMobileRemarks: [],
  flightMobileMilestones: [],
  flightMobileRampServices: [],
  flightMobileRampService: {},
  flightMobileMail: [],
  flightMobileById: [],
  flightMobileSearchData: [],
  mobileAirportRequesters: [],
  airportParkings: [],
};

// getters
const storeGetters = {
  getFlightMobile: state => state.flightMobile,
  getFlightsMobile: state => state.flightsMobile,
  getFlightMobileConnection: state => state.flightMobileConnection,
  getFlightMobileDelays: state => state.flightMobileDelays,
  getFlightMobileLoads: state => state.flightMobileLoads,
  getFlightMobileRemarks: state => state.flightMobileRemarks,
  getFlightMobileMilestones: state => state.flightMobileMilestones,
  getFlightMobileRampServices: state => state.flightMobileRampServices,
  getFlightMobileRampService: state => state.flightMobileRampService,
  getFlightMobileMail: state => state.flightMobileMail,
  getFlightMobileById: state => state.flightMobileById,
  getFlightMobileSearchData: state => state.flightMobileSearchData,
  getMobileAirportRequesters: state => state.mobileAirportRequesters,
  getAirportParkings: state => state.airportParkings,
};

// actions
const storeActions = {
  [MOBILE_FIND_FLIGHTS]: ({ dispatch, commit, state }) => {
    commit(REQUEST_START);
    commit(MOBILE_FIND_FLIGHTS_SUCCESS, []);
    return HTTP.post('mobile/flights/find', state.filter)
      .then((resp) => {
        commit(REQUEST_SUCCESS);
        commit(MOBILE_FIND_FLIGHTS_SUCCESS, resp.data);
        dispatch(ACTION_SET_NO_FLIGHTS_TO_SHOW_MESSAGE, resp.status);
      })
      .catch((err) => {
        dispatch(ACTION_SET_NO_FLIGHTS_TO_SHOW_MESSAGE, err.response.status);
        console.log(err); // eslint-disable-line no-console
        commit(REQUEST_ERROR);
      });
  },
  [MOBILE_GET_FLIGHT]: ({ commit }, flightId) => {
    commit(REQUEST_START);
    commit(MOBILE_FIND_FLIGHTS_SUCCESS, []);
    return HTTP.get(`mobile/flights/${flightId}`)
      .then((resp) => {
        commit(REQUEST_SUCCESS);
        commit(MOBILE_GET_FLIGHT_SUCCESS, resp.data);
      })
      .catch((err) => {
        console.log(err); // eslint-disable-line no-console
        commit(REQUEST_ERROR);
      });
  },
  [MOBILE_GET_FLIGHT_CONNECTION]: ({ commit }, flightId) => {
    commit(REQUEST_START);
    return HTTP.get(`mobile/flights/${flightId}`)
      .then((resp) => {
        commit(REQUEST_SUCCESS);
        commit(MOBILE_GET_FLIGHT_CONNECTION_SUCCESS, resp.data);
      })
      .catch((err) => {
        console.log(err); // eslint-disable-line no-console
        commit(REQUEST_ERROR);
      });
  },
  [MOBILE_GET_FLIGHT_DELAYS]: ({ commit }, flightId) => {
    commit(REQUEST_START);
    return HTTP.get(`flights/${flightId}/delays`)
      .then((resp) => {
        commit(REQUEST_SUCCESS);
        commit(MOBILE_GET_FLIGHT_DELAYS_SUCCESS, resp.data);
      })
      .catch((err) => {
        console.log(err); // eslint-disable-line no-console
        commit(REQUEST_ERROR);
      });
  },
  [MOBILE_GET_FLIGHT_LOADS]: ({ commit }, flightId) => {
    commit(MOBILE_GET_FLIGHT_LOADS_SUCCESS, []);
    commit(REQUEST_START);
    return HTTP.get(`flights/${flightId}/loads-holding`)
      .then((resp) => {
        const flightMobileLoads = resp.data;
        if (flightMobileLoads !== null) {
          if (!flightMobileLoads.totalLoad.unit) {
            flightMobileLoads.totalLoad.unit = 'KILOGRAMS';
          }
        }
        commit(REQUEST_SUCCESS);
        commit(MOBILE_GET_FLIGHT_LOADS_SUCCESS, flightMobileLoads);
      })
      .catch((err) => {
        console.log(err); // eslint-disable-line no-console
        commit(REQUEST_ERROR);
      });
  },
  [MOBILE_PUT_FLIGHT_LOADS]: ({ commit }, { flightId, data }) => {
    commit(REQUEST_START);
    return HTTP.put(`flights/${flightId}/loads-holding`, data)
      .then((resp) => {
        commit(REQUEST_SUCCESS);
        commit(MOBILE_GET_FLIGHT_LOADS_SUCCESS, resp.data);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.loadsUpdate'),
          color: 'success',
        });
      })
      .catch((err) => {
        console.log(err); // eslint-disable-line no-console
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR);
      });
  },
  [MOBILE_GET_FLIGHT_REMARKS]: ({ commit }, flightId) => {
    commit(REQUEST_START);
    return HTTP.get(`flights/${flightId}/remarks`)
      .then((resp) => {
        commit(REQUEST_SUCCESS);
        commit(MOBILE_GET_FLIGHT_REMARKS_SUCCESS, resp.data);
      })
      .catch((err) => {
        console.log(err); // eslint-disable-line no-console
        commit(REQUEST_ERROR);
      });
  },
  [MOBILE_GET_FLIGHT_MILESTONES]: ({ commit }, flightId) => {
    commit(REQUEST_START);
    return HTTP.get(`flights/${flightId}/milestones`)
      .then((resp) => {
        commit(REQUEST_SUCCESS);
        commit(MOBILE_GET_FLIGHT_MILESTONES_SUCCESS, resp.data);
      })
      .catch((err) => {
        console.log(err); // eslint-disable-line no-console
        commit(REQUEST_ERROR);
      });
  },
  [MOBILE_UPDATE_FLIGHT_MILESTONE]: ({ commit }, { flightId, milestoneId, value }) => {
    commit(REQUEST_START);
    // clear flightMobile to refresh the flight
    return HTTP.put(`flights/${flightId}/milestones/${milestoneId}`, value)
      .then((resp) => {
        // update mobile get flight when milestone is updated
        if (resp.data) {
          storeState.flightMobile = resp.data;
        }
        commit(REQUEST_SUCCESS);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.milestoneUpdate'),
          color: 'success',
        });
      })
      .catch((err) => {
        console.log(err); // eslint-disable-line no-console
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR);
      });
  },
  [MOBILE_GET_FLIGHT_RAMP_SERVICES]: ({ commit }, flightId) => {
    commit(REQUEST_START);
    return HTTP.get(`mobile/${flightId}/ramp/services`)
      .then((resp) => {
        commit(REQUEST_SUCCESS);
        commit(MOBILE_GET_FLIGHT_RAMP_SERVICES_SUCCESS, resp.data);
      })
      .catch((err) => {
        console.log(err); // eslint-disable-line no-console
        commit(REQUEST_ERROR);
      });
  },
  [MOBILE_CREATE_EMPTY_FLIGHT_RAMP_SERVICE]: ({ commit }, { flightId, serviceId }) => {
    commit(REQUEST_START);
    // return HTTP.get(`mobile/${flightId}/ramp/services/create`)
    return HTTP.get(`mobile/${flightId}/ramp/services/${serviceId}`)
      .then((resp) => {
        commit(REQUEST_SUCCESS);
        commit(MOBILE_CREATE_EMPTY_FLIGHT_RAMP_SERVICE_SUCCESS, resp.data);
        return resp.data;
      })
      .catch((err) => {
        console.log(err); // eslint-disable-line no-console
        commit(REQUEST_ERROR);
      });
  },
  [MOBILE_UPDATE_FLIGHT_RAMP_SERVICES]: ({ commit }, { flightId, rampService }) => {
    commit(REQUEST_START);
    return HTTP.post(`mobile/${flightId}/ramp/services/request`, rampService)
      .then((resp) => {
        commit(REQUEST_SUCCESS);
        commit(MOBILE_UPDATE_FLIGHT_RAMP_SERVICES_SUCCESS, resp.data);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.successNotification'),
          color: 'success',
        });
      })
      .catch((err) => {
        console.log(err); // eslint-disable-line no-console
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR);
      });
  },
  [MOBILE_GET_MAIL]: ({ commit }, flightId) => {
    commit(REQUEST_START);
    return HTTP.get(`flights/${flightId}/messages/last-of-each-type`)
      .then((resp) => {
        commit(REQUEST_SUCCESS);
        commit(MOBILE_GET_MAIL_SUCCESS, resp.data);
      })
      .catch((err) => {
        console.log(err); // eslint-disable-line no-console
        commit(REQUEST_ERROR);
        console.log('mail error: ', err); // eslint-disable-line no-console
      });
  },
  [MOBILE_GET_FLIGHT_BY_ID]: ({ commit }, flightId) => {
    commit(REQUEST_START);
    return HTTP.get(`operations/flights/findById/${flightId}`)
      .then((resp) => {
        commit(REQUEST_SUCCESS);
        commit(MOBILE_GET_FLIGHT_BY_ID_SUCCESS, resp.data);
      })
      .catch((err) => {
        console.log(err); // eslint-disable-line no-console
        commit(REQUEST_ERROR);
      });
  },
  // eslint-disable-next-line no-unused-vars
  [MOBILE_GET_FLIGHT_SEARCH_DATA]: ({ commit }, flightId) => {
    commit(REQUEST_START);
    return HTTP.get(`flights/${flightId}/pax-bag-search`)
      .then((resp) => {
        commit(REQUEST_SUCCESS);
        commit(MOBILE_GET_FLIGHT_SEARCH_DATA_SUCCESS, resp.data);
      })
      .catch((err) => {
        console.log(err); // eslint-disable-line no-console
        commit(REQUEST_ERROR);
      });
  },
  [MOBILE_START_FLIGHT_SEARCH]: ({ commit }, flightId) => {
    commit(REQUEST_START);
    return HTTP.patch(`flights/${flightId}/pax-bag-search/start`)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.searchStarted'),
          color: 'success',
        });
      })
      .catch((err) => {
        console.log(err); // eslint-disable-line no-console
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR);
      });
  },
  [MOBILE_END_FLIGHT_SEARCH]: ({ commit }, { flightId, data }) => {
    commit(REQUEST_START);
    return HTTP.patch(`flights/${flightId}/pax-bag-search/end`, data)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.searchEnded'),
          color: 'success',
        });
      })
      .catch((err) => {
        console.log(err); // eslint-disable-line no-console
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR);
      });
  },
  [MOBILE_GET_AIRPORT_REQUESTERS]: ({ commit }) => {
    commit(REQUEST_START);
    return HTTP.get('airport-requesters')
      .then((resp) => {
        // add text property to each object from name
        resp.data.forEach((item) => {
          item.text = item.name;
        });
        commit(REQUEST_SUCCESS);
        commit(MOBILE_GET_AIRPORT_REQUESTERS_SUCCESS, resp.data);
      })
      .catch((err) => {
        console.log(err); // eslint-disable-line no-console
        commit(REQUEST_ERROR);
        console.log('airport requesters error: ', err); // eslint-disable-line no-console
      });
  },
  [MOBILE_GET_AIRPORT_PARKINGS]: ({ commit }) => {
    commit(REQUEST_START);
    return HTTP.get('airport-parkings')
      .then((resp) => {
        // add text property to each object from code
        // add description property to each object from comments
        resp.data.forEach((item) => {
          item.text = item.code;
          item.description = item.comments;
        });
        commit(REQUEST_SUCCESS);
        commit(MOBILE_GET_AIRPORT_PARKINGS_SUCCESS, resp.data);
      })
      .catch((err) => {
        console.log(err); // eslint-disable-line no-console
        commit(REQUEST_ERROR);
        console.log('airport parkings error: ', err); // eslint-disable-line no-console
      });
  },


};


// mutations
const storeMutations = {
  [MOBILE_FIND_FLIGHTS_SUCCESS]: (state, flightsMobile) => {
    state.flightsMobile = flightsMobile;
  },
  [MOBILE_GET_FLIGHT_SUCCESS]: (state, flightMobile) => {
    state.flightMobile = flightMobile;
  },
  [MOBILE_GET_FLIGHT_CONNECTION_SUCCESS]: (state, flightMobileConnection) => {
    state.flightMobileConnection = flightMobileConnection;
  },
  [MOBILE_GET_FLIGHT_DELAYS_SUCCESS]: (state, flightMobileDelays) => {
    state.flightMobileDelays = flightMobileDelays;
  },
  [MOBILE_GET_FLIGHT_REMARKS_SUCCESS]: (state, flightMobileRemarks) => {
    state.flightMobileRemarks = flightMobileRemarks;
  },
  [MOBILE_GET_FLIGHT_LOADS_SUCCESS]: (state, flightMobileLoads) => {
    state.flightMobileLoads = flightMobileLoads;
  },
  [MOBILE_GET_FLIGHT_MILESTONES_SUCCESS]: (state, flightMobileMilestones) => {
    state.flightMobileMilestones = flightMobileMilestones;
  },
  [MOBILE_GET_FLIGHT_RAMP_SERVICES_SUCCESS]: (state, flightMobileRampServices) => {
    state.flightMobileRampServices = flightMobileRampServices;
  },
  [MOBILE_UPDATE_FLIGHT_RAMP_SERVICES_SUCCESS]: (state, flightMobileRampService) => {
    state.flightMobileRampService = flightMobileRampService;
  },
  [MOBILE_CREATE_EMPTY_FLIGHT_RAMP_SERVICE_SUCCESS]: (state, flightMobileRampService) => {
    state.flightMobileRampService = flightMobileRampService;
  },
  [MOBILE_GET_MAIL_SUCCESS]: (state, flightMobileMail) => {
    state.flightMobileMail = flightMobileMail;
  },
  [MOBILE_GET_FLIGHT_BY_ID_SUCCESS]: (state, flightMobileById) => {
    state.flightMobileById = flightMobileById;
  },
  [MOBILE_FILTER_RESET_TIMEZONE]: (state) => {
    state.filter.date = moment().startOf('day')
      .year(state.filter.date.year())
      .month(state.filter.date.month())
      .date(state.filter.date.date());
  },
  [MOBILE_GET_FLIGHT_SEARCH_DATA_SUCCESS]: (state, flightMobileSearchData) => {
    state.flightMobileSearchData = flightMobileSearchData;
  },
  [MOBILE_GET_AIRPORT_REQUESTERS_SUCCESS]: (state, mobileAirportRequesters) => {
    state.mobileAirportRequesters = mobileAirportRequesters;
  },
  [MOBILE_GET_AIRPORT_PARKINGS_SUCCESS]: (state, airportParkings) => {
    state.airportParkings = airportParkings;
  },
};

export default {
  state: storeState,
  getters: storeGetters,
  actions: storeActions,
  mutations: storeMutations,
};
