import { render, staticRenderFns } from "./RushBaggageListChangesList.vue?vue&type=template&id=e9db9f78"
import script from "./RushBaggageListChangesList.vue?vue&type=script&lang=js"
export * from "./RushBaggageListChangesList.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports