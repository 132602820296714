import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListTile } from 'vuetify/lib/components/VList';
import { VListTileAction } from 'vuetify/lib/components/VList';
import { VListTileContent } from 'vuetify/lib/components/VList';
import { VListTileTitle } from 'vuetify/lib/components/VList';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.isEmptyMilestones())?_c(VContainer,{staticClass:"infoMobileMilestones"},[_c(VCard,{attrs:{"dark":this.$vuetify.dark}},[_c(VCardTitle,{staticClass:"py-2 px-3"},[_c(VIcon,{attrs:{"large":""}},[_vm._v("emoji_flags")]),_c('h4',{staticClass:"px-2"},[_vm._v(_vm._s(_vm.$t('dispatcher.milestones')))]),_c(VSpacer),_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){_vm.showMilestones = !_vm.showMilestones}}},[_c(VIcon,{attrs:{"large":""}},[_vm._v(_vm._s(_vm.showMilestones ? 'mdi-chevron-up' : 'mdi-chevron-down'))])],1)],1),_c(VDivider),_c(VList,{directives:[{name:"show",rawName:"v-show",value:(_vm.showMilestones),expression:"showMilestones"}],staticClass:"pa-0"},_vm._l((_vm.flightMobileMilestones),function(milestone,index){return _c('div',{key:milestone.milestoneId,class:milestone.milestoneType},[(milestone.airportServiceAgentPermission === 'READ' || milestone.airportServiceAgentPermission === 'EDIT')?_c('div',[_c(VListTile,[_c(VListTileAction,[_c(VIcon,{attrs:{"color":milestone.airportServiceAgentPermission === 'EDIT' ? 'secondary' : 'grey'}},[_vm._v(_vm._s(_vm.iconByType(milestone.milestoneType)))])],1),_c(VListTileContent,[_c(VListTileTitle,[_vm._v(_vm._s(milestone.milestoneName)+" ")])],1),_c(VListTileAction,{staticClass:"full-width"},[_c('mobile-input-type',{class:milestone.airportServiceAgentPermission === 'EDIT' ? 'editable' : 'readonly',attrs:{"name":milestone.milestoneName,"value":milestone.flightMilestoneValue,"typeValue":milestone.milestoneType,"editable":milestone.airportServiceAgentPermission === 'EDIT',"options":milestone.milestoneOptions},on:{"input":function($event){return _vm.saveMilestone(milestone, $event)}}})],1)],1),(index < _vm.flightMobileMilestones.length - 1)?_c(VDivider,{attrs:{"inset":""}}):_vm._e()],1):_vm._e()])}),0)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }