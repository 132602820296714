/* eslint no-param-reassign: "off" */
import moment from 'moment';
import { HTTP } from '../../../http';
import { ROLE_TOWING_ADHOC_TASKS_APPLICANT, ROLE_TOWING_ADHOC_TASKS_DISPATCHER } from '../../../constants/roles';
import TowingAdhocTaskFilter from '../../../components/towingAdhocTask/model/towingAdhocTaskFilter';
import { REQUEST_ERROR, REQUEST_START, REQUEST_SUCCESS } from '../../store';
import { NOTIFICATION_SHOW } from '../notifications';
import TowingAdhocTaskFactory from '../../../components/towingAdhocTask/model/towingAdhocTaskFactory';
import i18n from '../../../plugins/i18n';

// action
export const TOWING_ADHOC_TASK_ROLE_CALCULATE = 'TOWING_ADHOC_TASK_ROLE_CALCULATE';

export const TOWING_ADHOC_TASK_SET_GRID_API = 'TOWING_ADHOC_TASK_SET_GRID_API';

export const TOWING_ADHOC_TASK_FIND = 'TOWING_ADHOC_TASK_FIND';
export const TOWING_ADHOC_TASK_APPROVE = 'TOWING_ADHOC_TASK_APPROVE';
export const TOWING_ADHOC_TASK_CANCEL = 'TOWING_ADHOC_TASK_CANCEL';
export const TOWING_ADHOC_TASK_DELETE = 'TOWING_ADHOC_TASK_DELETE';

export const FIT_COLUMNS_TO_VIEW = 'FIT_COLUMNS_TO_VIEW';
export const FIT_COLUMNS_TO_BEST = 'FIT_COLUMNS_TO_BEST';

// mutations
export const TOWING_ADHOC_TASK_ROLE_SET = 'TOWING_ADHOC_TASK_ROLE_SET';

export const TOWING_ADHOC_TASK_FIND_SUCCESS = 'TOWING_ADHOC_TASK_FIND_SUCCESS';
export const TOWING_ADHOC_TASK_CHANGE_SELECTION = 'TOWING_ADHOC_TASK_CHANGE_SELECTION';
export const TOWING_ADHOC_TASK_SET_CHANGE = 'FLIGHT_TOWING_ADHOC_TASK_SET_CHANGE ';

export const TOWING_ADHOC_TASK_FILTER_RESET = 'TOWING_ADHOC_TASK_FILTER_RESET ';
export const TOWING_ADHOC_TASK_FILTER_RESET_TIMEZONE = 'TOWING_ADHOC_TASK_FILTER_RESET_TIMEZONE ';
export const TOWING_ADHOC_TASK_FILTER_INCREASE_DAY = 'TOWING_ADHOC_TASK_FILTER_INCREASE_DAY ';
export const TOWING_ADHOC_TASK_FILTER_DECREASE_DAY = 'TOWING_ADHOC_TASK_FILTER_DECREASE_DAY ';
export const TOWING_ADHOC_TASK_FILTER_SET_DAY = 'TOWING_ADHOC_TASK_FILTER_SET_DAY ';

export const UPDATE_FIT_COLUMNS_ON_RESIZE = 'UPDATE_FIT_COLUMNS_ON_RESIZE ';

// initial state
const storeState = {
  towingAdhocTaskUserRole: null,
  gridApi: null,
  filter: TowingAdhocTaskFilter.createEmptyFilter,
  towingAdhocTasks: [],
  selectedTowingAdhocTasks: [],
  towingAdhocTasksChanges: [],
  fitColumnsOnResize: true,
};

// getters
const storeGetters = {
  hasSelectedTowingAdhocTasks: state => state.selectedTowingAdhocTasks.length > 0,
  isSelectedTowingAdhocTasks: state => id => !!state.selectedTowingAdhocTasks.find(s => s.getId() === id),
  getSelectedTowingAdhocTasks: state => state.selectedTowingAdhocTasks,
  getSelectedTowingAdhocTaskFlights: state => state.selectedTowingAdhocTasks.filter(rampServices => rampServices.flightId != null && rampServices.flightTripNumber != null),
  getSelectedTowingAdhocTaskAircraftGround: state => state.selectedTowingAdhocTasks.filter(rampServices => rampServices.flightId != null && rampServices.flightTripNumber == null),
};

// actions
const storeActions = {
  [TOWING_ADHOC_TASK_ROLE_CALCULATE]: ({ commit }, authorities) => {
    if (authorities.includes(ROLE_TOWING_ADHOC_TASKS_DISPATCHER) || authorities.includes(`${ROLE_TOWING_ADHOC_TASKS_DISPATCHER}_WR`)) {
      commit(TOWING_ADHOC_TASK_ROLE_SET, ROLE_TOWING_ADHOC_TASKS_DISPATCHER);
    } else if (authorities.includes(ROLE_TOWING_ADHOC_TASKS_APPLICANT) || authorities.includes(`${ROLE_TOWING_ADHOC_TASKS_APPLICANT}_WR`)) {
      commit(TOWING_ADHOC_TASK_ROLE_SET, ROLE_TOWING_ADHOC_TASKS_APPLICANT);
    }
  },
  [TOWING_ADHOC_TASK_FIND]: ({ commit, state }) => {
    console.log(state.filter); // eslint-disable-line no-console
    commit(REQUEST_START);
    commit(TOWING_ADHOC_TASK_CHANGE_SELECTION, []);
    return HTTP.post('towing-adhoc-task/find', state.filter)
      .then((resp) => {
        commit(REQUEST_SUCCESS);
        commit(
          TOWING_ADHOC_TASK_FIND_SUCCESS,
          TowingAdhocTaskFactory.createTowingAdhocTaskFromData(resp.data),
        );
        commit(TOWING_ADHOC_TASK_CHANGE_SELECTION, []);
      })
      .catch((err) => {
        commit(TOWING_ADHOC_TASK_FIND_SUCCESS, []);
        commit(REQUEST_ERROR, err);
      });
  },
  [TOWING_ADHOC_TASK_APPROVE]: ({ commit, dispatch }, towingAdhocTaskDTOList) => {
    commit(REQUEST_START);
    return HTTP.post('towing-adhoc-task/batch-approve', towingAdhocTaskDTOList)
      .then(() => {
        commit(REQUEST_SUCCESS);
        dispatch(TOWING_ADHOC_TASK_FIND);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.rampServicesApproveSuccess'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
  [TOWING_ADHOC_TASK_CANCEL]: ({ commit, dispatch }, towingAdhocTaskDTOList) => {
    commit(REQUEST_START);
    return HTTP.post('towing-adhoc-task/batch-cancel', towingAdhocTaskDTOList)
      .then(() => {
        commit(REQUEST_SUCCESS);
        dispatch(TOWING_ADHOC_TASK_FIND);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.rampServicesCancelSuccess'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
  [TOWING_ADHOC_TASK_DELETE]: ({ commit, dispatch }, towingAdhocTaskDTOList) => {
    commit(REQUEST_START);
    return HTTP.post('towing-adhoc-task/batch-delete', towingAdhocTaskDTOList)
      .then(() => {
        commit(REQUEST_SUCCESS);
        dispatch(TOWING_ADHOC_TASK_FIND);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.rampServicesDeleteSuccess'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
  [FIT_COLUMNS_TO_VIEW]: ({ state }) => {
    if (state.gridApi) {
      state.gridApi.sizeColumnsToFit();
    }
  },
  [FIT_COLUMNS_TO_BEST]: ({ state }) => {
    if (state.gridApi && state.gridApi.columnController && state.gridApi.columnController.columnApi) {
      state.gridApi.columnController.columnApi.autoSizeAllColumns();
    }
  },
};

// mutations
const storeMutations = {
  [TOWING_ADHOC_TASK_ROLE_SET]: (state, role) => {
    state.towingAdhocTaskUserRole = role;
  },
  [TOWING_ADHOC_TASK_SET_GRID_API]: (state, gridApi) => {
    state.gridApi = gridApi.api;
  },
  [TOWING_ADHOC_TASK_FIND_SUCCESS]: (state, towingAdhocTasks) => {
    state.towingAdhocTasks = towingAdhocTasks;
  },
  [TOWING_ADHOC_TASK_CHANGE_SELECTION]: (state, selection) => {
    state.selectedTowingAdhocTasks = selection;
  },
  [TOWING_ADHOC_TASK_FILTER_RESET]: (state) => {
    state.filter.dateFrom = moment().startOf('day');
    state.filter.dateTo = moment().endOf('day');
    state.filter.timezoneDate = moment.defaultZone.name;
  },
  [TOWING_ADHOC_TASK_FILTER_RESET_TIMEZONE]: (state) => {
    state.filter.dateFrom = moment().startOf('day')
      .year(state.filter.dateFrom.year())
      .month(state.filter.dateFrom.month())
      .date(state.filter.dateFrom.date());
    state.filter.dateTo = moment().endOf('day')
      .year(state.filter.dateTo.year())
      .month(state.filter.dateTo.month())
      .date(state.filter.dateTo.date());

    state.filter.timezoneDate = moment.defaultZone.name;
  },
  [TOWING_ADHOC_TASK_FILTER_INCREASE_DAY]: (state) => {
    state.filter.dateFrom = moment().startOf('day')
      .year(state.filter.dateFrom.year())
      .month(state.filter.dateFrom.month())
      .date(state.filter.dateFrom.date())
      .add(1, 'days')
      .startOf('day');

    state.filter.dateTo = moment().endOf('day')
      .year(state.filter.dateTo.year())
      .month(state.filter.dateTo.month())
      .date(state.filter.dateTo.date())
      .add(1, 'days');
  },
  [TOWING_ADHOC_TASK_FILTER_DECREASE_DAY]: (state) => {
    state.filter.dateFrom = moment().startOf('day')
      .year(state.filter.dateFrom.year())
      .month(state.filter.dateFrom.month())
      .date(state.filter.dateFrom.date())
      .subtract(1, 'days');

    state.filter.dateTo = moment().endOf('day')
      .year(state.filter.dateTo.year())
      .month(state.filter.dateTo.month())
      .date(state.filter.dateTo.date())
      .subtract(1, 'days')
      .endOf('day');
  },
  [TOWING_ADHOC_TASK_FILTER_SET_DAY]: (state, dateSelected) => {
    state.filter.dateFrom = moment(dateSelected).startOf('day');

    state.filter.dateTo = moment(dateSelected).endOf('day');
  },
  [UPDATE_FIT_COLUMNS_ON_RESIZE]: (state, fitColumnsOnResize) => {
    state.fitColumnsOnResize = fitColumnsOnResize;
  },
};

export default {
  state: storeState,
  getters: storeGetters,
  actions: storeActions,
  mutations: storeMutations,
};
