import { VBtn } from 'vuetify/lib/components/VBtn';
import { VFabTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpeedDial } from 'vuetify/lib/components/VSpeedDial';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.hasPermissions(_vm.rolePermission))?_c(VFabTransition,[(_vm.selectionMode)?_c(VSpeedDial,{key:_vm.activeSpeedDial.key,attrs:{"fixed":"","bottom":"","right":"","direction":"left","open-on-hover":_vm.activeSpeedDial.hover,"transition":_vm.activeSpeedDial.transition},model:{value:(_vm.fabSelection),callback:function ($$v) {_vm.fabSelection=$$v},expression:"fabSelection"}},[_c(VBtn,{attrs:{"slot":"activator","color":"","dark":"","fab":""},slot:"activator",model:{value:(_vm.fabSelection),callback:function ($$v) {_vm.fabSelection=$$v},expression:"fabSelection"}},[_c(VIcon,[_vm._v("build")]),_c(VIcon,[_vm._v("close")])],1),_c(VTooltip,{attrs:{"top":""}},[_c(VBtn,{attrs:{"slot":"activator","fab":"","dark":"","small":"","color":""},on:{"click":_vm.clickArchive},slot:"activator"},[_c(VIcon,[_vm._v("archive")])],1),_c('span',[_vm._v(_vm._s(_vm.$t('common.archiveSelectedFlights')))])],1),_c(VTooltip,{attrs:{"top":""}},[_c(VBtn,{attrs:{"slot":"activator","fab":"","dark":"","small":"","color":""},on:{"click":_vm.clickUnarchive},slot:"activator"},[_c(VIcon,[_vm._v("unarchive")])],1),_c('span',[_vm._v(_vm._s(_vm.$t('common.unArchiveSelectedFlights')))])],1)],1):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }