<template>
  <v-card>
    <v-layout row align-center>
      <v-flex xs6>
        <v-card-title primary-title>
          <div>
            <div class="headline">{{$t("administration.aviNetReadTitle")}}</div>
            <div>{{$t("administration.aviNetReadText")}}</div>
          </div>
        </v-card-title>
      </v-flex>

      <v-flex xs6>
        <v-dialog v-model="dialog" max-width="50%" v-if="hasPermissions(rolePermission)">
          <v-btn slot="activator" color="primary" dark>{{$t("administration.aviNetRead")}}</v-btn>
          <v-card :dark="this.$vuetify.dark" :color="this.$vuetify.dark ? 'bggrey_dark' : 'bggrey'">
            <v-toolbar dark color="primary">
              <v-icon>dns</v-icon>
              <v-toolbar-title>{{$t("administration.aviNetReadConfirmation")}}</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              {{$t("administration.continueAlert")}}
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn flat color="primary" @click.native="cancel">{{$t("common.cancel")}}</v-btn>
              <v-btn color="primary" depressed @click.native="confirm">{{$t("common.confirm")}}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-btn v-else color="primary" disabled>{{$t("administration.aviNetRead")}}</v-btn>
      </v-flex>
    </v-layout>

    <v-divider light></v-divider>

    <AdminResult :is-error="isError" :is-success="isSuccess" :loading="loading" :time="time" :traces="traces"/>
  </v-card>
</template>
<script>
import { ADMIN_AVINET_READ } from '../../../store/modules/admin/avinet';
import AdminResult from '../AdminResult.vue';
import RolePermissions from '../../mixins/RolePermissions.vue';

export default {
  name: 'AvinetRead',
  mixins: [RolePermissions],
  components: { AdminResult },
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    loading() {
      return !!this.$store.state.admin.avinet.readLoading;
    },
    time() {
      return this.$store.state.admin.avinet.readLastRequestTime ? this.$moment(this.$store.state.admin.avinet.readLastRequestTime).format('DD/MM/YYYY HH:mm') : null;
    },
    isSuccess() {
      return this.$store.getters.isAvinetReadSuccess;
    },
    isError() {
      return this.$store.getters.isAvinetReadError;
    },
    traces() {
      return this.$store.state.admin.avinet.readResult;
    },
    rolePermission() {
      return `${this.$route.meta.role}_WR`;
    },
  },
  methods: {
    cancel() {
      this.dialog = false;
    },
    confirm() {
      this.$store.dispatch(ADMIN_AVINET_READ);
      this.dialog = false;
    },
  },
};
</script>
