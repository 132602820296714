<template>
  <v-card>
    <v-card-text>
      <v-container fluid>
        <v-layout justify-center>
          <v-flex v-flex-none style="margin-right: 2ch">
            <date-input v-char-length.date v-model="startDateComputed" :locale="$i18n.locale" :label="this.$i18n.t('common.fromDate')"></date-input>
          </v-flex>

          <v-flex v-flex-none style="margin-right: 2ch">
            <date-input v-char-length.date v-model="endDateComputed" :locale="$i18n.locale" :label="this.$i18n.t('common.toDate')"></date-input>
          </v-flex>

          <v-flex v-flex-none style="margin-right: 2ch">
            <days-of-week v-model="daysOfWeekComputed"></days-of-week>
          </v-flex>

          <v-flex v-flex-none style="margin-right: 2ch">
            <v-tooltip top>
              <v-text-field
                slot="activator"
                type="number"
                v-model="frequencyComputed"
                :label="this.$i18n.t('scheduling.frequency')"
                min="1"></v-text-field>
              <span>{{$t("scheduling.eachXweeks")}}</span>
            </v-tooltip>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card-text>
  </v-card>
</template>
<script>
import DateInput from '../../ui/DateInput.vue';
import DaysOfWeek from '../../ui/DaysOfWeek.vue';
import DaysOfWeekModel from '../model/daysOfWeek';

export default {
  name: 'scheduling-long-term-form',
  components: {
    DaysOfWeek,
    DateInput,
  },
  props: {
    startDate: { type: Date },
    endDate: { type: Date },
    daysOfWeek: {
      type: DaysOfWeekModel,
      required: true,
    },
    frequency: { type: Number },
  },
  computed: {
    startDateComputed: {
      get() {
        return this.startDate;
      },
      set(data) {
        this.$emit('update:startDate', this.$moment(data).toDate());
      },
    },
    endDateComputed: {
      get() {
        return this.endDate;
      },
      set(data) {
        this.$emit('update:endDate', this.$moment(data).toDate());
      },
    },
    daysOfWeekComputed: {
      get() {
        return this.daysOfWeek;
      },
      set(data) {
        this.$emit('update:daysOfWeek', data);
      },
    },
    frequencyComputed: {
      get() {
        return this.frequency;
      },
      set(data) {
        this.$emit('update:frequency', data);
      },
    },
  },
};
</script>
<style>
  .bound-icon {
    padding-top: 10px !important;
    padding-right: 20px;
    padding-left: 20px;
  }
</style>
