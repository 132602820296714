export default class FlightRemark {
  constructor(
    flightId, id, passengerRemarks, passengerRemarksTs, passengerRemarksRead, rampRemarks, rampRemarksTs, rampRemarksRead,
    operationRemarks, operationRemarksTs, operationRemarksRead, passageRemarks,
    baggageYardRemarks, baggageYardRemarksTs, baggageYardRemarksRead, loadRemarks, loadRemarksTs, loadRemarksRead,
    notificationTime, missingPaxNumber, missingPaxInfo,
  ) {
    this.id = id;
    this.passengerRemarks = passengerRemarks;
    this.passengerRemarksTs = passengerRemarksTs;
    this.passengerRemarksRead = passengerRemarksRead;
    this.rampRemarks = rampRemarks;
    this.rampRemarksTs = rampRemarksTs;
    this.rampRemarksRead = rampRemarksRead;
    this.operationRemarks = operationRemarks;
    this.operationRemarksTs = operationRemarksTs;
    this.operationRemarksRead = operationRemarksRead;
    this.passageRemarks = passageRemarks;
    this.baggageYardRemarks = baggageYardRemarks;
    this.baggageYardRemarksTs = baggageYardRemarksTs;
    this.baggageYardRemarksRead = baggageYardRemarksRead;
    this.loadRemarks = loadRemarks;
    this.loadRemarksTs = loadRemarksTs;
    this.loadRemarksRead = loadRemarksRead;
    this.flightId = flightId;
    this.notificationTime = notificationTime;
    this.missingPaxNumber = missingPaxNumber;
    this.missingPaxInfo = missingPaxInfo;
  }

  static createFlightRemark(flightId) {
    return new FlightRemark(flightId);
  }
}
