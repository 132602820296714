import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"persistent":"","max-width":"800px","content-class":_vm.isContentVisible ? 'modal-common' : 'modal-common hide-title'},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,{attrs:{"dark":this.$vuetify.dark,"color":this.$vuetify.dark ? 'bggrey_dark' : 'bggrey'}},[_c(VToolbar,{attrs:{"dark":"","color":"primary"}},[_c(VIcon,[_vm._v("flight")]),(_vm.isExternal)?_c(VToolbarTitle,[_vm._v(_vm._s(_vm.$t("contingencies.newExternalContingency")))]):_c(VToolbarTitle,[_vm._v(_vm._s(_vm.$t("contingencies.newContingency")))]),_c(VSpacer),_c(VBtn,{attrs:{"icon":""},on:{"click":_vm.toggleContentVisibility}},[_c(VIcon,[_vm._v(_vm._s(_vm.isContentVisible ? 'mdi-chevron-up' : 'mdi-chevron-down'))])],1)],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isContentVisible),expression:"isContentVisible"}]},[_c('loading-component'),(_vm.isExternal)?_c(VCardText,{staticClass:"pb-0"},[_c('contingency-date',{on:{"dateSelected":_vm.setDate}})],1):_c(VCardText,{staticClass:"pb-0"},[_c('flight-selector-container',{on:{"flightSelected":_vm.setFlight,"clearFlight":_vm.clearFlight}})],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"flat":"","color":"primary"},nativeOn:{"click":function($event){return _vm.close.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t("common.close")))]),_c(VBtn,{attrs:{"color":"primary","depressed":"","disabled":!_vm.valid},nativeOn:{"click":function($event){return _vm.save.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t("common.save")))])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }