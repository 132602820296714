import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{attrs:{"fluid":"","pa-0":""}},[_c(VLayout,{attrs:{"column":""}},[_c(VFlex,{attrs:{"xs12":""}},[_c('ag-grid-vue',{staticClass:"ag-theme-material",style:(`width: 100%; height: 80vh;`),attrs:{"gridOptions":_vm.gridOptions,"frameworkComponents":_vm.frameworkComponentsComputed,"defaultColDef":_vm.defaultColDef,"columnDefs":_vm.columnDefsComputed,"suppressHorizontalScroll":"true","rowData":_vm.items,"deltaRowDataMode":true},on:{"grid-ready":_vm.setAgGridApi}}),_c('div',{staticClass:"export-n-import-buttons"},[_c(VBtn,{attrs:{"color":"primary","small":"","flat":""},on:{"click":function($event){$event.stopPropagation();return _vm.exportCSV.apply(null, arguments)}}},[_c(VIcon,[_vm._v("mdi-download")]),_vm._v(" "+_vm._s(_vm.$t("common.export"))+" ")],1),(_vm.importEnabled)?_c('CSVImporter',{on:{"onCSVProcessed":_vm.importCSV}}):_vm._e()],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }