<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>

    <ag-grid-dialog-template-editor :is-dialog-open="isDialogOpen" @save="save" @cancel="cancel"
      :saveDisabled="!validForm">

      <template v-slot:header>
        {{ $t('operations.editStatusModalTitle') }} {{ flightNumber }}
      </template>

      <template v-slot:default>
        <v-container grid-list-md text-xs-center fluid>
          <v-form ref="form" v-model="validForm">
            <v-layout row wrap>
              <v-flex xs12 v-if="isInbound">
                <v-card>
                  <v-card-text>
                    <v-container fluid>
                      <v-layout justify-center align-center>
                        <v-flex xs3>
                          <v-tooltip bottom>
                            <v-icon x-large left slot="activator">flight_land</v-icon>
                            <span>{{ $t('operations.inboundFlight') }}</span>
                          </v-tooltip>
                        </v-flex>
                        <v-flex xs3>
                          <v-tooltip bottom v-if="times && times.manualOverwriteEstimatedTime">
                            <template slot="activator">
                              <div v-bind:class="{ 'fieldBlockedClass': times.manualOverwriteEstimatedTime }">
                                <time-input v-model="times.estimatedTime"
                                  :label="$t('flightInformation.estimatedTimeArrival')" :referenceDate="referenceDate"
                                  :disabled="!hasPermissions(rolePermission)"
                                  :box="times.manualOverwriteEstimatedTime"></time-input>
                              </div>
                            </template>
                            <span>{{ $t("common.fieldBlockedForIntegrations") }}</span>
                          </v-tooltip>
                          <template v-else>
                            <time-input v-if="times" v-model="times.estimatedTime"
                              :label="$t('flightInformation.estimatedTimeArrival')" :referenceDate="referenceDate"
                              :disabled="!hasPermissions(rolePermission)"></time-input>
                          </template>
                        </v-flex>
                        <v-flex xs3 v-bind:class="{ 'fieldBlockedClass': times && times.manualOverwriteLandTime }">
                          <time-input v-if="times" v-model="times.landTime"
                            :label="$t('flightInformation.landTimeArrival')" :referenceDate="referenceDate"
                            :disabled="!hasPermissions(rolePermission)" :rules="[rules.landTimeBeforeBlockTime]"
                            :box="times.manualOverwriteLandTime"></time-input>
                        </v-flex>
                        <v-flex xs3 v-bind:class="{ 'fieldBlockedClass': times && times.manualOverwriteBlockTime }">
                          <time-input v-if="times" v-model="times.blockTime"
                            :label="$t('flightInformation.onBlockTime')" :referenceDate="referenceDate"
                            :disabled="!hasPermissions(rolePermission)" :rules="[rules.blockTimeAfterLandTime]"
                            :box="times.manualOverwriteBlockTime"></time-input>
                        </v-flex>
                      </v-layout>
                    </v-container>
                  </v-card-text>
                </v-card>
              </v-flex>

              <v-flex xs12 v-if="isOutbound">
                <v-card>
                  <v-card-text>
                    <v-container fluid>
                      <v-layout justify-center align-center>
                        <v-flex>
                          <v-tooltip bottom>
                            <v-icon x-large left slot="activator">flight_takeoff</v-icon>
                            <span>{{ $t('operations.outboundFlight') }}</span>
                          </v-tooltip>
                        </v-flex>
                        <v-flex v-bind:class="{ 'fieldBlockedClass': times && times.manualOverwriteEstimatedTime }">
                          <time-input v-if="times" v-model="times.estimatedTime"
                            :label="$t('flightInformation.estimatedTimeDeparture')" :referenceDate="referenceDate"
                            :disabled="!hasPermissions(rolePermission)"
                            :box="times.manualOverwriteEstimatedTime"></time-input>
                        </v-flex>
                        <v-flex>
                          <time-input v-if="times" v-model="times.estimatedRealTime" class="etd-real-time"
                            :label="$t('flightInformation.estimatedRealTimeDeparture')" :referenceDate="referenceDate"
                            :disabled="!hasPermissions(rolePermission)"></time-input>
                        </v-flex>
                        <v-flex>
                          <time-input v-if="times" v-model="times.tsatTime" class="etd-real-time"
                            :label="$t('flightInformation.tsatTimeDeparture')" :referenceDate="referenceDate"
                            :disabled="!hasPermissions(rolePermission)"></time-input>
                        </v-flex>
                        <v-flex>
                          <time-input v-if="times" v-model="times.tobtTime" class="etd-real-time"
                            :label="$t('flightInformation.tobtTimeDeparture')" :referenceDate="referenceDate"
                            :disabled="!hasPermissions(rolePermission)"></time-input>
                        </v-flex>
                        <v-flex v-bind:class="{ 'fieldBlockedClass': times && times.manualOverwriteBlockTime }">
                          <time-input v-if="times" v-model="times.blockTime"
                            :label="$t('flightInformation.ofBlockTime')" :referenceDate="referenceDate"
                            :disabled="!hasPermissions(rolePermission)" :rules="[rules.blockTimeBeforeLandTime]"
                            :box="times.manualOverwriteBlockTime"></time-input>
                        </v-flex>
                        <v-flex v-bind:class="{ 'fieldBlockedClass': times && times.manualOverwriteLandTime }">
                          <time-input v-if="times" v-model="times.landTime" :label="$t('flightInformation.airTime')"
                            :referenceDate="referenceDate" :disabled="!hasPermissions(rolePermission)"
                            :rules="[rules.landTimeAfterBlockTime]" :box="times.manualOverwriteLandTime"></time-input>
                        </v-flex>
                        <v-flex>
                          <time-input v-if="times" v-model="eet" :label="$t('flightInformation.estimatedElapsedTime')"
                            :disabled="!hasPermissions(rolePermission)"></time-input>
                        </v-flex>
                      </v-layout>
                    </v-container>
                  </v-card-text>
                </v-card>
              </v-flex>
              <v-flex xs12 v-if="statusIsCancel">
                <ag-grid-connected-flights-dialog-cancellation-cell-info :cancel-info="cancelInfo" :flight-id="flightId"
                  :context="context" @restore="isDialogOpen = false" />
              </v-flex>
            </v-layout>
          </v-form>
        </v-container>
      </template>


    </ag-grid-dialog-template-editor>

  </div>
</template>

<script>
import Vue from 'vue';
import AgGridDialogParentCellEditor from '../../mixins/AgGridDialogParentCellEditor.vue';
import AgGridDialogTemplateEditor from '../../templates/AgGridDialogTemplateEditor.vue';
import TimeInput from '../../../../ui/TimeInput.vue';
import { JSUtils } from '../../../../shared/js/JSUtils';
import FlightTimes from '../../model/flightTimes';
import { FlightBound } from '../../../../shared/model/flightBound';
import AgGridConnectedFlightsDialogCancellationCellInfo from './AgGridConnectedFlightsDialogCancellationCellInfo.vue';

export default Vue.extend({
  mixins: [AgGridDialogParentCellEditor],
  components: {
    AgGridDialogTemplateEditor,
    TimeInput,
    AgGridConnectedFlightsDialogCancellationCellInfo,
  },
  data() {
    return {
      times: null,
      eet: null,
      cancelInfo: null,
      isDialogOpen: true,
      validForm: false,
      rules: {
        landTimeBeforeBlockTime: value => (!value || !this.times || !this.times.blockTime || (value && this.$moment(value, 'hh:mm').isSameOrBefore(this.$moment(this.times.blockTime)))) || (value && this.$moment(this.$moment.parseZone(this.times.landTime).utc().format()).isSameOrBefore(this.$moment(this.$moment.parseZone(this.times.blockTime).utc().format()))) || this.$i18n.t('validation.landTimeBeforeBlockTime'),
        blockTimeAfterLandTime: value => (!value || !this.times || !this.times.landTime || (value && true && this.$moment(value, 'hh:mm').isSameOrAfter(this.$moment(this.times.landTime)))) || (value && this.$moment(this.$moment.parseZone(this.times.blockTime).utc().format()).isSameOrAfter(this.$moment(this.$moment.parseZone(this.times.landTime).utc().format()))) || this.$i18n.t('validation.blockTimeAfterLandTime'),
        blockTimeBeforeLandTime: value => (!value || !this.times || !this.times.landTime || (value && this.$moment(value, 'hh:mm').isSameOrBefore(this.$moment(this.times.landTime)))) || (value && this.$moment(this.$moment.parseZone(this.times.blockTime).utc().format()).isSameOrBefore(this.$moment(this.$moment.parseZone(this.times.landTime).utc().format()))) || this.$i18n.t('validation.blockTimeBeforeLandTime'),
        landTimeAfterBlockTime: value => (!value || !this.times || !this.times.blockTime || (value && this.$moment(value, 'hh:mm').isSameOrAfter(this.$moment(this.times.blockTime)))) || (value && this.$moment(this.$moment.parseZone(this.times.landTime).utc().format()).isSameOrAfter(this.$moment(this.$moment.parseZone(this.times.blockTime).utc().format()))) || this.$i18n.t('validation.landTimeAfterBlockTime'),
      },
    };
  },
  computed: {
    isInbound() {
      if (typeof (this.flightBound) === 'function') {
        return this.params.column.colDef.colCustomConfig.flightBound(this.connectedFlights) === FlightBound.INBOUND;
      }
      return this.flightBound === FlightBound.INBOUND;
    },
    isOutbound() {
      if (typeof (this.flightBound) === 'function') {
        return this.params.column.colDef.colCustomConfig.flightBound(this.connectedFlights) === FlightBound.OUTBOUND;
      }
      return this.flightBound === FlightBound.OUTBOUND;
    },
    referenceDate() {
      if (this.params.column.colDef.colCustomConfig.referenceDate && typeof (this.params.column.colDef.colCustomConfig.referenceDate) === 'function') {
        return this.params.column.colDef.colCustomConfig.referenceDate();
      }
      return this.connectedFlights.getChildPropertyValue(this.flightBound, ['common', 'scheduledDate']);
    },
    context() {
      return this.params.context.module;
    },
    statusIsCancel() {
      return ((this.connectedFlights.getChildPropertyValue(this.flightBound, ['common', 'status']) === 'CANCELLED') && this.cancelInfo);
    },
    rolePermission() {
      return `${this.$route.meta.role}_WR`;
    },
  },
  watch: {
    times: {
      handler() {
        this.$refs.form.validate();
      },
      deep: true,
    },
  },
  methods: {
    getValue() {
      if (this.isSaving && this.$refs.form.validate()) {
        this.requestInit();
        this.times.estimatedElapsedTime = Number(this.$moment(this.eet).format('H').toString()) * 60;
        this.times.estimatedElapsedTime += Number(this.$moment(this.eet).format('m').toString());
        this.$http.put(`flights/${this.flightId}/timeAndCancellation/times`, this.times)
          .then(resp => this.dialogRequestSuccessCallback(this, resp))
          .catch(error => this.requestErrorCallback(this, error));
      }

      return this.params.value;
    },
  },
  created() {
    this.$http.get(`flights/${this.flightId}/timeAndCancellation`)
      .then((resp) => {
        this.times = JSUtils.cast(resp.data.flightTimesDTO, FlightTimes);
        this.cancelInfo = resp.data.cancellationDTO;
        this.eet = this.times.estimatedElapsedTime != null ? this.$moment('0000', 'HHmm').add(this.times.estimatedElapsedTime, 'minute') : null;
      });
  },
});
</script>
<style lang="scss" scoped>
.etd-real-time {
  max-width: 8em !important;
}

.fieldBlockedClass {
  color: darkslategray;
  font-weight: bold;
}
</style>
