import { JSUtils } from '../js/JSUtils';
import RushBaggage from '../../lostAndFound/rushBaggage/model/rushBaggage';

export default class RushBaggageFactory {
  static mapRushBaggage(data) {
    if (data && data.constructor === Array && data.length > 0) {
      return data.map(d => this.createRushBaggageFromData(d));
    }
    return [];
  }

  static createRushBaggageFromData(data) {
    if (!data) {
      return data;
    }

    return JSUtils.cast(data, RushBaggage);
  }
}
