<template>
  <div>
        <ag-grid-vue
          class="ag-theme-material connected-flights-table operational-table"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :rowData="items"
          :onGridReady="onGridReady"
          @first-data-rendered="firstDataRendered"
          :gridOptions="gridOptions"
          :suppressHorizontalScroll="true"
          :frameworkComponents="frameworkComponents"
          v-bind:style="`width: 100%; height: calc(100vh - ${this.sizeFilter});`"
        >
        </ag-grid-vue>
      </div>
</template>

<script>
import { AgGridVue } from 'ag-grid-vue';
import AgGridConnectedFlights from '../../ag-grid/mixins/AgGridConnectedFlights.vue';
import { cellClassAgGridRules } from '../../ag-grid/mixins/cellClassAgGridRules';
import { FisModule } from '../../ag-grid/cell-editors/model/fisModule';
import agGridTextCustomFilterComparator from '../../ag-grid/filters/agGridTextCustomFilterComparator';
import agGridTextCustomFilterFormatter from '../../ag-grid/filters/agGridTextCustomFilterFormatter';
import AgGridCustomService from '../../shared/services/agGridCustomService';
import AgGridValueBaseCellRenderer from '../../ag-grid/cell-renderers/AgGridValueBaseCellRenderer.vue';
import AgGridCurrencyCellRenderer from '../../ag-grid/cell-renderers/AgGridCurrencyCellRenderer.vue';
import RolePermissions from '../../mixins/RolePermissions.vue';
import {
  EMPLOYEE_INCOME_TICKETING_REPORT_CHANGE_GRID_API,
  EMPLOYEE_INCOME_TICKETING_REPORT_FIT_COLUMNS_TO_VIEW,
} from '../../../store/modules/ticketingReports/employeeIncome';
import { CURRENT_LANGUAGE_CHANGED } from '../../../store/modules/i18n';

export default {
  name: 'employee-income-ticketing-report-list-table',
  mixins: [AgGridConnectedFlights, RolePermissions],
  components: {
    AgGridVue,
    AgGridValueBaseCellRenderer,
    AgGridCurrencyCellRenderer,
  },
  props: {
    items: { type: Array, required: true },
    isFilterDisplayed: { type: Boolean, required: false, default: false },
  },
  computed: {
    sizeFilter() {
      return this.isFilterDisplayed ? '320px' : '180px';
    },
    fitColumnsOnResize() {
      return this.$store.state.ticketingReports.employeeIncome.fitColumnsOnResize;
    },
    rolePermission() {
      return `${this.$route.meta.role}_WR`;
    },
  },
  data() {
    const that = this;
    return {
      frameworkComponents: {

      },
      defaultColDef: {
        cellClassRules: cellClassAgGridRules,
        minWidth: 30,
        suppressMenu: true,
        sortable: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        filterParams: {
          textCustomComparator: agGridTextCustomFilterComparator,
          textFormatter: agGridTextCustomFilterFormatter,
        },
        editable: false,
      },
      gridOptions: {
        onFilterChanged: this.uncheckAllOnFilter,
        getRowNodeId: this.getRowNodeId,
        getRowClass: this.getRowClass,
        floatingFilter: true,
        groupHeaderHeight: 35,
        headerHeight: 35,
        floatingFiltersHeight: 20,
        rowHeight: 30,
        context: {
          module: FisModule.TICKETING_REPORTS,
        },
        alignedGrids: [],
      },
      columnDefs: [
        {
          colId: 'employee',
          headerValueGetter() {
            return that.$i18n.t('common.employee');
          },
          headerTooltip: that.$i18n.t('common.employee'),
          field: 'employee',
          cellRendererFramework: AgGridValueBaseCellRenderer,
        },
        {
          colId: 'airlineCode',
          headerValueGetter() {
            return that.$i18n.t('common.airline');
          },
          headerTooltip: that.$i18n.t('common.airline'),
          field: 'airlineCode',
          cellRendererFramework: AgGridValueBaseCellRenderer,
        },
        {
          colId: 'total',
          headerValueGetter() {
            return that.$i18n.t('ticketing.totalIncome');
          },
          headerTooltip: that.$i18n.t('ticketing.totalIncome'),
          field: 'total',
          colCustomConfig: {
            valueGetterExportCsv(params) {
              return that.formatNumberCurrency(params.data.currency, params.data.total);
            },
          },
          valueGetter(params) {
            return {
              value: params.data.total,
              currency: params.data.currency,
            };
          },
          cellRendererFramework: AgGridCurrencyCellRenderer,
        },
        {
          colId: 'currency',
          headerValueGetter() {
            return that.$i18n.t('configuration.currency');
          },
          headerTooltip: that.$i18n.t('configuration.currency'),
          field: 'currency',
          cellRendererFramework: AgGridValueBaseCellRenderer,
        },
        {
          headerValueGetter() {
            return that.$i18n.t('scheduling.actions');
          },
          hide: true,
        },
      ],
      rules: {
        required: value => !!value || 'Required.',
        requiredMulti: value => !!value.length > 0 || 'Required.',
      },
    };
  },
  methods: {
    onGridReady(params) {
      this.setAgGridApi(params);
      this.showLoading();
      this.$store.commit(EMPLOYEE_INCOME_TICKETING_REPORT_CHANGE_GRID_API, params); // MOVE TO MIXIN?
      this.refreshCellsOnMutation();
      this.refreshCellsOnChangeLanguage();
    },
    resized() {
      if (this.gridApi) {
        this.gridApi.sizeColumnsToFit();
      }
    },
    renderNeededDiffs(differences, rowNode) {
      const { gridApi } = this.$store.state.ticketingReports.employeeIncome;

      const agGridCustomService = new AgGridCustomService(this.columnDefs); // TODO one time
      const colIds = agGridCustomService.getColIdByMatchAnyfieldsRelated(this.joinAllPropertyPath(differences));

      if (colIds && colIds.length > 0) {
        colIds.forEach((colId) => {
          if (gridApi.getColumnDef(colId)) {
            gridApi.refreshCells({
              rowNodes: [rowNode],
              columns: [colId],
              force: true,
            });

            gridApi.flashCells({
              rowNodes: [rowNode],
              columns: [colId],
              force: true,
            });
          }
        });
      }
    },
    sizeColumnsToFit() {
      if (this.gridApi) {
        this.$store.dispatch(EMPLOYEE_INCOME_TICKETING_REPORT_FIT_COLUMNS_TO_VIEW);
      }
    },
    firstDataRendered() {
      this.$nextTick(() => {
        this.resized();
      });
    },
    getRowNodeId(data) {
      return data.airlineId + data.employee;
    },
    formatNumberCurrency(currency, value) {
      if (currency != null && value != null) {
        return new Intl.NumberFormat(this.$store.getters.getCurrencies.find(p => p.id === currency).locale, { style: 'currency', currency }).format(value);
      }
      return '';
    },
  },
  mounted() {
    this.subscribeMutation(CURRENT_LANGUAGE_CHANGED, () => {
      this.gridApi.refreshHeader();
    });
  },
};
</script>
