<template>
    <div class="gantt-flights-task-tooltip">
        <div v-if="inbound">
          <v-icon small>flight_land</v-icon>
          <span>{{inboundFlightNumber}} BEST: {{inboundBestTime}}</span>
        </div>
        <div v-if="outbound">
          <v-icon small>flight_takeoff</v-icon>
          <span>{{outboundFlightNumber}} BEST: {{outboundBestTime}}</span>
        </div>
    </div>
</template>
<script>
export default {
  name: 'GanttFlightsTaskTooltip',
  props: {
    connectedFlights: { type: Object },
  },
  computed: {
    inbound() {
      return this.connectedFlights ? this.connectedFlights.inbound : null;
    },
    outbound() {
      return this.connectedFlights ? this.connectedFlights.outbound : null;
    },
    inboundFlightNumber() {
      return this.inbound.common.flightNo();
    },
    inboundBestTime() {
      return this.$moment(this.inbound.times.bestTime).format('HH:mm');
    },
    outboundFlightNumber() {
      return this.outbound.common.flightNo();
    },
    outboundBestTime() {
      return this.$moment(this.outbound.times.bestTime).format('HH:mm');
    },
  },
};
</script>
<style scoped>
  .gantt-flights-task-tooltip .v-icon {
    color: white;
    margin-right: 5px;
  }
</style>
