<template>
  <v-menu
    :close-on-content-click="false"
    v-model="menu2"
    :nudge-right="40"
    lazy
    transition="scale-transition"
    offset-y
    :disabled="disabled"
  >
    <span :ref="'refDateInput'" slot="activator">{{ formattedDate }}</span>
    <v-date-picker :value="dateValue" :locale="parsedLocale" :first-day-of-week="1" @input="onInput" @change="datePickerChange" :readonly="disabled"/>
  </v-menu>
</template>

<script>
import Arrowable from '../mixins/Arrowable.vue';

export default {
  mixins: [Arrowable],
  props: {
    value: { required: true },
    label: { type: String },
    rules: { type: Array },
    disabled: { type: Boolean },
    clearable: { type: Boolean, default: false },
    locale: { type: String },
    charLenght: { type: Number, default: 10 },
  },
  data() {
    return {
      menu2: false,
      dateMoment: null,
    };
  },
  computed: {
    parsedLocale() {
      switch (this.locale) {
        case ('es'):
          return 'es-ES';
        case ('en'):
          return 'en-US';
        default:
          return this.locale;
      }
    },
    dateValue() {
      return this.dateMoment ? this.dateMoment.format('YYYY-MM-DD') : null;
    },
    formattedDate() {
      return this.$moment(this.dateMoment).locale(this.$i18n.locale).format('DD/MM/YY (ddd)');
    },
  },
  mounted() {
    this.dateMoment = this.value ? this.$moment(this.value) : null;
  },
  methods: {
    onInput(value) {
      this.dateMoment = this.$moment(value);
      this.menu2 = false;
      this.emitInput();
    },
    datePickerChange(value) {
      this.$emit('change', value);
    },
    emitInput() {
      this.$emit('input', this.dateMoment);
    },
    dblclick() {
      if (!this.dateMoment) {
        const dateMomentAux = this.$moment();
        this.onInput(dateMomentAux);
      }
    },
  },
  watch: {
    value(newValue) {
      this.dateMoment = newValue ? this.$moment(newValue) : null;
    },
  },
};
</script>

<style scoped>
  .menuable__content__active {
    z-index: 300 !important;
  }
</style>
