
/* the interfaces if project migrate to truescript

interface Milestone {
  id: number;
  name: string;
  description: string;
  type: string;
  status: string;
}

interface MilestoneActive {
  id: number;
  idGlobal: number;
  idAirport: number;
  name: string;
  description: string;
  type: string;
  airportMilestone: boolean;
}

interface MilestoneActiveList {
  id: number;
  idGlobal: number;
  idAirport: number;
  name: string;
  description: string;
  type: string;
  status: string;
}
*/


export const MilestoneTypes = Object.freeze({
  TEXT: 'TEXT',
  DATE: 'DATE',
  TIME: 'TIME',
  DATETIME: 'DATETIME',
  NUMBER: 'NUMBER',
  BOOLEAN: 'BOOLEAN',
  OPTIONS: 'OPTIONS',
});

export const MilestonesPermissions = Object.freeze({
  EDIT: 'EDIT',
  VIEW: 'VIEW',
  NONE: 'NONE',
});

// convert MilestoneActiveList to AirportServiceMilestones
export function mapAirportMilestonesToAirportServiceMilestones(data) {
  return data.map(item => ({
    milestoneId: item.id,
    name: item.name,
    description: item.description,
    type: item.type,
    permission: 'EDIT',
    order: 1,
  }));
}


// convert MilestoneActive to MilestoneActiveList
export function mapAirportMilestonesList(data) {
  return data.map((item) => {
    if (!item.airportMilestone) {
      return {
        id: item.id,
        idGlobal: item.idGlobal,
        idAirport: null,
        name: item.name,
        description: item.description,
        type: item.type,
        flightBound: item.flightBound,
        status: 'GLOBAL',
        disableEdit: true,
      };
    }
    return {
      id: item.id,
      idGlobal: null,
      idAirport: item.idAirport,
      name: item.name,
      description: item.description,
      type: item.type,
      flightBound: item.flightBound,
      status: 'AIRPORT',
      disableEdit: false,
    };
  });
}
