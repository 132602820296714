<template>
  <div>
    <scheduling-weighting-list-filter @changeShowFilter="changeShowFilter"/>

    <scheduling-weighting-list-table :items="items" :isFilterDisplayed="filterDisplayed" />
  </div>
</template>
<script>

import SchedulingWeightingListFilter from '../components/scheduling/weighting/SchedulingWeightingListFilter.vue';
import SchedulingWeightingListTable from '../components/scheduling/weighting/SchedulingWeightingListTable.vue';

export default {
  components: {
    SchedulingWeightingListFilter,
    SchedulingWeightingListTable,
  },
  data() {
    return {
      filterDisplayed: true,
    };
  },
  computed: {
    items() {
      return this.$store.state.scheduling.schedulingWeighting.flightsWeighting;
    },
  },
  methods: {
    changeShowFilter(dispayled) {
      this.filterDisplayed = dispayled;
    },
  },
};
</script>
