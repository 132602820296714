
<template>
  <v-container fluid>
    <v-layout row wrap>
      <v-flex xs12 md6 class="pa-2">
        <GHSInputStorageTree />
      </v-flex>
      <v-flex xs12 md6 class="pa-2">
        <GHSOutputStorageTree />
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import GHSInputStorageTree from '@/components/billing/ghs-integration/GHSInputStorageTree.vue';
import GHSOutputStorageTree from '@/components/billing/ghs-integration/GHSOutputStorageTree.vue';

export default {
  components: {
    GHSInputStorageTree,
    GHSOutputStorageTree,
  },
};
</script>
