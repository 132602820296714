export class JSUtils {
  static isFunction(functionToCheck) {
    return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
  }

  static isObject(objectToCheck) {
    return objectToCheck && {}.toString.call(objectToCheck) === '[object Object]';
  }

  static cast(item, clazz) {
    if (Array.isArray(item)) {
      return item.map(i => this.cast(i, clazz));
    }

    return Object.setPrototypeOf(item, clazz.prototype);
  }

  static decamelizeString(str, separator) {
    str.replace(/([a-z\d])([A-Z])/g, `$1${separator}$2`)
      .replace(/([A-Z]+)([A-Z][a-z\d]+)/g, `$1${separator}$2`)
      .toLowerCase();
  }

  static getFilenameFromContentDisposition(disposition) {
    let filename = '';
    if (disposition && (disposition.indexOf('attachment') !== -1 || disposition.indexOf('inline') !== -1)) {
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = filenameRegex.exec(disposition);
      if (matches != null && matches[1]) {
        filename = matches[1].replace(/['"]/g, '');
      }
    }
    return filename;
  }

  static downloadFile(data, filename) {
    const fileURL = window.URL.createObjectURL(new Blob([data]));
    const fileLink = document.createElement('a');

    fileLink.href = fileURL;
    fileLink.setAttribute('download', filename);
    document.body.appendChild(fileLink);

    fileLink.click();
  }

  static reducePathArray(pathArray, context) {
    return pathArray.reduce((a, b) => {
      if (a) {
        const prop = a[b];
        if (JSUtils.isFunction(prop)) {
          return prop.bind(a)();
        }
        return prop;
      }
      return null;
    }, context);
  }

  static isObjectEmpty(obj) {
    // eslint-disable-next-line
    for (const prop in obj) {
      if (Object.hasOwn(obj, prop)) {
        return false;
      }
    }
    return true;
  }

  static isZonedDateTimeValue(zonedDateTimeCandidate) {
    const pattern = new RegExp(/^(?:[1-9]\d{3}-(?:(?:0[1-9]|1[0-2])-(?:0[1-9]|1\d|2[0-8])|(?:0[13-9]|1[0-2])-(?:29|30)|(?:0[13578]|1[02])-31)|(?:[1-9]\d(?:0[48]|[2468][048]|[13579][26])|(?:[2468][048]|[13579][26])00)-02-29)T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:Z|[+-][01]\d:[0-5]\d)$/);
    return pattern.test(zonedDateTimeCandidate);
  }

  static isMomentObject(momentCandidate) {
    if (momentCandidate && momentCandidate.constructor) {
      const candidateClassName = momentCandidate.constructor.name;
      return candidateClassName === 'Moment';
    }
    return false;
  }
}
