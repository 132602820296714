<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>

    <ag-grid-dialog-template-editor :is-dialog-open="isDialogOpen" @save="save" @cancel="cancel" :saveDisabled="!validForm">

      <template v-slot:header>
        {{$t("operations.remarksModalTitle")}} {{flightNumber}}
      </template>

      <template v-slot:default>
        <v-container grid-list-md text-xs-center fluid>
          <v-layout row wrap>
            <v-flex xs12>
              <v-card>
                <v-card-text>
                  <v-form ref="form" v-model="validForm">
                  <v-flex xs1 offset-xs11>
                    <div class="mark-read">{{$t("operations.remarksModalReadLabel")}}</div>
                  </v-flex>
                  <v-layout>
                    <v-flex xs3 class="space-label">
                      <v-label>{{$t("operations.remarksModalPassengerComment")}}</v-label>
                    </v-flex>
                    <v-flex xs8>
                      <v-textarea v-model="flightRemark.passageRemarks" rows="1" disabled auto-grow :rules="[rules.maxSizeTwoHundred]"></v-textarea>
                    </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex xs3 class="space-label">
                      <v-label>{{$t("operations.remarksModalRampComment")}}</v-label>
                    </v-flex>
                    <v-flex xs8>
                      <v-textarea v-model="flightRemark.rampRemarks" rows="1" :disabled="isDisabledForRamp" auto-grow :rules="[rules.maxSizeTwoHundred]"></v-textarea>
                    </v-flex>
                    <v-flex xs1>
                      <v-switch
                        class="toggle-cell-input"
                        color="primary"
                        v-model="flightRemark.rampRemarksRead"
                        :disabled="isDisabledForRamp">
                      </v-switch>
                    </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex xs3 class="space-label">
                      <v-label>{{$t("operations.remarksModalBaggageYardComment")}}</v-label>
                    </v-flex>
                    <v-flex xs8>
                      <v-textarea v-model="flightRemark.baggageYardRemarks" rows="1" :disabled="isDisabledForRampDuty" auto-grow :rules="[rules.maxSizeTwoHundred]"></v-textarea>
                    </v-flex>
                    <v-flex xs1>
                      <v-switch
                        class="toggle-cell-input"
                        color="primary"
                        v-model="flightRemark.baggageYardRemarksRead"
                        :disabled="isDisabledForRampDuty">
                      </v-switch>
                    </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex xs3 class="space-label">
                      <v-label>{{$t("operations.remarksModalLoadComment")}}</v-label>
                    </v-flex>
                    <v-flex xs8>
                      <v-textarea v-model="flightRemark.loadRemarks" rows="1" :disabled="isDisabledForRampDuty" auto-grow :rules="[rules.maxSizeTwoHundred]"></v-textarea>
                    </v-flex>
                    <v-flex xs1>
                      <v-switch
                        class="toggle-cell-input"
                        color="primary"
                        v-model="flightRemark.loadRemarksRead"
                        :disabled="isDisabledForRampDuty">
                      </v-switch>
                    </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex xs3 class="space-label">
                      <v-label>{{$t("operations.remarksModalOperationsComment")}}</v-label>
                    </v-flex>
                    <v-flex xs8>
                      <v-textarea v-model="flightRemark.operationRemarks"
                                  auto-grow
                                  rows="1"
                                  :disabled="isDisabledForOp && isDisabledForRampDuty" :rules="[rules.maxSizeTwoHundred]">
                      </v-textarea>
                    </v-flex>
                    <v-flex xs1>
                      <v-switch
                        class="toggle-cell-input"
                        color="primary"
                        v-model="flightRemark.operationRemarksRead"
                        :disabled="isDisabledForOp && isDisabledForRampDuty">
                      </v-switch>
                    </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex xs3 class="space-label">
                      <v-label>{{$t("operations.remarksModalNotificationTime")}} : {{flightRemark.notificationTime | moment('HH:mm') }}</v-label>
                    </v-flex>
                    <v-flex xs3 class="space-label">
                      <v-label>{{$t("operations.remarksModalMissingPaxNumber")}} : {{flightRemark.missingPaxNumber}}</v-label>
                    </v-flex>
                    <v-flex xs3 class="space-label">
                      <v-label>{{$t("operations.remarksModalMissingPaxInfo")}} : {{flightRemark.missingPaxInfo}}</v-label>
                    </v-flex>
                    <v-flex xs3 class="space-label">
                      <v-label>{{$t("operations.remarksModalPaxDataRealTime")}} : {{flightRemark.paxDataRealTime}}</v-label>
                    </v-flex>
                  </v-layout>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-layout>
        </v-container>
      </template>
    </ag-grid-dialog-template-editor>

  </div>
</template>

<script>
import Vue from 'vue';
import AgGridDialogParentCellEditor from '../../mixins/AgGridDialogParentCellEditor.vue';
import AgGridDialogTemplateEditor from '../../templates/AgGridDialogTemplateEditor.vue';
import FlightRemark from '../../model/flightRemark';
import { JSUtils } from '../../../../shared/js/JSUtils';
import { FisModule } from '../../model/fisModule';

export default Vue.extend({
  mixins: [AgGridDialogParentCellEditor],
  components: {
    AgGridDialogTemplateEditor,
  },
  data() {
    return {
      flightRemark: new FlightRemark(),
      validForm: false,
      rules: {
        maxSizeTwoHundred: value => (!value || (value && value.length <= 200)) || this.$i18n.t('validation.fieldMax200'),
      },
    };
  },
  computed: {
    flightNumber() {
      return this.connectedFlights.getChildPropertyValue(this.flightBound, ['common', 'flightNo']);
    },
    isDisabledForOp() {
      return FisModule.OPERATION !== this.module || !this.hasPermissions(this.rolePermission);
    },
    isDisabledForRamp() {
      return FisModule.RAMP !== this.module || !this.hasPermissions(this.rolePermission);
    },
    isDisabledForRampDuty() {
      return this.isDisabledForRamp || this.$route.name !== 'rampDuty';
    },
    rolePermission() {
      return `${this.$route.meta.role}_WR`;
    },
  },
  methods: {
    getValue() {
      if (this.isSaving) {
        this.requestInit();
        this.$http.put(`flights/${this.flightId}/remarks`, this.flightRemark)
          .then(resp => this.dialogRequestSuccessCallback(this, resp))
          .catch(error => this.requestErrorCallback(this, error));
      }

      return this.params.value;
    },
  },
  created() {
    this.$http.get(`flights/${this.flightId}/remarks`)
      .then((resp) => {
        this.flightRemark = JSUtils.cast(resp.data, FlightRemark);
      });
  },
});
</script>
<style lang="scss">
  .space-label {
    margin-top: 20px;
  }
  .mark-read {
    text-align: left !important;
    font-size: inherit;
    color: rgba(0, 0, 0, 0.54)
  ;
  }
</style>
