<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>

    <ag-grid-dialog-template-editor :is-dialog-open="isDialogOpen" @save="save" @cancel="cancel">

      <template v-slot:header>
        {{$t("operations.operationStatusModalTitle")}} {{flightNumber}}
      </template>
      <template v-slot:default>
        <v-container fluid grid-list-md>
          <v-layout row wrap>
            <v-flex d-flex xs12 sm8 md6>
              <v-layout align-space-between justify-center column fill-height>
                <label class="primary--text subheading">{{$t("operations.doorsCloseTitle")}}</label>
                <v-layout row wrap>
                  <v-flex xs6>
                    <label>{{$t("operations.doorsCloseExpected")}}</label>
                    <time-input v-model="operationStatusTimes.doorsCloseEstimated" :referenceDate="referenceDate" :disabled="!hasPermissions(rolePermission)"></time-input>
                  </v-flex>
                  <v-flex xs6>
                    <label class="label-real">{{$t("operations.doorsCloseReal")}}</label>
                    <time-input v-model="operationStatusTimes.doorsCloseCompleted" :referenceDate="referenceDate" :disabled="!hasPermissions(rolePermission)"></time-input>
                  </v-flex>
                </v-layout>
              </v-layout>
            </v-flex>
            <v-flex d-flex xs12 sm8 md6>
              <v-layout align-space-between justify-center column fill-height>
                <v-flex >
                  <label class="primary--text subheading">{{$t("operations.loadSheetTitle")}}</label>
                  <v-layout row wrap>
                    <v-flex xs6>
                      <label>{{$t("operations.loadSheetExpected")}}</label>
                      <time-input v-model="operationStatusTimes.loadSheetEstimated" :referenceDate="referenceDate" :disabled="!hasPermissions(rolePermission)"></time-input>
                    </v-flex>
                    <v-flex xs6>
                      <label class="label-real">{{$t("operations.loadSheetReal")}}</label>
                      <time-input v-model="operationStatusTimes.loadSheetCompleted" :referenceDate="referenceDate" :disabled="!hasPermissions(rolePermission)"></time-input>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex d-flex xs12 sm8 md6>
              <v-layout align-space-between justify-center column fill-height>
                <v-flex >
                  <label class="primary--text subheading">{{$t("operations.loadingInstructionTitle")}}</label>
                  <v-layout row wrap>
                    <v-flex xs6>
                      <label>{{$t("operations.loadingInstructionExpected")}}</label>
                      <time-input v-model="operationStatusTimes.loadingInstructionEstimated" :referenceDate="referenceDate" :disabled="!hasPermissions(rolePermission)"></time-input>
                    </v-flex>
                    <v-flex xs6>
                      <label class="label-real">{{$t("operations.loadingInstructionReal")}}</label>
                      <time-input v-model="operationStatusTimes.loadingInstructionCompleted" :referenceDate="referenceDate" :disabled="!hasPermissions(rolePermission)"></time-input>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-container>
      </template>

    </ag-grid-dialog-template-editor>
  </div>
</template>

<script>
import Vue from 'vue';
import AgGridDialogParentCellEditor from '../../mixins/AgGridDialogParentCellEditor.vue';
import AgGridDialogTemplateEditor from '../../templates/AgGridDialogTemplateEditor.vue';
import { JSUtils } from '../../../../shared/js/JSUtils';
import AgGridOptionsConfigurable from '../../../mixins/AgGridOptionsConfigurable.vue';
import TimeInput from '../../../../ui/TimeInput.vue';
import FlightOperationStatusInformationDTO from '../../model/FlightOperationStatusInformationDTO';


export default Vue.extend({
  mixins: [AgGridDialogParentCellEditor, AgGridOptionsConfigurable],
  components: {
    AgGridDialogTemplateEditor,
    TimeInput,
  },
  computed: {
    rolePermission() {
      return `${this.$route.meta.role}_WR`;
    },
  },
  data() {
    return {
      referenceDate: null,
      operationStatusTimes: new FlightOperationStatusInformationDTO(),
    };
  },
  methods: {
    getValue() {
      if (this.isSaving) {
        this.requestInit();
        this.$http.put(`flights/${this.flightId}/operation/actions`, this.operationStatusTimes)
          .then(resp => this.dialogRequestSuccessCallback(this, resp))
          .catch(error => this.requestErrorCallback(this, error));
      }

      return this.params.value;
    },
  },
  created() {
    this.referenceDate = this.connectedFlights.getChildPropertyValue(this.flightBound, ['common', 'scheduledDate']);

    this.$http.get(`flights/${this.flightId}/operation/actions`)
      .then((resp) => {
        this.operationStatusTimes = JSUtils.cast(resp.data, FlightOperationStatusInformationDTO);
      });
  },
});
</script>
<style lang="scss">
.message-table {
  padding: 0;
}
.newMsgCard {
  margin-top: 10px;
}
</style>
