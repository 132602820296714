<template>
  <v-container v-if="!isEmptyRampServices()" class="infoMobileRampServices">
    <v-card :dark="this.$vuetify.dark">
      <v-card-title class="py-2 px-3">
        <v-icon>rv_hookup</v-icon>
        <h4 class="px-2 text-no-wrap text-truncate">{{ $t('common.rampServices') }}</h4>
        <v-spacer></v-spacer>
        <v-btn icon @click="showRampServices = !showRampServices">
          <v-icon large>{{ showRampServices ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-list class="pa-0" v-show="showRampServices">
        <div v-for="(rampService, rampIndex) in localFlightMobileRampServices" :key="rampService.rampServiceId">
          <v-list-tile class="blue lighten-2 white--text">
            <h4 class="text-no-wrap text-truncate">{{ rampService.serviceName }}{{ rampService.duplicate > 1 ? ' (' + rampService.duplicate + ')' : '' }}
            </h4>
            <v-spacer></v-spacer>
            <span v-if="rampService.serviceName !== rampService.serviceDescription" class="ramp-description white--text text-no-wrap text-truncate">
              {{ rampService.serviceDescription }}
            </span>
            <v-spacer></v-spacer>
            <v-btn v-if="!loadingNewRampService && rampService.duplicate > 0" icon
              @click="showDuplicateRampService(rampService)">
              <v-icon large color="white">playlist_add</v-icon>
            </v-btn>
            <v-btn v-if="!loadingNewRampService && rampService.duplicate === 0" icon
              @click="showAddRampService(rampService)">
              <v-icon large color="white">add_circle</v-icon>
            </v-btn>
          </v-list-tile>
          <v-divider />
          <!-- Loading if there are no ramp services and is loading -->
          <v-list
            v-if="loadingNewRampService && loadingIdRampService === rampService.id && !rampService.flightRampService"
            class="pa-0">
            <!---spinner -->
            <v-list-tile>
              <v-list-tile-action>
                <v-icon>mdi-loading mdi-spin mdi-24px</v-icon>
              </v-list-tile-action>
              <v-list-tile-content>
                <v-list-tile-title>{{ $t('common.loading') }}</v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
          </v-list>
          <div v-if="rampService.flightRampService" class="pa-0">
            <v-list class="ramp-service-values pa-0" v-if="!isEmptyFieldsRampServices(rampService)">
              <div v-for="(item, index) in items" :key="item.value + '[' + rampIndex + ']'" :class="item.typeValue">
                <v-list-tile v-if="rampService.rampService[item.required]">
                  <v-list-tile-action>
                    <v-icon :color="item.permission === 'EDIT' ? 'secondary' : 'grey'">{{
    item.icon }}</v-icon>
                  </v-list-tile-action>
                  <v-list-tile-content>
                    <v-list-tile-title>{{ $t(item.label) }}</v-list-tile-title>
                  </v-list-tile-content>
                  <v-list-tile-action class="full-width">
                    <mobile-input-type :name="item.value + '[' + rampIndex + ']'"
                      :value="rampService.flightRampService[item.value]" :typeValue="item.typeValue"
                      :editable="item.permission === 'EDIT'"
                      @input="updateFlightRampService(rampService, $event, item.value)"
                      :class="item.permission === 'EDIT' ? 'editable' : 'readonly'"
                      :options="item.options ? item.options : []"></mobile-input-Type>
                  </v-list-tile-action>
                </v-list-tile>
                <v-divider inset v-if="rampService.rampService[item.required] && index < items.length - 1"></v-divider>
              </div>
              <!-- BUTTON SAVE -->
              <v-list-tile>
                <v-list-tile-content>
                  <v-list-tile-title>
                    <span v-if="rampServiceCountFaults(rampService) > 0" class="error--text">
                      {{ $t('dispatcher.rampServices.requiredFields') }} {{ rampServiceCountFaults(rampService) }}
                    </span>
                  </v-list-tile-title>
                </v-list-tile-content>
                <v-list-tile-action>
                  <v-btn color="primary" @click="saveRampService(rampService.flightRampService)"
                    :disabled="!isRampServiceRequiredCompleted(rampService) || loadingNewRampService" class="save-button">
                    <v-icon class="mr-2">save</v-icon>
                    {{ rampService.flightRampService.id ? $t('common.update') : $t('common.add') }}
                  </v-btn>
                </v-list-tile-action>
              </v-list-tile>
            </v-list>
            <!-- if not rampService.rampService[item.required] in rampService, display message -->
            <v-list v-if="isEmptyFieldsRampServices(rampService)">
              <span class="ma-2 col-max-width">
                <strong>{{ $t('dispatcher.rampServices.emptyRampService') }}</strong>
              </span>
            </v-list>
          </div>
          <v-divider v-if="rampIndex < localFlightMobileRampServices.length - 1"></v-divider>
        </div>
      </v-list>
    </v-card>
    <div v-if="selectedService">
      <mobile-confirmation-dialog :dialog="showDuplicateConfirmation"
        :title="$t('dispatcher.duplicateRampServiceTitle') + ' ' + selectedService.serviceName"
        :message="$t('dispatcher.duplicateRampServiceMessage')" @confirm="confirmDuplicate" @reject="cancelDuplicate" />
      <mobile-confirmation-dialog :dialog="showAddConfirmation"
        :title="$t('dispatcher.addRampServiceTitle') + ' ' + selectedService.serviceName"
        :message="$t('dispatcher.addRampServiceMessage')" @confirm="confirmAdd" @reject="cancelAdd" />
    </div>
  </v-container>
</template>
<script>

import {
  MOBILE_GET_FLIGHT_RAMP_SERVICES, MOBILE_CREATE_EMPTY_FLIGHT_RAMP_SERVICE, MOBILE_UPDATE_FLIGHT_RAMP_SERVICES, MOBILE_GET_AIRPORT_REQUESTERS, MOBILE_GET_AIRPORT_PARKINGS,
} from '../../store/modules/mobile';
import MobileInputType from './mobileInputType/MobileInputType.vue';
import MobileConfirmationDialog from './MobileConfirmationDialog.vue';
import MobileFlightUtil from './model/mobileFlightUtil';
import { TIME_TIMEZONE_CHANGED } from '../../store/modules/time';
import StoreSubscribable from '../mixins/StoreSubscribable.vue';
import './infoMobileRampServices.scss';
import { OrientationTypes } from '../rampService/orientationType';

export default {
  name: 'info-mobile-ramp-services',
  props: {
    flightId: {
      required: true,
    },
  },
  mixins: [StoreSubscribable],
  components: {
    MobileInputType,
    MobileConfirmationDialog,
  },
  data() {
    return {
      selectedService: null,
      showDuplicateConfirmation: false,
      showAddConfirmation: false,
      localTimezone: this.$store.state.time.timezone,
      showConfirmDialog: false,
      showRampServices: true,
      loadingNewRampService: false,
      loadingIdRampService: null,
      localFlightMobileRampServices: [],
    };
  },
  methods: {
    showDuplicateRampService(rampService) {
      this.selectedService = rampService;
      if (this.showConfirmDialog) {
        this.showDuplicateConfirmation = true;
      } else {
        this.confirmDuplicate();
      }
    },
    confirmDuplicate() {
      if (this.selectedService.id) {
        const newRampService = {
          flightId: this.flightId,
          serviceId: this.selectedService.rampService.id,
          serviceName: this.selectedService.serviceName,
          serviceDescription: this.selectedService.serviceDescription,
          status: 'PENDING',
        };
        this.loadingNewRampService = true;
        this.loadingIdRampService = newRampService.serviceId;
        this.createRampService(newRampService);
      }
      this.showDuplicateConfirmation = false;
    },
    cancelDuplicate() {
      this.showDuplicateConfirmation = false;
    },
    showAddRampService(rampService) {
      this.selectedService = rampService;
      if (this.showConfirmDialog) {
        this.showAddConfirmation = true;
      } else {
        this.confirmAdd();
      }
    },
    confirmAdd() {
      if (this.selectedService.id) {
        const newRampService = {
          flightId: this.flightId,
          serviceId: this.selectedService.rampService.id,
          serviceName: this.selectedService.serviceName,
          serviceDescription: this.selectedService.serviceDescription,
          status: 'PENDING',
        };
        this.loadingNewRampService = true;
        this.loadingIdRampService = newRampService.serviceId;
        this.createRampService(newRampService);
      }
      this.showAddConfirmation = false;
    },
    cancelAdd() {
      this.showAddConfirmation = false;
    },
    formattedDateTime(dateTime) {
      if (dateTime) {
        return this.$moment(dateTime).format('DD/MM/YYYY HH:mm');
      }
      return '';
    },
    isEmptyRampServices() {
      return this.localFlightMobileRampServices.length === 0;
    },
    isEmptyFieldsRampServices(rampService) {
      return !this.items.some(item => rampService.rampService[item.required]);
    },
    isRampServiceRequiredCompleted(rampService) {
      return this.items.every(item => !rampService.rampService[item.required] || rampService.flightRampService[item.value]);
    },
    rampServiceCountFaults(rampService) {
      return this.items.filter(item => rampService.rampService[item.required] && !rampService.flightRampService[item.value]).length;
    },
    updateFlightRampService(rampService, newValue, field) {
      if (rampService.flightRampService) {
        const rampServiceValue = rampService.flightRampService;
        switch (field) {
          // Unidades
          case 'units':
            rampServiceValue.units = newValue;
            break;
          // Hora de recepción
          case 'requestTs':
            rampServiceValue.requestTs = MobileFlightUtil.setDateTimeToBackend(newValue, this.localTimezone);
            break;
          // Hora de inicio requerida
          case 'requestedTs':
            rampServiceValue.requestedTs = MobileFlightUtil.setDateTimeToBackend(newValue, this.localTimezone);
            break;
          // Hora de finalización requerida
          case 'requestedEndTs':
            rampServiceValue.requestedEndTs = MobileFlightUtil.setDateTimeToBackend(newValue, this.localTimezone);
            break;
          // Hora Real de inicio
          case 'startTs':
            rampServiceValue.startTs = MobileFlightUtil.setDateTimeToBackend(newValue, this.localTimezone);
            break;
          // Origen
          case 'originId':
            rampServiceValue.originId = newValue.id ? newValue.id : null;
            rampServiceValue.originCode = newValue.code ? newValue.code : null;
            break;
          // Hora Real de fin
          case 'finishTs':
            rampServiceValue.finishTs = MobileFlightUtil.setDateTimeToBackend(newValue, this.localTimezone);
            break;
          // Destino
          case 'destinationId':
            rampServiceValue.destinationId = newValue.id ? newValue.id : null;
            rampServiceValue.destinationCode = newValue.code ? newValue.code : null;
            break;
          // Orientación
          case 'orientation':
            rampServiceValue.orientation = newValue.id ? newValue.id : null;
            break;
          // Solicitante
          case 'requesterId':
            rampServiceValue.requesterId = newValue.id ? newValue.id : null;
            break;
          // Comentario
          case 'remarks':
            rampServiceValue.remarks = newValue;
            break;
          default:
            // eslint-disable-next-line
            console.error('field not found: ', field);
            break;
        }
      }
    },
    createRampService(rampService) {
      this.$store.dispatch(MOBILE_CREATE_EMPTY_FLIGHT_RAMP_SERVICE, {
        flightId: this.flightId,
        serviceId: rampService.serviceId,
      }).then((newFlightMobileRampService) => {
        const rampServiceFound = this.localFlightMobileRampServices.find(ramp => ramp.rampService.id === rampService.serviceId);
        const newRampService = { ...rampServiceFound };
        if (newRampService) {
          const countServices = this.localFlightMobileRampServices.filter(ramp => ramp.rampService.id === rampService.serviceId && ramp.flightRampService).length + 1;
          newRampService.duplicate = countServices;
          newRampService.flightRampService = newFlightMobileRampService;
          newRampService.flightRampService.serviceId = rampService.serviceId;
          newRampService.flightRampService.serviceName = rampServiceFound.rampService.name;
          this.localFlightMobileRampServices.push(newRampService);
          // remove rampService by id with empty flightRampService
          for (let i = this.localFlightMobileRampServices.length - 1; i >= 0; i -= 1) {
            if (this.localFlightMobileRampServices[i].id === rampServiceFound.id && !this.localFlightMobileRampServices[i].flightRampService) {
              this.localFlightMobileRampServices.splice(i, 1);
            }
          }
          // sort localFlightMobileRampServices by serviceName with flightRampService, and after without flightRampService
          this.localFlightMobileRampServices.sort((a, b) => {
            if (a.flightRampService && b.flightRampService) {
              return a.serviceName.localeCompare(b.serviceName);
            }
            if (a.flightRampService) {
              return -1;
            }
            return 1;
          });
        } else {
          // eslint-disable-next-line
          console.error('Error creating new ramp service');
        }
        this.loadingNewRampService = false;
        this.loadingIdRampService = null;
      });
    },
    saveRampService(rampService) {
      this.loadingNewRampService = true;
      this.$store.dispatch(MOBILE_UPDATE_FLIGHT_RAMP_SERVICES, {
        flightId: this.flightId,
        rampService,
      }).then(() => {
        this.$store.dispatch(MOBILE_GET_FLIGHT_RAMP_SERVICES, this.flightId);
        this.loadingNewRampService = false;
        this.loadingIdRampService = null;
      });
    },
  },
  computed: {
    flightMobileRampServices() {
      return this.$store.state.mobile.flightMobileRampServices;
    },
    airportRequesters() {
      return this.$store.state.mobile.mobileAirportRequesters;
    },
    airportParkings() {
      return this.$store.state.mobile.airportParkings;
    },
    orientations() {
      return [
        {
          id: OrientationTypes.ARRIVAL,
          text: this.$i18n.t('common.arrival'),
          icon: 'flight_land',
        },
        {
          id: OrientationTypes.GROUND,
          text: this.$i18n.t('common.ground'),
          icon: 'swap_horiz',
        },
        {
          id: OrientationTypes.DEPARTURE,
          text: this.$i18n.t('common.departure'),
          icon: 'flight_takeoff',
        },
      ];
    },
    items() {
      return [
        {
          label: 'dispatcher.rampServices.requestTs',
          value: 'requestTs',
          typeValue: 'DATETIME',
          required: 'requestTimeRequired',
          icon: 'more_time',
          permission: 'EDIT',
          options: null,
        },
        {
          label: 'dispatcher.rampServices.units',
          value: 'units',
          typeValue: 'NUMBER',
          required: 'unitsRequired',
          icon: 'filter_1',
          permission: 'EDIT',
          options: null,
        },
        {
          label: 'dispatcher.rampServices.requestedTs',
          value: 'requestedTs',
          typeValue: 'DATETIME',
          required: 'requestStartTimeRequired',
          icon: 'share_arrival_time',
          permission: 'EDIT',
          options: null,
        },
        {
          label: 'dispatcher.rampServices.requestedEndTs',
          value: 'requestedEndTs',
          typeValue: 'DATETIME',
          required: 'requestEndTimeRequired',
          icon: 'bedtime_off',
          permission: 'EDIT',
          options: null,
        },
        {
          label: 'dispatcher.rampServices.startTs',
          value: 'startTs',
          typeValue: 'DATETIME',
          required: 'startTimeRequired',
          icon: 'access_time_filled',
          permission: 'EDIT',
          options: null,
        },
        {
          label: 'dispatcher.rampServices.originId',
          value: 'originId',
          typeValue: 'OPTIONS',
          required: 'originRequired',
          icon: 'flight_takeoff',
          permission: 'EDIT',
          options: this.airportParkings ? this.airportParkings : null,
        },
        {
          label: 'dispatcher.rampServices.finishTs',
          value: 'finishTs',
          typeValue: 'DATETIME',
          required: 'finishTimeRequired',
          icon: 'timer_off',
          permission: 'EDIT',
          options: null,
        },
        {
          label: 'dispatcher.rampServices.destinationId',
          value: 'destinationId',
          typeValue: 'OPTIONS',
          required: 'destinationRequired',
          icon: 'flight_land',
          permission: 'EDIT',
          options: this.airportParkings ? this.airportParkings : null,
        },
        {
          label: 'dispatcher.rampServices.orientation',
          value: 'orientation',
          typeValue: 'OPTIONS',
          required: 'orientationRequired',
          icon: 'swap_horiz',
          permission: 'EDIT',
          options: this.orientations,
        },
        {
          label: 'dispatcher.rampServices.requesterId',
          value: 'requesterId',
          typeValue: 'OPTIONS',
          required: 'requesterRequired',
          icon: 'person',
          permission: 'EDIT',
          options: this.airportRequesters ? this.airportRequesters : null,
        },
        {
          label: 'dispatcher.rampServices.remarks',
          value: 'remarks',
          typeValue: 'TEXT',
          required: 'remarksRequired',
          icon: 'comment',
          permission: 'EDIT',
          options: null,
        },
      ];
    },
  },
  watch: {
    flightId() {
      if (this.flightId) {
        this.$store.dispatch(MOBILE_GET_FLIGHT_RAMP_SERVICES, this.flightId);
      }
    },
    flightMobileRampServices() {
      this.localFlightMobileRampServices = this.flightMobileRampServices;
    },
  },
  async mounted() {
    await this.$store.dispatch(MOBILE_GET_AIRPORT_REQUESTERS);
    await this.$store.dispatch(MOBILE_GET_AIRPORT_PARKINGS);
    this.subscribeMutation(TIME_TIMEZONE_CHANGED, () => {
      this.localTimezone = this.$store.state.time.timezone;
    });
    if (this.flightId) {
      this.$store.dispatch(MOBILE_GET_FLIGHT_RAMP_SERVICES, this.flightId);
    }
  },
};
</script>
<style lang="scss">
.infoMobileRampServices .save-button {
  width: auto;
  padding: 20px;
}
</style>
