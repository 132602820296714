<template>
  <div :style="`width: 100%; height: calc(100vh - ${this.sizeFilter});`">
    <ag-grid-vue
      class="ag-theme-material connected-flights-table operational-table"
      :columnDefs="columnDefs"
      :defaultColDef="defaultColDef"
      :rowData="items"
      @grid-ready="onGridReady"
      @first-data-rendered="firstDataRendered"
      :frameworkComponents="frameworkComponents"
      :gridOptions="gridOptions"
      :multiSortKey="multiSortKey"
      v-bind:style="`width: 100%; height: calc(100vh - ${this.sizeFilter});`">
    </ag-grid-vue>
  </div>
</template>

<script>
import { AgGridVue } from 'ag-grid-vue';
import AgGridConnectedFlights from '../../ag-grid/mixins/AgGridConnectedFlights.vue';
import FlightsDifferences from '../../mixins/FlightsDifferences.vue';
import { FIT_COLUMNS_TO_VIEW, MESSAGES_SET_GRID_API } from '../../../store/modules/flightMessages/flightMessages';
import { cellClassAgGridRules } from '../../ag-grid/mixins/cellClassAgGridRules';
import agGridTextCustomFilterComparator from '../../ag-grid/filters/agGridTextCustomFilterComparator';
import agGridTextCustomFilterFormatter from '../../ag-grid/filters/agGridTextCustomFilterFormatter';
import agGridDateTimeFormatter from '../../ag-grid/formatters/agGridDateTimeFormatter';
import AgGridRowActionIconCellRenderer from '../../ag-grid/cell-renderers/AgGridRowActionIconCellRenderer.vue';
import RolePermissions from '../../mixins/RolePermissions.vue';

export default {
  name: 'flight-messages-list-table',
  mixins: [AgGridConnectedFlights, FlightsDifferences, RolePermissions],
  components: {
    AgGridVue,
  },
  props: {
    items: { type: Array, required: true },
    isFilterDisplayed: { type: Boolean, required: false, default: false },
  },
  computed: {
    sizeFilter() {
      return this.isFilterDisplayed ? '250px' : '180px';
    },
    fitColumnsOnResize() {
      return this.$store.state.flightMessages.fitColumnsOnResize;
    },
  },
  data() {
    const that = this;
    const rolePermission = this.getRoles();
    return {
      dialog: false,
      gridOptions: {
        onFilterChanged: this.uncheckAllOnFilter,
        getRowNodeId: this.getRowNodeId,
        getRowClass: this.getRowClass,
        floatingFilter: true,
        groupHeaderHeight: 35,
        headerHeight: 35,
        floatingFiltersHeight: 20,
        rowHeight: 30,
      },
      multiSortKey: 'ctrl',
      frameworkComponents: {
      },
      defaultColDef: {
        resizable: true,
        sortable: true,
        minWidth: 30,
        suppressMenu: true,
        filter: 'agTextColumnFilter',
        filterParams: {
          textCustomComparator: agGridTextCustomFilterComparator,
          textFormatter: agGridTextCustomFilterFormatter,
        },
        cellClassRules: cellClassAgGridRules,
      },
      columnDefs: [
        {
          headerValueGetter() {
            return that.$i18n.t('common.actions');
          },
          hide: !this.hasPermissions(rolePermission),
          width: 120,
          cellClass: 'text-center',
          suppressMenu: true,
          sortable: false,
          suppressMovable: true,
          suppressSizeToFit: true,
          filter: false,
          resizable: false,
          suppressNavigable: true,
          colCustomConfig: {
            exportCsvDisabled: true,
          },
          valueGetter(params) {
            return params.data;
          },
          cellRendererFramework: AgGridRowActionIconCellRenderer,
          cellRendererParams: {
            action: this.actionFlightMessage,
            icon: params => (params.data.flightId ? 'airplanemode_inactive' : 'flight'),
            tooltip: params => (params.data.flightId ? that.$i18n.t('messages.disassociateFlight') : that.$i18n.t('messages.associateFlight')),
          },
        },
        {
          field: 'ts',
          width: 120,
          headerValueGetter() {
            return that.$i18n.t('messages.timestamp');
          },
          valueFormatter: agGridDateTimeFormatter,
        },
        {
          headerValueGetter() {
            return that.$i18n.t('messages.flight');
          },
          width: 120,
          valueGetter(params) {
            if (params.data.flightId) {
              const flightDate = that.$moment(params.data.flightDate).format('DD/MM/YYYY');
              return `${params.data.flightNumber} - ${flightDate}`;
            }
            return null;
          },
        },
        {
          field: 'typeCode',
          width: 120,
          headerValueGetter() {
            return that.$i18n.t('messages.type');
          },
        },
        {
          width: 200,
          field: 'content',
          headerValueGetter() {
            return that.$i18n.t('messages.content');
          },
          autoHeight: true,
          cellClass: 'cell-wrap-text-multiline',
        },
      ],
    };
  },
  methods: {
    onGridReady(params) {
      this.setAgGridApi(params);
      this.showLoading();
      this.sizeColumnsToFit();

      this.$store.commit(MESSAGES_SET_GRID_API, params); // TODO eliminar api del store
    },
    firstDataRendered() {
      this.$nextTick(() => {
        this.resized();
      });
    },
    resized() {
      if (this.fitColumnsOnResize) {
        this.sizeColumnsToFit();
      }
    },
    sizeColumnsToFit() {
      if (this.gridApi) {
        this.$store.dispatch(FIT_COLUMNS_TO_VIEW);
      }
    },
    actionFlightMessage(item) {
      if (item.flightId) {
        this.disassociateFlight(item);
      } else {
        this.associateFlight(item);
      }
    },
    associateFlight(item) {
      this.$emit('associate-flight', item);
    },
    disassociateFlight(item) {
      this.$emit('disassociate-flight', item);
    },
  },
};

</script>
<style lang="scss">
</style>
