<template>
  <v-card-text>
    <v-container class="container-fields">
      <v-layout row wrap>
        <v-flex xs3 class="input-ticketing-numeration">
          <v-text-field
            :label="this.$i18n.t('ticketingNumeration.format')"
            v-model="item.format"
            :readonly="!hasPermissions(rolePermission)"
          ></v-text-field>
        </v-flex>

        <v-flex xs4 class="input-ticketing-numeration">
          <v-text-field
            :label="this.$i18n.t('ticketingNumeration.initValue')"
            v-model="item.initValue"
            type="number"
            :readonly="!hasPermissions(rolePermission)"
            :rules="[rules.required]"
          ></v-text-field>
        </v-flex>

        <v-flex xs3 class="input-ticketing-numeration">
          <v-text-field
            :label="this.$i18n.t('ticketingNumeration.incrementValue')"
            v-model="item.incrementValue"
            type="number"
            :readonly="!hasPermissions(rolePermission)"
            :rules="[rules.required]"
          ></v-text-field>
        </v-flex>

        <v-flex xs4 class="input-ticketing-numeration">
          <date-input
            v-model="item.initDate"
            :locale="$i18n.locale"
            :label="this.$i18n.t('ticketingNumeration.initDate')"
            :charLenght="0"
            :disabled="!hasPermissions(rolePermission)"
            :rules="[rules.required, rules.endDateAfter]"
          />
        </v-flex>

        <v-flex xs4 class="input-ticketing-numeration">
          <date-input
            v-model="item.endDate"
            :locale="$i18n.locale"
            :label="this.$i18n.t('ticketingNumeration.endDate')"
            :charLenght="0"
            :disabled="!hasPermissions(rolePermission)"
            :rules="[rules.endDateAfter]"
            @input="setDateToEndOfDay"
          />
        </v-flex>

        <v-flex xs2 class="input-ticketing-numeration">
          <airline-select
            v-char-length.clearable="2"
            :label="this.$i18n.t('common.airline')"
            v-model="item.airlineId"
            :disabled="!hasPermissions(rolePermission)"
            :rules="[rules.required]"
          ></airline-select>
        </v-flex>
      </v-layout>
    </v-container>
  </v-card-text>
</template>
<script>
/* eslint no-param-reassign: "off" */
import AirlineSelect from '../../airline/AirlineSelect.vue';
import DateInput from '../../ui/DateInput.vue';
import RolePermissions from '../../mixins/RolePermissions.vue';

export default {
  name: 'TicketingNumerationEditCardContent',
  mixins: [RolePermissions],
  props: {
    item: { type: Object, required: true },
  },
  components: {
    AirlineSelect,
    DateInput,
  },
  data() {
    return {
      rules: {
        required: value => !!value || 'Required.',
        endDateAfter: () => this.item.endDate == null
          || this.$moment(this.item.endDate).isAfter(this.$moment(this.item.initDate))
          || this.$i18n.t('validation.endDateBefore'),
      },
    };
  },
  computed: {
    rolePermission() {
      return `${this.$route.meta.role}_WR`;
    },
  },
  methods: {
    setDateToEndOfDay(event) {
      this.item.dateTo = this.$moment(event).endOf('day');
    },
  },
};
</script>
<style lang="scss" scoped>
.input-ticketing-numeration {
  margin-right: 2ch;
}

.delete-field {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.2em;
}

.container-fields {
  overflow-y: auto;
  max-height: 36em;
  padding: 0.4em;
}

.card-field {
  margin: 0.7em auto;
}

.container-related-fields {
  margin: 0 auto;
  padding: 0.4em;
}
</style>
