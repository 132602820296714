<template>
  <v-container fluid class="contentData">
    <v-layout row justify class="infoNewCatalogs">
      <v-flex xs6 v-if="flights.length > 0">
        <div><v-icon color="primary">mdi-alert</v-icon><span> {{$t("schedulingImport.sameTitle")}}</span></div>
      </v-flex>
      <v-flex xs6 v-else>
        <div><span>{{$t("schedulingImport.allFlightsCorrect")}}</span></div>
      </v-flex>
    </v-layout>

    <v-layout align-center justify-space-around row fill-height v-for="(possiblySameFlight, index) in flights" :key="index" mt-3>
      <v-flex xs5>
        <v-card>
            <h4 :class="getClassLeft(possiblySameFlight)">
              <v-avatar color="grey lighten-5">
                <v-icon v-if="possiblySameFlight.left.bound==='INBOUND'">flight_land</v-icon>
                <v-icon v-if="possiblySameFlight.left.bound==='OUTBOUND'">flight_takeoff</v-icon>
              </v-avatar>
              {{possiblySameFlight.left.airlineCode}}{{possiblySameFlight.left.tripNumber}} - {{possiblySameFlight.left.airport1Code}} - {{$moment(possiblySameFlight.left.scheduledTime).format('DD/MM/YY')}} {{$moment(possiblySameFlight.left.scheduledTime).format('HH:mm')}}
            </h4>
        </v-card>
      </v-flex>
      <v-hover>
        <v-btn flat icon @click="changeAction(possiblySameFlight)" slot-scope="{ hover }">
          <v-icon v-if="!hover">{{getIcon(possiblySameFlight.action)}}</v-icon>
          <v-icon v-if="hover">{{getIconHover(possiblySameFlight.action)}}</v-icon>
        </v-btn>
      </v-hover>
      <v-flex xs5>
        <v-card>
          <h4 :class="getClassRight(possiblySameFlight)">
            <v-avatar color="grey lighten-5">
              <v-icon v-if="possiblySameFlight.right.bound==='INBOUND'">flight_land</v-icon>
              <v-icon v-if="possiblySameFlight.right.bound==='OUTBOUND'">flight_takeoff</v-icon>
            </v-avatar>
            {{possiblySameFlight.right.airlineCode}}{{possiblySameFlight.right.tripNumber}} - {{possiblySameFlight.right.airport1Code}} - {{$moment(possiblySameFlight.right.scheduledTime).format('DD/MM/YY')}} {{$moment(possiblySameFlight.right.scheduledTime).format('HH:mm')}}
          </h4>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>


import { SchedulingImportResultOperation } from '../model/schedulingImportResult';
import { PossiblySameFlightAction } from '../model/possiblySameFlight';

export default {
  name: 'scheduling-import-step-validation',
  mixins: [],
  components: {

  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      flights: [],
    };
  },
  computed: {

  },
  methods: {
    changeAction(possiblySameFlight) {
      /* eslint no-param-reassign: ["error", { "props": false }] */
      possiblySameFlight.action = possiblySameFlight.action === PossiblySameFlightAction.DISTINCT ? PossiblySameFlightAction.SAME : PossiblySameFlightAction.DISTINCT;
      possiblySameFlight.leftOperation = possiblySameFlight.leftOperation === SchedulingImportResultOperation.DELETE ? SchedulingImportResultOperation.EQUAL : SchedulingImportResultOperation.DELETE;
      possiblySameFlight.rightOperation = possiblySameFlight.rightOperation === SchedulingImportResultOperation.INSERT ? SchedulingImportResultOperation.EQUAL : SchedulingImportResultOperation.INSERT;
      possiblySameFlight.left.operation = possiblySameFlight.leftOperation;
      possiblySameFlight.right.operation = possiblySameFlight.rightOperation;
    },
    getIcon(action) {
      return action === PossiblySameFlightAction.DISTINCT ? 'mdi-not-equal-variant' : 'mdi-equal';
    },
    getIconHover(action) {
      return action === PossiblySameFlightAction.DISTINCT ? 'mdi-equal' : 'mdi-not-equal-variant';
    },
    getClassLeft(possiblySameFlight) {
      return possiblySameFlight.leftOperation === SchedulingImportResultOperation.DELETE ? 'delete' : 'equal';
    },
    getClassRight(possiblySameFlight) {
      return possiblySameFlight.rightOperation === SchedulingImportResultOperation.INSERT ? 'insert' : 'equal';
    },
  },
  mounted() {
    this.flights = this.items;
  },
};
</script>
<style lang="scss" scoped>
//@import "../../../assets/scss/ag-grid-fis-theme";
  .infoNewCatalogs {
    padding: 2em;
    font-weight: bold;
  }
  .insert {
    background: #E8F5E9
  }
  .delete {
    background: #FBE9E7
  }
  .equal {
    background: #E3F2FD
  }
  .modify {
    background: #FFF3E0
  }
.contentData {
  max-height: 60vh;
  overflow-y: auto;
}
</style>
