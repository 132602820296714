import { JSUtils } from '../../shared/js/JSUtils';

export default class FlightOperationExtendedInformation {
  constructor(
    id,
    load,
    loadBag,
    loadCargo,
    loadMail,
    loadEstimated,
    loadSpecial,
    loadUnit,
    loadHoldingWeight,
    loadHoldingPcs,
    loadAVIH,
    loadHUM,
    loadWeap,
    loadOths,
  ) {
    this.id = id;
    this.load = load;
    this.loadBag = loadBag;
    this.loadCargo = loadCargo;
    this.loadMail = loadMail;
    this.loadEstimated = loadEstimated;
    this.loadSpecial = loadSpecial;
    this.loadUnit = loadUnit;
    this.loadHoldingWeight = loadHoldingWeight;
    this.loadHoldingPcs = loadHoldingPcs;
    this.loadAVIH = loadAVIH;
    this.loadHUM = loadHUM;
    this.loadWeap = loadWeap;
    this.loadOths = loadOths;
  }

  static get createEmptyFlightOperationExtendedInformation() {
    return new FlightOperationExtendedInformation();
  }

  static createFlightOperationExtendedInformationFromData(o) {
    if (!o) {
      return this.createEmptyFlightOperationExtendedInformation;
    }

    return JSUtils.cast(o, FlightOperationExtendedInformation);
  }
}
