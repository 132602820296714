<template>
  <div v-if="params.isEditable">
    <v-icon
      v-if="!params.data.disableEdit === undefined || !params.data.disableEdit"
      color="secondary"
      small
      class="mr-2"
      @click.stop="params.editItem(params.data)"
    >
      edit
    </v-icon>
    <v-icon v-if="params.data.deleted != null && params.data.deleted"
      color="secondary"
      small
      @click.stop="params.enableDisableItem(params.data)"
    >
      power_settings_new
    </v-icon>
    <v-icon v-if="params.data.deleted != null && !params.data.deleted"
      color="error"
      small
      @click.stop="params.enableDisableItem(params.data)"
    >
      not_interested
    </v-icon>
    <v-icon
      v-if="!params.data.disableDelete === undefined || !params.data.disableDelete"
      color="error"
      small
      @click.stop="params.deleteItem(params.data)"
    >
      delete
    </v-icon>
  </div>
  <div v-else>
    <v-icon
      color="secondary"
      small
      class="mr-2"
      @click.stop="params.editItem(params.data)"
    >
      remove_red_eye
    </v-icon>
  </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
});
</script>

<style scoped>

</style>
