<template>
  <v-card-text>
    <v-container fluid style="margin-bottom: 0; padding-bottom: 0">
      <v-layout style="margin-bottom: 0; padding-bottom: 0">

        <v-flex style="margin-right: 2ch; width: 70vw">
          <v-text-field :label="this.$i18n.t('configuration.delayReasons.delayReasonName')" v-model="item.reason"
            :readonly="!hasPermissions(rolePermission)"
            :rules="[rules.required]"/>
        </v-flex>


      </v-layout>

      <v-layout style="margin-bottom: 0; padding-bottom: 0">
        <v-flex style="margin-right: 2ch; width: 100vw">
          <v-text-field :label="this.$i18n.t('configuration.description')" v-model="item.description"
            :readonly="!hasPermissions(rolePermission)"/>
        </v-flex>
      </v-layout>

      <v-layout style="margin-bottom: 0; padding-bottom: 0">
        <v-flex style="margin-right: 2ch; width: 10vw">
          <v-radio-group v-model="travelStageSelected" @change="updateTravelStageSelected"
            :readonly="!hasPermissions(rolePermission)">
            <v-radio :label="this.$i18n.t('configuration.delayReasons.checkIn')" :value="checkIn" />
            <v-radio :label="this.$i18n.t('configuration.delayReasons.boarding')" :value="boarding" />
          </v-radio-group>
        </v-flex>

        <v-flex style="margin-right: 2ch; width: 10vw">
          <v-radio-group v-model="beginningEndingSelected" @change="updateBeginningEndingSelected"
            :readonly="!hasPermissions(rolePermission)">
            <v-radio :label="this.$i18n.t('configuration.delayReasons.start')" :value="start" />
            <v-radio :label="this.$i18n.t('configuration.delayReasons.end')" :value="end" />
          </v-radio-group>
        </v-flex>
      </v-layout>

    </v-container>
  </v-card-text>
</template>
<script>
import RolePermissions from '../../mixins/RolePermissions.vue';
import { JSUtils } from '../../shared/js/JSUtils';

export default {
  name: 'DelayReasonEditCardContent',
  mixins: [RolePermissions],
  props: {
    item: { type: Object, required: true },
  },
  computed: {
    rolePermission() {
      return `${this.$route.meta.role}_WR`;
    },
    travelStageSelected: {
      // eslint-disable-next-line
      get: function () {
        return this.item.applyToCheckIn ? this.checkIn : this.boarding;
      },
      // eslint-disable-next-line
      set: function (newValue) {
        this.item.applyToCheckIn = newValue === this.checkIn;
        this.item.applyToBoarding = newValue === this.boarding;
      },
    },
    beginningEndingSelected: {
      // eslint-disable-next-line
      get: function () {
        return this.item.applyToStartTime ? this.start : this.end;
      },
      // eslint-disable-next-line
      set: function (newValue) {
        this.item.applyToStartTime = newValue === this.start;
        this.item.applyToEndTime = newValue === this.end;
      },
    },
  },
  created() {
    this.initializeItemIfNeeded();
  },
  data() {
    const checkIn = 'checkIn';
    const boarding = 'boarding';
    const start = 'start';
    const end = 'end';
    return {
      checkIn,
      boarding,
      start,
      end,
      rules: {
        required: value => !!value || this.$i18n.t('common.fieldRequired'),
      },
    };
  },
  methods: {
    initializeItemIfNeeded() {
      if (JSUtils.isObjectEmpty(this.item)) {
        this.item.reason = '';
        this.item.description = '';
        this.item.applyToCheckIn = true;
        this.item.applyToBoarding = false;
        this.item.applyToStartTime = true;
        this.item.applyToEndTime = false;
        this.item.deleted = false;
      }
    },
    updateTravelStageSelected(userSelection) {
      this.travelStageSelected = userSelection;
    },
    updateBeginningEndingSelected(userSelection) {
      this.beginningEndingSelected = userSelection;
    },
  },
};
</script>
