<template>
  <v-layout row justify-center>
    <v-dialog v-model="dialog" persistent max-width="1370px"
      :content-class="isContentVisible ? 'modal-common' : 'modal-common hide-title'">
      <v-card :dark="this.$vuetify.dark" :color="this.$vuetify.dark ? 'bggrey_dark' : 'bggrey'">
        <v-toolbar dark color="primary">
          <v-icon>flight</v-icon>
          <v-toolbar-title>{{ $t("common.addAircraftGroundServices") }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <!-- Botón para contraer/expandir el contenido -->
          <v-btn icon @click="toggleContentVisibility">
            <v-icon>{{ isContentVisible ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
          </v-btn>
        </v-toolbar>
        <div v-show="isContentVisible">

          <loading-component />

          <v-card-text class="services-table">
            <ag-grid-vue class="ag-theme-material" :columnDefs="columnDefs" :defaultColDef="defaultColDef"
              @grid-ready="setAgGridApi" :gridOptions="gridOptions" rowSelection="multiple"
              @selection-changed="onSelectionChanged" :frameworkComponents="frameworkComponents" :rowData="rowData">
            </ag-grid-vue>
          </v-card-text>

          <v-card-actions>
            <v-btn color="secondary" flat @click="addFlightRampService()"><v-icon>add</v-icon></v-btn>
            <v-btn v-show="rampStatusSelected.length > 0" color="error" flat
              @click="removeRampStatus()"><v-icon>delete</v-icon></v-btn>
            <v-spacer></v-spacer>
            <v-btn flat color="primary" @click="reject" :disabled="loading">{{ $t("common.cancel") }}</v-btn>
            <v-btn color="primary" @click="saveAircraftGroundServices" :disabled="loading || !!validData">{{
      $t("common.save") }}</v-btn>
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>
  </v-layout>
</template>
<script>

import { AgGridVue } from 'ag-grid-vue';
import CrudOperationsDTO from '../shared/model/crudOperationsDTO';
import FlightRampService from '../ag-grid/cell-editors/model/flightRampService';
import AgGridValueWithBackgroundCellRenderer
from '../ag-grid/cell-renderers/AgGridValueWithBackgroundCellRenderer.vue';
import agGridDateTimeFormatter from '../ag-grid/formatters/agGridDateTimeFormatter';
import { GET_RAMP_SERVICE } from '../../store/modules/rampServices/rampServicesCatalog';
import AgGridRampServiceSelectCellEditor from '../ag-grid/cell-editors/AgGridRampServiceSelectCellEditor.vue';
import AgGridAirportRequesterSelectCellEditor from '../ag-grid/cell-editors/AgGridAirportRequesterSelectCellEditor.vue';
import AgGridDateTimeCellEditor from '../ag-grid/cell-editors/AgGridDateTimeCellEditor.vue';
import AgGridNumberCellEditor from '../ag-grid/cell-editors/AgGridNumberCellEditor.vue';
import AgGridValueWithAsteriskCellRenderer
from '../ag-grid/cell-renderers/AgGridValueWithAsteriskCellRenderer.vue';
import AgGridValueBaseWithTooltipCellRenderer
from '../ag-grid/cell-renderers/AgGridValueBaseWithTooltipCellRenderer.vue';
import AgGridTextCellEditor from '../ag-grid/cell-editors/AgGridTextCellEditor.vue';
import { cellClassAgGridRules } from '../ag-grid/mixins/cellClassAgGridRules';
import AgGridOptionsConfigurable from '../ag-grid/mixins/AgGridOptionsConfigurable.vue';
import AgGridApiHoldable from '../ag-grid/mixins/AgGridApiHoldable.vue';
import LoadingComponent from '../layout/LoadingComponent.vue';
import { REQUEST_ERROR, REQUEST_START, REQUEST_SUCCESS } from '../../store/store';
import { NOTIFICATION_SHOW } from '../../store/modules/notifications';
import AgGridDateTimeWithBackgroundCellRenderer
from '../ag-grid/cell-renderers/AgGridDateTimeWithBackgroundCellRenderer.vue';
import RolePermissions from '../mixins/RolePermissions.vue';

export default {
  name: 'aircraft-ground-new-services-modal',
  mixins: [AgGridApiHoldable, AgGridOptionsConfigurable, RolePermissions],
  components: {
    AgGridVue,
    LoadingComponent,
  },
  props: {
    aircraftGround: { required: true },
    dialog: {},
  },
  computed: {
    loading() {
      return this.$store.state.loading;
    },
    rolePermission() {
      return `${this.$route.meta.role}_WR`;
    },
  },
  data() {
    return {
      referenceDate: null,
      columnDefs: null,
      frameworkComponents: {
        AgGridRampServiceSelectCellEditor,
        AgGridDateTimeCellEditor,
        AgGridNumberCellEditor,
        AgGridValueWithAsteriskCellRenderer,
        AgGridValueBaseWithTooltipCellRenderer,
        AgGridValueWithBackgroundCellRenderer,
        AgGridTextCellEditor,
        AgGridDateTimeWithBackgroundCellRenderer,
        AgGridAirportRequesterSelectCellEditor,
      },
      defaultColDef: {
        cellClassRules: cellClassAgGridRules,
      },
      gridOptions: {
        getRowClass: this.getRowClass,
      },
      rowData: [],
      validData: true,
      rampStatusSelected: [],
      crudOperationsDto: new CrudOperationsDTO(),
      isContentVisible: true,
    };
  },
  methods: {
    onColumnResized(event) {
      if (event.finished) {
        this.gridApi.resetRowHeights();
      }
    },
    addFlightRampService() {
      this.rowData.push(FlightRampService.createFlightRampService(this.aircraftGround.id));
      this.validateRampStatus();
    },
    removeRampStatus() {
      if (this.rampStatusSelected.length > 0) {
        this.crudOperationsDto.deletes.push(...this.rampStatusSelected.filter(tow => !!tow.id));

        this.rampStatusSelected.forEach((msg) => {
          const index = this.rowData.indexOf(msg);
          this.rowData.splice(index, 1);
        });
      }
      this.rampStatusSelected = [];
      this.validateRampStatus();
    },
    onSelectionChanged(event) {
      if (event.api.getSelectedNodes().length > 0) {
        this.rampStatusSelected = event.api.getSelectedNodes().map(n => n.data);
      } else {
        this.rampStatusSelected = [];
      }
    },
    validateRampStatus(params) {
      this.validData = false;
      this.columnDefs.slice(1, this.columnDefs.length).map(colDef => colDef.field).forEach((colField) => {
        this.rowData.forEach((data) => {
          this.validateColFieldOfRow(colField, data);
        });

        if (params) {
          params.api.refreshCells({ force: true });
        }
      });
    },

    validateColFieldOfRow(colField, rowData) {
      const data = rowData;
      const errorValidate = {};
      const errorMessages = [];

      errorValidate.errorExist = false;
      const rampService = this.$store.getters.getRampServiceById(data.serviceId);
      if (rampService) {
        if (colField === 'units') {
          if (rampService.unitsRequired && !data.units) {
            errorValidate.errorExist = true;
            this.validData = true;
            if (!errorMessages.includes('This field is required')) errorMessages.push('This field is required');
          }
        }
        if (colField === 'requestTs') {
          if (rampService.requestTimeRequired && !data.requestTs) {
            errorValidate.errorExist = true;
            this.validData = true;
            if (!errorMessages.includes('This field is required')) errorMessages.push('This field is required');
          }
        }
        if (colField === 'requestedTs') {
          if (rampService.requestStartTimeRequired && !data.requestedTs) {
            errorValidate.errorExist = true;
            this.validData = true;
            if (!errorMessages.includes('This field is required')) errorMessages.push('This field is required');
          }
        }
        if (colField === 'requestedEndTs') {
          if (data.requestedEndTs != null && data.requestedTs != null && this.$moment(data.requestedEndTs).isBefore(this.$moment(data.requestedTs))) {
            errorValidate.errorExist = true;
            this.validData = true;
            if (!errorMessages.includes(this.$i18n.t('validation.finishTimeIsBeforeThatStartTime'))) errorMessages.push(this.$i18n.t('validation.finishTimeIsBeforeThatStartTime'));
          }
        }
        if (colField === 'startTs') {
          if (rampService.startTimeRequired && !data.startTs) {
            errorValidate.errorExist = true;
            this.validData = true;
            if (!errorMessages.includes('This field is required')) errorMessages.push('This field is required');
          }
        }
        if (colField === 'finishTs') {
          if (rampService.finishTimeRequired && !data.finishTs) {
            errorValidate.errorExist = true;
            this.validData = true;
            if (!errorMessages.includes('This field is required')) errorMessages.push('This field is required');
          }
        }
        if (colField === 'requesterName') {
          if (rampService.requesterRequired && !data.requesterName) {
            errorValidate.errorExist = true;
            this.validData = true;
            if (!errorMessages.includes(this.$i18n.t('validation.fieldRequired'))) errorMessages.push(this.$i18n.t('validation.fieldRequired'));
          }
        }
        if (colField === 'remarks') {
          if (rampService.remarksRequired && !data.remarks) {
            errorValidate.errorExist = true;
            this.validData = true;
            if (!errorMessages.includes('This field is required')) errorMessages.push('This field is required');
          }
        }
      } else if (colField === 'serviceName') {
        errorValidate.errorExist = true;
        this.validData = true;
        if (!errorMessages.includes('This field is required')) errorMessages.push('This field is required');
      }

      if (data.messageValidate == null) {
        data.messageValidate = {};
      }

      errorValidate.errorMessages = errorMessages;
      data.messageValidate[colField] = errorValidate;
    },

    setColDefs() {
      const that = this;
      this.columnDefs = [
        {
          checkboxSelection: true,
          width: 36,
          editable: that.editable(true, that.rolePermission),
        },
        {
          field: 'serviceName',
          headerValueGetter() {
            return that.$i18n.t('rampServices.rampServices');
          },
          editable: that.editable(true, that.rolePermission),
          cellEditor: 'AgGridRampServiceSelectCellEditor',
          cellEditorCustomConfig: {
            fieldId: 'serviceId',
            fieldName: 'serviceName',
          },
          valueGetter(params) {
            return {
              value: params.data.serviceName,
              error: params.data.messageValidate.serviceName.errorExist,
              tooltip: params.data.messageValidate.serviceName.errorMessages.join(' '),
            };
          },
          onCellValueChanged: this.validateRampStatus,
          cellRendererFramework: AgGridValueWithBackgroundCellRenderer,
        },
        {
          field: 'units',
          headerValueGetter() {
            return that.$i18n.t('rampServices.units');
          },
          editable: that.editable(true, that.rolePermission),
          cellEditor: 'AgGridNumberCellEditor',
          valueGetter(params) {
            return {
              value: params.data.units ? params.data.units : null,
              error: params.data.messageValidate.units.errorExist,
              tooltip: params.data.messageValidate.units.errorMessages.join(' '),
            };
          },
          onCellValueChanged: this.validateRampStatus,
          cellRendererFramework: AgGridValueWithBackgroundCellRenderer,
        },
        {
          field: 'requestTs',
          headerValueGetter() {
            return that.$i18n.t('rampServices.requestTime');
          },
          cellClass: 'date-time-cell',
          editable: that.editable(true, that.rolePermission),
          cellEditor: 'AgGridDateTimeCellEditor',
          cellEditorCustomConfig: {
            referenceDate: this.referenceDate,
          },
          valueGetter(params) {
            return {
              value: params.data.requestTs,
              error: params.data.messageValidate.requestTs.errorExist,
              tooltip: params.data.messageValidate.requestTs.errorMessages.join(' '),
            };
          },
          onCellValueChanged: this.validateRampStatus,
          cellRendererFramework: AgGridDateTimeWithBackgroundCellRenderer,
          valueFormatter: agGridDateTimeFormatter,
        },
        {
          field: 'requestedTs',
          headerValueGetter() {
            return that.$i18n.t('rampServices.requestedStartTime');
          },
          cellClass: 'date-time-cell',
          editable: that.editable(true, that.rolePermission),
          cellEditor: 'AgGridDateTimeCellEditor',
          cellEditorCustomConfig: {
            referenceDate: this.referenceDate,
          },
          valueGetter(params) {
            return {
              value: params.data.requestedTs,
              error: params.data.messageValidate.requestedTs.errorExist,
              tooltip: params.data.messageValidate.requestedTs.errorMessages.join(' '),
            };
          },
          onCellValueChanged: this.validateRampStatus,
          cellRendererFramework: AgGridDateTimeWithBackgroundCellRenderer,
          valueFormatter: agGridDateTimeFormatter,
        },
        {
          field: 'requestedEndTs',
          headerTooltip: `${this.$i18n.t('rampServices.requestedEndTime')}`,
          headerValueGetter() {
            return that.$i18n.t('rampServices.requestedEndTime');
          },
          cellClass: 'date-time-cell',
          editable: that.editable(true, that.rolePermission),
          cellEditor: 'AgGridDateTimeCellEditor',
          cellEditorCustomConfig: {
            referenceDate: this.referenceDate,
          },
          valueGetter(params) {
            return {
              value: params.data.requestedEndTs,
              error: params.data.messageValidate.requestedEndTs.errorExist,
              tooltip: params.data.messageValidate.requestedEndTs.errorMessages.join(' '),
            };
          },
          onCellValueChanged: this.validateRampStatus,
          cellRendererFramework: AgGridDateTimeWithBackgroundCellRenderer,
          valueFormatter: agGridDateTimeFormatter,
        },
        {
          field: 'startTs',
          headerValueGetter() {
            return that.$i18n.t('rampServices.startTime');
          },
          cellClass: 'date-time-cell',
          editable: that.editable(true, that.rolePermission),
          cellEditor: 'AgGridDateTimeCellEditor',
          cellEditorCustomConfig: {
            referenceDate: this.referenceDate,
          },
          valueGetter(params) {
            return {
              value: params.data.startTs,
              error: params.data.messageValidate.startTs.errorExist,
              tooltip: params.data.messageValidate.startTs.errorMessages.join(' '),
            };
          },
          onCellValueChanged: this.validateRampStatus,
          cellRendererFramework: AgGridDateTimeWithBackgroundCellRenderer,
          valueFormatter: agGridDateTimeFormatter,
        },
        {
          field: 'finishTs',
          headerValueGetter() {
            return that.$i18n.t('rampServices.endTime');
          },
          cellClass: 'date-time-cell',
          editable: that.editable(true, that.rolePermission),
          cellEditor: 'AgGridDateTimeCellEditor',
          cellEditorCustomConfig: {
            referenceDate: this.referenceDate,
          },
          valueGetter(params) {
            return {
              value: params.data.finishTs,
              error: params.data.messageValidate.finishTs.errorExist,
              tooltip: params.data.messageValidate.finishTs.errorMessages.join(' '),
            };
          },
          onCellValueChanged: this.validateRampStatus,
          cellRendererFramework: AgGridDateTimeWithBackgroundCellRenderer,
          valueFormatter: agGridDateTimeFormatter,
        },
        {
          field: 'requesterName',
          headerValueGetter() {
            return that.$i18n.t('common.requester');
          },
          editable: that.editable(true, that.rolePermission),
          cellEditor: 'AgGridAirportRequesterSelectCellEditor',
          cellEditorCustomConfig: {
            fieldId: 'requesterId',
            fieldName: 'requesterName',
          },
          valueGetter(params) {
            return {
              value: params.data.requesterName,
              error: params.data.messageValidate.requesterName.errorExist,
              tooltip: params.data.messageValidate.requesterName.errorMessages.join(' '),
            };
          },
          onCellValueChanged: this.validateRampStatus,
          cellRendererFramework: AgGridValueWithBackgroundCellRenderer,
        },
        {
          field: 'remarks',
          headerValueGetter() {
            return that.$i18n.t('operations.remarksExtended');
          },

          valueGetter(params) {
            return {
              value: params.data.remarks ? params.data.remarks : null,
              error: params.data.messageValidate.remarks.errorExist,
              tooltip: params.data.messageValidate.remarks.errorMessages.join(' '),
            };
          },
          cellEditor: 'AgGridTextCellEditor',
          onCellValueChanged: this.validateRampStatus,
          cellRendererFramework: AgGridValueWithBackgroundCellRenderer,
          editable: that.editable(true, that.rolePermission),
        },
      ];
    },
    saveAircraftGroundServices() {
      this.rowData.forEach((row) => {
        if (row.requestedTs !== null && row.requestTs === null) {
          /* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["row"] }] */
          row.requestTs = this.$moment();
        }
      });
      this.crudOperationsDto.updates.push(...this.rowData);
      this.$store.commit(REQUEST_START);
      this.$http.put(`aircraft-ground/${this.aircraftGround.id}/ramp/services`, this.crudOperationsDto)
        .then(() => {
          this.$store.commit(REQUEST_SUCCESS);
          this.confirm();
        })
        .catch((err) => {
          this.$store.commit(REQUEST_ERROR, err);
          this.$store.commit(NOTIFICATION_SHOW, {
            message: err.response.data.title || this.$i18n.t('notifications.errorNotification'),
            color: 'error',
          });
        });
    },
    reject() {
      this.$emit('aircraft-ground-service-new-reject');
    },
    confirm() {
      this.$emit('aircraft-ground-service-new-confirm', {});
    },
    toggleContentVisibility() {
      this.isContentVisible = !this.isContentVisible;
    },
  },
  created() {
    this.referenceDate = this.aircraftGround.aircraftGroundDate;

    this.setColDefs();
    this.$store.dispatch(GET_RAMP_SERVICE);
  },
};
</script>
<style></style>
