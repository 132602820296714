<template>
  <div>
    <entity-configuration-list
      :entity-name="this.$i18n.t('configuration.aircraftType')"
      entity-icon="local_airport"
      :framework-components="frameworkComponents" :column-defs="columnDefs" :items="aircraftTypes"
      @confirm-edit-item="editConfirm"
      @confirm-delete-item="deleteConfirm"
      @confirm-enable-disable-item="enableDisableConfirm"
    >
    <template v-slot:modal-content="{ itemEdit }">
      <aircraft-types-edit-card-content :item="itemEdit" />
      </template>
    </entity-configuration-list>
  </div>
</template>
<script>
import {
  GET_AIRCRAFT_TYPES,
  AIRCRAFT_TYPES_CREATE,
  AIRCRAFT_TYPES_DELETE,
  AIRCRAFT_TYPES_UPDATE,
  AIRCRAFT_TYPES_DISABLE,
  AIRCRAFT_TYPES_ENABLE,
} from '@/store/modules/aircraftTypes/aircraftTypesCatalog';
import EntityConfigurationList from '@/components/configuration/common/EntityConfigurationList.vue';
import AircraftTypesEditCardContent from './AircraftTypesEditCardContent.vue';
import AgGridIconCheckOrIconClearCellRenderer from '../../ag-grid/cell-renderers/AgGridIconCheckOrIconClearCellRenderer.vue';
import AgGridToggleCellEditor from '../../ag-grid/cell-editors/AgGridToggleCellEditor.vue';
import AgGridRowActionButtonsCellRenderer from '../../ag-grid/cell-renderers/AgGridRowActionButtonsCellRenderer.vue';
import AgGridIconCellRenderer from '../../ag-grid/cell-renderers/AgGridIconCellRenderer.vue';

export default {
  name: 'AircraftTypesList',
  components: {
    EntityConfigurationList,
    AircraftTypesEditCardContent,
  },
  data() {
    const that = this;
    return {
      frameworkComponents: {
        AgGridIconCheckOrIconClearCellRenderer,
        AgGridToggleCellEditor,
        AgGridRowActionButtonsCellRenderer,
        AgGridIconCellRenderer,
      },
      columnDefs: [
        {
          field: 'iataCode',
          headerValueGetter() {
            return that.$i18n.t('configuration.iataCode');
          },
          sort: 'asc',
        },
        {
          field: 'icaoCode',
          headerValueGetter() {
            return that.$i18n.t('configuration.icaoCode');
          },
        },
        {
          field: 'comments',
          headerValueGetter() {
            return that.$i18n.t('configuration.description');
          },
        },
        {
          field: 'manufacturer',
          headerValueGetter() {
            return that.$i18n.t('configuration.manufacturer');
          },
          cellRendererFramework: AgGridIconCheckOrIconClearCellRenderer,
        },
        {
          headerValueGetter() {
            return that.$i18n.t('configuration.mtow');
          },
          field: 'mtow',
        },
        {
          headerValueGetter() {
            return that.$i18n.t('configuration.disabled');
          },
          field: 'deleted',
          cellRendererFramework: AgGridIconCellRenderer,
          cellRendererParams: {
            icon: 'mdi-cancel',
            tooltip: params => (params.data.deleted ? this.$i18n.t('configuration.disabled') : null),
          },
        },
      ],
    };
  },
  computed: {
    aircraftTypes() {
      return this.$store.getters.getAircraftTypes;
    },
  },
  methods: {
    getAircraftTypes() {
      this.$store.dispatch(GET_AIRCRAFT_TYPES);
    },
    deleteConfirm(item) {
      this.$store.dispatch(AIRCRAFT_TYPES_DELETE, item.id);
    },
    editConfirm(item) {
      this.$store.dispatch(item.id ? AIRCRAFT_TYPES_UPDATE : AIRCRAFT_TYPES_CREATE, item);
    },
    enableDisableConfirm(item) {
      this.$store.dispatch(item.deleted ? AIRCRAFT_TYPES_ENABLE : AIRCRAFT_TYPES_DISABLE, item.id);
    },
  },
  created() {
    this.getAircraftTypes();
  },
};
</script>
