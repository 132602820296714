/* eslint no-param-reassign: "off" */
import { HTTP } from '../../http';
import { REQUEST_ERROR, REQUEST_START, REQUEST_SUCCESS } from '../store';
import { NOTIFICATION_SHOW } from './notifications';
import i18n from '../../plugins/i18n';


// action
export const GET_AVINET_USERS = 'GET_AVINET_USERS';
export const AVINET_USERS_DELETE = 'AVINET_USERS_DELETE';
export const AVINET_USERS_CREATE = 'AVINET_USERS_CREATE';
export const AVINET_USERS_UPDATE = 'AVINET_USERS_UPDATE';


// mutations
export const CHANGE_AVINET_USERS_SELECTION = 'CHANGE_AVINET_USERS_SELECTION';
export const GET_AVINET_USERS_SUCCESS = 'GET_AVINET_USERS_SUCCESS';
export const GET_AVINET_USERS_REQUESTED = 'GET_AVINET_USERS_REQUESTED';
export const UPDATE_AVINET_USERS_SUCCESS = 'UPDATE_AVINET_USERS_SUCCESS';


// initial state
const storeState = {
  avinetUsers: [],
  avinetUsersRequested: false,
  selectedavinetUsers: [],
};

// getters
const storeGetters = {
  getAvinetUsers: state => state.avinetUsers,
  getAvinetUsersSelected: state => state.selectedAvinetUsers,
  hasSelectedAvinetUsers: state => state.selectedAvinetUsers.length > 0,
  getAvinetUsersById: state => id => state.avinetUsers.find(a => a.id === id),

};

// actions
const storeActions = {
  [GET_AVINET_USERS]: ({ commit, state }) => {
    if (state.avinetUsersRequested) {
      return;
    }
    commit(REQUEST_START);
    commit(GET_AVINET_USERS_REQUESTED);
    HTTP.get('avinet-user')
      .then((resp) => {
        commit(REQUEST_SUCCESS);
        commit(GET_AVINET_USERS_SUCCESS, resp.data);
      })
      .catch((err) => {
        commit(REQUEST_ERROR, err);
      });
  },

  [AVINET_USERS_CREATE]: ({ commit, dispatch }, avinetUser) => {
    commit(REQUEST_START);
    return HTTP.post('avinet-user', avinetUser)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(UPDATE_AVINET_USERS_SUCCESS);
        dispatch(GET_AVINET_USERS);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.avinetSuccessCreate'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },

  [AVINET_USERS_UPDATE]: ({ commit, dispatch }, avinetUser) => {
    commit(REQUEST_START);
    return HTTP.put('avinet-user', avinetUser)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(UPDATE_AVINET_USERS_SUCCESS);
        dispatch(GET_AVINET_USERS);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.avinetSuccessUpdate'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },

  [AVINET_USERS_DELETE]: ({ commit, dispatch }, id) => {
    commit(REQUEST_START);
    return HTTP.delete(`avinet-user/${id}`)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(UPDATE_AVINET_USERS_SUCCESS);
        dispatch(GET_AVINET_USERS);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.avinetSuccessDelete'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
};

// mutations
const storeMutations = {
  [GET_AVINET_USERS_SUCCESS]: (state, avinetUsers) => {
    state.avinetUsers = avinetUsers;
  },
  [GET_AVINET_USERS_REQUESTED]: (state) => {
    state.avinetUsersRequested = true;
  },
  [UPDATE_AVINET_USERS_SUCCESS]: (state) => {
    state.avinetUsersRequested = false;
  },
  [CHANGE_AVINET_USERS_SELECTION]: (state, selection) => {
    state.selectedAvinetUsers = selection;
  },
};

export default {
  state: storeState,
  getters: storeGetters,
  actions: storeActions,
  mutations: storeMutations,
};
