export default function agGridTextCustomFilterComparator(filter, value, filterText) {
  console.log('FILTER'); // eslint-disable-line no-console
  const filterTextLoweCase = filterText.toLowerCase();
  const valueLowerCase = value ? value.toString().toLowerCase() : '';

  // if (typeof obj1 === 'string' && typeof obj2 === 'string') {
  //   return obj1.value.toLowerCase().localeCompare(obj2.value.toLowerCase());
  // }
  //
  // if (typeof obj1 === 'string') {
  //   return obj1.value.toLowerCase().localeCompare(obj2.value.toString().toLowerCase());
  // }
  //
  // if (typeof obj2 === 'string') {
  //   return obj2.value.toLowerCase().localeCompare(obj1.value.toString().toLowerCase());
  // }
  //
  // return obj1.value.toString().toLowerCase().localeCompare(obj2.value.toString().toLowerCase());
  //
  // var valueLowerCase = value.toString().toLowerCase();


  switch (filter) {
    case 'contains': {
      return valueLowerCase.indexOf(filterTextLoweCase) >= 0;
    }
    case 'notContains': {
      return valueLowerCase.indexOf(filterTextLoweCase) === -1;
    }
    case 'equals': {
      return valueLowerCase === filterTextLoweCase;
    }
    case 'notEqual': {
      return valueLowerCase !== filterTextLoweCase;
    }
    case 'startsWith': {
      return valueLowerCase.indexOf(filterTextLoweCase) === 0;
    }
    case 'endsWith': {
      const index = valueLowerCase.lastIndexOf(filterTextLoweCase);
      return index >= 0 && index === (valueLowerCase.length - filterTextLoweCase.length);
    }
    default: {
      // should never happen
      console.warn(`invalid filter type ${filter}`); // eslint-disable-line no-console
      return false;
    }
  }
}
