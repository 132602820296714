import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataIterator } from 'vuetify/lib/components/VDataIterator';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListTile } from 'vuetify/lib/components/VList';
import { VListTileContent } from 'vuetify/lib/components/VList';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{staticClass:"contentData",attrs:{"fluid":""}},[_c(VLayout,{staticClass:"infoNewCatalogs",attrs:{"row":"","justify":""}},[(_vm.flights.length > 0)?_c(VFlex,[_c('div',[_c(VIcon,{attrs:{"color":"primary"}},[_vm._v("mdi-alert")]),_c('span',[_vm._v(" "+_vm._s(_vm.$t("schedulingImport.sameFlightsUniqueKey")))])],1)]):_c(VFlex,[_c('div',[_c('span',[_vm._v(_vm._s(_vm.$t("schedulingImport.allFlightsCorrect")))])])])],1),_c(VLayout,{attrs:{"align-center":"","justify-space-around":"","row":"","fill-height":""}},[(_vm.flights.length > 0)?_c(VContainer,{staticClass:"grid-duplicate-flights",attrs:{"fluid":"","grid-list-md":""}},[_c(VDataIterator,{attrs:{"items":_vm.flights,"item-key":"name","rows-per-page-items":_vm.rowsPerPageItems,"pagination":_vm.paginationDuplicatedFlights,"content-class":"layout row wrap"},on:{"update:pagination":function($event){_vm.paginationDuplicatedFlights=$event}},scopedSlots:_vm._u([{key:"item",fn:function(props){return _c(VFlex,{attrs:{"xs4":""}},[_c(VCard,[_c(VCardTitle,[_c('h4',[(props.item[0].bound==='INBOUND')?_c(VIcon,[_vm._v("flight_land")]):_vm._e(),(props.item[0].bound==='OUTBOUND')?_c(VIcon,[_vm._v("flight_takeoff")]):_vm._e(),_vm._v(" "+_vm._s(props.item[0].flightDate)+" - "+_vm._s(props.item[0].airlineCode)+" "+_vm._s(props.item[0].tripNumber)+" - "+_vm._s(props.item[0].airport1Code))],1)]),_c(VDivider),_c(VList,{attrs:{"dense":""}},_vm._l((props.item),function(flight,index){return _c(VListTile,{key:index},[_c(VListTileContent,[_c('span',[_vm._v(" "+_vm._s(_vm.$moment(flight.scheduledTime).tz('UTC').format('YYYY-MM-DD HH:mm'))+" - "+_vm._s(_vm.$moment(flight.scheduledTime).tz(_vm.localTimezone).format('YYYY-MM-DD HH:mm'))+" - "+_vm._s(flight.airlineCode)+" "+_vm._s(flight.tripNumber)+" - "+_vm._s(flight.airport1Code)+" ")])])],1)}),1)],1)],1)}}],null,false,2555937707)})],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }