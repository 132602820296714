import { render, staticRenderFns } from "./ContingencyBasicServiceForm.vue?vue&type=template&id=6b61f8c6&scoped=true"
import script from "./ContingencyBasicServiceForm.vue?vue&type=script&lang=js"
export * from "./ContingencyBasicServiceForm.vue?vue&type=script&lang=js"
import style0 from "./ContingencyBasicServiceForm.vue?vue&type=style&index=0&id=6b61f8c6&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b61f8c6",
  null
  
)

export default component.exports