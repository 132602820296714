<template>
  <contingencies-list-table :items="items" :isFilterDisplayed="this.isFilterDisplayed" @edit-item="editItem" @delete-item="deleteItem" @open-item="openItem" @close-item="closeItem" @invoice-item="invoiceItem" @no-invoice-item="noInvoiceItem"/>
</template>
<script>
import { FLIGHT_CONTINGENCIES_FIND } from '../../../store/modules/contingency/contingency';
import ContingenciesListTable from './ContingenciesListTable.vue';

export default {
  name: 'contingencies-list-container',
  components: {
    ContingenciesListTable,
  },
  props: {
    isFilterDisplayed: { type: Boolean, required: false, default: false },
  },
  mounted() {
    this.$store.dispatch(FLIGHT_CONTINGENCIES_FIND);
  },
  computed: {
    items() {
      return this.$store.state.contingency.contingencies;
    },
  },
  methods: {
    deleteItem(item) {
      this.$emit('delete-item', item);
    },
    editItem(item) {
      this.$emit('edit-item', item);
    },
    openItem(item) {
      this.$emit('open-item', item);
    },
    closeItem(item) {
      this.$emit('close-item', item);
    },
    invoiceItem(item) {
      this.$emit('invoice-item', item);
    },
    noInvoiceItem(item) {
      this.$emit('no-invoice-item', item);
    },
  },
};
</script>
<style lang="scss">


</style>
