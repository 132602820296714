import { VBadge } from 'vuetify/lib/components/VBadge';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.flightMobile)?_c('div',{staticClass:"mobileView"},[_c('info-mobile-flight-header',{attrs:{"backArrow":_vm.backArrow,"flightMobile":_vm.flightMobile,"filterDate":_vm.flightMobile.flightDate}}),_c('info-mobile-parking-header',{attrs:{"parkingCode":_vm.flightMobile.parkingCode,"gateCode":_vm.flightMobile.gateCode}}),_c('info-mobile-plane-header',{attrs:{"flightMobile":_vm.flightMobile}}),_c(VFlex,[[_c('div',[_c(VLayout,{staticClass:"flex justify-center icons-container",attrs:{"row":"","wrap":""}},[_vm._l((_vm.icons),function(icon){return [(_vm.hasPermissionButton(icon.role))?_c(VFlex,{key:icon.id,class:icon.class,attrs:{"xs12":"","sm6":"","md6":"","lg6":"","xl4":""}},[_c(VCard,{staticClass:"icon-card",on:{"click":icon.action}},[_c(VCardText,{staticClass:"text-center"},[(icon.badge)?_c(VBadge,{attrs:{"color":"red","overlap":""},scopedSlots:_vm._u([{key:"badge",fn:function(){return [_c('span',[_vm._v(_vm._s(icon.badgeValue))])]},proxy:true}],null,true)}):_vm._e(),_c(VIcon,{class:icon.badge ? 'icon-badge' : '',attrs:{"size":"64"}},[_vm._v(_vm._s(icon.icon))]),_c('div',{staticClass:"label"},[_vm._v(_vm._s(icon.label))])],1)],1)],1):_vm._e()]})],2)],1)]],2),_c(VLayout,[(_vm.bottomNav === 'additionalInfo')?_c('div',[_c('mobileAdditionalInfo',{attrs:{"flight-id":_vm.flightId,"filterDate":_vm.flightMobile.flightDate}})],1):_vm._e()])],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }