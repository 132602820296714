<template>
  <v-layout row justify-center>
    <v-dialog v-model="dialog" persistent max-width="50%">
      <v-card :dark="this.$vuetify.dark" :color="this.$vuetify.dark ? 'bggrey_dark' : 'bggrey'">
        <v-toolbar dark color="primary">
          <v-icon v-if="isEnable">not_interested</v-icon>
          <v-icon v-else>power_settings_new</v-icon>
          <v-toolbar-title v-if="!isEnable">{{$t("configuration.disableConfirmation")}}</v-toolbar-title>
          <v-toolbar-title v-else>{{$t("configuration.enableConfirmation")}}</v-toolbar-title>
        </v-toolbar>

        <loading-component />

        <v-card-text>
          {{$t("configuration.followingAlert")}} {{ configEntity.toLowerCase() }} {{isEnable ? this.$i18n.t('configuration.enableAlert') : this.$i18n.t('configuration.disableAlert')}}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn flat color="primary" @click.native="reject()">{{$t("common.cancel")}}</v-btn>
          <v-btn color="error" depressed @click.native="confirm()" :disabled="loading">
            {{isEnable ? this.$i18n.t('configuration.enable') : this.$i18n.t('configuration.disable')}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>
<script>
import LoadingComponent from '@/components/layout/LoadingComponent.vue';

export default {
  name: 'entity-configuration-modal-enable-disable',
  components: {
    LoadingComponent,
  },
  props: {
    dialog: { type: Boolean, required: true },
    isEnable: { type: Boolean, required: true },
    configEntity: { type: String, required: true },
  },
  computed: {
    loading() {
      return this.$store.state.loading;
    },
  },
  methods: {
    reject() {
      this.$emit('enable-disable-reject');
    },
    confirm() {
      this.$emit('enable-disable-confirm');
    },
  },
};
</script>
