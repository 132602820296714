<script>
import Vue from 'vue';

export default Vue.extend({
  mounted() {
    this.$root.$on('saveAll', () => {
      this.save();
    });
  },
  beforeDestroy() {
    this.$root.$off('saveAll');
  },
});
</script>
