import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListTile } from 'vuetify/lib/components/VList';
import { VListTileAvatar } from 'vuetify/lib/components/VList';
import { VListTileContent } from 'vuetify/lib/components/VList';
import { VListTileSubTitle } from 'vuetify/lib/components/VList';
import { VListTileTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VMenu,{attrs:{"max-height":"600px"}},[_c(VBtn,{attrs:{"slot":"activator","icon":""},on:{"click":_vm.getChanges},slot:"activator"},[_c(VIcon,[_vm._v("add_alert")])],1),_c(VCard,[_c(VList,{attrs:{"subheader":""}},[_c(VSubheader,[_vm._v(_vm._s(_vm.$t("rushBaggage.rushBaggageChanges")))]),_c(VTextField,{attrs:{"prepend-icon":"filter_list","clearable":""},on:{"input":_vm.filterChanges},model:{value:(_vm.paramFilter),callback:function ($$v) {_vm.paramFilter=$$v},expression:"paramFilter"}}),_vm._l((_vm.changesFilter),function(change,index){return _c(VListTile,{key:index,attrs:{"avatar":""}},[_c(VListTileAvatar,[_c(VIcon,[_vm._v("local_shipping")])],1),_c(VListTileContent,[_c(VListTileTitle,[_vm._v(_vm._s(change.fileNumber)+" "),_c('small',[_vm._v("["+_vm._s(change.requestTs.format('HH:mm DD/MM/YYYY'))+"]")])]),_c(VListTileSubTitle,[_vm._v(_vm._s(change.diff.path[change.diff.path.length - 1])+": "),_c('small',[_vm._v(_vm._s(change.diff.lhs)+" > "+_vm._s(change.diff.rhs))])])],1)],1)})],2),_c(VDivider)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }