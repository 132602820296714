<template>
  <div>
    <v-select
      :label="label"
      :items="items"
      itemText="name"
      itemValue="value"
      :value="translatedValue()"
      :clearable="false"
      :rules="rules"
      :disabled="disabled"
      @input="emitInput"
      :menu-props="{zIndex:'1000'}"
      :ref="refComp"
    >
      <template
        slot="item"
        slot-scope="{ item }"
      >
        <v-list-tile-content>
          <v-list-tile-title>{{ item.name }}</v-list-tile-title>
        </v-list-tile-content>
      </template>
    </v-select>
  </div>
</template>

<script>
import { ROLE_STATES } from '../../constants/roles';

export default {
  props: {
    value: { required: true },
    label: { type: String },
    charLenght: { type: Number, default: 6 },
    rules: { type: Array },
    disabled: { type: Boolean, default: false },
    refComp: {
      type: String,
      required: false,
      default: 'refComp',
    },
  },
  data() {
    return {
      items: ROLE_STATES.map(a => ({ name: this.$i18n.t(`roles.${a}`), value: a })),
    };
  },
  methods: {
    translatedValue() {
      return ({ name: this.$i18n.t(`roles.${this.value}`), value: this.value });
    },
    emitInput(event) {
      if (typeof (event) !== 'undefined') {
        this.$emit('input', event);
      } else {
        this.$emit('input', null);
      }
    },
  },
};
</script>

<style lang="scss">
.v-autocomplete .v-autocomplete-list {
  /*overflow-y: auto;*/
  z-index: 1500;
}
</style>
