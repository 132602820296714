<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <entity-configuration-list
      :entity-name="this.$i18n.t('configuration.avinetUsers')"
      entity-icon="local_airport"
      :framework-components="frameworkComponents" :column-defs="columnDefs" :items="avinetUsers"
      @confirm-edit-item="editConfirm"
      @confirm-delete-item="deleteConfirm"
    >
      <template v-slot:modal-content="{ itemEdit }">
        <avinet-user-edit-card-content :item="itemEdit" />
      </template>
    </entity-configuration-list>
  </div>
</template>
<script>

import EntityConfigurationList from '@/components/configuration/common/EntityConfigurationList.vue';
import AvinetUserEditCardContent from './AvinetUserEditCardContent.vue';
import AgGridIconCellRenderer from '../../ag-grid/cell-renderers/AgGridIconCellRenderer.vue';
import AgGridValueBaseWithTooltipCellRendererVue from '../../ag-grid/cell-renderers/AgGridValueBaseWithTooltipCellRenderer.vue';
import {
  AVINET_USERS_CREATE,
  AVINET_USERS_DELETE,
  AVINET_USERS_UPDATE,
  GET_AVINET_USERS,
} from '../../../store/modules/avinetUser';


export default {
  name: 'AvinetUserList',
  components: {
    EntityConfigurationList,
    AvinetUserEditCardContent,
  },
  data() {
    const that = this;
    return {
      frameworkComponents: {
        AgGridIconCellRenderer,
      },
      columnDefs: [
        {
          field: 'user',
          headerValueGetter() {
            return that.$i18n.t('configuration.user');
          },
        },
        {
          field: 'tenantName',
          headerValueGetter() {
            return that.$i18n.t('common.airport');
          },
          valueGetter(params) {
            return {
              value: params.data.tenantName,
              tooltip: that.getTooltipTenant(params.data.tenantName),
            };
          },
          cellRendererFramework: AgGridValueBaseWithTooltipCellRendererVue,
        },
        {
          field: 'defaultUser',
          headerValueGetter() {
            return that.$i18n.t('configuration.defaultUser');
          },
          cellRendererFramework: AgGridIconCellRenderer,
          cellRendererParams: {
            icon: 'done',
          },
        },
      ],
    };
  },
  computed: {
    avinetUsers() {
      return this.$store.getters.getAvinetUsers;
    },
    tenants() {
      return this.$store.getters.getTenant;
    },
  },
  methods: {
    getAvinetUsers() {
      this.$store.dispatch(GET_AVINET_USERS);
    },
    deleteConfirm(item) {
      this.$store.dispatch(AVINET_USERS_DELETE, item.id);
    },
    editConfirm(item) {
      this.$store.dispatch(item.id ? AVINET_USERS_UPDATE : AVINET_USERS_CREATE, item);
    },
    getTooltipTenant(tenant) {
      return this.tenants.filter(t => t.name === tenant).map(te => te.description).shift();
    },

  },
  created() {
    this.getAvinetUsers();
  },
};
</script>
