import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,[_c(VExpansionPanel,[_c(VExpansionPanelContent,{staticClass:"exp-header",scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c(VIcon,{attrs:{"color":"white"}},[_vm._v("mdi-menu-down")])]},proxy:true},{key:"header",fn:function(){return [_c('div',[_c('h4',{staticClass:"headline font-weight-light"},[_c(VIcon,{attrs:{"dark":"","left":""}},[_vm._v("info")]),_vm._v(_vm._s(_vm.$t("contingencies.contingencyInfoHandling")))],1)])]},proxy:true}])},[_c('ag-grid-contingency-form-template',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c(VCard,[_c(VCardText,{staticClass:"tow-table"},[_c('ag-grid-vue',{staticClass:"ag-theme-material",staticStyle:{"height":"400px"},attrs:{"columnDefs":_vm.columnDefs,"defaultColDef":_vm.defaultColDef,"gridOptions":_vm.gridOptions,"suppressHorizontalScroll":true,"rowSelection":"multiple","row":"","frameworkComponents":_vm.frameworkComponents,"rowData":_vm.contingencyInformation},on:{"grid-ready":_vm.onGridReady,"selection-changed":_vm.onSelectionChanged}})],1)],1)]},proxy:true},(_vm.hasPermissions(_vm.rolePermission))?{key:"footer",fn:function(){return [_c(VBtn,{attrs:{"color":"secondary","flat":""},on:{"click":function($event){return _vm.addContingencyInformation()}}},[_c(VIcon,[_vm._v("add")])],1),_c(VBtn,{directives:[{name:"show",rawName:"v-show",value:(_vm.contingencyInformationSelected.length > 0),expression:"contingencyInformationSelected.length > 0"}],attrs:{"color":"error","flat":""},on:{"click":function($event){return _vm.removeContingencyInformation()}}},[_c(VIcon,[_vm._v("delete")])],1),_c(VSpacer)]},proxy:true}:null],null,true)})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }