<template>
    <v-card-text class="form">
      <v-container fluid>
        <v-layout row wrap>
          <v-flex xs4 pr-4>
            <v-text-field :label="this.$i18n.t('configuration.name')" v-model="item.name" :rules="[rules.required]" :readonly="!hasPermissions(rolePermission)"/>
          </v-flex>

          <v-flex xs8>
            <v-text-field :label="this.$i18n.t('configuration.description')" v-model="item.description" :readonly="!hasPermissions(rolePermission)"/>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card-text>
</template>

<script>
import RolePermissionsVue from '../../mixins/RolePermissions.vue';

export default {
  name: 'service-agent-edit-card-content',
  mixins: [RolePermissionsVue],
  props: {
    item: { type: Object, required: true },
  },
  components: {
  },
  computed: {
    rolePermission() {
      return `${this.$route.meta.role}_WR`;
    },
  },
  data() {
    return {
      rules: {
        required: value => !!value || this.$i18n.t('common.fieldRequired'),
      },
    };
  },
  methods: {
  },
};
</script>

<style scoped>
  .form {
    overflow-y:hidden;
  }
</style>
