<template>
  <v-layout row justify-center>
    <v-dialog v-model="dialog" persistent max-width="1370px">
      <v-card :dark="this.$vuetify.dark" :color="this.$vuetify.dark ? 'bggrey_dark' : 'bggrey'">
        <v-toolbar dark color="primary">
          <img height="30" :src="require('@/assets/svg-icons/linkflights-w.svg')">
          <v-toolbar-title>{{$t("scheduling.linkFlightsConfirmation")}}</v-toolbar-title>
        </v-toolbar>

        <loading-component />

        <v-card-text v-if="linkItems.length === 0">
          Flights are not linkable because:
          <ul>
            <li v-for="message in linkErrorMessages" :key="message">
              {{message}}
            </li>
          </ul>
        </v-card-text>
        <v-card-text v-if="linkItems.length === 1">
          {{$t("scheduling.linkFlightsAlert")}}
          <connected-flights-base-grid :items="linkItems" @selected-changed="selectedChanged" />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn flat color="primary" @click.native="reject()">{{$t("common.cancel")}}</v-btn>
          <v-btn :disabled="linkItems.length === 0" color="primary" depressed @click.native="confirm()">{{$t("common.confirm")}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>
<script>

import LoadingComponent from '../../layout/LoadingComponent.vue';
import ConnectedFlightsBaseGrid from '../../flights/ConnectedFlightsBaseGrid.vue';

import SchedulingFlightUtils from '../model/schedulingFlightUtils';
import SchedulingLinkOptions from '../model/schedulingLinkOptions';
import ConnectedFlightsIds from '../../shared/model/connectedFlightsIds';

export default {
  name: 'scheduling-link-direct-modal',
  components: {
    LoadingComponent,
    ConnectedFlightsBaseGrid,
  },
  props: ['dialog', 'items'],
  data() {
    return {
      selectedItems: [],
      linkOptions: SchedulingLinkOptions.createDirectLinkOptions(),
    };
  },
  computed: {
    linkItems() {
      return SchedulingFlightUtils.getLinkableFlights(this.items, this.linkOptions);
    },
    linkErrorMessages() {
      return SchedulingFlightUtils.getNotLinkableFlightsValidationMessages(this.items, this.linkOptions);
    },
  },
  methods: {
    selectedChanged(selectedItems) {
      this.selectedItems = selectedItems;
    },
    reject() {
      this.$emit('link-reject');
    },
    confirm() {
      const flightsIds = ConnectedFlightsIds.mapFromConnectedFlightsList(this.selectedItems);
      this.$emit('link-confirm', flightsIds);
    },
  },
};
</script>
<style>
</style>
