
export default class GanttFlightsOptions {
  constructor(unlinkedFlightsDurationMinutes) {
    this.unlinkedFlightsDurationMinutes = unlinkedFlightsDurationMinutes;
  }

  static createDefault() {
    return new GanttFlightsOptions(30);
  }
}
