import SchedulingFilter from '../../scheduling/model/schedulingFilter';
import { TemporalUnit } from '../../ui/temporalUnit/temporalUnit';

export default class FlightWeightingFilter extends SchedulingFilter {
  static get createEmptyFilter() {
    const filter = SchedulingFilter.createEmptyFilter;
    filter.temporalAggregationUnit = TemporalUnit.DAY;
    filter.aggregateByAirline = false;
    filter.aggregateByAircraftTypeCode = false;
    return filter;
  }
}
