<template>
  <div>
    <v-autocomplete
      :placeholder="placeholder"
      :value="value"
      :label="label"
      :items="items"
      :filter="customFilter"
      :clearable="true"
      item-text="code"
      item-value="id"
      :rules="rules"
      :disabled="disabled"
      :ref="refComp"
      @input="$emit('input', $event)"
      @change="onChangeSelection"
    >
      <template
        slot="item"
        slot-scope="{ item, tile }"
      >
        <v-list-tile-content>
          <v-list-tile-title v-text="item.code"></v-list-tile-title>
          <v-list-tile-sub-title v-text="item.name"></v-list-tile-sub-title>
        </v-list-tile-content>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>

import { GET_SERVICE_TYPE } from '../../../store/modules/serviceTypeCatalog';

export default {
  name: 'service-type-select',
  props: {
    value: { required: true },
    label: { type: String },
    placeholder: { type: String, default: '...' },
    charLenght: { type: Number },
    rules: { type: Array },
    disabled: { type: Boolean },
    refComp: {
      type: String,
      required: false,
      default: 'refComp',
    },
    validateConnectedFlight: { type: Boolean, default: false },
    isConnectedFlight: { type: Boolean, default: false },
  },
  computed: {
    items: {
      get() {
        if (this.validateConnectedFlight) {
          return this.isConnectedFlight ? this.$store.getters.getActiveServiceTypesFilteredToConnectedFlights : this.$store.getters.getActiveServiceTypesFilteredToSplitFlights;
        }
        return this.$store.getters.getActiveServiceTypes;
      },
    },
  },
  methods: {
    getServiceTypes() {
      this.$store.dispatch(GET_SERVICE_TYPE);
    },
    customFilter(item, queryText) {
      const textOne = item.code ? item.code.toLowerCase() : '';
      const textTwo = item.name ? item.name.toLowerCase() : '';
      const searchText = queryText.toLowerCase();

      return textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1;
    },
    onChangeSelection(selectionId) {
      const serviceType = this.items.filter(a => a.id === selectionId)[0];
      this.$emit('change', serviceType);
    },
  },
  mounted() {
    this.getServiceTypes();
  },
};
</script>
