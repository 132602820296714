import { VBtn } from 'vuetify/lib/components/VBtn';
import { VList } from 'vuetify/lib/components/VList';
import { VListTile } from 'vuetify/lib/components/VList';
import { VListTileTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VMenu,{scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(VBtn,_vm._g({attrs:{"icon":""}},on),[(_vm.currentLanguageModel.value === 'en')?_c('img',{staticClass:"flag",attrs:{"src":require('@/assets/en.png'),"alt":"English","width":"32","height":"32"}}):_c('img',{staticClass:"flag",attrs:{"src":require('@/assets/es.png'),"alt":"Spanish","width":"32","height":"32"}})])]}}])},[_c(VList,_vm._l((_vm.supportedLanguagesArray),function(item){return _c(VListTile,{key:item.value,on:{"click":function($event){return _vm.refreshTranslation(item.value)}}},[_c(VListTileTitle,{staticClass:"primary--text d-flex align-center"},[_c('img',{staticClass:"flag-list",attrs:{"src":require(`@/assets/${item.value}.png`),"alt":item.text,"width":"24","height":"24"}}),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(item.text))])])],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }