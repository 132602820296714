<template>
  <div>
    <ag-grid-vue
      class="ag-theme-material connected-flights-table operational-table"
      :columnDefs="columnDefs"
      :defaultColDef="defaultColDef"
      :rowData="items"
      :rowSelection="'multiple'"
      :suppressRowClickSelection="true"
      :rowMultiSelectWithClick="false"
      @grid-ready="onGridReady"
      @first-data-rendered="firstDataRendered"
      @selection-changed="onSelectionChanged"
      :frameworkComponents="frameworkComponents"
      :gridOptions="gridOptions"
      :multiSortKey="multiSortKey"
      :overlayNoRowsTemplate="customNoRowsOverlay"
      :overlayLoadingTemplate="customLoadingOverlay"
      v-bind:style="`width: 100%; height: calc(100vh - ${this.sizeFilter});`">
    </ag-grid-vue>
  </div>
</template>
<script>
import
{ AgGridVue } from 'ag-grid-vue';
import AgGridStatusOperationCellRenderer from '../../ag-grid/cell-renderers/AgGridStatusOperationCellRenderer.vue';
import AgGridIconTooltipOperationCellRenderer from '../../ag-grid/cell-renderers/AgGridIconTooltipOperationCellRenderer.vue';
import AgGridRampServiceCellRenderer from '../../ag-grid/cell-renderers/AgGridRampServiceCellRenderer.vue';
import AgGridConnectedFlightsAircraftTypeSelectCellEditor from '../../ag-grid/cell-editors/connectedFlights/AgGridConnectedFlightsAircraftTypeSelectCellEditor.vue';
import AgGridConnectedFlightsAircraftSelectCellEditor from '../../ag-grid/cell-editors/connectedFlights/AgGridConnectedFlightsAircraftSelectCellEditor.vue';
import AgGridConnectedFlightsTimeCellEditor from '../../ag-grid/cell-editors/connectedFlights/AgGridConnectedFlightsTimeCellEditor.vue';
import AgGridConnectedFlightsStringCellEditor from '../../ag-grid/cell-editors/connectedFlights/AgGridConnectedFlightsStringCellEditor.vue';
import AgGridConnectedFlightsDialogLoadCellEditor from '../../ag-grid/cell-editors/connectedFlights/dialog/AgGridConnectedFlightsDialogLoadCellEditor.vue';
import AgGridConnectedFlightsDialogLoadHoldingCellEditor from '../../ag-grid/cell-editors/connectedFlights/dialog/AgGridConnectedFlightsDialogLoadHoldingCellEditor.vue';
import AgGridConnectedFlightsDialogDelayCellEditor from '../../ag-grid/cell-editors/connectedFlights/dialog/AgGridConnectedFlightsDialogDelayCellEditor.vue';
import AgGridConnectedFlightsRemarksCellEditor from '../../ag-grid/cell-editors/connectedFlights/dialog/AgGridConnectedFlightsRemarksCellEditor.vue';
import AgGridConnectedFlightsDialogRampStatusCellEditor from '../../ag-grid/cell-editors/connectedFlights/dialog/AgGridConnectedFlightsDialogRampStatusCellEditor.vue';
import AgGridConnectedFlightsDialogRampServiceCellEditor from '../../ag-grid/cell-editors/connectedFlights/dialog/AgGridConnectedFlightsDialogRampServiceCellEditor.vue';
import AgGridValueWithColorCellRenderer from '../../ag-grid/cell-renderers/AgGridValueWithColorCellRenderer.vue';
import AgGridValueAndSupCellRenderer from '../../ag-grid/cell-renderers/AgGridValueAndSupCellRenderer.vue';
import AgGridValueBaseCellRenderer from '../../ag-grid/cell-renderers/AgGridValueBaseCellRenderer.vue';
import AgGridTimeCellRenderer from '../../ag-grid/cell-renderers/AgGridTimeCellRenderer.vue';
import AgGridConnectedFlights from '../../ag-grid/mixins/AgGridConnectedFlights.vue';
import FlightsDifferences from '../../mixins/FlightsDifferences.vue';

import { FlightBound } from '../../shared/model/flightBound';
import {
  RAMP_CHANGE_FLIGHT_SELECTION, RAMP_FIT_COLUMNS_TO_VIEW,
  RAMP_ON_EXTERNAL_CHANGE, RAMP_SET_CHANGE,
  RAMP_SET_GRID_API,
} from '../../../store/modules/ramp/ramp';
import { cellClassAgGridRules } from '../../ag-grid/mixins/cellClassAgGridRules';
import { WEBSOCKET_CONNECTION } from '../../../store/modules/websocket';
import AgGridCustomService from '../../shared/services/agGridCustomService';
import AgGridValueWithAsteriskCellRenderer from '../../ag-grid/cell-renderers/AgGridValueWithAsteriskCellRenderer.vue';
import AgGridConnectedFlightsAirportParkingSelectCellEditor from '../../ag-grid/cell-editors/connectedFlights/AgGridConnectedFlightsAirportParkingSelectCellEditor.vue';
import AgGridConnectedFlightsAirportGateSelectCellEditor from '../../ag-grid/cell-editors/connectedFlights/AgGridConnectedFlightsAirportGateSelectCellEditor.vue';
import AgGridConnectedFlightsAirportBeltSelectCellEditor from '../../ag-grid/cell-editors/connectedFlights/AgGridConnectedFlightsAirportBeltSelectCellEditor.vue';
import AgGridConnectedFlightsAirportCarouselSelectCellEditor from '../../ag-grid/cell-editors/connectedFlights/AgGridConnectedFlightsAirportCarouselSelectCellEditor.vue';
import agGridTimeFormatter from '../../ag-grid/formatters/agGridTimeFormatter';
import { FisModule } from '../../ag-grid/cell-editors/model/fisModule';
import { ColDefRampConfiguration } from '../model/colDefRampConfiguration';
import agGridTextCustomFilterComparator from '../../ag-grid/filters/agGridTextCustomFilterComparator';
import agGridTextCustomFilterFormatter from '../../ag-grid/filters/agGridTextCustomFilterFormatter';
import AgGridConnectedFlightsRampCrewCellEditor
from '../../ag-grid/cell-editors/connectedFlights/dialog/AgGridConnectedFlightsRampCrewCellEditor.vue';
import AgGridMultilineStringsCellRenderer from '../../ag-grid/cell-renderers/AgGridMultilineStringsCellRenderer.vue';
import AgGridConnectedFlightsDialogPassageCellEditor
from '../../ag-grid/cell-editors/connectedFlights/dialog/AgGridConnectedFlightsDialogPassageCellEditor.vue';
import RolePermissions from '../../mixins/RolePermissions.vue';
import AgGridSpecialBaggageCellRenderer from '../../ag-grid/cell-renderers/AgGridSpecialBaggageCellRenderer.vue';
import AgGridConnectedFlightsToggleCellEditor
from '../../ag-grid/cell-editors/connectedFlights/AgGridConnectedFlightsToggleCellEditor.vue';
import AgGridConnectedFlightsDialogFlightStatusIntegrationCellEditor from '../../ag-grid/cell-editors/connectedFlights/dialog/AgGridConnectedFlightsDialogFlightStatusIntegrationCellEditor.vue';
import AgGridFlightStatusCellRenderer from '../../ag-grid/cell-renderers/AgGridFlightStatusCellRenderer.vue';
import AgGridConnectedFlightsDialogMessageCellEditor
from '../../ag-grid/cell-editors/connectedFlights/dialog/AgGridConnectedFlightsDialogMessageCellEditor.vue';
import AgGridValueWithAsteriskAndSupCellRenderer from '../../ag-grid/cell-renderers/AgGridValueWithAsteriskAndSupCellRenderer.vue';
import AgGridConnectedFlightsFlightNumberServiceTypeCellEditor from '../../ag-grid/cell-editors/connectedFlights/AgGridConnectedFlightsFlightNumberServiceTypeCellEditor.vue';
import AgGridConnectedFlightsAirportSLABaggageDeliverySelectCellEditor from '../../ag-grid/cell-editors/connectedFlights/AgGridConnectedFlightsAirportSLABaggageDeliverySelectCellEditor.vue';
import AgGridValueAndSupFieldPossibleBlockedCellRenderer from '../../ag-grid/cell-renderers/AgGridValueAndSupFieldPossibleBlockedCellRenderer.vue';
import AgGridValueAndIconAndSupFieldPossibleBlockedCellRenderer from '../../ag-grid/cell-renderers/AgGridValueAndIconAndSupFieldPossibleBlockedCellRenderer.vue';
import AgGridNoRowsOverlay from '../../ag-grid/mixins/AgGridNoRowsOverlay.vue';
import OperationsUtils from '../../operations/OperationsUtils';

export default {
  name: 'ramp-flight-list-table',
  mixins: [AgGridConnectedFlights, FlightsDifferences, RolePermissions, AgGridNoRowsOverlay],
  components: {
    AgGridVue,
  },
  props: {
    items: { type: Array, required: true },
    isFilterDisplayed: { type: Boolean, required: false, default: false },
  },
  mounted() {
    this.$store.dispatch(WEBSOCKET_CONNECTION, this.$store.state.tenant.currentTenant.name.toLowerCase());
  },
  computed: {
    sizeFilter() {
      return this.isFilterDisplayed ? '250px' : '180px';
    },
    fitColumnsOnResize() {
      return this.$store.state.ramp.fitColumnsOnResize;
    },
    routeName() {
      return this.$route.name;
    },
    dateFilter() {
      return this.$store.state.ramp.filter.dateFrom;
    },
  },
  watch: {
    routeName: {
      handler(newValue) {
        if (newValue !== 'rampBuses') {
          this.$store.state.ramp.filter.onlyRemoteParkings = false;
        }
        this.setColumnVisibleOfView();
      },
    },
  },
  data() {
    const that = this;
    const rolePermission = this.getRoles();
    return {
      dialog: false,
      scheduledTimeRequired: true,
      isRowSelectable(rowNode) {
        if (that.selectedRowsFlights.length > 0 && that.selectedRowsAircraftGround.length === 0) {
          return !that.isAircraftGround(rowNode.data);
        }
        if (that.selectedRowsFlights.length === 0 && that.selectedRowsAircraftGround.length > 0) {
          return that.isAircraftGround(rowNode.data);
        }
        return true;
      },
      gridOptions: {
        onFilterChanged: this.uncheckAllOnFilter,
        getRowNodeId: this.getRowNodeId,
        getRowClass: this.getRowClass,
        floatingFilter: true,
        groupHeaderHeight: 35,
        headerHeight: 35,
        floatingFiltersHeight: 20,
        rowHeight: 30,
        context: {
          module: FisModule.RAMP,
        },
      },
      multiSortKey: 'ctrl',
      frameworkComponents: {
        AgGridConnectedFlightsAircraftSelectCellEditor,
        AgGridConnectedFlightsAircraftTypeSelectCellEditor,
        AgGridConnectedFlightsAirportBeltSelectCellEditor,
        AgGridConnectedFlightsAirportCarouselSelectCellEditor,
        AgGridConnectedFlightsAirportGateSelectCellEditor,
        AgGridConnectedFlightsAirportParkingSelectCellEditor,
        AgGridConnectedFlightsTimeCellEditor,
        AgGridConnectedFlightsDialogDelayCellEditor,
        AgGridConnectedFlightsDialogLoadCellEditor,
        AgGridConnectedFlightsDialogLoadHoldingCellEditor,
        AgGridConnectedFlightsStringCellEditor,
        AgGridConnectedFlightsDialogRampServiceCellEditor,
        AgGridConnectedFlightsDialogRampStatusCellEditor,
        AgGridConnectedFlightsRemarksCellEditor,
        AgGridConnectedFlightsRampCrewCellEditor,
        AgGridMultilineStringsCellRenderer,
        AgGridConnectedFlightsDialogPassageCellEditor,
        AgGridConnectedFlightsToggleCellEditor,
        AgGridConnectedFlightsDialogFlightStatusIntegrationCellEditor,
        AgGridConnectedFlightsDialogMessageCellEditor,
        AgGridConnectedFlightsFlightNumberServiceTypeCellEditor,
        AgGridConnectedFlightsAirportSLABaggageDeliverySelectCellEditor,
      },
      defaultColDef: {
        resizable: true,
        sortable: true,
        minWidth: 30,
        suppressMenu: true,
        filter: 'agTextColumnFilter',
        filterParams: {
          textCustomComparator: agGridTextCustomFilterComparator,
          textFormatter: agGridTextCustomFilterFormatter,
        },
        colCustomConfig: {
          flightBound: FlightBound.ANY,
        },
        cellClassRules: cellClassAgGridRules,
        editable: false,
      },
      columnDefs: [
        {
          headerName: '',
          children: [
            {
              headerTooltip: 'Check/uncheck all',
              hide: !this.hasPermissions(rolePermission),
              checkboxSelection: true,
              width: 21,
              minWidth: 20,
              headerCheckboxSelection: true,
              headerCheckboxSelectionFilteredOnly: true,
              suppressMenu: true,
              sortable: false,
              suppressMovable: true,
              suppressSizeToFit: true,
              filter: false,
              resizable: false,
              colCustomConfig: {
                exportCsvDisabled: true,
              },
              editable: this.hasPermissions(rolePermission),
            },
            {
              headerName: 'SortDate',
              field: 'sortingTime',
              hide: true,
              sort: 'asc',
            },
          ],
        },
        {
          headerValueGetter() {
            return that.$i18n.t('ramp.aircraftInformation');
          },
          headerTooltip: 'Aircraft Information',
          children: [
            {
              colId: 'anybound.rampExtended.file',
              field: 'anybound.rampExtended.file',
              headerValueGetter() {
                return that.$i18n.t('ramp.file');
              },
              width: 40,
              minWidth: 40,
              cellClass: 'aircraft-type-cell separator',
              comparator: this.objectWithValueComparator,
              colCustomConfig: {
                flightBound: FlightBound.ANY,
                fieldsRelated: ['inbound.rampExtended.file', 'outbound.rampExtended.file'],
              },
              valueGetter(params) {
                return params.data.getChildPropertyValue(FlightBound.ANY, ['rampExtended', 'file']);
              },
              cellRendererFramework: AgGridValueBaseCellRenderer,
            },
            {
              colId: 'anybound.common.aircraftRegisterNumber',
              field: 'anybound.common.aircraftRegisterNumber',
              headerValueGetter() {
                return that.$i18n.t('ramp.aircraftRegistration');
              },
              headerTooltip: 'Aircraft registration',
              width: 70,
              cellEditor: 'AgGridConnectedFlightsAircraftSelectCellEditor',
              colCustomConfig: {
                fieldPaths: ['common.aircraftId', 'common.aircraftTypeId'],
                flightBound: FlightBound.ANY,
                fieldsRelated: ['inbound.common.aircraftRegisterNumber', 'outbound.common.aircraftRegisterNumber',
                  'inbound.common.aircraftAcars', 'outbound.common.aircraftAcars',
                  'inbound.blockedFieldsIntegration.manualOverwriteAircraft', 'outbound.blockedFieldsIntegration.manualOverwriteAircraft'],
                isAircraftGround(connectedFlights) {
                  return that.isAircraftGround(connectedFlights);
                },
              },
              cellClass: 'aircraft-cell separator',
              comparator: this.objectWithValueComparator,
              valueGetter(params) {
                return {
                  value: params.data.getChildPropertyValue(FlightBound.ANY, ['common', 'aircraftRegisterNumber']),
                  sup: params.data.getChildPropertyValue(FlightBound.ANY, ['common', 'aircraftAcars']) ? 'A' : null,
                  fieldBlockedIntegration: params.data.getChildPropertyValue(FlightBound.ANY, ['blockedFieldsIntegration', 'manualOverwriteAircraft']),
                };
              },
              cellRendererFramework: AgGridValueAndSupFieldPossibleBlockedCellRenderer,
            },
            {
              colId: 'anybound.flight.aircraftTypeCode',
              field: 'anybound.flight.aircraftTypeCode',
              headerValueGetter() {
                return that.$i18n.t('ramp.aircraftType');
              },
              headerTooltip: 'Aircraft type',
              width: 60,
              editable: this.hasPermissions(rolePermission) && this.isNotAircraftGround,
              cellClass: 'aircraft-type-cell',
              comparator: this.objectWithValueComparator,
              cellEditor: 'AgGridConnectedFlightsAircraftTypeSelectCellEditor',
              colCustomConfig: {
                fieldPaths: ['common.aircraftId', 'common.aircraftTypeId'],
                flightBound: FlightBound.ANY,
                fieldsRelated: ['inbound.common.aircraftTypeIataCode', 'inbound.common.aircraftTypeIcaoCode',
                  'outbound.common.aircraftTypeIataCode', 'outbound.common.aircraftTypeIcaoCode',
                  'inbound.common.aircraftCargo', 'outbound.common.aircraftCargo',
                  'inbound.blockedFieldsIntegration.manualOverwriteAircraftType', 'blockedFieldsIntegration.common.manualOverwriteAircraftType'],
              },
              valueGetter(params) {
                return {
                  value: params.data.getChildPropertyValue(FlightBound.ANY, ['common', 'aircraftTypeCode']),
                  sup: params.data.getChildPropertyValue(FlightBound.ANY, ['common', 'aircraftCargo']) ? 'P' : null,
                  tooltip: params.data.getChildPropertyValue(FlightBound.ANY, ['common', 'aircraftTypeComments']),
                  fieldBlockedIntegration: params.data.getChildPropertyValue(FlightBound.ANY, ['blockedFieldsIntegration', 'manualOverwriteAircraftType']),
                };
              },
              cellRendererFramework: AgGridValueAndSupFieldPossibleBlockedCellRenderer,
            },
            {
              colId: 'anybound.ramp.externalAPU',
              field: 'anybound.ramp.externalAPU',
              headerValueGetter() {
                return 'APU';
              },
              headerTooltip: 'APU',
              width: 30,
              editable: this.hasPermissions(rolePermission) && this.isNotAircraftGround,
              cellClass: 'text-center',
              comparator: this.objectWithValueComparator,
              valueGetter(params) {
                const externalAPU = params.data.getChildPropertyValue(FlightBound.ANY, ['ramp', 'externalAPU']) != null && params.data.getChildPropertyValue(FlightBound.ANY, ['ramp', 'externalAPU']) !== ''
                  ? params.data.getChildPropertyValue(FlightBound.ANY, ['ramp', 'externalAPU']) : params.data.getChildPropertyValue(FlightBound.ANY, ['ramp', 'externalAPUAircraft']);

                return {
                  value: externalAPU,
                  color: externalAPU ? 'primary' : 'grey',
                  icon: externalAPU ? 'battery_alert' : 'battery_full',
                };
              },
              colCustomConfig: {
                fieldPaths: ['ramp.externalAPU'],
                flightBound: FlightBound.ANY,
                fieldsRelated: ['inbound.ramp.externalAPU', 'outbound.ramp.externalAPU'],
              },
              cellRendererFramework: AgGridIconTooltipOperationCellRenderer,
              cellEditor: 'AgGridConnectedFlightsToggleCellEditor',
            },
          ],
        },
        {
          headerValueGetter() {
            return that.$i18n.t('ramp.arrivalFlight');
          },
          children: [
            {
              colId: 'inbound.common.flightNumber',
              field: 'inbound.common.flightNumber',
              headerValueGetter() {
                return that.getTextResizable(that.$i18n.t('ramp.flightNumberShort'), that.$i18n.t('ramp.flightNumber'));
              },
              width: 91,
              cellClass: 'flight-number-cell separator',
              comparator: this.objectWithValueComparator,
              editable: this.hasPermissions(rolePermission) && this.isInboundAndNotAircraftGround,
              cellEditor: 'AgGridConnectedFlightsFlightNumberServiceTypeCellEditor',
              colCustomConfig: {
                fieldPaths: ['common.serviceTypeId'],
                flightBound: FlightBound.INBOUND,
                fieldsRelated: ['inbound.common.flightNumber', 'inbound.common.flagAirlineOperation'],
                editedOnlyServiceType() {
                  return true;
                },
                isAircraftGround(connectedFlights) {
                  return that.isAircraftGround(connectedFlights);
                },
              },
              valueGetter(params) {
                if (that.isAircraftGround(params)) {
                  return {
                    value: params.data.getChildPropertyValue(FlightBound.INBOUND, ['common', 'airlineCode']),
                  };
                }
                return {
                  value: params.data.getChildPropertyValue(FlightBound.INBOUND, ['common', 'flightNumber']),
                  asterisk: params.data.getChildPropertyValue(FlightBound.INBOUND, ['common', 'flagAirlineOperation']),
                  tooltip: params.data.getChildPropertyValue(FlightBound.INBOUND, ['common', 'airlineOperationMessage']),
                  sup: params.data.getChildPropertyValue(FlightBound.INBOUND, ['common', 'serviceTypeCode']),
                };
              },
              cellRendererFramework: AgGridValueWithAsteriskAndSupCellRenderer,
            },
            {
              colId: 'inbound.common.airport1Code',
              field: 'inbound.common.airport1Code',
              headerValueGetter() {
                return that.$i18n.t('ramp.airportOfDeparture');
              },
              headerTooltip: 'Airport of departure',
              width: 40,
              cellClass: 'airport-from-cell',
              comparator: this.objectWithValueComparator,
              cellEditor: 'agGridFromCellEditor',
              colCustomConfig: {
                flightBound: FlightBound.INBOUND,
                fieldsRelated: ['inbound.common.airport1Code', 'inbound.common.hasSeveralAirports'],
              },
              valueGetter(params) {
                return {
                  value: params.data.getChildPropertyValue(FlightBound.INBOUND, ['common', 'airport1Code']),
                  asterisk: params.data.getChildPropertyValue(FlightBound.INBOUND, ['common', 'hasSeveralAirports']),
                  tooltip: params.data.getChildPropertyValue(FlightBound.INBOUND, ['common', 'hasSeveralAirports']) ? params.data.getChildPropertyValue(FlightBound.INBOUND, ['common', 'airportsIata']) : undefined,
                };
              },
              cellRendererFramework: AgGridValueWithAsteriskCellRenderer,
            },
            {
              colId: 'inbound.common.scheduledTime',
              field: 'inbound.common.scheduledTime',
              headerValueGetter() {
                return that.$i18n.t('ramp.scheduledTimeArrival');
              },
              headerTooltip: 'Scheduled Time of Arrival',
              width: 35,
              minWidth: 35,
              cellClass: 'airport-sta-cell',
              comparator: this.objectWithValueComparator,
              cellEditor: 'AgGridConnectedFlightsTimeCellEditor',
              colCustomConfig: {
                fieldPaths: ['common.scheduledTime'],
                flightBound: FlightBound.INBOUND,
                fieldsRelated: ['inbound.common.scheduledTime'],
                oldValueFormatter: agGridTimeFormatter,
              },
              valueGetter(params) {
                return that.agGridTimeGetter(params.data.getChildPropertyValue(FlightBound.INBOUND, ['common', 'scheduledTime']), that.dateFilter, null, null, that.scheduledTimeRequired);
              },
              cellRendererFramework: AgGridTimeCellRenderer,
            },
            {
              colId: 'inbound.common.pkn',
              field: 'inbound.common.pkn',
              headerValueGetter() {
                return that.$i18n.t('operations.parking');
              },
              headerTooltip: 'Parking',
              width: 50,
              minWidth: 50,
              editable: this.hasPermissions(rolePermission) && this.isInboundAndNotAircraftGround,
              cellClass: 'aircraft-config-cell',
              comparator: this.objectWithValueComparator,
              cellEditor: 'AgGridConnectedFlightsAirportParkingSelectCellEditor',
              colCustomConfig: {
                fieldPaths: ['common.parkingId'],
                flightBound: FlightBound.INBOUND,
                fieldsRelated: ['inbound.common.parkingCode', 'inbound.common.parkingRemote', 'inbound.common.parkingPushback',
                  'inbound.common.parkingInop400Hz', 'inbound.blockedFieldsIntegration.manualOverwriteParking'],
              },
              flight: FlightBound.INBOUND,
              valueGetter(params) {
                let superindice = params.data.getChildPropertyValue(FlightBound.INBOUND, ['common', 'parkingRemote']) ? 'R' : '';
                superindice += params.data.getChildPropertyValue(FlightBound.INBOUND, ['common', 'parkingPushback']) ? 'P' : '';
                return {
                  value: params.data.getChildPropertyValue(FlightBound.INBOUND, ['common', 'parkingCode']),
                  sup: superindice,
                  fieldBlockedIntegration: params.data.getChildPropertyValue(FlightBound.INBOUND, ['blockedFieldsIntegration', 'manualOverwriteParking']),
                  icon: params.data.getChildPropertyValue(FlightBound.INBOUND, ['common', 'parkingInop400Hz']) ? 'flash_off' : null,
                  colorIcon: params.data.getChildPropertyValue(FlightBound.INBOUND, ['common', 'parkingInop400Hz']) ? 'red' : null,
                };
              },
              cellRendererFramework: AgGridValueAndIconAndSupFieldPossibleBlockedCellRenderer,
            },
            {
              colId: 'inbound.times.bestTime',
              field: 'inbound.times.bestTime',
              headerValueGetter() {
                return that.$i18n.t('ramp.bestTime');
              },
              headerTooltip: 'Best known time',
              width: 50,
              cellClass: 'airport-best-cell',
              comparator: this.objectWithValueComparator,
              colCustomConfig: {
                flightBound: FlightBound.INBOUND,
                fieldsRelated: ['inbound.times.bestTime'],
              },
              valueGetter(params) {
                return that.bestTimeGetter(
                  params.data.getChildPropertyValue(FlightBound.INBOUND, ['times', 'bestTime']),
                  params.data.getChildPropertyValue(FlightBound.INBOUND, ['common', 'scheduledTime']),
                  that.dateFilter,
                  params.data.getChildPropertyValue(FlightBound.INBOUND, ['common', 'status']),
                );
              },
              cellRendererFramework: AgGridTimeCellRenderer,
            },
            {
              colId: 'inbound.integration.flightStatusIntegration',
              field: 'inbound.integration.flightStatusIntegration',
              headerValueGetter() {
                return that.getTextResizable(that.$i18n.t('operations.airportStatusShort'), that.$i18n.t('operations.airportStatus'));
              },
              headerTooltip: (() => this.$i18n.t('operations.airportStatus'))(),
              width: 52,
              minWidth: 52,
              editable: this.isInboundAndNotAircraftGround,
              cellEditor: 'AgGridConnectedFlightsDialogFlightStatusIntegrationCellEditor',
              comparator: this.objectWithValueComparator,
              cellClass: 'airport-status-cell',
              colCustomConfig: {
                flightBound: FlightBound.INBOUND,
                fieldsRelated: ['inbound.integration.flightStatusIntegration'],
              },
              flight: FlightBound.INBOUND,
              valueGetter(params) {
                return {
                  value: params.data.getChildPropertyValue(FlightBound.INBOUND, ['integration', 'statusIntegration']),
                  tooltip: params.data.getChildPropertyValue(FlightBound.INBOUND, ['integration', 'statusIntegration']) != null ? that.$i18n.t(`flightStatusIntegration.${params.data.getChildPropertyValue(FlightBound.INBOUND, ['integration', 'statusIntegration'])}`) : null,
                };
              },
              cellRendererFramework: AgGridFlightStatusCellRenderer,
            },
            {
              colId: 'inbound.passageSummary.pax',
              field: 'inbound.passageSummary.pax',
              headerValueGetter() {
                return that.$i18n.t('ramp.flightPassengers');
              },
              headerTooltip: 'Flight passengers',
              width: 40,
              minWidth: 40,
              editable: this.isInboundAndNotAircraftGround,
              cellEditor: 'AgGridConnectedFlightsDialogPassageCellEditor',
              cellClass: 'airport-pax-cell',
              comparator: this.objectWithValueComparator,
              colCustomConfig: {
                flightBound: FlightBound.INBOUND,
                fieldsRelated: ['inbound.passageSummary.passengersNumber', 'inbound.passageSummary.passengersChildNumber', 'inbound.passageSummary.passengersSpecial'],
              },
              valueGetter(params) {
                const isRealNumber = params.data.getChildPropertyValue(FlightBound.INBOUND, ['passageSummary', 'passengersNumber']) > 0 || params.data.getChildPropertyValue(FlightBound.INBOUND, ['passageSummary', 'passengersChildNumber']) > 0;
                let text = params.data.getChildPropertyValue(FlightBound.INBOUND, ['passageSummary', 'passengersNumber']) == null ? '' : params.data.getChildPropertyValue(FlightBound.INBOUND, ['passageSummary', 'passengersNumber']);
                let tooltip = '';
                let styleContent = {};
                text += params.data.getChildPropertyValue(FlightBound.INBOUND, ['passageSummary', 'passengersChildNumber']) == null ? '' : `+${params.data.getChildPropertyValue(FlightBound.INBOUND, ['passageSummary', 'passengersChildNumber'])}`;
                if ((params.data.getChildPropertyValue(FlightBound.INBOUND, ['passageSummary', 'passengersEstimated']) > 0) && !isRealNumber) {
                  text = params.data.getChildPropertyValue(FlightBound.INBOUND, ['passageSummary', 'passengersEstimated']);
                  styleContent = { color: 'darkslategray', 'font-weight': 'bold', 'font-style': 'italic' };
                }
                // when handling type is P, the total of passengers must be (P)
                if (params.data.getChildPropertyValue(FlightBound.INBOUND, ['common', 'handlingTypeCode']) === 'P') {
                  text = '(P)';
                  tooltip = that.$i18n.t('operations.emptyFlight');
                }
                return {
                  value: text,
                  asterisk: params.data.getChildPropertyValue(FlightBound.INBOUND, ['passageSummary', 'passengersSpecial']),
                  styleObject: {
                    asteriskColor: 'red',
                    content: styleContent,
                  },
                  tooltip,
                };
              },
              cellRendererFramework: AgGridValueWithAsteriskCellRenderer,
            },
            {
              colId: 'inbound.operationExtended.loadHoldingWeightCell',
              field: 'inbound.operationExtended.loadHoldingWeightCell',
              headerValueGetter() {
                return that.$i18n.t('ramp.flightLoad');
              },
              headerTooltip: 'Flight load',
              width: 55,
              editable: this.isInboundAndNotAircraftGround,
              cellEditor: 'AgGridConnectedFlightsDialogLoadHoldingCellEditor',
              cellClass: 'airport-pax-cell',
              comparator: this.objectWithValueComparator,
              colCustomConfig: {
                flightBound: FlightBound.INBOUND,
                fieldsRelated: ['inbound.operationExtended.load', 'inbound.operationExtended.loadUnit', 'inbound.operationExtended.loadSpecial'],
              },
              valueGetter(params) {
                return OperationsUtils.getValueLoadHolding(params, FlightBound.INBOUND);
              },
              cellRendererFramework: AgGridValueWithAsteriskCellRenderer,
            },
            {
              colId: 'inbound.operationExtended.cargo',
              field: 'inbound.operationExtended.cargo',
              headerValueGetter() {
                return that.$i18n.t('ramp.cgo');
              },
              width: 55,
              editable: this.isInboundAndNotAircraftGround,
              cellEditor: 'AgGridConnectedFlightsDialogLoadCellEditor',
              cellClass: 'airport-pax-cell',
              comparator: this.objectWithValueComparator,
              colCustomConfig: {
                flightBound: FlightBound.INBOUND,
                fieldsRelated: ['inbound.operationExtended.loadCargo', 'inbound.operationExtended.loadUnit'],
              },
              valueGetter(params) {
                let text = params.data.getChildPropertyValue(FlightBound.INBOUND, ['operationExtended', 'loadCargo']);
                let unit = params.data.getChildPropertyValue(FlightBound.INBOUND, ['operationExtended', 'loadUnit']);
                unit = unit === 'POUNDS' ? 'lbs' : 'kg';
                text = text ? `${text}${unit}` : '';
                return {
                  value: text,
                };
              },
              cellRendererFramework: AgGridValueWithAsteriskCellRenderer,
            },
            {
              colId: 'inbound.common.gat',
              field: 'inbound.common.gat',
              headerValueGetter() {
                return that.$i18n.t('ramp.gate');
              },
              headerTooltip: 'Gate',
              width: 25,
              minWidth: 25,
              editable: this.hasPermissions(rolePermission) && this.isInboundAndNotAircraftGround,
              cellClass: 'aircraft-config-cell',
              comparator: this.objectWithValueComparator,
              cellEditor: 'AgGridConnectedFlightsAirportGateSelectCellEditor',
              colCustomConfig: {
                fieldPaths: ['common.gateId'],
                flightBound: FlightBound.INBOUND,
                fieldsRelated: ['inbound.common.gateCode', 'inbound.common.gateRemote'],
              },
              valueGetter(params) {
                return {
                  value: params.data.getChildPropertyValue(FlightBound.INBOUND, ['common', 'gateCode']),
                  sup: params.data.getChildPropertyValue(FlightBound.INBOUND, ['common', 'gateRemote']) ? 'R' : null,
                };
              },
              cellRendererFramework: AgGridValueAndSupCellRenderer,
            },
            {
              colId: 'inbound.common.belt',
              field: 'inbound.common.belt',
              headerValueGetter() {
                return that.$i18n.t('ramp.belt');
              },
              width: 50,
              editable: this.hasPermissions(rolePermission) && this.isInboundAndNotAircraftGround,
              cellClass: 'aircraft-config-cell',
              comparator: this.objectWithValueComparator,
              cellEditor: 'AgGridConnectedFlightsAirportBeltSelectCellEditor',
              colCustomConfig: {
                fieldPaths: ['common.beltId'],
                flightBound: FlightBound.INBOUND,
                fieldsRelated: ['inbound.common.beltId', 'inbound.common.beltCode', 'inbound.blockedFieldsIntegration.manualOverwriteBelt'],
              },
              valueGetter(params) {
                return {
                  value: params.data.getChildPropertyValue(FlightBound.INBOUND, ['common', 'beltCode']),
                  fieldBlockedIntegration: params.data.getChildPropertyValue(FlightBound.INBOUND, ['blockedFieldsIntegration', 'manualOverwriteBelt']),
                };
              },
              cellRendererFramework: AgGridValueAndSupFieldPossibleBlockedCellRenderer,
            },
            {
              colId: 'inbound.ramp.specialBaggage',
              field: 'inbound.ramp.specialBaggage',
              headerValueGetter() {
                return that.$i18n.t('ramp.specialBaggage');
              },
              width: 30,
              minWidth: 30,
              cellEditor: 'AgGridConnectedFlightsStringCellEditor',
              cellClass: 'aircraft-type-cell',
              comparator: this.objectWithValueComparator,
              colCustomConfig: {
                fieldPaths: ['ramp.specialBaggage'],
                flightBound: FlightBound.INBOUND,
                fieldsRelated: ['inbound.ramp.specialBaggage'],
              },
              valueGetter(params) {
                return {
                  avih: params.data.getChildPropertyValue(FlightBound.INBOUND, ['operationExtended', 'loadAVIH']) > 0,
                  hum: params.data.getChildPropertyValue(FlightBound.INBOUND, ['operationExtended', 'loadHUM']) > 0,
                  pex: params.data.getChildPropertyValue(FlightBound.INBOUND, ['ramp', 'loadPEX']),
                };
              },
              cellRendererFramework: AgGridSpecialBaggageCellRenderer,
            },
            {
              colId: 'inbound.remarks.rampRemarks',
              field: 'inbound.remarks.rampRemarks',
              headerValueGetter() {
                return that.$i18n.t('ramp.remRamp');
              },
              width: 50,
              editable: this.hasPermissions(rolePermission) && this.isInboundAndNotAircraftGround,
              cellEditor: 'AgGridConnectedFlightsStringCellEditor',
              cellClass: 'aircraft-type-cell',
              comparator: this.objectWithValueComparator,
              colCustomConfig: {
                fieldPaths: ['remarks.rampRemarks'],
                flightBound: FlightBound.INBOUND,
                fieldsRelated: ['inbound.remarks.rampRemarks'],
              },
              valueGetter(params) {
                return params.data.getChildPropertyValue(FlightBound.INBOUND, ['remarks', 'rampRemarks']);
              },
              cellRendererFramework: AgGridValueBaseCellRenderer,
            },
            {
              colId: 'inbound.ramp.rem',
              field: 'inbound.ramp.rem',
              headerValueGetter() {
                return that.$i18n.t('ramp.remarks');
              },
              headerTooltip: 'Remarks',
              width: 18,
              minWidth: 18,
              editable: this.hasPermissions(rolePermission) && this.isInboundAndNotAircraftGround,
              cellEditor: 'AgGridConnectedFlightsRemarksCellEditor',
              cellClass: 'ag-grid-tooltip-cell',
              comparator: this.objectWithValueComparator,
              colCustomConfig: {
                flightBound: FlightBound.INBOUND,
                fieldsRelated: ['inbound.ramp.remarksStatus'],
              },
              valueGetter(params) {
                const remarkValue = params.data.getChildPropertyValue(FlightBound.INBOUND, ['ramp', 'remarksStatus']);
                const missingPaxAlert = params.data.getChildPropertyValue(FlightBound.INBOUND, ['remarks', 'missingPaxAlert']);
                return that.checkBoundAndSetRemTooltipObject(remarkValue, missingPaxAlert);
              },
              cellRendererFramework: AgGridIconTooltipOperationCellRenderer,
            },
            {
              colId: 'inbound.extended.msg',
              field: 'inbound.extended.msg',
              headerValueGetter() {
                return that.$i18n.t('operations.messages');
              },
              headerTooltip: 'Messages',
              width: 18,
              minWidth: 18,
              editable: this.hasPermissions(rolePermission) && this.isInboundAndNotAircraftGround,
              cellEditor: 'AgGridConnectedFlightsDialogMessageCellEditor',
              cellClass: 'ag-grid-popover-cell',
              comparator: this.objectWithValueComparator,
              colCustomConfig: {
                flightBound: FlightBound.INBOUND,
                fieldsRelated: ['inbound.extended.messageStatus'],
              },
              flight: FlightBound.INBOUND,
              valueGetter(params) {
                const messageValue = params.data.getChildPropertyValue(FlightBound.INBOUND, ['extended', 'messageStatus']);
                return that.checkBoundAndSetMsgTooltipObject(messageValue);
              },
              cellRendererFramework: AgGridIconTooltipOperationCellRenderer,
            },
            {
              colId: 'inbound.ramp.firstBaggageTime',
              field: 'inbound.ramp.firstBaggageTime',
              headerValueGetter() {
                return that.$i18n.t('ramp.oneBag');
              },
              width: 35,
              minWidth: 35,
              editable: this.hasPermissions(rolePermission) && this.isInboundAndNotAircraftGround,
              cellClass: 'airport-sta-cell',
              comparator: this.objectWithValueComparator,
              cellEditor: 'AgGridConnectedFlightsTimeCellEditor',
              colCustomConfig: {
                fieldPaths: ['ramp.firstBaggageTime'],
                flightBound: FlightBound.INBOUND,
                fieldsRelated: ['inbound.ramp.firstBaggageTime'],
                oldValueFormatter: agGridTimeFormatter,
              },
              valueGetter(params) {
                return that.agGridTimeGetter(params.data.getChildPropertyValue(FlightBound.INBOUND, ['ramp', 'firstBaggageTime']), that.dateFilter, null, params.data.getChildPropertyValue(FlightBound.INBOUND, ['ramp', 'lastBaggageTime']));
              },
              cellRendererFramework: AgGridTimeCellRenderer,
            },
            {
              colId: 'inbound.ramp.lastBaggageTime',
              field: 'inbound.ramp.lastBaggageTime',
              headerValueGetter() {
                return that.$i18n.t('ramp.lBag');
              },
              width: 35,
              minWidth: 35,
              editable: this.hasPermissions(rolePermission) && this.isInboundAndNotAircraftGround,
              cellClass: 'airport-sta-cell',
              comparator: this.objectWithValueComparator,
              cellEditor: 'AgGridConnectedFlightsTimeCellEditor',
              colCustomConfig: {
                fieldPaths: ['ramp.lastBaggageTime'],
                flightBound: FlightBound.INBOUND,
                fieldsRelated: ['inbound.ramp.lastBaggageTime'],
                oldValueFormatter: agGridTimeFormatter,
              },
              valueGetter(params) {
                return that.agGridTimeGetter(params.data.getChildPropertyValue(FlightBound.INBOUND, ['ramp', 'lastBaggageTime']), that.dateFilter, params.data.getChildPropertyValue(FlightBound.INBOUND, ['ramp', 'firstBaggageTime']), null);
              },
              cellRendererFramework: AgGridTimeCellRenderer,
            },
            {
              colId: 'inbound.ramp.baggageTime',
              field: 'inbound.ramp.baggageTime',
              headerValueGetter() {
                return that.$i18n.t('ramp.baggageTime');
              },
              width: 35,
              minWidth: 35,
              cellClass: 'airport-sta-cell',
              comparator: this.objectWithValueComparator,
              colCustomConfig: {
                flightBound: FlightBound.INBOUND,
                fieldsRelated: ['inbound.ramp.firstBaggageTime', 'inbound.ramp.lastBaggageTime'],
                oldValueFormatter: agGridTimeFormatter,
              },
              valueGetter(params) {
                const lastBaggageTime = params.data.getChildPropertyValue(FlightBound.INBOUND, ['ramp', 'lastBaggageTime']);
                const firstBaggageTime = params.data.getChildPropertyValue(FlightBound.INBOUND, ['ramp', 'firstBaggageTime']);
                let value;
                if (lastBaggageTime && firstBaggageTime) {
                  value = that.$moment(lastBaggageTime).diff(that.$moment(firstBaggageTime), 'minutes');
                }

                return { value };
              },
              cellRendererFramework: AgGridValueWithColorCellRenderer,
            },
            {
              // Q1
              colId: 'inbound.slaBaggageDelivery.firstSLABaggageDelivery',
              field: 'inbound.slaBaggageDelivery.firstSLABaggageDelivery',
              headerValueGetter() {
                return that.$i18n.t('ramp.firstSLABaggageDelivery');
              },
              headerTooltip: (() => this.$i18n.t('ramp.firstSLABaggageDelivery'))(),
              width: 40,
              cellClass: 'aircraft-config-cell',
              comparator: this.objectWithValueComparator,
              editable: this.hasPermissions(rolePermission) && this.isInboundAndNotAircraftGround,
              cellEditor: 'AgGridConnectedFlightsAirportSLABaggageDeliverySelectCellEditor',
              colCustomConfig: {
                fieldPaths: ['slaBaggageDelivery.firstSLABaggageDeliveryId'],
                fieldEditor: 'firstSLABaggageDelivery',
                flightBound: FlightBound.INBOUND,
                fieldsRelated: ['inbound.slaBaggageDelivery.firstSLABaggageDeliveryType', 'inbound.slaBaggageDelivery.firstSLABaggageDeliveryCode'],
              },
              valueSetter(params) {
                return params.data.getChildPropertyValue(FlightBound.INBOUND, ['slaBaggageDelivery', 'firstSLABaggageDeliveryId']);
              },
              valueGetter(params) {
                return params.data.getChildPropertyValue(FlightBound.INBOUND, ['slaBaggageDelivery', 'firstSLABaggageDeliveryCode']);
              },
              cellRendererFramework: AgGridValueBaseCellRenderer,
            },
            {
              // QL
              colId: 'inbound.slaBaggageDelivery.lastSLABaggageDelivery',
              field: 'inbound.slaBaggageDelivery.lastSLABaggageDeliveryId',
              headerValueGetter() {
                return that.$i18n.t('ramp.lastSLABaggageDelivery');
              },
              headerTooltip: (() => this.$i18n.t('ramp.lastSLABaggageDelivery'))(),
              width: 40,
              cellClass: 'aircraft-config-cell',
              comparator: this.objectWithValueComparator,
              editable: this.hasPermissions(rolePermission) && this.isInboundAndNotAircraftGround,
              cellEditor: 'AgGridConnectedFlightsAirportSLABaggageDeliverySelectCellEditor',
              colCustomConfig: {
                fieldPaths: ['slaBaggageDelivery.lastSLABaggageDeliveryId'],
                fieldEditor: 'lastSLABaggageDelivery',
                flightBound: FlightBound.INBOUND,
                fieldsRelated: ['inbound.slaBaggageDelivery.lastSLABaggageDeliveryType', 'inbound.slaBaggageDelivery.lastSLABaggageDeliveryCode'],
              },
              valueSetter(params) {
                return params.data.getChildPropertyValue(FlightBound.INBOUND, ['slaBaggageDelivery', 'lastSLABaggageDeliveryId']);
              },
              valueGetter(params) {
                return params.data.getChildPropertyValue(FlightBound.INBOUND, ['slaBaggageDelivery', 'lastSLABaggageDeliveryCode']);
              },
              cellRendererFramework: AgGridValueBaseCellRenderer,
            },
            {
              // QP1
              colId: 'inbound.slaBaggageDelivery.prioritySLABaggageDelivery',
              field: 'inbound.slaBaggageDelivery.prioritySLABaggageDelivery',
              headerValueGetter() {
                return that.$i18n.t('ramp.prioritySLABaggageDelivery');
              },
              headerTooltip: (() => this.$i18n.t('ramp.prioritySLABaggageDelivery'))(),
              width: 40,
              cellClass: 'aircraft-config-cell',
              comparator: this.objectWithValueComparator,
              editable: this.hasPermissions(rolePermission) && this.isInboundAndNotAircraftGround,
              cellEditor: 'AgGridConnectedFlightsAirportSLABaggageDeliverySelectCellEditor',
              colCustomConfig: {
                fieldPaths: ['slaBaggageDelivery.prioritySLABaggageDeliveryId'],
                fieldEditor: 'prioritySLABaggageDelivery',
                flightBound: FlightBound.INBOUND,
                fieldsRelated: ['inbound.slaBaggageDelivery.prioritySLABaggageDeliveryType', 'inbound.slaBaggageDelivery.prioritySLABaggageDeliveryCode'],
              },
              valueSetter(params) {
                return params.data.getChildPropertyValue(FlightBound.INBOUND, ['slaBaggageDelivery', 'prioritySLABaggageDeliveryId']);
              },
              valueGetter(params) {
                return params.data.getChildPropertyValue(FlightBound.INBOUND, ['slaBaggageDelivery', 'prioritySLABaggageDeliveryCode']);
              },
              cellRendererFramework: AgGridValueBaseCellRenderer,
            },
            {
              // QP2
              colId: 'inbound.slaBaggageDelivery.subPrioritySLABaggageDelivery',
              field: 'inbound.slaBaggageDelivery.subPrioritySLABaggageDelivery',
              headerValueGetter() {
                return that.$i18n.t('ramp.subPrioritySLABaggageDelivery');
              },
              headerTooltip: (() => this.$i18n.t('ramp.subPrioritySLABaggageDelivery'))(),
              width: 40,
              cellClass: 'aircraft-config-cell',
              comparator: this.objectWithValueComparator,
              editable: this.hasPermissions(rolePermission) && this.isInboundAndNotAircraftGround,
              cellEditor: 'AgGridConnectedFlightsAirportSLABaggageDeliverySelectCellEditor',
              colCustomConfig: {
                fieldPaths: ['slaBaggageDelivery.subPrioritySLABaggageDeliveryId'],
                fieldEditor: 'subPrioritySLABaggageDelivery',
                flightBound: FlightBound.INBOUND,
                fieldsRelated: ['inbound.slaBaggageDelivery.subPrioritySLABaggageDeliveryType', 'inbound.slaBaggageDelivery.subPrioritySLABaggageDeliveryCode'],
              },
              valueGetter(params) {
                return params.data.getChildPropertyValue(FlightBound.INBOUND, ['slaBaggageDelivery', 'subPrioritySLABaggageDeliveryCode']);
              },
              cellRendererFramework: AgGridValueBaseCellRenderer,
            },
            {
              colId: 'inbound.rampExtended.buses',
              field: 'inbound.rampExtended.buses',
              headerValueGetter() {
                return that.$i18n.t('ramp.extendedBus');
              },
              width: 45,
              editable: this.hasPermissions(rolePermission) && this.isInboundAndNotAircraftGround,
              cellEditor: 'AgGridConnectedFlightsStringCellEditor',
              cellClass: 'aircraft-type-cell',
              comparator: this.objectWithValueComparator,
              colCustomConfig: {
                fieldPaths: ['rampExtended.buses'],
                flightBound: FlightBound.INBOUND,
                fieldsRelated: ['inbound.rampExtended.buses'],
              },
              valueGetter(params) {
                return params.data.getChildPropertyValue(FlightBound.INBOUND, ['rampExtended', 'buses']);
              },
              cellRendererFramework: AgGridValueBaseCellRenderer,
            },
            {
              colId: 'inbound.ramp.crew',
              field: 'inbound.ramp.crew',
              headerValueGetter() {
                return that.$i18n.t('ramp.crew');
              },
              width: 45,
              editable: this.hasPermissions(rolePermission) && this.isInboundAndNotAircraftGround,
              cellEditor: 'AgGridConnectedFlightsRampCrewCellEditor',
              cellClass: 'aircraft-type-cell',
              comparator: this.objectWithValueComparator,
              colCustomConfig: {
                flightBound: FlightBound.INBOUND,
                fieldsRelated: ['inbound.ramp.crewChanged'],
              },
              valueGetter(params) {
                const crewValue = params.data.getChildPropertyValue(FlightBound.INBOUND, ['ramp', 'crewChanged']);
                return that.checkBoundAndSetCrewTooltipObject(crewValue);
              },
              cellRendererFramework: AgGridIconTooltipOperationCellRenderer,
            },
            {
              colId: 'inbound.ramp.busesEsc',
              field: 'inbound.ramp.busesEsc',
              headerValueGetter() {
                return that.$i18n.t('ramp.busesEsc');
              },
              width: 45,
              editable: this.hasPermissions(rolePermission) && this.isInboundAndNotAircraftGround,
              cellEditor: 'AgGridConnectedFlightsTimeCellEditor',
              cellClass: 'aircraft-type-cell',
              comparator: this.objectWithValueComparator,
              colCustomConfig: {
                fieldPaths: ['ramp.busesEsc'],
                flightBound: FlightBound.INBOUND,
                fieldsRelated: ['inbound.ramp.busesEsc'],
                oldValueFormatter: agGridTimeFormatter,
              },
              valueGetter(params) {
                return that.agGridTimeGetter(params.data.getChildPropertyValue(FlightBound.INBOUND, ['ramp', 'busesEsc']), that.dateFilter);
              },
              cellRendererFramework: AgGridTimeCellRenderer,
            },
          ],
        },
        {
          headerName: '',
          children: [
            {
              colId: 'anybound.ramp.loadMaster',
              field: 'anybound.ramp.loadMaster',
              headerValueGetter() {
                return that.$i18n.t('ramp.loadMaster');
              },
              width: 50,
              editable: this.hasPermissions(rolePermission) && this.isNotAircraftGround,
              cellEditor: 'AgGridConnectedFlightsStringCellEditor',
              cellClass: 'aircraft-type-cell separator',
              comparator: this.objectWithValueComparator,
              colCustomConfig: {
                fieldPaths: ['ramp.loadMaster'],
                flightBound: FlightBound.ANY,
                fieldsRelated: ['inbound.ramp.loadMaster', 'outbound.ramp.loadMaster'],
              },
              valueGetter(params) {
                return params.data.getChildPropertyValue(FlightBound.ANY, ['ramp', 'loadMaster']);
              },
              cellRendererFramework: AgGridValueBaseCellRenderer,
            },
          ],
        },
        {
          headerValueGetter() {
            return that.$i18n.t('ramp.departureFlight');
          },
          children: [
            {
              colId: 'outbound.common.flightNumber',
              field: 'outbound.common.flightNumber',
              headerValueGetter() {
                return that.getTextResizable(that.$i18n.t('ramp.flightNumberShort'), that.$i18n.t('ramp.flightNumber'));
              },
              headerTooltip: 'Flight number',
              width: 91,
              cellClass: 'flight-number-cell separator',
              comparator: this.objectWithValueComparator,
              editable: this.hasPermissions(rolePermission) && this.isOutboundAndNotAircraftGround,
              cellEditor: 'AgGridConnectedFlightsFlightNumberServiceTypeCellEditor',
              colCustomConfig: {
                fieldPaths: ['common.serviceTypeId'],
                flightBound: FlightBound.OUTBOUND,
                fieldsRelated: ['outbound.common.flightNumber', 'outbound.common.flagAirlineOperation'],
                editedOnlyServiceType() {
                  return true;
                },
              },
              valueGetter(params) {
                return {
                  value: params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'flightNumber']),
                  asterisk: params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'flagAirlineOperation']),
                  tooltip: params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'airlineOperationMessage']),
                  sup: params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'serviceTypeCode']),
                };
              },
              cellRendererFramework: AgGridValueWithAsteriskAndSupCellRenderer,
            },
            {
              colId: 'outbound.common.airport1Code',
              field: 'outbound.common.airport1Code',
              headerValueGetter() {
                return that.$i18n.t('ramp.airportOfDestination');
              },
              headerTooltip: 'Airport of destination',
              width: 50,
              cellClass: 'airport-from-cell',
              comparator: this.objectWithValueComparator,
              cellEditor: 'agGridFromCellEditor',
              colCustomConfig: {
                flightBound: FlightBound.OUTBOUND,
                fieldsRelated: ['outbound.common.airport1Code', 'outbound.common.hasSeveralAirports'],
              },
              valueGetter(params) {
                return {
                  value: params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'airport1Code']),
                  asterisk: params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'hasSeveralAirports']),
                  tooltip: params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'hasSeveralAirports']) ? params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'airportsIata']) : undefined,
                };
              },
              cellRendererFramework: AgGridValueWithAsteriskCellRenderer,
            },
            {
              colId: 'outbound.common.scheduledTime',
              field: 'outbound.common.scheduledTime',
              headerValueGetter() {
                return that.$i18n.t('ramp.scheduledTimeOfDeparture');
              },
              headerTooltip: 'Scheduled Time of Departure',
              width: 35,
              minWidth: 35,
              cellClass: 'airport-sta-cell',
              comparator: this.objectWithValueComparator,
              cellEditor: 'AgGridConnectedFlightsTimeCellEditor',
              colCustomConfig: {
                fieldPaths: ['common.scheduledTime'],
                flightBound: FlightBound.OUTBOUND,
                fieldsRelated: ['outbound.common.scheduledTime'],
              },
              valueGetter(params) {
                return that.agGridTimeGetter(params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'scheduledTime']), that.dateFilter, null, null, that.scheduledTimeRequired);
              },
              valueFormatter: agGridTimeFormatter,
              cellRendererFramework: AgGridTimeCellRenderer,
            },
            {
              colId: 'outbound.common.pkn',
              field: 'outbound.common.pkn',
              headerValueGetter() {
                return that.$i18n.t('operations.parking');
              },
              headerTooltip: 'Parking',
              width: 50,
              minWidth: 50,
              editable: this.isOutboundAndNotAircraftGround,
              cellClass: 'aircraft-config-cell',
              comparator: this.objectWithValueComparator,
              cellEditor: 'AgGridConnectedFlightsAirportParkingSelectCellEditor',
              colCustomConfig: {
                fieldPaths: ['common.parkingId'],
                flightBound: FlightBound.OUTBOUND,
                fieldsRelated: ['outbound.common.parkingCode', 'outbound.common.parkingRemote', 'outbound.common.parkingPushback', 'outbound.common.parkingInop400Hz', 'outbound.blockedFieldsIntegration.manualOverwriteParking'],
              },
              flight: FlightBound.OUTBOUND,
              valueGetter(params) {
                let superindice = params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'parkingRemote']) ? 'R' : '';
                superindice += params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'parkingPushback']) ? 'P' : '';
                return {
                  value: params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'parkingCode']),
                  sup: superindice,
                  fieldBlockedIntegration: params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['blockedFieldsIntegration', 'manualOverwriteParking']),
                  icon: params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'parkingInop400Hz']) ? 'flash_off' : null,
                  colorIcon: params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'parkingInop400Hz']) ? 'red' : null,
                };
              },
              cellRendererFramework: AgGridValueAndIconAndSupFieldPossibleBlockedCellRenderer,
            },
            {
              colId: 'outbound.times.bestTime',
              field: 'outbound.times.bestTime',
              headerValueGetter() {
                return that.$i18n.t('ramp.bestTime');
              },
              headerTooltip: 'Best known time',
              width: 50,
              cellClass: 'airport-best-cell',
              comparator: this.objectWithValueComparator,
              colCustomConfig: {
                flightBound: FlightBound.OUTBOUND,
                fieldsRelated: ['outbound.times.bestTime'],
              },
              valueGetter(params) {
                return that.bestTimeGetter(
                  params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['times', 'bestTime']),
                  params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'scheduledTime']),
                  that.dateFilter,
                  params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'status']),
                );
              },
              cellRendererFramework: AgGridTimeCellRenderer,
            },
            {
              colId: 'outbound.integration.flightStatusIntegration',
              field: 'outbound.integration.flightStatusIntegration',
              headerValueGetter() {
                return that.getTextResizable(that.$i18n.t('operations.airportStatusShort'), that.$i18n.t('operations.airportStatus'));
              },
              headerTooltip: (() => this.$i18n.t('operations.airportStatus'))(),
              width: 52,
              minWidth: 52,
              editable: this.hasPermissions(rolePermission) && this.isOutboundAndNotAircraftGround,
              cellEditor: 'AgGridConnectedFlightsDialogFlightStatusIntegrationCellEditor',
              comparator: this.objectWithValueComparator,
              cellClass: 'airport-status-cell',
              colCustomConfig: {
                flightBound: FlightBound.OUTBOUND,
                fieldsRelated: ['outbound.integration.flightStatusIntegration'],
              },
              flight: FlightBound.OUTBOUND,
              valueGetter(params) {
                return {
                  value: params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['integration', 'statusIntegration']),
                  tooltip: params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['integration', 'statusIntegration']) != null ? that.$i18n.t(`flightStatusIntegration.${params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['integration', 'statusIntegration'])}`) : null,
                };
              },
              cellRendererFramework: AgGridFlightStatusCellRenderer,
            },
            {
              colId: 'outbound.passageOutbound.checkInStartTime',
              field: 'outbound.passageOutbound.checkInStartTime',
              headerValueGetter() {
                return that.$i18n.t('operations.passengerTimesModal.checkInStart');
              },
              headerTooltip: this.$i18n.t('passage.real'),
              width: 60,
              cellEditor: 'AgGridConnectedFlightsTimeCellEditor',
              colCustomConfig: {
                flightBound: FlightBound.OUTBOUND,
                fieldsRelated: ['outbound.passageOutbound.checkInExpectedStartTime'],
              },
              valueGetter(params) {
                return params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['passageOutbound', 'checkInStartTime'])
                  ? that.agGridTimeGetter(params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['passageOutbound', 'checkInStartTime']), params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'scheduledDate']))
                  : that.agGridTimeGetter(params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['passageOutbound', 'checkInExpectedStartTime']), params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'scheduledTime']));
              },
              cellRendererFramework: AgGridTimeCellRenderer,
            },
            {
              colId: 'outbound.passageOutbound.checkInEndTime',
              field: 'outbound.passageOutbound.checkInEndTime',
              headerValueGetter() {
                return that.$i18n.t('operations.passengerTimesModal.checkInEnd');
              },
              headerTooltip: this.$i18n.t('passage.real'),
              width: 60,
              cellEditor: 'AgGridConnectedFlightsTimeCellEditor',
              colCustomConfig: {
                flightBound: FlightBound.OUTBOUND,
                fieldsRelated: ['outbound.passageOutbound.checkInExpectedEndTime'],
              },
              valueGetter(params) {
                return params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['passageOutbound', 'checkInEndTime'])
                  ? that.agGridTimeGetter(params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['passageOutbound', 'checkInEndTime']), params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'scheduledDate']))
                  : that.agGridTimeGetter(params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['passageOutbound', 'checkInExpectedEndTime']), params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'scheduledTime']));
              },
              cellRendererFramework: AgGridTimeCellRenderer,
            },
            {
              colId: 'outbound.passageSummary.totalPax',
              field: 'outbound.passageSummary.totalPax',
              headerValueGetter() {
                return that.$i18n.t('ramp.totalPax');
              },
              width: 50,
              editable: this.isOutboundAndNotAircraftGround,
              cellEditor: 'AgGridConnectedFlightsDialogPassageCellEditor',
              colCustomConfig: {
                flightBound: FlightBound.OUTBOUND,
                fieldsRelated: ['outbound.passageSummary.passengersNumber', 'outbound.passageSummary.passengersChildNumber', 'outbound.passageSummary.passengersSpecial'],
              },
              valueGetter(params) {
                const isRealNumber = params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['passageSummary', 'passengersNumber']) > 0 || params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['passageSummary', 'passengersChildNumber']) > 0;
                let text = params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['passageSummary', 'passengersNumber']) == null ? '' : params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['passageSummary', 'passengersNumber']);
                let tooltip = '';
                let styleContent = {};
                text += params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['passageSummary', 'passengersChildNumber']) == null ? '' : `+${params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['passageSummary', 'passengersChildNumber'])}`;
                if ((params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['passageSummary', 'passengersEstimated']) > 0) && !isRealNumber) {
                  text = params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['passageSummary', 'passengersEstimated']);
                  styleContent = { color: 'darkslategray', 'font-weight': 'bold', 'font-style': 'italic' };
                }
                // when handling type is P, the total of passengers must be (P)
                if (params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'handlingTypeCode']) === 'P') {
                  text = '(P)';
                  tooltip = that.$i18n.t('operations.emptyFlight');
                }
                return {
                  value: text,
                  asterisk: params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['passageSummary', 'passengersSpecial']),
                  styleObject: {
                    asteriskColor: 'red',
                    content: styleContent,
                  },
                  tooltip,
                };
              },
              cellRendererFramework: AgGridValueWithAsteriskCellRenderer,
            },
            {
              colId: 'outbound.operationExtended.loadHoldingWeightCell',
              field: 'outbound.operationExtended.loadHoldingWeightCell',
              headerValueGetter() {
                return that.$i18n.t('ramp.flightLoad');
              },
              headerTooltip: 'Flight load',
              width: 50,
              editable: this.isOutboundAndNotAircraftGround,
              cellEditor: 'AgGridConnectedFlightsDialogLoadHoldingCellEditor',
              cellClass: 'airport-pax-cell',
              comparator: this.objectWithValueComparator,
              colCustomConfig: {
                flightBound: FlightBound.OUTBOUND,
                fieldsRelated: ['outbound.operationExtended.load', 'outbound.operationExtended.loadUnit', 'outbound.operationExtended.loadSpecial'],
              },
              valueGetter(params) {
                return OperationsUtils.getValueLoadHolding(params, FlightBound.OUTBOUND);
              },
              cellRendererFramework: AgGridValueWithAsteriskCellRenderer,
            },
            {
              colId: 'outbound.operationExtended.cargo',
              field: 'outbound.operationExtended.cargo',
              headerValueGetter() {
                return that.$i18n.t('ramp.cgo');
              },
              width: 50,
              editable: this.isOutboundAndNotAircraftGround,
              cellEditor: 'AgGridConnectedFlightsDialogLoadCellEditor',
              cellClass: 'airport-pax-cell',
              comparator: this.objectWithValueComparator,
              colCustomConfig: {
                flightBound: FlightBound.OUTBOUND,
                fieldsRelated: ['outbound.operationExtended.loadCargo', 'outbound.operationExtended.loadUnit'],
              },
              valueGetter(params) {
                let text = params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['operationExtended', 'loadCargo']);
                let unit = params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['operationExtended', 'loadUnit']);
                unit = unit === 'POUNDS' ? 'lbs' : 'kg';
                text = text ? `${text}${unit}` : '';
                return {
                  value: text,
                };
              },
              cellRendererFramework: AgGridValueWithAsteriskCellRenderer,
            },
            {
              colId: 'outbound.rampExtended.rushBaggage',
              field: 'outbound.rampExtended.rushBaggage',
              headerValueGetter() {
                return that.$i18n.t('ramp.baggageRush');
              },
              width: 30,
              minWidth: 30,
              cellEditor: 'AgGridConnectedFlightsStringCellEditor',
              cellClass: 'aircraft-type-cell',
              comparator: this.objectWithValueComparator,
              colCustomConfig: {
                fieldPaths: ['rampExtended.rushBaggage'],
                flightBound: FlightBound.OUTBOUND,
                fieldsRelated: ['outbound.rampExtended.rushBaggage'],
              },
              valueGetter(params) {
                return params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['rampExtended', 'rushBaggage']);
              },
              cellRendererFramework: AgGridValueBaseCellRenderer,
            },
            {
              colId: 'outbound.rampExtended.carousel',
              field: 'outbound.rampExtended.carousel',
              headerValueGetter() {
                return that.$i18n.t('ramp.carousel');
              },
              width: 45,
              editable: this.hasPermissions(rolePermission) && this.isOutboundAndNotAircraftGround,
              cellEditor: 'AgGridConnectedFlightsAirportCarouselSelectCellEditor',
              cellClass: 'aircraft-type-cell',
              comparator: this.objectWithValueComparator,
              colCustomConfig: {
                fieldPaths: ['rampExtended.carouselId'],
                flightBound: FlightBound.OUTBOUND,
                fieldsRelated: ['outbound.rampExtended.carouselCode'],
              },
              valueGetter(params) {
                return params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['rampExtended', 'carouselCode']);
              },
              cellRendererFramework: AgGridValueBaseCellRenderer,
            },
            {
              colId: 'outbound.ramp.specialBaggage',
              field: 'outbound.ramp.specialBaggage',
              headerValueGetter() {
                return that.$i18n.t('ramp.specialBaggage');
              },
              width: 50,
              cellEditor: 'AgGridConnectedFlightsStringCellEditor',
              cellClass: 'aircraft-type-cell',
              comparator: this.objectWithValueComparator,
              colCustomConfig: {
                flightBound: FlightBound.OUTBOUND,
                fieldsRelated: ['outbound.ramp.specialBaggage'],
              },
              valueGetter(params) {
                return {
                  avih: params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['operationExtended', 'loadAVIH']) > 0,
                  hum: params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['operationExtended', 'loadHUM']) > 0,
                  pex: params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['ramp', 'loadPEX']),
                };
              },
              cellRendererFramework: AgGridSpecialBaggageCellRenderer,
            },
            {
              colId: 'outbound.remarks.rampRemarks',
              field: 'outbound.remarks.rampRemarks',
              headerValueGetter() {
                return that.$i18n.t('ramp.remRamp');
              },
              width: 50,
              editable: this.hasPermissions(rolePermission) && this.isOutboundAndNotAircraftGround,
              cellEditor: 'AgGridConnectedFlightsStringCellEditor',
              cellClass: 'aircraft-type-cell',
              comparator: this.objectWithValueComparator,
              colCustomConfig: {
                fieldPaths: ['remarks.rampRemarks'],
                flightBound: FlightBound.OUTBOUND,
                fieldsRelated: ['outbound.remarks.rampRemarks'],
              },
              valueGetter(params) {
                return params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['remarks', 'rampRemarks']);
              },
              cellRendererFramework: AgGridValueBaseCellRenderer,
            },
            {
              colId: 'outbound.ramp.rem',

              headerValueGetter() {
                return that.$i18n.t('ramp.remarks');
              },
              headerTooltip: 'Remarks',
              width: 18,
              minWidth: 18,
              editable: this.hasPermissions(rolePermission) && this.isOutboundAndNotAircraftGround,
              cellEditor: 'AgGridConnectedFlightsRemarksCellEditor',
              cellClass: 'ag-grid-tooltip-cell',
              comparator: this.objectWithValueComparator,
              colCustomConfig: {
                flightBound: FlightBound.OUTBOUND,
                fieldsRelated: ['outbound.ramp.remarksStatus'],
              },
              valueGetter(params) {
                const remarkValue = params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['ramp', 'remarksStatus']);
                const missingPaxAlert = params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['remarks', 'missingPaxAlert']);
                return that.checkBoundAndSetRemTooltipObject(remarkValue, missingPaxAlert);
              },
              cellRendererFramework: AgGridIconTooltipOperationCellRenderer,
            },
            {
              colId: 'outbound.extended.msg',
              field: 'outbound.extended.msg',
              headerValueGetter() {
                return that.$i18n.t('operations.messages');
              },
              headerTooltip: 'Messages',
              width: 18,
              minWidth: 18,
              editable: this.isOutboundAndNotAircraftGround,
              cellEditor: 'AgGridConnectedFlightsDialogMessageCellEditor',
              cellClass: 'ag-grid-popover-cell',
              comparator: this.objectWithValueComparator,
              colCustomConfig: {
                flightBound: FlightBound.OUTBOUND,
                fieldsRelated: ['outbound.extended.messageStatus'],
              },
              flight: FlightBound.OUTBOUND,
              valueGetter(params) {
                const messageValue = params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['extended', 'messageStatus']);
                return that.checkBoundAndSetMsgTooltipObject(messageValue);
              },
              cellRendererFramework: AgGridIconTooltipOperationCellRenderer,
            },
            {
              colId: 'outbound.common.gat',
              field: 'outbound.common.gat',
              headerValueGetter() {
                return that.$i18n.t('ramp.gate');
              },
              headerTooltip: 'Gate',
              width: 50,
              editable: this.hasPermissions(rolePermission) && this.isOutboundAndNotAircraftGround,
              cellClass: 'aircraft-config-cell',
              comparator: this.objectWithValueComparator,
              cellEditor: 'AgGridConnectedFlightsAirportGateSelectCellEditor',
              colCustomConfig: {
                fieldPaths: ['common.gateId'],
                flightBound: FlightBound.OUTBOUND,
                fieldsRelated: ['outbound.common.gateCode', 'outbound.common.gateRemote'],
              },
              valueGetter(params) {
                return {
                  value: params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'gateCode']),
                  sup: params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'gateRemote']) ? 'R' : null,
                };
              },
              cellRendererFramework: AgGridValueAndSupCellRenderer,
            },
            {
              colId: 'outbound.times.scheduledBoardingTime',
              field: 'outbound.times.scheduledBoardingTime',
              headerValueGetter() {
                return that.$i18n.t('ramp.scheduledBoardingTime');
              },
              headerTooltip: 'Scheduled boarding time',
              width: 35,
              minWidth: 35,
              editable: this.isRampBusesView && this.hasPermissions(rolePermission) && this.isOutboundAndNotAircraftGround,
              cellClass: 'airport-sta-cell',
              comparator: this.objectWithValueComparator,
              cellEditor: 'AgGridConnectedFlightsTimeCellEditor',
              colCustomConfig: {
                fieldPaths: ['times.scheduledBoardingTime'],
                flightBound: FlightBound.OUTBOUND,
                fieldsRelated: ['outbound.times.scheduledBoardingTime'],
              },
              valueGetter(params) {
                return that.agGridTimeGetter(params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['times', 'scheduledBoardingTime']), that.dateFilter);
              },
              valueFormatter: agGridTimeFormatter,
              cellRendererFramework: AgGridTimeCellRenderer,
            },
            {
              colId: 'outbound.times.completedBoardingTime',
              field: 'outbound.times.completedBoardingTime',
              headerValueGetter() {
                return that.$i18n.t('ramp.completedBoardingTime');
              },
              width: 50,
              editable: this.isRampBusesView && this.hasPermissions(rolePermission) && this.isOutboundAndNotAircraftGround,
              colCustomConfig: {
                fieldPaths: ['times.completedBoardingTime'],
                flightBound: FlightBound.OUTBOUND,
                fieldsRelated: ['outbound.times.completedBoardingTime'],
              },
              cellEditor: 'AgGridConnectedFlightsTimeCellEditor',
              cellClass: 'aircraft-type-cell',
              comparator: this.objectWithValueComparator,
              valueFormatter: agGridTimeFormatter,
              valueGetter(params) {
                return that.agGridTimeGetter(params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['times', 'completedBoardingTime']), that.dateFilter);
              },
              cellRendererFramework: AgGridTimeCellRenderer,
            },
            {
              colId: 'outbound.rampExtended.buses',
              field: 'outbound.rampExtended.buses',
              headerValueGetter() {
                return that.$i18n.t('ramp.extendedBus');
              },
              width: 45,
              editable: this.hasPermissions(rolePermission) && this.isOutboundAndNotAircraftGround,
              cellEditor: 'AgGridConnectedFlightsStringCellEditor',
              cellClass: 'aircraft-type-cell',
              comparator: this.objectWithValueComparator,
              colCustomConfig: {
                fieldPaths: ['rampExtended.buses'],
                flightBound: FlightBound.OUTBOUND,
                fieldsRelated: ['outbound.rampExtended.buses'],
              },
              valueGetter(params) {
                return params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['rampExtended', 'buses']);
              },
              cellRendererFramework: AgGridValueBaseCellRenderer,
            },
            {
              colId: 'outbound.ramp.crew',

              headerValueGetter() {
                return that.$i18n.t('ramp.crew');
              },
              width: 45,
              editable: this.hasPermissions(rolePermission) && this.isOutboundAndNotAircraftGround,
              cellEditor: 'AgGridConnectedFlightsRampCrewCellEditor',
              cellClass: 'aircraft-type-cell',
              comparator: this.objectWithValueComparator,
              colCustomConfig: {
                flightBound: FlightBound.OUTBOUND,
                fieldsRelated: ['outbound.ramp.crewChanged'],
              },
              valueGetter(params) {
                const crewValue = params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['ramp', 'crewChanged']);
                return that.checkBoundAndSetCrewTooltipObject(crewValue);
              },
              cellRendererFramework: AgGridIconTooltipOperationCellRenderer,
            },
            {
              colId: 'outbound.extended.delay',
              field: 'outbound.extended.delay',
              headerValueGetter() {
                return that.getTextResizable(that.$i18n.t('operations.flightDelayShort'), that.$i18n.t('operations.flightDelay'));
              },
              width: 50,
              editable: this.isOutboundAndNotAircraftGround,
              cellEditor: 'AgGridConnectedFlightsDialogDelayCellEditor',
              cellClass: 'ag-grid-tooltip-cell',
              comparator: this.objectWithValueComparator,
              colCustomConfig: {
                flightBound: FlightBound.OUTBOUND,
                fieldsRelated: ['outbound.extended.delay'],
                valueGetterExportCsv(params) {
                  return that.getInformationDelay(params, FlightBound.OUTBOUND);
                },
              },
              valueGetter(params) {
                const delayValue = params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['extended', 'delay']);
                return {
                  icon: delayValue ? 'chevron_right' : '',
                  color: delayValue ? 'bggreydark' : '',
                  tooltip: delayValue ? that.getInformationDelay(params, FlightBound.OUTBOUND) : 'There are not delays',
                };
              },
              cellRendererFramework: AgGridIconTooltipOperationCellRenderer,
            },
          ],
        },
        {
          headerValueGetter() {
            return that.$i18n.t('ramp.ramp');
          },
          children: [
            {
              colId: 'services.gpu',
              field: 'services.gpu',
              headerValueGetter() {
                return that.$i18n.t('ramp.serviceGpu');
              },
              width: 35,
              minWidth: 35,
              editable(params) {
                return !!params.data.getChildPropertyValue(FlightBound.ANY, ['services', 'serviceGPURequested']);
              },
              cellEditor: 'AgGridConnectedFlightsDialogRampServiceCellEditor',
              cellClass: 'ag-grid-popover-cell separator',
              comparator: this.objectWithValueComparator,
              colCustomConfig: {
                flightBound: FlightBound.ANY,
                fieldsRelated: [
                  'inbound.services.serviceGPURequested', 'inbound.services.serviceGPURequestTs', 'inbound.services.serviceGPURequestedTs', 'inbound.services.serviceGPUStartTs', 'inbound.services.serviceGPUFinishTs', 'inbound.services.serviceGPUUnits', 'inbound.services.serviceGPURemarks', 'inbound.services.serviceGPURequesterId', 'inbound.services.serviceGPUServiceId',
                  'outbound.services.serviceGPURequested', 'outbound.services.serviceGPURequestTs', 'outbound.services.serviceGPURequestedTs', 'outbound.services.serviceGPUStartTs', 'outbound.services.serviceGPUFinishTs', 'outbound.services.serviceGPUUnits', 'outbound.services.serviceGPURemarks', 'outbound.services.serviceGPURequesterId', 'outbound.services.serviceGPUServiceId'],
              },
              valueGetter(params) {
                return {
                  serviceServiceId: params.data.getChildPropertyValue(FlightBound.ANY, ['services', 'serviceGPUServiceId']),
                  serviceRequesterId: params.data.getChildPropertyValue(FlightBound.ANY, ['services', 'serviceGPURequesterId']),
                  serviceRequested: params.data.getChildPropertyValue(FlightBound.ANY, ['services', 'serviceGPURequested']),
                  serviceId: params.data.getChildPropertyValue(FlightBound.ANY, ['services', 'serviceGPUId']),
                  serviceRequestTs: params.data.getChildPropertyValue(FlightBound.ANY, ['services', 'serviceGPURequestTs']),
                  serviceRequestedTs: params.data.getChildPropertyValue(FlightBound.ANY, ['services', 'serviceGPURequestedTs']),
                  serviceRequestedEndTs: params.data.getChildPropertyValue(FlightBound.ANY, ['services', 'serviceGPURequestedEndTs']),
                  serviceStartTs: params.data.getChildPropertyValue(FlightBound.ANY, ['services', 'serviceGPUStartTs']),
                  serviceFinishTs: params.data.getChildPropertyValue(FlightBound.ANY, ['services', 'serviceGPUFinishTs']),
                  serviceUnits: params.data.getChildPropertyValue(FlightBound.ANY, ['services', 'serviceGPUUnits']),
                  serviceRemarks: params.data.getChildPropertyValue(FlightBound.ANY, ['services', 'serviceGPURemarks']),
                  referenceDate: params.data.getChildPropertyValue(FlightBound.ANY, ['common', 'scheduledDate']),
                  serviceName: 'GPU',
                  value: params.data.getChildPropertyValue(FlightBound.ANY, ['services', 'serviceGPURequestedTs']),
                };
              },
              cellRendererFramework: AgGridRampServiceCellRenderer,
            },
            {
              colId: 'services.asu',
              field: 'services.asu',
              headerValueGetter() {
                return that.$i18n.t('ramp.serviceAsu');
              },
              width: 35,
              minWidth: 35,
              editable(params) {
                return !!params.data.getChildPropertyValue(FlightBound.ANY, ['services', 'serviceASURequested']);
              },
              cellEditor: 'AgGridConnectedFlightsDialogRampServiceCellEditor',
              cellClass: 'ag-grid-popover-cell separator',
              comparator: this.objectWithValueComparator,
              colCustomConfig: {
                flightBound: FlightBound.ANY,
                fieldsRelated: [
                  'inbound.services.serviceASURequested', 'inbound.services.serviceASURequestTs', 'inbound.services.serviceASURequestedTs', 'inbound.services.serviceASUStartTs', 'inbound.services.serviceASUFinishTs', 'inbound.services.serviceASUUnits', 'inbound.services.serviceASURemarks', 'inbound.services.serviceASURequesterId', 'inbound.services.serviceASUServiceId',
                  'outbound.services.serviceASURequested', 'outbound.services.serviceASURequestTs', 'outbound.services.serviceASURequestedTs', 'outbound.services.serviceASUStartTs', 'outbound.services.serviceASUFinishTs', 'outbound.services.serviceASUUnits', 'outbound.services.serviceASURemarks', 'outbound.services.serviceASURequesterId', 'outbound.services.serviceASUServiceId'],
              },
              valueGetter(params) {
                return {
                  serviceServiceId: params.data.getChildPropertyValue(FlightBound.ANY, ['services', 'serviceASUServiceId']),
                  serviceRequesterId: params.data.getChildPropertyValue(FlightBound.ANY, ['services', 'serviceASURequesterId']),
                  serviceRequested: params.data.getChildPropertyValue(FlightBound.ANY, ['services', 'serviceASURequested']),
                  serviceId: params.data.getChildPropertyValue(FlightBound.ANY, ['services', 'serviceASUId']),
                  serviceRequestTs: params.data.getChildPropertyValue(FlightBound.ANY, ['services', 'serviceASURequestTs']),
                  serviceRequestedTs: params.data.getChildPropertyValue(FlightBound.ANY, ['services', 'serviceASURequestedTs']),
                  serviceRequestedEndTs: params.data.getChildPropertyValue(FlightBound.ANY, ['services', 'serviceASURequestedEndTs']),
                  serviceStartTs: params.data.getChildPropertyValue(FlightBound.ANY, ['services', 'serviceASUStartTs']),
                  serviceFinishTs: params.data.getChildPropertyValue(FlightBound.ANY, ['services', 'serviceASUFinishTs']),
                  serviceUnits: params.data.getChildPropertyValue(FlightBound.ANY, ['services', 'serviceASUUnits']),
                  serviceRemarks: params.data.getChildPropertyValue(FlightBound.ANY, ['services', 'serviceASURemarks']),
                  referenceDate: params.data.getChildPropertyValue(FlightBound.ANY, ['common', 'scheduledDate']),
                  serviceName: 'ASU',
                  value: params.data.getChildPropertyValue(FlightBound.ANY, ['services', 'serviceASURequestedTs']),
                };
              },
              cellRendererFramework: AgGridRampServiceCellRenderer,
            },
            {
              colId: 'ramp.pbk',
              field: 'ramp.pbk',
              headerValueGetter() {
                return that.$i18n.t('ramp.servicePkb');
              },
              width: 35,
              minWidth: 35,
              editable(params) {
                return !!params.data.getChildPropertyValue(FlightBound.ANY, ['services', 'servicePBKRequested']);
              },
              cellEditor: 'AgGridConnectedFlightsDialogRampServiceCellEditor',
              cellClass: 'ag-grid-popover-cell separator',
              comparator: this.objectWithValueComparator,
              colCustomConfig: {
                flightBound: FlightBound.ANY,
                fieldsRelated: [
                  'inbound.services.servicePBKRequested', 'inbound.services.servicePBKRequestTs', 'inbound.services.servicePBKRequestedTs', 'inbound.services.servicePBKStartTs', 'inbound.services.servicePBKFinishTs', 'inbound.services.servicePBKUnits', 'inbound.services.servicePBKRemarks', 'inbound.services.servicePBKRequesterId', 'inbound.services.servicePBKServiceId',
                  'outbound.services.servicePBKRequested', 'outbound.services.servicePBKRequestTs', 'outbound.services.servicePBKRequestedTs', 'outbound.services.servicePBKStartTs', 'outbound.services.servicePBKFinishTs', 'outbound.services.servicePBKUnits', 'outbound.services.servicePBKRemarks', 'outbound.services.servicePBKRequesterId', 'outbound.services.servicePBKServiceId'],
              },
              valueGetter(params) {
                return {
                  serviceServiceId: params.data.getChildPropertyValue(FlightBound.ANY, ['services', 'servicePBKServiceId']),
                  serviceRequesterId: params.data.getChildPropertyValue(FlightBound.ANY, ['services', 'servicePBKRequesterId']),
                  serviceRequested: params.data.getChildPropertyValue(FlightBound.ANY, ['services', 'servicePBKRequested']),
                  serviceId: params.data.getChildPropertyValue(FlightBound.ANY, ['services', 'servicePBKId']),
                  serviceRequestTs: params.data.getChildPropertyValue(FlightBound.ANY, ['services', 'servicePBKRequestTs']),
                  serviceRequestedTs: params.data.getChildPropertyValue(FlightBound.ANY, ['services', 'servicePBKRequestedTs']),
                  serviceRequestedEndTs: params.data.getChildPropertyValue(FlightBound.ANY, ['services', 'servicePBKRequestedEndTs']),
                  serviceStartTs: params.data.getChildPropertyValue(FlightBound.ANY, ['services', 'servicePBKStartTs']),
                  serviceFinishTs: params.data.getChildPropertyValue(FlightBound.ANY, ['services', 'servicePBKFinishTs']),
                  serviceUnits: params.data.getChildPropertyValue(FlightBound.ANY, ['services', 'servicePBKUnits']),
                  serviceRemarks: params.data.getChildPropertyValue(FlightBound.ANY, ['services', 'servicePBKRemarks']),
                  referenceDate: params.data.getChildPropertyValue(FlightBound.ANY, ['common', 'scheduledDate']),
                  serviceName: 'PBK',
                  value: params.data.getChildPropertyValue(FlightBound.ANY, ['services', 'servicePBKRequestedTs']),
                };
              },
              cellRendererFramework: AgGridRampServiceCellRenderer,
            },
            {
              colId: 'operationExtended.oth',
              field: 'operationExtended.oth',
              headerValueGetter() {
                return that.$i18n.t('ramp.serviceOth');
              },
              width: 35,
              minWidth: 35,
              editable(params) {
                return params.data.getChildPropertyValue(FlightBound.ANY, ['operationExtended', 'rampServices']).length > 0;
              },
              cellEditor: 'AgGridConnectedFlightsDialogRampStatusCellEditor',
              cellClass: 'ag-grid-popover-cell separator',
              comparator: this.objectWithValueComparator,
              colCustomConfig: {
                flightBound: FlightBound.ANY,
                fieldsRelated: [
                  'inbound.services.serviceOTHRequested', 'inbound.services.serviceOTHRequestTs', 'inbound.services.serviceOTHRequestedTs', 'inbound.services.serviceOTHStartTs', 'inbound.services.serviceOTHFinishTs', 'inbound.services.serviceOTHUnits', 'inbound.services.serviceOTHRemarks',
                  'outbound.services.serviceOTHRequested', 'outbound.services.serviceOTHRequestTs', 'outbound.services.serviceOTHRequestedTs', 'outbound.services.serviceOTHStartTs', 'outbound.services.serviceOTHFinishTs', 'outbound.services.serviceOTHUnits', 'outbound.services.serviceOTHRemarks'],
              },
              valueGetter(params) {
                const rampServices = [];
                if (params.data.getChildPropertyValue(FlightBound.INBOUND, ['services', 'rampServices']) != null) {
                  params.data.getChildPropertyValue(FlightBound.INBOUND, ['services', 'rampServices']).forEach(rs => rampServices.push(rs));
                }
                if (params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['services', 'rampServices']) != null) {
                  params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['services', 'rampServices']).forEach(rs => rampServices.push(rs));
                }
                return Array.from(new Set(rampServices));
              },
              cellRendererFramework: AgGridMultilineStringsCellRenderer,
            },
            {
              colId: 'services.rampServicesStatus',
              field: 'services.rampServicesStatus',
              headerValueGetter() {
                return that.getTextResizable(that.$i18n.t('ramp.statusShort'), that.$i18n.t('ramp.status'));
              },
              width: 35,
              minWidth: 35,
              editable() {
                return true;
              },
              cellClass: 'ag-grid-popover-cell',
              comparator: this.objectWithValueComparator,
              cellEditor: 'AgGridConnectedFlightsDialogRampStatusCellEditor',
              colCustomConfig: {
                flightBound: FlightBound.ANY,
                fieldsRelated: ['inbound.services.rampServicesStatus', 'outbound.services.rampServicesStatus'],
                isAircraftGround(connectedFlights) {
                  return that.isAircraftGround(connectedFlights);
                },
              },
              valueGetter(params) {
                return params.data.getChildPropertyValue(FlightBound.ANY, ['services', 'rampServicesStatus']);
              },
              cellRendererFramework: AgGridStatusOperationCellRenderer,
            },
          ],
        },
      ],
    };
  },
  methods: {
    onGetChanges(changes) {
      const rowNode = this.gridApi.getRowNode(changes.payload.id);

      if (!rowNode) {
        return;
      }
      const oldConnectedFlight = rowNode.data.clone();
      const newConnectedFlight = changes.payload;
      this.assignInboundOrOutboundIfDontExist(newConnectedFlight, oldConnectedFlight);
      const differencies = this.getDifferenciesAndCreateOldValuesProperties(oldConnectedFlight, newConnectedFlight);

      if (differencies && differencies.length > 0) {
        rowNode.setData(newConnectedFlight);
        if (this.$store.state.ramp.flashOnChanges) {
          this.renderNeededDiffs(differencies, rowNode); // TODO only shows flash
        }
        differencies.forEach(dif => this.$store.commit(RAMP_SET_CHANGE, { diff: dif, connectedFlights: newConnectedFlight, user: changes.origin }));
      }
    },
    onGridReady(params) {
      this.setAgGridApi(params);
      this.showLoading();
      this.$store.commit(RAMP_SET_GRID_API, params); // TODO eliminar api del store

      this.refreshCellsOnMutation();
      this.refreshCellsOnChangeLanguage();
      const that = this;
      this.subscribeMutation(RAMP_ON_EXTERNAL_CHANGE, (mutationPayload) => {
        that.onGetChanges(mutationPayload);
      });
      this.setColumnVisibleOfView();
    },
    setColumnVisibleOfView() {
      this.columnApi.setColumnsVisible(ColDefRampConfiguration[this.$route.name].notVisible, false);
      this.columnApi.setColumnsVisible(ColDefRampConfiguration[this.$route.name].visible, true);
      this.gridApi.sizeColumnsToFit();
    },
    firstDataRendered() {
      this.$nextTick(() => {
        this.resized();
      });
    },
    resized() {
      if (this.gridApi) {
        this.gridApi.sizeColumnsToFit();
      }
    },
    sizeColumnsToFit() {
      if (this.gridApi) {
        this.$store.dispatch(RAMP_FIT_COLUMNS_TO_VIEW);
      }
    },
    onSelectionChanged(event) {
      const selectedNodes = event.api.getSelectedNodes();
      const selectedData = selectedNodes.map(node => node.data);
      this.$store.commit(RAMP_CHANGE_FLIGHT_SELECTION, selectedData);
    },
    deleteItem(item) {
      console.log(`delete ${item}`); // eslint-disable-line no-console
    },
    editItem(item) {
      console.log(`edit ${item}`); // eslint-disable-line no-console
    },
    isInbound(params) {
      return params.data.hasInbound();
    },
    isOutbound(params) {
      return params.data.hasOutbound();
    },
    bestTimeGetter(bestTime, scheduledTime, referenceDateTime, flightStatus) {
      const result = this.agGridTimeGetter(bestTime, referenceDateTime);
      const scheduledTimeAux = scheduledTime ? this.$moment(scheduledTime) : null;
      if (flightStatus === 'OFFBLOCK' && result.value && result.value.isValid()) {
        if (scheduledTimeAux && scheduledTimeAux.isValid() && result.value.startOf('minute').isAfter(scheduledTimeAux.startOf('minute'))) {
          result.color = 'red';
        }
      }
      if (flightStatus) {
        result.cssClass = `status-op status-${flightStatus}`;
        result.tooltip = flightStatus;
      }
      return result;
    },
    renderNeededDiffs(differences, rowNode) {
      const { gridApi } = this.$store.state.ramp;

      const agGridCustomService = new AgGridCustomService(this.columnDefs); // TODO one time
      const colIds = agGridCustomService.getColIdByMatchAnyfieldsRelated(this.joinAllPropertyPath(differences));

      if (colIds && colIds.length > 0) {
        colIds.forEach((colId) => {
          if (gridApi.getColumnDef(colId)) {
            gridApi.refreshCells({
              rowNodes: [rowNode],
              columns: [colId],
              force: true,
            });

            gridApi.flashCells({
              rowNodes: [rowNode],
              columns: [colId],
              force: true,
            });
          }
        });
      }
    },
    checkBoundAndSetMsgTooltipObject(messageValue) {
      if (messageValue === null || typeof (messageValue) === 'undefined') {
        return null;
      }
      if (messageValue === 'WARNING_LDM') {
        return {
          value: messageValue,
          icon: 'warning',
          color: 'red',
          tooltip: this.$t('messages.warningMessageLDM'),
        };
      }

      return {
        value: messageValue,
        icon: messageValue !== 'EMPTY' ? 'sms' : '',
        color: messageValue === 'UNREAD' ? 'success' : 'bggreydark',
        tooltip: messageValue === 'UNREAD' ? this.$t('messages.messagesUnread') : this.$t('messages.messagesRead'),
      };
    },
    checkBoundAndSetRemTooltipObject(remarkValue, missingPaxAlert) {
      if (remarkValue === null || typeof (remarkValue) === 'undefined') {
        return null;
      }
      if (missingPaxAlert) {
        return {
          value: remarkValue,
          icon: 'info',
          color: 'orange',
          tooltip: this.$t('remarks.missingPaxAlert'),
        };
      }
      return {
        value: remarkValue,
        icon: remarkValue !== 'EMPTY' ? 'info' : '',
        color: remarkValue === 'UNREAD' ? 'primary' : 'bggreydark',
        tooltip: remarkValue === 'UNREAD' ? this.$t('remarks.remarksUnread') : this.$t('remarks.remarksRead'),
      };
    },
    checkBoundAndSetCrewTooltipObject(crewValue) {
      if (crewValue === null || typeof (crewValue) === 'undefined') {
        return null;
      }

      return {
        value: crewValue,
        icon: crewValue ? 'info' : '',
        color: 'primary',
        tooltip: crewValue ? 'There are crew changes' : '',
      };
    },
    getInformationDelay(params, bound) {
      const amount1 = this.getAmountFormat(params.data.getChildPropertyValue(bound, ['delays', 'flightDelayAmount1']));
      const delayCode1 = params.data.getChildPropertyValue(bound, ['delays', 'flightDelayCode1']) ? `(${params.data.getChildPropertyValue(bound, ['delays', 'flightDelayCode1'])})` : '';
      const delayRemark1 = params.data.getChildPropertyValue(bound, ['delays', 'flightDelayRemark1']) ? ` - ${params.data.getChildPropertyValue(bound, ['delays', 'flightDelayRemark1'])}` : '';

      const delay1 = delayCode1 !== '' || amount1 != null ? `${delayCode1} ${amount1} ${delayRemark1}` : '';

      const amount2 = this.getAmountFormat(params.data.getChildPropertyValue(bound, ['delays', 'flightDelayAmount2']));
      const delayCode2 = params.data.getChildPropertyValue(bound, ['delays', 'flightDelayCode2']) ? `(${params.data.getChildPropertyValue(bound, ['delays', 'flightDelayCode2'])})` : '';
      const delayRemark2 = params.data.getChildPropertyValue(bound, ['delays', 'flightDelayRemark2']) ? ` - ${params.data.getChildPropertyValue(bound, ['delays', 'flightDelayRemark2'])}` : '';

      const delay2 = delayCode2 !== '' || amount2 != null ? `<br>${delayCode2} ${amount2} ${delayRemark2}` : '';

      const amount3 = this.getAmountFormat(params.data.getChildPropertyValue(bound, ['delays', 'flightDelayAmount3']));
      const delayCode3 = params.data.getChildPropertyValue(bound, ['delays', 'flightDelayCode3']) ? `(${params.data.getChildPropertyValue(bound, ['delays', 'flightDelayCode3'])})` : '';
      const delayRemark3 = params.data.getChildPropertyValue(bound, ['delays', 'flightDelayRemark3']) ? ` - ${params.data.getChildPropertyValue(bound, ['delays', 'flightDelayRemark3'])}` : '';

      const delay3 = delayCode3 !== '' || amount3 != null ? `<br>${delayCode3} ${amount3} ${delayRemark3}` : '';

      return `${delay1} ${delay2} ${delay3}`;
    },
    getAmountFormat(amount) {
      if (amount) {
        const milliseconds = amount ? Number(amount) * 60000 : 0;
        let hours = (Number(this.$moment.duration(milliseconds).days()) * 24) + Number(this.$moment.duration(milliseconds).hours());
        if (hours === 0) {
          hours = '00';
        } else if (hours.toString().length === 1) {
          hours = `0${hours}`;
        }
        let minutes = this.$moment.duration(milliseconds).minutes();
        if (minutes === 0) {
          minutes = '00';
        } else if (minutes.toString().length === 1) {
          minutes = `0${minutes}`;
        }
        return `${hours}:${minutes}`;
      }
      return null;
    },
    isAircraftGround(params) {
      if (params.data) {
        return params.data.getChildPropertyValue(FlightBound.INBOUND, ['common', 'id']) != null && params.data.getChildPropertyValue(FlightBound.INBOUND, ['common', 'tripNumber']) == null;
      }
      return params.getChildPropertyValue(FlightBound.INBOUND, ['common', 'id']) != null && params.getChildPropertyValue(FlightBound.INBOUND, ['common', 'tripNumber']) == null;
    },
    isNotAircraftGround(params) {
      return !this.isAircraftGround(params);
    },
    isInboundAndNotAircraftGround(params) {
      return this.isInbound(params) && !this.isAircraftGround(params);
    },
    isOutboundAndNotAircraftGround(params) {
      return this.isOutbound(params) && !this.isAircraftGround(params);
    },
    isRampBusesView() {
      return this.$route.name === 'rampBuses';
    },
    isRampBaggageView() {
      return this.$route.name === 'rampBaggage';
    },
    isRampDutyView() {
      return this.$route.name === 'rampDuty';
    },
  },
};

</script>
