<template>
  <v-tooltip left>
    <v-speed-dial
      slot="activator"
      fixed bottom right
      color="primary">
      <template v-slot:activator>
        <v-btn color="primary" dark fab>
          <v-icon>edit</v-icon>
          <v-icon>close</v-icon>
        </v-btn>
      </template>
      <v-tooltip left>
        <v-btn fab dark small @click="clickAddNew" slot="activator">
          <v-icon>add</v-icon>
        </v-btn>
        <span>Add new Acciona department</span>
      </v-tooltip>

      <v-tooltip left>
        <v-btn fab dark small @click="toggleCollapseAll" slot="activator">
          <v-icon>line_weight</v-icon>
        </v-btn>
        <span>Toogle collpase</span>
      </v-tooltip>
      </v-speed-dial>
    <span>Directory settings</span>
  </v-tooltip>
</template>

<script>
export default {
  name: 'DirectorySpeedDial',
  data: () => ({
  }),
  methods: {
    clickAddNew() {
      this.$emit('click-new-item');
    },
    toggleCollapseAll() {
      this.$emit('click-collapse-all');
    },
  },
};
</script>
