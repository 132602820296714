/* eslint no-param-reassign: "off" */
/* eslint no-confusing-arrow: "off" */
/* eslint no-nested-ternary: "off" */

import { HTTP } from '../../../http';
import { REQUEST_ERROR, REQUEST_START, REQUEST_SUCCESS } from '../../store';
import { NOTIFICATION_SHOW } from '../notifications';
import i18n from '../../../plugins/i18n';

// action names
export const GET_AIRPORT_DEFAULT_BOARDING_TIME = 'GET_AIRPORT_DEFAULT_BOARDING_TIME';
export const AIRPORT_DEFAULT_BOARDING_TIME_UPDATE = 'AIRPORT_DEFAULT_BOARDING_TIME_UPDATE';
export const AIRPORT_DEFAULT_BOARDING_TIME_CREATE = 'AIRPORT_DEFAULT_BOARDING_TIME_CREATE';
export const AIRPORT_DEFAULT_BOARDING_TIME_ENABLE = 'AIRPORT_DEFAULT_BOARDING_TIME_ENABLE';
export const AIRPORT_DEFAULT_BOARDING_TIME_DISABLE = 'AIRPORT_DEFAULT_BOARDING_TIME_DISABLE';

// mutations
export const GET_AIRPORT_BOARDING_TIME_REQUESTED = 'GET_AIRPORT_BOARDING_TIME_REQUESTED';
export const GET_AIRPORT_BOARDING_TIME_SUCCESS = 'GET_AIRPORT_BOARDING_TIME_SUCCESS';
export const UPDATE_AIRPORT_BOARDING_TIME_SUCCESS = 'UPDATE_AIRPORT_BOARDING_TIME_SUCCESS';

const storeState = {
  airportDefaultBoardingTime: [],
  airportDefaultBoardingTimeRequested: false,
  selectedAirportDefaultBoardingTime: [],
};

const storeGetters = {
  getAirportDefaultBoardingTime: state => state.airportDefaultBoardingTime
    .map(defaultCheckInTime => ({ ...defaultCheckInTime, disableDelete: true })) // La eliminación física está deshabilida en estas entidades, y debería estarlo en algunas más
    .sort((a, b) => a.airline > b.airline ? 1 : b.airline > a.airline ? -1 : 0),
  getAirportDefaultBoardingTimeSelected: state => state.selectedAirportDefaultBoardingTime,
};

const successResponse = (commit, dispatch, notification) => {
  commit(REQUEST_SUCCESS);
  commit(UPDATE_AIRPORT_BOARDING_TIME_SUCCESS);
  dispatch(GET_AIRPORT_DEFAULT_BOARDING_TIME);
  commit(NOTIFICATION_SHOW, {
    message: i18n.t(notification),
    color: 'success',
  });
};
const errorResponse = (commit, err) => {
  commit(NOTIFICATION_SHOW, {
    message: i18n.t('notifications.errorNotification'),
    color: 'error',
  });
  commit(REQUEST_ERROR, err);
};

const storeActions = {
  [GET_AIRPORT_DEFAULT_BOARDING_TIME]: ({ commit, state }) => {
    if (state.airportDefaultBoardingTimeRequested) {
      return;
    }
    commit(REQUEST_START);
    commit(GET_AIRPORT_BOARDING_TIME_REQUESTED);
    HTTP.get('/airline/default-pax-time/boarding')
      .then((resp) => {
        commit(REQUEST_SUCCESS);
        commit(GET_AIRPORT_BOARDING_TIME_SUCCESS, resp.data);
      })
      .catch((err) => {
        commit(REQUEST_ERROR, err);
      });
  },
  [AIRPORT_DEFAULT_BOARDING_TIME_CREATE]: ({ commit, dispatch }, defaultBoardingTime) => {
    commit(REQUEST_START);
    return HTTP.post('airline/default-pax-time/boarding', defaultBoardingTime)
      .then(() => {
        successResponse(commit, dispatch, 'notifications.airportDefaultBoardingTimeCreate');
      })
      .catch((err) => {
        errorResponse(commit, err);
      });
  },
  [AIRPORT_DEFAULT_BOARDING_TIME_UPDATE]: ({ commit, dispatch }, defaultBoardingTime) => {
    commit(REQUEST_START);
    return HTTP.put('airline/default-pax-time/boarding', defaultBoardingTime)
      .then(() => {
        successResponse(commit, dispatch, 'notifications.airportDefaultBoardingTimeUpdate');
      })
      .catch((err) => {
        errorResponse(commit, err);
      });
  },
  [AIRPORT_DEFAULT_BOARDING_TIME_DISABLE]: ({ commit, dispatch }, id) => {
    commit(REQUEST_START);
    return HTTP.patch(`/airline/default-pax-time/boarding/disable/${id}`)
      .then(() => {
        successResponse(commit, dispatch, 'notifications.airportSLABaggageDeliveryDisable');
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
  [AIRPORT_DEFAULT_BOARDING_TIME_ENABLE]: ({ commit, dispatch }, id) => {
    commit(REQUEST_START);
    return HTTP.patch(`/airline/default-pax-time/boarding/enable/${id}`)
      .then(() => {
        successResponse(commit, dispatch, 'notifications.airportSLABaggageDeliveryEnable');
      })
      .catch((err) => {
        errorResponse(commit, err);
      });
  },
};

const storeMutations = {
  [GET_AIRPORT_BOARDING_TIME_SUCCESS]: (state, data) => {
    state.airportDefaultBoardingTime = data;
  },
  [GET_AIRPORT_BOARDING_TIME_REQUESTED]: (state) => {
    state.airportDefaultBoardingTimeRequested = true;
  },
  [UPDATE_AIRPORT_BOARDING_TIME_SUCCESS]: (state) => {
    state.airportDefaultBoardingTimeRequested = false;
  },
};

export default {
  state: storeState,
  getters: storeGetters,
  actions: storeActions,
  mutations: storeMutations,
};
