<template>
  <div>
    <v-autocomplete placeholder="..." :value="value" :label="label" :items="items" :filter="customFilter"
      item-text="name" item-value="id" :rules="rules" :disabled="disabled" :menu-props="{ zIndex: '1000' }"
      @input="emitInput" :ref="refComp" multiple deletable-chips :prepend-inner-icon="icon" chips hide-details>

      <template slot="item" slot-scope="{ item }">
        <v-list-tile-content>
          <v-list-tile-title>{{ item.name }}</v-list-tile-title>
          <v-list-tile-sub-title>{{ item.description }}</v-list-tile-sub-title>
        </v-list-tile-content>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
// TO-DO Unificar con componente RampServiceSelect

import { GET_RAMP_SERVICE } from '../../store/modules/rampServices/rampServicesCatalog';

export default {
  props: {
    value: { required: true },
    label: { type: String },
    charLenght: { type: Number },
    rules: { type: Array },
    disabled: { type: Boolean },
    multiple: {
      type: Boolean,
      default: false,
    },
    refComp: {
      type: String,
      required: false,
      default: 'refComp',
    },
    icon: { type: String },
  },
  computed: {
    items: {
      get() {
        return this.$store.getters.getActiveRampServices;
      },
    },
  },
  methods: {
    getRampServices() {
      this.$store.dispatch(GET_RAMP_SERVICE);
    },
    customFilter(item, queryText) {
      const textOne = item.name ? item.name.toLowerCase() : '';
      const textTwo = item.description ? item.description.toLowerCase() : '';
      const searchText = queryText.toLowerCase();

      return textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1;
    },
    emitInput($event) {
      // FIXME así no rompe al limpiar el select pero hay que validar que no se pueda guardar el tipo de mensaje como NULL
      if (typeof $event !== 'undefined') {
        this.$emit('input', $event);
      } else {
        this.$emit('input', null);
      }
    },
  },
  mounted() {
    this.getRampServices();
  },
};
</script>
