import { render, staticRenderFns } from "./MobileServiceMilestones.vue?vue&type=template&id=8f484032&scoped=true&class=mobileView"
import script from "./MobileServiceMilestones.vue?vue&type=script&lang=js"
export * from "./MobileServiceMilestones.vue?vue&type=script&lang=js"
import style0 from "./MobileServiceMilestones.vue?vue&type=style&index=0&id=8f484032&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8f484032",
  null
  
)

export default component.exports