import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListTile } from 'vuetify/lib/components/VList';
import { VListTileAction } from 'vuetify/lib/components/VList';
import { VListTileContent } from 'vuetify/lib/components/VList';
import { VListTileSubTitle } from 'vuetify/lib/components/VList';
import { VListTileTitle } from 'vuetify/lib/components/VList';
import { VSwitch } from 'vuetify/lib/components/VSwitch';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,[_c(VList,[_c(VListTile,[_c(VListTileContent,[_c(VListTileTitle,[_vm._v(_vm._s(_vm.$t("tableOptions.flightNumberShort")))])],1)],1)],1),_c(VDivider),_c(VList,[_c(VListTile,[_c(VListTileAction,[_c(VSwitch,{attrs:{"color":"primary"},on:{"change":function($event){return _vm.updateFitColumnsOnResize()}},model:{value:(_vm.fitColumnsOnResize),callback:function ($$v) {_vm.fitColumnsOnResize=$$v},expression:"fitColumnsOnResize"}})],1),_c(VListTileContent,[_c(VListTileTitle,[_vm._v(_vm._s(_vm.$t("tableOptions.fitColumnsWidthBrowser")))]),_c(VListTileSubTitle,[_vm._v(_vm._s(_vm.$t("tableOptions.upToMinWidth")))])],1)],1),_c(VListTile,[_c(VListTileAction,[_c(VBtn,{attrs:{"flat":"","icon":"","color":"primary"},on:{"click":_vm.fitColumnsToView}},[_c(VIcon,[_vm._v("compare_arrows")])],1)],1),_c(VListTileContent,[_c(VListTileTitle,[_vm._v(_vm._s(_vm.$t("tableOptions.fitColumnsWidth")))]),_c(VListTileSubTitle,[_vm._v(_vm._s(_vm.$t("tableOptions.upToMinWidth")))])],1)],1),_c(VListTile,[_c(VListTileAction,[_c(VBtn,{attrs:{"flat":"","icon":"","color":"primary"},on:{"click":_vm.fitColumnsToBest}},[_c(VIcon,[_vm._v("swap_horiz")])],1)],1),_c(VListTileContent,[_c(VListTileTitle,[_vm._v(_vm._s(_vm.$t("tableOptions.fitColumnsWidthBest")))]),_c(VListTileSubTitle,[_vm._v(_vm._s(_vm.$t("tableOptions.horizontalScrollAppears")))])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }