<template>
  <div class="mobileView">
    <div class="header-filter">
      <v-layout class="flex justify-center">
        <v-flex>
          <v-toolbar dark dense color="primary lighten-1">
            <h6 class="title ma-0">{{ $t("dispatcher.title") }}</h6>
            <v-spacer></v-spacer>
            <v-btn icon @click="updateFlights">
              <v-icon>cached</v-icon>
            </v-btn>
            <h6 class="title ma-0"> {{ referenceDate.format("D/M/YYYY") }}</h6>
            <div>
              <v-dialog v-model="filterDialog" max-width="600px">
                <template v-slot:activator="{ on }">
                  <v-btn dark icon v-on="on">
                    <v-icon>tune</v-icon>
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title class="pa-2">
                    <h5 class="headline red--text">{{ $t("dispatcher.searchFilter") }}</h5>
                  </v-card-title>
                  <v-card-text class="pa-0">
                    <v-container grid-list-md class="py-1">
                      <v-layout wrap>
                        <v-flex xs12 sm12>
                          <date-input class="date-mobile" v-model="filter.date"
                            :label="this.$i18n.t('dispatcher.filterDate')" :locale="this.$i18n.locale"></date-input>
                        </v-flex>
                        <v-flex xs12 sm12>
                          <aircraft-select :label="this.$i18n.t('common.aircraft')"
                            v-model="filter.aircraft"></aircraft-select>
                        </v-flex>
                        <v-flex xs12 sm12>
                          <airline-select :label="this.$i18n.t('common.airline')"
                            v-model="filter.airline"></airline-select>
                        </v-flex>
                        <v-flex xs12 sm12>
                          <v-text-field color="primary" :label="this.$i18n.t('dispatcher.filterFlightNumber')"
                            v-model="filter.tripNumber"></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm12>
                          <v-flex xs12 sm12>
                            <airport-select :label="this.$i18n.t('dispatcher.filterAirport')"
                              :placeholder="this.$i18n.t('dispatcher.filterAirport')"
                              v-model="filter.airport"></airport-select>
                          </v-flex>
                        </v-flex>
                        <v-flex xs6 sm6 class="py-0" v-if="hasOnlyMyFlightsRole">
                          <v-switch class="my-0" v-model="filter.onlyMyFlights"
                            :label="$t('dispatcher.filterOnlyMyFlights')"></v-switch>
                        </v-flex>
                        <v-flex xs6 sm6 class="py-0">
                          <v-switch class="my-0" v-model="filter.showAircraftOnGround"
                            :label="$t('dispatcher.filterShowAircraftOnGround')"></v-switch>
                        </v-flex>
                        <v-flex xs6 sm6 class="py-0">
                          <v-switch class="my-0" v-model="filter.showArchivedFlights"
                            :label="$t('dispatcher.filterShowArchivedFlights')"></v-switch>
                        </v-flex>
                      </v-layout>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="grey" flat @click="filterDialog = false">{{ $t("common.close") }}</v-btn>
                    <v-btn color="primary" flat @click="filterChanged">{{ $t("common.apply") }}</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
          </v-toolbar>
          <v-card>
            <v-bottom-nav :active.sync="buttonNav" :value="true" color="transparent">
              <v-btn color="primary" flat value="all">
                <span>{{ $t("dispatcher.navAll") }}</span>
                <div>
                  <v-icon>flight_land</v-icon>
                  <v-icon>flight_takeoff</v-icon>
                </div>
              </v-btn>
              <v-btn color="primary" flat value="arrival">
                <span>{{ $t("dispatcher.navArrival") }}</span>
                <v-icon>flight_land</v-icon>
              </v-btn>

              <v-btn color="primary" flat value="departures">
                <span>{{ $t("dispatcher.navDepartures") }}</span>
                <v-icon>flight_takeoff</v-icon>
              </v-btn>
            </v-bottom-nav>
          </v-card>
        </v-flex>
      </v-layout>
    </div>
    <div class="mobile-flights-content">
      <v-layout class="flex justify-center" column style="height: calc(100vh - 200px)">
        <v-flex style="overflow: auto">
          <v-data-table :items="mobileFlights" class="elevation-1 mobile-flights-table" hide-actions
            :loading="isLoading" :dark="this.$vuetify.dark">
            <template v-slot:no-data>
              <p v-if="!isLoading" class="subheading text-xs-center ma-0">{{ customNoRowsOverlay }}</p>
              <p v-else class="subheading text-xs-center ma-0"></p>
            </template>
            <template v-slot:progress>
              <p class="subheading text-xs-center ma-0">{{ $t("common.loading") }}</p>
            </template>
            <template v-slot:headers>
              <tr class="mobile-table-headers-fixed" v-if="mobileFlights.length > 0">
                <th class="pa-0"><v-icon>local_airport</v-icon></th>
                <th class="pa-0">{{ $t("operations.flightNumberShort") }}</th>
                <th class="pa-0"><v-icon>apartment</v-icon></th>
                <th class="pa-0"><v-icon>schedule</v-icon></th>
                <th class="pa-0">{{ $t("dispatcher.dataHeaderStatus") }}</th>
                <th class="pa-0">{{ $t("dispatcher.dataHeaderRegistration") }}</th>
                <th class="pa-0"><v-icon>local_parking</v-icon></th>
              </tr>
            </template>
            <template v-slot:items="props">
              <tr @click=toInfoMobileView(props.item) class="flights-data">
                <td class="text-xs-center pa-0">
                  <v-icon v-if="props.item.bound === 'INBOUND'">flight_land</v-icon>
                  <v-icon v-else-if="props.item.bound === 'OUTBOUND'">flight_takeoff</v-icon>
                </td>
                <td class="subheading text-xs-center pa-0">{{ props.item.airlineCode + props.item.tripNumber }}</td>
                <td class="subheading text-xs-center pa-0">{{ props.item.airport1Code }}</td>
                <td class="subheading text-xs-center pa-0">{{ formattedTime(props.item.scheduledTime) }}</td>
                <td class="subheading text-xs-center pa-0">
                  <flight-status :status="props.item.status" :resizable="true"></flight-status>
                </td>
                <td class="subheading text-xs-center pa-0">{{ props.item.aircraftRegisterNumber }}</td>
                <td class="subheading text-xs-center pa-0">{{ props.item.parkingCode }}</td>
              </tr>
            </template>
          </v-data-table>
        </v-flex>
      </v-layout>
    </div>
  </div>
</template>

<script>
import AirportSelect from '../../components/airport/AirportSelect.vue';
import { MOBILE_FILTER_RESET_TIMEZONE, MOBILE_FIND_FLIGHTS } from '../../store/modules/mobile';
import DateInput from '../../components/ui/DateInput.vue';
import MobileFlightUtil from '../../components/mobile/model/mobileFlightUtil';
import FlightStatus from '../../components/flights/FlightStatus.vue';
import { TIME_TIMEZONE_CHANGED } from '../../store/modules/time';
import StoreSubscribable from '../../components/mixins/StoreSubscribable.vue';
import AirlineSelect from '../../components/airline/AirlineSelect.vue';
import AircraftSelect from '../../components/aircraft/AircraftSelect.vue';
import AgGridNoRowsOverlay from '../../components/ag-grid/mixins/AgGridNoRowsOverlay.vue';
import RolePermissions from '../../components/mixins/RolePermissions.vue';
import './InfoMobile.scss';

export default {
  name: 'mobile-view',
  props: {
    filterDate: String,
  },
  mixins: [RolePermissions, StoreSubscribable, AgGridNoRowsOverlay],
  components: {
    AirportSelect,
    DateInput,
    FlightStatus,
    AirlineSelect,
    AircraftSelect,
  },
  data() {
    return {
      buttonNav: 'all',
      filterDialog: false,
      datePicker: false,
      date: null,
      flightRegistration: null,
      flightNumber: null,
      airport: null,
      mobileFilter: {
        airport: null,
        tripNumber: null,
        aircraftRegistration: null,
      },
      referenceDate: null,
    };
  },
  computed: {
    hasOnlyMyFlightsRole() {
      return this.hasPermissions('AIRPORT_SERVICE_AGENT_ROLE_FILTER_BY_AGENT');
    },
    filter() {
      return this.$store.state.mobile.filter;
    },
    mobileFlights() {
      return MobileFlightUtil.getFilterFlights(this.mobileFilter, this.buttonNav, this.$store.state.mobile.flightsMobile);
    },
    isLoading() {
      return this.$store.state.loading;
    },
  },
  methods: {
    filterChanged() {
      this.filterDialog = false;
      this.referenceDate = this.filter.date;
      this.$store.dispatch(MOBILE_FIND_FLIGHTS);
    },
    toInfoMobileView(selectedFlight) {
      this.$router.push({ name: 'info', params: { flightId: selectedFlight.id } });
    },
    formattedTime(time) {
      let formattedTime = '';
      if (time) {
        formattedTime = this.$moment(time).format('HH:mm');
      }
      return formattedTime;
    },
    updateFlights() {
      this.$store.commit(MOBILE_FILTER_RESET_TIMEZONE);
      this.$store.dispatch(MOBILE_FIND_FLIGHTS);
    },
  },
  created() {
    this.dark = this.$vuetify.dark;
    this.subscribeMutation(TIME_TIMEZONE_CHANGED, () => {
      this.$store.commit(MOBILE_FILTER_RESET_TIMEZONE);
      this.$store.dispatch(MOBILE_FIND_FLIGHTS);
    });
    if (this.filterDate) {
      this.referenceDate = this.$moment(this.filterDate);
      this.filter.date = this.$moment(this.filterDate).startOf('day');
      this.date = this.$moment(this.filterDate);
    } else {
      this.filter.date = this.$moment().startOf('day');
      this.referenceDate = this.$moment().startOf('day');
      this.date = this.$moment().startOf('day');
    }
    if (this.hasOnlyMyFlightsRole) {
      this.filter.onlyMyFlights = false;
    } else {
      this.filter.onlyMyFlights = true;
    }
    this.$store.commit(MOBILE_FILTER_RESET_TIMEZONE);
    this.$store.dispatch(MOBILE_FIND_FLIGHTS);
  },
};
</script>
<style lang="scss">
// DARK MODE

.dark-mode .mobileView .v-item-group {
  background: #333 !important;
  color: white !important;
}


.dark-mode .mobileView .v-btn__content {
  color: white !important;
}

//v-bottom-nav--active
.dark-mode .mobileView .v-btn--active .v-btn__content {
  color: red !important;
}


.date-mobile,
.date-mobile .date-text-input {
  max-width: 100% !important;
}

.date-mobile .v-input__prepend-outer {
  display: none;
}
.mobile-flights-table table {
  width: 100vw;
}

.mobile-flights-table>table thead {
  display: table;
  width: calc(100% - 0px);
}

.mobile-flights-table>table tbody {
  display: block;
  height: 70vh;
  overflow: auto;
  font-size: 14px !important;
}

.mobile-flights-table>table tr {
  width: calc(100% - 0px);
  display: table;
}

.mobile-flights-table>table th,
td {
  width: calc(100% / 7);
  padding: 10px;
  word-break: break-all;
  cursor: pointer;
}

.mobile-flights-table table.v-table thead th {
  font-weight: bold;
}

.mobile-flights-table table.v-table tbody td {
  font-size: 14px !important;
}


.dark-mode .mobile-flights-table .status-op {
  color: black !important;
}
</style>
