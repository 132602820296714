<template>
  <div>
    <ag-grid-vue
      class="ag-theme-material connected-flights-table operational-table"
      :columnDefs="columnDefs"
      :defaultColDef="defaultColDef"
      :rowData="items"
      rowSelection="multiple"
      :rowMultiSelectWithClick="true"
      :suppressRowClickSelection="true"
      :onGridReady="onGridReady"
      @first-data-rendered="firstDataRendered"
      :gridOptions="gridOptions"
      @selection-changed="onSelectionChanged"
      :suppressHorizontalScroll="true"
      :frameworkComponents="frameworkComponents"
      v-bind:style="`width: 100%; height: calc(100vh - ${this.sizeFilter} - 8vh);`">
    </ag-grid-vue>
    <v-divider></v-divider>
    <ag-grid-vue
      class="ag-theme-material connected-flights-table operational-table"
      :columnDefs="columnDefsT"
      :defaultColDef="defaultColDef"
      :headerHeight="0"
      :rowData="itemTotal"
      :gridOptions="gridOptionsTotal"
      :suppressHorizontalScroll="true"
      :frameworkComponents="frameworkComponents"
      v-bind:style="`width: 100%; height: 8vh; font-weight: bold`">
    </ag-grid-vue>
  </div>
</template>
<script>
import { AgGridVue } from 'ag-grid-vue';
import AgGridTimeCellRenderer from '../../ag-grid/cell-renderers/AgGridTimeCellRenderer.vue';
import AgGridConnectedFlights from '../../ag-grid/mixins/AgGridConnectedFlights.vue';
import { cellClassAgGridRules } from '../../ag-grid/mixins/cellClassAgGridRules';
import { FisModule } from '../../ag-grid/cell-editors/model/fisModule';
import agGridTextCustomFilterComparator from '../../ag-grid/filters/agGridTextCustomFilterComparator';
import agGridTextCustomFilterFormatter from '../../ag-grid/filters/agGridTextCustomFilterFormatter';
import AgGridValueBaseWithTooltipCellRenderer
from '../../ag-grid/cell-renderers/AgGridValueBaseWithTooltipCellRenderer.vue';
import AgGridCustomService from '../../shared/services/agGridCustomService';
import AgGridIconCellRenderer from '../../ag-grid/cell-renderers/AgGridIconCellRenderer.vue';
import AgGridRowActionButtonsCellRenderer from '../../ag-grid/cell-renderers/AgGridRowActionButtonsCellRenderer.vue';
import AgGridValueBaseCellRenderer from '../../ag-grid/cell-renderers/AgGridValueBaseCellRenderer.vue';
import RolePermissions from '../../mixins/RolePermissions.vue';
import {
  TICKETING_CHANGE_FLIGHT_SELECTION,
  TICKETING_CHANGE_GRID_API,
  TICKETING_FIT_COLUMNS_TO_VIEW,
} from '../../../store/modules/ticketing';
import agGridTimeFormatter from '../../ag-grid/formatters/agGridTimeFormatter';
import AgGridRowActionButtonsTicketingCellRenderer
from '../../ag-grid/cell-renderers/AgGridRowActionButtonsTicketingCellRenderer.vue';
import AgGridTicketingFlightsDialogStatusCellEditor
from '../../ag-grid/cell-editors/AgGridTicketingFlightsDialogStatusCellEditor.vue';
import AgGridCurrencyCellRenderer from '../../ag-grid/cell-renderers/AgGridCurrencyCellRenderer.vue';
import { TicketingStatus } from '../model/ticketingStatus';
import AgGridFlightStatusCellRenderer from '../../ag-grid/cell-renderers/AgGridFlightStatusCellRenderer.vue';
import AgGridPaymentTypeCellRenderer from '../../ag-grid/cell-renderers/AgGridPaymentTypeCellRenderer.vue';
import AgGridTpvTypeCellRenderer from '../../ag-grid/cell-renderers/AgGridTpvTypeCellRenderer.vue';
import agGridPaymentTypeFilterComparator from '../../ag-grid/filters/agGridPaymentTypeFilterComparator';

export default {
  name: 'ticketing-list-table',
  mixins: [AgGridConnectedFlights, RolePermissions],
  components: {
    AgGridVue,
    AgGridRowActionButtonsCellRenderer,
    AgGridValueBaseCellRenderer,
    AgGridCurrencyCellRenderer,
  },
  props: {
    items: { type: Array, required: true },
    itemTotal: { type: Array, required: true },
    isFilterDisplayed: { type: Boolean, required: false, default: false },
  },
  computed: {
    sizeFilter() {
      return this.isFilterDisplayed ? '320px' : '180px';
    },
    fitColumnsOnResize() {
      return this.$store.state.ticketing.fitColumnsOnResize;
    },
  },
  data() {
    const that = this;
    const rolePermission = `${this.$store.state.ticketing.ticketingUserRole}_WR`;
    return {
      frameworkComponents: {
        AgGridValueBaseWithTooltipCellRenderer,
        AgGridIconCellRenderer,
        AgGridTicketingFlightsDialogStatusCellEditor,
        AgGridPaymentTypeCellRenderer,
      },
      defaultColDef: {
        cellClassRules: cellClassAgGridRules,
        minWidth: 30,
        suppressMenu: true,
        resizable: true,
        sortable: true,
        filter: 'agTextColumnFilter',
        filterParams: {
          textCustomComparator: agGridTextCustomFilterComparator,
          textFormatter: agGridTextCustomFilterFormatter,
        },
        editable: false,
      },
      gridOptions: {
        onFilterChanged: this.uncheckAllOnFilter,
        getRowNodeId: this.getRowNodeId,
        getRowClass: this.getRowClass,
        floatingFilter: true,
        groupHeaderHeight: 35,
        headerHeight: 35,
        floatingFiltersHeight: 20,
        rowHeight: 30,
        context: {
          module: FisModule.TICKETING,
        },
        alignedGrids: [],
      },
      gridOptionsTotal: {
        getRowNodeId: this.getRowNodeId,
        getRowClass: this.getRowClass,
        floatingFilter: true,
        groupHeaderHeight: 35,
        headerHeight: 35,
        floatingFiltersHeight: 20,
        rowHeight: 30,
        context: {
          module: FisModule.TICKETING,
        },
        alignedGrids: [],
      },
      columnDefs: [
        {
          headerName: '',
          children: [
            {
              headerTooltip: 'Check/uncheck all',
              hide: !this.hasPermissions(rolePermission),
              checkboxSelection: true,
              width: 21,
              minWidth: 20,
              headerCheckboxSelection: true,
              headerCheckboxSelectionFilteredOnly: true,
              suppressMenu: true,
              sortable: false,
              suppressMovable: true,
              suppressSizeToFit: true,
              filter: false,
              resizable: false,
              colCustomConfig: {
                exportCsvDisabled: true,
              },
              editable: this.editable(true, rolePermission),
            },
          ],
        },
        {
          headerValueGetter() {
            return that.$i18n.t('ticketing.ticketInformation');
          },
          children: [
            {
              colId: 'ticketing.ticketingDate',
              headerValueGetter() {
                return that.$i18n.t('common.date');
              },
              field: 'ticketingDate',
              cellClass: 'date-cell separator',
              valueFormatter(params) {
                return that.$moment(params.data.ticketingDate).format('DD/MM/YYYY HH:mm');
              },
              minWidth: 20,
              width: 20,
            },
            {
              colId: 'ticketing.serialNumber',
              headerValueGetter() {
                return that.$i18n.t('ticketing.ticketNumber');
              },
              field: 'serialNumber',
              width: 21,
              cellRendererFramework: AgGridValueBaseCellRenderer,
            },
            {
              colId: 'ticketing.status',
              headerValueGetter() {
                return that.$i18n.t('common.status');
              },
              field: 'status',
              cellClass: 'airline-from-cell ticket-status-cell',
              valueGetter(params) {
                return params.data.status;
              },
              cellRendererFramework: AgGridFlightStatusCellRenderer,
              cellRendererParams: {
                resizable: false,
              },
              editable(params) {
                return params.data.status === 'CANCELLED';
              },
              cellEditor: 'AgGridTicketingFlightsDialogStatusCellEditor',
              cellEditorParams(params) {
                return {
                  cancellationReason: params.data.cancellationReason,
                  cancellationDate: params.data.cancellationDate,
                };
              },
              width: 12,
            },
            {
              colId: 'ticketing.authorizationNumber',
              headerValueGetter() {
                return that.$i18n.t('ticketing.authorizationNumber');
              },
              field: 'authorizationNumber',
              cellRendererFramework: AgGridValueBaseCellRenderer,
              width: 21,
            },
            {
              colId: 'ticketing.locator',
              headerValueGetter() {
                return that.$i18n.t('ticketing.locator');
              },
              field: 'locator',
              cellRendererFramework: AgGridValueBaseCellRenderer,
              width: 21,
            },
            {
              colId: 'ticketing.comments',
              headerValueGetter() {
                return that.$i18n.t('common.comments');
              },
              field: 'comments',
              cellRendererFramework: AgGridValueBaseCellRenderer,
              width: 21,
            },
            {
              colId: 'ticketing.user',
              headerValueGetter() {
                return that.$i18n.t('ticketing.user');
              },
              field: 'user',
              cellRendererFramework: AgGridValueBaseCellRenderer,
              width: 21,
            },
          ],
        },
        {
          headerValueGetter() {
            return that.$i18n.t('commonForm.flightInformation');
          },
          children: [
            {
              colId: 'ticketing.flight.flightNo',
              headerValueGetter() {
                return that.$i18n.t('common.flight');
              },
              cellClass: 'flight-number-cell separator',
              valueGetter(params) {
                return params.data.flightNo();
              },
              cellRendererFramework: AgGridValueBaseCellRenderer,
              width: 8,
            },
            {
              colId: 'ticketing.flight.flightDate',
              headerValueGetter() {
                return that.$i18n.t('common.date');
              },
              field: 'flightDate',
              cellClass: 'date-cell separator',
              valueFormatter(params) {
                return that.$moment(params.data.flightDate).format('DD/MM/YYYY');
              },
              width: 12,
            },
            {
              colId: 'ticketing.flight.scheduledTime',
              headerValueGetter() {
                return that.$i18n.t('common.scheduledTimeOfDeparture');
              },
              field: 'scheduledTime',
              cellClass: 'airline-from-cell',
              colCustomConfig: {
                oldValueFormatter: agGridTimeFormatter,
              },
              valueGetter(params) {
                return that.agGridTimeGetter(params.data.scheduledTime, params.data.flightDate);
              },
              valueFormatter: agGridTimeFormatter,
              cellRendererFramework: AgGridTimeCellRenderer,
              width: 5,
            },
            {
              colId: 'ticketing.flight.airport',
              headerValueGetter() {
                return that.$i18n.t('common.to');
              },
              cellClass: 'airport-from-cell',
              valueGetter(params) {
                return params.data.airport1Code;
              },
              width: 5,
            },
          ],
        },
        {
          headerValueGetter() {
            return that.$i18n.t('ticketing.airlineFareInformation');
          },
          children: [
            {
              colId: 'ticketing.airlineFareName',
              headerValueGetter() {
                return that.$i18n.t('ticketing.fare');
              },
              field: 'airlineFareName',
              cellRendererFramework: AgGridValueBaseCellRenderer,
              width: 21,
            },
            {
              colId: 'ticketing.quantity',
              headerValueGetter() {
                return that.$i18n.t('ticketing.quantity');
              },
              field: 'quantity',
              cellRendererFramework: AgGridValueBaseCellRenderer,
              width: 5,
            },
            {
              colId: 'ticketing.airlineFareCost',
              headerValueGetter() {
                return that.$i18n.t('ticketing.fareCost');
              },
              field: 'airlineFareCost',
              valueGetter(params) {
                return {
                  value: params.data.airlineFareCost,
                  currency: params.data.airlineFareCurrency,
                };
              },
              cellRendererFramework: AgGridCurrencyCellRenderer,
              width: 21,
            },
            {
              colId: 'ticketing.paymentType',
              headerValueGetter() {
                return that.$i18n.t('ticketing.paymentType');
              },
              field: 'paymentType',
              filterParams: {
                textCustomComparator: agGridPaymentTypeFilterComparator,
              },
              cellRendererFramework: AgGridPaymentTypeCellRenderer,
              width: 20,
            },
            {
              colId: 'ticketing.tpvType',
              headerValueGetter() {
                return that.$i18n.t('ticketing.tpvType');
              },
              field: 'tpvType',
              cellRendererFramework: AgGridTpvTypeCellRenderer,
              width: 15,
            },
            {
              colId: 'ticketing.total',
              headerValueGetter() {
                return that.$i18n.t('ticketing.total');
              },
              field: 'realQuantity',
              valueGetter(params) {
                return {
                  value: params.data.realQuantity,
                  currency: params.data.airlineFareCurrency,
                };
              },
              cellRendererFramework: AgGridCurrencyCellRenderer,
              cellClass: 'currency-cell',
              width: 20,
            },
            {
              colId: 'ticketing.cashQuantity',
              headerValueGetter() {
                return that.$i18n.t('ticketing.cashQuantity');
              },
              field: 'cashQuantity',
              valueGetter(params) {
                return {
                  value: params.data.cashQuantity,
                  currency: params.data.airlineFareCurrency,
                };
              },
              cellRendererFramework: AgGridCurrencyCellRenderer,
              cellClass: 'currency-cell',
              width: 20,
            },
            {
              colId: 'ticketing.cardQuantity',
              headerValueGetter() {
                return that.$i18n.t('ticketing.cardQuantity');
              },
              field: 'cardQuantity',
              valueGetter(params) {
                return {
                  value: params.data.cardQuantity,
                  currency: params.data.airlineFareCurrency,
                };
              },
              cellRendererFramework: AgGridCurrencyCellRenderer,
              cellClass: 'currency-cell',
              width: 20,
            },
          ],
        },
        {
          headerName: '',
          width: 100,
          cellClass: 'text-center',
          field: 'id',
          suppressMenu: true,
          sortable: false,
          suppressMovable: true,
          suppressSizeToFit: true,
          filter: false,
          resizable: false,
          suppressNavigable: true,
          colCustomConfig: {
            exportCsvDisabled: true,
          },
          cellRendererFramework: AgGridRowActionButtonsTicketingCellRenderer,
          cellRendererParams: {
            isEditable: that.hasPermissions(rolePermission),
            isEmitted: params => (params.data.status != null),
            isCancellable: params => (params.data.status !== TicketingStatus.CANCELLED.description),
            deleteItem: this.deleteItem,
            editItem: this.editItem,
            cancelItem: this.cancelItem,
            showItem: this.showItem,
          },
        },
      ],
      // TODO El objetivo es que el columnDefs para el total se genere a partir del columnDefs de la tabla, en colCustomConfig se defina una propiedad colTotalCustomConfig en la que se indique el field de total, el valueGetter si se debe sobrescribir, asi no tenemos definidos dos columnDefs y es ma facil añadir o eliminar columnas
      columnDefsT: [
        {
          headerName: '',
          children: [
            {
              headerTooltip: 'Check/uncheck all',
              hide: !this.hasPermissions(rolePermission),
              checkboxSelection: false,
              width: 21,
              minWidth: 20,
              headerCheckboxSelection: true,
              headerCheckboxSelectionFilteredOnly: true,
              suppressMenu: true,
              sortable: false,
              suppressMovable: true,
              suppressSizeToFit: true,
              filter: false,
              resizable: false,
              colCustomConfig: {
                exportCsvDisabled: true,
              },
              editable: this.editable(true, rolePermission),
            },
          ],
        },
        {
          headerValueGetter() {
            return that.$i18n.t('ticketing.ticketInformation');
          },
          children: [
            {
              colId: 'ticketing.ticketingDate',
              headerValueGetter() {
                return that.$i18n.t('common.date');
              },
              field: 'totalTickets',
              cellClass: 'date-cell separator',
              minWidth: 20,
              width: 20,
            },
            {
              colId: 'ticketing.serialNumber',
              headerValueGetter() {
                return that.$i18n.t('ticketing.ticketNumber');
              },
              width: 21,
            },
            {
              colId: 'ticketing.status',
              headerValueGetter() {
                return that.$i18n.t('common.status');
              },
              cellClass: 'airline-from-cell',
              width: 12,
            },
            {
              colId: 'ticketing.authorizationNumber',
              headerValueGetter() {
                return that.$i18n.t('ticketing.authorizationNumber');
              },
              width: 21,
            },
            {
              colId: 'ticketing.locator',
              headerValueGetter() {
                return that.$i18n.t('ticketing.locator');
              },
              width: 21,
            },
            {
              colId: 'ticketing.comments',
              headerValueGetter() {
                return that.$i18n.t('common.comments');
              },
              width: 21,
            },
            {
              colId: 'ticketing.user',
              headerValueGetter() {
                return that.$i18n.t('ticketing.user');
              },
              width: 21,
            },
          ],
        },
        {
          headerValueGetter() {
            return that.$i18n.t('commonForm.flightInformation');
          },
          children: [
            {
              colId: 'ticketing.flight.flightNo',
              headerValueGetter() {
                return that.$i18n.t('common.flight');
              },
              cellClass: 'flight-number-cell separator',
              cellRendererFramework: AgGridValueBaseCellRenderer,
              width: 8,
            },
            {
              colId: 'ticketing.flight.flightDate',
              headerValueGetter() {
                return that.$i18n.t('common.date');
              },
              cellClass: 'date-cell separator',
              width: 12,
            },
            {
              colId: 'ticketing.flight.scheduledTime',
              headerValueGetter() {
                return that.$i18n.t('common.scheduledTimeOfDeparture');
              },
              cellClass: 'airline-from-cell',
              width: 5,
            },
            {
              colId: 'ticketing.flight.airport',
              headerValueGetter() {
                return that.$i18n.t('common.to');
              },
              cellClass: 'airport-from-cell',
              width: 5,
            },
          ],
        },
        {
          headerValueGetter() {
            return that.$i18n.t('ticketing.airlineFareInformation');
          },
          children: [
            {
              colId: 'ticketing.airlineFareName',
              headerValueGetter() {
                return that.$i18n.t('ticketing.fare');
              },
              width: 21,
            },
            {
              colId: 'ticketing.quantity',
              headerValueGetter() {
                return that.$i18n.t('ticketing.quantity');
              },
              width: 5,
            },
            {
              colId: 'ticketing.airlineFareCost',
              headerValueGetter() {
                return that.$i18n.t('ticketing.fareCost');
              },
              width: 21,
            },
            {
              colId: 'ticketing.paymentType',
              headerValueGetter() {
                return that.$i18n.t('ticketing.paymentType');
              },
              field: 'totalCash',
              width: 20,
            },
            {
              colId: 'ticketing.total',
              headerValueGetter() {
                return that.$i18n.t('ticketing.total');
              },
              field: 'totalAmountCash',
              cellClass: 'currency-cell',
              width: 20,
            },
            {
              colId: 'ticketing.cashQuantity',
              headerValueGetter() {
                return that.$i18n.t('ticketing.cashQuantity');
              },
              field: 'totalCard',
              width: 20,
            },
            {
              colId: 'ticketing.cardQuantity',
              headerValueGetter() {
                return that.$i18n.t('ticketing.cardQuantity');
              },
              field: 'totalAmountCard',
              width: 20,
            },
          ],
        },
        {
          headerName: '',
          width: 100,
          cellClass: 'text-center',
          field: 'id',
          suppressMenu: true,
          sortable: false,
          suppressMovable: true,
          suppressSizeToFit: true,
          filter: false,
          resizable: false,
          suppressNavigable: true,
          colCustomConfig: {
            exportCsvDisabled: true,
          },
        },
      ],
    };
  },
  methods: {
    deleteItem(item) {
      this.$emit('delete-item', item);
    },
    editItem(item) {
      this.$emit('edit-item', item);
    },
    showItem(item) {
      this.$emit('show-item', item);
    },
    cancelItem(item) {
      this.$emit('cancel-item', item);
    },
    onGridReady(params) {
      this.setAgGridApi(params);
      this.showLoading();
      this.$store.commit(TICKETING_CHANGE_GRID_API, params); // MOVE TO MIXIN?
      this.refreshCellsOnMutation();
      this.refreshCellsOnChangeLanguage();
    },
    onSelectionChanged(event) {
      const selectedNodes = event.api.getSelectedNodes();
      const selectedData = selectedNodes.map(node => node.data);
      this.$store.commit(TICKETING_CHANGE_FLIGHT_SELECTION, selectedData);
    },
    resized() {
      if (this.gridApi) {
        this.gridApi.sizeColumnsToFit();
      }
    },
    renderNeededDiffs(differences, rowNode) {
      const { gridApi } = this.$store.state.ticketing;

      const agGridCustomService = new AgGridCustomService(this.columnDefs); // TODO one time
      const colIds = agGridCustomService.getColIdByMatchAnyfieldsRelated(this.joinAllPropertyPath(differences));

      if (colIds && colIds.length > 0) {
        colIds.forEach((colId) => {
          if (gridApi.getColumnDef(colId)) {
            gridApi.refreshCells({
              rowNodes: [rowNode],
              columns: [colId],
              force: true,
            });

            gridApi.flashCells({
              rowNodes: [rowNode],
              columns: [colId],
              force: true,
            });
          }
        });
      }
    },
    sizeColumnsToFit() {
      if (this.gridApi) {
        this.$store.dispatch(TICKETING_FIT_COLUMNS_TO_VIEW);
      }
    },
    firstDataRendered() {
      this.$nextTick(() => {
        this.resized();
      });
    },
  },
  mounted() {
    this.gridOptions.alignedGrids.push(this.gridOptionsTotal);
    this.gridOptionsTotal.alignedGrids.push(this.gridOptions);
  },
};
</script>
