<template>
  <div>
    <v-autocomplete
      placeholder="..."
      :value="value"
      :label="label"
      :items="items"
      :rules="rules"
      :disabled="disabled"
      :clearable="true"
      @input="inputStatus($event)"
      :ref="refComp"
      hide-details
    >
      <template slot="item" slot-scope="{ item }" >
        <v-list-tile-content>
          <v-list-tile-title>{{ item }}</v-list-tile-title>
        </v-list-tile-content>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>

export default {
  name: 'ticketing-status-select',
  props: {
    value: { required: true },
    label: { type: String },
    charLenght: { type: Number },
    rules: { type: Array },
    disabled: { type: Boolean },
    multiple: {
      type: Boolean,
      default: false,
    },
    refComp: {
      type: String,
      required: false,
      default: 'refComp',
    },
  },
  data() {
    return {
      items: ['OK', 'CANCELLED'],
    };
  },
  methods: {
    inputStatus(event) {
      this.$emit('input', event);
    },
  },
};
</script>
