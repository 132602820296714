/* eslint no-param-reassign: "off" */
import avinet from './avinet';
import fileNumber from './fileNumber';
import ghm from './ghm';
import inform from './inform';
import sftp from './sftp';
import pmi from './pmi';
import dus from './dus';

// modules
const storeModules = {
  avinet,
  fileNumber,
  ghm,
  inform,
  sftp,
  pmi,
  dus,
};

// initial state
const storeState = {
};

// getters
const storeGetters = {
};

// actions
const storeActions = {
};

// mutations
const storeMutations = {
};

export default {
  modules: storeModules,
  state: storeState,
  getters: storeGetters,
  actions: storeActions,
  mutations: storeMutations,
};
