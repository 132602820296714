<template>
  <ag-grid-template-cell-renderer :params="params">
    <span v-if="isCheck">{{ this.params.value }}</span>
    <span v-if="!isCheck">-</span>
  </ag-grid-template-cell-renderer>
</template>

<script>
import Vue from 'vue';
import AgGridTemplateCellRenderer from './templates/AgGridTemplateCellRenderer.vue';

export default Vue.extend({
  components: {
    AgGridTemplateCellRenderer,
  },
  computed: {
    isCheck() {
      return this.params.value;
    },
  },
});
</script>
