<template>
  <div>
    <v-flex v-flex-none>
      <v-text-field :ref="refNumberInput" v-model="updatedNumber" type="number" min="0" ></v-text-field>
    </v-flex>
  </div>
</template>

<script>
import Vue from 'vue';
import { REQUEST_ERROR, REQUEST_START, REQUEST_SUCCESS } from '../../../../store/store';
import { NOTIFICATION_SHOW } from '../../../../store/modules/notifications';
import { JSUtils } from '../../../shared/js/JSUtils';
import AgGridParentConnectedFlightsCellEditor from '../mixins/AgGridParentConnectedFlightsCellEditor.vue';

export default Vue.extend({
  mixins: [AgGridParentConnectedFlightsCellEditor],
  components: {
  },
  props: {
    refNumberInput: {
      type: String,
      required: false,
      default: 'refNumberInput',
    },
  },
  data() {
    return {
      originalNumber: null,
      updatedNumber: null,
    };
  },
  methods: {
    getValue() {
      this.unmarkCellsSelectedAsEdit();
      if (this.originalNumber !== this.updatedNumber) {
        const that = this;
        const connectedFlightPropertyName = this.getConnectedFlightsPropertyName();
        this.saveForValueOrAllSelectedFlights((s) => {
          s.setChildPropertyValueByBound(that.params.column.colDef.colCustomConfig.flightBound, connectedFlightPropertyName, that.updatedNumber);
        }, connectedFlightPropertyName);
      }
      return this.updatedNumber;
    },
    saveForValueOrAllSelectedFlights(action, field, callback) {
      const selectedRows = this.getSelectedRowsOrCurrentRow();
      selectedRows.forEach((s) => {
        action(s);
      });

      this.saveFlightPassageUm(selectedRows, field, callback);
    },
    saveFlightPassageUm(selectedRows, field, callback) {
      this.requestInit();
      const lastIteration = (index, array) => index === array.length - 1;

      if (selectedRows && selectedRows.length > 0) {
        selectedRows.forEach((s, index, array) => {
          this.$http.patch(`flights/${s.inbound.common.id}/um`, { um: this.updatedNumber })
            .then(() => {
              if (lastIteration(index, array)) {
                this.requestSuccessCallback(this);
                this.params.api.refreshCells({
                  force: true,
                  columns: [this.params.column],
                });
                if (callback && JSUtils.isFunction(callback)) {
                  callback();
                }
              }
            }).catch(error => this.requestErrorCallback(this, error));
        });
      }
    },
    requestInit() {
      this.$store.commit(REQUEST_START);
    },
    requestSuccessCallback: (context) => {
      context.$store.commit(REQUEST_SUCCESS);
      context.$store.commit(NOTIFICATION_SHOW, {
        message: context.$i18n.t('notifications.lostAndFoundUpdate'),
        color: 'success',
      });
    },
    requestErrorCallback: (context, err) => {
      context.$store.commit(REQUEST_ERROR, err);
      context.$store.commit(NOTIFICATION_SHOW, {
        message: context.$i18n.t('notifications.errorNotification'),
        color: 'error',
      });
    },
  },
  created() {
    this.originalNumber = this.params.value ? this.params.value : null;
    this.updatedNumber = this.originalNumber;

    this.markCellsSelectedAsEdit();
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$refs.refNumberInput) {
        this.$refs.refNumberInput.$refs.input.focus();
      }
    });
  },
});
</script>

<style>

</style>
