<template>
  <v-layout row wrap v-if="aircraftWithoutAircraftType.length > 0" style="margin:0; padding:0;">
    <v-flex xs12>
      <div>
        <v-icon color="primary">mdi-alert</v-icon>
        <span class="font-weight-bold">{{$t("rampServiceImport.aircraftWithoutAircraftType")}}</span>
      </div>
      <ul>
        <li v-for="(aircarft, index) in aircraftWithoutAircraftType" :key="index" class="text-error">
          {{ aircarft }}
        </li>
      </ul>
    </v-flex>
  </v-layout>
</template>
<script>

export default {
  name: 'ramp-service-import-step-file-validation-catalog-content',
  components: {
  },
  props: {
    summaryRampServices: {
      type: Object,
    },
  },
  computed: {
    aircraftWithoutAircraftType() {
      return this.summaryRampServices ? this.summaryRampServices.aircraftWithoutAircraftType : [];
    },
  },
  watch: {
    aircraftWithoutAircraftType: {
      handler() {
        this.isValidData();
      },
      deep: true,
    },
  },
  data() {
    return {
      validData: true,
    };
  },
  methods: {
    isValidData() {
      this.validData = (!this.aircraftWithoutAircraftType || this.aircraftWithoutAircraftType.length === 0);
      this.$emit('dataCatalogsImportOptionValidation', this.validData);
    },
  },
  mounted() {
    this.isValidData();
  },
};
</script>
<style lang="scss">

</style>
