<template>
  <div class="talleying-cash list-filter">
    <v-card :dark="this.$vuetify.dark" :color="this.$vuetify.dark ? 'bggrey_dark' : 'bggrey'">
      <v-toolbar dark dense color="primary lighten-1">
        <v-btn v-on:click="displayedFilter" small flat>
          <v-icon>filter_list</v-icon>
          <v-icon v-if="!showFilter">keyboard_arrow_down</v-icon>
          <v-icon v-if="showFilter">keyboard_arrow_up</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-flex grow="false" shrink="true">
          <v-btn-toggle v-model="unitOfTime" class="transparent" @change="filterChanged">
            <v-tooltip left>
              <v-btn value="day" flat slot="activator">{{ $t("commonFilter.filterD") }}</v-btn>
              <span>{{ $t("commonFilter.filterDay") }}</span>
            </v-tooltip>
            <v-tooltip left>
              <v-btn value="isoWeek" flat slot="activator">{{ $t("commonFilter.filterW") }}</v-btn>
              <span>{{ $t("commonFilter.filterWeek") }}</span>
            </v-tooltip>
            <v-tooltip left>
              <v-btn value="month" flat slot="activator">{{ $t("commonFilter.filterM") }}</v-btn>
              <span>{{ $t("commonFilter.filterMonth") }}</span>
            </v-tooltip>
            <v-tooltip left>
              <v-btn value="year" flat slot="activator">{{ $t("commonFilter.filterY") }}</v-btn>
              <span>{{ $t("commonFilter.filterYear") }}</span>
            </v-tooltip>
          </v-btn-toggle>
        </v-flex>
        <TallyingCashListTableOptionsFilterButton />
      </v-toolbar>
      <v-container fluid v-if="showFilter" class="pa-2 pb-2">
        <v-layout class="filter-inline py-0" align-center justify-start>
          <date-input v-model="filter.dateFrom" :locale="$i18n.locale" :label="$t('common.fromDate')"
            @change="changeDateFrom"></date-input>

          <date-input class="ml-2" v-model="filter.dateTo" :locale="$i18n.locale" :label="$t('common.toDate')"
            @change="changeDateTo"></date-input>
          <v-flex class="ml-2 text-no-wrap" align-center justify-start>
            <airline-select style="width: 200px" :label="this.$i18n.t('common.airlineOp')" v-model="filter.airlineId"
              @input="filterChanged" :filter-handling-active="true"></airline-select>
          </v-flex>
          <v-spacer style="width: 45%"></v-spacer>
          <v-flex class="ml-2 text-no-wrap" align-center justify-start>
            <v-btn color="secondary" flat round v-on:click.stop="exportCSV" block>
              <v-icon>mdi-download</v-icon>
              {{ $t("common.export") }}
            </v-btn>
          </v-flex>
          <v-flex class="ml-2 text-no-wrap" align-center justify-start>
            <v-btn color="primary" round v-on:click.stop="find" block>
              <v-icon>search</v-icon>
              {{ $t("common.find") }}
            </v-btn>
          </v-flex>
        </v-layout>
        <v-alert type="info" color="blue" :value="true" class="py-1" outline dismissible>
          {{ $t("ticketing.infoTallyingCashReport") }}
        </v-alert>
      </v-container>
    </v-card>
  </div>

</template>
<script>
import AirlineSelect from '../../airline/AirlineSelect.vue';
import DateInput from '../../ui/DateInput.vue';
import AgGridApiCsvExportable from '../../ag-grid/mixins/AgGridApiCsvExportable.vue';
import StoreSubscribable from '../../mixins/StoreSubscribable.vue';
import {
  TALLYING_CASH_FIND,
  TALLYING_CASH_FILTER_SET_UNIT_TIME,
  TALLYING_CASH_CHANGE_GRID_API, TALLYING_CASH_RECALCULATE_FILTER, TALLYING_CASH_FILTER_SET_TENANT,
} from '../../../store/modules/ticketingReports/tallyingCash';
import { TENANT_CURRENT_SET_SUCCESS } from '../../../store/modules/tenant';
import { TIME_TIMEZONE_CHANGED } from '../../../store/modules/time';
import TallyingCashListTableOptionsFilterButton from './TallyingCashListTableOptionsFilterButton.vue';
import { ROLE_CASHIER_TICKETING } from '../../../constants/roles';

export default {
  name: 'tallying-cash-list-filter',
  mixins: [AgGridApiCsvExportable, StoreSubscribable],
  components: {
    AirlineSelect,
    DateInput,
    TallyingCashListTableOptionsFilterButton,
  },
  props: ['value'],
  data() {
    return {
      showFilter: true,
      filterSummary: null,
    };
  },
  computed: {
    filter() {
      return this.$store.state.ticketingReports.tallyingCash.filter;
    },
    unitOfTime: {
      get() {
        return this.filter.unitOfTime;
      },
      set(value) {
        this.$store.commit(TALLYING_CASH_FILTER_SET_UNIT_TIME, value);
      },
    },
    authUser() {
      return this.$store.state.auth.authUser;
    },
    ticketingUserRole() {
      return this.$store.state.ticketing.ticketingUserRole;
    },
    isTenantSetted() {
      return this.$store.getters.isTenantSetted;
    },
    tenantId() {
      return this.isTenantSetted
        ? this.$store.state.tenant.currentTenant.id
        : null;
    },
  },
  watch: {
    ticketingUserRole() {
      this.checkIfUserIsCashier();
      // this.find();
    },
  },
  methods: {
    filterChanged() {
      // this.find();
    },
    find() {
      this.showLoading();
      this.$store.dispatch(TALLYING_CASH_FIND);
    },
    exportCSV() {
      this.exportAgGridCsv('TallyingCash');
    },
    changeDateFrom() {
      this.unitOfTime = null;
      const from = this.$moment(this.filter.dateFrom);
      const to = this.$moment(this.filter.dateTo);

      this.filter.dateFrom = from.startOf('day');
      if (from.isAfter(to)) {
        this.filter.dateTo = this.$moment(this.filter.dateFrom).endOf('day');
      }
      this.filterChanged();
    },
    changeDateTo() {
      this.unitOfTime = null;
      const from = this.$moment(this.filter.dateFrom);
      const to = this.$moment(this.filter.dateTo);

      this.filter.dateTo = to.endOf('day');
      if (to.isBefore(from)) {
        this.filter.dateFrom = this.$moment(this.filter.dateTo).startOf('day');
      }
      this.filterChanged();
    },
    displayedFilter() {
      this.showFilter = !this.showFilter;
      this.$emit('changeShowFilter', this.showFilter);
    },
    checkIfUserIsCashier() {
      if (this.ticketingUserRole === ROLE_CASHIER_TICKETING) {
        this.filter.user = { equals: this.authUser.login };
      } else {
        this.filter.user = null;
      }
    },
  },
  mounted() {
    const that = this;
    this.filter.tenantId = this.tenantId;
    this.subscribeMutation(TALLYING_CASH_CHANGE_GRID_API, (mutationPayload) => {
      that.setAgGridApi(mutationPayload);
    });
    this.subscribeMutation(TIME_TIMEZONE_CHANGED, () => {
      this.$store.dispatch(TALLYING_CASH_RECALCULATE_FILTER);
      this.find();
    });
    this.subscribeMutation(TENANT_CURRENT_SET_SUCCESS, () => {
      this.$store.commit(TALLYING_CASH_FILTER_SET_TENANT, this.tenantId);
      this.find();
    });
    this.$store.dispatch(TALLYING_CASH_RECALCULATE_FILTER);
    this.checkIfUserIsCashier();
    this.find();
  },
};
</script>
