export class SchedulingImportSummaryResult {
  constructor(
    importId,
    flightsSameUniqueKey,
    flightsPossiblySame,
    newAircrafts,
    newAircraftTypes,
    newAircraftConfigs,
    newAirlines,
    newAirports,
    newHandlingTypes,
    newServiceTypes,
    aircraftAircraftTypeCatalogMismatch,
    serviceTypeMismatches,
  ) {
    this.importId = importId;
    this.flightsSameUniqueKey = flightsSameUniqueKey;
    this.flightsPossiblySame = flightsPossiblySame;
    this.newAircrafts = newAircrafts;
    this.newAircraftTypes = newAircraftTypes;
    this.newAircraftConfigs = newAircraftConfigs;
    this.newAirlines = newAirlines;
    this.newAirports = newAirports;
    this.newHandlingTypes = newHandlingTypes;
    this.newServiceTypes = newServiceTypes;
    this.aircraftAircraftTypeCatalogMismatch = aircraftAircraftTypeCatalogMismatch;
    this.serviceTypeMismatches = serviceTypeMismatches;
  }

  static createSchedulingImportSummaryResultFromData(data) {
    return new SchedulingImportSummaryResult(
      data.importId,
      data.flightsSameUniqueKey,
      data.flightsPossiblySame,
      data.newAircrafts,
      data.newAircraftTypes,
      data.newAircraftConfigs,
      data.newAirlines,
      data.newAirports,
      data.newHandlingTypes,
      data.newServiceTypes,
      data.aircraftAircraftTypeCatalogMismatch,
      data.serviceTypeMismatches,
    );
  }
}
