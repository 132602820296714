import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VStepper } from 'vuetify/lib/components/VStepper';
import { VStepperContent } from 'vuetify/lib/components/VStepper';
import { VStepperHeader } from 'vuetify/lib/components/VStepper';
import { VStepperItems } from 'vuetify/lib/components/VStepper';
import { VStepperStep } from 'vuetify/lib/components/VStepper';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VStepper,{model:{value:(_vm.step),callback:function ($$v) {_vm.step=$$v},expression:"step"}},[_c(VStepperHeader,[_c(VStepperStep,{attrs:{"step":"1","complete":_vm.step > 1,"rules":[() => _vm.isValidCriteria]}},[_vm._v(" "+_vm._s(_vm.$t("scheduling.linkChooseCriteria"))+" "),(!_vm.isValidCriteria)?_c('small',[_vm._v(_vm._s(_vm.$t("scheduling.linkIncorrectCriteria")))]):_vm._e()]),_c(VDivider),_c(VStepperStep,{attrs:{"step":"2","complete":_vm.step > 2}},[_vm._v(" "+_vm._s(_vm.$t("scheduling.linkChangesSummary"))+" ")])],1),_c('loading-component'),_c(VStepperItems,[_c(VStepperContent,{attrs:{"step":"1"}},[_c(VCard,[_c(VCardText,[_c('scheduling-link-step-options',{attrs:{"link-options":_vm.linkOptions},on:{"update:linkOptions":function($event){_vm.linkOptions=$event},"update:link-options":function($event){_vm.linkOptions=$event}}})],1),_c(VCardActions,[_c(VBtn,{attrs:{"flat":"","color":"primary","disabled":_vm.loading},on:{"click":_vm.cancel}},[_vm._v(_vm._s(_vm.$t("common.cancel")))]),_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","disabled":_vm.loading || !_vm.isValidCriteria},on:{"click":_vm.step1Next}},[_vm._v(_vm._s(_vm.$t("common.continue")))])],1)],1)],1),_c(VStepperContent,{attrs:{"step":"2"}},[_c(VCard,[_c(VCardText,[_vm._v(" "+_vm._s(_vm.$t("scheduling.linkFlightsAlert"))+" "),(_vm.step === 2)?_c('connected-flights-base-grid',{attrs:{"items":_vm.summaryItems},on:{"selected-changed":_vm.selectedChanged}}):_vm._e()],1),_c(VCardActions,[_c(VBtn,{attrs:{"flat":"","color":"primary","disabled":_vm.loading},on:{"click":_vm.cancel}},[_vm._v(_vm._s(_vm.$t("common.cancel")))]),_c(VSpacer),_c(VBtn,{attrs:{"flat":"","color":"primary","disabled":_vm.loading},on:{"click":_vm.step2Back}},[_vm._v(_vm._s(_vm.$t("common.back")))]),_c(VBtn,{attrs:{"color":"primary","disabled":_vm.loading || _vm.summaryItems.length == 0},on:{"click":_vm.confirm}},[_vm._v(_vm._s(_vm.$t("common.confirm")))])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }