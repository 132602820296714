import moment from 'moment-timezone';

export default class MobileFlightUtil {
  static getFilterFlights(filter, mobileNavFilter, mobileFlights) {
    const ret = [];
    mobileFlights.forEach((flight) => {
      if (mobileNavFilter === 'all') {
        ret.push(flight);
      } else if (mobileNavFilter === 'arrival' && flight.bound === 'INBOUND') {
        ret.push(flight);
      } else if (mobileNavFilter === 'departures' && flight.bound === 'OUTBOUND') {
        ret.push(flight);
      }
    });

    return ret;
  }

  static setTimeToBackend(time, timezone) {
    let momentDateTime = null;
    if (moment(time, 'HH:mm', true).isValid()) {
      momentDateTime = moment(time, 'HH:mm', true);
    } else if (moment(time, 'HH:mm:ss', true).isValid()) {
      momentDateTime = moment(time, 'HH:mm:ss', true);
    }
    let fixedTime = null;
    if (momentDateTime === null) {
      fixedTime = null;
    } else if (timezone === 'UTC') {
      fixedTime = momentDateTime.utc().format('HH:mm');
    } else {
      const offset = moment.tz(timezone).utcOffset();
      fixedTime = momentDateTime.utc().add(-offset, 'minutes').format('HH:mm');
    }
    return fixedTime;
  }

  static setDateTimeToBackend(dateTime, timezone) {
    // get the dateTime in the correct format
    let momentDateTime = null;
    if (moment(dateTime, 'YYYY-MM-DDTHH:mm', true).isValid()) {
      momentDateTime = moment(dateTime, 'YYYY-MM-DDTHH:mm', true);
    } else if (moment(dateTime, 'YYYY-MM-DD HH:mm', true).isValid()) {
      momentDateTime = moment(dateTime, 'YYYY-MM-DD HH:mm', true);
    }
    // fix the timezone
    let fixedDateTime = null;
    if (momentDateTime === null) {
      fixedDateTime = null;
    } else if (timezone === 'UTC') {
      fixedDateTime = momentDateTime.utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
    } else {
      const offset = moment.tz(timezone).utcOffset();
      fixedDateTime = momentDateTime.add(-offset, 'minutes').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
    }
    return fixedDateTime;
  }

  static setTimeToDisplay(timeUTC, timezone) {
    let fixedTime = null;
    if (timeUTC === null) {
      fixedTime = null;
    } else if (timezone === 'UTC') {
      fixedTime = this.setUtcTime(timeUTC);
    } else {
      fixedTime = this.setLocalTime(timeUTC, timezone);
    }
    return fixedTime;
  }

  static setLocalTime(timeUTC, timezone) {
    let momentDateTime = null;
    if (moment(timeUTC, 'HH:mm', true).isValid()) {
      momentDateTime = moment(timeUTC, 'HH:mm', true);
    } else if (moment(timeUTC, 'HH:mm:ss', true).isValid()) {
      momentDateTime = moment(timeUTC, 'HH:mm:ss', true);
    } else if (moment(timeUTC).isValid()) {
      momentDateTime = moment(timeUTC).utc();
    }
    const offset = moment.tz(timezone).utcOffset();
    return momentDateTime ? momentDateTime.add(offset, 'minutes').format('HH:mm') : null;
  }


  static setUtcTime(timeUTC) {
    let momentDateTime = null;
    if (moment(timeUTC, 'HH:mm', true).isValid()) {
      momentDateTime = moment(timeUTC, 'HH:mm', true);
    } else if (moment(timeUTC, 'HH:mm:ss', true).isValid()) {
      momentDateTime = moment(timeUTC, 'HH:mm:ss', true);
    } else if (moment(timeUTC).isValid()) {
      momentDateTime = moment(timeUTC);
    }
    return momentDateTime ? momentDateTime.utc().format('HH:mm') : null;
  }

  static getNowTime(timezone) {
    const now = moment();
    return now.tz(timezone).format('HH:mm');
  }

  static parseFloatNullable(value) {
    if (value === null || value === undefined || value === '' || value === 'NaN') {
      return null;
    }
    try {
      return parseFloat(value);
    } catch (e) {
      return null;
    }
  }

  static parseFloatZero(value) {
    if (value === null || value === undefined || value === '' || value === 'NaN') {
      return 0;
    }
    try {
      return parseFloat(value);
    } catch (e) {
      return 0;
    }
  }
}
