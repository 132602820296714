/* eslint no-param-reassign: "off" */
/* eslint no-confusing-arrow: "off" */
/* eslint no-nested-ternary: "off" */

import { HTTP } from '../../http';
import { REQUEST_ERROR, REQUEST_START, REQUEST_SUCCESS } from '../store';
import { NOTIFICATION_SHOW } from './notifications';
import i18n from '../../plugins/i18n';
import TicketingNumeration from '../../components/configuration/ticketingNumeration/model/ticketingNumeration';

// actions names
export const TICKETING_NUMERATION_GET = 'TICKETING_NUMERATION_GET';
export const TICKETING_NUMERATION_CREATE = 'TICKETING_NUMERATION_CREATE';
export const TICKETING_NUMERATION_UPDATE = 'TICKETING_NUMERATION_UPDATE';
export const TICKETING_NUMERATION_DELETE = 'TICKETING_NUMERATION_DELETE';

// mutations
export const TICKETING_NUMERATION_GET_SUCCESS = 'TICKETING_NUMERATION_GET_SUCCESS';

// initial state
const storeState = {
  ticketingNumeration: [],
};

// getters
const storeGetters = {
  getTicketingNumeration: state => state.ticketingNumeration,
};

// actions
const storeActions = {
  [TICKETING_NUMERATION_GET]: ({ commit }) => {
    commit(REQUEST_START);
    HTTP.get('ticketing-numeration')
      .then((resp) => {
        commit(REQUEST_SUCCESS);
        commit(TICKETING_NUMERATION_GET_SUCCESS, resp.data);
      })
      .catch((err) => {
        commit(REQUEST_ERROR, err);
      });
  },

  [TICKETING_NUMERATION_CREATE]: ({ commit, dispatch }, ticketingNumeration) => {
    commit(REQUEST_START);
    return HTTP.post('ticketing-numeration', ticketingNumeration)
      .then(() => {
        commit(REQUEST_SUCCESS);
        dispatch(TICKETING_NUMERATION_GET);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.ticketingNumerationCreate'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },

  [TICKETING_NUMERATION_UPDATE]: ({ commit, dispatch }, ticketingNumeration) => {
    commit(REQUEST_START);
    return HTTP.put('ticketing-numeration', ticketingNumeration)
      .then(() => {
        commit(REQUEST_SUCCESS);
        dispatch(TICKETING_NUMERATION_GET);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.ticketingNumerationUpdate'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
  [TICKETING_NUMERATION_DELETE]: ({ commit, dispatch }, id) => {
    commit(REQUEST_START);
    return HTTP.delete(`ticketing-numeration/${id}`)
      .then(() => {
        commit(REQUEST_SUCCESS);
        dispatch(TICKETING_NUMERATION_GET);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.ticketingNumerationDelete'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
};

// mutations
const storeMutations = {
  [TICKETING_NUMERATION_GET_SUCCESS]: (state, ticketingNumeration) => {
    state.ticketingNumeration = TicketingNumeration.createTicketingNumerationFromData(ticketingNumeration);
  },
};

export default {
  state: storeState,
  getters: storeGetters,
  actions: storeActions,
  mutations: storeMutations,
};
