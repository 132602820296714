<template>
  <div class="bg-home">
    <v-container>
      <v-layout>
        <v-flex>
          <v-img :src="require('../assets/logo.svg')" class="main-logo"></v-img>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
export default {

};
</script>
<style>

.dark-mode .bg-home {
  filter:  invert(1) hue-rotate(180deg) brightness(0.65) grayscale(100%);
}

.bg-home {
  background-image: url("../assets/photos-bg-fis-Blank/air-atmosphere-blue-314726(20_).jpg");
  max-width: 100%;
  height: 100%;
  background-size: cover;
  padding: 0;
}

.main-logo {
  padding: 0;
  margin: 0;
  position: absolute !important;
  width: 30%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -80%);
}
</style>
