import { VCardText } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCardText,[_c(VContainer,{staticStyle:{"margin-bottom":"0","padding-bottom":"0"}},[_c(VLayout,{staticStyle:{"margin-bottom":"0","padding-bottom":"0"}},[_c(VFlex,{staticStyle:{"margin-right":"2ch","width":"3vw"}},[_c(VTextField,{attrs:{"label":this.$i18n.t('configuration.iataCode'),"readonly":!_vm.hasPermissions(_vm.rolePermission)},model:{value:(_vm.item.iataCode),callback:function ($$v) {_vm.$set(_vm.item, "iataCode", $$v)},expression:"item.iataCode"}})],1),_c(VFlex,{staticStyle:{"margin-right":"2ch","width":"3vw"}},[_c(VTextField,{attrs:{"label":this.$i18n.t('configuration.icaoCode'),"readonly":!_vm.hasPermissions(_vm.rolePermission)},model:{value:(_vm.item.icaoCode),callback:function ($$v) {_vm.$set(_vm.item, "icaoCode", $$v)},expression:"item.icaoCode"}})],1),_c(VFlex,{staticStyle:{"margin-right":"2ch","width":"8vw"}},[_c(VTextField,{attrs:{"label":this.$i18n.t('configuration.name'),"readonly":!_vm.hasPermissions(_vm.rolePermission)},model:{value:(_vm.item.name),callback:function ($$v) {_vm.$set(_vm.item, "name", $$v)},expression:"item.name"}})],1),_c(VFlex,{staticStyle:{"width":"25vw"}},[_c(VTextField,{attrs:{"label":this.$i18n.t('configuration.description'),"readonly":!_vm.hasPermissions(_vm.rolePermission)},model:{value:(_vm.item.comments),callback:function ($$v) {_vm.$set(_vm.item, "comments", $$v)},expression:"item.comments"}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }