/* eslint no-param-reassign: "off" */

import moment from 'moment';
import { HTTP } from '../../http';
import { REQUEST_ERROR, REQUEST_START, REQUEST_SUCCESS } from '../store';
import FlightFilter from '../../components/ui/flightSelector/model/flightFilter';
import { JSUtils } from '../../components/shared/js/JSUtils';
import FlightCommonInformation from '../../components/scheduling/model/flightCommonInformation';

export const FIND_FLIGHTS = 'FIND_FLIGHTS';

export const FIND_FLIGHTS_SUCCESS = 'FIND_FLIGHTS_SUCCESS';

// initial state
const storeState = {
  flightsList: [],
  filter: FlightFilter.createEmptyFilter,
};

// getters
const storeGetters = {
  getFlightsSorted: state => state.flightsList.sort((x, y) => {
    if (x.scheduledTime !== null && y.scheduledTime !== null) {
      if (moment(x.scheduledTime).isBefore(moment(y.scheduledTime))) {
        return -1;
      }
      return 1;
    }
    if (x.scheduledTime !== null) {
      return 1;
    }
    if (y.scheduledTime !== null) {
      return -1;
    }
    return 0;
  }),
};

// actions
const storeActions = {
  [FIND_FLIGHTS]: ({ commit, state }) => {
    commit(REQUEST_START);
    const params = {
      dateFrom: state.filter.dateFrom,
      dateTo: state.filter.dateTo,
      bound: { equals: state.filter.bound },
      airlineId: state.filter.airlineId ? { equals: state.filter.airlineId } : null,
    };
    return HTTP.post('flights/find', params)
      .then((resp) => {
        commit(REQUEST_SUCCESS);
        commit(FIND_FLIGHTS_SUCCESS, resp.data);
      })
      .catch((err) => {
        commit(REQUEST_ERROR, err);
      });
  },
};

// mutations
const storeMutations = {
  [FIND_FLIGHTS_SUCCESS]: (state, flights) => {
    state.flightsList = JSUtils.cast(flights, FlightCommonInformation);
  },
};

export default {
  state: storeState,
  getters: storeGetters,
  actions: storeActions,
  mutations: storeMutations,
};
