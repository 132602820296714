<template>
  <v-card>
    <v-card-title>
      <v-icon class="pr-2">groups</v-icon>
      <div class="font-weight-bold">
        {{ item.dest }}
      </div>
    </v-card-title>
    <v-divider />
    <v-layout row wrap class="pa-2">
      <v-flex xs4>
        <label>{{ $t('passage.passengers') }}</label>
        <v-layout>
          <v-flex xs3>
            <v-text-field :class="classOption" :label="this.$i18n.t('operations.passengersMale')" v-model="item.male"
              @change="calculateTotalPaxOnBoard" type="number" :box="boxOption" :disabled="disabledOption" />
          </v-flex>
          <v-flex xs3>
            <v-text-field :class="classOption" :label="this.$i18n.t('operations.passengersFemale')" v-model="item.female"
              @change="calculateTotalPaxOnBoard" type="number" :box="boxOption" :disabled="disabledOption" />
          </v-flex>
          <v-flex xs3>
            <v-text-field :class="classOption" :label="this.$i18n.t('operations.passengersChild')" v-model="item.child"
              @change="calculateTotalPaxOnBoard" type="number" :box="boxOption" :disabled="disabledOption" />
          </v-flex>
          <v-flex xs3>
            <v-text-field :class="classOption" :label="this.$i18n.t('operations.passengersInfant')" v-model="item.infant"
              @change="calculateTotalPaxOnBoardInfant" type="number" :box="boxOption" :disabled="disabledOption" />
          </v-flex>
        </v-layout>
      </v-flex>

      <v-flex xs3>
        <label>{{ $t('passage.paxClass') }}</label>
        <v-layout>
          <v-flex xs4>
            <v-text-field :class="classOption" :label="this.$i18n.t('passage.class1')" v-model="item.paxFirstClass"
              type="number" :box="boxOption" :disabled="disabledOption" />
          </v-flex>
          <v-flex xs4>
            <v-text-field :class="classOption" :label="this.$i18n.t('passage.class2')" v-model="item.paxSecondClass"
              type="number" :box="boxOption" :disabled="disabledOption" />
          </v-flex>
          <v-flex xs4>
            <v-text-field :class="classOption" :label="this.$i18n.t('passage.class3')" v-model="item.paxThirdClass"
              type="number" :box="boxOption" :disabled="disabledOption" />
          </v-flex>
        </v-layout>
      </v-flex>

      <v-flex xs3>
        <label>{{ $t('passage.padClass') }}</label>
        <v-layout>
          <v-flex xs4>
            <v-text-field :class="classOption" :label="this.$i18n.t('passage.class1')" v-model="item.padFirstClass"
              type="number" :box="boxOption" :disabled="disabledOption" />
          </v-flex>
          <v-flex xs4>
            <v-text-field :class="classOption" :label="this.$i18n.t('passage.class2')" v-model="item.padSecondClass"
              type="number" :box="boxOption" :disabled="disabledOption" />
          </v-flex>
          <v-flex xs4>
            <v-text-field :class="classOption" :label="this.$i18n.t('passage.class3')" v-model="item.padThirdClass"
              type="number" :box="boxOption" :disabled="disabledOption" />
          </v-flex>
        </v-layout>
      </v-flex>

      <v-flex xs2>
        <label>{{ $t('passage.total') }}</label>
        <v-layout>
          <v-flex xs6>
            <v-text-field :class="classOption" :label="this.$i18n.t('operations.totalPax')"
              v-model="item.totalPaxOnBoardAdult" type="number" @change="calculateLMC" :box="boxOption"
              :disabled="disabledOption" />
          </v-flex>
          <v-flex xs6>
            <v-text-field :class="classOption" :label="this.$i18n.t('operations.passengersInfant')"
              v-model="item.totalPaxOnBoardInfant" type="number" @change="calculateLMCInf" :box="boxOption"
              :disabled="disabledOption" />
          </v-flex>
        </v-layout>
      </v-flex>

      <v-flex xs5>
        <label>{{ $t('passage.bags') }}</label>
        <v-layout>
          <v-flex xs2>
            <v-text-field :class="classOption" :label="this.$i18n.t('passage.class1')" v-model="item.bagsFirstClass"
              @change="calculateTotalBags" type="number" :box="boxOption" :disabled="disabledOption" />
          </v-flex>
          <v-flex xs2>
            <v-text-field :class="classOption" :label="this.$i18n.t('passage.class2')" v-model="item.bagsSecondClass"
              @change="calculateTotalBags" type="number" :box="boxOption" :disabled="disabledOption" />
          </v-flex>
          <v-flex xs2>
            <v-text-field :class="classOption" :label="this.$i18n.t('passage.class3')" v-model="item.bagsThirdClass"
              @change="calculateTotalBags" type="number" :box="boxOption" :disabled="disabledOption" />
          </v-flex>
          <v-flex xs3>
            <v-text-field :class="classOption" :label="this.$i18n.t('passage.total')" v-model="item.totalBags"
              type="number" @change="calculateLMCBag" :box="boxOption" :disabled="disabledOption" />
          </v-flex>
          <v-flex xs3>
            <v-text-field :class="classOption" :label="this.$i18n.t('scheduling.realWeight')"
              v-model="item.realWeightBags" type="number" :box="boxOption" :disabled="disabledOption" />
          </v-flex>
        </v-layout>
      </v-flex>

      <v-flex xs5>
        <label>{{ $t('passage.weelchairs') }}</label>
        <v-layout>
          <v-flex xs2>
            <v-text-field :class="classOption" :label="this.$i18n.t('passage.wchc')" v-model="item.wchc"
              @change="calculateTotalWheelchairsColumn" type="number" :box="boxOption" :disabled="disabledOption" />
          </v-flex>
          <v-flex xs2>
            <v-text-field :class="classOption" :label="this.$i18n.t('passage.wchs')" v-model="item.wchs"
              @change="calculateTotalWheelchairsColumn" type="number" :box="boxOption" :disabled="disabledOption" />
          </v-flex>
          <v-flex xs2>
            <v-text-field :class="classOption" :label="this.$i18n.t('passage.wchr')" v-model="item.wchr"
              @change="calculateTotalWheelchairsColumn" type="number" :box="boxOption" :disabled="disabledOption" />
          </v-flex>
          <v-flex xs2>
            <v-text-field :class="classOption" :label="this.$i18n.t('passage.total')" v-model="item.totalWheelchairs"
              type="number" :box="boxOption" :disabled="disabledOption" />
          </v-flex>
          <v-flex xs2>
            <v-text-field :class="classOption" :label="this.$i18n.t('passage.wcbd')" v-model="item.wcbd" type="number"
              :box="boxOption" :disabled="disabledOption" />
          </v-flex>
          <v-flex xs2>
            <v-text-field :class="classOption" :label="this.$i18n.t('passage.wcbw')" v-model="item.wcbw" type="number"
              :box="boxOption" :disabled="disabledOption" />
          </v-flex>
          <v-flex xs2>
            <v-text-field :class="classOption" :label="this.$i18n.t('passage.wclb')" v-model="item.wclb" type="number"
              :box="boxOption" :disabled="disabledOption" />
          </v-flex>
        </v-layout>
      </v-flex>

      <v-flex xs1>
        <label>{{ $t('passage.ums') }}</label>
        <v-layout>
          <v-flex xs12>
            <v-text-field :class="classOption" :label="this.$i18n.t('passage.ums')" v-model="item.umnr" type="number"
              :box="boxOption" :disabled="disabledOption" />
          </v-flex>
        </v-layout>
      </v-flex>

      <v-flex xs1>
        <label>{{ $t('passage.petc') }}</label>
        <v-layout>
          <v-flex xs12>
            <v-text-field :class="classOption" :label="this.$i18n.t('passage.petc')" v-model="item.petc" type="number"
              :box="boxOption" :disabled="disabledOption" />
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-card>
</template>

<script>
import RolePermissions from '../mixins/RolePermissions.vue';

export default {
  mixins: [RolePermissions],
  props: {
    passageInformation: { type: Object, required: true },
    type: { type: String, required: true },
    disabled: { type: Boolean, default: true },
  },
  computed: {
    classOption() {
      return this.disabled ? 'disabled-text' : '';
    },
    disabledOption() {
      return this.disabled ? true : !this.hasPermissions(this.rolePermission);
    },
    boxOption() {
      return !this.disabled;
    },
    rolePermission() {
      return `${this.$route.meta.role}_WR`;
    },
    item() {
      return this.passageInformation[this.type];
    },
  },
  methods: {
    calculateTotalPaxOnBoard() {
      if (this.item.male != null || this.item.female != null || this.item.child != null) {
        this.item.totalPaxOnBoardAdult = Number(this.item.male) + Number(this.item.female) + Number(this.item.child);
        this.calculateLMC();
      }
    },
    calculateTotalPaxOnBoardInfant() {
      // eslint-disable-next-line no-param-reassign
      this.item.totalPaxOnBoardInfant = this.item.infant;
      this.calculateLMCInf();
    },
    calculateTotalBags() {
      if (this.item.bagsFirstClass != null || this.item.bagsSecondClass != null || this.item.bagsThirdClass != null) {
        // eslint-disable-next-line no-param-reassign
        this.item.totalBags = Number(this.item.bagsFirstClass) + Number(this.item.bagsSecondClass) + Number(this.item.bagsThirdClass);
        this.calculateLMCBag();
      }
    },
    calculateTotalWheelchairsColumn() {
      if (this.item.wchc != null || this.item.wchr != null || this.item.wchs != null) {
        // eslint-disable-next-line no-param-reassign
        this.item.totalWheelchairs = Number(this.item.wchc) + Number(this.item.wchr) + Number(this.item.wchs);
      }
    },
    calculateLMC() {
      this.passageInformation.lastMinuteChangesPax = (Number(this.passageInformation.totalCheckInPax.totalPaxOnBoardAdult) - Number(this.passageInformation.totalPaxOnBoard.totalPaxOnBoardAdult)) * -1;
    },
    calculateLMCInf() {
      this.passageInformation.lastMinuteChangesInf = (Number(this.passageInformation.totalCheckInPax.totalPaxOnBoardInfant) - Number(this.passageInformation.totalPaxOnBoard.totalPaxOnBoardInfant)) * -1;
    },
    calculateLMCBag() {
      this.passageInformation.lastMinuteChangesBag = (Number(this.passageInformation.totalCheckInPax.totalBags) - Number(this.passageInformation.totalPaxOnBoard.totalBags)) * -1;
    },
  },
};
</script>
