/* eslint no-param-reassign: "off" */
import moment from 'moment/moment';
import { HTTP } from '../../../http';
import { REQUEST_ERROR, REQUEST_START, REQUEST_SUCCESS } from '../../store';
import FlightContingencyFactory from '../../../components/shared/model/flightContingencyFactory';
import { NOTIFICATION_SHOW } from '../notifications';
import { createFlightContingencyBasicServiceEventFromData } from '../../../components/shared/model/flightContingencyEvent';
import i18n from '../../../plugins/i18n';
import CrudOperationsDTO from '../../../components/shared/model/crudOperationsDTO';

// actions names
export const FLIGHT_CONTINGENCY_BASIC_SERVICE_FIND = 'FLIGHT_CONTINGENCY_BASIC_SERVICE_FIND';
export const FLIGHT_CONTINGENCY_BASIC_SERVICE_UPDATE = 'FLIGHT_CONTINGENCY_BASIC_SERVICE_UPDATE';
export const FLIGHT_CONTINGENCY_BASIC_SERVICE_CRUD_UPDATE = 'FLIGHT_CONTINGENCY_BASIC_SERVICE_CRUD_UPDATE';
export const FLIGHT_CONTINGENCY_BASIC_SERVICE_DELETE = 'FLIGHT_CONTINGENCY_BASIC_SERVICE_DELETE';
export const FLIGHT_CONTINGENCY_BASIC_SERVICE_DO_EXTERNAL_CHANGE = 'FLIGHT_CONTINGENCY_BASIC_SERVICE_DO_EXTERNAL_CHANGE';
export const FLIGHT_CONTINGENCY_BASIC_SERVICE_CRUD_INIT = 'FLIGHT_CONTINGENCY_BASIC_SERVICE_CRUD_INIT';

// mutations
export const FLIGHT_CONTINGENCY_BASIC_SERVICE_SET_GRID_API = 'FLIGHT_CONTINGENCY_BASIC_SERVICE_SET_GRID_API';
export const FLIGHT_CONTINGENCY_BASIC_SERVICE_FIND_SUCCESS = 'FLIGHT_CONTINGENCY_BASIC_SERVICE_FIND_SUCCESS';
export const FLIGHT_CHANGE_CONTINGENCY_BASIC_SERVICE_SELECTION = 'FLIGHT_CHANGE_CONTINGENCY_BASIC_SERVICE_SELECTION';
export const UPDATE_SHOW_OLD_VALUES = 'UPDATE_SHOW_OLD_VALUES';
export const UPDATE_FLASH_ON_CHANGES = 'UPDATE_FLASH_ON_CHANGES';
export const FLIGHT_CONTINGENCY_BASIC_SERVICE_ON_EXTERNAL_CHANGE = 'FLIGHT_CONTINGENCY_BASIC_SERVICE_ON_EXTERNAL_CHANGE';
export const FLIGHT_CONTINGENCY_BASIC_SERVICE_SET_CHANGE = 'FLIGHT_CONTINGENCY_BASIC_SERVICE_SET_CHANGE';
export const FLIGHT_CONTINGENCY_BASIC_SERVICE_CRUD_SET_INIT = 'FLIGHT_CONTINGENCY_BASIC_SERVICE_CRUD_SET_INIT';


// initial state
const storeState = {
  gridApi: null,
  contingencyBasicService: [],
  selectedContingencyBasicService: [],
  contingencyBasicServiceChanges: [],
  showOldValues: true,
  flashOnChanges: false,
  crudOperationsDto: new CrudOperationsDTO(),
};

// getters
const storeGetters = {
  hasSelectedContingencyBasicService: state => state.selectedContingencyBasicService.length > 0,
  isSelectedContingencyBasicService: state => id => !!state.selectedContingencyBasicService.find(s => s.getId() === id),
};

// actions
const storeActions = {
  [FLIGHT_CONTINGENCY_BASIC_SERVICE_FIND]: ({ commit }, contingencyId) => {
    commit(REQUEST_START);
    commit(FLIGHT_CONTINGENCY_BASIC_SERVICE_FIND_SUCCESS, []);
    commit(FLIGHT_CHANGE_CONTINGENCY_BASIC_SERVICE_SELECTION, []);
    return HTTP.get(`flight-contingency/basic-servs?contingencyId.equals=${contingencyId}`)
      .then((resp) => {
        commit(REQUEST_SUCCESS);
        commit(
          FLIGHT_CONTINGENCY_BASIC_SERVICE_FIND_SUCCESS,
          FlightContingencyFactory.createFlightContingencyBasicServiceFromData(resp.data),
        );
        commit(FLIGHT_CHANGE_CONTINGENCY_BASIC_SERVICE_SELECTION, []);
      })
      .catch((err) => {
        commit(REQUEST_ERROR, err);
      });
  },

  [FLIGHT_CONTINGENCY_BASIC_SERVICE_UPDATE]: ({ commit, dispatch }, item) => {
    commit(REQUEST_START);
    return HTTP.put('flight-contingency/basic-servs', item)
      .then(() => {
        commit(REQUEST_SUCCESS);
        dispatch(FLIGHT_CONTINGENCY_BASIC_SERVICE_FIND, item.contingencyId);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.contingencyBasicServiceUpdate'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(REQUEST_ERROR, err);
      });
  },
  [FLIGHT_CONTINGENCY_BASIC_SERVICE_CRUD_UPDATE]: ({ commit, dispatch }, { itemsCrud, contingencyId }) => {
    commit(REQUEST_START);
    return HTTP.put(`flight-contingency/basic-servs/${contingencyId}`, itemsCrud)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(FLIGHT_CONTINGENCY_BASIC_SERVICE_CRUD_SET_INIT);
        dispatch(FLIGHT_CONTINGENCY_BASIC_SERVICE_FIND, contingencyId);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.contingencyUpdate'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(REQUEST_ERROR, err);
      });
  },
  [FLIGHT_CONTINGENCY_BASIC_SERVICE_DELETE]: ({ commit }, ids) => {
    commit(REQUEST_START);
    return HTTP.post('flight-contingency/basic-servs/batch-delete', ids)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.contingencyBasicServiceDelete'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
  [FLIGHT_CONTINGENCY_BASIC_SERVICE_DO_EXTERNAL_CHANGE]: ({ commit }, change) => {
    commit(FLIGHT_CONTINGENCY_BASIC_SERVICE_ON_EXTERNAL_CHANGE, createFlightContingencyBasicServiceEventFromData(change));
  },
  [FLIGHT_CONTINGENCY_BASIC_SERVICE_CRUD_INIT]: ({ commit }) => {
    commit(FLIGHT_CONTINGENCY_BASIC_SERVICE_CRUD_SET_INIT);
  },
};

// mutations
const storeMutations = {
  [FLIGHT_CONTINGENCY_BASIC_SERVICE_SET_GRID_API]: (state, gridApi) => {
    state.gridApi = gridApi.api;
  },
  [FLIGHT_CONTINGENCY_BASIC_SERVICE_FIND_SUCCESS]: (state, contingencyBasicService) => {
    state.contingencyBasicService = contingencyBasicService !== null && contingencyBasicService.length > 0 ? contingencyBasicService : [];
  },
  [FLIGHT_CHANGE_CONTINGENCY_BASIC_SERVICE_SELECTION]: (state, selection) => {
    state.selectedContingencyBasicService = selection;
  },
  [UPDATE_SHOW_OLD_VALUES]: (state, showOldValues) => {
    state.showOldValues = showOldValues;
  },
  [UPDATE_FLASH_ON_CHANGES]: (state, flashOnChanges) => {
    state.flashOnChanges = flashOnChanges;
  },
  [FLIGHT_CONTINGENCY_BASIC_SERVICE_ON_EXTERNAL_CHANGE]: (state, change) => {
    console.log(`mutations external change ${change}`); // eslint-disable-line no-console
  },
  [FLIGHT_CONTINGENCY_BASIC_SERVICE_SET_CHANGE]: (state, change) => {
    change.ts = moment();
    state.contingencyBasicServiceChanges.unshift(change);
    state.contingencyBasicServiceChanges = state.contingencyBasicServiceChanges.slice(0, 10);
  },
  [FLIGHT_CONTINGENCY_BASIC_SERVICE_CRUD_SET_INIT]: (state) => {
    state.crudOperationsDto = new CrudOperationsDTO();
  },
};

export default {
  state: storeState,
  getters: storeGetters,
  actions: storeActions,
  mutations: storeMutations,
};
