<template>
  <div>
    <v-flex v-flex-none>
      <v-flex v-flex-none>
        <v-text-field :disabled="!hasInbound" prepend-icon="flight_land" :ref="'refComp1'" v-on:keydown.native="keyEvent1" v-char-length="300" v-model="commentsInboundUpdated"></v-text-field>
      </v-flex>
      <v-flex v-flex-none>
        <v-text-field class="editor-text-field" :disabled="!hasOutbound" prepend-icon="flight_takeoff" :ref="'refComp2'" v-on:keydown.native="keyEvent2" v-char-length="300" v-model="commentsOutboundUpdated"></v-text-field>
      </v-flex>
    </v-flex>
  </div>
</template>

<script>
import Vue from 'vue';
import KeyEventsUtils, { keyEvents } from '../../../keyEvents';
import AgGridParentConnectedFlightsCellEditor from '../mixins/AgGridParentConnectedFlightsCellEditor.vue';
import { FlightBound } from '../../../shared/model/flightBound';

export default Vue.extend({
  mixins: [AgGridParentConnectedFlightsCellEditor],
  components: {
  },
  data() {
    return {
      commentsInboundOriginal: '',
      commentsInboundUpdated: '',
      commentsOutboundOriginal: '',
      commentsOutboundUpdated: '',
      schedulingFlight: {},
    };
  },
  computed: {
    hasInbound() {
      return this.schedulingFlight.inbound !== null && this.schedulingFlight.inbound.common !== null;
    },
    hasOutbound() {
      return this.schedulingFlight.outbound !== null && this.schedulingFlight.outbound.common !== null;
    },
  },
  methods: {
    getValue() {
      this.unmarkCellsSelectedAsEdit();

      const valueCell = [];
      if (this.commentsInboundOriginal !== this.commentsInboundUpdated) {
        valueCell.push({ id: 'inbound.common.comments', value: this.commentsInboundUpdated, initialValue: this.commentsInboundOriginal });
      }

      if (this.commentsOutboundOriginal !== this.commentsOutboundUpdated) {
        valueCell.push({ id: 'outbound.common.comments', value: this.commentsOutboundUpdated, initialValue: this.commentsOutboundOriginal });
      }

      if (valueCell.length > 0) {
        const that = this;
        this.saveFlightChangesFromCellValue((s) => {
          s.setChildPropertyValueByBound(FlightBound.INBOUND, 'common.comments', that.commentsInboundUpdated);
          s.setChildPropertyValueByBound(FlightBound.OUTBOUND, 'common.comments', that.commentsOutboundUpdated);
        }, 'Comments', valueCell);
      }

      return this.params.node.data;
    },
    keyEvent1($event) {
      if (KeyEventsUtils.isArrowEvent($event) || $event.code === keyEvents.ENTER) {
        $event.stopPropagation();
      }

      if ($event.code === keyEvents.DOWN) {
        $event.stopPropagation();
        this.$refs.refComp2.$refs.input.focus();
      }

      if ($event.code === keyEvents.TAB) {
        $event.stopPropagation();
        this.$refs.refComp2.$refs['input-slot'].focus();
      }
    },
    keyEvent2($event) {
      if (KeyEventsUtils.isArrowEvent($event)) {
        $event.stopPropagation();
      }

      if ($event.code === keyEvents.UP) {
        $event.stopPropagation();
        this.$refs.refComp1.$refs.input.focus();
      }

      if (($event.code === keyEvents.TAB && $event.shiftKey) || $event.code === keyEvents.UP) {
        $event.stopPropagation();
        this.$refs.refComp1.$refs['input-slot'].focus();
      }
    },
  },
  created() {
    this.schedulingFlight = this.params.node.data;

    if (this.schedulingFlight.inbound && this.schedulingFlight.inbound.common) {
      this.commentsInboundOriginal = this.schedulingFlight.inbound.common.comments;
      this.commentsInboundUpdated = this.commentsInboundOriginal;
    }

    if (this.schedulingFlight.outbound) {
      this.commentsOutboundOriginal = this.schedulingFlight.outbound.common.comments;
      this.commentsOutboundUpdated = this.commentsOutboundOriginal;
    }

    this.markCellsSelectedAsEdit();
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$refs.refComp1) {
        this.$refs.refComp1.$refs.input.focus();
      }
    });
  },
});
</script>

<style>
  .comments-flight-cell.ag-cell-inline-editing {
    min-width: 60px;
    height: 105px !important;
  }
  .editor-text-field {
    padding-top: 0;
  }
</style>
