<template>
  <ag-grid-template-cell-renderer :params="params">
    <v-tooltip bottom v-if="params.value && params.value.tooltip">
      <template slot="activator">
        <div v-bind:class="{ 'errorClass': this.params.value.error }">
          <span :style="contentStyleObject">{{params.value.value}}</span>
          <span v-if="params.value.error && params.value.value==null">-</span>
        </div>
      </template>
      <span>{{params.value.tooltip}}</span>
      <span v-if="params.value.warning">{{'*'}}</span>
    </v-tooltip>
    <template v-if="params.value && !params.value.tooltip">
      <div v-bind:class="{ errorClass: params.value.error }">
        <span v-if="!Number.isNaN(params.value.value)" :style="contentStyleObject">{{params.value.value}}</span>
        <span v-if="!Number.isNaN(params.value.value) && params.value.warning" :style="contentStyleObject">{{'*'}}</span>
        <span v-if="params.value.error && params.value.value==null">-</span>
        <span v-if="params.value.error && Number.isNaN(params.value.value)">-</span>
      </div>
    </template>
  </ag-grid-template-cell-renderer>
</template>

<script>
import Vue from 'vue';
import AgGridTemplateCellRenderer from './templates/AgGridTemplateCellRenderer.vue';

export default Vue.extend({
  components: {
    AgGridTemplateCellRenderer,
  },
  computed: {
    contentStyleObject() {
      if (this.params && this.params.value && this.params.value.styleObject && this.params.value.styleObject.content) {
        return this.params.value.styleObject.content;
      }

      return null;
    },
  },
});
</script>
<style scoped lang="scss">

</style>
