<template>
  <div>
    <v-flex v-flex-none class="airport-from-input">
      <airport-select :ref="'refComp1'" v-on:keydown.native="keyEvent1And2" v-char-length.clearable="3" v-model="airport1IdUpdated"></airport-select>
    </v-flex>
    <v-flex v-flex-none class="airport-from-input">
      <airport-select :ref="'refComp2'" v-on:keydown.native="keyEvent1And2" v-char-length.clearable="3" v-model="airport2IdUpdated"></airport-select>
    </v-flex>
    <v-flex v-flex-none class="airport-from-input">
      <airport-select :ref="'refComp3'"  v-on:keydown.native="keyEvent3" v-char-length.clearable="3" v-model="airport3IdUpdated"></airport-select>
    </v-flex>
  </div>
</template>

<script>
import Vue from 'vue';
import AirportSelect from '../../../airport/AirportSelect.vue';
import KeyEventsUtils, { keyEvents } from '../../../keyEvents';
import AgGridParentConnectedFlightsCellEditor from '../mixins/AgGridParentConnectedFlightsCellEditor.vue';

export default Vue.extend({
  mixins: [AgGridParentConnectedFlightsCellEditor],
  components: {
    AirportSelect,
  },
  data() {
    return {
      airport1IdOriginal: null,
      airport1IdUpdated: null,
      airport2IdOriginal: null,
      airport2IdUpdated: null,
      airport3IdOriginal: null,
      airport3IdUpdated: null,
      flight: {},
    };
  },
  methods: {
    getValue() {
      this.unmarkCellsSelectedAsEdit();

      if (this.airport1IdOriginal !== this.airport1IdUpdated
        || this.airport2IdOriginal !== this.airport2IdUpdated
        || this.airport3IdOriginal !== this.airport3IdUpdated) {
        const airport1 = this.$store.getters.getAirportById(this.airport1IdUpdated);
        const airport2 = this.$store.getters.getAirportById(this.airport2IdUpdated);
        const airport3 = this.$store.getters.getAirportById(this.airport3IdUpdated);

        const that = this;

        const valueCell = [
          { id: 'common.airport1Id', value: this.airport1IdUpdated, initialValue: this.airport1IdOriginal },
          { id: 'common.airport2Id', value: this.airport2IdUpdated, initialValue: this.airport2IdOriginal },
          { id: 'common.airport3Id', value: this.airport3IdUpdated, initialValue: this.airport3IdOriginal },
        ];
        this.saveFlightChangesFromCellValue((s) => {
          if (airport1) {
            s.setChildPropertyValueByBound(that.params.column.colDef.colCustomConfig.flightBound, 'common.airport1Id', that.airport1IdUpdated);
            s.setChildPropertyValueByBound(that.params.column.colDef.colCustomConfig.flightBound, 'common.airport1IataCode', airport1.iataCode);
            s.setChildPropertyValueByBound(that.params.column.colDef.colCustomConfig.flightBound, 'common.airport1IcaoCode', airport1.icaoCode);
            s.setChildPropertyValueByBound(that.params.column.colDef.colCustomConfig.flightBound, 'common.airport1Code', airport1.code);
          } else {
            s.setChildPropertyValueByBound(that.params.column.colDef.colCustomConfig.flightBound, 'common.airport1Id', null);
            s.setChildPropertyValueByBound(that.params.column.colDef.colCustomConfig.flightBound, 'airport1IataCode', '');
            s.setChildPropertyValueByBound(that.params.column.colDef.colCustomConfig.flightBound, 'airport1IcaoCode', '');
            s.setChildPropertyValueByBound(that.params.column.colDef.colCustomConfig.flightBound, 'airport1Code', '');
          }

          if (airport2) {
            s.setChildPropertyValueByBound(that.params.column.colDef.colCustomConfig.flightBound, 'common.airport2Id', that.airport2IdUpdated);
            s.setChildPropertyValueByBound(that.params.column.colDef.colCustomConfig.flightBound, 'common.airport2IataCode', airport2.iataCode);
            s.setChildPropertyValueByBound(that.params.column.colDef.colCustomConfig.flightBound, 'common.airport2IcaoCode', airport2.icaoCode);
            s.setChildPropertyValueByBound(that.params.column.colDef.colCustomConfig.flightBound, 'common.airport2Code', airport2.code);
          } else {
            s.setChildPropertyValueByBound(that.params.column.colDef.colCustomConfig.flightBound, 'common.airport2Id', null);
            s.setChildPropertyValueByBound(that.params.column.colDef.colCustomConfig.flightBound, 'common.airport2IataCode', '');
            s.setChildPropertyValueByBound(that.params.column.colDef.colCustomConfig.flightBound, 'common.airport2IcaoCode', '');
            s.setChildPropertyValueByBound(that.params.column.colDef.colCustomConfig.flightBound, 'common.airport2Code', '');
          }

          if (airport3) {
            s.setChildPropertyValueByBound(that.params.column.colDef.colCustomConfig.flightBound, 'common.airport3Id', that.airport3IdUpdated);
            s.setChildPropertyValueByBound(that.params.column.colDef.colCustomConfig.flightBound, 'common.airport3IataCode', airport3.iataCode);
            s.setChildPropertyValueByBound(that.params.column.colDef.colCustomConfig.flightBound, 'common.airport3IcaoCode', airport3.icaoCode);
            s.setChildPropertyValueByBound(that.params.column.colDef.colCustomConfig.flightBound, 'common.airport3Code', airport3.code);
          } else {
            s.setChildPropertyValueByBound(that.params.column.colDef.colCustomConfig.flightBound, 'common.airport3Id', null);
            s.setChildPropertyValueByBound(that.params.column.colDef.colCustomConfig.flightBound, 'common.airport3IataCode', '');
            s.setChildPropertyValueByBound(that.params.column.colDef.colCustomConfig.flightBound, 'common.airport3IcaoCode', '');
            s.setChildPropertyValueByBound(that.params.column.colDef.colCustomConfig.flightBound, 'common.airport3Code', '');
          }
        }, 'Airports', valueCell);
      }
      return this.params.node.data;
    },
    keyEvent1And2($event) {
      if (KeyEventsUtils.isArrowEvent($event) || $event.code === keyEvents.ENTER || $event.code === keyEvents.TAB) {
        $event.stopPropagation();
      }
    },
    keyEvent3($event) {
      if (KeyEventsUtils.isArrowEvent($event)) {
        $event.stopPropagation();
      }
      if ($event.code === keyEvents.TAB && $event.shiftKey) {
        $event.stopPropagation();
      }
    },
  },
  created() {
    const schedulingFlight = this.params.node.data;
    this.flight = schedulingFlight.getBound(this.params.column.colDef.colCustomConfig.flightBound);

    this.airport1IdOriginal = this.flight.airport1Id;
    this.airport1IdUpdated = this.airport1IdOriginal;

    this.airport2IdOriginal = this.flight.airport2Id;
    this.airport2IdUpdated = this.airport2IdOriginal;

    this.airport3IdOriginal = this.flight.airport3Id;
    this.airport3IdUpdated = this.airport3IdOriginal;

    this.markCellsSelectedAsEdit();
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$refs.refComp1) {
        this.$refs.refComp1.$refs.refComp.focus();
      }
    });
  },
});
</script>

<style>
  .airport-from-cell.ag-cell-inline-editing {
    min-width: 290px;
  }
  .airport-from-input {
    display: inline-block;
    margin-left: 5px;
  }
</style>
