<template>
  <div>
    <deliveries-delete-modal v-if="dialogDelete" :dialog="dialogDelete" :items="selectedItemsToDelete" @delete-reject="deleteReject" @delete-confirm="deleteConfirm" />
    <delivery-edit-modal v-if="dialog" :dialog="dialog" :isExternal="deliveryhBaggageExternal" :edited-delivery="editedDelivery" @close="close" />

    <deliveries-list-filter @changeShowFilter="changeShowFilter" />
    <deliveries-list-container :isFilterDisplayed="filterDisplayed" @delete-item="deleteItem" />

    <deliveries-list-options @clickNewDelivery="newDelivery" @clickExternalDelivery="externalDelivery" @clickDeleteDeliveries="deleteItems" />
  </div>
</template>
<script>
import DeliveriesListContainer from '../components/lostAndFound/delivery/list/DeliveriesListContainer.vue';
import DeliveriesListFilter from '../components/lostAndFound/delivery/list/DeliveriesListFilter.vue';
import FlightDeliveryBaggage from '../components/lostAndFound/delivery/model/flightDeliveryBaggage';
import DeliveriesListOptions from '../components/lostAndFound/delivery/list/DeliveriesListOptions.vue';
import DeliveriesDeleteModal from '../components/lostAndFound/delivery/delete/DeliveriesDeleteModal.vue';
import { DELIVERIES_DELETE, FIND_DELIVERIES } from '../store/modules/lostAndFound/delivery';
import DeliveryEditModal from '../components/lostAndFound/delivery/edit/DeliveryEditModal.vue';

export default {
  components: {
    DeliveriesListContainer,
    DeliveriesListFilter,
    DeliveriesListOptions,
    DeliveriesDeleteModal,
    DeliveryEditModal,
  },
  data() {
    return {
      filterDisplayed: true,
      dialog: false,
      editedDelivery: FlightDeliveryBaggage.createEmptyFlightDeliveryBaggage(),
      dialogDelete: false,
      selectedItemsToDelete: [],
      deliveryhBaggageExternal: false,
    };
  },
  computed: {
    selectedRowsItems() {
      return this.$store.state.delivery.selectedDeliveries;
    },
  },
  methods: {
    changeShowFilter(dispayled) {
      this.filterDisplayed = dispayled;
    },
    newDelivery() {
      this.deliveryhBaggageExternal = false;
      this.editedDelivery = FlightDeliveryBaggage.createEmptyFlightDeliveryBaggage();
      this.dialog = true;
    },
    externalDelivery() {
      this.deliveryhBaggageExternal = true;
      this.editedDelivery = FlightDeliveryBaggage.createEmptyFlightDeliveryBaggage();
      this.dialog = true;
    },
    deleteItem(item) {
      this.selectedItemsToDelete = [];
      this.selectedItemsToDelete.push(item);
      this.dialogDelete = true;
    },
    deleteItems() {
      this.selectedItemsToDelete = [];
      this.selectedItemsToDelete = this.selectedRowsItems;
      this.dialogDelete = true;
    },
    deleteConfirm(ids) {
      this.$store.dispatch(DELIVERIES_DELETE, { ids })
        .finally(() => {
          this.selectedItemsToDelete = [];
          this.dialogDelete = false;
        });
    },
    deleteReject() {
      this.selectedItemsToDelete = [];
      this.dialogDelete = false;
    },
    close(result) {
      this.dialog = false;
      if (result && result.doFind) {
        this.$store.dispatch(FIND_DELIVERIES);
      }
    },
  },
};
</script>
