<template>
    <div>
        <v-btn v-scroll="onScroll" v-show="fabToTop" fab dark fixed bottom right color="primary" @click="toTop">
            <v-icon>mdi-chevron-up</v-icon>
        </v-btn>
        <v-btn v-scroll="onScroll" v-show="fabToBottom" fab dark fixed bottom left color="secondary" @click="toBottom">
            <v-icon>mdi-chevron-down</v-icon>
        </v-btn>
    </div>
</template>

<script>
export default {
  data() {
    return {
      fabToTop: false,
      fabToBottom: document.documentElement.scrollHeight > window.innerHeight,
    };
  },
  mounted() {
    setTimeout(() => {
      this.checkScrollHeight();
    }, 1000);
    window.addEventListener('resize', this.checkScrollHeight);
    this.checkScrollHeight();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkScrollHeight);
  },
  methods: {
    onScroll(e) {
      if (typeof window === 'undefined') return;
      const top = window.scrollY || e.target.scrollTop || 0;
      this.fabToTop = top > 20;
      this.fabToBottom = top < 20 && document.documentElement.scrollHeight > window.innerHeight;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
    toBottom() {
      this.$vuetify.goTo(document.documentElement.scrollHeight);
    },
    checkScrollHeight() {
      this.fabToBottom = document.documentElement.scrollHeight > window.innerHeight;
    },
  },
};
</script>
