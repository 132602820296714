/* eslint no-param-reassign: "off" */
/* eslint no-confusing-arrow: "off" */
/* eslint no-nested-ternary: "off" */

import { HTTP } from '../../../http';
import { REQUEST_ERROR, REQUEST_START, REQUEST_SUCCESS } from '../../store';
import { NOTIFICATION_SHOW } from '../notifications';
import i18n from '../../../plugins/i18n';

// actions names
export const GET_RAMP_SERVICE = 'GET_RAMP_SERVICE';
export const RAMP_SERVICE_DELETE = 'RAMP_SERVICE_DELETE';
export const RAMP_SERVICE_CREATE = 'RAMP_SERVICE_CREATE';
export const RAMP_SERVICE_UPDATE = 'RAMP_SERVICE_UPDATE';
export const RAMP_SERVICE_DISABLE = 'RAMP_SERVICE_DISABLE';
export const RAMP_SERVICE_ENABLE = 'RAMP_SERVICE_ENABLE';

// mutations
export const CHANGE_RAMP_SERVICE_SELECTION = 'CHANGE_RAMP_SERVICE_SELECTION';
export const GET_RAMP_SERVICE_SUCCESS = 'GET_RAMP_SERVICE_SUCCESS';
export const GET_RAMP_SERVICE_REQUESTED = 'GET_RAMP_SERVICE_REQUESTED';
export const UPDATE_RAMP_SERVICE_SUCCESS = 'UPDATE_RAMP_SERVICE_SUCCESS';

// initial state
const storeState = {
  rampServices: [],
  rampServiceRequested: false,
  selectedRampServices: [],
};

// getters
const storeGetters = {
  getRampServices: state => state.rampServices.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)),
  getActiveRampServices: state => state.rampServices.filter(ramp => !ramp.deleted).sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)),
  getDefaultRampServices: state => state.rampServices.filter(ramp => ['ASU', 'GPU', 'OTH', 'Pushback'].includes(ramp.name)),
  getRampServicesSelected: state => state.selectedRampServices,
  hasSelectedRampService: state => state.selectedRampServices.length > 0,
  getRampServiceById: state => id => state.rampServices.find(a => a.id === id),
};

// actions
const storeActions = {
  [GET_RAMP_SERVICE]: ({ commit, state }) => {
    if (state.rampServiceRequested) {
      return;
    }
    commit(REQUEST_START);
    commit(GET_RAMP_SERVICE_REQUESTED);
    HTTP.get('ramp-services')
      .then((resp) => {
        commit(REQUEST_SUCCESS);
        commit(GET_RAMP_SERVICE_SUCCESS, resp.data);
      })
      .catch((err) => {
        commit(REQUEST_ERROR, err);
      });
  },

  [RAMP_SERVICE_CREATE]: ({ commit, dispatch }, rampService) => {
    commit(REQUEST_START);
    return HTTP.post('ramp-services', rampService)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(UPDATE_RAMP_SERVICE_SUCCESS);
        dispatch(GET_RAMP_SERVICE);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.rampServiceCreate'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },

  [RAMP_SERVICE_UPDATE]: ({ commit, dispatch }, rampService) => {
    commit(REQUEST_START);
    return HTTP.put('ramp-services', rampService)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(UPDATE_RAMP_SERVICE_SUCCESS);
        dispatch(GET_RAMP_SERVICE);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.rampServiceUpdate'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
  [RAMP_SERVICE_DELETE]: ({ commit, dispatch }, id) => {
    commit(REQUEST_START);
    return HTTP.delete(`ramp-services/${id}`)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(UPDATE_RAMP_SERVICE_SUCCESS);
        dispatch(GET_RAMP_SERVICE);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.rampServiceDelete'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
  [RAMP_SERVICE_DISABLE]: ({ commit, dispatch }, id) => {
    commit(REQUEST_START);
    return HTTP.patch(`/ramp-services/disable/${id}`)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(UPDATE_RAMP_SERVICE_SUCCESS);
        dispatch(GET_RAMP_SERVICE);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.rampServiceDisable'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
  [RAMP_SERVICE_ENABLE]: ({ commit, dispatch }, id) => {
    commit(REQUEST_START);
    return HTTP.patch(`/ramp-services/enable/${id}`)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(UPDATE_RAMP_SERVICE_SUCCESS);
        dispatch(GET_RAMP_SERVICE);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.rampServiceEnable'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
};

// mutations
const storeMutations = {
  [GET_RAMP_SERVICE_SUCCESS]: (state, rampServices) => {
    state.rampServices = rampServices;
  },
  [GET_RAMP_SERVICE_REQUESTED]: (state) => {
    state.rampServiceRequested = true;
  },
  [UPDATE_RAMP_SERVICE_SUCCESS]: (state) => {
    state.rampServiceRequested = false;
  },
  [CHANGE_RAMP_SERVICE_SELECTION]: (state, selection) => {
    state.selectedRampServices = selection;
  },
};

export default {
  state: storeState,
  getters: storeGetters,
  actions: storeActions,
  mutations: storeMutations,
};
