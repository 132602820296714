/* eslint no-param-reassign: "off" */

import moment from 'moment';
import i18n from '../../../plugins/i18n';
import { HTTP } from '../../../http';
import { REQUEST_ERROR, REQUEST_START, REQUEST_SUCCESS } from '../../store';
import { NOTIFICATION_SHOW } from '../notifications';
import TallyingCashInformation from '../../../components/ticketingReports/model/tallyingCashInformation';
import TallyingCashFilter from '../../../components/ticketingReports/model/tallyingCashFilter';

// action
export const TALLYING_CASH_GRID_FIT = 'TALLYING_CASH_GRID_FIT';
export const TALLYING_CASH_CHANGE_GRID_API = 'TALLYING_CASH_CHANGE_GRID_API';

export const TALLYING_CASH_FIND = 'TALLYING_CASH_FIND';
export const TALLYING_CASH_UPDATE = 'TALLYING_CASH_UPDATE';
export const TALLYING_CASH_FIT_COLUMNS_TO_VIEW = 'TALLYING_CASH_FIT_COLUMNS_TO_VIEW';
export const TALLYING_CASH_FIT_COLUMNS_TO_BEST = 'TALLYING_CASH_FIT_COLUMNS_TO_BEST';

export const TALLYING_CASH_RECALCULATE_FILTER = 'TALLYING_CASH_RECALCULATE_FILTER';

// mutations
export const TALLYING_CASH_FIND_SUCCESS = 'TALLYING_CASH_FIND_SUCCESS';

export const TALLYING_CASH_FILTER_SET = 'TALLYING_CASH_FILTER_SET';
export const TALLYING_CASH_FILTER_SET_UNIT_TIME = 'TALLYING_CASH_FILTER_SET_UNIT_TIME';
export const TALLYING_CASH_UPDATE_FIT_COLUMNS_ON_RESIZE = 'TALLYING_CASH_UPDATE_FIT_COLUMNS_ON_RESIZE';

export const TALLYING_CASH_FILTER_RESET = 'TALLYING_CASH_FILTER_RESET';
export const TALLYING_CASH_FILTER_RESET_TIMEZONE = 'TALLYING_CASH_FILTER_RESET_TIMEZONE';

export const CHANGE_TALLYING_CASH_SELECTION = 'CHANGE_TALLYING_CASH_SELECTION';
export const TALLYING_CASH_FILTER_SET_TENANT = 'TALLYING_CASH_FILTER_SET_TENANT';

// initial state
const storeState = {
  gridApi: null,
  filter: TallyingCashFilter.createEmptyFilter,
  tallyingCashResults: [],
  selectedTallyingCash: [],
  fitColumnsOnResize: true,
};

// getters
const storeGetters = {
  hasSelectedTallyingCash: state => state.selectedTallyingCash.length > 0,
  isSelectedTallyingCash: state => id => !!state.selectedTallyingCash.find(s => s.getId() === id),
};

// actions
const storeActions = {
  [TALLYING_CASH_FIND]: ({ commit, state }) => {
    commit(REQUEST_START);
    const params = {
      dateFrom: state.filter.dateFrom,
      dateTo: state.filter.dateTo,
      airlineId: state.filter.airlineId ? { equals: state.filter.airlineId } : null,
      airlines: state.filter.airlines,
      unitOfTime: state.filter.unitOfTime,
      user: state.filter.user,
      tenantId: state.filter.tenantId ? { equals: state.filter.tenantId } : null,
    };
    return HTTP.post('ticketing/tallying-cash/find', params)
      .then((resp) => {
        commit(REQUEST_SUCCESS);
        commit(TALLYING_CASH_FIND_SUCCESS, resp.data);
      })
      .catch((err) => {
        commit(TALLYING_CASH_FIND_SUCCESS, []);
        commit(REQUEST_ERROR, err);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
      });
  },
  [TALLYING_CASH_UPDATE]: ({ commit, dispatch }, { tallyingCashList, field }) => new Promise((resolve, reject) => {
    commit(REQUEST_START);
    // TODO FixMe One request
    const lastIteration = (index, array) => index === array.length - 1;

    if (tallyingCashList && tallyingCashList.length > 0) {
      tallyingCashList.forEach((s, index, array) => {
        HTTP.put('ticketing/tallying-cash', s)
          .then((response) => {
            if (lastIteration(index, array)) {
              commit(REQUEST_SUCCESS);
              dispatch(TALLYING_CASH_FIND);
              commit(NOTIFICATION_SHOW, {
                message: field ? `${i18n.t('notifications.tallyingCashFieldUpdate')} ${field}` : i18n.t('notifications.tallyingCashUpdate'),
                color: 'success',
              });
              resolve(response);
            }
          })
          .catch((err) => {
            commit(REQUEST_ERROR, err);
            reject(err);
          });
      });
    }
  }),
  [TALLYING_CASH_CHANGE_GRID_API]: (state, gridApi) => {
    state.gridApi = gridApi.api;
  },
  [TALLYING_CASH_GRID_FIT]: ({ state }) => {
    if (state.gridApi) {
      state.gridApi.sizeColumnsToFit();
    }
  },
  [TALLYING_CASH_FIT_COLUMNS_TO_VIEW]: ({ state }) => {
    if (state.gridApi) {
      state.gridApi.sizeColumnsToFit();
    }
  },
  [TALLYING_CASH_FIT_COLUMNS_TO_BEST]: ({ state }) => {
    if (state.gridApi && state.gridApi.columnController && state.gridApi.columnController.columnApi) {
      state.gridApi.columnController.columnApi.autoSizeAllColumns();
    }
  },
  [TALLYING_CASH_RECALCULATE_FILTER]: ({ commit, state }) => {
    if (state.filter.dateFrom == null && state.filter.dateTo == null) {
      commit(TALLYING_CASH_FILTER_RESET);
    } else {
      commit(TALLYING_CASH_FILTER_RESET_TIMEZONE, state.filter.unitOfTime);
    }
  },
};

// mutations
const storeMutations = {
  [TALLYING_CASH_CHANGE_GRID_API]: (state, gridApi) => {
    state.gridApi = gridApi.api;
  },
  [TALLYING_CASH_FIND_SUCCESS]: (state, tallyingCashResults) => {
    state.tallyingCashResults = TallyingCashInformation.createTallyingCashInformationFromData(tallyingCashResults);
  },
  [TALLYING_CASH_FILTER_SET]: (state, filter) => {
    if (filter) {
      state.filter = filter;
    } else {
      state.filter = TallyingCashFilter.createEmptyFilter;
    }
  },
  [TALLYING_CASH_FILTER_SET_UNIT_TIME]: (state, unitOfTime) => {
    state.filter.unitOfTime = unitOfTime;
    if (unitOfTime) {
      state.filter.dateFrom = moment().startOf(unitOfTime);
      state.filter.dateTo = moment().endOf(unitOfTime);
    }
  },
  [TALLYING_CASH_UPDATE_FIT_COLUMNS_ON_RESIZE]: (state, fitColumnsOnResize) => {
    state.fitColumnsOnResize = fitColumnsOnResize;
  },
  [TALLYING_CASH_FILTER_RESET]: (state) => {
    state.filter.dateFrom = moment().startOf('day');
    state.filter.dateTo = moment().endOf('day');
  },
  [TALLYING_CASH_FILTER_RESET_TIMEZONE]: (state, unitOfTime) => {
    state.filter.unitOfTime = unitOfTime != null ? unitOfTime : 'day';
    state.filter.dateFrom = moment().startOf(state.filter.unitOfTime)
      .year(state.filter.dateFrom.year())
      .month(state.filter.dateFrom.month())
      .date(state.filter.dateFrom.date());
    state.filter.dateTo = moment().endOf(state.filter.unitOfTime)
      .year(state.filter.dateTo.year())
      .month(state.filter.dateTo.month())
      .date(state.filter.dateTo.date());
  },
  [CHANGE_TALLYING_CASH_SELECTION]: (state, selection) => {
    state.selectedTallyingCash = selection;
  },
  [TALLYING_CASH_FILTER_SET_TENANT]: (state, tenant) => {
    state.filter.tenantId = tenant;
  },
};

export default {
  state: storeState,
  getters: storeGetters,
  actions: storeActions,
  mutations: storeMutations,
};
