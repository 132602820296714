<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <v-combobox
      name="messageDestination"
      id="messageDestination"
      :placeholder="this.$i18n.t('messages.avinetUserDestination')"
      :value="value"
      :items="items"
      item-value="recipientsFormat"
      :label="label"
      :rules="rules"
      multiple
      hide-selected
      chips
      deletable-chips
      @input="emitInput"
      content-class="avinetMessageDestinationSelector"
    >
      <template v-slot:data="item">
        <v-list-tile-content>
          <v-list-tile-title>
            {{ item }}
          </v-list-tile-title>
        </v-list-tile-content>
      </template>
      <template v-slot:item="data">
        <v-list-tile-content>
          <v-list-tile-title>
              <span v-for="(recipient, index) in data.item.recipientsFormat" :key="index">
                {{ recipient }}
              </span>
          </v-list-tile-title>
          <v-list-tile-sub-title>{{ `Message type: ${data.item.messageTypeCode} - Airline: ${data.item.airlineCode} - Destination: ${data.item.airportIataCode} - Aircraft Type: ${data.item.aircraftTypeCode} - Source: ${data.item.source}` }}</v-list-tile-sub-title>
        </v-list-tile-content>
      </template>
    </v-combobox>
  </div>
</template>

<script>

import { GET_MESSAGE_DIRECTORY } from '../../store/modules/messageDirectory/messageDirectoryCatalog';

export default {
  name: 'AvinetMessageDestinationSelector',
  props: {
    value: { required: true },
    label: { type: String },
    rules: { type: Array },
    disabled: { type: Boolean },
    refComp: {
      type: String,
      required: false,
      default: 'refComp',
    },
    sourceFilter: { type: String },
    typeMessageFilter: { },
    airlineFilter: { },
    airportFilter: { },
    aircraftTypeFilter: { },
  },
  data: () => ({
    select: [],
  }),
  computed: {
    items() {
      return this.$store.state.messageDirectoryCatalog.messagesDirectoryList
        .filter(md => (this.sourceFilter != null && this.typeMessageFilter != null && this.airlineFilter != null && this.airportFilter != null && this.aircraftTypeFilter != null ? md.source === this.sourceFilter && md.messageTypeId === this.typeMessageFilter && (md.airlineId == null || md.airlineId === this.airlineFilter) && (md.airportId == null || md.airportId === this.airportFilter) && (md.aircraftTypeId == null || md.aircraftTypeId === this.aircraftTypeFilter) : md))
        .filter(md => (this.sourceFilter != null ? md.source === this.sourceFilter : md))
        .filter(md => (this.typeMessageFilter != null ? md.messageTypeId === this.typeMessageFilter : md))
        .filter(md => (this.airlineFilter != null ? md.airlineId === this.airlineFilter || md.airlineId == null : md))
        .filter(md => (this.airportFilter != null ? md.airportId === this.airportFilter || md.airportId == null : md))
        .filter(md => (this.aircraftTypeFilter != null ? md.aircraftTypeId == null || md.aircraftTypeId === this.aircraftTypeFilter : md));
    },
  },
  watch: {
    typeMessageFilter: {
      handler() {
        this.emitInput();
      },
    },
    sourceFilter: {
      handler() {
        this.emitInput();
      },
    },
    airlineFilter: {
      handler() {
        this.emitInput();
      },
    },
    aircraftTypeFilter: {
      handler() {
        this.emitInput();
      },
    },
  },
  methods: {
    getMessageDirectory() {
      return this.$store.dispatch(GET_MESSAGE_DIRECTORY);
    },
    emitInput(event) {
      if (typeof (event) !== 'undefined') {
        const recipients = [];
        event.forEach((e) => {
          if (typeof (e) === 'object') {
            e.recipientsFormat.forEach((recipient) => {
              if (!recipients.includes(recipient)) {
                recipients.push(recipient);
              }
            });
          } else if (e != null) {
            if (!recipients.includes(e)) {
              recipients.push(e);
            }
          }
        });
        this.$emit('input', recipients);
      } else {
        this.$emit('input', null);
      }
    },
    customFilter(item, queryText) {
      const textOne = item.messageTypeCode ? item.messageTypeCode.toLowerCase() : '';
      const textTwo = item.airlineCode ? item.airlineCode.toLowerCase() : '';
      const textThree = item.aircraftTypeCode ? item.aircraftTypeCode.toLowerCase() : '';
      const searchText = queryText.toLowerCase();

      return textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1 || textThree.indexOf(searchText) > -1;
    },
  },
  created() {
    this.getMessageDirectory();
  },
};
</script>
<style scoped>
.v-select__selections {
  min-height: 32px !important;
}
.v-chip {
  font-size: 16px !important;
  background-color: #eeeded !important;
}
.mdi-close-circle::before{
  color: red !important;
}
</style>
