<template>
  <ag-grid-template-cell-renderer :params="params">
    <flight-status :status="getStatus" :tooltip="getStatusTooltip" :resizable="params.resizable"/>
  </ag-grid-template-cell-renderer>
</template>

<script>
import Vue from 'vue';
import AgGridTemplateCellRenderer from './templates/AgGridTemplateCellRenderer.vue';
import CellRendererMixin from './mixins/CellRendererMixin.vue';
import FlightStatus from '../../flights/FlightStatus.vue';

export default Vue.extend({
  mixins: [CellRendererMixin],
  components: {
    AgGridTemplateCellRenderer,
    FlightStatus,
  },
  computed: {
    getStatus() {
      if (this.isParamsValueNotEmpty() && this.params.value.value) {
        return this.params.value.value;
      }
      return this.isParamsValueNotEmpty() && typeof this.params.value !== 'object' ? this.params.value : null;
    },
    getStatusTooltip() {
      return this.isParamsValueNotEmpty() && this.params.value.tooltip ? this.params.value.tooltip : null;
    },
  },
});
</script>
