<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>

    <ag-grid-dialog-template-editor :is-dialog-open="isDialogOpen" @save="save" @cancel="cancel">

      <template v-slot:header>
        {{$t("operations.editCrewModalTitle")}} {{flightNumber}}
      </template>

      <template v-slot:default>
        <v-container grid-list-md text-xs-center fluid>
          <v-layout row wrap>
            <v-flex xs12>
              <v-card>
                <v-card-text>
                  <v-layout>
                    <v-flex xs3 class="space-label">
                      <v-label>{{$t("operations.remarksExtended")}}</v-label>
                    </v-flex>
                    <v-flex xs8>
                      <v-textarea v-model="flightCrew.crewRemarks" rows="1" auto-grow :readonly="!hasPermissions(rolePermission)"></v-textarea>
                    </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex xs3 class="space-label">
                      <v-label>{{$t("operations.crewModalVehicles")}}</v-label>
                    </v-flex>
                    <v-flex xs8>
                      <v-textarea v-model="flightCrew.crewVehicles" rows="1" auto-grow :readonly="!hasPermissions(rolePermission)"></v-textarea>
                    </v-flex>
                  </v-layout>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-layout>
        </v-container>
      </template>
    </ag-grid-dialog-template-editor>

  </div>
</template>

<script>
import Vue from 'vue';
import AgGridDialogParentCellEditor from '../../mixins/AgGridDialogParentCellEditor.vue';
import AgGridDialogTemplateEditor from '../../templates/AgGridDialogTemplateEditor.vue';
import { JSUtils } from '../../../../shared/js/JSUtils';
import FlightCrew from '../../model/flightCrew';

export default Vue.extend({
  mixins: [AgGridDialogParentCellEditor],
  components: {
    AgGridDialogTemplateEditor,
  },
  data() {
    return {
      flightCrew: new FlightCrew(),
    };
  },
  computed: {
    flightNumber() {
      return this.connectedFlights.getChildPropertyValue(this.flightBound, ['common', 'flightNo']);
    },
    rolePermission() {
      return `${this.$route.meta.role}_WR`;
    },
  },
  methods: {
    getValue() {
      if (this.isSaving) {
        this.requestInit();
        this.$http.put(`flights/${this.flightId}/crew`, this.flightCrew)
          .then(resp => this.dialogRequestSuccessCallback(this, resp))
          .catch(error => this.requestErrorCallback(this, error));
      }

      return this.params.value;
    },
  },
  created() {
    this.$http.get(`flights/${this.flightId}/crew`)
      .then((resp) => {
        this.flightCrew = JSUtils.cast(resp.data, FlightCrew);
      });
  },
});
</script>
<style lang="scss">
  .space-label {
    margin-top: 20px;
  }
  .mark-read {
    text-align: left !important;
    font-size: inherit;
    color: rgba(0, 0, 0, 0.54)
  ;
  }
</style>
