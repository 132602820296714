<template>
  <div>
    <v-flex v-flex-none>
      <v-text-field :ref="refTextInput" v-model="updatedText" type="text"></v-text-field>
    </v-flex>
  </div>
</template>

<script>
import Vue from 'vue';
import AgGridParentCellEditor from './mixins/AgGridParentCellEditor.vue';

export default Vue.extend({
  mixins: [AgGridParentCellEditor],
  components: {
  },
  props: {
    refTextInput: {
      type: String,
      required: false,
      default: 'refTextInput',
    },
  },
  data() {
    return {
      originalText: null,
      updatedText: null,
    };
  },
  methods: {
    getValue() {
      this.unmarkCellsSelectedAsEdit();
      return this.updatedText;
    },
  },
  created() {
    this.originalText = this.params.value.value != null ? this.params.value.value : null;
    this.updatedText = this.originalText;

    this.markCellsSelectedAsEdit();
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$refs.refTextInput) {
        this.$refs.refTextInput.$refs.input.focus();
      }
    });
  },
});
</script>

<style>

</style>
