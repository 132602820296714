<template>
  <div class="pagination-settings-menu">
    <v-text-field class="pagination-settings-menu-field"
      :dark="this.$vuetify.dark"
      v-model="pageSize"
      @blur="changePageSize"
      type="number"
      :label="this.$i18n.t('pagination.settings.pageSizeLabel')"
      placeholder="Page Size"
      :min="minPageSize"
      :max="maxPageSize">
    </v-text-field>
  </div>
</template>
<script>
import PaginationRequestProperties from './model/paginationRequestProperties';

export default {
  name: 'pagination-settings',
  props: {
    originalPageSize: { type: Number, required: true },
  },
  data() {
    const maxPageSize = PaginationRequestProperties.MAXIMUM_PAGE_SIZE;
    const minPageSize = PaginationRequestProperties.MINIMUM_PAGE_SIZE;
    return {
      pageSize: this.originalPageSize,
      maxPageSize,
      minPageSize,
    };
  },
  methods: {
    changePageSize() {
      if (this.pageSize < this.minPageSize) {
        this.pageSize = this.minPageSize;
      }
      if (this.pageSize > this.maxPageSize) {
        this.pageSize = this.maxPageSize;
      }
      this.$emit('onChangePageSize', this.pageSize);
    },
  },
};
</script>
