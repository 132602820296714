<template>
  <div class="water-toilet-flights list-filter">
    <v-card :dark="this.$vuetify.dark" :color="this.$vuetify.dark ? 'bggrey_dark' : 'bggrey'">
      <v-toolbar dark dense color="primary lighten-1">
        <v-btn v-on:click="displayedFilter" small flat>
          <v-icon>filter_list</v-icon>
          <v-icon v-if="!showFilter">keyboard_arrow_down</v-icon>
          <v-icon v-if="showFilter">keyboard_arrow_up</v-icon>
        </v-btn>

        <v-divider vertical light style="margin-left: 30px; margin-right: 30px"></v-divider>

        <water-toilet-flights-list-filter-date-selector />

        <v-divider vertical light style="margin-left: 30px; margin-right: 30px"></v-divider>

        <v-spacer></v-spacer>

        <v-divider vertical light style="margin-left: 30px; margin-right: 30px"></v-divider>

        <v-flex grow="false" shrink="true">
          <audit-flights-changes-list />
        </v-flex>

        <WaterToiletFlightsListTableOptionsFilterButton />
      </v-toolbar>
      <v-container fluid v-if="showFilter" class="pa-2 pb-2">
        <v-layout class="filter-inline" align-center justify-start>

          <v-flex class="text-no-wrap" align-center justify-start>
            <v-switch
              slot="activator"
              color="red"
              :label="this.$i18n.t('commonFilter.showOldValues')"
              v-model="showOldValues"
              @change="updateShowOldValuesInStore()"  hide-details>
            </v-switch>
            <v-switch
              slot="activator"
              color="red"
              :label="this.$i18n.t('commonFilter.flashOnChanges')"
              v-model="flashOnChanges"
              @change="updateFlashOnChangesInStore()"  hide-details>
            </v-switch>
          </v-flex>

          <v-flex class="text-no-wrap" align-center justify-start>
            <v-switch
              slot="activator"
              color="red"
              :label="this.$i18n.t('operations.showFlights')"
              v-model="filter.showFlights"
              hide-details>
            </v-switch>
            <v-switch
              slot="activator"
              color="red"
              :label="this.$i18n.t('operations.showAircraftsGround')"
              v-model="filter.showAircraftGround"
              hide-details>
            </v-switch>
          </v-flex>
          <v-spacer style="width: 45%"></v-spacer>
          <v-flex class="ml-2 text-no-wrap" align-center justify-start>
            <v-btn color="primary" round v-on:click.stop="find" block>
              <v-icon>search</v-icon>
              {{ $t("common.find") }}
            </v-btn>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card>
  </div>

</template>
<script>
import StoreSubscribable from '../../../mixins/StoreSubscribable.vue';
import {
  UPDATE_FLASH_ON_CHANGES,
  UPDATE_SHOW_OLD_VALUES,
  WATER_TOILET_FIND_FLIGHTS,
} from '../../../../store/modules/ramp/waterToilet';
import WaterToiletFlightsListFilterDateSelector from './WaterToiletFlightsListFilterDateSelector.vue';
import WaterToiletFlightsListTableOptionsFilterButton from './WaterToiletFlightsListTableOptionsFilterButton.vue';
import AuditFlightsChangesList from '../../../audit/list/AuditFlightsChangesList.vue';
import AgGridApiHoldable from '../../../ag-grid/mixins/AgGridApiHoldable.vue';

export default {
  name: 'water-toilet-flights-list-filter',
  mixins: [StoreSubscribable, AgGridApiHoldable],
  components: {
    WaterToiletFlightsListFilterDateSelector,
    WaterToiletFlightsListTableOptionsFilterButton,
    AuditFlightsChangesList,
  },
  props: [],
  data() {
    return {
      select: 50,
      showFilter: false,
      valid: true,
      queryName: null,
      dialog: false,
      showOldValues: null,
      flashOnChanges: null,
    };
  },
  computed: {
    filter() {
      return this.$store.state.waterToilet.filter;
    },
    date() {
      return this.$moment(this.$store.state.waterToilet.filter.dateFrom);
    },
  },
  methods: {
    find() {
      this.showLoading();
      this.$store.dispatch(WATER_TOILET_FIND_FLIGHTS);
    },
    updateShowOldValuesInStore() {
      this.$store.commit(UPDATE_SHOW_OLD_VALUES, this.showOldValues);
    },
    updateFlashOnChangesInStore() {
      this.$store.commit(UPDATE_FLASH_ON_CHANGES, this.flashOnChanges);
    },
    displayedFilter() {
      this.showFilter = !this.showFilter;
      this.$emit('changeShowFilter', this.showFilter);
    },
  },
  created() {
    this.showOldValues = this.$store.state.waterToilet.showOldValues;
    this.flashOnChanges = this.$store.state.waterToilet.flashOnChanges;
  },
  mounted() {

  },
};
</script>
