<script>
import Vue from 'vue';
import { JSUtils } from '../shared/js/JSUtils';

export default Vue.extend({
  data() {
    return {
      subscriptions: new Map(),
      unsubscribeFunction: null,
    };
  },
  methods: {
    subscribeMutation(mutationName, mutationCallback) {
      this.subscriptions.set(this.subscriptions.size + 1, {
        name: mutationName,
        action: mutationCallback,
      });
    },
    clearSubscriptions() {
      this.subscriptions.clear();
      if (this.unsubscribeFunction) {
        this.unsubscribeFunction();
      }
    },
  },
  mounted() {
    const that = this;
    this.unsubscribeFunction = this.$store.subscribe((mutation) => {
      that.subscriptions.forEach((subscription) => {
        if (mutation.type === subscription.name && subscription.action && JSUtils.isFunction(subscription.action)) {
          subscription.action(mutation.payload);
        }
      });
    });
  },
  beforeDestroy() {
    this.clearSubscriptions();
  },
});
</script>
