<template>
  <v-container v-if="!isEmptyLink()" class="mobileInfoConnection mobileView">
    <v-layout row wrap class="flex justify-center">
      <v-flex xs12 sm12 md12 lg12 xl12 v-if="connectionId || originId">
        <div class="mx-2">
          <v-chip color="red" dark @click="gotoConnection">
            <v-icon class="mx-1 hidden-xs-only">share</v-icon>
            <strong class="mx-1 hidden-xs-only" v-if="this.connectionId"
              >{{ $t('flightFields.connection.tripNumber') }}:
            </strong>
            <strong class="mx-1 hidden-xs-only" v-if="this.originId"
              >{{ $t('configuration.origin') }}:
            </strong>
            <v-icon v-if="flightMobileConnection.bound === 'INBOUND'"
              >flight_land</v-icon
            >
            <v-icon v-else-if="flightMobileConnection.bound === 'OUTBOUND'"
              >flight_takeoff</v-icon
            >
            <strong class="mx-1">
              {{
                flightMobileConnection.airlineCode +
                flightMobileConnection.tripNumber
              }}
              {{ flightMobileConnection.airport1Code }}
            </strong>
            <span class="label" v-if="flightMobileConnection.scheduledTime">
              {{
                flightMobileConnection.scheduledTime
                  ? formattedTime(flightMobileConnection.scheduledTime)
                  : '-'
              }}
            </span>
            <flight-status
              class="mx-1"
              :status="flightMobileConnection.status"
              :resizable="true"
            />
            <span v-if="flightMobileConnection.parkingCode">
              <strong class="mx-1">P</strong>
              <span>{{ flightMobileConnection.parkingCode }}</span>
            </span>
            <span v-if="flightMobileConnection.gateCode">
              <strong class="mx-1">{{
                $t('dispatcher.additionalInfoGate')
              }}</strong>
              <span>{{ flightMobileConnection.gateCode }}</span>
            </span>
          </v-chip>
        </div>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import FlightStatus from '../flights/FlightStatus.vue';

import { MOBILE_GET_FLIGHT_CONNECTION } from '../../store/modules/mobile';
import { TIME_TIMEZONE_CHANGED } from '../../store/modules/time';
import StoreSubscribable from '../mixins/StoreSubscribable.vue';

export default {
  name: 'info-mobile-connection',
  props: {
    connectionId: {
      required: false,
    },
    originId: {
      required: false,
    },
    urlDestination: {
      required: false,
    },
  },
  mixins: [StoreSubscribable],
  components: {
    FlightStatus,
  },
  computed: {
    flightMobileConnection() {
      return this.$store.state.mobile.flightMobileConnection;
    },
  },
  data() {
    return {
      localTimezone: this.$store.state.time.timezone,
    };
  },
  methods: {
    formattedTime(date) {
      if (date) {
        return this.$moment(date).tz(this.localTimezone).format('HH:mm');
      }
      return '';
    },
    isEmptyLink() {
      let empty = true;
      if (this.flightMobileConnection) {
        empty = false;
      }
      return empty;
    },
    gotoConnection() {
      const flightId = this.connectionId || this.originId;
      const name = this.urlDestination || 'additionalInfo';
      this.$router
        .push({
          name,
          params: { flightId, flightMobileProps: this.flightMobileConnection },
        })
        .catch((err) => {
          // ignore NavigationDuplicated error
          if (err.name === 'NavigationFailure' && err.type === 4) {
            // eslint-disable-next-line no-console
            console.log('NavigationFailure', err);
          }
        });
    },
  },
  watch: {
    connectionId() {
      if (this.connectionId) {
        this.$store.dispatch(MOBILE_GET_FLIGHT_CONNECTION, this.connectionId);
      }
    },
    originId() {
      if (this.originId) {
        this.$store.dispatch(MOBILE_GET_FLIGHT_CONNECTION, this.originId);
      }
    },
  },
  created() {
    this.subscribeMutation(TIME_TIMEZONE_CHANGED, () => {
      this.localTimezone = this.$store.state.time.timezone;
    });
    if (this.connectionId) {
      this.$store.dispatch(MOBILE_GET_FLIGHT_CONNECTION, this.connectionId);
    } else if (this.originId) {
      this.$store.dispatch(MOBILE_GET_FLIGHT_CONNECTION, this.originId);
    }
  },
};
</script>
<style>
.mobileInfoConnection {
  padding-bottom: 0px;
  padding-top: 0px;
}

.mobileInfoConnection .v-chip {
  max-width: 100%;
  width: 100%;
  font-size: 17px;
  padding-top: 10px;
  padding-bottom: 8px;
  margin-bottom: 8px !important;
  cursor: pointer !important;
}

.mobileInfoConnection span,
.mobileInfoConnection strong,
.mobileInfoConnection i {
  cursor: pointer !important;
}

.mobileInfoConnection .status-badge {
  cursor: pointer !important;
  color: black !important;
  /* padding-top: 16px; */
  /* padding-bottom: 15px; */
  /* border-radius: 0px; */
  padding-left: 10px;
  padding-right: 10px;
  font-size: 16px;
  font-weight: 400;
}
</style>
