<template>
  <v-form ref="form">
    <v-text-field class="currency-input" ref="refInput" v-model="valueUpdated" :rules="currencyRules" v-on:keydown.native="keyEvent" />
  </v-form>
</template>

<script>
import Vue from 'vue';
import AgGridParentCellEditor from './mixins/AgGridParentCellEditor.vue';
import KeyEventsUtils from '../../keyEvents';

export default Vue.extend({
  mixins: [AgGridParentCellEditor],
  data() {
    return {
      valueOriginal: null,
      valueUpdated: null,
      currencyRules: [
        value => this.currencyRule(value),
      ],
    };
  },
  methods: {
    getValue() {
      if (this.$refs.form.validate()) {
        this.unmarkCellsSelectedAsEdit();
        return String(this.valueUpdated).replaceAll(',', '.');
      }
      this.unmarkCellsSelectedAsEdit();
      return this.valueOriginal;
    },
    keyEvent($event) {
      if (KeyEventsUtils.isArrowEvent($event)) {
        $event.stopPropagation();
      }
    },
    currencyRule(value) {
      if (value !== 'undefined' && value != null) {
        if (/[A-Za-z]/g.test(value)) {
          return this.$i18n.t('contingencies.validationOnlyNumbers');
        } if ((String(value).includes(',') && String(value).includes('.')) || (String(value).indexOf('.') !== String(value).lastIndexOf('.')) || (String(value).indexOf(',') !== String(value).lastIndexOf(','))) {
          return this.$i18n.t('contingencies.validationRepeatSign');
        } if (!/^(\d*)([,.]\d{0,2})?$/g.test(value)) {
          return this.$i18n.t('contingencies.validationOnlyTwoDecimals');
        }
        return true;
      }
      return true;
    },
  },
  created() {
    this.valueOriginal = this.params.value.value ? this.params.value.value : '';

    this.valueUpdated = this.valueOriginal;

    this.markCellsSelectedAsEdit();
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$refs.refInput) {
        this.$refs.refInput.$refs.input.focus();
      }
    });
  },
});
</script>

<style>
.currency-cell.ag-cell-inline-editing {
  width: auto !important;
}
.currency-input .v-input__control {
  flex-direction: column-reverse;
}
.currency-input .v-text-field__details {
  margin-right: 5px;
}
.currency-input {
  padding: 0;
}
</style>
