<script>
import Vue from 'vue';

export default Vue.extend({
  computed: {
    customNoRowsOverlay() {
      return this.$i18n.t(this.$store.getters.getNoFlightsToShowMessage);
    },
    customLoadingOverlay() {
      return `<span class="ag-overlay-loading-center">
        <div class="v-progress-circular v-progress-circular--indeterminate primary--text mr-3" style="height: 22px; width: 22px;">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="22.857142857142858 22.857142857142858 45.714285714285715 45.714285714285715" style="transform: rotate(0deg);"><circle fill="transparent" cx="45.714285714285715" cy="45.714285714285715" r="20" stroke-width="5.714285714285714" stroke-dasharray="125.664" stroke-dashoffset="125.66370614359172px" class="v-progress-circular__overlay"></circle></svg>
          <div class="v-progress-circular__info"></div></div></i>${this.$i18n.t(this.$store.getters.getLoadingMessage)}</span>`;
    },
  },
  watch: {
    customNoRowsOverlay(newCustomNoRowsOverlay) {
      this.gridApi.gridCore.gridOptions.overlayNoRowsTemplate = newCustomNoRowsOverlay;
    },
    customLoadingOverlay(newCustomLoadingOverlay) {
      this.gridApi.gridCore.gridOptions.overlayLoadingTemplate = newCustomLoadingOverlay;
    },
  },
});
</script>
