<script>
import Vue from 'vue';

export default Vue.extend({
  methods: {
    checkIfCatalogElementIsNew(params, bound, idFieldName, codeFieldName) {
      const idFieldValue = params.getChildPropertyValue(bound, [idFieldName]);
      const codeFieldValue = params.getChildPropertyValue(bound, [codeFieldName]);
      return !!(codeFieldValue && (idFieldValue == null || idFieldValue.length === 0));
    },
  },
});
</script>
