import 'roboto-fontface/css/roboto/roboto-fontface.css';
import 'material-design-icons-iconfont/dist/material-design-icons.css';
import '@mdi/font/css/materialdesignicons.css';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import '@babel/polyfill';
import Vue from 'vue';
import { HTTP, AXIOS_DEFAULT } from './http';
import './assets/svg-icons/vsvg.css';
import './assets/styles/ag-grid.css';
import './assets/scss/ag-grid-fis-theme.scss';
import './plugins/vuetify';
import './plugins/vue-svgicon';
import './plugins/moment';
import i18n from './plugins/i18n';
import App from './App.vue';
import router from './router/router';
import store from './store/store';
import './registerServiceWorker';
import './directives/char-length.directive';
import './directives/flex-none.directive';
import './compiled-icons/index';
import './compiled-icons/flights';
import './mixins/globalMixins';
import AuthService from './components/shared/services/auth/auth-service';
import TenantService from './components/shared/services/tenant/tenant-service';

Vue.config.productionTip = false;

Vue.prototype.$http = HTTP;
Vue.prototype.$axiosDefault = AXIOS_DEFAULT;
Vue.prototype.$auth = AuthService;
Vue.prototype.$tenant = TenantService;

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app');
