import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import 'vuetify/src/stylus/app.styl';

Vue.use(Vuetify, {
  theme: {
    primary: '#FE0900',
    secondary: '#3F88C5',
    accent: '#FFB145',
    error: '#C02E26',
    info: '#90C0E7',
    success: '#2CC354',
    warning: '#FFD145',
    bggrey: '#F4F5F6',
    bggrey_dark: '#2e2e2e',
    bggreydark: '#c3c4c5',
    /* ag-grid selection options */
    agRowSelected: '#c2d9ee',
    agRowSelectedDark: '#5e0000',
  },
  customProperties: true,
  iconfont: 'mdi',
});
