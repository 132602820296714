<template>
  <v-card-text>
    <v-container fluid style="margin-bottom: 0; padding-bottom: 0">
      <v-layout style="margin-bottom: 0; padding-bottom: 0">
        <v-flex style="margin-right: 2ch; width: 3vw">
          <v-text-field :label="this.$i18n.t('configuration.code')" v-model="item.code" :readonly="!hasPermissions(rolePermission)"/>
        </v-flex>

        <v-flex style="margin-right: 2ch; width: 30vw">
          <v-text-field :label="this.$i18n.t('configuration.description')" v-model="item.comments" :readonly="!hasPermissions(rolePermission)"/>
        </v-flex>

        <v-flex xs3 pr-2>
          <v-text-field :label="this.$i18n.t('configuration.weighting')" type="number" min="0" v-model="item.weighting" :readonly="!hasPermissions(rolePermission)"></v-text-field>
        </v-flex>
        </v-layout>
    </v-container>
  </v-card-text>
</template>
<script>

import RolePermissions from '../../mixins/RolePermissions.vue';

export default {
  name: 'HandlingTypesEditCardContent',
  mixins: [RolePermissions],
  props: {
    item: { type: Object, required: true },
  },
  computed: {
    rolePermission() {
      return `${this.$route.meta.role}_WR`;
    },
  },
};
</script>
