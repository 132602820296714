<template>
  <div>
    <towing-adhoc-task-list-filter @changeShowFilter="changeShowFilter"></towing-adhoc-task-list-filter>
    <towing-adhoc-task-list-container :isFilterDisplayed="filterDisplayed" @delete-item="deleteItem" @cancel-item="cancelItem" @approve-item="approveItem"></towing-adhoc-task-list-container>
    <towing-adhoc-task-delete-modal v-if="dialogDelete" :dialog="dialogDelete" :items="selectedItemsToDelete" @delete-reject="deleteReject" @delete-confirm="deleteConfirm" />
    <towing-adhoc-task-cancel-modal v-if="dialogCancel" :dialog="dialogCancel" :items="selectedItemsToCancel" @cancel-reject="cancelReject" @cancel-confirm="cancelConfirm" />
    <towing-adhoc-task-approve-modal v-if="dialogApprove" :dialog="dialogApprove" :items="selectedItemsToApprove" @approve-reject="approveReject" @approve-confirm="approveConfirm" />
  </div>
</template>
<script>
import TowingAdhocTaskListFilter from '../components/towingAdhocTask/list/TowingAdhocTaskListFilter.vue';
import TowingAdhocTaskListContainer from '../components/towingAdhocTask/list/TowingAdhocTaskListContainer.vue';
import TowingAdhocTaskDeleteModal from '../components/towingAdhocTask/delete/TowingAdhocTaskDeleteModal.vue';
import TowingAdhocTaskCancelModal from '../components/towingAdhocTask/cancel/TowingAdhocTaskCancelModal.vue';
import TowingAdhocTaskApproveModal from '../components/towingAdhocTask/approve/TowingAdhocTaskApproveModal.vue';
import {
  TOWING_ADHOC_TASK_DELETE,
  TOWING_ADHOC_TASK_CANCEL,
  TOWING_ADHOC_TASK_APPROVE,
} from '../store/modules/towingAdhocTask/towingAdhocTask';

export default {
  components: {
    TowingAdhocTaskListFilter,
    TowingAdhocTaskListContainer,
    TowingAdhocTaskDeleteModal,
    TowingAdhocTaskCancelModal,
    TowingAdhocTaskApproveModal,
  },
  computed: {
  },
  data() {
    return {
      filterDisplayed: false,
      dialogDelete: false,
      dialogCancel: false,
      dialogApprove: false,
      selectedItemsToDelete: [],
      selectedItemsToCancel: [],
      selectedItemsToApprove: [],
    };
  },
  methods: {
    changeShowFilter(dispayled) {
      this.filterDisplayed = dispayled;
    },
    deleteItem(item) {
      this.selectedItemsToDelete = [];
      this.selectedItemsToDelete.push(item);
      this.dialogDelete = true;
    },
    deleteItems() {
      this.selectedItemsToDelete = [];
      this.selectedItemsToDelete = this.selectedRowsItems;
      this.dialogDelete = true;
    },
    deleteConfirm(items) {
      this.$store.dispatch(TOWING_ADHOC_TASK_DELETE, items)
        .finally(() => {
          this.selectedItemsToDelete = [];
          this.dialogDelete = false;
        });
    },
    deleteReject() {
      this.selectedItemsToDelete = [];
      this.dialogDelete = false;
    },
    cancelItem(item) {
      this.selectedItemsToCancel = [];
      this.selectedItemsToCancel.push(item);
      this.dialogCancel = true;
    },
    cancelItems() {
      this.selectedItemsToCancel = [];
      this.selectedItemsToCancel = this.selectedRowsItems;
      this.dialogCancel = true;
    },
    cancelConfirm(items) {
      this.$store.dispatch(TOWING_ADHOC_TASK_CANCEL, items)
        .finally(() => {
          this.selectedItemsToCancel = [];
          this.dialogCancel = false;
        });
    },
    cancelReject() {
      this.selectedItemsToCancel = [];
      this.dialogCancel = false;
    },
    approveItem(item) {
      this.selectedItemsToApprove = [];
      this.selectedItemsToApprove.push(item);
      this.dialogApprove = true;
    },
    approveItems() {
      this.selectedItemsToApprove = [];
      this.selectedItemsToApprove = this.selectedRowsItems;
      this.dialogApprove = true;
    },
    approveConfirm(items) {
      this.$store.dispatch(TOWING_ADHOC_TASK_APPROVE, items)
        .finally(() => {
          this.selectedItemsToApprove = [];
          this.dialogApprove = false;
        });
    },
    approveReject() {
      this.selectedItemsToApprove = [];
      this.dialogApprove = false;
    },
  },
};
</script>
