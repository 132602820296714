/* eslint no-param-reassign: "off" */
import { HTTP } from '../../../http';

// action
export const ADMIN_INFORM_BULK = 'ADMIN_INFORM_BULK';
export const ADMIN_AIRCRAFT_GROUND_INFORM_BULK = 'ADMIN_AIRCRAFT_GROUND_INFORM_BULK';

// mutations
export const ADMIN_INFORM_BULK_REQUEST = 'ADMIN_INFORM_BULK_REQUEST';
export const ADMIN_INFORM_BULK_RESPONSE = 'ADMIN_INFORM_BULK_RESPONSE';
export const ADMIN_INFORM_BULK_ERROR = 'ADMIN_INFORM_BULK_ERROR';

// initial state
const storeState = {
  bulkLoading: null,
  bulkSuccess: null,
  bulkResult: [],
  bulkLastRequestTime: null,
};

// getters
const storeGetters = {
  isInformBulkSuccess: state => state.bulkSuccess != null && !!state.bulkSuccess,
  isInformBulkError: state => state.bulkSuccess != null && !state.bulkSuccess,
};

// actions
const storeActions = {
  [ADMIN_INFORM_BULK]: ({ commit }) => {
    commit(ADMIN_INFORM_BULK_REQUEST);
    return HTTP.post('inform/bulk')
      .then((resp) => {
        commit(ADMIN_INFORM_BULK_RESPONSE, resp.data);
      })
      .catch((err) => {
        commit(ADMIN_INFORM_BULK_ERROR, err.response.data);
      });
  },
  [ADMIN_AIRCRAFT_GROUND_INFORM_BULK]: ({ commit }) => {
    commit(ADMIN_INFORM_BULK_REQUEST);
    return HTTP.post('inform/bulk/aircraftGround')
      .then((resp) => {
        commit(ADMIN_INFORM_BULK_RESPONSE, resp.data);
      })
      .catch((err) => {
        commit(ADMIN_INFORM_BULK_ERROR, err.response.data);
      });
  },
};

// mutations
const storeMutations = {
  [ADMIN_INFORM_BULK_REQUEST]: (state) => {
    state.bulkLoading = true;
    state.bulkSuccess = null;
    state.bulkResult = [];
    state.bulkLastRequestTime = new Date();
  },
  [ADMIN_INFORM_BULK_RESPONSE]: (state, result) => {
    state.bulkLoading = false;
    state.bulkSuccess = result.success;
    state.bulkResult = result.traces;
  },
  [ADMIN_INFORM_BULK_ERROR]: (state, result) => {
    state.bulkLoading = false;
    state.bulkSuccess = false;
    state.bulkResult = [result.traces, result.message, result.detail];
  },
};

export default {
  state: storeState,
  getters: storeGetters,
  actions: storeActions,
  mutations: storeMutations,
};
