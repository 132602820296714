<script>
import Vue from 'vue';
import AgGridApiHoldable from './AgGridApiHoldable.vue';
import AgGridOptionsConfigurable from './AgGridOptionsConfigurable.vue';
import AgGridValueComparable from './AgGridValueComparable.vue';
import AgGridTimeValueGetteable from './AgGridTimeValueGetteable.vue';
import Resizable from '../../mixins/Resizable.vue';

export default Vue.extend({
  mixins: [AgGridApiHoldable, AgGridOptionsConfigurable, AgGridValueComparable, AgGridTimeValueGetteable, Resizable],
});
</script>
