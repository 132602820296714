export default class DelayFilter {
  constructor(dateFrom, dateTo, onlyUnarchived, onlyCurrentDesk, showFlights, showAircraftGround, timezoneDate) {
    this.dateFrom = dateFrom;
    this.dateTo = dateTo;
    this.onlyUnarchived = onlyUnarchived === true;
    this.onlyCurrentDesk = onlyCurrentDesk === true;
    this.showFlights = showFlights === true;
    this.showAircraftGround = showAircraftGround === true;
    this.timezoneDate = timezoneDate;
  }

  static get createEmptyFilter() {
    return new DelayFilter(null, null, false, false, true, true, null);
  }
}
