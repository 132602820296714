<template>
  <ag-grid-template-cell-renderer :params="params">
    <v-icon small v-if="isCheck">check</v-icon>
    <v-icon small v-if="!isCheck">clear</v-icon>
  </ag-grid-template-cell-renderer>
</template>

<script>
import Vue from 'vue';
import AgGridTemplateCellRenderer from './templates/AgGridTemplateCellRenderer.vue';

export default Vue.extend({
  components: {
    AgGridTemplateCellRenderer,
  },
  computed: {
    isCheck() {
      return this.params.value;
    },
  },
});
</script>

<style scoped>

</style>
