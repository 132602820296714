export const FlightStatus = Object.freeze({
  SCHEDULED: Symbol('SCHEDULED'),
  ESTIMATED: Symbol('ESTIMATED'),
  LANDED: Symbol('LANDED'),
  ON_BLOCK: Symbol('ON_BLOCK'),
  OFF_BLOCK: Symbol('OFF_BLOCK'),
  DELAYED: Symbol('DELAYED'),
  CANCELLED: Symbol('CANCELLED'),
  AIR: Symbol('AIR'),
  GROUND: Symbol('GROUND'),
});

export const statusTextResize = function statusTextResize(fligthStatus) {
  switch (fligthStatus) {
    case 'SCHEDULED': return 'SCHD';
    case 'ESTIMATED': return 'ESTM';
    case 'LANDED': return 'LAND';
    case 'ONBLOCK': return 'ONBK';
    case 'OFFBLOCK': return 'OFFB';
    case 'DELAYED': return 'DEL';
    case 'CANCELLED': return 'CANC';
    case 'AIR': return 'AIR';
    case 'ON_AIR': return 'AIR';
    case 'GROUND': return 'GRND';
    default: return fligthStatus;
  }
};
