import moment from 'moment';

export class JSUtils {
  static getDatesDiff(date1, date2) {
    if (date1 && date2) {
      const dateAux1 = moment(date1);
      const dateAux2 = moment(date2);
      const diff = dateAux1.diff(dateAux2, 'days', true);
      if (Math.abs(diff) < 1 && dateAux1.date() === dateAux2.date()) {
        return 0;
      }
      return diff;
    }
    return 0;
  }

  static getDatesInMinutesDiff(date1, date2) {
    if (date1 && date2) {
      const dateAux1 = moment(date1);
      const dateAux2 = moment(date2);
      const diff = dateAux1.diff(dateAux2, 'minutes', true);
      if (Math.abs(diff) < 1 && dateAux1.date() === dateAux2.date()) {
        return 0;
      }
      return diff;
    }
    return 0;
  }

  static formattedTimeCsv(time) {
    if (time == null || time.trim().length === 0) {
      return null;
    }

    const fixedTime = time.replace(':', '').padStart(4, '0');
    return moment(fixedTime, 'hmm').format('HH:mm');
  }
}
