import i18n from '../../../plugins/i18n';

export const AirlineFareUnitTypes = Object.freeze({
  UNITS: 'UNITS',
  KILOS: 'KILOS',
});

export const i18nValue = function i18nValue(airlineFareUnitType) {
  switch (airlineFareUnitType) {
    case 'UNITS': return i18n.t('airlineFares.units');
    case 'KILOS': return i18n.t('airlineFares.kilos');
    default: return airlineFareUnitType;
  }
};
