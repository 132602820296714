import { JSUtils } from '../../shared/js/JSUtils';

export default class FlightServicesInformation {
  constructor(
    id,
    serviceACURequested,
    serviceACUId,
    serviceACURequestTs,
    serviceACURequestedTs,
    serviceACUStartTs,
    serviceACUFinishTs,
    serviceACUUnits,
    serviceACURemarks,
    serviceASURequested,
    serviceASUId,
    serviceASURequestTs,
    serviceASURequestedTs,
    serviceASUStartTs,
    serviceASUFinishTs,
    serviceASUUnits,
    serviceASURemarks,
    serviceASUMinutes,
    serviceGPURequested,
    serviceGPUId,
    serviceGPURequestTs,
    serviceGPURequestedTs,
    serviceGPUStartTs,
    serviceGPUFinishTs,
    serviceGPUUnits,
    serviceGPURemarks,
    serviceGPUMinutes,
    serviceOTHRequested,
    serviceOTHId,
    serviceOTHRequestTs,
    serviceOTHRequestedTs,
    serviceOTHStartTs,
    serviceOTHFinishTs,
    serviceOTHUnits,
    serviceOTHRemarks,
    serviceOTHMinutes,
    servicePBKRequested,
    servicePBKId,
    servicePBKRequestTs,
    servicePBKRequestedTs,
    servicePBKStartTs,
    servicePBKFinishTs,
    servicePBKUnits,
    servicePBKRemarks,
    servicePBKMinutes,
    rampServices,
    rampServicesStatus,
  ) {
    this.id = id;
    this.serviceACURequested = serviceACURequested;
    this.serviceACUId = serviceACUId;
    this.serviceACURequestTs = serviceACURequestTs;
    this.serviceACURequestedTs = serviceACURequestedTs;
    this.serviceACUStartTs = serviceACUStartTs;
    this.serviceACUFinishTs = serviceACUFinishTs;
    this.serviceACUUnits = serviceACUUnits;
    this.serviceACURemarks = serviceACURemarks;
    this.serviceASURequested = serviceASURequested;
    this.serviceASUId = serviceASUId;
    this.serviceASURequestTs = serviceASURequestTs;
    this.serviceASURequestedTs = serviceASURequestedTs;
    this.serviceASUStartTs = serviceASUStartTs;
    this.serviceASUFinishTs = serviceASUFinishTs;
    this.serviceASUUnits = serviceASUUnits;
    this.serviceASURemarks = serviceASURemarks;
    this.serviceASUMinutes = serviceASUMinutes;
    this.serviceGPURequested = serviceGPURequested;
    this.serviceGPUId = serviceGPUId;
    this.serviceGPURequestTs = serviceGPURequestTs;
    this.serviceGPURequestedTs = serviceGPURequestedTs;
    this.serviceGPUStartTs = serviceGPUStartTs;
    this.serviceGPUFinishTs = serviceGPUFinishTs;
    this.serviceGPUUnits = serviceGPUUnits;
    this.serviceGPURemarks = serviceGPURemarks;
    this.serviceGPUMinutes = serviceGPUMinutes;
    this.serviceOTHRequested = serviceOTHRequested;
    this.serviceOTHId = serviceOTHId;
    this.serviceOTHRequestTs = serviceOTHRequestTs;
    this.serviceOTHRequestedTs = serviceOTHRequestedTs;
    this.serviceOTHStartTs = serviceOTHStartTs;
    this.serviceOTHFinishTs = serviceOTHFinishTs;
    this.serviceOTHUnits = serviceOTHUnits;
    this.serviceOTHRemarks = serviceOTHRemarks;
    this.serviceOTHMinutes = serviceOTHMinutes;
    this.servicePBKRequested = servicePBKRequested;
    this.servicePBKId = servicePBKId;
    this.servicePBKRequestTs = servicePBKRequestTs;
    this.servicePBKRequestedTs = servicePBKRequestedTs;
    this.servicePBKStartTs = servicePBKStartTs;
    this.servicePBKFinishTs = servicePBKFinishTs;
    this.servicePBKUnits = servicePBKUnits;
    this.servicePBKRemarks = servicePBKRemarks;
    this.servicePBKMinutes = servicePBKMinutes;
    this.rampServices = rampServices;
    this.rampServicesStatus = rampServicesStatus;
  }

  static get createEmptyFlightServicesInformation() {
    return new FlightServicesInformation();
  }

  static createFlightServicesInformationFromData(o) {
    if (!o) {
      return this.createEmptyFlightServicesInformation;
    }

    return JSUtils.cast(o, FlightServicesInformation);
  }
}
