<template>
  <!--<v-container>-->
    <!--<v-layout row wrap>-->
      <!--<directory-board/>-->
    <!--</v-layout>-->
  <!--</v-container>-->
  <div>
    <directory-board/>
  </div>
</template>

<script>
import DirectoryBoard from '../components/directory/DirectoryBoard.vue';

export default {
  name: 'Directory',
  components: {
    DirectoryBoard,
  },
};
</script>
