/* eslint no-param-reassign: "off" */
/* eslint no-confusing-arrow: "off" */
/* eslint no-nested-ternary: "off" */

import { HTTP } from '../../http';
import { REQUEST_ERROR, REQUEST_START, REQUEST_SUCCESS } from '../store';
import { NOTIFICATION_SHOW } from './notifications';
import i18n from '../../plugins/i18n';

// actions names
export const GET_SERVICE_TYPE = 'GET_SERVICE_TYPE';
export const SERVICE_TYPE_DELETE = 'SERVICE_TYPE_DELETE';
export const SERVICE_TYPE_CREATE = 'SERVICE_TYPE_CREATE';
export const SERVICE_TYPE_UPDATE = 'SERVICE_TYPE_UPDATE';
export const SERVICE_TYPE_DISABLE = 'SERVICE_TYPE_DISABLE';
export const SERVICE_TYPE_ENABLE = 'SERVICE_TYPE_ENABLE';

// mutations
export const CHANGE_SERVICE_TYPE_SELECTION = 'CHANGE_SERVICE_TYPE_SELECTION';
export const GET_SERVICE_TYPE_SUCCESS = 'GET_SERVICE_TYPE_SUCCESS';
export const GET_SERVICE_TYPE_REQUESTED = 'GET_SERVICE_TYPE_REQUESTED';
export const UPDATE_SERVICE_TYPE_SUCCESS = 'UPDATE_SERVICE_TYPE_SUCCESS';

// initial state
const storeState = {
  serviceType: [],
  serviceTypeRequested: false,
  selectedServiceType: [],
};

// getters
const storeGetters = {
  getServiceTypes: state => state.serviceType.sort((a, b) => (a.registerNumber > b.registerNumber) ? 1 : ((b.registerNumber > a.registerNumber) ? -1 : 0)),
  getServiceTypeSelected: state => state.selectedServiceType,
  getActiveServiceTypes: state => state.serviceType.filter(elem => !elem.deleted),
  getActiveServiceTypesFilteredToConnectedFlights: state => state.serviceType.filter(elem => !elem.deleted).filter(elem => elem.code !== 'SPL'),
  getActiveServiceTypesFilteredToSplitFlights: state => state.serviceType.filter(elem => !elem.deleted).filter(elem => elem.code !== 'TRN'),
  hasSelectedServiceType: state => state.selectedServiceType.length > 0,
  getServiceTypeById: state => id => state.serviceType.find(a => a.id === id),
};

// actions
const storeActions = {
  [GET_SERVICE_TYPE]: ({ commit, state }) => {
    if (state.serviceTypeRequested) {
      return;
    }
    commit(REQUEST_START);
    commit(GET_SERVICE_TYPE_REQUESTED);
    HTTP.get('serviceType')
      .then((resp) => {
        commit(REQUEST_SUCCESS);
        commit(GET_SERVICE_TYPE_SUCCESS, resp.data);
      })
      .catch((err) => {
        commit(REQUEST_ERROR, err);
      });
  },

  [SERVICE_TYPE_CREATE]: ({ commit, dispatch }, serviceType) => {
    commit(REQUEST_START);
    return HTTP.post('serviceType', serviceType)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(UPDATE_SERVICE_TYPE_SUCCESS);
        dispatch(GET_SERVICE_TYPE);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.serviceTypeCreate'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },

  [SERVICE_TYPE_UPDATE]: ({ commit, dispatch }, serviceType) => {
    commit(REQUEST_START);
    return HTTP.put('serviceType', serviceType)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(UPDATE_SERVICE_TYPE_SUCCESS);
        dispatch(GET_SERVICE_TYPE);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.serviceTypeUpdate'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
  [SERVICE_TYPE_DELETE]: ({ commit, dispatch }, id) => {
    commit(REQUEST_START);
    return HTTP.delete(`serviceType/${id}`)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(UPDATE_SERVICE_TYPE_SUCCESS);
        dispatch(GET_SERVICE_TYPE);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.serviceTypeDelete'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
  [SERVICE_TYPE_DISABLE]: ({ commit, dispatch }, id) => {
    commit(REQUEST_START);
    return HTTP.patch(`/serviceType/disable/${id}`)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(UPDATE_SERVICE_TYPE_SUCCESS);
        dispatch(GET_SERVICE_TYPE);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.serviceTypeDisable'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
  [SERVICE_TYPE_ENABLE]: ({ commit, dispatch }, id) => {
    commit(REQUEST_START);
    return HTTP.patch(`/serviceType/enable/${id}`)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(UPDATE_SERVICE_TYPE_SUCCESS);
        dispatch(GET_SERVICE_TYPE);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.serviceTypeEnable'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
};

// mutations
const storeMutations = {
  [GET_SERVICE_TYPE_SUCCESS]: (state, serviceType) => {
    state.serviceType = serviceType;
  },
  [GET_SERVICE_TYPE_REQUESTED]: (state) => {
    state.serviceTypeRequested = true;
  },
  [UPDATE_SERVICE_TYPE_SUCCESS]: (state) => {
    state.serviceTypeRequested = false;
  },
  [CHANGE_SERVICE_TYPE_SELECTION]: (state, selection) => {
    state.selectedServiceType = selection;
  },
};

export default {
  state: storeState,
  getters: storeGetters,
  actions: storeActions,
  mutations: storeMutations,
};
