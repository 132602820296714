<template>
  <div>
    <airport-baggage-delivery-select
    :ref="'refComp'"
    v-on:keydown.native="keyEvent"
    v-char-length.clearable="4"
    :type="slaBaggageDeliveryType"
    v-model="itemId"
    @input-code="setItemCode($event)"/>
  </div>
</template>

<script>
import Vue from 'vue';
import AirportBaggageDeliverySelect from '../../../configuration/airportSLABaggageDelivery/AirportSLABaggageDeliverySelect.vue';
import AgGridParentConnectedFlightsCellEditor from '../mixins/AgGridParentConnectedFlightsCellEditor.vue';
import { AirportSLABaggageDeliveryType } from '../../../configuration/airportSLABaggageDelivery/model/airportSLABaggageDeliveryType';

export default Vue.extend({
  mixins: [AgGridParentConnectedFlightsCellEditor],
  components: {
    AirportBaggageDeliverySelect,
  },
  data() {
    return {
      itemId: null,
      itemIdOriginal: null,
      itemCode: null,
    };
  },
  computed: {
    isFirstSLABaggageDelivery() {
      return this.params.column.colDef.colCustomConfig.fieldEditor === 'firstSLABaggageDelivery';
    },
    isLastSLABaggageDelivery() {
      return this.params.column.colDef.colCustomConfig.fieldEditor === 'lastSLABaggageDelivery';
    },
    isPrioritySLABaggageDelivery() {
      return this.params.column.colDef.colCustomConfig.fieldEditor === 'prioritySLABaggageDelivery';
    },
    isSubPrioritySLABaggageDelivery() {
      return this.params.column.colDef.colCustomConfig.fieldEditor === 'subPrioritySLABaggageDelivery';
    },
    slaBaggageDeliveryType() {
      if (this.isFirstSLABaggageDelivery) {
        return AirportSLABaggageDeliveryType.Q1.description;
      } if (this.isLastSLABaggageDelivery) {
        return AirportSLABaggageDeliveryType.QL.description;
      } if (this.isPrioritySLABaggageDelivery) {
        return AirportSLABaggageDeliveryType.QP1.description;
      } if (this.isSubPrioritySLABaggageDelivery) {
        return AirportSLABaggageDeliveryType.QP2.description;
      }
      return null;
    },
  },
  methods: {
    getValue() {
      this.unmarkCellsSelectedAsEdit();

      if (this.itemIdOriginal !== this.itemId) {
        const that = this;

        this.saveFlightChangesFromValue((s) => {
          if (that.isFirstSLABaggageDelivery) {
            s.setChildPropertyValueByBound(this.params.column.colDef.colCustomConfig.flightBound, 'slaBaggageDelivery.firstSLABaggageDeliveryId', that.itemId);
            s.setChildPropertyValueByBound(this.params.column.colDef.colCustomConfig.flightBound, 'slaBaggageDelivery.firstSLABaggageDeliveryCode', that.itemCode);
          } else if (that.isLastSLABaggageDelivery) {
            s.setChildPropertyValueByBound(this.params.column.colDef.colCustomConfig.flightBound, 'slaBaggageDelivery.lastSLABaggageDeliveryId', that.itemId);
            s.setChildPropertyValueByBound(this.params.column.colDef.colCustomConfig.flightBound, 'slaBaggageDelivery.lastSLABaggageDeliveryCode', that.itemCode);
          } else if (that.isPrioritySLABaggageDelivery) {
            s.setChildPropertyValueByBound(this.params.column.colDef.colCustomConfig.flightBound, 'slaBaggageDelivery.prioritySLABaggageDeliveryId', that.itemId);
            s.setChildPropertyValueByBound(this.params.column.colDef.colCustomConfig.flightBound, 'slaBaggageDelivery.prioritySLABaggageDeliveryCode', that.itemCode);
          } else if (that.isSubPrioritySLABaggageDelivery) {
            s.setChildPropertyValueByBound(this.params.column.colDef.colCustomConfig.flightBound, 'slaBaggageDelivery.subPrioritySLABaggageDeliveryId', that.itemId);
            s.setChildPropertyValueByBound(this.params.column.colDef.colCustomConfig.flightBound, 'slaBaggageDelivery.subPrioritySLABaggageDeliveryCode', that.itemCode);
          }
        }, 'SLA Baggage Delivery', this.itemIdOriginal, this.itemId);

        return that.itemCode;
      }
      return this.params.node.data;
    },
    setItemCode(code) {
      this.itemCode = code;
    },
  },
  created() {
    const connectedFlights = this.params.node.data.clone();
    if (this.isFirstSLABaggageDelivery) {
      this.itemIdOriginal = connectedFlights.getChildPropertyValue(this.params.column.colDef.colCustomConfig.flightBound, ['slaBaggageDelivery', 'firstSLABaggageDeliveryId']);
    } else if (this.isLastSLABaggageDelivery) {
      this.itemIdOriginal = connectedFlights.getChildPropertyValue(this.params.column.colDef.colCustomConfig.flightBound, ['slaBaggageDelivery', 'lastSLABaggageDeliveryId']);
    } else if (this.isPrioritySLABaggageDelivery) {
      this.itemIdOriginal = connectedFlights.getChildPropertyValue(this.params.column.colDef.colCustomConfig.flightBound, ['slaBaggageDelivery', 'prioritySLABaggageDeliveryId']);
    } else if (this.isSubPrioritySLABaggageDelivery) {
      this.itemIdOriginal = connectedFlights.getChildPropertyValue(this.params.column.colDef.colCustomConfig.flightBound, ['slaBaggageDelivery', 'subPrioritySLABaggageDeliveryId']);
    }
    this.itemId = this.itemIdOriginal;

    this.markCellsSelectedAsEdit();
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$refs.refComp && this.$refs.refComp.$refs.refComp) {
        this.$refs.refComp.$refs.refComp.focus();
      }
    });
  },
});
</script>

<style>

</style>
