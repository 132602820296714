import RushBaggageFactory from './RushBaggageFactory';

export default class RushBaggageEvent {
  constructor(
    type,
    ts,
    origin,
    payload,
  ) {
    this.type = type;
    this.ts = ts;
    this.origin = origin;
    this.payload = payload;
  }
}

export const createRushBaggageEventFromData = function createRushBaggageEventFromData(data) {
  if (data) {
    return new RushBaggageEvent(
      data.type,
      data.ts,
      data.origin,
      RushBaggageFactory.createRushBaggageFromData(data.payload),
    );
  }

  throw new Error('No data for RushBaggageEvent');
};
