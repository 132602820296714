<template>
  <div>
    <ag-grid-vue
      class="ag-theme-material connected-flights-table operational-table"
      :columnDefs="columnDefs"
      :defaultColDef="defaultColDef"
      :rowData="items"
      rowSelection="multiple"
      :rowMultiSelectWithClick="true"
      :suppressRowClickSelection="true"
      @grid-ready="onGridReady"
      @first-data-rendered="firstDataRendered"
      @selection-changed="onSelectionChanged"
      :suppressHorizontalScroll="true"
      :frameworkComponents="frameworkComponents"
      :gridOptions="gridOptions"
      :multiSortKey="multiSortKey"
      v-bind:style="`width: 100%; height: calc(100vh - ${this.sizeFilter} - 8vh);`">
    </ag-grid-vue>
    <v-divider></v-divider>
    <ag-grid-vue
      class="ag-theme-material connected-flights-table operational-table"
      :columnDefs="columnDefsT"
      :defaultColDef="defaultColDef"
      :headerHeight="0"
      :rowData="itemTotal"
      :gridOptions="gridOptionsTotal"
      :suppressHorizontalScroll="true"
      :frameworkComponents="frameworkComponents"
      v-bind:style="`width: 100%; height: 8vh; font-weight: bold`">
    </ag-grid-vue>
  </div>
</template>
<script>
import { AgGridVue } from 'ag-grid-vue';
import AgGridConnectedFlights from '../../ag-grid/mixins/AgGridConnectedFlights.vue';
import {
  CHANGE_TALLYING_CASH_SELECTION,
  TALLYING_CASH_CHANGE_GRID_API,
  TALLYING_CASH_FIT_COLUMNS_TO_VIEW,
} from '../../../store/modules/ticketingReports/tallyingCash';
import { cellClassAgGridRules } from '../../ag-grid/mixins/cellClassAgGridRules';
import { FisModule } from '../../ag-grid/cell-editors/model/fisModule';
import agGridTextCustomFilterComparator from '../../ag-grid/filters/agGridTextCustomFilterComparator';
import agGridTextCustomFilterFormatter from '../../ag-grid/filters/agGridTextCustomFilterFormatter';
import agGridDateFormatter from '../../ag-grid/formatters/agGridDateFormatter';
import AgGridCustomService from '../../shared/services/agGridCustomService';
import AgGridValueBaseCellRenderer from '../../ag-grid/cell-renderers/AgGridValueBaseCellRenderer.vue';
import AgGridCurrencyCellRenderer from '../../ag-grid/cell-renderers/AgGridCurrencyCellRenderer.vue';
import AgGridPaymentTypeCellRenderer from '../../ag-grid/cell-renderers/AgGridPaymentTypeCellRenderer.vue';
import AgGridTallyingCashCurrencyCellEditor
from '../../ag-grid/cell-editors/tallyingCash/AgGridTallyingCashCurrencyCellEditor.vue';
import RolePermissions from '../../mixins/RolePermissions.vue';
import { ROLE_ADMINISTRATOR_TICKETING } from '../../../constants/roles';
import agGridPaymentTypeFilterComparator from '../../ag-grid/filters/agGridPaymentTypeFilterComparator';

export default {
  name: 'tallying-cash-list-table',
  mixins: [AgGridConnectedFlights, RolePermissions],
  components: {
    AgGridVue,
    AgGridValueBaseCellRenderer,
    AgGridCurrencyCellRenderer,
    AgGridPaymentTypeCellRenderer,
  },
  props: {
    items: { type: Array, required: true },
    itemTotal: { type: Array, required: true },
    isFilterDisplayed: { type: Boolean, required: false, default: false },
  },
  computed: {
    sizeFilter() {
      return this.isFilterDisplayed ? '250px' : '180px';
    },
    fitColumnsOnResize() {
      return this.$store.state.ticketingReports.tallyingCash.fitColumnsOnResize;
    },
    authUser() {
      return this.$store.state.auth.authUser;
    },
    ticketingUserRole() {
      return this.$store.state.ticketing.ticketingUserRole;
    },
  },
  data() {
    const that = this;
    const rolePermission = this.getRoles();
    return {
      frameworkComponents: {
        AgGridTallyingCashCurrencyCellEditor,
      },
      defaultColDef: {
        cellClassRules: cellClassAgGridRules,
        minWidth: 30,
        suppressMenu: true,
        sortable: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        filterParams: {
          textCustomComparator: agGridTextCustomFilterComparator,
          textFormatter: agGridTextCustomFilterFormatter,
        },
        editable: false,
      },
      multiSortKey: 'ctrl',
      gridOptions: {
        onFilterChanged: this.uncheckAllOnFilter,
        getRowNodeId: this.getRowNodeId,
        getRowClass: this.getRowClass,
        floatingFilter: true,
        groupHeaderHeight: 35,
        headerHeight: 35,
        floatingFiltersHeight: 20,
        rowHeight: 30,
        context: {
          module: FisModule.TICKETING_REPORTS,
        },
        alignedGrids: [],
      },
      gridOptionsTotal: {
        getRowNodeId: this.getRowNodeId,
        getRowClass: this.getRowClass,
        floatingFilter: true,
        groupHeaderHeight: 35,
        headerHeight: 35,
        floatingFiltersHeight: 20,
        rowHeight: 30,
        context: {
          module: FisModule.TICKETING_REPORTS,
        },
        alignedGrids: [],
      },
      columnDefs: [
        {
          headerTooltip: 'Check/uncheck all',
          hide: !this.hasPermissions(rolePermission),
          checkboxSelection: true,
          width: 21,
          minWidth: 20,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          suppressMenu: true,
          sortable: false,
          suppressMovable: true,
          suppressSizeToFit: true,
          filter: false,
          resizable: false,
          colCustomConfig: {
            exportCsvDisabled: true,
          },
          editable: this.editable(this.checkIfUserIsAdministrator, rolePermission),
        },
        {
          colId: 'tallyingCash.tallyingCashDate',
          field: 'tallyingCashDate',
          headerValueGetter() {
            return that.$i18n.t('common.date');
          },
          type: 'date',
          valueFormatter: agGridDateFormatter,
        },
        {
          colId: 'tallyingCash.user',
          hide: !this.checkIfUserIsAdministrator(),
          headerValueGetter() {
            return that.$i18n.t('ticketing.user');
          },
          field: 'userTickets',
          cellRendererFramework: AgGridValueBaseCellRenderer,
        },
        {
          colId: 'tallyingCash.airlineCode',
          headerValueGetter() {
            return that.$i18n.t('common.airline');
          },
          field: 'airlineCode',
          cellRendererFramework: AgGridValueBaseCellRenderer,
        },
        {
          colId: 'tallyingCash.currency',
          headerValueGetter() {
            return that.$i18n.t('contingencies.currency');
          },
          field: 'currency',
          cellRendererFramework: AgGridValueBaseCellRenderer,
        },
        {
          colId: 'tallyingCash.paymentType',
          headerValueGetter() {
            return that.$i18n.t('ticketing.paymentType');
          },
          field: 'paymentType',
          filterParams: {
            textCustomComparator: agGridPaymentTypeFilterComparator,
          },
          cellRendererFramework: AgGridPaymentTypeCellRenderer,
        },
        {
          colId: 'tallyingCash.totalTickets',
          headerValueGetter() {
            return that.$i18n.t('ticketing.totalAmountTickets');
          },
          field: 'totalTickets',
          colCustomConfig: {
            valueGetterExportCsv(params) {
              return that.formatNumberCurrency(params.data.currency, params.data.totalTickets);
            },
          },
          valueGetter(params) {
            return {
              value: params.data.totalTickets,
              currency: params.data.currency,
            };
          },
          cellRendererFramework: AgGridCurrencyCellRenderer,
        },
        {
          colId: 'tallyingCash.totalCompany',
          headerValueGetter() {
            return that.$i18n.t('ticketing.totalAmountCompany');
          },
          field: 'totalCompany',
          cellClass: 'total-company-cell',
          editable: this.editable(this.checkIfUserIsAdministrator, rolePermission),
          cellEditor: 'AgGridTallyingCashCurrencyCellEditor',
          colCustomConfig: {
            valueGetterExportCsv(params) {
              return that.formatNumberCurrency(params.data.currency, params.data.totalCompany);
            },
          },
          valueGetter(params) {
            return {
              value: params.data.totalCompany,
              currency: params.data.currency,
            };
          },
          cellRendererFramework: AgGridCurrencyCellRenderer,
        },
        {
          colId: 'tallyingCash.cashMismatch',
          headerValueGetter() {
            return that.$i18n.t('ticketing.cashMismatch');
          },
          field: 'cashMismatch',
          colCustomConfig: {
            valueGetterExportCsv(params) {
              return that.formatNumberCurrency(params.data.currency, params.data.cashMismatch);
            },
          },
          valueGetter(params) {
            return {
              value: params.data.cashMismatch,
              currency: params.data.currency,
            };
          },
          cellRendererFramework: AgGridCurrencyCellRenderer,
        },
      ],
      columnDefsT: [
        {
          headerTooltip: 'Check/uncheck all',
          hide: !this.hasPermissions(rolePermission),
          checkboxSelection: false,
          width: 21,
          minWidth: 20,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          suppressMenu: true,
          sortable: false,
          suppressMovable: true,
          suppressSizeToFit: true,
          filter: false,
          resizable: false,
          colCustomConfig: {
            exportCsvDisabled: true,
          },
          editable: this.editable(this.checkIfUserIsAdministrator, rolePermission),
        },
        {
          colId: 'tallyingCash.tallyingCashDate',
          headerValueGetter() {
            return that.$i18n.t('common.date');
          },
          field: 'totalTickets',
        },
        {
          colId: 'tallyingCash.user',
          hide: !this.checkIfUserIsAdministrator(),
          headerValueGetter() {
            return that.$i18n.t('ticketing.user');
          },
        },
        {
          colId: 'tallyingCash.airlineCode',
          headerValueGetter() {
            return that.$i18n.t('common.airline');
          },
          field: 'totalTicketsAmount',
        },
        {
          colId: 'tallyingCash.currency',
          headerValueGetter() {
            return that.$i18n.t('contingencies.currency');
          },
          field: 'totalCompany',
        },
        {
          colId: 'tallyingCash.paymentType',
          headerValueGetter() {
            return that.$i18n.t('ticketing.paymentType');
          },
          field: 'totalCompanyAmount',
        },
        {
          colId: 'tallyingCash.totalTickets',
          headerValueGetter() {
            return that.$i18n.t('ticketing.totalAmountTickets');
          },
        },
        {
          colId: 'tallyingCash.totalCompany',
          headerValueGetter() {
            return that.$i18n.t('ticketing.totalAmountCompany');
          },
          field: 'totalCashMismatch',
        },
        {
          colId: 'tallyingCash.cashMismatch',
          headerValueGetter() {
            return that.$i18n.t('ticketing.cashMismatch');
          },
          field: 'totalCashMismatchAmount',
        },
      ],
    };
  },
  methods: {
    onGridReady(params) {
      this.setAgGridApi(params);
      this.showLoading();
      this.$store.commit(TALLYING_CASH_CHANGE_GRID_API, params);
      this.refreshCellsOnMutation();
      this.refreshCellsOnChangeLanguage();
    },
    resized() {
      if (this.gridApi) {
        this.gridApi.sizeColumnsToFit();
      }
    },
    renderNeededDiffs(differences, rowNode) {
      const { gridApi } = this.$store.state.ticketingReports.tallyingCash;

      const agGridCustomService = new AgGridCustomService(this.columnDefs);
      const colIds = agGridCustomService.getColIdByMatchAnyfieldsRelated(this.joinAllPropertyPath(differences));

      if (colIds && colIds.length > 0) {
        colIds.forEach((colId) => {
          if (gridApi.getColumnDef(colId)) {
            gridApi.refreshCells({
              rowNodes: [rowNode],
              columns: [colId],
              force: true,
            });

            gridApi.flashCells({
              rowNodes: [rowNode],
              columns: [colId],
              force: true,
            });
          }
        });
      }
    },
    sizeColumnsToFit() {
      if (this.gridApi) {
        this.$store.dispatch(TALLYING_CASH_FIT_COLUMNS_TO_VIEW);
      }
    },
    firstDataRendered() {
      this.$nextTick(() => {
        this.resized();
      });
    },
    onSelectionChanged(event) {
      const selectedNodes = event.api.getSelectedNodes();
      const selectedData = selectedNodes.map(node => node.data);
      this.$store.commit(CHANGE_TALLYING_CASH_SELECTION, selectedData);
    },
    formatNumberCurrency(currency, value) {
      if (currency != null && value != null) {
        return new Intl.NumberFormat(this.$store.getters.getCurrencies.find(p => p.id === currency).locale, { style: 'currency', currency }).format(value);
      }
      return '';
    },
    checkIfUserIsAdministrator() {
      const a = this.$store.state.ticketing.ticketingUserRole === ROLE_ADMINISTRATOR_TICKETING;
      const b = this.ticketingUserRole === ROLE_ADMINISTRATOR_TICKETING;

      return a || b;
    },
  },
  mounted() {
    this.gridOptions.alignedGrids.push(this.gridOptionsTotal);
    this.gridOptionsTotal.alignedGrids.push(this.gridOptions);
  },
};
</script>
