<template>
  <v-container class="flightPassageBoarding pa-0" fluid v-if="flightPassageBoarding != null">
    <v-form ref="form">
      <v-toolbar dark color="primary">
        <v-icon>flight</v-icon>
        <v-toolbar-title>{{ flightPassageBoarding.flightNumber }} - {{ flightPassageBoarding.airport1Code
        }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-title>Passage Boarding</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-title>
          &nbsp;{{ flightPassageBoarding.scheduledTime | moment('DD/MM/YY HH:mm') }}
        </v-toolbar-title>
      </v-toolbar>
      <v-card :dark="this.$vuetify.dark" v-if="flightPassageBoarding != null">
        <v-card-title>
          <v-icon class="pr-2">info</v-icon>
          <div class="font-weight-bold">
            {{ $t('commonForm.flightInformation') }}
          </div>
        </v-card-title>
        <v-divider />
        <v-card-text class="pa-2">

          <v-container grid-list-md text-xs-center fluid class="pa-0">
            <v-layout row wrap>
              <v-flex xs12>
                <v-layout>
                  <v-flex xs2>
                    <v-list-tile>
                      <v-list-tile-avatar size="32">
                        <v-icon>mdi-calendar</v-icon>
                      </v-list-tile-avatar>
                      <v-list-tile-content>
                        <v-list-tile-title>{{ $t('common.date') }}</v-list-tile-title>
                        <v-list-tile-sub-title>{{ flightPassageBoarding.flightDate }}</v-list-tile-sub-title>
                      </v-list-tile-content>
                    </v-list-tile>
                    <v-divider />
                  </v-flex>
                  <v-flex xs2>
                    <v-list-tile>
                      <v-list-tile-avatar size="32">
                        <v-icon>mdi-airplane</v-icon>
                      </v-list-tile-avatar>
                      <v-list-tile-content>
                        <v-list-tile-title>{{ $t('operations.flightNumber') }}</v-list-tile-title>
                        <v-list-tile-sub-title>{{ flightPassageBoarding.flightNumber }}</v-list-tile-sub-title>
                      </v-list-tile-content>
                    </v-list-tile>
                    <v-divider />
                  </v-flex>
                  <v-flex xs2>
                    <v-list-tile>
                      <v-list-tile-avatar size="32">
                        <v-icon>mdi-format-size</v-icon>
                      </v-list-tile-avatar>
                      <v-list-tile-content>
                        <v-list-tile-title>{{ $t('common.type') }}</v-list-tile-title>
                        <v-list-tile-sub-title>{{ flightPassageBoarding.aircraftTypeCode }}</v-list-tile-sub-title>
                      </v-list-tile-content>
                    </v-list-tile>
                    <v-divider />
                  </v-flex>
                  <v-flex xs2>
                    <v-list-tile>
                      <v-list-tile-avatar size="32">
                        <v-icon>mdi-clock-outline</v-icon>
                      </v-list-tile-avatar>
                      <v-list-tile-content>
                        <v-list-tile-title>{{ $t('operations.scheduledTimeOfDeparture') }}</v-list-tile-title>
                        <v-list-tile-sub-title>{{ flightPassageBoarding.scheduledTime | moment('HH:mm')
                        }}</v-list-tile-sub-title>
                      </v-list-tile-content>
                    </v-list-tile>
                    <v-divider />
                  </v-flex>
                  <v-flex xs2>
                    <v-list-tile>
                      <v-list-tile-avatar size="32">
                        <v-icon>mdi-parking</v-icon>
                      </v-list-tile-avatar>
                      <v-list-tile-content>
                        <v-list-tile-title>{{ $t('operations.parking') }}</v-list-tile-title>
                        <v-list-tile-sub-title>{{ flightPassageBoarding.parkingCode }}</v-list-tile-sub-title>
                      </v-list-tile-content>
                    </v-list-tile>
                    <v-divider />
                  </v-flex>
                  <v-flex xs2>
                    <v-list-tile>
                      <v-list-tile-avatar size="32">
                        <v-icon>mdi-cog-outline</v-icon>
                      </v-list-tile-avatar>
                      <v-list-tile-content>
                        <v-list-tile-title>{{ $t('common.config') }}</v-list-tile-title>
                        <v-list-tile-sub-title>{{ flightPassageBoarding.aircraftConfigCode }}</v-list-tile-sub-title>
                      </v-list-tile-content>
                    </v-list-tile>
                    <v-divider />
                  </v-flex>
                  <v-flex xs2>
                    <v-list-tile>
                      <v-list-tile-avatar size="32">
                        <v-icon>mdi-clock-check-outline</v-icon>
                      </v-list-tile-avatar>
                      <v-list-tile-content>
                        <v-list-tile-title>{{ $t('flightInformation.estimatedTimeDeparture') }}</v-list-tile-title>
                        <v-list-tile-sub-title>{{ flightPassageBoarding.estimatedTime | moment('HH:mm')
                        }}</v-list-tile-sub-title>
                      </v-list-tile-content>
                    </v-list-tile>
                    <v-divider />
                  </v-flex>
                  <v-flex xs2>
                    <v-list-tile>
                      <v-list-tile-avatar size="32">
                        <v-icon>mdi-airport</v-icon>
                      </v-list-tile-avatar>
                      <v-list-tile-content>
                        <v-list-tile-title>{{ $t('common.to') }}</v-list-tile-title>
                        <v-list-tile-sub-title>{{ flightPassageBoarding.airport1Code }}</v-list-tile-sub-title>
                      </v-list-tile-content>
                    </v-list-tile>
                    <v-divider />
                  </v-flex>
                  <v-flex xs2>
                    <v-list-tile>
                      <v-list-tile-avatar size="32">
                        <v-icon>mdi-alert-circle-outline</v-icon>
                      </v-list-tile-avatar>
                      <v-list-tile-content>
                        <v-list-tile-title>{{ $t('passage.alert') }}</v-list-tile-title>
                        <v-list-tile-sub-title>{{ flightPassageBoarding.boardingRefuelingRequested ? '&#10003;' : ''
                        }}</v-list-tile-sub-title>
                      </v-list-tile-content>
                    </v-list-tile>
                    <v-divider />
                  </v-flex>
                </v-layout>
              </v-flex>

              <v-flex xs12>
                <v-layout>
                  <v-flex xs2>
                    <v-list-tile>
                      <v-list-tile-avatar size="32">
                        <v-icon>mdi-clock-outline</v-icon>
                      </v-list-tile-avatar>
                      <v-list-tile-content>
                        <v-list-tile-title>{{ $t('operations.scheduledBoardingTime') }}</v-list-tile-title>
                        <v-list-tile-sub-title>{{ flightPassageBoarding.scheduledBoardingTime | moment('HH:mm')
                        }}</v-list-tile-sub-title>
                      </v-list-tile-content>
                    </v-list-tile>
                    <v-divider />
                  </v-flex>
                  <v-flex xs2>
                    <v-list-tile>
                      <v-list-tile-avatar size="32">
                        <v-icon>edit</v-icon>
                      </v-list-tile-avatar>
                      <v-list-tile-content>
                        <v-list-tile-title>{{ $t('common.aircraftRegistrationLarge') }}</v-list-tile-title>
                        <v-list-tile-sub-title>{{ flightPassageBoarding.aircraftRegisterNumber }}</v-list-tile-sub-title>
                      </v-list-tile-content>
                    </v-list-tile>
                    <v-divider />
                  </v-flex>
                  <v-flex xs2>
                    <v-list-tile>
                      <v-list-tile-avatar size="32">
                        <v-icon>mdi-desk</v-icon>
                      </v-list-tile-avatar>
                      <v-list-tile-content>
                        <v-list-tile-title>{{ $t('operations.counter') }}</v-list-tile-title>
                        <v-list-tile-sub-title>{{ flightPassageBoarding.checkInCounterFirstCode }}</v-list-tile-sub-title>
                      </v-list-tile-content>
                    </v-list-tile>
                    <v-divider />
                  </v-flex>
                  <v-flex xs2>
                    <v-list-tile>
                      <v-list-tile-avatar size="32">
                        <v-icon>screen_search_desktop</v-icon>
                      </v-list-tile-avatar>
                      <v-list-tile-content>
                        <v-list-tile-title>{{ $t('operations.desk') }}</v-list-tile-title>
                        <v-list-tile-sub-title>{{ flightPassageBoarding.operationDesk }}</v-list-tile-sub-title>
                      </v-list-tile-content>
                    </v-list-tile>
                    <v-divider />
                  </v-flex>
                  <v-flex xs2>
                    <v-list-tile>
                      <v-list-tile-avatar size="32">
                        <v-icon>door_front</v-icon>
                      </v-list-tile-avatar>
                      <v-list-tile-content>
                        <v-list-tile-title>{{ $t('operations.gateExtended') }}</v-list-tile-title>
                        <v-list-tile-sub-title>{{ flightPassageBoarding.gateCode }}</v-list-tile-sub-title>
                      </v-list-tile-content>
                    </v-list-tile>
                    <v-divider />
                  </v-flex>
                  <v-flex xs2>
                    <v-list-tile>
                      <v-list-tile-avatar size="32">
                        <v-icon>mdi-account-group-outline</v-icon>
                      </v-list-tile-avatar>
                      <v-list-tile-content>
                        <v-list-tile-title>{{ $t('operations.prevPax') }}</v-list-tile-title>
                        <v-list-tile-sub-title>{{ flightPassageBoarding.localPaxExpected }}</v-list-tile-sub-title>
                      </v-list-tile-content>
                    </v-list-tile>
                    <v-divider />
                  </v-flex>
                </v-layout>
              </v-flex>

              <v-flex xs12>
                <v-layout class="justify-space-around">
                  <v-flex xs3>
                    <time-input :label="this.$i18n.t('operations.presence')" box class="time-input" ref="presenceInput"
                      v-model="flightPassageBoarding.gateAgentPresence"
                      :disabled="!hasPermissions(rolePermission)"></time-input>
                  </v-flex>
                  <v-flex xs3>
                    <time-input :label="this.$i18n.t('operations.scheduledBoardingEndTime')" box class="time-input"
                      ref="presenceInput" v-model="flightPassageBoarding.completedBoardingTime"
                      :disabled="!hasPermissions(rolePermission)"></time-input>
                  </v-flex>
                  <v-flex xs3>
                    <time-input :label="this.$i18n.t('passage.pmrsWarning')" box class="time-input"
                      v-model="flightPassageBoarding.pmrsWarning"
                      :disabled="!hasPermissions(rolePermission)"></time-input>
                  </v-flex>
                  <v-flex xs3>
                    <time-input :label="this.$i18n.t('passage.pmrsArrival')" box class="time-input"
                      v-model="flightPassageBoarding.pmrsArrival"
                      :disabled="!hasPermissions(rolePermission)"></time-input>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12>
                <v-layout class="justify-space-around">
                  <v-flex xs2>
                    <time-input :label="this.$i18n.t('passage.notificationTime')" box class="time-input"
                      v-model="flightPassageBoarding.missingPaxBagRequestedTime"
                      :disabled="!hasPermissions(rolePermission)"></time-input>
                  </v-flex>

                  <v-flex xs2>
                    <v-text-field :label="this.$i18n.t('passage.missingPaxNumber')" type="number" box
                      v-model="flightPassageBoarding.missingPaxNumber" :disabled="!hasPermissions(rolePermission)"
                      class="shrink"></v-text-field>
                  </v-flex>

                  <v-flex xs2>
                    <v-text-field :label="this.$i18n.t('passage.missingPaxInfo')" box
                      v-model="flightPassageBoarding.missingPaxInfo"
                      :disabled="!hasPermissions(rolePermission)"></v-text-field>
                  </v-flex>
                  <v-flex xs2 v-if="!flightPassageBoarding.searchStartTime"/>
                  <v-flex xs2 v-if="flightPassageBoarding.searchStartTime">
                    <v-list-tile>
                      <v-list-tile-avatar>
                        <v-icon>mdi-magnify</v-icon>
                      </v-list-tile-avatar>
                      <v-list-tile-content>
                        <v-list-tile-title>{{ $t('passage.searchStartTime') }}</v-list-tile-title>
                        <v-list-tile-sub-title>{{ formatTime(flightPassageBoarding.searchStartTime)
                        }}</v-list-tile-sub-title>
                      </v-list-tile-content>
                    </v-list-tile>
                    <v-divider />
                  </v-flex>
                  <v-flex xs2 v-if="!flightPassageBoarding.searchEndTime"/>
                  <v-flex xs2 v-if="flightPassageBoarding.searchEndTime">
                    <v-list-tile>
                      <v-list-tile-avatar>
                        <v-icon>mdi-magnify-close</v-icon>
                      </v-list-tile-avatar>
                      <v-list-tile-content>
                        <v-list-tile-title>{{ $t('passage.SearchEnd') }}</v-list-tile-title>
                        <v-list-tile-sub-title>{{ formatTime(flightPassageBoarding.searchEndTime) }} {{ getSearchTimeLapse
                        }}</v-list-tile-sub-title>
                      </v-list-tile-content>
                    </v-list-tile>
                    <v-divider />
                  </v-flex>
                  <v-flex xs2 v-if="!flightPassageBoarding.endingSearchReason"/>
                  <v-flex xs2 v-if="flightPassageBoarding.endingSearchReason">
                    <v-list-tile>
                      <v-list-tile-avatar>
                        <v-icon>{{ getSearchOptionIcon(flightPassageBoarding.endingSearchReason) }}</v-icon>
                      </v-list-tile-avatar>
                      <v-list-tile-content>
                        <v-list-tile-title>{{ $t('passage.endingSearchReason') }}</v-list-tile-title>
                        <v-list-tile-sub-title>{{ getSearchOptionText(flightPassageBoarding.endingSearchReason)
                        }}</v-list-tile-sub-title>
                      </v-list-tile-content>
                    </v-list-tile>
                    <v-divider />
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
            <passage-information-data :passageInformation="flightPassageBoarding"
              type="totalCheckInPax"></passage-information-data>
            <passage-information-data :passageInformation="flightPassageBoarding" type="totalPaxOnBoard"
              :disabled="false"></passage-information-data>
            <v-card>
              <v-card-title>
                <v-icon class="pr-2">6_ft_apart</v-icon>
                <div class="font-weight-bold">
                  {{ $t('operations.cabinDistributionLabel') }}
                </div>
              </v-card-title>
              <v-divider />
              <v-layout row wrap>
                <v-flex xs12>
                  <v-layout>
                    <v-flex xs2></v-flex>
                    <v-flex xs1>
                      <v-text-field v-model="flightPassageBoarding.lastMinuteChangesPax" label="LMC" type="number" box
                        readonly />
                    </v-flex>
                    <v-flex xs1>
                      <v-text-field v-model="flightPassageBoarding.lastMinuteChangesInf" label="LMC Inf" type="number" box
                        readonly />
                    </v-flex>
                    <v-flex xs1>
                      <v-text-field v-model="flightPassageBoarding.lastMinuteChangesBag" label="LMC Bags" type="number"
                        box readonly />
                    </v-flex>
                    <v-flex xs1></v-flex>
                    <v-flex xs1>
                      <v-text-field label="0A" v-model="flightPassageBoarding.cabin0A" type="number"
                        :rules="[rules.max500]" box :readonly="!hasPermissions(rolePermission)" />
                    </v-flex>
                    <v-flex xs1>
                      <v-text-field label="0B" v-model="flightPassageBoarding.cabin0B" type="number"
                        :rules="[rules.max500]" box :readonly="!hasPermissions(rolePermission)" />
                    </v-flex>
                    <v-flex xs1>
                      <v-text-field label="0C" v-model="flightPassageBoarding.cabin0C" type="number"
                        :rules="[rules.max500]" box :readonly="!hasPermissions(rolePermission)" />
                    </v-flex>
                    <v-flex xs1>
                      <v-text-field label="0D" v-model="flightPassageBoarding.cabin0D" type="number"
                        :rules="[rules.max500]" box :readonly="!hasPermissions(rolePermission)" />
                    </v-flex>
                    <v-flex xs1>
                      <v-text-field label="0E" v-model="flightPassageBoarding.cabin0E" type="number"
                        :rules="[rules.max500]" box :readonly="!hasPermissions(rolePermission)" />
                    </v-flex>
                    <v-flex xs1>
                      <v-text-field label="0F" v-model="flightPassageBoarding.cabin0F" type="number"
                        :rules="[rules.max500]" box :readonly="!hasPermissions(rolePermission)" />
                    </v-flex>
                    <v-flex xs1>
                      <v-text-field label="Conmail" v-model="flightPassageBoarding.comail" type="number"
                        :rules="[rules.max500]" box :readonly="!hasPermissions(rolePermission)" />
                    </v-flex>
                    <v-flex xs2>
                      <v-text-field v-model="flightPassageBoarding.jumpseats" label="JMP" type="number"
                        :rules="[rules.max100]" box :readonly="!hasPermissions(rolePermission)" />
                    </v-flex>
                    <v-flex xs1></v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-card>
            <v-card>
              <v-card-title>
                <v-icon class="pr-2">comment</v-icon>
                <div class="font-weight-bold">
                  {{ $t('operations.remarksExtended') }}
                </div>
              </v-card-title>
              <v-divider />
              <v-card-text>
                <v-layout row wrap>
                  <v-flex xs12>
                    <v-layout>
                      <v-flex xs12>
                        <v-text-field v-model="flightPassageBoarding.paxItemsRemarks" :label="this.$i18n.t('passage.itemsRemarks')"
                          box :disabled="!hasPermissions(rolePermission)" />
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-card-text>
            </v-card>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" flat @click.native="exitPassageBoarding">Exit</v-btn>
          <v-btn v-show="hasPermissions(rolePermission)" color="primary" depressed
            @click.native="savePassageBoarding">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-container>
</template>
<script>
import PassageInformationData from './PassageInformationData.vue';
import DateInput from '../ui/DateInput.vue';
import TimeInput from '../ui/TimeInput.vue';
import AirportParkingSelect from '../airport/AirportParkingSelect.vue';
import AircraftTypeSelect from '../aircraft/AircraftTypeSelect.vue';
import AircraftConfigTypeahead from '../aircraft/AircraftConfigTypeahead.vue';
import AirportSelect from '../airport/AirportSelect.vue';
import AirportGateSelect from '../airport/AirportGateSelect.vue';
import { PASSAGE_BOARDING_FLIGHT_UPDATE } from '../../store/modules/passageBoarding';
import RolePermissions from '../mixins/RolePermissions.vue';
import './FlightPassageBoardingForm.scss';

export default {
  mixins: [RolePermissions],
  components: {
    DateInput,
    TimeInput,
    AircraftTypeSelect,
    AirportParkingSelect,
    AircraftConfigTypeahead,
    AirportSelect,
    AirportGateSelect,
    PassageInformationData,
  },
  props: {
    item: { required: true },
  },
  computed: {
    flightPassageBoarding() {
      return this.item;
    },
    rolePermission() {
      return `${this.$route.meta.role}_WR`;
    },
    searchOptions() {
      return [
        {
          text: this.$t('dispatcher.searchFound'),
          value: 'SEARCH_FOUND',
          icon: 'luggage',
        },
        {
          text: this.$t('dispatcher.searchNotFound'),
          value: 'SEARCH_NOT_FOUND',
          icon: 'no_luggage',
        },
        {
          text: this.$t('dispatcher.searchCancelled'),
          value: 'SEARCH_CANCELLED',
          icon: 'mdi-close-circle',
        },
      ];
    },
    getSearchTimeLapse() {
      // search time in hours, minutes
      if (this.flightPassageBoarding.searchStartTime && this.flightPassageBoarding.searchEndTime) {
        const startTime = this.$moment(this.flightPassageBoarding.searchStartTime);
        const endTime = this.$moment(this.flightPassageBoarding.searchEndTime);
        const duration = this.$moment.duration(endTime.diff(startTime));
        if (duration.asHours() < 1) {
          return ` (${duration.minutes()}min ${duration.seconds()}s)`;
        }
        return ` (${duration.hours()}h ${duration.minutes()}min ${duration.seconds()}s)`;
      }
      return '';
    },
  },
  data() {
    return {
      rules: {
        max100: value => value <= 100 || this.$i18n.t('validation.fieldMax100'),
        max500: value => value <= 500 || this.$i18n.t('validation.fieldMax500'),
      },
    };
  },
  methods: {
    getSearchOptionIcon(value) {
      const foundOption = this.searchOptions.find(option => option.value === value);
      return foundOption ? foundOption.icon : '';
    },
    getSearchOptionText(value) {
      const foundOption = this.searchOptions.find(option => option.value === value);
      return foundOption ? foundOption.text : '';
    },
    shortcutsKey($event) {
      if ($event.ctrlKey) {
        switch ($event.key) {
          case '1':
            this.$refs.jarPInput.$el.focus();
            break;
          case '2': this.$refs.presenceInput.$el.focus();
            break;
          case '3': this.$refs.megafOkInput.$el.focus();
            break;
          case '4': this.$refs.missingPaxInput.$el.focus();
            break;
          case '5': this.$refs.acisoPmrsInput.$el.focus();
            break;
          case '6': this.$refs.paxInput.$el.focus();
            break;
          case '7': this.$refs.commentsInput.$el.focus();
            break;
          case 's': this.savePassageBoarding();
            break;
          default:
            break;
        }
      }
    },
    formatTime(dateTime) {
      return dateTime ? this.$moment(dateTime).format('HH:mm') : '';
    },

    savePassageBoarding() {
      this.$store.dispatch(PASSAGE_BOARDING_FLIGHT_UPDATE, this.flightPassageBoarding);
      // this.exitPassageBoarding();
    },
    exitPassageBoarding() {
      this.$router.push({ name: 'passageOutbounds' });
    },
  },
  created() {
    window.addEventListener('keydown.native', this.shortcutsKey);
  },
};
</script>
