<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <entity-configuration-list
      :entity-name="this.$i18n.t('configuration.aicDocument')"
      entity-icon="library_books"
      :framework-components="frameworkComponents" :column-defs="columnDefs" :items="aicDocuments"
      @confirm-edit-item="editConfirm"
      @confirm-delete-item="deleteConfirm"
    >
      <template v-slot:modal-content="{ itemEdit }">
        <aic-document-edit-card-content :item="itemEdit" />
      </template>
    </entity-configuration-list>
  </div>
</template>
<script>

import EntityConfigurationList from '../common/EntityConfigurationList.vue';
import AgGridIconValueOrDashCellRenderer from '../../ag-grid/cell-renderers/AgGridIconValueOrDashCellRenderer.vue';
import {
  AIC_DOCUMENTS_CREATE,
  AIC_DOCUMENTS_DELETE,
  AIC_DOCUMENTS_UPDATE,
  GET_AIC_DOCUMENTS,
} from '../../../store/modules/aicDocuments/aicDocumentsCatalog';
import AicDocumentEditCardContent from './AICDocumentsEditCardContent.vue';
import AgGridLinkOpenerButtonCellRenderer from '../../ag-grid/cell-renderers/AgGridLinkOpenerButtonCellRenderer.vue';


export default {
  name: 'AICDocumentstList',
  components: {
    EntityConfigurationList,
    AicDocumentEditCardContent,
    AgGridLinkOpenerButtonCellRenderer,
  },
  data() {
    const that = this;
    return {
      frameworkComponents: {
        AgGridIconValueOrDashCellRenderer,
      },
      columnDefs: [
        {
          field: 'airlineCode',
          headerValueGetter() {
            return that.$i18n.t('configuration.airlineCode');
          },
        },
        {
          field: 'airlineName',
          headerValueGetter() {
            return that.$i18n.t('configuration.airlineName');
          },
        },
        {
          field: 'link',
          headerValueGetter() {
            return that.$i18n.t('configuration.documentLink');
          },
        },
        {
          field: 'link',
          headerValueGetter() {
            return that.$i18n.t('configuration.documentOpen');
          },
          suppressMenu: true,
          sortable: false,
          suppressMovable: true,
          suppressSizeToFit: true,
          filter: false,
          resizable: false,
          suppressNavigable: true,
          colCustomConfig: {
            exportCsvDisabled: true,
          },
          cellRendererFramework: AgGridLinkOpenerButtonCellRenderer,
        },
      ],
    };
  },
  computed: {
    aicDocuments() {
      return this.$store.getters.getAICDocuments;
    },
  },
  methods: {
    getAICDocuments() {
      this.$store.dispatch(GET_AIC_DOCUMENTS);
    },
    deleteConfirm(item) {
      this.$store.dispatch(AIC_DOCUMENTS_DELETE, item.id);
    },
    editConfirm(item) {
      this.$store.dispatch(item.id ? AIC_DOCUMENTS_UPDATE : AIC_DOCUMENTS_CREATE, item);
    },
  },
  created() {
    this.getAICDocuments();
  },
};
</script>
