import moment from 'moment';
import SchedulingImportOptions from './schedulingImportOptions';
import { FlightBound } from '../../shared/model/flightBound';
import { JSUtils as TimeUtils } from '../../shared/js/TimeUtils';

export default class SchedulingImportParsedFile {
  constructor(headers, items, minDate, maxDate, airlines, allItems, duplicateFlights, possibleSameFlights, connectedFlightsStdBeforeSta, tripNumberWithErrors, flightsWithScheduledTimeEmpty) {
    this.headers = headers;
    this.items = items;
    this.minDate = minDate;
    this.maxDate = maxDate;
    this.airlines = airlines || [];
    this.allItems = allItems;
    this.duplicateFlights = duplicateFlights || [];
    this.possibleSameFlights = possibleSameFlights || [];
    this.connectedFlightsStdBeforeSta = connectedFlightsStdBeforeSta || [];
    this.tripNumberWithErrors = tripNumberWithErrors || [];
    this.flightsWithScheduledTimeEmpty = flightsWithScheduledTimeEmpty || [];
  }

  static createFromFile(file, importOptions) {
    if (!importOptions || !(importOptions instanceof SchedulingImportOptions)) {
      throw new Error('Parameter importOptions is not valid SchedulingImportOptions instance');
    }
    const fileLines = file.split('\n');
    const items = [];
    const headers = fileLines[0].split(importOptions.delimitierCharacter);

    let minDate = null;
    let maxDate = null;
    const airlines = [];
    const flights = new Map();
    const duplicatedFlights = [];
    const possibleSameFlights = [];
    let flightKeyIn = null;
    let flightKeyOut = null;
    const allItems = [];
    const connectedFlightsStdBeforeSta = [];
    const tripNumberWithErrors = [];
    const flightsWithScheduledTimeEmpty = [];

    for (let i = 1; i < fileLines.length; i += 1) {
      const obj = {};
      const currentLine = fileLines[i].split(importOptions.delimitierCharacter);

      headers.forEach((header, indexHeader) => {
        obj[header] = currentLine[indexHeader];
      });

      if (i <= importOptions.linesToPreview) {
        items.push(obj);
      }

      allItems.push(obj);

      if (obj.DATE_IN && obj.DATE_IN !== '' && !obj.DATE_IN.includes('**') && obj.DATE_OUT && obj.DATE_OUT !== '' && !obj.DATE_OUT.includes('**') && obj.STA && obj.STA !== '' && !obj.STA.includes('**') && obj.STD && obj.STD !== '' && !obj.STD.includes('**')) {
        const flightDateInbound = moment(obj.DATE_IN, this.getDateFormat(obj.DATE_IN)).hour(moment(TimeUtils.formattedTimeCsv(obj.STA), 'HH:mm').hour()).minute(moment(TimeUtils.formattedTimeCsv(obj.STA), 'HH:mm').minutes());
        const flightDateOutbound = moment(obj.DATE_OUT, this.getDateFormat(obj.DATE_OUT)).hour(moment(TimeUtils.formattedTimeCsv(obj.STD), 'HH:mm').hour()).minute(moment(TimeUtils.formattedTimeCsv(obj.STD), 'HH:mm').minutes());
        if (flightDateInbound.isAfter(flightDateOutbound)) {
          connectedFlightsStdBeforeSta.push(obj);
        }
      }

      if (obj.DATE_IN) {
        const lineDate = moment(obj.DATE_IN, this.getDateFormat(obj.DATE_IN));
        if (minDate == null || lineDate.isBefore(minDate)) {
          minDate = lineDate;
        }
        if (maxDate == null || lineDate.isAfter(maxDate)) {
          maxDate = lineDate;
        }
      }
      if (obj.DATE_OUT) {
        const lineDate = moment(obj.DATE_OUT, this.getDateFormat(obj.DATE_OUT));
        if (minDate == null || lineDate.isBefore(minDate)) {
          minDate = lineDate;
        }
        if (maxDate == null || lineDate.isAfter(maxDate)) {
          maxDate = lineDate;
        }
      }
      if (obj.SCHEDULE_START) {
        const lineDate = moment(obj.SCHEDULE_START, this.getDateFormat(obj.SCHEDULE_START));
        if (minDate == null || lineDate.isBefore(minDate)) {
          minDate = lineDate;
        }
      }

      if (obj.SCHEDULE_END) {
        const lineDate = moment(obj.SCHEDULE_END, this.getDateFormat(obj.SCHEDULE_END));
        if (maxDate == null || lineDate.isAfter(maxDate)) {
          maxDate = lineDate;
        }
      }
      if (obj.AIRLINE_IN && obj.AIRLINE_IN !== '' && !obj.AIRLINE_IN.includes('**')) {
        if (!airlines.includes(obj.AIRLINE_IN)) {
          airlines.push(obj.AIRLINE_IN);
        }
      }
      if (obj.AIRLINE_OUT && obj.AIRLINE_OUT !== '' && !obj.AIRLINE_OUT.includes('**')) {
        if (!airlines.includes(obj.AIRLINE_OUT)) {
          airlines.push(obj.AIRLINE_OUT);
        }
      }
      if (obj.AIRLINE && obj.AIRLINE !== '' && !obj.AIRLINE.includes('**')) {
        if (!airlines.includes(obj.AIRLINE)) {
          airlines.push(obj.AIRLINE);
        }
      }
      flightKeyIn = null;
      flightKeyOut = null;
      if (obj.DATE_IN != null
        && obj.AIRLINE_IN && obj.AIRLINE_IN !== '' && !obj.AIRLINE_IN.includes('**')
        && obj.TRIP_IN && obj.TRIP_IN !== '' && !obj.TRIP_IN.includes('**')
        && obj.FROM_01 && obj.FROM_01 !== '' && !obj.FROM_01.includes('**')) {
        flightKeyIn = {
          date: obj.DATE_IN,
          airline: obj.AIRLINE_IN,
          tripNumber: obj.TRIP_IN,
          airport: obj.FROM_01,
          bound: FlightBound.INBOUND,
          scheduledTime: obj.STA,
        };
      }
      if (obj.DATE_OUT != null
        && obj.AIRLINE_OUT && obj.AIRLINE_OUT !== '' && !obj.AIRLINE_OUT.includes('**')
        && obj.TRIP_OUT && obj.TRIP_OUT !== '' && !obj.TRIP_OUT.includes('**')
        && obj.TO_01 && obj.TO_01 !== '' && !obj.TO_01.includes('**')) {
        flightKeyOut = {
          date: obj.DATE_OUT,
          airline: obj.AIRLINE_OUT,
          tripNumber: obj.TRIP_OUT,
          airport: obj.TO_01,
          bound: FlightBound.OUTBOUND,
          scheduledTime: obj.STD,
        };
      }

      if (flightKeyIn != null && (!obj.STA || obj.STA === '') && !this.existFlightsWithScheduledTimeEmpty(flightsWithScheduledTimeEmpty, flightKeyIn)) {
        flightsWithScheduledTimeEmpty.push(flightKeyIn);
      } else if (flightKeyOut != null && (!obj.STD || obj.STD === '') && !this.existFlightsWithScheduledTimeEmpty(flightsWithScheduledTimeEmpty, flightKeyOut)) {
        flightsWithScheduledTimeEmpty.push(flightKeyOut);
      } else if (flightKeyIn != null && this.existDuplicatedFlight(Array.from(flights.keys()), flightKeyIn) && !this.existDuplicatedFlight(duplicatedFlights, flightKeyIn)) {
        duplicatedFlights.push(flightKeyIn);
      } else if (flightKeyOut != null && this.existDuplicatedFlight(Array.from(flights.keys()), flightKeyOut) && !this.existDuplicatedFlight(duplicatedFlights, flightKeyOut)) {
        duplicatedFlights.push(flightKeyOut);
      } else if (flightKeyIn != null && this.existPossibleSameFlight(Array.from(flights.keys()), flightKeyIn) && !this.existPossibleSameFlight(possibleSameFlights, flightKeyIn)) {
        possibleSameFlights.push(flightKeyIn);
      } else if (flightKeyOut != null && this.existPossibleSameFlight(Array.from(flights.keys()), flightKeyOut) && !this.existPossibleSameFlight(possibleSameFlights, flightKeyOut)) {
        possibleSameFlights.push(flightKeyOut);
      } else {
        if (flightKeyIn != null) {
          flights.set(flightKeyIn, [obj]);
        }
        if (flightKeyOut != null) {
          flights.set(flightKeyOut, [obj]);
        }
      }

      const pattern = new RegExp(/^[A-Za-z0-9\s]{1,5}$/);
      if (obj.TRIP_IN && obj.TRIP_IN !== '' && !obj.TRIP_IN.startsWith('***')) {
        if (!pattern.test(obj.TRIP_IN) && !tripNumberWithErrors.includes(obj.TRIP_IN)) {
          tripNumberWithErrors.push(obj.TRIP_IN);
        }
      }
      if (obj.TRIP_OUT && obj.TRIP_OUT !== '' && !obj.TRIP_OUT.startsWith('***')) {
        if (!pattern.test(obj.TRIP_OUT) && !tripNumberWithErrors.includes(obj.TRIP_OUT)) {
          tripNumberWithErrors.push(obj.TRIP_OUT);
        }
      }
    }

    const airlinesSortedAndFiltered = airlines
      .map(item => item.trim())
      .filter((item, index) => airlines.indexOf(item) === index)
      .sort();

    return new SchedulingImportParsedFile(headers, items, minDate, maxDate, airlinesSortedAndFiltered, allItems, duplicatedFlights, possibleSameFlights, connectedFlightsStdBeforeSta, tripNumberWithErrors, flightsWithScheduledTimeEmpty);
  }

  static getDateFormat(sourceDate) {
    if (sourceDate && sourceDate.length === 10) {
      return 'DD/MM/YYYY';
    }
    if (sourceDate && sourceDate.length === 8) {
      return 'DD/MM/YY';
    }
    return null;
  }

  static existDuplicatedFlight(flights, flightKey) {
    return flights.filter(this.filterDuplicateFlights(flightKey)).length !== 0;
  }

  static existPossibleSameFlight(flights, flightKey) {
    return flights.filter(this.filterPossibleSameFlights(flightKey)).length !== 0;
  }

  static existFlightsWithScheduledTimeEmpty(flights, flightKey) {
    return flights.filter(this.filterPossibleSameFlights(flightKey)).length !== 0;
  }

  static existFlightsWithSameUniqueKey(flights, flightKey) {
    return flights.filter(this.filterSameFlights(flightKey)).length !== 0;
  }

  static filterDuplicateFlights(flightKey) {
    return key => key.airline === flightKey.airline && key.date === flightKey.date && key.airport === flightKey.airport && key.tripNumber === flightKey.tripNumber && key.bound === flightKey.bound && flightKey.scheduledTime != null && key.scheduledTime != null && flightKey.scheduledTime === key.scheduledTime;
  }

  static filterPossibleSameFlights(flightKey) {
    return key => key.airline === flightKey.airline && key.date === flightKey.date && key.airport === flightKey.airport && key.tripNumber === flightKey.tripNumber && key.bound === flightKey.bound;
  }

  static filterSameFlights(flightKey) {
    return key => key.airline === flightKey.airline && key.date === flightKey.date && key.airport === flightKey.airport && key.tripNumber === flightKey.tripNumber && key.bound === flightKey.bound;
  }
}
