/* eslint no-param-reassign: "off" */

import { HTTP } from '../../http';
import { REQUEST_ERROR, REQUEST_START, REQUEST_SUCCESS } from '../store';
import airportParkingCatalog from './airportParking/airportParkingCatalog';
import airportGateCatalog from './airportGate/airportGateCatalog';
import airportCheckInCounterCatalog from './airportCheckInCounter/airportCheckInCounterCatalog';
import aircraftCatalog from './aircraft/aircraftCatalog';
import aircraftTypesCatalog from './aircraftTypes/aircraftTypesCatalog';
import airportCatalog from './airport/airportCatalog';
import airportBeltCatalog from './airportBelt/airportBeltCatalog';
import airportCarouselCatalog from './airportCarousel/airportCarouselCatalog';
import airlineCatalog from './airline/airlineCatalog';
import delayTypesCatalog from './delayTypes/delayTypesCatalog';
import delayReasonsCatalog from './delayReasons/delayReasonsCatalog';
import flightWeightingCatalog from './flightWeighting/flightWeightingCatalog';
import rampServicesCatalog from './rampServices/rampServicesCatalog';
import airportRampServicesCatalog from './rampServices/airportRampServicesCatalog';
import airportRequesterCatalog from './airportRequester/airportRequesterCatalog';
import airportSLABaggageDeliveryCatalog from './airportSLABaggageDelivery/airportSLABaggageDeliveryCatalog';

// actions names
export const GET_AIRCRAFT_CONFIGS = 'GET_AIRCRAFT_CONFIGS';
export const FORCE_GET_AIRCRAFT_CONFIGS = 'FORCE_GET_AIRCRAFT_CONFIGS';
export const GET_MESSAGE_TYPES = 'GET_MESSAGE_TYPES';

// commits names
export const GET_AIRCRAFT_CONFIG_SUCCESS = 'GET_AIRCRAFT_CONFIG_SUCCESS';
export const GET_AIRCRAFT_CONFIG_REQUESTED = 'GET_AIRCRAFT_CONFIG_REQUESTED';
export const GET_MESSAGE_TYPES_SUCCESS = 'GET_MESSAGE_TYPES_SUCCESS';
export const GET_MESSAGE_TYPES_REQUESTED = 'GET_MESSAGE_TYPES_REQUESTED';

// modules
const storeModules = {
  airportParkingCatalog,
  airportGateCatalog,
  airportCheckInCounterCatalog,
  aircraftCatalog,
  aircraftTypesCatalog,
  airportCatalog,
  airportBeltCatalog,
  airportCarouselCatalog,
  airlineCatalog,
  delayTypesCatalog,
  delayReasonsCatalog,
  flightWeightingCatalog,
  rampServicesCatalog,
  airportRampServicesCatalog,
  airportRequesterCatalog,
  airportSLABaggageDeliveryCatalog,
};

// initial state
const storeState = {
  aircraftConfigs: [],
  aircraftConfigsRequested: false,
  messageTypes: [],
  messageTypesRequested: false,
  responsibleType: [{ value: 'HANDLING' }, { value: 'STATION' }],
  responsibleDepartment: [{ value: 'OPERATIONS' }, { value: 'PASSAGE' }, { value: 'RAMP' }, { value: 'GLOBAL' }],

};

// getters
const storeGetters = {
  getAircraftConfigById: state => id => state.aircraftConfigs.find(a => a.id === id),
  getMessageById: state => id => state.messageTypes.find(a => a.id === id),
  getResponsibleType: state => state.responsibleType,
  getResponsibleDepartment: state => state.responsibleDepartment,
};

// actions
const storeActions = {
  [GET_AIRCRAFT_CONFIGS]: ({ dispatch, state }) => {
    if (state.aircraftConfigsRequested) {
      return;
    }
    dispatch(FORCE_GET_AIRCRAFT_CONFIGS);
  },
  [FORCE_GET_AIRCRAFT_CONFIGS]: ({ commit }) => {
    commit(REQUEST_START);
    commit(GET_AIRCRAFT_CONFIG_REQUESTED);
    HTTP.get('aircraft-configs')
      .then((resp) => {
        commit(REQUEST_SUCCESS);
        commit(GET_AIRCRAFT_CONFIG_SUCCESS, resp.data);
      })
      .catch((err) => {
        commit(REQUEST_ERROR, err);
      });
  },
  [GET_MESSAGE_TYPES]: ({ commit, state }) => {
    if (state.messageTypesRequested) {
      return;
    }
    commit(REQUEST_START);
    commit(GET_MESSAGE_TYPES_REQUESTED);
    HTTP.get('message-types')
      .then((resp) => {
        commit(REQUEST_SUCCESS);
        commit(GET_MESSAGE_TYPES_SUCCESS, resp.data);
      })
      .catch((err) => {
        commit(REQUEST_ERROR, err);
      });
  },
};

// mutations
const storeMutations = {
  [GET_AIRCRAFT_CONFIG_SUCCESS]: (state, aircraftCofigs) => {
    state.aircraftConfigs = aircraftCofigs;
  },
  [GET_AIRCRAFT_CONFIG_REQUESTED]: (state) => {
    state.aircraftConfigsRequested = true;
  },
  [GET_MESSAGE_TYPES_SUCCESS]: (state, messageTypes) => {
    state.messageTypes = messageTypes;
  },
  [GET_MESSAGE_TYPES_REQUESTED]: (state) => {
    state.messageTypesRequested = true;
  },
};

export default {
  modules: storeModules,
  state: storeState,
  getters: storeGetters,
  actions: storeActions,
  mutations: storeMutations,
};
