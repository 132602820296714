import moment from 'moment';
import FlightComposition from './flightComposition';
import ConnectedFlights from './connectedFlights';
import FlightWeighting from './flightWeighting';
import FlightCommonInformation from '../../scheduling/model/flightCommonInformation';
import FlightExtendedInformation from '../../scheduling/model/flightExtendedInformation';
import FlightTimesInformation from '../../scheduling/model/flightTimesInformation';
import FlightOperationInformation from '../../operations/model/flightOperationInformation';
import FlightOperationExtendedInformation from '../../operations/model/flightOperationExtendedInformation';
import FlightPassageSummaryInformation from '../../passage/model/flightPassageSummaryInformation';
import FlightPassageCheckInInformation from '../../passage/model/flightPassageCheckInInformation';
import FlightRampInformation from '../../ramp/model/flightRampInformation';
import FlightRampExtendedInformation from '../../ramp/model/flightRampExtendedInformation';
import FlightServicesInformation from '../../ramp/model/flightServicesInformation';
import FlightWaterToiletInformation from '../../ramp/watertoilet/model/flightWaterToiletInformation';
import FlightPassageOutboundInformation from '../../passage/model/flightPassageOutboundInformation';
import FlightPassageBoardingInformation from '../../passage/model/flightPassageBoardingInformation';
import FlightBillingInformation from '../../billing/model/flightBillingInformation';
import FlightDelayInformation from '../../delay/model/flightDelayInformation';
import FlightIntegrationInformation from '../../operations/model/flightIntegrationInformation';
import FlightPassageDistributionInformation from '../../operations/model/flightPassageDistributionInformation';
import FlightLoadHoldingDistributionInformation from '../../operations/model/flightLoadHoldingDistributionInformation';
import FlightRemarksInformation from '../../operations/model/flightRemarksInformation';
import FlightTowingAdhocTaskServicesInformation from '../../towingAdhocTask/flights/model/flightTowingAdhocTaskServicesInformation';
import FlightSLABaggageDeliveryInformation from '../../ramp/model/flightSLABaggageDeliveryInformation';
import FlightBlockedFieldsIntegrationInformation from './connectedFlight/flightBlockedFieldsIntegrationInformation';

export default class ConnectedFlightsFactory {
  static createConnectedFlightsFromData(data) {
    if (data && data.constructor === Array && data.length > 0) {
      return data.map(d => this.mapConnectedFlights(d)).sort((item1, item2) => moment(item1.sortingTime) - moment(item2.sortingTime));
    }
    return [];
  }

  static mapConnectedFlights(d) {
    return new ConnectedFlights(
      d.inbound ? this.createFlightCompositionFromData(d.inbound) : null,
      d.outbound ? this.createFlightCompositionFromData(d.outbound) : null,
      d.weighting ? FlightWeighting.createFromData(d.weighting) : null,
      d.sortingTime ? d.sortingTime : null,
    );
  }

  static createFlightCompositionFromData(json) {
    if (!json) {
      throw Error('No data from flights API');
    }

    return new FlightComposition(
      FlightCommonInformation.createFlightCommonInformationFromData(json.common),
      FlightExtendedInformation.createFlightExtendedInformationFromData(json.extended),
      FlightTimesInformation.createFlightTimesInformationFromData(json.times),
      FlightOperationInformation.createFlightOperationInformationFromData(json.operation),
      FlightOperationExtendedInformation.createFlightOperationExtendedInformationFromData(json.operationExtended),
      FlightPassageSummaryInformation.createFlightPassageSummaryInformationFromData(json.passageSummary),
      FlightPassageCheckInInformation.createFlightPassageCheckInInformationFromData(json.passageCheckIn),
      FlightRampInformation.createFlightRampInformationFromData(json.ramp),
      FlightRampExtendedInformation.createFlightRampExtendedInformationFromData(json.rampExtended),
      FlightServicesInformation.createFlightServicesInformationFromData(json.services),
      FlightWaterToiletInformation.createFlightWaterToiletInformationFromData(json.waterToilet),
      FlightPassageOutboundInformation.createFlightPassageOutboundInformationFromData(json.passageOutbound),
      FlightBillingInformation.createFlightBillingInformationFromData(json.billing),
      FlightDelayInformation.createFlightDelayInformationFromData(json.delays),
      FlightIntegrationInformation.createFlightIntegrationInformationFromData(json.integration),
      FlightPassageDistributionInformation.createFlightPassageDistributionInformationFromData(json.passageDistribution),
      FlightPassageDistributionInformation.createFlightPassageDistributionInformationFromData(json.passageTransitsDistribution),
      FlightLoadHoldingDistributionInformation.createFlightLoadHoldingDistributionInformationFromData(json.loadHoldingDistribution),
      FlightRemarksInformation.createFlightRemarksInformationFromData(json.remarks),
      FlightPassageBoardingInformation.createFlightPassageBoardingInformationFromData(json.passageBoarding),
      FlightTowingAdhocTaskServicesInformation.createFlightTowingAdhocTaskServicesInformationFromData(json.towingAdhocTaskServices),
      FlightSLABaggageDeliveryInformation.createFlightSLABaggageDeliveryInformationFromData(json.slaBaggageDelivery),
      FlightBlockedFieldsIntegrationInformation.createFlightBlockedFieldsIntegrationInformationFromData(json.blockedFieldsIntegration),
    );
  }
}
