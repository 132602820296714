<script>
import Vue from 'vue';
import moment from 'moment-timezone';
import { HTTP } from '../../http';
import { TIME_CHANGE_TIMEZONE } from '../../store/modules/time';

export default Vue.extend({
  computed: {
    localTimezone() {
      return this.$store.getters.isTenantSetted ? this.$store.state.tenant.currentTenant.timezone : '';
    },
    globalTimezone() {
      return this.$store.state.time.timezone;
    },
  },
  methods: {
    setGlobalTimezone(timezone) {
      if (timezone) {
        this.$moment.tz.setDefault(timezone);
        moment.tz.setDefault(timezone);
        HTTP.defaults.headers.common['FIS-TIMEZONE'] = timezone;
        this.$store.dispatch(TIME_CHANGE_TIMEZONE, timezone);
      }
    },
  },
});
</script>
