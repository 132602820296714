<template>
  <v-tooltip left v-if="hasPermissions(rolePermission)">
    <v-btn
      slot="activator"
      fixed bottom right
      color="primary"
      dark
      fab
      @click="clickNew">
      <v-icon>{{iconBtnNew}}</v-icon>
    </v-btn>
    <span>{{$t("configuration.new")}}</span>
  </v-tooltip>
</template>
<script>
import RolePermissions from '../../mixins/RolePermissions.vue';

export default {
  name: 'entity-configuration-list-options',
  mixins: [RolePermissions],
  props: {
    iconNew: {
      type: String,
    },
  },
  data: () => ({
    fabSingle: false,
    fabSelection: false,
  }),
  computed: {
    iconBtnNew() {
      return this.iconNew ? this.iconNew : 'add';
    },
    rolePermission() {
      return `${this.$route.meta.role}_WR`;
    },
  },
  methods: {
    clickNew() {
      this.$emit('click-new-item');
    },
  },
};
</script>
