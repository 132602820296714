<script>
import Vue from 'vue';
import { TIME_TIMEZONE_CHANGED } from '../../../store/modules/time';
import { CURRENT_LANGUAGE_CHANGED } from '../../../store/modules/i18n';
import StoreSubscribable from '../../mixins/StoreSubscribable.vue';

export default Vue.extend({
  mixins: [StoreSubscribable],
  data() {
    return {
      gridApi: null,
      columnApi: null,
    };
  },
  methods: {
    setAgGridApi(gridApiParams) {
      this.gridApi = gridApiParams && gridApiParams.api ? gridApiParams.api : gridApiParams;
      this.gridApi.sizeColumnsToFit();
      this.columnApi = gridApiParams && gridApiParams.columnApi ? gridApiParams.columnApi : null;
    },
    refreshCellsOnMutation() {
      const that = this;
      this.subscribeMutation(TIME_TIMEZONE_CHANGED, () => {
        if (that.gridApi) {
          that.gridApi.refreshCells({ force: true });
        }
      });
    },
    refreshCellsOnChangeLanguage() {
      const that = this;
      this.subscribeMutation(CURRENT_LANGUAGE_CHANGED, () => {
        if (that.gridApi) {
          that.gridApi.refreshHeader({ force: true });
        }
      });
    },
    showLoading() {
      if (this.gridApi) {
        this.gridApi.showLoadingOverlay();
      }
    },
    uncheckAllOnFilter() {
      if (this.gridApi) {
        this.gridApi.deselectAll();
      }
    },
  },
});
</script>
