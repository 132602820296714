<template>
  <ag-grid-template-cell-renderer :params="params">
    <v-tooltip bottom v-if="tooltip">
      <template slot="activator">
        <span :style="styleObject" :class="cssClass">{{formattedTime}}{{dayModifier}}</span>
      </template>
      <span>{{tooltip}}</span>
    </v-tooltip>
    <span v-if="params.value && !tooltip" :style="styleObject" :class="cssClass">{{formattedTime}}{{dayModifier}}</span>
  </ag-grid-template-cell-renderer>
</template>

<script>
import Vue from 'vue';
import AgGridTemplateCellRenderer from './templates/AgGridTemplateCellRenderer.vue';
import { JSUtils as TimeUtils } from '../../shared/js/TimeUtils';

export default Vue.extend({
  components: {
    AgGridTemplateCellRenderer,
  },
  computed: {
    styleObject() {
      return {
        color: (this.params && this.params.value && this.params.value.color) ? this.params.value.color : '',
      };
    },
    cssClass() {
      return this.params && this.params.value && this.params.value.cssClass ? this.params.value.cssClass : '';
    },
    formattedTime() {
      return this.params.value.value ? this.$moment(this.params.value.value).format('HH:mm') : '';
    },
    dayModifier() {
      if (!this.params.value.value || !this.params.value.referenceDate) {
        return '';
      }
      const datesDiff = TimeUtils.getDatesDiff(this.params.value.value, this.params.value.referenceDate);
      if (datesDiff > 0) {
        return '+';
      } if (datesDiff < 0) {
        return '-';
      }
      return '';
    },
    tooltip() {
      let tooltip = '';
      if (this.dayModifier !== '') {
        tooltip += this.params.value.value ? this.$moment(this.params.value.value).format('DD/MM/YYYY') : '';
      }
      if (this.params.value && this.params.value.tooltip) {
        tooltip += `\n${this.params.value.tooltip}`;
      }

      return tooltip !== '' ? tooltip : null;
    },
  },
});
</script>
