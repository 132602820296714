<template>
  <div v-if="this.isExternal">
    <v-flex v-flex-none class="inline-flight-number">
      <airline-select :ref="'refComp'" v-on:keydown.native="keyEventAirline" v-char-length.clearable="4" v-model="airlineIdUpdated"></airline-select>
    </v-flex>
    <v-flex v-flex-none class="inline-flight-number inline-flight-number-input">
      <v-text-field :ref="'refInput'" v-on:keydown.native="keyEventFlightNumber" v-char-length="5" v-model="tripNumberUpdated"></v-text-field>
    </v-flex>
  </div>
  <div v-else>
    <v-flex v-flex-none class="inline-flight-number inline-flight-number-input">
      <flight-selector-container :flight-original="this.flightDelivery.flightId" :date-original="date" @flightSelected="setFlight"></flight-selector-container>
    </v-flex>
  </div>
</template>

<script>
import Vue from 'vue';
import AirlineSelect from '../../../airline/AirlineSelect.vue';
import { keyEvents } from '../../../keyEvents';
import { FlightBound } from '../../../shared/model/flightBound';
import FlightSelector from '../../../ui/flightSelector/FlightSelector.vue';
import AgGridParentFlightDeliveryBaggageCellEditor from '../mixins/AgGridParentFlightDeliveryBaggageCellEditor.vue';
import FlightSelectorContainer from '../../../ui/flightSelector/FlightSelectorContainer.vue';
import DateInput from '../../../ui/DateInput.vue';

export default Vue.extend({
  mixins: [AgGridParentFlightDeliveryBaggageCellEditor],
  components: {
    FlightSelectorContainer,
    AirlineSelect,
    FlightSelector,
    DateInput,
  },
  computed: {
    flights() {
      return this.$store.state.flight.flightsList;
    },
    filter() {
      return this.$store.state.flight.filter;
    },
    isExternal() {
      return this.flightDelivery.flightId == null;
    },
  },
  data() {
    return {
      airlineIdOriginal: null,
      airlineIdUpdated: null,
      tripNumberOriginal: null,
      tripNumberUpdated: null,
      flightId: null,
      flightDelivery: {},
      dialog: false,
      bound: FlightBound.INBOUND.description,
      date: null,
    };
  },
  methods: {
    getValue() {
      if (this.airlineIdOriginal !== this.airlineIdUpdated || this.tripNumberOriginal !== this.tripNumberUpdated) {
        const airline = this.$store.getters.getAirlineById(this.airlineIdUpdated);
        const code = typeof (airline) !== 'undefined' ? airline.iataCode : undefined;

        const that = this;
        this.saveForValueOrAllSelectedDeliveries((s) => {
          s.setChildPropertyValue('flightTripNumber', that.tripNumberUpdated);
          s.setChildPropertyValue('flightId', that.flightId);
          s.setChildPropertyValue('airlineId', that.airlineIdUpdated);
          s.setChildPropertyValue('airlineCode', code);
        }, 'Flight Number');
      }
      return this.params.node.data;
    },
    keyEventAirline($event) {
      this.keyEvent($event);

      if ($event.code === keyEvents.ENTER) {
        $event.stopPropagation();
      }

      if ($event.code === keyEvents.TAB) {
        $event.stopPropagation();
        this.$refs.refInput.$refs['input-slot'].focus();
      }
    },
    keyEventFlightNumber($event) {
      this.keyEvent($event);

      if ($event.code === keyEvents.TAB && $event.shiftKey) {
        $event.stopPropagation();
      }
    },
    setFlight(flight) {
      this.airlineIdUpdated = flight.airlineId;
      this.tripNumberUpdated = flight.tripNumber;
      this.flightId = flight.id;
    },
  },
  created() {
    this.flightDelivery = this.params.node.data;
    this.date = this.flightDelivery.flightDate ? this.flightDelivery.flightDate : this.$moment();
    this.airlineIdOriginal = this.flightDelivery.airlineId;
    this.airlineIdUpdated = this.airlineIdOriginal;
    this.tripNumberOriginal = this.flightDelivery.flightTripNumber;
    this.tripNumberUpdated = this.tripNumberOriginal;
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$refs.refComp) {
        this.$refs.refComp.$refs.refSelectFlight.focus();
      }
    });
  },
});
</script>

<style>
  .ag-cell.flight-number-cell.ag-cell-inline-editing {
    min-width: 23vw;
    height: 15vh;
  }
  .inline-flight-number {
    display: inline-block;
  }
  .inline-flight-number-input {
    margin-left: 5px;
  }
</style>
