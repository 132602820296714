<template>
  <div>
    <v-flex v-flex-none>
      <v-text-field :ref="refNumberInput" v-model="updatedNumber" type="number" min="0" ></v-text-field>
    </v-flex>
  </div>
</template>

<script>
import Vue from 'vue';
import AgGridParentCellEditor from './mixins/AgGridParentCellEditor.vue';

export default Vue.extend({
  mixins: [AgGridParentCellEditor],
  components: {
  },
  props: {
    refNumberInput: {
      type: String,
      required: false,
      default: 'refNumberInput',
    },
  },
  data() {
    return {
      originalNumber: null,
      updatedNumber: null,
    };
  },
  methods: {
    getValue() {
      this.unmarkCellsSelectedAsEdit();
      return typeof this.updatedNumber === 'object' ? this.updatedNumber.value : this.updatedNumber;
    },
  },
  created() {
    this.originalNumber = this.params.value.value != null ? this.params.value.value : this.params.value;
    this.updatedNumber = this.originalNumber;

    this.markCellsSelectedAsEdit();
  },
  mounted() {
    setTimeout(() => {
      this.$refs.refNumberInput.$refs.input.focus();
      this.$refs.refNumberInput.$refs.input.type = 'text'; // Necessary conversion to use setSelectionRange
      this.$refs.refNumberInput.$refs.input.setSelectionRange(0, this.$refs.refNumberInput.$refs.input.value.length);
      this.$refs.refNumberInput.$refs.input.type = 'number';
    }, 0);
  },
});
</script>

<style>

</style>
