<template>
  <v-card-text>
    <v-container fluid>
      <v-layout row wrap>
        <v-flex  xs6 pr-2>
          <ramp-service-select v-char-length.clearable="4" v-on:keydown.native="keyEvent" :label="this.$i18n.t('common.rampServices')" v-model="item.serviceId" :disabled="!hasPermissions(rolePermission)"/>
        </v-flex>
      </v-layout>
    </v-container>
  </v-card-text>
</template>
<script>

import RampServiceSelect from '../../rampService/RampServiceSelect.vue';
import RolePermissions from '../../mixins/RolePermissions.vue';

export default {
  name: 'airport-ramp-service-edit-card-content',
  mixins: [RolePermissions],
  props: {
    item: { type: Object, required: true },
  },
  components: {
    RampServiceSelect,
  },
  computed: {
    rolePermission() {
      return `${this.$route.meta.role}_WR`;
    },
  },
};
</script>
