<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <entity-configuration-list
      :entity-name="this.$i18n.t('configuration.airportServiceAgents')"
      entity-icon="person"
      :framework-components="frameworkComponents" :column-defs="columnDefs" :items="airportServiceAgents"
      @confirm-edit-item="editConfirm"
      @confirm-delete-item="deleteConfirm"
      @confirm-enable-disable-item="enableDisableConfirm"
    >

    <template v-slot:modal-content="{ itemEdit }">
        <airport-service-agent-edit-card-content :item="itemEdit" />
      </template>
    </entity-configuration-list>
  </div>
</template>

<script>
import EntityConfigurationList from '../common/EntityConfigurationList.vue';
import AirportServiceAgentEditCardContent from './AirportServiceAgentEditCardContent.vue';
import AgGridIconCellRenderer from '../../ag-grid/cell-renderers/AgGridIconCellRenderer.vue';
import { MilestonesPermissions } from '../../shared/model/milestoneTypes';
import {
  GET_AIRPORT_SERVICE_AGENTS,
  AIRPORT_SERVICE_AGENT_CREATE,
  AIRPORT_SERVICE_AGENT_DELETE,
  AIRPORT_SERVICE_AGENT_DISABLE,
  AIRPORT_SERVICE_AGENT_ENABLE,
  AIRPORT_SERVICE_AGENT_UPDATE,
} from '../../../store/modules/serviceAgent/airportServiceAgent';

export default {
  name: 'airport-service-agents-list',
  components: {
    EntityConfigurationList,
    AirportServiceAgentEditCardContent,
  },
  data() {
    const that = this;
    return {
      gridOptions: {
        rowSelection: 'single',
        suppressRowClickSelection: false,
        onRowSelected(event) {
          that.$emit('row-selected', event);
        },
      },
      frameworkComponents: {
        AgGridIconCellRenderer,
      },
      columnDefs: [
        {
          field: 'serviceAgentName',
          width: 55,
          headerValueGetter() {
            return that.$i18n.t('configuration.serviceAgent');
          },
          sort: 'asc',
        },
        {
          field: 'defaultServiceInboundServiceName',
          width: 75,
          headerValueGetter() {
            return that.$i18n.t('configuration.defaultInboundRampService');
          },
        },
        {
          field: 'defaultServiceOutboundServiceName',
          width: 75,
          headerValueGetter() {
            return that.$i18n.t('configuration.defaultOutboundRampService');
          },
        },
        {
          field: 'defaultServiceAircraftGroundServiceName',
          width: 75,
          headerValueGetter() {
            return that.$i18n.t('configuration.defaultAircraftGroundRampService');
          },
        },
        {
          headerValueGetter() {
            return that.$i18n.t('configuration.baggageDistribution');
          },
          field: 'dispatcherPermissions.showBaggageDistribution',
          width: 40,
          cellRenderer: params => this.$i18n.t(`roles.${MilestonesPermissions[params.value]}`),
        },
        {
          headerValueGetter() {
            return that.$i18n.t('configuration.standardMessaging');
          },
          field: 'dispatcherPermissions.showStandardMessaging',
          width: 40,
          cellRenderer: params => this.$i18n.t(`roles.${MilestonesPermissions[params.value]}`),
        },
        {
          headerValueGetter() {
            return that.$i18n.t('configuration.filterByAgent');
          },
          field: 'dispatcherPermissions.filterByAgent',
          width: 40,
          cellRendererFramework: AgGridIconCellRenderer,
          cellRendererParams: {
            icon: params => (params.value ? 'mdi-check' : 'mdi-close'),
            tooltip: params => (params.value ? 'Enabled' : 'Disabled'),
          },

        },
        {
          headerValueGetter() {
            return that.$i18n.t('configuration.showBaggageSearch');
          },
          field: 'dispatcherPermissions.showBaggageSearch',
          width: 40,
          cellRendererFramework: AgGridIconCellRenderer,
          cellRendererParams: {
            icon: params => (params.value ? 'mdi-check' : 'mdi-close'),
            tooltip: params => (params.value ? 'Enabled' : 'Disabled'),
          },
        },
        {
          headerValueGetter() {
            return that.$i18n.t('configuration.disabled');
          },
          field: 'deleted',
          width: 35,
          cellRendererFramework: AgGridIconCellRenderer,
          cellRendererParams: {
            icon: 'mdi-cancel',
            tooltip: params => (params.data.deleted ? 'Disabled' : null),
          },
        },
      ],
    };
  },
  computed: {
    airportServiceAgents() {
      return this.$store.getters.getAirportServiceAgents;
    },
  },
  methods: {
    getAirportServiceAgents() {
      this.$store.dispatch(GET_AIRPORT_SERVICE_AGENTS);
    },
    deleteConfirm(item) {
      this.$store.dispatch(AIRPORT_SERVICE_AGENT_DELETE, item.id);
    },
    editConfirm(item) {
      this.$store.dispatch(item.id ? AIRPORT_SERVICE_AGENT_UPDATE : AIRPORT_SERVICE_AGENT_CREATE, item);
    },
    enableDisableConfirm(item) {
      this.$store.dispatch(item.deleted ? AIRPORT_SERVICE_AGENT_ENABLE : AIRPORT_SERVICE_AGENT_DISABLE, item.id);
    },
  },
  created() {
    this.getAirportServiceAgents();
  },
};

</script>
