/* eslint no-param-reassign: "off" */
/* eslint no-confusing-arrow: "off" */
/* eslint no-nested-ternary: "off" */

import { HTTP } from '../../../http';
import { REQUEST_ERROR, REQUEST_START, REQUEST_SUCCESS } from '../../store';
import { NOTIFICATION_SHOW } from '../notifications';
import i18n from '../../../plugins/i18n';

// actions names
export const GET_AIRPORT_GATE = 'GET_AIRPORT_GATE';
export const GET_AIRTPORT_GATE_CONTACT = 'GET_AIRTPORT_GATE_CONTACT';
export const AIRPORT_GATE_DELETE = 'AIRPORT_GATE_DELETE';
export const AIRPORT_GATE_CREATE = 'AIRPORT_GATE_CREATE';
export const AIRPORT_GATE_UPDATE = 'AIRPORT_GATE_UPDATE';
export const AIRPORT_GATE_CONTACT_UPDATE = 'AIRPORT_GATE_CONTACT_UPDATE';
export const AIRPORT_GATE_DISABLE = 'AIRPORT_GATE_DISABLE';
export const AIRPORT_GATE_ENABLE = 'AIRPORT_GATE_ENABLE';

// mutations
export const CHANGE_AIRPORT_GATE_SELECTION = 'CHANGE_AIRPORT_GATE_SELECTION';
export const GET_AIRPORT_GATE_SUCCESS = 'GET_AIRPORT_GATE_SUCCESS';
export const GET_AIRPORT_GATE_REQUESTED = 'GET_AIRPORT_GATE_REQUESTED';
export const UPDATE_AIRPORT_GATE_SUCCESS = 'UPDATE_AIRPORT_GATE_SUCCESS';
export const CHANGED_TENANT_AIRPORT_GATE = 'CHANGED_TENANT_AIRPORT_GATE';

// initial state
const storeState = {
  airportGates: [],
  airportGateRequested: false,
  selectedAirportGate: [],
};

// getters
const storeGetters = {
  getBoardingGates: state => state.airportGates.filter(gate => gate.boardingGate).sort((a, b) => (a.code > b.code) ? 1 : ((b.code > a.code) ? -1 : 0)),
  getActiveBoardingGates: state => state.airportGates.filter(gate => gate.boardingGate && !gate.deleted).sort((a, b) => (a.code > b.code) ? 1 : ((b.code > a.code) ? -1 : 0)),
  getDeboardingGates: state => state.airportGates.filter(gate => gate.unboardingGate).sort((a, b) => (a.code > b.code) ? 1 : ((b.code > a.code) ? -1 : 0)),
  getActiveDeboardingGates: state => state.airportGates.filter(gate => gate.unboardingGate && !gate.deleted).sort((a, b) => (a.code > b.code) ? 1 : ((b.code > a.code) ? -1 : 0)),
  getAirportGates: state => state.airportGates.sort((a, b) => (a.code > b.code) ? 1 : ((b.code > a.code) ? -1 : 0)),
  getActiveAirportGates: state => state.airportGates.filter(gate => !gate.deleted).sort((a, b) => (a.code > b.code) ? 1 : ((b.code > a.code) ? -1 : 0)),
  getAirportGateSelected: state => state.selectedAirportGate,
  hasSelectedAirportGate: state => state.selectedAirportGate.length > 0,
};

// actions
const storeActions = {
  [GET_AIRPORT_GATE]: ({ commit, state }) => {
    if (state.airportGateRequested) {
      return;
    }
    commit(REQUEST_START);
    commit(GET_AIRPORT_GATE_REQUESTED);
    HTTP.get('airport-gates')
      .then((resp) => {
        commit(REQUEST_SUCCESS);
        commit(GET_AIRPORT_GATE_SUCCESS, resp.data);
      })
      .catch((err) => {
        commit(REQUEST_ERROR, err);
      });
  },
  [GET_AIRTPORT_GATE_CONTACT]: ({ commit, state }) => {
    if (state.airportGateRequested) return;
    commit(REQUEST_START);
    commit(GET_AIRPORT_GATE_REQUESTED);
    HTTP.get('airport-gates/contact')
      .then((resp) => {
        commit(REQUEST_SUCCESS);
        commit(GET_AIRPORT_GATE_SUCCESS, resp.data);
      })
      .catch((err) => {
        commit(REQUEST_ERROR, err);
      });
  },
  [AIRPORT_GATE_CREATE]: ({ commit, dispatch }, gate) => {
    commit(REQUEST_START);
    return HTTP.post('airport-gates', gate)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(UPDATE_AIRPORT_GATE_SUCCESS);
        dispatch(GET_AIRPORT_GATE);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.airportGateCreate'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
  [AIRPORT_GATE_UPDATE]: ({ commit, dispatch }, gate) => {
    commit(REQUEST_START);
    return HTTP.put('airport-gates', gate)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(UPDATE_AIRPORT_GATE_SUCCESS);
        dispatch(GET_AIRPORT_GATE);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.airportGateUpdate'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
  [AIRPORT_GATE_CONTACT_UPDATE]: ({ commit, dispatch }, gate) => {
    commit(REQUEST_START);
    return HTTP.put('airport-gates/contact', gate)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(UPDATE_AIRPORT_GATE_SUCCESS);
        dispatch(GET_AIRTPORT_GATE_CONTACT);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.airportGateUpdate'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
  [AIRPORT_GATE_DELETE]: ({ commit, dispatch }, id) => {
    commit(REQUEST_START);
    return HTTP.delete(`airport-gates/${id}`)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(UPDATE_AIRPORT_GATE_SUCCESS);
        dispatch(GET_AIRPORT_GATE);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.airportGateDelete'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
  [AIRPORT_GATE_DISABLE]: ({ commit, dispatch }, id) => {
    commit(REQUEST_START);
    return HTTP.patch(`/airport-gates/disable/${id}`)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(UPDATE_AIRPORT_GATE_SUCCESS);
        dispatch(GET_AIRPORT_GATE);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.airportGateDisable'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
  [AIRPORT_GATE_ENABLE]: ({ commit, dispatch }, id) => {
    commit(REQUEST_START);
    return HTTP.patch(`/airport-gates/enable/${id}`)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(UPDATE_AIRPORT_GATE_SUCCESS);
        dispatch(GET_AIRPORT_GATE);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.airportGateEnable'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
};

// mutations
const storeMutations = {
  [GET_AIRPORT_GATE_SUCCESS]: (state, airportGates) => {
    const airportGatesAux = airportGates !== null ? airportGates : [];
    state.airportGates = airportGatesAux.map((c) => {
      if (!c.contact) {
        c.contact = {};
      }
      return c;
    });
  },
  [GET_AIRPORT_GATE_REQUESTED]: (state) => {
    state.airportGateRequested = true;
  },
  [UPDATE_AIRPORT_GATE_SUCCESS]: (state) => {
    state.airportGateRequested = false;
  },
  [CHANGE_AIRPORT_GATE_SELECTION]: (state, selection) => {
    state.selectedAirportGate = selection;
  },
  [CHANGED_TENANT_AIRPORT_GATE]: (state) => {
    state.airportGateRequested = false;
  },
};

export default {
  state: storeState,
  getters: storeGetters,
  actions: storeActions,
  mutations: storeMutations,
};
