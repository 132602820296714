/* eslint no-param-reassign: "off" */

import AuthService from '../../components/shared/services/auth/auth-service';
import { HTTP } from '../../http';
import { TICKETING_ROLE_CALCULATE } from './ticketing';
import { TOWING_ADHOC_TASK_ROLE_CALCULATE } from './towingAdhocTask/towingAdhocTask';
import router from '../../router/router';

export const AUTH_CHECK = 'AUTH_CHECK';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_SET_ROLE = 'AUTH_SET_ROLE';

export const AUTH_CHECK_REQUEST = 'AUTH_CHECK_REQUEST';
export const AUTH_CHECK_SUCCESS = 'AUTH_CHECK_SUCCESS';
export const AUTH_CHECK_ERROR = 'AUTH_CHECK_ERROR';
export const AUTH_LOGOUT_REQUEST = 'AUTH_LOGOUT_REQUEST';
export const AUTH_LOGOUT_SUCCESS = 'AUTH_LOGOUT_SUCCESS';
export const AUTH_LOGOUT_ERROR = 'AUTH_LOGOUT_ERROR';

// initial state
const storeState = {
  authStatus: null,
  authUser: null,
};

// getters
const storeGetters = {
  isAuthenticated: state => !!state.authUser,
  user: state => state.authUser,
  hasRoleScheduling: state => (state.authUser !== null ? !!state.authUser.authorities.includes('ROLE_SCHEDULING') : false),
  hasRoleOperations: state => (state.authUser !== null ? !!state.authUser.authorities.includes('ROLE_OPERATIONS') : false),
  hasRole: state => (roles) => {
    if (state.authUser !== null) {
      if (Array.isArray(roles)) {
        return !!state.authUser.authorities.some(role => roles.includes(role));
      }
      return !!state.authUser.authorities.includes(roles);
    }
    return false;
  },
};

// actions
const storeActions = {
  [AUTH_CHECK]: ({ commit, dispatch }) => new Promise((resolve, reject) => { // The Promise used for router redirect in login
    commit(AUTH_CHECK_REQUEST);
    HTTP.get('account', { withCredentials: false })
      .then((resp) => {
        commit(AUTH_CHECK_SUCCESS, resp.data);
        dispatch(AUTH_SET_ROLE);
        resolve(resp);
      })
      .catch((err) => {
        commit(AUTH_CHECK_ERROR, err);
        reject(err);
      });
  }),
  [AUTH_LOGOUT]: ({ commit }) => new Promise((resolve, reject) => {
    commit(AUTH_LOGOUT_REQUEST);
    HTTP.post('logout', null, { withCredentials: false })
      .then((resp) => {
        commit(AUTH_LOGOUT_SUCCESS, resp.data);
        resolve(resp);
      })
      .catch((err) => {
        commit(AUTH_LOGOUT_ERROR, err);
        reject(err);
      }).finally(() => {
        AuthService.clearAuth();
        commit('TENANT_CURRENT_SET_SUCCESS', null);
        router.push('/login');
      });
  }),
  [AUTH_SET_ROLE]: ({ dispatch, state }) => {
    dispatch(TICKETING_ROLE_CALCULATE, state.authUser.authorities);
    dispatch(TOWING_ADHOC_TASK_ROLE_CALCULATE, state.authUser.authorities);
  },
};

// mutations
const storeMutations = {
  [AUTH_CHECK_REQUEST]: (state) => {
    state.authStatus = 'loading';
  },
  [AUTH_CHECK_SUCCESS]: (state, user) => {
    state.authStatus = 'success';
    state.authUser = user;
  },
  [AUTH_CHECK_ERROR]: (state) => {
    state.authUser = null;
    state.authStatus = 'error';
    AuthService.clearAuth();
    router.push('/login');
  },
  [AUTH_LOGOUT_REQUEST]: (state) => {
    state.authStatus = 'loading';
    state.authUser = null;
  },
  [AUTH_LOGOUT_SUCCESS]: (state) => {
    state.authStatus = 'success';
  },
  [AUTH_LOGOUT_ERROR]: (state) => {
    state.authStatus = 'error';
  },
};

export default {
  state: storeState,
  getters: storeGetters,
  actions: storeActions,
  mutations: storeMutations,
};
