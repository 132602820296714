<template>
  <v-card-text>
    <v-container fluid>
      <v-layout>
        <v-flex xs4 pr-2>
          <v-text-field :label="this.$i18n.t('configuration.avinetUsers')" v-model="item.user"
            :readonly="!hasPermissions(rolePermission)" />
        </v-flex>

        <v-flex xs4 px-2>
          <v-text-field v-model="item.password" :append-icon="show2 ? 'visibility' : 'visibility_off'"
            :type="show2 ? 'text' : 'password'" @click:append="show2 = !show2"
            :label="this.$i18n.t('configuration.avinetPassword')" :readonly="!hasPermissions(rolePermission)" />
        </v-flex>

        <v-flex xs4 px-2>
          <v-container fluid>
            <v-layout row column>
              <v-flex>
                <tenant-selector style="padding:0" v-model="item.tenantId"
                  :label="this.$i18n.t('configuration.tenantAuthorities')"
                  :disabled="!hasPermissions(rolePermission)" />
              </v-flex>
            </v-layout>
          </v-container>
        </v-flex>
        <v-flex xs3 px-2>
          <v-checkbox color="primary" :label="this.$i18n.t('configuration.defaultUser')" v-model="item.defaultUser"
            :disabled="!hasPermissions(rolePermission)" />
        </v-flex>
      </v-layout>
    </v-container>
  </v-card-text>
</template>
<script>
import TenantSelector from '../../tenant/TenantSelector.vue';
import RolePermissions from '../../mixins/RolePermissions.vue';

export default {
  name: 'AvinetUserEditCardContent',
  mixins: [RolePermissions],
  props: {
    item: { type: Object, required: true },
  },
  components: {
    TenantSelector,
  },
  computed: {
    rolePermission() {
      return `${this.$route.meta.role}_WR`;
    },
  },
  data() {
    return {
      show2: false,
    };
  },
  methods: {

  },
};
</script>
