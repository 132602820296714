/* eslint no-param-reassign: "off" */
import moment from 'moment/moment';
import { HTTP } from '../../../http';
import { REQUEST_ERROR, REQUEST_START, REQUEST_SUCCESS } from '../../store';
import { NOTIFICATION_SHOW } from '../notifications';
import rushBaggageFilter from '../../../components/lostAndFound/rushBaggage/model/rushBaggageFilter';
import RushBaggageFactory from '../../../components/shared/model/RushBaggageFactory';
import { createRushBaggageEventFromData } from '../../../components/shared/model/rushBaggageEvent';
import i18n from '../../../plugins/i18n';


// action
export const RUSH_BAGGAGE_SET_GRID_API = 'RUSH_BAGGAGE_SET_GRID_API';

export const FIND_RUSHES_BAGGAGE = 'FIND_RUSHES_BAGGAGE';
export const RUSHES_BAGGAGE_UPDATE = 'RUSHES_BAGGAGE_UPDATE';
export const RUSH_BAGGAGE_DO_EXTERNAL_CHANGE = 'RUSH_BAGGAGE_DO_EXTERNAL_CHANGE';
export const RUSH_BAGGAGE_DELETE = 'RUSH_BAGGAGE_DELETE';
export const RUSH_BAGGAGE_FIT_COLUMNS_TO_VIEW = 'RUSH_BAGGAGE_FIT_COLUMNS_TO_VIEW';
export const RUSH_BAGGAGE_FIT_COLUMNS_TO_BEST = 'RUSH_BAGGAGE_FIT_COLUMNS_TO_BEST';

// mutations
export const FIND_RUSHES_BAGGAGE_SUCCESS = 'FIND_RUSHES_BAGGAGE_SUCCESS';
export const CHANGE_RUSHES_BAGGAGE_SELECTION = 'CHANGE_RUSHES_BAGGAGE_SELECTION';
export const RUSH_BAGGAGE_ON_EXTERNAL_CHANGE = 'RUSH_BAGGAGE_ON_EXTERNAL_CHANGE ';
export const RUSH_BAGGAGE_SET_CHANGE = 'RUSH_BAGGAGE_SET_CHANGE ';

export const RUSH_BAGGAGE_FILTER_RESET = 'RUSH_BAGGAGE_FILTER_RESET ';
export const RUSH_BAGGAGE_FILTER_RESET_TIMEZONE = 'RUSH_BAGGAGE_FILTER_RESET_TIMEZONE ';
export const RUSH_BAGGAGE_FILTER_INCREASE_DAY = 'RUSH_BAGGAGE_FILTER_INCREASE_DAY ';
export const RUSH_BAGGAGE_FILTER_DECREASE_DAY = 'RUSH_BAGGAGE_FILTER_DECREASE_DAY ';
export const RUSH_BAGGAGE_FILTER_SET_DAY = 'RUSH_BAGGAGE_FILTER_SET_DAY ';

export const UPDATE_SHOW_OLD_VALUES = 'UPDATE_SHOW_OLD_VALUES ';
export const UPDATE_FLASH_ON_CHANGES = 'UPDATE_FLASH_ON_CHANGES ';
export const RUSH_BAGGAGE_UPDATE_FIT_COLUMNS_ON_RESIZE = 'RUSH_BAGGAGE_UPDATE_FIT_COLUMNS_ON_RESIZE ';

// initial state
const storeState = {
  gridApi: null,
  filter: rushBaggageFilter.createEmptyFilter,
  rushesBaggage: [],
  selectedRushesBaggage: [],
  rushesBaggageChanges: [],
  showOldValues: true,
  flashOnChanges: false,
  fitColumnsOnResize: true,
};

// getters
const storeGetters = {
  hasSelectedRushesBaggage: state => state.selectedRushesBaggage.length > 0,
  isSelectedRushBaggage: state => id => !!state.selectedRushesBaggage.find(s => s.getId() === id),
};

// actions
const storeActions = {
  [FIND_RUSHES_BAGGAGE]: ({ commit, state }) => {
    console.log(state.filter); // eslint-disable-line no-console
    commit(REQUEST_START);
    commit(CHANGE_RUSHES_BAGGAGE_SELECTION, []);
    return HTTP.post('rush-baggage/find', state.filter)
      .then((resp) => {
        commit(REQUEST_SUCCESS);
        commit(
          FIND_RUSHES_BAGGAGE_SUCCESS,
          RushBaggageFactory.mapRushBaggage(resp.data),
        );
        commit(CHANGE_RUSHES_BAGGAGE_SELECTION, []);
      })
      .catch((err) => {
        commit(FIND_RUSHES_BAGGAGE_SUCCESS, []);
        commit(REQUEST_ERROR, err);
      });
  },
  [RUSHES_BAGGAGE_UPDATE]: ({ commit }, { rushBaggageList, field }) => new Promise((resolve, reject) => {
    commit(REQUEST_START);
    // TODO FixMe One request
    const lastIteration = (index, array) => index === array.length - 1;

    if (rushBaggageList && rushBaggageList.length > 0) {
      rushBaggageList.forEach((s, index, array) => {
        HTTP.put('rush-baggage', s)
          .then((response) => {
            if (lastIteration(index, array)) {
              commit(REQUEST_SUCCESS);
              commit(NOTIFICATION_SHOW, {
                message: field ? `${i18n.t('notifications.rushBaggageFieldUpdate')} ${field}` : i18n.t('notifications.rushBaggageUpdate'),
                color: 'success',
              });
              resolve(response);
            }
          })
          .catch((err) => {
            commit(REQUEST_ERROR, err);
            reject(err);
          });
      });
    }
  }),
  [RUSH_BAGGAGE_DO_EXTERNAL_CHANGE]: ({ commit }, change) => {
    commit(RUSH_BAGGAGE_ON_EXTERNAL_CHANGE, createRushBaggageEventFromData(change));
  },
  [RUSH_BAGGAGE_DELETE]: ({ commit, dispatch }, ids) => {
    commit(REQUEST_START);
    return HTTP.post('rush-baggage/batch-delete', ids)
      .then(() => {
        commit(REQUEST_SUCCESS);
        dispatch(FIND_RUSHES_BAGGAGE);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.rushBaggageDelete'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
  [RUSH_BAGGAGE_FIT_COLUMNS_TO_VIEW]: ({ state }) => {
    if (state.gridApi) {
      state.gridApi.sizeColumnsToFit();
    }
  },
  [RUSH_BAGGAGE_FIT_COLUMNS_TO_BEST]: ({ state }) => {
    if (state.gridApi && state.gridApi.columnController && state.gridApi.columnController.columnApi) {
      state.gridApi.columnController.columnApi.autoSizeAllColumns();
    }
  },
};

// mutations
const storeMutations = {
  [RUSH_BAGGAGE_SET_GRID_API]: (state, gridApi) => {
    state.gridApi = gridApi.api;
  },
  [FIND_RUSHES_BAGGAGE_SUCCESS]: (state, rushesBaggage) => {
    state.rushesBaggage = rushesBaggage;
  },
  [CHANGE_RUSHES_BAGGAGE_SELECTION]: (state, selection) => {
    state.selectedRushesBaggage = selection;
  },
  [RUSH_BAGGAGE_ON_EXTERNAL_CHANGE]: (state, change) => {
    console.log(`mutations external change ${change}`); // eslint-disable-line no-console
  },
  [RUSH_BAGGAGE_SET_CHANGE]: (state, change) => {
    change.ts = moment();
    state.rushesBaggageChanges.unshift(change);
    state.rushesBaggageChanges = state.rushesBaggageChanges.slice(0, 10);
  },
  [RUSH_BAGGAGE_FILTER_RESET]: (state) => {
    state.filter.dateFrom = moment().startOf('day');
    state.filter.dateTo = moment().endOf('day');

    state.filter.timezoneDate = moment.defaultZone.name;
  },
  [RUSH_BAGGAGE_FILTER_RESET_TIMEZONE]: (state) => {
    state.filter.dateFrom = moment().startOf('day')
      .year(state.filter.dateFrom.year())
      .month(state.filter.dateFrom.month())
      .date(state.filter.dateFrom.date());
    state.filter.dateTo = moment().endOf('day')
      .year(state.filter.dateTo.year())
      .month(state.filter.dateTo.month())
      .date(state.filter.dateTo.date());

    state.filter.timezoneDate = moment.defaultZone.name;
  },
  [RUSH_BAGGAGE_FILTER_INCREASE_DAY]: (state) => {
    state.filter.dateFrom = moment().startOf('day')
      .year(state.filter.dateFrom.year())
      .month(state.filter.dateFrom.month())
      .date(state.filter.dateFrom.date())
      .add(1, 'days')
      .startOf('day');

    state.filter.dateTo = moment().endOf('day')
      .year(state.filter.dateTo.year())
      .month(state.filter.dateTo.month())
      .date(state.filter.dateTo.date())
      .add(1, 'days');
  },
  [RUSH_BAGGAGE_FILTER_DECREASE_DAY]: (state) => {
    state.filter.dateFrom = moment().startOf('day')
      .year(state.filter.dateFrom.year())
      .month(state.filter.dateFrom.month())
      .date(state.filter.dateFrom.date())
      .subtract(1, 'days');

    state.filter.dateTo = moment().endOf('day')
      .year(state.filter.dateTo.year())
      .month(state.filter.dateTo.month())
      .date(state.filter.dateTo.date())
      .subtract(1, 'days')
      .endOf('day');
  },
  [RUSH_BAGGAGE_FILTER_SET_DAY]: (state, dateSelected) => {
    state.filter.dateFrom = moment(dateSelected).startOf('day');

    state.filter.dateTo = moment(dateSelected).endOf('day');
  },
  [UPDATE_SHOW_OLD_VALUES]: (state, showOldValues) => {
    state.showOldValues = showOldValues;
  },
  [UPDATE_FLASH_ON_CHANGES]: (state, flashOnChanges) => {
    state.flashOnChanges = flashOnChanges;
  },
  [RUSH_BAGGAGE_UPDATE_FIT_COLUMNS_ON_RESIZE]: (state, fitColumnsOnResize) => {
    state.fitColumnsOnResize = fitColumnsOnResize;
  },
};

export default {
  state: storeState,
  getters: storeGetters,
  actions: storeActions,
  mutations: storeMutations,
};
