<template>
  <v-container grid-list-md text-xs-center fluid>
    <span style="font-weight: bold">{{$t('commonForm.flightInformation')}}</span>

    <v-layout row wrap>

      <v-flex xs2 class="inputFlight">
        <date-input :locale="$i18n.locale" :label="$t('common.date')" :ref="'refComp'" v-model="rushBaggage.flightDate" :rules="[rules.required]"></date-input>
      </v-flex>

      <v-flex xs4 class="inputFlight">
        <div>
          <v-flex v-flex-none class="inline-flight-number">
            <airline-select :label="$t('commonForm.airlineTripNumber')" :ref="'refComp'" v-char-length.clearable="4"
                            v-model="rushBaggage.airlineId" :rules="[rules.required]"></airline-select>
          </v-flex>
          <v-flex v-flex-none class="inline-flight-number inline-flight-number-input">
            <v-text-field :ref="'refInput'" v-char-length="5" v-model="rushBaggage.flightTripNumber" :rules="[rules.required]"></v-text-field>
          </v-flex>
        </div>
      </v-flex>

      <v-flex xs2 class="inputFlight">
        <time-input :label="$t('operations.scheduledTimeOfDeparture') " :ref="'refTimeInput'" v-model="rushBaggage.scheduledTime"
                    :referenceDate="rushBaggage.flightDate"></time-input>
      </v-flex>

      <v-flex xs2 class="inputFlight">
        <airport-select :label="$t('operations.airportOfDestination')" :ref="'refComp1'" v-char-length.clearable="3"
                        v-model="rushBaggage.airport1Id"></airport-select>
      </v-flex>

    </v-layout>

    <span style="font-weight: bold">{{$t('lostAndFound.rushBaggageInformation')}}</span>

    <v-layout row wrap>

      <v-flex xs3 class="inputFlight">
        <v-text-field :label="$t('lostAndFound.paxNameFileReference')" v-model="rushBaggage.passengerName"></v-text-field>
      </v-flex>

      <v-flex xs2 class="inputFlight">
        <v-text-field :label="$t('lostAndFound.rushTag')" v-model="rushBaggage.rushTag"></v-text-field>
      </v-flex>

      <v-flex xs2 class="inputFlight">
        <v-text-field :label="$t('lostAndFound.originalTag')" v-model="rushBaggage.originalTag"></v-text-field>
      </v-flex>

      <v-flex xs2 class="inputFlight">
        <v-text-field :label="$t('lostAndFound.agent')" v-model="rushBaggage.agent"></v-text-field>
      </v-flex>
      <v-flex xs1 class="inputFlight">
        <v-text-field :label="$t('lostAndFound.rl')" v-model="rushBaggage.rl" :rules="[rules.length2]"></v-text-field>
      </v-flex>

    </v-layout>
  </v-container>
</template>
<script>

import DateInput from '../../../ui/DateInput.vue';
import AirportSelect from '../../../airport/AirportSelect.vue';
import AirlineSelect from '../../../airline/AirlineSelect.vue';
import TimeInput from '../../../ui/TimeInput.vue';

export default {
  name: 'rush-baggage-flight-external-form',
  components: {
    DateInput,
    TimeInput,
    AirportSelect,
    AirlineSelect,
  },
  props: {
    rushBaggageOriginal: {},
  },
  data() {
    return {
      rushBaggage: this.rushBaggageOriginal,
      rules: {
        required: value => !!value || 'Required.',
        length2: value => value.length <= 2 || 'Max characters: 2',
      },
    };
  },
  watch: {
    rushBaggageOriginal: {
      handler(newRushBaggageProp) {
        this.rushBaggage = newRushBaggageProp;
      },
      deep: true,
    },
    rushBaggage: {
      handler(newRushBaggage) {
        this.$emit('rush-baggage-update', newRushBaggage);
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
  .v-btn-toggle .v-btn.v-btn--active.v-btn--disabled i {
    color: white !important;;
  }

  .inputFlight {
    margin: auto 1em;
  }

  .inline-flight-number {
    display: inline-block;
  }

  .inline-flight-number-input {
    margin-left: 5px;
  }
</style>
