/* eslint no-param-reassign: "off" */

import { HTTP } from '../../../http';
import { REQUEST_ERROR, REQUEST_START, REQUEST_SUCCESS } from '../../store';
import { NOTIFICATION_SHOW } from '../notifications';
import i18n from '../../../plugins/i18n';

// actions names
export const GET_AIRPORT_CONTACT = 'GET_AIRPORT_CONTACT';
export const AIRPORT_CONTACT_DELETE = 'AIRPORT_CONTACT_DELETE';
export const AIRPORT_CONTACT_CREATE = 'AIRPORT_CONTACT_CREATE';
export const AIRPORT_CONTACT_UPDATE = 'AIRPORT_CONTACT_UPDATE';


// mutations
export const GET_AIRPORT_CONTACT_SUCCESS = 'GET_AIRPORT_CONTACT_SUCCESS';
export const GET_AIRPORT_CONTACT_REQUESTED = 'GET_AIRPORT_CONTACT_REQUESTED';
export const UPDATE_AIRPORT_CONTACT_SUCCESS = 'UPDATE_AIRPORT_CONTACT_SUCCESS';

// initial state
const storeState = {
  airportContacts: [],
  airportContactRequested: false,
};

// getters
const storeGetters = {
  getAirportContacts: state => state.airportContacts,
};

// actions
const storeActions = {
  [GET_AIRPORT_CONTACT]: ({ commit, state }) => {
    if (state.airportContactRequested) return;
    commit(REQUEST_START);
    commit(GET_AIRPORT_CONTACT_REQUESTED);
    HTTP.get('airports-contact')
      .then((resp) => {
        commit(REQUEST_SUCCESS);
        commit(GET_AIRPORT_CONTACT_SUCCESS, resp.data);
      })
      .catch((err) => {
        commit(REQUEST_ERROR, err);
      });
  },
  [AIRPORT_CONTACT_CREATE]: ({ commit, dispatch }, item) => {
    commit(REQUEST_START);
    return HTTP.post('airports-contact', item)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(UPDATE_AIRPORT_CONTACT_SUCCESS);
        dispatch(GET_AIRPORT_CONTACT);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.airportContactsCreate'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
  [AIRPORT_CONTACT_UPDATE]: ({ commit, dispatch }, item) => {
    commit(REQUEST_START);
    return HTTP.put('airports-contact', item)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(UPDATE_AIRPORT_CONTACT_SUCCESS);
        dispatch(GET_AIRPORT_CONTACT);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.airportContactsUpdate'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
  [AIRPORT_CONTACT_DELETE]: ({ commit, dispatch }, id) => {
    commit(REQUEST_START);
    return HTTP.delete(`airports-contact/${id}`)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(UPDATE_AIRPORT_CONTACT_SUCCESS);
        dispatch(GET_AIRPORT_CONTACT);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.airportContactsDelete'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
};

// mutations
const storeMutations = {
  [GET_AIRPORT_CONTACT_SUCCESS]: (state, airportContacts) => {
    state.airportContacts = airportContacts;
  },
  [GET_AIRPORT_CONTACT_REQUESTED]: (state) => {
    state.airportContactRequested = true;
  },
  [UPDATE_AIRPORT_CONTACT_SUCCESS]: (state) => {
    state.airportContactRequested = false;
  },
};

export default {
  state: storeState,
  getters: storeGetters,
  actions: storeActions,
  mutations: storeMutations,
};
