<template>
  <v-container v-if="!isEmptyLoads()" class="infoMobileLoads py-0">
    <v-layout row wrap>
      <v-flex class="pb-2" xs8>
        <v-radio-group name="unitOptions" id="unitOptions" :dark="$vuetify.dark" row class="large-radio ml-2" v-model="flightMobileLoads.totalLoad.unit">
          <v-radio v-for="(option, index) in unitOptions" :name="option.text" :key="index" :label="option.text" :value="option.value"
            :disabled="!hasPermissions('AIRPORT_SERVICE_AGENT_ROLE_BAGGAGE_DISTRIBUTION_WR')" class="large-radio" />
        </v-radio-group>
      </v-flex>
      <!-- add button save-->
      <v-flex class="py-2 text-xs-right" xs4 v-if="hasPermissions('AIRPORT_SERVICE_AGENT_ROLE_BAGGAGE_DISTRIBUTION_WR')">
        <v-btn large color="primary" @click="saveFlightLoads" class="mx-0" :disabled="!saveButton">
          {{ $t('common.save') }}
        </v-btn>
      </v-flex>
    </v-layout>
    <mobile-flight-loads :flightLoads="flightMobileLoads.loads" :totals="flightMobileLoads.totalLoad"
      @updateLoad="updateLoad" :unit="getUnit()" />
    <v-spacer class="pb-2"></v-spacer>
    <mobile-flight-loads-holding :flightLoads="flightMobileLoads" :updateFlightLoadHolding="updateFlightLoadHolding"
      @enableSave="enableSave" />
    <!-- add button save at end -->
    <v-layout row wrap>
      <v-flex class="py-0 text-xs-center" xs12 v-if="hasPermissions('AIRPORT_SERVICE_AGENT_ROLE_BAGGAGE_DISTRIBUTION_WR')">
        <v-btn large color="primary" @click="saveFlightLoads" class="mx-0" :disabled="!saveButton">
          {{ $t('common.save') }}
        </v-btn>
      </v-flex>
    </v-layout>
    <v-spacer class="pb-5"></v-spacer>
    <scroll-buttons />
  </v-container>
</template>

<script>

import { VLayout } from 'vuetify/lib';
import { MOBILE_GET_FLIGHT_LOADS, MOBILE_PUT_FLIGHT_LOADS } from '../../store/modules/mobile';

import MobileConfirmationDialog from './MobileConfirmationDialog.vue';
import MobileFlightLoads from './loads/MobileFlightLoads.vue';
import MobileFlightLoadsHolding from './loads/MobileFlightLoadsHolding.vue';
import scrollButtons from './scroll/scrollButtons.vue';
import RolePermissions from '../mixins/RolePermissions.vue';
import './infoMobileLoads.scss';

export default {
  name: 'info-mobile-loads',
  props: {
    flightId: {
      required: true,
    },
  },
  mixins: [RolePermissions],
  components: {
    MobileConfirmationDialog,
    MobileFlightLoads,
    MobileFlightLoadsHolding,
    scrollButtons,
    VLayout,
  },
  methods: {
    isEmptyLoads() {
      return this.flightMobileLoads.length === 0;
    },
    updateFlightLoadHolding(loadHolding) {
      // find loadHolding in array and update
      const index = this.flightMobileLoads.loadsHolding.findIndex(load => load.holdOrder === loadHolding.holdOrder);
      this.flightMobileLoads.loadsHolding[index] = loadHolding;
      // update totalLoadHolding
      this.flightMobileLoads.totalLoadHolding.pcs = this.flightMobileLoads.loadsHolding.reduce((acc, load) => acc + load.pcs, 0);
      this.flightMobileLoads.totalLoadHolding.weight = this.flightMobileLoads.loadsHolding.reduce((acc, load) => acc + load.weight, 0);
    },
    saveFlightLoads() {
      this.$store.dispatch(MOBILE_PUT_FLIGHT_LOADS, {
        flightId: this.flightId,
        data: this.flightMobileLoads,
      }).then(() => {
        this.$store.dispatch(MOBILE_GET_FLIGHT_LOADS, this.flightId);
      });
    },
    updateLoad(load, totalLoad) {
      // find load in array and update
      const index = this.flightMobileLoads.loads.findIndex(loadAux => loadAux.id === load.id);
      this.flightMobileLoads.loads[index] = load;
      Object.assign(this.flightMobileLoads.totalLoad, totalLoad);
    },
    enableSave(value) {
      if (!this.hasPermissions('AIRPORT_SERVICE_AGENT_ROLE_BAGGAGE_DISTRIBUTION_WR')) {
        this.saveButton = false;
        return;
      }
      this.saveButton = value;
    },
    getUnit() {
      return this.flightMobileLoads.totalLoad.unit === 'KILOGRAMS' ? 'Kgs' : 'Lbs';
    },
  },
  data() {
    return {
      loadsColumns: [],
      unitOptions: [
        {
          text: 'Kilograms',
          value: 'KILOGRAMS',
        },
        {
          text: 'Pounds',
          value: 'POUNDS',
        },
      ],
      saveButton: false,
    };
  },
  computed: {
    flightMobileLoads() {
      return this.$store.state.mobile.flightMobileLoads;
    },
  },
  created() {
    if (this.flightId) {
      this.$store.dispatch(MOBILE_GET_FLIGHT_LOADS, this.flightId);
    }
  },
};
</script>
