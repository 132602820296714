<template>
  <div>
    <ag-grid-vue
      class="ag-theme-material connected-flights-table operational-table"
      :columnDefs="columnDefs"
      :defaultColDef="defaultColDef"
      :rowData="items"
      :onGridReady="onGridReady"
      @first-data-rendered="firstDataRendered"
      :gridOptions="gridOptions"
      :suppressHorizontalScroll="true"
      :frameworkComponents="frameworkComponents"
      v-bind:style="`width: 100%; height: calc(100vh - ${this.sizeFilter});`">
    </ag-grid-vue>
  </div>
</template>
<script>
import { AgGridVue } from 'ag-grid-vue';
import AgGridConnectedFlights from '../../ag-grid/mixins/AgGridConnectedFlights.vue';
import { cellClassAgGridRules } from '../../ag-grid/mixins/cellClassAgGridRules';
import { FisModule } from '../../ag-grid/cell-editors/model/fisModule';
import agGridTextCustomFilterComparator from '../../ag-grid/filters/agGridTextCustomFilterComparator';
import agGridTextCustomFilterFormatter from '../../ag-grid/filters/agGridTextCustomFilterFormatter';
import AgGridCustomService from '../../shared/services/agGridCustomService';
import AgGridValueBaseCellRenderer from '../../ag-grid/cell-renderers/AgGridValueBaseCellRenderer.vue';
import AgGridCurrencyCellRenderer from '../../ag-grid/cell-renderers/AgGridCurrencyCellRenderer.vue';
import RolePermissions from '../../mixins/RolePermissions.vue';
import {
  AIRPORT_INCOME_TICKETING_REPORT_CHANGE_GRID_API,
  AIRPORT_INCOME_TICKETING_REPORT_FIT_COLUMNS_TO_VIEW,
} from '../../../store/modules/ticketingReports/airportIncome';

export default {
  name: 'airport-income-ticketing-report-list-table',
  mixins: [AgGridConnectedFlights, RolePermissions],
  components: {
    AgGridVue,
    AgGridValueBaseCellRenderer,
    AgGridCurrencyCellRenderer,
  },
  props: {
    items: { type: Array, required: true },
    isFilterDisplayed: { type: Boolean, required: false, default: false },
  },
  computed: {
    sizeFilter() {
      return this.isFilterDisplayed ? '320px' : '180px';
    },
    fitColumnsOnResize() {
      return this.$store.state.ticketingReports.airportIncome.fitColumnsOnResize;
    },
  },
  data() {
    const that = this;
    return {
      frameworkComponents: {

      },
      defaultColDef: {
        cellClassRules: cellClassAgGridRules,
        minWidth: 30,
        suppressMenu: true,
        resizable: true,
        sortable: true,
        filter: 'agTextColumnFilter',
        filterParams: {
          textCustomComparator: agGridTextCustomFilterComparator,
          textFormatter: agGridTextCustomFilterFormatter,
        },
        editable: false,
      },
      gridOptions: {
        onFilterChanged: this.uncheckAllOnFilter,
        getRowNodeId: this.getRowNodeId,
        getRowClass: this.getRowClass,
        floatingFilter: true,
        groupHeaderHeight: 35,
        headerHeight: 35,
        floatingFiltersHeight: 20,
        rowHeight: 30,
        context: {
          module: FisModule.TICKETING_REPORTS,
        },
        alignedGrids: [],
      },
      columnDefs: [
        {
          colId: 'tenant',
          headerValueGetter() {
            return that.$i18n.t('common.airport');
          },
          headerTooltip: this.$i18n.t('common.airport'),
          field: 'tenantName',
          cellRendererFramework: AgGridValueBaseCellRenderer,
        },
        {
          colId: 'airline',
          headerValueGetter() {
            return that.$i18n.t('common.airline');
          },
          headerTooltip: this.$i18n.t('common.airline'),
          field: 'airlineCode',
          cellRendererFramework: AgGridValueBaseCellRenderer,
        },
        {
          colId: 'total',
          headerValueGetter() {
            return that.$i18n.t('ticketing.quantity');
          },
          headerTooltip: this.$i18n.t('ticketing.quantity'),
          field: 'total',
          colCustomConfig: {
            valueGetterExportCsv(params) {
              return that.formatNumberCurrency(params.data.currency, params.data.total);
            },
          },
          valueGetter(params) {
            return {
              value: params.data.total,
              currency: params.data.currency,
            };
          },
          cellRendererFramework: AgGridCurrencyCellRenderer,
        },
        {
          colId: 'currency',
          headerValueGetter() {
            return that.$i18n.t('contingencies.currency');
          },
          headerTooltip: this.$i18n.t('flightInformation.airlineOperation'),
          field: 'currency',
          cellRendererFramework: AgGridValueBaseCellRenderer,
        },
      ],
    };
  },
  methods: {
    onGridReady(params) {
      this.setAgGridApi(params);
      this.showLoading();
      this.$store.commit(AIRPORT_INCOME_TICKETING_REPORT_CHANGE_GRID_API, params); // MOVE TO MIXIN?
      this.refreshCellsOnMutation();
      this.refreshCellsOnChangeLanguage();
    },
    resized() {
      if (this.gridApi) {
        this.gridApi.sizeColumnsToFit();
      }
    },
    renderNeededDiffs(differences, rowNode) {
      const { gridApi } = this.$store.state.ticketingReports.airportIncome;

      const agGridCustomService = new AgGridCustomService(this.columnDefs); // TODO one time
      const colIds = agGridCustomService.getColIdByMatchAnyfieldsRelated(this.joinAllPropertyPath(differences));

      if (colIds && colIds.length > 0) {
        colIds.forEach((colId) => {
          if (gridApi.getColumnDef(colId)) {
            gridApi.refreshCells({
              rowNodes: [rowNode],
              columns: [colId],
              force: true,
            });

            gridApi.flashCells({
              rowNodes: [rowNode],
              columns: [colId],
              force: true,
            });
          }
        });
      }
    },
    sizeColumnsToFit() {
      if (this.gridApi) {
        this.$store.dispatch(AIRPORT_INCOME_TICKETING_REPORT_FIT_COLUMNS_TO_VIEW);
      }
    },
    firstDataRendered() {
      this.$nextTick(() => {
        this.resized();
      });
    },
    formatNumberCurrency(currency, value) {
      if (currency != null && value != null) {
        return new Intl.NumberFormat(this.$store.getters.getCurrencies.find(p => p.id === currency).locale, { style: 'currency', currency }).format(value);
      }
      return '';
    },
  },
};
</script>
