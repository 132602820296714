/* eslint no-param-reassign: "off" */
import employeeIncome from './employeeIncome';
import airportIncome from './airportIncome';
import tallyingCash from './tallyingCash';

// modules
const storeModules = {
  employeeIncome,
  airportIncome,
  tallyingCash,
};

// initial state
const storeState = {
};

// getters
const storeGetters = {
};

// actions
const storeActions = {
};

// mutations
const storeMutations = {
};

export default {
  modules: storeModules,
  state: storeState,
  getters: storeGetters,
  actions: storeActions,
  mutations: storeMutations,
};
