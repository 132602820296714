<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <entity-configuration-list
      :entity-name="this.$i18n.t('configuration.delayReasons.delayReasons')"
      entity-icon="share_arrival_time"
      :column-defs="columnDefs" :items="delayReasons"
      @confirm-edit-item="editConfirm"
      @confirm-enable-disable-item="enableDisableConfirm"
    >
      <template v-slot:modal-content="{ itemEdit }">
        <delay-reason-edit-card-content :item="itemEdit" />
      </template>
    </entity-configuration-list>
  </div>
</template>

<script>
import {
  GET_DELAY_REASONS,
  CREATE_DELAY_REASON,
  UPDATE_DELAY_REASON,
  DISABLE_DELAY_REASON,
  ENABLE_DELAY_REASON,
} from '../../../store/modules/delayReasons/delayReasonsCatalog';
import EntityConfigurationList from '../common/EntityConfigurationList.vue';
import DelayReasonEditCardContent from './DelayReasonEditCardContent.vue';
import AgGridIconCellRenderer from '../../ag-grid/cell-renderers/AgGridIconCellRenderer.vue';

export default {
  name: 'DelayReasons',
  components: {
    DelayReasonEditCardContent,
    EntityConfigurationList,
    AgGridIconCellRenderer,
  },
  data() {
    const that = this;
    return {
      columnDefs: [
        {
          field: 'reason',
          headerValueGetter() {
            return that.$i18n.t('configuration.delayReasons.delayReasonName');
          },
        },
        {
          field: 'description',
          headerValueGetter() {
            return that.$i18n.t('configuration.description');
          },
        },
        {
          headerValueGetter() {
            return that.$i18n.t('configuration.delayReasons.checkIn');
          },
          field: 'applyToCheckIn',
          cellRendererFramework: AgGridIconCellRenderer,
          cellRendererParams: {
            icon: 'mdi-check',
            tooltip: params => (params.data.applyToCheckIn ? this.$i18n.t('configuration.delayReasons.checkIn') : null),
          },
        },
        {
          headerValueGetter() {
            return that.$i18n.t('configuration.delayReasons.boarding');
          },
          field: 'applyToBoarding',
          cellRendererFramework: AgGridIconCellRenderer,
          cellRendererParams: {
            icon: 'mdi-check',
            tooltip: params => (params.data.applyToBoarding ? this.$i18n.t('configuration.delayReasons.boarding') : null),
          },
        },
        {
          headerValueGetter() {
            return that.$i18n.t('configuration.delayReasons.start');
          },
          field: 'applyToStartTime',
          cellRendererFramework: AgGridIconCellRenderer,
          cellRendererParams: {
            icon: 'mdi-check',
            tooltip: params => (params.data.applyToStartTime ? this.$i18n.t('configuration.delayReasons.start') : null),
          },
        },
        {
          headerValueGetter() {
            return that.$i18n.t('configuration.delayReasons.end');
          },
          field: 'applyToEndTime',
          cellRendererFramework: AgGridIconCellRenderer,
          cellRendererParams: {
            icon: 'mdi-check',
            tooltip: params => (params.data.applyToEndTime ? this.$i18n.t('configuration.delayReasons.end') : null),
          },
        },
        {
          headerValueGetter() {
            return that.$i18n.t('configuration.disabled');
          },
          field: 'deleted',
          cellRendererFramework: AgGridIconCellRenderer,
          cellRendererParams: {
            icon: 'mdi-cancel',
            tooltip: params => (params.data.deleted ? this.$i18n.t('configuration.disabled') : null),
          },
        },
      ],
    };
  },
  computed: {
    delayReasons() {
      return this.$store.getters.getDelayReasons;
    },
  },
  methods: {
    getDelayReasons() {
      this.$store.dispatch(GET_DELAY_REASONS);
    },
    editConfirm(item) {
      this.$store.dispatch(item.id ? UPDATE_DELAY_REASON : CREATE_DELAY_REASON, item);
    },
    enableDisableConfirm(item) {
      this.$store.dispatch(item.deleted ? ENABLE_DELAY_REASON : DISABLE_DELAY_REASON, item.id);
    },
  },
  created() {
    this.getDelayReasons();
  },
};
</script>
