<template>
  <rush-baggage-list-table :items="items" :isFilterDisplayed="this.isFilterDisplayed" @delete-item="deleteItem" />
</template>
<script>
import RushBaggageListTable from './RushBaggageListTable.vue';
import { FIND_RUSHES_BAGGAGE, RUSH_BAGGAGE_FILTER_RESET } from '../../../../store/modules/lostAndFound/rushBaggage';

export default {
  name: 'rush-baggage-list-container',
  components: {
    RushBaggageListTable,
  },
  props: {
    isFilterDisplayed: { type: Boolean, required: false, default: false },
  },
  mounted() {
    this.$store.commit(RUSH_BAGGAGE_FILTER_RESET);
    this.$store.dispatch(FIND_RUSHES_BAGGAGE);
  },
  computed: {
    items() {
      return this.$store.state.rushBaggage.rushesBaggage;
    },
  },
  methods: {
    deleteItem(item) {
      this.$emit('delete-item', item);
    },
  },
};
</script>
<style lang="scss">


</style>
