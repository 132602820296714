/* eslint no-param-reassign: "off" */
import moment from 'moment';
import { HTTP } from '../../http';
import { REQUEST_ERROR, REQUEST_START, REQUEST_SUCCESS } from '../store';
import ConnectedFlightCompositionOperationFactory from '../../components/shared/model/connectedFlightsFactory';
import { createFlightEventFromData } from '../../components/shared/model/flightEvent';
import OperationFilter from '../../components/operations/model/operationFilter';
import { NOTIFICATION_SHOW } from './notifications';
import { FlightBound } from '../../components/shared/model/flightBound';
import i18n from '../../plugins/i18n';
import { ACTION_SET_NO_FLIGHTS_TO_SHOW_MESSAGE } from './noFlightsToShowMessages';

// action
export const OPERATION_SET_GRID_API = 'OPERATION_SET_GRID_API';

export const OPERATION_FIND_FLIGHTS = 'OPERATION_FIND_FLIGHTS';
export const OPERATION_FLIGHTS_UPDATE = 'OPERATION_FLIGHTS_UPDATE';
export const OPERATION_FLIGHTS_WRITE_CHANGES = 'OPERATION_FLIGHTS_WRITE_CHANGES';
export const OPERATION_DO_EXTERNAL_CHANGE = 'OPERATION_DO_EXTERNAL_CHANGE';

export const OPERATION_ARCHIVE = 'OPERATION_ARCHIVE';
export const OPERATION_UNARCHIVE = 'OPERATION_UNARCHIVE';
export const OPERATION_CANCEL_FLIGHTS = 'OPERATION_CANCEL_FLIGHTS';
export const OPERATION_RESTORE_FLIGHT = 'OPERATION_RESTORE_FLIGHT';

export const OPERATION_LINK_FLIGHTS = 'OPERATION_LINK_FLIGHTS';
export const OPERATION_UNLINK_FLIGHTS = 'OPERATION_UNLINK_FLIGHTS';

export const FIT_COLUMNS_TO_VIEW = 'FIT_COLUMNS_TO_VIEW';
export const FIT_COLUMNS_TO_BEST = 'FIT_COLUMNS_TO_BEST';

export const OPERATION_DELETE_AIRCRAFT_GROUND = 'OPERATION_DELETE_AIRCRAFT_GROUND';

// mutations
export const OPERATION_FIND_FLIGHTS_SUCCESS = 'OPERATION_FIND_FLIGHTS_SUCCESS';
export const OPERATION_CHANGE_FLIGHT_SELECTION = 'OPERATION_CHANGE_FLIGHT_SELECTION';
export const OPERATION_ON_EXTERNAL_CHANGE = 'OPERATION_ON_EXTERNAL_CHANGE ';
export const OPERATION_SET_CHANGE = 'OPERATION_SET_CHANGE ';

export const OPERATION_FILTER_RESET = 'OPERATION_FILTER_RESET ';
export const OPERATION_FILTER_RESET_TIMEZONE = 'OPERATION_FILTER_RESET_TIMEZONE ';
export const OPERATION_FILTER_INCREASE_DAY = 'OPERATION_FILTER_INCREASE_DAY ';
export const OPERATION_FILTER_DECREASE_DAY = 'OPERATION_FILTER_DECREASE_DAY ';
export const OPERATION_FILTER_SET_DAY = 'OPERATION_FILTER_SET_DAY ';

export const UPDATE_SHOW_OLD_VALUES = 'UPDATE_SHOW_OLD_VALUES ';
export const UPDATE_FLASH_ON_CHANGES = 'UPDATE_FLASH_ON_CHANGES ';

export const UPDATE_FIT_COLUMNS_ON_RESIZE = 'UPDATE_FIT_COLUMNS_ON_RESIZE ';

// initial state
const storeState = {
  gridApi: null,
  filter: OperationFilter.createEmptyFilter,
  connectedFlightsOperation: [],
  selectedFlights: [],
  flightsChanges: [],
  showOldValues: true,
  flashOnChanges: false,
  fitColumnsOnResize: true,
};

// getters
const storeGetters = {
  hasSelectedOperationFlights: state => state.selectedFlights.length > 0,
  isSelectedOperationFlights: state => id => !!state.selectedFlights.find(s => s.getId() === id),
  getSelectedFlights: state => state.selectedFlights.filter(connectedFlights => connectedFlights.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'id']) != null || (connectedFlights.getChildPropertyValue(FlightBound.INBOUND, ['common', 'id']) != null && connectedFlights.getChildPropertyValue(FlightBound.INBOUND, ['common', 'tripNumber']) != null)),
  getSelectedAircraftGround: state => state.selectedFlights.filter(connectedFlights => connectedFlights.getChildPropertyValue(FlightBound.INBOUND, ['common', 'id']) != null && connectedFlights.getChildPropertyValue(FlightBound.INBOUND, ['common', 'tripNumber']) == null),
};

// actions
const storeActions = {
  [OPERATION_FIND_FLIGHTS]: ({ dispatch, commit, state }) => {
    commit(REQUEST_START);
    commit(OPERATION_CHANGE_FLIGHT_SELECTION, []);
    return HTTP.post('operations/flights/find', state.filter)
      .then((resp) => {
        commit(REQUEST_SUCCESS);
        commit(
          OPERATION_FIND_FLIGHTS_SUCCESS,
          ConnectedFlightCompositionOperationFactory.createConnectedFlightsFromData(resp.data),
        );
        commit(OPERATION_CHANGE_FLIGHT_SELECTION, []);
        dispatch(ACTION_SET_NO_FLIGHTS_TO_SHOW_MESSAGE, resp.status);
      })
      .catch((err) => {
        dispatch(ACTION_SET_NO_FLIGHTS_TO_SHOW_MESSAGE, err.response.status);
        commit(OPERATION_FIND_FLIGHTS_SUCCESS, []);
        commit(REQUEST_ERROR, err);
      });
  },
  [OPERATION_FLIGHTS_UPDATE]: ({ commit }, { connectedFlightsList, field }) => new Promise((resolve, reject) => {
    commit(REQUEST_START);

    HTTP.put('operations/flights', connectedFlightsList)
      .then((response) => {
        commit(REQUEST_SUCCESS);
        commit(NOTIFICATION_SHOW, {
          message: field ? `${i18n.t('notifications.flightFieldUpdate')} ${i18n.t(field)}` : i18n.t('notifications.flightUpdate'),
          color: 'success',
        });
        resolve(response);
      })
      .catch((err) => {
        commit(REQUEST_ERROR, err);
        reject(err);
      });
  }),
  [OPERATION_FLIGHTS_WRITE_CHANGES]: ({ commit }, { flightChanges, field }) => new Promise((resolve, reject) => {
    commit(REQUEST_START);
    HTTP.put('operations/flights/write/changes', flightChanges)
      .then((response) => {
        commit(REQUEST_SUCCESS);
        commit(NOTIFICATION_SHOW, {
          message: field ? `${i18n.t('notifications.flightFieldUpdate')} ${i18n.t(field)}` : i18n.t('notifications.flightUpdate'),
          color: 'success',
        });
        resolve(response);
      })
      .catch((err) => {
        commit(REQUEST_ERROR, err);
        reject(err);
      });
  }),
  [OPERATION_DO_EXTERNAL_CHANGE]: ({ commit }, change) => {
    commit(OPERATION_ON_EXTERNAL_CHANGE, createFlightEventFromData(change));
  },
  [OPERATION_ARCHIVE]: ({ commit, dispatch, getters }) => {
    commit(REQUEST_START);
    return HTTP.post('operations/flights/archive', getters.getSelectedFlights)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.flightArchive'),
          color: 'success',
        });
        dispatch(OPERATION_FIND_FLIGHTS);
      })
      .catch((err) => {
        commit(REQUEST_ERROR, err);
      });
  },
  [OPERATION_UNARCHIVE]: ({ commit, dispatch, getters }) => {
    commit(REQUEST_START);
    return HTTP.post('operations/flights/unarchive', getters.getSelectedFlights)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.flightUnarchive'),
          color: 'success',
        });
        dispatch(OPERATION_FIND_FLIGHTS);
      })
      .catch((err) => {
        commit(REQUEST_ERROR, err);
      });
  },
  [OPERATION_CANCEL_FLIGHTS]: ({ commit, dispatch }, flightCancellation) => {
    commit(REQUEST_START);
    return HTTP.put('operations/flights/cancel', flightCancellation)
      .then(() => {
        commit(REQUEST_SUCCESS);
        dispatch(OPERATION_FIND_FLIGHTS);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.flightCancel'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
  [OPERATION_RESTORE_FLIGHT]: ({ commit, dispatch }, flightId) => {
    commit(REQUEST_START);
    return HTTP.put(`flights/${flightId}/timeAndCancellation/restore`)
      .then(() => {
        commit(REQUEST_SUCCESS);
        dispatch(OPERATION_FIND_FLIGHTS);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.flightRestore'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
  [FIT_COLUMNS_TO_VIEW]: ({ state }) => {
    if (state.gridApi) {
      state.gridApi.sizeColumnsToFit();
    }
  },
  [FIT_COLUMNS_TO_BEST]: ({ state }) => {
    if (state.gridApi && state.gridApi.columnController && state.gridApi.columnController.columnApi) {
      state.gridApi.columnController.columnApi.autoSizeAllColumns();
    }
  },
  [OPERATION_DELETE_AIRCRAFT_GROUND]: ({ commit, dispatch }, ids) => {
    commit(REQUEST_START);
    return HTTP.post('aircraft-ground/batch-delete', ids)
      .then(() => {
        commit(REQUEST_SUCCESS);
        dispatch(OPERATION_FIND_FLIGHTS);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.aircraftGroundDelete'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
  [OPERATION_LINK_FLIGHTS]: ({ commit, dispatch }, flights) => {
    commit(REQUEST_START);
    HTTP.post('scheduling/link', flights)
      .then(() => {
        commit(REQUEST_SUCCESS);
        dispatch(OPERATION_FIND_FLIGHTS);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.flightsLinkSuccess'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(REQUEST_ERROR, err);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
      });
  },
  [OPERATION_UNLINK_FLIGHTS]: ({ commit, dispatch }, flights) => {
    commit(REQUEST_START);
    HTTP.post('scheduling/unlink', flights)
      .then(() => {
        commit(REQUEST_SUCCESS);
        dispatch(OPERATION_FIND_FLIGHTS);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.flightsUnlinkSuccess'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(REQUEST_ERROR, err);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
      });
  },
};

// mutations
const storeMutations = {
  [OPERATION_SET_GRID_API]: (state, gridApi) => {
    state.gridApi = gridApi.api;
  },
  [OPERATION_FIND_FLIGHTS_SUCCESS]: (state, connectedFlightsOperation) => {
    state.connectedFlightsOperation = connectedFlightsOperation;
  },
  [OPERATION_CHANGE_FLIGHT_SELECTION]: (state, selection) => {
    state.selectedFlights = selection;
  },
  [OPERATION_ON_EXTERNAL_CHANGE]: (state, change) => {
    console.log(`mutations external change ${change}`); // eslint-disable-line no-console
  },
  [OPERATION_SET_CHANGE]: (state, change) => {
    change.ts = moment();
    if (change.diff.path.includes('inbound')) {
      change.flightNumber = change.connectedFlights.inbound.common.flightNo();
      change.bound = 'inbound';
    }
    if (change.diff.path.includes('outbound')) {
      change.flightNumber = change.connectedFlights.outbound.common.flightNo();
      change.bound = 'outbound';
    }
    state.flightsChanges.unshift(change);
    state.flightsChanges = state.flightsChanges.slice(0, 10);
  },
  [OPERATION_FILTER_RESET]: (state) => {
    state.filter.dateFrom = moment().startOf('day');
    state.filter.dateTo = moment().endOf('day');
    state.filter.timezoneDate = moment.defaultZone.name;
  },
  [OPERATION_FILTER_RESET_TIMEZONE]: (state) => {
    state.filter.dateFrom = moment().startOf('day')
      .year(state.filter.dateFrom.year())
      .month(state.filter.dateFrom.month())
      .date(state.filter.dateFrom.date());
    state.filter.dateTo = moment().endOf('day')
      .year(state.filter.dateTo.year())
      .month(state.filter.dateTo.month())
      .date(state.filter.dateTo.date());

    state.filter.timezoneDate = moment.defaultZone.name;
  },
  [OPERATION_FILTER_INCREASE_DAY]: (state) => {
    state.filter.dateFrom = moment().startOf('day')
      .year(state.filter.dateFrom.year())
      .month(state.filter.dateFrom.month())
      .date(state.filter.dateFrom.date())
      .add(1, 'days')
      .startOf('day');

    state.filter.dateTo = moment().endOf('day')
      .year(state.filter.dateTo.year())
      .month(state.filter.dateTo.month())
      .date(state.filter.dateTo.date())
      .add(1, 'days');
  },
  [OPERATION_FILTER_DECREASE_DAY]: (state) => {
    state.filter.dateFrom = moment().startOf('day')
      .year(state.filter.dateFrom.year())
      .month(state.filter.dateFrom.month())
      .date(state.filter.dateFrom.date())
      .subtract(1, 'days');

    state.filter.dateTo = moment().endOf('day')
      .year(state.filter.dateTo.year())
      .month(state.filter.dateTo.month())
      .date(state.filter.dateTo.date())
      .subtract(1, 'days')
      .endOf('day');
  },
  [OPERATION_FILTER_SET_DAY]: (state, dateSelected) => {
    state.filter.dateFrom = moment(dateSelected).startOf('day');

    state.filter.dateTo = moment(dateSelected).endOf('day');
  },
  [UPDATE_SHOW_OLD_VALUES]: (state, showOldValues) => {
    state.showOldValues = showOldValues;
  },
  [UPDATE_FLASH_ON_CHANGES]: (state, flashOnChanges) => {
    state.flashOnChanges = flashOnChanges;
  },
  [UPDATE_FIT_COLUMNS_ON_RESIZE]: (state, fitColumnsOnResize) => {
    state.fitColumnsOnResize = fitColumnsOnResize;
  },
};

export default {
  state: storeState,
  getters: storeGetters,
  actions: storeActions,
  mutations: storeMutations,
};
