/* eslint no-param-reassign: "off" */
/* eslint no-confusing-arrow: "off" */
/* eslint no-nested-ternary: "off" */

import { REQUEST_ERROR, REQUEST_START, REQUEST_SUCCESS } from '../../store';
import { HTTP } from '../../../http';
import { WARNING_COLOR, NOTIFICATION_SHOW } from '../notifications';
import i18n from '../../../plugins/i18n';

// actions names
export const GET_DELAY_TYPES = 'GET_DELAY_TYPES';
export const DELAY_TYPE_DELETE = 'DELAY_TYPE_DELETE';
export const DELAY_TYPE_UPDATE = 'DELAY_TYPE_UPDATE';
export const DELAY_TYPE_CREATE = 'DELAY_TYPE_CREATE';
export const DELAY_TYPE_IMPORT = 'DELAY_TYPE_IMPORT';
export const DELAY_TYPE_ENABLE = 'DELAY_TYPE_ENABLE';
export const DELAY_TYPE_DISABLE = 'DELAY_TYPE_DISABLE';

// mutations
export const CHANGE_DELAY_TYPES_SELECTION = 'CHANGE_DELAY_TYPES_SELECTION';
export const GET_DELAY_TYPES_SUCCESS = 'GET_DELAY_TYPES_SUCCESS';
export const GET_DELAY_TYPES_REQUESTED = 'GET_DELAY_TYPES_REQUESTED';
export const UPDATE_DELAY_TYPES_SUCCESS = 'UPDATE_DELAY_TYPES_SUCCESS';
export const BUILD_NOTIFICATION_SHOW = 'BUILD_NOTIFICATION_SHOW';
export const CLEAR_NOTIFICATION_SHOW = 'CLEAR_NOTIFICATION_SHOW';


// initial state
const storeState = {
  delayTypes: [],
  delayTypesRequested: false,
  selectedDelayTypes: [],
  notification: {},
};

// getters
const storeGetters = {
  getDelayTypes: state => state.delayTypes.sort((a, b) => (a.code > b.code) ? 1 : ((b.code > a.code) ? -1 : 0)),
  getActiveDelayTypes: state => state.delayTypes.filter(elem => !elem.deleted).sort((a, b) => (a.code > b.code) ? 1 : ((b.code > a.code) ? -1 : 0)),
  getDelayTypeSelected: state => state.selectedDelayType,
  hasSelectedDelayType: state => state.selectedDelayType.length > 0,
};

// actions
const storeActions = {
  [GET_DELAY_TYPES]: ({ commit, state }) => {
    if (state.delayTypesRequested) {
      return;
    }
    commit(REQUEST_START);
    commit(GET_DELAY_TYPES_REQUESTED);
    HTTP.get('delay-types')
      .then((resp) => {
        commit(REQUEST_SUCCESS);
        commit(GET_DELAY_TYPES_SUCCESS, resp.data);
      })
      .catch((err) => {
        commit(REQUEST_ERROR, err);
      });
  },
  [DELAY_TYPE_DELETE]: ({ commit, dispatch }, id) => {
    commit(REQUEST_START);
    return HTTP.delete(`delay-types/${id}`)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(UPDATE_DELAY_TYPES_SUCCESS);
        dispatch(GET_DELAY_TYPES);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.delayTypesDelete'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
  [DELAY_TYPE_UPDATE]: ({ commit, dispatch }, delayType) => {
    commit(REQUEST_START);
    return HTTP.put('delay-types', delayType)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(UPDATE_DELAY_TYPES_SUCCESS);
        dispatch(GET_DELAY_TYPES);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.delayTypesUpdate'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
  [DELAY_TYPE_CREATE]: ({ commit, dispatch }, delayType) => {
    commit(REQUEST_START);
    return HTTP.post('delay-types', delayType)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(UPDATE_DELAY_TYPES_SUCCESS);
        dispatch(GET_DELAY_TYPES);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.delayTypesCreate'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
  [DELAY_TYPE_IMPORT]: ({ state, commit, dispatch }, delayType) => {
    commit(REQUEST_START);
    return HTTP.post('/import/delay-types', delayType)
      .then((resp) => {
        commit(REQUEST_SUCCESS);
        commit(UPDATE_DELAY_TYPES_SUCCESS);
        dispatch(GET_DELAY_TYPES);
        commit(BUILD_NOTIFICATION_SHOW, resp);
        commit(NOTIFICATION_SHOW, state.notification);
        commit(CLEAR_NOTIFICATION_SHOW);
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
  [DELAY_TYPE_DISABLE]: ({ commit, dispatch }, id) => {
    commit(REQUEST_START);
    return HTTP.patch(`/delay-types/disable/${id}`)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(UPDATE_DELAY_TYPES_SUCCESS);
        dispatch(GET_DELAY_TYPES);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.delayTypesDisable'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
  [DELAY_TYPE_ENABLE]: ({ commit, dispatch }, id) => {
    commit(REQUEST_START);
    return HTTP.patch(`/delay-types/enable/${id}`)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(UPDATE_DELAY_TYPES_SUCCESS);
        dispatch(GET_DELAY_TYPES);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.delayTypesEnable'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
};

// mutations
const storeMutations = {
  [GET_DELAY_TYPES_SUCCESS]: (state, delayTypes) => {
    state.delayTypes = delayTypes;
  },
  [GET_DELAY_TYPES_REQUESTED]: (state) => {
    state.delayTypesRequested = true;
  },
  [UPDATE_DELAY_TYPES_SUCCESS]: (state) => {
    state.delayTypesRequested = false;
  },
  [CHANGE_DELAY_TYPES_SELECTION]: (state, selection) => {
    state.selectedDelayType = selection;
  },
  [BUILD_NOTIFICATION_SHOW]: (state, response) => {
    const notificationColor = (status) => {
      switch (status) {
        default:
          return 'success';
        case 202:
          return WARNING_COLOR;
      }
    };
    const notificationMessage = (status) => {
      switch (status) {
        default:
          return i18n.t('notifications.delayTypesCreate');
        case 202:
          return i18n.t('notifications.someDelayTypesCreate');
      }
    };
    state.notification = {
      message: notificationMessage(response.status),
      color: notificationColor(response.status),
    };
  },
  [CLEAR_NOTIFICATION_SHOW]: (state) => {
    state.notification = {};
  },
};

export default {
  state: storeState,
  getters: storeGetters,
  actions: storeActions,
  mutations: storeMutations,
};
