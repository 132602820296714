<template>
  <v-container v-if="!isEmptyRemarks()" class="infoMobileRemarks">
    <v-card :dark="this.$vuetify.dark">
      <v-card-text>
        <div class="font-weight-bold ms-2 mb-2">
          {{ $t('operations.remarksExtended') }}
        </div>
        <v-item-group>
          <v-item v-if="flightMobileRemarks.baggageYardRemarks">
            <div class="mb-0">
              <div class="font-weight-normal">
                <strong>{{ $t('operations.remarksModalBaggageYardShort') }}</strong>
              </div>
              <div>{{ flightMobileRemarks.baggageYardRemarks }}</div>
            </div>
          </v-item>
          <v-item v-if="flightMobileRemarks.loadRemarks">
            <div class="mb-0">
              <div class="font-weight-normal">
                <strong>{{ $t('operations.remarksModalLoadShort') }}</strong>
              </div>
              <div>{{ flightMobileRemarks.loadRemarks }}</div>
            </div>
          </v-item>
          <v-item v-if="flightMobileRemarks.operationRemarks">
            <div class="mb-0">
              <div class="font-weight-normal">
                <strong>{{ $t('operations.remarksModalOperationsShort') }}</strong>
              </div>
              <div>{{ flightMobileRemarks.operationRemarks }}</div>
            </div>
          </v-item>
          <v-item v-if="flightMobileRemarks.passageRemarks">
            <div class="mb-0">
              <div class="font-weight-normal">
                <strong>{{ $t('operations.remarksModalPassengerShort') }}</strong>
              </div>
              <div>{{ flightMobileRemarks.passageRemarks }}</div>
            </div>
          </v-item>
          <v-item v-if="flightMobileRemarks.rampRemarks">
            <div class="mb-0">
              <div class="font-weight-normal">
                <strong>{{ $t('operations.remarksModalRampShort') }}</strong>
              </div>
              <div>{{ flightMobileRemarks.rampRemarks }}</div>
            </div>
          </v-item>
        </v-item-group>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>

import FlightStatus from '../flights/FlightStatus.vue';
import { MOBILE_GET_FLIGHT_REMARKS } from '../../store/modules/mobile';

export default {
  name: 'info-mobile-remarks',
  props: {
    flightId: {
      required: true,
    },
  },
  components: {
    FlightStatus,
  },
  methods: {
    formattedTime(minutes) {
      if (minutes) {
        const time = new Date(minutes * 60000);
        return this.$moment(time).format('HH:mm');
      }
      return '';
    },
    isEmptyRemarks() {
      let empty = true;
      if (this.flightMobileRemarks.baggageYardRemarks) {
        empty = false;
      }
      if (this.flightMobileRemarks.loadRemarks) {
        empty = false;
      }
      if (this.flightMobileRemarks.operationRemarks) {
        empty = false;
      }
      if (this.flightMobileRemarks.passageRemarks) {
        empty = false;
      }
      if (this.flightMobileRemarks.rampRemarks) {
        empty = false;
      }
      return empty;
    },
  },
  computed: {
    flightMobileRemarks() {
      return this.$store.state.mobile.flightMobileRemarks;
    },
  },
  watch: {
    flightId() {
      if (this.flightId) {
        this.$store.dispatch(MOBILE_GET_FLIGHT_REMARKS, this.flightId);
      }
    },
  },
  created() {
    if (this.flightId) {
      this.$store.dispatch(MOBILE_GET_FLIGHT_REMARKS, this.flightId);
    }
  },
};
</script>
<style>
.infoMobileRemarks {
  font-size: 16px;
}
</style>
