<template>
  <div>
    <aircraft-select :ref="'refComp'" v-on:keydown.native="keyEvent" v-char-length.clearable="7" v-model="aircraftIdUpdated"></aircraft-select>
  </div>
</template>

<script>
import Vue from 'vue';
import AircraftSelect from '../../../aircraft/AircraftSelect.vue';
import AgGridParentConnectedFlightsCellEditor from '../mixins/AgGridParentConnectedFlightsCellEditor.vue';

export default Vue.extend({
  mixins: [AgGridParentConnectedFlightsCellEditor],
  components: {
    AircraftSelect,
  },
  data() {
    return {
      aircraftIdOriginal: null,
      aircraftTypeIdOriginal: null,
      aircraftIdUpdated: null,
    };
  },
  methods: {
    getValue() {
      this.unmarkCellsSelectedAsEdit();

      if (this.aircraftIdOriginal !== this.aircraftIdUpdated) {
        const aircraft = this.$store.getters.getAircraftById(this.aircraftIdUpdated);
        const registerNumber = typeof (aircraft) !== 'undefined' ? aircraft.registerNumber : undefined;
        const typeIdAircraft = typeof (aircraft) !== 'undefined' ? aircraft.typeId : undefined;
        const typeIataCodeAircraft = typeof (aircraft) !== 'undefined' ? aircraft.typeIataCode : undefined;
        const typeIcaoCodeAircraft = typeof (aircraft) !== 'undefined' ? aircraft.typeIcaoCode : undefined;

        const that = this;

        const valueCell = [
          { id: 'common.aircraftId', value: this.aircraftIdUpdated, initialValue: this.aircraftIdOriginal },
          { id: 'common.aircraftTypeId', value: typeIdAircraft, initialValue: this.aircraftTypeIdOriginal },
        ];
        this.saveFlightChangesFromCellValue((s) => {
          s.setChildPropertyValueByBound(that.params.column.colDef.colCustomConfig.flightBound, 'common.aircraftId', that.aircraftIdUpdated);
          s.setChildPropertyValueByBound(that.params.column.colDef.colCustomConfig.flightBound, 'common.aircraftRegisterNumber', registerNumber);
          s.setChildPropertyValueByBound(that.params.column.colDef.colCustomConfig.flightBound, 'common.aircraftTypeId', typeIdAircraft);
          s.setChildPropertyValueByBound(that.params.column.colDef.colCustomConfig.flightBound, 'common.aircraftTypeIataCode', typeIataCodeAircraft);
          s.setChildPropertyValueByBound(that.params.column.colDef.colCustomConfig.flightBound, 'common.aircraftTypeIcaoCode', typeIcaoCodeAircraft);
        }, 'Aircraft', valueCell, () => {
          this.params.api.refreshCells({
            force: true,
          });
        });

        return aircraft;
      }
      return this.params.node.data;
    },
  },
  created() {
    const connectedFlights = this.params.node.data.clone();
    this.aircraftIdOriginal = connectedFlights.getSafeValue(['common', 'aircraftId']);
    this.aircraftTypeIdOriginal = connectedFlights.getSafeValue(['common', 'aircraftTypeId']);
    this.aircraftIdUpdated = this.aircraftIdOriginal;

    this.markCellsSelectedAsEdit();
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$refs.refComp) {
        this.$refs.refComp.$refs.refComp.focus();
      }
    });
  },
});
</script>

<style>
.aircraft-cell.ag-cell-inline-editing {
  min-width: 130px;
}

</style>
