<template>
  <div class="d-flex justify-center">
    <role-permission-select
    :ref="'refComp'"
    v-on:keydown.native="keyEvent"
    v-char-length.clearable="4"
    :value="getValue()"
    @input="changeAuthorities($event)"
    ></role-permission-select>
  </div>
</template>

<script>
import Vue from 'vue';
import AgGridParentCellEditor from './mixins/AgGridParentCellEditor.vue';
import RolePermissionSelect from '../../authority/RolePermissionSelect.vue';
import { ROLE_NONE, ROLE_READ, ROLE_WRITE } from '../../../constants/roles';

export default Vue.extend({
  mixins: [AgGridParentCellEditor],
  components: {
    RolePermissionSelect,
  },
  methods: {
    getValue() {
      return this.params.value;
    },
    changeAuthorities(event) {
      const readRole = this.params.itemAuthorities.find(element => element.name === this.params.node.data.rol);
      const writeRole = this.params.itemAuthorities.find(element => element.name === `${this.params.node.data.rol}_WR`);
      if (event === ROLE_WRITE) {
        this.params.itemAuthorities.push({ name: `${this.params.node.data.rol}_WR`, displayName: `${this.params.node.data.rol}_WR` });
        if (!readRole) {
          this.params.itemAuthorities.push({ name: this.params.node.data.rol, displayName: this.params.node.data.rol });
        }
      } else if (event === ROLE_READ) {
        if (!readRole) {
          this.params.itemAuthorities.push({ name: this.params.node.data.rol, displayName: this.params.node.data.rol });
        }
        if (writeRole) {
          this.params.itemAuthorities.splice(this.params.itemAuthorities.findIndex(element => element.name === writeRole.name), 1);
        }
      } else if (event === ROLE_NONE) {
        if (readRole) {
          this.params.itemAuthorities.splice(this.params.itemAuthorities.findIndex(element => element.name === readRole.name), 1);
        }
        if (writeRole) {
          this.params.itemAuthorities.splice(this.params.itemAuthorities.findIndex(element => element.name === writeRole.name), 1);
        }
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$refs.refComp) {
        this.$refs.refComp.$refs.refComp.focus();
      }
    });
  },
});
</script>

<style>

</style>
