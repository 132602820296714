<template>
  <div>
    <v-autocomplete placeholder="..." :value="selectedValue" :label="label" :items="orientations" :clearable="true"
      item-text="id" :rules="rules" :disabled="disabled" @input="emitInput" :menu-props="{ zIndex: '1000' }"
      :ref="refComp">
      <template v-slot:item="{ item }">
        <div class="orientation-select-input">
          <span>{{ item.text }}</span>
          <span v-if="item.icon">
            <v-icon>{{ item.icon }}</v-icon>
          </span>
          <span v-if="item.description && item.description !== '' && item.description !== item.text"
            class="description">{{ item.description }}</span>
        </div>
      </template>
      <template v-slot:selection="{ item }">
        <div class="orientation-select-input">
          <span>{{ item.text }}</span>
          <span v-if="item.icon">
            <v-icon>{{ item.icon }}</v-icon>
          </span>
        </div>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import { OrientationTypes } from './orientationType';
import { FlightBound } from '../shared/model/flightBound';
import OrientationConverter from './OrientationConverter.vue';

export default {
  mixins: [OrientationConverter],
  props: {
    value: { required: true },
    label: { type: String },
    rules: { type: Array },
    disabled: { type: Boolean, default: false },
    useFlightBoundInsteadOfOrientationType: { type: Boolean, default: false },
    refComp: {
      type: String,
      required: false,
      default: 'refComp',
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        this.selectedValue = newValue;
      },
    },
  },
  data() {
    return {
      selectedValue: null,
    };
  },
  computed: {
    orientations() {
      if (this.useFlightBoundInsteadOfOrientationType && this.useFlightBoundInsteadOfOrientationType === true) {
        return this.getFlightBounds();
      }
      return this.getOrientationTypes();
    },

  },
  methods: {
    emitInput(value) {
      if (value !== undefined) {
        this.$emit('input', value);
      } else {
        this.$emit('input', null);
      }
    },
    getOrientationTypes() {
      return [
        {
          id: OrientationTypes.ARRIVAL,
          text: this.$i18n.t('common.arrival'),
          icon: 'flight_land',
        },
        {
          id: OrientationTypes.GROUND,
          text: this.$i18n.t('common.ground'),
          icon: 'swap_horiz',
        },
        {
          id: OrientationTypes.DEPARTURE,
          text: this.$i18n.t('common.departure'),
          icon: 'flight_takeoff',
        },
      ];
    },
    getFlightBounds() {
      return [
        {
          id: FlightBound.INBOUND,
          text: this.$i18n.t('common.inbound'),
          icon: 'flight_land',
        },
        {
          id: FlightBound.ANY,
          text: this.$i18n.t('common.both'),
          icon: 'swap_horiz',
        },
        {
          id: FlightBound.OUTBOUND,
          text: this.$i18n.t('common.outbound'),
          icon: 'flight_takeoff',
        },
      ];
    },
  },
};
</script>
<style>
.orientation-select-input .v-select__selections {
  font-size: 24px !important;
  padding: 2px 10px;
}

.v-select-list .v-list__tile {
  font-size: 20px;
  font-weight: 400;
  height: 50px;
  margin: 0;
  padding: 0 16px;
}

.orientation-select-input .description {
  color: grey;
  display: block;
  margin-top: -9px;
  margin-left: 10px;
}

.orientation-select-input i.v-icon.material-icons {
  font-size: 16px;
  vertical-align: super;
}

.orientation-select-input .v-select .v-select__selections {
  color: rgba(0, 0, 0, 0.87);
  background: #eee;
  min-width: 90px;
}

.theme--dark .orientation-select-input .v-select .v-select__selections {
  color: rgba(255, 255, 255, 0.87);
  background: #333333;
}

.orientation-select-input .v-select__selection--comma {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin: 0;
  width: 100%;
  padding: 0 10px;
}
</style>
