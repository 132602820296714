<template>
  <div>
    <v-tooltip bottom v-for="(airline, index) in this.params.value" :key="index">
      <template slot="activator">
        <v-chip>{{airline}}</v-chip>
      </template>
      <span>{{getTooltipAirline(airline)}}</span>
    </v-tooltip>
  </div>
</template>

<script>
import Vue from 'vue';
import { GET_AIRLINE } from '../../../store/modules/airline/airlineCatalog';

export default Vue.extend({
  computed: {
    airlines() {
      return this.$store.getters.getAirlines;
    },
  },
  methods: {
    getTooltipAirline(airline) {
      if (airline === 'ALL') {
        return 'All airlines';
      }
      return this.airlines.filter(a => a.code === airline).map(air => (air.name || air.name.length > 0 ? air.name : air.code)).shift();
    },
  },
  mounted() {
    this.$store.dispatch(GET_AIRLINE);
  },
});
</script>

<style scoped>

</style>
