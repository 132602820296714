<template>
  <div>
    <entity-configuration-list
      :entity-name="this.$i18n.t('configuration.airportCarousel')"
      entity-icon="video_label"
      :framework-components="frameworkComponents" :column-defs="columnDefs" :items="airportCarousels"
      @confirm-edit-item="editConfirm"
      @confirm-delete-item="deleteConfirm"
      @confirm-enable-disable-item="enableDisableConfirm"
    >
      <template v-slot:modal-content="{ itemEdit }">
        <airport-carousel-edit-card-content :item="itemEdit" />
      </template>
    </entity-configuration-list>
  </div>
</template>
<script>
import {
  AIRPORT_CAROUSEL_CREATE,
  AIRPORT_CAROUSEL_DELETE,
  AIRPORT_CAROUSEL_UPDATE,
  GET_AIRPORT_CAROUSEL,
  AIRPORT_CAROUSEL_ENABLE,
  AIRPORT_CAROUSEL_DISABLE,
} from '@/store/modules/airportCarousel/airportCarouselCatalog';
import EntityConfigurationList from '@/components/configuration/common/EntityConfigurationList.vue';
import AirportCarouselEditCardContent from './AirportCarouselEditCardContent.vue';
import AgGridRowActionButtonsCellRenderer from '../../ag-grid/cell-renderers/AgGridRowActionButtonsCellRenderer.vue';
import AgGridIconCellRenderer from '../../ag-grid/cell-renderers/AgGridIconCellRenderer.vue';


export default {
  name: 'AirportCarouselList',
  components: {
    EntityConfigurationList,
    AirportCarouselEditCardContent,
  },
  data() {
    const that = this;
    return {
      frameworkComponents: {
        AgGridRowActionButtonsCellRenderer,
        AgGridIconCellRenderer,
      },
      columnDefs: [
        {
          field: 'code',
          headerValueGetter() {
            return that.$i18n.t('configuration.code');
          },
        },
        {
          field: 'codeInterface',
          headerValueGetter() {
            return that.$i18n.t('configuration.codeInterface');
          },
        },
        {
          field: 'comments',
          headerValueGetter() {
            return that.$i18n.t('configuration.description');
          },
        },
        {
          headerValueGetter() {
            return that.$i18n.t('configuration.disabled');
          },
          field: 'deleted',
          cellRendererFramework: AgGridIconCellRenderer,
          cellRendererParams: {
            icon: 'mdi-cancel',
            tooltip: params => (params.data.deleted ? this.$i18n.t('configuration.disabled') : null),
          },
        },
      ],
    };
  },
  computed: {
    airportCarousels() {
      return this.$store.getters.getAirportCarousels;
    },
  },
  methods: {
    getAirportCarousels() {
      this.$store.dispatch(GET_AIRPORT_CAROUSEL);
    },
    deleteConfirm(item) {
      this.$store.dispatch(AIRPORT_CAROUSEL_DELETE, item.id);
    },
    editConfirm(item) {
      this.$store.dispatch(item.id ? AIRPORT_CAROUSEL_UPDATE : AIRPORT_CAROUSEL_CREATE, item);
    },
    enableDisableConfirm(item) {
      this.$store.dispatch(item.deleted ? AIRPORT_CAROUSEL_ENABLE : AIRPORT_CAROUSEL_DISABLE, item.id);
    },
  },
  created() {
    this.getAirportCarousels();
  },
};
</script>
<style>
</style>
