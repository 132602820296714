<template>
  <div :style="`width: 100%; height: calc(100vh - ${this.sizeFilter});`" class="TowingAdhocTaskListTable">
    <ag-grid-vue
      class="ag-theme-material connected-flights-table operational-table"
      :columnDefs="columnDefs"
      :defaultColDef="defaultColDef"
      :rowData="items"
      :rowSelection="'simple'"
      :suppressRowClickSelection="true"
      :rowMultiSelectWithClick="false"
      @grid-ready="onGridReady"
      @first-data-rendered="firstDataRendered"
      @selection-changed="onSelectionChanged"
      :frameworkComponents="frameworkComponents"
      :gridOptions="gridOptions"
      :multiSortKey="multiSortKey"
      :isRowSelectable="isRowSelectable"
      :overlayNoRowsTemplate="customNoRowsOverlay"
      :overlayLoadingTemplate="customLoadingOverlay"
      v-bind:style="`width: 100%; height: calc(100vh - ${this.sizeFilter});`">
    </ag-grid-vue>
  </div>
</template>

<script>
import { AgGridVue } from 'ag-grid-vue';
import AgGridConnectedFlights from '../../ag-grid/mixins/AgGridConnectedFlights.vue';
import FlightsDifferences from '../../mixins/FlightsDifferences.vue';

import { FlightBound } from '../../shared/model/flightBound';
import { cellClassAgGridRules } from '../../ag-grid/mixins/cellClassAgGridRules';
import AgGridIconCellRenderer from '../../ag-grid/cell-renderers/AgGridIconCellRenderer.vue';
import AgGridRowTowingAdhocTaskActionButtonsCellRenderer from '../../ag-grid/cell-renderers/AgGridRowTowingAdhocTaskActionButtonsCellRenderer.vue';
import { FisModule } from '../../ag-grid/cell-editors/model/fisModule';
import agGridTextCustomFilterComparator from '../../ag-grid/filters/agGridTextCustomFilterComparator';
import agGridTextCustomFilterFormatter from '../../ag-grid/filters/agGridTextCustomFilterFormatter';
import agGridDateTimeFormatter from '../../ag-grid/formatters/agGridDateTimeFormatter';
import AgGridFlightStatusCellRenderer from '../../ag-grid/cell-renderers/AgGridFlightStatusCellRenderer.vue';
import AgGridValueWithBackgroundCellRenderer
from '../../ag-grid/cell-renderers/AgGridValueWithBackgroundCellRenderer.vue';
import OperationConverter from '../../rampService/OrientationConverter.vue';
import RolePermissions from '../../mixins/RolePermissions.vue';
import {
  FIT_COLUMNS_TO_VIEW,
  TOWING_ADHOC_TASK_CHANGE_SELECTION,
  TOWING_ADHOC_TASK_SET_GRID_API,
} from '../../../store/modules/towingAdhocTask/towingAdhocTask';
import AgGridNoRowsOverlay from '../../ag-grid/mixins/AgGridNoRowsOverlay.vue';

export default {
  name: 'towing-adhoc-task-flight-list-table',
  mixins: [AgGridConnectedFlights, FlightsDifferences, RolePermissions, OperationConverter, AgGridNoRowsOverlay],
  components: {
    AgGridVue,
  },
  props: {
    items: { type: Array, required: true },
    isFilterDisplayed: { type: Boolean, required: false, default: false },
  },
  computed: {
    sizeFilter() {
      return this.isFilterDisplayed ? '250px' : '180px';
    },
    fitColumnsOnResize() {
      return this.$store.state.towingAdhocTask.fitColumnsOnResize;
    },
    selectedRows() {
      return this.$store.getters.getSelectedTowingAdhocTasks;
    },
    selectedRowsFlights() {
      return this.$store.getters.getSelectedFlightTowingAdhocTaskFlights;
    },
    selectedRowsAircraftGround() {
      return this.$store.getters.getSelectedFlightTowingAdhocTaskAircraftGround;
    },
    dateFilter() {
      return this.$store.state.flightTowingAdhocTask.filter.dateFrom;
    },
  },
  data() {
    const that = this;
    const rolePermission = this.getRoles();
    return {
      dialog: false,
      gridOptions: {
        onFilterChanged: this.uncheckAllOnFilter,
        getRowNodeId: this.getRowNodeId,
        getRowClass: this.getRowClass,
        floatingFilter: true,
        groupHeaderHeight: 35,
        headerHeight: 35,
        floatingFiltersHeight: 20,
        rowHeight: 30,
        context: {
          module: FisModule.TOWING_ADHOC_TASK,
        },
      },
      isRowSelectable(rowNode) {
        if (that.selectedRowsFlights.length > 0 && that.selectedRowsAircraftGround.length === 0) {
          return !that.isAircraftGround(rowNode.data);
        }
        if (that.selectedRowsFlights.length === 0 && that.selectedRowsAircraftGround.length > 0) {
          return that.isAircraftGround(rowNode.data);
        }
        return true;
      },
      multiSortKey: 'ctrl',
      frameworkComponents: {
      },
      defaultColDef: {
        resizable: true,
        sortable: true,
        minWidth: 30,
        suppressMenu: true,
        filter: 'agTextColumnFilter',
        filterParams: {
          textCustomComparator: agGridTextCustomFilterComparator,
          textFormatter: agGridTextCustomFilterFormatter,
        },
        colCustomConfig: {
          flightBound: FlightBound.ANY,
        },
        cellClassRules: cellClassAgGridRules,
        editable: false,
      },
      columnDefs: [
        {
          headerTooltip: 'Check/uncheck all',
          hide: !this.hasPermissions(rolePermission),
          checkboxSelection: true,
          width: 21,
          minWidth: 20,
          headerCheckboxSelection: false,
          headerCheckboxSelectionFilteredOnly: false,
          suppressMenu: true,
          sortable: false,
          suppressMovable: true,
          suppressSizeToFit: true,
          filter: false,
          resizable: false,
          colCustomConfig: {
            exportCsvDisabled: true,
          },
          editable: this.hasPermissions(rolePermission),
        },
        {
          headerName: 'SortDate',
          field: 'sortingTime',
          hide: true,
          sort: 'asc',
        },
        {
          colId: 'flightDate',
          headerValueGetter() {
            return that.$i18n.t('common.date');
          },
          headerTooltip: (() => this.$i18n.t('operations.aircraftType'))(),
          type: 'date',
          comparator: this.objectWithValueComparator,
          field: 'flightDate',
          valueFormatter(params) {
            return params.value ? that.$moment(params.value).format('DD/MM/YYYY') : null;
          },
        },
        {
          colId: 'aircraftRegisterNumber',
          headerValueGetter() {
            return that.$i18n.t('operations.aircraftRegistration');
          },
          headerTooltip: (() => this.$i18n.t('operations.aircraftRegistration'))(),
          width: 54,
          minWidth: 52,
          cellClass: 'aircraft-cell',
          comparator: this.objectWithValueComparator,
          field: 'aircraftRegisterNumber',
        },
        {
          colId: 'flightNumber',
          headerName: (() => this.getTextResizable(this.$i18n.t('operations.flightNumberShort'), this.$i18n.t('operations.flightNumber')))(),
          headerTooltip: (() => this.$i18n.t('operations.flightNumber'))(),
          cellClass: 'flight-number-cell',
          comparator: this.objectWithValueComparator,
          valueGetter(params) {
            return params.data.flightNo();
          },
        },
        {
          colId: 'bound',
          headerValueGetter() {
            return that.$i18n.t('flightInformation.inboundOrOutbound');
          },
          headerTooltip: (() => this.$i18n.t('flightInformation.inboundOrOutbound'))(),
          comparator: this.objectWithValueComparator,
          field: 'bound',
          valueGetter(params) {
            if (!params.data.bound) {
              return null;
            }
            return params.data.bound;
          },
          cellRendererFramework: AgGridIconCellRenderer,
          cellRendererParams: {
            // eslint-disable-next-line no-nested-ternary
            icon: params => (params.value ? params.value === 'INBOUND' ? 'flight_land' : params.value === 'OUTBOUND' ? 'flight_takeoff' : null : null),
            // eslint-disable-next-line no-nested-ternary
            tooltip: params => (params.value ? params.value === 'INBOUND' ? this.$i18n.t('common.arrivalFlight') : params.value === 'OUTBOUND' ? this.$i18n.t('common.departureFlight') : null : null),
          },
        },
        {
          colId: 'serviceName',
          field: 'serviceName',
          headerValueGetter() {
            return that.$i18n.t('rampServices.rampServices');
          },
          headerTooltip: (() => this.$i18n.t('rampServices.rampServices'))(),
        },
        {
          colId: 'units',
          field: 'units',
          headerValueGetter() {
            return that.$i18n.t('rampServices.units');
          },
          headerTooltip: (() => this.$i18n.t('rampServices.units'))(),
          valueGetter(params) {
            return {
              value: params.data.units,
              warning: params.data.unitsDefault,
            };
          },
          cellRendererFramework: AgGridValueWithBackgroundCellRenderer,
        },
        {
          colId: 'requestTs',
          field: 'requestTs',
          headerTooltip: `${this.$i18n.t('rampServices.requestTime')}`,
          headerValueGetter() {
            return that.$i18n.t('rampServices.requestTime');
          },
          cellClass: 'date-time-cell',
          valueFormatter: agGridDateTimeFormatter,
        },
        {
          colId: 'requestedTs',
          field: 'requestedTs',
          headerTooltip: `${this.$i18n.t('rampServices.requestedStartTime')}`,
          headerValueGetter() {
            return that.$i18n.t('rampServices.requestedStartTime');
          },
          cellClass: 'date-time-cell',
          valueFormatter: agGridDateTimeFormatter,
          valueGetter(params) {
            return {
              value: params.data.requestedTs,
              warning: params.data.requestedTsDefault,
            };
          },
          cellRendererFramework: AgGridValueWithBackgroundCellRenderer,
        },
        {
          colId: 'requestedEndTs',
          field: 'requestedEndTs',
          headerTooltip: `${this.$i18n.t('rampServices.requestedEndTime')}`,
          headerValueGetter() {
            return that.$i18n.t('rampServices.requestedEndTime');
          },
          cellClass: 'date-time-cell',
          valueFormatter: agGridDateTimeFormatter,
          valueGetter(params) {
            return {
              value: params.data.requestedEndTs,
              warning: params.data.requestedEndTsDefault,
            };
          },
          cellRendererFramework: AgGridValueWithBackgroundCellRenderer,
        },
        {
          colId: 'startTs',
          field: 'startTs',
          headerValueGetter() {
            return that.$i18n.t('rampServices.startTime');
          },
          headerTooltip: (() => this.$i18n.t('rampServices.startTime'))(),
          cellClass: 'date-time-cell',
          valueFormatter: agGridDateTimeFormatter,
          valueGetter(params) {
            return {
              value: params.data.startTs,
              warning: params.data.startTsDefault,
            };
          },
          cellRendererFramework: AgGridValueWithBackgroundCellRenderer,
        },
        {
          colId: 'originCode',
          field: 'originCode',
          headerValueGetter() {
            return that.$i18n.t('rampServices.origin');
          },
          headerTooltip: (() => this.$i18n.t('rampServices.origin'))(),
        },
        {
          colId: 'finishTs',
          field: 'finishTs',
          headerValueGetter() {
            return that.$i18n.t('rampServices.endTime');
          },
          headerTooltip: (() => this.$i18n.t('rampServices.endTime'))(),
          cellClass: 'date-time-cell',
          valueFormatter: agGridDateTimeFormatter,
          valueGetter(params) {
            return {
              value: params.data.finishTs,
              warning: params.data.finishTsDefault,
            };
          },
          cellRendererFramework: AgGridValueWithBackgroundCellRenderer,
        },
        {
          colId: 'destinationCode',
          field: 'destinationCode',
          headerValueGetter() {
            return that.$i18n.t('operations.destination');
          },
          headerTooltip: (() => this.$i18n.t('operations.destination'))(),
        },
        {
          colId: 'orientation',
          field: 'orientation',
          headerValueGetter() {
            return that.$i18n.t('common.orientation');
          },
          headerTooltip: (() => this.$i18n.t('common.orientation'))(),
          valueGetter(params) {
            return {
              value: that.getOrientationName(params.data.orientation),
              warning: params.data.orientationDefault,
            };
          },
          cellRendererFramework: AgGridValueWithBackgroundCellRenderer,
        },
        {
          colId: 'requesterName',
          field: 'requesterName',
          headerValueGetter() {
            return that.$i18n.t('common.requester');
          },
          headerTooltip: (() => this.$i18n.t('common.requester'))(),
          valueGetter(params) {
            return {
              value: params.data.requesterName,
              warning: params.data.requesterNameDefault,
            };
          },
          cellRendererFramework: AgGridValueWithBackgroundCellRenderer,
        },
        {
          colId: 'status',
          field: 'status',
          headerValueGetter() {
            return that.$i18n.t('common.status');
          },
          headerTooltip: (() => this.$i18n.t('common.status'))(),
          valueGetter(params) {
            return params.data.status;
          },
          cellRendererFramework: AgGridFlightStatusCellRenderer,
        },
        {
          colId: 'remarks',
          field: 'remarks',
          headerValueGetter() {
            return that.$i18n.t('operations.remarksExtended');
          },
          headerTooltip: (() => this.$i18n.t('operations.remarksExtended'))(),
          valueGetter(params) {
            return {
              value: params.data.remarks ? params.data.remarks : null,
              warning: params.data.remarksDefault,
            };
          },
          cellRendererFramework: AgGridValueWithBackgroundCellRenderer,
        },
        {
          colId: 'services.actions',
          headerValueGetter() {
            return that.$i18n.t('common.actions');
          },
          hide: !this.hasPermissions(rolePermission),
          headerName: 'Actions',
          width: 100,
          cellClass: 'text-center',
          field: 'id',
          suppressMenu: true,
          sortable: false,
          suppressMovable: true,
          suppressSizeToFit: true,
          filter: false,
          resizable: false,
          suppressNavigable: true,
          colCustomConfig: {
            exportCsvDisabled: true,
          },
          cellRendererFramework: AgGridRowTowingAdhocTaskActionButtonsCellRenderer,
          cellRendererParams: {
            isAircraftGround: this.isAircraftGround,
            deleteItem: this.deleteItem,
            cancelItem: this.cancelItem,
            approveItem: this.approveItem,
          },
        },
      ],
    };
  },
  methods: {
    onGridReady(params) {
      this.setAgGridApi(params);
      this.showLoading();
      this.sizeColumnsToFit();

      this.$store.commit(TOWING_ADHOC_TASK_SET_GRID_API, params); // TODO eliminar api del store

      this.refreshCellsOnMutation();
      this.refreshCellsOnChangeLanguage();
    },
    firstDataRendered() {
      this.$nextTick(() => {
        this.resized();
      });
    },
    resized() {
      if (this.fitColumnsOnResize) {
        this.sizeColumnsToFit();
      }
    },
    sizeColumnsToFit() {
      if (this.gridApi) {
        this.$store.dispatch(FIT_COLUMNS_TO_VIEW);
      }
    },
    onSelectionChanged(event) {
      const selectedNodes = event.api.getSelectedNodes();
      const selectedData = selectedNodes.map(node => node.data);
      this.$store.commit(TOWING_ADHOC_TASK_CHANGE_SELECTION, selectedData);
    },
    isAircraftGround(params) {
      if (params.data) {
        return !!params.data.isAircraftGround;
      }
      return !!params.isAircraftGround;
    },
    isNotAircraftGround(params) {
      return !this.isAircraftGround(params);
    },
    deleteItem(item) {
      this.$emit('delete-item', item);
    },
    cancelItem(item) {
      this.$emit('cancel-item', item);
    },
    approveItem(item) {
      this.$emit('approve-item', item);
    },
  },
};

</script>

<style lang="scss">

.TowingAdhocTaskListTable {
    .status-op {
        display: block; // Asegúrate de que sea un elemento de bloque
        width: 100%; // O cualquier ancho específico
        max-height: initial !important;
        /*only one line*/
        white-space: nowrap;
        overflow: hidden;
    }
}


</style>
