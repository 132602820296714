<template>
  <div>
    <v-flex v-flex-none>
      <service-type-select :ref="'refComp'" v-on:keydown.native="keyEvent" v-char-length.clearable="4" v-model="serviceTypeIdUpdated" :validate-connected-flight="true" :is-connected-flight="isConnectedFlights"></service-type-select>
    </v-flex>
  </div>
</template>

<script>
import Vue from 'vue';
import AgGridParentConnectedFlightsCellEditor from '../mixins/AgGridParentConnectedFlightsCellEditor.vue';
import ServiceTypeSelect from '../../../ui/serviceTypeSelector/ServiceTypeSelect.vue';

export default Vue.extend({
  mixins: [AgGridParentConnectedFlightsCellEditor],
  components: {
    ServiceTypeSelect,
  },
  data() {
    return {
      serviceTypeIdOriginal: null,
      serviceTypeCodeOriginal: null,
      serviceTypeIdUpdated: null,
      flight: {},
      isConnectedFlights: null,
    };
  },
  methods: {
    getValue() {
      if (this.serviceTypeIdOriginal !== this.serviceTypeIdUpdated) {
        const serviceType = this.$store.getters.getServiceTypeById(this.serviceTypeIdUpdated);
        const code = typeof (serviceType) !== 'undefined' ? serviceType.code : undefined;

        this.saveFlightChangesFromValue((s) => {
          s.setChildPropertyValueByBound(this.params.column.colDef.colCustomConfig.flightBound, 'common.serviceTypeId', this.serviceTypeIdUpdated);
          s.setChildPropertyValueByBound(this.params.column.colDef.colCustomConfig.flightBound, 'common.serviceTypeCode', code);
        }, 'Service Type', this.serviceTypeIdOriginal, this.serviceTypeIdUpdated);
      }
      this.unmarkCellsSelectedAsEdit();
      return this.params.node.data;
    },
    codeIsSPL(code) {
      return code === 'SPL';
    },
    codeIsTRN(code) {
      return code === 'TRN';
    },
  },
  created() {
    const schedulingFlight = this.params.node.data;
    this.flight = schedulingFlight.getBound(this.params.column.colDef.colCustomConfig.flightBound);
    this.isConnectedFlights = schedulingFlight.inbound != null && schedulingFlight.inbound.common != null && schedulingFlight.inbound.common.id != null && schedulingFlight.outbound != null && schedulingFlight.outbound.common != null && schedulingFlight.outbound.common.id != null;
    this.serviceTypeIdOriginal = this.flight.serviceTypeId;
    this.serviceTypeIdUpdated = this.serviceTypeIdOriginal;

    this.markCellsSelectedAsEdit();
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$refs.refComp) {
        this.$refs.refComp.$refs.refComp.focus();
      }
    });
  },
});
</script>

<style>
  .service-type-cell.ag-cell-inline-editing {
    min-width: 100px;
  }
</style>
