<template>
  <v-dialog v-model="isDialogOpen" :max-width="modalMaxWidth" persistent
    :content-class="isContentVisible ? 'modal-common' : 'modal-common hide-title'">
    <v-card :dark="this.$vuetify.dark" :color="this.$vuetify.dark ? 'bggrey_dark' : 'bggrey'">
      <v-toolbar dark color="primary">
        <slot name="header-icon"><v-icon>flight</v-icon></slot>
        <v-toolbar-title>
          <slot name="header"></slot>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <!-- Botón para contraer/expandir el contenido -->
        <v-btn icon @click="toggleContentVisibility">
          <v-icon>{{ isContentVisible ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
        </v-btn>
      </v-toolbar>
      <div v-show="isContentVisible">
        <loading-component />
        <v-card-text>
          <slot></slot>
        </v-card-text>

        <v-card-actions>
          <slot name="footer"></slot>
          <v-spacer></v-spacer>
          <v-btn color="primary" flat @click.native="$emit('cancel')">{{ $t("common.close") }}</v-btn>
        </v-card-actions>
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
import LoadingComponent from '../../../layout/LoadingComponent.vue';

export default {
  name: 'ag-grid-dialog-show',
  components: {
    LoadingComponent,
  },
  props: {
    isDialogOpen: { type: Boolean, required: true },
    modalMaxWidth: { type: String, required: false, default: '1000px' },
  },
  data() {
    return {
      isContentVisible: true,
    };
  },
  computed: {
  },
  methods: {
    toggleContentVisibility() {
      this.isContentVisible = !this.isContentVisible;
    },
  },
};
</script>
