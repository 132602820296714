import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VChip } from 'vuetify/lib/components/VChip';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VListTileContent } from 'vuetify/lib/components/VList';
import { VListTileTitle } from 'vuetify/lib/components/VList';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('ag-grid-dialog-template-editor',{attrs:{"is-dialog-open":_vm.isDialogOpen},on:{"save":_vm.save,"cancel":_vm.cancel},scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t("operations.operationAgentModalTitle"))+" "+_vm._s(_vm.flightNumber)+" ")]},proxy:true},{key:"default",fn:function(){return [_c(VContainer,{attrs:{"fluid":"","grid-list-md":""}},[_c(VLayout,{attrs:{"row":"","wrap":""}},[_c(VFlex,{attrs:{"d-flex":"","xs12":"","sm12":"","md12":""}},[(_vm.hasPermissions(_vm.rolePermission))?_c(VAutocomplete,{attrs:{"items":_vm.users,"item-text":"firstName","item-value":"email","label":_vm.$t('operations.searchForUserAgent'),"persistent-hint":"","loading":_vm.isLoading,"search-input":_vm.search,"filter":_vm.customFilter},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event},"input":_vm.changeOperationAgent},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c(VChip,{staticClass:"chip--select-multi",attrs:{"selected":data.selected,"close":""},on:{"input":function($event){return _vm.remove(data.item)}}},[_vm._v(" "+_vm._s(data.item.firstName)+" "+_vm._s(data.item.lastName)+" ("+_vm._s(data.item.email)+") ")])]}},{key:"item",fn:function({ item, tile }){return [_c(VListTileContent,[_c(VListTileTitle,[_vm._v(_vm._s(item.firstName)+" "+_vm._s(item.lastName)+" ("+_vm._s(item.email)+")")])],1)]}}],null,false,1510574018),model:{value:(_vm.itemSelected),callback:function ($$v) {_vm.itemSelected=$$v},expression:"itemSelected"}}):_vm._e()],1)],1)],1)]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }