/* eslint no-param-reassign: "off" */
/* eslint no-confusing-arrow: "off" */
/* eslint no-nested-ternary: "off" */

import { HTTP } from '../../../http';
import { REQUEST_ERROR, REQUEST_START, REQUEST_SUCCESS } from '../../store';
import { NOTIFICATION_SHOW } from '../notifications';
import i18n from '../../../plugins/i18n';

// actions names
export const GET_AIRCRAFT = 'GET_AIRCRAFT';
export const AIRCRAFT_DELETE = 'AIRCRAFT_DELETE';
export const AIRCRAFT_CREATE = 'AIRCRAFT_CREATE';
export const AIRCRAFT_UPDATE = 'AIRCRAFT_UPDATE';
export const AIRCRAFT_DISABLE = 'AIRCRAFT_DISABLE';
export const AIRCRAFT_ENABLE = 'AIRCRAFT_ENABLE';

// mutations
export const CHANGE_AIRCRAFT_SELECTION = 'CHANGE_AIRCRAFT_SELECTION';
export const GET_AIRCRAFT_SUCCESS = 'GET_AIRCRAFT_SUCCESS';
export const GET_AIRCRAFT_REQUESTED = 'GET_AIRCRAFT_REQUESTED';
export const UPDATE_AIRCRAFT_SUCCESS = 'UPDATE_AIRCRAFT_SUCCESS';

// initial state
const storeState = {
  aircraft: [],
  aircraftRequested: false,
  selectedAircraft: [],
};

// getters
const storeGetters = {
  getAircrafts: state => state.aircraft.sort((a, b) => (a.registerNumber > b.registerNumber) ? 1 : ((b.registerNumber > a.registerNumber) ? -1 : 0)),
  getAircraftSelected: state => state.selectedAircraft,
  getActiveAircrafts: state => state.aircraft.filter(elem => !elem.deleted),
  hasSelectedAircraft: state => state.selectedAircraft.length > 0,
  getAircraftById: state => id => state.aircraft.find(a => a.id === id),
};

// actions
const storeActions = {
  [GET_AIRCRAFT]: ({ commit, state }) => {
    if (state.aircraftRequested) {
      return;
    }
    commit(REQUEST_START);
    commit(GET_AIRCRAFT_REQUESTED);
    HTTP.get('aircrafts')
      .then((resp) => {
        commit(REQUEST_SUCCESS);
        commit(GET_AIRCRAFT_SUCCESS, resp.data);
      })
      .catch((err) => {
        commit(REQUEST_ERROR, err);
      });
  },

  [AIRCRAFT_CREATE]: ({ commit, dispatch }, aircraft) => {
    commit(REQUEST_START);
    return HTTP.post('aircrafts', aircraft)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(UPDATE_AIRCRAFT_SUCCESS);
        dispatch(GET_AIRCRAFT);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.aircraftCreate'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },

  [AIRCRAFT_UPDATE]: ({ commit, dispatch }, aircraft) => {
    commit(REQUEST_START);
    return HTTP.put('aircrafts', aircraft)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(UPDATE_AIRCRAFT_SUCCESS);
        dispatch(GET_AIRCRAFT);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.aircraftUpdate'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
  [AIRCRAFT_DELETE]: ({ commit, dispatch }, id) => {
    commit(REQUEST_START);
    return HTTP.delete(`aircrafts/${id}`)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(UPDATE_AIRCRAFT_SUCCESS);
        dispatch(GET_AIRCRAFT);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.aircraftDelete'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
  [AIRCRAFT_DISABLE]: ({ commit, dispatch }, id) => {
    commit(REQUEST_START);
    return HTTP.patch(`/aircrafts/disable/${id}`)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(UPDATE_AIRCRAFT_SUCCESS);
        dispatch(GET_AIRCRAFT);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.aircraftDisable'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
  [AIRCRAFT_ENABLE]: ({ commit, dispatch }, id) => {
    commit(REQUEST_START);
    return HTTP.patch(`/aircrafts/enable/${id}`)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(UPDATE_AIRCRAFT_SUCCESS);
        dispatch(GET_AIRCRAFT);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.aircraftEnable'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
};

// mutations
const storeMutations = {
  [GET_AIRCRAFT_SUCCESS]: (state, aircraft) => {
    state.aircraft = aircraft;
  },
  [GET_AIRCRAFT_REQUESTED]: (state) => {
    state.aircraftRequested = true;
  },
  [UPDATE_AIRCRAFT_SUCCESS]: (state) => {
    state.aircraftRequested = false;
  },
  [CHANGE_AIRCRAFT_SELECTION]: (state, selection) => {
    state.selectedAircraft = selection;
  },
};

export default {
  state: storeState,
  getters: storeGetters,
  actions: storeActions,
  mutations: storeMutations,
};
