import { JSUtils } from '../../shared/js/JSUtils';

export default class FlightLoadHoldingDistributionInformation {
  constructor(
    id,
    weightHolding1,
    weightHolding2,
    weightHolding3,
    weightHolding4,
    weightHolding5,
  ) {
    this.id = id;
    this.weightHolding1 = weightHolding1;
    this.weightHolding2 = weightHolding2;
    this.weightHolding3 = weightHolding3;
    this.weightHolding4 = weightHolding4;
    this.weightHolding5 = weightHolding5;
  }

  static get createEmptyFlightLoadHoldingDistributionInformation() {
    return new FlightLoadHoldingDistributionInformation();
  }

  static createFlightLoadHoldingDistributionInformationFromData(o) {
    if (!o) {
      return this.createEmptyFlightLoadHoldingDistributionInformation;
    }

    return JSUtils.cast(o, FlightLoadHoldingDistributionInformation);
  }
}
