<template>
  <v-layout row justify-center>
    <v-dialog v-model="dialog" persistent
      :content-class="isContentVisible ? 'ticketing-cancel-modal modal-common' : 'ticketing-cancel-modal modal-common hide-title'">
      <v-card :dark="this.$vuetify.dark" :color="this.$vuetify.dark ? 'bggrey_dark' : 'bggrey'">
        <v-toolbar dark color="primary">
          <v-icon>subtitles_off</v-icon>
          <v-toolbar-title>{{ $t("ticketing.statusModalTitle") }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <!-- Botón para contraer/expandir el contenido -->
          <v-btn icon @click="toggleContentVisibility">
            <v-icon>{{ isContentVisible ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
          </v-btn>
        </v-toolbar>
        <div v-show="isContentVisible">

          <loading-component />

          <v-card-text>
            <v-form ref="form" v-model="valid">
              <v-container grid-list-md>
                <v-layout row wrap>
                  <v-flex md2 xs12>
                    <date-input :locale="$i18n.locale" :label="this.$i18n.t('common.date')" v-model="date"
                      :rules="[rules.required]" />
                  </v-flex>
                  <v-flex md10 xs12>
                    <v-text-field v-model="reason" :label="this.$i18n.t('scheduling.cancellationFlightReason')"
                      :rules="[rules.required]" />
                  </v-flex>
                </v-layout>
              </v-container>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn flat color="primary" @click.native="reject()">{{ $t("common.close") }}</v-btn>
            <v-btn color="error" depressed @click.native="confirm()" :disabled="!valid || loading">
              {{ $t("ticketing.cancelTickets") }}
            </v-btn>
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import DateInput from '../../ui/DateInput.vue';
import LoadingComponent from '../../layout/LoadingComponent.vue';

export default {
  name: 'ticketing-cancel-modal',
  components: {
    DateInput,
    LoadingComponent,
  },
  props: {
    dialog: { type: Boolean, required: true },
    items: { type: Array, required: true },
  },
  data() {
    return {
      reason: '',
      date: null,
      time: null,
      valid: false,
      rules: {
        required: value => !!value || this.$i18n.t('common.fieldRequired'),
      },
      isContentVisible: true,
    };
  },
  computed: {
    loading() {
      return this.$store.state.loading;
    },
  },
  methods: {
    reject() {
      this.$emit('cancel-reject');
    },
    confirm() {
      const flightTicketingCancellation = {
        flightTicketingIdsList: this.items.map(item => item.id),
        cancellation: {
          reason: this.reason,
          date: this.date,
        },
      };
      console.log(flightTicketingCancellation); // eslint-disable-line no-console

      this.$emit('cancel-confirm', flightTicketingCancellation);
    },
    toggleContentVisibility() {
      this.isContentVisible = !this.isContentVisible;
    },
  },
};
</script>

<style lang="scss">
.ticketing-cancel-modal.v-dialog {
  max-width: 50%;
}

@media (max-width: 1024px) {
  .ticketing-cancel-modal.v-dialog {
    max-width: 90%;
  }
}
</style>
