<template>
  <v-stepper v-model="importStep" non-linear>
    <v-stepper-header>
      <v-stepper-step step="1" :complete="importStep > 1" :rules="[() => isValidFile]">
        {{$t("schedulingImport.selectFile")}}
        <small v-if="!isValidFile">{{$t("schedulingImport.incorrectFile")}}</small>
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step step="2" :complete="importStep > 2" :rules="[() => !!isValidOptions]">
        {{$t("schedulingImport.options")}}
        <small v-if="!isValidOptions">{{$t("schedulingImport.incorrectValues")}}</small>
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step step="3" :complete="importStep > 3" :rules="[() => isValidFileContent]">
        {{$t("schedulingImport.filePreview")}}
        <small v-if="!isValidFileContent">{{$t("schedulingImport.incorrectFileContent")}}</small>
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step step="4" :complete="importStep > 4" :rules="[() => !!isValidRequiredFields]">
        {{$t("rampServiceImport.validation")}}
        <small v-if="!isValidRequiredFields">{{$t("rampServiceImport.validationError")}}</small>
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step step="5" :complete="importStep > 5" :rules="[() => !!isValidServicesData]">
        {{$t("rampServiceImport.validation")}}
        <small v-if="!isValidServicesData">{{$t("rampServiceImport.validationError")}}</small>
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step step="6" :complete="importStep > 6" :rules="[() => isValidOptionsFilter]">
        {{$t("schedulingImport.contentOptions")}}
        <small v-if="!isValidOptionsFilter">{{$t("schedulingImport.incorrectValues")}}</small>
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step step="7" :complete="importStep > 7" :rules="[() => !!isValidCatalogData]">
        {{$t("rampServiceImport.validation")}}
        <small v-if="!isValidCatalogData">{{$t("rampServiceImport.validationError")}}</small>
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step step="8" :complete="importStep > 8" :rules="[() => !!isValidFlightServicesData]">
        {{$t("rampServiceImport.validation")}}
        <small v-if="!isValidFlightServicesData">{{$t("rampServiceImport.validationError")}}</small>
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step step="9" :complete="importStep > 9" :rules="[() => !importError]">
        {{$t("schedulingImport.changesSummary")}}
        <small v-if="importError">{{$t("schedulingImport.serverError")}}</small>
      </v-stepper-step>
    </v-stepper-header>

    <loading-component />

    <v-stepper-items>
      <v-stepper-content step="1">
        <v-card>
          <v-card-text>
            <ramp-service-import-step-file-selection v-model="importFile" />
            <v-btn flat color="primary" :disabled="loading" @click="downloadTemplate"  style="margin: 0 auto"><v-icon>mdi-download</v-icon>{{$t("common.downloadTemplate")}}</v-btn>
          </v-card-text>
          <v-card-actions>
            <v-btn flat color="primary" @click="cancel" :disabled="loading">{{$t("common.cancel")}}</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="step1Next" :disabled="loading || !isValidFile">{{$t("common.continue")}}</v-btn>
          </v-card-actions>
        </v-card>
      </v-stepper-content>

      <v-stepper-content step="2">
        <v-card>
          <v-card-text>
            <ramp-service-import-step-options :import-options.sync="importOptions" />
          </v-card-text>
          <v-card-actions>
            <v-btn flat color="primary" @click="cancel" :disabled="loading">{{$t("common.cancel")}}</v-btn>
            <v-spacer></v-spacer>
            <v-btn flat color="primary" @click="step2Back" :disabled="loading">{{$t("common.back")}}</v-btn>
            <v-btn color="primary" @click="step2Next" :disabled="loading || !this.isValidOptions">{{$t("common.continue")}}</v-btn>
          </v-card-actions>
        </v-card>
      </v-stepper-content>

      <v-stepper-content step="3">
        <v-card>
          <v-card-text>
            <ramp-service-import-step-file-preview :parsed-file="importParsedFile" @on-grid-ready="onGridReady"/>
          </v-card-text>
          <v-card-actions>
            <v-btn flat color="primary" @click="cancel" :disabled="loading">{{$t("common.cancel")}}</v-btn>
            <v-spacer></v-spacer>
            <v-btn flat color="primary" @click="step3Back" :disabled="loading">{{$t("common.back")}}</v-btn>
            <v-btn color="primary" @click="step3Next" :disabled="loading">{{$t("common.continue")}}</v-btn>
          </v-card-actions>
        </v-card>
      </v-stepper-content>

      <v-stepper-content step="4">
        <v-card>
          <v-card-text>
            <ramp-service-import-step-file-validation-required-fields :parsed-file="importParsedFile" @on-grid-ready="onGridReady" @dataImportOptionValidation="requiredFieldsValidation"/>
          </v-card-text>
          <v-card-actions>
            <v-btn flat color="primary" @click="cancel" :disabled="loading">{{$t("common.cancel")}}</v-btn>
            <v-spacer></v-spacer>
            <v-btn flat color="primary" @click="step4Back" :disabled="loading">{{$t("common.back")}}</v-btn>
            <v-btn color="primary" @click="step4Next" :disabled="loading || !this.isValidRequiredFields">{{$t("common.continue")}}</v-btn>
          </v-card-actions>
        </v-card>
      </v-stepper-content>

      <v-stepper-content step="5">
        <v-card>
          <v-card-text>
            <ramp-service-import-step-file-validation-services-without-data :parsed-file="importParsedFile" @on-grid-ready="onGridReady" @dataServicesImportOptionValidation="servicesDataValidation"/>
          </v-card-text>
          <v-card-actions>
            <v-btn flat color="primary" @click="cancel" :disabled="loading">{{$t("common.cancel")}}</v-btn>
            <v-spacer></v-spacer>
            <v-btn flat color="primary" @click="step5Back" :disabled="loading">{{$t("common.back")}}</v-btn>
            <v-btn color="primary" @click="step5Next" :disabled="loading || !this.isValidServicesData">{{$t("common.continue")}}</v-btn>
          </v-card-actions>
        </v-card>
      </v-stepper-content>

      <v-stepper-content step="6">
        <v-card>
          <v-card-text>
            <ramp-service-import-step-options-filter :import-options.sync="importOptions" :import-parsed-file="importParsedFile" />
          </v-card-text>
          <v-card-actions>
            <v-btn flat color="primary" @click="cancel" :disabled="loading">{{$t("common.cancel")}}</v-btn>
            <v-spacer></v-spacer>
            <v-btn flat color="primary" @click="step6Back" :disabled="loading">{{$t("common.back")}}</v-btn>
            <v-btn color="primary" @click="step6Next" :disabled="loading || !(this.isValidOptionsFilter && !this.importError && !!this.importSummaryData)">{{$t("common.continue")}}</v-btn>
          </v-card-actions>
        </v-card>
      </v-stepper-content>

      <v-stepper-content step="7">
        <v-card>
          <v-card-text>
            <ramp-service-import-step-file-validation-catalog-content :summaryRampServices="importSummaryData" v-if="importStep === 7" @dataCatalogsImportOptionValidation="catalogDataValidation"/>
          </v-card-text>
          <v-card-actions>
            <v-btn flat color="primary" @click="cancel" :disabled="loading">{{$t("common.cancel")}}</v-btn>
            <v-spacer></v-spacer>
            <v-btn flat color="primary" @click="step7Back" :disabled="loading">{{$t("common.back")}}</v-btn>
            <v-btn color="primary" @click="step7Next" :disabled="loading || !this.isValidCatalogData">{{$t("common.continue")}}</v-btn>
          </v-card-actions>
        </v-card>
      </v-stepper-content>

      <v-stepper-content step="8">
        <v-card>
          <v-card-text>
            <ramp-service-import-step-file-validation-content :summaryRampServices="importSummaryData" @on-grid-ready="onGridReady" v-if="importStep === 8" @dataFlightsImportOptionValidation="flightServicesDataValidation"/>
          </v-card-text>
          <v-card-actions>
            <v-btn flat color="primary" @click="cancel" :disabled="loading">{{$t("common.cancel")}}</v-btn>
            <v-spacer></v-spacer>
            <v-btn flat color="primary" @click="step8Back" :disabled="loading">{{$t("common.back")}}</v-btn>
            <v-btn color="primary" @click="step8Next" :disabled="loading || !this.isValidFlightServicesData">{{$t("common.continue")}}</v-btn>
          </v-card-actions>
        </v-card>
      </v-stepper-content>

      <v-stepper-content step="9">
        <v-card>
          <v-card-text>
            <ramp-service-import-step-changes-summary :importOptions="importOptions" :summaryRampServices="importSummaryData" v-if="importStep === 9" @on-grid-ready="onGridReady" />
          </v-card-text>
          <v-card-actions>
            <v-btn flat color="primary" @click="cancel" :disabled="loading">{{$t("common.cancel")}}</v-btn>
            <v-spacer></v-spacer>
            <v-btn flat color="primary" @click="step9Back" :disabled="loading">{{$t("common.back")}}</v-btn>
            <v-btn color="primary" @click="step9Next" :disabled="loading || !importSummaryData || importSummaryData.length ==0">{{$t("common.import")}}</v-btn>
          </v-card-actions>
        </v-card>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>
<script>
import { saveAs } from 'file-saver';

import { NOTIFICATION_SHOW } from '../../../store/modules/notifications';
import { REQUEST_ERROR, REQUEST_START, REQUEST_SUCCESS } from '../../../store/store';
import { ADMIN_RAMP_SERVICE_IMPORT } from '../../../store/modules/invoicing/rampServiceImport';

import LoadingComponent from '../../layout/LoadingComponent.vue';
import RampServiceImportStepFileSelection from './RampServiceImportStepFileSelection.vue';
import RampServiceImportStepOptions from './RampServiceImportStepOptions.vue';
import RampServiceImportStepFilePreview from './RampServiceImportStepFilePreview.vue';
import RampServiceImportStepFileValidationRequiredFields from './RampServiceImportStepFileValidationRequiredFields.vue';
import RampServiceImportStepFileValidationServicesWithoutData from './RampServiceImportStepFileValidationServicesWithoutData.vue';
import RampServiceImportStepFileValidationContent from './RampServiceImportStepFileValidationContent.vue';
import RampServiceImportStepFileValidationCatalogContent from './RampServiceImportStepFileValidationCatalogContent.vue';
import RampServiceImportStepOptionsFilter from './RampServiceImportStepOptionsFilter.vue';
import RampServiceImportStepChangesSummary from './RampServiceImportStepChangesSummary.vue';
import TimezoneConfigurable from '../../mixins/TimezoneConfigurable.vue';
import AgGridApiHoldable from '../../ag-grid/mixins/AgGridApiHoldable.vue';

import RampServiceImportOptions from './model/rampServiceImportOptions';
import RampServiceImportParsedFile from './model/rampServiceImportParsedFile';
import RampServiceImportSummaryChanges from './model/rampServiceImportSummaryChanges';
import RampServiceImport from './model/rampServiceImport';
import SchedulingImportStepFlightsConflict from '../../scheduling/import/SchedulingImportStepFlightsConflict.vue';
import FileSerializable from '../../mixins/FileSerializable.vue';

export default {
  name: 'ramp-service-import-stepper',
  mixins: [TimezoneConfigurable, AgGridApiHoldable, FileSerializable],
  components: {
    LoadingComponent,
    RampServiceImportStepFileSelection,
    RampServiceImportStepOptions,
    RampServiceImportStepFilePreview,
    RampServiceImportStepFileValidationRequiredFields,
    RampServiceImportStepFileValidationServicesWithoutData,
    RampServiceImportStepFileValidationContent,
    RampServiceImportStepFileValidationCatalogContent,
    RampServiceImportStepOptionsFilter,
    RampServiceImportStepChangesSummary,
    SchedulingImportStepFlightsConflict,
  },
  data() {
    return {
      importStep: 1,

      importFile: null,
      importOptions: null,
      importParsedFile: null,

      importError: false,
      importValidRequiredFields: true,
      importValidServicesData: true,
      importValidFlightServicesData: true,
      importValidCatalogData: true,
      importSummaryData: [],
      importValidData: true,
      importValidFlights: true,
      importSummaryConfirm: {},
      flightsTagSummary: {
        flightsImportSummary: [],
        aircraftGroundsImportSummary: [],
        servicesSummary: [],
        servicesWithoutFlightsSummary: [],
      },
    };
  },
  created() {
    this.importOptions = new RampServiceImportOptions();
    this.importOptions.timeZoneMode = this.globalTimezone;
  },
  computed: {
    loading() {
      return this.$store.state.loading;
    },
    isValidFile() {
      return !!this.importFile;
    },
    isValidOptions() {
      return this.importStep < 2 || this.importOptions.timeZoneMode;
    },
    isValidFileContent() {
      return this.importStep < 3 || !!this.importParsedFile;
    },
    isValidRequiredFields() {
      return this.importStep < 4 || this.importValidRequiredFields;
    },
    isValidServicesData() {
      return this.importStep < 5 || this.importValidServicesData;
    },
    isValidOptionsFilter() {
      return this.importStep < 6 || (this.importOptions.isValidOptionsFilter() && this.importParsedFile.areThereRampServices() && !this.importParsedFile.areThereRampServicesThatNotFound() && !this.importParsedFile.areThereErrors());
    },
    isValidCatalogData() {
      return this.importStep < 7 || this.importValidCatalogData;
    },
    isValidFlightServicesData() {
      return this.importStep < 8 || this.importValidFlightServicesData;
    },
  },
  methods: {
    cancel() {
      this.clean();
      this.$emit('import-stepper-cancel');
    },
    confirm() {
      this.clean();
      this.$emit('import-stepper-confirm');
    },
    step1Next() {
      this.importStep = 2;
    },
    step2Back() {
      this.importStep = 1;
    },
    step2Next() {
      this.uploadFile().then(() => {
        this.importStep = 3;
      });
    },
    step3Back() {
      this.importStep = 2;
    },
    step3Next() {
      this.importStep = 4;
    },
    step4Back() {
      this.importStep = 3;
    },
    step4Next() {
      this.importStep = 5;
    },
    step5Back() {
      this.importStep = 4;
    },
    step5Next() {
      this.importStep = 6;
    },
    step6Back() {
      this.importStep = 5;
    },
    step6Next() {
      this.summaryChanges().then(() => {
        this.importStep = 7;
      });
    },
    step7Back() {
      this.importStep = 6;
    },
    step7Next() {
      this.importStep = 8;
    },
    step8Back() {
      this.importStep = 7;
    },
    step8Next() {
      this.importStep = 9;
    },
    step9Back() {
      this.importStep = 8;
    },
    step9Next() {
      this.confirmChanges();
    },
    clean() {
      this.importStep = 1;
      this.importError = false;
      if (this.importFile && this.importFile.ref) {
        const input = this.importFile.ref;
        input.value = null;
        input.type = 'text';
        input.type = 'file';
      }
    },
    uploadFile() {
      const formData = new FormData();
      formData.append('file', this.importFile.ref.files[0]);
      formData.append('timeZoneMode', this.importOptions.timeZoneMode);

      const post = this.$http.post('ramp/services/import', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      this.$store.commit(REQUEST_START);
      this.importError = false;
      post.then((resp) => {
        this.$store.commit(REQUEST_SUCCESS);
        this.importParsedFile = RampServiceImportParsedFile.createRampServiceImportParsedFileFromData(resp.data, this.importOptions);
      }).catch((err) => {
        this.$store.commit(REQUEST_ERROR, err);
        this.importParsedFile = null;
        this.importError = true;
        this.$store.commit(NOTIFICATION_SHOW, {
          message: err.response.data.title || this.$i18n.t('notifications.errorNotification'),
          color: 'error',
        });
      });
      return post;
    },
    summaryChanges() {
      const that = this;
      const rampServiceImport = new RampServiceImport(this.importParsedFile.allItems, this.$moment(this.importOptions.fromDate).format('YYYY-MM-DD'), this.$moment(this.importOptions.toDate).format('YYYY-MM-DD'), this.importOptions.timeZoneMode);
      this.$store.commit(REQUEST_START);
      return this.$http.post('ramp/services/import/summary', rampServiceImport)
        .then((resp) => {
          this.$store.commit(REQUEST_SUCCESS);
          that.importSummaryData = RampServiceImportSummaryChanges.createRampServiceImportSummaryChangesFromData(resp.data);
        }).catch((err) => {
          this.$store.commit(REQUEST_ERROR, err);
          this.$store.commit(NOTIFICATION_SHOW, {
            message: this.$i18n.t('notifications.errorNotification'),
            color: 'error',
          });
        });
    },
    confirmChanges() {
      this.importSummaryConfirm.flightRampServicesImport = this.importSummaryData.flightRampServicesImport;
      this.importSummaryConfirm.aircraftGroundServicesImport = this.importSummaryData.aircraftGroundServicesImport;
      this.importSummaryConfirm.aircraftGroundServicesImportThatCreateAircraftGround = this.importSummaryData.aircraftGroundServicesImportThatCreateAircraftGround;
      this.$store.dispatch(ADMIN_RAMP_SERVICE_IMPORT, this.importSummaryData);
      this.confirm();
    },
    onGridReady(gridApi) {
      this.setAgGridApi(gridApi);
    },
    downloadTemplate() {
      return this.$http.get('ramp/services/download/template')
        .then((response) => {
          const blob = new Blob([this.s2ab(atob(response.data.content))], { type: response.data.contentType });
          saveAs(blob, response.data.name);
        }).catch(() => {

        });
    },
    requiredFieldsValidation(data) {
      this.importValidRequiredFields = data;
    },
    servicesDataValidation(data) {
      this.importValidServicesData = data;
    },
    flightServicesDataValidation(data) {
      this.importValidFlightServicesData = data;
    },
    catalogDataValidation(data) {
      this.importValidCatalogData = data;
    },
  },
};
</script>
<style>
</style>
