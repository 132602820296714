import { JSUtils } from '../js/JSUtils';

export default class FlightServiceBilling {
  constructor(
    name,
    bound,
    minutes,
    units,
  ) {
    this.name = name;
    this.bound = bound;
    this.minutes = minutes;
    this.units = units;
  }

  clone() {
    const flightServiceBillingFromData = FlightServiceBilling.createFlightServiceBillingFromData(JSON.parse(JSON.stringify(this)));
    return flightServiceBillingFromData;
  }


  static createEmptyFlightServiceBilling() {
    return new FlightServiceBilling();
  }

  static createFlightServiceBillingFromData(o) {
    if (!o) {
      return this.createEmptyFlightServiceBilling;
    }

    return JSUtils.cast(o, FlightServiceBilling);
  }
}
