<template>
  <v-card-text>
    <v-container fluid style="margin-bottom: 0; padding-bottom: 0">
      <v-layout align-center justify-space-between row style="margin-bottom: 0; padding-bottom: 0">
        <v-flex xs3 style="margin-right: 2ch">
          <airport-baggage-delivery-type-select :label="this.$i18n.t('configuration.type')" v-model="item.type" :readonly="!hasPermissions(rolePermission)"/>
          <!-- <v-text-field :label="this.$i18n.t('configuration.type')" v-model="item.type" :readonly="!hasPermissions(rolePermission)"/> -->
        </v-flex>

        <v-flex xs3 style="margin-right: 2ch">
          <v-text-field :label="this.$i18n.t('configuration.code')" v-model="item.code" :readonly="!hasPermissions(rolePermission)"/>
        </v-flex>

        <v-flex xs6 style="margin-right: 2ch">
          <v-text-field :label="this.$i18n.t('configuration.description')" v-model="item.description" :readonly="!hasPermissions(rolePermission)"/>
        </v-flex>
      </v-layout>
    </v-container>
  </v-card-text>
</template>
<script>

import RolePermissions from '../../mixins/RolePermissions.vue';
import AirportBaggageDeliveryTypeSelect from './AirportSLABaggageDeliveryTypeSelect.vue';

export default {
  name: 'AirportSLABaggageDeliveryEditCardContent',
  mixins: [RolePermissions],
  components: {
    AirportBaggageDeliveryTypeSelect,
  },
  props: {
    item: { type: Object, required: true },
  },
  computed: {
    rolePermission() {
      return `${this.$route.meta.role}_WR`;
    },
  },
};
</script>
