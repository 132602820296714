import { JSUtils } from '../../shared/js/JSUtils';

export default class FlightPassageSummaryInformation {
  constructor(
    id,
    localPaxExpected,
    localPaxCheckInCounters,
    passengersNumber,
    passengersChildNumber,
    passengersEstimated,
    passengersSpecial,
    paxUMNR,
    connectionPax,
    wchc,
    wchs,
    wchr,
  ) {
    this.id = id;
    this.localPaxExpected = localPaxExpected;
    this.localPaxCheckInCounters = localPaxCheckInCounters;
    this.passengersNumber = passengersNumber;
    this.passengersChildNumber = passengersChildNumber;
    this.passengersEstimated = passengersEstimated;
    this.passengersSpecial = passengersSpecial;
    this.paxUMNR = paxUMNR;
    this.connectionPax = connectionPax;
    this.wchc = wchc;
    this.wchs = wchs;
    this.wchr = wchr;
  }

  static get createEmptyFlightPassageSummaryInformation() {
    return new FlightPassageSummaryInformation();
  }

  static createFlightPassageSummaryInformationFromData(o) {
    if (!o) {
      return this.createEmptyFlightPassageSummaryInformation;
    }

    return JSUtils.cast(o, FlightPassageSummaryInformation);
  }
}
