<template>
  <div v-if="tooltip">
  <v-tooltip bottom>
    <span v-if="status" :class="getStatusClass" class="status-badge" slot="activator">{{getStatusValue}}</span>
    <span>{{tooltip}}</span>
  </v-tooltip>
  </div>
  <div v-else>
    <v-tooltip bottom :open-delay="1000">
    <span v-if="status" :class="getStatusClass" class="status-badge" slot="activator">{{getStatusValue}}</span>
    <span>{{getFullStatus}}</span>
  </v-tooltip>
  </div>
</template>
<script>
import Resizable from '../mixins/Resizable.vue';
import { statusTextResize } from '../shared/model/flightStatus';

export default {
  name: 'flight-status',
  mixins: [Resizable],
  props: {
    status: { type: String },
    tooltip: { type: String },
    resizable: {
      default: true,
    },
  },
  computed: {
    getFullStatus() {
      return this.status;
    },
    getStatusValue() {
      if (!this.status) {
        return '';
      }
      if (!this.resizable) {
        return this.status;
      }
      return statusTextResize(this.status);
    },
    getStatusClass() {
      return this.status ? `status-op status-${this.status}` : '';
    },
  },
};
</script>
