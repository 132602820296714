<template>
  <div>
    <v-flex xs12 class="">
      <date-input :locale="$i18n.locale" :ref="'refComp'" v-on:keydown.native="keyEvent1" v-model="date"></date-input>
    </v-flex>
  </div>
</template>

<script>
import Vue from 'vue';
import AgGridParentCellEditor from './mixins/AgGridParentCellEditor.vue';
import DateInput from '../../ui/DateInput.vue';
import KeyEventsUtils, { keyEvents } from '../../keyEvents';

export default Vue.extend({
  mixins: [AgGridParentCellEditor],
  components: {
    DateInput,
  },
  computed: {
    referenceDate() {
      if (this.params.value) {
        return this.params.value;
      }
      return null;
    },
  },
  data() {
    return {
      date: null,
    };
  },
  methods: {
    getValue() {
      let requestDate = null;
      if (this.date != null) {
        requestDate = this.$moment(this.date);
      }

      return requestDate;
    },
    keyEvent1($event) {
      if (KeyEventsUtils.isArrowEvent($event) || $event.code === keyEvents.ENTER || $event.code === keyEvents.TAB) {
        $event.stopPropagation();
      }
    },
  },
  created() {
    if (this.params.value) {
      this.date = this.params.value;
    } else {
      const now = this.$moment();
      if (this.params.column.colDef.colCustomConfig && this.params.column.colDef.colCustomConfig.setSysdate) {
        this.date = now;
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$refs.refComp && this.$refs.refComp.$refs && this.$refs.refComp.$refs.refDateInput && this.$refs.refComp.$refs.refDateInput.focus) {
        this.$refs.refComp.$refs.refDateInput.focus();
      }
    });
  },
});
</script>

<style scoped>
  .date-input {
    display: inline-block !important;
    margin-left: -9em;
  }
  .time-input {
    display: inline-block !important;
    margin-left: 5px;
    position: absolute;
  }
</style>
