<template>
  <v-card-text>
    <v-container fluid>
      <v-layout row wrap>
        <v-flex xs4 pr-2>
          <message-type-select
            :label="this.$i18n.t('messages.type')"
            v-model="item.messageTypeId"
            :rules="[rules.required]"
          ></message-type-select>
        </v-flex>
        <v-flex xs4 pr-2>
          <airline-select
            :label="this.$i18n.t('common.airline')"
            v-model="item.airlineId"
            :disabled="!hasPermissions(rolePermission)"
          ></airline-select>
        </v-flex>
        <v-flex xs4 pr-2>
          <airport-select
            :label="$t('messageDirectory.destination')"
            v-model="item.airportId"
            :disabled="!hasPermissions(rolePermission)">
          </airport-select>
        </v-flex>
        <v-flex xs6 pr-2>
          <avinet-user-selector :label="this.$i18n.t('messageDirectory.source')" v-model="item.source" :rules="[rules.required]" />
        </v-flex>
        <v-flex xs6 pr-2>
          <aircraft-type-select
            style="padding:0"
            v-model="item.aircraftTypeId"
            :label="this.$i18n.t('configuration.aircraftType')"
            :disabled="!hasPermissions(rolePermission)"
          />
        </v-flex>
      </v-layout>
      <v-layout row wrap>
        <v-flex xs12>
          <avinet-user-destination-selector :label="this.$i18n.t('messageDirectory.recipients')" v-model="item.recipientsFormat" :rules="destinationRules" />
        </v-flex>
      </v-layout>
    </v-container>
  </v-card-text>
</template>
<script>
import AirlineSelect from '../../airline/AirlineSelect.vue';
import AirportSelect from '../../airport/AirportSelect.vue';
import MessageTypeSelect from '../../messages/MessageTypeSelect.vue';
import RolePermissions from '../../mixins/RolePermissions.vue';
import AircraftTypeSelect from '../../aircraft/AircraftTypeSelect.vue';
import AvinetUserDestinationSelector from '../../messages/AvinetUserDestinationSelector.vue';
import AvinetUserSelector from '../../messages/AvinetUserSelector.vue';

export default {
  name: 'MessageDirectoryEditCardContent',
  mixins: [RolePermissions],
  components: {
    AircraftTypeSelect,
    AirlineSelect,
    AirportSelect,
    MessageTypeSelect,
    AvinetUserDestinationSelector,
    AvinetUserSelector,
  },
  props: {
    item: { type: Object, required: true },
  },
  data() {
    return {
      rules: {
        required: value => !!value || 'Required.',
        email: (value) => {
          const pattern = /^(?:[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,6})?$/;
          return (
            value === null
            || pattern.test(value)
            || this.$i18n.t('configuration.invalidEmail')
          );
        },
      },
      destinationRules: [
        v => (!!v && v.length > 0) || this.$i18n.t('common.fieldRequired'),
        (emailList) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;
          if (typeof emailList !== 'undefined' && Array.isArray(emailList)) {
            let result = true;
            emailList.forEach((email) => {
              result = result ? pattern.test(email.user || email) : result;
            });
            return result || this.$i18n.t('configuration.invalidEmail');
          }
          return true;
        },
      ],
    };
  },
  computed: {
    rolePermission() {
      return `${this.$route.meta.role}_WR`;
    },
    isTenantSetted() {
      return this.$store.getters.isTenantSetted;
    },
    currentTenant() {
      return this.isTenantSetted ? this.$store.state.tenant.currentTenant : {};
    },
    avinetUsers() {
      return this.$store.getters.getAvinetUsers;
    },
  },
  watch: {
    avinetUsers: {
      handler() {
        this.setMessageSource();
      },
    },
  },
  methods: {
    setMessageSource() {
      this.item.source = this.avinetUsers.filter(avinetUser => avinetUser.tenantId === this.currentTenant.id && avinetUser.defaultUser).map(au => au.user).shift();
    },
  },
  created() {
    this.setMessageSource();
  },
};
</script>
<style scoped lang="scss">
  .theme--light.v-list {
    background: #F4F5F6;
  }
</style>
