import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VLayout,{attrs:{"row":"","justify-center":""}},[_c(VDialog,{attrs:{"persistent":"","max-width":"30%"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardActions,[_c(VCardTitle,{staticClass:"headline",attrs:{"primary-title":""}},[_c(VIcon,{attrs:{"left":"","large":""}},[_vm._v(_vm._s(_vm.icon))]),_vm._v(" "+_vm._s(_vm.title)+" ")],1),_c(VLayout,{attrs:{"align-center":"","justify-end":""}},[(_vm.deleteable)?_c(VBtn,{attrs:{"right":"","icon":""},nativeOn:{"click":function($event){return _vm.remove.apply(null, arguments)}}},[_c(VIcon,[_vm._v("delete")])],1):_vm._e(),(_vm.item.contact)?_c(VBtn,{attrs:{"right":"","icon":""},nativeOn:{"click":function($event){return _vm.edit.apply(null, arguments)}}},[_c(VIcon,[_vm._v("edit")])],1):_vm._e(),_c(VBtn,{attrs:{"right":"","icon":""},nativeOn:{"click":function($event){return _vm.close.apply(null, arguments)}}},[_c(VIcon,[_vm._v("close")])],1)],1)],1),_c(VDivider),_c(VCardText,[(_vm.item.contact)?_c('div',{staticClass:"subheading pl-2"},[_vm._v("Contact details")]):_vm._e(),(_vm.item.contact)?_c('card-info-single-column',{attrs:{"item":_vm.item}}):_vm._e(),(_vm.item.contact)?_c('card-description-text',{attrs:{"item":_vm.item}}):_c('empty-state',{attrs:{"msg":_vm.msg,"btnIcon":_vm.icon,"btnText":_vm.btnText},on:{"add-item":_vm.edit}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }