/* eslint no-param-reassign: "off" */
/* eslint no-confusing-arrow: "off" */
/* eslint no-nested-ternary: "off" */

import { HTTP } from '../../../http';
import { REQUEST_ERROR, REQUEST_START, REQUEST_SUCCESS } from '../../store';
import { NOTIFICATION_SHOW } from '../notifications';
import i18n from '../../../plugins/i18n';

// actions names
export const GET_AIRPORT_CAROUSEL = 'GET_AIRPORT_CAROUSEL';
export const AIRPORT_CAROUSEL_DELETE = 'AIRPORT_CAROUSEL_DELETE';
export const AIRPORT_CAROUSEL_CREATE = 'AIRPORT_CAROUSEL_CREATE';
export const AIRPORT_CAROUSEL_UPDATE = 'AIRPORT_CAROUSEL_UPDATE';
export const AIRPORT_CAROUSEL_DISABLE = 'AIRPORT_CAROUSEL_DISABLE';
export const AIRPORT_CAROUSEL_ENABLE = 'AIRPORT_CAROUSEL_ENABLE';


// mutations
export const CHANGE_AIRPORT_CAROUSEL_SELECTION = 'CHANGE_AIRPORT_CAROUSEL_SELECTION';
export const GET_AIRPORT_CAROUSEL_SUCCESS = 'GET_AIRPORT_CAROUSEL_SUCCESS';
export const GET_AIRPORT_CAROUSEL_REQUESTED = 'GET_AIRPORT_CAROUSEL_REQUESTED';
export const UPDATE_AIRPORT_CAROUSEL_SUCCESS = 'UPDATE_AIRPORT_CAROUSEL_SUCCESS';
export const CHANGED_TENANT_AIRPORT_CAROUSEL = 'CHANGED_TENANT_AIRPORT_CAROUSEL';

// initial state
const storeState = {
  airportCarousel: [],
  airportCarouselRequested: false,
  selectedAirportCarousel: [],
};

// getters
const storeGetters = {
  getAirportCarousels: state => state.airportCarousel.sort((a, b) => (a.code > b.code) ? 1 : ((b.code > a.code) ? -1 : 0)),
  getActiveAirportCarousels: state => state.airportCarousel.filter(elem => !elem.deleted).sort((a, b) => (a.code > b.code) ? 1 : ((b.code > a.code) ? -1 : 0)),
  getAirportCarouselSelected: state => state.selectedAirportCarousel,
  hasSelectedAirportCarousel: state => state.selectedAirportCarousel.length > 0,
};

// actions
const storeActions = {
  [GET_AIRPORT_CAROUSEL]: ({ commit, state }) => {
    if (state.airportCarouselRequested) {
      return;
    }
    commit(REQUEST_START);
    commit(GET_AIRPORT_CAROUSEL_REQUESTED);
    HTTP.get('airport-carousels')
      .then((resp) => {
        commit(REQUEST_SUCCESS);
        commit(GET_AIRPORT_CAROUSEL_SUCCESS, resp.data);
      })
      .catch((err) => {
        commit(REQUEST_ERROR, err);
      });
  },

  [AIRPORT_CAROUSEL_CREATE]: ({ commit, dispatch }, carousel) => {
    commit(REQUEST_START);
    return HTTP.post('airport-carousels', carousel)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(UPDATE_AIRPORT_CAROUSEL_SUCCESS);
        dispatch(GET_AIRPORT_CAROUSEL);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.airportCarouselCreate'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },

  [AIRPORT_CAROUSEL_UPDATE]: ({ commit, dispatch }, carousel) => {
    commit(REQUEST_START);
    return HTTP.put('airport-carousels', carousel)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(UPDATE_AIRPORT_CAROUSEL_SUCCESS);
        dispatch(GET_AIRPORT_CAROUSEL);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.airportCarouselUpdate'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
  [AIRPORT_CAROUSEL_DELETE]: ({ commit, dispatch }, id) => {
    commit(REQUEST_START);
    return HTTP.delete(`airport-carousels/${id}`)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(UPDATE_AIRPORT_CAROUSEL_SUCCESS);
        dispatch(GET_AIRPORT_CAROUSEL);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.airportCarouselDelete'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
  [AIRPORT_CAROUSEL_DISABLE]: ({ commit, dispatch }, id) => {
    commit(REQUEST_START);
    return HTTP.patch(`/airport-carousels/disable/${id}`)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(UPDATE_AIRPORT_CAROUSEL_SUCCESS);
        dispatch(GET_AIRPORT_CAROUSEL);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.airportCarouselDisable'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
  [AIRPORT_CAROUSEL_ENABLE]: ({ commit, dispatch }, id) => {
    commit(REQUEST_START);
    return HTTP.patch(`/airport-carousels/enable/${id}`)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(UPDATE_AIRPORT_CAROUSEL_SUCCESS);
        dispatch(GET_AIRPORT_CAROUSEL);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.airportCarouselEnable'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
};

// mutations
const storeMutations = {
  [GET_AIRPORT_CAROUSEL_SUCCESS]: (state, airportCarousel) => {
    state.airportCarousel = airportCarousel;
  },
  [GET_AIRPORT_CAROUSEL_REQUESTED]: (state) => {
    state.airportCarouselRequested = true;
  },
  [UPDATE_AIRPORT_CAROUSEL_SUCCESS]: (state) => {
    state.airportCarouselRequested = false;
  },
  [CHANGE_AIRPORT_CAROUSEL_SELECTION]: (state, selection) => {
    state.selectedAirportCarousel = selection;
  },
  [CHANGED_TENANT_AIRPORT_CAROUSEL]: (state) => {
    state.airportCarouselRequested = false;
  },
};

export default {
  state: storeState,
  getters: storeGetters,
  actions: storeActions,
  mutations: storeMutations,
};
