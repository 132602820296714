/* eslint no-param-reassign: "off" */

import { HTTP } from '../../../http';
import { REQUEST_ERROR, REQUEST_START, REQUEST_SUCCESS } from '../../store';
import { NOTIFICATION_SHOW } from '../notifications';
import i18n from '../../../plugins/i18n';

// actions names
export const GET_FLIGHT_WEIGHTING = 'GET_FLIGHT_WEIGHTING';
export const FLIGHT_WEIGHTING_DELETE = 'FLIGHT_WEIGHTING_DELETE';
export const FLIGHT_WEIGHTING_CREATE = 'FLIGHT_WEIGHTING_CREATE';
export const FLIGHT_WEIGHTING_UPDATE = 'FLIGHT_WEIGHTING_UPDATE';

// mutations
export const CHANGE_FLIGHT_WEIGHTING_SELECTION = 'CHANGE_FLIGHT_WEIGHTING_SELECTION';
export const GET_FLIGHT_WEIGHTING_SUCCESS = 'GET_FLIGHT_WEIGHTING_SUCCESS';
export const GET_FLIGHT_WEIGHTING_REQUESTED = 'GET_FLIGHT_WEIGHTING_REQUESTED';
export const UPDATE_FLIGHT_WEIGHTING_SUCCESS = 'UPDATE_FLIGHT_WEIGHTING_SUCCESS';
export const CHANGED_TENANT_FLIGHT_WEIGHTING = 'CHANGED_TENANT_FLIGHT_WEIGHTING';

// initial state
const storeState = {
  flightWeighting: [],
  flightWeightingRequested: false,
  selectedFlightWeighting: [],
};

// getters
const storeGetters = {
  getFlightWeighting: state => state.flightWeighting,
  getFlightWeightingById: state => id => state.flightWeighting.find(a => a.id === id),
  getFlightWeightingSelected: state => state.selectedFlightWeighting,
  hasSelectedFlightWeighting: state => state.selectedFlightWeighting.length > 0,
};

// actions
const storeActions = {
  [GET_FLIGHT_WEIGHTING]: ({ commit, state }) => {
    if (state.flightWeightingRequested) {
      return;
    }
    commit(REQUEST_START);
    commit(GET_FLIGHT_WEIGHTING_REQUESTED);
    HTTP.get('flight-weightings')
      .then((resp) => {
        commit(REQUEST_SUCCESS);
        commit(GET_FLIGHT_WEIGHTING_SUCCESS, resp.data);
      })
      .catch((err) => {
        commit(REQUEST_ERROR, err);
      });
  },

  [FLIGHT_WEIGHTING_CREATE]: ({ commit, dispatch }, gate) => {
    commit(REQUEST_START);
    return HTTP.post('flight-weightings', gate)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(UPDATE_FLIGHT_WEIGHTING_SUCCESS);
        dispatch(GET_FLIGHT_WEIGHTING);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.flightWeightingCreate'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },

  [FLIGHT_WEIGHTING_UPDATE]: ({ commit, dispatch }, gate) => {
    commit(REQUEST_START);
    return HTTP.put('flight-weightings', gate)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(UPDATE_FLIGHT_WEIGHTING_SUCCESS);
        dispatch(GET_FLIGHT_WEIGHTING);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.flightWeightingUpdate'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
  [FLIGHT_WEIGHTING_DELETE]: ({ commit, dispatch }, id) => {
    commit(REQUEST_START);
    return HTTP.delete(`flight-weightings/${id}`)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(UPDATE_FLIGHT_WEIGHTING_SUCCESS);
        dispatch(GET_FLIGHT_WEIGHTING);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.flightWeightingDelete'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
};

// mutations
const storeMutations = {
  [GET_FLIGHT_WEIGHTING_SUCCESS]: (state, flightWeighting) => {
    state.flightWeighting = flightWeighting;
  },
  [GET_FLIGHT_WEIGHTING_REQUESTED]: (state) => {
    state.flightWeightingRequested = true;
  },
  [UPDATE_FLIGHT_WEIGHTING_SUCCESS]: (state) => {
    state.flightWeightingRequested = false;
  },
  [CHANGE_FLIGHT_WEIGHTING_SELECTION]: (state, selection) => {
    state.selectedFlightWeighting = selection;
  },
  [CHANGED_TENANT_FLIGHT_WEIGHTING]: (state) => {
    state.flightWeightingRequested = false;
  },
};

export default {
  state: storeState,
  getters: storeGetters,
  actions: storeActions,
  mutations: storeMutations,
};
