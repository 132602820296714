export default class ContingencyInformation {
  constructor(contingencyId, id, notificationChannel, time, deliveredInformation, actions, services, ni, estimatedTime, other) {
    this.id = id;
    this.notificationChannel = notificationChannel;
    this.time = time;
    this.deliveredInformation = deliveredInformation;
    this.actions = actions;
    this.services = services;
    this.ni = ni;
    this.estimatedTime = estimatedTime;
    this.other = other;
    this.contingencyId = contingencyId;
  }

  static createEmptyContingencyInformation() {
    return new ContingencyInformation();
  }

  static createContingencyInformation(contingencyId) {
    return new ContingencyInformation(contingencyId);
  }

  setChildPropertyValue(property, value) {
    if (!property.constructor === Array) {
      throw Error('pathArray has to be an Array');
    }

    const schema = this; // a moving reference to internal objects within obj

    schema[property] = value;
  }

  getChildPropertyValue(property) {
    if (!property.constructor === Array) {
      throw Error('pathArray has to be an Array');
    }

    const schema = this; // a moving reference to internal objects within obj

    return schema[property];
  }
}
