<script>
import Vue from 'vue';
import StoreSubscribable from '../../mixins/StoreSubscribable.vue';

export default Vue.extend({
  mixins: [StoreSubscribable],
  data() {
    return {
    };
  },
  methods: {
    getRowNodeId(data) {
      return data.id;
    },
    getRowClass(params) {
      if (params.node.rowIndex % 2 === 0) {
        return 'ag-grid-streaped-row';
      }
      return '';
    },
  },
});
</script>
