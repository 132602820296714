<template>
  <div>
    <v-autocomplete
      :placeholder="placeholder"
      :value="value"
      :label="label"
      :items="items"
      :filter="customFilter"
      :clearable="true"
      item-text="code"
      item-value="id"
      :rules="rules"
      :disabled="disabled"
      :multiple="multiple"
      @input="inputMilestone"
      :ref="refComp"
    >
      <template v-slot:selection="data" v-if="chipView">
        <v-chip
          :selected="data.selected"
          close
          class="chip--select-multi"
          @input="remove(data.item)"
        >
          {{ data.item.name }}
        </v-chip>
      </template>
      <template
        slot="item"
        slot-scope="{ item, tile }"
      >
        <v-list-tile-content>
          <v-list-tile-title v-text="item.name"></v-list-tile-title>
          <v-list-tile-sub-title v-if="item.description" v-text="item.description"></v-list-tile-sub-title>
        </v-list-tile-content>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import { GET_MILESTONES_TO_ACTIVE } from '../../store/modules/airportMilestone/airportMilestoneCatalog';

export default {
  props: {
    value: { required: true },
    label: { type: String },
    placeholder: { type: String, default: '...' },
    charLenght: { type: Number },
    rules: { type: Array },
    disabled: { type: Boolean },
    filterHandlingActive: { type: Boolean, default: false },
    multiple: {
      type: Boolean,
      default: false,
    },
    refComp: {
      type: String,
      required: false,
      default: 'refComp',
    },
    chipView: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    items: {
      get() {
        // return this.$store.dispatch(GET_MILESTONES_TO_ACTIVE);
        return this.$store.getters.getMilestonesToActive;
      },
    },
  },
  methods: {
    inputMilestone($event) {
      // FIXME así no rompe al limpiar el select pero hay que validar que no se pueda guardar el tipo de mensaje como NULL
      if (typeof $event !== 'undefined') {
        this.$emit('input', $event);
      } else {
        this.$emit('input', null);
      }
    },
    getMilestonesToActive() {
      this.$store.dispatch(GET_MILESTONES_TO_ACTIVE);
    },
    customFilter(item, queryText) {
      const searchText = queryText.toLowerCase().trim();
      return item.name.startsWith(searchText);
    },
    remove(item) {
      const index = this.value.map(a => (typeof a === 'object' && a.id ? a.id : a)).indexOf(item.id);
      if (index >= 0) this.value.splice(index, 1);
      this.$emit('input', this.value.map(a => (typeof a === 'object' && a.id ? a.id : a)));
    },
  },
  mounted() {
    this.getMilestonesToActive();
  },

};
</script>
