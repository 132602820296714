<template>
    <v-snackbar
            v-model="show"
            :color="color"
            :multi-line="config.multiLine"
            :vertical="config.vertical"
            :top="config.top"
            :bottom="config.bottom"
            :left="config.left"
            :right="config.right"
            :timeout="config.timeout"
    >
        {{message}}
        <v-btn
                dark
                flat
                @click="dismiss()"
        >
            Close
        </v-btn>
    </v-snackbar>
</template>
<script>
import { NOTIFICATION_DISMISS } from '../../store/modules/notifications';

export default {
  data() {
    return {
      show: false,
      message: null,
      color: null,
      config: {
        multiLine: false,
        vertical: false,
        top: false,
        bottom: false,
        left: false,
        right: true,
        timeout: 5000,
      },
    };
  },
  created() {
    this.$store.watch(state => state.notifications.message, () => {
      const msg = this.$store.state.notifications.message;
      if (msg !== null) {
        if (this.show) {
          this.show = false;
        }
        this.message = msg;
        this.color = this.$store.state.notifications.color;
        this.show = true;
        this.$store.commit(NOTIFICATION_DISMISS);
      }
    });
  },
  methods: {
    dismiss() {
      this.show = false;
      this.$store.commit(NOTIFICATION_DISMISS);
    },
  },
};
</script>
