<template>
  <v-layout row>
    <filter-checkbox class="large-checkbox" v-model="value.invoiced" :label="this.$i18n.t('contingencies.invoiced')" v-char-length="6" @input="$emit('input', value)"/>
    <filter-checkbox class="large-checkbox" v-model="value.notInvoiced" :label="this.$i18n.t('contingencies.notInvoiced')" v-char-length="6" @input="$emit('input', value)"/>
  </v-layout>
</template>

<script>
import FilterCheckbox from './FilterCheckbox.vue';

export default {
  name: 'contingency-invoice',
  components: { FilterCheckbox },
  props: ['value'],
};
</script>

<style scoped>

.large-checkbox {
  max-width: 10ch !important;
}

</style>
