/* eslint no-param-reassign: "off" */

import { HTTP } from '../../../http';
import { REQUEST_ERROR, REQUEST_START, REQUEST_SUCCESS } from '../../store';
import { NOTIFICATION_SHOW } from '../notifications';
import { AUTH_CHECK } from '../auth';
import i18n from '../../../plugins/i18n';

// actions names
export const GET_USERS = 'GET_USERS';
export const GET_USERS_FILTERED = 'GET_USERS_FILTERED';
export const USER_DELETE = 'USER_DELETE';
export const USER_CREATE = 'USER_CREATE';
export const USER_UPDATE = 'USER_UPDATE';
export const GET_AUTHORITIES = 'GET_AUTHORITIES';

// mutations
export const CHANGE_USER_SELECTION = 'CHANGE_USER_SELECTION';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_FILTERED_SUCCESS = 'GET_USERS_FILTERED_SUCCESS';
export const GET_USERS_REQUESTED = 'GET_USERS_REQUESTED';
export const GET_USERS_FILTERED_REQUESTED = 'GET_USERS_FILTERED_REQUESTED';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const GET_AUTHORITIES_SUCCESS = 'GET_AUTHORITIES_SUCCESS';
export const GET_AUTHORITIES_REQUESTED = 'GET_AUTHORITIES_REQUESTED';

// initial state
const storeState = {
  users: [],
  usersFiltered: [],
  usersRequested: false,
  selectedUsers: [],
  authorities: [],
  authoritiesRequested: false,
};

// getters
const storeGetters = {
  getUsers: state => state.users,
  getUsersFiltered: state => state.usersFiltered,
  getAuthorities: state => state.authorities.sort(),
  getUsersSelected: state => state.selectedUsers,
  hasSelectedUser: state => state.selectedUsers.length > 0,
  getUserById: state => id => state.users.find(a => a.id === id),
};

// actions
const storeActions = {
  [GET_USERS]: ({ commit, state }) => {
    if (state.usersRequested) {
      return;
    }
    commit(REQUEST_START);
    commit(GET_USERS_REQUESTED);
    HTTP.get('users')
      .then((resp) => {
        commit(REQUEST_SUCCESS);
        commit(GET_USERS_SUCCESS, resp.data);
      })
      .catch((err) => {
        commit(REQUEST_ERROR, err);
      });
  },
  [GET_USERS_FILTERED]: ({ commit, state }) => {
    if (state.usersRequested) {
      return;
    }
    commit(REQUEST_START);
    commit(GET_USERS_FILTERED_REQUESTED);
    HTTP.get('users/names-and-emails')
      .then((resp) => {
        commit(REQUEST_SUCCESS);
        commit(GET_USERS_FILTERED_SUCCESS, resp.data);
      })
      .catch((err) => {
        commit(REQUEST_ERROR, err);
      });
  },
  [USER_CREATE]: ({ commit, dispatch }, user) => {
    commit(REQUEST_START);
    return HTTP.post('users', user)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(UPDATE_USER_SUCCESS);
        dispatch(GET_USERS);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.userCatalogCreate'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },

  [USER_UPDATE]: ({ commit, dispatch }, rampService) => {
    commit(REQUEST_START);
    return HTTP.put('users', rampService)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(UPDATE_USER_SUCCESS);
        dispatch(GET_USERS);
        dispatch(AUTH_CHECK);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.userCatalogUpdate'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
  [USER_DELETE]: ({ commit, dispatch }, id) => {
    commit(REQUEST_START);
    return HTTP.delete(`users/${id}`)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(UPDATE_USER_SUCCESS);
        dispatch(GET_USERS);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.userCatalogDelete'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
  [GET_AUTHORITIES]: ({ commit, state }) => {
    if (state.authoritiesRequested) {
      return;
    }
    commit(REQUEST_START);
    commit(GET_AUTHORITIES_REQUESTED);
    HTTP.get('users/authorities')
      .then((resp) => {
        commit(REQUEST_SUCCESS);
        commit(GET_AUTHORITIES_SUCCESS, resp.data);
      })
      .catch((err) => {
        commit(REQUEST_ERROR, err);
      });
  },
};

// mutations
const storeMutations = {
  [GET_USERS_SUCCESS]: (state, users) => {
    state.users = users;
  },
  [GET_USERS_FILTERED_SUCCESS]: (state, users) => {
    state.usersFiltered = users;
  },
  [GET_USERS_REQUESTED]: (state) => {
    state.usersRequested = true;
  },
  [UPDATE_USER_SUCCESS]: (state) => {
    state.usersRequested = false;
  },
  [CHANGE_USER_SELECTION]: (state, selection) => {
    state.selectedUsers = selection;
  },
  [GET_AUTHORITIES_SUCCESS]: (state, authorities) => {
    state.authorities = authorities;
  },
  [GET_AUTHORITIES_REQUESTED]: (state) => {
    state.authoritiesRequested = true;
  },
};

export default {
  state: storeState,
  getters: storeGetters,
  actions: storeActions,
  mutations: storeMutations,
};
