import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flight-messages list-filter"},[_c(VCard,{attrs:{"dark":this.$vuetify.dark,"color":this.$vuetify.dark ? 'bggrey_dark' : 'bggrey'}},[_c(VToolbar,{attrs:{"dark":"","dense":"","color":"primary lighten-1"}},[_c(VBtn,{attrs:{"small":"","flat":""},on:{"click":_vm.displayedFilter}},[_c(VIcon,[_vm._v("filter_list")]),(!_vm.showFilter)?_c(VIcon,[_vm._v("keyboard_arrow_down")]):_vm._e(),(_vm.showFilter)?_c(VIcon,[_vm._v("keyboard_arrow_up")]):_vm._e()],1),_c(VDivider,{staticStyle:{"margin-left":"30px","margin-right":"30px"},attrs:{"vertical":"","light":""}}),_c('flight-messages-list-filter-date-selector'),_c(VSpacer)],1),(_vm.showFilter)?_c(VContainer,{staticClass:"pa-2 pb-2",attrs:{"fluid":""}},[_c(VLayout,{staticClass:"filter-inline py-0",attrs:{"align-center":"","justify-start":""}},[_c(VFlex,{staticClass:"mr-2",attrs:{"align-center":"","justify-start":""}},[_c(VSwitch,{attrs:{"slot":"activator","color":"red","label":'Only without flights'},slot:"activator",model:{value:(_vm.filter.onlyWithoutFlights),callback:function ($$v) {_vm.$set(_vm.filter, "onlyWithoutFlights", $$v)},expression:"filter.onlyWithoutFlights"}})],1),_c(VSpacer,{staticStyle:{"width":"45%"}}),_c(VFlex,{staticClass:"ml-2 text-no-wrap",attrs:{"align-center":"","justify-start":""}},[_c(VBtn,{attrs:{"color":"secondary","flat":"","round":"","block":""},on:{"click":function($event){$event.stopPropagation();return _vm.exportCSV.apply(null, arguments)}}},[_c(VIcon,[_vm._v("mdi-download")]),_vm._v(" "+_vm._s(_vm.$t("common.export"))+" ")],1)],1),_c(VFlex,{staticClass:"ml-2 text-no-wrap",attrs:{"align-center":"","justify-start":""}},[_c(VBtn,{attrs:{"color":"primary","round":"","block":""},on:{"click":function($event){$event.stopPropagation();return _vm.find.apply(null, arguments)}}},[_c(VIcon,[_vm._v("search")]),_vm._v(" "+_vm._s(_vm.$t("common.find"))+" ")],1)],1)],1)],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }