<template>
  <div>
    <v-flex v-flex-none>
      <time-input :ref="refTimeInput" v-model="time" :referenceDate="referenceDate"></time-input>
    </v-flex>
  </div>
</template>

<script>
import Vue from 'vue';
import TimeInput from '../../ui/TimeInput.vue';
import AgGridParentCellEditor from './mixins/AgGridParentCellEditor.vue';

export default Vue.extend({
  mixins: [AgGridParentCellEditor],
  components: {
    TimeInput,
  },
  props: {
    refTimeInput: {
      type: String,
      required: false,
      default: 'refTimeInput',
    },
  },
  data() {
    return {
      time: null,
    };
  },
  methods: {
    getValue() {
      return this.time;
    },
  },
  computed: {
    referenceDate() {
      if (this.params.column.colDef.cellEditorCustomConfig && this.params.column.colDef.cellEditorCustomConfig.referenceDate) {
        return this.params.column.colDef.cellEditorCustomConfig.referenceDate;
      }
      return null;
    },
  },
  created() {
    this.time = this.params.value;
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$refs.refTimeInput) {
        this.$refs.refTimeInput.$refs['time-input-ref'].focus();
      }
    });
  },
});
</script>

<style>
  .scheduled-time-cell.ag-cell-inline-editing {
    min-width: 60px;
  }
</style>
