/* eslint no-param-reassign: "off" */
import { HTTP } from '../../../http';
import { REQUEST_ERROR, REQUEST_START, REQUEST_SUCCESS } from '../../store';
import { NOTIFICATION_SHOW } from '../notifications';
import i18n from '../../../plugins/i18n';
import Integration from '../../../components/shared/model/integration/integration';
import IntegrationFactory from '../../../components/shared/model/integration/integrationFactory';

// action
export const ADMIN_PMI = 'ADMIN_PMI';
export const ADMIN_PMI_INTEGRATION = 'ADMIN_PMI_INTEGRATION';
export const ADMIN_PMI_ENABLE = 'ADMIN_PMI_ENABLE';
export const ADMIN_PMI_DISABLE = 'ADMIN_PMI_DISABLE';
export const ADMIN_PMI_CLEAN = 'ADMIN_PMI_CLEAN';

// mutations
export const ADMIN_PMI_REQUEST = 'ADMIN_PMI_REQUEST';
export const ADMIN_PMI_RESPONSE = 'ADMIN_PMI_RESPONSE';
export const ADMIN_PMI_ERROR = 'ADMIN_PMI_ERROR';
export const ADMIN_PMI_RESPONSE_INTEGRATION = 'ADMIN_PMI_RESPONSE_INTEGRATION';
export const ADMIN_PMI_REQUEST_CLEAN = 'ADMIN_PMI_REQUEST_CLEAN';
export const ADMIN_PMI_RESPONSE_CLEAN = 'ADMIN_PMI_RESPONSE_CLEAN';
export const ADMIN_PMI_ERROR_CLEAN = 'ADMIN_PMI_ERROR_CLEAN';

// initial state
const storeState = {
  pmiLoading: null,
  pmiSuccess: null,
  pmiResult: [],
  pmiLastRequestTime: null,
  pmiOperation: null,

  pmiIntegration: Integration.createEmptyIntegration,
};

// getters
const storeGetters = {
  isPmiSuccess: state => state.pmiSuccess != null && !!state.pmiSuccess,
  isPmiError: state => state.pmiSuccess != null && !state.pmiSuccess,
};

// actions
const storeActions = {
  [ADMIN_PMI]: ({ commit }) => {
    commit(ADMIN_PMI_REQUEST);
    return HTTP.post('integration/live-pmi')
      .then((resp) => {
        commit(ADMIN_PMI_RESPONSE, resp.data);
      })
      .catch((err) => {
        commit(ADMIN_PMI_ERROR, err.response.data);
      });
  },
  [ADMIN_PMI_INTEGRATION]: ({ commit }) => {
    commit(REQUEST_START);
    return HTTP.get('integration/live-pmi')
      .then((resp) => {
        commit(REQUEST_SUCCESS);
        commit(ADMIN_PMI_RESPONSE_INTEGRATION, resp.data);
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
  [ADMIN_PMI_ENABLE]: ({ commit }) => {
    commit(REQUEST_START);
    return HTTP.patch('integration/live-pmi/enable')
      .then((resp) => {
        commit(REQUEST_SUCCESS);
        commit(ADMIN_PMI_RESPONSE_INTEGRATION, resp.data);
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
  [ADMIN_PMI_DISABLE]: ({ commit }) => {
    commit(REQUEST_START);
    return HTTP.patch('integration/live-pmi/disable')
      .then((resp) => {
        commit(REQUEST_SUCCESS);
        commit(ADMIN_PMI_RESPONSE_INTEGRATION, resp.data);
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
  [ADMIN_PMI_CLEAN]: ({ commit }) => {
    commit(ADMIN_PMI_REQUEST_CLEAN);
    return HTTP.patch('integration/live-pmi/clean')
      .then((resp) => {
        commit(ADMIN_PMI_RESPONSE_CLEAN, resp.data);
      })
      .catch((err) => {
        commit(ADMIN_PMI_ERROR_CLEAN, err.response.data);
      });
  },
};

// mutations
const storeMutations = {
  [ADMIN_PMI_REQUEST]: (state) => {
    state.pmiLoading = true;
    state.pmiSuccess = null;
    state.pmiResult = [];
    state.pmiLastRequestTime = new Date();
    state.pmiOperation = 'PMI Integration';
  },
  [ADMIN_PMI_RESPONSE]: (state, result) => {
    state.pmiLoading = false;
    state.pmiSuccess = result.success;
    state.pmiResult = result.traces;
    state.pmiOperation = 'PMI Integration';
  },
  [ADMIN_PMI_ERROR]: (state, result) => {
    state.pmiLoading = false;
    state.pmiSuccess = false;
    state.pmiResult = result.traces;
    state.pmiResult.push(result.message);
    state.pmiResult.push(result.detail);
    state.pmiOperation = 'PMI Integration';
  },
  [ADMIN_PMI_RESPONSE_INTEGRATION]: (state, result) => {
    state.pmiIntegration = IntegrationFactory.createIntegrationFromData(result);
  },
  [ADMIN_PMI_REQUEST_CLEAN]: (state) => {
    state.pmiLoading = true;
    state.pmiSuccess = null;
    state.pmiResult = [];
    state.pmiLastRequestTime = new Date();
    state.pmiOperation = 'PMI Integration Clean';
  },
  [ADMIN_PMI_RESPONSE_CLEAN]: (state, result) => {
    state.pmiLoading = false;
    state.pmiSuccess = result.success;
    state.pmiResult = result.traces;
    state.pmiOperation = 'PMI Integration Clean';
  },
  [ADMIN_PMI_ERROR_CLEAN]: (state, result) => {
    state.pmiLoading = false;
    state.pmiSuccess = false;
    state.pmiResult = result.traces;
    state.pmiResult.push(result.message);
    state.pmiResult.push(result.detail);
    state.pmiOperation = 'PMI Integration Clean';
  },
};

export default {
  state: storeState,
  getters: storeGetters,
  actions: storeActions,
  mutations: storeMutations,
};
