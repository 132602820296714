import { JSUtils } from '../../shared/js/JSUtils';

export default class FlightPassageOutboundInformation {
  constructor(
    id,
    priority,
    baggageDock,
    checkInExpectedStartTime,
    checkInStartTime,
    checkInExpectedEndTime,
    checkInEndTime,
    jumpseats,
    over,
    lastMinuteChangesPax,
    lastMinuteChangesBag,
    paxItemsRemarks,
    paxDataRealTime,
    missingPaxNumber,
    missingPaxBagRequestedTime,
    missingPaxInfo,
    boardingRefuelingRequested,
    crewChanges,
    boardingBagChanged,
    paxTotalWCH,
    paxTotalPetc,
  ) {
    this.id = id;
    this.priority = priority;
    this.baggageDock = baggageDock;
    this.checkInExpectedStartTime = checkInExpectedStartTime;
    this.checkInStartTime = checkInStartTime;
    this.checkInExpectedEndTime = checkInExpectedEndTime;
    this.checkInEndTime = checkInEndTime;
    this.jumpseats = jumpseats;
    this.over = over;
    this.lastMinuteChangesPax = lastMinuteChangesPax;
    this.lastMinuteChangesBag = lastMinuteChangesBag;
    this.paxItemsRemarks = paxItemsRemarks;
    this.paxDataRealTime = paxDataRealTime;
    this.missingPaxNumber = missingPaxNumber;
    this.missingPaxBagRequestedTime = missingPaxBagRequestedTime;
    this.missingPaxInfo = missingPaxInfo;
    this.boardingRefuelingRequested = boardingRefuelingRequested;
    this.crewChanges = crewChanges;
    this.boardingBagChanged = boardingBagChanged;
    this.paxTotalWCH = paxTotalWCH;
    this.paxTotalPetc = paxTotalPetc;
  }

  static get createEmptyFlightPassageOutboundInformation() {
    return new FlightPassageOutboundInformation();
  }

  static createFlightPassageOutboundInformationFromData(o) {
    if (!o) {
      return this.createEmptyFlightPassageOutboundInformation;
    }

    return JSUtils.cast(o, FlightPassageOutboundInformation);
  }
}
