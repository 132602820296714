<template>
      <v-layout class="flex justify-center">
        <v-flex>
          <v-toolbar dark dense color="primary lighten-1 d-flex justify-center">
            <v-btn icon @click="backArrow">
              <v-icon>keyboard_backspace</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <h6 class="title ma-0 text-xs-center">
              <v-icon v-if="flightMobile.bound === 'INBOUND'">flight_land</v-icon>
              <v-icon v-else-if="flightMobile.bound === 'OUTBOUND'">flight_takeoff</v-icon>
              {{ flightMobile.airlineCode + flightMobile.tripNumber }}
              {{ flightMobile.airport1Code }}
            </h6>
            <v-spacer></v-spacer>
            <h6 class="title ma-0"> {{ getFiltererDate() }}</h6>
          </v-toolbar>
        </v-flex>
      </v-layout>
</template>

<script>
export default {
  name: 'info-mobile-flight-header',
  props: {
    backArrow: {
      type: Function,
      required: true,
    },
    flightMobile: {
      required: true,
    },
    filterDate: {
      type: String,
    },
  },
  methods: {
    getFiltererDate() {
      if (!this.filterDate) {
        return '';
      }
      return this.$moment(this.filterDate).format('D/M/YYYY');
    },
  },
};
</script>
