<template>
  <div>
    <div v-if="params.value.length < 5">
      <v-tooltip bottom v-for="(tenant, index) in this.params.value" :key="index">
        <template slot="activator">
          <v-chip>{{tenant}}</v-chip>
        </template>
        <span>{{getTooltipTenant(tenant)}}</span>
      </v-tooltip>
    </div>
    <div v-else>
      <v-tooltip bottom v-for="(tenant, index) in this.params.value.slice(0, chipsLimit)" :key="index">
        <template slot="activator">
          <v-chip>{{tenant}}</v-chip>
        </template>
        <span>{{getTooltipTenant(tenant)}}</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template slot="activator">
          <v-chip>+{{this.params.value.length - chipsLimit}}</v-chip>
        </template>
        <span v-for="(tenant, index) in this.params.value.slice(chipsLimit, this.params.value.length)" :key="index">
          {{getTooltipTenant(tenant)}}
          <br/>
        </span>
      </v-tooltip>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  data() {
    return {
      chipsLimit: 6,
    };
  },
  computed: {
    tenants() {
      return this.params.tenants ? this.params.tenants() : [];
    },
  },
  methods: {
    getTooltipTenant(tenant) {
      if (tenant === 'GLOBAL') {
        return 'GLOBAL';
      }
      return this.tenants.filter(t => t.name === tenant).map(te => te.description).shift();
    },
  },
});
</script>
