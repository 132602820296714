<template>
  <v-container>
    <v-card :class="isCancelled ? 'background-cancelled' : null" :dark="this.$vuetify.dark" >
      <v-card-text>
        <v-form ref="form" v-model="validForm">
          <v-container grid-list-md fluid>
            <v-layout row wrap>
              <v-flex lg3 md4 sm6 xs12 v-if="ticketing.flightId">
                <span class="bigLabel boldText">{{$t("common.flight")}}: </span>
                <span class="bigLabel">{{ticketing.flightNo()}}</span>
              </v-flex>

              <v-flex lg3 md4 sm6 xs12 v-if="ticketing.flightId">
                <span class="bigLabel boldText">{{$t("contingencies.destinationAirport")}}: </span>
                <span class="bigLabel">{{ticketing.airport1Code}}</span>
              </v-flex>

              <v-flex lg3 md4 sm6 xs12 v-if="ticketing.flightId">
                <span class="bigLabel boldText">{{$t("contingencies.flightDate")}}: </span>
                <span class="bigLabel">{{this.$moment(ticketing.flightDate).format('DD/MM/YYYY')}}</span>
              </v-flex>

              <v-flex lg3 md4 sm6 xs12 v-if="ticketing.flightId && ticketing.scheduledTime">
                <span class="bigLabel boldText">{{$t("contingencies.departureTime")}}: </span>
                <span class="bigLabel">{{this.$moment(ticketing.scheduledTime).format('HH:MM')}}</span>
              </v-flex>

              <v-flex lg3 md4 sm6 xs12>
                <span class="bigLabel boldText">{{$t("ticketing.fare")}}: </span>
                <span class="bigLabel">{{ticketing.airlineFareName}}</span>
              </v-flex>

              <v-flex lg3 md4 sm6 xs12>
                <span class="bigLabel boldText">{{$t("ticketing.fareCost")}}: </span>
                <span class="bigLabel">{{ticketing.airlineFareCost}} {{ticketing.airlineFareCurrency}}</span>
              </v-flex>

              <v-flex lg3 md4 sm6 xs12>
                <span class="bigLabel boldText">{{formatUnitsType(ticketing.airlineFareUnitsType)}} {{$t("ticketing.fareUnitPerCost")}}: </span>
                <span class="bigLabel">{{parseFloat(ticketing.airlineFareUnitsPerCost)}}</span>
              </v-flex>

              <v-flex lg12 md12 sm12 xs12 v-if="ticketing.airlineFareDescription">
                <span class="bigLabel boldText">{{$t("ticketing.fareInformation")}}: </span>
                <span class="bigLabel">{{ticketing.airlineFareDescription}}</span>
              </v-flex>
            </v-layout>
          </v-container>

          <v-divider />

          <v-container>
            <h1>
              <v-flex xs12><span>{{$t("ticketing.ticketInformation")}}</span></v-flex>
            </h1>
            <v-layout row wrap>
              <v-flex md4 sm6 xs12>
                <span class="bigLabel boldText">{{$t("ticketing.ticketDate")}}: </span>
                <span class="bigLabel">{{this.$moment(ticketing.ticketingDate).format('DD/MM/YYYY')}}</span>
              </v-flex>
              <v-flex md4 sm6 xs12 v-if="ticketing.hasSerialNumber">
                <span class="bigLabel boldText">{{$t("ticketing.ticketNumber")}}: </span>
                <span class="bigLabel">{{ticketing.serialNumber}}</span>
              </v-flex>
              <v-flex md4 sm6 xs12>
                <span class="bigLabel boldText">{{$t("common.status")}}: </span>
                <span class="bigLabel">{{ticketing.status}}</span>
              </v-flex>
            </v-layout>
            <v-layout row wrap>
              <v-flex md4 sm6 xs12 v-if="!ticketing.hasSerialNumber">
                <v-text-field v-model="ticketing.serialNumber" :label="this.$i18n.t('ticketing.ticketNumber')" :readonly="!isEditable" :rules="[rules.required]"></v-text-field>
              </v-flex>
              <v-flex md4 sm6 xs12>
                <v-text-field v-model="ticketing.locator" :label="this.$i18n.t('ticketing.locator')" :readonly="!isEditable" :rules="[rules.required]"></v-text-field>
              </v-flex>
              <v-flex md4 sm6 xs12>
                <v-text-field v-model="ticketing.authorizationNumber" :label="$t('ticketing.authorizationNumber')" :readonly="!isEditable" :rules="[rules.required]"/>
              </v-flex>
            </v-layout>
            <v-layout row wrap>
              <v-flex md4 sm6 xs12>
                <v-text-field v-model="ticketing.passengerFirstName" :label="this.$i18n.t('ticketing.passengerName')" :readonly="!isEditable"/>
              </v-flex>
              <v-flex md4 sm6 xs12>
                <v-text-field v-model="ticketing.passengerLastName" :label="this.$i18n.t('ticketing.passengerLastName')" :readonly="!isEditable"/>
              </v-flex>
              <v-flex md4 sm6 xs12>
                <v-text-field v-model="ticketing.passengerEmail" :label="this.$i18n.t('ticketing.passengerEmail')" :readonly="!isEditable" :rules="[rules.email, rules.requiredEmail]"/>
              </v-flex>
            </v-layout>
            <v-layout>
              <v-flex>
                <v-textarea :label="$t('common.comments')" class="mt-1 remarks-form" rows="1" v-model="ticketing.comments" :readonly="!isEditable"></v-textarea>
              </v-flex>
            </v-layout>
          </v-container>

          <v-divider />

          <v-container  v-if="ticketing.airlineFareFields && ticketing.airlineFareFields.length > 0">
            <h1>
              <v-flex xs12><span>{{$t("ticketing.fieldsFareInformation")}}</span></v-flex>
            </h1>
            <v-layout row wrap >
              <!--TODO Refactor please-->
              <v-flex xs12 v-for="(field, indexField) in ticketing.airlineFareFields" :key="indexField">

                <v-layout wrap>
                  <v-flex sm6 xs12>
                    <v-text-field v-if="field.type === 'text' || field.type === 'number'" v-model="ticketing.fields[field.name]"  :label="field.name"  :rules="field.required ? [rules.required] : []" :readonly="!isEditable"></v-text-field>
                    <date-input :char-length="30" v-if="field.type === 'date'" v-model="ticketing.fields[field.name]" :locale="$i18n.locale" :label="field.name" :rules="field.required ? [rules.required] : []"></date-input>
                    <v-checkbox v-if="field.type === 'checkbox'" color="primary" :label="field.name" v-model="ticketing.fields[field.name]" :rules="field.required ? [rules.required] : []" :readonly="!isEditable"></v-checkbox>
                  </v-flex>
                </v-layout>

                <v-layout wrap v-if="field.relatedFields && field.relatedFields.length > 0">
                  <v-flex sm6 xs12 class="nestedInputs" v-for="(relateField, indexRelateField) in field.relatedFields" :key="indexRelateField">
                    <v-text-field v-if="relateField.type === 'text' || relateField.type === 'number'" v-model="ticketing.fields[field.name + relateField.name]"  :label="relateField.name"  :rules="relateField.required ? [rules.required] : []" :readonly="!isEditable"></v-text-field>
                    <date-input :char-length="30" v-if="relateField.type === 'date'" v-model="ticketing.fields[field.name + relateField.name]" :locale="$i18n.locale" :label="relateField.name" :rules="relateField.required ? [rules.required] : []"></date-input>
                    <v-checkbox v-if="relateField.type === 'checkbox'" color="primary" :label="relateField.name" v-model="ticketing.fields[field.name + relateField.name]" :rules="relateField.required ? [rules.required] : []" :readonly="!isEditable"></v-checkbox>
                  </v-flex>
                </v-layout>

              </v-flex>
            </v-layout>
          </v-container>

          <v-divider v-if="ticketing.airlineFareFields && ticketing.airlineFareFields.length > 0" />
          <v-container grid-list-md fluid  >
            <h1>
              <v-flex xs12><span>{{$t("ticketing.payInformation")}}</span></v-flex>
            </h1>
            <v-layout row wrap>
              <v-flex xs12>
                <h4>
                  <v-flex xs12><span>{{$t("ticketing.quantityPay")}}</span></v-flex>
                </h4>
                <v-layout row wrap>
                  <v-flex sm4 xs12>
                    <v-text-field v-model="ticketing.quantity" :label="formatUnitsType(ticketing.airlineFareUnitsType)" :readonly="!isEditable" :rules="[rules.required]" @input="recalculateRealPayment"></v-text-field>
                  </v-flex>
                  <v-flex offset-sm2 sm6 md4 xs12>
                    <span class="bigLabel boldText">{{$t("ticketing.conceptTotal")}} </span><br>
                    <span class="bigLabel">{{conceptTotal}}</span>
                  </v-flex>
                </v-layout>
                <v-layout row wrap>
                  <v-flex sm4 xs12>
                    <currency-input :label="$t('ticketing.realPayment')" v-model="ticketing.realQuantity" :currency="ticketing.airlineFareCurrency" :rules="[rules.required]" :readonly="!isEditable" />
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12>
                <h4>
                  <v-flex xs12><span>{{$t("ticketing.paymentType")}}</span></v-flex>
                </h4>
                <v-layout wrap>
                  <v-flex sm6 xs12>
                    <payment-type-selector v-model="ticketing.paymentType" :label="$t('ticketing.paymentType')" :rules="[rules.required]" @change="initializeTpvType" :readonly="!isEditable"/>
                  </v-flex>
                  <v-flex sm6 xs12>
                    <v-layout wrap row>
                      <v-flex sm8 xs12>
                        <v-layout wrap column>
                          <v-flex v-if="ticketing.paymentType === 'CASH_AND_CARD'">
                            <currency-input :label="$t('ticketing.cashQuantity')" v-model="ticketing.cashQuantity" :currency="ticketing.airlineFareCurrency" @input="recalculateCard" :rules="[rules.required, rules.nonNegative, rules.totalQuantity]" :readonly="!isEditable"/>
                          </v-flex>
                          <v-flex v-if="ticketing.paymentType === 'CASH_AND_CARD'">
                            <currency-input :label="$t('ticketing.cardQuantity')" v-model="ticketing.cardQuantity" :currency="ticketing.airlineFareCurrency" @input="recalculateCash" :rules="[rules.required, rules.nonNegative, rules.totalQuantity]" :readonly="!isEditable"/>
                          </v-flex>
                        </v-layout>
                        <v-layout wrap column>
                          <v-flex v-if="ticketing.paymentType === 'CASH_AND_CARD' || ticketing.paymentType === 'CREDIT_CARD'">
                            <tpv-type-selector v-model="ticketing.tpvType" :label="$t('ticketing.tpvType')" :rules="[rules.required]" :readonly="!isEditable"/>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12>
                <h4>
                  <v-flex xs12><span>{{$t("ticketing.emitType")}}</span></v-flex>
                </h4>
                <v-layout wrap>
                  <v-flex sm6 xs12>
                    <emit-type-selector v-model="emitType" :rules="[rules.required]" @change="validateForm" :readonly="!isEditable"/>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-layout wrap justify-end>
          <v-spacer></v-spacer>
          <v-btn :class="{'mb-2': $vuetify.breakpoint.xsOnly }" color="primary" flat @click.native="close">{{$t("common.return")}}</v-btn>
          <v-tooltip top :class="{'mb-2': $vuetify.breakpoint.xsOnly }" class="px-2" v-if="isEmitable">
            <v-btn slot="activator" color="primary" @click.native="saveAndEmit" :disabled="loadingGlobal || !validForm">{{$t("ticketing.emit")}}</v-btn>
            <span>{{$t("ticketing.emitTooltip")}}</span>
          </v-tooltip>
          <v-btn
            v-if="isRegisterable"
            :class="{'mr-2': $vuetify.breakpoint.xsOnly, 'mb-2': $vuetify.breakpoint.xsOnly }"
            color="primary"
            depressed
            :disabled="loadingGlobal || !validForm || !integrationChileAvailable">
            {{$t("ticketing.register")}}
          </v-btn>
        </v-layout>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>

import DateInput from '../../ui/DateInput.vue';
import PaymentTypeSelector from '../PaymentTypeSelector.vue';
import TpvTypeSelector from '../TpvTypeSelector.vue';
import RolePermissions from '../../mixins/RolePermissions.vue';
import CurrencyInput from '../../currency/CurrencyInput.vue';
import { TicketingStatus } from '../model/ticketingStatus';
import { i18nValue } from '../../shared/model/airlineFareUnitTypes';
import EmitTypeSelector from '../EmitTypeSelector.vue';
import { TicketingEmitTypes } from '../model/ticketingEmitTypes';

export default {
  name: 'ticketing-report-form',
  mixins: [RolePermissions],
  components: {
    EmitTypeSelector,
    DateInput,
    PaymentTypeSelector,
    TpvTypeSelector,
    CurrencyInput,
  },
  computed: {
    ticketing() {
      return this.$store.state.ticketing.ticketingEdited;
    },
    ticketingEditedSaved() {
      return this.$store.state.ticketing.ticketingEditedSaved;
    },
    rolePermission() {
      return `${this.$store.state.ticketing.ticketingUserRole}_WR`;
    },
    totalTicket() {
      let total = (parseFloat(this.ticketing.quantity) * parseFloat(this.ticketing.airlineFareCost)) / parseFloat(this.ticketing.airlineFareUnitsPerCost);
      total = Number.isNaN(total) ? 0 : total;
      return total;
    },
    isRegisterable() {
      return this.ticketing.status === TicketingStatus.PENDING.description;
    },
    isEmitable() {
      return this.ticketing.status == null;
    },
    isCancelled() {
      return this.ticketing.status === TicketingStatus.CANCELLED.description;
    },
    loading() {
      return this.$store.state.loading;
    },
    isEditable() {
      return this.isEmitable && this.hasPermissions(this.rolePermission);
    },
    conceptTotal() {
      return new Intl.NumberFormat(this.$store.getters.getCurrencies.find(p => p.id === this.ticketing.airlineFareCurrency).locale, { style: 'currency', currency: this.ticketing.airlineFareCurrency }).format(parseFloat(this.totalTicket));
    },
  },
  data() {
    return {
      validForm: false,
      integrationChileAvailable: false,
      rules: {
        required: value => !!value || 'Required.',
        nonNegative: value => parseFloat(String(value).replace(/[^0-9.-]+/g, '')) > 0 || this.$i18n.t('validation.nonNegative'),
        totalQuantity: () => {
          if (!this.isEmitable) {
            const sum = (parseFloat(this.ticketing.cardQuantity) + parseFloat(this.ticketing.cashQuantity)).toFixed(2);
            return parseFloat(sum) === parseFloat(this.ticketing.realQuantity) || this.$i18n.t('validation.totalQuantity');
          }
          return null;
        },
        email: (value) => {
          const pattern = /^(?:[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,6})?$/;
          return ((value === null) || pattern.test(value)) || this.$i18n.t('configuration.invalidEmail');
        },
        requiredEmail: value => ((this.emitType === TicketingEmitTypes.PRINT) || (this.emitType !== TicketingEmitTypes.PRINT && !!value)) || 'Required.',
      },
      mixedTotalTicket: 0,
      emitType: TicketingEmitTypes.PRINT,
      hasSerialNumber: true,
    };
  },
  methods: {
    close() {
      this.$emit('close');
    },
    save() {
      this.ticketing.total = this.totalTicket;
      this.$emit('save');
    },
    saveAndEmit() {
      this.ticketing.total = this.totalTicket;
      this.$emit('save-and-emit', this.emitType);
    },
    formatUnitsType(item) {
      return i18nValue(item);
    },
    recalculateCash(cardInput) {
      this.ticketing.cardQuantity = parseFloat(cardInput);
      this.ticketing.cashQuantity = this.ticketing.realQuantity - this.ticketing.cardQuantity;
      this.ticketing.cashQuantity = parseFloat(this.ticketing.cashQuantity).toFixed(2);
    },
    recalculateCard(cashInput) {
      this.ticketing.cashQuantity = parseFloat(cashInput).toFixed(2);
      this.ticketing.cardQuantity = this.ticketing.realQuantity - this.ticketing.cashQuantity;
      this.ticketing.cardQuantity = parseFloat(this.ticketing.cardQuantity).toFixed(2);
    },
    recalculateRealPayment() {
      this.ticketing.realQuantity = this.totalTicket;
    },
    initializeTpvType(event) {
      if (event !== 'CASH' && this.ticketing.tpvType === null) {
        this.ticketing.tpvType = 'INTERNAL';
      }
    },
    validateForm() {
      if (this.isEmitable) {
        this.$refs.form.validate();
      }
    },
  },
  watch: {
    'ticketing.quantity': {
      handler() {
        this.validateForm();
      },
    },
  },
};
</script>

<style scoped lang="scss">

  .background-cancelled {
    position: relative;
    overflow:hidden; /* hide the overflow here not on the pseudo element */
  }

  .background-cancelled::before {
    content: "";
    position: absolute;
    /* 141% ~ sqrt(2)x100% to make sure to cover all the area after the rotation */
    width: 141%;
    height:141%;

    /* to center*/
    left: 8%;
    top: 8%;

    background: url("../../../assets/photos-bg-fis-ticketing/void.png") no-repeat;
    background-size: 70% 40%; /* size of the image*/
    -webkit-transform: rotate(-15deg);
    -moz-transform: rotate(-15deg);
    -ms-transform: rotate(-15deg);
    -o-transform: rotate(-15deg);
    transform: rotate(-15deg);
    opacity: 0.2;
  }
  @media (max-width: 1920px) {
    .background-cancelled::before  {
      left: 10% !important;
      top: 8% !important;
    }
  }
  @media (max-width: 1024px) {
    .background-cancelled::before  {
      left: 16% !important;
      top: 8% !important;
    }
  }
  @media (max-width: 1024px) {
    .background-cancelled::before  {
      left: 16% !important;
      top: 14% !important;
    }
  }
  @media (max-width: 800px) {
    .background-cancelled::before  {
      left: 20% !important;
      top: 12% !important;
    }
  }
  @media (max-width: 420px) {
    .background-cancelled::before  {
      left: 52% !important;
      top: 12% !important;
    }
  }
  @media (max-width: 360px) {
    .background-cancelled::before  {
      left: 61% !important;
      top: 12% !important;
    }
  }

  .nestedInputs{
    padding: 0 1em;
  }

  .bigLabel {
    font-size: 16px;
  }

  .boldText {
    font-weight: bold;
  }

</style>
