/* eslint no-param-reassign: "off" */

import { HTTP } from '../../http';
import { REQUEST_ERROR, REQUEST_START, REQUEST_SUCCESS } from '../store';

export const DESK_CURRENT_GET = 'DESK_CURRENT_GET';
export const DESK_CURRENT_SET = 'DESK_CURRENT_SET';

export const DESK_CURRENT_GET_SUCCESS = 'DESK_CURRENT_GET_SUCCESS';
export const DESK_CURRENT_SET_SUCCESS = 'DESK_CURRENT_SET_SUCCESS';

// initial state
const storeState = {
  currentDesk: null,
};

// getters
const storeGetters = {
};

// actions
const storeActions = {
  [DESK_CURRENT_GET]: ({ commit }) => {
    // if vue-cli-service serve --mode dev
    if (process.env.NODE_ENV === 'development') {
      return null;
    }
    commit(REQUEST_START);
    return HTTP.get('desk/current')
      .then((resp) => {
        commit(REQUEST_SUCCESS);
        commit(DESK_CURRENT_GET_SUCCESS, resp.data);
      })
      .catch((err) => {
        commit(REQUEST_ERROR, err);
      });
  },
  [DESK_CURRENT_SET]: ({ commit }, desk) => new Promise((resolve, reject) => {
    // if vue-cli-service serve --mode dev
    if (process.env.NODE_ENV === 'development') {
      return;
    }
    commit(REQUEST_START);
    commit(DESK_CURRENT_SET_SUCCESS, null);
    HTTP.put(`desk/current/${desk}`)
      .then((resp) => {
        commit(DESK_CURRENT_SET_SUCCESS, resp.data);
        resolve(resp.data);
      })
      .catch((err) => {
        commit(REQUEST_ERROR, err);
        reject(err);
      });
  }),
};

// mutations
const storeMutations = {
  [DESK_CURRENT_GET_SUCCESS]: (state, desk) => {
    state.currentDesk = desk;
  },
  [DESK_CURRENT_SET_SUCCESS]: (state, desk) => {
    state.currentDesk = desk;
  },
};

export default {
  state: storeState,
  getters: storeGetters,
  actions: storeActions,
  mutations: storeMutations,
};
