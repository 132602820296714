export default class FlightPassengerTimesDTO {
  constructor(
    flightId,
    id,
    checkInStartTime,
    checkInExpectedStartTime,
    checkInEndTime,
    checkInExpectedEndTime,
    startedBoardingTime,
    scheduledBoardingEndTime,
    completedBoardingTime,
    scheduledBoardingTime,
    boardingAgent1,
    boardingAgent2,
  ) {
    this.id = id;
    this.flightId = flightId;
    this.checkInStartTime = checkInStartTime;
    this.checkInExpectedStartTime = checkInExpectedStartTime;
    this.checkInEndTime = checkInEndTime;
    this.checkInExpectedEndTime = checkInExpectedEndTime;
    this.startedBoardingTime = startedBoardingTime;
    this.scheduledBoardingEndTime = scheduledBoardingEndTime;
    this.completedBoardingTime = completedBoardingTime;
    this.scheduledBoardingTime = scheduledBoardingTime;
    this.boardingAgent1 = boardingAgent1;
    this.boardingAgent2 = boardingAgent2;
  }

  static createFlightPassengerTimesDTO(flightId) {
    return new FlightPassengerTimesDTO(flightId);
  }
}
