<template>
    <div>
        {{$t("common.defaultService")}}:
        - {{$t("common.inboundServiceDefault")}}: {{inboundServiceDefault}}
        - {{$t("common.outboundServiceDefault")}}: {{outboundServiceDefault}}
        - {{$t("common.aircraftGroundServiceDefault")}}: {{aircraftGroundServiceDefault}}
    </div>
</template>

<script>

export default {
  props: {
  },
  computed: {
    airportServiceAgentByUser() {
      return this.$store.state.airportServiceAgent.airportServiceAgentByUser;
    },
    inboundServiceDefault() {
      return this.airportServiceAgentByUser.defaultServiceInboundServiceName ? this.airportServiceAgentByUser.defaultServiceInboundServiceName : this.$i18n.t('common.notDefined');
    },
    outboundServiceDefault() {
      return this.airportServiceAgentByUser.defaultServiceOutboundServiceName ? this.airportServiceAgentByUser.defaultServiceOutboundServiceName : this.$i18n.t('common.notDefined');
    },
    aircraftGroundServiceDefault() {
      return this.airportServiceAgentByUser.defaultServiceAircraftGroundServiceName ? this.airportServiceAgentByUser.defaultServiceAircraftGroundServiceName : this.$i18n.t('common.notDefined');
    },
  },
  data() {
    return {
      defaultServices: '',
    };
  },
};
</script>

<style lang="scss" scoped>

</style>
