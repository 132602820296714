<template>
  <div>
    <v-autocomplete
      placeholder="..."
      :value="value"
      :label="label"
      :items="items"
      :clearable="true"
      :rules="rules"
      :disabled="disabled"
      :multiple="multiple"
      @input="$emit('input', $event)"
      :ref="refComp"
      hide-details
    >
    </v-autocomplete>
  </div>
</template>

<script>
import { TemporalUnit } from './temporalUnit';

export default {
  props: {
    value: { required: true },
    label: { type: String },
    rules: { type: Array },
    disabled: { type: Boolean },
    multiple: {
      type: Boolean,
      default: false,
    },
    refComp: {
      type: String,
      required: false,
      default: 'refComp',
    },
  },
  data() {
    return {
      items: [TemporalUnit.DAY, TemporalUnit.WEEK, TemporalUnit.MONTH],
    };
  },
};
</script>
