export default class FlightDeliveryBaggage {
  constructor(
    flightId, flightTripNumber, flightDate, airlineId, airlineCode, id, fileNumber, date, passengerName, address, city,
    postalCode, numberBag, loadBag, type, remarks, fsId, fsIataCode, ir, pnr, shippingCost, currency, bdo, provider,
  ) {
    this.id = id;
    this.fileNumber = fileNumber;
    this.date = date;
    this.passengerName = passengerName;
    this.address = address;
    this.city = city;
    this.postalCode = postalCode;
    this.numberBag = numberBag;
    this.loadBag = loadBag;
    this.type = type;
    this.remarks = remarks;
    this.flightId = flightId;
    this.flightTripNumber = flightTripNumber;
    this.flightDate = flightDate;
    this.airlineId = airlineId;
    this.airlineCode = airlineCode;
    this.fsId = fsId;
    this.fsIataCode = fsIataCode;
    this.ir = ir;
    this.pnr = pnr;
    this.shippingCost = shippingCost;
    this.currency = currency;
    this.bdo = bdo;
    this.provider = provider;
  }

  static createFlightDeliveryBaggage(o) {
    return new FlightDeliveryBaggage(o.flightId, o.flightTripNumber, o.flightDate, o.airlineId, o.airlineCode);
  }

  static createEmptyFlightDeliveryBaggage() {
    return new FlightDeliveryBaggage();
  }

  isNew() {
    return !(this.id);
  }

  flightNo() {
    return this.airlineCode ? `${this.airlineCode}${this.flightTripNumber}` : '';
  }

  setChildPropertyValue(property, value) {
    if (!property.constructor === Array) {
      throw Error('pathArray has to be an Array');
    }

    const schema = this; // a moving reference to internal objects within obj

    schema[property] = value;
  }
}
