<template>
  <v-layout row justify-center>
    <v-dialog v-model="dialog" persistent max-width="30%">
      <v-card>
        <v-card-actions>
          <v-card-title class="headline" primary-title>
            <v-icon left large>{{ icon }}</v-icon>
            {{ title }}
          </v-card-title>
          <v-layout align-center justify-end>
            <v-btn right icon @click.native="remove" v-if="deleteable"><v-icon>delete</v-icon></v-btn>
            <v-btn right icon @click.native="edit" v-if="item.contact"><v-icon>edit</v-icon></v-btn>
            <v-btn right icon @click.native="close"><v-icon>close</v-icon></v-btn>
          </v-layout>
        </v-card-actions>
        <v-divider></v-divider>
        <v-card-text>
          <div v-if="item.contact" class="subheading pl-2">Contact details</div>
          <card-info-single-column v-if="item.contact" :item="item"/>
          <card-description-text v-if="item.contact" :item="item"/>
          <empty-state v-else :msg="msg" :btnIcon="icon" :btnText="btnText" @add-item="edit"/>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import CardInfoSingleColumn from '../CardInfoSingleColumn.vue';
import CardDescriptionText from '../CardDescriptionText.vue';
import EmptyState from './EmptyState.vue';

export default {
  name: 'ContactModal',
  components: {
    CardInfoSingleColumn,
    CardDescriptionText,
    EmptyState,
  },
  props: {
    item: { type: Object, required: true },
    dialog: { type: Boolean, required: true },
    icon: { type: String, required: true },
    msg: { type: String, required: true },
    btnText: { type: String, required: true },
    title: { type: String, required: true },
    deleteable: { type: Boolean, default: false },
  },
  methods: {
    close() {
      this.$emit('modal-close');
    },
    edit() {
      this.$emit('modal-edit', this.item);
    },
    remove() {
      this.$emit('modal-delete', this.item);
    },
  },
};
</script>
