/* eslint no-param-reassign: "off" */
import moment from 'moment/moment';
import { HTTP } from '../../../http';
import { REQUEST_ERROR, REQUEST_START, REQUEST_SUCCESS } from '../../store';
import FlightContingencyFactory from '../../../components/shared/model/flightContingencyFactory';
import { NOTIFICATION_SHOW } from '../notifications';
import { createFlightContingencyDetailEventFromData } from '../../../components/shared/model/flightContingencyEvent';
import i18n from '../../../plugins/i18n';

// actions names
export const FLIGHT_CONTINGENCY_DETAIL_FIND = 'FLIGHT_CONTINGENCY_DETAIL_FIND';
export const FLIGHT_CONTINGENCY_DETAIL_UPDATE = 'FLIGHT_CONTINGENCY_DETAIL_UPDATE';
export const FLIGHT_CONTINGENCY_DETAIL_DELETE = 'FLIGHT_CONTINGENCY_DETAIL_DELETE';
export const FLIGHT_CONTINGENCY_DETAIL_DO_EXTERNAL_CHANGE = 'FLIGHT_CONTINGENCY_DETAIL_DO_EXTERNAL_CHANGE';

// mutations
export const FLIGHT_CONTINGENCY_DETAIL_SET_GRID_API = 'FLIGHT_CONTINGENCY_DETAIL_SET_GRID_API';
export const FLIGHT_CONTINGENCY_DETAIL_FIND_SUCCESS = 'FLIGHT_CONTINGENCY_DETAIL_FIND_SUCCESS';
export const FLIGHT_CHANGE_CONTINGENCY_DETAIL_SELECTION = 'FLIGHT_CHANGE_CONTINGENCY_DETAIL_SELECTION';
export const UPDATE_SHOW_OLD_VALUES = 'UPDATE_SHOW_OLD_VALUES';
export const UPDATE_FLASH_ON_CHANGES = 'UPDATE_FLASH_ON_CHANGES';
export const FLIGHT_CONTINGENCY_DETAIL_ON_EXTERNAL_CHANGE = 'FLIGHT_CONTINGENCY_DETAIL_ON_EXTERNAL_CHANGE';
export const FLIGHT_CONTINGENCY_DETAIL_SET_CHANGE = 'FLIGHT_CONTINGENCY_DETAIL_SET_CHANGE';


// initial state
const storeState = {
  gridApi: null,
  contingencyDetail: [],
  selectedContingencyDetail: [],
  contingencyDetailChanges: [],
  showOldValues: true,
  flashOnChanges: false,
};

// getters
const storeGetters = {
  hasSelectedContingencyDetail: state => state.selectedContingencyDetail.length > 0,
  isSelectedContingencyDetail: state => id => !!state.selectedContingencyDetail.find(s => s.getId() === id),
};

// actions
const storeActions = {
  [FLIGHT_CONTINGENCY_DETAIL_FIND]: ({ commit }, contingencyId) => {
    commit(REQUEST_START);
    commit(FLIGHT_CONTINGENCY_DETAIL_FIND_SUCCESS, []);
    commit(FLIGHT_CHANGE_CONTINGENCY_DETAIL_SELECTION, []);
    return HTTP.get(`flight-contingency/detail?contingencyId.equals=${contingencyId}`)
      .then((resp) => {
        commit(REQUEST_SUCCESS);
        commit(
          FLIGHT_CONTINGENCY_DETAIL_FIND_SUCCESS,
          FlightContingencyFactory.createFlightContingencyDetailFromData(resp.data),
        );
        commit(FLIGHT_CHANGE_CONTINGENCY_DETAIL_SELECTION, []);
      })
      .catch((err) => {
        commit(REQUEST_ERROR, err);
      });
  },

  [FLIGHT_CONTINGENCY_DETAIL_UPDATE]: ({ commit, dispatch }, { item }) => {
    commit(REQUEST_START);
    return HTTP.put('flight-contingency/detail', item)
      .then(() => {
        commit(REQUEST_SUCCESS);
        dispatch(FLIGHT_CONTINGENCY_DETAIL_FIND, item.contingencyId);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.contingencyUpdate'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(REQUEST_ERROR, err);
      });
  },
  [FLIGHT_CONTINGENCY_DETAIL_DELETE]: ({ commit }, ids) => {
    commit(REQUEST_START);
    return HTTP.post('flight-contingency/detail/batch-delete', ids)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.contingencyDetailDelete'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
  [FLIGHT_CONTINGENCY_DETAIL_DO_EXTERNAL_CHANGE]: ({ commit }, change) => {
    commit(FLIGHT_CONTINGENCY_DETAIL_ON_EXTERNAL_CHANGE, createFlightContingencyDetailEventFromData(change));
  },
};

// mutations
const storeMutations = {
  [FLIGHT_CONTINGENCY_DETAIL_SET_GRID_API]: (state, gridApi) => {
    state.gridApi = gridApi.api;
  },
  [FLIGHT_CONTINGENCY_DETAIL_FIND_SUCCESS]: (state, contingencyDetail) => {
    state.contingencyDetail = contingencyDetail !== null ? contingencyDetail : {};
  },
  [FLIGHT_CHANGE_CONTINGENCY_DETAIL_SELECTION]: (state, selection) => {
    state.selectedContingencyDetail = selection;
  },
  [UPDATE_SHOW_OLD_VALUES]: (state, showOldValues) => {
    state.showOldValues = showOldValues;
  },
  [UPDATE_FLASH_ON_CHANGES]: (state, flashOnChanges) => {
    state.flashOnChanges = flashOnChanges;
  },
  [FLIGHT_CONTINGENCY_DETAIL_ON_EXTERNAL_CHANGE]: (state, change) => {
    console.log(`mutations external change ${change}`); // eslint-disable-line no-console
  },
  [FLIGHT_CONTINGENCY_DETAIL_SET_CHANGE]: (state, change) => {
    change.ts = moment();
    state.contingencyDetailChanges.unshift(change);
    state.contingencyDetailChanges = state.contingencyDetailChanges.slice(0, 10);
  },
};

export default {
  state: storeState,
  getters: storeGetters,
  actions: storeActions,
  mutations: storeMutations,
};
