/* eslint no-param-reassign: "off" */
/* eslint no-return-assign: "off" */
import { JSUtils } from '../../../shared/js/JSUtils';

export default class MessageDirectory {
  constructor(
    id,
    messageTypeId,
    messageTypeCode,
    source,
    recipients,
    recipientsFormat,
    airlineId,
    airlineCode,
    airlineIataCode,
    airlineIcaoCode,
    aircraftTypeId,
    aircraftTypeIataCode,
    aircraftTypeIcaoCode,
    aircraftTypeCode,
    airportId,
    airportIataCode,
  ) {
    this.id = id;
    this.messageTypeId = messageTypeId;
    this.messageTypeCode = messageTypeCode;
    this.source = source;
    this.recipients = recipients;
    this.recipientsFormat = recipientsFormat;
    this.airlineId = airlineId;
    this.airlineCode = airlineCode;
    this.airlineIataCode = airlineIataCode;
    this.airlineIcaoCode = airlineIcaoCode;
    this.aircraftTypeId = aircraftTypeId;
    this.aircraftTypeIataCode = aircraftTypeIataCode;
    this.aircraftTypeIcaoCode = aircraftTypeIcaoCode;
    this.aircraftTypeCode = aircraftTypeCode;
    this.airportId = airportId;
    this.airportIataCode = airportIataCode;
  }

  clone() {
    return MessageDirectory.createMessageDirectoryFromData(JSON.parse(JSON.stringify(this)));
  }

  static get createEmptyMessageDirectory() {
    return new MessageDirectory();
  }

  static createMessageDirectoryFromData(o) {
    if (!o) {
      return this.createEmptyMessageDirectory;
    }

    const messageDirectory = JSUtils.cast(o, MessageDirectory);

    messageDirectory.forEach(a => a.recipientsFormat = a.recipients.map(r => r.email));

    return messageDirectory;
  }
}
