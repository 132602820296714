<template>
  <div>
    <v-menu
      v-model="menu"
      bottom
      left
      :close-on-content-click="false"
    >
      <v-tooltip left slot="activator">
        <v-btn
          slot="activator"
          small
          flat>
          <v-icon left>desktop_windows</v-icon>
          {{ currentDesk }}
        </v-btn>
        <span>{{$t("operations.changeCurrentDesk")}}</span>
      </v-tooltip>

      <v-card>
        <v-list>
          <v-list-tile>
            <v-list-tile-action>
              <v-text-field
                autofocus
                :label="this.$i18n.t('operations.currentDesk')"
                prepend-icon="desktop_windows"
                v-model="currentDeskValue"
              ></v-text-field>
            </v-list-tile-action>
          </v-list-tile>
        </v-list>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" flat @click.native="cancel">{{$t("operations.cancel")}}</v-btn>
          <v-btn color="primary" depressed @click.native="save">{{$t("operations.save")}}</v-btn>
        </v-card-actions>
      </v-card>

    </v-menu>
  </div>
</template>
<script>

import { OPERATION_FIND_FLIGHTS } from '../../../store/modules/operation';
import { DESK_CURRENT_SET } from '../../../store/modules/desk';

export default {
  name: 'operation-flights-list-desk-selector',
  data() {
    return {
      menu: false,
      currentDeskValue: null,
    };
  },
  computed: {
    currentDesk() {
      return this.$store.state.desk.currentDesk ? this.$store.state.desk.currentDesk : '-';
    },
  },
  methods: {
    cancel() {
      this.menu = false;
      this.currentDeskValue = this.currentDesk;
    },
    save() {
      this.$store.dispatch(DESK_CURRENT_SET, this.currentDeskValue).then((currentDesk) => {
        console.log('current desk changed'); // eslint-disable-line no-console
        console.log(currentDesk); // eslint-disable-line no-console
      });
      this.isFilterCurrentDesk();
      this.$store.dispatch(OPERATION_FIND_FLIGHTS);
      this.menu = false;
    },
    isFilterCurrentDesk() {
      this.$store.state.operation.filter.onlyCurrentDesk = this.currentDeskValue !== '-' && this.currentDeskValue !== '';
    },
  },
  mounted() {
    this.currentDeskValue = this.currentDesk;
    this.isFilterCurrentDesk();
  },
};
</script>

<style>
</style>
