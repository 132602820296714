/* eslint no-param-reassign: "off" */

import moment from 'moment';
import { HTTP } from '../../http';
import { REQUEST_ERROR, REQUEST_START, REQUEST_SUCCESS } from '../store';
import { NOTIFICATION_SHOW } from './notifications';
import TicketingFilter from '../../components/ticketing/model/ticketingFilter';
import i18n from '../../plugins/i18n';
import FlightTicketingInformation from '../../components/ticketing/model/flightTicketingInformation';
import { ROLE_ADMINISTRATOR_TICKETING, ROLE_CASHIER_TICKETING, ROLE_SUPERVISOR_TICKETING } from '../../constants/roles';


// action
export const TICKETING_MOBILE_FLIGHTS_FIND = 'TICKETING_MOBILE_FLIGHTS_FIND';
export const TICKETING_MOBILE_FLIGHT_FIND = 'TICKETING_MOBILE_FLIGHT_FIND';
export const TICKETING_MOBILE_FLIGHT_UPDATE = 'TICKETING_MOBILE_FLIGHT_UPDATE';
export const TICKETING_MOBILE_FLIGHTS_DELETE = 'TICKETING_MOBILE_FLIGHTS_DELETE';
export const TICKETING_MOBILE_FLIGHTS_CANCEL = 'TICKETING_MOBILE_FLIGHTS_CANCEL';

export const TICKETING_ROLE_CALCULATE = 'TICKETING_ROLE_CALCULATE';

// mutations
export const TICKETING_MOBILE_FLIGHTS_FIND_SUCCESS = 'TICKETING_MOBILE_FLIGHTS_FIND_SUCCESS';
export const TICKETING_MOBILE_FLIGHT_FIND_SUCCESS = 'TICKETING_MOBILE_FLIGHT_FIND_SUCCESS';
export const TICKETING_MOBILE_FILTER_RESET_TIMEZONE = 'TICKETING_MOBILE_FILTER_RESET_TIMEZONE';
export const TICKETING_MOBILE_RECALCULATE_FILTER = 'TICKETING_MOBILE_RECALCULATE_FILTER';
export const TICKETING_MOBILE_FILTER_RESET = 'TICKETING_MOBILE_FILTER_RESET';
export const TICKETING_MOBILE_FILTER_SET_TENANT = 'TICKETING_MOBILE_FILTER_SET_TENANT';

export const TICKETING_ROLE_SET = 'TICKETING_ROLE_SET';


// initial state
const storeState = {
  filter: TicketingFilter.createEmptyFilter,
  flightsTicketing: [],
  ticketingEdited: FlightTicketingInformation.createEmptyFlightTicketingInformation,
  ticketingUserRole: null,
};

// getters
const storeGetters = {
};

// actions
const storeActions = {
  [TICKETING_MOBILE_FLIGHTS_FIND]: ({ commit, state }) => {
    commit(REQUEST_START);
    return HTTP.post('flight-ticketing/find', state.filter)
      .then((resp) => {
        commit(REQUEST_SUCCESS);
        commit(TICKETING_MOBILE_FLIGHTS_FIND_SUCCESS, resp.data);
      })
      .catch((err) => {
        commit(TICKETING_MOBILE_FLIGHTS_FIND_SUCCESS, []);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
  [TICKETING_MOBILE_FLIGHT_FIND]: ({ commit }, id) => {
    commit(REQUEST_START);
    return HTTP.get(`flight-ticketing/${id}`)
      .then((resp) => {
        commit(REQUEST_SUCCESS);
        commit(TICKETING_MOBILE_FLIGHT_FIND_SUCCESS, FlightTicketingInformation.createFlightTicketingInformationFromData(resp.data));
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
  [TICKETING_MOBILE_FLIGHT_UPDATE]: ({ commit }, { ticketing }) => {
    commit(REQUEST_START);

    return HTTP.put('flight-ticketing', ticketing)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.ticketingUpdate'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(REQUEST_ERROR, err);
      });
  },
  [TICKETING_MOBILE_FLIGHTS_DELETE]: ({ commit, dispatch }, ids) => {
    commit(REQUEST_START);
    return HTTP.post('flight-ticketing/batch-delete', ids)
      .then(() => {
        commit(REQUEST_SUCCESS);
        dispatch(TICKETING_MOBILE_FLIGHTS_FIND);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.ticketingDeleteSuccess'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
  [TICKETING_MOBILE_FLIGHTS_CANCEL]: ({ commit, dispatch }, flightTicketingCancellation) => {
    commit(REQUEST_START);
    return HTTP.put('flight-ticketing/cancel', flightTicketingCancellation)
      .then(() => {
        commit(REQUEST_SUCCESS);
        dispatch(TICKETING_MOBILE_FLIGHTS_FIND);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.ticketingCancelSuccess'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
  [TICKETING_MOBILE_RECALCULATE_FILTER]: ({ commit, state }) => {
    if (state.filter.dateFrom === null && state.filter.dateTo === null) {
      commit(TICKETING_MOBILE_FILTER_RESET);
    } else {
      commit(TICKETING_MOBILE_FILTER_RESET_TIMEZONE, state.filter.unitOfTime);
    }
  },
  [TICKETING_ROLE_CALCULATE]: ({ commit }, authorities) => {
    if (authorities.includes(ROLE_ADMINISTRATOR_TICKETING) || authorities.includes(`${ROLE_ADMINISTRATOR_TICKETING}_WR`)) {
      commit(TICKETING_ROLE_SET, ROLE_ADMINISTRATOR_TICKETING);
    } else if (authorities.includes(ROLE_SUPERVISOR_TICKETING) || authorities.includes(`${ROLE_SUPERVISOR_TICKETING}_WR`)) {
      commit(TICKETING_ROLE_SET, ROLE_SUPERVISOR_TICKETING);
    } else if (authorities.includes(ROLE_CASHIER_TICKETING) || authorities.includes(`${ROLE_CASHIER_TICKETING}_WR`)) {
      commit(TICKETING_ROLE_SET, ROLE_CASHIER_TICKETING);
    }
  },
};

// mutations
const storeMutations = {
  [TICKETING_MOBILE_FLIGHTS_FIND_SUCCESS]: (state, flightsTicketing) => {
    state.flightsTicketing = FlightTicketingInformation.createFlightTicketingInformationFromData(flightsTicketing);
  },
  [TICKETING_MOBILE_FLIGHT_FIND_SUCCESS]: (state, ticketing) => {
    state.ticketingEdited = ticketing !== null ? ticketing : FlightTicketingInformation.createEmptyFlightTicketingInformation;
  },
  [TICKETING_MOBILE_FILTER_RESET]: (state) => {
    state.filter.dateFrom = moment().startOf('day');
    state.filter.dateTo = moment().endOf('day');
  },
  [TICKETING_MOBILE_FILTER_RESET_TIMEZONE]: (state, unitOfTime) => {
    state.filter.unitOfTime = unitOfTime != null ? unitOfTime : 'day';
    state.filter.dateFrom = moment().startOf(state.filter.unitOfTime)
      .year(state.filter.dateFrom.year())
      .month(state.filter.dateFrom.month())
      .date(state.filter.dateFrom.date());
    state.filter.dateTo = moment().endOf(state.filter.unitOfTime)
      .year(state.filter.dateTo.year())
      .month(state.filter.dateTo.month())
      .date(state.filter.dateTo.date());
  },
  [TICKETING_MOBILE_FILTER_SET_TENANT]: (state, tenant) => {
    state.filter.tenants = tenant ? [tenant] : null;
  },
  [TICKETING_ROLE_SET]: (state, role) => {
    state.ticketingUserRole = role;
  },
};

export default {
  state: storeState,
  getters: storeGetters,
  actions: storeActions,
  mutations: storeMutations,
};
