import FlightDeliveryBaggageFactory from './FlightDeliveryBaggageFactory';

export default class FlightDeliveryBaggageEvent {
  constructor(
    type,
    ts,
    origin,
    payload,
  ) {
    this.type = type;
    this.ts = ts;
    this.origin = origin;
    this.payload = payload;
  }
}

export const createFlightDeliveryBaggageEventFromData = function createFlightDeliveryBaggageEventFromData(data) {
  if (data) {
    return new FlightDeliveryBaggageEvent(
      data.type,
      data.ts,
      data.origin,
      FlightDeliveryBaggageFactory.createFlightDeliveryBaggageFromData(data.payload),
    );
  }

  throw new Error('No data for FlightDeliveryBaggageEvent');
};
