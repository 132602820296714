<template>
  <passage-outbound-flights-list-table :items="items" :isFilterDisplayed="this.isFilterDisplayed"></passage-outbound-flights-list-table>
</template>
<script>
import PassageOutboundFlightsListTable from './PassageOutboundFlightsListTable.vue';
import { PASSAGE_FILTER_RESET, PASSAGE_FIND_FLIGHTS } from '../../../store/modules/passageOutbound';

export default {
  components: {
    PassageOutboundFlightsListTable,
  },
  props: {
    isFilterDisplayed: { type: Boolean, required: false, default: false },
  },
  mounted() {
    this.$store.commit(PASSAGE_FILTER_RESET);
    this.$store.dispatch(PASSAGE_FIND_FLIGHTS);
  },
  computed: {
    items() {
      return this.$store.state.passageOutbound.connectedFlightsPassage.slice(0).sort((item1, item2) => this.$moment(item1.outbound.common.scheduledTime) - this.$moment(item2.outbound.common.scheduledTime));
    },
  },
  methods: {
  },
};
</script>
<style lang="scss">


</style>
