<template>
  <div>
    <v-flex v-flex-none class="inline-cki">
      <airport-check-in-counter-select :ref="'refCompFirst'" v-on:keydown.native="keyEvent1" v-char-length.clearable="4" v-model="itemIdFirst" @input-code="setItemCodeFirst($event)"></airport-check-in-counter-select>
    </v-flex>
    <span>-</span>
    <v-flex v-flex-none class="inline-cki">
      <airport-check-in-counter-select :ref="'refCompLast'" v-on:keydown.native="keyEvent2" v-char-length.clearable="4" v-model="itemIdLast" @input-code="setItemCodeLast($event)"></airport-check-in-counter-select>
    </v-flex>
  </div>
</template>

<script>
import Vue from 'vue';
import KeyEventsUtils, { keyEvents } from '../../../keyEvents';
import AgGridParentConnectedFlightsCellEditor from '../mixins/AgGridParentConnectedFlightsCellEditor.vue';
import AirportCheckInCounterSelect from '../../../airport/AirportCheckInCounterSelect.vue';

export default Vue.extend({
  mixins: [AgGridParentConnectedFlightsCellEditor],
  components: {
    AirportCheckInCounterSelect,
  },
  data() {
    return {
      itemIdFirst: null,
      itemIdOriginalFirst: null,
      itemCodeFirst: null,
      itemIdLast: null,
      itemIdOriginalLast: null,
      itemCodeLast: null,
    };
  },
  methods: {
    getValue() {
      this.unmarkCellsSelectedAsEdit();

      if (this.itemIdOriginalFirst !== this.itemIdFirst || this.itemIdOriginalLast !== this.itemIdLast) {
        const that = this;

        const valueCell = [
          { id: 'passageCheckIn.checkInCounterFirstId', value: this.itemIdFirst, initialValue: this.itemIdOriginalFirst },
          { id: 'passageCheckIn.checkInCounterLastId', value: this.itemIdLast, initialValue: this.itemIdOriginalLast },
        ];
        this.saveFlightChangesFromCellValue((s) => {
          s.setChildPropertyValueByBound(this.params.column.colDef.colCustomConfig.flightBound, 'passageCheckIn.checkInCounterFirstId', that.itemIdFirst);
          s.setChildPropertyValueByBound(this.params.column.colDef.colCustomConfig.flightBound, 'passageCheckIn.checkInCounterFirstCode', that.itemCodeFirst);
          s.setChildPropertyValueByBound(this.params.column.colDef.colCustomConfig.flightBound, 'passageCheckIn.checkInCounterLastId', that.itemIdLast);
          s.setChildPropertyValueByBound(this.params.column.colDef.colCustomConfig.flightBound, 'passageCheckIn.checkInCounterLastCode', that.itemCodeLast);
        }, 'Check in counters', valueCell);
      }

      return this.params.node.data;
    },
    setItemCodeFirst(code) {
      this.itemCodeFirst = code;
    },
    setItemCodeLast(code) {
      this.itemCodeLast = code;
    },
    keyEvent1($event) {
      if (KeyEventsUtils.isArrowEvent($event) || $event.code === keyEvents.ENTER || $event.code === keyEvents.TAB) {
        $event.stopPropagation();
      }
    },
    keyEvent2($event) {
      if (KeyEventsUtils.isArrowEvent($event)) {
        $event.stopPropagation();
      }

      if (($event.code === keyEvents.TAB && $event.shiftKey)) {
        $event.stopPropagation();
        this.$refs.refComp1.$refs['input-slot'].focus();
      }
    },
  },
  created() {
    const connectedFlights = this.params.node.data.clone();
    this.itemCodeFirst = connectedFlights.getChildPropertyValue(this.params.column.colDef.colCustomConfig.flightBound, ['passageCheckIn', 'checkInCounterFirstCode']);
    this.itemIdOriginalFirst = connectedFlights.getChildPropertyValue(this.params.column.colDef.colCustomConfig.flightBound, ['passageCheckIn', 'checkInCounterFirstId']);
    this.itemIdFirst = this.itemIdOriginalFirst;
    this.itemCodeLast = connectedFlights.getChildPropertyValue(this.params.column.colDef.colCustomConfig.flightBound, ['passageCheckIn', 'checkInCounterLastCode']);
    this.itemIdOriginalLast = connectedFlights.getChildPropertyValue(this.params.column.colDef.colCustomConfig.flightBound, ['passageCheckIn', 'checkInCounterLastId']);
    this.itemIdLast = this.itemIdOriginalLast;

    this.markCellsSelectedAsEdit();
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$refs.refCompFirst && this.$refs.refCompFirst.$refs.refComp) {
        this.$refs.refCompFirst.$refs.refComp.focus();
      }
    });
  },
});
</script>

<style>
  .cki-flight-cell.ag-cell-inline-editing {
    min-width: 230px;
    /*height: 105px !important;*/
  }
  .inline-cki {
    display: inline-block;
    margin-left: 5px;
  }
</style>
