<template>
  <v-card-text>
    <v-container grid-list-md>
      <v-layout wrap justify-start>
        <v-flex xs10>
          <v-text-field label="Title" v-model="member.title" :rules="[v => !!v || 'Department title is required']"/>
        </v-flex>
        <v-flex xs6>
          <v-text-field label="Fullname" v-model="member.contact.name"/>
        </v-flex>
        <v-flex xs6>
          <v-text-field label="Role" v-model="member.role"/>
        </v-flex>
        <v-flex xs10>
          <v-text-field label="E-mail" v-model="member.contact.mail"/>
        </v-flex>
        <v-flex xs4>
          <v-text-field label="Phone" v-model="member.contact.phone"/>
        </v-flex>
        <v-flex xs4>
          <v-text-field label="Mobile Phone" v-model="member.contact.phoneMobile"/>
        </v-flex>
        <v-flex xs4>
          <v-text-field label="Fax" v-model="member.contact.fax"/>
        </v-flex>
        <v-flex xs4>
          <v-text-field label="Street" v-model="member.contact.addressStreet"/>
        </v-flex>
        <v-flex xs3>
          <v-text-field label="Postal code" v-model="member.contact.addressPostalCode"/>
        </v-flex>
        <v-flex xs3>
          <v-text-field label="City" v-model="member.contact.addressCity"/>
        </v-flex>
        <v-flex xs10>
          <v-text-field label="Region" v-model="member.contact.addressRegion"/>
        </v-flex>
        <v-flex xs10>
          <v-text-field label="Country" v-model="member.contact.addressCountry"/>
        </v-flex>
        <directory-image-uploader @add-directory-file-image="setImage"/>
      </v-layout>
    </v-container>
  </v-card-text>
</template>

<script>
import DirectoryImageUploader from './DirectoryImageUploader.vue';

export default {
  name: 'MemberEditModalContent',
  props: {
    member: { type: Object, required: true },
  },
  components: {
    DirectoryImageUploader,
  },
  methods: {
    setImage(image) {
      this.member.contact.imageData = image.substr(image.indexOf('base64,') + 'base64,'.length);
    },
  },
};
</script>
