import { JSUtils } from '../../shared/js/JSUtils';

export default class FlightBillingInformation {
  constructor(
    id,
    minutesDelays,
    hasWaterService,
    hasToiletService,
    buses,
  ) {
    this.id = id;
    this.minutesDelays = minutesDelays;
    this.hasWaterService = hasWaterService;
    this.hasToiletService = hasToiletService;
    this.buses = buses;
  }

  static get createEmptyFlightBillingInformation() {
    return new FlightBillingInformation();
  }

  static createFlightBillingInformationFromData(o) {
    if (!o) {
      return this.createEmptyFlightBillingInformation;
    }

    return JSUtils.cast(o, FlightBillingInformation);
  }
}
