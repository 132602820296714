export default class AirlineFareFilter {
  constructor(airline, tenants, active) {
    this.airline = airline;
    this.tenants = tenants;
    this.active = active;
  }

  static get createEmptyFilter() {
    return new AirlineFareFilter(null, null, true);
  }
}
