import { JSUtils } from '../../shared/js/JSUtils';

export default class TallyingCashInformation {
  constructor(
    id,
    tallyingCashDate,
    currency,
    paymentType,
    totalTickets,
    totalCompany,
    cashMismatch,
    airlineId,
    airlineCode,
    userTickets,
    minTimeTickets,
    maxTimeTickets,
  ) {
    this.id = id;
    this.tallyingCashDate = tallyingCashDate;
    this.currency = currency;
    this.paymentType = paymentType;
    this.totalTickets = totalTickets;
    this.totalCompany = totalCompany;
    this.cashMismatch = cashMismatch;
    this.airlineId = airlineId;
    this.airlineCode = airlineCode;
    this.userTickets = userTickets;
    this.minTimeTickets = minTimeTickets;
    this.maxTimeTickets = maxTimeTickets;
  }

  clone() {
    return TallyingCashInformation.createTallyingCashInformationFromData(JSON.parse(JSON.stringify(this)));
  }

  static get createEmptyTallyingCashInformation() {
    return new TallyingCashInformation();
  }

  static createTallyingCashInformationFromData(o) {
    if (!o) {
      return this.createEmptyTallyingCashInformation;
    }

    return JSUtils.cast(o, TallyingCashInformation);
  }

  setChildPropertyValue(property, value) {
    if (!property.constructor === Array) {
      throw Error('pathArray has to be an Array');
    }

    const schema = this; // a moving reference to internal objects within obj

    schema[property] = value;
  }
}
