export const ColDefRampConfiguration = {
  rampDuty: {
    visible: [
      'inbound.operationExtended.load',
      'inbound.operationExtended.cargo',
      'inbound.common.gat',
      'inbound.ramp.specialBaggage',
      'inbound.rampExtended.buses',
      'inbound.ramp.crew',
      'inbound.ramp.firstBaggageTime',
      'inbound.ramp.lastBaggageTime',
      'inbound.extended.msg',
      'inbound.common.pkn',
      'outbound.common.pkn',
      'outbound.common.gat',
      'outbound.times.scheduledBoardingTime',
      'outbound.times.completedBoardingTime',
      'outbound.rampExtended.buses',
      'outbound.ramp.crew',
      'outbound.extended.delay',
      'outbound.extended.msg',
      'services.gpu',
      'services.asu',
      'services.pbk',
      'services.pbk',
      'operationExtended.oth',
      'services.rampServicesStatus',
      'outbound.operationExtended.load',
      'outbound.operationExtended.cargo',
      'outbound.rampExtended.rushBaggage',
      'outbound.ramp.carousel',
      'outbound.ramp.specialBaggage',
      'outbound.ramp.rem',
      'anybound.ramp.loadMaster',
      'anybound.ramp.externalAPU',
      'inbound.integration.flightStatusIntegration',
      'outbound.integration.flightStatusIntegration',
    ],
    notVisible: [
      'inbound.ramp.baggageTime',
      'inbound.remarks.rampRemarks',
      'outbound.remarks.rampRemarks',
      'inbound.ramp.busesEsc',
      'outbound.passageOutbound.checkInExpectedStartTime',
      'outbound.passageOutbound.checkInStartTime',
      'outbound.passageOutbound.checkInExpectedEndTime',
      'outbound.passageOutbound.checkInEndTime',
      'inbound.slaBaggageDelivery.firstSLABaggageDelivery',
      'inbound.slaBaggageDelivery.lastSLABaggageDelivery',
      'inbound.slaBaggageDelivery.prioritySLABaggageDelivery',
      'inbound.slaBaggageDelivery.subPrioritySLABaggageDelivery',
      'outbound.slaBaggageDelivery.firstSLABaggageDelivery',
      'outbound.slaBaggageDelivery.lastSLABaggageDelivery',
      'outbound.slaBaggageDelivery.prioritySLABaggageDelivery',
      'outbound.slaBaggageDelivery.subPrioritySLABaggageDelivery',
    ],
  },
  rampBaggage: {
    visible: [
      'inbound.operationExtended.load',
      'inbound.operationExtended.cargo',
      'inbound.ramp.specialBaggage',
      'inbound.ramp.firstBaggageTime',
      'inbound.ramp.lastBaggageTime',
      'inbound.ramp.baggageTime',
      'inbound.remarks.rampRemarks',
      'inbound.common.pkn',
      'inbound.slaBaggageDelivery.firstSLABaggageDelivery',
      'inbound.slaBaggageDelivery.lastSLABaggageDelivery',
      'inbound.slaBaggageDelivery.prioritySLABaggageDelivery',
      'inbound.slaBaggageDelivery.subPrioritySLABaggageDelivery',
      'outbound.common.pkn',
      'outbound.remarks.rampRemarks',
      'outbound.operationExtended.load',
      'outbound.operationExtended.cargo',
      'outbound.rampExtended.rushBaggage',
      'outbound.ramp.carousel',
      'outbound.ramp.specialBaggage',
      'outbound.ramp.rem',
      'anybound.ramp.loadMaster',
      'outbound.passageOutbound.checkInExpectedStartTime',
      'outbound.passageOutbound.checkInStartTime',
      'outbound.passageOutbound.checkInExpectedEndTime',
      'outbound.passageOutbound.checkInEndTime',
      'outbound.slaBaggageDelivery.firstSLABaggageDelivery',
      'outbound.slaBaggageDelivery.lastSLABaggageDelivery',
      'outbound.slaBaggageDelivery.prioritySLABaggageDelivery',
      'outbound.slaBaggageDelivery.subPrioritySLABaggageDelivery',
    ],
    notVisible: [
      'inbound.common.gat',
      'inbound.rampExtended.buses',
      'inbound.ramp.crew',
      'inbound.extended.msg',
      'outbound.common.gat',
      'outbound.times.scheduledBoardingTime',
      'outbound.times.completedBoardingTime',
      'outbound.rampExtended.buses',
      'outbound.ramp.crew',
      'outbound.extended.delay',
      'outbound.extended.msg',
      'services.gpu',
      'services.asu',
      'services.pbk',
      'services.pbk',
      'operationExtended.oth',
      'services.rampServicesStatus',
      'inbound.ramp.busesEsc',
      'anybound.ramp.externalAPU',
      'inbound.integration.flightStatusIntegration',
      'outbound.integration.flightStatusIntegration',
    ],
  },
  rampBuses: {
    visible: [
      'inbound.common.gat',
      'inbound.ramp.busesEsc',
      'inbound.extended.msg',
      'inbound.common.pkn',
      'outbound.common.pkn',
      'outbound.common.gat',
      'outbound.times.scheduledBoardingTime',
      'outbound.times.completedBoardingTime',
      'outbound.rampExtended.buses',
      'outbound.ramp.crew',
      'inbound.integration.flightStatusIntegration',
      'outbound.integration.flightStatusIntegration',
    ],
    notVisible: [
      'inbound.operationExtended.load',
      'inbound.operationExtended.loadHoldingWeight',
      'inbound.operationExtended.cargo',
      'inbound.ramp.specialBaggage',
      'inbound.remarks.rampRemarks',
      'inbound.ramp.firstBaggageTime',
      'inbound.ramp.lastBaggageTime',
      'inbound.ramp.baggageTime',
      'outbound.operationExtended.load',
      'outbound.operationExtended.cargo',
      'outbound.rampExtended.rushBaggage',
      'outbound.rampExtended.carousel',
      'outbound.ramp.specialBaggage',
      'outbound.remarks.rampRemarks',
      'outbound.ramp.rem',
      'outbound.extended.delay',
      'outbound.extended.msg',
      'outbound.operationExtended.loadHoldingWeight',
      'services.gpu',
      'services.asu',
      'services.pbk',
      'services.pbk',
      'operationExtended.oth',
      'services.rampServicesStatus',
      'anybound.ramp.loadMaster',
      'anybound.ramp.externalAPU',
      'outbound.passageOutbound.checkInExpectedStartTime',
      'outbound.passageOutbound.checkInStartTime',
      'outbound.passageOutbound.checkInExpectedEndTime',
      'outbound.passageOutbound.checkInEndTime',
      'inbound.slaBaggageDelivery.firstSLABaggageDelivery',
      'inbound.slaBaggageDelivery.lastSLABaggageDelivery',
      'inbound.slaBaggageDelivery.prioritySLABaggageDelivery',
      'inbound.slaBaggageDelivery.subPrioritySLABaggageDelivery',
      'outbound.slaBaggageDelivery.firstSLABaggageDelivery',
      'outbound.slaBaggageDelivery.lastSLABaggageDelivery',
      'outbound.slaBaggageDelivery.prioritySLABaggageDelivery',
      'outbound.slaBaggageDelivery.subPrioritySLABaggageDelivery',
    ],
  },
};
