export default class RushBaggage {
  constructor(flightId, flightTripNumber, flightDate, airport1Id, airport1Code, airlineId, airlineCode, scheduledTime, timeCreated, id, passengerName, rushTag, originalTag, agent, rl) {
    this.id = id;
    this.passengerName = passengerName;
    this.rushTag = rushTag;
    this.originalTag = originalTag;
    this.agent = agent;
    this.timeCreated = timeCreated;
    this.flightId = flightId;
    this.flightTripNumber = flightTripNumber;
    this.flightDate = flightDate;
    this.airport1Id = airport1Id;
    this.airport1Code = airport1Code;
    this.airlineId = airlineId;
    this.airlineCode = airlineCode;
    this.scheduledTime = scheduledTime;
    this.rl = rl;
  }

  flightNo() {
    return this.airlineCode ? `${this.airlineCode}${this.flightTripNumber}` : '';
  }

  airportIata() {
    return this.airport1Code ? this.airport1Code : '';
  }

  static createRushBaggage(o) {
    return new RushBaggage(o.flightId, o.flightTripNumber, o.flightDate, o.airport1Id, o.airport1Code, o.airlineId, o.airlineCode, o.scheduledTime, o.rl);
  }

  static createEmptyRushBaggage() {
    return new RushBaggage();
  }


  setChildPropertyValue(property, value) {
    if (!property.constructor === Array) {
      throw Error('pathArray has to be an Array');
    }

    const schema = this; // a moving reference to internal objects within obj

    schema[property] = value;
  }

  getChildPropertyValue(property) {
    if (!property.constructor === Array) {
      throw Error('pathArray has to be an Array');
    }

    const schema = this; // a moving reference to internal objects within obj

    return schema[property];
  }
}
