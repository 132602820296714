/* eslint no-param-reassign: "off" */
/* eslint no-confusing-arrow: "off" */
/* eslint no-nested-ternary: "off" */

import { HTTP } from '../../../http';
import { REQUEST_ERROR, REQUEST_START, REQUEST_SUCCESS } from '../../store';
import { NOTIFICATION_SHOW } from '../notifications';
import i18n from '../../../plugins/i18n';

// actions names
export const GET_AIRPORT_REQUESTER = 'GET_AIRPORT_REQUESTER';
export const AIRPORT_REQUESTER_DELETE = 'AIRPORT_REQUESTER_DELETE';
export const AIRPORT_REQUESTER_CREATE = 'AIRPORT_REQUESTER_CREATE';
export const AIRPORT_REQUESTER_UPDATE = 'AIRPORT_REQUESTER_UPDATE';
export const AIRPORT_REQUESTER_DISABLE = 'AIRPORT_REQUESTER_DISABLE';
export const AIRPORT_REQUESTER_ENABLE = 'AIRPORT_REQUESTER_ENABLE';


// mutations
export const CHANGE_AIRPORT_REQUESTER_SELECTION = 'CHANGE_AIRPORT_REQUESTER_SELECTION';
export const GET_AIRPORT_REQUESTER_SUCCESS = 'GET_AIRPORT_REQUESTER_SUCCESS';
export const GET_AIRPORT_REQUESTER_REQUESTED = 'GET_AIRPORT_REQUESTER_REQUESTED';
export const UPDATE_AIRPORT_REQUESTER_SUCCESS = 'UPDATE_AIRPORT_REQUESTER_SUCCESS';
export const CHANGED_TENANT_AIRPORT_REQUESTER = 'CHANGED_TENANT_AIRPORT_REQUESTER';

// initial state
const storeState = {
  airportRequester: [],
  airportRequesterRequested: false,
  selectedAirportRequester: [],
};

// getters
const storeGetters = {
  getAirportRequesters: state => state.airportRequester.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)),
  getActiveAirportRequesters: state => state.airportRequester.filter(elem => !elem.deleted).sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)),
  getAirportRequesterSelected: state => state.selectedAirportRequester,
  hasSelectedAirportRequester: state => state.selectedAirportRequester.length > 0,
};

// actions
const storeActions = {
  [GET_AIRPORT_REQUESTER]: ({ commit, state }) => {
    if (state.airportRequesterRequested) {
      return;
    }
    commit(REQUEST_START);
    commit(GET_AIRPORT_REQUESTER_REQUESTED);
    HTTP.get('airport-requesters')
      .then((resp) => {
        commit(REQUEST_SUCCESS);
        commit(GET_AIRPORT_REQUESTER_SUCCESS, resp.data);
      })
      .catch((err) => {
        commit(REQUEST_ERROR, err);
      });
  },

  [AIRPORT_REQUESTER_CREATE]: ({ commit, dispatch }, requester) => {
    commit(REQUEST_START);
    return HTTP.post('airport-requesters', requester)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(UPDATE_AIRPORT_REQUESTER_SUCCESS);
        dispatch(GET_AIRPORT_REQUESTER);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.airportRequesterCreate'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },

  [AIRPORT_REQUESTER_UPDATE]: ({ commit, dispatch }, requester) => {
    commit(REQUEST_START);
    return HTTP.put('airport-requesters', requester)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(UPDATE_AIRPORT_REQUESTER_SUCCESS);
        dispatch(GET_AIRPORT_REQUESTER);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.airportRequesterUpdate'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
  [AIRPORT_REQUESTER_DELETE]: ({ commit, dispatch }, id) => {
    commit(REQUEST_START);
    return HTTP.delete(`airport-requesters/${id}`)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(UPDATE_AIRPORT_REQUESTER_SUCCESS);
        dispatch(GET_AIRPORT_REQUESTER);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.airportRequesterDelete'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
  [AIRPORT_REQUESTER_DISABLE]: ({ commit, dispatch }, id) => {
    commit(REQUEST_START);
    return HTTP.patch(`/airport-requesters/disable/${id}`)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(UPDATE_AIRPORT_REQUESTER_SUCCESS);
        dispatch(GET_AIRPORT_REQUESTER);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.airportRequesterDisable'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
  [AIRPORT_REQUESTER_ENABLE]: ({ commit, dispatch }, id) => {
    commit(REQUEST_START);
    return HTTP.patch(`/airport-requesters/enable/${id}`)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(UPDATE_AIRPORT_REQUESTER_SUCCESS);
        dispatch(GET_AIRPORT_REQUESTER);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.airportRequesterEnable'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
};

// mutations
const storeMutations = {
  [GET_AIRPORT_REQUESTER_SUCCESS]: (state, airportRequester) => {
    state.airportRequester = airportRequester;
  },
  [GET_AIRPORT_REQUESTER_REQUESTED]: (state) => {
    state.airportRequesterRequested = true;
  },
  [UPDATE_AIRPORT_REQUESTER_SUCCESS]: (state) => {
    state.airportRequesterRequested = false;
  },
  [CHANGE_AIRPORT_REQUESTER_SELECTION]: (state, selection) => {
    state.selectedAirportRequester = selection;
  },
  [CHANGED_TENANT_AIRPORT_REQUESTER]: (state) => {
    state.airportRequesterRequested = false;
  },
};

export default {
  state: storeState,
  getters: storeGetters,
  actions: storeActions,
  mutations: storeMutations,
};
