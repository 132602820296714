<template>
  <v-card-text>
    <v-container fluid style="margin-bottom: 0; padding-bottom: 0">
      <v-layout style="margin-bottom: 0; padding-bottom: 0">
        <legend style="color: gray;">{{$t("configuration.general")}}</legend>
        <legend style="margin-left: 46vh; color: gray;">{{$t("configuration.epg")}}</legend>
        <legend style="margin-left: 15.5vh; color: gray;">{{$t("configuration.realTime")}}</legend>
      </v-layout>
      <v-layout style="margin-bottom: 0; padding-bottom: 0">
        <v-flex xs2 style="margin-right: 2ch;">
          <v-text-field :label="this.$i18n.t('configuration.name')" v-model="item.name" :readonly="!hasPermissions(rolePermission)"/>
        </v-flex>

        <v-flex xs4  style="margin-right: 2ch;">
          <v-text-field :label="this.$i18n.t('configuration.description')" v-model="item.description" :readonly="!hasPermissions(rolePermission)"/>
        </v-flex>

        <v-flex xs2  style="margin-right: 2ch;">
          <v-text-field :label="this.$i18n.t('configuration.serviceRampId')" v-model="item.identifier" :readonly="!hasPermissions(rolePermission)"/>
        </v-flex>
        <v-flex xs3 style="margin-right: 2ch;">
          <ramp-service-type-select :label="this.$i18n.t('configuration.serviceRampType')" v-model="item.type" :readonly="!hasPermissions(rolePermission)" :rules="[rules.required]"/>
        </v-flex>

        <v-flex xs1 style="margin-right: 2ch">
          <v-checkbox v-model="item.directDelivery" :label="this.$i18n.t('configuration.directDelivery')" color="primary" :disabled="!hasPermissions(rolePermission)"></v-checkbox>
        </v-flex>
      </v-layout>

      <b>{{$t("configuration.fieldsDefinition")}}</b>

       <fieldset style="border-width: 0.005vh; border-style: solid;">
        <legend style="margin-left: 1vh; color: gray;">{{$t("configuration.general")}}</legend>
        <v-layout align-center justify-space-between row style="margin-left: 1vh;">
          <v-flex xs4>
            <v-layout class="definition-fields" align-center justify-space-between row>
              <v-flex xs6>
                <span>{{$t("configuration.requestTime")}}:</span>
              </v-flex>
              <v-flex xs6>
                <v-checkbox class="checkbox" v-model="item.requestTimeRequired" :label="this.$i18n.t('configuration.isRequired')" color="primary" :disabled="!hasPermissions(rolePermission)"></v-checkbox>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-layout align-center justify-space-between row style="margin-left: 1vh;">
          <v-flex xs4>
            <v-layout class="definition-fields" align-center justify-space-between row>
              <v-flex xs6>
                <span>{{$t("configuration.airportRequester")}}:</span>
              </v-flex>
              <v-flex xs6>
                <v-checkbox class="checkbox" v-model="item.requesterRequired" :label="this.$i18n.t('configuration.isRequired')" color="primary" :disabled="!hasPermissions(rolePermission)"></v-checkbox>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>

        <v-layout align-center justify-space-between row style="margin-left: 1vh;">
          <v-flex xs7>
            <v-layout class="definition-fields" align-center justify-space-between row>
              <v-flex xs4>
                <span>{{$t("configuration.units")}}:</span>
              </v-flex>
              <v-flex xs4>
                <v-checkbox class="checkbox"  v-model="item.unitsRequired" :label="this.$i18n.t('configuration.isRequired')" color="primary" :disabled="!hasPermissions(rolePermission)"></v-checkbox>
              </v-flex>
              <v-flex xs6>
                <v-text-field :label="this.$i18n.t('configuration.defaultValue')" type="number" v-model="item.unitsDefault" :readonly="!hasPermissions(rolePermission)"/>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-layout align-center justify-space-between row style="margin-left: 1vh;">
          <v-flex xs8>
            <v-layout class="definition-fields" align-center justify-space-between row>
              <v-flex xs3>
                <span>{{$t("configuration.remarks")}}:</span>
              </v-flex>
              <v-flex xs3>
                <v-checkbox  class="checkbox" v-model="item.remarksRequired" :label="this.$i18n.t('configuration.isRequired')" color="primary" :disabled="!hasPermissions(rolePermission)"></v-checkbox>
              </v-flex>
              <v-flex xs5>
                <v-text-field :label="this.$i18n.t('configuration.defaultValue')" type="text" v-model="item.remarksDefault" :readonly="!hasPermissions(rolePermission)"/>
              </v-flex>
              <v-flex xs1>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="bggreydark"
                      dark
                      v-bind="attrs"
                      v-on="on"
                    >
                      info_outline
                    </v-icon>
                  </template>
                  <v-layout><span>{{$t("configuration.reservedWords")}}</span></v-layout>
                  <v-layout><span>{{$t("configuration.emailUserPattern")}}</span></v-layout>
                </v-tooltip>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>

        <v-layout align-center justify-space-between row style="margin-left: 1vh;">
          <v-flex xs7 style="margin-right: 2ch;">
            <v-layout class="definition-fields" align-center justify-space-between row>
              <v-flex xs4>
                <span>{{$t("configuration.orientation")}}:</span>
              </v-flex>
              <v-flex xs4 style="margin-right: 4ch;">
                <v-checkbox class="checkbox"  v-model="item.orientationRequired" :label="this.$i18n.t('configuration.isRequired')" color="primary" :disabled="!hasPermissions(rolePermission)"></v-checkbox>
              </v-flex>
              <v-flex xs6>
                <orientation-select :label="$i18n.t('common.orientation')" v-model="item.orientationDefault" :disabled="!hasPermissions(rolePermission)"></orientation-select>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>

        <v-layout class="definition-fields" align-center justify-space-between row style="margin-left: 1vh;">
          <v-flex xs2>
            <span>{{$t("configuration.requestStartTime")}}:</span>
          </v-flex>
          <v-flex xs2>
            <v-checkbox class="checkbox" v-model="item.requestStartTimeRequired" :label="this.$i18n.t('configuration.isRequired')" color="primary" :disabled="!hasPermissions(rolePermission)"></v-checkbox>
          </v-flex>
          <v-flex xs2>
            <v-checkbox class="checkbox" v-model="item.requestedTsDefault" :label="this.$i18n.t('configuration.useDefaultValue')" color="primary" :disabled="!hasPermissions(rolePermission)"></v-checkbox>
          </v-flex>
          <v-flex xs6>
            <v-layout class="definition-fields" align-center justify-space-between row>
              <v-flex xs3>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <span v-on="on">{{$t("configuration.defaultValue")}}*:</span>
                  </template>
                  <v-layout><span>{{$t("configuration.defaultValueMinutesExplanation")}}</span></v-layout>
                </v-tooltip>
              </v-flex>
              <v-flex xs2 style="margin-right: 2ch;">
                <v-text-field :label="this.$i18n.t('configuration.minutesDefault')" type="number" v-model="item.requestedTsDefaultMinutes" :readonly="!hasPermissions(rolePermission)" :disabled="!item.requestedTsDefault"/>
              </v-flex>
              <v-flex xs6>
                <v-radio-group class="radio-group" row v-model="item.requestedTsDefaultType" :disabled="!item.requestedTsDefault">
                  <v-radio :label="this.$i18n.t('configuration.scheduledTime')" color="primary" :value="rampServiceTimeTypes.ST"></v-radio>
                  <v-radio :label="this.$i18n.t('configuration.estimatedTime')" color="primary" :value="rampServiceTimeTypes.ET"></v-radio>
                </v-radio-group>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>

        <v-layout class="definition-fields" align-center justify-space-between row style="margin-left: 1vh;">
          <v-flex xs2>
            <span>{{$t("configuration.requestEndTime")}}:</span>
          </v-flex>
          <v-flex xs2>
            <v-checkbox class="checkbox" v-model="item.requestEndTimeRequired" :label="this.$i18n.t('configuration.isRequired')" color="primary" :disabled="!hasPermissions(rolePermission)"></v-checkbox>
          </v-flex>
          <v-flex xs2>
            <v-checkbox class="checkbox" v-model="item.requestedEndTsDefault" :label="this.$i18n.t('configuration.useDefaultValue')" color="primary" :disabled="!hasPermissions(rolePermission)"></v-checkbox>
          </v-flex>
          <v-flex xs6>
            <v-layout class="definition-fields" align-center justify-space-between row>
              <v-flex xs3>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <span v-on="on">{{$t("configuration.defaultValue")}}*:</span>
                  </template>
                  <v-layout><span>{{$t("configuration.defaultValueMinutesExplanation")}}</span></v-layout>
                </v-tooltip>
              </v-flex>
              <v-flex xs2 style="margin-right: 2ch;">
                <v-text-field :label="this.$i18n.t('configuration.minutesDefault')" type="number" v-model="item.requestedEndTsDefaultMinutes" :readonly="!hasPermissions(rolePermission)" :disabled="!item.requestedEndTsDefault"/>
              </v-flex>
              <v-flex xs6>
                <v-radio-group class="radio-group" row v-model="item.requestedEndTsDefaultType" :disabled="!item.requestedEndTsDefault">
                  <v-radio :label="this.$i18n.t('configuration.scheduledTime')" color="primary" :value="rampServiceTimeTypes.ST"></v-radio>
                  <v-radio :label="this.$i18n.t('configuration.estimatedTime')" color="primary" :value="rampServiceTimeTypes.ET"></v-radio>
                </v-radio-group>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>

        <v-layout class="definition-fields" align-center justify-space-between row style="margin-left: 1vh;">
          <v-flex xs2>
            <span>{{$t("configuration.startTime")}}:</span>
          </v-flex>
          <v-flex xs2>
            <v-checkbox class="checkbox" v-model="item.startTimeRequired" :label="this.$i18n.t('configuration.isRequired')" color="primary" :disabled="!hasPermissions(rolePermission)"></v-checkbox>
          </v-flex>
          <v-flex xs2>
            <v-checkbox class="checkbox" v-model="item.startTsDefault" :label="this.$i18n.t('configuration.useDefaultValue')" color="primary" :disabled="!hasPermissions(rolePermission)"></v-checkbox>
          </v-flex>
          <v-flex xs6>
            <v-layout class="definition-fields" align-center justify-space-between row>
              <v-flex xs3>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <span v-on="on">{{$t("configuration.defaultValue")}}*:</span>
                  </template>
                  <v-layout><span>{{$t("configuration.defaultValueMinutesExplanation")}}</span></v-layout>
                </v-tooltip>
              </v-flex>
              <v-flex xs2 style="margin-right: 2ch;">
                <v-text-field :label="this.$i18n.t('configuration.minutesDefault')" type="number" v-model="item.startTsDefaultMinutes" :readonly="!hasPermissions(rolePermission)" :disabled="!item.startTsDefault"/>
              </v-flex>
              <v-flex xs6>
                <v-radio-group class="radio-group" row v-model="item.startTsDefaultType" :disabled="!item.startTsDefault">
                  <v-radio :label="this.$i18n.t('configuration.scheduledTime')" color="primary" :value="rampServiceTimeTypes.ST"></v-radio>
                  <v-radio :label="this.$i18n.t('configuration.estimatedTime')" color="primary" :value="rampServiceTimeTypes.ET"></v-radio>
                </v-radio-group>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>

        <v-layout class="definition-fields" align-center justify-space-between row style="margin-left: 1vh;">
          <v-flex xs2>
            <span>{{$t("configuration.finishTime")}}:</span>
          </v-flex>
          <v-flex xs2>
            <v-checkbox class="checkbox" v-model="item.finishTimeRequired" :label="this.$i18n.t('configuration.isRequired')" color="primary" :disabled="!hasPermissions(rolePermission)"></v-checkbox>
          </v-flex>
          <v-flex xs2>
            <v-checkbox class="checkbox" v-model="item.finishTsDefault" :label="this.$i18n.t('configuration.useDefaultValue')" color="primary" :disabled="!hasPermissions(rolePermission)"></v-checkbox>
          </v-flex>
          <v-flex xs6>
            <v-layout class="definition-fields" align-center justify-space-between row>
              <v-flex xs3>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <span v-on="on">{{$t("configuration.defaultValue")}}*:</span>
                  </template>
                  <v-layout><span>{{$t("configuration.defaultValueMinutesExplanation")}}</span></v-layout>
                </v-tooltip>
              </v-flex>
              <v-flex xs2 style="margin-right: 2ch;">
                <v-text-field :label="this.$i18n.t('configuration.minutesDefault')" type="number" v-model="item.finishTsDefaultMinutes" :readonly="!hasPermissions(rolePermission)" :disabled="!item.finishTsDefault"/>
              </v-flex>
              <v-flex xs6>
                <v-radio-group class="radio-group" row v-model="item.finishTsDefaultType" :disabled="!item.finishTsDefault">
                  <v-radio :label="this.$i18n.t('configuration.scheduledTime')" color="primary" :value="rampServiceTimeTypes.ST"></v-radio>
                  <v-radio :label="this.$i18n.t('configuration.estimatedTime')" color="primary" :value="rampServiceTimeTypes.ET"></v-radio>
                </v-radio-group>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </fieldset>

      <fieldset style="border-width: 0.005vh; border-style: solid;">
        <legend style="margin-left: 1vh; color: gray;">{{$t("configuration.realTime")}}</legend>
        <v-layout align-center justify-space-between row style="margin-left: 1vh;">
          <v-flex xs4>
            <v-layout class="definition-fields" align-center justify-space-between row>
              <v-flex xs6>
                <span>{{$t("configuration.origin")}}:</span>
              </v-flex>
              <v-flex xs6>
                <v-checkbox class="checkbox" v-model="item.originRequired" :label="this.$i18n.t('configuration.isRequired')" color="primary" :disabled="!hasPermissions(rolePermission)"></v-checkbox>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-layout align-center justify-space-between row style="margin-left: 1vh;">
          <v-flex xs4>
            <v-layout class="definition-fields" align-center justify-space-between row>
              <v-flex xs6>
                <span>{{$t("configuration.destination")}}:</span>
              </v-flex>
              <v-flex xs6>
                <v-checkbox class="checkbox" v-model="item.destinationRequired" :label="this.$i18n.t('configuration.isRequired')" color="primary" :disabled="!hasPermissions(rolePermission)"></v-checkbox>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </fieldset>
    </v-container>
  </v-card-text>
</template>
<script>
import RolePermissions from '../../mixins/RolePermissions.vue';
import RampServiceTypeSelect from '../../ui/rampServiceTypeSelector/RampServiceTypeSelect.vue';
import DateTimeInput from '../../ui/DateTimeInput.vue';
import { RampServiceTimeTypes } from './RampServiceTimeTypes';
import OrientationSelect from '../../rampService/OrientationSelect.vue';

export default {
  name: 'RampServiceEditCardContent',
  mixins: [RolePermissions],
  props: {
    item: { type: Object, required: true },
  },
  components: {
    RampServiceTypeSelect,
    DateTimeInput,
    OrientationSelect,
  },
  computed: {
    rolePermission() {
      return `${this.$route.meta.role}_WR`;
    },
  },
  data() {
    return {
      rampServiceTimeTypes: RampServiceTimeTypes,
      rules: {
        required: value => !this.isSCL() || !!value || 'Required.',
      },
    };
  },
  methods: {
    initializeDefaultTime() {
      if (!this.item.requestedTsDefaultMinutes) {
        this.item.requestedTsDefaultMinutes = 0;
      }

      if (!this.item.requestedTsDefaultType) {
        this.item.requestedTsDefaultType = this.rampServiceTimeTypes.ST;
      }

      if (!this.item.requestedEndTsDefaultMinutes) {
        this.item.requestedEndTsDefaultMinutes = 0;
      }

      if (!this.item.requestedEndTsDefaultType) {
        this.item.requestedEndTsDefaultType = this.rampServiceTimeTypes.ST;
      }

      if (!this.item.startTsDefaultMinutes) {
        this.item.startTsDefaultMinutes = 0;
      }

      if (!this.item.startTsDefaultType) {
        this.item.startTsDefaultType = this.rampServiceTimeTypes.ST;
      }

      if (!this.item.finishTsDefaultMinutes) {
        this.item.finishTsDefaultMinutes = 0;
      }

      if (!this.item.finishTsDefaultType) {
        this.item.finishTsDefaultType = this.rampServiceTimeTypes.ST;
      }
    },
    isSCL() {
      return this.$store.getters.isSCL;
    },
  },
  created() {
    this.initializeDefaultTime();
  },
};
</script>
<style lang="scss" scoped>
  .definition-fields {
    // margin-bottom: 0;
    // padding-bottom: 0;
  }
  .fields-required {
    margin-bottom: 0;
    padding-bottom: 0;
  }
  .layout-row-fields-required {
    margin-bottom: 0;
    padding-bottom: 0;
  }
  .checkbox{
    margin: 0;
    padding: 0;
  }
  .radio-group {
    margin: 0;
  }
</style>
