import moment from 'moment';

export default function agGridTimeFormatter(params) {
  if (params && params.value) {
    if (params.value.length === 5 && params.value.contains(':')) {
      return params.value;
    }
    const momentValue = moment(params.value);
    return momentValue.format('HH:mm');
  }
  return params.value;
}
