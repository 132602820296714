<template>
    <v-container fluid grid-list-xs class="contentData">
      <v-form ref="formOptions">
      <v-layout row justify-center>
        <v-flex xs2>
          <date-input v-char-length.date v-model="importOptions.fromDate" :locale="$i18n.locale" :label="this.$i18n.t('common.fromDate')" :rules="dateFromRules"></date-input>
        </v-flex>
        <v-flex xs2>
          <date-input v-char-length.date v-model="importOptions.toDate" :locale="$i18n.locale" :label="this.$i18n.t('common.toDate')" :rules="dateToRules"></date-input>
        </v-flex>
        <v-flex xs2>
          {{$t("schedulingImport.dateMinInFile")}}
          {{minDate}}
        </v-flex>
        <v-flex xs2>
          {{$t("schedulingImport.dateMaxInFile")}}
          {{maxDate}}
        </v-flex>
        <v-flex xs2 v-if="importParsedFile && importParsedFile.rampServices">
          {{$t("rampServiceImport.rampServicesRead")}}
          <ul>
            <li v-for="(rampService, index) in importParsedFile.rampServices" :key="index">
              {{ rampService.serviceIdentifier }} - {{ rampService.serviceName }}
            </li>
          </ul>
        </v-flex>
      </v-layout>
      </v-form>
      <v-flex xs12 v-if="importParsedFile && (!importParsedFile.rampServices || importParsedFile.rampServices.length == 0)">
        <div>
          <v-icon color="primary">mdi-alert</v-icon>
          <span class="font-weight-bold">{{$t("rampServiceImport.notFoundRampServices")}}</span>
        </div>
      </v-flex>
      <v-flex xs12 v-if="importParsedFile && importParsedFile.areThereRampServicesThatNotFound()">
        <div>
          <v-icon color="primary">mdi-alert</v-icon>
          <span class="font-weight-bold">{{$t("rampServiceImport.notFoundRampServicesInTenant")}}</span>
        </div>
        <ul>
         <li v-for="(rampService, index) in importParsedFile.rampServicesNotFound" :key="index" class="text-error">
            {{ rampService }}
         </li>
        </ul>
      </v-flex>
      <v-flex xs12 v-if="importParsedFile && importParsedFile.errors && importParsedFile.errors.length > 0">
        <div>
          <v-icon color="primary">mdi-alert</v-icon>
          <span class="font-weight-bold">{{$t("rampServiceImport.errorsImportFile")}}</span>
        </div>
        <ul>
         <li v-for="(error, index) in importParsedFile.errors" :key="index" class="text-error">
            {{ error }}
         </li>
        </ul>
      </v-flex>

    </v-container>


</template>
<script>

import DateInput from '../../ui/DateInput.vue';
import RampServiceImportOptions from './model/rampServiceImportOptions';
import RampServiceImportParsedFile from './model/rampServiceImportParsedFile';

export default {
  name: 'ramp-service-import-step-options-filter',
  components: {
    DateInput,
  },
  props: {
    importOptions: {
      type: RampServiceImportOptions,
      required: true,
    },
    importParsedFile: {
      type: RampServiceImportParsedFile,
    },
  },
  computed: {
    minDate() {
      return this.importParsedFile && this.importParsedFile.minDate ? this.$moment(this.importParsedFile.minDate).format('DD-MM-YYYY') : null;
    },
    maxDate() {
      return this.importParsedFile && this.importParsedFile.maxDate ? this.$moment(this.importParsedFile.maxDate).format('DD-MM-YYYY') : null;
    },
  },
  watch: {
  },
  data() {
    return {
      dateFromRules: [
        v => !!v || this.$i18n.t('common.fieldRequired'),
      ],
      dateToRules: [
        v => !!v || this.$i18n.t('common.fieldRequired'),
      ],
    };
  },
  methods: {
  },
};
</script>
<style lang="scss" scoped>

  .text-error {
    padding: 2px;
  }
  .contentData {
    max-height: 60vh;
    overflow-y: auto;
  }
</style>
