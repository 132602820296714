<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <v-layout row column>
      <v-flex>
        <v-autocomplete
          v-model="select"
          :items="items"
          :label="this.$i18n.t('commonForm.selectAirport')"
          prepend-icon="local_airport"
          :filter="customFilter"
          :disabled="disabled"
          :rules="rules"
          @input="emitInput"
          multiple
          small-chips
          deletable-chips
          return-object
          item-text="name"
        >
          <template v-slot:prepend-item>
            <v-list-tile
              ripple
              @click="toggle"
            >
              <v-list-tile-action>
                <v-icon :color="select.length === items.length ? 'red' : 'grey'">done_all</v-icon>
              </v-list-tile-action>
              <v-list-tile-content>
                <v-list-tile-title>{{select.length === items.length ? $t('common.unCheck') : $t('common.check')}}</v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
            <v-divider class="mt-2"></v-divider>
          </template>
          <template
            slot="item"
            slot-scope="{ item, tile }"
          >
            <v-list-tile-content>
              <v-list-tile-title v-text="item.name"></v-list-tile-title>
              <v-list-tile-sub-title v-text="item.description"></v-list-tile-sub-title>
            </v-list-tile-content>
          </template>
        </v-autocomplete>
      </v-flex>
    </v-layout>
</template>

<script>
import { TENANT_LIST } from '../../store/modules/tenant';

export default {
  name: 'TentantMultipleSelector',
  props: {
    value: { },
    disabled: { type: Boolean },
    rules: { type: Array },
  },
  data: () => ({
    select: [],
  }),
  computed: {
    items() {
      return this.$store.getters.getTenant;
    },
  },
  methods: {
    getTenants() {
      return this.$store.dispatch(TENANT_LIST);
    },
    customFilter(item, queryText) {
      const description = item.description ? item.description.toLowerCase() : '';
      const name = item.name ? item.name.toLowerCase() : '';
      const country = item.country ? item.country.toLowerCase() : '';
      const searchText = queryText.toLowerCase();

      return description.indexOf(searchText) > -1 || name.indexOf(searchText) > -1 || country.indexOf(searchText) > -1;
    },
    emitInput(event) {
      if (typeof (event) !== 'undefined') {
        this.$emit('input', event);
      } else {
        this.$emit('input', null);
      }
    },
    toggle() {
      this.$nextTick(() => {
        if (this.select.length === this.items.length) {
          this.select = [];
        } else {
          this.select = this.items.slice();
        }
        this.emitInput(this.select);
      });
    },
  },
  created() {
    this.getTenants();
    this.select = this.value ? this.value : [];
  },
};
</script>
