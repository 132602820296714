/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'flights': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" _fill="none" d="M0 0h24v24H0V0z"/><path pid="1" _fill="none" d="M8.813 2.813h24v24h-24v-24z"/><path pid="2" d="M15.232 16.184l-2.586-.809v3.045l.981.736.312-.09 3.018.862v-1.293l-1.725-1.293zM15.232 8.723v-4.74c0-.715-.578-1.292-1.293-1.292s-1.293.577-1.293 1.292v4.74l-.002.001v1.079l6.373 3.982 3.109.973v-1.725l-6.894-4.31z"/><path pid="3" d="M18.248 16.139v-1.725l-6.895-4.309v-4.74c0-.715-.578-1.292-1.293-1.292s-1.292.576-1.292 1.292v4.74l-6.895 4.309v1.725l6.895-2.156v4.74l-1.724 1.293v1.293l3.017-.86 3.017.86v-1.293l-1.724-1.293v-4.74l6.894 2.156z"/>'
  }
})
