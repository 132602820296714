export default class PossiblySameFlight {
  constructor(left, right, leftOperation, rightOperation, tagLeft, tagRight, action) {
    this.left = left;
    this.right = right;
    this.leftOperation = leftOperation;
    this.rightOperation = rightOperation;
    this.tagLeft = tagLeft;
    this.tagRight = tagRight;
    this.action = action;
  }
}
export const PossiblySameFlightAction = {
  SAME: 'SAME',
  DISTINCT: 'DISTINCT',
};
