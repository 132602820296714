<template>
  <v-fab-transition v-if="hasPermissions(rolePermission)">
    <v-speed-dial
      v-if="false"
      fixed bottom right
      v-model="fabSingle"
      :key="activeSpeedDial.key"
      direction="top"
      :open-on-hover="activeSpeedDial.hover"
      :transition="activeSpeedDial.transition"
    >
      <v-btn
        v-model="fabSingle"
        slot="activator"
        color="primary"
        dark
        fab
      >
        <v-icon>add</v-icon>
        <v-icon>close</v-icon>
      </v-btn>

      <v-tooltip left>
        <v-btn
          slot="activator"
          fab
          dark
          small
          color="secondary">
          <v-icon>flight</v-icon>
        </v-btn>
        <span>{{$t("scheduling.newFlight")}}</span>
      </v-tooltip>

    </v-speed-dial>

    <v-speed-dial
      v-else-if="selectionMode"
      fixed bottom right
      v-model="fabSelection"
      :key="activeSpeedDial.key"
      direction="left"
      :open-on-hover="activeSpeedDial.hover"
      :transition="activeSpeedDial.transition"
    >
      <v-btn
        v-model="fabSelection"
        slot="activator"
        color=""
        dark
        fab
      >
        <v-icon>build</v-icon>
        <v-icon>close</v-icon>
      </v-btn>

      <v-tooltip top>
        <v-btn
          slot="activator"
          fab
          dark
          small
          color=""
          @click="clickArchive">
          <v-icon>archive</v-icon>
        </v-btn>
        <span>{{$t("common.archiveSelectedFlights")}}</span>
      </v-tooltip>

      <v-tooltip top>
        <v-btn
          slot="activator"
          fab
          dark
          small
          color=""
          @click="clickUnarchive">
          <v-icon>unarchive</v-icon>
        </v-btn>
        <span>{{$t("common.unArchiveSelectedFlights")}}</span>
      </v-tooltip>

    </v-speed-dial>
  </v-fab-transition>
</template>
<script>
import RolePermissions from '../../mixins/RolePermissions.vue';
import { NOTIFICATION_SHOW } from '../../../store/modules/notifications';
import FlightsArchivable from '../../mixins/FlightsArchivable.vue';

export default {
  mixins: [RolePermissions, FlightsArchivable],
  data: () => ({
    fabSingle: false,
    fabSelection: false,
  }),
  computed: {
    selectionMode() {
      return this.$store.getters.hasSelectedPassageFlights;
    },
    activeSpeedDial() {
      if (this.selectionMode) {
        return {
          key: 'selection-speed-dial',
          direction: 'left',
          hover: false,
          transition: 'slide-y-reverse-transition',
        };
      }
      return {
        key: 'single-speed-dial',
        direction: 'top',
        hover: false,
        transition: 'slide-x-reverse-transition',
      };
    },
    rolePermission() {
      return `${this.$route.meta.role}_WR`;
    },
    flightsSelected() {
      return this.$store.state.passageOutbound.selectedFlights;
    },
  },
  methods: {
    clickArchive() {
      const archivableFlights = this.getArchivableFlights(this.flightsSelected);

      if (archivableFlights.length === this.flightsSelected.length) {
        this.$emit('click-archive');
      } else {
        this.$store.commit(NOTIFICATION_SHOW, {
          message: this.$i18n.t('operations.archiveValidation'),
          color: 'error',
        });
      }
    },
    clickUnarchive() {
      this.$emit('click-unarchive');
    },
  },
};
</script>
