<template>
  <div>
    <v-select
      :label="label"
      :items="items"
      itemText="name"
      itemValue="value"
      :value="initialValue"
      :clearable="false"
      :disabled="disabled"
      @input="emitInput"
      :menu-props="{ zIndex: '1000' }"
      ref="refComp"
    >
      <template slot="item" slot-scope="{ item }">
        <v-list-tile-content>
          <v-list-tile-title>{{ item.name }}</v-list-tile-title>
        </v-list-tile-content>
      </template>
    </v-select>
  </div>
</template>

<script>
import Vue from 'vue';
import { MilestonesPermissions } from '../shared/model/milestoneTypes';

export default Vue.extend({
  props: {
    value: { required: true },
    label: { type: String },
    charLenght: { type: Number, default: 6 },
    rules: { type: Array },
    disabled: { type: Boolean, default: false },
    refComp: {
      type: String,
      required: false,
      default: 'refComp',
    },
  },
  data() {
    return {
      items: [
        { name: this.$i18n.t(`roles.${MilestonesPermissions.VIEW}`), value: MilestonesPermissions.VIEW },
        { name: this.$i18n.t(`roles.${MilestonesPermissions.EDIT}`), value: MilestonesPermissions.EDIT },
        { name: this.$i18n.t(`roles.${MilestonesPermissions.NONE}`), value: MilestonesPermissions.NONE },
      ],
    };
  },
  computed: {
    initialValue() {
      // Find the item that matches the initial value by comparing the value property
      return this.items.find(item => item.value === this.value) || null;
    },
  },
  methods: {
    emitInput(event) {
      if (typeof event !== 'undefined') {
        this.$emit('input', event);
      } else {
        this.$emit('input', null);
      }
    },
  },
});
</script>

<style lang="scss">
.v-autocomplete .v-autocomplete-list {
  z-index: 1500;
}
</style>
