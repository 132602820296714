<template>
  <div>
    <v-autocomplete
      :value="value"
      :items="items"
      :label="$t('commonForm.flight')"
      :placeholder="$t('commonForm.selectFlight')"
      prepend-icon="local_airport"
      :filter="customFilter"
      :rules="rules"
      item-value="id"
      :ref="'refSelectFlight'"
      :clearable="true"
      @input="inputFlight($event)"
      @change="onChangeSelection"
    >
      <template v-slot:selection="data">{{ data.item.flightNo() }}</template>
      <template v-slot:item="data">
        <v-list-tile-content>
          <v-list-tile-title v-html="data.item.flightNo()"/>
          <v-list-tile-sub-title v-html="`Flight date: ${customFormatDate(data.item.scheduledDate())} - Airline: ${data.item.airlineCode} - From/To: ${data.item.airportsIata()}`"/>
        </v-list-tile-content>
        <v-list-tile-action>
          <v-icon>{{getIconBound(data.item.bound)}}</v-icon>
        </v-list-tile-action>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>

export default {
  name: 'flight-selector',
  data: () => ({
    select: [],
  }),
  props: {
    value: { required: true },
    items: { type: Array, required: true },
    rules: { type: Array },
    valueId: { },
  },
  computed: {

  },
  methods: {
    inputFlight(event) {
      this.$emit('input', event);
    },
    onChangeSelection(selectionId) {
      const flight = this.items.filter(f => f.id === selectionId)[0];
      this.$emit('change', flight);
    },
    selectItem(item) {
      this.$emit('flight', item);
    },
    customFilter(item, queryText) {
      const flightNo = item.flightNo() ? item.flightNo().toLowerCase() : '';
      const scheduledDate = item.scheduledDate() ? item.scheduledDate().toLowerCase() : '';
      const airline = item.airlineCode ? item.airlineCode.toLowerCase() : '';
      const airports = item.airportsIata() ? item.airportsIata().toLowerCase() : '';
      const searchText = queryText.toLowerCase();

      return flightNo.indexOf(searchText) > -1 || scheduledDate.indexOf(searchText) > -1 || airline.indexOf(searchText) > -1 || airports.indexOf(searchText) > -1;
    },
    customFormatDate(date) {
      return date.length === 10 ? date : this.$moment(date).format('HH:mm DD/MM/YYYY');
    },
    getIconBound(bound) {
      return bound === 'INBOUND' ? 'flight_land' : 'flight_takeoff';
    },
  },
  watch: {
    select(item) {
      this.selectItem(item);
    },
    items() {
      if (this.value) {
        this.select = this.items.find(f => f.id === this.value);
      }
    },
  },
  created() {
    if (this.valueId) {
      this.select = this.items.find(f => f.id === this.valueId);
    }
  },
};
</script>
