export default class AuditFilter {
  constructor(changesDateFrom, changesDateTo, flightDate, airline, tripNumber, timezoneDate) {
    this.changesDateFrom = changesDateFrom;
    this.changesDateTo = changesDateTo;
    this.flightDate = flightDate;
    this.airline = airline;
    this.tripNumber = tripNumber;
    this.timezoneDate = timezoneDate;
  }

  static get createEmptyFilter() {
    return new AuditFilter(null, null, null, null, null, null);
  }
}
