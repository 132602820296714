<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <slot>
      {{value}}
    </slot>
    <v-tooltip bottom v-if="ischanged" class="tooltip-changes" :min-width="250">
      <v-icon class="change-mark" :disabled="oldValuesRelatedFieldsList.length === 0" slot="activator" color="secondary" small >signal_cellular_4_bar</v-icon>
      <v-container nudge-width="250" class="tooltip-changes-container" pa-1 fluid grid-list-md>
        <h4 text-xs-center>Old values</h4>
        <v-divider></v-divider>
        <v-layout v-for="oldItemValue in oldValuesRelatedFieldsList" :key="oldItemValue.label" row>
          <v-flex xs6>{{oldItemValue.label}} <br><span class="font-italic">(at {{oldItemValue.ts}})</span></v-flex>
          <v-flex xs6 text-xs-right>{{oldItemValue.value}}</v-flex>
        </v-layout>
      </v-container>
    </v-tooltip>
  </div>
</template>

<script>
/* eslint-disable */
// TODO
import Vue from 'vue';
import {JSUtils} from "../../../shared/js/JSUtils";

export default Vue.extend({
  name: 'ag-grid-template-cell-renderer',
  mixins: [],
  props: {
    params: {
      type: Object,
      required: true,
    },
  },
  components: {

  },
  data() {
    return {
      message: null,
      isChangedFlag: false,
      oldValuesRelatedFieldsList: [],
    };
  },
  updated() {
    if (this.params.colDef.colCustomConfig && this.params.colDef.colCustomConfig.fieldsRelated) {
      this.params.colDef.colCustomConfig.fieldsRelated.forEach((field) => {
        if (Object.prototype.hasOwnProperty.call(this.params.node.data, `${field}_old`)) {
          this.isChangedFlag = true;
        }
      });
    }
    return this.isChangedFlag;
  },
  computed: {
    value() {
      if(this.params && this.params.valueFormatted) {
        return this.params.valueFormatted;
      }
      return this.params.value;
    },

    //Fixme: Ver estado del showOldValues de la pantalla que corresponde.
    ischanged() {
      if(!this.$store.state.operation.showOldValues) {
        return false;
      }

      if (this.params.colDef.colCustomConfig && this.params.colDef.colCustomConfig.fieldsRelated) {
        this.params.colDef.colCustomConfig.fieldsRelated.forEach((field) => {
          if (Object.prototype.hasOwnProperty.call(this.params.node.data, `${field}_old`)) {
            this.isChangedFlag = true;
            this.setOldValue();
          }
        });
      }
      return this.isChangedFlag;
    },
  },
  methods: {
    setOldValue() {
      this.params.colDef.colCustomConfig.fieldsRelated.forEach((field) => {
        const fieldsArray = field.split('.');
        let label = fieldsArray[fieldsArray.length - 1];
        label = label.replace(/([A-Z])/g, ' $1')
          .toLowerCase()
          .trim();

        const value = JSUtils.isFunction(this.params.colDef.colCustomConfig.oldValueFormatter) ? this.params.colDef.colCustomConfig.oldValueFormatter({value:this.params.node.data[`${field}_old`]}) : this.params.node.data[`${field}_old`];
        const labelValue = {label: label, value: value, ts: this.$moment().format("HH:mm")};
        if(labelValue.value && labelValue.value !== this.value && !this.oldValuesRelatedFieldsList.find(o => labelValue.label === o.label && labelValue.value === o.value)) {
          this.oldValuesRelatedFieldsList.push(labelValue);
        }
      });
    },
  },
});
</script>

<style scoped lang="scss">

.tooltip-messages {
  position: absolute;
  top: 0;
  right: 15px;
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  transform: rotate(-90deg);

  span {
    position: absolute;
    top: 0;
    right: 0;
  }

  i.change-mark {
    position: absolute;
    top: 0;
    right: 0;
  }
}

.tooltip-changes {
  position: absolute;
  bottom: 0;
  right: 0;

  span {
    position: absolute;
    bottom: 0;
    right: 0;
  }

  i.change-mark {
    position: absolute;
    bottom: 0;
    right: 0;
  }

  .tooltip-changes-container {
    min-width: 350px !important;
  }
}

</style>
