<script>
import Vue from 'vue';
import AgGridApiHoldable from '../../mixins/AgGridApiHoldable.vue';
import AgGridParentConnectedFlightsCellEditor from './AgGridParentConnectedFlightsCellEditor.vue';
import RolePermissions from '../../../mixins/RolePermissions.vue';

export default Vue.extend({
  mixins: [AgGridParentConnectedFlightsCellEditor, AgGridApiHoldable, RolePermissions],
  data() {
    return {
      isDialogOpen: false,
      isSaving: false,
    };
  },
  methods: {
    cancel() {
      this.params.api.stopEditing();
      this.isDialogOpen = false;
    },
    save() {
      this.isSaving = true;
      this.stopEditing(); // internal gridApi, if any
      this.params.api.stopEditing(); // caller gridApi
    },
    dialogRequestSuccessCallback(context, resp) {
      this.requestSuccessCallback(context, resp);
      this.isDialogOpen = false;
    },
    stopEditing() {
      if (this.gridApi) {
        this.gridApi.stopEditing();
      }
    },
  },
  created() {
    this.isDialogOpen = true;
  },
});
</script>
