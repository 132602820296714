<template>
  <div class="flight-messages list-filter">
    <v-card :dark="this.$vuetify.dark" :color="this.$vuetify.dark ? 'bggrey_dark' : 'bggrey'">
      <v-toolbar dark dense color="primary lighten-1">
        <v-btn v-on:click="displayedFilter" small flat>
          <v-icon>filter_list</v-icon>
          <v-icon v-if="!showFilter">keyboard_arrow_down</v-icon>
          <v-icon v-if="showFilter">keyboard_arrow_up</v-icon>
        </v-btn>

        <v-divider vertical light style="margin-left: 30px; margin-right: 30px"></v-divider>

        <flight-messages-list-filter-date-selector />

        <v-spacer></v-spacer>

      </v-toolbar>
      <v-container fluid v-if="showFilter" class="pa-2 pb-2">
        <v-layout class="filter-inline py-0" align-center justify-start>
          <v-flex class="mr-2" align-center justify-start>
            <v-switch
              slot="activator"
              color="red"
              :label="'Only without flights'"
              v-model="filter.onlyWithoutFlights">
            </v-switch>
          </v-flex>

          <v-spacer style="width: 45%"></v-spacer>
          <v-flex class="ml-2 text-no-wrap" align-center justify-start>
            <v-btn color="secondary" flat round v-on:click.stop="exportCSV" block>
              <v-icon>mdi-download</v-icon>
              {{ $t("common.export") }}
            </v-btn>
          </v-flex>
          <v-flex class="ml-2 text-no-wrap" align-center justify-start>
          <v-btn color="primary" round v-on:click.stop="find" block>
            <v-icon>search</v-icon>
            {{ $t("common.find") }}
          </v-btn>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card>
  </div>

</template>
<script>
import FlightMessagesListFilterDateSelector from './FlightMessagesListFilterDateSelector.vue';
import AgGridApiCsvExportable from '../../ag-grid/mixins/AgGridApiCsvExportable.vue';
import StoreSubscribable from '../../mixins/StoreSubscribable.vue';
import {
  MESSAGES_FIND_FLIGHTS,
  MESSAGES_SET_GRID_API,
} from '../../../store/modules/flightMessages/flightMessages';

export default {
  name: 'flight-messages-list-filter',
  mixins: [AgGridApiCsvExportable, StoreSubscribable],
  components: {
    FlightMessagesListFilterDateSelector,
  },
  props: [],
  data() {
    return {
      showFilter: false,
    };
  },
  computed: {
    filter() {
      return this.$store.state.flightMessages.filter;
    },
  },
  methods: {
    find() {
      this.showLoading();
      this.$store.dispatch(MESSAGES_FIND_FLIGHTS);
    },
    exportCSV() {
      this.exportAgGridCsv('FlightMessages');
    },
    displayedFilter() {
      this.showFilter = !this.showFilter;
      this.$emit('changeShowFilter', this.showFilter);
    },
  },
  mounted() {
    const that = this;
    this.subscribeMutation(MESSAGES_SET_GRID_API, (mutationPayload) => {
      that.setAgGridApi(mutationPayload);
    });
  },
};
</script>
