export default class PassageInboundFilter {
  constructor(
    dateFrom,
    dateTo,
    onlyPassageInboundUnarchived,
    onlyCurrentDesk,
    timezoneDate,
    filterDateByEstimatedTime,
  ) {
    this.dateFrom = dateFrom;
    this.dateTo = dateTo;
    this.onlyPassageInboundUnarchived = onlyPassageInboundUnarchived === true;
    this.onlyCurrentDesk = onlyCurrentDesk === true;
    this.timezoneDate = timezoneDate;
    this.filterDateByEstimatedTime = filterDateByEstimatedTime;
  }

  static get createEmptyFilter() {
    return new PassageInboundFilter(null, null, true, false, true);
  }
}
