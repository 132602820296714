export default class AgGridCustomService {
  constructor(columnDefs) {
    this.columnDefs = columnDefs;
  }

  getColIdByMatchAnyfieldsRelated(fieldsRelated) {
    if (!this.columnDefs) {
      throw Error('ColumnDefs parameter is missing!');
    }

    if (!fieldsRelated) {
      throw Error('ColumnDefs wrong parameter: should be string or Array');
    }

    if (!Array.isArray(fieldsRelated)) {
      /* eslint no-param-reassign: "off" */
      fieldsRelated = [fieldsRelated];
    }

    const colIds = [];

    fieldsRelated.forEach((fieldRelated) => {
      this.columnDefs.forEach((colDef) => {
        AgGridCustomService.addColIdIfMatchRelatedField(colDef, fieldRelated, colIds);

        if (colDef.children && colDef.children.length > 0) {
          colDef.children.forEach((colDefChild) => {
            AgGridCustomService.addColIdIfMatchRelatedField(colDefChild, fieldRelated, colIds);
          });
        }
      });
    });

    return [...new Set(colIds)];
  }


  static addColIdIfMatchRelatedField(colDef, fieldRelated, colIds) {
    if (colDef.colCustomConfig && colDef.colCustomConfig.fieldsRelated && colDef.colCustomConfig.fieldsRelated.length > 0) {
      if (colDef.colCustomConfig.fieldsRelated.indexOf(fieldRelated) > -1) {
        colIds.push(colDef.colId);
      }
    }
  }
}
