export default class PaginationRequestProperties {
  constructor(number, size) {
    this.currentPage = number;
    this.pageSize = size;
    // TODO this.sort=sort
  }

  static get createDefaultPaginationRequestProperties() {
    return new PaginationRequestProperties(PaginationRequestProperties.DEFAULT_CURRENT_PAGE, PaginationRequestProperties.MINIMUM_PAGE_SIZE);
  }

  // Static Constants
  static get DEFAULT_CURRENT_PAGE() {
    return 1;
  }

  static get MAXIMUM_PAGE_SIZE() {
    return 150;
  }

  static get MINIMUM_PAGE_SIZE() {
    return 25;
  }
}
