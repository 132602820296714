<template>
    <v-dialog v-model="show" max-width="60%" persistent>
        <v-card :dark="this.$vuetify.dark" :color="this.$vuetify.dark ? 'bggrey_dark' : 'bggrey'">
            <v-toolbar dark color="primary">
                <v-icon>flight</v-icon>
                <v-toolbar-title>{{$t("messages.sender.modalTitle")}} {{flight.flightNo()}}</v-toolbar-title>
            </v-toolbar>

            <v-card-text>
              <v-container grid-list-md text-xs-center fluid>
                <v-layout row wrap>
                  <v-flex xs12>
                    <v-card>
                      <v-card-text>
                        <v-form ref="form" v-model="validForm">
                          <v-container fluid>
                            <v-layout>
                              <v-flex xs2>
                                <message-type-select :label="this.$i18n.t('messages.type')" v-model="message.typeId" @input-element="setType" :rules="[rules.required]"></message-type-select>
                              </v-flex>
                              <v-flex xs4>
                                <avinet-user-selector :label="this.$i18n.t('messages.source')" v-model="message.source" :rules="[rules.required]" />
                              </v-flex>
                              <v-flex xs6>
                                <avinet-message-destination-selector :label="this.$i18n.t('messages.destination')" :source-filter="message.source" :type-message-filter="message.typeId" :airline-filter="flight.airlineId" :airport-filter="flight.airport1Id" :aircraft-type-filter="flight.aircraftTypeId" v-model="message.destination" :rules="[rules.required]" />
                              </v-flex>
                            </v-layout>
                            <v-flex xs12>
                              <v-textarea class="cell-wrap-text-multiline" :label="this.$i18n.t('messages.content')" v-model="message.content" rows="5" auto-grow :rules="[rules.required]"></v-textarea>
                            </v-flex>
                          </v-container>
                        </v-form>
                      </v-card-text>
                    </v-card>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" flat @click.native="close">{{$t("common.cancel")}}</v-btn>
              <v-btn color="primary" depressed @click.native="send" :disabled="loadingGlobal || !validForm">{{$t("messages.sender.send")}}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import AvinetMessageDestinationSelector from '../AvinetMessageDestinationSelector.vue';
import AvinetUserDestinationSelector from '../AvinetUserDestinationSelector.vue';
import AvinetUserSelector from '../AvinetUserSelector.vue';
import MessageTypeSelect from '../MessageTypeSelect.vue';
import FlightMessage from '../model/flightMessage';
import FlightMessageTemplateEngine from '../model/flightMessageTemplateEngine';
import { REQUEST_ERROR, REQUEST_START, REQUEST_SUCCESS } from '../../../store/store';
import { NOTIFICATION_SHOW } from '../../../store/modules/notifications';
import { GET_AVINET_USERS } from '../../../store/modules/avinetUser';

export default {
  name: 'MessageSenderDialog',
  components: {
    MessageTypeSelect,
    AvinetUserSelector,
    AvinetUserDestinationSelector,
    AvinetMessageDestinationSelector,
  },
  props: {
    visible: { type: Boolean, required: true },
    flightComposition: { type: Object, required: true },
  },
  data() {
    return {
      message: null,
      type: null,
      validForm: false,
      rules: {
        required: v => !!v || this.$i18n.t('common.fieldRequired'),
      },
      destinationRules: [
        v => (!!v && v.length > 0) || this.$i18n.t('common.fieldRequired'),
        (emailList) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;
          if (typeof emailList !== 'undefined' && Array.isArray(emailList)) {
            let result = true;
            emailList.forEach((email) => {
              result = result ? pattern.test(email.user || email) : result;
            });
            return result || this.$i18n.t('configuration.invalidEmail');
          }
          return true;
        },
      ],
    };
  },
  computed: {
    show: {
      get() {
        return this.visible;
      },
      set(showObject) {
        if (!showObject.show) {
          this.$emit('close', showObject.reload);
        }
      },
    },
    isTenantSetted() {
      return this.$store.getters.isTenantSetted;
    },
    currentTenant() {
      return this.isTenantSetted ? this.$store.state.tenant.currentTenant : {};
    },
    avinetUsers() {
      return this.$store.getters.getAvinetUsers;
    },
    flight() {
      return this.flightComposition.common;
    },
    userAuth() {
      return this.$store.getters.user;
    },
  },
  watch: {
    avinetUsers: {
      handler() {
        this.setMessageSource();
      },
    },
  },
  methods: {
    setType(type) {
      this.type = type;
      if (this.type) {
        this.message.typeCode = type.code;
        this.message.content = FlightMessageTemplateEngine.applyTemplateToFlight(this.type.template, this.flightComposition, this.currentTenant, this.userAuth);
      }
    },
    close(reload) {
      this.show = {
        show: false,
        reload,
      };
    },
    send() {
      if (this.$refs.form.validate()) {
        this.requestInit();
        this.$http.put(`messages/flights/${this.flight.id}/send`, this.message)
          .then(resp => this.requestSuccessCallback(this, resp))
          .catch(error => this.requestErrorCallback(this, error));
      }
    },
    requestInit() {
      this.$store.commit(REQUEST_START);
    },
    requestSuccessCallback: (context) => {
      context.$store.commit(REQUEST_SUCCESS);
      context.$store.commit(NOTIFICATION_SHOW, {
        message: context.$i18n.t('messages.messageSentMessage'),
        color: 'success',
      });
      context.close(true);
    },
    requestErrorCallback: (context, err) => {
      context.$store.commit(REQUEST_ERROR, err);
      context.$store.commit(NOTIFICATION_SHOW, {
        message: context.$i18n.t('notifications.errorNotification'),
        color: 'error',
      });
    },
    getAvinetUsers() {
      return this.$store.dispatch(GET_AVINET_USERS);
    },
    setMessageSource() {
      this.message.source = this.avinetUsers.filter(avinetUser => avinetUser.tenantId === this.currentTenant.id && avinetUser.defaultUser).map(au => au.user).shift();
    },
  },
  created() {
    this.getAvinetUsers();
    this.message = FlightMessage.createFlightMessageFromFlightId(this.flight.id);
    this.setMessageSource();
  },
};
</script>
