<template>
  <v-layout row align-center justify-end>
    <v-autocomplete
      v-model="selectedQuery"
      :placeholder="this.$i18n.t('queries.savedQueries')"
      :items="queries"
      clearable
      return-object
      item-text="name"
      item-value="id"
      @change="changeFilter"
      ref="refObject"
    >
      <template
        slot="item"
        slot-scope="{ item, tile }"
      >
        <v-list-tile-content>
          <v-list-tile-title v-text="item.name"></v-list-tile-title>
          <v-list-tile-sub-title v-text="item.description"></v-list-tile-sub-title>
        </v-list-tile-content>
      </template>
    </v-autocomplete>

    <v-dialog
      v-model="saveDialog"
      width="500"
    >
      <v-tooltip left slot="activator">
        <v-btn slot="activator" icon @click="openSaveModal">
          <v-icon>mdi-bookmark-plus-outline</v-icon>
        </v-btn>
        <span>{{$t("queries.saveOrUpdateQuery")}}</span>
      </v-tooltip>
      <v-card>
          <v-toolbar dark color="primary">
            <v-icon>mdi-bookmark-plus-outline</v-icon>
            <v-toolbar-title>{{$t("queries.saveQuery")}}</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-text-field
                required
                :label="this.$i18n.t('queries.name')"
                v-model="queryName"
                :rules="[v => !!v || 'Name is required']"
                :messages="this.$i18n.t('queries.nameMessage')"
              ></v-text-field>
              <v-text-field
                :label="this.$i18n.t('queries.description')"
                v-model="queryDescription"
                :messages="this.$i18n.t('queries.descriptionMessage')"
              ></v-text-field>
            </v-form>
            <v-alert
              :class="'mt-3'"
              :value="true"
              type="info"
            >
              <strong>{{$t("queries.noteTitle")}}</strong>{{$t("queries.note")}}
            </v-alert>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary"
                   flat
                   @click="saveDialog = false"
            >
              {{$t("common.cancel")}}
            </v-btn>
            <v-tooltip top>
              <v-btn
                slot="activator"
                color="primary"
                :disabled="!valid"
                @click="saveQuery"
              >
                {{$t("common.save")}}
              </v-btn>
              <span>{{$t("queries.note")}}</span>
            </v-tooltip>
          </v-card-actions>
        </v-card>
    </v-dialog>

    <v-dialog v-if="selectedQuery"
              v-model="deleteDialog"
              width="500"
    >
      <v-tooltip left slot="activator">
        <v-btn slot="activator" icon>
        <v-icon>delete</v-icon>
      </v-btn>
        <span>{{$t("queries.deleteSelectedQuery")}}</span>
      </v-tooltip>
      <v-card>
        <v-toolbar dark color="primary">
          <v-icon>delete</v-icon>
          <v-toolbar-title>{{$t("queries.deleteQuery")}}</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          {{$t("queries.deleteAlertText")}} <strong>{{ selectedQuery.name }}</strong>?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary"
                 flat
                 @click="deleteDialog = false"
          >
            {{$t("common.cancel")}}
          </v-btn>
          <v-btn color="error" @click="deleteQuery">
            {{$t("common.save")}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-layout>
</template>
<script>
import {
  SCHED_FILTER_SET, SCHED_FILTER_SET_UNIT_TIME, SCHED_FIND_FLIGHTS, SCHED_UPDATE_FILTER_PAGINATION_SET_FIRST_PAGE,
} from '../../../store/modules/scheduling';
import { QUERY_FLIGHTS_DELETE, QUERY_FLIGHTS_GET, QUERY_FLIGHTS_SAVE } from '../../../store/modules/queries';

export default {
  name: 'scheduling-flights-saved-queries',
  data() {
    return {
      valid: true,
      queryName: null,
      queryDescription: null,
      saveDialog: false,
      deleteDialog: false,
      selectedQuery: null,
    };
  },
  computed: {
    filter() {
      return this.$store.state.scheduling.filter;
    },
    queries() {
      return this.$store.state.queries.queriesFlights;
    },
  },
  methods: {
    find() {
      this.$store.dispatch(QUERY_FLIGHTS_GET);
    },
    openSaveModal() {
      if (this.selectedQuery) {
        this.queryName = this.selectedQuery.name;
        this.queryDescription = this.selectedQuery.description;
      } else {
        this.queryName = null;
        this.queryDescription = null;
      }
    },
    saveQuery() {
      if (this.$refs.form.validate()) {
        const filterCloned = JSON.parse(JSON.stringify(this.filter));
        if (filterCloned.unitOfTime) {
          filterCloned.dateFrom = null;
          filterCloned.dateTo = null;
        }
        const filterParam = {
          name: this.queryName,
          description: this.queryDescription,
          content: JSON.stringify(filterCloned),
        };
        this.$store.dispatch(QUERY_FLIGHTS_SAVE, filterParam)
          .then((resp) => {
            // TODO sacar a objecto JS
            this.selectedQuery = {
              id: resp.data.id,
              name: resp.data.name,
              description: resp.data.description,
              filter: JSON.parse(resp.data.content),
            };
            this.saveDialog = false;
          })
          .finally(() => {
            this.saveDialog = false;
          });
      }
    },
    deleteQuery() {
      this.$store.dispatch(QUERY_FLIGHTS_DELETE, this.selectedQuery.id)
        .finally(() => {
          this.selectedQuery = null;
          this.deleteDialog = false;
        });
    },
    changeFilter($event) {
      if ($event) {
        this.$store.commit(SCHED_FILTER_SET, $event.filter);
        if ($event.filter.unitOfTime) {
          this.$store.commit(SCHED_FILTER_SET_UNIT_TIME, $event.filter.unitOfTime);
        }
        this.$store.dispatch(SCHED_UPDATE_FILTER_PAGINATION_SET_FIRST_PAGE);
        this.$store.dispatch(SCHED_FIND_FLIGHTS);
        this.$refs.refObject.blur();
      }
    },
  },
  mounted() {
    this.find();
  },
};
</script>
