<template>
  <div>
    <v-autocomplete :placeholder="placeholder" :value="value" :label="label" :items="items" :filter="customFilter"
      :clearable="true" item-text="code" item-value="id" :rules="rules" :disabled="disabled" :multiple="multiple"
      hide-details
      @input="inputAirline" :ref="refComp">
      <template v-slot:selection="data" v-if="chipView">
        <v-chip :selected="data.selected" close class="chip--select-multi" @input="remove(data.item)">
          {{ data.item.code }}
        </v-chip>
      </template>
      <template slot="item" slot-scope="{ item }">
        <v-list-tile-content>
          <v-list-tile-title>{{ getAirlineTitle(item) }}</v-list-tile-title>
          <v-list-tile-sub-title>{{ item.name }}</v-list-tile-sub-title>
        </v-list-tile-content>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import { GET_AIRLINE, GET_AIRLINE_FILTERED_BY_HANDLING_AGENT } from '../../store/modules/airline/airlineCatalog';

export default {
  props: {
    value: { required: true },
    label: { type: String },
    placeholder: { type: String, default: '...' },
    charLenght: { type: Number },
    rules: { type: Array },
    disabled: { type: Boolean },
    filterHandlingActive: { type: Boolean, default: false },
    multiple: {
      type: Boolean,
      default: false,
    },
    refComp: {
      type: String,
      required: false,
      default: 'refComp',
    },
    chipView: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    items: {
      get() {
        return this.filterHandlingActive ? this.$store.getters.getActiveAirlinesFilteredByHandlingAgent : this.$store.getters.getActiveAirlines;
      },
    },
  },
  methods: {
    inputAirline($event) {
      // FIXME así no rompe al limpiar el select pero hay que validar que no se pueda guardar el tipo de mensaje como NULL
      if (typeof $event !== 'undefined') {
        this.$emit('input', $event);
      } else {
        this.$emit('input', null);
      }
    },
    getAirlines() {
      if (this.filterHandlingActive) {
        this.$store.dispatch(GET_AIRLINE_FILTERED_BY_HANDLING_AGENT);
      } else {
        this.$store.dispatch(GET_AIRLINE);
      }
    },
    customFilter(item, queryText) {
      const textOne = item.iataCode ? item.iataCode.toLowerCase() : '';
      const textTwo = item.icaoCode ? item.icaoCode.toLowerCase() : '';
      const searchText = queryText.toLowerCase().trim();

      return textOne.startsWith(searchText) || textTwo.startsWith(searchText);
    },
    getAirlineTitle(item) {
      const codes = [];
      if (item.iataCode) {
        codes.push(item.iataCode);
      }
      if (item.icaoCode) {
        codes.push(item.icaoCode);
      }
      return codes.join(' - ');
    },
    remove(item) {
      const index = this.value.map(a => (typeof a === 'object' && a.id ? a.id : a)).indexOf(item.id);
      if (index >= 0) this.value.splice(index, 1);
      this.$emit('input', this.value.map(a => (typeof a === 'object' && a.id ? a.id : a)));
    },
  },
  mounted() {
    this.getAirlines();
  },
};
</script>
