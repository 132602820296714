<template>
  <v-menu>
    <template v-slot:activator="{ on }">
      <v-btn icon v-on="on">
        <img v-if="currentLanguageModel.value === 'en'" :src="require('@/assets/en.png')" alt="English" width="32"
          height="32" class="flag" />
        <img v-else :src="require('@/assets/es.png')" alt="Spanish" width="32" height="32" class="flag" />
      </v-btn>
    </template>
    <v-list>
      <v-list-tile v-for="(item) in supportedLanguagesArray" :key="item.value" @click="refreshTranslation(item.value)">
        <v-list-tile-title class="primary--text d-flex align-center">
          <img :src="require(`@/assets/${item.value}.png`)" :alt="item.text" width="24" height="24" class="flag-list" />
          <span class="ml-2">{{ item.text }}</span>
        </v-list-tile-title>
      </v-list-tile>
    </v-list>
  </v-menu>
</template>

<script>
import Trans from '@/plugins/Translation';
import axios from 'axios';

export default {
  name: 'LanguageSwitcher',
  computed: {
    supportedLanguagesArray() {
      const keys = Object.keys(this.supportedLanguages);
      return keys.map((lang) => {
        const language = this.supportedLanguages[lang];
        return {
          text: language.name,
          value: lang,
        };
      });
    },
    supportedLanguages() {
      return this.$store.state.i18n.languages;
    },

    currentLanguageModel() {
      return {
        text: this.supportedLanguages[this.currentLanguage].name,
        value: this.currentLanguage,
      };
    },

    currentLanguage() {
      return this.$store.state.i18n.currentLanguage;
    },
  },
  methods: {
    changeLanguage(e) {
      const lang = e;
      const to = this.$router.resolve({ params: { lang } });
      return Trans.changeLanguage(lang).then(() => {
        this.$router.push(to.location);
      });
    },
    isCurrentLanguage(lang) {
      return lang === this.currentLanguage;
    },

    refreshTranslation(e) {
      const newLanguage = e;
      let { currentLanguage } = this.$store.state.i18n;
      currentLanguage = newLanguage || 'en';
      if (this.$i18n && !this.$i18n.messages[currentLanguage]) {
        this.$i18n.setLocaleMessage(currentLanguage, {});
        axios.get(`lang/${currentLanguage}.json`).then((res) => {
          if (res.data) {
            this.$i18n.setLocaleMessage(currentLanguage, res.data);
            this.$i18n.locale = currentLanguage;
            this.$store.commit('CURRENT_LANGUAGE_CHANGED', currentLanguage);
          }
        });
      } else if (this.$i18n) {
        this.$i18n.locale = currentLanguage;
        this.$store.commit('CURRENT_LANGUAGE_CHANGED', currentLanguage);
      }
    },
  },
};
</script>

<style scoped>
.flag {
  border-radius: 50%;
  border: 2px solid white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

.dark-mode .flag {
  border: 2px solid black;
}

.flag-list {
  border-radius: 50%;
}
</style>
