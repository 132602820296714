import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VLayout } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"container",staticClass:"login",attrs:{"tabindex":"0"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.login.apply(null, arguments)}}},[_c(VContainer,[_c(VLayout,{attrs:{"text-xs-center":"","wrap":""}},[_c(VFlex,{attrs:{"xs12":""}},[_c(VImg,{staticClass:"my-3",attrs:{"src":require('../assets/logo.svg'),"contain":"","height":"200"}})],1),_c(VFlex,{attrs:{"xs12":""}},[_c(VBtn,{attrs:{"large":"","color":"primary"},on:{"click":_vm.login}},[_c(VIcon,{attrs:{"x-large":"","left":""}},[_vm._v("mdi-google")]),_vm._v(" Login with Google ")],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }