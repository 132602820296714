import i18n from '../../../plugins/i18n';
import { FlightBound } from '../../shared/model/flightBound';
import { JSUtils } from '../../shared/js/JSUtils';

export default class FlightCommonInformation {
  constructor(
    id,
    flightDate,
    bound,
    tripNumber,
    scheduledTime,
    comments,
    connectionId,
    connectionTripNumber,
    aircraftId,
    aircraftRegisterNumber,
    aircraftTypeId,
    aircraftTypeIataCode,
    aircraftTypeIcaoCode,
    aircraftTypeCode,
    aircraftTypeComments,
    aircraftConfigId,
    aircraftConfigCode,
    airlineId,
    airlineCode,
    airlineName,
    airlineOperationId,
    airlineOperationIataCode,
    airlineOperationName,
    airport1Id,
    airport1IataCode,
    airport1IcaoCode,
    airport1Code,
    airport2Id,
    airport2IataCode,
    airport2IcaoCode,
    airport2Code,
    airport3Id,
    airport3IataCode,
    airport3IcaoCode,
    airport3Code,
    handlingTypeId,
    handlingTypeCode,
    parkingId,
    parkingCode,
    parkingRemote,
    parkingPushback,
    parkingInop400Hz,
    gateId,
    gateCode,
    gateRemote,
    beltId,
    beltCode,
    serviceTypeId,
    serviceTypeCode,
    serviceTypeName,
    tripNumberIATAFormatValid,
  ) {
    this.id = id;
    this.flightDate = flightDate;
    this.bound = bound;
    this.tripNumber = tripNumber;
    this.scheduledTime = scheduledTime;
    this.comments = comments;
    this.connectionId = connectionId;
    this.connectionTripNumber = connectionTripNumber;
    this.aircraftId = aircraftId;
    this.aircraftRegisterNumber = aircraftRegisterNumber;
    this.aircraftTypeId = aircraftTypeId;
    this.aircraftTypeIataCode = aircraftTypeIataCode;
    this.aircraftTypeIcaoCode = aircraftTypeIcaoCode;
    this.aircraftTypeCode = aircraftTypeCode;
    this.aircraftTypeComments = aircraftTypeComments;
    this.aircraftConfigId = aircraftConfigId;
    this.aircraftConfigCode = aircraftConfigCode;
    this.airlineId = airlineId;
    this.airlineCode = airlineCode;
    this.airlineName = airlineName;
    this.airlineOperationId = airlineOperationId;
    this.airlineOperationIataCode = airlineOperationIataCode;
    this.airlineOperationName = airlineOperationName;
    this.airport1Id = airport1Id;
    this.airport1IataCode = airport1IataCode;
    this.airport1IcaoCode = airport1IcaoCode;
    this.airport1Code = airport1Code;
    this.airport2Id = airport2Id;
    this.airport2IataCode = airport2IataCode;
    this.airport2IcaoCode = airport2IcaoCode;
    this.airport2Code = airport2Code;
    this.airport3Id = airport3Id;
    this.airport3IataCode = airport3IataCode;
    this.airport3IcaoCode = airport3IcaoCode;
    this.airport3Code = airport3Code;
    this.handlingTypeId = handlingTypeId;
    this.handlingTypeCode = handlingTypeCode;
    this.parkingId = parkingId;
    this.parkingCode = parkingCode;
    this.parkingRemote = parkingRemote;
    this.parkingPushback = parkingPushback;
    this.parkingInop400Hz = parkingInop400Hz;
    this.gateId = gateId;
    this.gateCode = gateCode;
    this.gateRemote = gateRemote;
    this.beltId = beltId;
    this.beltCode = beltCode;
    this.serviceTypeId = serviceTypeId;
    this.serviceTypeCode = serviceTypeCode;
    this.serviceTypeName = serviceTypeName;
    this.tripNumberIATAFormatValid = tripNumberIATAFormatValid;
  }

  clone() {
    const flightCommonInformationFromData = FlightCommonInformation.createFlightCommonInformationFromData(JSON.parse(JSON.stringify(this)));
    return flightCommonInformationFromData;
  }

  scheduledDate() {
    return this.scheduledTime ? this.scheduledTime : this.flightDate;
  }

  flightNo() {
    let flightNumber = this.airlineCode ? `${this.airlineCode}` : '';
    flightNumber += this.tripNumber ? `${this.tripNumber}` : '';
    return flightNumber;
  }

  airportsIata() {
    return this.airport1Code ? `${this.airport1Code} ${this.airport2Code ? this.airport2Code : ''} ${this.airport3Code ? this.airport3Code : ''}` : '';
  }

  get hasSeveralAirports() {
    return this.airport2Id || this.airport3Id;
  }

  get flightNumber() {
    return this.flightNo();
  }

  get flagAirlineOperation() {
    return this.airlineOperationId && this.airlineOperationId !== this.airlineId;
  }

  get airlineOperationMessage() {
    const operatorCode = this.airlineOperationIataCode ? this.airlineOperationIataCode : this.airlineOperationIcaoCode;
    return this.flagAirlineOperation ? `${i18n.t('operations.operatedBy') + operatorCode} (${this.airlineOperationName})` : undefined;
  }

  static get createEmptyFlightCommonInformation() {
    return new FlightCommonInformation();
  }

  static get createEmptyInboundFlight() {
    const flight = this.createEmptyFlightCommonInformation;
    flight.bound = FlightBound.INBOUND;
    return flight;
  }

  static get createEmptyOutBoundFlight() {
    const flight = this.createEmptyFlightCommonInformation;
    flight.bound = FlightBound.OUTBOUND;
    return flight;
  }

  static createFlightCommonInformationFromData(o) {
    if (!o) {
      return this.createEmptyFlightCommonInformation;
    }

    return JSUtils.cast(o, FlightCommonInformation);
  }
}
