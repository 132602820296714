<template>
  <v-layout row justify-center align-center>
    <v-flex xs3 class="date-input">
      <v-flex xs8 class="date-input">
        <date-input :ref="'refComp'" :locale="$i18n.locale" v-on:keydown.native="keyEvent1" v-model="date" @input="changeDate" />
      </v-flex>
      <v-flex xs2 class="date-input">
        <v-btn v-on:click="cleanDate" small flat>
          <v-icon>clear</v-icon>
        </v-btn>
      </v-flex>
    </v-flex>
  </v-layout>
</template>

<script>
import DateInput from './DateInput.vue';

export default {
  name: 'contingency-date',
  components: { DateInput },
  props: {
    dateOriginal: { },
    rules: { type: Array },
  },
  data: () => ({
    date: null,
  }),
  methods: {
    changeDate() {
      this.$emit('dateSelected', this.$moment(this.date));
    },
    cleanDate() {
      this.date = null;
    },
  },
  created() {
    this.date = this.dateOriginal != null ? this.dateOriginal : this.$moment();
    this.$emit('dateSelected', this.$moment(this.date));
  },
  watch: {
    date: {
      handler() {
        this.$emit('dateSelected', this.$moment(this.date));
      },
    },
  },
};
</script>
<style scoped>
  .date-input {
    display: inline-block !important;
    margin: auto 0;
  }
  .date-input .v-btn {
    min-width: 44px;
  }
</style>
