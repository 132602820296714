<template>
  <v-container grid-list-md text-xs-center fluid>
    <span style="font-weight: bold">{{$t('commonForm.flightInformation')}}</span>
    <v-layout row wrap>
      <v-flex xs2 class="inputFlight">
        <date-input :locale="$i18n.locale" :label="this.$i18n.t('common.date')" :ref="'refComp'" v-model="delivery.flightDate" :rules="[rules.required]"></date-input>
      </v-flex>

      <v-flex xs4 class="inputFlight">
        <div>
          <v-flex v-flex-none class="inline-flight-number">
            <airline-select :label="$t('commonForm.airlineTripNumber')" :ref="'refComp'" v-char-length.clearable="4"
                            v-model="delivery.airlineId" :rules="[rules.required]"></airline-select>
          </v-flex>
          <v-flex v-flex-none class="inline-flight-number inline-flight-number-input">
            <v-text-field :ref="'refInput'" v-char-length="5" v-model="delivery.flightTripNumber" :rules="[rules.required]"></v-text-field>
          </v-flex>
        </div>
      </v-flex>

      <v-flex xs2 class="inputFlight">
        <time-input :label="$t('operations.scheduledTimeOfDeparture') " :ref="'refTimeInput'" v-model="delivery.scheduledTime"
                    :referenceDate="delivery.flightDate"></time-input>
      </v-flex>

      <v-flex xs2 class="inputFlight">
        <airport-select :label="$t('operations.airportOfDeparture')" :ref="'refComp1'" v-char-length.clearable="3"
                        v-model="delivery.airportId"></airport-select>
      </v-flex>

    </v-layout>
  </v-container>
</template>
<script>
import DateInput from '../../../ui/DateInput.vue';
import AirportSelect from '../../../airport/AirportSelect.vue';
import AirlineSelect from '../../../airline/AirlineSelect.vue';
import TimeInput from '../../../ui/TimeInput.vue';

export default {
  name: 'delivery-external-form',
  components: {
    DateInput,
    TimeInput,
    AirportSelect,
    AirlineSelect,
  },
  props: {
    deliveryOriginal: {},
  },
  data() {
    return {
      delivery: this.deliveryOriginal,
      rules: {
        required: value => !!value || 'Required.',
      },
    };
  },
  watch: {
    deliveryOriginal: {
      handler(newDeliveryProp) {
        this.delivery = newDeliveryProp;
      },
      deep: true,
    },
    delivery: {
      handler(newDelivery) {
        this.$emit('delivery-update', newDelivery);
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
  .v-btn-toggle .v-btn.v-btn--active.v-btn--disabled i {
    color: white !important;;
  }

  .inputDelivery {
    margin: auto 0;
  }
</style>
