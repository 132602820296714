<template>
  <div>

    <ConnectedFlightsDetailDialog v-model="showDetailDialog" :connected-flights="detailDialogConnectedFlights"/>

    <v-tooltip
      v-model="showTooltip"
      absolute
      :position-x="tooltipX"
      :position-y="tooltipY"
      bottom>
      <GanttFlightsTaskTooltip :connected-flights="tooltipConnectedFlights"/>
    </v-tooltip>

    <gantt-elastic
      v-if="tasks && !loading"
      :options="options"
      :tasks="tasks"
      @chart-task-click="chartTaskClick"
      @chart-task-mousemove="chartTaskMouseEnter"
      @chart-task-mouseenter="chartTaskMouseOut"
      @chart-task-mouseout="chartTaskMouseOut"
      @text-task-click="chartTaskClick"
      @text-task-mousemove="chartTaskMouseEnter"
      @text-task-mouseenter="chartTaskMouseOut"
      @text-task-mouseout="chartTaskMouseOut"
    >
      <gantt-header :options="options" slot="header"></gantt-header>
    </gantt-elastic>

    <v-progress-linear v-if="loading" :indeterminate="true"></v-progress-linear>
  </div>
</template>

<script>
import { RAMP_GANTT_FIND_FLIGHTS } from '@/store/modules/ramp/rampGantt';
import GanttElastic from 'gantt-elastic/src/GanttElastic.vue';
import GanttHeader from 'gantt-elastic-header/src/Header.vue';
import GanttFlightsTaskTooltip from './GanttFlightsTaskTooltip.vue';
import ConnectedFlightsDetailDialog from '../flights/ConnectedFlightsDetailDialog.vue';


export default {
  name: 'GanttFlights',
  components: {
    ConnectedFlightsDetailDialog,
    GanttFlightsTaskTooltip,
    GanttElastic,
    GanttHeader,
  },
  props: {
    isFilterDisplayed: { type: Boolean, required: false, default: true },
  },
  data() {
    return {
      showDetailDialog: false,
      detailDialogConnectedFlights: null,
      tooltipX: null,
      tooltipY: null,
      tooltipConnectedFlights: null,
      showTooltip: false,
      dynamicStyle: {},
      updatedTasks: null,
    };
  },
  computed: {
    tasks() {
      return this.$store.getters.getGanttFlight;
    },
    loading() {
      return this.$store.state.rampGantt.loading;
    },
    currentZone() {
      this.$store.dispatch(RAMP_GANTT_FIND_FLIGHTS);
      return this.$store.state.time.timezone;
    },
    options() {
      const localZone = this.currentZone;
      return {
        taskMapping: {
          progress: 'percent',
        },
        maxRows: 100,
        maxHeight: 560,
        title: {
          label: 'Flights timeline',
          html: false,
        },
        row: {
          height: 24,
        },
        calendar: {
          hour: {
            display: true,
          },
        },
        chart: {
          progress: {
            bar: false,
          },
          expander: {
            display: true,
          },
          text: {
            offset: -70, // 4
            xPadding: 2, // 10
            display: true,
          },
        },
        scope: {
          before: 0, // 1
          after: 0, // 1
        },
        times: {
          timeScale: 6 * 1000, // 60 * 1000
          timeZoom: 10, // 17
          timePerPixel: 0,
        },
        taskList: {
          expander: {
            straight: false,
          },
          columns: [
            {
              id: 1,
              label: 'Position',
              value: 'label',
              width: 200,
              expander: true,
              html: true,
              events: {
                click({ data, column }) {
                  console.log(`description clicked!\n${data.label}`); // eslint-disable-line no-console
                  console.log(column); // eslint-disable-line no-console
                },
              },
            },
          ],
        },
        locale: {
          name: 'en',
          Now: 'Now',
          'X-Scale': 'Zoom-X',
          'Y-Scale': 'Zoom-Y',
          'Before/After': 'Expand',
          'Task list width': 'Positions list width',
          'Display task list': 'Positions list',
        },
        localZone,
      };
    },
  },
  methods: {
    tasksUpdate(tasks) {
      this.updatedTasks = tasks;
    },
    optionsUpdate(optionsUpdate) {
      this.options = optionsUpdate;
    },
    styleUpdate(style) {
      this.dynamicStyle = style;
    },
    chartTaskClick({ data }) {
      this.showDetailDialog = true;
      this.detailDialogConnectedFlights = data.connectedFlights;
    },
    chartTaskMouseEnter({ data, event }) {
      this.tooltipX = event.pageX;
      this.tooltipY = event.pageY; // - 64 - 48;
      this.tooltipConnectedFlights = data.connectedFlights;
      this.showTooltip = true;
    },
    chartTaskMouseOut() {
      this.showTooltip = false;
      // this.tooltipX = null;
      // this.tooltipY = null;
      // this.tooltipContent = null;
    },
    updateHeight() {
      if (this.isFilterDisplayed) {
        this.options.maxHeight = 560;
      } else {
        this.options.maxHeight = 680;
      }
    },
  },
  mounted() {
    this.updateHeight();
  },
  watch: {
    isFilterDisplayed: {
      handler() {
        this.updateHeight();
      },
    },
  },
};
</script>
<style>
.gantt-elastic__calendar-row-rect-child.gantt-elastic__calendar-row-rect-child--hour {
  text-align: start !important;
  padding-left: 1px;
}
.gantt-elastic__chart-row-text-content {
  font-size: 11px !important;
}
</style>
