<template>
  <v-card :dark="$vuetify.dark">
    <v-card-text class="loads-table">
      <v-flex sm6 d-flex pt-2 pl-2>
        <v-select :items="unitOptions" item-text="text" item-value="value" :label="label" v-model="unitLoad"
          :readonly="!hasPermissions(rolePermission)" />
      </v-flex>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-text class="loads-table">
      <ag-grid-vue class="ag-theme-material" :columnDefs="columnDefs" :defaultColDef="defaultColDef"
        @grid-ready="setAgGridApi" :gridOptions="gridOptions" :frameworkComponents="frameworkComponents"
        :rowData="rowData">
      </ag-grid-vue>
    </v-card-text>
  </v-card>
</template>
<script>
import Vue from 'vue';
import { AgGridVue } from 'ag-grid-vue';
import AgGridNumberCellEditor from '../../AgGridNumberCellEditor.vue';
import AgGridValueWithAsteriskCellRenderer from '../../../cell-renderers/AgGridValueWithAsteriskCellRenderer.vue';
import AgGridApiHoldable from '../../../mixins/AgGridApiHoldable.vue';
import AgGridOptionsConfigurable from '../../../mixins/AgGridOptionsConfigurable.vue';
import { cellClassAgGridRules } from '../../../mixins/cellClassAgGridRules';
import RolePermissions from '../../../../mixins/RolePermissions.vue';

export default Vue.extend({
  name: 'FlightLoadsTable',
  mixins: [AgGridApiHoldable, AgGridOptionsConfigurable, RolePermissions],
  components: {
    AgGridVue,
  },
  props: {
    items: { type: Array, required: true },
    invalidData: { type: Boolean, required: true },
  },
  data() {
    const that = this;
    return {
      totalLoad: 0,
      label: `${this.$i18n.t('operations.loadsUnits')}`,
      frameworkComponents: {
        AgGridNumberCellEditor,
      },
      defaultColDef: {
        cellClassRules: cellClassAgGridRules,
      },
      gridOptions: {
        getRowClass: this.getRowClass,
      },
      rowData: [],
      localInvalidData: true,
      columnDefs: [
        {
          field: 'airportCode',
          headerValueGetter() {
            return that.$i18n.t('operations.loadsTableFromTo');
          },
          editable: false,
        },
        {
          field: 'bag',
          headerValueGetter() {
            return that.$i18n.t('operations.loadsTableBag');
          },
          editable: this.isCellEditable,
          cellEditor: 'AgGridNumberCellEditor',
          onCellValueChanged: this.calcularTotal,
          valueGetter(params) {
            return {
              value: params.data.bag,
              asterisk: params.data.messageValidate.bag.errorExist,
              tooltip: params.data.messageValidate.bag.listMessage.join('\n'),
            };
          },
          cellRendererFramework: AgGridValueWithAsteriskCellRenderer,
        },
        {
          field: 'estimatedLoad',
          headerValueGetter() {
            return that.$i18n.t('operations.loadsTableEstimatedLoadShort');
          },
          editable: this.isCellEditable,
          cellEditor: 'AgGridNumberCellEditor',
          onCellValueChanged: this.calcularTotal,
          valueGetter(params) {
            return {
              value: params.data.estimatedLoad,
              asterisk: params.data.messageValidate.estimatedLoad.errorExist,
              tooltip: params.data.messageValidate.estimatedLoad.listMessage.join('\n'),
            };
          },
          cellRendererFramework: AgGridValueWithAsteriskCellRenderer,
        },
        {
          field: 'cargo',
          headerValueGetter() {
            return that.$i18n.t('operations.loadsTableCargo');
          },
          editable: this.isCellEditable,
          cellEditor: 'AgGridNumberCellEditor',
          onCellValueChanged: this.calcularTotal,
          valueGetter(params) {
            return {
              value: params.data.cargo,
              asterisk: params.data.messageValidate.cargo.errorExist,
              tooltip: params.data.messageValidate.cargo.listMessage.join('\n'),
            };
          },
          cellRendererFramework: AgGridValueWithAsteriskCellRenderer,
        },
        {
          field: 'mail',
          headerValueGetter() {
            return that.$i18n.t('operations.loadsTableMail');
          },
          editable: this.isCellEditable,
          cellEditor: 'AgGridNumberCellEditor',
          onCellValueChanged: this.calcularTotal,
          valueGetter(params) {
            return {
              value: params.data.mail,
              asterisk: params.data.messageValidate.mail.errorExist,
              tooltip: params.data.messageValidate.mail.listMessage.join('\n'),
            };
          },
          cellRendererFramework: AgGridValueWithAsteriskCellRenderer,
        },
        {
          field: 'avih',
          headerValueGetter() {
            return that.$i18n.t('operations.loadsTableAVIH');
          },
          editable: this.isCellEditable,
          cellEditor: 'AgGridNumberCellEditor',
          onCellValueChanged: this.calcularTotal,
          valueGetter(params) {
            return {
              value: params.data.avih,
              asterisk: params.data.messageValidate.avih.errorExist,
              tooltip: params.data.messageValidate.avih.listMessage.join('\n'),
            };
          },
          cellRendererFramework: AgGridValueWithAsteriskCellRenderer,
        },
        {
          field: 'hum',
          headerValueGetter() {
            return that.$i18n.t('operations.loadsTableHum');
          },
          editable: this.isCellEditable,
          cellEditor: 'AgGridNumberCellEditor',
          onCellValueChanged: this.calcularTotal,
          valueGetter(params) {
            return {
              value: params.data.hum,
              asterisk: params.data.messageValidate.hum.errorExist,
              tooltip: params.data.messageValidate.hum.listMessage.join('\n'),
            };
          },
          cellRendererFramework: AgGridValueWithAsteriskCellRenderer,
        }, {
          field: 'weap',
          headerName: 'WEAP',
          editable: this.isCellEditable,
          cellEditor: 'AgGridNumberCellEditor',
          onCellValueChanged: this.calcularTotal,
          valueGetter(params) {
            return {
              value: params.data.weap,
              asterisk: params.data.messageValidate.weap.errorExist,
              tooltip: params.data.messageValidate.weap.listMessage.join('\n'),
            };
          },
          cellRendererFramework: AgGridValueWithAsteriskCellRenderer,
        },
        {
          field: 'oths',
          headerName: 'OTHS',
          editable: this.isCellEditable,
          cellEditor: 'AgGridNumberCellEditor',
          onCellValueChanged: this.calcularTotal,
          valueGetter(params) {
            return {
              value: params.data.oths,
              asterisk: params.data.messageValidate.oths.errorExist,
              tooltip: params.data.messageValidate.oths.listMessage.join('\n'),
            };
          },
          cellRendererFramework: AgGridValueWithAsteriskCellRenderer,
        },
        {
          field: 'remarks',
          headerName: 'REM',
          editable: this.isCellEditable,
        },

      ],
      unitOptions: [
        {
          text: 'Kilograms',
          value: 'KILOGRAMS',
        },
        {
          text: 'Pounds',
          value: 'POUNDS',
        },
      ],
    };
  },
  computed: {
    unitLoad: {
      get() {
        const unitLoaded = this.rowData.find(item => item.unit != null);
        return unitLoaded ? unitLoaded.unit : 'KILOGRAMS';
      },
      set(unitSelected) {
        this.setUnit(unitSelected);
      },
    },
    rolePermission() {
      return `${this.$route.meta.role}_WR`;
    },
  },
  methods: {
    changeTotalLoad(dataRow) {
      this.totalLoad = dataRow;
      this.$emit('update:totalLoad', dataRow);
    },

    isCellEditable(params) {
      return !params.data.isTotalRow && this.hasPermissions(this.rolePermission);
    },
    changeInvalidData(newInvalidData) {
      this.localInvalidData = newInvalidData;
      this.$emit('update:invalidData', newInvalidData);
    },
    initTotal() {
      const [totalRow] = this.rowData.filter(r => !!r.isTotalRow);
      totalRow.airportCode = 'Total';
      this.changeInvalidData(false);

      this.columnDefs.map(colDef => colDef.field).forEach((colField) => {
        if (colField === 'airportCode' || colField === 'remarks' || colField === 'unit') {
          return;
        }
        this.rowData.forEach((data) => {
          this.validateColFieldOfRow(colField, data);
        });
      });

      const totalLoad = this.sumProperty([totalRow.bag ? totalRow.bag : 0, totalRow.cargo ? totalRow.cargo : 0, totalRow.mail ? totalRow.mail : 0]);
      this.changeTotalLoad(totalLoad);
    },
    calcularTotal(params) {
      if (params.newValue.value === params.oldValue.value) {
        return;
      }
      if (params.colDef.field === 'airportCode' || params.colDef.field === 'remarks' || params.colDef.field === 'unit') {
        return;
      }
      this.changeInvalidData(false);

      const dataLoad = this.rowData.filter(r => !r.isTotalRow);
      const [totalRow] = this.rowData.filter(r => !!r.isTotalRow);

      this.sumTotalProperty(totalRow, dataLoad, params.colDef.field);
      params.api.refreshCells({ force: true, columns: [params.column] });

      const totalLoad = this.sumProperty([totalRow.bag ? totalRow.bag : 0, totalRow.cargo ? totalRow.cargo : 0, totalRow.mail ? totalRow.mail : 0]);
      this.changeTotalLoad(totalLoad);
    },
    validateColFieldOfRow(colField, rowData) {
      const data = rowData;
      const errorValidate = {};
      const messageValidate = [];

      errorValidate.errorExist = false;

      if (data[colField] < 0) {
        this.changeInvalidData(true);
        errorValidate.errorExist = true;
        messageValidate.push(`${colField} is less than 0.`);
      }

      if (data.messageValidate == null) {
        data.messageValidate = {};
      }
      errorValidate.listMessage = messageValidate;

      data.messageValidate[colField] = errorValidate;
    },
    setUnit(unit) {
      this.rowData.forEach((data) => {
        const row = data;
        row.unit = unit;
      });
    },
    sumTotalProperty(total, loads, property) {
      // eslint-disable-next-line no-param-reassign
      total[property] = this.getTotalProperty(loads, property);
    },
    getTotalProperty(loads, property) {
      const loadsProperty = loads.map(p => p[property]).filter(a => a != null);
      return loadsProperty.length > 0 ? this.sumProperty(loadsProperty) : null;
    },
    sumProperty(loadsPropertyValue) {
      const reducer = (accumulator, currentValue) => (parseInt(accumulator, 10) + parseInt(currentValue, 10));
      return loadsPropertyValue.reduce(reducer);
    },
  },
  created() {
    this.rowData = this.items;
    this.initTotal();
  },
});
</script>
<style lang="scss">
.loads-table {
  padding: 0;
}

.radio-unit-label {
  padding-top: 1.5vh;
  font-weight: bold;
  margin-right: 1vw;
}
</style>
