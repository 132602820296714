import { VCardText } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCardText,{staticClass:"form"},[_c(VContainer,{attrs:{"fluid":""}},[_c(VLayout,{attrs:{"row":"","wrap":""}},[_c(VFlex,{attrs:{"xs4":"","pr-4":""}},[_c(VTextField,{attrs:{"label":this.$i18n.t('configuration.name'),"rules":[_vm.rules.required],"readonly":!_vm.hasPermissions(_vm.rolePermission)},model:{value:(_vm.item.name),callback:function ($$v) {_vm.$set(_vm.item, "name", $$v)},expression:"item.name"}})],1),_c(VFlex,{attrs:{"xs8":""}},[_c(VTextField,{attrs:{"label":this.$i18n.t('configuration.description'),"readonly":!_vm.hasPermissions(_vm.rolePermission)},model:{value:(_vm.item.description),callback:function ($$v) {_vm.$set(_vm.item, "description", $$v)},expression:"item.description"}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }