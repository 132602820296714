<template>
  <v-card-text>
    <v-container fluid>
      <v-layout row wrap>
        <v-flex xs4 pr-2>
          <airline-select :label="this.$i18n.t('common.airline')" v-model="item.airlineId" :disabled="!hasPermissions(rolePermission)"></airline-select>
        </v-flex>
        <v-flex xs4 pr-2>
          <aircraft-type-select v-model="item.aircraftTypeId" :label="this.$i18n.t('configuration.aircraftType')" :disabled="!hasPermissions(rolePermission)"/>
        </v-flex>
        <v-flex xs4 pr-2>
          <handling-type-select v-model="item.handlingTypeId" :label="this.$i18n.t('configuration.handlingType')" :disabled="!hasPermissions(rolePermission)"/>
        </v-flex>
        <v-flex xs3 pr-2>
          <v-text-field type="number" min="0" v-model="item.total" :label="this.$i18n.t('flightsWeighting.totalWeighting')" :readonly="!hasPermissions(rolePermission)"></v-text-field>
        </v-flex>
        <v-flex xs3 pr-2>
          <v-text-field type="number" min="0" v-model="item.ramp" :label="this.$i18n.t('flightsWeighting.rampWeighting')" :readonly="!hasPermissions(rolePermission)"></v-text-field>
        </v-flex>
        <v-flex xs3 pr-2>
          <v-text-field type="number" min="0" v-model="item.passage" :label="this.$i18n.t('flightsWeighting.passageWeighting')" :readonly="!hasPermissions(rolePermission)"></v-text-field>
        </v-flex>
        <v-flex xs3 pr-2>
          <v-text-field type="number" min="0" v-model="item.operations" :label="this.$i18n.t('flightsWeighting.operationWeighting')" :readonly="!hasPermissions(rolePermission)"></v-text-field>
        </v-flex>
      </v-layout>
    </v-container>
  </v-card-text>
</template>
<script>
import HandlingTypeSelect from '@/components/handling/HandlingTypeSelect.vue';
import AirlineSelect from '../../airline/AirlineSelect.vue';
import AircraftTypeSelect from '../../aircraft/AircraftTypeSelect.vue';
import RolePermissions from '../../mixins/RolePermissions.vue';

export default {
  name: 'FlightWeightingEditCardContent',
  mixins: [RolePermissions],
  components: {
    AirlineSelect,
    AircraftTypeSelect,
    HandlingTypeSelect,
  },
  props: {
    item: { type: Object, required: true },
  },
  computed: {
    rolePermission() {
      return `${this.$route.meta.role}_WR`;
    },
  },
};
</script>
