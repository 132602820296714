<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>

    <ag-grid-dialog-template-editor :is-dialog-open="isDialogOpen" @save="save" @cancel="cancel" :saveDisabled="validData">

      <template v-slot:header>
        {{$t("operations.delaysModalTitle")}} {{flightNumber}}
      </template>

      <template v-slot:default>
        <v-card>
          <v-card-text class="tow-table">
            <ag-grid-vue
              class="ag-theme-material"
              :columnDefs="columnDefs"
              :defaultColDef="defaultColDef"
              @grid-ready="setAgGridApi"
              :gridOptions="gridOptions"
              rowSelection="multiple"
              :frameworkComponents="frameworkComponents"
              @selection-changed="onSelectionChanged"
              :rowData="rowData">
            </ag-grid-vue>
          </v-card-text>
        </v-card>
      </template>

      <template v-slot:footer>
        <v-btn v-show="isCellEditable()" color="secondary" flat @click="addDelay()"><v-icon>add</v-icon></v-btn>
        <v-btn v-show="delaysSelected.length > 0 && isCellEditable()" color="error" flat @click="removeDelays()"><v-icon>delete</v-icon></v-btn>
      </template>
    </ag-grid-dialog-template-editor>
  </div>
</template>

<script>
import Vue from 'vue';
import { AgGridVue } from 'ag-grid-vue';
import AgGridDialogParentCellEditor from '../../mixins/AgGridDialogParentCellEditor.vue';
import AgGridDelayCodeSelectCellEditor from '../../AgGridDelayCodeSelectCellEditor.vue';
import AgGridMinuteTimeCellEditor from '../../AgGridMinuteTimeCellEditor.vue';
import AgGridDialogTemplateEditor from '../../templates/AgGridDialogTemplateEditor.vue';
import AgGridOptionsConfigurable from '../../../mixins/AgGridOptionsConfigurable.vue';
import FlightDelays from '../../model/flightDelays';
import CrudOperationsDTO from '../../../../shared/model/crudOperationsDTO';
import { JSUtils } from '../../../../shared/js/JSUtils';
import { cellClassAgGridRules } from '../../../mixins/cellClassAgGridRules';
import { FisModule } from '../../model/fisModule';

export default Vue.extend({
  mixins: [AgGridDialogParentCellEditor, AgGridOptionsConfigurable],
  components: {
    AgGridDialogTemplateEditor,
    AgGridVue,
  },
  computed: {
    rolePermission() {
      return `${this.$route.meta.role}_WR`;
    },
  },
  data() {
    const that = this;
    return {
      module: null,
      frameworkComponents: {
        AgGridDelayCodeSelectCellEditor,
        AgGridMinuteTimeCellEditor,
      },
      defaultColDef: {
        cellClassRules: cellClassAgGridRules,
      },
      gridOptions: {
        getRowClass: this.getRowClass,
      },
      rowData: [],
      validData: true,
      columnDefs: [
        {
          checkboxSelection: this.isCellEditable,
          width: 36,
          editable: that.isCellEditable,
        },
        {
          field: 'typeCode',
          headerValueGetter() {
            return that.$i18n.t('operations.flightDelay');
          },
          editable: that.isCellEditable,
          cellEditor: 'AgGridDelayCodeSelectCellEditor',
          cellEditorCustomConfig: {
            fieldId: 'typeId',
          },
          cellRenderer(params) {
            let val = '';
            if (params.data.typeCode != null && params.data.typeDescription != null) {
              val = `${params.data.typeCode} - ${params.data.typeDescription}`;
            } else if (params.data.typeCode != null && params.data.typeDescription == null) {
              val = params.data.typeCode;
            } else if (params.data.typeCode == null && params.data.typeDescription != null) {
              val = params.data.typeDescription;
            }
            return val;
          },
          onCellValueChanged: this.validateDelay,
        },
        {
          field: 'amount',
          headerValueGetter() {
            return that.$i18n.t('operations.delaysModalDuration');
          },
          editable: that.isCellEditable,
          cellEditor: 'AgGridMinuteTimeCellEditor',
          valueFormatter: this.getHour,
          onCellValueChanged: this.validateDelay,
        },
        {
          field: 'remarks',
          headerValueGetter() {
            return that.$i18n.t('operations.remarksExtended');
          },
          editable: that.isCellEditable,
          onCellValueChanged: this.validateDelay,
        },
      ],
      delaysSelected: [],
      crudOperationsDto: new CrudOperationsDTO(),
    };
  },
  methods: {
    getValue() {
      if (this.isSaving) {
        this.crudOperationsDto.updates.push(...this.rowData);
        this.requestInit();
        this.$http.put(`flights/${this.flightId}/delays`, this.crudOperationsDto)
          .then(resp => this.dialogRequestSuccessCallback(this, resp))
          .catch(error => this.requestErrorCallback(this, error));
      }

      return this.params.value;
    },
    addDelay() {
      this.rowData.push(FlightDelays.createFlightDelays(this.flightId));
      this.validateDelay();
    },
    removeDelays() {
      if (this.delaysSelected.length > 0) {
        this.crudOperationsDto.deletes.push(...this.delaysSelected.filter(delay => !!delay.id));

        this.delaysSelected.forEach((tow) => {
          const index = this.rowData.indexOf(tow);
          this.rowData.splice(index, 1);
        });
        this.delaysSelected = [];
        this.validateDelay();
      }
    },
    onSelectionChanged(event) {
      if (event.api.getSelectedNodes().length > 0) {
        this.delaysSelected = event.api.getSelectedNodes().map(n => n.data);
      } else {
        this.delaysSelected = [];
      }
    },
    getHour(params) {
      const milliseconds = params.value ? Number(params.value) * 60000 : 0;
      let hours = (Number(this.$moment.duration(milliseconds).days()) * 24) + Number(this.$moment.duration(milliseconds).hours());
      if (hours === 0) {
        hours = '00';
      } else if (hours.toString().length === 1) {
        hours = `0${hours}`;
      }
      let minutes = this.$moment.duration(milliseconds).minutes();
      if (minutes === 0) {
        minutes = '00';
      } else if (minutes.toString().length === 1) {
        minutes = `0${minutes}`;
      }
      return `${hours}:${minutes}`;
    },
    validateDelay() {
      this.validData = false;
      this.rowData.forEach((data) => {
        if (data.typeId == null) {
          this.validData = true;
        }
        if (data.amount == null || data.amount === 0) {
          this.validData = true;
        }
      });
    },
    getByModule(delay) {
      return this.module === FisModule.RAMP
        ? delay.responsibleDepartment === FisModule.RAMP.description || delay.responsibleDepartment === null
        : true;
    },
    isCellEditable() {
      return this.module !== FisModule.RAMP && this.module !== FisModule.PASSAGE_OUTBOUND && this.hasPermissions(this.rolePermission);
    },
  },
  created() {
    this.module = this.params.context.module;
    this.$http.get(`flights/${this.flightId}/delays`)
      .then((resp) => {
        this.rowData = JSUtils.cast(resp.data, FlightDelays)
          .filter(this.getByModule);

        const reg = this.connectedFlights.getSafeValue('aircraftRegisterNumber');
        /* eslint no-param-reassign: "off" */
        this.rowData.forEach((row) => {
          row.reg = reg;
        });
      });
  },
});
</script>
<style lang="scss">
.tow-table {
  padding: 0;
}
</style>
