<template>
  <v-fab-transition v-if="hasPermissions(rolePermission)">
    <v-speed-dial
      v-if="!selectionMode"
      fixed bottom right
      v-model="fabSingle"
      :key="activeSpeedDial.key"
      direction="top"
      :open-on-hover="activeSpeedDial.hover"
      :transition="activeSpeedDial.transition"
    >
      <v-btn
        v-model="fabSingle"
        slot="activator"
        color="primary"
        dark
        fab
      >
        <v-icon>add</v-icon>
        <v-icon>close</v-icon>
      </v-btn>

      <v-tooltip left>
        <v-btn
          slot="activator"
          fab
          dark
          small
          color="secondary"
          @click="clickNewContingency">
          <v-icon>new_releases</v-icon>
        </v-btn>
        <span>{{$t("contingencies.newContingency")}}</span>
      </v-tooltip>

      <v-tooltip left>
        <v-btn
          slot="activator"
          fab
          dark
          small
          color="secondary"
          @click="clickNewExternalContingency">
          <v-icon>explicit</v-icon>
        </v-btn>
        <span>{{$t("contingencies.newExternalContingency")}}</span>
      </v-tooltip>

    </v-speed-dial>
    <v-speed-dial
      v-else-if="selectionMode"
      fixed bottom right
      v-model="fabSelection"
      :key="activeSpeedDial.key"
      direction="left"
      :open-on-hover="activeSpeedDial.hover"
      :transition="activeSpeedDial.transition"
    >
      <v-btn
        v-model="fabSelection"
        slot="activator"
        color=""
        dark
        fab
      >
        <v-icon>build</v-icon>
        <v-icon>close</v-icon>
      </v-btn>

      <v-tooltip top>
        <v-btn
          slot="activator"
          fab
          dark
          small
          color="secondary"
          @click="clickOpen">
          <v-icon>lock_open</v-icon>
        </v-btn>
        <span>{{$t("contingencies.reopenSelected")}}</span>
      </v-tooltip>

      <v-tooltip top>
        <v-btn
          slot="activator"
          fab
          dark
          small
          color="secondary"
          @click="clickClose">
          <v-icon>lock</v-icon>
        </v-btn>
        <span>{{$t("contingencies.closeSelected")}}</span>
      </v-tooltip>

      <v-tooltip top>
        <v-btn
          slot="activator"
          fab
          dark
          small
          color="secondary"
          @click="clickInvoice">
          <v-icon>attach_money</v-icon>
        </v-btn>
        <span>{{$t("contingencies.invoiceSelected")}}</span>
      </v-tooltip>

      <v-tooltip top>
        <v-btn
          slot="activator"
          fab
          dark
          small
          color="secondary"
          @click="clickNotInvoice">
          <v-icon>money_off</v-icon>
        </v-btn>
        <span>{{$t("contingencies.notInvoiceSelected")}}</span>
      </v-tooltip>

      <v-tooltip top>
        <v-btn
          slot="activator"
          fab
          dark
          small
          color="primary"
          @click="clickDelete">
          <v-icon>delete</v-icon>
        </v-btn>
        <span>{{$t("contingencies.deleteSelected")}}</span>
      </v-tooltip>

    </v-speed-dial>
  </v-fab-transition>
</template>
<script>
import RolePermissions from '../../mixins/RolePermissions.vue';

export default {
  name: 'contingencies-list-options',
  mixins: [RolePermissions],
  data: () => ({
    fabSingle: false,
    fabSelection: false,
  }),
  computed: {
    selectionMode() {
      return this.$store.getters.hasSelectedContingencies;
    },
    activeSpeedDial() {
      return {
        key: 'single-speed-dial',
        direction: 'top',
        hover: false,
        transition: 'slide-x-reverse-transition',
      };
    },
    rolePermission() {
      return `${this.$route.meta.role}_WR`;
    },
  },
  methods: {
    clickNewContingency() {
      this.$emit('clickNewContingency');
    },

    clickNewExternalContingency() {
      this.$emit('clickNewExternalContingency');
    },

    clickDelete() {
      this.$emit('clickDeleteContingencies');
    },

    clickOpen() {
      this.$emit('clickOpenContingencies');
    },

    clickClose() {
      this.$emit('clickCloseContingencies');
    },
    clickInvoice() {
      this.$emit('clickInvoiceContingencies');
    },
    clickNotInvoice() {
      this.$emit('clickNotInvoiceContingencies');
    },

  },
};
</script>
