<template>
  <v-card-text>
    <v-container style="margin-bottom: 0; padding-bottom: 0">
      <v-layout style="margin-bottom: 0; padding-bottom: 0">
        <v-flex style="min-width: 40vw; max-width: 40vw;">
          <v-text-field
          :label="this.$i18n.t('configuration.name')"
          v-model="item.name"
          :readonly="!hasPermissions(rolePermission)"
          :rules="[rules.required]"
          />
        </v-flex>
      </v-layout>
      <v-layout>
        <v-flex style="min-width: 40vw; max-width: 40vw;">
          <v-text-field :label="this.$i18n.t('configuration.description')" v-model="item.description" :readonly="!hasPermissions(rolePermission)"/>
        </v-flex>
      </v-layout>
      <v-layout>
        <v-flex xs4 style="min-width: 30vw;" class="pb-3">
          <v-select
          v-model="item.type"
          :items="Object.keys(items)"
          :label="this.$i18n.t('configuration.type')"
          :disabled="!hasPermissions(rolePermission)"
          :rules="[rules.required]"
        >
          <template
            slot="selection"
            slot-scope="{ item }"
          >
            <span>
              {{formatUnitsType(item)}}
            </span>
          </template>
          <template
            slot="item"
            slot-scope="{ item }"
          >
            <span>
              {{formatUnitsType(item)}}
            </span>
          </template>
        </v-select>
        </v-flex>
      </v-layout>
      <v-layout>
        <v-flex xs4 style="min-width: 30vw">
          <orientation-select :useFlightBoundInsteadOfOrientationType="true"
          :label="$i18n.t('common.orientation')" v-model="item.flightBound" :disabled="!hasPermissions(rolePermission)"></orientation-select>
        </v-flex>
      </v-layout>
    </v-container>
  </v-card-text>
</template>
<script>

import RolePermissions from '../../mixins/RolePermissions.vue';
import TypeFieldSelect from './TypeFieldSelect.vue';
import { MilestoneTypes } from '../../shared/model/milestoneTypes';
import OrientationSelect from '../../rampService/OrientationSelect.vue';

export default {
  name: 'MilestoneEditCardContent',
  mixins: [RolePermissions],
  props: {
    item: { type: Object, required: true },
  },
  components: {
    TypeFieldSelect,
    OrientationSelect,
  },
  data() {
    return {
      items: MilestoneTypes,
      rules: {
        required: value => !!value || this.$i18n.t('common.fieldRequired'),
      },
    };
  },
  computed: {
    rolePermission() {
      return `${this.$route.meta.role}_WR`;
    },
    unitTypes() {
      return [...Array(this.items.length).keys()];
    },
  },
  methods: {
    formatUnitsType(item) {
      return this.$i18n.t(`configuration.${this.items[item]}`);
    },
  },
};
</script>
