export default class RampFilter {
  constructor(
    dateFrom,
    dateTo,
    OnlyRampUnarchived,
    onlyCurrentDesk,
    onlyRemoteParkings,
    showFlights,
    showAircraftGround,
    timezoneDate,
    filterDateByEstimatedTime,
  ) {
    this.dateFrom = dateFrom;
    this.dateTo = dateTo;
    this.OnlyRampUnarchived = OnlyRampUnarchived === true;
    this.onlyCurrentDesk = onlyCurrentDesk === true;
    this.onlyRemoteParkings = onlyRemoteParkings === true;
    this.showFlights = showFlights === true;
    this.showAircraftGround = showAircraftGround === true;
    this.timezoneDate = timezoneDate;
    this.filterDateByEstimatedTime = filterDateByEstimatedTime;
  }

  static get createEmptyFilter() {
    return new RampFilter(null, null, true, false, false, true, true, null, true);
  }
}
