<template>
  <div class="mobile-time-input">
    <div v-if="editable" class="d-flex align-center">
      <v-dialog ref="dialog" v-model="modal2" :return-value.sync="timeValue" persistent lazy full-width width="450px">
        <template v-slot:activator="{ on }">
          <v-text-field :name="name" :id="name" hide-details class="pa-0" v-model="timeValue" readonly
            v-on="on"></v-text-field>
        </template>
        <v-time-picker v-if="modal2" v-model="timeValue" full-width class="mobile-time-input">
          <v-btn class="clearable" icon @click="$refs.dialog.save(null); emitValue(null)" v-if="clearable" large color="white">
            <v-icon color="primary">mdi-trash-can-outline</v-icon>
          </v-btn>
          <v-btn depressed large color="secondary" @click="$refs.dialog.save(getNow()); emitValue(getNow())">{{
      $t("common.now") }}</v-btn>
          <v-btn depressed large flat color="primary" @click="modal2 = false">{{ $t("common.cancel") }}</v-btn>
          <v-btn depressed large color="primary" @click="$refs.dialog.save(timeValue); emitValue(timeValue)">{{
      $t("common.confirm") }}</v-btn>
        </v-time-picker>
      </v-dialog>
    </div>
    <div v-if="!editable" class="d-flex align-center">
      <v-text-field :name="name" :id="name" hide-details class="pa-0" v-model="timeValue" readonly
        disabled></v-text-field>
    </div>
  </div>
</template>

<script>

import StoreSubscribable from '../../mixins/StoreSubscribable.vue';
import MobileFlightUtil from '../model/mobileFlightUtil';

export default {
  name: 'mobile-time-input',
  props: {
    name: {
      type: String,
      required: true,
    },
    value: {
      required: true,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [StoreSubscribable],
  data() {
    return {
      timeValue: null, // Inicializamos timeValue a null
      modal2: false,
    };
  },
  watch: {
    value(newValue) {
      this.setValue(newValue);
    },
  },
  methods: {
    setValue(newValue) {
      this.timeValue = newValue;
      this.value = newValue;
    },
    getNow() {
      this.$emit('now');
      return MobileFlightUtil.getNowTime(this.localTimezone);
    },
    emitValue(newValue) {
      if (this.editable) {
        this.$emit('input', newValue);
      }
    },
  },
  computed: {
    localTimezone() {
      return this.$store.state.time.timezone;
    },
  },
  created() {
    this.setValue(this.value);
  },
};
</script>
<style>
.mobile-time-input .v-time-picker-clock__container {
  max-width: 580px;
}

.mobile-time-input .v-time-picker-clock__item {
  font-size: 30px !important;
}

.mobile-time-input .v-time-picker-title__ampm {
  font-size: 28px;
  line-height: 30px;
  margin: 0px 0px 4px 19px;
}

.mobile-time-input .clearable {
  position: absolute;
  top: 30px;
  left: 30px;
}
</style>
