/* eslint-disable no-continue,no-labels,no-restricted-syntax */

import moment from 'moment';

export default class FlightMessageTemplateEngine {
  static applyTemplateToFlightMobile(messageTemplate, flightComposition, tenant, userAuth) {
    const flight = flightComposition && flightComposition.common ? flightComposition.common : null;
    if (!messageTemplate || !flight) {
      return '';
    }
    const flightDateTime = moment(flight.scheduledTime ? flight.scheduledTime : flight.flightDate).tz('UTC');
    const placeholdersArray = [
      { type: '#FLIGHT.NUMBER#', value: this.generateFlightNumberInformation(flight) },
      { type: '#FLIGHT.DAY#', value: flightDateTime.format('DD') },
      { type: '#FLIGHT.MONTH#', value: flightDateTime.format('MMM').toUpperCase() },
      { type: '#FLIGHT.DESTINATION#', value: flight.airport1Code },
      { type: '#FLIGHT.AIRCRAFT.REGISTRATION#', value: flight.aircraftRegisterNumber ? flight.aircraftRegisterNumber : 'registration' },
      { type: '#FLIGHT.AIRCRAFT.CONFIG#', value: flight.aircraftConfigCode ? flight.aircraftConfigCode : 'config' },
      { type: '#TENANT.CODE#', value: tenant ? tenant.name : 'baseIata' },
      { type: '#LND#', value: flightComposition.times != null && flightComposition.times.landTime != null ? moment(flightComposition.times.landTime).tz('UTC').format('HHmm') : 'hhmm' },
      { type: '#ONB#', value: flightComposition.times != null && flightComposition.times.blockTime != null ? moment(flightComposition.times.blockTime).tz('UTC').format('HHmm') : 'hhmm' },
      { type: '#OFB#', value: flightComposition.times != null && flightComposition.times.blockTime != null ? moment(flightComposition.times.blockTime).tz('UTC').format('HHmm') : 'hhmm' },
      { type: '#AIR#', value: flightComposition.times != null && flightComposition.times.landTime != null ? moment(flightComposition.times.landTime).tz('UTC').format('HHmm') : 'hhmm' },
      { type: '#EET#', value: flightComposition.times != null && flightComposition.times.landTime != null && flightComposition.times.estimatedElapsedTime != null ? moment(moment(flightComposition.times.landTime).format('HHmm'), 'HHmm').add(flightComposition.times.estimatedElapsedTime, 'minute').tz('UTC').format('HHmm') : 'hhmm' },
      { type: '#ETD#', value: flightComposition.times != null && flightComposition.times.estimatedTime != null ? moment(flightComposition.times.estimatedTime).format('HHmm') : 'hhmm' },
      { type: '#PAX#', value: this.generatePaxInformation(flightComposition.passageDistribution) },
      { type: '#TOTAL.WEIGHT#', value: this.generateTotalWeightInformation(flightComposition.operationExtended) },
      { type: '#HOLD.DISTRIBUTION#', value: this.generateHoldDistributionInformation(flightComposition.loadHoldingDistribution) },
      { type: '#PAX.CLASS#', value: this.generatePaxClassInformation(flightComposition.passageDistribution) },
      { type: '#PAD.CLASS#', value: this.generatePadClassInformation(flightComposition.passageDistribution) },
      { type: '#BAG#', value: this.generateBagInformation(flightComposition.operationExtended, flightComposition.passageDistribution) },
      { type: '#CARGO#', value: this.generateCargoInformation(flightComposition.operationExtended) },
      { type: '#MAIL#', value: this.generateMailInformation(flightComposition.operationExtended) },
      { type: '#AVI#', value: this.generateAviInformation(flightComposition.operationExtended) },
      { type: '#HUM#', value: this.generateHumInformation(flightComposition.operationExtended) },
      { type: '#WEA#', value: this.generateWeapInformation(flightComposition.operationExtended) },
      { type: '#PX#', value: this.generateMvtADPaxInformation(flightComposition.passageSummary) },
      { type: '#DL#', value: this.generateMvtADDelayInformation(flightComposition.delays) },
      { type: '#FLIGHT.DATE#', value: flightDateTime.format('ll') },
      { type: '#AIRLINE.CODE#', value: flight.airlineCode ? flight.airlineCode.toUpperCase() : '' },
      { type: '#TRIP.NUMBER#', value: flight.tripNumber },
      { type: '#STD#', value: flight.scheduledTime != null ? moment(flight.scheduledTime).tz('UTC').format('HHmm') : 'hhmm' },
      { type: '#ATD#', value: flightComposition.times != null && flightComposition.times.blockTime != null ? moment(flightComposition.times.blockTime).tz('UTC').format('HHmm') : 'hhmm' },
      { type: '#DELAY.CODES#', value: this.generateDelaysCodesInformation(flightComposition.delays) },
      { type: '#DELAY.TIMES#', value: this.generateDelaysTimesInformation(flightComposition.delays) },
      { type: '#DELAY.REMARKS#', value: this.generateDelaysRemarksInformation(flightComposition.delays) },
      { type: '#PARKING#', value: this.generateParkingInformation(flight) },
      { type: '#OUT.PAX#', value: this.generateOutPaxInformation(flightComposition.passageSummary) },
      { type: '#OUT.BAG#', value: this.generateOutBagInformation(flightComposition.passageDistribution) },
      { type: '#BOARDING.AGENTS#', value: this.generateBoardingAgentsInformation(flightComposition.operation) },
      { type: '#USER.LOGGED.IN#', value: this.generateUserAuth(userAuth) },
    ];

    let message = messageTemplate;
    const replacedElements = [];
    const notDataElements = [];
    const notReplacedElements = [];

    for (let i = 0; i < placeholdersArray.length; i += 1) {
      const oldMessage = message;
      try {
        message = message.replace(new RegExp(placeholdersArray[i].type, 'g'), placeholdersArray[i].value);
        if (message !== oldMessage) {
          if (placeholdersArray[i].value === '' || placeholdersArray[i].value === null) {
            notDataElements.push(placeholdersArray[i]);
            // message = oldMessage;
          } else {
            replacedElements.push(placeholdersArray[i]);
          }
        } else {
          notReplacedElements.push(placeholdersArray[i]);
        }
      } catch (error) {
        console.log('fail to replace', placeholdersArray[i].type, placeholdersArray[i].value); // eslint-disable-line no-console
        console.log(error); // eslint-disable-line no-console
      }
    }
    // Elimina las líneas vacías
    message = message.replace(/^\s*[\r\n]/gm, '');

    return message;
  }

  static generateUserAuth(userAuth) {
    if (userAuth && userAuth.firstName && userAuth.lastName) {
      return `${userAuth.firstName.toUpperCase()} ${userAuth.lastName.toUpperCase()}`;
    }
    if (userAuth && userAuth.firstName) {
      return userAuth.firstName.toUpperCase();
    }
    return '';
  }

  static generateFlightNumberInformation(flight) {
    let flightNumberInformation = null;

    flightNumberInformation = flight.airlineIataCode ? flight.airlineIataCode : flight.airlineIcaoCode;

    flightNumberInformation += flight.tripNumber.toString().padStart(4, '0');

    return flightNumberInformation;
  }

  static generateMvtADPaxInformation(passageSummary) {
    return passageSummary && passageSummary.passengersNumber ? `PX${passageSummary.passengersNumber}` : '';
  }

  static generateOutPaxInformation(passageSummary) {
    return passageSummary && passageSummary.passengersNumber ? `${passageSummary.passengersNumber}` : '';
  }

  static generateOutBagInformation(passageDistribution) {
    return passageDistribution && passageDistribution.totalBags ? `${passageDistribution.totalBags}` : '';
  }

  static generateParkingInformation(flight) {
    if (flight.parkingCode) {
      let parkingInformation = `${flight.parkingCode}(REMOTE=`;
      parkingInformation += flight.parkingRemote ? 'YES' : 'NO';

      return `${parkingInformation})`;
    }
    return '';
  }

  static generateBoardingAgentsInformation(operation) {
    if (operation && operation.boardingAgent1 && operation.boardingAgent2) {
      return `${operation.boardingAgent1.toUpperCase()}/${operation.boardingAgent2.toUpperCase()}`;
    }
    if (operation && operation.boardingAgent1) {
      return operation.boardingAgent1.toUpperCase();
    }
    if (operation && operation.boardingAgent2) {
      return operation.boardingAgent2.toUpperCase();
    }
    return '';
  }

  static generateMvtADDelayInformation(delays) {
    if (delays && delays.flightDelayCode1 && delays.flightDelayCode2 && delays.flightDelayCode3) {
      return `DL${delays.flightDelayCode1}/${delays.flightDelayCode2}/${delays.flightDelayCode3}/${this.getAmountDelay(delays.flightDelayAmount1)}/${this.getAmountDelay(delays.flightDelayAmount2)}/${this.getAmountDelay(delays.flightDelayAmount3)}`;
    }
    if (delays && delays.flightDelayCode1 && delays.flightDelayCode2) {
      return `DL${delays.flightDelayCode1}/${delays.flightDelayCode2}/${this.getAmountDelay(delays.flightDelayAmount1)}/${this.getAmountDelay(delays.flightDelayAmount2)}`;
    }
    if (delays && delays.flightDelayCode1) {
      return `DL${delays.flightDelayCode1}/${this.getAmountDelay(delays.flightDelayAmount1)}`;
    }

    return '';
  }

  static generateDelaysCodesInformation(delays) {
    if (delays && delays.flightDelayCode1 && delays.flightDelayCode2 && delays.flightDelayCode3) {
      return `${delays.flightDelayCode1}/${delays.flightDelayCode2}/${delays.flightDelayCode3}`;
    }
    if (delays && delays.flightDelayCode1 && delays.flightDelayCode2) {
      return `${delays.flightDelayCode1}/${delays.flightDelayCode2}`;
    }
    if (delays && delays.flightDelayCode1) {
      return `${delays.flightDelayCode1}`;
    }

    return '';
  }

  static generateDelaysTimesInformation(delays) {
    if (delays && delays.flightDelayCode1 && delays.flightDelayCode2 && delays.flightDelayCode3) {
      return `${this.getAmountDelay(delays.flightDelayAmount1)}/${this.getAmountDelay(delays.flightDelayAmount2)}/${this.getAmountDelay(delays.flightDelayAmount3)}`;
    }
    if (delays && delays.flightDelayCode1 && delays.flightDelayCode2) {
      return `${this.getAmountDelay(delays.flightDelayAmount1)}/${this.getAmountDelay(delays.flightDelayAmount2)}`;
    }
    if (delays && delays.flightDelayCode1) {
      return `${this.getAmountDelay(delays.flightDelayAmount1)}`;
    }

    return '';
  }

  static generateDelaysRemarksInformation(delays) {
    if (delays && delays.flightDelayCode1 && delays.flightDelayCode2 && delays.flightDelayCode3 && delays.flightDelayRemark1 && delays.flightDelayRemark2 && delays.flightDelayRemark3) {
      return `${delays.flightDelayRemark1} - ${delays.flightDelayRemark2} - ${delays.flightDelayRemark3} `;
    }
    if (delays && delays.flightDelayCode1 && delays.flightDelayCode2 && delays.flightDelayRemark1 && delays.flightDelayRemark2) {
      return `${delays.flightDelayRemark1} - ${delays.flightDelayRemark2} `;
    }
    if (delays && delays.flightDelayCode1 && delays.flightDelayRemark1) {
      return `${delays.flightDelayRemark1} `;
    }

    return '';
  }

  static getAmountDelay(amount) {
    if (amount) {
      const milliseconds = amount ? Number(amount) * 60000 : 0;
      let hours = (Number(moment.duration(milliseconds).days()) * 24) + Number(moment.duration(milliseconds).hours());
      if (hours === 0) {
        hours = '00';
      } else if (hours.toString().length === 1) {
        hours = `0${hours}`;
      }
      let minutes = moment.duration(milliseconds).minutes();
      if (minutes === 0) {
        minutes = '00';
      } else if (minutes.toString().length === 1) {
        minutes = `0${minutes}`;
      }
      return `${hours}${minutes}`;
    }
    return '0000';
    // return amount ? moment('0000', 'HHmm').add(amount, 'minute').format('HHmm') : moment('0000', 'HHmm');
  }

  static generatePaxInformation(passageDistribution) {
    let paxInformation = null;

    paxInformation = passageDistribution && passageDistribution.male != null ? passageDistribution.male : '0';

    paxInformation += passageDistribution && passageDistribution.female != null ? `/${passageDistribution.female}` : '/0';

    paxInformation += passageDistribution && passageDistribution.child != null ? `/${passageDistribution.child}` : '/0';

    if (passageDistribution && passageDistribution.infant != null) {
      paxInformation += `/${passageDistribution.infant}`;
    }
    return paxInformation;
  }

  static generateTotalWeightInformation(operationExtended) {
    return operationExtended == null || operationExtended.loadHoldingWeight == null ? 'T0' : `T${operationExtended.loadHoldingWeight}`;
  }

  static generateHoldDistributionInformation(loadHoldingDistribution) {
    if (loadHoldingDistribution == null || (loadHoldingDistribution.weightHolding1 == null && loadHoldingDistribution.weightHolding2 == null && loadHoldingDistribution.weightHolding3 == null && loadHoldingDistribution.weightHolding4 == null && loadHoldingDistribution.weightHolding5 == null)) {
      return '';
    }

    let holdInformation = '';

    if (loadHoldingDistribution.weightHolding1 != null) {
      holdInformation += `.1/${loadHoldingDistribution.weightHolding1}`;
    }

    if (loadHoldingDistribution.weightHolding2 != null) {
      holdInformation += `.2/${loadHoldingDistribution.weightHolding2}`;
    }

    if (loadHoldingDistribution.weightHolding3 != null) {
      holdInformation += `.3/${loadHoldingDistribution.weightHolding3}`;
    }

    if (loadHoldingDistribution.weightHolding4 != null) {
      holdInformation += `.4/${loadHoldingDistribution.weightHolding4}`;
    }

    if (loadHoldingDistribution.weightHolding5 != null) {
      holdInformation += `.5/${loadHoldingDistribution.weightHolding5}`;
    }
    return holdInformation;
  }

  static generatePaxClassInformation(passageDistribution) {
    if (this.hasPaxClassInformation(passageDistribution)) {
      let paxClassInformation = '.PAX';

      if (passageDistribution.paxFirstClass != null) {
        paxClassInformation += `/${passageDistribution.paxFirstClass}`;
      }

      if (passageDistribution.paxSecondClass != null) {
        paxClassInformation += `/${passageDistribution.paxSecondClass}`;
      }

      if (passageDistribution.paxThirdClass != null) {
        paxClassInformation += `/${passageDistribution.paxThirdClass}`;
      }

      return paxClassInformation;
    }
    return '.PAX/0';
  }

  static generatePadClassInformation(passageDistribution) {
    if (this.hasPadClassInformation(passageDistribution)) {
      let padClassInformation = '.PAD';

      if (passageDistribution.padFirstClass != null) {
        padClassInformation += `/${passageDistribution.padFirstClass}`;
      }

      if (passageDistribution.padSecondClass != null) {
        padClassInformation += `/${passageDistribution.padSecondClass}`;
      }

      if (passageDistribution.padThirdClass != null) {
        padClassInformation += `/${passageDistribution.padThirdClass}`;
      }

      return padClassInformation;
    }
    return '.PAD/0';
  }

  static generateBagInformation(operationExtended, passageDistribution) {
    if (operationExtended && operationExtended.loadBag != null && operationExtended.loadBag !== 0) {
      return `.B/${operationExtended.loadBag}`;
    }
    if (passageDistribution && passageDistribution.realWeightBags != null && passageDistribution.realWeightBags !== 0) {
      return `.B/${passageDistribution.realWeightBags}`;
    }
    return '.B/0';
  }

  static generateCargoInformation(operationExtended) {
    return operationExtended && operationExtended.loadCargo != null ? `.C/${operationExtended.loadCargo}` : '.C/0';
  }

  static generateMailInformation(operationExtended) {
    return operationExtended && operationExtended.loadMail != null ? `.M/${operationExtended.loadMail}` : '.M/0';
  }

  static generateAviInformation(operationExtended) {
    return operationExtended && operationExtended.loadAVIH != null && operationExtended.loadAVIH !== 0 ? `.AVI/${operationExtended.loadAVIH}` : '';
  }

  static generateHumInformation(operationExtended) {
    return operationExtended && operationExtended.loadHUM != null && operationExtended.loadHUM !== 0 ? `.HUM/${operationExtended.loadHUM}` : '';
  }

  static generateWeapInformation(operationExtended) {
    return operationExtended && operationExtended.loadWeap != null && operationExtended.loadWeap !== 0 ? `.WEA/${operationExtended.loadWeap}` : '';
  }

  static hasPaxClassInformation(passageDistribution) {
    return passageDistribution != null && (passageDistribution.paxFirstClass != null || passageDistribution.paxSecondClass != null || passageDistribution.paxThirdClass != null);
  }

  static hasPadClassInformation(passageDistribution) {
    return passageDistribution != null && (passageDistribution.padFirstClass != null || passageDistribution.padSecondClass != null || passageDistribution.padThirdClass != null);
  }
}
