<template>
  <div v-if="modeEdition">
    <v-text-field :label="label" type="number" v-model="valueNotFormatted" @blur="onBlur" :readonly="readonly" :disabled="disabled"/>
  </div>
  <div v-else>
    <v-text-field :label="label" :value="valueFormatted" @focus="onFocus" :rules="rules" :readonly="readonly" :disabled="disabled"/>
  </div>
</template>

<script>

export default {
  name: 'currency-input',
  props: {
    value: { required: true },
    currency: { type: String },
    label: { type: String },
    disabled: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
    rules: { type: Array },
  },
  data() {
    return {
      modeEdition: false,
      valueNotFormatted: null,
    };
  },
  computed: {
    valueFormatted() {
      if (this.valueNotFormatted != null && this.valueNotFormatted !== '' && this.currency != null) {
        return new Intl.NumberFormat(this.$store.getters.getCurrencies.find(p => p.id === this.currency).locale, { style: 'currency', currency: this.currency }).format(parseFloat(this.valueNotFormatted));
      }
      return this.valueNotFormatted;
    },
  },
  watch: {
    currency: {
      handler() {
        this.emitInput();
      },
    },
    value: {
      handler() {
        if (this.value && Number.isNaN(this.value)) {
          this.valueNotFormatted = parseFloat(this.value.replaceAll(',', '.'));
        } else {
          this.valueNotFormatted = this.value && this.value !== '' ? parseFloat(this.value.toString().replaceAll(',', '.')) : this.value;
        }
      },
    },
  },
  methods: {
    onFocus() {
      if (!this.readonly && !this.disabled) {
        this.modeEdition = true;
      }
    },
    onBlur() {
      this.modeEdition = false;
      this.emitInput();
    },
    emitInput() {
      let parseValue = this.valueNotFormatted;
      if (this.valueNotFormatted && this.currency) {
        parseValue = new Intl.NumberFormat(this.$store.getters.getCurrencies.find(p => p.id === this.currency).locale, { style: 'currency', currency: this.currency })
          .format(parseFloat(this.valueNotFormatted))
          .replace(this.$store.getters.getCurrencies.find(p => p.id === this.currency).replacementRule, '')
          .replaceAll(',', '.');
      }

      this.$emit('input', parseValue);
    },
  },
  mounted() {
    if (this.value && Number.isNaN(this.value)) {
      this.valueNotFormatted = this.value && this.value !== '' ? parseFloat(this.value.replaceAll(',', '.')) : this.value;
    } else {
      this.valueNotFormatted = this.value && this.value !== '' ? parseFloat(this.value.toString().replaceAll(',', '.')) : this.value;
    }
  },
};
</script>

<style lang="scss" scoped>

</style>
