<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>

      <ag-grid-dialog-template-editor :is-dialog-open="isDialogOpen" @save="save" @cancel="cancel" :saveDisabled="validData">

      <template v-slot:header>
       {{$t("operations.messagesModalTitle")}} {{flightNumber}}
      </template>

      <template v-slot:default>
        <v-card>
          <v-card-text class="message-table">
            <ag-grid-vue
              class="ag-theme-material"
              :columnDefs="columnDefs"
              :defaultColDef="defaultColDef"
              @grid-ready="setAgGridApi"
              :gridOptions="gridOptions"
              rowSelection="multiple"
              @selection-changed="onSelectionChanged"
              :frameworkComponents="frameworkComponents"
              :rowData="rowData">
            </ag-grid-vue>
          </v-card-text>
        </v-card>
        <MessageSenderDialog :flight-composition="flightComposition" :visible="senderDialog" @close="closeMessageSenderDialog" v-if="senderDialog" />
      </template>
      <template v-slot:footer>
        <v-btn v-if="hasPermissions(rolePermission)" color="secondary" flat @click="addMessage()"><v-icon>add</v-icon></v-btn>
        <v-btn v-if="messagesSelected.length > 0 && hasPermissions(rolePermission)" color="error" flat @click="removeMessages()"><v-icon>delete</v-icon></v-btn>
        <v-btn v-if="messagesSelected.length > 0 && hasPermissions(rolePermission)" color="error" flat @click="printMessages()"><v-icon>print</v-icon></v-btn>
      </template>
    </ag-grid-dialog-template-editor>
  </div>
</template>

<script>
import Vue from 'vue';
import print from 'print-js';
import { AgGridVue } from 'ag-grid-vue';
import AgGridDialogParentCellEditor from '../../mixins/AgGridDialogParentCellEditor.vue';
import AgGridDialogTemplateEditor from '../../templates/AgGridDialogTemplateEditor.vue';
import agGridDateTimeFormatter from '../../../formatters/agGridDateTimeFormatter';
import CrudOperationsDTO from '../../../../shared/model/crudOperationsDTO';
import { JSUtils } from '../../../../shared/js/JSUtils';
import FlightMessage from '../../../../messages/model/flightMessage';
import AgGridMessageTypeSelectCellEditor from '../../AgGridMessageTypeSelectCellEditor.vue';
import AgGridToggleCellEditor from '../../AgGridToggleCellEditor.vue';
import AgGridCheckboxCellRenderer from '../../../cell-renderers/AgGridCheckboxCellRenderer.vue';
import { cellClassAgGridRules } from '../../../mixins/cellClassAgGridRules';
import AgGridOptionsConfigurable from '../../../mixins/AgGridOptionsConfigurable.vue';
import MessageSenderDialog from '../../../../messages/sender/MessageSenderDialog.vue';
import { REQUEST_ERROR, REQUEST_START, REQUEST_SUCCESS } from '../../../../../store/store';
import { NOTIFICATION_SHOW } from '../../../../../store/modules/notifications';
import FileSerializable from '../../../../mixins/FileSerializable.vue';

export default Vue.extend({
  mixins: [AgGridDialogParentCellEditor, AgGridOptionsConfigurable, FileSerializable],
  components: {
    AgGridDialogTemplateEditor,
    AgGridVue,
    MessageSenderDialog,
  },
  data() {
    const that = this;
    return {
      frameworkComponents: {
        AgGridMessageTypeSelectCellEditor,
        AgGridToggleCellEditor,
      },
      show: false,
      senderDialog: false,
      flightMsg: FlightMessage.createEmptyFlightMessage(),
      defaultColDef: {
        cellClassRules: cellClassAgGridRules,
      },
      gridOptions: {
        getRowClass: this.getRowClass,
      },
      rowData: [],
      validData: true,
      columnDefs: [
        {
          checkboxSelection: true,
          width: 36,
          editable: that.isCellEditable,
        },
        {
          field: 'ts',
          headerValueGetter() {
            return that.$i18n.t('operations.messagesModalTableDateTime');
          },
          valueFormatter: agGridDateTimeFormatter,
          editable: false,
        },
        {
          field: 'bound',
          headerValueGetter() {
            return that.$i18n.t('operations.messagesModalTableBound');
          },
          editable: false,
        },
        {
          field: 'typeCode',
          headerValueGetter() {
            return that.$i18n.t('operations.aircraftType');
          },
          editable: false,
          cellEditor: 'AgGridMessageTypeSelectCellEditor',
          cellEditorCustomConfig: {
            fieldId: 'typeId',
          },
          onCellValueChanged: this.validateMessage,
        },
        {
          width: 750,
          field: 'content',
          headerValueGetter() {
            return that.$i18n.t('operations.messagesModalTableMessage');
          },
          editable: false,
          autoHeight: true,
          cellClass: 'cell-wrap-text-multiline',
          onCellValueChanged: this.validateMessage,
        },
        {
          width: 75,
          field: 'read',
          headerValueGetter() {
            return that.$i18n.t('operations.remarksModalReadLabel');
          },
          editable: that.isCellEditable,
          singleClickEdit: true,
          cellClass: 'ag-grid-popover-cell',
          cellEditor: 'AgGridToggleCellEditor',
          cellRendererFramework: AgGridCheckboxCellRenderer,
          onCellValueChanged: this.validateMessage,
        },
      ],
      messagesSelected: [],
      crudOperationsDto: new CrudOperationsDTO(),
    };
  },
  methods: {
    getValue() {
      if (this.isSaving) {
        this.crudOperationsDto.updates.push(...this.rowData);
        this.requestInit();
        this.$http.put(`messages/flights/${this.flightId}`, this.crudOperationsDto)
          .then(resp => this.dialogRequestSuccessCallback(this, resp))
          .catch(error => this.requestErrorCallback(this, error));
      }

      return this.params.value;
    },
    onColumnResized(event) {
      if (event.finished) {
        this.gridApi.resetRowHeights();
      }
    },
    closeMessageSenderDialog(reload) {
      this.senderDialog = false;
      if (reload) {
        this.getMessages();
      }
    },
    addMessage() {
      this.senderDialog = true;
    },
    removeMessages() {
      if (this.messagesSelected.length > 0) {
        this.crudOperationsDto.deletes.push(...this.messagesSelected.filter(message => !!message.id));

        this.messagesSelected.forEach((msg) => {
          const index = this.rowData.indexOf(msg);
          this.rowData.splice(index, 1);
        });
        this.messagesSelected = [];
        this.validateMessage();
      }
    },
    printMessages() {
      this.$store.commit(REQUEST_START);
      const messageIds = this.messagesSelected.map(message => message.id);
      this.$http
        .post(`messages/export-pdf-message/${messageIds}`)
        .then((resp) => {
          const reader = new FileReader();
          this.$store.commit(REQUEST_SUCCESS);
          const blob = new Blob([this.s2ab(atob(resp.data.content))], {
            type: resp.data.contentType,
          });
          reader.readAsDataURL(blob);
          reader.onloadend = () => {
            const base64pdf = reader.result.split(',')[1];

            print({
              printable: base64pdf,
              type: 'pdf',
              base64: true,
              showModal: true,
            });
          };
        })
        .catch((err) => {
          this.$store.commit(REQUEST_ERROR, err);
          this.$store.commit(NOTIFICATION_SHOW, {
            message:
              err.response.data.title
              || this.$i18n.t('notifications.errorNotification'),
            color: 'error',
          });
        });
    },
    onSelectionChanged(event) {
      if (event.api.getSelectedNodes().length > 0) {
        this.messagesSelected = event.api.getSelectedNodes().map(n => n.data);
      } else {
        this.messagesSelected = [];
      }
    },
    validateMessage() {
      this.validData = false;
      this.rowData.forEach((data) => {
        if (data.typeId == null) {
          this.validData = true;
        }
        if (data.content == null || data.content === '') {
          this.validData = true;
        }
      });
    },
    getMessages() {
      this.$http.get(`messages/flights/${this.flightId}`)
        .then((resp) => {
          this.rowData = JSUtils.cast(resp.data, FlightMessage);
        });
    },
    isCellEditable() {
      return this.hasPermissions(this.rolePermission);
    },
  },
  computed: {
    flightComposition() {
      const currentFlight = this.params.node.data;
      return currentFlight.getBoundComposition(this.params.column.colDef.colCustomConfig.flightBound);
    },
    flight() {
      return this.flightComposition.common;
    },
    rolePermission() {
      return `${this.$route.meta.role}_WR`;
    },
  },
  created() {
    this.getMessages();
  },
});
</script>
<style lang="scss">
.message-table {
  padding: 0;
}
.newMsgCard {
  margin-top: 10px;
}
</style>
