export default class OperationFilter {
  constructor(dateFrom, dateTo, onlyUnarchived, onlyCurrentDesk, showFlights, showAircraftGround, timezoneDate, filterDateByEstimatedTime) {
    this.dateFrom = dateFrom;
    this.dateTo = dateTo;
    this.onlyUnarchived = onlyUnarchived === true;
    this.onlyCurrentDesk = onlyCurrentDesk === true;
    this.showFlights = showFlights === true;
    this.showAircraftGround = showAircraftGround === true;
    this.timezoneDate = timezoneDate;
    this.filterDateByEstimatedTime = filterDateByEstimatedTime;
  }

  static get createEmptyFilter() {
    return new OperationFilter(null, null, true, false, true, true, null, true);
  }
}
