import { JSUtils } from '../js/JSUtils';
import FlightPassageOutboundInformation from '../../ag-grid/cell-editors/model/flightPassageOutboundInformation';
import FlightPassage from '../../ag-grid/cell-editors/model/flightPassage';

export default class FlightPassageFactory {
  static mapFlightPassageOutboundInformation(data) {
    if (data && data.constructor === Array && data.length > 0) {
      return data.map(d => this.createFlightPassageOutboundInformationFromData(d));
    }
    return [];
  }

  static createFlightPassageOutboundInformationFromData(data) {
    if (!data) {
      return data;
    }
    const flightPassageInformation = JSUtils.cast(data, FlightPassageOutboundInformation);

    flightPassageInformation.cabin0A = flightPassageInformation.cabin0A || 0;
    flightPassageInformation.cabin0B = flightPassageInformation.cabin0B || 0;
    flightPassageInformation.cabin0C = flightPassageInformation.cabin0C || 0;
    flightPassageInformation.cabin0D = flightPassageInformation.cabin0D || 0;
    flightPassageInformation.cabin0E = flightPassageInformation.cabin0E || 0;
    flightPassageInformation.cabin0F = flightPassageInformation.cabin0F || 0;
    flightPassageInformation.comail = flightPassageInformation.comail || 0;
    flightPassageInformation.jumpseats = flightPassageInformation.jumpseats || 0;
    flightPassageInformation.lastMinuteChangesPax = flightPassageInformation.lastMinuteChangesPax || 0;
    flightPassageInformation.lastMinuteChangesInf = flightPassageInformation.lastMinuteChangesInf || 0;
    flightPassageInformation.lastMinuteChangesBag = flightPassageInformation.lastMinuteChangesBag || 0;
    flightPassageInformation.avih = flightPassageInformation.avih || 0;
    flightPassageInformation.stcr = flightPassageInformation.stcr || 0;
    flightPassageInformation.totalPriority = flightPassageInformation.totalPriority || 0;
    flightPassageInformation.localPaxCheckInCounters = flightPassageInformation.localPaxCheckInCounters || 0;
    flightPassageInformation.localPaxCheckInWeb = flightPassageInformation.localPaxCheckInWeb || 0;
    flightPassageInformation.localPaxCheckInKiosks = flightPassageInformation.localPaxCheckInKiosks || 0;
    flightPassageInformation.connectionPax = flightPassageInformation.connectionPax || 0;

    return flightPassageInformation;
  }

  static createFlightPassageFromData(data, flightPassageInformation) {
    if (!data) {
      return data;
    }

    const flightPassage = JSUtils.cast(data, FlightPassage);

    if (flightPassage.isTotalPaxOnBoardedPerAirport !== null && flightPassage.isTotalPaxOnBoardedPerAirport) {
      if (flightPassageInformation) {
        flightPassage.destLabel = (flightPassageInformation.flightBound === 'INBOUND')
          ? `Boarded from ${flightPassage.dest}`
          : `Boarded to ${flightPassage.dest}`;
      }
    } else {
      flightPassage.destLabel = flightPassage.dest;
    }
    flightPassage.male = flightPassage.male || 0;
    flightPassage.female = flightPassage.female || 0;
    flightPassage.child = flightPassage.child || 0;
    flightPassage.infant = flightPassage.infant || 0;
    flightPassage.paxFirstClass = flightPassage.paxFirstClass || 0;
    flightPassage.paxSecondClass = flightPassage.paxSecondClass || 0;
    flightPassage.paxThirdClass = flightPassage.paxThirdClass || 0;
    flightPassage.padFirstClass = flightPassage.padFirstClass || 0;
    flightPassage.padSecondClass = flightPassage.padSecondClass || 0;
    flightPassage.padThirdClass = flightPassage.padThirdClass || 0;
    flightPassage.bagsFirstClass = flightPassage.bagsFirstClass || 0;
    flightPassage.bagsSecondClass = flightPassage.bagsSecondClass || 0;
    flightPassage.bagsThirdClass = flightPassage.bagsThirdClass || 0;
    flightPassage.totalPaxOnBoardAdult = flightPassage.totalPaxOnBoardAdult || 0;
    flightPassage.totalPaxOnBoardInfant = flightPassage.totalPaxOnBoardInfant || 0;
    flightPassage.totalBags = flightPassage.totalBags || 0;
    flightPassage.realWeightBags = flightPassage.realWeightBags || 0;
    flightPassage.wchc = flightPassage.wchc || 0;
    flightPassage.wchr = flightPassage.wchr || 0;
    flightPassage.wchs = flightPassage.wchs || 0;
    flightPassage.wcbd = flightPassage.wcbd || 0;
    flightPassage.wcbw = flightPassage.wcbw || 0;
    flightPassage.wclb = flightPassage.wclb || 0;
    flightPassage.totalWheelchairs = flightPassage.totalWheelchairs || 0;
    flightPassage.umnr = flightPassage.umnr || 0;
    flightPassage.petc = flightPassage.petc || 0;

    return flightPassage;
  }
}
