<template>
<div>
  <flight-message-associate-flight-modal v-if="associateDialog" :dialog="associateDialog" :flight-message="flightMessage" @close="closeAssociateDialog" />
  <flight-message-disassociate-flight-modal v-if="disassociateDialog" :dialog="disassociateDialog" :flight-message="flightMessage" @close="closeDisassociateDialog" />

  <flight-messages-list-table :items="items" :isFilterDisplayed="this.isFilterDisplayed" @associate-flight="associateFlight" @disassociate-flight="disassociateFlight"></flight-messages-list-table>
</div>
</template>
<script>
import FlightMessagesListTable from './FlightMessagesListTable.vue';
import FlightMessageAssociateFlightModal from '../associate/FlightMessageAssociateFlightModal.vue';
import FlightMessageDisassociateFlightModal from '../associate/FlightMessageDisassociateFlightModal.vue';
import { MESSAGES_FILTER_RESET, MESSAGES_FIND_FLIGHTS } from '../../../store/modules/flightMessages/flightMessages';

export default {
  name: 'flight-messages-list-container',
  components: {
    FlightMessagesListTable,
    FlightMessageAssociateFlightModal,
    FlightMessageDisassociateFlightModal,
  },
  props: {
    isFilterDisplayed: { type: Boolean, required: false, default: false },
  },
  data() {
    return {
      flightMessage: {},
      associateDialog: false,
      disassociateDialog: false,
    };
  },
  mounted() {
    this.$store.commit(MESSAGES_FILTER_RESET);
    this.$store.dispatch(MESSAGES_FIND_FLIGHTS);
  },
  computed: {
    items() {
      return this.$store.state.flightMessages.messages;
    },
  },
  methods: {
    associateFlight(message) {
      this.flightMessage = message;
      this.associateDialog = true;
    },
    disassociateFlight(message) {
      this.flightMessage = message;
      this.disassociateDialog = true;
    },
    closeAssociateDialog(result) {
      this.associateDialog = false;
      this.find(result);
    },
    closeDisassociateDialog(result) {
      this.disassociateDialog = false;
      this.find(result);
    },
    find(result) {
      if (result && result.doFind) {
        this.$store.dispatch(MESSAGES_FIND_FLIGHTS);
      }
    },
  },
};
</script>
<style lang="scss">


</style>
