<template>
  <ag-grid-template-cell-renderer :params="params">
    <v-tooltip bottom v-if="params.value && params.value.tooltip">
      <template slot="activator">
        <span :style="contentStyleObject">{{params.value.value}}</span>
        <span v-if="params.value.asterisk" :style="asteriskStyleObject">*</span>
      </template>
      <span>{{params.value.tooltip}}</span>
    </v-tooltip>
    <template v-if="params.value && !params.value.tooltip">
      <span :style="contentStyleObject">{{params.value.value}}</span>
      <span v-if="params.value.asterisk" :style="asteriskStyleObject">*</span>
    </template>
  </ag-grid-template-cell-renderer>
</template>

<script>
import Vue from 'vue';
import AgGridTemplateCellRenderer from './templates/AgGridTemplateCellRenderer.vue';

export default Vue.extend({
  components: {
    AgGridTemplateCellRenderer,
  },
  computed: {
    asteriskStyleObject() {
      return {
        color: (this.params && this.params.value && this.params.value.styleObject && this.params.value.styleObject.asteriskColor) ? this.params.value.styleObject.asteriskColor : '',
      };
    },
    contentStyleObject() {
      if (this.params && this.params.value && this.params.value.styleObject && this.params.value.styleObject.content) {
        return this.params.value.styleObject.content;
      }

      return null;
    },
  },
});
</script>
