<template>
  <v-layout row wrap v-if="items.length > 0" style="margin:0; padding:0;">
    <v-flex>
      <v-icon color="warning">mdi-alert</v-icon><span> {{$t("rampServiceImport.flightsNotFoundAlert")}}</span>
    </v-flex>
    <v-flex>
      <ag-grid-vue
        class="ag-theme-material"
        :defaultColDef="defaultColDef"
        :columnDefs="columnDefs"
        :rowData="items"
        :onGridReady="onGridReady"
        :suppressHorizontalScroll="true"
        style="width: 100%; height: calc(100vh - 500px);">
      </ag-grid-vue>
    </v-flex>
    <v-flex>
        <div>
          <v-checkbox
          color="primary"
          v-model="servicesCorrect"
          :label="this.$i18n.t('rampServiceImport.flightsNotFound')"
          @change="isValidData"
          ></v-checkbox>
        </div>
    </v-flex>
  </v-layout>
  <v-layout row justify-center v-else>
  </v-layout>
</template>
<script>

import { AgGridVue } from 'ag-grid-vue';
import TimezoneConfigurable from '../../mixins/TimezoneConfigurable.vue';
import AgGridValueComparable from '../../ag-grid/mixins/AgGridValueComparable.vue';
import { cellClassAgGridRules } from '../../ag-grid/mixins/cellClassAgGridRules';
import agGridTextCustomFilterComparator from '../../ag-grid/filters/agGridTextCustomFilterComparator';
import agGridTextCustomFilterFormatter from '../../ag-grid/filters/agGridTextCustomFilterFormatter';
import agGridDateTimeFormatter from '../../ag-grid/formatters/agGridDateTimeFormatter';
import { FlightBound } from '../../shared/model/flightBound';

export default {
  name: 'ramp-service-import-step-file-validation-content',
  mixins: [TimezoneConfigurable, AgGridValueComparable],
  components: {
    AgGridVue,
  },
  props: {
    summaryRampServices: {
      type: Object,
    },
  },
  computed: {
    items() {
      return this.summaryRampServices ? this.summaryRampServices.servicesThatNotFoundFlight : [];
    },
  },
  watch: {
    items: {
      handler() {
        this.isValidData();
      },
      deep: true,
    },
  },
  data() {
    const that = this;
    return {
      validData: true,
      servicesCorrect: false,
      defaultColDef: {
        cellClassRules: cellClassAgGridRules,
        resizable: true,
        filter: true,
        sortable: true,
        filterParams: {
          textCustomComparator: agGridTextCustomFilterComparator,
          textFormatter: agGridTextCustomFilterFormatter,
        },
      },
      columnDefs: [
        {
          headerValueGetter() {
            return that.$i18n.t('rampServices.identifier');
          },
          field: 'serviceIdentifier',
        },
        {
          headerValueGetter() {
            return that.$i18n.t('rampServices.units');
          },
          field: 'units',
        },
        {
          headerTooltip: `${this.$i18n.t('rampServices.requestTime')}`,
          headerValueGetter() {
            return that.$i18n.t('rampServices.requestTime');
          },
          field: 'requestTs',
          cellClass: 'date-time-cell',
          valueFormatter: agGridDateTimeFormatter,
        },
        {
          headerTooltip: `${this.$i18n.t('rampServices.requestedStartTime')}`,
          headerValueGetter() {
            return that.$i18n.t('rampServices.requestedStartTime');
          },
          field: 'requestedTs',
          cellClass: 'date-time-cell',
          valueFormatter: agGridDateTimeFormatter,
        },
        {
          headerTooltip: `${this.$i18n.t('rampServices.requestedEndTime')}`,
          headerValueGetter() {
            return that.$i18n.t('rampServices.requestedEndTime');
          },
          field: 'requestedEndTs',
          cellClass: 'date-time-cell',
          valueFormatter: agGridDateTimeFormatter,
        },
        {
          headerValueGetter() {
            return that.$i18n.t('rampServices.startTime');
          },
          field: 'startTs',
          cellClass: 'date-time-cell',
          valueFormatter: agGridDateTimeFormatter,
        },
        {
          headerValueGetter() {
            return that.$i18n.t('rampServices.endTime');
          },
          field: 'finishTs',
          cellClass: 'date-time-cell',
          valueFormatter: agGridDateTimeFormatter,
        },
        {
          headerValueGetter() {
            return that.$i18n.t('common.requester');
          },
          field: 'requesterName',
        },
        {
          headerValueGetter() {
            return that.$i18n.t('operations.remarksExtended');
          },
          field: 'remarks',
        },
        {
          headerValueGetter() {
            return that.$i18n.t('common.airline');
          },
          field: 'airlineCode',
        },
        {
          headerValueGetter() {
            return that.$i18n.t('operations.flightNumber');
          },
          field: 'flightTripNumber',
        },
        {
          headerValueGetter() {
            return that.$i18n.t('common.additional');
          },
          field: 'additional',
        },
        {
          headerValueGetter() {
            return that.$i18n.t('common.flightDate');
          },
          field: 'flightDate',
        },
        {
          headerValueGetter() {
            return that.$i18n.t('operations.aircraftRegistration');
          },
          field: 'aircraftRegisterNumber',
        },
        {
          headerValueGetter() {
            return that.$i18n.t('operations.messagesModalTableBound');
          },
          field: 'bound',
          valueGetter(params) {
            if (params.data.bound && params.data.bound === FlightBound.INBOUND) {
              return '1';
            }
            if (params.data.bound && params.data.bound === FlightBound.OUTBOUND) {
              return '0';
            }
            return params.data.bound;
          },
          comparator: this.objectWithValueComparator,
        },
        {
          headerValueGetter() {
            return that.$i18n.t('common.airport');
          },
          field: 'airportIataCode',
        },
      ],
    };
  },
  methods: {
    onGridReady(params) {
      this.$emit('on-grid-ready', params);
    },
    isValidData() {
      this.validData = (!this.items || this.items.length === 0 || this.servicesCorrect);
      this.$emit('dataFlightsImportOptionValidation', this.validData);
    },
  },
  mounted() {
    this.isValidData();
  },
};
</script>
