import { VCardText } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCardText,[_c(VContainer,{staticStyle:{"margin-bottom":"0","padding-bottom":"0"}},[_c(VLayout,{staticStyle:{"margin-bottom":"0","padding-bottom":"0"}},[_c(VFlex,{staticStyle:{"min-width":"40vw","max-width":"40vw"}},[_c(VTextField,{attrs:{"label":this.$i18n.t('configuration.name'),"readonly":!_vm.hasPermissions(_vm.rolePermission),"rules":[_vm.rules.required]},model:{value:(_vm.item.name),callback:function ($$v) {_vm.$set(_vm.item, "name", $$v)},expression:"item.name"}})],1)],1),_c(VLayout,[_c(VFlex,{staticStyle:{"min-width":"40vw","max-width":"40vw"}},[_c(VTextField,{attrs:{"label":this.$i18n.t('configuration.description'),"readonly":!_vm.hasPermissions(_vm.rolePermission)},model:{value:(_vm.item.description),callback:function ($$v) {_vm.$set(_vm.item, "description", $$v)},expression:"item.description"}})],1)],1),_c(VLayout,[_c(VFlex,{staticClass:"pb-3",staticStyle:{"min-width":"30vw"},attrs:{"xs4":""}},[_c(VSelect,{attrs:{"items":Object.keys(_vm.items),"label":this.$i18n.t('configuration.type'),"disabled":!_vm.hasPermissions(_vm.rolePermission),"rules":[_vm.rules.required]},scopedSlots:_vm._u([{key:"selection",fn:function({ item }){return [_c('span',[_vm._v(" "+_vm._s(_vm.formatUnitsType(item))+" ")])]}},{key:"item",fn:function({ item }){return [_c('span',[_vm._v(" "+_vm._s(_vm.formatUnitsType(item))+" ")])]}}]),model:{value:(_vm.item.type),callback:function ($$v) {_vm.$set(_vm.item, "type", $$v)},expression:"item.type"}})],1)],1),_c(VLayout,[_c(VFlex,{staticStyle:{"min-width":"30vw"},attrs:{"xs4":""}},[_c('orientation-select',{attrs:{"useFlightBoundInsteadOfOrientationType":true,"label":_vm.$i18n.t('common.orientation'),"disabled":!_vm.hasPermissions(_vm.rolePermission)},model:{value:(_vm.item.flightBound),callback:function ($$v) {_vm.$set(_vm.item, "flightBound", $$v)},expression:"item.flightBound"}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }