<template>
  <div>
    <v-autocomplete
      placeholder="..."
      :value="value"
      :label="label"
      :items="items"
      :filter="customFilter"
      :clearable="true"
      item-text="registerNumber"
      item-value="id"
      :rules="rules"
      :disabled="disabled"
      :ref="refComp"
      @input="$emit('input', $event)"
      @change="onChangeSelection"
    >
      <template
        slot="item"
        slot-scope="{ item, tile }"
      >
        <v-list-tile-content>
          <v-list-tile-title v-text="item.registerNumber"></v-list-tile-title>
          <v-list-tile-sub-title v-text="getAircraftSubtitle(item)"></v-list-tile-sub-title>
        </v-list-tile-content>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import { GET_AIRCRAFT } from '../../store/modules/aircraft/aircraftCatalog';

export default {
  props: {
    value: { required: true },
    label: { type: String },
    charLenght: { type: Number },
    rules: { type: Array },
    disabled: { type: Boolean },
    refComp: {
      type: String,
      required: false,
      default: 'refComp',
    },
  },
  computed: {
    items: {
      get() {
        return this.$store.getters.getActiveAircrafts;
      },
    },
  },
  methods: {
    getAircrafts() {
      this.$store.dispatch(GET_AIRCRAFT);
    },
    customFilter(item, queryText) {
      const textOne = item.registerNumber ? item.registerNumber.toLowerCase() : '';
      const textTwo = item.typeCode ? item.typeCode.toLowerCase() : '';
      const textThree = item.typeComments ? item.typeComments.toLowerCase() : '';
      const searchText = queryText.toLowerCase();

      return textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1 || textThree.indexOf(searchText) > -1;
    },
    getAircraftSubtitle(aircraft) {
      return `${aircraft.typeCode} - ${aircraft.typeComments}`;
    },
    onChangeSelection(selectionId) {
      const aircraft = this.items.filter(a => a.id === selectionId)[0];
      this.$emit('change', aircraft);
    },
  },
  mounted() {
    this.getAircrafts();
  },
};
</script>
