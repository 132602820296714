<template>
  <v-layout row>
    <filter-checkbox v-model="value.delay" :label="this.$i18n.t('invoicing.delay')" v-char-length="5" @input="$emit('input', value)"/>
  </v-layout>
</template>

<script>
import FilterCheckbox from './FilterCheckbox.vue';

export default {
  name: 'FlightService',
  components: { FilterCheckbox },
  props: ['value'],
};
</script>

<style>
</style>
