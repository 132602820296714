<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-text-field
    v-model="timeString"
    v-char-length="6"
    mask="##:##X"
    :return-masked-value="true"
    :label="label"
    :ref="refInput"
    :disabled="disabled"
    :rules="rules"
    @keydown.native="keyEventTimeInput"
    @dblclick="dblclick"
    @input="changeTimeString"
    @blur="onBlur"
    :box="box"
    hide-details
    class="input-time-width"
    :placeholder="placeholder"
    :readonly="readOnly"
  >
  </v-text-field>
</template>

<script>
import VTextField from 'vuetify/lib/components/VTextField/VTextField';
import Arrowable from '../mixins/Arrowable.vue';
import { keyEvents } from '../keyEvents';
import { JSUtils as TimeUtils } from '../shared/js/TimeUtils';

export default {
  mixins: [Arrowable],
  components: { VTextField },
  props: {
    id: { type: String },
    refInput: {
      type: String,
      default: 'time-input-ref',
    },
    value: { required: true },
    label: { type: String },
    disabled: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    referenceDate: { required: false },
    rules: {
      type: Array,
    },
    box: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      timeMoment: null,
      timeString: null,
      referenceDateMoment: null,
      dayChangeThresholdMinutes: 60,
    };
  },
  methods: {
    keyEventTimeInput($event) {
      this.keyEvent($event);

      if ($event.code === keyEvents.UP || $event.code === keyEvents.DOWN) {
        if (!this.timeString || this.timeString.length >= 4) {
          this.timeString = this.addMinutesToStringTime(this.timeString, $event.code === keyEvents.UP ? 1 : -1);
          this.changeTimeString(this.timeString);
        }
      }
    },
    dblclick() {
      if (!this.timeString) {
        this.timeString = this.$moment().format('HH:mm');
        this.changeTimeString(this.timeString);
      }
    },
    addMinutesToStringTime(timeString, minutes) {
      let dayModifier = '';
      let timeStringAux;
      if (this.isTimeStringWithDateModifier(timeString)) {
        dayModifier = timeString[timeString.length - 1];
        timeStringAux = timeString.substring(0, timeString.length - 1);
      }
      timeStringAux = (timeStringAux || timeString || '00:00');
      const timeMomentAux = this.setHoursAndMinutesStringToTimeMoment(this.$moment(), timeStringAux);
      timeMomentAux.add(minutes, 'minutes');
      return `${timeMomentAux.format('HH:mm')}${dayModifier}`;
    },
    changeTimeString(value) {
      if (!value || value.trim().length === 0) {
        this.timeMoment = null;
      } else if (value && value.length === 5) {
        this.timeMoment = this.getTimeFromStringWithHoursAndMinute(value);
      } else if (value && value.length === 6) {
        if (!this.isTimeStringWithDateModifier(value)) {
          this.timeString = value.substring(0, value.length - 1);
          return;
        }
        const dayModifier = value[value.length - 1];
        const timeMomentAux = this.getTimeFromStringWithHoursAndMinute(value.substring(0, value.length - 1));
        this.applyDatyModifier(timeMomentAux, dayModifier);
        this.timeMoment = timeMomentAux;
      }
      this.checkDayChange();
      this.emitTimeMoment();
    },
    onBlur() {
      this.$emit('onBlur', this.timeMoment ? this.timeMoment.toISOString() : null);
    },
    isTimeStringWithDateModifier(timeString) {
      return timeString[timeString.length - 1] === '+' || timeString[timeString.length - 1] === '-';
    },
    getTimeFromStringWithHoursAndMinute(string) {
      return this.setHoursAndMinutesStringToTimeMoment(this.getTimeMomentToUse(), string);
    },
    setHoursAndMinutesStringToTimeMoment(timeMoment, string) {
      return timeMoment
        .hours(string.split(':')[0])
        .minutes(string.split(':')[1])
        .seconds(0)
        .milliseconds(0);
    },
    applyDatyModifier(timeMoment, dayModifier) {
      if (!timeMoment) {
        return;
      }
      if (dayModifier === '+') {
        timeMoment.add(1, 'days');
      } else if (dayModifier === '-') {
        timeMoment.subtract(1, 'days');
      }
    },
    emitTimeMoment() {
      this.$emit('input', this.timeMoment ? this.timeMoment.toISOString() : null);
    },
    checkDayChange() {
      if (this.referenceDateMoment && this.timeMoment) {
        if (this.referenceDateMoment.isValid() && this.timeMoment.isBefore(this.referenceDateMoment) && Math.abs(this.timeMoment.diff(this.referenceDateMoment, 'minutes', true)) > this.dayChangeThresholdMinutes) {
          console.log('Day should be shifted?'); // eslint-disable-line no-console
        }
      }
    },
    getTimeMomentToUse() {
      if (this.referenceDateMoment) {
        return this.$moment(this.referenceDateMoment);
      }
      return this.$moment();
    },
    getTimeAndRefDateDiff() {
      return TimeUtils.getDatesDiff(this.timeMoment, this.referenceDateMoment);
    },
    setDateModifier() {
      const getTimeAndRefDateDiffValue = this.getTimeAndRefDateDiff();
      if (this.timeString && getTimeAndRefDateDiffValue > 0) {
        this.timeString = `${this.timeString}+`;
      } else if (this.timeString && getTimeAndRefDateDiffValue < 0) {
        this.timeString = `${this.timeString}-`;
      }
    },
  },
  created() {
    this.timeMoment = this.value ? this.$moment(this.value) : null;
    this.timeString = this.timeMoment ? this.timeMoment.format('HH:mm') : null;
    this.referenceDateMoment = this.referenceDate ? this.$moment(this.referenceDate) : null;
    this.setDateModifier();
  },
  watch: {
    value: {
      handler(newValue) {
        this.timeMoment = newValue ? this.$moment(newValue) : null;
        this.timeString = this.timeMoment ? this.timeMoment.format('HH:mm') : null;
        this.setDateModifier();
      },

    },
    referenceDate: {
      handler(newReferenceDate) {
        this.referenceDateMoment = newReferenceDate ? this.$moment(newReferenceDate) : null;
        this.setDateModifier();
      },

    },
  },
};
</script>

<style>
  .input-time-width {
    min-width: 50px;
  }
</style>
