<template>
  <div>
    <v-combobox
      placeholder="..."
      :value="value"
      :label="label"
      :items="items"
      :filter="customFilter"
      :clearable="true"
      item-text="code"
      item-value="id"
      hide-no-data
      :rules="rules"
      :disabled="disabled"
      @change="emitIdAndCode"
      :ref="refComp"
    >
      <template
        slot="item"
        slot-scope="{ item, tile }"
      >
        <v-list-tile-content>
          <v-list-tile-title v-if="item && item.code" v-text="item.code"></v-list-tile-title>
        </v-list-tile-content>
      </template>
    </v-combobox>
  </div>
</template>

<script>
import { GET_AIRCRAFT_CONFIGS } from '../../store/modules/flightCatalogs';

export default {
  props: {
    value: { required: true },
    label: { type: String },
    charLenght: { type: Number },
    rules: { type: Array },
    disabled: { type: Boolean },
    refComp: {
      type: String,
      required: false,
      default: 'refComp',
    },
  },
  computed: {
    items: {
      get() {
        return this.$store.state.flightsCatalogs.aircraftConfigs;
      },
    },
  },
  methods: {
    getAircraftConfigs() {
      this.$store.dispatch(GET_AIRCRAFT_CONFIGS);
    },
    customFilter(item, queryText) {
      const textOne = item.code ? item.code.toLowerCase() : '';
      const searchText = queryText.toLowerCase();

      return textOne.indexOf(searchText) > -1;
    },
    emitIdAndCode(event) {
      if (typeof (event) !== 'undefined' && event != null) {
        const code = event.code ? event.code : event;
        const id = event.id ? event.id : null;
        this.emit(code, id);
      } else {
        this.emit(null, null);
      }
    },
    emit(code, id) {
      this.$emit('input', code);
      this.$emit('input-id', id);
    },
  },
  mounted() {
    this.getAircraftConfigs();
  },
};
</script>
