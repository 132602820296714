/* eslint no-param-reassign: "off" */

// CONSTANTS
export const WARNING_COLOR = '#b3a402';

// MUTATIONS
export const NOTIFICATION_SHOW = 'NOTIFICATION_SHOW';
export const NOTIFICATION_DISMISS = 'NOTIFICATION_DISMISS';

// initial state
const storeState = {
  message: null,
  color: null,
};

// getters
const storeGetters = {};

// actions
const storeActions = {};

// mutations
const storeMutations = {
  [NOTIFICATION_SHOW]: (state, notification) => {
    state.message = notification.message;
    state.color = notification.color;
  },
  [NOTIFICATION_DISMISS]: (state) => {
    state.message = null;
    state.color = null;
  },
};

export default {
  state: storeState,
  getters: storeGetters,
  actions: storeActions,
  mutations: storeMutations,
};
