<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <v-combobox
      name="destination"
      id="destination"
      :placeholder="this.$i18n.t('messages.avinetUserDestination')"
      :value="value"
      :items="items"
      :label="label"
      item-text="user"
      :rules="rules"
      multiple
      hide-selected
      chips
      deletable-chips
      @input="emitInput"
    >
      <template v-slot:data="item">
        <v-list-tile-content>
          <v-list-tile-title>
            {{ item.user }}
          </v-list-tile-title>
        </v-list-tile-content>
      </template>
    </v-combobox>
  </div>
</template>

<script>

import { GET_AVINET_USERS } from '../../store/modules/avinetUser';

export default {
  name: 'AvinetUserDestinationSelector',
  props: {
    value: { required: true },
    label: { type: String },
    rules: { type: Array },
    disabled: { type: Boolean },
    refComp: {
      type: String,
      required: false,
      default: 'refComp',
    },
    messages: {
      type: String,
    },
  },
  data: () => ({
    select: [],
  }),
  computed: {
    items() {
      return this.$store.state.avinetUser.avinetUsers;
    },
  },
  methods: {
    getAvinetUsers() {
      return this.$store.dispatch(GET_AVINET_USERS);
    },
    emitInput(event) {
      if (typeof (event) !== 'undefined') {
        this.$emit('input', event.map(e => (typeof e === 'object' ? e.user : e)));
      } else {
        this.$emit('input', null);
      }
    },
  },
  created() {
    this.getAvinetUsers();
  },
};
</script>
<style scoped>
.v-select__selections {
  min-height: 32px !important;
}
.v-chip {
  font-size: 16px !important;
  background-color: #eeeded !important;
}
.mdi-close-circle::before{
  color: red !important;
}
</style>
