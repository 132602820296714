<template>
  <div>
    <v-tooltip left>
      <v-btn
        slot="activator"
        small
        flat
        icon
        v-on:click="decreaseDay">
        <v-icon>keyboard_arrow_left</v-icon>
      </v-btn>
      <span>{{$t("commonFilter.prevDay")}}</span>
    </v-tooltip>
    <date-picker :value="date" :locale="$i18n.locale" @change="changeDate"></date-picker>
    <v-tooltip right>
      <v-btn
        slot="activator"
        small
        flat
        icon
        v-on:click="increaseDay">
        <v-icon>keyboard_arrow_right</v-icon>
      </v-btn>
      <span>{{$t("commonFilter.nextDay")}}</span>
    </v-tooltip>
    <v-tooltip bottom>
      <v-btn
        small
        flat
        icon
        v-on:click="today" slot="activator">
        <v-icon>today</v-icon>
      </v-btn>
      <span>{{$t("commonFilter.today")}}</span>
    </v-tooltip>
  </div>
</template>
<script>
import StoreSubscribable from '../../mixins/StoreSubscribable.vue';
import {
  OPERATION_FILTER_DECREASE_DAY,
  OPERATION_FILTER_INCREASE_DAY, OPERATION_FILTER_RESET, OPERATION_FILTER_RESET_TIMEZONE, OPERATION_FILTER_SET_DAY,
  OPERATION_FIND_FLIGHTS,
} from '../../../store/modules/operation';
import { TIME_TIMEZONE_CHANGED } from '../../../store/modules/time';
import DatePicker from '../../ui/DatePicker.vue';

export default {
  name: 'operation-flights-list-filter-date-selector',
  mixins: [StoreSubscribable],
  components: {
    DatePicker,
  },
  methods: {
    find() {
      this.$store.dispatch(OPERATION_FIND_FLIGHTS);
    },
    increaseDay() {
      this.$store.commit(OPERATION_FILTER_INCREASE_DAY);
      this.find();
    },
    decreaseDay() {
      this.$store.commit(OPERATION_FILTER_DECREASE_DAY);
      this.find();
    },
    today() {
      this.$store.commit(OPERATION_FILTER_RESET);
      this.find();
    },
    changeDate(date) {
      this.$store.commit(OPERATION_FILTER_SET_DAY, date);
      this.find();
    },
  },
  computed: {
    date() {
      return this.$moment(this.$store.state.operation.filter.dateFrom);
    },
  },
  mounted() {
    this.subscribeMutation(TIME_TIMEZONE_CHANGED, () => {
      this.$store.commit(OPERATION_FILTER_RESET_TIMEZONE);
      this.find();
    });
  },
};
</script>
<style lang="scss">
</style>
