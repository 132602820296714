<template>
  <v-btn flat icon v-if="manualUrl" :href="manualUrl" target="_blank">
    <v-icon>help_outline</v-icon>
  </v-btn>
</template>

<script>
import UsersManualUrl from '../mixins/UsersManualUrl.vue';

export default {
  name: 'HelpButton',
  mixins: [UsersManualUrl],
  props: {
    module: {
      type: String,
    },
    lang: {
      type: String,
    },
  },
};
</script>
