<template>
  <v-fab-transition v-if="hasPermissions(rolePermission)">

    <v-speed-dial
      v-if="selectionMode"
      fixed bottom right
      v-model="fabSelection"
      :key="activeSpeedDial.key"
      direction="left"
      :open-on-hover="activeSpeedDial.hover"
      :transition="activeSpeedDial.transition"
    >
      <v-btn
        v-model="fabSelection"
        @click="clickAdd"
        slot="activator"
        color=""
        dark
        fab
      >
        <v-icon>add</v-icon>
        <v-icon>close</v-icon>
      </v-btn>

     <v-tooltip top>
       <v-btn
         v-if="isDefaultService"
         slot="activator"
         fab
         dark
         small
         color=""
         @click="clickRequestDefaultRampService">
         <v-icon>rv_hookup</v-icon>
       </v-btn>
       <span>{{$t("rampServices.requestDefaultService")}}</span>
      </v-tooltip>

      <v-tooltip top>
        <v-btn
          v-if="isNewService"
          slot="activator"
          fab
          dark
          small
          color=""
          @click="clickAddService">
          <v-icon>home_repair_service</v-icon>
        </v-btn>
        <span>{{$t("rampServices.newServiceButton")}}</span>
      </v-tooltip>

    </v-speed-dial>

    <v-speed-dial
      v-else-if="!selectionMode"
      fixed bottom right
      v-model="fabSingle"
      :key="activeSpeedDial.key"
      direction="top"
      :open-on-hover="activeSpeedDial.hover"
      :transition="activeSpeedDial.transition"
    >
      <v-btn
        v-model="fabSingle"
        slot="activator"
        color="primary"
        dark
        fab
      >
        <v-icon>add</v-icon>
        <v-icon>close</v-icon>
      </v-btn>

      <v-tooltip left>
        <v-btn
          slot="activator"
          fab
          dark
          small
          color="secondary"
          @click="clickNewAircraftGround">
          <v-icon>handyman</v-icon>
        </v-btn>
        <span>{{$t("common.newAircraftGround")}}</span>
      </v-tooltip>
    </v-speed-dial>

  </v-fab-transition>
</template>
<script>
import RolePermissions from '../../../mixins/RolePermissions.vue';
import { FlightBound } from '../../../shared/model/flightBound';

export default {
  name: 'towing-adhoc-task-flights-list-options',
  mixins: [RolePermissions],
  data() {
    return {
      fabSingle: false,
      fabSelection: false,
      isDefaultService: false,
      isNewService: false,
    };
  },
  computed: {
    selectionMode() {
      return this.$store.getters.hasSelectedTowingAdhocTaskFlights;
    },
    activeAirportRampServicesByServiceAgentOfUse() {
      return this.$store.getters.getActiveAirportRampServicesByServiceAgentOfUser;
    },
    activeSpeedDial() {
      return {
        key: 'selection-speed-dial',
        direction: 'top',
        hover: false,
        transition: 'slide-x-reverse-transition',
      };
    },
    isAircraftGroundSelected() {
      return this.$store.getters.getSelectedFlightTowingAdhocTaskAircraftGround.length > 0;
    },
    isFlightsSelected() {
      return this.$store.getters.getSelectedFlightTowingAdhocTaskFlights.length > 0;
    },
    isInbound() {
      return this.$store.getters.getSelectedFlightTowingAdhocTaskFlights != null && this.$store.getters.getSelectedFlightTowingAdhocTaskFlights.length > 0 ? this.$store.getters.getSelectedFlightTowingAdhocTaskFlights[0].hasInbound() : false;
    },
    isOutbound() {
      return this.$store.getters.getSelectedFlightTowingAdhocTaskFlights != null && this.$store.getters.getSelectedFlightTowingAdhocTaskFlights.length > 0 ? this.$store.getters.getSelectedFlightTowingAdhocTaskFlights[0].hasOutbound() : false;
    },
    rolePermission() {
      return `${this.$store.state.towingAdhocTask.towingAdhocTaskUserRole}_WR`;
    },
    flightInformation() {
      let connectedFlight;

      if (this.isFlightsSelected) {
        [connectedFlight] = this.$store.getters.getSelectedFlightTowingAdhocTaskFlights;
      } else if (this.isAircraftGroundSelected) {
        [connectedFlight] = this.$store.getters.getSelectedFlightTowingAdhocTaskAircraftGround;
      }

      let f;
      if (connectedFlight.hasInbound()) {
        f = connectedFlight.inbound;
      } else if (connectedFlight.hasOutbound()) {
        f = connectedFlight.outbound;
      }

      return {
        flight: f,
        serviceId: connectedFlight.getChildPropertyValue(FlightBound.ANY, ['towingAdhocTaskServices', 'defaultRampServiceId']),
        serviceRequested: connectedFlight.getChildPropertyValue(FlightBound.ANY, ['towingAdhocTaskServices', 'defaultServiceRequested']),
      };
    },
  },
  methods: {
    clickRequestDefaultRampService() {
      this.$emit('click-request-default-ramp-service', this.flightInformation);
    },
    clickAddService() {
      this.$emit('click-add-service');
    },
    clickAdd() {
      if (!this.fabSelection) {
        this.isDefaultService = this.flightInformation.serviceId && !this.flightInformation.serviceRequested;
        this.isNewService = this.activeAirportRampServicesByServiceAgentOfUse.length > 0;
      }
    },
    clickNewAircraftGround() {
      this.$emit('click-new-aircraft-ground');
    },
  },
};
</script>
