import IntegrationResultFactory from './integrationResultFactory';

export default class IntegrationEvent {
  constructor(
    type,
    ts,
    origin,
    payload,
  ) {
    this.type = type;
    this.ts = ts;
    this.origin = origin;
    this.payload = payload;
  }
}

export const createIntegrationEventFromData = function createIntegrationEventFromData(data) {
  if (data) {
    return new IntegrationEvent(
      data.type,
      data.ts,
      data.origin,
      IntegrationResultFactory.createIntegrationResultFromData(data.payload),
    );
  }

  throw new Error('No data for IntegrationEvent');
};
