<template>
  <v-card >
    <v-layout row align-center>
      <v-flex xs6>
        <v-card-title primary-title>
          <div>
            <div class="headline">{{$t("administration.importFileRampServiceTitle")}}</div>
            <div class="grey--text text--darken-1">{{$t("administration.importFileRampServiceSubTitle")}}</div>
            <div class="grey--text text--darken-1">{{$t("administration.importFileRampServiceText")}}</div>
          </div>
        </v-card-title>
      </v-flex>

      <v-flex xs4>
        <v-dialog v-model="dialog" persistent max-width="1370px">
          <v-btn slot="activator" color="primary" :disabled="loading">{{$t("common.importFile")}}</v-btn>
            <v-card :dark="this.$vuetify.dark" :color="this.$vuetify.dark ? 'bggrey_dark' : 'bggrey'">
              <v-toolbar dark color="primary">
                <v-icon>attach_file</v-icon>
                <v-toolbar-title>{{$t("rampServiceImport.importRampServicesFromFile")}}</v-toolbar-title>
              </v-toolbar>

              <loading-component />

              <v-card-text>
                <ramp-service-import-stepper @import-stepper-cancel="reject" @import-stepper-confirm="confirm" />
              </v-card-text>
            </v-card>
          </v-dialog>
      </v-flex>

      <v-flex xs1>
        <v-btn color="primary" :disabled="loading" @click="downloadTemplate"  style="margin: 0 auto">{{$t("common.downloadTemplate")}}</v-btn>
      </v-flex>
    </v-layout>

    <v-divider light></v-divider>

    <AdminResult :is-error="isError" :is-success="isSuccess" :loading="loading" :time="time" :traces="traces"/>
  </v-card>
</template>
<script>
import { saveAs } from 'file-saver';
import RampServiceImportStepper from './RampServiceImportStepper.vue';
import RolePermissions from '../../mixins/RolePermissions.vue';
import LoadingComponent from '../../layout/LoadingComponent.vue';
import AdminResult from '../../admin/AdminResult.vue';
import FileSerializable from '../../mixins/FileSerializable.vue';

export default {
  name: 'import-file-ramp-service',
  mixins: [RolePermissions, FileSerializable],
  components: { RampServiceImportStepper, LoadingComponent, AdminResult },
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    loading() {
      return !!this.$store.state.rampServiceImport.rampServiceImportLoading;
    },
    time() {
      return this.$store.state.rampServiceImport.rampServiceImportLastRequestTime ? this.$moment(this.$store.state.rampServiceImport.rampServiceImportLastRequestTime).format('DD/MM/YYYY HH:mm') : null;
    },
    isSuccess() {
      return this.$store.getters.isRampServiceImportSuccess;
    },
    isError() {
      return this.$store.getters.isRampServiceImportError;
    },
    traces() {
      return this.$store.state.rampServiceImport.rampServiceImportResult;
    },
    rolePermission() {
      return `${this.$route.meta.role}_WR`;
    },
  },
  methods: {
    reject() {
      this.dialog = false;
    },
    confirm() {
      this.dialog = false;
    },
    downloadTemplate() {
      return this.$http.get('ramp/services/download/template')
        .then((response) => {
          const blob = new Blob([this.s2ab(atob(response.data.content))], { type: response.data.contentType });
          saveAs(blob, response.data.name);
        }).catch(() => {

        });
    },
  },
};
</script>
