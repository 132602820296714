import { JSUtils } from '../../shared/js/JSUtils';

export default class AircraftGround {
  constructor(
    id,
    aircraftGroundDate,
    aircraftId,
    aircraftRegisterNumber,
    aircraftAcars,
    aircraftCargo,
    aircraftTypeId,
    aircraftTypeIataCode,
    aircraftTypeIcaoCode,
    aircraftTypeComments,
    airlineId,
    airlineIataCode,
    airlineIcaoCode,
  ) {
    this.id = id;
    this.aircraftGroundDate = aircraftGroundDate;
    this.aircraftId = aircraftId;
    this.aircraftRegisterNumber = aircraftRegisterNumber;
    this.aircraftAcars = aircraftAcars;
    this.aircraftCargo = aircraftCargo;
    this.aircraftTypeId = aircraftTypeId;
    this.aircraftTypeIataCode = aircraftTypeIataCode;
    this.aircraftTypeIcaoCode = aircraftTypeIcaoCode;
    this.aircraftTypeComments = aircraftTypeComments;
    this.airlineId = airlineId;
    this.airlineIataCode = airlineIataCode;
    this.airlineIcaoCode = airlineIcaoCode;
  }

  static get createEmptyAircraftGround() {
    return new AircraftGround();
  }

  static createAircraftGroundFromData(o) {
    if (!o) {
      return this.createEmptyAircraftGround;
    }

    return JSUtils.cast(o, AircraftGround);
  }
}
