import axios from 'axios';

import store from './store/store';
import { AUTH_LOGOUT } from './store/modules/auth';
import { NOTIFICATION_SHOW } from './store/modules/notifications';
import AuthService from './components/shared/services/auth/auth-service';
import TenantService from './components/shared/services/tenant/tenant-service';
import i18n from './plugins/i18n';

export const BASE_URL = '/api/';

export const HTTP = axios.create({
  baseURL: BASE_URL,
});

export const AXIOS_DEFAULT = axios.create();

async function refreshToken() {
  const refreshData = {
    refreshToken: AuthService.getRefreshToken(),
    tenant: TenantService.getTenant() != null ? TenantService.getTenant().name : null,
  };
  return AXIOS_DEFAULT.post('/auth/jwt/refresh', refreshData)
    .then((resp) => {
      AuthService.updateAccessToken(resp.data.accessToken);
    })
    .catch(() => {
      AuthService.clearAuth();
    });
}

HTTP.interceptors.request.use(
  (config) => {
    const authHeader = AuthService.getAuthHeader();
    if (authHeader) {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = authHeader;
    }
    const tenant = TenantService.getTenantHeader();
    if (tenant) {
      // eslint-disable-next-line no-param-reassign
      config.headers['FIS-TENANT'] = tenant;
    }
    return config;
  },
  error => Promise.reject(error),
);

HTTP.interceptors.response.use(
  response => response,
  async (error) => {
    let errorToReturn = error;
    const originalRequest = error.config;
    if (error.response.status && typeof error.response.status === 'number') {
      if (error.response.status === 401 && !originalRequest.authRetry && AuthService.getRefreshToken() != null) {
        originalRequest.authRetry = true;
        try {
          await refreshToken();
          return HTTP(originalRequest);
        } catch (e) {
          errorToReturn = e;
        }
      }
      if (error.response.status === 401) {
        store.dispatch(AUTH_LOGOUT);
      }
      if (error.response.status === 456) {
        store.commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.exceedsTheResultsLimit'),
          color: 'error',
        });
      }
    }
    return Promise.reject(errorToReturn);
  },
);
