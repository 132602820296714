<script>
import Vue from 'vue';
import TemporalCheckable from '../../mixins/TemporalCheckable.vue';

export default Vue.extend({
  mixins: [TemporalCheckable],
  methods: {
    objectWithValueComparator(obj1, obj2) {
      if (!obj1 || !obj2) {
        return 0;
      }

      if (this.stringIsNullOrUndefinedOrEmpty(obj1.value) && !this.stringIsNullOrUndefinedOrEmpty(obj2.value)) {
        return 1;
      }

      if (this.stringIsNullOrUndefinedOrEmpty(obj2.value) && !this.stringIsNullOrUndefinedOrEmpty(obj1.value)) {
        return -1;
      }

      if (this.stringIsNullOrUndefinedOrEmpty(obj1.value) && this.stringIsNullOrUndefinedOrEmpty(obj2.value)) {
        return 0;
      }

      if (this.isTemporalObject(obj1.value) && this.isTemporalObject(obj2.value)) {
        return this.$moment(obj1.value).format('YYYYMMDDHHmmss').localeCompare(this.$moment(obj2.value).format('YYYYMMDDHHmmss'));
      }

      if (typeof obj1 === 'string' && typeof obj2 === 'string') {
        return obj1.value.toLowerCase().localeCompare(obj2.value.toLowerCase());
      }

      if (typeof obj1 === 'string') {
        return obj1.value.toLowerCase().localeCompare(obj2.value.toString().toLowerCase());
      }

      if (typeof obj2 === 'string') {
        return obj2.value.toLowerCase().localeCompare(obj1.value.toString().toLowerCase());
      }

      return obj1.value.toString().toLowerCase().localeCompare(obj2.value.toString().toLowerCase());
    },
    stringIsNullOrUndefinedOrEmpty(str) {
      return str === null || typeof (str) === 'undefined' || str === '';
    },
  },
});
</script>
