export const keyEvents = {
  TAB: 'Tab',
  RIGHT: 'ArrowRight',
  LETF: 'ArrowLeft',
  UP: 'ArrowUp',
  DOWN: 'ArrowDown',
  ENTER: 'Enter',
  SPACE: 'Space',
};

export default class KeyEventsUtils {
  static isArrowEvent($event) {
    return $event.code === keyEvents.DOWN
      || $event.code === keyEvents.UP
      || $event.code === keyEvents.RIGHT
      || $event.code === keyEvents.LETF;
  }
}
