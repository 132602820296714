/* eslint no-param-reassign: "off" */
/* eslint no-return-assign: "off" */
import { JSUtils } from '../../../shared/js/JSUtils';
import { AirlineFareUnitTypes } from '../../../shared/model/airlineFareUnitTypes';

export default class AirlineFare {
  constructor(
    id,
    name,
    description,
    cost,
    currency,
    airlineId,
    airlineCode,
    fields,
    dateFrom,
    dateTo,
    unitsPerCost,
    unitsType,
    tenants,
    active,
    tpvType,
  ) {
    this.id = id;
    this.name = name;
    this.description = description;
    this.airlineId = airlineId;
    this.airlineCode = airlineCode;
    this.fields = fields;
    this.dateFrom = dateFrom;
    this.dateTo = dateTo;
    this.unitsPerCost = unitsPerCost;
    this.unitsType = unitsType;
    this.tenants = tenants;
    this.active = active;
    this.tpvType = tpvType;
  }

  clone() {
    return AirlineFare.createAirlineFareFromData(JSON.parse(JSON.stringify(this)));
  }

  static get createEmptyAirlineFare() {
    return new AirlineFare(null, null, null, null, null, null, null, [], null, null, 1, AirlineFareUnitTypes.UNITS, [], null, 'INTERNAL');
  }

  static createAirlineFareFromData(o) {
    if (!o) {
      return this.createEmptyAirlineFare;
    }

    const airlineFare = JSUtils.cast(o, AirlineFare);
    airlineFare.forEach(a => a.fields = typeof a.fields === 'string' ? JSON.parse(a.fields) : a.fields);
    return airlineFare;
  }
}
