<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>

    <ag-grid-dialog-template-editor :is-dialog-open="isDialogOpen" @save="save" @cancel="cancel" :saveDisabled="invalidDataLoads || invalidDataHolding || !validData">

      <template v-slot:header>
        {{$t("operations.editLoadsModalTitle")}} {{flightNumber}}
      </template>

      <template v-slot:default>
        <FlightLoadsTable v-if="loads" :items="loads" :invalidData.sync="invalidDataLoads" :totalLoad.sync="totalLoadValue"/>
        <v-divider></v-divider>
        <br>
        <br>
        <FlightLoadsTableHolding v-if="loadsHolding" :items="loadsHolding" :invalidData.sync="invalidDataHolding" :totalLoadHolding.sync="totalLoadHoldingValue"/>
        <v-alert :value="errorMessagesValidate.length > 0" color="info" style="font-size: 16px">
          <ul>
            <li v-for="(item, index) in errorMessagesValidate" :key="index">
              {{item}}
            </li>
          </ul>
        </v-alert>
      </template>
    </ag-grid-dialog-template-editor>
  </div>
</template>

<script>
import Vue from 'vue';
import AgGridDialogParentCellEditor from '../../mixins/AgGridDialogParentCellEditor.vue';
import AgGridDialogTemplateEditor from '../../templates/AgGridDialogTemplateEditor.vue';
import FlightLoads from '../../model/flightLoads';
import { JSUtils } from '../../../../shared/js/JSUtils';
import FlightLoadsTable from './FlightLoadsTable.vue';
import FlightLoadsTableHolding from './FlightLoadsTableHolding.vue';
import FlightLoadsHolding from '../../model/flightLoadsHolding';

export default Vue.extend({
  mixins: [AgGridDialogParentCellEditor],
  components: {
    AgGridDialogTemplateEditor,
    FlightLoadsTable,
    FlightLoadsTableHolding,
  },
  data() {
    return {
      loads: null,
      loadsHolding: null,
      invalidDataLoads: true,
      invalidDataHolding: true,
      validData: true,
      totalLoadValue: 0,
      totalLoadHoldingValue: 0,
      errorMessagesValidate: [],
    };
  },
  computed: {
    flightNumber() {
      return this.connectedFlights.getChildPropertyValue(this.flightBound, ['common', 'flightNo']);
    },
  },
  methods: {
    getValue() {
      if (this.isSaving) {
        this.requestInit();
        const data = {
          loads: this.loads.filter(r => !r.isTotalRow),
          totalLoad: this.loads.filter(r => !!r.isTotalRow)[0],
          loadsHolding: this.loadsHolding.filter(r => !r.isTotalRow),
          totalLoadHolding: this.loadsHolding.filter(r => !!r.isTotalRow)[0],
        };
        this.$http.put(`flights/${this.flightId}/loads-holding`, data)
          .then(resp => this.dialogRequestSuccessCallback(this, resp))
          .catch(error => this.requestErrorCallback(this, error));
      }

      return this.params.value;
    },

    checkValues() {
      this.validData = true;
      this.errorMessagesValidate = [];

      if (this.totalLoadValue !== this.totalLoadHoldingValue) {
        this.validData = false;
        this.errorMessagesValidate.push('The sum of the values of bag, cargo and mail must be equal to the sum of the weights');
      }
    },

  },

  watch: {
    totalLoadValue: {
      handler() {
        this.checkValues();
      },
    },
    totalLoadHoldingValue: {
      handler() {
        this.checkValues();
      },
    },
  },

  created() {
    this.$http.get(`flights/${this.flightId}/loads-holding`)
      .then((resp) => {
        this.loadsHolding = JSUtils.cast(resp.data.loadsHolding, FlightLoadsHolding);
        const rowData = JSUtils.cast(resp.data.loads, FlightLoads);
        for (let i = 0; i < rowData.length; i += 1) {
          rowData[i].airportCode = this.connectedFlights.getChildPropertyValue(this.flightBound, ['common', `airport${rowData[i].airportOrder}IataCode`]);
        }
        const totalLoad = JSUtils.cast(resp.data.totalLoad, FlightLoads);
        totalLoad.isTotalRow = true;
        rowData.push(totalLoad);
        this.loads = rowData;

        const totalLoadHolding = JSUtils.cast(resp.data.totalLoadHolding, FlightLoadsHolding);
        totalLoadHolding.isTotalRow = true;
        this.loadsHolding.push(totalLoadHolding);
      });
  },
});
</script>
<style lang="scss">
.loads-table {
  padding: 0;
}

.radio-unit-label {
  padding-top: 1.5vh;
  font-weight: bold;
  margin-right: 1vw;
}
</style>
