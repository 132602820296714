<template>
  <v-card-text class="default-boarding-time-edit-card-content">
    <v-container fluid style="margin-bottom: 0; padding-bottom: 0">
      <v-layout align-center justify-space-between row style="margin-bottom: 0; padding-bottom: 0">
        <v-flex xs6 style="margin-right: 2ch">
          <airline-select :label="this.$i18n.t('common.airline')" :multiple=false
            :disabled="!hasPermissions(rolePermission)" v-model="item.airlineId" />
        </v-flex>
        <v-flex xs3 style="margin-right: 2ch; margin-bottom: 3ch"> {{ this.$i18n.t('configuration.startBoarding') }}
          <v-text-field
            :label="this.minutesBeforeLabel"
            type="number"
            v-model="item.startTime"
            :readonly="!hasPermissions(rolePermission)"
            onkeypress="return event.charCode >= 48"
            min="1" />
        </v-flex>
        <v-flex xs3 style="margin-right: 2ch; margin-bottom: 3ch;" > {{ this.$i18n.t('configuration.endBoarding') }}
          <v-text-field
            :label="this.minutesBeforeLabel"
            type="number"
            v-model="item.endTime"
            :readonly="!hasPermissions(rolePermission)"
            onkeypress="return event.charCode >= 48"
            min="1"/>
        </v-flex>
      </v-layout>
      <v-layout style="margin-bottom: 0; padding-bottom: 0">
        <v-flex xs12>
          <v-radio-group class="radio-group" row v-model="localTimeType" @change="setBasedOnScheduledTime">
            <v-radio :label="this.$i18n.t('configuration.scheduledTime')" color="primary" :value="timeTypes.ST"></v-radio>
            <v-radio :label="this.$i18n.t('configuration.estimatedTime')" color="primary" :value="timeTypes.ET"></v-radio>
          </v-radio-group>
        </v-flex>
      </v-layout>
    </v-container>
  </v-card-text>
</template>
<script>
import RolePermissions from '../../mixins/RolePermissions.vue';
import AirlineSelect from '../../airline/AirlineSelect.vue';
import { AirportDefaultBoardingTimeTypes } from './AirportDefaultBoardingTimeTypes';

export default {
  name: 'AirportDefaultBoardingTimeEditCardContent',
  mixins: [RolePermissions],
  props: {
    item: { type: Object, required: true },
  },
  components: {
    AirlineSelect,
  },
  computed: {
    rolePermission() {
      return `${this.$route.meta.role}_WR`;
    },
  },
  methods: {
    setBasedOnScheduledTime() {
      this.item.basedOnScheduledTime = this.localTimeType === this.timeTypes.ST;
      this.item.basedOnEstimatedTime = this.localTimeType === this.timeTypes.ET;
      this.minutesBeforeLabel = this.getMinutesBeforeLabel();
    },
    getMinutesBeforeLabel() {
      let basedTime = '';
      if (this.item.basedOnScheduledTime && this.item.basedOnScheduledTime === true) {
        basedTime = this.$i18n.t('configuration.scheduledTime');
      } else if (this.item.basedOnEstimatedTime && this.item.basedOnEstimatedTime === true) {
        basedTime = this.$i18n.t('configuration.estimatedTime');
      }
      return `${this.$i18n.t('configuration.minutesBefore')} ${basedTime}`;
    },
  },
  data() {
    return {
      timeTypes: AirportDefaultBoardingTimeTypes,
      localTimeType: AirportDefaultBoardingTimeTypes.ST,
      minutesBeforeLabel: '',
    };
  },
  created() {
    if (!this.item.basedOnEstimatedTime && !this.item.basedOnScheduledTime) {
      this.item.basedOnEstimatedTime = false;
      this.item.basedOnScheduledTime = true;
    }
    this.localTimeType = this.item.basedOnEstimatedTime ? this.timeTypes.ET : this.timeTypes.ST;
    this.minutesBeforeLabel = this.getMinutesBeforeLabel();
  },
};
</script>
<style>
.default-boarding-time-edit-card-content .v-text-field input[type="number"] {
  text-align: right;
}
/* size of up and down arrows in number input */
.default-boarding-time-edit-card-content .v-text-field input[type="number"]::-webkit-inner-spin-button,
.default-boarding-time-edit-card-content .v-text-field input[type="number"]::-webkit-outer-spin-button {
  padding: 1em 0.25em;
  margin-left: 0.5em;
}

</style>
