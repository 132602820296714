<template>
  <v-layout row justify-center>
    <v-dialog v-model="dialog" persistent max-width="1370px"
    :content-class="isContentVisible ? 'modal-common' : 'modal-common hide-title'">
      <v-card :dark="this.$vuetify.dark" :color="this.$vuetify.dark ? 'bggrey_dark' : 'bggrey'">
        <v-toolbar dark color="primary">
          <v-icon>link_off</v-icon>
          <v-toolbar-title>{{$t("scheduling.unLinkFlightsTitle")}}</v-toolbar-title>
          <v-spacer></v-spacer>
        <!-- Botón para contraer/expandir el contenido -->
        <v-btn icon @click="toggleContentVisibility">
          <v-icon>{{ isContentVisible ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
        </v-btn>
      </v-toolbar>
      <div v-show="isContentVisible">

        <loading-component />

        <v-card-text>
          {{$t("scheduling.unLinkFlightsAlert")}}
          <connected-flights-base-grid :items="summaryItems" @selected-changed="selectedChanged" />
        </v-card-text>
        <v-card-actions>
          <v-btn flat color="primary" @click="reject" :disabled="loading">{{$t("common.cancel")}}</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="confirm" :disabled="loading || summaryItems.length == 0">{{$t("common.confirm")}}</v-btn>
        </v-card-actions>
      </div>
      </v-card>
    </v-dialog>
  </v-layout>
</template>
<script>

import SchedulingImportCsvStepper from '../import/SchedulingImportStepper.vue';
import LoadingComponent from '../../layout/LoadingComponent.vue';
import ConnectedFlightsBaseGrid from '../../flights/ConnectedFlightsBaseGrid.vue';
import ConnectedFlightsIds from '../../shared/model/connectedFlightsIds';

export default {
  name: 'scheduling-unlink-modal',
  components: {
    SchedulingImportCsvStepper,
    ConnectedFlightsBaseGrid,
    LoadingComponent,
  },
  props: ['dialog', 'items'],
  data() {
    return {
      selectedItems: [],
      isContentVisible: true,
    };
  },
  computed: {
    loading() {
      return this.$store.state.loading;
    },
    summaryItems() {
      return this.items.filter(connectedFlights => connectedFlights.hasInbound() && connectedFlights.hasOutbound());
    },
  },
  methods: {
    selectedChanged(selectedItems) {
      this.selectedItems = selectedItems;
    },
    reject() {
      this.$emit('unlink-reject');
    },
    confirm() {
      const flightsIds = ConnectedFlightsIds.mapFromConnectedFlightsList(this.selectedItems);
      this.$emit('unlink-confirm', flightsIds);
    },
    toggleContentVisibility() {
      this.isContentVisible = !this.isContentVisible;
    },
  },
};
</script>
<style>
</style>
