<template>
  <div>
    <currency-select
      :ref="'refComp'"
      v-on:keydown.native="keyEvent"
      v-char-length.clearable="4"
      v-model="updatedCurrency"
    />
  </div>
</template>

<script>
import Vue from 'vue';
import AgGridParentFlightDeliveryBaggageCellEditor from '../mixins/AgGridParentFlightDeliveryBaggageCellEditor.vue';
import CurrencySelect from '../../../contingencyManagement/common/CurrencySelect.vue';

export default Vue.extend({
  mixins: [AgGridParentFlightDeliveryBaggageCellEditor],
  components: {
    CurrencySelect,
  },
  data() {
    return {
      originalCurrency: null,
      updatedCurrency: null,
    };
  },
  methods: {
    getValue() {
      this.unmarkCellsSelectedAsEdit();
      if (this.originalCurrency !== this.updatedCurrency) {
        const that = this;
        const deliveryPropertyName = this.getFlightDeliveryBaggagePropertyName();
        this.saveForValueOrAllSelectedDeliveries((s) => {
          s.setChildPropertyValue(deliveryPropertyName, that.updatedCurrency);
        }, deliveryPropertyName);
      }
      return this.updatedCurrency;
    },
  },
  created() {
    this.originalCurrency = this.params.value ? this.params.value : '';
    this.updatedCurrency = this.originalCurrency;

    this.markCellsSelectedAsEdit();
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$refs.refComp) {
        this.$refs.refComp.$refs.refComp.focus();
      }
    });
  },
});
</script>

<style>

</style>
