<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>

    <ag-grid-dialog-template-editor :is-dialog-open="isDialogOpen" @save="save" @cancel="cancel">

      <template v-slot:header>
        Edit Ramp Service {{serviceName}} from {{flightNumber}}
      </template>

      <template v-slot:default>
        <v-container grid-list-md text-xs-center fluid>
          <v-layout row wrap>
            <v-flex xs12>
              <v-card>
                <v-card-text>
                  <v-container fluid>
                    <v-layout row wrap>
                      <v-flex xs6>
                        <v-text-field v-model="service.units" :label="$i18n.t('rampServices.units')" class="ramp-service-modal" :readonly="!hasPermissions(rolePermission)"></v-text-field>
                      </v-flex>
                      <v-flex xs6>
                        <time-input v-model="service.requestTs" :label="$i18n.t('rampServices.requestTime')" :referenceDate="referenceDate" class="ramp-service-modal" :disabled="!hasPermissions(rolePermission)"></time-input>
                      </v-flex>
                      <v-flex xs6>
                        <time-input v-model="service.requestedTs" :label="$i18n.t('rampServices.requestedStartTime')" :referenceDate="referenceDate" class="ramp-service-modal" :disabled="!hasPermissions(rolePermission)"></time-input>
                      </v-flex>
                      <v-flex xs6>
                        <time-input v-model="service.requestedEndTs" :label="$i18n.t('rampServices.requestedEndTime')" :referenceDate="referenceDate" class="ramp-service-modal" :disabled="!hasPermissions(rolePermission)"></time-input>
                      </v-flex>
                      <v-flex xs6>
                        <time-input v-model="service.startTs" :label="$i18n.t('rampServices.startTime')" :referenceDate="referenceDate" class="ramp-service-modal" :disabled="!hasPermissions(rolePermission)"></time-input>
                      </v-flex>
                      <v-flex xs6>
                        <time-input v-model="service.finishTs" :label="$i18n.t('rampServices.endTime')" :referenceDate="referenceDate" class="ramp-service-modal" :disabled="!hasPermissions(rolePermission)"></time-input>
                      </v-flex>
                      <v-flex xs6>
                        <airport-requester-select v-model="service.requesterId" :label="$i18n.t('common.requester')" class="ramp-service-modal" :disabled="!hasPermissions(rolePermission)"></airport-requester-select>
                      </v-flex>
                      <v-flex xs12>
                        <v-text-field v-model="service.remarks" :label="$i18n.t('operations.remarksExtended')" :readonly="!hasPermissions(rolePermission)"></v-text-field>
                      </v-flex>
                    </v-layout>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-layout>
        </v-container>
      </template>

    </ag-grid-dialog-template-editor>

  </div>
</template>

<script>
import Vue from 'vue';
import AgGridDialogParentCellEditor from '../../mixins/AgGridDialogParentCellEditor.vue';
import AgGridDialogTemplateEditor from '../../templates/AgGridDialogTemplateEditor.vue';
import TimeInput from '../../../../ui/TimeInput.vue';
import AirportRequesterSelect from '../../../../configuration/airportRequester/AirportRequesterSelect.vue';

export default Vue.extend({
  mixins: [AgGridDialogParentCellEditor],
  components: {
    AgGridDialogTemplateEditor,
    TimeInput,
    AirportRequesterSelect,
  },
  data() {
    return {
      service: null,
    };
  },
  computed: {
    referenceDate() {
      return this.connectedFlights.getChildPropertyValue(this.flightBound, ['common', 'scheduledDate']);
    },
    serviceName() {
      return this.params.value.serviceName;
    },
    rolePermission() {
      return `${this.$route.meta.role}_WR`;
    },
  },
  methods: {
    getValue() {
      if (this.isSaving) {
        this.requestInit();
        this.$http.put(`flights/${this.flightId}/ramp/services/${this.service.id}`, this.service)
          .then(resp => this.dialogRequestSuccessCallback(this, resp))
          .catch(error => this.requestErrorCallback(this, error));
      }

      return this.params.value;
    },
  },
  created() {
    this.service = {
      id: this.params.value.serviceId,
      requestTs: this.params.value.serviceRequestTs,
      requestedTs: this.params.value.serviceRequestedTs,
      requestedEndTs: this.params.value.serviceRequestedEndTs,
      startTs: this.params.value.serviceStartTs,
      finishTs: this.params.value.serviceFinishTs,
      serviceId: this.params.value.serviceServiceId,
      serviceName: this.params.value.serviceName,
      units: this.params.value.serviceUnits,
      remarks: this.params.value.serviceRemarks,
      requesterId: this.params.value.serviceRequesterId,
    };
  },
});
</script>
<style lang="scss">
.ramp-service-modal {
  max-width: 15em !important;
}
</style>
