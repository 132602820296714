<template>
  <div :style="`width: 100%; height: calc(100vh - ${this.sizeFilter});`">
    <ag-grid-vue
      class="ag-theme-material connected-flights-table operational-table"
      :columnDefs="columnDefs"
      :defaultColDef="defaultColDef"
      :rowData="items"
      :rowSelection="'simple'"
      :suppressRowClickSelection="true"
      :rowMultiSelectWithClick="false"
      @grid-ready="onGridReady"
      @first-data-rendered="firstDataRendered"
      @selection-changed="onSelectionChanged"
      :frameworkComponents="frameworkComponents"
      :gridOptions="gridOptions"
      :multiSortKey="multiSortKey"
      :isRowSelectable="isRowSelectable"
      :overlayNoRowsTemplate="customNoRowsOverlay"
      :overlayLoadingTemplate="customLoadingOverlay"
      v-bind:style="`width: 100%; height: calc(100vh - ${this.sizeFilter});`">
    </ag-grid-vue>
  </div>
</template>

<script>
import { AgGridVue } from 'ag-grid-vue';
import AgGridStatusOperationCellRenderer from '../../../ag-grid/cell-renderers/AgGridStatusOperationCellRenderer.vue';
import AgGridMultilineStringsCellRenderer from '../../../ag-grid/cell-renderers/AgGridMultilineStringsCellRenderer.vue';
import AgGridConnectedFlightsDialogStatusCellEditor
from '../../../ag-grid/cell-editors/connectedFlights/dialog/AgGridConnectedFlightsDialogStatusCellEditor.vue';
import AgGridConnectedFlightsDialogRampStatusCellEditor
from '../../../ag-grid/cell-editors/connectedFlights/dialog/AgGridConnectedFlightsDialogRampStatusCellEditor.vue';
import AgGridValueAndSupCellRenderer from '../../../ag-grid/cell-renderers/AgGridValueAndSupCellRenderer.vue';
import AgGridValueAndIconAndSupCellRenderer from '../../../ag-grid/cell-renderers/AgGridValueAndIconAndSupCellRenderer.vue';
import AgGridValueWithAsteriskAndSupCellRenderer from '../../../ag-grid/cell-renderers/AgGridValueWithAsteriskAndSupCellRenderer.vue';
import AgGridTimeCellRenderer from '../../../ag-grid/cell-renderers/AgGridTimeCellRenderer.vue';
import AgGridConnectedFlights from '../../../ag-grid/mixins/AgGridConnectedFlights.vue';
import FlightsDifferences from '../../../mixins/FlightsDifferences.vue';

import { FlightBound } from '../../../shared/model/flightBound';
import { cellClassAgGridRules } from '../../../ag-grid/mixins/cellClassAgGridRules';
import { WEBSOCKET_CONNECTION_FLIGHT_TOWING_ADHOC_TASK } from '../../../../store/modules/websocket';
import AgGridCustomService from '../../../shared/services/agGridCustomService';
import AgGridValueWithAsteriskCellRenderer
from '../../../ag-grid/cell-renderers/AgGridValueWithAsteriskCellRenderer.vue';
import AgGridFlightStatusCellRenderer from '../../../ag-grid/cell-renderers/AgGridFlightStatusCellRenderer.vue';
import AgGridIconCellRenderer from '../../../ag-grid/cell-renderers/AgGridIconCellRenderer.vue';
import AgGridRowActionIconCellRenderer from '../../../ag-grid/cell-renderers/AgGridRowActionIconCellRenderer.vue';
import agGridTimeFormatter from '../../../ag-grid/formatters/agGridTimeFormatter';
import { FisModule } from '../../../ag-grid/cell-editors/model/fisModule';
import agGridTextCustomFilterComparator from '../../../ag-grid/filters/agGridTextCustomFilterComparator';
import agGridTextCustomFilterFormatter from '../../../ag-grid/filters/agGridTextCustomFilterFormatter';
import AgGridNumberCellEditor from '../../../ag-grid/cell-editors/AgGridNumberCellEditor.vue';
import RolePermissions from '../../../mixins/RolePermissions.vue';
import {
  FIT_COLUMNS_TO_VIEW,
  FLIGHT_TOWING_ADHOC_TASK_CHANGE_FLIGHT_SELECTION,
  FLIGHT_TOWING_ADHOC_TASK_ON_EXTERNAL_CHANGE, FLIGHT_TOWING_ADHOC_TASK_SET_CHANGE,
  FLIGHT_TOWING_ADHOC_TASK_SET_GRID_API,
} from '../../../../store/modules/towingAdhocTask/flightTowingAdhocTask';
import AgGridNoRowsOverlay from '../../../ag-grid/mixins/AgGridNoRowsOverlay.vue';

export default {
  name: 'towing-adhoc-task-flight-list-table',
  mixins: [AgGridConnectedFlights, FlightsDifferences, RolePermissions, AgGridNoRowsOverlay],
  components: {
    AgGridVue,
  },
  props: {
    items: { type: Array, required: true },
    isFilterDisplayed: { type: Boolean, required: false, default: false },
  },
  mounted() {
    this.$store.dispatch(WEBSOCKET_CONNECTION_FLIGHT_TOWING_ADHOC_TASK, this.$store.state.tenant.currentTenant.name.toLowerCase());
  },
  computed: {
    sizeFilter() {
      return this.isFilterDisplayed ? '250px' : '180px';
    },
    fitColumnsOnResize() {
      return this.$store.state.flightTowingAdhocTask.fitColumnsOnResize;
    },
    selectedRowsFlights() {
      return this.$store.getters.getSelectedFlights;
    },
    selectedRowsAircraftGround() {
      return this.$store.getters.getSelectedAircraftGround;
    },
    dateFilter() {
      return this.$store.state.flightTowingAdhocTask.filter.dateFrom;
    },
  },
  data() {
    const that = this;
    const rolePermission = this.getRoles();
    return {
      dialog: false,
      gridOptions: {
        onFilterChanged: this.uncheckAllOnFilter,
        getRowNodeId: this.getRowNodeId,
        getRowClass: this.getRowClass,
        floatingFilter: true,
        groupHeaderHeight: 35,
        headerHeight: 35,
        floatingFiltersHeight: 20,
        rowHeight: 30,
        context: {
          module: FisModule.TOWING_ADHOC_TASK_FLIGHT,
        },
      },
      isRowSelectable(rowNode) {
        if (that.selectedRowsFlights.length > 0 && that.selectedRowsAircraftGround.length === 0) {
          return !that.isAircraftGround(rowNode.data);
        }
        if (that.selectedRowsFlights.length === 0 && that.selectedRowsAircraftGround.length > 0) {
          return that.isAircraftGround(rowNode.data);
        }
        return true;
      },
      multiSortKey: 'ctrl',
      frameworkComponents: {
        AgGridConnectedFlightsDialogRampStatusCellEditor,
        AgGridConnectedFlightsDialogStatusCellEditor,
        AgGridNumberCellEditor,
      },
      defaultColDef: {
        resizable: true,
        sortable: true,
        minWidth: 30,
        suppressMenu: true,
        filter: 'agTextColumnFilter',
        filterParams: {
          textCustomComparator: agGridTextCustomFilterComparator,
          textFormatter: agGridTextCustomFilterFormatter,
        },
        colCustomConfig: {
          flightBound: FlightBound.ANY,
        },
        cellClassRules: cellClassAgGridRules,
        editable: false,
      },
      columnDefs: [
        {
          headerTooltip: 'Check/uncheck all',
          hide: !this.hasPermissions(rolePermission),
          checkboxSelection: true,
          width: 21,
          minWidth: 20,
          headerCheckboxSelection: false,
          headerCheckboxSelectionFilteredOnly: false,
          suppressMenu: true,
          sortable: false,
          suppressMovable: true,
          suppressSizeToFit: true,
          filter: false,
          resizable: false,
          colCustomConfig: {
            exportCsvDisabled: true,
          },
          editable: this.hasPermissions(rolePermission),
        },
        {
          headerName: 'SortDate',
          field: 'sortingTime',
          hide: true,
          sort: 'asc',
        },
        {
          colId: 'anybound.common.aircraftRegisterNumber',
          headerValueGetter() {
            return that.$i18n.t('operations.aircraftRegistration');
          },
          headerTooltip: (() => this.$i18n.t('operations.aircraftRegistration'))(),
          width: 54,
          minWidth: 52,
          colCustomConfig: {
            flightBound: FlightBound.ANY,
            fieldsRelated: ['inbound.common.aircraftRegisterNumber', 'outbound.common.aircraftRegisterNumber',
              'inbound.common.aircraftAcars', 'outbound.common.aircraftAcars'],
            isAircraftGround(connectedFlights) {
              return that.isAircraftGround(connectedFlights);
            },
          },
          cellClass: 'aircraft-cell',
          comparator: this.objectWithValueComparator,
          valueGetter(params) {
            return {
              value: params.data.getChildPropertyValue(FlightBound.ANY, ['common', 'aircraftRegisterNumber']),
              sup: params.data.getChildPropertyValue(FlightBound.ANY, ['common', 'aircraftAcars']) ? 'A' : null,
            };
          },
          cellRendererFramework: AgGridValueAndSupCellRenderer,
        },
        {
          colId: 'anybound.common.aircraftTypeCode',
          headerValueGetter() {
            return that.$i18n.t('operations.aircraftType');
          },
          headerTooltip: (() => this.$i18n.t('operations.aircraftType'))(),
          width: 31,
          minWidth: 30,
          cellClass: 'aircraft-type-cell',
          comparator: this.objectWithValueComparator,
          colCustomConfig: {
            flightBound: FlightBound.ANY,
            fieldsRelated: ['inbound.common.aircraftTypeIataCode', 'inbound.common.aircraftTypeIcaoCode',
              'outbound.common.aircraftTypeIataCode', 'outbound.common.aircraftTypeIcaoCode',
              'inbound.common.aircraftCargo', 'outbound.common.aircraftCargo'],
          },
          valueGetter(params) {
            return {
              value: params.data.getChildPropertyValue(FlightBound.ANY, ['common', 'aircraftTypeCode']),
              sup: params.data.getChildPropertyValue(FlightBound.ANY, ['common', 'aircraftCargo']) ? 'P' : null,
              tooltip: params.data.getChildPropertyValue(FlightBound.ANY, ['common', 'aircraftTypeComments']),
            };
          },
          cellRendererFramework: AgGridValueAndSupCellRenderer,
        },
        {
          colId: 'anybound.common.flightNumber',
          headerName: (() => this.getTextResizable(this.$i18n.t('operations.flightNumberShort'), this.$i18n.t('operations.flightNumber')))(),
          headerTooltip: (() => this.$i18n.t('operations.flightNumber'))(),
          width: 68,
          minWidth: 68,
          cellClass: 'flight-number-cell',
          comparator: this.objectWithValueComparator,
          colCustomConfig: {
            flightBound: FlightBound.ANY,
            fieldsRelated: ['inbound.common.flightNumber', 'inbound.common.flagAirlineOperation', 'outbound.common.flightNumber', 'outbound.common.flagAirlineOperation'],
            isAircraftGround(connectedFlights) {
              return that.isAircraftGround(connectedFlights);
            },
            editedOnlyServiceType(connectedFlights) {
              return !that.isAircraftGround(connectedFlights);
            },
          },
          flight: FlightBound.ANY,
          valueGetter(params) {
            if (that.isAircraftGround(params)) {
              return {
                value: params.data.getChildPropertyValue(FlightBound.ANY, ['common', 'airlineCode']),
              };
            }
            return {
              value: params.data.getChildPropertyValue(FlightBound.ANY, ['common', 'flightNumber']),
              asterisk: params.data.getChildPropertyValue(FlightBound.ANY, ['common', 'flagAirlineOperation']),
              tooltip: params.data.getChildPropertyValue(FlightBound.ANY, ['common', 'airlineOperationMessage']),
              sup: params.data.getChildPropertyValue(FlightBound.ANY, ['common', 'serviceTypeCode']),
            };
          },
          cellRendererFramework: AgGridValueWithAsteriskAndSupCellRenderer,
        },
        {
          colId: 'anybound.common.airport1Code',
          headerValueGetter() {
            return that.$i18n.t('operations.airportOfDepartureOrDestination');
          },
          headerTooltip: (() => this.$i18n.t('operations.airportOfDepartureOrDestination'))(),
          width: 31,
          minWidth: 31,
          cellClass: 'airport-from-cell',
          comparator: this.objectWithValueComparator,
          colCustomConfig: {
            flightBound: FlightBound.ANY,
            fieldsRelated: ['inbound.common.airport1Code', 'inbound.common.hasSeveralAirports', 'outbound.common.airport1Code', 'outbound.common.hasSeveralAirports'],
          },
          flight: FlightBound.ANY,
          valueGetter(params) {
            return {
              value: params.data.getChildPropertyValue(FlightBound.ANY, ['common', 'airport1Code']),
              asterisk: params.data.getChildPropertyValue(FlightBound.ANY, ['common', 'hasSeveralAirports']),
              tooltip: params.data.getChildPropertyValue(FlightBound.ANY, ['common', 'hasSeveralAirports']) ? params.data.getChildPropertyValue(FlightBound.ANY, ['common', 'airportsIata']) : undefined,
            };
          },
          cellRendererFramework: AgGridValueWithAsteriskCellRenderer,
        },
        {
          colId: 'anybound.common.pkn',
          headerValueGetter() {
            return that.$i18n.t('operations.parking');
          },
          headerTooltip: (() => this.$i18n.t('operations.parkingExtended'))(),
          width: 38,
          minWidth: 38,
          cellClass: 'aircraft-config-cell',
          comparator: this.objectWithValueComparator,
          colCustomConfig: {
            flightBound: FlightBound.ANY,
            fieldsRelated: ['inbound.common.parkingCode', 'inbound.common.parkingRemote', 'inbound.common.parkingPushback', 'inbound.common.parkingInop400Hz',
              'outbound.common.parkingCode', 'outbound.common.parkingRemote', 'outbound.common.parkingPushback', 'outbound.common.parkingInop400Hz'],
          },
          flight: FlightBound.ANY,
          valueGetter(params) {
            let superindice = params.data.getChildPropertyValue(FlightBound.ANY, ['common', 'parkingRemote']) ? 'R' : '';
            superindice += params.data.getChildPropertyValue(FlightBound.ANY, ['common', 'parkingPushback']) ? 'P' : '';
            return {
              value: params.data.getChildPropertyValue(FlightBound.ANY, ['common', 'parkingCode']),
              sup: superindice,
              icon: params.data.getChildPropertyValue(FlightBound.ANY, ['common', 'parkingInop400Hz']) ? 'flash_off' : null,
              colorIcon: params.data.getChildPropertyValue(FlightBound.ANY, ['common', 'parkingInop400Hz']) ? 'red' : null,
            };
          },
          cellRendererFramework: AgGridValueAndIconAndSupCellRenderer,
        },
        {
          colId: 'anybound.common.gat',
          headerValueGetter() {
            return that.$i18n.t('operations.gateExtended');
          },
          headerTooltip: (() => this.$i18n.t('operations.gateExtended'))(),
          width: 25,
          minWidth: 25,
          cellClass: 'aircraft-config-cell',
          comparator: this.objectWithValueComparator,
          colCustomConfig: {
            flightBound: FlightBound.ANY,
            fieldsRelated: ['inbound.common.gateCode', 'inbound.common.gateRemote', 'outbound.common.gateCode', 'outbound.common.gateRemote'],
          },
          flight: FlightBound.ANY,
          valueGetter(params) {
            return {
              value: params.data.getChildPropertyValue(FlightBound.ANY, ['common', 'gateCode']),
              sup: params.data.getChildPropertyValue(FlightBound.ANY, ['common', 'gateRemote']) ? 'R' : null,
            };
          },
          cellRendererFramework: AgGridValueAndSupCellRenderer,
        },
        {
          colId: 'anybound.common.bound',
          headerValueGetter() {
            return that.$i18n.t('flightInformation.inboundOrOutbound');
          },
          headerTooltip: (() => this.$i18n.t('flightInformation.inboundOrOutbound'))(),
          width: 25,
          minWidth: 25,
          cellClass: 'aircraft-config-cell',
          comparator: this.objectWithValueComparator,
          colCustomConfig: {
            flightBound: FlightBound.ANY,
            fieldsRelated: ['inbound.common.gateCode', 'inbound.common.gateRemote', 'outbound.common.gateCode', 'outbound.common.gateRemote'],
          },
          flight: FlightBound.ANY,
          valueGetter(params) {
            if (that.isInbound(params)) {
              return 'INBOUND';
            }
            if (that.isOutbound(params)) {
              return 'OUTBOUND';
            }
            return 'ANY';
          },
          cellRendererFramework: AgGridIconCellRenderer,
          cellRendererParams: {
            // eslint-disable-next-line no-nested-ternary
            icon: params => (params.value ? params.value === 'INBOUND' ? 'flight_land' : params.value === 'OUTBOUND' ? 'flight_takeoff' : null : null),
            // eslint-disable-next-line no-nested-ternary
            tooltip: params => (params.value ? params.value === 'INBOUND' ? this.$i18n.t('common.arrivalFlight') : params.value === 'OUTBOUND' ? this.$i18n.t('common.departureFlight') : null : null),
          },
        },
        {
          colId: 'anybound.common.scheduledTime',
          headerValueGetter() {
            return that.$i18n.t('flightInformation.scheduledTime');
          },
          headerTooltip: (() => this.$i18n.t('dispatcher.scheduledTime'))(),
          width: 43,
          minWidth: 43,
          cellClass: 'airport-sta-cell',
          comparator: this.objectWithValueComparator,
          colCustomConfig: {
            flightBound: FlightBound.ANY,
            fieldsRelated: ['inbound.common.scheduledTime', 'outbound.common.scheduledTime'],
            oldValueFormatter: agGridTimeFormatter,
          },
          flight: FlightBound.ANY,
          valueGetter(params) {
            return that.agGridTimeGetter(params.data.getChildPropertyValue(FlightBound.ANY, ['common', 'scheduledTime']), that.dateFilter);
          },
          cellRendererFramework: AgGridTimeCellRenderer,
        },
        {
          colId: 'anybound.times.bestTime',
          headerValueGetter() {
            return that.$i18n.t('operations.bestTime');
          },
          headerTooltip: (() => this.$i18n.t('operations.bestKnownTime'))(),
          width: 43,
          minWidth: 43,
          cellClass: 'airport-best-cell',
          comparator: this.objectWithValueComparator,
          colCustomConfig: {
            flightBound: FlightBound.ANY,
            fieldsRelated: ['inbound.times.bestTime', 'outbound.times.bestTime'],
            oldValueFormatter: agGridTimeFormatter,
          },
          flight: FlightBound.ANY,
          valueGetter(params) {
            return that.bestTimeGetter(params.data.getChildPropertyValue(FlightBound.ANY, ['times', 'bestTime']), params.data.getChildPropertyValue(FlightBound.ANY, ['common', 'scheduledTime']), that.dateFilter);
          },
          cellRendererFramework: AgGridTimeCellRenderer,
        },
        {
          colId: 'anybound.times.status',
          headerValueGetter() {
            return that.getTextResizable(that.$i18n.t('operations.statusShort'), that.$i18n.t('operations.status'));
          },
          headerTooltip: (() => this.$i18n.t('operations.status'))(),
          width: 51,
          minWidth: 45,
          editable: this.hasPermissions(rolePermission) && this.isNotAircraftGround,
          cellEditor: 'AgGridConnectedFlightsDialogStatusCellEditor',
          comparator: this.objectWithValueComparator,
          cellClass: 'status-cell',
          colCustomConfig: {
            flightBound(connectedFlights) {
              return that.getBound(connectedFlights);
            },
            fieldsRelated: ['inbound.common.status', 'outbound.common.status'],
            referenceDate() {
              return that.dateFilter;
            },
          },
          flight: FlightBound.ANY,
          valueGetter(params) {
            if (that.isAircraftGround(params)) {
              return 'GROUND';
            }
            return params.data.getChildPropertyValue(FlightBound.ANY, ['common', 'status']);
          },
          cellRendererFramework: AgGridFlightStatusCellRenderer,
        },
        {
          colId: 'anybound.common.belt',
          headerValueGetter() {
            return that.$i18n.t('ramp.belt');
          },
          headerTooltip: (() => this.$i18n.t('ramp.belt'))(),
          width: 50,
          cellClass: 'aircraft-config-cell',
          comparator: this.objectWithValueComparator,
          colCustomConfig: {
            flightBound: FlightBound.ANY,
            fieldsRelated: ['inbound.common.beltId', 'inbound.common.beltCode', 'outbound.common.beltId', 'outbound.common.beltCode'],
          },
          valueGetter(params) {
            return {
              value: params.data.getChildPropertyValue(FlightBound.ANY, ['common', 'beltCode']),
            };
          },
          cellRendererFramework: AgGridValueAndSupCellRenderer,
        },
        {
          colId: 'services.rampServices',
          headerValueGetter() {
            return that.$i18n.t('operations.specialServices');
          },
          headerTooltip: (() => this.$i18n.t('rampServices.specialServices'))(),
          width: 40,
          minWidth: 40,
          cellClass: 'ag-grid-popover-cell',
          comparator: this.objectWithValueComparator,
          colCustomConfig: {
            flightBound: FlightBound.ANY,
            fieldsRelated: ['inbound.services.rampServices', 'outbound.services.rampServices'],
          },
          valueGetter(params) {
            return params.data.getChildPropertyValue(FlightBound.ANY, ['services', 'rampServices']);
          },
          cellRendererFramework: AgGridMultilineStringsCellRenderer,
        },
        {
          colId: 'services.rampServicesStatus',
          headerName: (() => this.getTextResizable(this.$i18n.t('operations.statusShort'), this.$i18n.t('operations.status')))(),
          headerTooltip: (() => this.$i18n.t('rampServices.rampServicesStatus'))(),
          width: 18,
          minWidth: 18,
          editable: true,
          cellClass: 'ag-grid-popover-cell',
          comparator: this.objectWithValueComparator,
          cellEditor: 'AgGridConnectedFlightsDialogRampStatusCellEditor',
          colCustomConfig: {
            flightBound: FlightBound.ANY,
            fieldsRelated: ['inbound.services.rampServicesStatus', 'outbound.services.rampServicesStatus'],
            isAircraftGround(connectedFlights) {
              return that.isAircraftGround(connectedFlights);
            },
          },
          flight: FlightBound.ANY,
          valueGetter(params) {
            return params.data.getChildPropertyValue(FlightBound.ANY, ['services', 'rampServicesStatus']);
          },
          cellRendererFramework: AgGridStatusOperationCellRenderer,
        },
        {
          colId: 'services.defaultService',
          headerValueGetter() {
            return that.$i18n.t('common.actions');
          },
          hide: !this.hasPermissions(rolePermission),
          width: 120,
          cellClass: 'text-center',
          suppressMenu: true,
          sortable: false,
          suppressMovable: true,
          suppressSizeToFit: true,
          filter: false,
          resizable: false,
          suppressNavigable: true,
          colCustomConfig: {
            exportCsvDisabled: true,
          },
          valueGetter(params) {
            return {
              value: params.data.getChildPropertyValue(FlightBound.ANY, ['towingAdhocTaskServices', 'defaultServiceRequested']),
              flightId: params.data.getChildPropertyValue(FlightBound.ANY, ['common', 'id']),
              serviceId: params.data.getChildPropertyValue(FlightBound.ANY, ['towingAdhocTaskServices', 'defaultRampServiceId']),
            };
          },
          cellRendererFramework: AgGridRowActionIconCellRenderer,
          cellRendererParams: {
            action: this.requestService,
            icon: 'rv_hookup',
            tooltip: this.$i18n.t('rampServices.requestService'),
          },
        },
      ],
    };
  },
  methods: {
    onGetChanges(changes) {
      const rowNode = this.gridApi.getRowNode(changes.payload.id);

      if (!rowNode) {
        return;
      }
      const oldConnectedFlight = rowNode.data.clone();
      const newConnectedFlight = changes.payload;
      this.assignInboundOrOutboundIfDontExist(newConnectedFlight, oldConnectedFlight);
      const differencies = this.getDifferenciesAndCreateOldValuesProperties(oldConnectedFlight, newConnectedFlight);

      this.setOldValuesDifferencesFromOldConnectedFlight(rowNode.data, newConnectedFlight);

      if (differencies && differencies.length > 0) {
        rowNode.setData(newConnectedFlight);
        if (this.$store.state.flightTowingAdhocTask.flashOnChanges) {
          this.renderNeededDiffs(differencies, rowNode); // TODO only shows flash
        }
        differencies.forEach(dif => this.$store.commit(FLIGHT_TOWING_ADHOC_TASK_SET_CHANGE, { diff: dif, connectedFlights: newConnectedFlight, user: changes.origin }));
      }
    },
    onGridReady(params) {
      this.setAgGridApi(params);
      this.showLoading();
      this.sizeColumnsToFit();

      this.$store.commit(FLIGHT_TOWING_ADHOC_TASK_SET_GRID_API, params); // TODO eliminar api del store

      this.refreshCellsOnMutation();
      this.refreshCellsOnChangeLanguage();
      const that = this;
      this.subscribeMutation(FLIGHT_TOWING_ADHOC_TASK_ON_EXTERNAL_CHANGE, (mutationPayload) => {
        that.onGetChanges(mutationPayload);
      });
    },
    firstDataRendered() {
      this.$nextTick(() => {
        this.resized();
      });
    },
    resized() {
      if (this.fitColumnsOnResize) {
        this.sizeColumnsToFit();
      }
    },
    sizeColumnsToFit() {
      if (this.gridApi) {
        this.$store.dispatch(FIT_COLUMNS_TO_VIEW);
      }
    },
    onSelectionChanged(event) {
      const selectedNodes = event.api.getSelectedNodes();
      const selectedData = selectedNodes.map(node => node.data);
      this.$store.commit(FLIGHT_TOWING_ADHOC_TASK_CHANGE_FLIGHT_SELECTION, selectedData);
    },
    isInbound(params) {
      return params.data.hasInbound();
    },
    isOutbound(params) {
      return params.data.hasOutbound();
    },
    getBound(params) {
      if (params.hasInbound()) {
        return FlightBound.INBOUND;
      }
      if (params.hasOutbound()) {
        return FlightBound.OUTBOUND;
      }
      return FlightBound.ANY;
    },
    bestTimeGetter(bestTime, scheduledTime, referenceDateTime, status = null) {
      const result = this.agGridTimeGetter(bestTime, referenceDateTime);
      const scheduledTimeAux = scheduledTime ? this.$moment(scheduledTime) : null;
      if (status === 'OFFBLOCK' && result.value && result.value.isValid()) {
        if (scheduledTimeAux && scheduledTimeAux.isValid() && result.value.startOf('minute').isAfter(scheduledTimeAux.startOf('minute'))) {
          result.color = 'red';
        }
      }
      return result;
    },
    renderNeededDiffs(differences, rowNode) {
      const { gridApi } = this.$store.state.flightTowingAdhocTask;

      const agGridCustomService = new AgGridCustomService(this.columnDefs); // TODO one time
      const colIds = agGridCustomService.getColIdByMatchAnyfieldsRelated(this.joinAllPropertyPath(differences));

      if (colIds && colIds.length > 0) {
        colIds.forEach((colId) => {
          if (gridApi.getColumnDef(colId)) {
            gridApi.refreshCells({
              rowNodes: [rowNode],
              columns: [colId],
              force: true,
            });

            gridApi.flashCells({
              rowNodes: [rowNode],
              columns: [colId],
              force: true,
            });
          }
        });
      }
    },
    isAircraftGround(params) {
      if (params.data) {
        return params.data.getChildPropertyValue(FlightBound.INBOUND, ['common', 'id']) != null && params.data.getChildPropertyValue(FlightBound.INBOUND, ['common', 'tripNumber']) == null;
      }
      return params.getChildPropertyValue(FlightBound.INBOUND, ['common', 'id']) != null && params.getChildPropertyValue(FlightBound.INBOUND, ['common', 'tripNumber']) == null;
    },
    isNotAircraftGround(params) {
      return !this.isAircraftGround(params);
    },
    isInboundAndNotAircraftGround(params) {
      return this.isInbound(params) && !this.isAircraftGround(params);
    },
    isOutboundAndNotAircraftGround(params) {
      return this.isOutbound(params) && !this.isAircraftGround(params);
    },
    requestService(item) {
      let flightComposition;
      if (item.hasInbound()) {
        flightComposition = item.inbound;
      } else if (item.hasOutbound()) {
        flightComposition = item.outbound;
      }
      const flightInformation = {
        flight: flightComposition,
        serviceId: item.getChildPropertyValue(FlightBound.ANY, ['towingAdhocTaskServices', 'defaultRampServiceId']),
      };
      this.$emit('click-request-ramp-service', flightInformation);
    },
  },
};

</script>
