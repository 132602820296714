<template>
  <div>
    <v-form ref="form" v-model="valid">
      <v-flex v-flex-none class="inline-flight-number">
        <airline-select :ref="'refComp'" v-on:keydown.native="keyEventAirline" v-char-length.clearable="4" v-model="airlineIdUpdated" :disabled="editedOnlyServiceType"></airline-select>
      </v-flex>
      <v-flex v-flex-none class="inline-flight-number inline-flight-number-input" v-if="!this.isAircraftGround">
        <v-text-field :ref="'refInput'" v-on:keydown.native="keyEventFlightNumber" v-char-length="5" v-model="tripNumberUpdated" :rules="tripNumberRules" :disabled="editedOnlyServiceType"></v-text-field>
      </v-flex>
      <v-flex v-flex-none class="inline-flight-number" v-if="!this.isAircraftGround">
        <service-type-select :ref="'refComp'" v-on:keydown.native="keyEventAirline" v-char-length.clearable="4" v-model="serviceTypeIdUpdated" :validate-connected-flight="true" :is-connected-flight="isConnectedFlights"></service-type-select>
      </v-flex>
    </v-form>
  </div>
</template>

<script>
import Vue from 'vue';
import AirlineSelect from '../../../airline/AirlineSelect.vue';
import ServiceTypeSelect from '../../../ui/serviceTypeSelector/ServiceTypeSelect.vue';
import { keyEvents } from '../../../keyEvents';
import AgGridParentConnectedFlightsCellEditor from '../mixins/AgGridParentConnectedFlightsCellEditor.vue';

export default Vue.extend({
  mixins: [AgGridParentConnectedFlightsCellEditor],
  components: {
    AirlineSelect,
    ServiceTypeSelect,
  },
  data() {
    return {
      airlineIdOriginal: null,
      airlineIdUpdated: null,
      tripNumberOriginal: null,
      tripNumberUpdated: null,
      serviceTypeIdOriginal: null,
      serviceTypeIdUpdated: null,
      flight: {},
      isAircraftGround: false,
      valid: true,
      editedOnlyServiceType: false,
      isConnectedFlights: null,
      tripNumberRules: [
        v => !!v || this.disabled || this.$i18n.t('common.fieldRequired'),
        (flightNumber) => {
          const pattern = /^[A-Za-z1-9][A-Za-z0-9]{0,4}$/g;
          if (flightNumber !== 'undefined' && flightNumber != null) {
            return pattern.test(flightNumber) || this.$i18n.t('configuration.invalidRegisterNumber');
          }
          return true;
        },
      ],
    };
  },
  methods: {
    getValue() {
      if (this.$refs.form.validate()) {
        this.unmarkCellsSelectedAsEdit();

        if (this.airlineIdOriginal !== this.airlineIdUpdated || this.tripNumberOriginal !== this.tripNumberUpdated) {
          const airline = this.$store.getters.getAirlineById(this.airlineIdUpdated);
          const code = typeof (airline) !== 'undefined' ? airline.code : undefined;

          const valueCell = [
            { id: 'common.airlineId', value: this.airlineIdUpdated, initialValue: this.airlineIdOriginal },
            { id: 'common.tripNumber', value: this.tripNumberUpdated, initialValue: this.tripNumberOriginal },
          ];

          const that = this;
          this.saveFlightChangesFromCellValue((s) => {
            s.setChildPropertyValueByBound(that.params.column.colDef.colCustomConfig.flightBound, 'common.tripNumber', that.tripNumberUpdated);
            s.setChildPropertyValueByBound(that.params.column.colDef.colCustomConfig.flightBound, 'common.airlineId', that.airlineIdUpdated);
            s.setChildPropertyValueByBound(that.params.column.colDef.colCustomConfig.flightBound, 'common.airlineCode', code);
          }, 'Flight Number', valueCell);
        }

        if (this.serviceTypeIdOriginal !== this.serviceTypeIdUpdated) {
          const serviceType = this.$store.getters.getServiceTypeById(this.serviceTypeIdUpdated);
          const serviceTypeCode = typeof (serviceType) !== 'undefined' ? serviceType.code : undefined;

          const valueCell = [
            { id: 'common.serviceTypeId', value: this.serviceTypeIdUpdated, initialValue: this.serviceTypeIdOriginal },
          ];

          const that = this;
          this.saveFlightChangesFromCellValue((s) => {
            s.setChildPropertyValueByBound(that.params.column.colDef.colCustomConfig.flightBound, 'common.serviceTypeId', that.serviceTypeIdUpdated);
            s.setChildPropertyValueByBound(that.params.column.colDef.colCustomConfig.flightBound, 'common.serviceTypeCode', serviceTypeCode);
          }, 'Flight Number', valueCell);
        }
      }
      return this.params.node.data;
    },
    keyEventAirline($event) {
      this.keyEvent($event);

      if ($event.code === keyEvents.ENTER) {
        $event.stopPropagation();
      }

      if ($event.code === keyEvents.TAB) {
        $event.stopPropagation();
        this.$refs.refInput.$refs['input-slot'].focus();
      }
    },
    keyEventFlightNumber($event) {
      this.keyEvent($event);

      if ($event.code === keyEvents.TAB && $event.shiftKey) {
        $event.stopPropagation();
      }
    },
  },
  created() {
    const schedulingFlight = this.params.node.data;
    this.isConnectedFlights = schedulingFlight.inbound != null && schedulingFlight.inbound.common != null && schedulingFlight.inbound.common.id != null && schedulingFlight.outbound != null && schedulingFlight.outbound.common != null && schedulingFlight.outbound.common.id != null;
    this.flight = schedulingFlight.getBound(this.params.column.colDef.colCustomConfig.flightBound);
    this.isAircraftGround = this.params.column.colDef.colCustomConfig.isAircraftGround ? this.params.column.colDef.colCustomConfig.isAircraftGround(schedulingFlight) : false;
    this.airlineIdOriginal = this.flight.airlineId;
    this.airlineIdUpdated = this.airlineIdOriginal;
    this.tripNumberOriginal = this.flight.tripNumber;
    this.tripNumberUpdated = this.tripNumberOriginal;
    this.serviceTypeIdOriginal = this.flight.serviceTypeId;
    this.serviceTypeIdUpdated = this.serviceTypeIdOriginal;
    this.editedOnlyServiceType = this.params.column.colDef.colCustomConfig.editedOnlyServiceType ? this.params.column.colDef.colCustomConfig.editedOnlyServiceType(schedulingFlight) : false;

    this.markCellsSelectedAsEdit();
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$refs.refComp) {
        this.$refs.refComp.$refs.refComp.focus();
      }
    });
  },
});
</script>

<style>
  .flight-number-cell.ag-cell-inline-editing {
    min-width: 160px;
  }
  .inline-flight-number {
    display: inline-block;
  }
  .inline-flight-number-input {
    margin-left: 5px;
  }
</style>
