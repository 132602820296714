export default class TallyingCashFilter {
  constructor(
    dateFrom,
    dateTo,
    airlineId,
    airlines,
    unitOfTime,
    user,
    tenantId,
  ) {
    this.dateFrom = dateFrom;
    this.dateTo = dateTo;
    this.airlineId = airlineId;
    this.airlines = airlines;
    this.unitOfTime = unitOfTime;
    this.user = user;
    this.tenantId = tenantId;
  }

  static get createEmptyFilter() {
    return new TallyingCashFilter();
  }
}
