<template>
  <v-card-text>
    <v-container fluid style="margin-bottom: 0; padding-bottom: 0">
      <v-layout style="margin-bottom: 0; padding-bottom: 0">
        <v-flex style="margin-right: 2ch; width: 3vw">
          <v-text-field :label="this.$i18n.t('configuration.iataCode')" :rules=[rules.checkIATACode] v-model="item.iataCode" :readonly="!hasPermissions(rolePermission)"/>
        </v-flex>

        <v-flex style="margin-right: 2ch; width: 3vw">
          <v-text-field :label="this.$i18n.t('configuration.icaoCode')" :rules=[rules.checkICAOCode] v-model="item.icaoCode" :readonly="!hasPermissions(rolePermission)"/>
        </v-flex>

        <v-flex style="margin-right: 2ch; width: 25vw">
          <v-text-field :label="this.$i18n.t('configuration.description')" v-model="item.comments" :readonly="!hasPermissions(rolePermission)"/>
        </v-flex>

        <v-flex style="margin-right: 2ch; width: 5vw">
          <v-text-field :label="this.$i18n.t('configuration.mtow')" type="number" min="0" v-model="item.mtow" :rules="[rules.requiredMtow]" :readonly="!hasPermissions(rolePermission)"/>
        </v-flex>

        <v-flex style="width: 5vw">
          <v-checkbox :label="this.$i18n.t('configuration.manufacturer')" color="primary" v-model="item.manufacturer" :disabled="!hasPermissions(rolePermission)"></v-checkbox>
        </v-flex>
        </v-layout>
    </v-container>
  </v-card-text>
</template>
<script>
import RolePermissions from '../../mixins/RolePermissions.vue';

export default {
  name: 'AircraftTypesEditCardContent',
  mixins: [RolePermissions],
  props: {
    item: { type: Object, required: true },
  },
  computed: {
    rolePermission() {
      return `${this.$route.meta.role}_WR`;
    },
  },
  data() {
    return {
      rules: {
        required: value => !!value || 'Required.',
        requiredMtow: value => (!!value || value === '0' || value === 0) || 'Required.',
        checkIATACode: str => (str != null && str.length > 3 ? this.$i18n.t('configuration.maxIataChars') : true),
        checkICAOCode: str => (str != null && str.length > 4 ? this.$i18n.t('configuration.maxIcaoChars') : true),
      },
    };
  },
};
</script>
