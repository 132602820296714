<template>
  <v-card>
    <v-navigation-drawer style="width:auto;">
      <v-list>
        <v-list-tile :class="{ 'active-menu': selectedDirectory === item.el}"
          v-for="item in items"
          :key="item.title"
          @click="selectDirectory(item)"
        >
          <v-list-tile-action>
            <v-icon large>{{ item.icon }}</v-icon>
          </v-list-tile-action>

          <v-list-tile-content>
            <v-list-tile-title>{{ item.title }}</v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
      </v-list>
    </v-navigation-drawer>
  </v-card>
</template>

<script>
export default {
  name: 'DirectoryMenu',
  props: {
    selectedDirectory: String,
  },
  data: () => ({
    items: [
      { title: 'Acciona Contacts Directory', icon: 'business', el: null },
      { title: 'Airport Contacts Directory', icon: 'local_airport', el: 'aiportContacts' },
      { title: 'Airport Gates Directory', icon: 'web', el: 'aiportGates' },
      { title: 'Check-in Counter Directory', icon: 'question_answer', el: 'checkinCounter' },
    ],
  }),
  methods: {
    selectDirectory(item) {
      this.$emit('directory-menu-select', item.el);
    },
  },
};
</script>

<style>
.active-menu > a{
  background: rgba(0,0,0,0.04);
}
</style>
