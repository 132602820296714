<template>
  <div>
    <v-tooltip left>
      <v-btn
        slot="activator"
        small
        flat
        icon
        v-on:click="decreaseDay">
        <v-icon>keyboard_arrow_left</v-icon>
      </v-btn>
      <span>{{$t("commonFilter.prevDay")}}</span>
    </v-tooltip>
    <span>{{ formattedDate }}</span>
    <v-tooltip right>
      <v-btn
        slot="activator"
        small
        flat
        icon
        v-on:click="increaseDay">
        <v-icon>keyboard_arrow_right</v-icon>
      </v-btn>
      <span>{{$t("commonFilter.nextDay")}}</span>
    </v-tooltip>
    <v-tooltip bottom>
      <v-btn
        small
        flat
        icon
        v-on:click="today" slot="activator">
        <v-icon>today</v-icon>
      </v-btn>
      <span>{{$t("commonFilter.today")}}</span>
    </v-tooltip>
  </div>
</template>
<script>
import StoreSubscribable from '../../mixins/StoreSubscribable.vue';
import {
  DELAYS_FILTER_DECREASE_DAY,
  DELAYS_FILTER_INCREASE_DAY,
  DELAYS_FILTER_RESET,
  DELAYS_FIND_FLIGHTS,
} from '../../../store/modules/delays';

export default {
  name: 'delays-flights-list-filter-date-selector',
  mixins: [StoreSubscribable],
  methods: {
    find() {
      this.$store.dispatch(DELAYS_FIND_FLIGHTS);
    },
    increaseDay() {
      this.$store.commit(DELAYS_FILTER_INCREASE_DAY);
      this.find();
    },
    decreaseDay() {
      this.$store.commit(DELAYS_FILTER_DECREASE_DAY);
      this.find();
    },
    today() {
      this.$store.commit(DELAYS_FILTER_RESET);
      this.find();
    },
  },
  computed: {
    formattedDate() {
      return this.$moment(this.$store.state.delays.filter.dateFrom).locale(this.$i18n.locale).format('DD/MM/YY (ddd)');
    },
  },
};
</script>
