<template>
        <div>

          <v-container fluid class="containerTitleSummary">
            <v-layout>
              <v-flex xs12 class="containerInfoSummary infoInsert">
                <span v-if="summaryFlights.flightsImportSummary.length > 0">{{summaryFlights.flightsImportSummary.length}} {{$t("schedulingImport.importFlightsSummaryInfo")}}</span>
                <span v-else>{{$t("schedulingImport.importFlightsEmptySummaryInfo")}}</span>
              </v-flex>
              <v-flex xs12 class="containerInfoSummary infoDelete">
                <span v-if="summaryFlights.flightsDeleteSummary.length > 0">{{summaryFlights.flightsDeleteSummary.length}} {{$t("schedulingImport.deleteFlightsSummaryInfo")}}</span>
                <span v-else>{{$t("schedulingImport.deleteFlightsEmptySummaryInfo")}}</span>
              </v-flex>
              <v-flex xs12 class="containerInfoSummary infoUpdate">
                <span v-if="summaryFlights.flightsUpdateSummary.length > 0">{{summaryFlights.flightsUpdateSummary.length}} {{$t("schedulingImport.updateFlightsSummaryInfo")}}</span>
                <span v-else>{{$t("schedulingImport.updateFlightsEmptySummaryInfo")}}</span>
              </v-flex>
            </v-layout>
          </v-container>
          <ag-grid-vue
            class="ag-theme-material"
            :defaultColDef="defaultColDef"
            :columnDefs="columnDefs"
            :rowData="items"
            :onGridReady="onGridReady"
            :getRowStyle="getRowStyle"
            :suppressHorizontalScroll="true"
            style="width: 100%; height: calc(100vh - 500px);">
          </ag-grid-vue>
          <p> {{$t("schedulingImport.calculatedAttributesTooltip")}} </p>
        </div>

</template>
<script>
import
{ AgGridVue } from 'ag-grid-vue';
import AgGridIconStatusCellRenderer from '../../ag-grid/cell-renderers/AgGridIconStatusCellRenderer.vue';
import TimezoneConfigurable from '../../mixins/TimezoneConfigurable.vue';
import AgGridValueAndSupCellRenderer from '../../ag-grid/cell-renderers/AgGridValueAndSupCellRenderer.vue';
import AgGridArrayValueAndSupCellRenderer from '../../ag-grid/cell-renderers/AgGridArrayValueAndSupCellRenderer.vue';
import { SchedulingImportResultOperation } from '../model/schedulingImportResult';
import SchedulingImportOptions from '../model/schedulingImportOptions';
import { FlightBound } from '../../shared/model/flightBound';
import AgGridValueComparable from '../../ag-grid/mixins/AgGridValueComparable.vue';
import AgGridTimeCellRenderer from '../../ag-grid/cell-renderers/AgGridTimeCellRenderer.vue';
import CatalogElementExistingCheckeable from '../../mixins/CatalogElementExistingCheckeable.vue';
import { cellClassAgGridRules } from '../../ag-grid/mixins/cellClassAgGridRules';
import agGridTextCustomFilterComparator from '../../ag-grid/filters/agGridTextCustomFilterComparator';
import agGridTextCustomFilterFormatter from '../../ag-grid/filters/agGridTextCustomFilterFormatter';

export default {
  name: 'scheduling-import-step-changes-summary',
  mixins: [TimezoneConfigurable, AgGridValueComparable, CatalogElementExistingCheckeable],
  components: {
    AgGridVue,
    AgGridIconStatusCellRenderer,
    AgGridTimeCellRenderer,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    importOptions: {
      type: SchedulingImportOptions,
    },
    summaryFlights: {
      type: Object,
    },
  },
  data() {
    const that = this;
    return {
      defaultColDef: {
        cellClassRules: cellClassAgGridRules,
        resizable: true,
        sortable: true,
        filter: true,
        filterParams: {
          textCustomComparator: agGridTextCustomFilterComparator,
          textFormatter: agGridTextCustomFilterFormatter,
        },
      },
      columnDefs: [
        {
          width: 30,
          headerName: '',
          field: 'importOperation',
          cellStyle: this.getRowStyle,
          cellRendererFramework: AgGridIconStatusCellRenderer,
          suppressMenu: true,
          sortable: false,
          suppressMovable: true,
          suppressSizeToFit: true,
          filter: false,
          resizable: false,
          colCustomConfig: {
            headerGetterExportCsv() {
              return 'Action';
            },
            valueGetterExportCsv(params) {
              return that.getActionLabel(params.data);
            },
          },
          valueGetter(params) {
            return params.data.importOperationResult != null ? params.data.importOperationResult : params.data.importOperation;
          },
        },
        {
          headerName: 'SortDate',
          field: 'sortingTime',
          hide: true,
          sort: 'asc',
        },
        {
          headerValueGetter() {
            return that.$i18n.t('common.aircraftRegistration');
          },
          width: 60,
          comparator: this.objectWithValueComparator,
          cellRendererFramework: AgGridValueAndSupCellRenderer,
          cellStyle: this.getRowStyle,
          valueGetter(params) {
            return {
              value: params.data.getChildPropertyValue(FlightBound.ANY, ['aircraftRegisterNumber']),
              sup: that.getLabelIfCatalogElementIsNew(params, FlightBound.ANY, 'aircraftId', 'aircraftRegisterNumber'),
              tooltip: that.getOldValueIfPropertyIsChanged(params, 'any.aircraftRegisterNumber'),
              color: that.getColorIfPropertyIsChanged(params, 'any.aircraftRegisterNumber'),
            };
          },
        },
        {
          headerValueGetter() {
            return that.$i18n.t('common.type');
          },
          width: 50,
          comparator: this.objectWithValueComparator,
          cellRendererFramework: AgGridValueAndSupCellRenderer,
          cellStyle: this.getRowStyle,
          valueGetter(params) {
            return {
              value: params.data.getChildPropertyValue(FlightBound.ANY, ['aircraftTypeCode']),
              sup: that.getLabelIfCatalogElementIsNew(params, FlightBound.ANY, 'aircraftTypeId', 'aircraftTypeCode'),
              tooltip: that.getOldValueIfPropertyIsChanged(params, 'any.aircraftTypeCode'),
              color: that.getColorIfPropertyIsChanged(params, 'any.aircraftTypeCode'),
            };
          },
        },
        {
          headerValueGetter() {
            return that.$i18n.t('common.config');
          },
          width: 60,
          comparator: this.objectWithValueComparator,
          cellRendererFramework: AgGridValueAndSupCellRenderer,
          cellStyle: this.getRowStyle,
          valueGetter(params) {
            return {
              value: params.data.getChildPropertyValue(FlightBound.ANY, ['aircraftConfigCode']),
              sup: that.getLabelIfCatalogElementIsNew(params, FlightBound.ANY, 'aircraftConfigId', 'aircraftConfigCode'),
              tooltip: that.getOldValueIfPropertyIsChanged(params, 'any.aircraftConfigCode'),
              color: that.getColorIfPropertyIsChanged(params, 'any.aircraftConfigCode'),
            };
          },
        },
        {
          headerValueGetter() {
            return that.$i18n.t('common.date');
          },
          width: 80,
          type: 'date',
          filter: 'agDateColumnFilter',
          comparator: this.objectWithValueComparator,
          cellRendererFramework: AgGridValueAndSupCellRenderer,
          cellStyle: this.getRowStyle,
          valueGetter(params) {
            const value = params.data.getChildPropertyValue(FlightBound.INBOUND, ['scheduledTime']) ? params.data.getChildPropertyValue(FlightBound.INBOUND, ['scheduledTime']) : params.data.getChildPropertyValue(FlightBound.INBOUND, ['scheduledDate']);
            return {
              value: value ? that.getMoment(value).format('DD/MM/YYYY') : '',
              sup: null,
              tooltip: that.getOldValueIfPropertyIsChanged(params, 'any.scheduledDate', (a, b) => that.getMoment(a).isSame(b, 'day'), a => that.getMoment(a).format('DD/MM/YYYY')),
              color: that.getColorIfPropertyIsChanged(params, 'any.scheduledDate', (a, b) => that.getMoment(a).isSame(b, 'day'), a => that.getMoment(a).format('DD/MM/YYYY')),
            };
          },
        },
        {
          headerValueGetter() {
            return that.$i18n.t('common.flight');
          },
          width: 60,
          cellClass: 'separator',
          comparator: this.objectWithValueComparator,
          cellRendererFramework: AgGridValueAndSupCellRenderer,
          cellStyle: this.getCellStyleInbound,
          valueGetter(params) {
            if (params.data.getChildPropertyValue(FlightBound.INBOUND, ['operationResult']) == null || params.data.getChildPropertyValue(FlightBound.INBOUND, ['operationResult']) !== SchedulingImportResultOperation.EQUAL) {
              return {
                value: params.data.getChildPropertyValue(FlightBound.INBOUND, ['flightNumber']),
                sup: that.getLabelIfCatalogElementIsNew(params, FlightBound.INBOUND, 'airlineId', 'airlineCode'),
                tooltip: that.getOldValueIfPropertyIsChanged(params, 'inbound', (a, b) => a.flightNo() === b.flightNo()),
                color: that.getColorIfPropertyIsChanged(params, 'inbound', (a, b) => a.flightNo() === b.flightNo()),
              };
            }
            return null;
          },
        },
        {
          headerValueGetter() {
            return that.$i18n.t('common.airlineOperation');
          },
          width: 40,
          cellClass: 'separator',
          comparator: this.objectWithValueComparator,
          cellRendererFramework: AgGridValueAndSupCellRenderer,
          cellStyle: this.getCellStyleInbound,
          valueGetter(params) {
            if (params.data.getChildPropertyValue(FlightBound.INBOUND, ['operationResult']) == null || params.data.getChildPropertyValue(FlightBound.INBOUND, ['operationResult']) !== SchedulingImportResultOperation.EQUAL) {
              return {
                value: params.data.getChildPropertyValue(FlightBound.INBOUND, ['airlineOperationIataCode']),
                sup: that.getLabelIfCatalogElementIsNew(params, FlightBound.INBOUND, 'airlineOperationId', 'airlineOperationIataCode'),
                tooltip: that.getOldValueIfPropertyIsChanged(params, 'inbound.airlineOperationIataCode'),
                color: that.getColorIfPropertyIsChanged(params, 'inbound.airlineOperationIataCode'),
              };
            }
            return null;
          },
        },
        {
          headerValueGetter() {
            return that.$i18n.t('common.scheduledTimeArrival');
          },
          width: 50,
          comparator: this.objectWithValueComparator,
          cellRendererFramework: AgGridTimeCellRenderer,
          cellStyle: this.getCellStyleInbound,
          valueGetter(params) {
            if (params.data.getChildPropertyValue(FlightBound.INBOUND, ['operationResult']) == null || params.data.getChildPropertyValue(FlightBound.INBOUND, ['operationResult']) !== SchedulingImportResultOperation.EQUAL) {
              const value = params.data.getChildPropertyValue(FlightBound.INBOUND, ['scheduledTime']);
              return {
                value: value ? that.getMoment(value) : null,
                sup: null,
                tooltip: that.getOldValueIfPropertyIsChanged(params, 'inbound.scheduledTime', (a, b) => that.getMoment(a).isSame(b, 'minute'), a => that.getMoment(a).format('HH:mm')),
                color: that.getColorIfPropertyIsChanged(params, 'inbound.scheduledTime', (a, b) => that.getMoment(a).isSame(b, 'minute')),
              };
            }
            return null;
          },
        },
        {
          headerValueGetter() {
            return that.$i18n.t('common.fromDate');
          },
          width: 100,
          comparator: this.objectWithValueComparator,
          cellRendererFramework: AgGridArrayValueAndSupCellRenderer,
          cellStyle: this.getCellStyleInbound,
          valueGetter(params) {
            if (params.data.getChildPropertyValue(FlightBound.INBOUND, ['operationResult']) == null || params.data.getChildPropertyValue(FlightBound.INBOUND, ['operationResult']) !== SchedulingImportResultOperation.EQUAL) {
              return {
                value: that.getAirportsValueArray(params, FlightBound.INBOUND),
                sup: null,
                tooltip: that.getOldValueIfPropertyIsChanged(params, 'inbound', (a, b) => a.airportsIata() === b.airportsIata(), a => a.airportsIata()),
                color: that.getColorIfPropertyIsChanged(params, 'inbound', (a, b) => a.airportsIata() === b.airportsIata()),
              };
            }
            return null;
          },
        },
        {
          headerValueGetter() {
            return that.$i18n.t('common.handlingTypeShort');
          },
          width: 40,
          cellRendererFramework: AgGridValueAndSupCellRenderer,
          cellStyle: this.getCellStyleInbound,
          valueGetter(params) {
            if (params.data.getChildPropertyValue(FlightBound.INBOUND, ['operationResult']) == null || params.data.getChildPropertyValue(FlightBound.INBOUND, ['operationResult']) !== SchedulingImportResultOperation.EQUAL) {
              return {
                value: params.data.getChildPropertyValue(FlightBound.INBOUND, ['handlingTypeCode']),
                sup: that.getLabelIfCatalogElementIsNew(params, FlightBound.INBOUND, 'handlingTypeId', 'handlingTypeCode'),
                tooltip: that.getOldValueIfPropertyIsChanged(params, 'inbound.handlingTypeCode'),
                color: that.getColorIfPropertyIsChanged(params, 'inbound.handlingTypeCode'),
              };
            }
            return null;
          },
        },
        {
          headerValueGetter() {
            return that.$i18n.t('common.serviceTypeShort');
          },
          width: 40,
          cellRendererFramework: AgGridValueAndSupCellRenderer,
          cellStyle: this.getCellStyleInbound,
          valueGetter(params) {
            if (params.data.getChildPropertyValue(FlightBound.INBOUND, ['operationResult']) == null || params.data.getChildPropertyValue(FlightBound.INBOUND, ['operationResult']) !== SchedulingImportResultOperation.EQUAL) {
              const serviceTypeValue = (params.data.getChildPropertyValue(FlightBound.INBOUND, ['serviceTypeCode']) !== '')
                ? params.data.getChildPropertyValue(FlightBound.INBOUND, ['serviceTypeCode'])
                : that.$t('schedulingImport.calculatedAttributesValue');
              const serviceTypeTooltip = (serviceTypeValue === that.$t('schedulingImport.calculatedAttributesValue'))
                ? that.$t('schedulingImport.calculatedAttributesTooltip')
                : that.getOldValueIfPropertyIsChanged(params, 'inbound.serviceTypeCode');
              return {
                value: serviceTypeValue,
                sup: that.getLabelIfCatalogElementIsNew(params, FlightBound.INBOUND, 'serviceTypeId', 'serviceTypeCode'),
                tooltip: serviceTypeTooltip,
                color: that.getColorIfPropertyIsChanged(params, 'inbound.serviceTypeCode'),
              };
            }
            return null;
          },
        },
        {
          headerValueGetter() {
            return that.$i18n.t('common.date');
          },
          width: 80,
          type: 'date',
          filter: 'agDateColumnFilter',
          comparator: this.objectWithValueComparator,
          cellRendererFramework: AgGridValueAndSupCellRenderer,
          cellStyle: this.getRowStyle,
          valueGetter(params) {
            const value = params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['scheduledTime']) ? params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['scheduledTime']) : params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['scheduledDate']);
            return {
              value: value ? that.getMoment(value).format('DD/MM/YYYY') : '',
              sup: null,
              tooltip: that.getOldValueIfPropertyIsChanged(params, 'any.scheduledDate', (a, b) => that.getMoment(a).isSame(b, 'day'), a => that.getMoment(a).format('DD/MM/YYYY')),
              color: that.getColorIfPropertyIsChanged(params, 'any.scheduledDate', (a, b) => that.getMoment(a).isSame(b, 'day'), a => that.getMoment(a).format('DD/MM/YYYY')),
            };
          },
        },
        {
          headerValueGetter() {
            return that.$i18n.t('common.flight');
          },
          width: 60,
          cellClass: 'separator',
          comparator: this.objectWithValueComparator,
          cellRendererFramework: AgGridValueAndSupCellRenderer,
          cellStyle: this.getCellStyleOutbound,
          valueGetter(params) {
            if (params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['operationResult']) == null || params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['operationResult']) !== SchedulingImportResultOperation.EQUAL) {
              return {
                value: params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['flightNumber']),
                sup: that.getLabelIfCatalogElementIsNew(params, FlightBound.OUTBOUND, 'airlineId', 'airlineCode'),
                tooltip: that.getOldValueIfPropertyIsChanged(params, 'outbound', (a, b) => a.flightNo() === b.flightNo()),
                color: that.getColorIfPropertyIsChanged(params, 'outbound', (a, b) => a.flightNo() === b.flightNo()),
              };
            }
            return null;
          },
        },
        {
          headerValueGetter() {
            return that.$i18n.t('common.airlineOperation');
          },
          width: 40,
          cellClass: 'separator',
          comparator: this.objectWithValueComparator,
          cellRendererFramework: AgGridValueAndSupCellRenderer,
          cellStyle: this.getCellStyleOutbound,
          valueGetter(params) {
            if (params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['operationResult']) == null || params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['operationResult']) !== SchedulingImportResultOperation.EQUAL) {
              return {
                value: params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['airlineOperationIataCode']),
                sup: that.getLabelIfCatalogElementIsNew(params, FlightBound.OUTBOUND, 'airlineOperationId', 'airlineOperationIataCode'),
                tooltip: that.getOldValueIfPropertyIsChanged(params, 'outbound.airlineOperationIataCode'),
                color: that.getColorIfPropertyIsChanged(params, 'outbound.airlineOperationIataCode'),
              };
            }
            return null;
          },
        },
        {
          headerValueGetter() {
            return that.$i18n.t('common.scheduledTimeOfDeparture');
          },
          width: 50,
          comparator: this.objectWithValueComparator,
          cellRendererFramework: AgGridTimeCellRenderer,
          cellStyle: this.getCellStyleOutbound,
          valueGetter(params) {
            if (params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['operationResult']) == null || params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['operationResult']) !== SchedulingImportResultOperation.EQUAL) {
              const value = params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['scheduledTime']);
              return {
                value: value ? that.getMoment(value) : null,
                sup: null,
                tooltip: that.getOldValueIfPropertyIsChanged(params, 'outbound.scheduledTime', (a, b) => that.getMoment(a).isSame(b, 'minute'), a => that.getMoment(a).format('HH:mm')),
                color: that.getColorIfPropertyIsChanged(params, 'outbound.scheduledTime', (a, b) => that.getMoment(a).isSame(b, 'minute')),
              };
            }
            return null;
          },
        },
        {
          headerValueGetter() {
            return that.$i18n.t('common.toDate');
          },
          width: 100,
          comparator: this.objectWithValueComparator,
          cellRendererFramework: AgGridArrayValueAndSupCellRenderer,
          cellStyle: this.getCellStyleOutbound,
          valueGetter(params) {
            if (params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['operationResult']) == null || params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['operationResult']) !== SchedulingImportResultOperation.EQUAL) {
              return {
                value: that.getAirportsValueArray(params, FlightBound.OUTBOUND),
                sup: null,
                tooltip: that.getOldValueIfPropertyIsChanged(params, 'outbound', (a, b) => a.airportsIata() === b.airportsIata(), a => a.airportsIata()),
                color: that.getColorIfPropertyIsChanged(params, 'outbound', (a, b) => a.airportsIata() === b.airportsIata()),
              };
            }
            return null;
          },
        },
        {
          headerValueGetter() {
            return that.$i18n.t('common.handlingTypeShort');
          },
          width: 40,
          comparator: this.objectWithValueComparator,
          cellRendererFramework: AgGridValueAndSupCellRenderer,
          cellStyle: this.getCellStyleOutbound,
          valueGetter(params) {
            if (params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['operationResult']) == null || params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['operationResult']) !== SchedulingImportResultOperation.EQUAL) {
              return {
                value: params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['handlingTypeCode']),
                sup: that.getLabelIfCatalogElementIsNew(params, FlightBound.OUTBOUND, 'handlingTypeId', 'handlingTypeCode'),
                tooltip: that.getOldValueIfPropertyIsChanged(params, 'outbound.handlingTypeCode'),
                color: that.getColorIfPropertyIsChanged(params, 'outbound.handlingTypeCode'),
              };
            }
            return null;
          },
        },
        {
          headerValueGetter() {
            return that.$i18n.t('common.serviceTypeShort');
          },
          width: 40,
          comparator: this.objectWithValueComparator,
          cellRendererFramework: AgGridValueAndSupCellRenderer,
          cellStyle: this.getCellStyleOutbound,
          valueGetter(params) {
            if (params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['operationResult']) == null || params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['operationResult']) !== SchedulingImportResultOperation.EQUAL) {
              const serviceTypeValue = (params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['serviceTypeCode']) !== '')
                ? params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['serviceTypeCode'])
                : that.$t('schedulingImport.calculatedAttributesValue');
              const serviceTypeTooltip = (serviceTypeValue === that.$t('schedulingImport.calculatedAttributesValue'))
                ? that.$t('schedulingImport.calculatedAttributesTooltip')
                : that.getOldValueIfPropertyIsChanged(params, 'outbound.serviceTypeCode');
              return {
                value: serviceTypeValue,
                sup: that.getLabelIfCatalogElementIsNew(params, FlightBound.OUTBOUND, 'serviceTypeId', 'serviceTypeCode'),
                tooltip: serviceTypeTooltip,
                color: that.getColorIfPropertyIsChanged(params, 'outbound.serviceTypeCode'),
              };
            }
            return null;
          },
        },
      ],
    };
  },
  computed: {
    selectedTimezone() {
      return this.importOptions && this.importOptions.timeZoneMode ? this.importOptions.timeZoneMode : this.globalTimezone;
    },
  },
  methods: {
    onGridReady(params) {
      this.$emit('on-grid-ready', params);
    },
    getMoment(string) {
      return this.$moment.tz(string, this.selectedTimezone);
    },
    getRowStyle(params) {
      return this.getStyle(params.data.importOperationResult != null ? params.data.importOperationResult : params.data.importOperation);
    },
    getCellStyleInbound(params) {
      return this.getStyle(params.data.importOperationInboundResult != null ? params.data.importOperationInboundResult : params.data.importOperationInbound);
    },
    getCellStyleOutbound(params) {
      return this.getStyle(params.data.importOperationOutboundResult != null ? params.data.importOperationOutboundResult : params.data.importOperationOutbound);
    },
    getStyle(importOperation) {
      let style = {};
      switch (importOperation) {
        case SchedulingImportResultOperation.INSERT:
          style = { backgroundColor: '#E8F5E9' };
          break;
        case SchedulingImportResultOperation.DELETE:
          style = { backgroundColor: '#FBE9E7' };
          break;
        case SchedulingImportResultOperation.EQUAL:
          style = { backgroundColor: '#E3F2FD' };
          break;
        case SchedulingImportResultOperation.MODIFY:
          style = { backgroundColor: '#FFF3E0' };
          break;
        default:
          style = { textAlign: 'center' };
          break;
      }
      return style;
    },
    getLabelIfCatalogElementIsNew(params, bound, idFieldName, codeFieldName) {
      if (this.checkIfCatalogElementIsNew(params.data, bound, idFieldName, codeFieldName)) {
        return 'NEW';
      }
      return null;
    },
    replacePropertyPathIfBoundIsAny(params, fieldPropertyPath) {
      if (fieldPropertyPath.includes('any')) {
        return fieldPropertyPath.replace('any', params.data.inbound ? 'inbound' : 'outbound');
      }
      return fieldPropertyPath;
    },
    getOldValueIfPropertyIsChanged(params, fieldPropertyPath, fieldComparator, fieldGetter) {
      const propertyIsChanged = params.data.isPropertyChanged(this.replacePropertyPathIfBoundIsAny(params, fieldPropertyPath), fieldComparator);
      if (propertyIsChanged) {
        return params.data.getOldValue(this.replacePropertyPathIfBoundIsAny(params, fieldPropertyPath), fieldGetter);
      }
      return null;
    },
    getColorIfPropertyIsChanged(params, fieldPropertyPath, fieldComparator) {
      const propertyIsChanged = params.data.isPropertyChanged(this.replacePropertyPathIfBoundIsAny(params, fieldPropertyPath), fieldComparator);
      if (propertyIsChanged) {
        return '#F00';
      }
      return null;
    },
    getAirportsValueArray(params, bound) {
      const valueArray = [];
      if (params.data.getChildPropertyValue(bound, ['airport1Code'])) {
        valueArray.push({
          value: params.data.getChildPropertyValue(bound, ['airport1Code']),
          sup: this.getLabelIfCatalogElementIsNew(params, bound, 'airport1Id', 'airport1Code'),
        });
      }
      if (params.data.getChildPropertyValue(bound, ['airport2Code'])) {
        valueArray.push({
          value: params.data.getChildPropertyValue(bound, ['airport2Code']),
          sup: this.getLabelIfCatalogElementIsNew(params, bound, 'airport2Id', 'airport2Code'),
        });
      }
      if (params.data.getChildPropertyValue(bound, ['airport3Code'])) {
        valueArray.push({
          value: params.data.getChildPropertyValue(bound, ['airport3Code']),
          sup: this.getLabelIfCatalogElementIsNew(params, bound, 'airport3Id', 'airport3Code'),
        });
      }
      return valueArray;
    },
    getActionLabel(data) {
      if (!data) {
        return null;
      }
      const operation = data.importOperationResult != null ? data.importOperationResult : data.importOperation;
      switch (operation) {
        case SchedulingImportResultOperation.INSERT: return 'I';
        case SchedulingImportResultOperation.DELETE: return 'D';
        case SchedulingImportResultOperation.EQUAL: return 'E';
        case SchedulingImportResultOperation.MODIFY: return 'U';
        default: return null;
      }
    },
  },
};
</script>
<style lang="scss">
//@import "../../../assets/scss/ag-grid-fis-theme";

.containerTitleSummary {
  padding: 0 0 1em 0;
}
  .containerInfoSummary {
    margin: 0 2em;
    padding: 1em;
    font-weight: bold;
    text-align:center;
  }
.containerInfoSummary>span {
  display:inline-block;
  vertical-align:middle;
}
  .infoInsert {
    background: #E8F5E9;
  }
  .infoDelete {
    background: #FBE9E7;
  }
  .infoUpdate {
    background: #FFF3E0;
  }
</style>
