<template>
  <div class="employee-income-ticketing-report list-filter">
    <v-card :dark="this.$vuetify.dark" :color="this.$vuetify.dark ? 'bggrey_dark' : 'bggrey'">
      <v-toolbar dark dense color="primary lighten-1">
        <v-btn v-on:click="displayedFilter" small flat>
          <v-icon>filter_list</v-icon>
          <v-icon v-if="!showFilter">keyboard_arrow_down</v-icon>
          <v-icon v-if="showFilter">keyboard_arrow_up</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <EmployeeIncomeTableOptionsFilterButton />
v      </v-toolbar>
      <v-container fluid v-if="showFilter" class="pa-2 pb-2">
        <v-layout class="filter-inline py-0" align-center justify-start>
          <date-input
            v-model="filter.dateFrom"
            :locale="$i18n.locale"
            :label="$t('common.fromDate')"
            @change="changeDateFrom"
          ></date-input>
          <date-input
            class="ml-2"
            v-model="filter.dateTo"
            :locale="$i18n.locale"
            :label="$t('common.toDate')"
            @change="changeDateTo"
          ></date-input>
          <v-flex class="mr-2" align-center justify-start>
            <airline-select class="ml-4" v-char-length.clearable="60" :label="$t('common.airlines')" :placeholder="$t('commonForm.selectAirline')" v-model="filter.airlines" @input="filterChanged" :multiple="true" :chip-view="true" :filter-handling-active="true"></airline-select>
          </v-flex>
          <v-spacer style="width: 45%"></v-spacer>
          <v-flex class="ml-2 text-no-wrap" align-center justify-start>
            <v-btn color="secondary" flat round v-on:click.stop="exportCSV" block>
              <v-icon>mdi-download</v-icon>
              {{ $t("common.export") }}
            </v-btn>
          </v-flex>
          <v-flex class="ml-2 text-no-wrap" align-center justify-start>
          <v-btn color="primary" round v-on:click.stop="find" block>
            <v-icon>search</v-icon>
            {{ $t("common.find") }}
          </v-btn>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card>
  </div>
</template>
<script>
import AgGridApiCsvExportable from '@/components/ag-grid/mixins/AgGridApiCsvExportable.vue';
import AirlineSelect from '../../airline/AirlineSelect.vue';
import DateInput from '../../ui/DateInput.vue';
import StoreSubscribable from '../../mixins/StoreSubscribable.vue';
import { TIME_TIMEZONE_CHANGED } from '../../../store/modules/time';
import EmployeeIncomeTableOptionsFilterButton from './EmployeeIncomeTableOptionsFilterButton.vue';
import FileSerializable from '../../mixins/FileSerializable.vue';
import {
  EMPLOYEE_INCOME_TICKETING_REPORT_CHANGE_GRID_API,
  EMPLOYEE_INCOME_TICKETING_REPORT_FILTER_SET_UNIT_TIME,
  EMPLOYEE_INCOME_TICKETING_REPORT_FIND,
  EMPLOYEE_INCOME_TICKETING_REPORT_RECALCULATE_FILTER,
} from '../../../store/modules/ticketingReports/employeeIncome';

export default {
  name: 'employee-income-ticketing-report-list-filter',
  mixins: [AgGridApiCsvExportable, StoreSubscribable, FileSerializable],
  components: {
    AirlineSelect,
    DateInput,
    EmployeeIncomeTableOptionsFilterButton,
  },
  props: ['value'],
  data() {
    return {
      showFilter: true,
      filterSummary: null,
    };
  },
  computed: {
    filter() {
      return this.$store.state.ticketingReports.employeeIncome.filter;
    },
    unitOfTime: {
      get() {
        return this.filter.unitOfTime;
      },
      set(value) {
        this.$store.commit(
          EMPLOYEE_INCOME_TICKETING_REPORT_FILTER_SET_UNIT_TIME,
          value,
        );
      },
    },
  },
  methods: {
    filterChanged() {
      // this.find();
    },
    find() {
      this.showLoading();
      this.$store.dispatch(EMPLOYEE_INCOME_TICKETING_REPORT_FIND);
    },
    exportCSV() {
      this.exportAgGridCsv('EmployeesCollection');
    },
    changeDateFrom() {
      this.unitOfTime = null;
      const from = this.$moment(this.filter.dateFrom);
      const to = this.$moment(this.filter.dateTo);

      this.filter.dateFrom = from.startOf('day');
      if (from.isAfter(to)) {
        this.filter.dateTo = this.$moment(this.filter.dateFrom).endOf('day');
      }
      this.filterChanged();
    },
    changeDateTo() {
      this.unitOfTime = null;
      const from = this.$moment(this.filter.dateFrom);
      const to = this.$moment(this.filter.dateTo);

      this.filter.dateTo = to.endOf('day');
      if (to.isBefore(from)) {
        this.filter.dateFrom = this.$moment(this.filter.dateTo).startOf('day');
      }
      console.log('change dateTo'); // eslint-disable-line no-console
      this.filterChanged();
    },
    displayedFilter() {
      this.showFilter = !this.showFilter;
      this.$emit('changeShowFilter', this.showFilter);
    },
  },
  mounted() {
    const that = this;
    this.subscribeMutation(EMPLOYEE_INCOME_TICKETING_REPORT_CHANGE_GRID_API, (mutationPayload) => {
      that.setAgGridApi(mutationPayload);
    });
    this.subscribeMutation(TIME_TIMEZONE_CHANGED, () => {
      this.$store.dispatch(EMPLOYEE_INCOME_TICKETING_REPORT_RECALCULATE_FILTER);
      this.find();
    });
    this.$store.dispatch(EMPLOYEE_INCOME_TICKETING_REPORT_RECALCULATE_FILTER);
    this.find();
  },
};
</script>
