import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VChip } from 'vuetify/lib/components/VChip';
import { VListTileContent } from 'vuetify/lib/components/VList';
import { VListTileSubTitle } from 'vuetify/lib/components/VList';
import { VListTileTitle } from 'vuetify/lib/components/VList';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VAutocomplete,{ref:_vm.refComp,attrs:{"value":_vm.value,"label":_vm.label,"placeholder":_vm.$t('commonForm.selectAirlineFare'),"items":_vm.items,"rules":_vm.rules,"disabled":_vm.disabled,"multiple":_vm.multiple,"clearable":true,"item-text":"name","item-value":"id","hide-details":""},on:{"input":_vm.inputAirlineFare,"change":_vm.onChangeSelection},scopedSlots:_vm._u([(_vm.chipView)?{key:"selection",fn:function(data){return [_c(VChip,{staticClass:"chip--select-multi",attrs:{"selected":data.selected,"close":""},on:{"input":function($event){return _vm.remove(data.item)}}},[_vm._v(" "+_vm._s(data.item.name)+" ")])]}}:null,{key:"item",fn:function({ item }){return [_c(VListTileContent,[_c(VListTileTitle,[_vm._v(_vm._s(item.name))]),(item.description)?_c(VListTileSubTitle,[_vm._v(_vm._s(item.description))]):_vm._e(),_c(VListTileSubTitle,[_vm._v(_vm._s(item.airlineCode))])],1)]}}],null,true)})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }