<template>
  <ag-grid-vue
    class="ag-theme-material"
    :defaultColDef="defaultColDef"
    :columnDefs="columnDefs"
    :rowData="items"
    :onGridReady="onGridReady"
    :suppressHorizontalScroll="true"
    rowSelection="multiple"
    :frameworkComponents="frameworkComponents"
    :gridOptions="gridOptions"
    @selection-changed="onSelectionChanged"
    style="width: 100%; height: calc(100vh - 500px);">
  </ag-grid-vue>
</template>
<script>
import { AgGridVue } from 'ag-grid-vue';
import AgGridTimeCellRenderer from '../ag-grid/cell-renderers/AgGridTimeCellRenderer.vue';
import AgGridApiHoldable from '../ag-grid/mixins/AgGridApiHoldable.vue';
import AgGridTimeValueGetteable from '../ag-grid/mixins/AgGridTimeValueGetteable.vue';
import AgGridOptionsConfigurable from '../ag-grid/mixins/AgGridOptionsConfigurable.vue';
import Resizable from '../mixins/Resizable.vue';

import { FlightBound } from '../shared/model/flightBound';
import AgGridValueComparable from '../ag-grid/mixins/AgGridValueComparable.vue';
import { cellClassAgGridRules } from '../ag-grid/mixins/cellClassAgGridRules';
import agGridTextCustomFilterComparator from '../ag-grid/filters/agGridTextCustomFilterComparator';
import agGridTextCustomFilterFormatter from '../ag-grid/filters/agGridTextCustomFilterFormatter';

export default {
  name: 'connected-flights-base-grid',
  mixins: [AgGridApiHoldable, AgGridTimeValueGetteable, Resizable, AgGridValueComparable, AgGridOptionsConfigurable],
  components: {
    AgGridVue,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    const that = this;
    return {
      frameworkComponents: {
      },
      gridOptions: {
        getRowClass: this.getRowClass,
        getRowNodeId: this.getRowNodeId,
      },
      defaultColDef: {
        cellClassRules: cellClassAgGridRules,
        resizable: true,
        sortable: true,
        filter: true,
        filterParams: {
          textCustomComparator: agGridTextCustomFilterComparator,
          textFormatter: agGridTextCustomFilterFormatter,
        },
      },
      columnDefs: [
        {
          checkboxSelection: true,
          width: 30,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          suppressMenu: true,
          sortable: false,
          suppressMovable: true,
          suppressSizeToFit: true,
          filter: false,
          resizable: false,
        },
        {
          headerValueGetter() {
            return that.$i18n.t('common.date');
          },
          type: 'date',
          filter: 'agDateColumnFilter',
          valueGetter(params) {
            return params.data.getChildPropertyValue(FlightBound.ANY, ['common', 'scheduledDate']);
          },
          valueFormatter(params) {
            return that.$moment(params.value).format('DD/MM/YYYY');
          },
        },
        {
          headerValueGetter() {
            return that.$i18n.t('common.aircraftRegistration');
          },
          valueGetter(params) {
            return params.data.getChildPropertyValue(FlightBound.ANY, ['common', 'aircraftRegisterNumber']);
          },
        },
        {
          headerValueGetter() {
            return that.$i18n.t('common.type');
          },
          valueGetter(params) {
            return params.data.getChildPropertyValue(FlightBound.ANY, ['common', 'aircraftTypeCode']);
          },
        },
        {
          headerValueGetter() {
            return that.$i18n.t('common.config');
          },
          valueGetter(params) {
            return params.data.getChildPropertyValue(FlightBound.ANY, ['common', 'aircraftConfigCode']);
          },
        },
        {
          headerValueGetter() {
            return that.$i18n.t('common.flight');
          },
          cellClass: 'separator',
          valueGetter(params) {
            return params.data.getChildPropertyValue(FlightBound.INBOUND, ['common', 'flightNo']);
          },
        },
        {
          headerValueGetter() {
            return that.$i18n.t('common.scheduledTimeArrival');
          },
          comparator: this.objectWithValueComparator,
          valueGetter(params) {
            return that.agGridTimeGetter(params.data.getChildPropertyValue(FlightBound.INBOUND, ['common', 'scheduledTime']), params.data.getChildPropertyValue(FlightBound.INBOUND, ['common', 'scheduledDate']));
          },
          cellRendererFramework: AgGridTimeCellRenderer,
        },
        {
          headerValueGetter() {
            return that.$i18n.t('common.from');
          },
          valueGetter(params) {
            return params.data.getChildPropertyValue(FlightBound.INBOUND, ['common', 'airportsIata']);
          },
        },
        {
          headerValueGetter() {
            return that.$i18n.t('common.flight');
          },
          cellClass: 'separator',
          valueGetter(params) {
            return params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'flightNo']);
          },
        },
        {
          headerValueGetter() {
            return that.$i18n.t('common.scheduledTimeOfDeparture');
          },
          valueGetter(params) {
            return that.agGridTimeGetter(params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'scheduledTime']), params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'scheduledDate']));
          },
          cellRendererFramework: AgGridTimeCellRenderer,
        },
        {
          headerValueGetter() {
            return that.$i18n.t('common.to');
          },
          valueGetter(params) {
            return params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'airportsIata']);
          },
        },
      ],
    };
  },
  methods: {
    onGridReady(params) {
      this.setAgGridApi(params);
      this.selectAll();
    },
    selectAll() {
      this.gridApi.selectAllFiltered();
    },
    onSelectionChanged() {
      const selectedNodes = this.gridApi.getSelectedNodes();
      this.$emit('selected-changed', selectedNodes.map(node => node.data));
    },
  },
};
</script>
<style>
</style>
