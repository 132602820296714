/* eslint no-param-reassign: "off" */
/* eslint no-confusing-arrow: "off" */
/* eslint no-nested-ternary: "off" */

import moment from 'moment';
import { HTTP } from '../../http';
import { REQUEST_ERROR, REQUEST_START, REQUEST_SUCCESS } from '../store';
import { NOTIFICATION_SHOW } from './notifications';
import i18n from '../../plugins/i18n';
import AirlineFare from '../../components/configuration/airlineFares/model/airlineFare';
import AirlineFareFilter from '../../components/ui/airlineFareSelector/model/airlineFareFilter';

// actions names
export const GET_AIRLINE_FARES = 'GET_AIRLINE_FARES';
export const AIRLINE_FARE_DELETE = 'AIRLINE_FARE_DELETE';
export const AIRLINE_FARE_CREATE = 'AIRLINE_FARE_CREATE';
export const AIRLINE_FARE_UPDATE = 'AIRLINE_FARE_UPDATE';
export const AIRLINE_FARE_DISABLE = 'AIRLINE_FARE_DISABLE';
export const AIRLINE_FARE_ENABLE = 'AIRLINE_FARE_ENABLE';
export const FIND_AIRLINE_FARES = 'FIND_AIRLINE_FARES';

// mutations
export const GET_AIRLINE_FARES_SUCCESS = 'GET_AIRLINE_FARES_SUCCESS';
export const GET_AIRLINE_FARES_REQUESTED = 'GET_AIRLINE_FARES_REQUESTED';
export const UPDATE_AIRLINE_FARES_SUCCESS = 'UPDATE_AIRLINE_FARES_SUCCESS';
export const CHANGE_AIRLINE_FARES_SELECTION = 'CHANGE_AIRLINE_FARES_SELECTION';
export const FIND_AIRLINE_FARES_SUCCESS = 'FIND_AIRLINE_FARES_SUCCESS';

// initial state
const storeState = {
  airlineFares: [],
  filteredAirlineFares: [],
  filter: AirlineFareFilter.createEmptyFilter,
  airlineFaresRequested: false,
  selectedAirlineFares: [],
  typeFields: [
    {
      name: 'Text',
      value: 'text',
    },
    {
      name: 'Number',
      value: 'number',
    },
    {
      name: 'Date',
      value: 'date',
    },
    {
      name: 'Checkbox',
      value: 'checkbox',
    },
  ],
};

// getters
const storeGetters = {
  getAirlineFares: state => state.airlineFares.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)),
  getActiveAirlineFares: state => state.airlineFares.filter(elem => !elem.deleted).sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)),
  getEnableAndActiveAirlineFares: state => state.airlineFares
    .filter(elem => !elem.deleted)
    .filter((elem) => {
      if (elem.dateTo && elem.dateTo != null) {
        return moment().startOf('day').isBetween(elem.dateFrom, elem.dateTo);
      }
      return moment().isAfter(elem.dateFrom);
    })
    .sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)),
  getFilteredAirlineFares: state => state.filteredAirlineFares.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)),
  getEnableAndActiveFilteredAirlineFares: state => state.filteredAirlineFares
    .filter(elem => !elem.deleted)
    .filter((elem) => {
      if (elem.dateTo && elem.dateTo != null) {
        return moment().startOf('day').isBetween(elem.dateFrom, elem.dateTo);
      }
      return moment().isAfter(elem.dateFrom);
    })
    .sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)),
  getAirlineFaresSelected: state => state.selectedAirlineFares,
  hasSelectedRates: state => state.selectedAirlineFares.length > 0,
  getAirlineFareById: state => id => state.airlineFares.find(a => a.id === id),
  getTypeFields: state => state.typeFields,
};

// actions
const storeActions = {
  [GET_AIRLINE_FARES]: ({ commit, state }) => {
    if (state.airlineFaresRequested) {
      return;
    }
    commit(REQUEST_START);
    commit(GET_AIRLINE_FARES_REQUESTED);
    HTTP.get('airline-fares')
      .then((resp) => {
        commit(REQUEST_SUCCESS);
        commit(GET_AIRLINE_FARES_SUCCESS, resp.data);
      })
      .catch((err) => {
        commit(REQUEST_ERROR, err);
      });
  },

  [AIRLINE_FARE_CREATE]: ({ commit, dispatch }, airlineFare) => {
    commit(REQUEST_START);
    return HTTP.post('airline-fares', airlineFare)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(UPDATE_AIRLINE_FARES_SUCCESS);
        dispatch(GET_AIRLINE_FARES);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.airlineFareCreate'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },

  [AIRLINE_FARE_UPDATE]: ({ commit, dispatch }, airlineFare) => {
    commit(REQUEST_START);
    return HTTP.put('airline-fares', airlineFare)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(UPDATE_AIRLINE_FARES_SUCCESS);
        dispatch(GET_AIRLINE_FARES);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.airlineFareUpdate'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
  [AIRLINE_FARE_DELETE]: ({ commit, dispatch }, id) => {
    commit(REQUEST_START);
    return HTTP.delete(`airline-fares/${id}`)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(UPDATE_AIRLINE_FARES_SUCCESS);
        dispatch(GET_AIRLINE_FARES);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.airlineFareDelete'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
  [AIRLINE_FARE_DISABLE]: ({ commit, dispatch }, id) => {
    commit(REQUEST_START);
    return HTTP.patch(`/airline-fares/disable/${id}`)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(UPDATE_AIRLINE_FARES_SUCCESS);
        dispatch(GET_AIRLINE_FARES);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.airlineFareDisable'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
  [AIRLINE_FARE_ENABLE]: ({ commit, dispatch }, id) => {
    commit(REQUEST_START);
    return HTTP.patch(`/airline-fares/enable/${id}`)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(UPDATE_AIRLINE_FARES_SUCCESS);
        dispatch(GET_AIRLINE_FARES);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.airlineFareEnable'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
  [FIND_AIRLINE_FARES]: ({ commit, state }) => {
    commit(REQUEST_START);
    const params = state.filter;
    return HTTP.post('airline-fares/find', params)
      .then((resp) => {
        commit(REQUEST_SUCCESS);
        commit(FIND_AIRLINE_FARES_SUCCESS, resp.data);
      })
      .catch((err) => {
        commit(REQUEST_ERROR, err);
      });
  },
};

// mutations
const storeMutations = {
  [GET_AIRLINE_FARES_SUCCESS]: (state, airlineFares) => {
    state.airlineFares = AirlineFare.createAirlineFareFromData(airlineFares);
  },
  [GET_AIRLINE_FARES_REQUESTED]: (state) => {
    state.airlineFaresRequested = true;
  },
  [UPDATE_AIRLINE_FARES_SUCCESS]: (state) => {
    state.airlineFaresRequested = false;
  },
  [CHANGE_AIRLINE_FARES_SELECTION]: (state, selection) => {
    state.selectedAirlineFares = selection;
  },
  [FIND_AIRLINE_FARES_SUCCESS]: (state, airlineFares) => {
    state.filteredAirlineFares = AirlineFare.createAirlineFareFromData(airlineFares);
  },
};

export default {
  state: storeState,
  getters: storeGetters,
  actions: storeActions,
  mutations: storeMutations,
};
