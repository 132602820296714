import moment from 'moment';
import { HTTP } from '../../http';
import { REQUEST_ERROR, REQUEST_START, REQUEST_SUCCESS } from '../store';

// action
export const GET_SCHEDULING_IMPORT_FILES_STATUS = 'GET_SCHEDULING_IMPORT_FILES_STATUS';
export const GET_LAST_SCHEDULING_IMPORT_FILE_STATUS_BY_USER = 'GET_LAST_SCHEDULING_IMPORT_FILE_STATUS_BY_USER';
// mutations
export const MUTATE_SCHEDULING_IMPORT_FILES_FILTER_FILE_MONTH = 'MUTATE_SCHEDULING_IMPORT_FILES_FILTER_FILE_MONTH';
export const MUTATE_SCHEDULING_IMPORT_FILES_FILTER_FILE_YEAR = 'MUTATE_SCHEDULING_IMPORT_FILES_FILTER_FILE_YEAR';
export const MUTATE_SCHEDULING_IMPORT_FILES_FILTER_FILE_NAME_OR_AUTHOR = 'MUTATE_SCHEDULING_IMPORT_FILES_FILTER_FILE_NAME_OR_AUTHOR';
export const MUTATE_SCHEDULING_IMPORT_FILES_FILTER_FILE_STATUS = 'MUTATE_SCHEDULING_IMPORT_FILES_FILTER_FILE_STATUS';
export const MUTATE_LAST_SCHEDULING_IMPORT_FILE_STATUS_BY_USER = 'MUTATE_LAST_SCHEDULING_IMPORT_FILE_STATUS_BY_USER';

export const MUTATE_SCHEDULING_IMPORT_FILES = 'MUTATE_SCHEDULING_IMPORT_FILES';

// initial state
const storeState = {
  schedulingImportFilesFilter: {
    year: moment().year(),
    month: moment().month() + 1, // becasuse moment objects set JAN  as 0 and java.time set JAN as 1 , so in some side it should be fix
    fileNameOrFileAuthor: null,
    status: null,
  },
  schedulingImportFiles: [],
  lastSchedulingImportedFileByUser: {},
};

// getters
const storeGetters = {
  getSchedulingImportFilesFilter: state => state.schedulingImportFilesFilter,
  getLastSchedulingImportedFileByUser: state => state.lastSchedulingImportedFileByUser,
  // get al the scheduling imports by day
  getSchedulingImportFilesByDay: state => (requestedDay) => {
    const requestedDayMoment = moment(requestedDay);

    if (!requestedDayMoment.isValid()) {
      return [];
    }

    return state.schedulingImportFiles
      .filter((file) => {
        const fileUploadDate = moment(file.uploadDate);
        if (!fileUploadDate.isValid()) {
          return false;
        }
        return requestedDayMoment.format('YYYY-MM-DD') === fileUploadDate.format('YYYY-MM-DD');
      })
      .sort((file, anotherFileToCompare) => {
        const fileUploadDate = moment(file.uploadDate);
        const anotherFileUploadDate = moment(anotherFileToCompare.uploadDate);

        // Ordena de fecha más reciente a más antigua
        if (fileUploadDate.isAfter(anotherFileUploadDate)) {
          return -1;
        }
        if (fileUploadDate.isBefore(anotherFileUploadDate)) {
          return 1;
        }
        return 0;
      });
  },
  // get all the days in which there have been scheduling imports
  getDaysWhenSchedulingImportFilesWereImported: (state) => {
    const uniqueDates = new Set(state.schedulingImportFiles
      .map(file => file.uploadDate)
      .map((uploadDateTime) => {
        const uploadDateTimeMoment = moment(uploadDateTime);
        return uploadDateTimeMoment.isValid() ? uploadDateTimeMoment.format('YYYY-MM-DD') : undefined;
      })
      .filter(uploadDate => uploadDate));

    return [...uniqueDates].sort((a, b) => {
      if (a > b) {
        return -1;
      }
      if (a < b) {
        return 1;
      }
      return 0;
    });
  },
};

// actions
const storeActions = {
  [GET_SCHEDULING_IMPORT_FILES_STATUS]: ({ commit, state }) => {
    commit(REQUEST_START);
    return HTTP.post('/scheduling/import/files/status', state.schedulingImportFilesFilter)
      .then((resp) => {
        commit(REQUEST_SUCCESS);
        commit(MUTATE_SCHEDULING_IMPORT_FILES, resp.data);
      })
      .catch((err) => {
        commit(REQUEST_ERROR, err);
      });
  },
  [GET_LAST_SCHEDULING_IMPORT_FILE_STATUS_BY_USER]: ({ commit }) => {
    commit(REQUEST_START);
    return HTTP.get('/scheduling/import/last-file/by-user/status')
      .then((resp) => {
        commit(REQUEST_SUCCESS);
        commit(MUTATE_LAST_SCHEDULING_IMPORT_FILE_STATUS_BY_USER, resp.data);
      })
      .catch((err) => {
        commit(REQUEST_ERROR, err);
      });
  },
};

// mutations
const storeMutations = {
  [MUTATE_SCHEDULING_IMPORT_FILES]: (state, importedFiles) => {
    state.schedulingImportFiles = importedFiles;
  },
  [MUTATE_SCHEDULING_IMPORT_FILES_FILTER_FILE_MONTH]: (state, newSelectedMonth) => {
    state.schedulingImportFilesFilter.month = newSelectedMonth + 1; // becasuse moment objects set JAN  as 0 and java.time set JAN as 1 , so in some side it should be fix
  },
  [MUTATE_SCHEDULING_IMPORT_FILES_FILTER_FILE_YEAR]: (state, newSelectedYear) => {
    state.schedulingImportFilesFilter.year = newSelectedYear;
  },
  [MUTATE_SCHEDULING_IMPORT_FILES_FILTER_FILE_NAME_OR_AUTHOR]: (state, newSelectedFileNameOrFileAuthor) => {
    state.schedulingImportFilesFilter.fileNameOrFileAuthor = newSelectedFileNameOrFileAuthor;
  },
  [MUTATE_SCHEDULING_IMPORT_FILES_FILTER_FILE_STATUS]: (state, newSelectedFileStatus) => {
    state.schedulingImportFilesFilter.status = newSelectedFileStatus;
  },
  [MUTATE_LAST_SCHEDULING_IMPORT_FILE_STATUS_BY_USER]: (state, lastSchedulingImportedFileByUser) => {
    state.lastSchedulingImportedFileByUser = lastSchedulingImportedFileByUser;
  },
};

export default {
  state: storeState,
  getters: storeGetters,
  actions: storeActions,
  mutations: storeMutations,
};
