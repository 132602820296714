<template>
  <v-layout row justify-center>
    <v-dialog v-model="dialog" persistent max-width="50%">
      <v-card :dark="this.$vuetify.dark" :color="this.$vuetify.dark ? 'bggrey_dark' : 'bggrey'">
        <v-toolbar dark color="primary">
          <v-icon>delete</v-icon>
          <v-toolbar-title>{{$t("common.deleteConfirmationTitle")}}</v-toolbar-title>
        </v-toolbar>

        <loading-component />

        <v-card-text>
          The rushes baggage selected are about to be deleted. Do you what to continue?
          <rush-baggage-base-grid :items="items" @selected-changed="selectedChanged" />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn flat color="primary" @click.native="reject()">CANCEL</v-btn>
          <v-btn color="error" depressed @click.native="confirm()" :disabled="loading">
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>
<script>
import LoadingComponent from '../../../layout/LoadingComponent.vue';
import RushBaggageBaseGrid from '../list/RushBaggageBaseGrid.vue';

export default {
  name: 'rush-baggage-delete-modal',
  components: {
    LoadingComponent,
    RushBaggageBaseGrid,
  },
  props: {
    dialog: { type: Boolean, required: true },
    items: { type: Array, required: true },
  },
  data() {
    return {
      selectedItems: [],
    };
  },
  computed: {
    loading() {
      return this.$store.state.loading;
    },
  },
  methods: {
    selectedChanged(selectedItems) {
      this.selectedItems = selectedItems;
    },
    reject() {
      this.$emit('delete-reject');
    },
    confirm() {
      this.$emit('delete-confirm', this.mapToIdsArray(this.items));
    },
    mapToIdsArray(rushesBaggage) {
      const deletable = [];
      if (rushesBaggage) {
        rushesBaggage.forEach((item) => {
          if (item.id) {
            deletable.push(item.id);
          }
        });
      }
      return deletable;
    },
  },
};
</script>
