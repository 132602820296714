import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VTimeline } from 'vuetify/lib/components/VTimeline';
import { VTimelineItem } from 'vuetify/lib/components/VTimeline';

var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.isEmptyDelays())?_c(VContainer,{staticClass:"infoMobileDelays"},[_c(VCard,{attrs:{"dark":this.$vuetify.dark}},[_c(VCardText,[_c('div',{staticClass:"font-weight-bold ms-2 mb-2"},[_vm._v(" "+_vm._s(_vm.$t('operations.flightDelay'))+" ")]),_c(VTimeline,{attrs:{"align":"start","dense":""}},_vm._l((_vm.flightMobileDelays),function(delay){return _c(VTimelineItem,{key:delay.id,attrs:{"color":delay.responsibleType === 'HANDLING' ? 'red' : 'orange',"small":""}},[_c('div',{staticClass:"mb-0"},[_c('div',{staticClass:"font-weight-normal"},[_c('strong',[_vm._v(" "+_vm._s(delay.typeCode)+" - "+_vm._s(delay.typeDescription))])]),_c('div',[_vm._v(_vm._s(_vm.formattedTime(delay.amount))+_vm._s(delay.remarks ? ' - '+delay.remarks : ''))])])])}),1)],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }