<template>
  <v-icon v-if="params.value" :class="getColorByStatus(params)" small>brightness_1</v-icon>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  methods: {
    getColorByStatus(params) {
      if (params.value && params.value.toLowerCase() === 'ok') {
        return 'status-ok';
      }
      return 'status-red';
    },
  },
});
</script>

<style scoped>

</style>
