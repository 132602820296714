<template>
    <div>
        <delays-flights-list-filter> </delays-flights-list-filter>
        <delays-flights-list-container> </delays-flights-list-container>
    </div>
</template>
<script>
import DelaysFlightsListContainer from '../components/delay/list/DelaysFlightsListContainer.vue';
import DelaysFlightsListFilter from '../components/delay/list/DelaysFlightsListFilter.vue';
import DelaysFlightsListTableOptions from '../components/delay/list/DelaysFlightsListTableOptions.vue';

export default {
  components: {
    DelaysFlightsListContainer,
    DelaysFlightsListFilter,
    DelaysFlightsListTableOptions,
  },
};
</script>
