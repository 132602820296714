<template>
  <v-container fluid py-0>
    <v-layout>
      <v-flex xs12>
        <v-breadcrumbs :items="breadcrumbs">
          <v-breadcrumbs-item slot="item" slot-scope="{ item }" :to="item.to" exact>
            {{ item.text }}
          </v-breadcrumbs-item>
        </v-breadcrumbs>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: 'Breadcrumbs',
  data() {
    return {
      breadcrumbs: [],
    };
  },
  mounted() {
    this.breadcrumbs = this.$route.meta.breadcrumbs;
  },
};
</script>
