<template>
  <div class="d-flex align-center pb-2">
    <div>
      <v-flex xs12>
        <div class="d-flex align-center justify-space-between">
          <v-icon class="mr-2">mdi-calendar</v-icon>
          <mobile-date-input :name="name + '_date'" :value="dateValue" :editable="editable"
            @input="emitValue($event, 'DATE')" @now="now" :clearable="clearable"></mobile-date-input>
        </div>
      </v-flex>
      <v-flex xs12>
        <div class="d-flex align-center justify-space-between pt-2">
          <v-icon class="mr-2">mdi-clock</v-icon>
          <mobile-time-input :name="name + '_time'" :value="timeValue" :editable="editable"
            @input="emitValue($event, 'TIME')" @now="now" :clearable="clearable">
          </mobile-time-input>
        </div>
      </v-flex>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import MobileTimeInput from './MobileTimeInput.vue';
import MobileDateInput from './MobileDateInput.vue';

export default {
  name: 'mobile-date-time-input',
  components: {
    MobileTimeInput,
    MobileDateInput,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    value: {
      required: true,
    },
    editable: {
      type: Boolean,
      default: true,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dateTimeValue: null,
      timeValue: null,
      dateValue: null,
      modal: false,
    };
  },
  watch: {
    value(newValue) {
      this.setValue(newValue);
    },
  },
  methods: {
    setValue(newValue) {
      if (newValue) {
        this.dateTimeValue = moment(newValue).format('YYYY-MM-DD HH:mm');
        this.timeValue = moment(newValue).format('HH:mm');
        this.dateValue = moment(newValue).format('YYYY-MM-DD');
      } else {
        this.timeValue = null;
        this.dateValue = null;
        this.dateTimeValue = null;
      }
    },

    emitValue(newValue, type) {
      if (type === 'DATE') {
        this.dateValue = newValue;
      } else if (type === 'TIME') {
        this.timeValue = newValue;
      }
      if (this.editable && this.dateValue === null && this.timeValue === null) {
        this.$emit('input', null);
      }
      this.dateTimeValue = `${this.dateValue} ${this.timeValue}`;
      if (this.editable && moment(this.dateTimeValue, 'YYYY-MM-DD HH:mm').isValid()
        && this.dateTimeValue.length === 16) {
        this.$emit('input', this.dateTimeValue);
      }
    },
    now() {
      this.dateValue = moment().format('YYYY-MM-DD');
      this.timeValue = moment().format('HH:mm');
    },
  },
  created() {
    this.setValue(this.value);
  },
};
</script>
