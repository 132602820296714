<template>
  <div>
    <entity-configuration-list
      :entity-name="this.$i18n.t('configuration.airportParking')"
      entity-icon="local_parking"
      :framework-components="frameworkComponents" :column-defs="columnDefs" :items="airportParkings"
      @confirm-edit-item="editConfirm"
      @confirm-delete-item="deleteConfirm"
      @confirm-enable-disable-item="enableDisableConfirm"
    >
      <template v-slot:modal-content="{ itemEdit }">
        <airport-parking-edit-card-content :item="itemEdit" />
      </template>
    </entity-configuration-list>
  </div>
</template>
<script>
import {
  AIRPORT_PARKING_CREATE,
  AIRPORT_PARKING_DELETE,
  AIRPORT_PARKING_UPDATE,
  GET_AIRPORT_PARKING,
  AIRPORT_PARKING_DISABLE,
  AIRPORT_PARKING_ENABLE,
} from '@/store/modules/airportParking/airportParkingCatalog';
import EntityConfigurationList from '@/components/configuration/common/EntityConfigurationList.vue';
import AirportParkingEditCardContent from './AirportParkingEditCardContent.vue';

import AgGridRowActionButtonsCellRenderer from '../../ag-grid/cell-renderers/AgGridRowActionButtonsCellRenderer.vue';
import AgGridIconValueOrDashCellRenderer from '../../ag-grid/cell-renderers/AgGridIconValueOrDashCellRenderer.vue';
import AgGridIconCellRenderer from '../../ag-grid/cell-renderers/AgGridIconCellRenderer.vue';
import AgGridToggleCellEditor from '../../ag-grid/cell-editors/AgGridToggleCellEditor.vue';

export default {
  name: 'airport-parking-list',
  components: {
    EntityConfigurationList,
    AirportParkingEditCardContent,
  },
  data() {
    const that = this;
    return {
      frameworkComponents: {
        AgGridIconValueOrDashCellRenderer,
        AgGridToggleCellEditor,
        AgGridRowActionButtonsCellRenderer,
        AgGridIconCellRenderer,
      },
      columnDefs: [
        {
          field: 'code',
          headerValueGetter() {
            return that.$i18n.t('configuration.code');
          },
        },
        {
          field: 'codeInterface',
          headerValueGetter() {
            return that.$i18n.t('configuration.codeInterface');
          },
        },
        {
          field: 'comments',
          headerValueGetter() {
            return that.$i18n.t('configuration.description');
          },
        },
        {
          field: 'remote',
          headerValueGetter() {
            return that.$i18n.t('configuration.remote');
          },
          cellRendererFramework: AgGridIconValueOrDashCellRenderer,
          valueGetter(params) {
            return (params.data.remote) ? 'R' : null;
          },
        },
        {
          field: 'pushback',
          headerValueGetter() {
            return that.$i18n.t('configuration.pushBack');
          },
          cellRendererFramework: AgGridIconValueOrDashCellRenderer,
          valueGetter(params) {
            return (params.data.pushback) ? 'P' : null;
          },
        },
        {
          field: 'inop400Hz',
          headerValueGetter() {
            return that.$i18n.t('configuration.inop400Hz');
          },
          cellRendererFramework: AgGridIconCellRenderer,
          cellRendererParams: {
            icon: 'check',
          },
        },
        {
          headerValueGetter() {
            return that.$i18n.t('configuration.disabled');
          },
          headerName: 'Disabled',
          field: 'deleted',
          cellRendererFramework: AgGridIconCellRenderer,
          cellRendererParams: {
            icon: 'mdi-cancel',
            tooltip: params => (params.data.deleted ? this.$i18n.t('configuration.disabled') : null),
          },
        },
      ],
    };
  },
  computed: {
    airportParkings() {
      return this.$store.getters.getAirportParking;
    },
  },
  methods: {
    getAirportParkings() {
      this.$store.dispatch(GET_AIRPORT_PARKING);
    },
    deleteConfirm(item) {
      this.$store.dispatch(AIRPORT_PARKING_DELETE, item.id);
    },
    editConfirm(item) {
      this.$store.dispatch(item.id ? AIRPORT_PARKING_UPDATE : AIRPORT_PARKING_CREATE, item);
    },
    enableDisableConfirm(item) {
      this.$store.dispatch(item.deleted ? AIRPORT_PARKING_ENABLE : AIRPORT_PARKING_DISABLE, item.id);
    },
  },
  created() {
    this.getAirportParkings();
  },
};
</script>
