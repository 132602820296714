<script>
import Vue from 'vue';
import AgGridParentConnectedFlightsCellEditor from './AgGridParentConnectedFlightsCellEditor.vue';
import { FlightBound } from '../../../shared/model/flightBound';

export default Vue.extend({
  mixins: [AgGridParentConnectedFlightsCellEditor],
  data() {
    return {
      flightDatePropertyName: 'common.flightDate',
      flightDateLocalPropertyName: 'common.flightDateLocal',
      scheduledTimePropertyName: 'common.scheduledTime',
      flightScheduledTimeChanges: [],
      updatedValue: null,
    };
  },
  methods: {
    setNewFlightDate(newFlightDate) {
      const selectedRows = this.getSelectedRowsOrCurrentRow();
      this.setFlightDateChanges(selectedRows, newFlightDate);
      if (this.flightScheduledTimeChanges.length > 0) {
        this.saveFlightDateChanges();
      }
      this.getAndSaveAircraftGround(selectedRows);
    },
    saveFlightDateChanges() {
      this.$store.dispatch(this.saveFlightAction, {
        flightChanges: this.flightScheduledTimeChanges,
        field: this.flightDatePropertyName,
      }).then(() => {
        this.params.api.refreshCells({
          force: true,
          columns: [this.params.column],
        });
      }).catch(error => this.requestErrorCallback(this, error));
    },
    getAndSaveAircraftGround(selectedRows) {
      const aircraftGrounds = selectedRows.filter(c => c.getChildPropertyValue(FlightBound.INBOUND, ['common', 'id']) != null && c.getChildPropertyValue(FlightBound.INBOUND, ['common', 'tripNumber']) == null);
      if (aircraftGrounds.length > 0) {
        this.saveAircraftGround(aircraftGrounds, this.scheduledTimePropertyName, null);
      }
    },
    setFlightDateChanges(selectedRows, newFlightDate) {
      const connectedFlights = selectedRows.filter(c => c.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'id']) != null || (c.getChildPropertyValue(FlightBound.INBOUND, ['common', 'id']) != null && c.getChildPropertyValue(FlightBound.INBOUND, ['common', 'tripNumber']) != null));
      const { flightBound } = this.params.column.colDef.colCustomConfig;

      connectedFlights.forEach((connectedFlight) => {
        const flightId = connectedFlight.getChildPropertyValue(flightBound, ['common', 'id']);
        const scheduledTime = this.$moment(connectedFlight.getChildPropertyValue(flightBound, ['common', 'scheduledTime']));
        const newConnectedFlightProperties = this.getNewConnectedFlightProperties(flightId, flightBound, newFlightDate, scheduledTime);

        this.setNewConnectedFlightsProperties(connectedFlight, newConnectedFlightProperties);
        this.flightScheduledTimeChanges.push(this.buildFlightDateChanges(newConnectedFlightProperties));
      });
    },
    getNewConnectedFlightProperties(flightId, flightBound, newFlightDate, scheduledTime) {
      const newFlightDateWithTheCorrectTime = this.changeTheTimeOfTheFlightDate(newFlightDate, scheduledTime);

      const newFlightDateWithTheCorrectTimeInLocalTimezone = this.getFlightDateLocalFromFlightDate(newFlightDateWithTheCorrectTime);
      return {
        flightId,
        flightBound,
        scheduledTime: newFlightDateWithTheCorrectTime,
        flightDate: newFlightDateWithTheCorrectTime,
        flightDateLocal: newFlightDateWithTheCorrectTimeInLocalTimezone,
      };
    },
    setNewConnectedFlightsProperties(connectedFlight, newConnectedFlightsProperties) {
      const { flightBound } = newConnectedFlightsProperties;
      const { scheduledTime } = newConnectedFlightsProperties;
      const { flightDate } = newConnectedFlightsProperties;
      const { flightDateLocal } = newConnectedFlightsProperties;

      connectedFlight.setChildPropertyValueByBound(flightBound, this.scheduledTimePropertyName, scheduledTime);
      connectedFlight.setChildPropertyValueByBound(flightBound, this.flightDatePropertyName, flightDate);
      connectedFlight.setChildPropertyValueByBound(flightBound, this.flightDateLocalPropertyName, flightDateLocal);
    },
    buildFlightDateChanges(flightDateChanges) {
      const { flightId } = flightDateChanges;
      const { scheduledTime } = flightDateChanges;
      const { flightDate } = flightDateChanges;
      const { flightDateLocal } = flightDateChanges;

      return {
        flightId,
        changes: {
          'common.scheduledTime': scheduledTime,
          'common.flightDate': flightDate,
          'common.flightDateLocal': flightDateLocal.format('YYYY-MM-DD'),
        },
      };
    },
    changeTheTimeOfTheFlightDate(flightDate, scheduledTime) {
      return this.$moment(flightDate).set({
        hour: scheduledTime.hour(),
        minute: scheduledTime.minute(),
      });
    },
    getFlightDateLocalFromFlightDate(flightDate) {
      return this.$moment(flightDate).tz(this.$store.state.tenant.currentTenant.timezone);
    },
  },
});
</script>

<style>

</style>
