<script>
import Vue from 'vue';
import AgGridParentConnectedFlightsCellEditor from './AgGridParentConnectedFlightsCellEditor.vue';
import { FlightBound } from '../../../shared/model/flightBound';

export default Vue.extend({
  mixins: [AgGridParentConnectedFlightsCellEditor],
  data() {
    return {
      flightDatePropertyName: 'common.flightDate',
      flightDateLocalPropertyName: 'common.flightDateLocal',
      scheduledTimePropertyName: 'common.scheduledTime',
      flightScheduledTimeChanges: [],
    };
  },
  methods: {
    setNewScheduledTime(newScheduledTime) {
      const selectedRows = this.getSelectedRowsOrCurrentRow();
      this.setScheduledTimeChanges(selectedRows, newScheduledTime);

      if (this.flightScheduledTimeChanges.length > 0) {
        this.saveScheduledTimeChanges();
      }
      this.getAndSaveAircraftGround(selectedRows);
    },
    saveScheduledTimeChanges() {
      this.$store.dispatch(this.saveFlightAction, {
        flightChanges: this.flightScheduledTimeChanges,
        field: this.scheduledTimePropertyName,
      }).then(() => {
        this.params.api.refreshCells({
          force: true,
          columns: [this.params.column],
        });
      }).catch(error => this.requestErrorCallback(this, error));
    },
    getAndSaveAircraftGround(selectedRows) {
      const aircraftGrounds = selectedRows.filter(c => c.getChildPropertyValue(FlightBound.INBOUND, ['common', 'id']) != null && c.getChildPropertyValue(FlightBound.INBOUND, ['common', 'tripNumber']) == null);
      if (aircraftGrounds.length > 0) {
        this.saveAircraftGround(aircraftGrounds, this.scheduledTimePropertyName, null);
      }
    },
    setScheduledTimeChanges(selectedRows, newScheduledTime) {
      const connectedFlights = selectedRows.filter(c => c.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'id']) != null || (c.getChildPropertyValue(FlightBound.INBOUND, ['common', 'id']) != null && c.getChildPropertyValue(FlightBound.INBOUND, ['common', 'tripNumber']) != null));
      const { flightBound } = this.params.column.colDef.colCustomConfig;

      connectedFlights.forEach((connectedFlight) => {
        const flightId = connectedFlight.getChildPropertyValue(flightBound, ['common', 'id']);
        const oldFlightDate = this.$moment(connectedFlight.getChildPropertyValue(flightBound, ['common', 'flightDate']));
        const oldFlightDateLocal = this.$moment(connectedFlight.getChildPropertyValue(flightBound, ['common', 'flightDateLocal']));
        const newConnectedFlightProperties = this.getNewConnectedFlightProperties(flightId, flightBound, newScheduledTime, oldFlightDate, oldFlightDateLocal);

        this.setNewConnectedFlightsProperties(connectedFlight, newConnectedFlightProperties);
        this.flightScheduledTimeChanges.push(this.buildScheduledTimeChanges(newConnectedFlightProperties));
      });
    },
    getNewConnectedFlightProperties(flightId, flightBound, newScheduledTime, oldFlightDate, oldFlightDateLocal) {
      if (newScheduledTime.isUtc()) {
        const newScheduledTimeInTheCorrectDate = this.changeTheDateOfTheScheduledTime(newScheduledTime, oldFlightDate);
        const newScheduledTimeInTheCorrectDateInLocalTimezone = this.getFlightDateLocalFromScheduleTime(newScheduledTimeInTheCorrectDate);
        return {
          flightId,
          flightBound,
          scheduledTime: newScheduledTimeInTheCorrectDate,
          flightDate: newScheduledTimeInTheCorrectDate,
          flightDateLocal: newScheduledTimeInTheCorrectDateInLocalTimezone,
        };
      }
      const newScheduledTimeInTheCorrectDateInLocalTimezone = this.changeTheDateOfTheScheduledTime(newScheduledTime, oldFlightDateLocal);
      const newScheduledTimeInTheCorrectDate = this.$moment(newScheduledTimeInTheCorrectDateInLocalTimezone).utc();
      return {
        flightId,
        flightBound,
        scheduledTime: newScheduledTimeInTheCorrectDateInLocalTimezone,
        flightDate: newScheduledTimeInTheCorrectDate,
        flightDateLocal: newScheduledTimeInTheCorrectDateInLocalTimezone,
      };
    },
    setNewConnectedFlightsProperties(connectedFlight, newConnectedFlightsProperties) {
      const { flightBound } = newConnectedFlightsProperties;
      const { scheduledTime } = newConnectedFlightsProperties;
      const { flightDate } = newConnectedFlightsProperties;
      const { flightDateLocal } = newConnectedFlightsProperties;

      connectedFlight.setChildPropertyValueByBound(flightBound, this.scheduledTimePropertyName, scheduledTime);
      connectedFlight.setChildPropertyValueByBound(flightBound, this.flightDatePropertyName, flightDate);
      connectedFlight.setChildPropertyValueByBound(flightBound, this.flightDateLocalPropertyName, flightDateLocal);
    },
    buildScheduledTimeChanges(scheduledTimeChanges) {
      const { flightId } = scheduledTimeChanges;
      const { scheduledTime } = scheduledTimeChanges;
      const { flightDate } = scheduledTimeChanges;
      const { flightDateLocal } = scheduledTimeChanges;

      return {
        flightId,
        changes: {
          'common.scheduledTime': scheduledTime,
          'common.flightDate': flightDate,
          'common.flightDateLocal': flightDateLocal.format('YYYY-MM-DD'),
        },
      };
    },
    changeTheDateOfTheScheduledTime(newScheduledTime, flightDate) {
      return this.$moment(newScheduledTime).set({
        year: flightDate.year(),
        month: flightDate.month(),
        date: flightDate.date(),
      });
    },
    getFlightDateLocalFromScheduleTime(scheduledTime) {
      return this.$moment(scheduledTime).tz(this.$store.state.tenant.currentTenant.timezone);
    },
  },
});
</script>

<style>

</style>
