<template>
  <ag-grid-template-cell-renderer :params="params">
    <v-tooltip bottom v-if="params.value && (params.value.tooltip || params.value.fieldBlockedIntegration)">
      <template slot="activator">
        <div v-bind:class="{ 'fieldBlockedClass': this.params.value.fieldBlockedIntegration }">
          <span :style="styleObject">{{params.value.value}}<sup>{{params.value.sup}}</sup></span>
          <v-icon small v-if="params.value.icon" :style="styleIcon">{{params.value.icon}}</v-icon>
        </div>
      </template>
      <div v-if="params.value.tooltip">
        <span>{{params.value.tooltip}}</span>
      </div>
      <div v-if="params.value.fieldBlockedIntegration">
        <span>{{$t("common.fieldBlockedForIntegrations")}}</span>
      </div>
    </v-tooltip>
    <span v-if="params.value && !params.value.tooltip" :style="styleObject">{{params.value.value}}<sup>{{params.value.sup}}</sup></span>
    <v-icon small v-if="params.value && !params.value.tooltip && params.value.icon" :style="styleIcon">{{params.value.icon}}</v-icon>
  </ag-grid-template-cell-renderer>
</template>

<script>
import Vue from 'vue';
import AgGridTemplateCellRenderer from './templates/AgGridTemplateCellRenderer.vue';

export default Vue.extend({
  components: {
    AgGridTemplateCellRenderer,
  },
  computed: {
    styleObject() {
      return {
        color: (this.params && this.params.value && this.params.value.color) ? this.params.value.color : '',
      };
    },
    styleIcon() {
      return {
        color: (this.params && this.params.value && this.params.value.colorIcon) ? this.params.value.colorIcon : '',
      };
    },
  },
});
</script>
