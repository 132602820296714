import { render, staticRenderFns } from "./AdminSchedulingImportsBody.vue?vue&type=template&id=5179c554"
import script from "./AdminSchedulingImportsBody.vue?vue&type=script&lang=js"
export * from "./AdminSchedulingImportsBody.vue?vue&type=script&lang=js"
import style0 from "./AdminSchedulingImportsBody.vue?vue&type=style&index=0&id=5179c554&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports