<template>
  <div class="mobile-flights-content">
    <v-layout class="flex justify-center" column style="height: calc(100vh - 200px)">
      <v-flex style="overflow: auto">
        <v-data-table :items="items" class="elevation-1 mobile-flights-table" hide-actions :loading="isLoading"
          :dark="this.$vuetify.dark">
          <template v-slot:no-data>
            <p v-if="!isLoading" class="subheading text-xs-center ma-0">
              {{ $t('ticketingMobile.noData') }}
            </p>
            <p v-else class="subheading text-xs-center ma-0"></p>
          </template>
          <template v-slot:progress>
            <p class="subheading text-xs-center ma-0">
              {{ $t('common.loading') }}
            </p>
          </template>
          <template v-slot:headers>
            <tr class="mobile-table-headers-fixed" v-if="items.length > 0">
              <th class="pa-0"><v-icon>confirmation_number</v-icon></th>
              <th class="pa-0"><v-icon>schedule</v-icon></th>
              <th class="pa-0"><v-icon>text_snippet</v-icon></th>
              <th class="pa-0"><v-icon>attach_money</v-icon></th>
              <th class="pa-0">{{ $t('dispatcher.dataHeaderStatus') }}</th>
              <th class="pa-0"></th>
            </tr>
          </template>
          <template v-slot:items="props">
            <tr class="flights-data">
              <td class="text-xs-center pa-0">
                {{ props.item.serialNumber }}9000001
              </td>
              <td class="text-xs-center pa-0">
                {{ formattedTime(props.item.ticketingDate) }}
              </td>
              <td class="text-xs-center pa-0">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">
                      {{ truncateText(props.item.airlineFareName, 13, '...') }}
                    </div>
                  </template>
                  <span>{{ props.item.airlineFareName }}</span>
                </v-tooltip>
              </td>
              <td class="text-xs-center pa-0">
                {{
          getCurrency(
            props.item.realQuantity,
            props.item.airlineFareCurrency
          )
        }}
              </td>
              <td class="text-xs-center pa-0">
                <flight-status :status="props.item.status" />
              </td>
              <td class="text-xs-center pa-0">
                <div v-if="props.item.status != null">
                  <v-icon color="secondary" @click.stop="showItem(props.item)" class="mr-4">
                    remove_red_eye
                  </v-icon>

                  <v-tooltip left v-if="hasPermissions(rolePermission) && isCancellable(props.item) && !userIsCashier">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon color="error" @click.stop="cancelItem(props.item)" v-bind="attrs" v-on="on">
                        dnd_forwardslash
                      </v-icon>
                    </template>
                    <span>{{ $t('ticketing.cancelTickets') }}</span>
                  </v-tooltip>
                </div>
                <div v-else-if="hasPermissions(rolePermission)">
                  <v-icon color="secondary" @click.stop="editItem(props.item)" class="mr-4">
                    edit
                  </v-icon>

                  <v-icon v-if="!userIsCashier" color="error" @click.stop="deleteItem(props.item)">
                    delete
                  </v-icon>
                </div>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import FlightStatus from '../../flights/FlightStatus.vue';
import StoreSubscribable from '../../mixins/StoreSubscribable.vue';
import AgGridRowActionButtonsTicketingCellRenderer from '../../ag-grid/cell-renderers/AgGridRowActionButtonsTicketingCellRenderer.vue';
import RolePermissions from '../../mixins/RolePermissions.vue';
import { TicketingStatus } from '../model/ticketingStatus';
import { ROLE_CASHIER_TICKETING } from '../../../constants/roles';

export default {
  name: 'ticketing-mobile-list-table',
  mixins: [StoreSubscribable, RolePermissions],
  components: {
    FlightStatus,
    AgGridRowActionButtonsTicketingCellRenderer,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    items() {
      return this.$store.state.ticketingMobile.flightsTicketing;
    },
    isLoading() {
      return this.$store.state.loading;
    },
    rolePermission() {
      return `${this.$store.state.ticketing.ticketingUserRole}_WR`;
    },
    userIsCashier() {
      return this.$store.state.ticketing.ticketingUserRole === ROLE_CASHIER_TICKETING;
    },
  },
  methods: {
    deleteItem(item) {
      this.$emit('delete-item', item);
    },
    editItem(item) {
      this.$emit('edit-item', item);
    },
    showItem(item) {
      this.$emit('show-item', item);
    },
    cancelItem(item) {
      this.$emit('cancel-item', item);
    },
    formattedTime(time) {
      let formattedTime = '';
      if (time) {
        formattedTime = this.$moment(time).format('DD/MM/YY');
      }
      return formattedTime;
    },
    truncateText(text, length, clamp) {
      const clampFinal = clamp || '...';
      return text.length > length ? text.slice(0, length) + clampFinal : text;
    },
    getCurrency(value, currency) {
      return new Intl.NumberFormat(
        this.$store.getters.getCurrencies.find(p => p.id === currency).locale,
        { style: 'currency', currency },
      ).format(value);
    },
    isCancellable(params) {
      return params.status !== TicketingStatus.CANCELLED.description;
    },
  },
};
</script>
