<template>
  <v-layout row justify-center>
    <v-dialog v-model="dialog" persistent max-width="50%">
      <v-card :dark="this.$vuetify.dark" :color="this.$vuetify.dark ? 'bggrey_dark' : 'bggrey'">
        <v-toolbar dark color="primary">
          <v-icon>airplanemode_inactive</v-icon>
          <v-toolbar-title>{{$t("messages.disassociateFlightConfirmTitle")}}</v-toolbar-title>
        </v-toolbar>

        <loading-component />

        <v-card-text>
          {{$t("messages.disassociateFlightConfirmAlert")}}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn flat color="primary" @click.native="close()">{{$t("common.close")}}</v-btn>
          <v-btn color="error" depressed @click.native="confirm()" :disabled="loading">
            {{$t("common.confirm")}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>
<script>
import LoadingComponent from '../../layout/LoadingComponent.vue';
import { REQUEST_ERROR, REQUEST_START, REQUEST_SUCCESS } from '../../../store/store';
import { NOTIFICATION_SHOW } from '../../../store/modules/notifications';

export default {
  name: 'flight-message-disassociate-flight-modal',
  components: {
    LoadingComponent,
  },
  props: {
    dialog: { type: Boolean, required: true },
    flightMessage: { type: Object, required: true },
  },
  data() {
    return {

    };
  },
  computed: {
    loading() {
      return this.$store.state.loading;
    },
  },
  methods: {
    close(result) {
      this.$emit('close', result);
    },
    confirm() {
      this.$store.commit(REQUEST_START);
      this.$http.put('messages/unlink', this.flightMessage)
        .then(() => {
          this.close({ doFind: true });
          this.$store.commit(NOTIFICATION_SHOW, {
            message: this.$i18n.t('messages.disassociateFlightSuccess'),
            color: 'success',
          });
          this.$store.commit(REQUEST_SUCCESS);
        })
        .catch((err) => {
          this.close();
          this.$store.commit(NOTIFICATION_SHOW, {
            message: this.$i18n.t('notifications.errorNotification'),
            color: 'error',
          });
          this.$store.commit(REQUEST_ERROR, err);
        });
    },
  },
};
</script>
