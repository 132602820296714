<template>
  <div>
    <v-flex v-flex-none>
      <airline-select :ref="'refComp'" v-on:keydown.native="keyEvent" v-char-length.clearable="4" v-model="airlineIdUpdated"></airline-select>
    </v-flex>
  </div>
</template>

<script>
import Vue from 'vue';
import AirlineSelect from '../../../airline/AirlineSelect.vue';
import AgGridParentConnectedFlightsCellEditor from '../mixins/AgGridParentConnectedFlightsCellEditor.vue';

export default Vue.extend({
  mixins: [AgGridParentConnectedFlightsCellEditor],
  components: {
    AirlineSelect,
  },
  data() {
    return {
      airlineIdOriginal: null,
      airlineIdUpdated: null,
      flight: {},
    };
  },
  methods: {
    getValue() {
      this.unmarkCellsSelectedAsEdit();

      if (this.airlineIdOriginal !== this.airlineIdUpdated) {
        const airline = this.$store.getters.getAirlineById(this.airlineIdUpdated);
        const iff = (condition, then, otherwise) => (condition ? then : otherwise);
        const code = typeof (airline) !== 'undefined' ? iff(airline.iataCode != null, airline.iataCode, iff(airline.icaoCode != null, airline.icaoCode, undefined)) : undefined;

        const that = this;

        this.saveFlightChangesFromValue((s) => {
          s.setChildPropertyValueByBound(that.params.column.colDef.colCustomConfig.flightBound, 'common.airlineOperationId', that.airlineIdUpdated);
          s.setChildPropertyValueByBound(that.params.column.colDef.colCustomConfig.flightBound, 'common.airlineOperationIataCode', code);
        }, 'Airline', this.airlineIdOriginal, this.airlineIdUpdated);
      }
      return this.params.node.data;
    },
  },
  created() {
    const schedulingFlight = this.params.node.data;
    this.flight = schedulingFlight.getBound(this.params.column.colDef.colCustomConfig.flightBound);
    this.airlineIdOriginal = this.flight.airlineOperationId;
    this.airlineIdUpdated = this.airlineIdOriginal;

    this.markCellsSelectedAsEdit();
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$refs.refComp) {
        this.$refs.refComp.$refs.refComp.focus();
      }
    });
  },
});
</script>

<style>
  .airline-cell.ag-cell-inline-editing {
    min-width: 100px;
  }
</style>
