import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"persistent":"","content-class":_vm.isContentVisible ? 'entity-configuration-modal-edit modal-common' : 'entity-configuration-modal-edit modal-common hide-title'},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,{attrs:{"dark":this.$vuetify.dark,"color":this.$vuetify.dark ? 'bggrey_dark' : 'bggrey'}},[_c(VToolbar,{staticClass:"ToolbarDialogEditor",attrs:{"dark":"","color":"primary"}},[(_vm.icon)?_c(VIcon,[_vm._v(_vm._s(_vm.icon))]):_vm._e(),_c(VToolbarTitle,[_vm._v(_vm._s(_vm.textTitle))]),_c(VSpacer),_c(VBtn,{attrs:{"icon":""},on:{"click":_vm.toggleContentVisibility}},[_c(VIcon,[_vm._v(_vm._s(_vm.isContentVisible ? 'mdi-chevron-up' : 'mdi-chevron-down'))])],1)],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isContentVisible),expression:"isContentVisible"}]},[_c('loading-component'),_c(VForm,{ref:"form",staticClass:"editSlot",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_vm._t("default",function(){return [_c(VFlex,{attrs:{"xs7":"","offset-xs5":"","offset-md5":"","offset-lg5":""}},[_vm._v(" "+_vm._s(_vm.$t("configuration.noContent"))+" ")])]})],2),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"flat":"","color":"primary"},nativeOn:{"click":function($event){return _vm.reject()}}},[_vm._v(_vm._s(_vm.$t("common.cancel")))]),_c(VBtn,{directives:[{name:"show",rawName:"v-show",value:(_vm.hasPermissions(_vm.rolePermission)),expression:"hasPermissions(rolePermission)"}],attrs:{"color":"error","depressed":"","disabled":!_vm.valid},nativeOn:{"click":function($event){return _vm.confirm()}}},[_vm._v(_vm._s(_vm.$t("common.save")))])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }