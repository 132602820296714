<template>
  <v-layout row>
    <filter-checkbox v-model="value.opened" :label="this.$i18n.t('contingencies.opened')" v-char-length="6" @input="$emit('input', value)"/>
    <filter-checkbox v-model="value.closed" :label="this.$i18n.t('contingencies.closed')" v-char-length="6" @input="$emit('input', value)"/>
  </v-layout>
</template>

<script>
import FilterCheckbox from './FilterCheckbox.vue';

export default {
  name: 'contingency-status',
  components: { FilterCheckbox },
  props: ['value'],
};
</script>
