<template>
  <div>
    <entity-configuration-list
      :entity-name="this.$i18n.t('configuration.flightWeighting')"
      entity-icon="tune"
      :framework-components="frameworkComponents" :column-defs="columnDefs" :items="flightWeighting"
      @confirm-edit-item="editConfirm"
      @confirm-delete-item="deleteConfirm"
    >
    <template v-slot:modal-content="{ itemEdit }">
      <flight-weighting-edit-card-content :item="itemEdit" />
      </template>
    </entity-configuration-list>
  </div>
</template>
<script>
import {
  GET_FLIGHT_WEIGHTING,
  FLIGHT_WEIGHTING_CREATE,
  FLIGHT_WEIGHTING_DELETE,
  FLIGHT_WEIGHTING_UPDATE,
} from '@/store/modules/flightWeighting/flightWeightingCatalog';
import EntityConfigurationList from '@/components/configuration/common/EntityConfigurationList.vue';
import FlightWeightingEditCardContent from './FlightWeightingEditCardContent.vue';
import AgGridIconCheckOrIconClearCellRenderer from '../../ag-grid/cell-renderers/AgGridIconCheckOrIconClearCellRenderer.vue';
import AgGridToggleCellEditor from '../../ag-grid/cell-editors/AgGridToggleCellEditor.vue';
import AgGridRowActionButtonsCellRenderer from '../../ag-grid/cell-renderers/AgGridRowActionButtonsCellRenderer.vue';

export default {
  name: 'FlightWeightingList',
  components: {
    EntityConfigurationList,
    FlightWeightingEditCardContent,
  },
  data() {
    const that = this;
    return {
      frameworkComponents: {
        AgGridIconCheckOrIconClearCellRenderer,
        AgGridToggleCellEditor,
        AgGridRowActionButtonsCellRenderer,
      },
      columnDefs: [
        {
          field: 'airlineCode',
          headerValueGetter() {
            return that.$i18n.t('common.airline');
          },
          sort: 'asc',
        },
        {
          field: 'aircraftTypeCode',
          headerValueGetter() {
            return that.$i18n.t('configuration.aircraftType');
          },
        },
        {
          field: 'handlingTypeCode',
          headerValueGetter() {
            return that.$i18n.t('configuration.handlingType');
          },
        },
        {
          field: 'total',
          headerValueGetter() {
            return that.$i18n.t('flightsWeighting.totalWeighting');
          },
        },
        {
          field: 'ramp',
          headerValueGetter() {
            return that.$i18n.t('flightsWeighting.rampWeighting');
          },
        },
        {
          field: 'passage',
          headerValueGetter() {
            return that.$i18n.t('flightsWeighting.passageWeighting');
          },
        },
        {
          field: 'operations',
          headerValueGetter() {
            return that.$i18n.t('flightsWeighting.operationWeighting');
          },
        },
      ],
    };
  },
  computed: {
    flightWeighting() {
      return this.$store.getters.getFlightWeighting;
    },
  },
  methods: {
    getFlightWeighting() {
      this.$store.dispatch(GET_FLIGHT_WEIGHTING);
    },
    deleteConfirm(item) {
      this.$store.dispatch(FLIGHT_WEIGHTING_DELETE, item.id);
    },
    editConfirm(item) {
      this.$store.dispatch(item.id ? FLIGHT_WEIGHTING_UPDATE : FLIGHT_WEIGHTING_CREATE, item);
    },
  },
  created() {
    this.getFlightWeighting();
  },
};
</script>
