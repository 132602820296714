<template>
  <v-card-text>
      <v-layout wrap justify-start>
        <v-flex xs6 class="input-airport">
          <v-text-field :label="$t('directory.airportName')" v-model="item.title" :rules="[v => !!v || 'Airport name is required']"/>
        </v-flex>
        <v-flex xs6 class="input-airport">
          <v-text-field :label="$t('directory.business')" v-model="item.subtitle"/>
        </v-flex>
        <v-flex xs3 class="input-airport">
          <v-text-field :label="$t('directory.phone')" v-model="item.contact.phone"/>
        </v-flex>
        <v-flex xs2 class="input-airport">
          <v-text-field :label="$t('directory.mobilePhone')" v-model="item.contact.phoneMobile"/>
        </v-flex>
        <v-flex xs2 class="input-airport">
          <v-text-field :label="$t('directory.fax')" v-model="item.contact.fax"/>
        </v-flex>
        <v-flex xs5 class="input-airport">
          <v-text-field :label="$t('directory.email')" v-model="item.contact.mail"/>
        </v-flex>
        <v-flex xs7 class="input-airport">
          <v-text-field :label="$t('directory.address')" v-model="item.contact.addressStreet"/>
        </v-flex>
        <v-flex xs5 class="input-airport">
          <v-text-field :label="$t('directory.city')" v-model="item.contact.addressCity"/>
        </v-flex>
        <v-flex xs5 class="input-airport">
          <v-text-field :label="$t('directory.region')" v-model="item.contact.addressRegion"/>
        </v-flex>
        <v-flex xs2 class="input-airport">
          <v-text-field :label="$t('directory.postalCode')" v-model="item.contact.addressPostalCode"/>
        </v-flex>
        <v-flex xs5 class="input-airport">
          <v-text-field :label="$t('directory.country')" v-model="item.contact.addressCountry"/>
        </v-flex>
        <v-flex xs12 class="input-airport">
          <v-textarea :label="$t('common.comments')" hint="Hint text" v-model="item.contact.description"/>
        </v-flex>
        <v-flex xs12 class="input-airport">
          <directory-image-uploader @add-directory-file-image="setImage"/>
        </v-flex>
      </v-layout>
  </v-card-text>
</template>

<script>
import DirectoryImageUploader from './DirectoryImageUploader.vue';

export default {
  name: 'AirportEditModalContent',
  props: {
    item: { type: Object, required: true },
  },
  components: {
    DirectoryImageUploader,
  },
  methods: {
    setImage(image) {
      this.item.contact.imageData = image.substr(image.indexOf('base64,') + 'base64,'.length);
    },
  },
};
</script>
<style lang="scss" scoped>
  .input-airport {
    padding: 0 1em;
  }
</style>
