import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListTile } from 'vuetify/lib/components/VList';
import { VListTileAction } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VMenu,{attrs:{"bottom":"","left":"","close-on-content-click":false},model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c(VTooltip,{attrs:{"slot":"activator","left":""},slot:"activator"},[_c(VBtn,{attrs:{"slot":"activator","small":"","flat":""},slot:"activator"},[_c(VIcon,{attrs:{"left":""}},[_vm._v("desktop_windows")]),_vm._v(" "+_vm._s(_vm.currentDesk)+" ")],1),_c('span',[_vm._v(_vm._s(_vm.$t("operations.changeCurrentDesk")))])],1),_c(VCard,[_c(VList,[_c(VListTile,[_c(VListTileAction,[_c(VTextField,{attrs:{"autofocus":"","label":this.$i18n.t('operations.currentDesk'),"prepend-icon":"desktop_windows"},model:{value:(_vm.currentDeskValue),callback:function ($$v) {_vm.currentDeskValue=$$v},expression:"currentDeskValue"}})],1)],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","flat":""},nativeOn:{"click":function($event){return _vm.cancel.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t("operations.cancel")))]),_c(VBtn,{attrs:{"color":"primary","depressed":""},nativeOn:{"click":function($event){return _vm.save.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t("operations.save")))])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }