import DayOfWeek from '../../scheduling/model/daysOfWeek';
import ContingencyStatusModel from './contingencyStatus';
import ContingencyInvoiceModel from './contingencyInvoice';

export default class ContingencyFilter {
  constructor(dateFrom, dateTo, unitOfTime, daysOfWeek, contingencyStatus, timezoneDate, contingencyInvoice) {
    this.dateFrom = dateFrom;
    this.dateTo = dateTo;
    this.unitOfTime = unitOfTime;
    this.daysOfWeek = daysOfWeek;
    this.contingencyStatus = contingencyStatus;
    this.timezoneDate = timezoneDate;
    this.contingencyInvoice = contingencyInvoice;
  }

  static get createEmptyFilter() {
    return new ContingencyFilter(null, null, 'day', new DayOfWeek(), new ContingencyStatusModel(), null, new ContingencyInvoiceModel());
  }
}
