<template>
  <div>
    <tallying-cash-list-filter @changeShowFilter="changeShowFilter" />
    <tallying-cash-list-table :items="items" :itemTotal="itemTotal" :isFilterDisplayed="filterDisplayed"/>
  </div>
</template>
<script>
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import TallyingCashListFilter from '../components/ticketingReports/tallyingCashTicketingReports/TallyingCashListFilter.vue';
import TallyingCashListTable from '../components/ticketingReports/tallyingCashTicketingReports/TallyingCashListTable.vue';

export default {
  name: 'tallying-cash-ticketing-report',
  components: {
    Breadcrumbs,
    TallyingCashListFilter,
    TallyingCashListTable,
  },
  data() {
    return {
      filterDisplayed: true,
    };
  },
  computed: {
    items() {
      return this.$store.state.ticketingReports.tallyingCash.tallyingCashResults;
    },
    itemTotal() {
      const total = {
        totalTickets: `${this.$i18n.t('ticketing.totalAmountTickets')}: `,
        totalTicketsAmount: `${this.$i18n.t('ticketing.totalCashQuantity')} ${this.getTicketTotalCash()} - ${this.$i18n.t('ticketing.totalCardQuantity')} ${this.getTicketTotalCard()}`,
        totalCompany: `${this.$i18n.t('ticketing.totalAmountCompany')}`,
        totalCompanyAmount: `${this.$i18n.t('ticketing.totalCashQuantity')} ${this.getCompanyTotalCash()} - ${this.$i18n.t('ticketing.totalCardQuantity')} ${this.getCompanyTotalCard()}`,
        totalCashMismatch: `${this.$i18n.t('ticketing.totalCashMismatch')}`,
        totalCashMismatchAmount: `${this.$i18n.t('ticketing.totalCashQuantity')} ${this.getTotalMismatchCash()} - ${this.$i18n.t('ticketing.totalCardQuantity')} ${this.getTotalMismatchCard()}`,
      };

      return [total];
    },
  },
  methods: {
    changeShowFilter(dispayled) {
      this.filterDisplayed = dispayled;
    },
    getSetCurrencies(paymentType) {
      return new Set(this.items.filter(tc => tc.paymentType === paymentType).filter(t => t.currency != null).map(t => t.currency));
    },
    getTicketTotalCash() {
      const that = this;
      const currencies = this.getSetCurrencies('CASH');

      let result = '';

      currencies.forEach((currency) => {
        const sumCurrency = that.sum(that.getTotalTicketsFilterByPaymentTypeAndCurrency(that, 'CASH', currency));
        if (sumCurrency != null && sumCurrency !== 0) {
          result = result !== '' ? `${result}  ${that.getParseValueCurrency(currency, sumCurrency)}` : ` ${that.getParseValueCurrency(currency, sumCurrency)}`;
        }
      });

      return result === '' ? ' 0' : result;
    },
    getTicketTotalCard() {
      const that = this;
      const currencies = this.getSetCurrencies('CREDIT_CARD');

      let result = '';

      currencies.forEach((currency) => {
        const sumCurrency = that.sum(that.getTotalTicketsFilterByPaymentTypeAndCurrency(that, 'CREDIT_CARD', currency));
        if (sumCurrency != null && sumCurrency !== 0) {
          result = result !== '' ? `${result}  ${that.getParseValueCurrency(currency, sumCurrency)}` : ` ${that.getParseValueCurrency(currency, sumCurrency)}`;
        }
      });

      return result === '' ? ' 0' : result;
    },
    getCompanyTotalCash() {
      const that = this;
      const currencies = this.getSetCurrencies('CASH');

      let result = '';

      currencies.forEach((currency) => {
        const sumCurrency = that.sum(that.getTotalCompanyFilterByPaymentTypeAndCurrency(that, 'CASH', currency));
        if (sumCurrency != null && sumCurrency !== 0) {
          result = result !== '' ? `${result}  ${that.getParseValueCurrency(currency, sumCurrency)}` : ` ${that.getParseValueCurrency(currency, sumCurrency)}`;
        }
      });

      return result === '' ? ' 0' : result;
    },
    getCompanyTotalCard() {
      const that = this;
      const currencies = this.getSetCurrencies('CREDIT_CARD');

      let result = '';

      currencies.forEach((currency) => {
        const sumCurrency = that.sum(that.getTotalCompanyFilterByPaymentTypeAndCurrency(that, 'CREDIT_CARD', currency));
        if (sumCurrency != null && sumCurrency !== 0) {
          result = result !== '' ? `${result}  ${that.getParseValueCurrency(currency, sumCurrency)}` : ` ${that.getParseValueCurrency(currency, sumCurrency)}`;
        }
      });

      return result === '' ? ' 0' : result;
    },
    getTotalMismatchCash() {
      const that = this;
      const currencies = this.getSetCurrencies('CASH');

      let result = '';

      currencies.forEach((currency) => {
        const sumCurrency = that.sum(that.getTotalCashMismatchFilterByPaymentTypeAndCurrency(that, 'CASH', currency));
        if (sumCurrency != null && sumCurrency !== 0) {
          result = result !== '' ? `${result}  ${that.getParseValueCurrency(currency, sumCurrency)}` : ` ${that.getParseValueCurrency(currency, sumCurrency)}`;
        }
      });

      return result === '' ? ' 0' : result;
    },
    getTotalMismatchCard() {
      const that = this;
      const currencies = this.getSetCurrencies('CREDIT_CARD');

      let result = '';

      currencies.forEach((currency) => {
        const sumCurrency = that.sum(that.getTotalCashMismatchFilterByPaymentTypeAndCurrency(that, 'CREDIT_CARD', currency));
        if (sumCurrency != null && sumCurrency !== 0) {
          result = result !== '' ? `${result}  ${that.getParseValueCurrency(currency, sumCurrency)}` : ` ${that.getParseValueCurrency(currency, sumCurrency)}`;
        }
      });

      return result === '' ? ' 0' : result;
    },
    sum(array) {
      return array.reduce((a, b) => a + b, 0);
    },
    getParseValueCurrency(currency, value) {
      return new Intl.NumberFormat(this.$store.getters.getCurrencies.find(p => p.id === currency).locale, { style: 'currency', currency }).format(parseFloat(value));
    },
    getTotalCompanyFilterByPaymentTypeAndCurrency(that, paymentType, currency) {
      return that.items.filter(tc => tc.paymentType === paymentType)
        .filter(ticket => ticket.currency === currency)
        .filter(tckt => tckt.totalCompany != null)
        .map(t => t.totalCompany);
    },
    getTotalTicketsFilterByPaymentTypeAndCurrency(that, paymentType, currency) {
      return that.items.filter(tc => tc.paymentType === paymentType)
        .filter(ticket => ticket.currency === currency)
        .filter(tckt => tckt.totalTickets != null)
        .map(t => t.totalTickets);
    },
    getTotalCashMismatchFilterByPaymentTypeAndCurrency(that, paymentType, currency) {
      return that.items.filter(tc => tc.paymentType === paymentType)
        .filter(ticket => ticket.currency === currency)
        .filter(tckt => tckt.cashMismatch != null)
        .map(t => t.cashMismatch);
    },
  },
};
</script>
