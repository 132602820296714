import { VCardText } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('ag-grid-dialog-template-show',{attrs:{"is-dialog-open":_vm.isDialogOpen},on:{"cancel":_vm.cancel},scopedSlots:_vm._u([{key:"header-icon",fn:function(){return [_c(VIcon,[_vm._v("confirmation_number")])]},proxy:true},{key:"header",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('ticketing.statusModalTitle'))+" ")]},proxy:true},{key:"default",fn:function(){return [_c(VCardText,[_c(VContainer,{attrs:{"grid-list-md":""}},[_c(VLayout,[_c(VFlex,{attrs:{"xs2":""}},[_c('date-input',{attrs:{"locale":_vm.$i18n.locale,"label":_vm.$t('common.date'),"disabled":""},model:{value:(_vm.params.cancellationDate),callback:function ($$v) {_vm.$set(_vm.params, "cancellationDate", $$v)},expression:"params.cancellationDate"}})],1),_c(VFlex,{attrs:{"xs10":""}},[_c(VTextField,{attrs:{"value":_vm.params.cancellationReason,"label":_vm.$t('scheduling.cancellationFlightReason'),"readonly":""}})],1)],1)],1)],1)]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }