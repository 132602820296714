import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListTile } from 'vuetify/lib/components/VList';
import { VListTileAction } from 'vuetify/lib/components/VList';
import { VListTileContent } from 'vuetify/lib/components/VList';
import { VListTileSubTitle } from 'vuetify/lib/components/VList';
import { VListTileTitle } from 'vuetify/lib/components/VList';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VList,[(_vm.item.role)?_c(VListTile,[_c(VListTileAction,[_c(VIcon,{attrs:{"color":"primary"}},[_vm._v("person")])],1),_c(VListTileContent,[_c(VListTileTitle,[_vm._v(_vm._s(_vm.item.role))]),_c(VListTileSubTitle,[_vm._v("Cargo")])],1)],1):_vm._e(),(_vm.item.contact.phone)?_c(VListTile,[_c(VListTileAction,[_c(VIcon,{attrs:{"color":"primary"}},[_vm._v("phone")])],1),_c(VListTileContent,[_c(VListTileTitle,[_c('a',{attrs:{"href":`tel:${_vm.item.contact.phone}`}},[_vm._v(_vm._s(_vm.item.contact.phone))])]),_c(VListTileSubTitle,[_vm._v("Phone")])],1)],1):_vm._e(),(_vm.item.contact.phoneMobile)?_c(VListTile,[_c(VListTileAction,[_c(VIcon,{attrs:{"color":"primary"}},[_vm._v("phone_iphone")])],1),_c(VListTileContent,[_c(VListTileTitle,[_c('a',{attrs:{"href":`tel:${_vm.item.contact.phoneMobile}`}},[_vm._v(_vm._s(_vm.item.contact.phoneMobile))])]),_c(VListTileSubTitle,[_vm._v("Mobile Phone")])],1)],1):_vm._e(),(_vm.item.contact.fax)?_c(VListTile,[_c(VListTileAction,[_c(VIcon,{attrs:{"color":"primary"}},[_vm._v("print")])],1),_c(VListTileContent,[_c(VListTileTitle,[_vm._v(_vm._s(_vm.item.contact.fax))]),_c(VListTileSubTitle,[_vm._v("Fax")])],1)],1):_vm._e(),(_vm.item.contact.mail)?_c(VListTile,[_c(VListTileAction,[_c(VIcon,{attrs:{"color":"primary"}},[_vm._v("mail")])],1),_c(VListTileContent,[_c(VListTileTitle,[_c('a',{attrs:{"href":`mailto:${_vm.item.contact.mail}`}},[_vm._v(_vm._s(_vm.item.contact.mail))])]),_c(VListTileSubTitle,[_vm._v("E-mail")])],1)],1):_vm._e(),(_vm.hasAddress)?_c(VListTile,[_c(VListTileAction,[_c(VIcon,{attrs:{"color":"primary"}},[_vm._v("location_on")])],1),_c(VListTileContent,[_c(VListTileTitle,[_vm._v(_vm._s(_vm.item.contact.addressPostalCode)+" "+_vm._s(_vm.item.contact.addressStreet)+" "+_vm._s(_vm.item.contact.addressCity))]),_c(VListTileSubTitle,[_vm._v(_vm._s(_vm.item.contact.addressRegion)+". "+_vm._s(_vm.item.contact.addressCountry))])],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }