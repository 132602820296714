<template>
  <deliveries-list-table :items="items" :isFilterDisplayed="this.isFilterDisplayed" @delete-item="deleteItem" />
</template>
<script>
import DeliveriesListTable from './DeliveriesListTable.vue';
import { DELIVERY_FILTER_RESET, FIND_DELIVERIES } from '../../../../store/modules/lostAndFound/delivery';

export default {
  name: 'deliveries-list-container',
  components: {
    DeliveriesListTable,
  },
  props: {
    isFilterDisplayed: { type: Boolean, required: false, default: false },
  },
  mounted() {
    this.$store.commit(DELIVERY_FILTER_RESET);
    this.$store.dispatch(FIND_DELIVERIES);
  },
  computed: {
    items() {
      return this.$store.state.delivery.deliveries;
    },
  },
  methods: {
    deleteItem(item) {
      this.$emit('delete-item', item);
    },
  },
};
</script>
<style lang="scss">


</style>
