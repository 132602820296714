<template>
  <div>
    <v-autocomplete
      placeholder="..."
      :value="value"
      :label="label"
      :items="items"
      :filter="customFilter"
      :clearable="true"
      :rules="rules"
      :disabled="disabled"
      :readonly="readonly"
      :multiple="multiple"
      @input="emitInput"
      :ref="refComp"
    >
    </v-autocomplete>
  </div>
</template>

<script>

export default {
  props: {
    value: { required: true },
    label: { type: String },
    rules: { type: Array },
    disabled: { type: Boolean },
    readonly: { type: Boolean },
    multiple: {
      type: Boolean,
      default: false,
    },
    refComp: {
      type: String,
      required: false,
      default: 'refComp',
    },
    type: { type: String },
  },
  computed: {
    items: {
      get() {
        return this.$store.getters.getAirportSLABaggageDeliveryTypes;
      },
    },
  },
  methods: {
    customFilter(item, queryText) {
      const textOne = item.type ? item.type.toLowerCase() : '';
      const searchText = queryText.toLowerCase();

      return textOne.indexOf(searchText) > -1;
    },
    emitInput(event) {
      if (typeof (event) !== 'undefined') {
        this.$emit('input', event);
      } else {
        this.$emit('input', null);
      }
    },
  },
};
</script>
