<template>
  <v-card
    fluid
    grid-list-lg
    :dark="this.$vuetify.dark" :color="this.$vuetify.dark ? 'bggrey_dark' : 'bggrey'"
  >
    <v-layout v-if="isSCL()" row wrap>
      <v-flex xs12>
        <GhmLatam/>
      </v-flex>
      <v-flex xs12>
        <GhmSat/>
      </v-flex>
      <v-flex xs12>
        <Sftp/>
      </v-flex>
    </v-layout>

    <v-layout v-if="isPMI()" row wrap>
      <v-flex xs12>
        <Pmi/>
      </v-flex>
    </v-layout>

    <v-layout v-if="isDUS()" row wrap>
      <v-flex xs12>
        <Dus/>
      </v-flex>
    </v-layout>

  </v-card>
</template>
<script>
import GhmLatam from './ghm/latam/GhmLatam.vue';
import GhmSat from './ghm/sat/GhmSat.vue';
import Sftp from './sftp/Sftp.vue';
import Pmi from './pmi/Pmi.vue';
import Dus from './dus/Dus.vue';
import { ADMIN_GHM_LATAM_INTEGRATION, ADMIN_GHM_SAT_INTEGRATION } from '../../../store/modules/admin/ghm';
import { ADMIN_SFTP_INTEGRATION } from '../../../store/modules/admin/sftp';
import { ADMIN_PMI_INTEGRATION } from '../../../store/modules/admin/pmi';
import { ADMIN_DUS_INTEGRATION } from '../../../store/modules/admin/dus';

export default {
  name: 'AdminGhm',
  components: {
    GhmSat, GhmLatam, Sftp, Pmi, Dus,
  },

  methods: {
    isSCL() {
      return this.$store.getters.isSCL;
    },
    isPMI() {
      return this.$store.getters.isPMI;
    },
    isDUS() {
      return this.$store.getters.isDUS;
    },
    findIntegrationsStatus() {
      if (this.isSCL()) {
        this.$store.dispatch(ADMIN_GHM_LATAM_INTEGRATION);
        this.$store.dispatch(ADMIN_GHM_SAT_INTEGRATION);
        this.$store.dispatch(ADMIN_SFTP_INTEGRATION);
      }
      if (this.isPMI()) {
        this.$store.dispatch(ADMIN_PMI_INTEGRATION);
      }
      if (this.isDUS()) {
        this.$store.dispatch(ADMIN_DUS_INTEGRATION);
      }
    },
  },
  created() {
    this.findIntegrationsStatus();
  },
};
</script>
<style>
.admin-content-cards {
  height: 18vh;
  white-space: pre-line;
}
</style>
