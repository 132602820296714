<template>
  <div>
    <employee-income-list-filter @changeShowFilter="changeShowFilter"/>

    <employee-income-list-table :items="items" :isFilterDisplayed="filterDisplayed" />
  </div>
</template>
<script>
import Breadcrumbs from '@/components/Breadcrumbs.vue';

import EmployeeIncomeListFilter
from '../components/ticketingReports/employeeIncome/EmployeeIncomeListFilter.vue';
import EmployeeIncomeListTable
from '../components/ticketingReports/employeeIncome/EmployeeIncomeListTable.vue';

export default {
  name: 'employee-income-ticketing-report',
  components: {
    EmployeeIncomeListTable,
    EmployeeIncomeListFilter,
    Breadcrumbs,
  },
  data() {
    return {
      filterDisplayed: true,
    };
  },
  computed: {
    items() {
      // eslint-disable-next-line
      return this.$store.state.ticketingReports.employeeIncome.rankingResults;
    },
  },
  methods: {
    changeShowFilter(dispayled) {
      this.filterDisplayed = dispayled;
    },
  },
};
</script>
