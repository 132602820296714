<template>
  <v-card :dark="$vuetify.dark" class="flightLoadsHolding">
    <v-card-title>
      <div class="font-weight-bold">
        <v-icon>line_weight</v-icon>
        {{ $t('dispatcher.loadsHolding') }}
      </div>
      <v-spacer></v-spacer>
      <v-list-tile-action>
        <span><span class="red--text" v-if="totals.pending !== 0">{{ $t('dispatcher.pending') }}: {{ totals.pending
        }} {{ getWeightUnit() }} </span>
        </span>
      </v-list-tile-action>
    </v-card-title>
    <v-divider />
    <v-list class="pa-0 mb-2" v-if="!isEmptyLoads()">
      <div v-for="(loadHolding, index) in flightLoads.loadsHolding" :key="index">
        <div>
          <v-list-tile>
            <h4>{{ $t('dispatcher.holding') }} {{ loadHolding.holdOrder }}</h4>
          </v-list-tile>
        </div>
        <v-divider />
        <div class="pt-2 pb-4 highlight d-flex">
          <v-list-tile class="p-0 value d-flex">
            <!-- Peso -->
            <span class="mx-2 NUMBER flex-grow-1" style="flex-basis: 25%;">
              <v-label :for="'weight[' + index + ']'" :dark="$vuetify.dark">{{ $t('operations.loadsTableHoldingWeight') }}</v-label>
              <mobile-input-type :name="'weight[' + index + ']'" :value="loadHolding.weight" typeValue="NUMBER" :append="getWeightUnit()"
                @input="updateLoadHolding(loadHolding, $event, 'weight')"
                :editable="hasPermissions('AIRPORT_SERVICE_AGENT_ROLE_BAGGAGE_DISTRIBUTION_WR')"
                :class="hasPermissions('AIRPORT_SERVICE_AGENT_ROLE_BAGGAGE_DISTRIBUTION_WR') ? 'editable' : 'readonly'"></mobile-input-type>
              <v-spacer></v-spacer>
            </span>
            <!-- Piezas -->
            <span class="mr-2 NUMBER flex-grow-1" style="flex-basis: 25%;">
              <v-label :for="'Pcs[' + index + ']'" :dark="$vuetify.dark">{{ $t('operations.loadsTableHoldingPcs') }}</v-label>
              <mobile-input-type :name="'Pcs[' + index + ']'" :value="loadHolding.pcs" typeValue="NUMBER" append="Uds"
                :editable="hasPermissions('AIRPORT_SERVICE_AGENT_ROLE_BAGGAGE_DISTRIBUTION_WR')"
                :class="hasPermissions('AIRPORT_SERVICE_AGENT_ROLE_BAGGAGE_DISTRIBUTION_WR') ? 'editable' : 'readonly'"
                @input="updateLoadHolding(loadHolding, $event, 'pcs')"></mobile-input-type>
            </span>
            <v-spacer></v-spacer>
            <!-- Especiales -->
            <span class="flex-grow-1 mr-2" style="flex-basis: 50%;">
              <v-label :for="'Specials[' + index + ']'" :dark="$vuetify.dark">{{ $t('operations.loadsTableHoldingSpecials') }}</v-label>
              <mobile-input-type :name="'Specials[' + index + ']'" :value="loadHolding.specials" typeValue="TEXT"
                :editable="hasPermissions('AIRPORT_SERVICE_AGENT_ROLE_BAGGAGE_DISTRIBUTION_WR')"
                :class="hasPermissions('AIRPORT_SERVICE_AGENT_ROLE_BAGGAGE_DISTRIBUTION_WR') ? 'editable' : 'readonly'"
                @input="updateLoadHolding(loadHolding, $event, 'specials')" :rows="1"></mobile-input-type>
            </span>
          </v-list-tile>
        </div>
        <v-divider v-if="index < flightLoads.loadsHolding.length - 1"></v-divider>
      </div>
      <!-- TOTALS -->
      <v-divider />
      <div class="pt-0 flightLoadsHolding totals">
        <v-list-tile class="py-2 value">
          <!-- Peso -->
          <span class="mx-2 NUMBER flex-grow-1" style="flex-basis: 25%;">
            <v-label for="totalsWeight" :dark="$vuetify.dark">{{ $t('dispatcher.total') }}</v-label>
            <mobile-input-type name="totalsWeight" :value="totals.weight" typeValue="NUMBER" :editable="false" :append="getWeightUnit()"
              class="editable"></mobile-input-type>
            <v-spacer></v-spacer>
          </span>
          <!-- Piezas -->
          <span class="mr-2 NUMBER flex-grow-1" style="flex-basis: 25%;">
            <v-label for="totalsPcs" :dark="$vuetify.dark">{{ $t('operations.loadsTableHoldingPcs') }}</v-label>
            <mobile-input-type name="totalsPcs" :value="totals.pcs" typeValue="NUMBER" :editable="false" append="Uds"
              class="editable"></mobile-input-type>
          </span>
          <v-spacer></v-spacer>
          <!-- Total -->
          <span class="mr-2  flex-grow-1" style="flex-basis: 50%;"></span>
        </v-list-tile>
      </div>
    </v-list>
  </v-card>
</template>

<script>
import MobileInputType from '../mobileInputType/MobileInputType.vue';
import RolePermissions from '../../mixins/RolePermissions.vue';
import './MobileFlightLoadsHolding.scss';
import MobileFlightUtil from '../model/mobileFlightUtil';

export default {
  name: 'mobile-flight-loads-holding',
  props: {
    flightLoads: {
      type: Object,
      required: true,
    },
    // emit
    updateFlightLoadHolding: {
      type: Function,
      required: true,
    },
  },
  mixins: [RolePermissions],
  components: {
    MobileInputType,
  },
  methods: {
    isEmptyLoads() {
      return this.flightLoads && Object.keys(this.flightLoads).length === 0;
    },
    getTotalLoad() {
      return MobileFlightUtil.parseFloatZero(this.flightLoads.totalLoad.bag) + MobileFlightUtil.parseFloatZero(this.flightLoads.totalLoad.cargo) + MobileFlightUtil.parseFloatZero(this.flightLoads.totalLoad.mail);
    },
    getWeightUnit() {
      return this.flightLoads.totalLoad.unit === 'KILOGRAMS' ? 'Kgs' : 'Lbs';
    },
    updateLoadHolding(loadHolding, value, field) {
      const loadHoldingAux = loadHolding;
      switch (field) {
        case 'weight':
          loadHoldingAux.weight = value;
          break;
        case 'pcs':
          loadHoldingAux.pcs = value;
          break;
        case 'specials':
          loadHoldingAux.specials = value;
          break;
        default:
          break;
      }
      this.updateTotals();
      this.updateFlightLoadHolding(loadHoldingAux);
    },
    updateTotals() {
      this.totals.pcs = this.flightLoads.loadsHolding.reduce((acc, loadHolding) => MobileFlightUtil.parseFloatZero(acc) + MobileFlightUtil.parseFloatZero(loadHolding.pcs), 0);
      this.totals.weight = this.flightLoads.loadsHolding.reduce((acc, loadHolding) => MobileFlightUtil.parseFloatZero(acc) + MobileFlightUtil.parseFloatZero(loadHolding.weight), 0);
      this.totals.total = this.totals.weight;
      this.totals.pending = this.getTotalLoad() - this.totals.total;
      this.totals.load = this.totals.total;
      // save button enable or disable if pending is 0
      this.$emit('enableSave', this.isEnableSave());
    },
    isEnableSave() {
      if (!this.flightLoads.totalLoad.unit) {
        return false;
      }
      return this.totals.pending === 0;
    },
  },
  mounted() {
    this.updateTotals();
  },
  watch: {
    flightLoads: {
      handler() {
        this.updateTotals();
      },
      deep: true,
    },
  },
  data() {
    return {
      totals: {
        pcs: 0,
        weight: 0,
        total: 0,
        pending: 0,
        load: 0,
      },
    };
  },
};
</script>
