<template>
  <v-stepper v-model="step">
    <v-stepper-header>
      <v-stepper-step step="1" :complete="step > 1" :rules="[() => isValidCriteria]">
        {{$t("scheduling.linkChooseCriteria")}}
        <small v-if="!isValidCriteria">{{$t("scheduling.linkIncorrectCriteria")}}</small>
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step step="2" :complete="step > 2">
        {{$t("scheduling.linkChangesSummary")}}
      </v-stepper-step>
    </v-stepper-header>

    <loading-component />

    <v-stepper-items>
      <v-stepper-content step="1">
        <v-card>
          <v-card-text>
            <scheduling-link-step-options :link-options.sync="linkOptions" />
          </v-card-text>
          <v-card-actions>
            <v-btn flat color="primary" @click="cancel" :disabled="loading">{{$t("common.cancel")}}</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="step1Next" :disabled="loading || !isValidCriteria">{{$t("common.continue")}}</v-btn>
          </v-card-actions>
        </v-card>
      </v-stepper-content>

      <v-stepper-content step="2">
        <v-card>
          <v-card-text>
            {{$t("scheduling.linkFlightsAlert")}}
            <connected-flights-base-grid v-if="step === 2" :items="summaryItems" @selected-changed="selectedChanged"/>
          </v-card-text>
          <v-card-actions>
            <v-btn flat color="primary" @click="cancel" :disabled="loading">{{$t("common.cancel")}}</v-btn>
            <v-spacer></v-spacer>
            <v-btn flat color="primary" @click="step2Back" :disabled="loading">{{$t("common.back")}}</v-btn>
            <v-btn color="primary" @click="confirm" :disabled="loading || summaryItems.length == 0">{{$t("common.confirm")}}</v-btn>
          </v-card-actions>
        </v-card>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>
<script>

import LoadingComponent from '../../layout/LoadingComponent.vue';
import SchedulingLinkStepOptions from './SchedulingLinkStepOptions.vue';
import ConnectedFlightsBaseGrid from '../../flights/ConnectedFlightsBaseGrid.vue';

import SchedulingLinkOptions from '../model/schedulingLinkOptions';
import SchedulingFlightUtils from '../model/schedulingFlightUtils';
import ConnectedFlightsIds from '../../shared/model/connectedFlightsIds';

export default {
  name: 'scheduling-link-stepper',
  components: {
    LoadingComponent,
    SchedulingLinkStepOptions,
    ConnectedFlightsBaseGrid,
  },
  props: ['items'],
  data() {
    return {
      step: 1,
      linkOptions: SchedulingLinkOptions.createDefaultOptions(),
      summaryItems: [],
      selectedItems: [],
    };
  },
  computed: {
    loading() {
      return this.$store.state.loading;
    },
    isValidCriteria() {
      return this.linkOptions.isValid();
    },
  },
  methods: {
    clean() {
      this.summaryItems = [];
      this.step = 1;
    },
    cancel() {
      this.clean();
      this.$emit('link-stepper-cancel');
    },
    step1Next() {
      this.summaryItems = SchedulingFlightUtils.getLinkableFlights(this.items, this.linkOptions);
      this.selectedItems = [];
      this.step = 2;
    },
    step2Back() {
      this.step = 1;
    },
    selectedChanged(selectedItems) {
      this.selectedItems = selectedItems;
    },
    confirm() {
      const flightsIds = ConnectedFlightsIds.mapFromConnectedFlightsList(this.selectedItems);
      this.clean();
      this.$emit('link-stepper-confirm', flightsIds);
    },
  },
};
</script>
<style>
</style>
