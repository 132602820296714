<template>
  <div>
    <passage-flights-list-filter @changeShowFilter="changeShowFilter" />
    <passage-flights-list-container :isFilterDisplayed="filterDisplayed" />
    <passage-flights-list-options @click-archive="archiveFlights" @click-unarchive="unarchiveFlights" />
  </div>
</template>
<script>
import PassageFlightsListFilter from '../components/passage/inbounds/PassageInboundFlightsListFilter.vue';
import PassageFlightsListContainer from '../components/passage/inbounds/PassageInboundFlightsListContainer.vue';
import PassageFlightsListOptions from '../components/passage/inbounds/PassageInboundFlightsListOptions.vue';
import { PASSAGE_INBOUND_ARCHIVE, PASSAGE_INBOUND_UNARCHIVE } from '../store/modules/passageInbound';

export default {
  components: {
    PassageFlightsListFilter,
    PassageFlightsListContainer,
    PassageFlightsListOptions,
  },
  computed: {
  },
  data() {
    return {
      filterDisplayed: false,
    };
  },
  methods: {
    archiveFlights() {
      this.$store.dispatch(PASSAGE_INBOUND_ARCHIVE);
    },
    unarchiveFlights() {
      this.$store.dispatch(PASSAGE_INBOUND_UNARCHIVE);
    },
    changeShowFilter(dispayled) {
      this.filterDisplayed = dispayled;
    },
  },
};
</script>
