<template>
  <div>
    <ag-grid-vue
      class="ag-theme-material connected-flights-table operational-table"
      :columnDefs="columnDefs"
      :defaultColDef="defaultColDef"
      :rowData="items"
      rowSelection="multiple"
      :rowMultiSelectWithClick="true"
      :suppressRowClickSelection="true"
      @grid-ready="onGridReady"
      @first-data-rendered="firstDataRendered"
      :gridOptions="gridOptions"
      @selection-changed="onSelectionChanged"
      :suppressHorizontalScroll="true"
      :frameworkComponents="frameworkComponents"
      :overlayNoRowsTemplate="customNoRowsOverlay"
      :overlayLoadingTemplate="customLoadingOverlay"
      v-bind:style="`width: 100%; height: ${this.tableHeight};`">
    </ag-grid-vue>
  </div>
</template>
<script>
import
{ AgGridVue } from 'ag-grid-vue';
import AgGridRowActionButtonsCellRenderer from '../../ag-grid/cell-renderers/AgGridRowActionButtonsCellRenderer.vue';
import AgGridConnectedFlightsAircraftTypeSelectCellEditor from '../../ag-grid/cell-editors/connectedFlights/AgGridConnectedFlightsAircraftTypeSelectCellEditor.vue';
import AgGridConnectedFlightsAircraftSelectCellEditor from '../../ag-grid/cell-editors/connectedFlights/AgGridConnectedFlightsAircraftSelectCellEditor.vue';
import AgGridConnectedFlightsDateCellEditor from '../../ag-grid/cell-editors/connectedFlights/AgGridConnectedFlightsDateCellEditor.vue';

import AgGridConnectedFlightsAircraftConfigTypeaheadCellEditor from '../../ag-grid/cell-editors/connectedFlights/AgGridConnectedFlightsAircraftConfigTypeaheadCellEditor.vue';
import AgGridConnectedFlightsFlightNumberCellEditor from '../../ag-grid/cell-editors/connectedFlights/AgGridConnectedFlightsFlightNumberCellEditor.vue';
import AgGridConnectedFlightsAirlineSelectCellEditor from '../../ag-grid/cell-editors/connectedFlights/AgGridConnectedFlightsAirlineSelectCellEditor.vue';
import AgGridConnectedFlightsTimeCellEditor from '../../ag-grid/cell-editors/connectedFlights/AgGridConnectedFlightsTimeCellEditor.vue';
import AgGridConnectedFlightsFromToCellEditor from '../../ag-grid/cell-editors/connectedFlights/AgGridConnectedFlightsFromToCellEditor.vue';
import AgGridConnectedFlightsHandlingTypeSelectCellEditor from '../../ag-grid/cell-editors/connectedFlights/AgGridConnectedFlightsHandlingTypeSelectCellEditor.vue';
import AgGridConnectedFlightsServiceTypeSelectCellEditor from '../../ag-grid/cell-editors/connectedFlights/AgGridConnectedFlightsServiceTypeSelectCellEditor.vue';
import AgGridConnectedFlightsCommentsCellEditor from '../../ag-grid/cell-editors/connectedFlights/AgGridConnectedFlightsCommentsCellEditor.vue';
import AgGridConnectedFlightsCheckInCountersCellEditor from '../../ag-grid/cell-editors/connectedFlights/AgGridConnectedFlightsCheckInCountersCellEditor.vue';
import AgGridTimeCellRenderer from '../../ag-grid/cell-renderers/AgGridTimeCellRenderer.vue';
import AgGridDateCellRenderer from '../../ag-grid/cell-renderers/AgGridDateCellRenderer.vue';
import AgGridCommentsCellRenderer from '../../ag-grid/cell-renderers/AgGridCommentsCellRenderer.vue';
import AgGridValueBaseCellRenderer from '../../ag-grid/cell-renderers/AgGridValueBaseCellRenderer.vue';
import AgGridFlightStatusCellRenderer from '../../ag-grid/cell-renderers/AgGridFlightStatusCellRenderer.vue';
import AgGridConnectedFlights from '../../ag-grid/mixins/AgGridConnectedFlights.vue';
import AgGridConnectedFlightsDialogStatusCellEditor from '../../ag-grid/cell-editors/connectedFlights/dialog/AgGridConnectedFlightsDialogStatusCellEditor.vue';
import AgGridConnectedFlightsToggleCellEditor from '../../ag-grid/cell-editors/connectedFlights/AgGridConnectedFlightsToggleCellEditor.vue';
import {
  SCHED_CHANGE_FLIGHT_SELECTION,
  SCHED_CHANGE_GRID_API,
  SCHED_FIT_COLUMNS_TO_VIEW,
  SCHED_ON_EXTERNAL_CHANGE,
  SCHED_SET_CHANGE,
} from '../../../store/modules/scheduling';
import { FlightBound } from '../../shared/model/flightBound';
import { cellClassAgGridRules } from '../../ag-grid/mixins/cellClassAgGridRules';
import { FisModule } from '../../ag-grid/cell-editors/model/fisModule';
import agGridTextCustomFilterComparator from '../../ag-grid/filters/agGridTextCustomFilterComparator';
import agGridTextCustomFilterFormatter from '../../ag-grid/filters/agGridTextCustomFilterFormatter';
import RolePermissions from '../../mixins/RolePermissions.vue';
import AgGridIconTooltipOperationCellRenderer
from '../../ag-grid/cell-renderers/AgGridIconTooltipOperationCellRenderer.vue';
import { WEBSOCKET_CONNECTION } from '../../../store/modules/websocket';
import FlightsDifferences from '../../mixins/FlightsDifferences.vue';
import agGridTimeFormatter from '../../ag-grid/formatters/agGridTimeFormatter';
import AgGridValueAndSupFieldPossibleBlockedCellRenderer from '../../ag-grid/cell-renderers/AgGridValueAndSupFieldPossibleBlockedCellRenderer.vue';
import AgGridNoRowsOverlay from '../../ag-grid/mixins/AgGridNoRowsOverlay.vue';

export default {
  name: 'scheduling-list-table',
  mixins: [AgGridConnectedFlights, FlightsDifferences, RolePermissions, AgGridNoRowsOverlay],
  components: {
    AgGridVue,
  },
  props: {
    items: { type: Array, required: true },
    tableHeight: { type: String, required: true },
  },
  mounted() {
    this.$store.dispatch(WEBSOCKET_CONNECTION, this.$store.state.tenant.currentTenant.name.toLowerCase());
  },
  computed: {
    fitColumnsOnResize() {
      return this.$store.state.scheduling.fitColumnsOnResize;
    },
  },
  data() {
    const that = this;
    const rolePermission = this.getRoles();
    return {
      scheduledTimeRequired: true,
      frameworkComponents: {
        AgGridConnectedFlightsAircraftSelectCellEditor,
        AgGridConnectedFlightsAircraftConfigTypeaheadCellEditor,
        AgGridConnectedFlightsAircraftTypeSelectCellEditor,
        AgGridConnectedFlightsAirlineSelectCellEditor,
        AgGridConnectedFlightsCheckInCountersCellEditor,
        AgGridConnectedFlightsCommentsCellEditor,
        AgGridConnectedFlightsTimeCellEditor,
        AgGridConnectedFlightsDateCellEditor,
        AgGridConnectedFlightsDialogStatusCellEditor,
        AgGridConnectedFlightsFlightNumberCellEditor,
        AgGridConnectedFlightsFromToCellEditor,
        AgGridConnectedFlightsHandlingTypeSelectCellEditor,
        AgGridConnectedFlightsServiceTypeSelectCellEditor,
        AgGridConnectedFlightsToggleCellEditor,
      },
      defaultColDef: {
        cellClassRules: cellClassAgGridRules,
        minWidth: 30,
        suppressMenu: true,
        filter: 'agTextColumnFilter',
        sortable: true,
        resizable: true,
        filterParams: {
          textCustomComparator: agGridTextCustomFilterComparator,
          textFormatter: agGridTextCustomFilterFormatter,
        },
        editable: false,
      },
      gridOptions: {
        onFilterChanged: this.uncheckAllOnFilter,
        getRowNodeId: this.getRowNodeId,
        getRowClass: this.getRowClass,
        floatingFilter: true,
        groupHeaderHeight: 35,
        headerHeight: 35,
        floatingFiltersHeight: 20,
        rowHeight: 30,
        context: {
          module: FisModule.SCHEDULING,
        },
      },
      columnDefs: [
        {
          headerTooltip: 'Check/uncheck all',
          hide: !this.hasPermissions(rolePermission),
          checkboxSelection: true,
          width: 21,
          minWidth: 20,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          suppressMenu: true,
          sortable: false,
          suppressMovable: true,
          suppressSizeToFit: true,
          filter: false,
          resizable: false,
          colCustomConfig: {
            exportCsvDisabled: true,
          },
          editable: this.editable(true, rolePermission),
        },
        {
          headerName: 'SortDate',
          field: 'sortingTime',
          hide: true,
          sort: 'asc',
        },
        {
          headerName: 'Reg',
          field: 'common.aircraftRegisterNumber',
          width: 60,
          editable: this.editable(true, rolePermission),
          cellEditor: 'AgGridConnectedFlightsAircraftSelectCellEditor',
          cellClass: 'aircraft-cell',
          colCustomConfig: {
            fieldPaths: ['common.aircraftId', 'common.aircraftTypeId'],
            flightBound: FlightBound.ANY,
            fieldsRelated: ['inbound.common.aircraftRegisterNumber', 'outbound.common.aircraftRegisterNumber',
              'inbound.common.aircraftAcars', 'outbound.common.aircraftAcars',
              'inbound.blockedFieldsIntegration.manualOverwriteAircraft', 'outbound.blockedFieldsIntegration.manualOverwriteAircraft'],
          },
          comparator: this.objectWithValueComparator,
          valueGetter(params) {
            return {
              value: params.data.getChildPropertyValue(FlightBound.ANY, ['common', 'aircraftRegisterNumber']),
              sup: params.data.getChildPropertyValue(FlightBound.ANY, ['common', 'aircraftAcars']) ? 'A' : null,
              fieldBlockedIntegration: params.data.getChildPropertyValue(FlightBound.ANY, ['blockedFieldsIntegration', 'manualOverwriteAircraft']),
            };
          },
          cellRendererFramework: AgGridValueAndSupFieldPossibleBlockedCellRenderer,
        },
        {
          field: 'common.aircraftTypeCode',
          headerValueGetter() {
            return that.$i18n.t('common.type');
          },
          width: 50,
          editable: this.editable(true, rolePermission),
          cellClass: 'aircraft-type-cell',
          cellEditor: 'AgGridConnectedFlightsAircraftTypeSelectCellEditor',
          colCustomConfig: {
            fieldPaths: ['common.aircraftId', 'common.aircraftTypeId'],
            flightBound: FlightBound.ANY,
            fieldsRelated: ['inbound.common.aircraftTypeIataCode', 'inbound.common.aircraftTypeIcaoCode',
              'outbound.common.aircraftTypeIataCode', 'outbound.common.aircraftTypeIcaoCode',
              'inbound.common.aircraftCargo', 'outbound.common.aircraftCargo',
              'inbound.blockedFieldsIntegration.manualOverwriteAircraftType', 'blockedFieldsIntegration.common.manualOverwriteAircraftType'],
          },
          valueGetter(params) {
            return {
              value: params.data.getChildPropertyValue(FlightBound.ANY, ['common', 'aircraftTypeCode']),
              sup: params.data.getChildPropertyValue(FlightBound.ANY, ['common', 'aircraftCargo']) ? 'P' : null,
              tooltip: params.data.getChildPropertyValue(FlightBound.ANY, ['common', 'aircraftTypeComments']),
              fieldBlockedIntegration: params.data.getChildPropertyValue(FlightBound.ANY, ['blockedFieldsIntegration', 'manualOverwriteAircraftType']),
            };
          },
          cellRendererFramework: AgGridValueAndSupFieldPossibleBlockedCellRenderer,
        },
        {
          field: 'common.Config',
          headerName: 'Config',
          width: 60,
          editable: this.editable(true, rolePermission),
          cellClass: 'aircraft-config-cell',
          cellEditor: 'AgGridConnectedFlightsAircraftConfigTypeaheadCellEditor',
          valueGetter(params) {
            return params.data.getSafeValue(['common', 'aircraftConfigCode']);
          },
          colCustomConfig: {
            fieldPaths: ['common.aircraftConfigId'],
            flightBound: FlightBound.ANY,
            fieldsRelated: ['inbound.common.aircraftConfigCode', 'outbound.common.aircraftConfigCode'],
          },
          cellRendererFramework: AgGridValueBaseCellRenderer,
        },
        {
          headerValueGetter() {
            return that.$i18n.t('common.date');
          },
          colId: 'inbound.common.flightDate',
          field: 'inbound.common.flightDate',
          width: 80,
          type: 'date',
          editable: this.editable(true, rolePermission) && this.isInbound,
          cellEditor: 'AgGridConnectedFlightsDateCellEditor',
          colCustomConfig: {
            fieldPaths: ['common.flightDate', 'common.scheduledTime'],
            flightBound: FlightBound.INBOUND,
            fieldsRelated: ['inbound.common.scheduledDate', 'inbound.common.flightDate', 'inbound.common.scheduledTime'],
          },
          valueGetter(params) {
            return {
              value: params.data.getChildPropertyValue(FlightBound.INBOUND, ['common', 'scheduledDate']),
            };
          },
          cellRendererFramework: AgGridDateCellRenderer,
        },
        {
          headerValueGetter() {
            return that.$i18n.t('common.flight');
          },
          field: 'flight',
          width: 60,
          editable: false,
          cellClass: 'flight-number-cell separator',
          cellEditor: 'AgGridConnectedFlightsFlightNumberCellEditor',
          colCustomConfig: {
            flightBound: FlightBound.INBOUND,
            fieldsRelated: ['inbound.common.flightNo'],
          },
          valueGetter(params) {
            return params.data.getChildPropertyValue(FlightBound.INBOUND, ['common', 'flightNo']);
          },
          cellRendererFramework: AgGridValueBaseCellRenderer,
        },
        {
          headerName: 'Op',
          field: 'common.Op',
          width: 40,
          editable: this.editable(true, rolePermission) && this.isInbound,
          cellClass: 'airline-cell separator',
          cellEditor: 'AgGridConnectedFlightsAirlineSelectCellEditor',
          colCustomConfig: {
            fieldPaths: ['common.airlineOperationId'],
            flightBound: FlightBound.INBOUND,
            fieldsRelated: ['inbound.common.airlineOperationIataCode', 'inbound.common.airlineOperationIcaoCode'],
          },
          valueGetter(params) {
            return params.data.getChildPropertyValue(FlightBound.INBOUND, ['common', 'airlineOperationIataCode']) != null ? params.data.getChildPropertyValue(FlightBound.INBOUND, ['common', 'airlineOperationIataCode']) : params.data.getChildPropertyValue(FlightBound.INBOUND, ['common', 'airlineOperationIcaoCode']);
          },
          cellRendererFramework: AgGridValueBaseCellRenderer,
        },
        {
          colId: 'inbound.common.scheduledTime',
          field: 'inbound.common.scheduledTime',
          headerName: 'STA',
          width: 50,
          editable: this.editable(true, rolePermission) && this.isInbound,
          cellClass: 'scheduled-time-cell',
          comparator: this.objectWithValueComparator,
          cellEditor: 'AgGridConnectedFlightsTimeCellEditor',
          colCustomConfig: {
            fieldPaths: ['common.scheduledTime'],
            flightBound: FlightBound.INBOUND,
            fieldsRelated: ['inbound.common.scheduledTime', 'inbound.common.scheduledDate'],
            oldValueFormatter: agGridTimeFormatter,
          },
          valueGetter(params) {
            return that.agGridTimeGetter(params.data.getChildPropertyValue(FlightBound.INBOUND, ['common', 'scheduledTime']), params.data.getChildPropertyValue(FlightBound.INBOUND, ['common', 'scheduledDate']), null, null, that.scheduledTimeRequired);
          },
          cellRendererFramework: AgGridTimeCellRenderer,
        },
        {
          field: 'common.From',
          headerValueGetter() {
            return that.$i18n.t('common.from');
          },
          width: 100,
          editable: this.editable(true, rolePermission) && this.isInbound,
          cellClass: 'airport-from-cell',
          cellEditor: 'AgGridConnectedFlightsFromToCellEditor',
          colCustomConfig: {
            fieldPaths: ['common.airport1Id', 'common.airport2Id', 'common.airport3Id'],
            flightBound: FlightBound.INBOUND,
            fieldsRelated: ['inbound.common.airportsIata', 'inbound.common.airport1Code', 'inbound.common.airport2Code', 'inbound.common.airport3Code'],
          },
          valueGetter(params) {
            return params.data.getChildPropertyValue(FlightBound.INBOUND, ['common', 'airportsIata']);
          },
          cellRendererFramework: AgGridValueBaseCellRenderer,
        },
        {
          field: 'common.HT',
          headerName: 'HT',
          width: 40,
          editable: this.editable(true, rolePermission) && this.isInbound,
          cellClass: 'handling-type-cell',
          cellEditor: 'AgGridConnectedFlightsHandlingTypeSelectCellEditor',
          colCustomConfig: {
            fieldPaths: ['common.handlingTypeId'],
            flightBound: FlightBound.INBOUND,
            fieldsRelated: ['inbound.common.handlingTypeCode'],
          },
          valueGetter(params) {
            return params.data.getChildPropertyValue(FlightBound.INBOUND, ['common', 'handlingTypeCode']);
          },
          cellRendererFramework: AgGridValueBaseCellRenderer,
        },
        {
          field: 'common.ST',
          headerName: 'ST',
          width: 40,
          editable: this.editable(true, rolePermission) && this.isInbound,
          cellClass: 'service-type-cell',
          cellEditor: 'AgGridConnectedFlightsServiceTypeSelectCellEditor',
          colCustomConfig: {
            fieldPaths: ['common.serviceTypeId'],
            flightBound: FlightBound.INBOUND,
            fieldsRelated: ['inbound.common.serviceTypeCode'],
          },
          valueGetter(params) {
            return params.data.getChildPropertyValue(FlightBound.INBOUND, ['common', 'serviceTypeCode']);
          },
          cellRendererFramework: AgGridValueBaseCellRenderer,
        },
        {
          field: 'common.Stat',
          headerValueGetter() {
            return that.getTextResizable('Stat', that.$i18n.t('scheduling.flightState'));
          },
          headerTooltip: `${this.$i18n.t('scheduling.flightState')}`,
          width: 51,
          minWidth: 45,
          editable: this.editable(true, rolePermission) && this.isInbound,
          cellEditor: 'AgGridConnectedFlightsDialogStatusCellEditor',
          cellClass: 'status-cell',
          colCustomConfig: {
            flightBound: FlightBound.INBOUND,
            fieldsRelated: ['inbound.common.status'],
          },
          flight: FlightBound.INBOUND,
          valueGetter(params) {
            return params.data.getChildPropertyValue(FlightBound.INBOUND, ['common', 'status']);
          },
          cellRendererFramework: AgGridFlightStatusCellRenderer,
        },
        {
          field: 'common.TripNumberIATAFormatValid',
          headerValueGetter() {
            return that.getTextResizable(that.$i18n.t('scheduling.tripNumberIATAFormatValidShort'), that.$i18n.t('scheduling.tripNumberIATAFormatValidTitle'));
          },
          headerTooltip: `${this.$i18n.t('scheduling.tripNumberIATAFormatValidTitle')}`,
          width: 40,
          minWidth: 35,
          colCustomConfig: {
            flightBound: FlightBound.INBOUND,
            fieldsRelated: ['inbound.common.tripNumberIATAFormatValid'],
          },
          flight: FlightBound.INBOUND,
          valueGetter(params) {
            const formatIATAValid = params.data.getChildPropertyValue(FlightBound.INBOUND, ['common', 'tripNumberIATAFormatValid']);
            return {
              value: formatIATAValid,
              color: 'primary',
              icon: formatIATAValid != null && !formatIATAValid ? 'format_clear' : null,
              tooltip: formatIATAValid != null && !formatIATAValid ? that.$i18n.t('scheduling.tripNumberIATAFormatInvalid') : null,
            };
          },
          cellRendererFramework: AgGridIconTooltipOperationCellRenderer,
        },
        {
          headerValueGetter() {
            return that.$i18n.t('common.date');
          },
          colId: 'outbound.common.flightDate',
          field: 'outbound.common.flightDate',
          width: 80,
          type: 'date',
          editable: this.editable(true, rolePermission) && this.isOutbound,
          cellEditor: 'AgGridConnectedFlightsDateCellEditor',
          colCustomConfig: {
            fieldPaths: ['common.flightDate', 'common.scheduledTime'],
            flightBound: FlightBound.OUTBOUND,
            fieldsRelated: ['outbound.common.scheduledDate', 'outbound.common.flightDate', 'outbound.common.scheduledTime'],
          },
          valueGetter(params) {
            return {
              value: params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'scheduledDate']),
            };
          },
          cellRendererFramework: AgGridDateCellRenderer,
        },
        {
          field: 'common.flight',
          headerValueGetter() {
            return that.$i18n.t('common.flight');
          },
          width: 60,
          editable: false,
          cellClass: 'flight-number-cell separator',
          cellEditor: 'AgGridConnectedFlightsFlightNumberCellEditor',
          colCustomConfig: {
            flightBound: FlightBound.OUTBOUND,
            fieldsRelated: ['outbound.common.flightNo'],
          },
          valueGetter(params) {
            return params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'flightNo']);
          },
          cellRendererFramework: AgGridValueBaseCellRenderer,
        },
        {
          field: 'common.Op',
          headerName: 'Op',
          width: 40,
          editable: this.editable(true, rolePermission) && this.isOutbound,
          cellClass: 'airline-cell separator',
          cellEditor: 'AgGridConnectedFlightsAirlineSelectCellEditor',
          colCustomConfig: {
            fieldPaths: ['common.airlineOperationId'],
            flightBound: FlightBound.OUTBOUND,
            fieldsRelated: ['outbound.common.airlineOperationIataCode', 'outbound.common.airlineOperationIcaoCode'],
          },
          valueGetter(params) {
            return params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'airlineOperationIataCode']) != null ? params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'airlineOperationIataCode']) : params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'airlineOperationIcaoCode']);
          },
          cellRendererFramework: AgGridValueBaseCellRenderer,
        },
        {
          field: 'outbound.common.scheduledTime',
          colId: 'outbound.common.scheduledTime',
          headerName: 'STD',
          width: 50,
          editable: this.editable(true, rolePermission) && this.isOutbound,
          cellClass: 'scheduled-time-cell',
          comparator: this.objectWithValueComparator,
          cellEditor: 'AgGridConnectedFlightsTimeCellEditor',
          colCustomConfig: {
            fieldPaths: ['common.scheduledTime'],
            flightBound: FlightBound.OUTBOUND,
            fieldsRelated: ['outbound.common.scheduledTime', 'outbound.common.scheduledDate'],
            oldValueFormatter: agGridTimeFormatter,
          },
          valueGetter(params) {
            return that.agGridTimeGetter(params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'scheduledTime']), params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'scheduledDate']), null, null, that.scheduledTimeRequired);
          },
          cellRendererFramework: AgGridTimeCellRenderer,
        },
        {
          field: 'common.to',
          headerValueGetter() {
            return that.$i18n.t('common.to');
          },
          width: 100,
          editable: this.editable(true, rolePermission) && this.isOutbound,
          cellClass: 'airport-from-cell',
          cellEditor: 'AgGridConnectedFlightsFromToCellEditor',
          colCustomConfig: {
            fieldPaths: ['common.airport1Id', 'common.airport2Id', 'common.airport3Id'],
            flightBound: FlightBound.OUTBOUND,
            fieldsRelated: ['outbound.common.airportsIata', 'outbound.common.airport1Code', 'outbound.common.airport2Code', 'outbound.common.airport3Code'],
          },
          valueGetter(params) {
            return params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'airportsIata']);
          },
          cellRendererFramework: AgGridValueBaseCellRenderer,
        },
        {
          field: 'common.HT',
          headerName: 'HT',
          width: 40,
          editable: this.editable(true, rolePermission) && this.isOutbound,
          cellClass: 'handling-type-cell',
          cellEditor: 'AgGridConnectedFlightsHandlingTypeSelectCellEditor',
          colCustomConfig: {
            fieldPaths: ['common.handlingTypeId'],
            flightBound: FlightBound.OUTBOUND,
            fieldsRelated: ['outbound.common.handlingTypeCode'],
          },
          valueGetter(params) {
            return params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'handlingTypeCode']);
          },
          cellRendererFramework: AgGridValueBaseCellRenderer,
        },
        {
          field: 'common.ST',
          headerName: 'ST',
          width: 40,
          editable: this.editable(true, rolePermission) && this.isOutbound,
          cellClass: 'service-type-cell',
          cellEditor: 'AgGridConnectedFlightsServiceTypeSelectCellEditor',
          colCustomConfig: {
            fieldPaths: ['common.serviceTypeId'],
            flightBound: FlightBound.OUTBOUND,
            fieldsRelated: ['outbound.common.serviceTypeCode'],
          },
          valueGetter(params) {
            return params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'serviceTypeCode']);
          },
          cellRendererFramework: AgGridValueBaseCellRenderer,
        },
        {
          field: 'scheduling.flightState',
          headerValueGetter() {
            return that.getTextResizable('Stat', that.$i18n.t('scheduling.flightState'));
          },
          headerTooltip: `${this.$i18n.t('scheduling.flightState')}`,
          width: 51,
          minWidth: 45,
          editable: this.isOutbound,
          cellEditor: 'AgGridConnectedFlightsDialogStatusCellEditor',
          cellClass: 'status-cell',
          colCustomConfig: {
            flightBound: FlightBound.OUTBOUND,
            fieldsRelated: ['outbound.common.status'],
          },
          flight: FlightBound.OUTBOUND,
          valueGetter(params) {
            return params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'status']);
          },
          cellRendererFramework: AgGridFlightStatusCellRenderer,
        },
        {
          field: 'common.TripNumberIATAFormatValid',
          headerValueGetter() {
            return that.getTextResizable(that.$i18n.t('scheduling.tripNumberIATAFormatValidShort'), that.$i18n.t('scheduling.tripNumberIATAFormatValidTitle'));
          },
          headerTooltip: `${this.$i18n.t('scheduling.tripNumberIATAFormatValidTitle')}`,
          width: 40,
          minWidth: 35,
          colCustomConfig: {
            flightBound: FlightBound.OUTBOUND,
            fieldsRelated: ['outbound.common.tripNumberIATAFormatValid'],
          },
          flight: FlightBound.OUTBOUND,
          valueGetter(params) {
            const formatIATAValid = params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'tripNumberIATAFormatValid']);
            return {
              value: formatIATAValid,
              color: 'primary',
              icon: formatIATAValid != null && !formatIATAValid ? 'format_clear' : null,
              tooltip: formatIATAValid != null && !formatIATAValid ? that.$i18n.t('scheduling.tripNumberIATAFormatInvalid') : null,
            };
          },
          cellRendererFramework: AgGridIconTooltipOperationCellRenderer,
        },
        {
          field: 'common.comments',
          headerValueGetter() {
            return that.$i18n.t('common.comments');
          },
          cellClass: 'separator comments-flight-cell',
          comparator: this.objectWithValueComparator,
          editable: this.editable(true, rolePermission),
          cellEditor: 'AgGridConnectedFlightsCommentsCellEditor',
          colCustomConfig: {
            fieldPaths: ['inbound.common.comments', 'outbound.common.comments'],
            flightBound: FlightBound.ANY,
            fieldsRelated: ['inbound.common.comments', 'outbound.common.comments'],
          },
          cellRendererFramework: AgGridCommentsCellRenderer,
          valueGetter(params) {
            return {
              value: [params.data.getChildPropertyValue(FlightBound.INBOUND, ['common', 'comments']), params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'comments'])],
            };
          },
        },
        {
          colId: 'anybound.common.external',
          field: 'anybound.common.external',
          headerValueGetter() {
            return that.getTextResizable('External', that.$i18n.t('common.flightExternal'));
          },
          width: 65,
          minWidth: 55,
          cellClass: 'text-center',
          comparator: this.objectWithValueComparator,
          editable: this.editable(true, rolePermission),
          cellEditor: 'AgGridConnectedFlightsToggleCellEditor',
          colCustomConfig: {
            fieldPaths: ['common.external'],
            flightBound: FlightBound.ANY,
            fieldsRelated: ['inbound.common.external', 'outbound.common.external'],
          },
          valueGetter(params) {
            const external = params.data.getChildPropertyValue(FlightBound.ANY, ['common', 'external']);
            return {
              value: external,
              color: 'primary',
              icon: external ? 'shuffle' : null,
            };
          },
          cellRendererFramework: AgGridIconTooltipOperationCellRenderer,
        },
        {
          field: 'scheduling.realWeight',
          headerValueGetter() {
            return that.$i18n.t('scheduling.realWeight');
          },
          headerName: `${this.$i18n.t('scheduling.realWeight')}`,
          cellClass: 'separator',
          cellRendererFramework: AgGridValueBaseCellRenderer,
          colCustomConfig: {
            flightBound: FlightBound.ANY,
            fieldsRelated: ['weighting.real'],
          },
          valueGetter(params) {
            return params.data.weighting ? params.data.weighting.real : null;
          },
        },
        {
          field: 'scheduling.totalWeight',
          headerValueGetter() {
            return that.$i18n.t('scheduling.totalWeight');
          },
          cellClass: '',
          cellRendererFramework: AgGridValueBaseCellRenderer,
          colCustomConfig: {
            flightBound: FlightBound.ANY,
            fieldsRelated: ['weighting.total'],
          },
          valueGetter(params) {
            return params.data.weighting ? params.data.weighting.total : null;
          },
        },
        {
          headerValueGetter() {
            return that.$i18n.t('scheduling.actions');
          },
          hide: !this.hasPermissions(rolePermission),
          width: 100,
          cellClass: 'text-center',
          field: 'id',
          suppressMenu: true,
          suppressMovable: true,
          suppressSizeToFit: true,
          suppressNavigable: true,
          colCustomConfig: {
            exportCsvDisabled: true,
          },
          cellRendererFramework: AgGridRowActionButtonsCellRenderer,
          cellRendererParams: {
            editItem: this.editItem,
            deleteItem: this.deleteItem,
          },
        },
      ],
    };
  },
  methods: {
    onGetChanges(changes) {
      const rowNode = this.gridApi.getRowNode(changes.payload.id);

      if (!rowNode) {
        return;
      }
      const oldConnectedFlight = rowNode.data.clone();
      const newConnectedFlight = changes.payload;
      this.assignInboundOrOutboundIfDontExist(newConnectedFlight, oldConnectedFlight);
      const differencies = this.getDifferenciesAndCreateOldValuesProperties(oldConnectedFlight, newConnectedFlight);

      this.setOldValuesDifferencesFromOldConnectedFlight(rowNode.data, newConnectedFlight);

      if (differencies && differencies.length > 0) {
        rowNode.setData(newConnectedFlight);
        if (this.$store.state.scheduling.flashOnChanges) {
          this.renderNeededDiffs(differencies, rowNode); // TODO only shows flash
        }
        differencies.forEach(dif => this.$store.commit(SCHED_SET_CHANGE, { diff: dif, connectedFlights: newConnectedFlight, user: changes.origin }));
      }
    },
    onGridReady(params) {
      this.setAgGridApi(params);
      this.showLoading();
      this.$store.commit(SCHED_CHANGE_GRID_API, params); // MOVE TO MIXIN?
      this.refreshCellsOnMutation();
      this.refreshCellsOnChangeLanguage();
      const that = this;
      this.subscribeMutation(SCHED_ON_EXTERNAL_CHANGE, (mutationPayload) => {
        that.onGetChanges(mutationPayload);
      });
    },
    onSelectionChanged(event) {
      const selectedNodes = event.api.getSelectedNodes();
      const selectedData = selectedNodes.map(node => node.data);
      this.$store.commit(SCHED_CHANGE_FLIGHT_SELECTION, selectedData);
    },
    deleteItem(item) {
      this.$emit('delete-item', item);
    },
    editItem(item) {
      this.$emit('edit-item', item);
    },
    isInbound(params) {
      return params.data.hasInbound();
    },
    isOutbound(params) {
      return params.data.hasOutbound();
    },
    resized() {
      if (this.gridApi) {
        this.gridApi.sizeColumnsToFit();
      }
    },
    sizeColumnsToFit() {
      if (this.gridApi) {
        this.$store.dispatch(SCHED_FIT_COLUMNS_TO_VIEW);
      }
    },
    firstDataRendered() {
      this.$nextTick(() => {
        this.resized();
      });
    },
  },
};
</script>
