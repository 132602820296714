<template>
  <div>
    <v-switch
      class="toggle-cell-input"
      color="primary"
      v-model="checkedUpdated"/>
  </div>
</template>

<script>
import Vue from 'vue';
import { keyEvents } from '../../../keyEvents';
import AgGridParentConnectedFlightsCellEditor from '../mixins/AgGridParentConnectedFlightsCellEditor.vue';
import { JSUtils } from '../../../shared/js/JSUtils';

export default Vue.extend({
  mixins: [AgGridParentConnectedFlightsCellEditor],
  components: {
  },
  data() {
    return {
      checkedOriginal: null,
      checkedUpdated: null,
    };
  },
  methods: {
    getValue() {
      this.unmarkCellsSelectedAsEdit();

      if (this.checkedOriginal !== this.checkedUpdated) {
        const that = this;
        const connectedFlightPropertyName = this.getConnectedFlightsPropertyName();

        this.saveFlightChangesFromValue((s) => {
          s.setChildPropertyValueByBound(that.params.column.colDef.colCustomConfig.flightBound, connectedFlightPropertyName, that.checkedUpdated);
        }, JSUtils.decamelizeString(connectedFlightPropertyName, ' '), this.checkedOriginal, this.checkedUpdated);
      }
      return this.checkedUpdated;
    },
    keyEventPos($event) {
      if ($event.code === keyEvents.SPACE) {
        $event.stopPropagation();
      }
    },
  },
  created() {
    this.checkedOriginal = (this.params && this.params.value && this.params.value.value) ? this.params.value.value : false;
    this.checkedUpdated = this.checkedOriginal;

    this.markCellsSelectedAsEdit();
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$refs.refComp) {
        this.$refs.refComp.$refs.input.focus();
      }
    });
  },
});
</script>

<style scoped>
  .pos-flight-cell.ag-cell-inline-editing {
    min-width: 60px;
  }
  .centered-checkbox {
    margin: 0;
    padding: 25%;
  }
</style>
