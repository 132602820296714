export default class FlightServiceModel {
  constructor() {
    this.water = false;
    this.toilet = false;
    this.buses = false;
    this.delay = false;
    this.wchc = false;
    this.wchs = false;
    this.wchr = false;
    this.umnr = false;
  }
}
