<template>
  <v-container fluid grid-list-md >
    <v-layout row wrap>
      <directory-modal-edit
        v-if="dialogEdit"
        :dialog="dialogEdit"
        :item="itemEdit"
        @edit-confirm="editConfirm"
        @edit-reject="editReject"
        :icon="icon"
        :element="element"
      >
        <airport-edit-modal-content :item="itemEdit" />
      </directory-modal-edit>
      <v-flex xs4>
        <v-layout column>
          <v-card class="mb-3">
            <card-airport-header
              v-if="airportDirectoryFile"
              :item="airportDirectoryFile"
              @click-edit-item="editItem"
            />
            <v-container v-if="isEmpty(airportDirectoryFile)" fill-height>
              <v-layout row align-center>
                <v-flex xs12 text-xs-center>
                  <h3 class="subheading">There is no airport info</h3>
                  <v-btn @click="editItem(airportDirectoryFile)" flat color="primary">
                    <v-icon left>local_airport</v-icon>Edit airport
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-container>
            <card-info-single-column
              v-if="airportDirectoryFile && !isEmpty(airportDirectoryFile)"
              :item="airportDirectoryFile"
            />
            <card-description-text
              v-if="airportDirectoryFile && !isEmpty(airportDirectoryFile)"
              :item="airportDirectoryFile"
            />
          </v-card>
          <directory-menu @directory-menu-select="selectDirectory" :selectedDirectory="directorySelect"/>
        </v-layout>
      </v-flex>
      <v-flex xs8>
        <v-layout row wrap mx-2 mb-4>
          <contact-panel
            v-if="directorySelect === 'aiportContacts'"
            :items="airportContacts"
            icon="local_airport"
            title="Contact"
            msg="There is no airport contact info available."
            :speed-dial="enableSpeedDial"
            :deleteable="deleteable"
            tooltipTextAdd="Add new airport contact"
            tooltipOpenButton="Aiport contacts settings"
            @contact-update="contactUpdate"
            @contact-add="addContact($event, 'AIRPORT_CONTACT_CREATE')"
            @contact-delete="deleteContact($event, 'AIRPORT_CONTACT_DELETE')"
          />
          <contact-panel
            v-if="directorySelect === 'aiportGates'"
            :items="airportGatesContacts"
            icon="web"
            title="Gate"
            msg="There is no gate contact info available."
            @contact-update="contactUpdate"
          />
          <contact-panel
            v-if="directorySelect === 'checkinCounter'"
            :items="airportCheckInCounterContacts"
            icon="question_answer"
            title="CheckIn counter"
            msg="There is no gate contact info available."
            @contact-update="contactUpdate"
          />
          <expansion-panel v-if="!directorySelect" :departments="directoryFiles" />
        </v-layout>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import {
  GET_DIRECTORY,
  CREATE_DIRECTORY_FILE,
  UPDATE_DIRECTORY_FILE,
} from '@/store/modules/directory';
import {
  GET_AIRTPORT_GATE_CONTACT,
  AIRPORT_GATE_CONTACT_UPDATE,
} from '@/store/modules/airportGate/airportGateCatalog';
import {
  GET_AIRPORT_CHECK_IN_COUNTER_CONTACT,
  AIRPORT_CHECK_IN_COUNTER_CONTACT_UPDATE,
} from '@/store/modules/airportCheckInCounter/airportCheckInCounterCatalog';
import {
  GET_AIRPORT_CONTACT,
  AIRPORT_CONTACT_UPDATE,
} from '@/store/modules/airport/airportContactsCatalog';
import CardAirportHeader from './CardAirportHeader.vue';
import CardInfoSingleColumn from './CardInfoSingleColumn.vue';
import CardDescriptionText from './CardDescriptionText.vue';
import MemberCard from './MemberCard.vue';
import ExpansionPanel from './ExpansionPanel.vue';
import DirectoryModalEdit from './common/DirectoryModalEdit.vue';
import AirportEditModalContent from './common/AirportEditModalContent.vue';
import DirectoryMenu from './DirectoryMenu.vue';
import ContactPanel from './ContactPanel.vue';

export default {
  name: 'DirectoryBoard',
  components: {
    CardAirportHeader,
    CardInfoSingleColumn,
    CardDescriptionText,
    MemberCard,
    ExpansionPanel,
    DirectoryModalEdit,
    AirportEditModalContent,
    DirectoryMenu,
    ContactPanel,
  },
  data: () => ({
    dialogEdit: false,
    itemEdit: null,
    element: 'Airport',
    icon: 'local_airport',
    directorySelect: null,
    enableSpeedDial: true,
    deleteable: true,
  }),
  computed: {
    directoryFiles() {
      const directoryFiles = this.$store.getters.getDirectoryFiles;
      return directoryFiles.sort((a, b) => a.title.localeCompare(b.title));
    },
    airportDirectoryFile() {
      return this.$store.getters.getAirportDirectoryFile;
    },
    airportContacts() {
      return this.$store.getters.getAirportContacts;
    },
    airportGatesContacts() {
      return this.$store.getters.getAirportGates;
    },
    airportCheckInCounterContacts() {
      return this.$store.getters.getAirportCheckInCounters;
    },
  },
  methods: {
    getDirectory() {
      this.$store.dispatch(GET_DIRECTORY);
    },
    getAirportContacts() {
      this.$store.dispatch(GET_AIRPORT_CONTACT);
    },
    getAirportGatesContacts() {
      this.$store.dispatch(GET_AIRTPORT_GATE_CONTACT);
    },
    getAirportCheckInCounterContacts() {
      this.$store.dispatch(GET_AIRPORT_CHECK_IN_COUNTER_CONTACT);
    },
    editItem(item) {
      this.itemEdit = JSON.parse(JSON.stringify(item));
      this.dialogEdit = true;
    },
    addItem(item) {
      this.$store.dispatch(CREATE_DIRECTORY_FILE, item);
    },
    addContact(item, store) {
      this.$store.dispatch(store, item);
    },
    deleteContact(id, store) {
      this.$store.dispatch(store, id);
    },
    editConfirm(item) {
      this.itemEdit = null;
      this.$store.dispatch(UPDATE_DIRECTORY_FILE, item);
      this.dialogEdit = false;
    },
    contactUpdate(item) {
      switch (this.directorySelect) {
        case 'aiportContacts':
          this.$store.dispatch(AIRPORT_CONTACT_UPDATE, item);
          break;
        case 'aiportGates':
          this.$store.dispatch(AIRPORT_GATE_CONTACT_UPDATE, item);
          break;
        case 'checkinCounter':
          this.$store.dispatch(AIRPORT_CHECK_IN_COUNTER_CONTACT_UPDATE, item);
          break;
        default:
          break;
      }
    },
    editReject() {
      this.itemEdit = null;
      this.dialogEdit = false;
    },
    isEmpty(item) {
      const clone = Object.assign({}, item);
      delete clone.title;
      delete clone.id;
      delete clone.order;
      delete clone.map;
      delete clone.members;
      return Object.values(clone).every(i => i === null || i === '');
    },
    selectDirectory(element) {
      this.directorySelect = element;
    },
  },
  beforeMount() {
    this.getDirectory();
    this.getAirportContacts();
    this.getAirportGatesContacts();
    this.getAirportCheckInCounterContacts();
  },
};
</script>
