export default class MobileFilter {
  constructor(date, airport, aircraft, airline, tripNumber) {
    this.date = date;
    this.airport = airport;
    this.aircraft = aircraft;
    this.airline = airline;
    this.tripNumber = tripNumber;
  }

  static get createEmptyFilter() {
    return new MobileFilter(null, null, null, null, null);
  }
}
