<template>
  <v-progress-linear class="progress-subnav" v-show="loading" :indeterminate="true" height="3"></v-progress-linear>
</template>
<script>
export default {
  computed: {
    loading() {
      return this.$store.state.loading;
    },
  },
};
</script>
<style>
    .progress-subnav {
        margin-top: 0;
    }

    .v-progress-linear.progress-subnav {
      margin-bottom: 0px;
    }
</style>
