import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VLayout,{attrs:{"row":"","justify-center":""}},[_c(VDialog,{attrs:{"persistent":"","max-width":"80%","content-class":_vm.isContentVisible ? 'modal-common' : 'modal-common hide-title'},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,{attrs:{"dark":this.$vuetify.dark,"color":this.$vuetify.dark ? 'bggrey_dark' : 'bggrey'}},[_c(VToolbar,{attrs:{"dark":"","color":"primary"}},[_c(VIcon,[_vm._v(_vm._s(_vm.icon))]),_c(VToolbarTitle,[_vm._v(_vm._s(_vm.element)+" editor")]),_c(VSpacer),_c(VBtn,{attrs:{"icon":""},on:{"click":_vm.toggleContentVisibility}},[_c(VIcon,[_vm._v(_vm._s(_vm.isContentVisible ? 'mdi-chevron-up' : 'mdi-chevron-down'))])],1)],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isContentVisible),expression:"isContentVisible"}]},[_c('loading-component'),_vm._t("default",function(){return [_c(VFlex,{attrs:{"xs7":"","offset-xs5":"","offset-md5":"","offset-lg5":""}},[_vm._v(" No content ")])]}),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"flat":"","color":"primary"},nativeOn:{"click":function($event){return _vm.reject()}}},[_vm._v("CANCEL")]),_c(VBtn,{attrs:{"color":"error","depressed":"","disabled":_vm.loading},nativeOn:{"click":function($event){return _vm.confirm()}}},[_vm._v("SAVE")])],1)],2)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }