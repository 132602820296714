import { TemporalUnit } from '../../ui/temporalUnit/temporalUnit';

export default class TicketingFilter {
  constructor(
    dateFrom,
    dateTo,
    airline,
    airport,
    status,
    airlineFare,
    airlines,
    tenants,
    temporalAggregationUnit,
    excludeCancelled,
    unitOfTime,
    createdBy,
  ) {
    this.dateFrom = dateFrom;
    this.dateTo = dateTo;
    this.airline = airline;
    this.airport = airport;
    this.status = status;
    this.airlineFare = airlineFare;
    this.airlines = airlines;
    this.tenants = tenants;
    this.temporalAggregationUnit = temporalAggregationUnit;
    this.excludeCancelled = excludeCancelled;
    this.unitOfTime = unitOfTime;
    this.createdBy = createdBy;
  }

  static get createEmptyFilter() {
    return new TicketingFilter(
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      TemporalUnit.DAY,
      null,
      null,
      null,
    );
  }

  static createFromData(json) {
    if (!json) {
      return this.createEmptyFilter;
    }
    return new TicketingFilter(
      json.dateFrom,
      json.dateTo,
      json.airline,
      json.airport,
      json.status,
      json.airlineFare,
      json.airlines,
      json.tenants,
      json.temporalAggregationUnit,
      json.excludeCancelled,
      json.unitOfTime,
      json.createdBy,
    );
  }
}
