<template>
  <v-dialog v-model="dialogValue" max-width="1370px" persistent
    :content-class="isContentVisible ? 'modal-common' : 'modal-common hide-title'">
    <v-card :dark="this.$vuetify.dark" :color="this.$vuetify.dark ? 'bggrey_dark' : 'bggrey'">
      <v-toolbar dark color="primary">
        <img height="30" :src="require('@/assets/svg-icons/flights-w.svg')">
        <v-toolbar-title>{{ $t("scheduling.newLongTermFlights") }}</v-toolbar-title>
        <v-spacer></v-spacer>

        <v-btn-toggle class="max-width-btn-toggle-header" v-model="flightBound">
          <v-tooltip bottom>
            <v-btn flat value="ANY" justify-end="true" slot="activator">
              <v-icon>flight_land</v-icon>
              <v-icon>flight_takeoff</v-icon>
            </v-btn>
            <span>{{ $t("scheduling.scheduledINandOut") }}</span>
          </v-tooltip>
          <span class="separator-btn-header"></span>
          <v-tooltip bottom>
            <v-btn flat value="INBOUND" slot="activator">
              <v-icon>flight_land</v-icon>
            </v-btn>
            <span>{{ $t("scheduling.scheduledIN") }}</span>
          </v-tooltip>
          <span class="separator-btn-header"></span>
          <v-tooltip bottom>
            <v-btn flat value="OUTBOUND" slot="activator">
              <v-icon>flight_takeoff</v-icon>
            </v-btn>
            <span>{{ $t("scheduling.scheduledOUT") }}</span>
          </v-tooltip>
        </v-btn-toggle>
        <!-- Botón para contraer/expandir el contenido -->
        <v-btn icon @click="toggleContentVisibility">
          <v-icon>{{ isContentVisible ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
        </v-btn>
      </v-toolbar>
      <div v-show="isContentVisible">

        <loading-component />

        <v-card-text>

          <v-form ref="form" v-model="valid">
            <v-container grid-list-md text-xs-center fluid>
              <v-layout row wrap>

                <v-flex xs12>
                  <scheduling-long-term-form :days-of-week.sync="daysOfWeek" :start-date.sync="startDate"
                    :end-date.sync="endDate" :frequency.sync="frequency"></scheduling-long-term-form>
                </v-flex>

                <v-flex xs12>
                  <scheduling-flight-form :edited-flight="inboundFlight" :is-long-term="true" type="INBOUND"
                    @flight-update="updateInboundFlight" :disabled="flightBound === 'OUTBOUND'"
                    :scheduledTimeError="scheduledTimeError" />
                </v-flex>

                <v-flex xs12>
                  <scheduling-flight-form :edited-flight="outboundFlight" :is-long-term="true" type="OUTBOUND"
                    @flight-update="updateOutboundFlight" :disabled="flightBound === 'INBOUND'"
                    :scheduledTimeError="scheduledTimeError" />
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>

        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" flat @click.native="close">{{ $t("common.cancel") }}</v-btn>
          <v-btn color="primary" depressed @click.native="save">{{ $t("common.save") }}</v-btn>
        </v-card-actions>
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
import SchedulingFlightForm from './SchedulingFlightForm.vue';
import LoadingComponent from '../../layout/LoadingComponent.vue';
import SchedulingLongTermForm from './SchedulingLongTermForm.vue';
import DaysOfWeekModel from '../model/daysOfWeek';
import LongTermSchedulingFlight from '../model/longTermSchedulingFlight';
import { NOTIFICATION_SHOW } from '../../../store/modules/notifications';
import { REQUEST_ERROR, REQUEST_START, REQUEST_SUCCESS } from '../../../store/store';
import FlightCommonInformation from '../model/flightCommonInformation';

export default {
  name: 'scheduling-long-term-modal',
  components: {
    SchedulingLongTermForm,
    SchedulingFlightForm,
    LoadingComponent,
  },
  props: {
    dialog: { type: Boolean, required: true },
  },
  data() {
    return {
      valid: true,
      selectedBound: '',
      inboundFlight: FlightCommonInformation.createEmptyInboundFlight,
      outboundFlight: FlightCommonInformation.createEmptyOutBoundFlight,
      daysOfWeek: new DaysOfWeekModel(),
      startDate: null,
      endDate: null,
      frequency: 1,
      isContentVisible: true,
    };
  },
  computed: {
    dialogValue: {
      get() {
        return this.dialog;
      },
      set() { },
    },
    flightBound: {
      get() {
        if (this.selectedBound) {
          return this.selectedBound;
        }
        return 'ANY';
      },
      set(bound) {
        this.selectedBound = bound;
        this.$refs.form.reset();
      },
    },
    scheduledTimeError() {
      if (this.inboundFlight.scheduledTime && this.outboundFlight.scheduledTime) {
        return this.$moment(this.outboundFlight.scheduledTime).isBefore(this.$moment(this.inboundFlight.scheduledTime));
      }
      return false;
    },
  },
  methods: {
    updateInboundFlight(flight) {
      this.inboundFlight = flight;
      if (!this.outboundFlight.aircraftId) {
        this.outboundFlight.aircraftId = flight.aircraftId;
      }
      if (!this.outboundFlight.aircraftTypeId) {
        this.outboundFlight.aircraftTypeId = flight.aircraftTypeId;
      }
      if (!this.outboundFlight.aircraftConfigId) {
        this.outboundFlight.aircraftConfigId = flight.aircraftConfigId;
      }
      if (!this.outboundFlight.airlineId) {
        this.outboundFlight.airlineId = flight.airlineId;
      }
      if (!this.outboundFlight.airlineOperationId) {
        this.outboundFlight.airlineOperationId = flight.airlineOperationId;
      }
      if (!this.outboundFlight.handlingTypeId) {
        this.outboundFlight.handlingTypeId = flight.handlingTypeId;
      }
    },
    updateOutboundFlight(flight) {
      this.outboundFlight = flight;
    },
    save() {
      if (this.$refs.form.validate()) {
        const longtermFlight = LongTermSchedulingFlight.createEmtpyLongTermSchedulingFlight;
        longtermFlight.addOutboundFlight(this.outboundFlight);
        longtermFlight.addInboundFlight(this.inboundFlight);
        longtermFlight.initDate = this.startDate;
        longtermFlight.finishDate = this.endDate;
        longtermFlight.setDaysOfWeek(this.daysOfWeek);
        longtermFlight.frequency = this.frequency;

        if (longtermFlight.inboundScheduledTime) {
          longtermFlight.inboundScheduledTime = this.$moment(longtermFlight.inboundScheduledTime).format('HH:mm');
        }

        if (longtermFlight.outboundScheduledTime) {
          longtermFlight.outboundScheduledTime = this.$moment(longtermFlight.outboundScheduledTime).format('HH:mm');
        }

        this.$store.commit(REQUEST_START);
        const promise = this.$http.post('long-term-schedulings', longtermFlight);
        promise
          .then(() => {
            this.close({ doFind: true });
            this.$store.commit(NOTIFICATION_SHOW, {
              message: this.$i18n.t('scheduling.messageSuccess'),
              color: 'success',
            });
            this.$store.commit(REQUEST_SUCCESS);
          })
          .catch((err) => {
            this.close();
            this.$store.commit(NOTIFICATION_SHOW, {
              message: this.$i18n.t('notifications.errorNotification'),
              color: 'error',
            });
            this.$store.commit(REQUEST_ERROR, err);
          });
      }
    },
    close(result) {
      this.$emit('close', result);
    },
    toggleContentVisibility() {
      this.isContentVisible = !this.isContentVisible;
    },
  },
};
</script>
<style scoped>
.max-width-btn-toggle-header {
  max-width: 146px;
}

.separator-btn-header {
  background-color: red;
  width: 3px;
}
</style>
