import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VChip } from 'vuetify/lib/components/VChip';
import { VListTileContent } from 'vuetify/lib/components/VList';
import { VListTileSubTitle } from 'vuetify/lib/components/VList';
import { VListTileTitle } from 'vuetify/lib/components/VList';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VAutocomplete,{ref:_vm.refComp,attrs:{"placeholder":_vm.placeholder,"value":_vm.value,"label":_vm.label,"items":_vm.items,"filter":_vm.customFilter,"clearable":true,"item-text":"code","item-value":"id","rules":_vm.rules,"disabled":_vm.disabled,"multiple":_vm.multiple},on:{"input":_vm.inputMilestone},scopedSlots:_vm._u([(_vm.chipView)?{key:"selection",fn:function(data){return [_c(VChip,{staticClass:"chip--select-multi",attrs:{"selected":data.selected,"close":""},on:{"input":function($event){return _vm.remove(data.item)}}},[_vm._v(" "+_vm._s(data.item.name)+" ")])]}}:null,{key:"item",fn:function({ item, tile }){return [_c(VListTileContent,[_c(VListTileTitle,{domProps:{"textContent":_vm._s(item.name)}}),(item.description)?_c(VListTileSubTitle,{domProps:{"textContent":_vm._s(item.description)}}):_vm._e()],1)]}}],null,true)})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }