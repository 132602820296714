<template>
  <v-container>
    <v-expansion-panel>
      <v-expansion-panel-content class="exp-header">
        <template v-slot:actions>
          <v-icon color="white">mdi-menu-down</v-icon>
        </template>
        <template v-slot:header>
          <div>
            <h4 class="headline font-weight-light"><v-icon dark left>hotel</v-icon>{{$t("contingencies.transportAndHotels")}}</h4>
          </div>
        </template>
        <ag-grid-contingency-form-template>

          <template v-slot:default>
            <v-card>
              <v-card-text class="tow-table">
                <ag-grid-vue
                  class="ag-theme-material"
                  :columnDefs="columnDefs"
                  :defaultColDef="defaultColDef"
                  @grid-ready="onGridReady"
                  :gridOptions="gridOptions"
                  :suppressHorizontalScroll="true"
                  rowSelection="multiple"
                  :frameworkComponents="frameworkComponents"
                  @selection-changed="onSelectionChanged"
                  :rowData="contingencyHtlTransport">
                </ag-grid-vue>
              </v-card-text>
            </v-card>
          </template>

          <template v-slot:footer v-if="hasPermissions(rolePermission)">
            <v-btn color="secondary" flat @click="addContingencyHtlTransport()"><v-icon>add</v-icon></v-btn>
            <v-btn v-show="contingencyHtlTransportSelected.length > 0" color="error" flat @click="removeContingencyHtlTransport()"><v-icon>delete</v-icon></v-btn>
            <v-spacer></v-spacer>
          </template>
        </ag-grid-contingency-form-template>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-container>
</template>

<script>


import { AgGridVue } from 'ag-grid-vue';
import AgGridOptionsConfigurable from '../../ag-grid/mixins/AgGridOptionsConfigurable.vue';
import AgGridApiHoldable from '../../ag-grid/mixins/AgGridApiHoldable.vue';
import { cellClassAgGridRules } from '../../ag-grid/mixins/cellClassAgGridRules';
import ContingencyHtlTransport from '../model/contingencyHtlTransport';
import { FLIGHT_CONTINGENCY_HTL_TRANSPORT_SET_GRID_API } from '../../../store/modules/contingency/contingencyHtlTransport';
import AgGridContingencyFormTemplate from '../common/AgGridContingencyFormTemplate.vue';
import agGridTextCustomFilterComparator from '../../ag-grid/filters/agGridTextCustomFilterComparator';
import agGridTextCustomFilterFormatter from '../../ag-grid/filters/agGridTextCustomFilterFormatter';
import Saveable from '../../mixins/Saveable.vue';
import RolePermissions from '../../mixins/RolePermissions.vue';

export default {
  name: 'contingency-htl-transport-form',
  mixins: [AgGridOptionsConfigurable, AgGridApiHoldable, Saveable, RolePermissions],
  components: {
    AgGridContingencyFormTemplate,
    AgGridVue,
  },
  props: {
    editedContingencyHtlTransport: { required: true },
  },
  computed: {
    rolePermission() {
      return `${this.$route.meta.role}_WR`;
    },
    crudOperationsDto() {
      return this.$store.state.contingencyHtlTransport.crudOperationsDto;
    },
  },
  data() {
    const that = this;
    const rolePermission = this.getRoles();
    return {
      module: null,
      contingencyHtlTransport: this.editedContingencyHtlTransport,
      frameworkComponents: {
      },
      defaultColDef: {
        resizable: true,
        sortable: true,
        filter: true,
        filterParams: {
          textCustomComparator: agGridTextCustomFilterComparator,
          textFormatter: agGridTextCustomFilterFormatter,
        },
        cellClassRules: cellClassAgGridRules,
      },
      gridOptions: {
        getRowClass: this.getRowClass,
      },
      validData: true,
      columnDefs: [
        {
          checkboxSelection: true,
          hide: !this.hasPermissions(rolePermission),
          width: 36,
          editable: this.editable(true, rolePermission),
        },
        {
          field: 'passengerName',
          width: 800,
          headerValueGetter() {
            return that.$i18n.t('contingencies.passageName');
          },
          editable: this.editable(true, rolePermission),
        },
        {
          field: 'hotelAddress',
          width: 850,
          headerValueGetter() {
            return that.$i18n.t('contingencies.hotelAddress');
          },
          editable: this.editable(true, rolePermission),
        },
      ],
      contingencyHtlTransportSelected: [],
    };
  },
  watch: {
    editedContingencyHtlTransport: {
      handler(newContingencyHtlTransport) {
        this.contingencyHtlTransport = newContingencyHtlTransport;
      },
      deep: true,
    },
  },
  methods: {
    onGridReady(params) {
      this.setAgGridApi(params);
      this.$store.commit(FLIGHT_CONTINGENCY_HTL_TRANSPORT_SET_GRID_API, params); // TODO eliminar api del store

      this.gridApi.sizeColumnsToFit();
      this.refreshCellsOnChangeLanguage();
    },
    resized() {
      if (this.gridApi) {
        this.gridApi.sizeColumnsToFit();
      }
    },
    save() {
      this.gridApi.stopEditing();
      this.crudOperationsDto.updates.push(...this.contingencyHtlTransport);
      this.$emit('contingency-htl-transport-update', this.crudOperationsDto);
    },
    onSelectionChanged(event) {
      if (event.api.getSelectedNodes().length > 0) {
        this.contingencyHtlTransportSelected = event.api.getSelectedNodes().map(n => n.data);
      } else {
        this.contingencyHtlTransportSelected = [];
      }
    },
    addContingencyHtlTransport() {
      this.contingencyHtlTransport.push(ContingencyHtlTransport.createContingencyHtlTransport(this.$route.params.contingencyId));
    },
    removeContingencyHtlTransport() {
      if (this.contingencyHtlTransportSelected.length > 0) {
        this.crudOperationsDto.deletes.push(...this.contingencyHtlTransportSelected.filter(contingency => !!contingency.id));

        this.contingencyHtlTransportSelected.forEach((contingency) => {
          const index = this.contingencyHtlTransport.indexOf(contingency);
          this.contingencyHtlTransport.splice(index, 1);
        });
        this.contingencyHtlTransportSelected = [];
      }
    },
  },
};
</script>

<style scoped lang="scss">
.exp-header {
  background: #FF4021 !important;
  color: white !important;
  border-bottom: 1px solid white;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
}
</style>
