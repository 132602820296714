<template>
  <div>
    <v-card v-if="!item.idAirport">
      <v-card-title>{{ $t("configuration.addMilestoneGlobal") }}</v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-container style="margin-bottom: 0; padding-bottom: 0">
          <milestone-select :label="this.$i18n.t('configuration.selectMilestoneGlobal')" :multiple=true :chip-view=true
            :value="item.globalMilestones" @input="changeMilestones" :disabled="!hasPermissions(rolePermission)" />
        </v-container>
      </v-card-text>
    </v-card>
    <v-card>
      <v-card-title v-if="!item.idAirport">{{ $t("configuration.addMilestoneAirport") }}</v-card-title>
      <v-divider v-if="!item.idAirport"></v-divider>
      <v-card-text>
        <v-container style="margin-bottom: 0; padding-bottom: 0">
          <v-layout style="margin-bottom: 0; padding-bottom: 0">
            <v-flex style="min-width: 50vw; max-width: 50vw">
              <v-text-field :label="this.$i18n.t('configuration.name')" v-model="item.name"
                :readonly="!hasPermissions(rolePermission)"
                />
            </v-flex>
          </v-layout>
          <v-layout>
            <v-flex xs12 class="input-milestone-types" style="min-width: 50vw; max-width: 50vw">
              <v-text-field :label="this.$i18n.t('configuration.description')" v-model="item.description"
                :readonly="!hasPermissions(rolePermission)" />
            </v-flex>
          </v-layout>
          <v-layout>
            <v-flex style="min-width: 30vw; max-width: 30vw">
              <v-select v-model="item.type"
                        :items="Object.keys(items)"
                        :label="this.$i18n.t('configuration.type')"
                        :disabled="!hasPermissions(rolePermission)"
                      >
                <template slot="selection" slot-scope="{ item }">
                  <span>
                    {{ formatUnitsType(item) }}
                  </span>
                </template>
                <template slot="item" slot-scope="{ item }">
                  <span>
                    {{ formatUnitsType(item) }}
                  </span>
                </template>
              </v-select>
            </v-flex>
          </v-layout>
          <v-layout>
            <v-flex xs4 style="min-width: 30vw; max-width: 30vw">
              <orientation-select :useFlightBoundInsteadOfOrientationType="true"
              :label="$i18n.t('common.orientation')" v-model="item.flightBound" :disabled="!hasPermissions(rolePermission)"></orientation-select>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import RolePermissions from '../../mixins/RolePermissions.vue';
import TypeFieldSelect from '../milestonesGlobal/TypeFieldSelect.vue';
import MilestoneSelect from '../../milestones/MilestoneSelect.vue';
import { MilestoneTypes } from '../../shared/model/milestoneTypes';
// eslint-disable-next-line
import OrientationSelect from '../../../components/rampService/OrientationSelect.vue';

export default {
  name: 'AirportMilestoneEditCardContent',
  mixins: [RolePermissions],
  props: {
    item: { type: Object, required: true },
  },
  components: {
    TypeFieldSelect,
    MilestoneSelect,
    OrientationSelect,
  },
  data() {
    return {
      items: MilestoneTypes,
      rules: {
        required: value => !!value || this.$i18n.t('common.fieldRequired'),
      },
    };
  },
  computed: {
    rolePermission() {
      return `${this.$route.meta.role}_WR`;
    },
    unitTypes() {
      return [...Array(this.items.length).keys()];
    },
  },
  methods: {
    formatUnitsType(item) {
      return this.$i18n.t(`configuration.${this.items[item]}`);
    },
    changeMilestones(milestones) {
      this.item.globalMilestones = milestones.map(a => ({ id: a }));
    },
  },
};
</script>
