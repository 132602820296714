<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-navigation-drawer :clipped="$vuetify.breakpoint.lgAndUp" v-model="showing" fixed app class="sidebar-background"
    @transitionend="$store.dispatch('SCHED_GRID_FIT')" hide-overlay>
    <v-list dense>
      <template v-for="(item, index) in items">
        <v-list-group v-if="item.items && canBeShown(item)" :key="item.text" v-model="item.active" no-action>
          <template v-slot:activator>
            <v-list-tile>
              <v-list-tile-action>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-tile-action>
              <v-list-tile-content>
                <v-list-tile-title>{{ $t(`sidebar.${item.text}`) }}</v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
          </template>
          <template v-for="subItem in item.items">
            <v-list-tile v-if="canBeShown(subItem)" :key="subItem.title" :to="{ name: subItem.link }"
              @click="closeSideBar()">
              <v-list-tile-content>
                <v-list-tile-title>{{ $t(`sidebar.${subItem.text}`) }}</v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
          </template>
        </v-list-group>


        <v-list-tile v-else-if="canBeShown(item)" :key="index" :to="{ name: item.link }" @click="closeSideBar()">
          <v-list-tile-action>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title>{{ $t(`sidebar.${item.text}`) }}</v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>
<script>
import {
  ROLE_SCHEDULING,
  ROLE_ADMINISTRATOR_TICKETING,
  ROLE_AVINET,
  ROLE_BAGGAGE,
  ROLE_BUSES, ROLE_CASHIER_TICKETING,
  ROLE_CONFIG,
  ROLE_CONTINGENCY,
  ROLE_DELAYS,
  ROLE_DELIVERIES,
  ROLE_DIRECTORY,
  ROLE_DISPATCHER,
  ROLE_DUTY,
  ROLE_FILE_NUMBER,
  ROLE_FLIGHTS_WEIGHT,
  ROLE_GANTT,
  ROLE_GHM,
  ROLE_IATA,
  ROLE_INBOUNDS,
  ROLE_INFORM,
  ROLE_INVOICING,
  ROLE_LOST_FOUND,
  ROLE_OPERATIONS,
  ROLE_OUTBOUNDS,
  ROLE_RUSH_BAGGAGE,
  ROLE_SUPERVISOR_TICKETING,
  ROLE_WATER_TOILET,
  ROLE_TOWING_ADHOC_TASKS_APPLICANT,
  ROLE_TOWING_ADHOC_TASKS_DISPATCHER,
} from '../../constants/roles';

export default {
  name: 'sidebar',
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      // eslint-disable-next-line
      //i18n
      showing: this.value,
      items: [
        { icon: 'home', text: 'Home', link: 'home' },
        {
          icon: 'schedule',
          text: 'Schedule',
          // link: 'scheduling',
          items: [
            {
              icon: 'schedule', text: 'Schedule', link: 'scheduling', role: ROLE_SCHEDULING,
            },
            {
              icon: 'settings', text: 'importAdministration', link: 'adminImports', role: ROLE_SCHEDULING,
            },
            {
              icon: 'tune', text: 'Flights Weighting', link: 'schedulingWeighting', role: ROLE_FLIGHTS_WEIGHT,
            },
          ],
        },
        {
          icon: 'event_note',
          text: 'Operations',
          items: [
            {
              icon: 'event_note', text: 'Operations', link: 'operations', role: ROLE_OPERATIONS,
            },
            {
              icon: 'timer', text: 'Delays', link: 'delays', role: ROLE_DELAYS,
            },
            {
              icon: 'event-note', text: 'Mobile', link: 'mobile', role: ROLE_DISPATCHER,
            },
          ],
        },
        {
          icon: 'build',
          text: 'Ramp',
          link: 'ramp',
          items: [
            {
              icon: 'build', text: 'Duty', link: 'rampDuty', role: ROLE_DUTY,
            },
            {
              icon: 'build', text: 'Baggage', link: 'rampBaggage', role: ROLE_BAGGAGE,
            },
            {
              icon: 'build', text: 'Buses', link: 'rampBuses', role: ROLE_BUSES,
            },
            {
              icon: 'build', text: 'Water and Toilet', link: 'rampWaterToilet', role: ROLE_WATER_TOILET,
            },
          ],
        },
        {
          icon: 'people',
          text: 'Passage',
          link: 'passage',
          items: [
            {
              icon: 'people', text: 'Inbounds', link: 'passageInbounds', role: ROLE_INBOUNDS,
            },
            {
              icon: 'people', text: 'Outbounds', link: 'passageOutbounds', role: ROLE_OUTBOUNDS,
            },
          ],
        },
        {
          icon: 'next_week',
          text: 'Lost And Found',
          items: [
            {
              icon: 'next_week', text: 'Lost And Found', link: 'lostAndFound', role: ROLE_LOST_FOUND,
            },
            {
              icon: 'local_shipping', text: 'Deliveries', link: 'deliveries', role: ROLE_DELIVERIES,
            },
            {
              icon: 'local_shipping', text: 'Rush Baggage', link: 'rushBaggage', role: ROLE_RUSH_BAGGAGE,
            },
          ],
        },
        {
          icon: 'rv_hookup',
          text: 'towingAdHocTask',
          items: [
            {
              icon: 'rv_hookup', text: 'flightsList', link: 'towingAdhocTaskFlights', role: [ROLE_TOWING_ADHOC_TASKS_APPLICANT, ROLE_TOWING_ADHOC_TASKS_DISPATCHER],
            },
            {
              icon: 'rv_hookup', text: 'towingAdHocTask', link: 'towingAdhocTask', role: [ROLE_TOWING_ADHOC_TASKS_APPLICANT, ROLE_TOWING_ADHOC_TASKS_DISPATCHER],
            },
          ],
        },
        {
          icon: 'clear_all', text: 'Gantt', link: 'rampGantt', role: ROLE_GANTT, // clear_all o timer
        },
        {
          icon: 'bookmarks',
          text: 'Invoicing',
          items: [
            {
              icon: 'bookmarks', text: 'Invoicing', link: 'invoicing', role: ROLE_INVOICING,
            },
            {
              icon: 'bookmarks', text: 'invoicing-ghs-integration', link: 'invoicingIntegration', role: ROLE_INVOICING,
            },
            {
              icon: 'bookmarks', text: 'importRampServiceFile', link: 'invoicingImportRampService', role: ROLE_INVOICING,
            },
          ],
        },
        {
          icon: 'new_releases', text: 'Contingency', link: 'contingencyManagement', role: ROLE_CONTINGENCY,
        },
        {
          icon: 'confirmation_number',
          text: 'Ticketing',
          items: [
            {
              icon: 'text_snippet', text: 'ticketingDesk', link: 'ticketing', role: [ROLE_ADMINISTRATOR_TICKETING, ROLE_CASHIER_TICKETING, ROLE_SUPERVISOR_TICKETING],
            },
            {
              icon: 'text_snippet', text: 'ticketingMobile', link: 'ticketingMobile', role: [ROLE_ADMINISTRATOR_TICKETING, ROLE_CASHIER_TICKETING, ROLE_SUPERVISOR_TICKETING],
            },
          ],
          role: [ROLE_ADMINISTRATOR_TICKETING, ROLE_CASHIER_TICKETING, ROLE_SUPERVISOR_TICKETING],
        },
        {
          icon: 'text_snippet',
          text: 'Ticketing reports',
          items: [
            {
              icon: 'text_snippet', text: 'employeesCollection', link: 'employeeIncomeTicketingReport', role: ROLE_ADMINISTRATOR_TICKETING,
            },
            {
              icon: 'text_snippet', text: 'airportsCollection', link: 'airportIncomeTicketingReport', role: ROLE_ADMINISTRATOR_TICKETING,
            },
            {
              icon: 'text_snippet', text: 'tallyingCash', link: 'tallyingCashTicketingReport', role: [ROLE_CASHIER_TICKETING, ROLE_ADMINISTRATOR_TICKETING],
            },
          ],
        },
        {
          icon: 'widgets', text: 'Configuration', link: 'configuration', role: ROLE_CONFIG,
        },
        {
          icon: 'settings',
          text: 'Administration',
          items: [
            {
              icon: 'settings', text: 'Inform', link: 'adminInform', role: ROLE_INFORM,
            },
            {
              icon: 'settings', text: 'airportIntegrations', link: 'adminAirportIntegrations', role: ROLE_GHM,
            },
            {
              icon: 'settings', text: 'Avinet', link: 'adminAvinet', role: ROLE_AVINET,
            },
            {
              icon: 'message', text: 'Flight messages', link: 'messages', role: ROLE_IATA,
            },
            {
              icon: 'settings', text: 'File Number', link: 'adminFileNumber', role: ROLE_FILE_NUMBER,
            },
          ],
        },
        {
          icon: 'account_circle', text: 'Directory', link: 'directory', role: ROLE_DIRECTORY,
        },
        { icon: 'help', text: 'Help', link: 'help' },
      ],
    };
  },
  watch: {
    value: {
      handler(showing) {
        this.showing = showing;
      },
    },
  },
  methods: {
    closeSideBar() {
      setTimeout(() => {
        this.showing = false;
        this.$emit('input', this.showing);
      }, 200);
    },
    canBeShown(item) {
      if (!item) {
        return false;
      }
      if (item.items) {
        return item.items.some(e => this.$store.getters.hasRole(e.role));
      }
      if (!item.role) {
        return true;
      }
      return this.$store.getters.hasRole(item.role);
    },
  },
};
</script>
<style lang="scss">
$shadowColor: #b8b9ba;

.dark-mode .sidebar-background {
  filter: invert(1) hue-rotate(180deg) ;
}

.sidebar-background {
  background-image: url(../../assets/sidebar-bg.jpg);
  background-position-x: 50%;
  background-size: cover;
}

.sidebar-background:after {
  background: #FFF;
  opacity: .8;
}

.sidebar-background {
  -webkit-box-shadow: 10px -3px 9px -9px $shadowColor;
  -moz-box-shadow: 10px -3px 9px -9px $shadowColor;
  box-shadow: 10px -3px 9px -9px $shadowColor;
}
</style>
