import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VLayout,{attrs:{"row":"","justify-center":""}},[_c(VDialog,{attrs:{"persistent":"","max-width":"1370px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,{attrs:{"dark":this.$vuetify.dark,"color":this.$vuetify.dark ? 'bggrey_dark' : 'bggrey'}},[_c(VToolbar,{attrs:{"dark":"","color":"primary"}},[_c(VIcon,[_vm._v("attach_file")]),_c(VToolbarTitle,[_vm._v(_vm._s(_vm.$t("scheduling.importFlightsFromFile")))])],1),_c('loading-component'),_c(VCardText,[_c('scheduling-import-stepper',{on:{"import-stepper-cancel":_vm.reject,"import-stepper-confirm":_vm.confirm}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }