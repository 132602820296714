<template>
  <div class="scheduling list-filter">
    <v-card :dark="this.$vuetify.dark" :color="this.$vuetify.dark ? 'bggrey_dark' : 'bggrey'">
      <v-toolbar dark dense color="primary lighten-1">
        <v-btn v-on:click="displayedFilter" small flat>
          <v-icon>filter_list</v-icon>
          <v-icon v-if="!showFilter">keyboard_arrow_down</v-icon>
          <v-icon v-if="showFilter">keyboard_arrow_up</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-flex grow="false" shrink="true">
          <v-btn-toggle v-model="unitOfTime" class="transparent" @change="filterChanged">
            <v-tooltip left>
              <v-btn value="day" flat slot="activator">{{ $t("commonFilter.filterD") }}</v-btn>
              <span>{{ $t("commonFilter.filterDay") }}</span>
            </v-tooltip>
            <v-tooltip left>
              <v-btn value="isoWeek" flat slot="activator">{{ $t("commonFilter.filterW") }}</v-btn>
              <span>{{ $t("commonFilter.filterWeek") }}</span>
            </v-tooltip>
            <v-tooltip left>
              <v-btn value="month" flat slot="activator">{{ $t("commonFilter.filterM") }}</v-btn>
              <span>{{ $t("commonFilter.filterMonth") }}</span>
            </v-tooltip>
            <v-tooltip left>
              <v-btn value="year" flat slot="activator">{{ $t("commonFilter.filterY") }}</v-btn>
              <span>{{ $t("commonFilter.filterYear") }}</span>
            </v-tooltip>
          </v-btn-toggle>
        </v-flex>
        <v-flex class="ml-2" grow="false" shrink="true">
          <scheduling-flights-saved-queries />
        </v-flex>
        <v-flex grow="false" shrink="true">
          <audit-flights-changes-list />
        </v-flex>
        <SchedulingListTableOptionsFilterButton />
      </v-toolbar>
      <v-container fluid v-if="showFilter" class="pa-2 pb-2">
        <v-layout class="filter-inline" align-center justify-start>
          <v-flex class="mr-2" align-center justify-start>
            <date-input v-model="filter.dateFrom" :locale="$i18n.locale" :label="$t('common.fromDate')" class="pb-2"
              @change="changeDateFrom"></date-input>
            <date-input v-model="filter.dateTo" :locale="$i18n.locale" :label="$t('common.toDate')"
              @change="changeDateTo"></date-input>
          </v-flex>
          <v-flex grow="false" shrink="true">
            <days-of-week v-model="filter.daysOfWeek"></days-of-week>
            <v-text-field v-model="filter.aircraftRegisterNumber" label="Reg" placeholder="..." class="pt-3"
              hide-details></v-text-field>
          </v-flex>
          <v-flex class="text-no-wrap" align-center justify-start>
            <aircraft-type-select class="ml-2 pb-2" :label="$t('common.type')"
              v-model="filter.aircraftType"></aircraft-type-select>
            <aircraft-config-select class="ml-2" :label="$t('common.config')"
              v-model="filter.aircraftConfig"></aircraft-config-select>
          </v-flex>
          <v-flex class="text-no-wrap" align-center justify-start>
            <v-text-field class="ml-2 pb-2" v-model="filter.flight" :label="$t('common.flight')" placeholder="..."
              hide-details></v-text-field>
            <airline-select class="ml-2" label="Op" v-model="filter.airline"></airline-select>
          </v-flex>
          <v-flex class="text-no-wrap" align-center justify-start>
            <!-- STA Time-->
            <time-input class="ml-2 pb-2" :label="$t('common.scheduledTimeArrival')" v-model="filter.sta"
              placeholder="hh:mm"></time-input>
            <time-input class="ml-2" :label="$t('common.scheduledTimeOfDeparture')" v-model="filter.std"
              placeholder="hh:mm"></time-input>
          </v-flex>
          <v-flex class="text-no-wrap" align-center justify-start>
            <!-- HT -->
            <handling-type-select class="ml-2 pb-2" label="HT" v-model="filter.handlingType"></handling-type-select>
            <status-select class="ml-2" label="Stat" v-model="filter.status" />
          </v-flex>
          <v-flex class="ml-2">
            <airport-select icon="flight_land" :label="$t('common.from')" v-model="filter.airportFrom"
              class="pb-2"></airport-select>
            <airport-select icon="flight_takeoff" :label="$t('common.to')" v-model="filter.airportTo"></airport-select>
          </v-flex>
          <!-- Hide checkboxes for now
          <v-flex class="ml-2 check-filters text-no-wrap" align-center justify-start>
            <v-switch hide-details slot="activator" color="red" :label="this.$i18n.t('commonFilter.showOldValues')"
              v-model="showOldValues" @change="updateShowOldValuesInStore()" class="mt-0 pb-3">
            </v-switch>
            <v-switch hide-details slot="activator" color="red" :label="this.$i18n.t('commonFilter.flashOnChanges')"
              v-model="flashOnChanges" @change="updateFlashOnChangesInStore()" class="mt-0">
            </v-switch>
          </v-flex>
          -->
          <v-spacer></v-spacer>
          <v-flex class="ml-2 text-no-wrap" align-center justify-start>
            <div align-left>
              <v-menu left offset-y>
                <template v-slot:activator="{ on }">
                  <v-btn color="secondary" small flat round v-on="on" block class="mb-4">
                    <v-icon>mdi-download</v-icon>
                    {{ $t("common.export") }}
                  </v-btn>
                </template>
                <v-list>
                  <v-list-tile-content>
                    <v-list-tile-sub-title>
                      <v-btn color="secondary" small flat v-on:click.stop="exportSchedulingCsv">
                        <v-icon>mdi-download</v-icon>
                        {{ $t("scheduling.exportSchedulingCsv") }}
                      </v-btn>
                    </v-list-tile-sub-title>
                    <v-list-tile-sub-title>
                      <v-btn color="secondary" small flat v-on:click.stop="exportSchedulingExcel">
                        <v-icon>mdi-download</v-icon>
                        {{ $t("scheduling.exportSchedulingExcel") }}
                      </v-btn>
                    </v-list-tile-sub-title>
                    <v-list-tile-sub-title>
                      <v-btn color="secondary" small flat v-on:click.stop="askForDownload">
                        <v-icon>mdi-download</v-icon>
                        {{ $t("scheduling.exportSchedulingGSPlanning") }}
                      </v-btn>
                    </v-list-tile-sub-title>
                    <v-list-tile-sub-title>
                      <v-btn color="secondary" small flat v-on:click.stop="exportSchedulingViewExcel">
                        <v-icon>mdi-download</v-icon>
                        {{ $t("scheduling.exportSchedulingTableView") }}
                      </v-btn>
                    </v-list-tile-sub-title>
                  </v-list-tile-content>
                </v-list>
              </v-menu>
            </div>
            <v-btn color="primary" round v-on:click.stop="find" block>
              <v-icon>search</v-icon>
              {{ $t("common.find") }}
            </v-btn>

          </v-flex>
          <scheduling-gs-planning-confirmation-modal v-if="dialogGsPlanning" :dialog="dialogGsPlanning"
            @gsPlaning-reject="gsPlanningReject" @gsPlaning-confirm="gsPlanningConfirm" />
        </v-layout>
      </v-container>
    </v-card>
  </div>

</template>
<script>
import { saveAs } from 'file-saver';
import AirlineSelect from '../../airline/AirlineSelect.vue';
import AircraftTypeSelect from '../../aircraft/AircraftTypeSelect.vue';
import AircraftConfigSelect from '../../aircraft/AircraftConfigSelect.vue';
import HandlingTypeSelect from '../../handling/HandlingTypeSelect.vue';
import AirportSelect from '../../airport/AirportSelect.vue';
import StatusSelect from '../StatusSelect.vue';
import DateInput from '../../ui/DateInput.vue';
import TimeInput from '../../ui/TimeInput.vue';
import DaysOfWeek from '../../ui/DaysOfWeek.vue';
import SchedulingFlightsSavedQueries from './SchedulingFlightsSavedQueries.vue';
import AgGridApiCsvExportable from '../../ag-grid/mixins/AgGridApiCsvExportable.vue';
import StoreSubscribable from '../../mixins/StoreSubscribable.vue';
import {
  SCHED_FIND_FLIGHTS,
  SCHED_UPDATE_FILTER_PAGINATION_SET_FIRST_PAGE,
  SCHED_FILTER_SET_UNIT_TIME, SCHED_CHANGE_GRID_API, GET_GS_PLANNING_CSV, SCHED_RECALCULATE_FILTER,
  UPDATE_SHOW_OLD_VALUES, UPDATE_FLASH_ON_CHANGES,
} from '../../../store/modules/scheduling';
import { TIME_TIMEZONE_CHANGED } from '../../../store/modules/time';
import SchedulingGsPlanningConfirmationModal from '../gsPlanning/SchedulingGsPlanningConfirmationModal.vue';
import SchedulingListTableOptionsFilterButton from './SchedulingListTableOptionsFilterButton.vue';
import AuditFlightsChangesList from '../../audit/list/AuditFlightsChangesList.vue';
import { REQUEST_ERROR, REQUEST_START, REQUEST_SUCCESS } from '../../../store/store';
import FileSerializable from '../../mixins/FileSerializable.vue';

export default {
  name: 'scheduling-list-filter',
  mixins: [AgGridApiCsvExportable, StoreSubscribable, FileSerializable],
  components: {
    SchedulingFlightsSavedQueries,
    DaysOfWeek,
    AirlineSelect,
    AircraftTypeSelect,
    AircraftConfigSelect,
    HandlingTypeSelect,
    AirportSelect,
    StatusSelect,
    DateInput,
    TimeInput,
    SchedulingGsPlanningConfirmationModal,
    SchedulingListTableOptionsFilterButton,
    AuditFlightsChangesList,
  },
  props: ['value'],
  data() {
    return {
      showFilter: true,
      filterSummary: null,
      dialogGsPlanning: false,
      showOldValues: null,
      flashOnChanges: null,
    };
  },
  computed: {
    filter() {
      return this.$store.state.scheduling.filter;
    },
    unitOfTime: {
      get() {
        return this.filter.unitOfTime;
      },
      set(value) {
        this.$store.commit(SCHED_FILTER_SET_UNIT_TIME, value);
      },
    },
  },
  methods: {
    filterChanged() {
      this.resetPaginationPropertiesCurrentPage();
      this.find();
    },
    find() {
      this.showLoading();
      this.$store.dispatch(SCHED_FIND_FLIGHTS);
    },
    resetPaginationPropertiesCurrentPage() {
      this.$store.dispatch(SCHED_UPDATE_FILTER_PAGINATION_SET_FIRST_PAGE);
    },
    changeDateFrom() {
      this.unitOfTime = null;
      const from = this.$moment(this.filter.dateFrom);
      const to = this.$moment(this.filter.dateTo);

      this.filter.dateFrom = from.startOf('day');
      if (from.isAfter(to)) {
        this.filter.dateTo = this.$moment(this.filter.dateFrom).endOf('day');
      }
      // this.filterChanged();
    },
    changeDateTo() {
      this.unitOfTime = null;
      const from = this.$moment(this.filter.dateFrom);
      const to = this.$moment(this.filter.dateTo);

      this.filter.dateTo = to.endOf('day');
      if (to.isBefore(from)) {
        this.filter.dateFrom = this.$moment(this.filter.dateTo).startOf('day');
      }
      // this.filterChanged();
    },
    displayedFilter() {
      this.showFilter = !this.showFilter;
      this.$emit('changeShowFilter', this.showFilter);
    },
    askForDownload() {
      if (this.gridApi.isColumnFilterPresent()) {
        this.dialogGsPlanning = true;
      } else {
        this.downloadGsPlannigngCsv();
      }
    },
    gsPlanningConfirm() {
      this.downloadGsPlannigngCsv();
      this.dialogGsPlanning = false;
    },
    gsPlanningReject() {
      this.dialogGsPlanning = false;
    },
    downloadGsPlannigngCsv() {
      this.$store.dispatch(GET_GS_PLANNING_CSV);
    },
    exportSchedulingExcel() {
      this.$store.commit(REQUEST_START);
      this.$http.post('scheduling/flights/export-excel', this.filter, {})
        .then((resp) => {
          this.$store.commit(REQUEST_SUCCESS);
          const blob = new Blob([this.s2ab(atob(resp.data.content))], { type: resp.data.contentType });
          saveAs(blob, resp.data.name);
        }).catch((err) => {
          this.$store.commit(REQUEST_ERROR, err);
        });
    },
    exportSchedulingViewExcel() {
      this.$store.commit(REQUEST_START);
      this.$http.post('scheduling/flights/export-view-excel', this.filter, {})
        .then((resp) => {
          this.$store.commit(REQUEST_SUCCESS);
          const blob = new Blob([this.s2ab(atob(resp.data.content))], { type: resp.data.contentType });
          saveAs(blob, resp.data.name);
        }).catch((err) => {
          this.$store.commit(REQUEST_ERROR, err);
        });
    },
    exportSchedulingCsv() {
      this.$store.commit(REQUEST_START);
      this.$http.post('scheduling/flights/export-csv', this.filter, {})
        .then((resp) => {
          this.$store.commit(REQUEST_SUCCESS);
          const blob = new Blob([this.s2ab(atob(resp.data.content))], { type: resp.data.contentType });
          saveAs(blob, resp.data.name);
        }).catch((err) => {
          this.$store.commit(REQUEST_ERROR, err);
        });
    },
    updateShowOldValuesInStore() {
      this.$store.commit(UPDATE_SHOW_OLD_VALUES, this.showOldValues);
    },
    updateFlashOnChangesInStore() {
      this.$store.commit(UPDATE_FLASH_ON_CHANGES, this.flashOnChanges);
    },
  },
  created() {
    this.showOldValues = this.$store.state.scheduling.showOldValues;
    this.flashOnChanges = this.$store.state.scheduling.flashOnChanges;
  },
  mounted() {
    const that = this;
    this.subscribeMutation(SCHED_CHANGE_GRID_API, (mutationPayload) => {
      that.setAgGridApi(mutationPayload);
    });
    this.subscribeMutation(TIME_TIMEZONE_CHANGED, () => {
      this.$store.dispatch(SCHED_RECALCULATE_FILTER);
      this.find();
    });
    this.$store.dispatch(SCHED_RECALCULATE_FILTER);
    this.find();
  },
};
</script>
