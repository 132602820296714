import ConnectedFlights from './connectedFlights';
import FlightServiceBilling from './flightServiceBilling';
import { getNestedStringValue } from './getNested';

export default class ConnectedFlightsServiceBilling {
  constructor(
    connectedFlights,
    service,
    sortingTime,
  ) {
    this.connectedFlights = connectedFlights;
    this.service = service;
    this.sortingTime = sortingTime;
    this.old = null;
  }

  static createEmtpyConnectedFlightsServiceBilling() {
    return new ConnectedFlightsServiceBilling(
      ConnectedFlights.createEmtpyConnectedFlights(),
      FlightServiceBilling.createEmptyFlightServiceBilling(),
    );
  }

  get id() {
    const conector = '-';
    return `${getNestedStringValue(this, ['service', 'name'])}${conector}${getNestedStringValue(this, ['connectedFlights', 'inbound', 'common', 'id'])}${conector}${getNestedStringValue(this, ['connectedFlights', 'outbound', 'common', 'id'])}`;
  }
}
