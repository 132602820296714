<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <entity-configuration-list
      :entity-name="this.$i18n.t('configuration.delayType')"
      entity-icon="local_airport"
      :column-defs="columnDefs" :items="delayTypes"
      :importEnabled="true"
      @confirm-delete-item="deleteConfirm"
      @confirm-edit-item="editConfirm"
      @confirm-enable-disable-item="enableDisableConfirm"
      @click-import-csv-items="importCsvDelayTypes"
    >
      <template v-slot:modal-content="{ itemEdit }">
        <delay-type-edit-card-content :item="itemEdit" />
      </template>
    </entity-configuration-list>
  </div>
</template>

<script>
import {
  DELAY_TYPE_CREATE,
  DELAY_TYPE_IMPORT,
  DELAY_TYPE_DELETE,
  DELAY_TYPE_UPDATE,
  GET_DELAY_TYPES,
  DELAY_TYPE_ENABLE,
  DELAY_TYPE_DISABLE,
} from '../../../store/modules/delayTypes/delayTypesCatalog';
import EntityConfigurationList from '../common/EntityConfigurationList.vue';
import DelayTypeEditCardContent from './DelayTypeEditCardContent.vue';
import AgGridIconCellRenderer from '../../ag-grid/cell-renderers/AgGridIconCellRenderer.vue';

export default {
  name: 'DelayTypes',
  components: {
    DelayTypeEditCardContent,
    EntityConfigurationList,
    AgGridIconCellRenderer,
  },
  data() {
    const that = this;
    return {
      columnDefs: [
        {
          field: 'code',
          headerValueGetter() {
            return that.$i18n.t('configuration.code');
          },
        },
        {
          field: 'description',
          headerValueGetter() {
            return that.$i18n.t('configuration.description');
          },
        },
        {
          field: 'airlineCode',
          headerValueGetter() {
            return that.$i18n.t('common.airline');
          },
        },
        {
          field: 'tenantName',
          headerValueGetter() {
            return that.$i18n.t('common.airport');
          },
          valueGetter(params) {
            const value = params.data.tenantName ? params.data.tenantName : that.$i18n.t('common.global');
            return value;
          },
        },
        {
          field: 'responsibleType',
          headerValueGetter() {
            return that.$i18n.t('configuration.responsibleType');
          },
        },
        {
          field: 'responsibleDepartment',
          headerValueGetter() {
            return that.$i18n.t('configuration.responsibleDepartment');
          },
        },
        {
          headerValueGetter() {
            return that.$i18n.t('configuration.disabled');
          },
          field: 'deleted',
          cellRendererFramework: AgGridIconCellRenderer,
          cellRendererParams: {
            icon: 'mdi-cancel',
            tooltip: params => (params.data.deleted ? this.$i18n.t('configuration.disabled') : null),
          },
        },
      ],
    };
  },
  computed: {
    delayTypes() {
      return this.$store.getters.getDelayTypes;
    },
  },
  methods: {
    getDelayTypes() {
      this.$store.dispatch(GET_DELAY_TYPES);
    },
    deleteConfirm(item) {
      this.$store.dispatch(DELAY_TYPE_DELETE, item.id);
    },
    editConfirm(item) {
      this.$store.dispatch(item.id ? DELAY_TYPE_UPDATE : DELAY_TYPE_CREATE, item);
    },
    enableDisableConfirm(item) {
      this.$store.dispatch(item.deleted ? DELAY_TYPE_ENABLE : DELAY_TYPE_DISABLE, item.id);
    },
    importCsvDelayTypes(csvData) {
      const mappedCsvData = csvData.data.map(delayTypeFromAgGrid => this.mapCsvHeadersFromAgGridHeadersToDTO(delayTypeFromAgGrid));
      this.$store.dispatch(DELAY_TYPE_IMPORT, mappedCsvData);
    },
    mapCsvHeadersFromAgGridHeadersToDTO(delayTypeFromAgGrid) {
      const delayTypeDTO = {};

      Object.keys(delayTypeFromAgGrid).forEach(
        (oldFieldName) => {
          const newFieldName = this.findDTOFieldNameFromAgGridHeader(oldFieldName);
          if (newFieldName == null) {
            return;
          }

          if (newFieldName.includes('airlineCode')) {
            const airlineCode = delayTypeFromAgGrid[oldFieldName];
            if (airlineCode && airlineCode.length > 2) {
              delayTypeDTO.airlineIcaoCode = airlineCode;
              return;
            }
            delayTypeDTO.airlineIataCode = airlineCode;
          }
          if (newFieldName.includes('tenantName')) {
            const tenantName = delayTypeFromAgGrid[oldFieldName];
            if (tenantName === 'Global') {
              delayTypeDTO.tenantName = null;
              return;
            }
          }

          delayTypeDTO[newFieldName] = delayTypeFromAgGrid[oldFieldName];
        },
      );

      return delayTypeDTO;
    },
    findDTOFieldNameFromAgGridHeader(oldFieldName) {
      return this.columnDefs
        .filter(agGridHeader => oldFieldName === agGridHeader.headerValueGetter())
        .map(agGridHeader => agGridHeader.field);
    },
  },
  created() {
    this.getDelayTypes();
  },
};
</script>
