import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"max-width":"1370px","persistent":""},model:{value:(_vm.dialogValue),callback:function ($$v) {_vm.dialogValue=$$v},expression:"dialogValue"}},[_c(VCard,{attrs:{"dark":this.$vuetify.dark,"color":this.$vuetify.dark ? 'bggrey_dark' : 'bggrey'}},[_c(VToolbar,{attrs:{"dark":"","color":"primary"}},[_c(VIcon,[_vm._v("flight")]),(_vm.isExternal)?_c(VToolbarTitle,[_vm._v(_vm._s(_vm.$t("rushBaggage.newRushBaggageExternal")))]):_c(VToolbarTitle,[_vm._v(_vm._s(_vm.$t("rushBaggage.newRushBaggageInternal")))])],1),(_vm.isExternal)?_c(VCardText,[_c(VForm,{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('rush-baggage-flight-external-form',{attrs:{"rush-baggage-original":_vm.rushBaggage},on:{"rush-baggage-update":_vm.updateRushBaggage}})],1)],1):_c(VCardText,[_c(VForm,{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('rush-baggage-flight-internal-form',{attrs:{"bound":_vm.bound,"rush-baggage-original":_vm.rushBaggage},on:{"rush-baggage-update":_vm.updateRushBaggage}})],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","flat":""},nativeOn:{"click":function($event){return _vm.close.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t("common.close")))]),_c(VBtn,{attrs:{"color":"primary","depressed":"","disabled":!_vm.valid},nativeOn:{"click":function($event){return _vm.save.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t("common.save")))])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }