export default class LostAndFoundFilter {
  constructor(
    dateFrom,
    dateTo,
    onlyLostAndFoundUnarchived,
    onlyCurrentDesk,
    onlyRemoteParkings,
    timezoneDate,
    filterDateByEstimatedTime,
  ) {
    this.dateFrom = dateFrom;
    this.dateTo = dateTo;
    this.onlyLostAndFoundUnarchived = onlyLostAndFoundUnarchived === true;
    this.onlyCurrentDesk = onlyCurrentDesk === true;
    this.onlyRemoteParkings = onlyRemoteParkings === true;
    this.timezoneDate = timezoneDate;
    this.filterDateByEstimatedTime = filterDateByEstimatedTime;
  }

  static get createEmptyFilter() {
    return new LostAndFoundFilter(null, null, true, false, false, null, true);
  }
}
