<template>
  <div>
    <!--FIXME replace item-value to id-->
    <v-autocomplete
      placeholder="..."
      :value="value"
      :label="label"
      :items="airportGates"
      :filter="customFilter"
      :clearable="true"
      item-text="code"
      item-value="id"
      :rules="rules"
      :disabled="disabled"
      @input="emitInput"
      :menu-props="{zIndex:'1000'}"
      :ref="refComp"
    >
      <template
        slot="item"
        slot-scope="{ item, tile }"
      >
        <v-list-tile-content>
          <v-list-tile-title v-text="item.code"></v-list-tile-title>
          <v-list-tile-sub-title v-text="item.comments"></v-list-tile-sub-title>
        </v-list-tile-content>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import { GET_AIRPORT_GATE } from '../../store/modules/airportGate/airportGateCatalog';
import { FlightBound } from '../shared/model/flightBound';

export default {
  props: {
    bound: { required: true },
    value: { required: true },
    label: { type: String },
    charLenght: { type: Number, default: 6 },
    rules: { type: Array },
    disabled: { type: Boolean, default: false },
    refComp: {
      type: String,
      required: false,
      default: 'refComp',
    },
  },
  computed: {
    airportGates() {
      if (FlightBound.INBOUND === this.bound) {
        return this.$store.getters.getActiveDeboardingGates;
      }
      if (FlightBound.OUTBOUND === this.bound) {
        return this.$store.getters.getActiveBoardingGates;
      }

      return this.$store.getters.getActiveAirportGates;
    },
  },
  methods: {
    getAiportGates() {
      this.$store.dispatch(GET_AIRPORT_GATE);
    },
    customFilter(item, queryText) {
      const textOne = item.code ? item.code.toLowerCase() : '';
      const textTwo = item.comments ? item.comments.toLowerCase() : '';
      const searchText = queryText.toLowerCase();

      return textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1;
    },
    emitInput(event) {
      if (typeof (event) !== 'undefined') {
        this.$emit('input-code', this.airportGates.find(p => p.id === event).code);
        this.$emit('input-remote', this.airportGates.find(p => p.id === event).remote);
        this.$emit('input', event);
      } else {
        this.$emit('input-code', null);
        this.$emit('input', null);
      }
    },
  },
  mounted() {
    this.getAiportGates();
  },
};
</script>

<style lang="scss">
  .v-autocomplete .v-autocomplete-list {
    /*overflow-y: auto;*/
    z-index: 1500;
  }
</style>
