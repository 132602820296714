<template>
  <v-data-table
    class="mt-3 elevation-1 contentData"
    hide-actions
    :headers="tableHeaders"
    :items="items"
  >
    <template slot="items" slot-scope="props">
      <td v-for="(header, index) in headers" :key="header + index">
        {{ props.item[header] }}
      </td>
    </template>
  </v-data-table>
</template>
<script>

import SchedulingImportParsedFile from '../model/schedulingImportParsedFile';

export default {
  name: 'scheduling-import-step-file-preview',
  props: {
    parsedFile: {
      type: SchedulingImportParsedFile,
    },
  },
  computed: {
    items() {
      return this.parsedFile ? this.parsedFile.items : [];
    },
    headers() {
      return this.parsedFile ? this.parsedFile.headers : [];
    },
    tableHeaders() {
      return this.parsedFile ? this.parsedFile.headers.map(h => ({ text: h, value: h })) : [];
    },
  },
  methods: {
  },
};
</script>
<style lang="scss" scoped>
  .contentData {
    max-height: 60vh;
    overflow-y: auto;
  }
</style>
