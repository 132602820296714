import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListTile } from 'vuetify/lib/components/VList';
import { VListTileAction } from 'vuetify/lib/components/VList';
import { VListTileContent } from 'vuetify/lib/components/VList';
import { VListTileTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,[_c(VNavigationDrawer,{staticStyle:{"width":"auto"}},[_c(VList,_vm._l((_vm.items),function(item){return _c(VListTile,{key:item.title,class:{ 'active-menu': _vm.selectedDirectory === item.el},on:{"click":function($event){return _vm.selectDirectory(item)}}},[_c(VListTileAction,[_c(VIcon,{attrs:{"large":""}},[_vm._v(_vm._s(item.icon))])],1),_c(VListTileContent,[_c(VListTileTitle,[_vm._v(_vm._s(item.title))])],1)],1)}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }