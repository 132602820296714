<template>
  <v-dialog v-model="dialog" persistent max-width="800px"
    :content-class="isContentVisible ? 'modal-common' : 'modal-common hide-title'">
    <v-card :dark="this.$vuetify.dark" :color="this.$vuetify.dark ? 'bggrey_dark' : 'bggrey'">
      <v-toolbar dark color="primary">
        <v-icon>flight</v-icon>
        <v-toolbar-title v-if="isExternal">{{ $t("contingencies.newExternalContingency") }}</v-toolbar-title>
        <v-toolbar-title v-else>{{ $t("contingencies.newContingency") }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <!-- Botón para contraer/expandir el contenido -->
        <v-btn icon @click="toggleContentVisibility">
          <v-icon>{{ isContentVisible ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
        </v-btn>
      </v-toolbar>
      <div v-show="isContentVisible">
        <loading-component />

        <v-card-text v-if="isExternal" class="pb-0">
          <contingency-date @dateSelected="setDate" />
        </v-card-text>

        <v-card-text v-else class="pb-0">
          <flight-selector-container @flightSelected="setFlight" @clearFlight="clearFlight" />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn flat color="primary" @click.native="close">{{ $t("common.close") }}</v-btn>
          <v-btn color="primary" depressed @click.native="save" :disabled="!valid">{{ $t("common.save") }}</v-btn>
        </v-card-actions>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>

import LoadingComponent from '../../layout/LoadingComponent.vue';
import FlightSelectorContainer from '../../ui/flightSelector/FlightSelectorContainer.vue';
import Contingency from '../model/contingency';
import { REQUEST_ERROR, REQUEST_START, REQUEST_SUCCESS } from '../../../store/store';
import { NOTIFICATION_SHOW } from '../../../store/modules/notifications';
import { JSUtils } from '../../shared/js/JSUtils';
import ContingencyDate from '../../ui/ContingencyDate.vue';

export default {
  name: 'contingency-new-modal',
  props: {
    dialog: { type: Boolean, required: true },
    isExternal: { type: Boolean, required: true },
  },
  data: () => ({
    contingency: Contingency.createEmptyContingency(),
    isContentVisible: true,
  }),
  components: {
    LoadingComponent,
    FlightSelectorContainer,
    ContingencyDate,
  },
  computed: {
    valid() {
      if (this.isExternal) {
        return this.contingency.flightDate ? this.contingency.flightDate.isValid() : true;
      }
      return this.contingency.flightId !== undefined && this.contingency.flightId !== null;
    },
  },
  methods: {
    setFlight(flight) {
      this.contingency.flightId = flight.id;
      this.contingency.flightTripNumber = flight.tripNumber;
      const momentFlightDate = this.$moment(flight.flightDate);
      this.contingency.flightDate = (momentFlightDate && momentFlightDate.isUtc())
        ? momentFlightDate
        : momentFlightDate.utc().set(this.getDateObjectFromString(flight.flightDate));
      this.contingency.scheduledTime = flight.scheduledTime;
      this.contingency.airlineId = flight.airlineId;
      this.contingency.airlineCode = flight.airlineCode;
      this.contingency.airport1Id = flight.airport1Id;
      this.contingency.airport1Code = flight.airport1Code;
    },
    getDateObjectFromString(yearMonthDay) {
      const yearMonthDaySplitted = yearMonthDay.split('-');
      return {
        year: yearMonthDaySplitted[0],
        month: yearMonthDaySplitted[1] - 1, // Month 0 equals Jan
        date: yearMonthDaySplitted[2],
      };
    },
    setDate(date) {
      this.contingency = Contingency.createEmptyContingency();
      this.contingency.flightDate = (date && date.isUtc())
        ? date
        : this.$moment().utc().set(this.getDateObjectFromMoment(date));
    },
    getDateObjectFromMoment(moment) {
      return {
        year: moment.year(),
        month: moment.month(),
        date: moment.date(),
      };
    },
    close(result) {
      this.$emit('close', result);
    },
    save() {
      this.$store.commit(REQUEST_START);
      this.$http.post('flight-contingencies', this.contingency)
        .then((response) => {
          this.$store.commit(NOTIFICATION_SHOW, {
            message: this.$i18n.t('contingencies.messageSuccessfully'),
            color: 'success',
          });
          this.$store.commit(REQUEST_SUCCESS);
          const contingencySaved = JSUtils.cast(response.data, Contingency);
          this.$router.push({ name: 'contingencyReport', params: { contingencyId: contingencySaved.id } });
        })
        .catch((err) => {
          this.close();
          this.$store.commit(NOTIFICATION_SHOW, {
            message: this.$i18n.t('notifications.errorNotification'),
            color: 'error',
          });
          this.$store.commit(REQUEST_ERROR, err);
        });
    },
    clearFlight() {
      this.contingency.flightId = null;
      this.contingency.flightTripNumber = null;
      this.contingency.scheduledTime = null;
      this.contingency.airlineId = null;
      this.contingency.airlineCode = null;
      this.contingency.airport1Id = null;
      this.contingency.airport1Code = null;
    },
    toggleContentVisibility() {
      this.isContentVisible = !this.isContentVisible;
    },
  },
};
</script>
