<template>
  <v-layout row justify-center>
    <v-dialog v-model="dialog" persistent max-width="50%"
      :content-class="isContentVisible ? 'modal-common' : 'modal-common hide-title'">
      <v-card :dark="this.$vuetify.dark" :color="this.$vuetify.dark ? 'bggrey_dark' : 'bggrey'">
        <v-toolbar dark color="primary">
          <v-icon>delete</v-icon>
          <v-toolbar-title>{{ $t("common.deleteConfirmationTitle") }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <!-- Botón para contraer/expandir el contenido -->
          <v-btn icon @click="toggleContentVisibility">
            <v-icon>{{ isContentVisible ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
          </v-btn>
        </v-toolbar>
        <div v-show="isContentVisible">

          <loading-component />

          <v-card-text>
            {{ $t("scheduling.deleteGroundConfirmationAlert") }}
            <connected-flights-base-grid :items="items" @selected-changed="selectedChanged" />
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn flat color="primary" @click.native="reject()">{{ $t("common.cancel") }}</v-btn>
            <v-btn color="error" depressed @click.native="confirm()" :disabled="loading">
              {{ $t("common.delete") }}
            </v-btn>
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>
  </v-layout>
</template>
<script>
import LoadingComponent from '../layout/LoadingComponent.vue';
import ConnectedFlightsBaseGrid from '../flights/ConnectedFlightsBaseGrid.vue';

export default {
  name: 'aircraft-ground-delete-modal',
  components: {
    LoadingComponent,
    ConnectedFlightsBaseGrid,
  },
  props: {
    dialog: { type: Boolean, required: true },
    items: { type: Array, required: true },
  },
  data() {
    return {
      selectedItems: [],
      isContentVisible: true,
    };
  },
  computed: {
    loading() {
      return this.$store.state.loading;
    },
  },
  methods: {
    selectedChanged(selectedItems) {
      this.selectedItems = selectedItems;
    },
    reject() {
      this.$emit('delete-reject');
    },
    confirm() {
      this.$emit('delete-confirm', this.mapToIdsArray(this.selectedItems));
    },
    mapToIdsArray(connectedFlights) {
      const deletable = [];
      if (connectedFlights) {
        connectedFlights.forEach((item) => {
          if (item.inbound && item.inbound.common && item.inbound.common.id) {
            deletable.push(item.inbound.common.id);
          }
          if (item.outbound && item.outbound.common && item.outbound.common.id) {
            deletable.push(item.outbound.common.id);
          }
        });
      }
      return deletable;
    },
    toggleContentVisibility() {
      this.isContentVisible = !this.isContentVisible;
    },
  },
};
</script>
