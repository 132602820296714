import { VAlert } from 'vuetify/lib/components/VAlert';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VExpansionPanel,{staticClass:"exp-header",attrs:{"expand":"","popout":""},model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}},[_c(VExpansionPanelContent,{attrs:{"expand-icon":"mdi-menu-down","expandable":""},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',[_c('h3',{staticClass:"font-weight-light"},[_vm._v(_vm._s(_vm.$t("administration.operationSummary")))]),(_vm.loading)?_c(VFlex,{attrs:{"xs12":""}},[_c(VProgressLinear,{attrs:{"indeterminate":true}})],1):_vm._e()],1)]},proxy:true}])},[_c(VLayout,{attrs:{"row":"","wrap":""}},[_c(VFlex,{attrs:{"xs12":""}},[(_vm.time)?_c(VAlert,{attrs:{"value":true,"type":"info"}},[_vm._v(" "+_vm._s(_vm.operation)+" "+_vm._s(_vm.$t("administration.operationLaunchedAt"))+" "+_vm._s(_vm.time)+" ")]):_vm._e()],1),_c(VFlex,{attrs:{"xs12":""}},[(_vm.isSuccess)?_c(VAlert,{attrs:{"value":true,"type":"success"}},[_vm._v(" "+_vm._s(_vm.$t("administration.operationCompetedSuccessfully"))+" ")]):_vm._e(),(_vm.isError)?_c(VAlert,{attrs:{"value":true,"type":"error"}},[_vm._v(" "+_vm._s(_vm.$t("notifications.errorNotification"))+" ")]):_vm._e()],1),_c(VFlex,{staticClass:"traces-list",attrs:{"xs12":"","mx-3":"","mb-3":""}},_vm._l((_vm.traces),function(trace,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(trace)+" ")])}),0)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }