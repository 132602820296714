<template>
  <div v-if="this.isExternal()">
    <v-flex v-flex-none class="inline-flight-number">
      <airline-select :ref="'refComp'" v-on:keydown.native="keyEventAirline" v-char-length.clearable="4" v-model="airlineIdUpdated"></airline-select>
    </v-flex>
    <v-flex v-flex-none class="inline-flight-number inline-flight-number-input">
      <v-text-field :ref="'refInput'" v-on:keydown.native="keyEventFlightNumber" v-char-length="5" v-model="tripNumberUpdated"></v-text-field>
    </v-flex>
  </div>
  <div v-else>
    <v-flex v-flex-none class="inline-flight-number inline-flight-number-input">
      <flight-selector :items="flights" @flight="setFlight"></flight-selector>
    </v-flex>
  </div>
</template>

<script>
import Vue from 'vue';
import AirlineSelect from '../../../airline/AirlineSelect.vue';
import { keyEvents } from '../../../keyEvents';
import AgGridParentRushBaggageCellEditor from '../mixins/AgGridParentRushBaggageCellEditor.vue';
import FlightSelectorContainer from '../../../ui/flightSelector/FlightSelectorContainer.vue';
import { FlightBound } from '../../../shared/model/flightBound';
import FlightSelector from '../../../ui/flightSelector/FlightSelector.vue';
import { FIND_FLIGHTS } from '../../../../store/modules/flight';

export default Vue.extend({
  mixins: [AgGridParentRushBaggageCellEditor],
  components: {
    AirlineSelect,
    FlightSelectorContainer,
    FlightSelector,
  },
  computed: {
    flights() {
      return this.$store.state.flight.flightsList;
    },
    filter() {
      return this.$store.state.flight.filter;
    },
  },
  data() {
    return {
      airlineIdOriginal: null,
      airlineIdUpdated: null,
      tripNumberOriginal: null,
      tripNumberUpdated: null,
      flightId: null,
      rushBaggage: {},
      dialog: false,
      bound: FlightBound.OUTBOUND.description,
    };
  },
  methods: {
    getValue() {
      if (this.airlineIdOriginal !== this.airlineIdUpdated || this.tripNumberOriginal !== this.tripNumberUpdated) {
        const airline = this.$store.getters.getAirlineById(this.airlineIdUpdated);
        const code = typeof (airline) !== 'undefined' ? airline.iataCode : undefined;

        const that = this;
        this.saveForValueOrRushesBaggage((s) => {
          s.setChildPropertyValue('flightTripNumber', that.tripNumberUpdated);
          s.setChildPropertyValue('flightId', that.flightId);
          s.setChildPropertyValue('airlineId', that.airlineIdUpdated);
          s.setChildPropertyValue('airlineCode', code);
        }, 'Flight Number');
      }
      return this.params.node.data;
    },
    keyEventAirline($event) {
      this.keyEvent($event);

      if ($event.code === keyEvents.ENTER) {
        $event.stopPropagation();
      }

      if ($event.code === keyEvents.TAB) {
        $event.stopPropagation();
        this.$refs.refInput.$refs['input-slot'].focus();
      }
    },
    keyEventFlightNumber($event) {
      this.keyEvent($event);

      if ($event.code === keyEvents.TAB && $event.shiftKey) {
        $event.stopPropagation();
      }
    },
    isExternal() {
      return this.rushBaggage.flightId == null;
    },
    setFlight(flight) {
      this.airlineIdUpdated = flight.airlineId;
      this.tripNumberUpdated = flight.tripNumber;
      this.flightId = flight.id;
    },
    find() {
      const from = this.$moment();
      const to = this.$moment();
      this.filter.dateFrom = from.startOf('day');
      this.filter.dateTo = to.endOf('day');
      this.filter.bound = FlightBound.OUTBOUND.description;
      this.$store.dispatch(FIND_FLIGHTS);
    },
  },
  created() {
    this.rushBaggage = this.params.node.data;
    this.airlineIdOriginal = this.rushBaggage.airlineId;
    this.airlineIdUpdated = this.airlineIdOriginal;
    this.tripNumberOriginal = this.rushBaggage.flightTripNumber;
    this.tripNumberUpdated = this.tripNumberOriginal;

    if (this.rushBaggage.flightId != null) {
      this.find();
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$refs.refComp) {
        this.$refs.refComp.$refs.refComp.focus();
      }
    });
  },
});
</script>

<style>
  .flight-number-cell.ag-cell-inline-editing {
    min-width: 160px;
  }
  .inline-flight-number {
    display: inline-block;
  }
  .inline-flight-number-input {
    margin-left: 5px;
  }
</style>
