import { JSUtils } from '../js/JSUtils';

export default class FlightWeighting {
  constructor(
    real,
    total,
    operations,
    passage,
    ramp,
  ) {
    this.real = real;
    this.total = total;
    this.operations = operations;
    this.passage = passage;
    this.ramp = ramp;
  }

  static createEmpty() {
    return new FlightWeighting();
  }

  static createFromData(data) {
    if (!data) {
      return data;
    }

    return JSUtils.cast(data, FlightWeighting);
  }
}
