<template>
  <div>
    <label
      for="file-upload-button"
      class="custom-file-upload-button">
      <v-icon color="primary"> mdi-upload </v-icon> {{ $i18n.t('common.import') }}
    </label>
    <!-- Display = none to use custom label : file-upload-button -->
    <input style="display: none;"
      id="file-upload-button"
      type="file"
      @change="processFile($event)"
      @click="$event.target.value = ''"
      accept=".csv" />
  </div>
</template>
<script>
import Vue from 'vue';
import Papa from 'papaparse';

export default Vue.extend({
  name: 'CSVImporter',
  data() {
    const onCompleteCallback = results => this.$emit('onCSVProcessed', results);
    const papaparseConfiguration = {
      // To optain headers based js objects
      header: true,
      // Avoid treating numbers as text strings
      dynamicTyping: true,
      // Avoid TooFewFields Exception
      skipEmptyLines: true,
      complete: onCompleteCallback,
    };
    return {
      papaparseConfiguration,
    };
  },
  methods: {
    processFile(event) {
      const uploadedFile = event.target.files[0];

      if (uploadedFile == null) {
        return;
      }

      Papa.parse(uploadedFile, this.papaparseConfiguration);
    },
  },
});
</script>
<style>
  .custom-file-upload-button {
    display: inline-flex;
    align-items: center;

    padding: 5px;

    text-transform: uppercase;
    font-size: small;
    font-weight: 500;

    color: red;
    cursor: pointer;
  }
  .custom-file-upload-button:hover {
    background-color: #fcd2d2;
  }

</style>
