<template>
  <v-layout row justify-center>
    <v-dialog v-model="dialog" persistent max-width="1370px"
      :content-class="isContentVisible ? 'modal-common' : 'modal-common hide-title'">
      <v-card :dark="this.$vuetify.dark" :color="this.$vuetify.dark ? 'bggrey_dark' : 'bggrey'">
        <v-toolbar dark color="primary">
          <v-icon>flight</v-icon>
          <v-toolbar-title v-if="!isAircraftGround">{{ $t("rampServices.modalTitleAddService") }}</v-toolbar-title>
          <v-toolbar-title v-else>{{ $t("rampServices.modalTitleAircraftGroundAddService") }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <!-- Botón para contraer/expandir el contenido -->
          <v-btn icon @click="toggleContentVisibility">
            <v-icon>{{ isContentVisible ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
          </v-btn>
        </v-toolbar>
        <div v-show="isContentVisible">

          <loading-component />

          <v-card-text>
            <v-container fluid>
              <v-form ref="form" v-model="isValidForm">
                <v-layout row wrap>
                  <v-flex xs3>
                    <airport-ramp-service-for-service-agent-select :rules="[rampServiceRules.required]"
                      v-model="service.serviceId" :idService="idService" :label="$i18n.t('rampServices.rampServices')"
                      class="ramp-service-modal" :readonly="!hasPermissions(rolePermission) || disabledService"
                      @change="onChangeService"></airport-ramp-service-for-service-agent-select>
                  </v-flex>
                  <v-flex xs3>
                    <airport-requester-select :rules="[requesterRules.required]" v-model="service.requesterId"
                      :label="$i18n.t('common.requester')" class="ramp-service-modal"
                      :disabled="!hasPermissions(rolePermission)"></airport-requester-select>
                  </v-flex>
                </v-layout>
                <v-layout row wrap>
                  <v-flex xs3>
                    <v-text-field :rules="[unitsRules.required]" type="number" v-model="service.units"
                      :label="$i18n.t('rampServices.units')" class="ramp-service-modal"
                      :readonly="!hasPermissions(rolePermission)"></v-text-field>
                  </v-flex>
                  <v-flex xs3>
                    <time-input :rules="[requestTimeRules.required]" v-model="service.requestTs"
                      :label="$i18n.t('rampServices.requestTime')" :referenceDate="referenceDate"
                      class="ramp-service-modal" :disabled="!hasPermissions(rolePermission)"></time-input>
                  </v-flex>
                  <v-flex xs3>
                    <time-input :rules="[requestStartTimeRules.required]" v-model="service.requestedTs"
                      :label="$i18n.t('rampServices.requestedStartTime')" :referenceDate="referenceDate"
                      class="ramp-service-modal" :disabled="!hasPermissions(rolePermission)"></time-input>
                  </v-flex>
                  <v-flex xs3>
                    <time-input :rules="[requestEndTimeRules.required]" v-model="service.requestedEndTs"
                      :label="$i18n.t('rampServices.requestedEndTime')" :referenceDate="referenceDate"
                      class="ramp-service-modal" :disabled="!hasPermissions(rolePermission)"></time-input>
                  </v-flex>
                </v-layout>
                <v-layout row wrap>
                  <v-flex xs3>
                    <time-input :rules="[startTimeRules.required]" v-model="service.startTs"
                      :label="$i18n.t('rampServices.startTime')" :referenceDate="referenceDate"
                      class="ramp-service-modal" :disabled="!hasPermissions(rolePermission)"></time-input>
                  </v-flex>
                  <v-flex xs3>
                    <airport-parking-select :rules="[originRules.required]" :ref="'refComp'" v-char-length.clearable="4"
                      :label="$i18n.t('rampServices.origin')" v-model="service.originId"
                      :disabled="!hasPermissions(rolePermission)"></airport-parking-select>
                  </v-flex>
                  <v-flex xs3>
                    <time-input :rules="[endTimeRules.required]" v-model="service.finishTs"
                      :label="$i18n.t('rampServices.endTime')" :referenceDate="referenceDate" class="ramp-service-modal"
                      :disabled="!hasPermissions(rolePermission)"></time-input>
                  </v-flex>
                  <v-flex xs3>
                    <airport-parking-select :rules="[destinationRules.required]" :ref="'refComp'"
                      v-char-length.clearable="4" :label="$i18n.t('operations.destination')"
                      v-model="service.destinationId"
                      :disabled="!hasPermissions(rolePermission)"></airport-parking-select>
                  </v-flex>
                  <v-layout row wrap>
                    <v-flex xs3>
                      <orientation-select :rules="[orientationRules.required]" v-model="service.orientation"
                        :label="$i18n.t('common.orientation')" class="ramp-service-modal"
                        :disabled="!hasPermissions(rolePermission)"></orientation-select>
                    </v-flex>
                  </v-layout>
                  <v-flex xs12>
                    <v-text-field :rules="[remarksRules.required]" v-model="service.remarks"
                      :label="$i18n.t('operations.remarksExtended')"
                      :readonly="!hasPermissions(rolePermission)"></v-text-field>
                  </v-flex>
                </v-layout>
              </v-form>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn flat color="primary" @click="close" :disabled="loading">{{ $t("common.cancel") }}</v-btn>
            <v-btn color="primary" @click="save" :disabled="loading || !isValidForm">{{ $t("common.save") }}</v-btn>
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>
  </v-layout>
</template>
<script>

import { AgGridVue } from 'ag-grid-vue';
import AgGridOptionsConfigurable from '../../ag-grid/mixins/AgGridOptionsConfigurable.vue';
import AgGridApiHoldable from '../../ag-grid/mixins/AgGridApiHoldable.vue';
import LoadingComponent from '../../layout/LoadingComponent.vue';
import RolePermissions from '../../mixins/RolePermissions.vue';
import TimeInput from '../../ui/TimeInput.vue';
import AirportRequesterSelect from '../../configuration/airportRequester/AirportRequesterSelect.vue';
import AirportRampServiceForServiceAgentSelect from '../../rampService/AirportRampServiceForServiceAgentSelect.vue';
import AirportParkingSelect from '../../airport/AirportParkingSelect.vue';
import { GET_RAMP_SERVICE } from '../../../store/modules/rampServices/rampServicesCatalog';
import { REQUEST_ERROR, REQUEST_START, REQUEST_SUCCESS } from '../../../store/store';
import { NOTIFICATION_SHOW } from '../../../store/modules/notifications';
import OrientationSelect from '../../rampService/OrientationSelect.vue';


export default {
  name: 'request-ramp-service-modal',
  mixins: [AgGridApiHoldable, AgGridOptionsConfigurable, RolePermissions],
  components: {
    AgGridVue,
    LoadingComponent,
    TimeInput,
    AirportRequesterSelect,
    AirportRampServiceForServiceAgentSelect,
    AirportParkingSelect,
    OrientationSelect,
  },
  props: {
    idService: {},
    flightComposition: { required: true },
    isAircraftGroundSelected: { default: false },
    dialog: {},
  },
  computed: {
    loading() {
      return this.$store.state.loading;
    },
    rolePermission() {
      return this.getRoles();
    },
    isAircraftGround() {
      return this.isAircraftGroundSelected || this.isFlightCompositionAircraftGround();
    },
    user() {
      return this.$store.getters.user;
    },
  },
  data() {
    return {
      referenceDate: null,
      columnDefs: null,
      service: {},
      disabledService: false,
      flightId: null,
      defRampService: {},
      isValidForm: false,
      rampServiceRules: {
        required: value => this.getFieldRequiredRule('rampService', value),
      },
      requesterRules: {
        required: value => this.getFieldRequiredRule('requesterRequired', value),
      },
      unitsRules: {
        required: value => this.getFieldRequiredRule('unitsRequired', value),
      },
      requestTimeRules: {
        required: value => this.getFieldRequiredRule('requestTimeRequired', value),
      },
      requestStartTimeRules: {
        required: value => this.getFieldRequiredRule('requestStartTimeRequired', value),
      },
      requestEndTimeRules: {
        required: value => this.getFieldRequiredRule('requestEndTimeRequired', value),
      },
      startTimeRules: {
        required: value => this.getFieldRequiredRule('startTimeRequired', value),
      },
      originRules: {
        required: value => this.getFieldRequiredRule('originRequired', value),
      },
      endTimeRules: {
        required: value => this.getFieldRequiredRule('finishTimeRequired', value),
      },
      destinationRules: {
        required: value => this.getFieldRequiredRule('destinationRequired', value),
      },
      remarksRules: {
        required: value => this.getFieldRequiredRule('remarksRequired', value),
      },
      orientationRules: {
        required: value => this.getFieldRequiredRule('orientationRequired', value),
      },
      isContentVisible: true,
    };
  },
  methods: {
    getFieldRequiredRule(field, value) {
      if (this.defRampService[field] !== undefined) {
        return !value && this.defRampService[field] ? 'Required' : true;
      }
      return !!value || 'Required';
    },
    isFlightCompositionAircraftGround() {
      return this.flightComposition.common.id && this.flightComposition.common.tripNumber == null;
    },
    save() {
      if (this.service.requestedTs !== null && this.service.requestTs === null) {
        this.service.requestTs = this.$moment();
      }
      this.$store.commit(REQUEST_START);
      if (this.isAircraftGround) {
        this.$http.post(`aircraft-ground/${this.flightId}/ramp/services/request`, this.service)
          .then(() => {
            this.close();
            this.$store.commit(REQUEST_SUCCESS);
          })
          .catch((err) => {
            this.$store.commit(REQUEST_ERROR, err);
            this.$store.commit(NOTIFICATION_SHOW, {
              message: err.response.data.title || this.$i18n.t('notifications.errorNotification'),
              color: 'error',
            });
          });
      } else {
        this.$http.post(`flights/${this.flightId}/ramp/services/request`, this.service)
          .then(() => {
            this.$store.commit(REQUEST_SUCCESS);
            this.close();
          })
          .catch((err) => {
            this.$store.commit(REQUEST_ERROR, err);
            this.$store.commit(NOTIFICATION_SHOW, {
              message: err.response.data.title || this.$i18n.t('notifications.errorNotification'),
              color: 'error',
            });
          });
      }
    },
    onChangeService() {
      this.defRampService = this.$store.getters.getRampServiceById(this.service.serviceId);
      this.setDefaultRampServiceData();
      this.$refs.form.validate();
    },
    close() {
      this.$emit('request-ramp-service-close');
    },
    setDefaultRampServiceData() {
      if (!this.service.units && this.defRampService.unitsDefault) {
        this.service.units = this.defRampService.unitsDefault;
        // this.service.unitsDefault = true;
      }
      if (!this.service.orientation && this.defRampService.orientationDefault) {
        this.service.orientation = this.defRampService.orientationDefault;
        // this.service.orientationDefault = true;
      }
      if (!this.service.requestedTs && this.defRampService.requestedTsDefault) {
        this.service.requestedTs = this.getDefaultTime(this.defRampService.requestedTsDefault, this.defRampService.requestedTsDefaultType, this.defRampService.requestedTsDefaultMinutes, this.flightComposition.times.estimatedTime, this.flightComposition.common.scheduledTime);
        // this.service.requestedTsDefault = true;
      }
      if (!this.service.requestedEndTs && this.defRampService.requestedEndTsDefault) {
        this.service.requestedEndTs = this.getDefaultTime(this.defRampService.requestedEndTsDefault, this.defRampService.requestedEndTsDefaultType, this.defRampService.requestedEndTsDefaultMinutes, this.flightComposition.times.estimatedTime, this.flightComposition.common.scheduledTime);
        // this.service.requestedEndTsDefault = true;
      }
      if (!this.service.startTs && this.defRampService.startTsDefault) {
        this.service.startTs = this.getDefaultTime(this.defRampService.startTsDefault, this.defRampService.startTsDefaultType, this.defRampService.startTsDefaultMinutes, this.flightComposition.times.estimatedTime, this.flightComposition.common.scheduledTime);
        // this.service.startTsDefault = true;
      }
      if (!this.service.finishTs && this.defRampService.finishTsDefault) {
        this.service.finishTs = this.getDefaultTime(this.defRampService.finishTsDefault, this.defRampService.finishTsDefaultType, this.defRampService.finishTsDefaultMinutes, this.flightComposition.times.estimatedTime, this.flightComposition.common.scheduledTime);
        // this.service.finishTsDefault = true;
      }
      if (!this.service.remarks && this.defRampService.remarksDefault) {
        this.service.remarks = this.getDefaultRemarks(this.defRampService.remarksDefault, this.user);
        // this.service.remarksDefault = true;
      }
    },
    getDefaultTime(isDefault, defaultType, defaultMinutes, estimatedTime, scheduledTime) {
      if (isDefault) {
        if (defaultType === 'ELAPSED_TIME' && defaultMinutes != null && estimatedTime != null) {
          return this.$moment(estimatedTime).add(defaultMinutes, 'minutes');
        } if (defaultType === 'SCHEDULED_TIME' && defaultMinutes != null && scheduledTime != null) {
          return this.$moment(scheduledTime).add(defaultMinutes, 'minutes');
        }
      }

      return null;
    },
    getDefaultRemarks(remark, user) {
      let defaultRemark = remark;
      const emailPattern = '{emailUser}';

      if (remark && user && user.email) {
        if (remark.includes(emailPattern)) {
          defaultRemark = defaultRemark.replace(emailPattern, user.email);
        }
      }

      return defaultRemark;
    },
    toggleContentVisibility() {
      this.isContentVisible = !this.isContentVisible;
    },
  },
  created() {
    this.$store.dispatch(GET_RAMP_SERVICE);

    this.referenceDate = this.flightComposition.common.flightDate;
    this.flightId = this.flightComposition.common.id;

    if (this.idService) {
      this.defRampService = this.$store.getters.getRampServiceById(this.idService);
      this.service.serviceId = this.idService;
      this.setDefaultRampServiceData();
    }
  },
  mounted() {
    this.$refs.form.validate();
  },
};
</script>
<style></style>
