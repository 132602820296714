<template>
  <div>
    <ag-grid-vue
      class="ag-theme-material connected-flights-table operational-table"
      :columnDefs="columnDefs"
      :defaultColDef="defaultColDef"
      :rowData="items"
      :rowSelection="'multiple'"
      :suppressRowClickSelection="true"
      :rowMultiSelectWithClick="false"
      @grid-ready="onGridReady"
      @first-data-rendered="firstDataRendered"
      @selection-changed="onSelectionChanged"
      :suppressHorizontalScroll="true"
      :frameworkComponents="frameworkComponents"
      :gridOptions="gridOptions"
      :multiSortKey="multiSortKey"
      v-bind:style="`width: 100%; height: calc(100vh - ${this.sizeFilter});`">
    </ag-grid-vue>
  </div>
</template>
<script>
import { AgGridVue } from 'ag-grid-vue';
import AgGridValueBaseCellRenderer from '../../ag-grid/cell-renderers/AgGridValueBaseCellRenderer.vue';
import AgGridConnectedFlights from '../../ag-grid/mixins/AgGridConnectedFlights.vue';
import FlightsDifferences from '../../mixins/FlightsDifferences.vue';
import { cellClassAgGridRules } from '../../ag-grid/mixins/cellClassAgGridRules';
import { FisModule } from '../../ag-grid/cell-editors/model/fisModule';
import agGridTextCustomFilterComparator from '../../ag-grid/filters/agGridTextCustomFilterComparator';
import agGridTextCustomFilterFormatter from '../../ag-grid/filters/agGridTextCustomFilterFormatter';
import AgGridTimeCellRenderer from '../../ag-grid/cell-renderers/AgGridTimeCellRenderer.vue';
import AgGridDateTimeCellRenderer from '../../ag-grid/cell-renderers/AgGridDateTimeCellRenderer.vue';
import AgGridIconCellRenderer from '../../ag-grid/cell-renderers/AgGridIconCellRenderer.vue';
import AgGridRowActionButtonsCellRenderer
from '../../ag-grid/cell-renderers/AgGridRowActionButtonsCellRenderer.vue';
import {
  FLIGHT_CHANGE_CONTINGENCIES_SELECTION,
  FLIGHT_CONTINGENCIES_SET_GRID_API,
  FLIGHT_CONTINGENCY_FIT_COLUMNS_TO_VIEW,
} from '../../../store/modules/contingency/contingency';
import agGridTimeFormatter from '../../ag-grid/formatters/agGridTimeFormatter';
import { FlightBound } from '../../shared/model/flightBound';
import AgGridIconTooltipOperationCellRenderer
from '../../ag-grid/cell-renderers/AgGridIconTooltipOperationCellRenderer.vue';
import AgGridRowActionButtonsContingenciesCellRenderer
from '../../ag-grid/cell-renderers/AgGridRowActionButtonsContingenciesCellRenderer.vue';
import AgGridCustomService from '../../shared/services/agGridCustomService';
import RolePermissions from '../../mixins/RolePermissions.vue';
import { contingencyInvoice } from '../../shared/model/contingencyInvoice';

export default {
  name: 'contingencies-list-table',
  mixins: [AgGridConnectedFlights, FlightsDifferences, RolePermissions],
  components: {
    AgGridVue,
  },
  props: {
    items: { type: Array, required: true },
    isFilterDisplayed: { type: Boolean, required: false, default: false },
  },
  computed: {
    sizeFilter() {
      return this.isFilterDisplayed ? '250px' : '180px';
    },
    fitColumnsOnResize() {
      return this.$store.state.contingency.fitColumnsOnResize;
    },
  },
  data() {
    const that = this;
    const rolePermission = this.getRoles();
    return {
      dialog: false,
      gridOptions: {
        onFilterChanged: this.uncheckAllOnFilter,
        getRowNodeId: this.getRowNodeId,
        getRowClass: this.getRowClass,
        floatingFilter: true,
        groupHeaderHeight: 35,
        headerHeight: 35,
        floatingFiltersHeight: 20,
        rowHeight: 30,
        context: {
          module: FisModule.CONTINGENCY,
        },
      },
      multiSortKey: 'ctrl',
      frameworkComponents: {
        AgGridValueBaseCellRenderer,
        AgGridTimeCellRenderer,
        AgGridDateTimeCellRenderer,
        AgGridRowActionButtonsCellRenderer,
        AgGridIconCellRenderer,
        AgGridIconTooltipOperationCellRenderer,
        AgGridRowActionButtonsContingenciesCellRenderer,
      },
      defaultColDef: {
        resizable: true,
        sortable: true,
        minWidth: 30,
        suppressMenu: true,
        filter: 'agTextColumnFilter',
        filterParams: {
          textCustomComparator: agGridTextCustomFilterComparator,
          textFormatter: agGridTextCustomFilterFormatter,
        },
        cellClassRules: cellClassAgGridRules,
      },
      columnDefs: [
        {
          headerName: '',
          children: [
            {
              headerTooltip: 'Check/uncheck all',
              hide: !this.hasPermissions(rolePermission),
              checkboxSelection: true,
              width: 21,
              minWidth: 20,
              headerCheckboxSelection: true,
              headerCheckboxSelectionFilteredOnly: true,
              suppressMenu: true,
              sortable: false,
              suppressMovable: true,
              suppressSizeToFit: true,
              filter: false,
              resizable: false,
              colCustomConfig: {
                exportCsvDisabled: true,
              },
              editable: this.editable(true, rolePermission),
            },
          ],
        },
        {
          colId: 'contingency.id',
          headerName: 'ID',
          field: 'id',
          editable: false,
        },
        {
          headerValueGetter() {
            return that.$i18n.t('commonForm.flightInformation');
          },
          children: [
            {
              colId: 'contingency.flightNo',
              headerValueGetter() {
                return that.$i18n.t('common.flight');
              },
              cellClass: 'flight-number-cell separator',
              valueGetter(params) {
                return params.data.flightNo();
              },
              cellRendererFramework: AgGridValueBaseCellRenderer,
            },
            {
              colId: 'contingency.flightDate',
              headerValueGetter() {
                return that.$i18n.t('common.date');
              },
              field: 'flightDate',
              cellClass: 'date-cell separator',
              valueFormatter(params) {
                return that.$moment(params.data.scheduledTime ? params.data.scheduledTime : params.data.flightDate).format('DD/MM/YYYY');
              },
            },
            {
              colId: 'contingency.scheduledTime',
              headerValueGetter() {
                return that.$i18n.t('common.scheduledTimeOfDeparture');
              },
              field: 'scheduledTime',
              cellClass: 'airline-from-cell',
              colCustomConfig: {
                flightBound: FlightBound.INBOUND,
                fieldsRelated: ['rushBaggage.flightDate'],
                oldValueFormatter: agGridTimeFormatter,
              },
              valueGetter(params) {
                return that.agGridTimeGetter(params.data.scheduledTime, params.data.scheduledTime ? params.data.scheduledTime : params.data.flightDate);
              },
              valueFormatter: agGridTimeFormatter,
              cellRendererFramework: AgGridTimeCellRenderer,
            },
            {
              colId: 'contingency.airport',
              headerValueGetter() {
                return that.$i18n.t('common.to');
              },
              cellClass: 'airport-from-cell',
              valueGetter(params) {
                return params.data.airportOriginIataCode != null ? params.data.airportOriginIataCode : params.data.airportDestinationIataCode;
              },
            },
          ],
        },
        {
          headerValueGetter() {
            return that.$i18n.t('contingencies.contingencyInformation');
          },
          children: [
            {
              colId: 'contingency.managerName',
              headerValueGetter() {
                return that.$i18n.t('contingencies.managerName');
              },
              field: 'managerName',
              cellRendererFramework: AgGridValueBaseCellRenderer,
            },
            {
              colId: 'contingency.supportName',
              headerValueGetter() {
                return that.$i18n.t('contingencies.supportName');
              },
              field: 'supportName',
              cellRendererFramework: AgGridValueBaseCellRenderer,
            },
            {
              colId: 'contingency.managerPosition',
              headerValueGetter() {
                return that.$i18n.t('contingencies.managerPosition');
              },
              field: 'managerPosition',
              cellRendererFramework: AgGridValueBaseCellRenderer,
            },
            {
              colId: 'contingency.supportPosition',
              headerValueGetter() {
                return that.$i18n.t('contingencies.supportPosition');
              },
              field: 'supportPosition',
              cellRendererFramework: AgGridValueBaseCellRenderer,
            },
            {
              colId: 'contingency.closedDate',
              headerValueGetter() {
                return that.$i18n.t('contingencies.closedDate');
              },
              field: 'closedDate',
              cellClass: 'date-cell separator',
              valueFormatter(params) {
                return params.data.closedDate != null ? that.$moment(params.data.closedDate).format('DD/MM/YYYY') : null;
              },
            },
          ],
        },
        {
          headerName: '',
          width: 100,
          cellClass: 'text-center',
          field: 'id',
          suppressMenu: true,
          sortable: false,
          suppressMovable: true,
          suppressSizeToFit: true,
          filter: false,
          resizable: false,
          suppressNavigable: true,
          colCustomConfig: {
            exportCsvDisabled: true,
          },
          valueGetter(params) {
            const statusClose = params.data.status === 'CLOSED';
            const invoiced = params.data.invoiced === contingencyInvoice.INVOICED;
            return {
              icon: statusClose ? 'lock' : 'lock_open',
              color: 'bggreydark',
              tooltip: statusClose ? 'CLOSED' : 'OPENED',
              iconI: invoiced ? 'attach_money' : 'money_off',
              colorI: invoiced ? 'green' : 'bggreydark',
            };
          },
          cellRendererFramework: AgGridRowActionButtonsContingenciesCellRenderer,
          cellRendererParams: {
            isEditable: that.hasPermissions(rolePermission),
            deleteItem: this.deleteItem,
            editItem: this.editItem,
            close: this.closeItem,
            invoice: this.invoiceItem,
          },
        },
      ],
    };
  },
  methods: {
    onGridReady(params) {
      this.setAgGridApi(params);
      this.showLoading();
      this.$store.commit(FLIGHT_CONTINGENCIES_SET_GRID_API, params); // TODO eliminar api del store

      this.refreshCellsOnMutation();
      this.refreshCellsOnChangeLanguage();
    },
    resized() {
      if (this.gridApi) {
        this.gridApi.sizeColumnsToFit();
      }
    },
    onSelectionChanged(event) {
      const selectedNodes = event.api.getSelectedNodes();
      const selectedData = selectedNodes.map(node => node.data);
      this.$store.commit(FLIGHT_CHANGE_CONTINGENCIES_SELECTION, selectedData);
    },
    deleteItem(item) {
      this.$emit('delete-item', item);
    },
    editItem(item) {
      this.$emit('edit-item', item);
    },
    closeItem(item) {
      if (item.status === 'OPENED') {
        this.$emit('close-item', item);
      } else {
        this.$emit('open-item', item);
      }
    },
    invoiceItem(item) {
      if (item.invoiced === contingencyInvoice.NOT_INVOICED) {
        this.$emit('invoice-item', item);
      } else {
        this.$emit('no-invoice-item', item);
      }
    },
    renderNeededDiffs(differences, rowNode) {
      const { gridApi } = this.$store.state.contingency;

      const agGridCustomService = new AgGridCustomService(this.columnDefs); // TODO one time
      const colIds = agGridCustomService.getColIdByMatchAnyfieldsRelated(this.joinAllPropertyPath(differences));

      if (colIds && colIds.length > 0) {
        colIds.forEach((colId) => {
          if (gridApi.getColumnDef(colId)) {
            gridApi.refreshCells({
              rowNodes: [rowNode],
              columns: [colId],
              force: true,
            });

            gridApi.flashCells({
              rowNodes: [rowNode],
              columns: [colId],
              force: true,
            });
          }
        });
      }
    },
    sizeColumnsToFit() {
      if (this.gridApi) {
        this.$store.dispatch(FLIGHT_CONTINGENCY_FIT_COLUMNS_TO_VIEW);
      }
    },
    firstDataRendered() {
      this.$nextTick(() => {
        this.resized();
      });
    },
  },
};

</script>
