<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <entity-configuration-list
      :entity-name="this.$i18n.t('configuration.milestoneAirport')"
      entity-icon="emoji_flags"
      :framework-components="frameworkComponents" :column-defs="columnDefs" :items="airportActiveMilestones"
      @confirm-edit-item="editConfirm"
      @confirm-delete-item="deleteConfirm"
    >
      <template v-slot:modal-content="{ itemEdit }">
        <airport-milestone-edit-card-content :item="itemEdit" />
      </template>
    </entity-configuration-list>
  </div>
</template>
<script>
import {
  AIRPORT_ACTIVE_MILESTONE_CREATE,
  AIRPORT_ACTIVE_MILESTONE_UPDATE,
  AIRPORT_ACTIVE_MILESTONE_DELETE,
  GET_AIRPORT_ACTIVE_MILESTONES,
} from '@/store/modules/airportMilestone/airportMilestoneCatalog';
import EntityConfigurationList from '@/components/configuration/common/EntityConfigurationList.vue';
import AirportMilestoneEditCardContent from './AirportMilestoneEditCardContent.vue';
import AgGridRowActionButtonsCellRenderer from '../../ag-grid/cell-renderers/AgGridRowActionButtonsCellRenderer.vue';
import AgGridIconCellRenderer from '../../ag-grid/cell-renderers/AgGridIconCellRenderer.vue';
import { FlightBound } from '../../shared/model/flightBound';

export default {
  name: 'AirportMilestonesList',
  components: {
    EntityConfigurationList,
    AirportMilestoneEditCardContent,
  },
  data() {
    const that = this;
    return {
      frameworkComponents: {
        AgGridRowActionButtonsCellRenderer,
        AgGridIconCellRenderer,
      },
      columnDefs: [
        {
          field: 'name',
          headerValueGetter() {
            return that.$i18n.t('configuration.name');
          },
        },
        {
          field: 'description',
          headerValueGetter() {
            return that.$i18n.t('configuration.description');
          },
        },
        {
          field: 'type',
          headerValueGetter() {
            return that.$i18n.t('configuration.type');
          },
        },
        {
          field: 'flightBound',
          headerValueGetter() {
            return that.$i18n.t('configuration.flightBound');
          },
          valueGetter(params) {
            if (FlightBound.INBOUND.description === params.data.flightBound) {
              return that.$i18n.t('common.inbound');
            }
            if (FlightBound.OUTBOUND.description === params.data.flightBound) {
              return that.$i18n.t('common.outbound');
            }
            return that.$i18n.t('common.both');
          },
        },
        {
          field: 'status',
          headerValueGetter() {
            return that.$i18n.t('configuration.scope');
          },
        },
      ],
    };
  },
  computed: {
    airportActiveMilestones() {
      return this.$store.getters.getAirportActiveMilestones;
    },
  },
  methods: {
    getAirportActiveMilestones() {
      this.$store.dispatch(GET_AIRPORT_ACTIVE_MILESTONES);
    },
    editItem(item) {
      if (item.disableEdit === undefined || !item.disableEdit) {
        this.$store.dispatch(AIRPORT_ACTIVE_MILESTONE_UPDATE, item);
      }
    },
    editConfirm(item) {
      this.$store.dispatch(item.id ? AIRPORT_ACTIVE_MILESTONE_UPDATE : AIRPORT_ACTIVE_MILESTONE_CREATE, item);
    },
    deleteConfirm(item) {
      this.$store.dispatch(AIRPORT_ACTIVE_MILESTONE_DELETE, item.id);
    },
  },
  created() {
    this.getAirportActiveMilestones();
  },
};
</script>
