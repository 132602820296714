
const LOCAL_STORAGE_AUTH_KEY = 'fis-user-auth';

export default class AuthService {
  static clearAuth() {
    localStorage.removeItem(LOCAL_STORAGE_AUTH_KEY);
  }

  static setAuth(auth) {
    localStorage.setItem(LOCAL_STORAGE_AUTH_KEY, JSON.stringify(auth));
  }

  static getAuth() {
    return localStorage.getItem(LOCAL_STORAGE_AUTH_KEY) ? JSON.parse(localStorage.getItem(LOCAL_STORAGE_AUTH_KEY)) : null;
  }

  static updateAccessToken(newAccessToken) {
    const auth = this.getAuth();
    auth.accessToken = newAccessToken;
    this.setAuth(auth);
  }

  static getAccessToken() {
    const auth = this.getAuth();
    if (auth) {
      return auth.accessToken;
    }
    return null;
  }

  static getRefreshToken() {
    const auth = this.getAuth();
    if (auth) {
      return auth.refreshToken;
    }
    return null;
  }

  static getAuthHeader() {
    const token = this.getAccessToken();
    if (token) {
      return `Bearer ${token}`;
    }
    return null;
  }
}
