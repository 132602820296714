<template>
  <ag-grid-template-cell-renderer :params="params">
    <v-tooltip bottom v-if="params.value && params.value.tooltip">
      <template slot="activator">
        <span :style="styleObject">{{params.value.value}}<sup>{{params.value.sup}}</sup></span>
      </template>
      <span>{{params.value.tooltip}}</span>
    </v-tooltip>
    <span v-if="params.value && !params.value.tooltip" :style="styleObject">{{params.value.value}}<sup>{{params.value.sup}}</sup></span>
  </ag-grid-template-cell-renderer>
</template>

<script>
import Vue from 'vue';
import AgGridTemplateCellRenderer from './templates/AgGridTemplateCellRenderer.vue';

export default Vue.extend({
  components: {
    AgGridTemplateCellRenderer,
  },
  computed: {
    styleObject() {
      return {
        color: (this.params && this.params.value && this.params.value.color) ? this.params.value.color : '',
      };
    },
  },
});
</script>

<style scoped>

</style>
