<template>
  <v-img :src="headerImage" height="300px">
    <v-layout column fill-height style="margin: 0 -4px 0 0">
      <v-card-title>
        <v-spacer/>
        <v-btn @click="editItem" dark icon class="card-title-background mr-3">
          <v-icon>edit</v-icon>
        </v-btn>
      </v-card-title>
      <v-spacer/>
      <v-card-title class="white--text card-title-background">
        <div class="pl-4">
          <h4 id="airport-name" class="headline font-weight-light pa-1"><v-icon left dark>local_airport</v-icon>{{ item.title }}</h4>
          <h6 v-if="item.contact.addressRegion && item.contact.addressCountry" class="subheading font-weight-light pa-1">{{ item.contact.addressRegion }}. {{ item.contact.addressCountry }}</h6>
          <div>
            <span class="caption pa-1">IATA: {{ item.map.IATA }}</span>
            <v-divider class="mx-3" inset vertical></v-divider>
            <span class="caption pa-1">OACI: {{ item.map.ICAO }}</span>
          </div>
        </div>
      </v-card-title>
    </v-layout>
  </v-img>
</template>

<script>
import { BASE_URL } from '@/http';

export default {
  name: 'CardAirportHeader',
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  methods: {
    editItem() {
      this.$emit('click-edit-item', this.item);
    },
  },
  computed: {
    headerImage() {
      return `${BASE_URL}directory-files/images/0/${this.item.id ? this.item.id : -1}`;
    },
  },
};
</script>

<style scoped>
.container.grid-list-md .layout {
  margin: 0 !important;
}

.card-title-background {
  background-color: black;
  opacity: 0.6;
  filter: alpha(opacity=60); /* For IE8 and earlier */
}
</style>
