<template>
  <v-layout row justify-center>
    <v-dialog v-model="dialog" persistent max-width="1370px"
      :content-class="isContentVisible ? 'modal-common' : 'modal-common hide-title'">
      <v-card :dark="this.$vuetify.dark" :color="this.$vuetify.dark ? 'bggrey_dark' : 'bggrey'">
        <v-toolbar dark color="primary">
          <v-icon>flight</v-icon>
          <v-toolbar-title>{{ $t("common.newAircraftGround") }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <!-- Botón para contraer/expandir el contenido -->
          <v-btn icon @click="toggleContentVisibility">
            <v-icon>{{ isContentVisible ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
          </v-btn>
        </v-toolbar>
        <div v-show="isContentVisible">
          <loading-component />

          <v-card-text v-if="!aircraftGroundCreated">
            <aircraft-ground-form-container v-model="aircraftGround" @form-update="validAircraftGroundForm" />
          </v-card-text>

          <v-card-text v-if="aircraftGroundCreated">
            <v-container fluid>
              <v-layout align-center justify-space-around column fill-height>
                <span>{{ $t("common.aircraftGroundCreatedSuccessfully") }}</span>
                <span><v-btn flat color="primary"
                    @click="addServices">Add</v-btn>{{ $t("common.servicesForAircraftGround") }}</span>
              </v-layout>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn flat color="primary" @click="reject" :disabled="loading">{{ $t("common.cancel") }}</v-btn>
            <v-btn v-if="!aircraftGroundCreated" color="primary" @click="saveAircraftGround"
              :disabled="loading || !isValidAircraft">{{ $t("common.save") }}</v-btn>
            <v-btn v-if="aircraftGroundCreated" color="primary" @click="confirm">{{ $t("common.close") }}</v-btn>
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>
  </v-layout>
</template>
<script>

import LoadingComponent from '../layout/LoadingComponent.vue';
import AircraftGroundFormContainer from './AircraftGroundFormContainer.vue';
import AircraftGround from '../operations/model/aircraftGround';
import { REQUEST_ERROR, REQUEST_SUCCESS } from '../../store/store';
import { NOTIFICATION_SHOW } from '../../store/modules/notifications';
import { GET_AIRCRAFT } from '../../store/modules/aircraft/aircraftCatalog';

export default {
  name: 'aircraft-ground-new-modal',
  components: {
    AircraftGroundFormContainer,
    LoadingComponent,
  },
  props: {
    dialog: Boolean,
    addService: { type: Boolean, default: true },
  },
  computed: {
    loading() {
      return this.$store.state.loading;
    },
    isValidAircraft() {
      return this.validAircraftGround;
    },
  },
  data() {
    return {
      aircraftGround: AircraftGround.createEmptyAircraftGround,
      validAircraftGround: false,
      aircraftGroundCreated: false,
      isContentVisible: true,
    };
  },
  methods: {
    reject() {
      this.$emit('aircraft-ground-new-reject');
    },
    confirm() {
      this.$emit('aircraft-ground-new-confirm', {});
    },
    validAircraftGroundForm(valid) {
      this.validAircraftGround = valid;
    },
    saveAircraftGround() {
      this.$http.post('aircraft-ground', this.aircraftGround, {
      }).then((resp) => {
        this.$store.commit(REQUEST_SUCCESS);
        this.aircraftGround = AircraftGround.createAircraftGroundFromData(resp.data);
        if (this.addService) {
          this.aircraftGroundCreated = true;
        } else {
          this.confirm();
        }
      }).catch((err) => {
        this.$store.commit(REQUEST_ERROR, err);
        this.$store.commit(NOTIFICATION_SHOW, {
          message: err.response.data.title || this.$i18n.t('notifications.errorNotification'),
          color: 'error',
        });
      });
    },
    addServices() {
      this.$emit('aircraft-ground-new-service', this.aircraftGround);
    },
    toggleContentVisibility() {
      this.isContentVisible = !this.isContentVisible;
    },
  },
  mounted() {
    this.$store.dispatch(GET_AIRCRAFT);
  },
};
</script>
<style></style>
