<template>
  <div>
    <v-flex v-flex-none>
      <date-input :ref="'refComp'" :locale="$i18n.locale" v-model="dateUpdated"></date-input>
    </v-flex>
  </div>
</template>

<script>
import Vue from 'vue';
import AgGridParentConnectedFlightsCellEditor from '../mixins/AgGridParentConnectedFlightsCellEditor.vue';
import DateInput from '../../../ui/DateInput.vue';
import { FlightBound } from '../../../shared/model/flightBound';
import AgGridFlightDateCellEditorMixin from '../mixins/AgGridFlightDateCellEditorMixin.vue';

export default Vue.extend({
  mixins: [AgGridParentConnectedFlightsCellEditor, AgGridFlightDateCellEditorMixin],
  components: {
    DateInput,
  },
  data() {
    return {
      dateOriginal: '',
      dateUpdated: '',
      flight: {},
    };
  },
  methods: {
    getValue() {
      this.unmarkCellsSelectedAsEdit();
      if (!this.dateOriginal) {
        return this.params.node.data;
      }
      if (this.dateUpdated && !this.dateOriginal.isSame(this.dateUpdated, 'day')) {
        const date = this.dateUpdated;

        if (date.isValid()) {
          const valueCell = [];
          valueCell.push({ id: 'common.flightDate', value: this.dateUpdated, initialValue: this.dateOriginal });

          const connectedFlightPropertyName = this.getConnectedFlightsPropertyName();
          if (valueCell.length > 0) {
            if (connectedFlightPropertyName === this.flightDatePropertyName) {
              this.setNewFlightDate(date);
              return date.toDate();
            }
            this.proceedAsDefault(valueCell, date); // TODO: Can it be removed?
          }
          return date.toDate();
        }
      }
      return this.params.node.data;
    },
  },
  proceedAsDefault(valueCell, date) {
    const that = this;
    this.saveFlightChangesFromCellValue((s) => {
      s.setFlightPropertyAllBound('flightDate', date);
      if (this.isInboundField(this.params.column.colDef.colCustomConfig.flightBound)) {
        let scheduledTimeInbound = s.getChildPropertyValue(FlightBound.INBOUND, ['common', 'scheduledTime']);
        if (scheduledTimeInbound) {
          scheduledTimeInbound = that.$moment(scheduledTimeInbound)
            .year(this.dateUpdated.year())
            .month(this.dateUpdated.month())
            .date(this.dateUpdated.date());
          s.setChildPropertyValueByBound(FlightBound.INBOUND, 'common.scheduledTime', scheduledTimeInbound);
          that.addChange('common.scheduledTime', scheduledTimeInbound);
        }
      } else if (this.isOutboundField(this.params.column.colDef.colCustomConfig.flightBound)) {
        let scheduledTimeOutbound = s.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'scheduledTime']);
        if (scheduledTimeOutbound) {
          scheduledTimeOutbound = that.$moment(scheduledTimeOutbound)
            .year(this.dateUpdated.year())
            .month(this.dateUpdated.month())
            .date(this.dateUpdated.date());
          s.setChildPropertyValueByBound(FlightBound.OUTBOUND, 'common.scheduledTime', scheduledTimeOutbound);
          that.addChange('common.scheduledTime', scheduledTimeOutbound);
        }
      }
    }, 'Date', valueCell);
  },
  created() {
    const schedulingFlight = this.params.node.data;
    this.flight = schedulingFlight.getBound(this.params.column.colDef.colCustomConfig.flightBound);
    if (this.flight) {
      this.dateOriginal = this.$moment(this.flight.scheduledDate());
      this.dateUpdated = this.dateOriginal.clone();
      this.markCellsSelectedAsEdit();
    } else {
      console.error('Flight is null'); // eslint-disable-line no-console
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$refs.refComp && this.$refs.refComp.$refs && this.$refs.refComp.$refs.refDateInput && this.$refs.refComp.$refs.refDateInput.focus) {
        this.$refs.refComp.$refs.refDateInput.focus();
      }
    });
  },
});
</script>

<style scoped>

</style>
