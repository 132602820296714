<template>
  <div>
    <v-flex v-flex-none>
      <v-text-field :ref="refNumberInput" v-model="updatedNumber" type="number" min="0" ></v-text-field>
    </v-flex>
  </div>
</template>

<script>
import Vue from 'vue';
import AgGridParentConnectedFlightsCellEditor from '../mixins/AgGridParentConnectedFlightsCellEditor.vue';
import { JSUtils } from '../../../shared/js/JSUtils';

export default Vue.extend({
  mixins: [AgGridParentConnectedFlightsCellEditor],
  components: {
  },
  props: {
    refNumberInput: {
      type: String,
      required: false,
      default: 'refNumberInput',
    },
  },
  data() {
    return {
      originalNumber: null,
      updatedNumber: null,
    };
  },
  methods: {
    getValue() {
      this.unmarkCellsSelectedAsEdit();
      if (this.originalNumber !== this.updatedNumber) {
        const that = this;
        const connectedFlightPropertyName = this.getConnectedFlightsPropertyName();

        this.saveFlightChangesFromValue((s) => {
          s.setChildPropertyValueByBound(that.params.column.colDef.colCustomConfig.flightBound, connectedFlightPropertyName, that.updatedNumber);
        }, JSUtils.decamelizeString(connectedFlightPropertyName, ' '), this.originalNumber, this.updatedNumber);
      }
      return this.params.node.data;
    },
  },
  created() {
    this.originalNumber = this.params.value ? this.params.value : null;
    this.updatedNumber = this.originalNumber;

    this.markCellsSelectedAsEdit();
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$refs.refNumberInput) {
        this.$refs.refNumberInput.$refs.input.focus();
      }
    });
  },
});
</script>

<style>

</style>
