<template>
  <div>
    <flight-messages-list-filter @changeShowFilter="changeShowFilter"></flight-messages-list-filter>
    <flight-messages-list-container :isFilterDisplayed="filterDisplayed"></flight-messages-list-container>

    <!--<operation-flights-list-options @click-archive="archiveFlights" @click-unarchive="unarchiveFlights" @click-cancel="cancelFlights" @click-new-aircraft-ground="newAircraftGround" @click-delete-aircraft-ground="deleteAircraftGround" />-->
  </div>
</template>
<script>
import FlightMessagesListFilter from '../components/messages/list/FlightMessagesListFilter.vue';
import FlightMessagesListContainer from '../components/messages/list/FlightMessagesListContainer.vue';

export default {
  components: {
    FlightMessagesListFilter,
    FlightMessagesListContainer,
  },
  computed: {
  },
  data() {
    return {
      filterDisplayed: false,
    };
  },
  methods: {
    changeShowFilter(dispayled) {
      this.filterDisplayed = dispayled;
    },
  },
};
</script>
