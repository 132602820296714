<template>
  <div v-if="params.isEditable">
    <v-icon v-if="params.value && params.value.iconI"
      :color="params.value.colorI"
      small
      class="mr-2"
      @click.stop="params.invoice(params.data)"
    >
      {{params.value.iconI}}
    </v-icon>
    <v-icon v-if="params.value && params.value.icon"
            class="mr-2"
            slot="activator"
            :color="params.value.color"
            small
            @click.stop="params.close(params.data)"
    >{{params.value.icon}}
    </v-icon>
    <v-icon
      color="secondary"
      small
      class="mr-2"
      @click.stop="params.editItem(params.data)"
    >
      edit
    </v-icon>
    <v-icon
      color="error"
      small
      @click.stop="params.deleteItem(params.data)"
    >
      delete
    </v-icon>
  </div>
  <div v-else-if="!params.isEditable">
    <v-icon
      color="bggreydark"
      small
      class="mr-2"
      @click.stop="params.editItem(params.data)"
    >
      remove_red_eye
    </v-icon>
  </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
});
</script>

<style scoped>

</style>
