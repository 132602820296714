Hablando de hackear<template>
  <div class="admin-imports">
    <AdminSchedulingImports></AdminSchedulingImports>
    <!--
      Long long time ago, it was going to be an administration sub-section instead of be an scheduling sub-section
      that is why we have an AdminImport and an AdminSchedulingImports.

      To do:
        <AdminSchedulingImports></AdminSchedulingImports>
        <AdminRampServicesImports></AdminRampServicesImports>
        ....

       But the client decided that it would be just a scheduling subsection.. so.. ¿should AdminImports be deprecated?
    -->
  </div>
</template>
<script>
import AdminSchedulingImports from './scheduling/AdminSchedulingImports.vue';

export default {
  name: 'AdminImports',
  components: {
    AdminSchedulingImports,
  },
};
</script>

<style>
  .admin-imports {
    width: 100%;
    height: 100%;
  }
</style>
