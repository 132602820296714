import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListGroup } from 'vuetify/lib/components/VList';
import { VListTile } from 'vuetify/lib/components/VList';
import { VListTileAction } from 'vuetify/lib/components/VList';
import { VListTileContent } from 'vuetify/lib/components/VList';
import { VListTileTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VNavigationDrawer,{staticClass:"sidebar-background",attrs:{"clipped":_vm.$vuetify.breakpoint.lgAndUp,"fixed":"","app":"","hide-overlay":""},on:{"transitionend":function($event){return _vm.$store.dispatch('SCHED_GRID_FIT')}},model:{value:(_vm.showing),callback:function ($$v) {_vm.showing=$$v},expression:"showing"}},[_c(VList,{attrs:{"dense":""}},[_vm._l((_vm.items),function(item,index){return [(item.items && _vm.canBeShown(item))?_c(VListGroup,{key:item.text,attrs:{"no-action":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c(VListTile,[_c(VListTileAction,[_c(VIcon,[_vm._v(_vm._s(item.icon))])],1),_c(VListTileContent,[_c(VListTileTitle,[_vm._v(_vm._s(_vm.$t(`sidebar.${item.text}`)))])],1)],1)]},proxy:true}],null,true),model:{value:(item.active),callback:function ($$v) {_vm.$set(item, "active", $$v)},expression:"item.active"}},[_vm._l((item.items),function(subItem){return [(_vm.canBeShown(subItem))?_c(VListTile,{key:subItem.title,attrs:{"to":{ name: subItem.link }},on:{"click":function($event){return _vm.closeSideBar()}}},[_c(VListTileContent,[_c(VListTileTitle,[_vm._v(_vm._s(_vm.$t(`sidebar.${subItem.text}`)))])],1)],1):_vm._e()]})],2):(_vm.canBeShown(item))?_c(VListTile,{key:index,attrs:{"to":{ name: item.link }},on:{"click":function($event){return _vm.closeSideBar()}}},[_c(VListTileAction,[_c(VIcon,[_vm._v(_vm._s(item.icon))])],1),_c(VListTileContent,[_c(VListTileTitle,[_vm._v(_vm._s(_vm.$t(`sidebar.${item.text}`)))])],1)],1):_vm._e()]})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }