<template>
  <div>
    <v-icon
      color="secondary"
      small
      class="mr-2"
      @click.stop="params.editItem(params.data)"
    >
      edit
    </v-icon>
    <v-icon
      color="error"
      small
      @click.stop="params.deleteItem(params.data)"
    >
      delete
    </v-icon>
  </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
});
</script>

<style scoped>

</style>
