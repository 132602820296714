<template>
  <div>
    <div>
      <svg :width="getWidth()" height="100%" viewBox="0 0 55.75 55.75"> <defs>
        <linearGradient id="logo-gradient"  x1="100%" y2="100%">
          <stop offset="0%" stop-color="#FD060010">
            <animate attributeName="stop-color" values="#FD060010; #FD0600; #FD060010" dur="5s" repeatCount="indefinite"></animate>
          </stop>
        </linearGradient>

      </defs>

        <g>
          <path id="loaderStatic" fill="#FD0600" d="M24.991,17.858c0.098-0.753,0.309-1.783,0.472-2.347c0.339-1.167,0.496-1.556,0.91-2.006
                        c0.352-0.387,0.934-0.344,0.934-0.344s0.406-0.911,0.409-0.927c-0.156-0.026-0.87,0.152-1.33,0.498
                        c-0.24,0.18-0.491,0.364-1.203,2.335c-0.63,1.749-0.629,2.448-0.74,2.816c-0.029,0.139-0.198,1.158-0.29,1.856
                        c-0.058-0.011-0.119-0.012-0.178,0.012c-7.444,2.809-9.383,3.432-12.906,4.567l-1.256,0.404c-0.123,0.039-0.21,0.145-0.228,0.27
                        c-0.085,0.596-0.127,1.005-0.153,1.291c-0.009,0.104,0.03,0.208,0.106,0.277c0.053,0.049,0.084,0.08,0.215,0.08
                        c0.226,0,0.746-0.092,2.173-0.341c3.406-0.596,9.746-1.7,12.066-1.726c0.006,0,0.014-0.003,0.02-0.003
                        c0.022,0.321,0.047,0.64,0.079,0.958c0.1,1.017,0.046,0.38,0.2,1.627c0.187,1.309,0.422,2.61,0.639,3.928
                        c0.229,1.368,0.098,0.584,0.29,1.889c0.286,2.087,0.588,4.277,0.209,6.642c-0.125,0.795-3.524,2.69-3.524,2.69
                        c-0.049,0.488,0.037,1.217,0.037,1.217l2.863-1.2l2.325-0.791l0.104-0.884c0.831-4.692-0.34-8.822-1.314-12.776
                        c-0.16-0.65-0.316-1.296-0.455-1.936c-0.255-1.173-0.461-2.335-0.558-3.497c-0.028-0.324-0.041-0.632-0.054-0.942
                        c-0.02-0.492-0.029-0.969-0.019-1.418C24.848,19.269,24.906,18.528,24.991,17.858z"></path>

          <path id="loader" fill="#FD0600" d="M27.665,0C12.394,0-0.401,12.541,0.01,27.995c0.309,11.938,7.443,19.088,7.443,19.088l0.635-1.574
                        c0,0-0.011-0.184-0.236-0.438c-2.877-3.194-5.375-10.755-5.714-14.462c-0.1-1.063-0.117-2.111-0.076-3.145l2.953-0.004
                        c0.275,0,0.5-0.226,0.5-0.5s-0.225-0.5-0.5-0.5l0,0l-2.896,0.004c0.364-4.44,1.977-8.523,4.437-11.959l2.003,1.482
                        c0.089,0.066,0.193,0.099,0.297,0.099c0.152,0,0.305-0.07,0.401-0.203c0.165-0.222,0.118-0.535-0.104-0.699L7.15,13.702
                        c2.542-3.269,5.875-5.887,9.626-7.584l1.113,2.354c0.086,0.181,0.266,0.286,0.451,0.286c0.072,0,0.146-0.015,0.215-0.048
                        c0.25-0.118,0.355-0.417,0.237-0.666l-1.097-2.318c2.815-1.141,5.842-1.772,8.933-1.772c1.65,0,3.064,0.048,4.343,0.186v2.028
                        c0,0.275,0.224,0.5,0.5,0.5c0.274,0,0.5-0.225,0.5-0.5V4.271c3.291,0.513,5.761,1.765,9.403,4.577
                        c2.5-0.687,4.608-1.233,5.563-1.281c-0.013-0.01-3.125-2.285-3.138-2.296C39.257,1.95,33.688,0,27.665,0z"></path>

        </g>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoaderFis',
  props: {
    width: {
      type: Number,
      default: 100,
    },
  },
  methods: {
    getWidth() {
      return `${this.width}px`;
    },
  },
};
</script>

<style scoped>

  #loader {
    transform-origin: 50% 50%;
    position: absolute;
    display: block;
    -webkit-animation: spin 5s linear infinite; /* speed animation */
    animation: spin 3s linear infinite; /* speed animation */
  }

  #loaderStatic {
    transform-origin: 50% 50%;
    position: absolute;
    display: block;
  }

  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }

  }

  /*compatibility styles*/
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

</style>
