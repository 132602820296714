<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-text-field
    v-model="timeString"
    v-char-length="6"
    mask="##:##X"
    :return-masked-value="true"
    :label="label"
    :ref="refInput"
    :disabled="disabled"
    :rules="rules"
    @keydown.native="keyEventTimeInput"
    @input="changeTimeString"
    @blur="onBlur"
    :box="box"
  >
  </v-text-field>
</template>

<script>
import VTextField from 'vuetify/lib/components/VTextField/VTextField';
import Arrowable from '../mixins/Arrowable.vue';
import { keyEvents } from '../keyEvents';

export default {
  mixins: [Arrowable],
  components: { VTextField },
  props: {
    refInput: {
      type: String,
      default: 'time-input-ref',
    },
    value: { required: true },
    label: { type: String },
    disabled: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
    },
    box: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      timeMilliSeconds: null,
      timeString: null,
    };
  },
  methods: {
    keyEventTimeInput($event) {
      this.keyEvent($event);

      if ($event.code === keyEvents.UP || $event.code === keyEvents.DOWN) {
        if (!this.timeString || this.timeString.length >= 4) {
          this.timeString = this.addMinutesToStringTime(this.timeString, $event.code === keyEvents.UP ? 1 : -1);
          this.changeTimeString(this.timeString);
        }
      }
    },
    addMinutesToStringTime(timeString, minutes) {
      const timeStringAux = timeString;
      let millisecondsAux = this.getMillisecondsFromStringWithHoursAndMinute(timeStringAux);
      millisecondsAux += (minutes * 60000);
      if (millisecondsAux < 0) {
        millisecondsAux = 0;
      }
      return this.getDurationStringFormatFromMilliseconds(millisecondsAux);
    },
    changeTimeString(value) {
      if (!value || value.trim().length === 0) {
        this.timeMilliSeconds = null;
      } else if (value && value.length === 5) {
        this.timeMilliSeconds = this.getMillisecondsFromStringWithHoursAndMinute(value);
      }
      this.emitTimeMilliseconds();
    },
    onBlur() {
      this.$emit('onBlur', this.timeMilliSeconds ? this.timeMilliSeconds : null);
    },
    getMillisecondsFromStringWithHoursAndMinute(string) {
      const hours = string.substring(0, 2);
      const minutes = string.substring(3, string.length);
      return (3600000 * Number(hours)) + (60000 * Number(minutes));
    },
    emitTimeMilliseconds() {
      this.$emit('input', this.timeMilliSeconds ? this.timeMilliSeconds : null);
    },
    getDurationStringFormatFromMilliseconds(milliseconds) {
      let hours = milliseconds ? (Number(this.$moment.duration(milliseconds).days()) * 24) + Number(this.$moment.duration(milliseconds).hours()) : 0;
      if (hours === 0) {
        hours = '00';
      } else if (hours.toString().length === 1) {
        hours = `0${hours}`;
      }
      let minutes = milliseconds ? this.$moment.duration(milliseconds).minutes() : 0;
      if (minutes === 0) {
        minutes = '00';
      } else if (minutes.toString().length === 1) {
        minutes = `0${minutes}`;
      }
      return `${hours}:${minutes}`;
    },
  },
  created() {
    this.timeString = this.getDurationStringFormatFromMilliseconds(this.value);
    this.timeMilliSeconds = this.value ? this.value : null;
  },
  watch: {
    value: {
      handler(newValue) {
        this.timeString = this.getDurationStringFormatFromMilliseconds(newValue);
        this.timeMilliSeconds = newValue;
      },

    },
  },
};
</script>

<style scoped>
  .input-width {
    max-width: 150px;
  }
</style>
