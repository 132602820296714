/* eslint no-param-reassign: "off" */
import moment from 'moment/moment';
import { HTTP } from '../../../http';
import { REQUEST_ERROR, REQUEST_START, REQUEST_SUCCESS } from '../../store';
import FlightContingencyFactory from '../../../components/shared/model/flightContingencyFactory';
import { NOTIFICATION_SHOW } from '../notifications';
import { createFlightContingencyInformationEventFromData } from '../../../components/shared/model/flightContingencyEvent';
import i18n from '../../../plugins/i18n';
import CrudOperationsDTO from '../../../components/shared/model/crudOperationsDTO';

// actions names
export const FLIGHT_CONTINGENCY_INFORMATION_FIND = 'FLIGHT_CONTINGENCY_INFORMATION_FIND';
export const FLIGHT_CONTINGENCY_INFORMATION_UPDATE = 'FLIGHT_CONTINGENCY_INFORMATION_UPDATE';
export const FLIGHT_CONTINGENCY_INFORMATION_CRUD_UPDATE = 'FLIGHT_CONTINGENCY_INFORMATION_CRUD_UPDATE';
export const FLIGHT_CONTINGENCY_INFORMATION_DELETE = 'FLIGHT_CONTINGENCY_INFORMATION_DELETE';
export const FLIGHT_CONTINGENCY_INFORMATION_DO_EXTERNAL_CHANGE = 'FLIGHT_CONTINGENCY_INFORMATION_DO_EXTERNAL_CHANGE';
export const FLIGHT_CONTINGENCY_INFORMATION_CRUD_INIT = 'FLIGHT_CONTINGENCY_INFORMATION_CRUD_INIT';

// mutations
export const FLIGHT_CONTINGENCY_INFORMATION_SET_GRID_API = 'FLIGHT_CONTINGENCY_INFORMATION_SET_GRID_API';
export const FLIGHT_CONTINGENCY_INFORMATION_FIND_SUCCESS = 'FLIGHT_CONTINGENCY_INFORMATION_FIND_SUCCESS';
export const FLIGHT_CHANGE_CONTINGENCY_INFORMATION_SELECTION = 'FLIGHT_CHANGE_CONTINGENCY_INFORMATION_SELECTION';
export const UPDATE_SHOW_OLD_VALUES = 'UPDATE_SHOW_OLD_VALUES';
export const UPDATE_FLASH_ON_CHANGES = 'UPDATE_FLASH_ON_CHANGES';
export const FLIGHT_CONTINGENCY_INFORMATION_ON_EXTERNAL_CHANGE = 'FLIGHT_CONTINGENCY_INFORMATION_ON_EXTERNAL_CHANGE';
export const FLIGHT_CONTINGENCY_INFORMATION_SET_CHANGE = 'FLIGHT_CONTINGENCY_INFORMATION_SET_CHANGE';
export const FLIGHT_CONTINGENCY_INFORMATION_CRUD_SET_INIT = 'FLIGHT_CONTINGENCY_INFORMATION_CRUD_SET_INIT';


// initial state
const storeState = {
  gridApi: null,
  contingencyInformation: [],
  selectedContingencyInformation: [],
  contingencyInformationChanges: [],
  showOldValues: true,
  flashOnChanges: false,
  crudOperationsDto: new CrudOperationsDTO(),
};

// getters
const storeGetters = {
  hasSelectedContingencyInformation: state => state.selectedContingencyInformation.length > 0,
  isSelectedContingencyInformation: state => id => !!state.selectedContingencyInformation.find(s => s.getId() === id),
};

// actions
const storeActions = {
  [FLIGHT_CONTINGENCY_INFORMATION_FIND]: ({ commit }, contingencyId) => {
    commit(REQUEST_START);
    commit(FLIGHT_CONTINGENCY_INFORMATION_FIND_SUCCESS, []);
    commit(FLIGHT_CHANGE_CONTINGENCY_INFORMATION_SELECTION, []);
    return HTTP.get(`flight-contingency/informations?contingencyId.equals=${contingencyId}`)
      .then((resp) => {
        commit(REQUEST_SUCCESS);
        commit(
          FLIGHT_CONTINGENCY_INFORMATION_FIND_SUCCESS,
          FlightContingencyFactory.createFlightContingencyInformationFromData(resp.data),
        );
        commit(FLIGHT_CHANGE_CONTINGENCY_INFORMATION_SELECTION, []);
      })
      .catch((err) => {
        commit(REQUEST_ERROR, err);
      });
  },
  [FLIGHT_CONTINGENCY_INFORMATION_UPDATE]: ({ commit, dispatch }, item) => {
    commit(REQUEST_START);
    return HTTP.put('flight-contingency/informations', item)
      .then(() => {
        commit(REQUEST_SUCCESS);
        dispatch(FLIGHT_CONTINGENCY_INFORMATION_FIND, item.contingencyId);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.contingencyInformationUpdate'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(REQUEST_ERROR, err);
      });
  },
  [FLIGHT_CONTINGENCY_INFORMATION_CRUD_UPDATE]: ({ commit, dispatch }, { itemsCrud, contingencyId }) => {
    commit(REQUEST_START);
    return HTTP.put(`flight-contingency/informations/${contingencyId}`, itemsCrud)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(FLIGHT_CONTINGENCY_INFORMATION_CRUD_SET_INIT);
        dispatch(FLIGHT_CONTINGENCY_INFORMATION_FIND, contingencyId);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.contingencyUpdate'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(REQUEST_ERROR, err);
      });
  },
  [FLIGHT_CONTINGENCY_INFORMATION_DELETE]: ({ commit }, ids) => {
    commit(REQUEST_START);
    return HTTP.post('flight-contingency/informations/batch-delete', ids)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.contingencyInformationDelete'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
  [FLIGHT_CONTINGENCY_INFORMATION_DO_EXTERNAL_CHANGE]: ({ commit }, change) => {
    commit(FLIGHT_CONTINGENCY_INFORMATION_ON_EXTERNAL_CHANGE, createFlightContingencyInformationEventFromData(change));
  },
  [FLIGHT_CONTINGENCY_INFORMATION_CRUD_INIT]: ({ commit }) => {
    commit(FLIGHT_CONTINGENCY_INFORMATION_CRUD_SET_INIT);
  },
};

// mutations
const storeMutations = {
  [FLIGHT_CONTINGENCY_INFORMATION_SET_GRID_API]: (state, gridApi) => {
    state.gridApi = gridApi.api;
  },
  [FLIGHT_CONTINGENCY_INFORMATION_FIND_SUCCESS]: (state, contingencyInformation) => {
    state.contingencyInformation = contingencyInformation !== null && contingencyInformation.length > 0 ? contingencyInformation : [];
  },
  [FLIGHT_CHANGE_CONTINGENCY_INFORMATION_SELECTION]: (state, selection) => {
    state.selectedContingencyInformation = selection;
  },
  [UPDATE_SHOW_OLD_VALUES]: (state, showOldValues) => {
    state.showOldValues = showOldValues;
  },
  [UPDATE_FLASH_ON_CHANGES]: (state, flashOnChanges) => {
    state.flashOnChanges = flashOnChanges;
  },
  [FLIGHT_CONTINGENCY_INFORMATION_ON_EXTERNAL_CHANGE]: (state, change) => {
    console.log(`mutations external change ${change}`); // eslint-disable-line no-console
  },
  [FLIGHT_CONTINGENCY_INFORMATION_SET_CHANGE]: (state, change) => {
    change.ts = moment();
    state.contingencyInformationChanges.unshift(change);
    state.contingencyInformationChanges = state.contingencyInformationChanges.slice(0, 10);
  },
  [FLIGHT_CONTINGENCY_INFORMATION_CRUD_SET_INIT]: (state) => {
    state.crudOperationsDto = new CrudOperationsDTO();
  },
};

export default {
  state: storeState,
  getters: storeGetters,
  actions: storeActions,
  mutations: storeMutations,
};
