<template>
  <div>
    <v-autocomplete
          v-model="values"
          :items="items"
          multiple
          @change="change"
          deletable-chips
          chips
          hide-details
        >
        <template
        slot="item"
        slot-scope="{ item }"
        >
        <v-list-tile-content>
          <v-list-tile-title>{{ item }}</v-list-tile-title>
        </v-list-tile-content>
      </template>
      </v-autocomplete>
  </div>
</template>

<script>
import { FLIGHT_TOWING_ADHOC_TASK_FIND_FLIGHTS } from '../../../../store/modules/towingAdhocTask/flightTowingAdhocTask';
import StoreSubscribable from '../../../mixins/StoreSubscribable.vue';
import { FlightTypes } from '../model/FlightTypes';

export default {
  mixins: [StoreSubscribable],
  data() {
    return {
      items: [FlightTypes.INBOUND, FlightTypes.OUTBOUND, FlightTypes.AIRCRAFT_GROUND],
    };
  },
  computed: {
    filter() {
      return this.$store.state.flightTowingAdhocTask.filter;
    },
  },
  methods: {
    change(values) {
      this.filter.flightState.inbound = values.includes(FlightTypes.INBOUND);
      this.filter.flightState.outbound = values.includes(FlightTypes.OUTBOUND);
      this.filter.showAircraftGround = values.includes(FlightTypes.AIRCRAFT_GROUND);

      this.$store.dispatch(FLIGHT_TOWING_ADHOC_TASK_FIND_FLIGHTS);
    },
  },
  mounted() {
  },
};
</script>
