/* eslint no-param-reassign: "off" */

import moment from 'moment';
import { HTTP } from '../../../http';
import { REQUEST_ERROR, REQUEST_START, REQUEST_SUCCESS } from '../../store';
import TicketingFilter from '../../../components/ticketing/model/ticketingFilter';
import FlightTicketingInformation from '../../../components/ticketing/model/flightTicketingInformation';


// action
export const AIRPORT_INCOME_TICKETING_REPORT_GRID_FIT = 'AIRPORT_INCOME_TICKETING_REPORT_GRID_FIT';
export const AIRPORT_INCOME_TICKETING_REPORT_GRID_REFRESH_CELLS = 'AIRPORT_INCOME_TICKETING_REPORT_GRID_REFRESH_CELLS';

export const AIRPORT_INCOME_TICKETING_REPORT_FIND = 'AIRPORT_INCOME_TICKETING_REPORT_FIND';
export const AIRPORT_INCOME_TICKETING_REPORT_FIT_COLUMNS_TO_VIEW = 'AIRPORT_INCOME_TICKETING_REPORT_FIT_COLUMNS_TO_VIEW';
export const AIRPORT_INCOME_TICKETING_REPORT_FIT_COLUMNS_TO_BEST = 'AIRPORT_INCOME_TICKETING_REPORT_FIT_COLUMNS_TO_BEST';

export const AIRPORT_INCOME_TICKETING_REPORT_RECALCULATE_FILTER = 'AIRPORT_INCOME_TICKETING_REPORT_RECALCULATE_FILTER';
export const AIRPORT_INCOME_TICKETING_REPORT_RECALCULATE_TENANT_FILTER = 'AIRPORT_INCOME_TICKETING_REPORT_RECALCULATE_TENANT_FILTER';

// mutations
export const AIRPORT_INCOME_TICKETING_REPORT_FIND_SUCCESS = 'AIRPORT_INCOME_TICKETING_REPORT_FIND_SUCCESS';
export const AIRPORT_INCOME_TICKETING_REPORT_CHANGE_GRID_API = 'AIRPORT_INCOME_TICKETING_REPORT_CHANGE_GRID_API';
export const AIRPORT_INCOME_TICKETING_REPORT_FILTER_SET = 'AIRPORT_INCOME_TICKETING_REPORT_FILTER_SET';
export const AIRPORT_INCOME_TICKETING_REPORT_FILTER_SET_UNIT_TIME = 'AIRPORT_INCOME_TICKETING_REPORT_FILTER_SET_UNIT_TIME';
export const AIRPORT_INCOME_TICKETING_REPORT_FILTER_SET_TENANT = 'AIRPORT_INCOME_TICKETING_REPORT_FILTER_SET_TENANT';
export const AIRPORT_INCOME_TICKETING_REPORT_UPDATE_FIT_COLUMNS_ON_RESIZE = 'AIRPORT_INCOME_TICKETING_REPORT_UPDATE_FIT_COLUMNS_ON_RESIZE';
export const AIRPORT_INCOME_TICKETING_REPORT_FILTER_RESET = 'AIRPORT_INCOME_TICKETING_REPORT_FILTER_RESET';
export const AIRPORT_INCOME_TICKETING_REPORT_FILTER_RESET_TIMEZONE = 'AIRPORT_INCOME_TICKETING_REPORT_FILTER_RESET_TIMEZONE';


// initial state
const storeState = {
  gridApi: null,
  filter: TicketingFilter.createEmptyFilter,
  tenantAirlinesTicketing: [],
  fitColumnsOnResize: true,
};

// getters
const storeGetters = {
  // eslint-disable-next-line
  getSortedTenantAirlinesTicketing: state => state.tenantAirlinesTicketing.sort((a, b) => (a.airlineCode > b.airlineCode ? 1 : (b.airlineCode > a.airlineCode ? -1 : 0))),
};

// actions
const storeActions = {
  [AIRPORT_INCOME_TICKETING_REPORT_FIND]: ({ commit, state }) => {
    commit(REQUEST_START);
    return HTTP.post('ticketing/reports/airport-income/find', state.filter)
      .then((resp) => {
        commit(REQUEST_SUCCESS);
        commit(AIRPORT_INCOME_TICKETING_REPORT_FIND_SUCCESS, resp.data);
      })
      .catch((err) => {
        commit(AIRPORT_INCOME_TICKETING_REPORT_FIND_SUCCESS, []);
        commit(REQUEST_ERROR, err);
      });
  },
  [AIRPORT_INCOME_TICKETING_REPORT_CHANGE_GRID_API]: (state, gridApi) => {
    state.gridApi = gridApi.api;
  },
  [AIRPORT_INCOME_TICKETING_REPORT_GRID_FIT]: ({ state }) => {
    if (state.gridApi) {
      state.gridApi.sizeColumnsToFit();
    }
  },
  [AIRPORT_INCOME_TICKETING_REPORT_GRID_REFRESH_CELLS]: ({ state }) => {
    if (state.gridApi) {
      state.gridApi.refreshCells({ force: true });
    }
  },
  [AIRPORT_INCOME_TICKETING_REPORT_FIT_COLUMNS_TO_VIEW]: ({ state }) => {
    if (state.gridApi) {
      state.gridApi.sizeColumnsToFit();
    }
  },
  [AIRPORT_INCOME_TICKETING_REPORT_FIT_COLUMNS_TO_BEST]: ({ state }) => {
    if (state.gridApi && state.gridApi.columnController && state.gridApi.columnController.columnApi) {
      state.gridApi.columnController.columnApi.autoSizeAllColumns();
    }
  },
  [AIRPORT_INCOME_TICKETING_REPORT_RECALCULATE_FILTER]: ({ commit, state }) => {
    if (state.filter.dateFrom === null && state.filter.dateTo === null) {
      commit(AIRPORT_INCOME_TICKETING_REPORT_FILTER_RESET);
    } else {
      commit(AIRPORT_INCOME_TICKETING_REPORT_FILTER_RESET_TIMEZONE, state.filter.unitOfTime);
    }
  },
  [AIRPORT_INCOME_TICKETING_REPORT_RECALCULATE_TENANT_FILTER]: ({ commit, state }, tenant) => {
    if (state.filter.tenants === null) {
      commit(AIRPORT_INCOME_TICKETING_REPORT_FILTER_SET_TENANT, tenant);
    }
  },
};

// mutations
const storeMutations = {
  [AIRPORT_INCOME_TICKETING_REPORT_CHANGE_GRID_API]: (state, gridApi) => {
    state.gridApi = gridApi.api;
  },
  [AIRPORT_INCOME_TICKETING_REPORT_FIND_SUCCESS]: (state, flightsTicketing) => {
    state.tenantAirlinesTicketing = FlightTicketingInformation.createFlightTicketingInformationFromData(flightsTicketing);
  },
  [AIRPORT_INCOME_TICKETING_REPORT_FILTER_SET]: (state, filter) => {
    if (filter) {
      state.filter = filter;
    } else {
      state.filter = TicketingFilter.createEmptyFilter;
    }
  },
  [AIRPORT_INCOME_TICKETING_REPORT_FILTER_SET_UNIT_TIME]: (state, unitOfTime) => {
    state.filter.unitOfTime = unitOfTime;
    if (unitOfTime) {
      state.filter.dateFrom = moment().startOf(unitOfTime);
      state.filter.dateTo = moment().endOf(unitOfTime);
    }
  },
  [AIRPORT_INCOME_TICKETING_REPORT_FILTER_SET_TENANT]: (state, tenant) => {
    state.filter.tenants = tenant ? [tenant] : null;
  },
  [AIRPORT_INCOME_TICKETING_REPORT_UPDATE_FIT_COLUMNS_ON_RESIZE]: (state, fitColumnsOnResize) => {
    state.fitColumnsOnResize = fitColumnsOnResize;
  },
  [AIRPORT_INCOME_TICKETING_REPORT_FILTER_RESET]: (state) => {
    state.filter.dateFrom = moment().startOf('day');
    state.filter.dateTo = moment().endOf('day');
  },
  [AIRPORT_INCOME_TICKETING_REPORT_FILTER_RESET_TIMEZONE]: (state, unitOfTime) => {
    state.filter.unitOfTime = unitOfTime != null ? unitOfTime : 'day';
    state.filter.dateFrom = moment().startOf(state.filter.unitOfTime)
      .year(state.filter.dateFrom.year())
      .month(state.filter.dateFrom.month())
      .date(state.filter.dateFrom.date());
    state.filter.dateTo = moment().endOf(state.filter.unitOfTime)
      .year(state.filter.dateTo.year())
      .month(state.filter.dateTo.month())
      .date(state.filter.dateTo.date());
  },
};

export default {
  state: storeState,
  getters: storeGetters,
  actions: storeActions,
  mutations: storeMutations,
};
