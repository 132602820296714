<template>
  <div>
    <v-autocomplete
      placeholder="..."
      :value="value"
      :label="label"
      :items="responsibleTypes"
      :filter="customFilter"
      :clearable="true"
      item-text="value"
      item-value="value"
      :rules="rules"
      :disabled="disabled"
      @input="$emit('input', $event)"
      :ref="refComp"
    >
      <template
        slot="item"
        slot-scope="{ item, tile }"
      >
        <v-list-tile-content>
          <v-list-tile-title v-text="item.value"></v-list-tile-title>
        </v-list-tile-content>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>

export default {
  props: {
    value: { required: true },
    label: { type: String },
    charLenght: { type: Number },
    rules: { type: Array },
    disabled: { type: Boolean },
    refComp: {
      type: String,
      required: false,
      default: 'refComp',
    },
  },
  computed: {
    responsibleTypes() {
      return this.$store.getters.getResponsibleType;
    },
  },
  methods: {
    customFilter(item, queryText) {
      const textOne = item.value ? item.value.toLowerCase() : '';
      const searchText = queryText.toLowerCase();

      return textOne.indexOf(searchText) > -1;
    },
  },
};
</script>
<style scoped>
  div {
    max-width: none !important;
  }
</style>
