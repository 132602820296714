<template>
  <div>
    <scheduling-edit-modal v-if="dialog" :dialog="dialog" :edited-scheduling-flight="editedSchedulingFlight"
      @close="close" />
    <scheduling-long-term-modal v-if="dialogLongTerm" :dialog="dialogLongTerm" @close="close" />
    <scheduling-delete-modal v-if="dialogDelete" :dialog="dialogDelete" :items="selectedItemsToDelete"
      @delete-reject="deleteReject" @delete-confirm="deleteConfirm" />
    <scheduling-link-modal v-if="dialogLink" :dialog="dialogLink" :items="selectedItemsToLink" @link-reject="linkReject"
      @link-confirm="linkConfirm" />
    <scheduling-unlink-modal v-if="dialogUnlink" :dialog="dialogUnlink" :items="selectedItemsToUnlink"
      @unlink-reject="unlinkReject" @unlink-confirm="unlinkConfirm" />
    <scheduling-link-direct-modal v-if="dialogLinkDirect" :dialog="dialogLinkDirect" :items="selectedItemsToLinkDirect"
      @link-reject="linkDirectReject" @link-confirm="linkConfirm" />
    <scheduling-import-modal v-if="dialogImport" :dialog="dialogImport" @import-reject="importReject"
      @import-confirm="importConfirm" />
    <scheduling-cancel-flights-modal v-if="dialogCancelFlights" :dialog="dialogCancelFlights"
      :items="selectedItemsToCancel" @cancel-reject="cancelFlightsReject" @cancel-confirm="cancelFlightsConfirm" />

    <scheduling-list-filter @changeShowFilter="changeShowFilter" />

    <scheduling-list-table :items="items" :tableHeight="tableHeight" @delete-item="deleteItem" @edit-item="editItem" />
    <scheduling-list-options @clickFlight="newFlight" @clickLongTermFlight="newLongTerm" @clickLinkFlights="linkFlights"
      @clickUnlinkFlights="unlinkFlights" @clickDeleteFlights="deleteItems" @clickCancelFlights="cancelFlights"
      @clickImport="importFlights" />
    <pagination-bar class="pagination-bar" :paginationProperties="paginationProperties"
      :mutationNameToSetAgGridGridApi="mutationNameToSetAgGridGridApi" @setNewPageSize="setNewPageSize"
      @setNewCurrentPage="setNewCurrentPage" />
  </div>
</template>
<script>

import {
  SCHED_FIND_FLIGHTS,
  SCHED_FLIGHTS_DELETE,
  SCHED_LINK_FLIGHTS,
  SCHED_UNLINK_FLIGHTS,
  SCHED_CANCEL_FLIGHTS,
  SCHED_UPDATE_FILTER_PAGINATION_PAGE_SIZE,
  SCHED_UPDATE_FILTER_PAGINATION_CURRENT_PAGE,
  SCHED_UPDATE_FILTER_PAGINATION_SET_FIRST_PAGE,
  SCHED_CHANGE_GRID_API,
} from '../store/modules/scheduling';

import SchedulingDeleteModal from '../components/scheduling/delete/SchedulingDeleteModal.vue';
import SchedulingCancelFlightsModal from '../components/scheduling/cancel/SchedulingCancelFlightsModal.vue';
import SchedulingEditModal from '../components/scheduling/edit/SchedulingEditModal.vue';
import SchedulingListOptions from '../components/scheduling/list/SchedulingListOptions.vue';
import SchedulingListTable from '../components/scheduling/list/SchedulingListTable.vue';
import SchedulingLinkModal from '../components/scheduling/link/SchedulingLinkModal.vue';
import SchedulingUnlinkModal from '../components/scheduling/link/SchedulingUnlinkModal.vue';
import SchedulingLinkDirectModal from '../components/scheduling/link/SchedulingLinkDirectModal.vue';
import SchedulingListFilter from '../components/scheduling/list/SchedulingListFilter.vue';
import SchedulingLongTermModal from '../components/scheduling/edit/SchedulingLongTermModal.vue';
import SchedulingImportModal from '../components/scheduling/import/SchedulingImportModal.vue';
import ConnectedFlights from '../components/shared/model/connectedFlights';
import FlightComposition from '../components/shared/model/flightComposition';
import FlightCommonInformation from '../components/scheduling/model/flightCommonInformation';
import PaginationBar from '../components/ui/pagination/PaginationBar.vue';

export default {
  components: {
    SchedulingListFilter,
    SchedulingLongTermModal,
    SchedulingLinkModal,
    SchedulingUnlinkModal,
    SchedulingLinkDirectModal,
    SchedulingListTable,
    SchedulingListOptions,
    SchedulingDeleteModal,
    SchedulingCancelFlightsModal,
    SchedulingEditModal,
    SchedulingImportModal,
    PaginationBar,
  },
  data() {
    return {
      dialog: false,
      dialogDelete: false,
      dialogCancelFlights: false,
      dialogLink: false,
      dialogLinkDirect: false,
      dialogUnlink: false,
      dialogLongTerm: false,
      dialogImport: false,
      selectedItemsToDelete: [],
      selectedItemsToLink: [],
      selectedItemsToLinkDirect: [],
      selectedItemsToUnlink: [],
      selectedItemsToCancel: [],
      menu2: false,
      editedSchedulingFlight: ConnectedFlights.createEmtpyConnectedFlights(),
      filterDisplayed: true,
      mutationNameToSetAgGridGridApi: SCHED_CHANGE_GRID_API,
    };
  },
  computed: {
    items() {
      // eslint-disable-next-line
      return this.$store.state.scheduling.flights;
    },
    paginationProperties() {
      // eslint-disable-next-line
      return this.$store.getters.schedulingPaginationResponseProperties;
    },
    selectedRowsItems() {
      return this.$store.state.scheduling.selectedFlights;
    },
    tableHeight() {
      const paginationBarHeightInPx = 60;
      const filterBarHeight = (this.filterDisplayed) ? 250 : 180;
      const schedulingTableHeight = filterBarHeight + paginationBarHeightInPx;
      return `calc(100vh - ${schedulingTableHeight}px)`;
    },
  },
  methods: {
    newFlight() {
      this.editedSchedulingFlight = ConnectedFlights.createEmtpyConnectedFlights();
      this.dialog = true;
    },
    newLongTerm() {
      this.dialogLongTerm = true;
    },
    deleteItem(item) {
      this.selectedItemsToDelete = [];
      this.selectedItemsToDelete.push(item);
      this.dialogDelete = true;
    },
    deleteItems() {
      this.selectedItemsToDelete = [];
      this.selectedItemsToDelete = this.selectedRowsItems;
      this.dialogDelete = true;
    },
    deleteConfirm(ids) {
      this.$store.dispatch(SCHED_FLIGHTS_DELETE, { ids })
        .finally(() => {
          this.selectedItemsToDelete = [];
          this.dialogDelete = false;
        });
    },
    deleteReject() {
      this.selectedItemsToDelete = [];
      this.dialogDelete = false;
    },
    editItem(item) {
      this.editedSchedulingFlight = item.clone();
      if (this.editedSchedulingFlight.inbound == null) {
        this.editedSchedulingFlight.inbound = new FlightComposition(FlightCommonInformation.createEmptyInboundFlight);
      }
      if (this.editedSchedulingFlight.outbound == null) {
        this.editedSchedulingFlight.outbound = new FlightComposition(FlightCommonInformation.createEmptyOutBoundFlight);
      }
      this.dialog = true;
    },
    close(result) {
      this.dialog = false;
      this.dialogLongTerm = false;
      if (result && result.doFind) {
        this.$store.dispatch(SCHED_UPDATE_FILTER_PAGINATION_SET_FIRST_PAGE);
        this.$store.dispatch(SCHED_FIND_FLIGHTS);
      }
    },
    linkFlights() {
      if (this.selectedRowsItems.length === 2) {
        this.selectedItemsToLinkDirect = [];
        this.selectedItemsToLinkDirect = this.selectedRowsItems;
        this.dialogLinkDirect = true;
      } else {
        this.selectedItemsToLink = [];
        this.selectedItemsToLink = this.selectedRowsItems;
        this.dialogLink = true;
      }
    },
    linkReject() {
      this.selectedItemsToLink = [];
      this.dialogLink = false;
    },
    linkDirectReject() {
      this.selectedItemsToLinkDirect = [];
      this.dialogLinkDirect = false;
    },
    linkConfirm(linkedFlights) {
      this.dialogLink = false;
      this.dialogLinkDirect = false;
      this.selectedItemsToLink = [];
      this.selectedItemsToLinkDirect = [];
      this.$store.dispatch(SCHED_LINK_FLIGHTS, linkedFlights);
    },
    unlinkFlights() {
      this.selectedItemsToUnlink = [];
      this.selectedItemsToUnlink = this.selectedRowsItems;
      this.dialogUnlink = true;
    },
    unlinkConfirm(flights) {
      this.dialogUnlink = false;
      this.selectedItemsToUnlink = [];
      this.$store.dispatch(SCHED_UNLINK_FLIGHTS, flights);
    },
    unlinkReject() {
      this.selectedItemsToUnlink = [];
      this.dialogUnlink = false;
    },
    importFlights() {
      this.dialogImport = true;
    },
    importReject() {
      this.dialogImport = false;
    },
    importConfirm() {
      this.$store.dispatch(SCHED_UPDATE_FILTER_PAGINATION_SET_FIRST_PAGE);
      this.$store.dispatch(SCHED_FIND_FLIGHTS);
      this.dialogImport = false;
    },
    changeShowFilter(dispayled) {
      this.filterDisplayed = dispayled;
    },
    cancelFlights() {
      this.selectedItemsToCancel = [];
      this.selectedItemsToCancel = this.selectedRowsItems;
      this.dialogCancelFlights = true;
    },
    cancelFlightsReject() {
      this.dialogCancelFlights = false;
    },
    cancelFlightsConfirm(flightsCancellation) {
      this.selectedItemsToCancel = [];
      this.$store.dispatch(SCHED_CANCEL_FLIGHTS, flightsCancellation);
      this.dialogCancelFlights = false;
    },
    setNewPageSize(newPageSize) {
      this.$store.dispatch(SCHED_UPDATE_FILTER_PAGINATION_PAGE_SIZE, Number(newPageSize));
    },
    setNewCurrentPage(newCurrentPage) {
      this.$store.dispatch(SCHED_UPDATE_FILTER_PAGINATION_CURRENT_PAGE, Number(newCurrentPage));
    },
  },
};
</script>
<style>
.pagination-bar {
  position: absolute;
  bottom: 0;
}
</style>
