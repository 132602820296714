<template>
  <div v-show="value !== null" :class="'d-flex align-center' + (!editable ? ' type-read-only' : '')">
    <template v-if="typeValue === TYPE.TEXT">
      <v-textarea :name="name" :id="name" hide-details class="pb-2" :rows="rows" :value="value" :readonly="!editable"
        :disabled="!editable" @blur="emitValue($event.target.value)">
      </v-textarea>
    </template>
    <template v-else-if="typeValue === TYPE.TIME">
      <mobile-time-input :name="name" :value="timeValue" :editable="editable" @input="emitValue($event)"
        :clearable="clearable"></mobile-time-input>
    </template>
    <template v-else-if="typeValue === TYPE.DATETIME">
      <mobile-date-time-input :name="name" :value="dateTimeValue" :editable="editable" :clearable="clearable"
        @input="emitValue($event)"></mobile-date-time-input>
    </template>
    <template v-else-if="typeValue === TYPE.DATE">
      <mobile-date-input :name="name" :value="dateValue" :editable="editable" :clearable="clearable"
        @input="emitValue($event)"></mobile-date-input>
    </template>
    <template v-else-if="typeValue === TYPE.NUMBER">
      <v-icon v-if="editable && incrementButtons" color="secondary" @click="increment" class="p-0">mdi-plus</v-icon>
      <v-text-field :name="name" :id="name" type="number" hide-spin-buttons :value="numberValue" :readonly="!editable"
        :disabled="!editable" hide-details class="pa-0 operator-button"
        @blur="emitValue($event.target.value)"></v-text-field>
      <span v-if="append" class="pl-1 append">{{ append }}</span>
      <v-icon v-if="editable && incrementButtons" color="primary" @click="decrement" class="p-0">mdi-minus</v-icon>
    </template>
    <template v-else-if="typeValue === TYPE.BOOLEAN">
      <v-switch :name="name" :id="name" v-if="editable" v-model="booleanValue" :readonly="!editable"
        @change="emitValue($event)"></v-switch>
      <v-icon v-if="!editable && booleanValue" color="green" large class="pr-3">mdi-check-circle</v-icon>
      <v-icon v-if="!editable && !booleanValue" color="red" large class="pr-3">mdi-close-circle</v-icon>
    </template>
    <template v-else-if="typeValue === TYPE.OPTIONS">
      <mobile-select-input :name="name" :options="options" :optionValue="optionValue" :editable="editable"
        :clearable="clearable" @input="emitValue($event)"></mobile-select-input>
    </template>
  </div>
</template>

<script>
import MobileTimeInput from './MobileTimeInput.vue';
import MobileDateInput from './MobileDateInput.vue';
import MobileDateTimeInput from './MobileDateTimeInput.vue';
import MobileSelectInput from './MobileSelectInput.vue';
import { TIME_TIMEZONE_CHANGED } from '../../../store/modules/time';
import StoreSubscribable from '../../mixins/StoreSubscribable.vue';
import MobileFlightUtil from '../model/mobileFlightUtil';
import { MilestoneTypes as TYPE } from '../../shared/model/milestoneTypes';

export default {
  name: 'mobile-input-type',
  components: {
    MobileTimeInput,
    MobileDateInput,
    MobileDateTimeInput,
    MobileSelectInput,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    value: {
      required: true,
    },
    typeValue: {
      type: String,
      required: true,
      validator: value => Object.values(TYPE).includes(value),
    },
    options: {
      type: Array,
      required: false,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    rows: {
      type: Number,
      default: 3,
    },
    append: {
      type: String,
      default: '',
    },
    incrementButtons: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
  },
  mixins: [StoreSubscribable],
  data() {
    return {
      oldValue: null,
      timeValue: null,
      dateValue: null,
      dateTimeValue: null,
      optionValue: null,
      numberValue: null,
      booleanValue: false,
      modal2: false,
      localValue: null, // Propiedad de datos local
      TYPE,
    };
  },
  computed: {
    localTimezone() {
      return this.$store.state.time.timezone;
    },
  },
  watch: {
    value(newValue) {
      if (this.typeValue === TYPE.TIME || this.typeValue === TYPE.DATETIME || this.typeValue === TYPE.DATE) {
        if (newValue && this.$moment(newValue, 'HH:mm:ss', true).tz(this.localTimezone).isValid()) {
          this.localValue = this.$moment(newValue, 'HH:mm:ss').tz(this.localTimezone);
        } else {
          this.localValue = newValue ? this.$moment(newValue).tz(this.localTimezone) : null;
        }
      } else {
        this.localValue = newValue;
      }
      this.setValue(newValue);
    },
  },
  methods: {
    increment() {
      this.emitValue(this.numberValue ? this.numberValue + 1 : 1);
    },
    decrement() {
      this.emitValue(this.numberValue > 0 ? this.numberValue - 1 : 0);
    },
    setValue(newValue) {
      const value = this.formatValue(newValue);
      this.timeValue = value;
      this.dateTimeValue = value;
      this.dateValue = value;
      this.booleanValue = value;
      this.numberValue = value;
      this.optionValue = value;
      this.localValue = newValue;
    },
    formatValue(newValue) {
      if (newValue === null || newValue === undefined) {
        return null;
      }
      switch (this.typeValue) {
        case TYPE.TIME:
          return MobileFlightUtil.setTimeToDisplay(newValue, this.localTimezone);
        case TYPE.DATETIME:
          return this.$moment(newValue).tz(this.localTimezone).format('YYYY-MM-DD HH:mm');
        case TYPE.DATE:
          return this.$moment(newValue).tz(this.localTimezone).format('YYYY-MM-DD');
        case TYPE.NUMBER:
          return MobileFlightUtil.parseFloatNullable(newValue);
        case TYPE.OPTIONS:
          return newValue;
        case TYPE.BOOLEAN:
          return newValue === 'true' || newValue === true;
        default:
          return newValue;
      }
    },
    emitValue(newValue) {
      if (this.formatValue(this.oldValue) === this.formatValue(newValue)) {
        return;
      }
      if (this.editable) {
        const value = this.formatValue(newValue);
        this.localValue = value;
        // if timezone is not UTC, convert to UTC
        if (this.typeValue === 'TIME' && this.timezone !== 'UTC') {
          this.localValue = MobileFlightUtil.setTimeToBackend(value, this.localTimezone);
        } else if (this.typeValue === 'DATETIME' && this.timezone !== 'UTC') {
          this.localValue = MobileFlightUtil.setDateTimeToBackend(value, this.localTimezone);
        }
        this.oldValue = value;
        this.$emit('input', value);
      }
    },
  },
  created() {
    this.localValue = this.value;
    this.oldValue = this.formatValue(this.localValue);
    this.setValue(this.localValue);
    if (this.typeValue === TYPE.TIME || this.typeValue === TYPE.DATETIME || this.typeValue === TYPE.DATE) {
      this.subscribeMutation(TIME_TIMEZONE_CHANGED, () => {
        const newValue = this.formatValue(this.localValue);
        this.timeValue = newValue;
        this.dateTimeValue = newValue;
        this.dateValue = newValue;
      });
    }
  },
};
</script>
<style scope>
.operator-button input[type="number"] {
  -moz-appearance: textfield;
  appearance: none;
}

.operator-button input::-webkit-outer-spin-button,
.operator-button input::-webkit-inner-spin-button {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}
</style>
