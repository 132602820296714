/* eslint no-param-reassign: "off" */
import moment from 'moment';
import { HTTP } from '../../http';
import { REQUEST_ERROR, REQUEST_START, REQUEST_SUCCESS } from '../store';
import { createFlightEventFromData } from '../../components/shared/model/flightEvent';
import passageInboundFilter from '../../components/passage/model/passageInboundFilter';
import { NOTIFICATION_SHOW } from './notifications';
import ConnectedFlightCompositionFactory from '../../components/shared/model/connectedFlightsFactory';
import i18n from '../../plugins/i18n';
import { ACTION_SET_NO_FLIGHTS_TO_SHOW_MESSAGE } from './noFlightsToShowMessages';


// action
export const PASSAGE_INBOUND_SET_GRID_API = 'PASSAGE_INBOUND_SET_GRID_API';
export const PASSAGE_INBOUND_FIND_FLIGHTS = 'PASSAGE_INBOUND_FIND_FLIGHTS';
export const PASSAGE_INBOUND_FLIGHTS_UPDATE = 'PASSAGE_INBOUND_FLIGHTS_UPDATE';
export const PASSAGE_INBOUND_FLIGHTS_WRITE_CHANGES = 'PASSAGE_INBOUND_FLIGHTS_WRITE_CHANGES';
export const PASSAGE_INBOUND_DO_EXTERNAL_CHANGE = 'PASSAGE_INBOUND_DO_EXTERNAL_CHANGE';
export const PASSAGE_INBOUND_ARCHIVE = 'PASSAGE_INBOUND_ARCHIVE';
export const PASSAGE_INBOUND_UNARCHIVE = 'PASSAGE_INBOUND_UNARCHIVE';
export const PASSAGE_INBOUND_FIND_FLIGHTS_SUCCESS = 'PASSAGE_INBOUND_FIND_FLIGHTS_SUCCESS';
export const PASSAGE_INBOUND_CHANGE_FLIGHT_SELECTION = 'PASSAGE_INBOUND_CHANGE_FLIGHT_SELECTION';
export const PASSAGE_INBOUND_ON_EXTERNAL_CHANGE = 'PASSAGE_INBOUND_ON_EXTERNAL_CHANGE';
export const PASSAGE_INBOUND_SET_CHANGE = 'PASSAGE_INBOUND_SET_CHANGE';
export const PASSAGE_INBOUND_FILTER_RESET = 'PASSAGE_INBOUND_FILTER_RESET';
export const PASSAGE_INBOUND_FILTER_RESET_TIMEZONE = 'PASSAGE_INBOUND_FILTER_RESET_TIMEZONE';
export const PASSAGE_INBOUND_FILTER_INCREASE_DAY = 'PASSAGE_INBOUND_FILTER_INCREASE_DAY';
export const PASSAGE_INBOUND_FILTER_DECREASE_DAY = 'PASSAGE_INBOUND_FILTER_DECREASE_DAY';
export const PASSAGE_INBOUND_FILTER_SET_DAY = 'PASSAGE_INBOUND_FILTER_SET_DAY';

export const FIT_COLUMNS_TO_VIEW = 'FIT_COLUMNS_TO_VIEW';
export const FIT_COLUMNS_TO_BEST = 'FIT_COLUMNS_TO_BEST';

export const UPDATE_SHOW_OLD_VALUES = 'UPDATE_SHOW_OLD_VALUES ';
export const UPDATE_FLASH_ON_CHANGES = 'UPDATE_FLASH_ON_CHANGES ';

export const UPDATE_FIT_COLUMNS_ON_RESIZE = 'UPDATE_FIT_COLUMNS_ON_RESIZE ';

// initial state
const storeState = {
  gridApi: null,
  filter: passageInboundFilter.createEmptyFilter,
  connectedFlightsPassage: [],
  selectedFlights: [],
  flightsChanges: [],
  showOldValues: true,
  flashOnChanges: false,
  fitColumnsOnResize: true,
};

// getters
const storeGetters = {
  hasSelectedPassageInboundFlights: state => state.selectedFlights.length > 0,
  isSelectedPassageInboundFlights: state => id => !!state.selectedFlights.find(s => s.getId() === id),
};

// actions
const storeActions = {
  [PASSAGE_INBOUND_FIND_FLIGHTS]: ({ dispatch, commit, state }) => {
    commit(REQUEST_START);
    commit(PASSAGE_INBOUND_CHANGE_FLIGHT_SELECTION, []);
    return HTTP.post('passage/flights/inbound/find', state.filter)
      .then((resp) => {
        commit(REQUEST_SUCCESS);
        commit(
          PASSAGE_INBOUND_FIND_FLIGHTS_SUCCESS,
          ConnectedFlightCompositionFactory.createConnectedFlightsFromData(resp.data),
        );
        commit(PASSAGE_INBOUND_CHANGE_FLIGHT_SELECTION, []);
        dispatch(ACTION_SET_NO_FLIGHTS_TO_SHOW_MESSAGE, resp.status);
      })
      .catch((err) => {
        dispatch(ACTION_SET_NO_FLIGHTS_TO_SHOW_MESSAGE, err.response.status);
        commit(PASSAGE_INBOUND_FIND_FLIGHTS_SUCCESS, []);
        commit(REQUEST_ERROR, err);
      });
  },
  [PASSAGE_INBOUND_FLIGHTS_UPDATE]: (
    { commit },
    { connectedFlightsList, field },
  ) => new Promise((resolve, reject) => {
    commit(REQUEST_START);

    HTTP.put('passage/flights/inbound', connectedFlightsList)
      .then((response) => {
        commit(REQUEST_SUCCESS);
        commit(NOTIFICATION_SHOW, {
          message: field
            ? `${i18n.t('notifications.flightFieldUpdate')} ${field}`
            : i18n.t('notifications.flightUpdate'),
          color: 'success',
        });
        resolve(response);
      })
      .catch((err) => {
        commit(REQUEST_ERROR, err);
        reject(err);
      });
  }),
  [PASSAGE_INBOUND_FLIGHTS_WRITE_CHANGES]: (
    { commit },
    { flightChanges, field },
  ) => new Promise((resolve, reject) => {
    commit(REQUEST_START);

    HTTP.put('passage/flights/inbound/write/changes', flightChanges)
      .then((response) => {
        commit(REQUEST_SUCCESS);
        commit(NOTIFICATION_SHOW, {
          message: field
            ? `${i18n.t('notifications.flightFieldUpdate')} ${field}`
            : i18n.t('notifications.flightUpdate'),
          color: 'success',
        });
        resolve(response);
      })
      .catch((err) => {
        commit(REQUEST_ERROR, err);
        reject(err);
      });
  }),
  [PASSAGE_INBOUND_DO_EXTERNAL_CHANGE]: ({ commit }, change) => {
    commit(
      PASSAGE_INBOUND_ON_EXTERNAL_CHANGE,
      createFlightEventFromData(change),
    );
  },
  [PASSAGE_INBOUND_ARCHIVE]: ({ commit, dispatch, state }) => {
    commit(REQUEST_START);
    return HTTP.post('passage/flights/inbound/archive', state.selectedFlights)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.flightArchive'),
          color: 'success',
        });
        dispatch(PASSAGE_INBOUND_FIND_FLIGHTS);
      })
      .catch((err) => {
        commit(REQUEST_ERROR, err);
      });
  },
  [PASSAGE_INBOUND_UNARCHIVE]: ({ commit, dispatch, state }) => {
    commit(REQUEST_START);
    return HTTP.post('passage/flights/inbound/unarchive', state.selectedFlights)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.flightUnarchive'),
          color: 'success',
        });
        dispatch(PASSAGE_INBOUND_FIND_FLIGHTS);
      })
      .catch((err) => {
        commit(REQUEST_ERROR, err);
      });
  },
  [FIT_COLUMNS_TO_VIEW]: ({ state }) => {
    if (state.gridApi) {
      state.gridApi.sizeColumnsToFit();
    }
  },
  [FIT_COLUMNS_TO_BEST]: ({ state }) => {
    if (
      state.gridApi
      && state.gridApi.columnController
      && state.gridApi.columnController.columnApi
    ) {
      state.gridApi.columnController.columnApi.autoSizeAllColumns();
    }
  },
};

// mutations
const storeMutations = {
  [PASSAGE_INBOUND_SET_GRID_API]: (state, gridApi) => {
    state.gridApi = gridApi.api;
  },
  [PASSAGE_INBOUND_FIND_FLIGHTS_SUCCESS]: (state, connectedFlightsPassage) => {
    state.connectedFlightsPassage = connectedFlightsPassage;
  },
  [PASSAGE_INBOUND_CHANGE_FLIGHT_SELECTION]: (state, selection) => {
    state.selectedFlights = selection;
  },
  [PASSAGE_INBOUND_ON_EXTERNAL_CHANGE]: (state, change) => {
    console.log(`mutations external change ${change}`); // eslint-disable-line no-console
  },
  [PASSAGE_INBOUND_SET_CHANGE]: (state, change) => {
    change.ts = moment();
    if (change.diff.path.includes('inbound')) {
      change.flightNumber = change.connectedFlights.inbound.common.flightNo();
      change.bound = 'inbound';
    }
    state.flightsChanges.unshift(change);
    state.flightsChanges = state.flightsChanges.slice(0, 10);
  },
  [PASSAGE_INBOUND_FILTER_RESET]: (state) => {
    state.filter.dateFrom = moment().startOf('day');
    state.filter.dateTo = moment().endOf('day');

    state.filter.timezoneDate = moment.defaultZone.name;
  },
  [PASSAGE_INBOUND_FILTER_RESET_TIMEZONE]: (state) => {
    state.filter.dateFrom = moment()
      .startOf('day')
      .year(state.filter.dateFrom.year())
      .month(state.filter.dateFrom.month())
      .date(state.filter.dateFrom.date());
    state.filter.dateTo = moment()
      .endOf('day')
      .year(state.filter.dateTo.year())
      .month(state.filter.dateTo.month())
      .date(state.filter.dateTo.date());

    state.filter.timezoneDate = moment.defaultZone.name;
  },
  [PASSAGE_INBOUND_FILTER_INCREASE_DAY]: (state) => {
    state.filter.dateFrom = moment()
      .startOf('day')
      .year(state.filter.dateFrom.year())
      .month(state.filter.dateFrom.month())
      .date(state.filter.dateFrom.date())
      .add(1, 'days')
      .startOf('day');

    state.filter.dateTo = moment()
      .endOf('day')
      .year(state.filter.dateTo.year())
      .month(state.filter.dateTo.month())
      .date(state.filter.dateTo.date())
      .add(1, 'days');
  },
  [PASSAGE_INBOUND_FILTER_DECREASE_DAY]: (state) => {
    state.filter.dateFrom = moment()
      .startOf('day')
      .year(state.filter.dateFrom.year())
      .month(state.filter.dateFrom.month())
      .date(state.filter.dateFrom.date())
      .subtract(1, 'days');

    state.filter.dateTo = moment()
      .endOf('day')
      .year(state.filter.dateTo.year())
      .month(state.filter.dateTo.month())
      .date(state.filter.dateTo.date())
      .subtract(1, 'days')
      .endOf('day');
  },
  [PASSAGE_INBOUND_FILTER_SET_DAY]: (state, dateSelected) => {
    state.filter.dateFrom = moment(dateSelected).startOf('day');

    state.filter.dateTo = moment(dateSelected).endOf('day');
  },
  [UPDATE_SHOW_OLD_VALUES]: (state, showOldValues) => {
    state.showOldValues = showOldValues;
  },
  [UPDATE_FLASH_ON_CHANGES]: (state, flashOnChanges) => {
    state.flashOnChanges = flashOnChanges;
  },
  [UPDATE_FIT_COLUMNS_ON_RESIZE]: (state, fitColumnsOnResize) => {
    state.fitColumnsOnResize = fitColumnsOnResize;
  },
};

export default {
  state: storeState,
  getters: storeGetters,
  actions: storeActions,
  mutations: storeMutations,
};
