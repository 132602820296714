import RampServiceImportOptions from './rampServiceImportOptions';

export default class RampServiceImportParsedFile {
  constructor(items, allItems, minDate, maxDate, errors, rampServices, rampServicesNotFound, servicesWithoutData, itemsWithEmptyRequiredFields) {
    this.items = items;
    this.allItems = allItems;
    this.minDate = minDate;
    this.maxDate = maxDate;
    this.errors = errors;
    this.rampServices = rampServices;
    this.rampServicesNotFound = rampServicesNotFound;
    this.servicesWithoutData = servicesWithoutData;
    this.itemsWithEmptyRequiredFields = itemsWithEmptyRequiredFields;
  }

  static createRampServiceImportParsedFileFromData(data, importOptions) {
    if (!importOptions || !(importOptions instanceof RampServiceImportOptions)) {
      throw new Error('Parameter importOptions is not valid RampServiceImportOptions instance');
    }

    const items = [];
    const servicesWithoutData = [];
    const rampServicesNotFound = new Set();
    const itemsWithEmptyRequiredFields = [];

    for (let i = 0; i < data.items.length; i += 1) {
      const item = data.items[i];
      if (i <= importOptions.linesToPreview) {
        items.push(item);
      }

      if (item.serviceIdentifier && !item.units && !item.requestTs && !item.requestedTs && !item.requestedEndTs && !item.startTs && !item.finishTs) {
        servicesWithoutData.push(item);
      }

      if (!data.rampServices || data.rampServices.length === 0 || (data.rampServices && data.rampServices.length > 0 && data.rampServices.filter(rampService => rampService.serviceIdentifier === item.serviceIdentifier).length === 0)) {
        rampServicesNotFound.add(item.serviceIdentifier);
      }

      if (!item.serviceIdentifier || !item.flightDate || !item.airlineCode) {
        itemsWithEmptyRequiredFields.push(item);
      } else if (!item.flightTripNumber && !item.bound && !item.aircraftRegisterNumber) {
        itemsWithEmptyRequiredFields.push(item);
      } else if (!item.aircraftRegisterNumber && (!item.flightTripNumber || !item.bound)) {
        itemsWithEmptyRequiredFields.push(item);
      }
    }
    return new RampServiceImportParsedFile(items, data.items, data.minDate, data.maxDate, data.errors, data.rampServices, [...rampServicesNotFound], servicesWithoutData, itemsWithEmptyRequiredFields);
  }

  areThereRampServices() {
    return !!this.rampServices && this.rampServices.length > 0;
  }

  areThereRampServicesThatNotFound() {
    return this.rampServicesNotFound && this.rampServicesNotFound.length > 0;
  }

  areThereErrors() {
    return this.errors && this.errors.length > 0;
  }
}
