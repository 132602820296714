<template>
  <v-container fluid>
    <v-layout row>
      <v-flex md6>
        <label>{{$t("scheduling.linkCriteriaSelection")}}</label>
        <v-checkbox
          :label="this.$i18n.t('scheduling.linkCriteriaAirline')"
          v-model="linkOptions.airlineCriteria"
        ></v-checkbox>
        <v-checkbox
          :label="this.$i18n.t('scheduling.linkCriteriaAirlineOp')"
          v-model="linkOptions.airlineOperationCriteria"
        ></v-checkbox>
        <v-checkbox
          :label="this.$i18n.t('scheduling.linkCriteriaAircraftReg')"
          v-model="linkOptions.aircraftCriteria"
          disabled
        ></v-checkbox>
        <v-checkbox
          :label="this.$i18n.t('scheduling.linkCriteriaAircraftType')"
          v-model="linkOptions.aircraftTypeCriteria"
          disabled
        ></v-checkbox>
      </v-flex>
      <v-flex md6>
        <label>{{$t("scheduling.linkGroundTimeSpecifications")}}</label>
        <v-text-field type="number" v-model="linkOptions.minGroundTime" :label="this.$i18n.t('scheduling.linkGroundMinTime')" min="0" :rules="minGroundTimeRules"></v-text-field>
        <v-text-field type="number" v-model="linkOptions.maxGroundTime" :label="this.$i18n.t('scheduling.linkGroundMaxTime')" min="0" :rules="maxGroundTimeRules"></v-text-field>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import SchedulingLinkOptions from '../model/schedulingLinkOptions';

export default {
  name: 'scheduling-link-step-options',
  props: {
    linkOptions: {
      type: SchedulingLinkOptions,
      required: true,
    },
  },
  data() {
    return {
      minGroundTimeRules: [
        v => !!v || 'Required',
        v => parseInt(v, 10) >= 0 || 'Min: 0',
      ],
      maxGroundTimeRules: [
        v => !!v || 'Required',
        v => parseInt(v, 10) >= 0 || 'Min: 0',
      ],
    };
  },
};
</script>
<style>
</style>
