import ConnectedFlights from '../../shared/model/connectedFlights';
import FlightCommonInformation from './flightCommonInformation';

// TODO refactor and unit test

export class SchedulingImportFlightResult {
  constructor(oldValue, newValue, validationStatus, importOperation, tag, fieldsBlocked) {
    this.oldValue = oldValue;
    this.newValue = newValue;
    this.validationStatus = validationStatus;
    this.importOperation = importOperation;
    this.tag = tag;
    this.fieldsBlocked = fieldsBlocked;
  }
}

export const SchedulingImportResultOperation = {
  INSERT: 'INSERT',
  DELETE: 'DELETE',
  MODIFY: 'MODIFY',
  EQUAL: 'EQUAL',
};

export const SchedulingImportResultValidationStatus = {
  OK: 'OK',
  AIRCRAFT_WITH_AIRCRAFT_TYPE_MISMATCH: 'AIRCRAFT_WITH_AIRCRAFT_TYPE_MISMATCH',
};


export class SchedulingImportResult {
  constructor(inbound, outbound, importOperation, sortingTime) {
    this.inbound = inbound;
    this.outbound = outbound;
    this.importOperation = importOperation;
    this.sortingTime = sortingTime;
  }

  static createSchedulingImportResultFromData(data) {
    let inbound = null;
    let outbound = null;
    if (data.inbound && (data.inbound.newValue || data.inbound.oldValue)) {
      inbound = new SchedulingImportFlightResult(
        data.inbound.oldValue ? FlightCommonInformation.createFlightCommonInformationFromData(data.inbound.oldValue) : null,
        data.inbound.newValue ? FlightCommonInformation.createFlightCommonInformationFromData(data.inbound.newValue) : null,
        data.inbound.schedulingImportFlightResultStatus,
        data.inbound.schedulingImportFlightResultOperation,
        data.inbound.tag,
        data.inbound.fieldsBlocked,
      );
    }

    if (data.outbound && (data.outbound.newValue || data.outbound.oldValue)) {
      outbound = new SchedulingImportFlightResult(
        data.outbound.oldValue ? FlightCommonInformation.createFlightCommonInformationFromData(data.outbound.oldValue) : null,
        data.outbound.newValue ? FlightCommonInformation.createFlightCommonInformationFromData(data.outbound.newValue) : null,
        data.outbound.schedulingImportFlightResultStatus,
        data.outbound.schedulingImportFlightResultOperation,
        data.outbound.tag,
        data.outbound.fieldsBlocked,
      );
    }

    return new SchedulingImportResult(inbound, outbound, data.schedulingImportResultOperation, data.sortingTime);
  }

  static createSchedulingFlightsWithImportStateFromData(data) {
    if (!data) {
      return [];
    }

    let listResult = [];
    data.forEach((d) => {
      const schedulingImportResult = SchedulingImportResult.createSchedulingImportResultFromData(d);
      const schedulingFlights = schedulingImportResult.getSchedulingFlights();
      listResult = listResult.concat(schedulingFlights);
    });
    return listResult;
  }

  getSchedulingFlights() {
    if (this.importOperation === SchedulingImportResultOperation.INSERT) {
      const schedulingFlight = new ConnectedFlights(this.inbound ? this.inbound.newValue : null, this.outbound ? this.outbound.newValue : null, null, this.sortingTime);
      schedulingFlight.importOperation = SchedulingImportResultOperation.INSERT;
      schedulingFlight.importStatusInbound = this.inbound ? this.inbound.importOperation : null;
      schedulingFlight.importStatusOutbound = this.outbound ? this.outbound.importOperation : null;
      schedulingFlight.importOperationInbound = this.inbound ? this.inbound.importOperation : null;
      schedulingFlight.importOperationOutbound = this.outbound ? this.outbound.importOperation : null;
      schedulingFlight.tagInbound = this.inbound ? this.inbound.tag : null;
      schedulingFlight.tagOutbound = this.outbound ? this.outbound.tag : null;
      schedulingFlight.fieldsBlockedInbound = this.inbound ? this.inbound.fieldsBlocked : null;
      schedulingFlight.fieldsBlockedOutbound = this.outbound ? this.outbound.fieldsBlocked : null;
      return [schedulingFlight];
    }

    if (this.importOperation === SchedulingImportResultOperation.EQUAL) {
      const schedulingFlight = new ConnectedFlights(this.inbound ? this.inbound.newValue : null, this.outbound ? this.outbound.newValue : null, null, this.sortingTime);
      schedulingFlight.importOperation = SchedulingImportResultOperation.EQUAL;
      schedulingFlight.importStatusInbound = this.inbound ? this.inbound.validationStatus : null;
      schedulingFlight.importStatusOutbound = this.outbound ? this.outbound.validationStatus : null;
      schedulingFlight.importOperationInbound = this.inbound ? this.inbound.importOperation : null;
      schedulingFlight.importOperationOutbound = this.outbound ? this.outbound.importOperation : null;
      schedulingFlight.tagInbound = this.inbound ? this.inbound.tag : null;
      schedulingFlight.tagOutbound = this.outbound ? this.outbound.tag : null;
      schedulingFlight.fieldsBlockedInbound = this.inbound ? this.inbound.fieldsBlocked : null;
      schedulingFlight.fieldsBlockedOutbound = this.outbound ? this.outbound.fieldsBlocked : null;
      return [schedulingFlight];
    }

    if (this.importOperation === SchedulingImportResultOperation.DELETE) {
      const schedulingFlight = new ConnectedFlights(this.inbound ? this.inbound.oldValue : null, this.outbound ? this.outbound.oldValue : null, null, this.sortingTime);
      schedulingFlight.importOperation = SchedulingImportResultOperation.DELETE;
      schedulingFlight.importStatusInbound = this.inbound ? this.inbound.validationStatus : null;
      schedulingFlight.importStatusOutbound = this.outbound ? this.outbound.validationStatus : null;
      schedulingFlight.importOperationInbound = this.inbound ? this.inbound.importOperation : null;
      schedulingFlight.importOperationOutbound = this.outbound ? this.outbound.importOperation : null;
      schedulingFlight.tagInbound = this.inbound ? this.inbound.tag : null;
      schedulingFlight.tagOutbound = this.outbound ? this.outbound.tag : null;
      schedulingFlight.fieldsBlockedInbound = this.inbound ? this.inbound.fieldsBlocked : null;
      schedulingFlight.fieldsBlockedOutbound = this.outbound ? this.outbound.fieldsBlocked : null;
      return [schedulingFlight];
    }

    if (this.importOperation === SchedulingImportResultOperation.MODIFY) {
      const schedulingFlight = new ConnectedFlights(this.inbound ? this.inbound.newValue : null, this.outbound ? this.outbound.newValue : null, null, this.sortingTime);
      schedulingFlight.old = new ConnectedFlights(this.inbound ? this.inbound.oldValue : null, this.outbound ? this.outbound.oldValue : null);
      schedulingFlight.importOperation = SchedulingImportResultOperation.MODIFY;
      schedulingFlight.importStatusInbound = this.inbound ? this.inbound.validationStatus : null;
      schedulingFlight.importStatusOutbound = this.outbound ? this.outbound.validationStatus : null;
      schedulingFlight.importOperationInbound = this.inbound ? this.inbound.importOperation : null;
      schedulingFlight.importOperationOutbound = this.outbound ? this.outbound.importOperation : null;
      schedulingFlight.tagInbound = this.inbound ? this.inbound.tag : null;
      schedulingFlight.tagOutbound = this.outbound ? this.outbound.tag : null;
      schedulingFlight.fieldsBlockedInbound = this.inbound ? this.inbound.fieldsBlocked : null;
      schedulingFlight.fieldsBlockedOutbound = this.outbound ? this.outbound.fieldsBlocked : null;
      return [schedulingFlight];
    }

    return [];
  }
}
