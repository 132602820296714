import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,[_c(VLayout,{attrs:{"align-right":"","justify-center":"","column":"","fill-height":""}},[_c(VFlex,{attrs:{"xs12":""}},[_c(VCardTitle,{attrs:{"primary-title":""}},[_c('div',{staticClass:"admin-content-cards"},[_c('div',{staticClass:"headline"},[_vm._v(_vm._s(_vm.$t("administration.cleaningGhmSatIntegrationTitle")))]),_c('div',{staticClass:"grey--text text--darken-1"},[_vm._v(_vm._s(_vm.$t("administration.cleaningGhmSatIntegrationText")))])])])],1),_c(VFlex,{attrs:{"xs12":""}},[_c(VLayout,{attrs:{"align-center":"","justify-center":"","column":""}},[_c(VFlex,{attrs:{"xs6":""}},[(_vm.hasPermissions(_vm.rolePermission) && _vm.ghmSatIntegration.available)?_c(VDialog,{attrs:{"max-width":"50%"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VBtn,{attrs:{"slot":"activator","color":"primary","dark":""},slot:"activator"},[_vm._v(_vm._s(_vm.$t("administration.cleaningGhmSatIntegration"))+" ")]),_c(VCard,{attrs:{"dark":this.$vuetify.dark,"color":this.$vuetify.dark ? 'bggrey_dark' : 'bggrey'}},[_c(VToolbar,{attrs:{"dark":"","color":"primary"}},[_c(VIcon,[_vm._v("dns")]),_c(VToolbarTitle,[_vm._v(_vm._s(_vm.$t("administration.ghmSatConfirmation")))])],1),_c(VCardText,[_vm._v(" "+_vm._s(_vm.$t("administration.continueAlert"))+" ")]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"flat":"","color":"primary"},nativeOn:{"click":function($event){return _vm.cancel.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t("common.cancel")))]),_c(VBtn,{attrs:{"color":"primary","depressed":""},nativeOn:{"click":function($event){return _vm.confirm.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t("common.confirm")))])],1)],1)],1):_c(VBtn,{attrs:{"color":"primary","disabled":""}},[_vm._v(_vm._s(_vm.$t("administration.cleaningGhmSatIntegration")))])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }