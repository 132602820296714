/* eslint no-param-reassign: "off" */

import moment from 'moment';
import { HTTP } from '../../http';
import { REQUEST_ERROR, REQUEST_START, REQUEST_SUCCESS } from '../store';
import FlightWeightingAggregated from '../../components/shared/model/flightWeightingAggregated';
import FlightWeightingFilter from '../../components/shared/model/flightWeightingFilter';
import { ACTION_SET_NO_FLIGHTS_TO_SHOW_MESSAGE } from './noFlightsToShowMessages';

// action
export const SCHED_WEIGHT_GRID_FIT = 'SCHED_WEIGHT_GRID_FIT';
export const SCHED_WEIGHT_CHANGE_GRID_API = 'SCHED_WEIGHT_CHANGE_GRID_API';

export const SCHED_WEIGHT_FIND = 'SCHED_WEIGHT_FIND';
export const SCHED_WEIGHT_FIT_COLUMNS_TO_VIEW = 'SCHED_WEIGHT_FIT_COLUMNS_TO_VIEW';
export const SCHED_WEIGHT_FIT_COLUMNS_TO_BEST = 'SCHED_WEIGHT_FIT_COLUMNS_TO_BEST';

export const SCHED_WEIGHT_RECALCULATE_FILTER = 'SCHED_WEIGHT_RECALCULATE_FILTER';

// mutations
export const SCHED_WEIGHT_FIND_SUCCESS = 'SCHED_WEIGHT_FIND_SUCCESS';

export const SCHED_WEIGHT_FILTER_SET = 'SCHED_WEIGHT_FILTER_SET';
export const SCHED_WEIGHT_FILTER_SET_UNIT_TIME = 'SCHED_WEIGHT_FILTER_SET_UNIT_TIME';
export const SCHED_WEIGHT_UPDATE_FIT_COLUMNS_ON_RESIZE = 'SCHED_WEIGHT_UPDATE_FIT_COLUMNS_ON_RESIZE';

export const SCHED_WEIGHT_FILTER_RESET = 'SCHED_WEIGHT_FILTER_RESET';
export const SCHED_WEIGHT_FILTER_RESET_TIMEZONE = 'SCHED_WEIGHT_FILTER_RESET_TIMEZONE';

// initial state
const storeState = {
  gridApi: null,
  filter: FlightWeightingFilter.createEmptyFilter,
  flightsWeighting: [],
  fitColumnsOnResize: true,
};

// getters
const storeGetters = {
  getFitColumnsOnResize: state => state.fitColumnsOnResize,
};

// actions
const storeActions = {
  [SCHED_WEIGHT_FIND]: ({ dispatch, commit, state }) => {
    commit(REQUEST_START);
    return HTTP.post('flight-weightings/aggregated', state.filter)
      .then((resp) => {
        commit(REQUEST_SUCCESS);
        commit(SCHED_WEIGHT_FIND_SUCCESS, resp.data);
        dispatch(ACTION_SET_NO_FLIGHTS_TO_SHOW_MESSAGE, resp.status);
      })
      .catch((err) => {
        dispatch(ACTION_SET_NO_FLIGHTS_TO_SHOW_MESSAGE, err.response.status);
        commit(SCHED_WEIGHT_FIND_SUCCESS, []);
        commit(REQUEST_ERROR, err);
      });
  },
  [SCHED_WEIGHT_GRID_FIT]: ({ state }) => {
    if (state.gridApi) {
      state.gridApi.sizeColumnsToFit();
    }
  },
  [SCHED_WEIGHT_FIT_COLUMNS_TO_VIEW]: ({ state }) => {
    if (state.gridApi) {
      state.gridApi.sizeColumnsToFit();
    }
  },
  [SCHED_WEIGHT_FIT_COLUMNS_TO_BEST]: ({ state }) => {
    if (state.gridApi && state.gridApi.columnController && state.gridApi.columnController.columnApi) {
      state.gridApi.columnController.columnApi.autoSizeAllColumns();
    }
  },
  [SCHED_WEIGHT_RECALCULATE_FILTER]: ({ commit, state }) => {
    if (state.filter.dateFrom === null && state.filter.dateTo === null) {
      commit(SCHED_WEIGHT_FILTER_RESET);
    } else {
      commit(SCHED_WEIGHT_FILTER_RESET_TIMEZONE, state.filter.unitOfTime);
    }
  },
};

// mutations
const storeMutations = {
  [SCHED_WEIGHT_CHANGE_GRID_API]: (state, gridApi) => {
    state.gridApi = gridApi.api;
  },
  [SCHED_WEIGHT_FIND_SUCCESS]: (state, flightsWeighting) => {
    state.flightsWeighting = FlightWeightingAggregated.createArrayFromData(flightsWeighting);
  },
  [SCHED_WEIGHT_FILTER_SET]: (state, filter) => {
    if (filter) {
      state.filter = filter;
    } else {
      state.filter = FlightWeightingFilter.createEmptyFilter;
    }
  },
  [SCHED_WEIGHT_FILTER_SET_UNIT_TIME]: (state, unitOfTime) => {
    state.filter.unitOfTime = unitOfTime;
    if (unitOfTime) {
      state.filter.dateFrom = moment().startOf(unitOfTime);
      state.filter.dateTo = moment().endOf(unitOfTime);
    }
  },
  [SCHED_WEIGHT_UPDATE_FIT_COLUMNS_ON_RESIZE]: (state, fitColumnsOnResize) => {
    state.fitColumnsOnResize = fitColumnsOnResize;
  },
  [SCHED_WEIGHT_FILTER_RESET]: (state) => {
    state.filter.dateFrom = moment().startOf('day');
    state.filter.dateTo = moment().endOf('day');
  },
  [SCHED_WEIGHT_FILTER_RESET_TIMEZONE]: (state, unitOfTime) => {
    state.filter.unitOfTime = unitOfTime != null ? unitOfTime : 'day';
    state.filter.dateFrom = moment().startOf(state.filter.unitOfTime)
      .year(state.filter.dateFrom.year())
      .month(state.filter.dateFrom.month())
      .date(state.filter.dateFrom.date());
    state.filter.dateTo = moment().endOf(state.filter.unitOfTime)
      .year(state.filter.dateTo.year())
      .month(state.filter.dateTo.month())
      .date(state.filter.dateTo.date());
  },
};

export default {
  state: storeState,
  getters: storeGetters,
  actions: storeActions,
  mutations: storeMutations,
};
