<template>
  <v-layout row class="days-of-week">
    <filter-checkbox v-model="value.monday" :label="this.$i18n.t('commonFilter.monday')"
      @input="$emit('input', value)" />
    <filter-checkbox v-model="value.tuesday" :label="this.$i18n.t('commonFilter.tuesday')"
      @input="$emit('input', value)" />
    <filter-checkbox v-model="value.wednesday" :label="this.$i18n.t('commonFilter.wednesday')"
      @input="$emit('input', value)" />
    <filter-checkbox v-model="value.thursday" :label="this.$i18n.t('commonFilter.thursday')"
      @input="$emit('input', value)" />
    <filter-checkbox v-model="value.friday" :label="this.$i18n.t('commonFilter.friday')"
      @input="$emit('input', value)" />
    <filter-checkbox v-model="value.saturday" :label="this.$i18n.t('commonFilter.saturday')"
      @input="$emit('input', value)" />
    <filter-checkbox v-model="value.sunday" :label="this.$i18n.t('commonFilter.sunday')"
      @input="$emit('input', value)" />
  </v-layout>
</template>

<script>
import FilterCheckbox from './FilterCheckbox.vue';

export default {
  name: 'DaysOfWeek',
  components: { FilterCheckbox },
  props: ['value'],
};
</script>

<style>
.days-of-week .v-input {
  max-width: 3.4ch !important;
}
.days-of-week .v-label {
  min-width: 4ch !important;
}
</style>
