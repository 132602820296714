<template>
    <div  v-if="hasDispatcherRole">
        <div class="hidden-md-and-up">
            <v-btn icon v-on:click="goToMobileInfo">
                <v-icon class="bigIcon">phone_android</v-icon>
            </v-btn>
        </div>
    </div>
</template>

<script>

import RolePermissions from './mixins/RolePermissions.vue';


export default {
  name: 'mobile-view',
  mixins: [RolePermissions],
  computed: {
    hasDispatcherRole() {
      return this.hasPermissions('ROLE_DISPATCHER');
    },
  },
  methods: {
    goToMobileInfo() {
      if (this.$route.name === 'mobile') {
        return;
      }
      this.$router.push({ name: 'mobile' });
    },
  },
};
</script>

<style scoped>
.bigIcon {
    font-size: 35px;
    padding: 10px;
}
</style>
