<template>
  <div>
    <water-toilet-flights-list-filter @changeShowFilter="changeShowFilter" />
    <water-toilet-flights-list-container :isFilterDisplayed="filterDisplayed" />
  </div>
</template>
<script>
import WaterToiletFlightsListContainer from '../components/ramp/watertoilet/list/WaterToiletFlightsListContainer.vue';
import WaterToiletFlightsListFilter from '../components/ramp/watertoilet/list/WaterToiletFlightsListFilter.vue';

export default {
  components: {
    WaterToiletFlightsListContainer,
    WaterToiletFlightsListFilter,
  },
  computed: {
  },
  data() {
    return {
      filterDisplayed: false,
    };
  },
  methods: {
    changeShowFilter(dispayled) {
      this.filterDisplayed = dispayled;
    },
  },
};
</script>
