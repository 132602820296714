/* eslint no-param-reassign: "off" */
/* eslint no-confusing-arrow: "off" */
/* eslint no-nested-ternary: "off" */

import { HTTP } from '../../../http';
import { REQUEST_ERROR, REQUEST_START, REQUEST_SUCCESS } from '../../store';
import { NOTIFICATION_SHOW } from '../notifications';
import i18n from '../../../plugins/i18n';

// actions names
export const GET_AIRPORT_PARKING = 'GET_AIRPORT_PARKING';
export const AIRPORT_PARKING_DELETE = 'AIRPORT_PARKING_DELETE';
export const AIRPORT_PARKING_CREATE = 'AIRPORT_PARKING_CREATE';
export const AIRPORT_PARKING_UPDATE = 'AIRPORT_PARKING_UPDATE';
export const AIRPORT_PARKING_DISABLE = 'AIRPORT_PARKING_DISABLE';
export const AIRPORT_PARKING_ENABLE = 'AIRPORT_PARKING_ENABLE';


// mutations
export const CHANGE_AIRPORT_PARKING_SELECTION = 'CHANGE_AIRPORT_PARKING_SELECTION';
export const GET_AIRPORT_PARKING_SUCCESS = 'GET_AIRPORT_PARKING_SUCCESS';
export const GET_AIRPORT_PARKING_REQUESTED = 'GET_AIRPORT_PARKING_REQUESTED';
export const UPDATE_AIRPORT_PARKING_SUCCESS = 'UPDATE_AIRPORT_PARKING_SUCCESS';
export const CHANGED_TENANT_AIRPORT_PARKING = 'CHANGED_TENANT_AIRPORT_PARKING';

// initial state
const storeState = {
  airportParking: [],
  airportParkingRequested: false,
  selectedAirportParking: [],
};

// getters
const storeGetters = {
  getAirportParking: state => state.airportParking.sort((a, b) => (a.code > b.code) ? 1 : ((b.code > a.code) ? -1 : 0)),
  getActiveAirportParking: state => state.airportParking.filter(elem => !elem.deleted).sort((a, b) => (a.code > b.code) ? 1 : ((b.code > a.code) ? -1 : 0)),
  getAirportParkingSelected: state => state.selectedAirportParking,
  hasSelectedAirportParking: state => state.selectedAirportParking.length > 0,
};

// actions
const storeActions = {
  [GET_AIRPORT_PARKING]: ({ commit, state }) => {
    if (state.airportParkingRequested) {
      return;
    }
    commit(REQUEST_START);
    commit(GET_AIRPORT_PARKING_REQUESTED);
    HTTP.get('airport-parkings')
      .then((resp) => {
        commit(REQUEST_SUCCESS);
        commit(GET_AIRPORT_PARKING_SUCCESS, resp.data);
      })
      .catch((err) => {
        commit(REQUEST_ERROR, err);
      });
  },

  [AIRPORT_PARKING_CREATE]: ({ commit, dispatch }, parking) => {
    commit(REQUEST_START);
    return HTTP.post('airport-parkings', parking)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(UPDATE_AIRPORT_PARKING_SUCCESS);
        dispatch(GET_AIRPORT_PARKING);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.airportParkingCreate'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },

  [AIRPORT_PARKING_UPDATE]: ({ commit, dispatch }, parking) => {
    commit(REQUEST_START);
    return HTTP.put('airport-parkings', parking)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(UPDATE_AIRPORT_PARKING_SUCCESS);
        dispatch(GET_AIRPORT_PARKING);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.airportParkingUpdate'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
  [AIRPORT_PARKING_DELETE]: ({ commit, dispatch }, id) => {
    commit(REQUEST_START);
    return HTTP.delete(`airport-parkings/${id}`)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(UPDATE_AIRPORT_PARKING_SUCCESS);
        dispatch(GET_AIRPORT_PARKING);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.airportParkingDelete'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
  [AIRPORT_PARKING_DISABLE]: ({ commit, dispatch }, id) => {
    commit(REQUEST_START);
    return HTTP.patch(`/airport-parkings/disable/${id}`)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(UPDATE_AIRPORT_PARKING_SUCCESS);
        dispatch(GET_AIRPORT_PARKING);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.airportParkingDisable'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
  [AIRPORT_PARKING_ENABLE]: ({ commit, dispatch }, id) => {
    commit(REQUEST_START);
    return HTTP.patch(`/airport-parkings/enable/${id}`)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(UPDATE_AIRPORT_PARKING_SUCCESS);
        dispatch(GET_AIRPORT_PARKING);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.airportParkingEnable'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },

};

// mutations
const storeMutations = {
  [GET_AIRPORT_PARKING_SUCCESS]: (state, airportParking) => {
    state.airportParking = airportParking;
  },
  [GET_AIRPORT_PARKING_REQUESTED]: (state) => {
    state.airportParkingRequested = true;
  },
  [UPDATE_AIRPORT_PARKING_SUCCESS]: (state) => {
    state.airportParkingRequested = false;
  },
  [CHANGE_AIRPORT_PARKING_SELECTION]: (state, selection) => {
    state.selectedAirportParking = selection;
  },
  [CHANGED_TENANT_AIRPORT_PARKING]: (state) => {
    state.airportParkingRequested = false;
  },
};

export default {
  state: storeState,
  getters: storeGetters,
  actions: storeActions,
  mutations: storeMutations,
};
