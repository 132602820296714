<script>
import Vue from 'vue';

export default Vue.extend({
  methods: {
    isTemporalObject(object) {
      // eslint-disable-next-line
      if (object.isValid && object._isAMomentObject) {
        return object.isValid();
      }
      return !!(object.contains && object.contains('-') && this.$moment(object).isValid());
    },
  },
});
</script>
