export default class FlightFilter {
  constructor(dateFrom, dateTo, bound, airlineId) {
    this.dateFrom = dateFrom;
    this.dateTo = dateTo;
    this.bound = bound;
    this.airlineId = airlineId;
  }

  static get createEmptyFilter() {
    return new FlightFilter(null, null, null, null);
  }
}
