<template>
  <div>
    <v-radio-group :value="value" @change="input" :rules="rules" :readonly="readonly">
      <v-radio
        :label="$t(`ticketing.${paymentTypes[0]}`)"
        :value="paymentTypes[0]"
        color="primary"
      ></v-radio>
      <v-radio
        :label="$t(`ticketing.${paymentTypes[1]}`)"
        :value="paymentTypes[1]"
        color="primary"
      ></v-radio>
      <v-radio
        :label="$t(`ticketing.${paymentTypes[2]}`)"
        :value="paymentTypes[2]"
        color="primary"
      ></v-radio>
    </v-radio-group>
  </div>
</template>

<script>

export default {
  name: 'payment-type-selector',
  props: {
    value: { required: true },
    label: { type: String },
    charLenght: { type: Number },
    rules: { type: Array },
    disabled: { type: Boolean },
    readonly: { type: Boolean, default: false },
    multiple: {
      type: Boolean,
      default: false,
    },
    refComp: {
      type: String,
      required: false,
      default: 'refComp',
    },
  },
  data() {
    return {
      paymentTypes: ['CASH', 'CREDIT_CARD', 'CASH_AND_CARD'],
    };
  },
  methods: {
    input(event) {
      this.$emit('input', event);
      this.$emit('change', event);
    },
  },
};
</script>
<style lang="scss" scoped>
  /*.btn-toggle {*/
    /*flex-direction: column;*/
  /*}*/
</style>
