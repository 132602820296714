/* eslint no-param-reassign: "off" */
/* eslint no-confusing-arrow: "off" */
/* eslint no-nested-ternary: "off" */

import { HTTP } from '../../../http';
import { REQUEST_ERROR, REQUEST_START, REQUEST_SUCCESS } from '../../store';
import { NOTIFICATION_SHOW } from '../notifications';
import i18n from '../../../plugins/i18n';

// actions names
export const GET_AIRPORTS = 'GET_AIRPORTS';
export const AIRPORT_DELETE = 'AIRPORT_DELETE';
export const AIRPORT_CREATE = 'AIRPORT_CREATE';
export const AIRPORT_UPDATE = 'AIRPORT_UPDATE';
export const AIRPORT_DISABLE = 'AIRPORT_DISABLE';
export const AIRPORT_ENABLE = 'AIRPORT_ENABLE';


// mutations
export const CHANGE_AIRPORT_SELECTION = 'CHANGE_AIRPORT_SELECTION';
export const GET_AIRPORTS_SUCCESS = 'GET_AIRPORTS_SUCCESS';
export const GET_AIRPORTS_REQUESTED = 'GET_AIRPORTS_REQUESTED';
export const UPDATE_AIRPORT_SUCCESS = 'UPDATE_AIRPORT_SUCCESS';

// initial state
const storeState = {
  airport: [],
  airportRequested: false,
  selectedAirport: [],
};

// getters
const storeGetters = {
  getAirports: state => state.airport.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)),
  getActiveAirports: state => state.airport.filter(elem => !elem.deleted).filter(elem => !!elem.code).sort((a, b) => (a.code > b.code) ? 1 : ((b.code > a.code) ? -1 : 0)),
  getAirportSelected: state => state.selectedAirport,
  hasSelectedAirport: state => state.selectedAirport.length > 0,
  getAirportById: state => id => state.airport.find(a => a.id === id),
};

// actions
const storeActions = {
  [GET_AIRPORTS]: ({ commit, state }) => {
    if (state.airportRequested) {
      return;
    }
    commit(REQUEST_START);
    commit(GET_AIRPORTS_REQUESTED);
    HTTP.get('airports')
      .then((resp) => {
        commit(REQUEST_SUCCESS);
        commit(GET_AIRPORTS_SUCCESS, resp.data);
      })
      .catch((err) => {
        commit(REQUEST_ERROR, err);
      });
  },

  [AIRPORT_CREATE]: ({ commit, dispatch }, parking) => {
    commit(REQUEST_START);
    return HTTP.post('airports', parking)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(UPDATE_AIRPORT_SUCCESS);
        dispatch(GET_AIRPORTS);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.airportCreate'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },

  [AIRPORT_UPDATE]: ({ commit, dispatch }, parking) => {
    commit(REQUEST_START);
    return HTTP.put('airports', parking)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(UPDATE_AIRPORT_SUCCESS);
        dispatch(GET_AIRPORTS);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.airportUpdate'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
  [AIRPORT_DELETE]: ({ commit, dispatch }, id) => {
    commit(REQUEST_START);
    return HTTP.delete(`airports/${id}`)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(UPDATE_AIRPORT_SUCCESS);
        dispatch(GET_AIRPORTS);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.airportDelete'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
  [AIRPORT_DISABLE]: ({ commit, dispatch }, id) => {
    commit(REQUEST_START);
    return HTTP.patch(`/airports/disable/${id}`)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(UPDATE_AIRPORT_SUCCESS);
        dispatch(GET_AIRPORTS);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.airportDisable'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
  [AIRPORT_ENABLE]: ({ commit, dispatch }, id) => {
    commit(REQUEST_START);
    return HTTP.patch(`/airports/enable/${id}`)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(UPDATE_AIRPORT_SUCCESS);
        dispatch(GET_AIRPORTS);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.airportEnable'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
};

// mutations
const storeMutations = {
  [GET_AIRPORTS_SUCCESS]: (state, airport) => {
    state.airport = airport;
  },
  [GET_AIRPORTS_REQUESTED]: (state) => {
    state.airportRequested = true;
  },
  [UPDATE_AIRPORT_SUCCESS]: (state) => {
    state.airportRequested = false;
  },
  [CHANGE_AIRPORT_SELECTION]: (state, selection) => {
    state.selectedAirport = selection;
  },
};

export default {
  state: storeState,
  getters: storeGetters,
  actions: storeActions,
  mutations: storeMutations,
};
