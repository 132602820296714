export default class OperationsUtils {
  static getValueLoadHolding(params, flightBound) {
    const realCargo = params.data.getChildPropertyValue(flightBound, ['operationExtended', 'loadHoldingWeight']) > 0
      || params.data.getChildPropertyValue(flightBound, ['operationExtended', 'loadCargo']) > 0
      || params.data.getChildPropertyValue(flightBound, ['operationExtended', 'loadBag']) > 0;
    const estimatedCargo = params.data.getChildPropertyValue(flightBound, ['operationExtended', 'loadEstimated']) > 0;

    let text = '';
    let tooltip = '';
    let styleContent = {};
    let unit = params.data.getChildPropertyValue(flightBound, ['operationExtended', 'loadUnit']);
    unit = unit === 'POUNDS' ? 'Lbs' : 'Kg';

    if (realCargo && !estimatedCargo) {
      text = params.data.getChildPropertyValue(flightBound, ['operationExtended', 'loadHoldingWeight'])
        || params.data.getChildPropertyValue(flightBound, ['operationExtended', 'loadCargo'])
        || params.data.getChildPropertyValue(flightBound, ['operationExtended', 'loadBag']);
      tooltip = `R: ${text} ${unit}`;
    } else if (!realCargo && !estimatedCargo) {
      text = '';
    } else if (!realCargo && estimatedCargo) {
      text = params.data.getChildPropertyValue(flightBound, ['operationExtended', 'loadEstimated']);
      tooltip = `Estimated: ${text} ${unit}`;
      styleContent = { color: 'darkslategray', 'font-weight': 'bold', 'font-style': 'italic' };
    } else if (realCargo && estimatedCargo) {
      const realValue = params.data.getChildPropertyValue(flightBound, ['operationExtended', 'loadHoldingWeight'])
        || params.data.getChildPropertyValue(flightBound, ['operationExtended', 'loadCargo'])
        || params.data.getChildPropertyValue(flightBound, ['operationExtended', 'loadBag']);
      const estimatedValue = params.data.getChildPropertyValue(flightBound, ['operationExtended', 'loadEstimated']);
      text = realValue;
      tooltip = `R: ${realValue} ${unit} E: ${estimatedValue} ${unit}`;
    }

    text = text ? `${text} ${unit}` : '';

    return {
      value: text,
      tooltip,
      asterisk: params.data.getChildPropertyValue(flightBound, ['operationExtended', 'loadSpecial']),
      styleObject: {
        asteriskColor: 'red',
        content: styleContent,
      },
    };
  }
}
