<template>
    <v-container v-if="!isEmptyRemarks()" class="mobileInfoTimes">
                <v-layout row wrap class="flex justify-center">
                    <v-flex xs12 sm12 md7 lg7 xl7 v-if="flightMobile.scheduledTime">
                        <div class="mx-2">
                            <v-chip color="blue" dark>
                                <v-icon class="mx-1">access_time</v-icon>
                                <strong class="mx-1">
                                {{ formattedTime(flightMobile.scheduledTime) }}
                                </strong>
                                <span>-</span>
                                <strong class="mx-1">{{ flightMobile.bound === FlightBound.INBOUND ? $t('operations.scheduledTimeArrival') : $t('operations.scheduledTimeOfDeparture') }}</strong>
                                <span>(</span>
                                <span>{{ flightMobile.bound === FlightBound.INBOUND ? $t('operations.scheduledTimeArrivalDescription') : $t('operations.scheduledTimeOfDepartureDescription') }}</span>
                                <span>)</span>
                            </v-chip>
                        </div>
                    </v-flex>
                    <v-flex xs12 sm12 md5 lg5 xl5 v-if="flightMobile.bestTime.flightBestTimeValue">
                        <div  class="mx-2">
                            <v-chip color="green" dark>
                              <v-icon class="mx-1">more_time</v-icon>
                                <strong class="mx-1">
                                {{ formattedTime(flightMobile.bestTime.flightBestTimeValue) }}
                                </strong>
                                <span >-</span>
                                <strong class="mx-1">{{ $t('operations.bestTime') }}</strong>
                                <span>(</span>
                                <span>{{ flightMobile.bestTime.flightBestTimeType }}</span>
                                <span >)</span>
                            </v-chip>
                        </div>
                    </v-flex>
                    <v-flex xs12 sm12 md7 lg7 xl7 v-if="flightMobile.ctotTime" >
                        <div class="mx-2">
                            <v-chip color="orange" dark>
                                <v-icon class="mx-1">airplane_ticket</v-icon>
                                <strong class="mx-1">
                                {{ formattedTime(flightMobile.ctotTime) }}
                                </strong>
                                <span>-</span>
                                <strong class="mx-1">{{ $t('operations.calculatedTakeOffTime') }}</strong>
                                <span>(</span>
                                <span>{{ $t('operations.calculatedTakeOffTimeTooltip') }}</span>
                                <span>)</span>
                            </v-chip>
                        </div>
                    </v-flex>
                    <v-flex xs12 sm12 md5 lg5 xl5 v-if="flightMobile.airportBeltCode">
                        <div  class="mx-2">
                            <v-chip color="orange" dark>
                              <v-icon class="mx-1">stacked_line_chart</v-icon>
                              <strong class="mx-1">{{ $t('ramp.belt') }}:</strong>
                              <strong class="mx-1">
                                {{ flightMobile.airportBeltCode }}
                              </strong>
                            </v-chip>
                        </div>
                    </v-flex>
                </v-layout>
    </v-container>
</template>

<script>

import FlightStatus from '../flights/FlightStatus.vue';
import { TIME_TIMEZONE_CHANGED } from '../../store/modules/time';
import StoreSubscribable from '../mixins/StoreSubscribable.vue';


export default {
  name: 'info-mobile-times',
  props: {
    flightId: {
      required: true,
    },
    flightMobile: {
      required: true,
    },
  },
  mixins: [StoreSubscribable],
  components: {
    FlightStatus,
  },
  computed: {
    FlightBound() {
      return {
        INBOUND: 'INBOUND',
        OUTBOUND: 'OUTBOUND',
      };
    },
  },
  data() {
    return {
      localTimezone: this.$store.state.time.timezone,
    };
  },
  methods: {
    formattedTime(date) {
      if (date) {
        return this.$moment(date).tz(this.localTimezone).format('HH:mm');
      }
      return '';
    },
    isEmptyRemarks() {
      let empty = true;
      if (this.flightMobile.scheduledTime) {
        empty = false;
      }
      if (this.flightMobile.bestTime) {
        empty = false;
      }
      if (this.flightMobile.ctotTime) {
        empty = false;
      }
      if (this.flightMobile.airportBeltCode) {
        empty = false;
      }
      return empty;
    },
  },
  created() {
    this.subscribeMutation(TIME_TIMEZONE_CHANGED, () => {
      this.localTimezone = this.$store.state.time.timezone;
    });
  },
};
</script>
<style scoped>
.mobileInfoTimes {
  padding-bottom: 0px;
  padding-top: 0px;
}
.mobileInfoTimes .v-chip {
    max-width: 100%;
    width: 100%;
    font-size: 17px;
    padding-top: 10px;
    padding-bottom: 8px;
    margin-bottom: 8px !important;
}
</style>
