/* eslint no-param-reassign: "off" */
import moment from 'moment';
import { HTTP } from '../../http';
import { REQUEST_ERROR, REQUEST_START, REQUEST_SUCCESS } from '../store';
import { createFlightEventFromData } from '../../components/shared/model/flightEvent';
import ConnectedFlightsFactory from '../../components/shared/model/connectedFlightsFactory';
import DelayFilter from '../../components/delay/model/delayFilter';
import { ACTION_SET_NO_FLIGHTS_TO_SHOW_MESSAGE } from './noFlightsToShowMessages';

// action
export const DELAYS_SET_GRID_API = 'DELAYS_SET_GRID_API';

export const DELAYS_FIND_FLIGHTS = 'DELAYS_FIND_FLIGHTS';
export const DELAYS_DO_EXTERNAL_CHANGE = 'DELAYS_DO_EXTERNAL_CHANGE';

export const FIT_COLUMNS_TO_VIEW = 'FIT_COLUMNS_TO_VIEW';
export const FIT_COLUMNS_TO_BEST = 'FIT_COLUMNS_TO_BEST';

export const DELAYS_RECALCULATE_FILTER = 'DELAYS_RECALCULATE_FILTER';

// mutations
export const DELAYS_FIND_FLIGHTS_SUCCESS = 'DELAYS_FIND_FLIGHTS_SUCCESS';
export const DELAYS_CHANGE_FLIGHT_SELECTION = 'DELAYS_CHANGE_FLIGHT_SELECTION';
export const DELAYS_ON_EXTERNAL_CHANGE = 'DELAYS_ON_EXTERNAL_CHANGE ';
export const DELAYS_SET_CHANGE = 'DELAYS_SET_CHANGE ';
export const DELAYS_FILTER_SET_UNIT_TIME = 'DELAYS_FILTER_SET_UNIT_TIME';

export const DELAYS_FILTER_RESET = 'DELAYS_FILTER_RESET ';
export const DELAYS_FILTER_INCREASE_DAY = 'DELAYS_FILTER_INCREASE_DAY ';
export const DELAYS_FILTER_DECREASE_DAY = 'DELAYS_FILTER_DECREASE_DAY ';
export const DELAYS_FILTER_RESET_TIMEZONE = 'DELAYS_FILTER_RESET_TIMEZONE';

export const UPDATE_SHOW_OLD_VALUES = 'UPDATE_SHOW_OLD_VALUES ';
export const UPDATE_FLASH_ON_CHANGES = 'UPDATE_FLASH_ON_CHANGES ';

export const UPDATE_FIT_COLUMNS_ON_RESIZE = 'UPDATE_FIT_COLUMNS_ON_RESIZE ';

// initial state
const storeState = {
  gridApi: null,
  filter: DelayFilter.createEmptyFilter,
  connectedFlightsDelays: [],
  selectedFlights: [],
  flightsChanges: [],
  showOldValues: true,
  flashOnChanges: false,
  fitColumnsOnResize: true,
};

// getters
const storeGetters = {
  hasSelectedDelayFlights: state => state.selectedFlights.length > 0,
  isSelectedDelayFlights: state => id => !!state.selectedFlights.find(s => s.getId() === id),
};

// actions
const storeActions = {
  [DELAYS_FIND_FLIGHTS]: ({ dispatch, commit, state }) => {
    commit(REQUEST_START);
    commit(DELAYS_CHANGE_FLIGHT_SELECTION, []);
    return HTTP.post('delays/flights/find', state.filter)
      .then((resp) => {
        commit(REQUEST_SUCCESS);
        commit(
          DELAYS_FIND_FLIGHTS_SUCCESS,
          ConnectedFlightsFactory.createConnectedFlightsFromData(resp.data),
        );
        commit(DELAYS_CHANGE_FLIGHT_SELECTION, []);
        dispatch(ACTION_SET_NO_FLIGHTS_TO_SHOW_MESSAGE, resp.status);
      })
      .catch((err) => {
        dispatch(ACTION_SET_NO_FLIGHTS_TO_SHOW_MESSAGE, err.response.status);
        commit(DELAYS_FIND_FLIGHTS_SUCCESS, []);
        commit(REQUEST_ERROR, err);
      });
  },
  [DELAYS_DO_EXTERNAL_CHANGE]: ({ commit }, change) => {
    commit(DELAYS_ON_EXTERNAL_CHANGE, createFlightEventFromData(change));
  },
  [FIT_COLUMNS_TO_VIEW]: ({ state }) => {
    if (state.gridApi) {
      state.gridApi.sizeColumnsToFit();
    }
  },
  [FIT_COLUMNS_TO_BEST]: ({ state }) => {
    if (state.gridApi && state.gridApi.columnController && state.gridApi.columnController.columnApi) {
      state.gridApi.columnController.columnApi.autoSizeAllColumns();
    }
  },
  [DELAYS_RECALCULATE_FILTER]: ({ commit, state }) => {
    if (state.filter.dateFrom === null && state.filter.dateTo === null) {
      commit(DELAYS_FILTER_RESET);
    } else {
      commit(DELAYS_FILTER_RESET_TIMEZONE, state.filter.unitOfTime);
    }
  },
};

// mutations
const storeMutations = {
  [DELAYS_SET_GRID_API]: (state, gridApi) => {
    state.gridApi = gridApi.api;
  },
  [DELAYS_FIND_FLIGHTS_SUCCESS]: (state, connectedFlightsDelays) => {
    state.connectedFlightsDelays = connectedFlightsDelays;
  },
  [DELAYS_CHANGE_FLIGHT_SELECTION]: (state, selection) => {
    state.selectedFlights = selection;
  },
  [DELAYS_ON_EXTERNAL_CHANGE]: (state, change) => {
    console.log(`mutations external change ${change}`); // eslint-disable-line no-console
  },
  [DELAYS_SET_CHANGE]: (state, change) => {
    change.ts = moment();
    if (change.diff.path.includes('inbound')) {
      change.flightNumber = change.connectedFlights.inbound.common.flightNo();
      change.bound = 'inbound';
    }
    if (change.diff.path.includes('outbound')) {
      change.flightNumber = change.connectedFlights.outbound.common.flightNo();
      change.bound = 'outbound';
    }
    state.flightsChanges.unshift(change);
    state.flightsChanges = state.flightsChanges.slice(0, 10);
  },
  [DELAYS_FILTER_RESET]: (state) => {
    state.filter.dateFrom = moment().startOf('day');
    state.filter.dateTo = moment().endOf('day');

    state.filter.timezoneDate = moment.defaultZone.name;
  },
  [DELAYS_FILTER_INCREASE_DAY]: (state) => {
    state.filter.dateFrom = moment().startOf('day')
      .year(state.filter.dateFrom.year())
      .month(state.filter.dateFrom.month())
      .date(state.filter.dateFrom.date())
      .add(1, 'days')
      .startOf('day');

    state.filter.dateTo = moment().endOf('day')
      .year(state.filter.dateTo.year())
      .month(state.filter.dateTo.month())
      .date(state.filter.dateTo.date())
      .add(1, 'days');
  },
  [DELAYS_FILTER_DECREASE_DAY]: (state) => {
    state.filter.dateFrom = moment().startOf('day')
      .year(state.filter.dateFrom.year())
      .month(state.filter.dateFrom.month())
      .date(state.filter.dateFrom.date())
      .subtract(1, 'days');

    state.filter.dateTo = moment().endOf('day')
      .year(state.filter.dateTo.year())
      .month(state.filter.dateTo.month())
      .date(state.filter.dateTo.date())
      .subtract(1, 'days')
      .endOf('day');
  },
  [UPDATE_SHOW_OLD_VALUES]: (state, showOldValues) => {
    state.showOldValues = showOldValues;
  },
  [UPDATE_FLASH_ON_CHANGES]: (state, flashOnChanges) => {
    state.flashOnChanges = flashOnChanges;
  },
  [UPDATE_FIT_COLUMNS_ON_RESIZE]: (state, fitColumnsOnResize) => {
    state.fitColumnsOnResize = fitColumnsOnResize;
  },
  [DELAYS_FILTER_SET_UNIT_TIME]: (state, unitOfTime) => {
    state.filter.unitOfTime = unitOfTime;
    if (unitOfTime) {
      state.filter.dateFrom = moment().startOf(unitOfTime);
      state.filter.dateTo = moment().endOf(unitOfTime);
    }
  },
  [DELAYS_FILTER_RESET_TIMEZONE]: (state, unitOfTime) => {
    state.filter.unitOfTime = unitOfTime != null ? unitOfTime : 'day';
    state.filter.dateFrom = moment().startOf(state.filter.unitOfTime)
      .year(state.filter.dateFrom.year())
      .month(state.filter.dateFrom.month())
      .date(state.filter.dateFrom.date());
    state.filter.dateTo = moment().endOf(state.filter.unitOfTime)
      .year(state.filter.dateTo.year())
      .month(state.filter.dateTo.month())
      .date(state.filter.dateTo.date());
  },
};

export default {
  state: storeState,
  getters: storeGetters,
  actions: storeActions,
  mutations: storeMutations,
};
