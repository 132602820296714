<template>
  <div class="deliveries list-filter">
    <v-card :dark="this.$vuetify.dark" :color="this.$vuetify.dark ? 'bggrey_dark' : 'bggrey'">
      <v-toolbar dark dense color="primary lighten-1">
        <v-btn v-on:click="displayedFilter" small flat>
          <v-icon>filter_list</v-icon>
          <v-icon v-if="!showFilter">keyboard_arrow_down</v-icon>
          <v-icon v-if="showFilter">keyboard_arrow_up</v-icon>
        </v-btn>

        <v-divider vertical light style="margin-left: 30px; margin-right: 30px"></v-divider>

        <deliveries-list-filter-date-selector />

        <v-divider vertical light style="margin-left: 30px; margin-right: 30px"></v-divider>

        <v-spacer></v-spacer>

        <v-divider vertical light style="margin-left: 30px; margin-right: 30px"></v-divider>

        <v-flex grow="false" shrink="true">
          <deliveries-list-changes-list />
        </v-flex>

        <DeliveriesListTableOptionsFilterButton />

      </v-toolbar>
      <v-container fluid v-if="showFilter" class="pa-2 pb-2">
        <v-layout class="filter-inline py-0" align-center justify-start>
          <v-flex class="mr-2" align-center justify-start>
            <v-switch slot="activator" color="red" :label="this.$i18n.t('deliveries.showInternalFlight')"
              hide-details v-model="filter.showInternal">
            </v-switch>
          </v-flex>
          <v-flex class="mr-2" align-center justify-start>
            <v-switch slot="activator" color="red" :label="this.$i18n.t('deliveries.showExternalFlight')"
              hide-details v-model="filter.showExternal">
            </v-switch>
          </v-flex>

          <v-spacer style="width: 45%"></v-spacer>
          <v-flex class="ml-2 text-no-wrap" align-center justify-start>
            <v-btn color="secondary" flat round v-on:click.stop="print" block>
              <v-icon>print</v-icon>
              {{ $t("common.print") }}
            </v-btn>
          </v-flex>
          <v-flex class="ml-2 text-no-wrap" align-center justify-start>
            <v-btn color="secondary" flat round v-on:click.stop="exportCSV" block>
              <v-icon>mdi-download</v-icon>
              {{ $t("common.export") }}
            </v-btn>
          </v-flex>
          <v-flex class="ml-2 text-no-wrap" align-center justify-start>
            <v-btn color="primary" round v-on:click.stop="find" block>
              <v-icon>search</v-icon>
              {{ $t("common.find") }}
            </v-btn>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card>
  </div>

</template>
<script>
import AgGridApiCsvExportable from '../../../ag-grid/mixins/AgGridApiCsvExportable.vue';
import StoreSubscribable from '../../../mixins/StoreSubscribable.vue';
import {
  DELIVERY_SET_GRID_API,
  FIND_DELIVERIES,
  PRINT_DELIVERIES,
  UPDATE_FLASH_ON_CHANGES,
  UPDATE_SHOW_OLD_VALUES,
} from '../../../../store/modules/lostAndFound/delivery';
import DeliveriesListChangesList from './DeliveriesListChangesList.vue';
import DeliveriesListFilterDateSelector from './DeliveriesListFilterDateSelector.vue';
import DeliveriesListTableOptionsFilterButton from './DeliveriesListTableOptionsFilterButton.vue';

export default {
  name: 'ramp-flights-list-filter',
  mixins: [AgGridApiCsvExportable, StoreSubscribable],
  components: {
    DeliveriesListFilterDateSelector,
    DeliveriesListChangesList,
    DeliveriesListTableOptionsFilterButton,
  },
  props: [],
  data() {
    return {
      select: 50,
      showFilter: false,
      valid: true,
      queryName: null,
      dialog: false,
      showOldValues: null,
      flashOnChanges: null,
    };
  },
  computed: {
    filter() {
      return this.$store.state.delivery.filter;
    },
    date() {
      return this.$moment(this.$store.state.delivery.filter.dateFrom);
    },
  },
  methods: {
    find() {
      this.showLoading();
      this.$store.dispatch(FIND_DELIVERIES);
    },
    print() {
      this.$store.dispatch(PRINT_DELIVERIES);
    },
    exportCSV() {
      this.exportAgGridCsv('Deliveries');
    },
    updateShowOldValuesInStore() {
      this.$store.commit(UPDATE_SHOW_OLD_VALUES, this.showOldValues);
    },
    updateFlashOnChangesInStore() {
      this.$store.commit(UPDATE_FLASH_ON_CHANGES, this.flashOnChanges);
    },
    displayedFilter() {
      this.showFilter = !this.showFilter;
      this.$emit('changeShowFilter', this.showFilter);
    },
  },
  created() {
    this.showOldValues = this.$store.state.delivery.showOldValues;
    this.flashOnChanges = this.$store.state.delivery.flashOnChanges;
  },
  mounted() {
    const that = this;
    this.subscribeMutation(DELIVERY_SET_GRID_API, (mutationPayload) => {
      that.setAgGridApi(mutationPayload);
    });
  },
};
</script>
