import moment from 'moment';
import ConnectedFlightsFactory from './connectedFlightsFactory';
import FlightServiceBilling from './flightServiceBilling';
import ConnectedFlightsServiceBilling from './connectedFlightsServiceBilling';

export default class ConnectedFlightsServiceBillingFactory {
  static createConnectedFlightsServiceBillingFromData(data) {
    if (data && data.constructor === Array && data.length > 0) {
      return data.map(d => this.mapConnectedFlightsServiceBilling(d)).sort((item1, item2) => moment(item1.sortingTime) - moment(item2.sortingTime));
    }
    return [];
  }

  static mapConnectedFlightsServiceBilling(d) {
    return new ConnectedFlightsServiceBilling(
      d.connectedFlightsDTO ? ConnectedFlightsFactory.mapConnectedFlights(d.connectedFlightsDTO) : null,
      d.service ? FlightServiceBilling.createFlightServiceBillingFromData(d.service) : null,
      d.sortingTime ? d.sortingTime : null,
    );
  }
}
