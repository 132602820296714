<template>
  <div class="admin-scheduling-imports">
    <AdminSchedulingImportsHeader />
    <AdminSchedulingImportsBody />
  </div>
</template>
<script>
import AdminSchedulingImportsHeader from './AdminSchedulingImportsHeader.vue';
import AdminSchedulingImportsBody from './AdminSchedulingImportsBody.vue';
import { GET_SCHEDULING_IMPORT_FILES_STATUS } from '../../../../store/modules/schedulingAdminImports';

export default {
  name: 'AdminSchedulingImports',
  components: {
    AdminSchedulingImportsHeader,
    AdminSchedulingImportsBody,
  },
  created() {
    this.$store.dispatch(GET_SCHEDULING_IMPORT_FILES_STATUS);
  },
};
</script>
