export default class FlightMessagesFilter {
  constructor(dateFrom, dateTo, onlyWithoutFlights, timezoneDate) {
    this.dateFrom = dateFrom;
    this.dateTo = dateTo;
    this.onlyWithoutFlights = onlyWithoutFlights === true;
    this.timezoneDate = timezoneDate;
  }

  static get createEmptyFilter() {
    return new FlightMessagesFilter(null, null, false, null);
  }
}
