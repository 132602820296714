<template>
  <div>
    <v-autocomplete
      name="messageType"
      id="messageType"
      placeholder="..."
      :value="value"
      :label="label"
      :items="items"
      :filter="customFilter"
      :clearable="true"
      item-text="code"
      item-value="id"
      :rules="rules"
      :disabled="disabled"
      :menu-props="{zIndex:'1000'}"
      @input="emitInput"
      :ref="refComp"
      content-class="messageType"
    >
      <template
        slot="item"
        slot-scope="{ item }"
      >
        <v-list-tile-content>
          <v-list-tile-title>{{ item.code }}</v-list-tile-title>
          <v-list-tile-sub-title>{{ item.description }}</v-list-tile-sub-title>
        </v-list-tile-content>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import { GET_MESSAGE_TYPES } from '../../store/modules/flightCatalogs';

export default {
  props: {
    value: { required: true },
    label: { type: String },
    charLenght: { type: Number },
    rules: { type: Array },
    disabled: { type: Boolean },
    multiple: {
      type: Boolean,
      default: false,
    },
    refComp: {
      type: String,
      required: false,
      default: 'refComp',
    },
  },
  computed: {
    items: {
      get() {
        return this.$store.state.flightsCatalogs.messageTypes;
      },
    },
  },
  methods: {
    getMessageTypes() {
      this.$store.dispatch(GET_MESSAGE_TYPES);
    },
    customFilter(item, queryText) {
      const textOne = item.code ? item.code.toLowerCase() : '';
      const textTwo = item.description ? item.description.toLowerCase() : '';
      const searchText = queryText.toLowerCase();

      return textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1;
    },
    emitInput($event) {
      // FIXME así no rompe al limpiar el select pero hay que validar que no se pueda guardar el tipo de mensaje como NULL
      if (typeof $event !== 'undefined') {
        const type = this.items.find(p => p.id === $event);
        this.$emit('input-element', type);
        this.$emit('input-code', type.code);
        this.$emit('input', $event);
      } else {
        this.$emit('input-element', null);
        this.$emit('input-code', null);
        this.$emit('input', null);
      }
    },
  },
  mounted() {
    this.getMessageTypes();
  },
};
</script>
