<template>
  <div>
    <towing-adhoc-task-flights-list-filter @changeShowFilter="changeShowFilter"></towing-adhoc-task-flights-list-filter>
    <towing-adhoc-task-flights-list-container :isFilterDisplayed="filterDisplayed" @click-request-ramp-service="addDefaultService" ></towing-adhoc-task-flights-list-container>

    <towing-adhoc-task-flights-list-options @click-request-ramp-service="addDefaultService" @click-add-service="addService" @click-new-aircraft-ground="newAircraftGround" />
    <request-ramp-service-modal v-if="dialogRequestRampService" :dialog="dialogRequestRampService" :flight-composition="flight" :id-service="serviceId" :is-aircraft-ground-selected="isAircraftGroundSelected" @request-ramp-service-close="requestRampServiceClose"/>
    <aircraft-ground-new-modal v-if="dialogAircraftGround" :dialog="dialogAircraftGround" :addService="false" @aircraft-ground-new-reject="newAircraftGroundReject" @aircraft-ground-new-confirm="newAircraftGroundConfirm"/>
  </div>
</template>
<script>
import TowingAdhocTaskFlightsListFilter from '../components/towingAdhocTask/flights/list/TowingAdhocTaskFlightsListFilter.vue';
import TowingAdhocTaskFlightsListContainer from '../components/towingAdhocTask/flights/list/TowingAdhocTaskFlightsListContainer.vue';
import TowingAdhocTaskFlightsListOptions from '../components/towingAdhocTask/flights/list/TowingAdhocTaskFlightsListOptions.vue';
import RequestRampServiceModal from '../components/towingAdhocTask/services/RequestRampServiceModal.vue';
import { GET_RAMP_SERVICE } from '../store/modules/rampServices/rampServicesCatalog';
import { GET_AIRPORT_RAMP_SERVICE_BY_SERVICE_AGENT_OF_USER } from '../store/modules/rampServices/airportRampServicesCatalog';
import { FLIGHT_TOWING_ADHOC_TASK_FIND_FLIGHTS } from '../store/modules/towingAdhocTask/flightTowingAdhocTask';
import AircraftGroundNewModal from '../components/aircraftGround/AircraftGroundNewModal.vue';

export default {
  components: {
    TowingAdhocTaskFlightsListFilter,
    TowingAdhocTaskFlightsListContainer,
    TowingAdhocTaskFlightsListOptions,
    AircraftGroundNewModal,
    RequestRampServiceModal,
  },
  computed: {
    selectedRowsItems() {
      return this.$store.state.flightTowingAdhocTask.selectedFlights;
    },
    selectedRowsFlights() {
      return this.$store.getters.getSelectedFlightTowingAdhocTaskFlights;
    },
    selectedRowsAircraftGround() {
      return this.$store.getters.getSelectedFlightTowingAdhocTaskAircraftGround;
    },
  },
  data() {
    return {
      dialog: false,
      filterDisplayed: false,
      dialogRequestRampService: false,
      dialogAircraftGround: false,
      flight: null,
      isAircraftGroundSelected: false,
      connectedFlight: null,
      serviceId: null,
      additionalDateRules: [
        (v) => {
          const value = this.$moment(v, 'DD/MM/YY').startOf('day');
          const today = this.$moment().startOf('day');
          const tomorrow = this.$moment().add(1, 'days').startOf('day');
          return (!value.isBefore(today) && !value.isAfter(tomorrow)) || this.$i18n.t('operations.unvalidDate');
        },
      ],
    };
  },
  methods: {
    changeShowFilter(dispayled) {
      this.filterDisplayed = dispayled;
    },
    addDefaultService(flightInformation) {
      this.serviceId = flightInformation.serviceId;
      this.requestRampService(flightInformation.flight);
    },
    addService() {
      this.serviceId = null;
      if (this.selectedRowsAircraftGround != null && this.selectedRowsAircraftGround.length > 0) {
        const [ag] = this.selectedRowsAircraftGround;
        this.isAircraftGroundSelected = true;
        this.requestRampService(ag.inbound);
      }
      if (this.selectedRowsFlights != null && this.selectedRowsFlights.length > 0) {
        const [f] = this.selectedRowsFlights;
        this.connectedFlight = f;
        this.isAircraftGroundSelected = false;
        if (f.hasInbound()) {
          this.requestRampService(f.inbound);
        } else if (f.hasOutbound()) {
          this.requestRampService(f.outbound);
        }
      }
    },
    requestRampService(flightComposition) {
      this.flight = flightComposition;
      this.dialogRequestRampService = true;
    },
    requestRampServiceClose() {
      this.dialogRequestRampService = false;
    },
    newAircraftGround() {
      this.dialogAircraftGround = true;
    },
    newAircraftGroundReject() {
      this.dialogAircraftGround = false;
    },
    newAircraftGroundConfirm() {
      this.$store.dispatch(FLIGHT_TOWING_ADHOC_TASK_FIND_FLIGHTS);
      this.dialogAircraftGround = false;
    },
  },
  mounted() {
    this.$store.dispatch(GET_RAMP_SERVICE);
    this.$store.dispatch(GET_AIRPORT_RAMP_SERVICE_BY_SERVICE_AGENT_OF_USER);
  },
};
</script>
