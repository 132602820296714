<template>
  <v-card-text>
    <v-container fluid style="margin-bottom: 0; padding-bottom: 0">
      <v-layout align-center justify-space-between row style="margin-bottom: 0; padding-bottom: 0">
        <v-flex xs6 style="margin-right: 2ch; max-width: 15%;" >
          <airline-select :label="this.$i18n.t('common.airline')" :multiple=false :disabled="!hasPermissions(rolePermission)" v-model="item.airlineId"/>
        </v-flex>

        <v-flex xs3 style="margin-right: 2ch">
          <label>{{ this.$i18n.t('configuration.startCheckIn') }}</label>
          <v-text-field :label="` ${this.$i18n.t('configuration.minutesBeforeSelectedHour')}`" type="number" v-model="item.startTime" :readonly="!hasPermissions(rolePermission)"/>
        </v-flex>

        <v-flex xs3 style="margin-right: 2ch">
          <label>{{ this.$i18n.t('configuration.endCheckIn') }}</label>
          <v-text-field :label="`${this.$i18n.t('configuration.minutesBeforeSelectedHour')}`" type="number" v-model="item.endTime" :readonly="!hasPermissions(rolePermission)"/>
        </v-flex>
        <v-flex xs3>
          <v-radio-group class="radio-group" row v-model="item.timeType">
            <v-radio :label="this.$i18n.t('configuration.scheduledTime')" color="primary" :value="timeTypes.ST"></v-radio>
            <v-radio :label="this.$i18n.t('configuration.estimatedTime')" color="primary" :value="timeTypes.ET"></v-radio>
          </v-radio-group>
        </v-flex>
      </v-layout>
    </v-container>
  </v-card-text>
</template>
<script>
import RolePermissions from '../../mixins/RolePermissions.vue';
import AirlineSelect from '../../airline/AirlineSelect.vue';
import { AirportDefaultCheckInTimeTypes } from './AirportDefaultCheckInTimeTypes';

export default {
  name: 'AirportDefaultCheckInTimeEditCardContent',
  mixins: [RolePermissions],
  props: {
    item: { type: Object, required: true },
  },
  components: {
    AirlineSelect,
  },
  computed: {
    rolePermission() {
      return `${this.$route.meta.role}_WR`;
    },
  },
  data() {
    return {
      timeTypes: AirportDefaultCheckInTimeTypes,
    };
  },
  mounted() {
    if (!this.item.timeType) {
      this.item.timeType = this.timeTypes.ST;
    }
  },
};
</script>
