<template>
  <v-container>
    <v-layout row wrap>
      <v-flex sm4 xs12 class="date-input">
        <v-flex xs8 class="date-input">
          <date-input
            :ref="'refComp'"
            :label="this.$i18n.t('common.flightDate')"
            :locale="$i18n.locale"
            :disabled="disabledDate"
            v-on:keydown.native="keyEvent1"
            v-model="date"
            @input="changeDate"
          />
        </v-flex>
        <v-flex xs2 v-if="isFilter() && !disabledDate" class="date-input">
          <v-btn v-on:click="cleanDate" small flat>
            <v-icon>clear</v-icon>
          </v-btn>
        </v-flex>
      </v-flex>
      <v-flex sm2 xs12 :class="{'ml-0': $vuetify.breakpoint.smAndDown, 'ml-5': $vuetify.breakpoint.smAndUp}">
        <v-tooltip bottom>
          <v-checkbox :label="$t('commonForm.onlyNextFlights')" color="primary" v-model="checkbox" slot="activator"/>
          <span>{{$t("commonForm.onlyNextFlightsTooltip")}}</span>
        </v-tooltip>
      </v-flex>
      <v-flex sm4 xs12 class="d-flex align-center">
        <flight-selector :items="flights" v-model="flight" :rules="rules" @change="setFlight" />
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import FlightSelector from './FlightSelector.vue';
import DateInput from '../DateInput.vue';
import { FIND_FLIGHTS } from '../../../store/modules/flight';

export default {
  name: 'flight-selector-container',
  components: {
    FlightSelector,
    DateInput,
  },
  props: {
    dateOriginal: {},
    bound: {},
    rules: { type: Array },
    flightOriginal: {},
    disabledDate: { type: Boolean, default: false },
    checkNextFlightsActive: { type: Boolean, default: false },
    airlineFilter: {},
  },
  data: () => ({
    flightSelected: null,
    date: null,
    flight: null,
    checkbox: false,
  }),
  computed: {
    flights() {
      return this.$store.getters.getFlightsSorted;
    },
    filter() {
      return this.$store.state.flight.filter;
    },
  },
  watch: {
    checkbox: {
      handler() {
        this.changeDate();
      },
    },
    airlineFilter: {
      handler() {
        this.changeAirline();
      },
    },
  },
  methods: {
    changeDate() {
      const from = this.$moment(this.date);
      const to = this.$moment(this.date);
      this.filter.dateFrom = this.checkbox ? this.$moment() : from.startOf('day');
      this.filter.dateTo = to.endOf('day');
      this.filterChanged();
      this.$emit('clearFlight');
    },
    changeAirline() {
      this.filter.airlineId = this.airlineFilter;
      this.filterChanged();
      if (this.flightSelected && this.airlineFilter && this.flightSelected.airlineId !== this.airlineFilter) {
        this.flight = null;
        this.$emit('clearFlight');
      }
    },
    filterChanged() {
      this.find();
    },
    find() {
      this.$store.dispatch(FIND_FLIGHTS);
    },
    isFilter() {
      return this.date != null;
    },
    cleanDate() {
      this.date = null;
      this.filter.dateFrom = null;
      this.filter.dateTo = null;
      this.find();
    },
    setFlight(flight) {
      if (typeof (flight) !== 'undefined') {
        this.flightSelected = flight;
        this.$emit('flightSelected', this.flightSelected);
      } else {
        this.$emit('clearFlight');
      }
    },
    initFilter() {
      const from = this.$moment(this.date);
      const to = this.$moment(this.date);
      this.filter.dateFrom = from.startOf('day');
      this.filter.dateTo = to.endOf('day');
      this.filter.bound = this.bound;
      this.filter.airlineId = this.airlineFilter;
    },
  },
  created() {
    this.date = this.dateOriginal != null ? this.dateOriginal : this.$moment();
    this.flight = this.flightOriginal;
    this.initFilter();
    this.find();
  },
  mounted() {
    this.checkbox = this.checkNextFlightsActive;
  },
};
</script>
<style scoped>
  .date-input {
    display: inline-block !important;
    margin: auto 0;
  }
  .date-input .v-btn {
    min-width: 44px;
  }
</style>
