<template>
  <div class="gantt-flights-toolbar list-filter">
    <v-card :dark="this.$vuetify.dark" :color="this.$vuetify.dark ? 'bggrey_dark' : 'bggrey'">
      <v-toolbar dark dense color="primary lighten-1">
        <v-btn v-on:click="displayedFilter" small flat>
          <v-icon>filter_list</v-icon>
          <v-icon v-if="!showFilter">keyboard_arrow_down</v-icon>
          <v-icon v-if="showFilter">keyboard_arrow_up</v-icon>
        </v-btn>

        <v-divider vertical light style="margin-left: 30px; margin-right: 30px"></v-divider>

        <GanttFlightsToolbarDateSelector />

        <v-spacer></v-spacer>

        <span>Flights: {{ flightsCount }}</span>
      </v-toolbar>
      <v-container fluid v-if="showFilter" class="pa-2 pb-2">
        <v-layout class="filter-inline" align-center justify-start>
          <v-flex class="mr-2" align-center justify-start>
            <TimePicker v-model="filter.dateFrom" :referenceDate="filter.dateFrom"
              :label="this.$i18n.t('common.fromDate')" @change="changeDateFrom"></TimePicker>
          </v-flex>
          <v-flex class="mr-2" align-center justify-start>
            <TimePicker class="ml-2" v-model="filter.dateTo" :referenceDate="filter.dateTo"
              :label="this.$i18n.t('common.toDate')" @change="changeDateTo"></TimePicker>
          </v-flex>
          <v-flex class="mr-2" align-center justify-start>
            <airline-select :label="this.$i18n.t('common.airlineOp')" style="min-width: 300px" v-model="filter.airlineAndOperator"
              @input="filterChanged"></airline-select>
          </v-flex>

          <v-flex class="mr-2" align-center justify-start>
            <v-tooltip top>
              <v-text-field slot="activator" type="number" v-char-length="15" hide-details
                v-model="options.unlinkedFlightsDurationMinutes" :label="this.$i18n.t('gantt.unlinkedDuration')" min="1"
                @change="filterChanged" :rules="[v => !!v || this.$i18n.t('common.fieldRequired')]"></v-text-field>
              <span>{{ $t("gantt.fakeDuration") }}</span>
            </v-tooltip>
          </v-flex>

          <v-spacer style="width: 45%"></v-spacer>
          <v-flex class="ml-2 text-no-wrap" align-center justify-start>
            <v-btn color="primary" round v-on:click.stop="find" block>
              <v-icon>search</v-icon>
              {{ $t("gantt.findAndDraw") }}
            </v-btn>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card>
  </div>

</template>
<script>
import AirlineSelect from '../airline/AirlineSelect.vue';
import DateInput from '../ui/DateInput.vue';
import GanttFlightsToolbarDateSelector from './GanttFlightsToolbarDateSelector.vue';
import TimeInput from '../ui/TimeInput.vue';
import TimePicker from '../ui/TimePicker.vue';
import {
  RAMP_GANTT_FILTER_INIT,
  RAMP_GANTT_FIND_FLIGHTS,
} from '../../store/modules/ramp/rampGantt';

export default {
  name: 'GanttFlightsToolbar',
  components: {
    TimePicker,
    TimeInput,
    GanttFlightsToolbarDateSelector,
    AirlineSelect,
    DateInput,
  },
  data() {
    return {
      showFilter: true,
    };
  },
  computed: {
    filter() {
      return this.$store.state.rampGantt.filter;
    },
    options() {
      return this.$store.state.rampGantt.options;
    },
    flightsCount() {
      return this.$store.state.rampGantt.flights.length;
    },
  },
  methods: {
    filterChanged() {
      // this.find();
    },
    find() {
      this.$store.dispatch(RAMP_GANTT_FIND_FLIGHTS);
    },
    changeDateFrom() {
      this.filterChanged();
    },
    changeDateTo() {
      this.filterChanged();
    },
    displayedFilter() {
      this.showFilter = !this.showFilter;
      this.$emit('changeShowFilter', this.showFilter);
    },
  },
  created() {
    this.$store.commit(RAMP_GANTT_FILTER_INIT);
  },
  mounted() {
    this.find();
  },
};
</script>
