<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <entity-configuration-list :entity-name="this.$i18n.t('configuration.ticketingNumeration')" entity-icon="style"
    :framework-components="frameworkComponents" :column-defs="columnDefs" :items="ticketingNumeration"
    @confirm-edit-item="editConfirm" @confirm-delete-item="deleteConfirm">
    <template v-slot:modal-content="{ itemEdit }">
      <ticketing-numeration-edit-card-content :item="itemEdit" />
    </template>
  </entity-configuration-list>
</template>
<script>
/* eslint no-param-reassign: "off" */
import EntityConfigurationList from '../common/EntityConfigurationList.vue';
import TicketingNumerationEditCardContent from './TicketingNumerationEditCardContent.vue';
import {
  TICKETING_NUMERATION_GET,
  TICKETING_NUMERATION_CREATE,
  TICKETING_NUMERATION_UPDATE,
  TICKETING_NUMERATION_DELETE,
} from '../../../store/modules/ticketingNumerationCatalog';
import AgGridIconCellRenderer from '../../ag-grid/cell-renderers/AgGridIconCellRenderer.vue';
import AgGridIconTooltipOperationCellRenderer from '../../ag-grid/cell-renderers/AgGridIconTooltipOperationCellRenderer.vue';

export default {
  name: 'ticketing-numeration-list',
  components: {
    EntityConfigurationList,
    TicketingNumerationEditCardContent,
  },
  data() {
    const that = this;
    return {
      frameworkComponents: {
        AgGridIconCellRenderer,
      },
      columnDefs: [
        {
          field: 'airlineCode',
          headerValueGetter() {
            return that.$i18n.t('common.airline');
          },
        },
        {
          field: 'format',
          headerValueGetter() {
            return that.$i18n.t('ticketingNumeration.format');
          },
        },
        {
          field: 'nextFormatValue',
          headerValueGetter() {
            return that.$i18n.t('ticketingNumeration.nextFormatValue');
          },
        },
        {
          field: 'initValue',
          headerValueGetter() {
            return that.$i18n.t('ticketingNumeration.initValue');
          },
        },
        {
          field: 'incrementValue',
          headerValueGetter() {
            return that.$i18n.t('ticketingNumeration.incrementValue');
          },
        },
        {
          field: 'initDate',
          headerValueGetter() {
            return that.$i18n.t('ticketingNumeration.initDate');
          },
          valueFormatter(params) {
            return params.value
              ? that.$moment(params.value).format('DD/MM/YYYY')
              : null;
          },
        },
        {
          field: 'endDate',
          headerValueGetter() {
            return that.$i18n.t('ticketingNumeration.endDate');
          },
          valueFormatter(params) {
            return params.value
              ? that.$moment(params.value).format('DD/MM/YYYY')
              : null;
          },
        },
        {
          headerValueGetter() {
            return that.$i18n.t('airlineFares.activeFare');
          },
          valueGetter(params) {
            let result = false;
            if (params.data.endDate && params.data.endDate != null) {
              result = that.$moment().startOf('day').isBetween(params.data.initDate, params.data.endDate, undefined, '[]');
            } else {
              result = that.$moment().isAfter(params.data.initDate);
            }
            return {
              value: result ? 'done' : 'close',
              color: result ? 'green' : 'red',
              icon: result ? 'done' : 'close',
            };
          },
          cellRendererFramework: AgGridIconTooltipOperationCellRenderer,
          width: 70,
        },
      ],
    };
  },
  computed: {
    ticketingNumeration() {
      return this.$store.getters.getTicketingNumeration;
    },
  },
  methods: {
    getTicketingNumeration() {
      this.$store.dispatch(TICKETING_NUMERATION_GET);
    },
    deleteConfirm(item) {
      this.$store.dispatch(TICKETING_NUMERATION_DELETE, item.id);
    },
    editConfirm(item) {
      this.$store.dispatch(
        item.id
          ? TICKETING_NUMERATION_UPDATE
          : TICKETING_NUMERATION_CREATE,
        item,
      );
    },
  },
  created() {
    this.getTicketingNumeration();
  },
};
</script>
