<template>
  <div>
    <ag-grid-vue
      class="ag-theme-material connected-flights-table operational-table"
      :columnDefs="columnDefs"
      :defaultColDef="defaultColDef"
      :rowData="items"
      :rowSelection="'multiple'"
      :suppressRowClickSelection="true"
      :rowMultiSelectWithClick="false"
      @grid-ready="onGridReady"
      @first-data-rendered="firstDataRendered"
      @selection-changed="onSelectionChanged"
      :suppressHorizontalScroll="true"
      :frameworkComponents="frameworkComponents"
      :gridOptions="gridOptions"
      :multiSortKey="multiSortKey"
      :overlayNoRowsTemplate="customNoRowsOverlay"
      :overlayLoadingTemplate="customLoadingOverlay"
      v-bind:style="`width: 100%; height: calc(100vh - ${this.sizeFilter});`">
    </ag-grid-vue>
  </div>
</template>
<script>
import { AgGridVue } from 'ag-grid-vue';
import AgGridValueBaseCellRenderer from '../../ag-grid/cell-renderers/AgGridValueBaseCellRenderer.vue';
import AgGridTimeCellRenderer from '../../ag-grid/cell-renderers/AgGridTimeCellRenderer.vue';
import AgGridConnectedFlights from '../../ag-grid/mixins/AgGridConnectedFlights.vue';
import FlightsDifferences from '../../mixins/FlightsDifferences.vue';

import { FlightBound } from '../../shared/model/flightBound';
import { cellClassAgGridRules } from '../../ag-grid/mixins/cellClassAgGridRules';
import { WEBSOCKET_CONNECTION } from '../../../store/modules/websocket';
import AgGridValueWithAsteriskCellRenderer
from '../../ag-grid/cell-renderers/AgGridValueWithAsteriskCellRenderer.vue';
import { FisModule } from '../../ag-grid/cell-editors/model/fisModule';
import agGridTextCustomFilterComparator from '../../ag-grid/filters/agGridTextCustomFilterComparator';
import agGridTextCustomFilterFormatter from '../../ag-grid/filters/agGridTextCustomFilterFormatter';
import {
  LOST_AND_FOUND_CHANGE_FLIGHT_SELECTION,
  LOST_AND_FOUND_FIT_COLUMNS_TO_VIEW,
  LOST_AND_FOUND_ON_EXTERNAL_CHANGE,
  LOST_AND_FOUND_SET_CHANGE,
  LOST_AND_FOUND_SET_GRID_API,
} from '../../../store/modules/lostAndFound/lostAndFound';
import AgGridValueAndSupCellRenderer from '../../ag-grid/cell-renderers/AgGridValueAndSupCellRenderer.vue';
import AgGridIconTooltipOperationCellRenderer
from '../../ag-grid/cell-renderers/AgGridIconTooltipOperationCellRenderer.vue';
import AgGridConnectedFlightsDialogMessageCellEditor
from '../../ag-grid/cell-editors/connectedFlights/dialog/AgGridConnectedFlightsDialogMessageCellEditor.vue';
import AgGridConnectedFlightsDialogRushBaggageCellEditor
from '../../ag-grid/cell-editors/connectedFlights/dialog/AgGridConnectedFlightsDialogRushBaggageCellEditor.vue';
import AgGridConnectedFlightsDialogDeliveryBaggageCellEditor
from '../../ag-grid/cell-editors/connectedFlights/dialog/AgGridConnectedFlightsDialogDeliveryBaggageCellEditor.vue';
import AgGridFlightPassageUmCellEditor
from '../../ag-grid/cell-editors/flightDeliveryBaggage/AgGridFlightPassageUmCellEditor.vue';
import AgGridCustomService from '../../shared/services/agGridCustomService';
import RolePermissions from '../../mixins/RolePermissions.vue';
import AgGridConnectedFlightsDialogFlightStatusIntegrationCellEditor from '../../ag-grid/cell-editors/connectedFlights/dialog/AgGridConnectedFlightsDialogFlightStatusIntegrationCellEditor.vue';
import AgGridFlightStatusCellRenderer from '../../ag-grid/cell-renderers/AgGridFlightStatusCellRenderer.vue';
import AgGridValueAndSupFieldPossibleBlockedCellRenderer from '../../ag-grid/cell-renderers/AgGridValueAndSupFieldPossibleBlockedCellRenderer.vue';
import AgGridTimeFieldPossibleBlockedCellRenderer from '../../ag-grid/cell-renderers/AgGridTimeFieldPossibleBlockedCellRenderer.vue';
import AgGridNoRowsOverlay from '../../ag-grid/mixins/AgGridNoRowsOverlay.vue';

export default {
  name: 'lost-and-found-flight-list-table',
  mixins: [AgGridConnectedFlights, FlightsDifferences, RolePermissions, AgGridNoRowsOverlay],
  components: {
    AgGridVue,
  },
  props: {
    items: { type: Array, required: true },
    isFilterDisplayed: { type: Boolean, required: false, default: false },
  },
  mounted() {
    this.$store.dispatch(WEBSOCKET_CONNECTION, this.$store.state.tenant.currentTenant.name.toLowerCase());
  },
  computed: {
    sizeFilter() {
      return this.isFilterDisplayed ? '250px' : '180px';
    },
    fitColumnsOnResize() {
      return this.$store.state.lostAndFound.fitColumnsOnResize;
    },
    dateFilter() {
      return this.$store.state.lostAndFound.filter.dateFrom;
    },
  },
  data() {
    const that = this;
    const rolePermission = this.getRoles();
    return {
      dialog: false,
      scheduledTimeRequired: true,
      gridOptions: {
        onFilterChanged: this.uncheckAllOnFilter,
        getRowNodeId: this.getRowNodeId,
        getRowClass: this.getRowClass,
        floatingFilter: true,
        groupHeaderHeight: 35,
        headerHeight: 35,
        floatingFiltersHeight: 20,
        rowHeight: 30,
        context: {
          module: FisModule.LOST_AND_FOUND,
        },
      },
      multiSortKey: 'ctrl',
      frameworkComponents: {
        AgGridValueBaseCellRenderer,
        AgGridTimeCellRenderer,
        AgGridValueWithAsteriskCellRenderer,
        AgGridValueAndSupCellRenderer,
        AgGridIconTooltipOperationCellRenderer,
        AgGridConnectedFlightsDialogMessageCellEditor,
        AgGridConnectedFlightsDialogRushBaggageCellEditor,
        AgGridConnectedFlightsDialogDeliveryBaggageCellEditor,
        AgGridFlightPassageUmCellEditor,
        AgGridConnectedFlightsDialogFlightStatusIntegrationCellEditor,
      },
      defaultColDef: {
        resizable: true,
        sortable: true,
        minWidth: 30,
        suppressMenu: true,
        filter: 'agTextColumnFilter',
        filterParams: {
          textCustomComparator: agGridTextCustomFilterComparator,
          textFormatter: agGridTextCustomFilterFormatter,
        },
        colCustomConfig: {
          flightBound: FlightBound.ANY,
        },
        cellClassRules: cellClassAgGridRules,
        editable: false,
      },
      columnDefs: [
        {
          headerName: '',
          children: [
            {
              headerTooltip: 'Check/uncheck all',
              hide: !this.hasPermissions(rolePermission),
              checkboxSelection: true,
              width: 21,
              minWidth: 20,
              headerCheckboxSelection: true,
              headerCheckboxSelectionFilteredOnly: true,
              suppressMenu: true,
              sortable: false,
              suppressMovable: true,
              suppressSizeToFit: true,
              filter: false,
              resizable: false,
              colCustomConfig: {
                exportCsvDisabled: true,
              },
              editable: this.editable(true, rolePermission),
            },
            {
              headerName: 'SortDate',
              field: 'sortingTime',
              hide: true,
              sort: 'asc',
            },
          ],
        },
        {
          headerName: '',
          children: [
            {
              colId: 'anybound.operation.operationDesk',
              headerValueGetter() {
                return that.$i18n.t('lostAndFound.desk');
              },
              width: 50,
              cellRendererFramework: AgGridValueBaseCellRenderer,
              cellClass: 'aircraft-type-cell',
              comparator: this.objectWithValueComparator,
              colCustomConfig: {
                flightBound: FlightBound.ANY,
                fieldsRelated: ['inbound.operation.operationDesk', 'outbound.operation.operationDesk'],
              },
              valueGetter(params) {
                return params.data.getChildPropertyValue(FlightBound.ANY, ['operation', 'operationDesk']);
              },
            },
          ],
        },
        {
          headerValueGetter() {
            return that.$i18n.t('common.aircraftInformation');
          },
          children: [
            {
              colId: 'anybound.common.aircraftRegisterNumber',
              headerValueGetter() {
                return that.$i18n.t('common.aircraftRegistration');
              },
              width: 60,
              cellClass: 'aircraft-cell',
              colCustomConfig: {
                flightBound: FlightBound.ANY,
                fieldsRelated: ['inbound.common.aircraftRegisterNumber', 'outbound.common.aircraftRegisterNumber',
                  'inbound.common.aircraftAcars', 'outbound.common.aircraftAcars',
                  'inbound.blockedFieldsIntegration.manualOverwriteAircraft', 'outbound.blockedFieldsIntegration.manualOverwriteAircraft'],
              },
              valueGetter(params) {
                return {
                  value: params.data.getChildPropertyValue(FlightBound.ANY, ['common', 'aircraftRegisterNumber']),
                  sup: params.data.getChildPropertyValue(FlightBound.ANY, ['common', 'aircraftAcars']) ? 'A' : null,
                  fieldBlockedIntegration: params.data.getChildPropertyValue(FlightBound.ANY, ['blockedFieldsIntegration', 'manualOverwriteAircraft']),
                };
              },
              cellRendererFramework: AgGridValueAndSupFieldPossibleBlockedCellRenderer,
            },
          ],
        },
        {
          headerValueGetter() {
            return that.$i18n.t('common.arrivalFlight');
          },
          children: [
            {
              colId: 'inbound.common.flightNo',
              headerValueGetter() {
                return that.$i18n.t('common.flight');
              },
              width: 60,
              cellClass: 'flight-number-cell separator',
              colCustomConfig: {
                flightBound: FlightBound.INBOUND,
                fieldsRelated: ['inbound.common.flightNumber', 'inbound.common.flagAirlineOperation'],
              },
              valueGetter(params) {
                return {
                  value: params.data.getChildPropertyValue(FlightBound.INBOUND, ['common', 'flightNumber']),
                  asterisk: params.data.getChildPropertyValue(FlightBound.INBOUND, ['common', 'flagAirlineOperation']),
                  tooltip: params.data.getChildPropertyValue(FlightBound.INBOUND, ['common', 'airlineOperationMessage']),
                };
              },
              cellRendererFramework: AgGridValueWithAsteriskCellRenderer,
            },
            {
              colId: 'inbound.common.airlineOperationIataCode',
              headerValueGetter() {
                return that.$i18n.t('common.airlineOperation');
              },
              width: 40,
              cellClass: 'airline-cell separator',
              colCustomConfig: {
                flightBound: FlightBound.INBOUND,
                fieldsRelated: ['inbound.common.airlineOperationCode'],
              },
              valueGetter(params) {
                return params.data.getChildPropertyValue(FlightBound.INBOUND, ['common', 'airlineOperationCode']);
              },
              cellRendererFramework: AgGridValueBaseCellRenderer,
            },
            {
              colId: 'inbound.common.airportsIata',
              headerValueGetter() {
                return that.$i18n.t('common.from');
              },
              width: 100,
              cellClass: 'airport-from-cell',
              colCustomConfig: {
                flightBound: FlightBound.INBOUND,
                fieldsRelated: ['inbound.common.airportsIata'],
              },
              valueGetter(params) {
                return params.data.getChildPropertyValue(FlightBound.INBOUND, ['common', 'airportsIata']);
              },
              cellRendererFramework: AgGridValueBaseCellRenderer,
            },
            {
              colId: 'inbound.common.scheduledTime',
              headerValueGetter() {
                return that.$i18n.t('common.scheduledTimeArrival');
              },
              width: 50,
              cellClass: 'scheduled-time-cell',
              comparator: this.objectWithValueComparator,
              colCustomConfig: {
                flightBound: FlightBound.INBOUND,
                fieldsRelated: ['inbound.common.scheduledTime'],
              },
              valueGetter(params) {
                return that.agGridTimeGetter(params.data.getChildPropertyValue(FlightBound.INBOUND, ['common', 'scheduledTime']), that.dateFilter, null, null, that.scheduledTimeRequired);
              },
              cellRendererFramework: AgGridTimeCellRenderer,
            },
            {
              colId: 'inbound.times.estimatedTime',
              headerValueGetter() {
                return that.$i18n.t('flightInformation.estimatedTimeArrival');
              },
              width: 50,
              cellClass: 'scheduled-time-cell',
              comparator: this.objectWithValueComparator,
              colCustomConfig: {
                flightBound: FlightBound.INBOUND,
                fieldsRelated: ['inbound.times.estimatedTime', 'inbound.blockedFieldsIntegration.manualOverwriteEstimatedTime'],
              },
              valueGetter(params) {
                const value = that.agGridTimeGetter(params.data.getChildPropertyValue(FlightBound.INBOUND, ['times', 'estimatedTime']), params.data.getChildPropertyValue(FlightBound.INBOUND, ['common', 'scheduledDate']));
                value.fieldBlockedIntegration = params.data.getChildPropertyValue(FlightBound.INBOUND, ['blockedFieldsIntegration', 'manualOverwriteEstimatedTime']);
                return value;
              },
              cellRendererFramework: AgGridTimeFieldPossibleBlockedCellRenderer,
            },
            {
              colId: 'inbound.times.blockTime',
              headerValueGetter() {
                return that.$i18n.t('flightInformation.onBlockTime');
              },
              width: 50,
              cellClass: 'scheduled-time-cell',
              comparator: this.objectWithValueComparator,
              colCustomConfig: {
                flightBound: FlightBound.INBOUND,
                fieldsRelated: ['inbound.times.blockTime', 'inbound.blockedFieldsIntegration.manualOverwriteBlockTime'],
              },
              valueGetter(params) {
                const value = that.agGridTimeGetter(params.data.getChildPropertyValue(FlightBound.INBOUND, ['times', 'blockTime']), params.data.getChildPropertyValue(FlightBound.INBOUND, ['common', 'scheduledDate']));
                value.fieldBlockedIntegration = params.data.getChildPropertyValue(FlightBound.INBOUND, ['blockedFieldsIntegration', 'manualOverwriteBlockTime']);
                return value;
              },
              cellRendererFramework: AgGridTimeFieldPossibleBlockedCellRenderer,
            },
            {
              colId: 'inbound.integration.flightStatusIntegration',
              headerValueGetter() {
                return that.getTextResizable(that.$i18n.t('operations.airportStatusShort'), that.$i18n.t('operations.airportStatus'));
              },
              headerTooltip: (() => this.$i18n.t('operations.airportStatus'))(),
              width: 48,
              minWidth: 39,
              editable: this.isInboundAndNotAircraftGround,
              cellEditor: 'AgGridConnectedFlightsDialogFlightStatusIntegrationCellEditor',
              comparator: this.objectWithValueComparator,
              cellClass: 'airport-status-cell',
              colCustomConfig: {
                flightBound: FlightBound.INBOUND,
                fieldsRelated: ['inbound.integration.flightStatusIntegration'],
              },
              flight: FlightBound.INBOUND,
              valueGetter(params) {
                return {
                  value: params.data.getChildPropertyValue(FlightBound.INBOUND, ['integration', 'statusIntegration']),
                  tooltip: params.data.getChildPropertyValue(FlightBound.INBOUND, ['integration', 'statusIntegration']) != null ? that.$i18n.t(`flightStatusIntegration.${params.data.getChildPropertyValue(FlightBound.INBOUND, ['integration', 'statusIntegration'])}`) : null,
                };
              },
              cellRendererFramework: AgGridFlightStatusCellRenderer,
            },
            {
              colId: 'inbound.passageSummary.pax',
              headerValueGetter() {
                return that.$i18n.t('lostAndFound.paxIn');
              },
              width: 50,
              cellClass: 'airport-pax-cell',
              comparator: this.objectWithValueComparator,
              colCustomConfig: {
                flightBound: FlightBound.INBOUND,
                fieldsRelated: ['inbound.passageSummary.passengersNumber', 'inbound.passageSummary.passengersChildNumber', 'inbound.passageSummary.passengersSpecial'],
              },
              flight: FlightBound.INBOUND,
              valueGetter(params) {
                const isRealNumber = params.data.getChildPropertyValue(FlightBound.INBOUND, ['passageSummary', 'passengersNumber']) > 0 || params.data.getChildPropertyValue(FlightBound.INBOUND, ['passageSummary', 'passengersChildNumber']) > 0;
                let text = params.data.getChildPropertyValue(FlightBound.INBOUND, ['passageSummary', 'passengersNumber']) == null ? '' : params.data.getChildPropertyValue(FlightBound.INBOUND, ['passageSummary', 'passengersNumber']);
                let tooltip = '';
                let styleContent = {};
                text += params.data.getChildPropertyValue(FlightBound.INBOUND, ['passageSummary', 'passengersChildNumber']) == null ? '' : `+${params.data.getChildPropertyValue(FlightBound.INBOUND, ['passageSummary', 'passengersChildNumber'])}`;
                if ((params.data.getChildPropertyValue(FlightBound.INBOUND, ['passageSummary', 'passengersEstimated']) > 0) && !isRealNumber) {
                  text = params.data.getChildPropertyValue(FlightBound.INBOUND, ['passageSummary', 'passengersEstimated']);
                  styleContent = { color: 'darkslategray', 'font-weight': 'bold', 'font-style': 'italic' };
                }
                // when handling type is P, the total of passengers must be (P)
                if (params.data.getChildPropertyValue(FlightBound.INBOUND, ['common', 'handlingTypeCode']) === 'P') {
                  text = '(P)';
                  tooltip = that.$i18n.t('operations.emptyFlight');
                }
                return {
                  value: text,
                  asterisk: params.data.getChildPropertyValue(FlightBound.INBOUND, ['passageSummary', 'passengersSpecial']),
                  styleObject: {
                    asteriskColor: 'red',
                    content: styleContent,
                  },
                  tooltip,
                };
              },
              cellRendererFramework: AgGridValueWithAsteriskCellRenderer,
            },
            {
              colId: 'inbound.passageSummary.paxUMNR',
              headerValueGetter() {
                return that.$i18n.t('lostAndFound.umsIn');
              },
              width: 50,
              editable: this.editable(true, rolePermission),
              cellEditor: 'AgGridFlightPassageUmCellEditor',
              cellClass: 'airport-pax-cell',
              comparator: this.objectWithValueComparator,
              colCustomConfig: {
                flightBound: FlightBound.INBOUND,
                fieldsRelated: ['inbound.passageSummary.paxUMNR'],
              },
              flight: FlightBound.INBOUND,
              valueGetter(params) {
                return params.data.getChildPropertyValue(FlightBound.INBOUND, ['passageSummary', 'paxUMNR']);
              },
              cellRendererFramework: AgGridValueBaseCellRenderer,
            },
            {
              colId: 'inbound.rampExtended.deliveryBaggage',
              headerValueGetter() {
                return that.$i18n.t('lostAndFound.deliveries');
              },
              width: 40,
              cellClass: 'deliveries-cell',
              editable: this.isInbound,
              cellEditor: 'AgGridConnectedFlightsDialogDeliveryBaggageCellEditor',
              colCustomConfig: {
                flightBound: FlightBound.INBOUND,
                fieldsRelated: ['inbound.rampExtended.deliveryBaggage'],
              },
              valueGetter(params) {
                return params.data.getChildPropertyValue(FlightBound.INBOUND, ['rampExtended', 'deliveryBaggage']);
              },
              valueFormatter(params) {
                return params.value == null || params.value === 0 ? '-' : params.value;
              },
              cellRendererFramework: AgGridValueBaseCellRenderer,
            },
            {
              colId: 'inbound.common.belt',
              headerValueGetter() {
                return that.$i18n.t('lostAndFound.belt');
              },
              width: 50,
              cellClass: 'aircraft-config-cell',
              comparator: this.objectWithValueComparator,
              colCustomConfig: {
                flightBound: FlightBound.INBOUND,
                fieldsRelated: ['inbound.common.beltId', 'inbound.common.beltCode', 'inbound.blockedFieldsIntegration.manualOverwriteBelt'],
              },
              valueGetter(params) {
                return {
                  value: params.data.getChildPropertyValue(FlightBound.INBOUND, ['common', 'beltCode']),
                  fieldBlockedIntegration: params.data.getChildPropertyValue(FlightBound.INBOUND, ['blockedFieldsIntegration', 'manualOverwriteBelt']),
                };
              },
              cellRendererFramework: AgGridValueAndSupFieldPossibleBlockedCellRenderer,
            },
            {
              colId: 'inbound.extended.msg',
              headerValueGetter() {
                return that.$i18n.t('lostAndFound.messages');
              },
              width: 50,
              editable: this.isInbound,
              cellEditor: 'AgGridConnectedFlightsDialogMessageCellEditor',
              cellClass: 'ag-grid-popover-cell',
              comparator: this.objectWithValueComparator,
              colCustomConfig: {
                flightBound: FlightBound.INBOUND,
                fieldsRelated: ['inbound.extended.messageStatus'],
              },
              flight: FlightBound.INBOUND,
              valueGetter(params) {
                const messageValue = params.data.getChildPropertyValue(FlightBound.INBOUND, ['extended', 'messageStatus']);
                return that.checkBoundAndSetMsgTooltipObject(messageValue);
              },
              cellRendererFramework: AgGridIconTooltipOperationCellRenderer,
            },
          ],
        },
        {
          headerValueGetter() {
            return that.$i18n.t('common.departureFlight');
          },
          children: [
            {
              colId: 'outbound.common.flightNo',
              headerValueGetter() {
                return that.$i18n.t('common.flight');
              },
              width: 60,
              cellClass: 'flight-number-cell separator',
              colCustomConfig: {
                flightBound: FlightBound.OUTBOUND,
                fieldsRelated: ['outbound.common.flightNumber', 'outbound.common.flagAirlineOperation'],
              },
              valueGetter(params) {
                return {
                  value: params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'flightNumber']),
                  asterisk: params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'flagAirlineOperation']),
                  tooltip: params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'airlineOperationMessage']),
                };
              },
              cellRendererFramework: AgGridValueWithAsteriskCellRenderer,
            },
            {
              colId: 'outbound.common.airlineOperationIataCode',
              headerValueGetter() {
                return that.$i18n.t('common.airlineOperation');
              },
              width: 40,
              cellClass: 'airline-cell separator',
              colCustomConfig: {
                flightBound: FlightBound.OUTBOUND,
                fieldsRelated: ['outbound.common.airlineOperationCode'],
              },
              valueGetter(params) {
                return params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'airlineOperationCode']);
              },
              cellRendererFramework: AgGridValueBaseCellRenderer,
            },
            {
              colId: 'outbound.common.airportsIata',
              headerValueGetter() {
                return that.$i18n.t('common.to');
              },
              width: 100,
              cellClass: 'airport-from-cell',
              colCustomConfig: {
                flightBound: FlightBound.OUTBOUND,
                fieldsRelated: ['outbound.common.airportsIata'],
              },
              valueGetter(params) {
                return params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'airportsIata']);
              },
              cellRendererFramework: AgGridValueBaseCellRenderer,
            },
            {
              colId: 'outbound.common.scheduledTime',
              headerValueGetter() {
                return that.$i18n.t('common.scheduledTimeOfDeparture');
              },
              width: 50,
              cellClass: 'scheduled-time-cell',
              comparator: this.objectWithValueComparator,
              colCustomConfig: {
                flightBound: FlightBound.OUTBOUND,
                fieldsRelated: ['outbound.common.scheduledTime'],
              },
              valueGetter(params) {
                return that.agGridTimeGetter(params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'scheduledTime']), that.dateFilter, null, null, that.scheduledTimeRequired);
              },
              cellRendererFramework: AgGridTimeCellRenderer,
            },
            {
              colId: 'outbound.times.estimatedTime',
              headerValueGetter() {
                return that.$i18n.t('flightInformation.estimatedTimeDeparture');
              },
              width: 50,
              cellClass: 'scheduled-time-cell',
              comparator: this.objectWithValueComparator,
              colCustomConfig: {
                flightBound: FlightBound.OUTBOUND,
                fieldsRelated: ['outbound.times.estimatedTime', 'outbound.blockedFieldsIntegration.manualOverwriteEstimatedTime'],
              },
              valueGetter(params) {
                const value = that.agGridTimeGetter(params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['times', 'estimatedTime']), params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'scheduledDate']));
                value.fieldBlockedIntegration = params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['blockedFieldsIntegration', 'manualOverwriteEstimatedTime']);
                return value;
              },
              cellRendererFramework: AgGridTimeFieldPossibleBlockedCellRenderer,
            },
            {
              colId: 'outbound.times.blockTime',
              headerValueGetter() {
                return that.$i18n.t('flightInformation.ofBlockTime');
              },
              width: 50,
              cellClass: 'scheduled-time-cell',
              comparator: this.objectWithValueComparator,
              colCustomConfig: {
                flightBound: FlightBound.OUTBOUND,
                fieldsRelated: ['outbound.times.blockTime', 'outbound.blockedFieldsIntegration.manualOverwriteBlockTime'],
              },
              valueGetter(params) {
                const value = that.agGridTimeGetter(params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['times', 'blockTime']), params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'scheduledDate']));
                value.fieldBlockedIntegration = params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['blockedFieldsIntegration', 'manualOverwriteBlockTime']);
                return value;
              },
              cellRendererFramework: AgGridTimeFieldPossibleBlockedCellRenderer,
            },
            {
              colId: 'outbound.integration.flightStatusIntegration',
              headerValueGetter() {
                return that.getTextResizable(that.$i18n.t('operations.airportStatusShort'), that.$i18n.t('operations.airportStatus'));
              },
              headerTooltip: (() => this.$i18n.t('operations.airportStatus'))(),
              width: 48,
              minWidth: 39,
              editable: this.isOutboundAndNotAircraftGround,
              cellEditor: 'AgGridConnectedFlightsDialogFlightStatusIntegrationCellEditor',
              comparator: this.objectWithValueComparator,
              cellClass: 'airport-status-cell',
              colCustomConfig: {
                flightBound: FlightBound.OUTBOUND,
                fieldsRelated: ['outbound.integration.flightStatusIntegration'],
              },
              flight: FlightBound.OUTBOUND,
              valueGetter(params) {
                return {
                  value: params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['integration', 'statusIntegration']),
                  tooltip: params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['integration', 'statusIntegration']) != null ? that.$i18n.t(`flightStatusIntegration.${params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['integration', 'statusIntegration'])}`) : null,
                };
              },
              cellRendererFramework: AgGridFlightStatusCellRenderer,
            },
            {
              colId: 'outbound.extended.msg',
              headerValueGetter() {
                return that.$i18n.t('lostAndFound.messages');
              },
              width: 50,
              editable: this.isOutbound,
              cellEditor: 'AgGridConnectedFlightsDialogMessageCellEditor',
              cellClass: 'ag-grid-popover-cell',
              comparator: this.objectWithValueComparator,
              colCustomConfig: {
                flightBound: FlightBound.OUTBOUND,
                fieldsRelated: ['outbound.extended.messageStatus'],
              },
              flight: FlightBound.OUTBOUND,
              valueGetter(params) {
                const messageValue = params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['extended', 'messageStatus']);
                return that.checkBoundAndSetMsgTooltipObject(messageValue);
              },
              cellRendererFramework: AgGridIconTooltipOperationCellRenderer,
            },
            {
              colId: 'outbound.rampExtended.rushBaggage',
              headerValueGetter() {
                return that.$i18n.t('lostAndFound.rushes');
              },
              width: 40,
              cellClass: 'rushes-cell',
              editable: this.isOutbound,
              cellEditor: 'AgGridConnectedFlightsDialogRushBaggageCellEditor',
              colCustomConfig: {
                flightBound: FlightBound.OUTBOUND,
                fieldsRelated: ['outbound.rampExtended.rushBaggage'],
              },
              valueGetter(params) {
                return params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['rampExtended', 'rushBaggage']);
              },
              valueFormatter(params) {
                return params.value == null || params.value === 0 ? '-' : params.value;
              },
              cellRendererFramework: AgGridValueBaseCellRenderer,
            },
          ],
        },
      ],
    };
  },
  methods: {
    onGetChanges(changes) {
      const rowNode = this.gridApi.getRowNode(changes.payload.id);

      if (!rowNode) {
        return;
      }
      const oldConnectedFlight = rowNode.data.clone();
      const newConnectedFlight = changes.payload;
      this.assignInboundOrOutboundIfDontExist(newConnectedFlight, oldConnectedFlight);
      const differencies = this.getDifferenciesAndCreateOldValuesProperties(oldConnectedFlight, newConnectedFlight);

      this.setOldValuesDifferencesFromOldConnectedFlight(rowNode.data, newConnectedFlight);

      if (differencies && differencies.length > 0) {
        rowNode.setData(newConnectedFlight);
        if (this.$store.state.lostAndFound.flashOnChanges) {
          this.renderNeededDiffs(differencies, rowNode); // TODO only shows flash
        }
        differencies.forEach(dif => this.$store.commit(LOST_AND_FOUND_SET_CHANGE, { diff: dif, connectedFlights: newConnectedFlight }));
      }
    },
    onGridReady(params) {
      this.setAgGridApi(params);
      this.showLoading();
      this.$store.commit(LOST_AND_FOUND_SET_GRID_API, params); // TODO eliminar api del store

      this.refreshCellsOnMutation();
      this.refreshCellsOnChangeLanguage();
      const that = this;
      this.subscribeMutation(LOST_AND_FOUND_ON_EXTERNAL_CHANGE, (mutationPayload) => {
        that.onGetChanges(mutationPayload);
      });
    },
    resized() {
      if (this.gridApi) {
        this.gridApi.sizeColumnsToFit();
      }
    },
    onSelectionChanged(event) {
      const selectedNodes = event.api.getSelectedNodes();
      const selectedData = selectedNodes.map(node => node.data);
      this.$store.commit(LOST_AND_FOUND_CHANGE_FLIGHT_SELECTION, selectedData);
    },
    deleteItem(item) {
      console.log(`delete ${item}`); // eslint-disable-line no-console
    },
    editItem(item) {
      console.log(`edit ${item}`); // eslint-disable-line no-console
    },
    isInbound(params) {
      return params.data.hasInbound();
    },
    isOutbound(params) {
      return params.data.hasOutbound();
    },
    checkBoundAndSetMsgTooltipObject(messageValue) {
      if (messageValue === null || typeof (messageValue) === 'undefined') {
        return null;
      }
      if (messageValue === 'WARNING_LDM') {
        return {
          value: messageValue,
          icon: 'warning',
          color: 'red',
          tooltip: this.$t('messages.warningMessageLDM'),
        };
      }

      return {
        value: messageValue,
        icon: messageValue !== 'EMPTY' ? 'sms' : '',
        color: messageValue === 'UNREAD' ? 'success' : 'bggreydark',
        tooltip: messageValue === 'UNREAD' ? this.$t('messages.messagesUnread') : this.$t('messages.messagesRead'),
      };
    },
    renderNeededDiffs(differences, rowNode) {
      const { gridApi } = this.$store.state.lostAndFound;

      const agGridCustomService = new AgGridCustomService(this.columnDefs); // TODO one time
      const colIds = agGridCustomService.getColIdByMatchAnyfieldsRelated(this.joinAllPropertyPath(differences));

      if (colIds && colIds.length > 0) {
        colIds.forEach((colId) => {
          if (gridApi.getColumnDef(colId)) {
            gridApi.refreshCells({
              rowNodes: [rowNode],
              columns: [colId],
              force: true,
            });

            gridApi.flashCells({
              rowNodes: [rowNode],
              columns: [colId],
              force: true,
            });
          }
        });
      }
    },
    sizeColumnsToFit() {
      if (this.gridApi) {
        this.$store.dispatch(LOST_AND_FOUND_FIT_COLUMNS_TO_VIEW);
      }
    },
    firstDataRendered() {
      this.$nextTick(() => {
        this.resized();
      });
    },
  },
};

</script>
