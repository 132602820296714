<template>
  <v-container fluid class="contentData">
    <v-layout row justify class="infoNewCatalogs">
      <v-flex v-if="flights.length > 0">
        <div><v-icon color="primary">mdi-alert</v-icon><span> {{$t("schedulingImport.sameFlightsUniqueKey")}}</span></div>
      </v-flex>
      <v-flex v-else>
        <div><span>{{$t("schedulingImport.allFlightsCorrect")}}</span></div>
      </v-flex>
    </v-layout>

    <v-layout align-center justify-space-around row fill-height >
      <v-container fluid grid-list-md class="grid-duplicate-flights" v-if="flights.length > 0">
          <v-data-iterator
            :items="flights"
            item-key="name"
            :rows-per-page-items="rowsPerPageItems"
            :pagination.sync="paginationDuplicatedFlights"
            content-class="layout row wrap"
          >
            <v-flex slot="item" slot-scope="props" xs4>
              <v-card>
                <v-card-title><h4>
                        <v-icon v-if="props.item[0].bound==='INBOUND'">flight_land</v-icon>
                        <v-icon v-if="props.item[0].bound==='OUTBOUND'">flight_takeoff</v-icon>
                        {{ props.item[0].flightDate }} - {{ props.item[0].airlineCode }} {{ props.item[0].tripNumber }} - {{ props.item[0].airport1Code }}</h4></v-card-title>
                <v-divider></v-divider>
                <v-list dense>
                  <v-list-tile v-for="(flight, index) in props.item" :key="index">
                    <v-list-tile-content>
                        <span>
                        {{ $moment(flight.scheduledTime).tz('UTC').format('YYYY-MM-DD HH:mm') }} - {{ $moment(flight.scheduledTime).tz(localTimezone).format('YYYY-MM-DD HH:mm') }} - {{ flight.airlineCode }} {{ flight.tripNumber }} - {{ flight.airport1Code }}
                      </span>
                    </v-list-tile-content>
                  </v-list-tile>
                </v-list>
              </v-card>
            </v-flex>
          </v-data-iterator>
        </v-container>
    </v-layout>
  </v-container>
</template>
<script>

export default {
  name: 'scheduling-import-step-flights-same-unique-key',
  mixins: [],
  components: {

  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      flights: [],
      rowsPerPageItems: [6, 12, 18],
      paginationDuplicatedFlights: {
        rowsPerPage: 6,
      },
    };
  },
  computed: {
    localTimezone() {
      return this.$store.getters.isTenantSetted ? this.$store.state.tenant.currentTenant.timezone : 'UTC';
    },
  },
  methods: {
    isFlightIn(flight) {
      const inbound = Symbol('INBOUND').toString();
      return (flight.bound && (flight.bound.toString() === inbound));
    },
  },
  mounted() {
    this.flights = this.items;
  },
};
</script>
<style lang="scss" scoped>
//@import "../../../assets/scss/ag-grid-fis-theme";
  .infoNewCatalogs {
    padding: 2em;
    font-weight: bold;
  }
  .insert {
    background: #E8F5E9
  }
  .delete {
    background: #FBE9E7
  }
  .equal {
    background: #E3F2FD
  }
  .modify {
    background: #FFF3E0
  }
.contentData {
  max-height: 60vh;
  overflow-y: auto;
}
</style>
