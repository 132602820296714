<template>
  <div>
    <ticketing-list-filter @changeShowFilter="changeShowFilter"/>

    <ticketing-list-table :items="items" :itemTotal="itemTotal" :isFilterDisplayed="filterDisplayed" @show-item="showItem" @edit-item="editItem" @delete-item="deleteItem" @cancel-item="cancelItem"/>

    <ticketing-flights-list-options @click-new-ticketing="newTicketing" @click-delete-ticketing="deleteTicketing" @click-cancel-ticketing="cancelTicketing"/>
    <ticketing-new-modal v-if="dialogTicketing" :dialog="dialogTicketing" @ticketing-new-reject="newTicketingReject" @ticketing-new-confirm="newTicketingConfirm" />
    <ticketing-delete-modal v-if="dialogDeleteTicketing" :dialog="dialogDeleteTicketing" :items="selectedItemsToDelete" @delete-reject="deleteTicketingReject" @delete-confirm="deleteTicketingConfirm" />
    <ticketing-cancel-modal v-if="dialogCancelTicketing" :dialog="dialogCancelTicketing" :items="selectedItemsToCancel" @cancel-reject="cancelTicketingReject" @cancel-confirm="cancelTicketingConfirm"/>
  </div>
</template>
<script>
import Breadcrumbs from '@/components/Breadcrumbs.vue';

import TicketingListFilter from '../components/ticketing/list/TicketingListFilter.vue';
import TicketingListTable from '../components/ticketing/list/TicketingListTable.vue';
import TicketingFlightsListOptions from '../components/ticketing/list/TicketingFlightsListOptions.vue';
import TicketingNewModal from '../components/ticketing/edit/TicketingNewModal.vue';
import TicketingDeleteModal from '../components/ticketing/delete/TicketingDeleteModal.vue';
import {
  TICKETING_FLIGHTS_FIND,
  TICKETING_FLIGHTS_CANCEL,
  TICKETING_FLIGHTS_DELETE,
  TICKETING_PREVIOUS_PAGE_SET_TICKETING,
} from '../store/modules/ticketing';
import TicketingCancelModal from '../components/ticketing/cancel/TicketingCancelModal.vue';
import { TicketingStatus } from '../components/ticketing/model/ticketingStatus';

export default {
  components: {
    TicketingCancelModal,
    TicketingListFilter,
    TicketingListTable,
    TicketingFlightsListOptions,
    TicketingNewModal,
    TicketingDeleteModal,
    Breadcrumbs,
  },
  data() {
    return {
      filterDisplayed: true,
      selectedItemsToDelete: [],
      dialogTicketing: false,
      dialogDeleteTicketing: false,
      dialogAircraftGroundServices: false,
      aircraftGround: null,
      dialogCancelTicketing: false,
      selectedItemsToCancel: [],
    };
  },
  computed: {
    items() {
      // eslint-disable-next-line
      return this.$store.state.ticketing.flightsTicketing;
    },
    itemTotal() {
      const tickets = this.items;
      const total = {
        totalTickets: this.$i18n.t('ticketing.totalTickets') + tickets.length,
        totalCash: `${this.$i18n.t('ticketing.totalCashQuantity')}`,
        totalAmountCash: this.getTotalCash(),
        totalCard: `${this.$i18n.t('ticketing.totalCardQuantity')}`,
        totalAmountCard: this.getTotalCard(),
      };

      return [total];
    },
    selectedRowsTicketing() {
      return this.$store.getters.getSelectedTicketing;
    },
  },
  methods: {
    changeShowFilter(dispayled) {
      this.filterDisplayed = dispayled;
    },
    newTicketing() {
      this.dialogTicketing = true;
    },
    newTicketingReject() {
      this.dialogTicketing = false;
    },
    newTicketingConfirm() {
      this.$store.dispatch(TICKETING_FLIGHTS_FIND);
      this.dialogTicketing = false;
    },
    deleteTicketing() {
      this.selectedItemsToDelete = [];
      this.selectedItemsToDelete = this.selectedRowsTicketing.filter(ticketing => ticketing.status == null);
      this.dialogDeleteTicketing = this.selectedItemsToDelete.length > 0;
    },
    deleteTicketingReject() {
      this.dialogDeleteTicketing = false;
    },
    deleteTicketingConfirm(ids) {
      this.selectedItemsToDelete = [];
      this.$store.dispatch(TICKETING_FLIGHTS_DELETE, { ids });
      this.dialogDeleteTicketing = false;
    },
    deleteItem(item) {
      this.selectedItemsToDelete = [];
      this.selectedItemsToDelete.push(item);
      this.dialogDeleteTicketing = true;
    },
    showItem(item) {
      this.$router.push({ name: 'ticketingReport', params: { ticketingId: item.id } });
    },
    editItem(item) {
      this.$router.push({ name: 'ticketingReport', params: { ticketingId: item.id } });
    },
    cancelItem(item) {
      this.selectedItemsToCancel = [];
      this.selectedItemsToCancel.push(item);
      this.dialogCancelTicketing = true;
    },
    cancelTicketing() {
      this.selectedItemsToCancel = [];
      this.selectedItemsToCancel = this.selectedRowsTicketing.filter(ticketing => ticketing.status === TicketingStatus.OK.description);
      this.dialogCancelTicketing = true;
    },
    cancelTicketingReject() {
      this.dialogCancelTicketing = false;
    },
    cancelTicketingConfirm(items) {
      this.selectedItemsToCancel = [];
      this.$store.dispatch(TICKETING_FLIGHTS_CANCEL, items);
      this.dialogCancelTicketing = false;
    },
    getTotalCash() {
      const that = this;
      const currencies = new Set(this.items.filter(tc => tc.status === TicketingStatus.OK.description).filter(t => t.airlineFareCurrency != null).map(t => t.airlineFareCurrency));

      let result = '';

      currencies.forEach((currency) => {
        const sumCurrency = that.sum(that.items.filter(tc => tc.status === TicketingStatus.OK.description)
          .filter(ticket => ticket.airlineFareCurrency === currency)
          .filter(tckt => tckt.cashQuantity != null)
          .map(t => t.cashQuantity));
        if (sumCurrency != null && sumCurrency !== 0) {
          result = result !== '' ? `${result}  ${that.getParseValueCurrency(currency, sumCurrency)}` : ` ${that.getParseValueCurrency(currency, sumCurrency)}`;
        }
      });

      return result === '' ? ' 0' : result;
    },
    getTotalCard() {
      const that = this;
      const currencies = new Set(this.items.filter(tc => tc.status === TicketingStatus.OK.description).filter(t => t.airlineFareCurrency != null).map(t => t.airlineFareCurrency));

      let result = '';

      currencies.forEach((currency) => {
        const sumCurrency = that.sum(that.items.filter(tc => tc.status === TicketingStatus.OK.description)
          .filter(ticket => ticket.airlineFareCurrency === currency)
          .filter(tckt => tckt.cardQuantity != null)
          .map(t => t.cardQuantity));
        if (sumCurrency != null && sumCurrency !== 0) {
          result = result !== '' ? `${result}  ${that.getParseValueCurrency(currency, sumCurrency)}` : ` ${that.getParseValueCurrency(currency, sumCurrency)}`;
        }
      });

      return result === '' ? ' 0' : result;
    },
    sum(array) {
      return array.reduce((a, b) => a + b, 0);
    },
    getParseValueCurrency(currency, value) {
      return new Intl.NumberFormat(this.$store.getters.getCurrencies.find(p => p.id === currency).locale, { style: 'currency', currency }).format(parseFloat(value));
    },
  },
  created() {
    this.$store.dispatch(TICKETING_PREVIOUS_PAGE_SET_TICKETING);
  },
};
</script>
