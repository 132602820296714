export default class TowingAdhocTaskFilter {
  constructor(dateFrom, dateTo, showFlights, showAircraftGround, rampServiceStatus, rampService, timezoneDate, createdBy) {
    this.dateFrom = dateFrom;
    this.dateTo = dateTo;
    this.showFlights = showFlights === true;
    this.showAircraftGround = showAircraftGround === true;
    this.rampServiceStatus = rampServiceStatus;
    this.rampService = rampService;
    this.timezoneDate = timezoneDate;
    this.createdBy = createdBy;
  }

  static get createEmptyFilter() {
    return new TowingAdhocTaskFilter(null, null, true, true, null, null, null, null);
  }
}
