<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div v-if="tenantSetted">
    <div class="hidden-sm-and-down d-flex">
      <div v-if="timeLastMessage">
        <v-tooltip bottom>
          <v-chip class="chip-zone" label outline :color="colorAvinet" slot="activator"><v-icon :color="colorAvinet">inbox</v-icon>&nbsp;{{timeLastMessage | moment('DD/MM/YY HH:mm:ss')}}</v-chip>
          <span>{{$t("administration.aviNetLastReadingTime")}}</span>
        </v-tooltip>
      </div>
      <div v-else>
        <v-tooltip bottom>
          <v-chip class="chip-zone" label outline color="red" slot="activator"><v-icon color="red">inbox</v-icon>&nbsp;{{$t("administration.aviNetNotRead")}}</v-chip>
          <span>{{$t("administration.aviNetLastReadingTime")}}</span>
        </v-tooltip>
      </div>
    </div>
    <div class="hidden-md-and-up">
      <v-menu>
        <template v-slot:activator="{ on }">
          <v-btn
            icon
            v-on="on"
          >
            <v-icon :color="colorAvinet">inbox</v-icon>
          </v-btn>
        </template>
        <v-list>
          <div>
            <div v-if="timeLastMessage">
              <v-tooltip bottom>
                <v-chip class="chip-zone" label outline :color="colorAvinet" slot="activator"><v-icon :color="colorAvinet">inbox</v-icon>&nbsp;{{timeLastMessage | moment('DD/MM/YY HH:mm:ss')}}</v-chip>
                <span>{{$t("administration.aviNetLastReadingTime")}}</span>
              </v-tooltip>
            </div>
            <div v-else>
              <v-tooltip bottom>
                <v-chip class="chip-zone" label outline color="red" slot="activator"><v-icon color="red">inbox</v-icon>&nbsp;{{$t("administration.aviNetNotRead")}}</v-chip>
                <span>{{$t("administration.aviNetLastReadingTime")}}</span>
              </v-tooltip>
            </div>
          </div>
        </v-list>
      </v-menu>
    </div>
  </div>
</template>
<script>

import { AVINET_FIND_TIME_LAST_EXECUTION } from '../../store/modules/admin/avinet';
import { WEBSOCKET_CONNECTION_INTEGRATION } from '../../store/modules/websocket';
import StoreSubscribable from '../mixins/StoreSubscribable.vue';

export default {
  name: 'time-avinet',
  mixins: [StoreSubscribable],
  data() {
    return {
      colorAvinet: null,
    };
  },
  computed: {
    timeNow() {
      return this.$store.state.time.timeNow;
    },
    timeLastMessage() {
      return this.$moment(this.$store.state.admin.avinet.readLastExecuted).isValid() ? this.$moment(this.$store.state.admin.avinet.readLastExecuted).tz(this.$store.state.time.timezone) : null;
    },
    tenantSetted() {
      return this.$store.getters.isTenantSetted;
    },
  },
  watch: {
    timeNow: {
      handler() {
        this.calculateColor();
      },
    },
    timeLastMessage: {
      handler() {
        this.calculateColor();
      },
    },
    tenantSetted: {
      handler() {
        this.changeTenant();
      },
    },
  },
  methods: {
    calculateColor() {
      this.colorAvinet = this.$moment(this.timeNow).diff(this.timeLastMessage, 'hours') >= 2 ? 'red' : null;
    },
    find() {
      this.$store.dispatch(AVINET_FIND_TIME_LAST_EXECUTION);
    },
    changeTenant() {
      if (this.tenantSetted) {
        this.$store.dispatch(WEBSOCKET_CONNECTION_INTEGRATION, this.$store.state.tenant.currentTenant.name.toLowerCase());
        this.find();
      }
    },
  },
  mounted() {
    this.changeTenant();
  },
};
</script>
<style lang="scss">
  .chip-zone{
    border: none;

    i{
      font-size: 18px;
    }
  }
</style>
