<template>
<div style="width: 100%; height:100%">
  <users-manual module="default" lang="es"/>
</div>
</template>

<script>
import UsersManual from '../components/ui/UsersManual.vue';

export default {
  name: 'Help',
  components: {
    UsersManual,
  },
};
</script>
