/* eslint no-param-reassign: "off" */
/* eslint no-confusing-arrow: "off" */
/* eslint no-nested-ternary: "off" */

import { HTTP } from '../../../http';
import { REQUEST_ERROR, REQUEST_START, REQUEST_SUCCESS } from '../../store';
import { NOTIFICATION_SHOW } from '../notifications';
import i18n from '../../../plugins/i18n';
import { mapAirportMilestonesList } from '../../../components/shared/model/milestoneTypes';

// actions names
export const GET_AIRPORT_ACTIVE_MILESTONES = 'GET_AIRPORT_ACTIVE_MILESTONES';
export const AIRPORT_ACTIVE_MILESTONE_DELETE = 'AIRPORT_ACTIVE_MILESTONE_DELETE';
export const AIRPORT_ACTIVE_MILESTONE_CREATE = 'AIRPORT_ACTIVE_MILESTONE_CREATE';
export const AIRPORT_ACTIVE_MILESTONE_UPDATE = 'AIRPORT_ACTIVE_MILESTONE_UPDATE';
export const GET_MILESTONES_TO_ACTIVE = 'GET_MILESTONES_TO_ACTIVE';


// mutations
export const CHANGE_AIRPORT_ACTIVE_MILESTONE_SELECTION = 'CHANGE_AIRPORT_ACTIVE_MILESTONE_SELECTION';
export const GET_AIRPORT_ACTIVE_MILESTONES_SUCCESS = 'GET_AIRPORT_ACTIVE_MILESTONES_SUCCESS';
export const GET_AIRPORT_ACTIVE_MILESTONES_REQUESTED = 'GET_AIRPORT_ACTIVE_MILESTONES_REQUESTED';
export const UPDATE_AIRPORT_ACTIVE_MILESTONE_SUCCESS = 'UPDATE_AIRPORT_ACTIVE_MILESTONE_SUCCESS';
export const GET_MILESTONES_TO_ACTIVE_REQUESTED = 'GET_MILESTONES_TO_ACTIVE_REQUESTED';
export const GET_MILESTONES_TO_ACTIVE_SUCCESS = 'GET_MILESTONES_TO_ACTIVE_SUCCESS';

// initial state
const storeState = {
  airportActiveMilestones: [],
  airportActiveMilestoneRequested: false,
  selectedMilestonesActiveAirport: [],
  milestonesToActive: [],
};

// getters
const storeGetters = {
  getAirportActiveMilestones: state => state.airportActiveMilestones.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)),
  getAirportActiveMilestoneSelected: state => state.selectedMilestonesActiveAirport,
  hasSelectedAirportActiveMilestone: state => state.selectedMilestonesActiveAirport.length > 0,
  getAirportActiveMilestoneById: state => id => state.airportActiveMilestones.find(a => a.id === id),
  getMilestonesToActive: state => state.milestonesToActive.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)),
};


// actions
const storeActions = {
  [GET_AIRPORT_ACTIVE_MILESTONES]: ({ commit, state }) => {
    if (state.airportActiveMilestoneRequested) {
      return;
    }
    commit(REQUEST_START);
    commit(GET_AIRPORT_ACTIVE_MILESTONES_REQUESTED);
    HTTP.get('airport-active-milestones')
      .then((resp) => {
        commit(REQUEST_SUCCESS);
        commit(GET_AIRPORT_ACTIVE_MILESTONES_SUCCESS, mapAirportMilestonesList(resp.data));
      })
      .catch((err) => {
        commit(REQUEST_ERROR, err);
      });
  },

  [AIRPORT_ACTIVE_MILESTONE_CREATE]: ({ commit, dispatch }, milestone) => {
    const milestonesToSave = {};
    if (milestone.globalMilestones) {
      milestonesToSave.globalMilestones = milestone.globalMilestones.map(item => item.id);
    }
    if (milestone.name && milestone.type) {
      milestonesToSave.airportMilestone = {
        name: milestone.name,
        description: milestone.description,
        type: milestone.type,
        flightBound: milestone.flightBound.description,
      };
    }
    commit(REQUEST_START);
    return HTTP.post('airport-active-milestone', milestonesToSave)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(UPDATE_AIRPORT_ACTIVE_MILESTONE_SUCCESS);
        dispatch(GET_AIRPORT_ACTIVE_MILESTONES);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.milestoneCreate'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },

  [AIRPORT_ACTIVE_MILESTONE_UPDATE]: ({ commit, dispatch }, activeMilestone) => {
    const milestone = {
      id: activeMilestone.idAirport,
      name: activeMilestone.name,
      description: activeMilestone.description,
      type: activeMilestone.type,
      flightBound: activeMilestone.flightBound.description,
    };
    commit(REQUEST_START);
    return HTTP.put('airport-milestone', milestone)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(UPDATE_AIRPORT_ACTIVE_MILESTONE_SUCCESS);
        dispatch(GET_AIRPORT_ACTIVE_MILESTONES);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.milestoneUpdate'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
  [AIRPORT_ACTIVE_MILESTONE_DELETE]: ({ commit, dispatch }, id) => {
    const ids = [];
    ids.push(id);
    commit(REQUEST_START);
    return HTTP.patch('airport-active-milestone/deactivate', ids)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(UPDATE_AIRPORT_ACTIVE_MILESTONE_SUCCESS);
        dispatch(GET_AIRPORT_ACTIVE_MILESTONES);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.milestoneDelete'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
  [GET_MILESTONES_TO_ACTIVE]: ({ commit }) => {
    commit(REQUEST_START);
    commit(GET_MILESTONES_TO_ACTIVE_REQUESTED);
    HTTP.get('/airport-active-milestones/milestones-to-active')
      .then((resp) => {
        commit(REQUEST_SUCCESS);
        commit(GET_MILESTONES_TO_ACTIVE_SUCCESS, resp.data);
      })
      .catch((err) => {
        commit(REQUEST_ERROR, err);
      });
  },

};

// mutations
const storeMutations = {
  [GET_MILESTONES_TO_ACTIVE_SUCCESS]: (state, milestonesToActive) => {
    state.milestonesToActive = milestonesToActive;
  },
  [GET_MILESTONES_TO_ACTIVE_REQUESTED]: (state) => {
    state.milestonesToActiveRequest = true;
  },
  [GET_AIRPORT_ACTIVE_MILESTONES_SUCCESS]: (state, airportActiveMilestones) => {
    state.airportActiveMilestones = airportActiveMilestones;
  },
  [GET_AIRPORT_ACTIVE_MILESTONES_REQUESTED]: (state) => {
    state.airportActiveMilestoneRequested = true;
  },
  [UPDATE_AIRPORT_ACTIVE_MILESTONE_SUCCESS]: (state) => {
    state.airportActiveMilestoneRequested = false;
  },
  [CHANGE_AIRPORT_ACTIVE_MILESTONE_SELECTION]: (state, selection) => {
    state.selectedMilestonesActiveAirport = selection;
  },
};

export default {
  state: storeState,
  getters: storeGetters,
  actions: storeActions,
  mutations: storeMutations,
};
