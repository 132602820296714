import FlightCommonInformation from '../../scheduling/model/flightCommonInformation';

export default class FlightComposition {
  constructor(
    common,
    extended,
    times,
    operation,
    operationExtended,
    passageSummary,
    passageCheckIn,
    ramp,
    rampExtended,
    services,
    waterToilet,
    passageOutbound,
    billing,
    delays,
    integration,
    passageDistribution,
    passageTransitsDistribution,
    loadHoldingDistribution,
    remarks,
    passageBoarding,
    towingAdhocTaskServices,
    slaBaggageDelivery,
    blockedFieldsIntegration,
  ) {
    this.common = common;
    this.extended = extended;
    this.times = times;
    this.operation = operation;
    this.operationExtended = operationExtended;
    this.passageSummary = passageSummary;
    this.passageCheckIn = passageCheckIn;
    this.ramp = ramp;
    this.rampExtended = rampExtended;
    this.services = services;
    this.waterToilet = waterToilet;
    this.passageOutbound = passageOutbound;
    this.billing = billing;
    this.delays = delays;
    this.integration = integration;
    this.passageDistribution = passageDistribution;
    this.passageTransitsDistribution = passageTransitsDistribution;
    this.loadHoldingDistribution = loadHoldingDistribution;
    this.remarks = remarks;
    this.passageBoarding = passageBoarding;
    this.towingAdhocTaskServices = towingAdhocTaskServices;
    this.slaBaggageDelivery = slaBaggageDelivery;
    this.blockedFieldsIntegration = blockedFieldsIntegration;
  }

  static createEmptyFlightComposition() {
    return new FlightComposition(FlightCommonInformation.createEmptyFlightCommonInformation, null, null, null, null, null, null);
  }
}
