<template>
  <div class="delays-flights list-filter">
    <v-card :dark="this.$vuetify.dark" :color="this.$vuetify.dark ? 'bggrey_dark' : 'bggrey'">
      <v-toolbar dark dense color="primary lighten-1">
        <v-btn v-on:click="displayedFilter" small flat>
          <v-icon>filter_list</v-icon>
          <v-icon v-if="!showFilter">keyboard_arrow_down</v-icon>
          <v-icon v-if="showFilter">keyboard_arrow_up</v-icon>
        </v-btn>
        <DelaysFlightsListTableOptionsFilterButton />
        <v-spacer></v-spacer>
        <v-flex grow="false" shrink="true">
          <v-btn-toggle v-model="unitOfTime" class="transparent" @change="filterChanged">
            <v-tooltip left>
              <v-btn value="day" flat slot="activator">{{$t("commonFilter.filterD")}}</v-btn>
              <span>{{$t("commonFilter.filterDay")}}</span>
            </v-tooltip>
            <v-tooltip left>
              <v-btn value="isoWeek" flat slot="activator">{{$t("commonFilter.filterW")}}</v-btn>
              <span>{{$t("commonFilter.filterWeek")}}</span>
            </v-tooltip>
            <v-tooltip left>
              <v-btn value="month" flat slot="activator">{{$t("commonFilter.filterM")}}</v-btn>
              <span>{{$t("commonFilter.filterMonth")}}</span>
            </v-tooltip>
            <v-tooltip left>
              <v-btn value="year" flat slot="activator">{{$t("commonFilter.filterY")}}</v-btn>
              <span>{{$t("commonFilter.filterYear")}}</span>
            </v-tooltip>
          </v-btn-toggle>
        </v-flex>
      </v-toolbar>
      <v-container fluid v-if="showFilter" class="pa-2 pb-2">
        <v-layout class="filter-inline" align-center justify-start>
          <v-flex class="mr-2" align-center justify-start>
            <date-input v-model="filter.dateFrom" :locale="$i18n.locale" :label="$t('common.fromDate')" class="pb-2"
              @change="changeDateFrom"></date-input>
            <date-input v-model="filter.dateTo" :locale="$i18n.locale" :label="$t('common.toDate')"
              @change="changeDateTo"></date-input>
          </v-flex>
          <v-flex class="text-no-wrap" align-center justify-start>
            <v-switch
              slot="activator"
              color="red"
              :label="this.$i18n.t('commonFilter.showOldValues')"
              v-model="showOldValues"
              hide-details
              @change="updateShowOldValuesInStore()"
            ></v-switch>
            <v-switch
              slot="activator"
              color="red"
              :label="this.$i18n.t('commonFilter.flashOnChanges')"
              v-model="flashOnChanges"
              hide-details
              @change="updateFlashOnChangesInStore()"
            ></v-switch>
          </v-flex>

          <v-spacer style="width: 55%"></v-spacer>
          <v-flex class="ml-2 text-no-wrap" align-center justify-start>
            <v-btn color="secondary" small flat round v-on:click.stop="exportCSV" block class="mb-4">
              <v-icon>mdi-download</v-icon>
              {{ $t("common.export") }}
            </v-btn>
            <v-btn color="primary" round v-on:click.stop="find" block>
              <v-icon>search</v-icon>
              {{ $t("common.find") }}
            </v-btn>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card>
  </div>
</template>
<script>
import DelaysFlightsListFilterDateSelector from './DelaysFlightsListFilterDateSelector.vue';
import DelaysFlightsListTableOptionsFilterButton from './DelaysFlightsListTableOptionsFilterButton.vue';
import AgGridApiCsvExportable from '../../ag-grid/mixins/AgGridApiCsvExportable.vue';
import StoreSubscribable from '../../mixins/StoreSubscribable.vue';
import DateInput from '../../ui/DateInput.vue';

import {
  DELAYS_FIND_FLIGHTS,
  DELAYS_SET_GRID_API,
  UPDATE_FLASH_ON_CHANGES,
  UPDATE_SHOW_OLD_VALUES,
  DELAYS_FILTER_SET_UNIT_TIME, DELAYS_RECALCULATE_FILTER,
} from '../../../store/modules/delays';
import { TIME_TIMEZONE_CHANGED } from '../../../store/modules/time';

export default {
  name: 'delays-flights-list-filter',
  mixins: [AgGridApiCsvExportable, StoreSubscribable],
  components: {
    DateInput,
    DelaysFlightsListTableOptionsFilterButton,
    DelaysFlightsListFilterDateSelector,
  },
  props: ['value'],
  data() {
    return {
      select: 50,
      showFilter: true,
      valid: true,
      queryName: null,
      dialog: false,
      showOldValues: null,
      flashOnChanges: null,
    };
  },
  computed: {
    filter() {
      return this.$store.state.delays.filter;
    },
    unitOfTime: {
      get() {
        return this.filter.unitOfTime;
      },
      set(value) {
        this.$store.commit(DELAYS_FILTER_SET_UNIT_TIME, value);
      },
    },
  },
  methods: {
    filterChanged() {
      this.find();
    },

    find() {
      this.showLoading();
      this.$store.dispatch(DELAYS_FIND_FLIGHTS);
    },
    exportCSV() {
      this.exportAgGridCsv('DelaysFlights');
    },
    updateShowOldValuesInStore() {
      this.$store.commit(UPDATE_SHOW_OLD_VALUES, this.showOldValues);
    },
    updateFlashOnChangesInStore() {
      this.$store.commit(UPDATE_FLASH_ON_CHANGES, this.flashOnChanges);
    },
    displayedFilter() {
      this.showFilter = !this.showFilter;
      this.$emit('changeShowFilter', this.showFilter);
    },
    changeDateFrom() {
      this.unitOfTime = null;
      const from = this.$moment(this.filter.dateFrom);
      const to = this.$moment(this.filter.dateTo);

      this.filter.dateFrom = from.startOf('day');
      if (from.isAfter(to)) {
        this.filter.dateTo = this.$moment(this.filter.dateFrom).endOf('day');
      }
      // this.filterChanged();
    },
    changeDateTo() {
      this.unitOfTime = null;
      const from = this.$moment(this.filter.dateFrom);
      const to = this.$moment(this.filter.dateTo);

      this.filter.dateTo = to.endOf('day');
      if (to.isBefore(from)) {
        this.filter.dateFrom = this.$moment(this.filter.dateTo).startOf('day');
      }
      // this.filterChanged();
    },
  },
  created() {
    this.showOldValues = this.$store.state.delays.showOldValues;
    this.flashOnChanges = this.$store.state.delays.flashOnChanges;
  },
  mounted() {
    const that = this;
    this.subscribeMutation(DELAYS_SET_GRID_API, (mutationPayload) => {
      that.setAgGridApi(mutationPayload);
    });
    this.subscribeMutation(TIME_TIMEZONE_CHANGED, () => {
      this.$store.dispatch(DELAYS_RECALCULATE_FILTER);
      this.find();
    });
    this.$store.dispatch(DELAYS_RECALCULATE_FILTER);
    this.find();
  },
};
</script>
