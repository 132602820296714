import { JSUtils } from '../../../shared/js/JSUtils';

export default class FlightTowingAdhocTaskServicesInformation {
  constructor(
    id,
    defaultServiceRequested,
    defaultRampServiceId,
    defaultRampServiceName,
  ) {
    this.id = id;
    this.defaultServiceRequested = defaultServiceRequested;
    this.defaultRampServiceId = defaultRampServiceId;
    this.defaultRampServiceName = defaultRampServiceName;
  }

  static get createEmptyFlightTowingAdhocTaskServicesInformation() {
    return new FlightTowingAdhocTaskServicesInformation();
  }

  static createFlightTowingAdhocTaskServicesInformationFromData(o) {
    if (!o) {
      return this.createEmptyFlightTowingAdhocTaskServicesInformation;
    }

    return JSUtils.cast(o, FlightTowingAdhocTaskServicesInformation);
  }
}
