<template>
  <v-layout row justify-center>
    <v-dialog v-model="dialog" persistent max-width="800px">
      <v-card :dark="this.$vuetify.dark" :color="this.$vuetify.dark ? 'bggrey_dark' : 'bggrey'">
        <v-toolbar dark color="primary">
          <v-icon>link_off</v-icon>
          <v-toolbar-title>Confirm GS PLANINIG</v-toolbar-title>
        </v-toolbar>

        <v-card-text>
          Filters made in the table will not be taken into account in the GS Planning file. Do you what to continue?
        </v-card-text>
        <v-card-actions>
          <v-btn flat color="primary" @click="reject">Cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="confirm">Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>
<script>

import SchedulingImportCsvStepper from '../import/SchedulingImportStepper.vue';
import LoadingComponent from '../../layout/LoadingComponent.vue';
import ConnectedFlightsBaseGrid from '../../flights/ConnectedFlightsBaseGrid.vue';

export default {
  name: 'scheduling-confirm-gs-planning-modal',
  components: {
    SchedulingImportCsvStepper,
    ConnectedFlightsBaseGrid,
    LoadingComponent,
  },
  props: ['dialog'],
  methods: {
    reject() {
      this.$emit('gsPlaning-reject');
    },
    confirm() {
      this.$emit('gsPlaning-confirm');
    },
  },
};
</script>
<style>
</style>
