import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VListTile } from 'vuetify/lib/components/VList';
import { VListTileAction } from 'vuetify/lib/components/VList';
import { VListTileContent } from 'vuetify/lib/components/VList';
import { VListTileSubTitle } from 'vuetify/lib/components/VList';
import { VListTileTitle } from 'vuetify/lib/components/VList';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VLayout,{attrs:{"row":"","column":""}},[_c(VFlex,[_c(VAutocomplete,{attrs:{"items":_vm.items,"label":this.$i18n.t('commonForm.selectAirport'),"prepend-icon":"local_airport","filter":_vm.customFilter,"disabled":_vm.disabled,"rules":_vm.rules,"multiple":"","small-chips":"","deletable-chips":"","return-object":"","item-text":"name"},on:{"input":_vm.emitInput},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c(VListTile,{attrs:{"ripple":""},on:{"click":_vm.toggle}},[_c(VListTileAction,[_c(VIcon,{attrs:{"color":_vm.select.length === _vm.items.length ? 'red' : 'grey'}},[_vm._v("done_all")])],1),_c(VListTileContent,[_c(VListTileTitle,[_vm._v(_vm._s(_vm.select.length === _vm.items.length ? _vm.$t('common.unCheck') : _vm.$t('common.check')))])],1)],1),_c(VDivider,{staticClass:"mt-2"})]},proxy:true},{key:"item",fn:function({ item, tile }){return [_c(VListTileContent,[_c(VListTileTitle,{domProps:{"textContent":_vm._s(item.name)}}),_c(VListTileSubTitle,{domProps:{"textContent":_vm._s(item.description)}})],1)]}}]),model:{value:(_vm.select),callback:function ($$v) {_vm.select=$$v},expression:"select"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }