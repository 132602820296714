<template>
  <v-flex xs6>
    <label class="primary--text subheading">{{ title }}</label>
    <v-layout row wrap>
      <v-flex xs6>
        <label>{{ $t("operations.passengerTimesModal.expectedTime") }}</label>
        <time-input v-model="copyAgreedTime" :referenceDate="referenceDate"
          :readOnly="true"></time-input>
      </v-flex>
      <v-flex xs6>
        <label class="label-real">{{ $t("operations.passengerTimesModal.realTime") }}</label>
        <time-input v-model="copyRealTime" :referenceDate="referenceDate"
          :disabled="!hasPermissions(rolePermission)"></time-input>
      </v-flex>
      <v-flex xs12>
        <v-combobox clearable :label="$t('operations.passengerTimesModal.brokenExpectedTimeDefaultReason')"
          :items="breakingReasons" v-model="localBreakingReason" item-text="reason" item-value="id"
          :disabled="!hasPermissions(rolePermission) || !isEndTimeUpperThanStartTime" />
      </v-flex>
    </v-layout>
  </v-flex>
</template>

<script>
import RolePermissions from '../../../../../mixins/RolePermissions.vue';
import TimeInput from '../../../../../ui/TimeInput.vue';

export default {
  mixins: [RolePermissions],
  props: {
    title: {
      type: String,
      required: true,
    },
    referenceDate: {
      type: String,
    },
    agreedTime: {
      type: String,
    },
    realTime: {
      type: String,
    },
    breakingReason: {
      type: Object,
    },
    breakingReasons: {
      type: Array,
    },
  },
  components: {
    TimeInput,
  },
  data() {
    return {
      copyRealTime: this.realTime,
      copyAgreedTime: this.agreedTime,
      localBreakingReason: this.breakingReason,
    };
  },
  watch: {
    agreedTime() {
      this.copyAgreedTime = this.agreedTime;
    },
    realTime() {
      this.copyRealTime = this.realTime;
    },
    breakingReason() {
      this.localBreakingReason = this.breakingReason;
    },
    copyAgreedTime() {
      this.$emit('update:agreedTime', this.copyAgreedTime);
    },
    copyRealTime() {
      this.$emit('update:realTime', this.copyRealTime);
    },
    localBreakingReason() {
      this.$emit('update:breakingReason', this.localBreakingReason);
    },
  },
  computed: {
    rolePermission() {
      return `${this.$route.meta.role}_WR`;
    },
    isEndTimeUpperThanStartTime() {
      if (this.copyAgreedTime && this.copyRealTime) {
        return this.$moment(this.copyRealTime).isAfter(this.$moment(this.copyAgreedTime));
      }
      return false;
    },
  },
};
</script>
