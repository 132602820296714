import IntegrationResult from './integrationResult';

export default class IntegrationResultFactory {
  static createIntegrationResultFromData(data) {
    if (data && data.constructor === Array && data.length > 0) {
      return data.map(d => this.mapIntegrationResult(d));
    }
    if (data) {
      return this.mapIntegrationResult(data);
    }
    return [];
  }

  static mapIntegrationResult(d) {
    return new IntegrationResult(
      d.tenant ? d.tenant : null,
      d.ts ? d.ts : null,
      d.success ? d.success : null,
      d.traces ? d.traces : null,
    );
  }
}
