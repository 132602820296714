/* eslint no-param-reassign: "off" */

// actions

export const TIME_CHANGE_TIMEZONE = 'TIME_CHANGE_TIMEZONE';
export const TIME_CHANGE_TIME_NOW = 'TIME_CHANGE_TIME_NOW';

// mutations
export const TIME_TIMEZONE_CHANGED = 'TIME_TIMEZONE_CHANGED';
export const TIME_TIME_NOW_CHANGED = 'TIME_TIME_NOW_CHANGED';

// initial state
const storeState = {
  timezone: 'UTC',
  timeNow: null,
};

// getters
const storeGetters = {
};

// actions
const storeActions = {
  [TIME_CHANGE_TIMEZONE]: ({ commit }, timezone) => {
    commit(TIME_TIMEZONE_CHANGED, timezone);
  },
  [TIME_CHANGE_TIME_NOW]: ({ commit }, timeNow) => {
    commit(TIME_TIME_NOW_CHANGED, timeNow);
  },
};

// mutations
const storeMutations = {
  [TIME_TIMEZONE_CHANGED]: (state, timezone) => {
    state.timezone = timezone;
  },
  [TIME_TIME_NOW_CHANGED]: (state, timeNow) => {
    state.timeNow = timeNow;
  },
};

export default {
  state: storeState,
  getters: storeGetters,
  actions: storeActions,
  mutations: storeMutations,
};
