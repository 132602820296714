/* eslint no-param-reassign: "off" */
/* eslint no-return-assign: "off" */
import { JSUtils } from '../../../shared/js/JSUtils';

export default class TicketingNumeration {
  constructor(
    id,
    initValue,
    incrementValue,
    initDate,
    endDate,
    format,
    currentValue,
    nextFormatValue,
    airlineId,
    airlineIataCode,
    airlineIcaoCode,
    airlineDefaultCode,
  ) {
    this.id = id;
    this.initValue = initValue;
    this.incrementValue = incrementValue;
    this.initDate = initDate;
    this.endDate = endDate;
    this.format = format;
    this.currentValue = currentValue;
    this.nextFormatValue = nextFormatValue;
    this.airlineId = airlineId;
    this.airlineIataCode = airlineIataCode;
    this.airlineIcaoCode = airlineIcaoCode;
    this.airlineDefaultCode = airlineDefaultCode;
  }

  clone() {
    return TicketingNumeration.createTicketingNumerationFromData(JSON.parse(JSON.stringify(this)));
  }

  static get createEmptyTicketingNumeration() {
    return new TicketingNumeration();
  }

  static createTicketingNumerationFromData(o) {
    if (!o) {
      return this.createEmptyTicketingNumeration;
    }

    return JSUtils.cast(o, TicketingNumeration);
  }
}
