<template>
  <div class="header-filter">
    <v-layout class="flex justify-center">
      <v-flex>
        <v-toolbar dark dense color="primary lighten-1">
          <h6 class="title ma-0">{{ $t('sidebar.Ticketing') }}</h6>
          <v-spacer></v-spacer>
          <v-btn icon @click="updateFlights">
            <v-icon>cached</v-icon>
          </v-btn>
          <div>
            <v-dialog v-model="filterDialog" max-width="600px">
              <template v-slot:activator="{ on }">
                <v-btn dark icon v-on="on">
                  <v-icon>tune</v-icon>
                </v-btn>
              </template>
              <v-card>
                <v-card-title class="pa-2">
                  <h5 class="headline red--text">
                    {{ $t('dispatcher.searchFilter') }}
                  </h5>
                </v-card-title>
                <v-card-text class="pa-0">
                  <v-container grid-list-md class="py-1">
                    <v-layout wrap>
                      <v-flex xs12 sm12>
                        <date-input
                          class="date-mobile"
                          v-model="filter.dateFrom"
                          :label="this.$i18n.t('ticketingMobile.dateFrom')"
                          :locale="this.$i18n.locale"
                        ></date-input>
                      </v-flex>
                      <v-flex xs12 sm12>
                        <date-input
                          class="date-mobile"
                          v-model="filter.dateTo"
                          :label="this.$i18n.t('ticketingMobile.dateTo')"
                          :locale="this.$i18n.locale"
                        ></date-input>
                      </v-flex>
                      <v-flex xs12 sm12>
                        <ticketing-status-select
                          :label="$t('common.status')"
                          v-model="filter.status"
                        />
                      </v-flex>
                      <v-flex xs12 sm12>
                        <airline-fare-select
                          :label="$t('ticketing.fare')"
                          v-model="filter.airlineFare"
                        />
                      </v-flex>
                      <v-flex xs12 sm12>
                        <airline-select
                          v-char-length.clearable="40"
                          :label="$t('common.airlineOp')"
                          v-model="filter.airline"
                        ></airline-select>
                      </v-flex>
                      <v-flex xs12 sm12>
                        <v-flex xs12 sm12>
                          <airport-select
                            v-char-length.clearable="40"
                            :label="$t('operations.loadsTableFromTo')"
                            v-model="filter.airport"
                          ></airport-select>
                        </v-flex>
                      </v-flex>
                    </v-layout>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="grey" flat @click="filterDialog = false">{{
                    $t('common.close')
                  }}</v-btn>
                  <v-btn color="primary" flat @click="filterChanged">{{
                    $t('common.apply')
                  }}</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
        </v-toolbar>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import AirportSelect from '../../airport/AirportSelect.vue';
import AirlineFareSelect from '../../ui/airlineFareSelector/AirlineFareSelect.vue';
import AirlineSelect from '../../airline/AirlineSelect.vue';
import DateInput from '../../ui/DateInput.vue';
import TicketingStatusSelect from '../TicketingStatusSelect.vue';
import {
  TICKETING_MOBILE_FILTER_SET_TENANT,
  TICKETING_MOBILE_FLIGHTS_FIND,
  TICKETING_MOBILE_RECALCULATE_FILTER,
} from '../../../store/modules/ticketingMobile';

import { TIME_TIMEZONE_CHANGED } from '../../../store/modules/time';
import StoreSubscribable from '../../mixins/StoreSubscribable.vue';
import { TENANT_CURRENT_SET_SUCCESS } from '../../../store/modules/tenant';

export default {
  name: 'ticketing-mobile-list-filter',
  mixins: [StoreSubscribable],
  components: {
    AirportSelect,
    AirlineFareSelect,
    AirlineSelect,
    DateInput,
    TicketingStatusSelect,
  },
  props: {
    filterDate: Object,
  },
  data() {
    return {
      buttonNav: 'all',
      filterDialog: false,
    };
  },
  computed: {
    filter() {
      return this.$store.state.ticketingMobile.filter;
    },
    isTenantSetted() {
      return this.$store.getters.isTenantSetted;
    },
    tenantId() {
      return this.isTenantSetted
        ? this.$store.state.tenant.currentTenant.id
        : null;
    },
  },
  methods: {
    filterChanged() {
      this.filterDialog = false;
      this.find();
    },
    updateFlights() {
      this.$store.dispatch(TICKETING_MOBILE_RECALCULATE_FILTER);
      this.$store.dispatch(TICKETING_MOBILE_FLIGHTS_FIND);
    },
    formattedTime(time) {
      let formattedTime = '';
      if (time) {
        formattedTime = this.$moment(time).format('D/M/YY');
      }
      return formattedTime;
    },
    find() {
      this.$store.dispatch(TICKETING_MOBILE_FLIGHTS_FIND);
    },
  },
  created() {
    this.subscribeMutation(TIME_TIMEZONE_CHANGED, () => {
      this.$store.dispatch(TICKETING_MOBILE_RECALCULATE_FILTER);
      this.find();
    });
    this.subscribeMutation(TENANT_CURRENT_SET_SUCCESS, () => {
      this.$store.commit(TICKETING_MOBILE_FILTER_SET_TENANT, this.tenantId);
      this.find();
    });
    this.$store.dispatch(TICKETING_MOBILE_RECALCULATE_FILTER);
    this.$store.commit(TICKETING_MOBILE_FILTER_SET_TENANT, this.tenantId);
    this.$store.dispatch(TICKETING_MOBILE_FLIGHTS_FIND);
  },
};
</script>
<style>
.bigLabel {
  font-size: 16px;
}

.boldText {
  font-weight: bold;
}

.mobile-flight-ramp-services > table th, td {
    width: calc(100% / 7);
    padding: 0px;
    word-break: break-all;
}
</style>
