import { JSUtils } from '../../shared/js/JSUtils';

export default class FlightSLABaggageDeliveryInformation {
  constructor(
    id,
    firstSLABaggageDeliveryId,
    firstSLABaggageDeliveryType,
    firstSLABaggageDeliveryCode,
    lastSLABaggageDeliveryId,
    lastSLABaggageDeliveryType,
    lastSLABaggageDeliveryCode,
    prioritySLABaggageDeliveryId,
    prioritySLABaggageDeliveryType,
    prioritySLABaggageDeliveryCode,
    subPrioritySLABaggageDeliveryId,
    subPrioritySLABaggageDeliveryType,
    subPrioritySLABaggageDeliveryCode,
  ) {
    this.id = id;
    this.firstSLABaggageDeliveryId = firstSLABaggageDeliveryId;
    this.firstSLABaggageDeliveryType = firstSLABaggageDeliveryType;
    this.firstSLABaggageDeliveryCode = firstSLABaggageDeliveryCode;
    this.lastSLABaggageDeliveryId = lastSLABaggageDeliveryId;
    this.lastSLABaggageDeliveryType = lastSLABaggageDeliveryType;
    this.lastSLABaggageDeliveryCode = lastSLABaggageDeliveryCode;
    this.prioritySLABaggageDeliveryId = prioritySLABaggageDeliveryId;
    this.prioritySLABaggageDeliveryType = prioritySLABaggageDeliveryType;
    this.prioritySLABaggageDeliveryCode = prioritySLABaggageDeliveryCode;
    this.subPrioritySLABaggageDeliveryId = subPrioritySLABaggageDeliveryId;
    this.subPrioritySLABaggageDeliveryType = subPrioritySLABaggageDeliveryType;
    this.subPrioritySLABaggageDeliveryCode = subPrioritySLABaggageDeliveryCode;
  }

  static get createEmptyFlightSLABaggageDeliveryInformation() {
    return new FlightSLABaggageDeliveryInformation();
  }

  static createFlightSLABaggageDeliveryInformationFromData(o) {
    if (!o) {
      return this.createEmptyFlightSLABaggageDeliveryInformation;
    }

    return JSUtils.cast(o, FlightSLABaggageDeliveryInformation);
  }
}
