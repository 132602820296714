import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VCard,{attrs:{"dark":_vm.$vuetify.dark}},[_c(VCardText,{staticClass:"loads-table"},[_c(VFlex,{attrs:{"sm6":"","d-flex":"","pt-2":"","pl-2":""}},[_c(VSelect,{attrs:{"items":_vm.unitOptions,"item-text":"text","item-value":"value","label":_vm.label,"readonly":!_vm.hasPermissions(_vm.rolePermission)},model:{value:(_vm.unitLoad),callback:function ($$v) {_vm.unitLoad=$$v},expression:"unitLoad"}})],1)],1),_c(VDivider),_c(VCardText,{staticClass:"loads-table"},[_c('ag-grid-vue',{staticClass:"ag-theme-material",attrs:{"columnDefs":_vm.columnDefs,"defaultColDef":_vm.defaultColDef,"gridOptions":_vm.gridOptions,"frameworkComponents":_vm.frameworkComponents,"rowData":_vm.rowData},on:{"grid-ready":_vm.setAgGridApi}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }