<template>
  <v-layout row justify-center>
    <v-dialog
      v-model="show"
      lazy
      width="500"
    >
      <v-card color="bggrey" v-if="connectedFlights">
        <v-card-text>
          <FlightDetail :flight-composition="connectedFlights.inbound" />
          <FlightDetail :flight-composition="connectedFlights.outbound" />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-layout>
</template>
<script>
import FlightStatus from './FlightStatus.vue';
import FlightDetail from './FlightDetail.vue';

export default {
  name: 'ConnectedFlightsDetailDialog',
  components: { FlightDetail, FlightStatus },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    connectedFlights: {
      type: Object,
    },
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
};
</script>
