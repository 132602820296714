<template>
  <v-checkbox
    color="primary"
    class="day-of-week"
    v-model="checkValue"
    :value="value"
    :label="label"
    @change="$emit('input', $event)"
    hide-details
  ></v-checkbox>
</template>

<script>
export default {
  name: 'DayOfWeekCheckbox',
  props: ['value', 'label'],
  data() {
    return {
      checkValue: this.value,
    };
  },
};
</script>

<style>
.day-of-week label {
  left:-50% !important;
  top: -20px !important;
  min-width: 4ch !important;
}
</style>
