export default class TowingAdhocTask {
  constructor(
    id, requestTs, requestedTs, requestedEndTs, startTs, finishTs, serviceId, serviceName, serviceDescription, units, remarks, requesterId, requesterName,
    originId, originCode, destinationId, destinationCode, status, flightId, flightDate, flightTripNumber, aircraftId, aircraftRegisterNumber, aircraftTypeId,
    aircraftTypeIataCode, aircraftTypeIcaoCode, aircraftTypeCode, bound, airlineId, airlineCode, airport1Id, airport1IataCode, airport1IcaoCode, airport1Code, aircraftGround,
  ) {
    this.id = id;
    this.requestTs = requestTs;
    this.requestedTs = requestedTs;
    this.requestedEndTs = requestedEndTs;
    this.startTs = startTs;
    this.finishTs = finishTs;
    this.serviceId = serviceId;
    this.serviceName = serviceName;
    this.serviceDescription = serviceDescription;
    this.units = units;
    this.remarks = remarks;
    this.requesterId = requesterId;
    this.requesterName = requesterName;
    this.originId = originId;
    this.originCode = originCode;
    this.destinationId = destinationId;
    this.destinationCode = destinationCode;
    this.status = status;
    this.flightId = flightId;
    this.flightDate = flightDate;
    this.flightTripNumber = flightTripNumber;
    this.aircraftId = aircraftId;
    this.aircraftRegisterNumber = aircraftRegisterNumber;
    this.aircraftTypeId = aircraftTypeId;
    this.aircraftTypeIataCode = aircraftTypeIataCode;
    this.aircraftTypeIcaoCode = aircraftTypeIcaoCode;
    this.aircraftTypeCode = aircraftTypeCode;
    this.bound = bound;
    this.airlineId = airlineId;
    this.airlineCode = airlineCode;
    this.airport1Id = airport1Id;
    this.airport1IataCode = airport1IataCode;
    this.airport1IcaoCode = airport1IcaoCode;
    this.airport1Code = airport1Code;
    this.aircraftGround = aircraftGround;
  }

  static createEmptyTowingAdhocTask() {
    return new TowingAdhocTask();
  }

  isNew() {
    return !(this.id);
  }

  flightNo() {
    let flightNumber = this.airlineCode ? `${this.airlineCode}` : '';
    flightNumber += this.flightTripNumber ? `${this.flightTripNumber}` : '';
    return flightNumber;
  }

  setChildPropertyValue(property, value) {
    if (!property.constructor === Array) {
      throw Error('pathArray has to be an Array');
    }

    const schema = this; // a moving reference to internal objects within obj

    schema[property] = value;
  }
}
