<template>
  <v-card :dark="this.$vuetify.dark" :color="this.$vuetify.dark ? 'bggrey_dark' : 'bggrey'">
    <v-card-text>
      <slot></slot>
    </v-card-text>

    <v-card-actions>
      <slot name="footer"></slot>
    </v-card-actions>
  </v-card>
</template>
<script>
export default {
  name: 'ag-grid-contingency-form-template',
  props: {

  },
  methods: {
  },
};
</script>
