<template>
  <v-fab-transition v-if="hasPermissions(rolePermission)">
    <v-speed-dial
      v-if="!selectionMode"
      class="scheduling-options-button"
      v-model="fabSingle"
      :key="activeSpeedDial.key"
      direction="top"
      :open-on-hover="activeSpeedDial.hover"
      :transition="activeSpeedDial.transition"
    >
      <v-btn
        v-model="fabSingle"
        slot="activator"
        color="primary"
        dark
        fab
      >
        <v-icon>add</v-icon>
        <v-icon>close</v-icon>
      </v-btn>

      <v-tooltip left>
        <v-btn
          slot="activator"
          fab
          dark
          small
          color="secondary"
          @click="clickFlight">
          <v-icon>flight</v-icon>
        </v-btn>
        <span>{{$t("scheduling.newFlight")}}</span>
      </v-tooltip>

      <v-tooltip left>
        <v-btn
          slot="activator"
          fab
          dark
          small
          color="secondary"
          @click="clickLongTermFlight">
          <!--<svgicon icon="flights" width="22" height="18" color="white"></svgicon>-->
          <img height="22" :src="require('@/assets/svg-icons/flights-w.svg')">
        </v-btn>
        <span>{{$t("scheduling.longTermFlights")}}</span>
      </v-tooltip>

      <v-tooltip left>
        <v-btn
          slot="activator"
          fab
          dark
          small
          color="secondary"
          @click="clickImport">
          <v-icon>attach_file</v-icon>
        </v-btn>
        <span>{{$t("scheduling.importFlightsFromFile")}}</span>
      </v-tooltip>

    </v-speed-dial>

    <v-speed-dial
      v-else-if="selectionMode"
      fixed bottom right
      v-model="fabSelection"
      :key="activeSpeedDial.key"
      direction="left"
      :open-on-hover="activeSpeedDial.hover"
      :transition="activeSpeedDial.transition"
    >
      <v-btn
        v-model="fabSelection"
        slot="activator"
        color=""
        dark
        fab
      >
        <v-icon>build</v-icon>
        <v-icon>close</v-icon>
      </v-btn>

      <v-tooltip top>
        <v-btn
          slot="activator"
          fab
          dark
          small
          color="secondary"
          @click="clickLink">
          <img height="22" :src="require('@/assets/svg-icons/linkflights-w.svg')">
        </v-btn>
        <span>{{$t("scheduling.findConnectionsOnSelectedFlights")}}</span>
      </v-tooltip>

      <v-tooltip top>
        <v-btn
          slot="activator"
          fab
          dark
          small
          color="secondary"
          @click="clickUnlink">
          <v-icon>link_off</v-icon>
        </v-btn>
        <span>{{$t("scheduling.breakConnectionsOnSelectedFlights")}}</span>
      </v-tooltip>

      <v-tooltip left>
        <v-btn
          slot="activator"
          fab
          dark
          small
          color="secondary"
          @click="clickCancelFlights">
          <span>CF</span>
        </v-btn>
        <span>{{$t("common.cancelFlights")}}</span>
      </v-tooltip>

      <v-tooltip top>
        <v-btn
          slot="activator"
          fab
          dark
          small
          color="primary"
          @click="clickDelete">
          <v-icon>delete</v-icon>
        </v-btn>
        <span>{{$t("scheduling.deleteSelectedFlights")}}</span>
      </v-tooltip>

    </v-speed-dial>
  </v-fab-transition>
</template>
<script>
import RolePermissions from '../../mixins/RolePermissions.vue';

export default {
  name: 'scheduling-list-options',
  mixins: [RolePermissions],
  data: () => ({
    fabSingle: false,
    fabSelection: false,
  }),
  computed: {
    selectionMode() {
      return this.$store.getters.hasSelectedFlights;
    },
    activeSpeedDial() {
      if (this.selectionMode) {
        return {
          key: 'selection-speed-dial',
          direction: 'left',
          hover: false,
          transition: 'slide-y-reverse-transition',
        };
      }
      return {
        key: 'single-speed-dial',
        direction: 'top',
        hover: false,
        transition: 'slide-x-reverse-transition',
      };
    },
    rolePermission() {
      return `${this.$route.meta.role}_WR`;
    },
  },
  methods: {
    clickFlight() {
      this.$emit('clickFlight');
    },
    clickLongTermFlight() {
      this.$emit('clickLongTermFlight');
    },
    clickLink() {
      this.$emit('clickLinkFlights');
    },
    clickUnlink() {
      this.$emit('clickUnlinkFlights');
    },
    clickDelete() {
      this.$emit('clickDeleteFlights');
    },
    clickImport() {
      this.$emit('clickImport');
    },
    clickCancelFlights() {
      this.$emit('clickCancelFlights');
    },
  },
};
</script>
<style>
.scheduling-options-button {
  position: absolute;
  margin: 0;
  padding: 0;
  bottom: 6px;
  right: 0;
  z-index: 9999;
}
</style>
