<template>
  <v-container>
    <v-expansion-panel>
      <v-expansion-panel-content class="exp-header">
        <template v-slot:actions>
          <v-icon color="white">mdi-menu-down</v-icon>
        </template>
        <template v-slot:header>
          <div>
            <h4 class="headline font-weight-light"><v-icon dark left>supervisor_account</v-icon>{{$t("contingencies.passageState")}}</h4>
          </div>
        </template>
        <v-card :dark="this.$vuetify.dark">
          <v-card-text>
            <v-container grid-list-md text-xs-center fluid>
              <v-layout>
                <v-flex xs3>
                  <label class="primary--text subheading">{{$t("contingencies.paxMoodStatus")}}</label>
                </v-flex>
              </v-layout>
              <v-layout>
                <v-flex xs2><v-checkbox color="primary" v-model="contingencyPassageState.passageMoodChild" :label="this.$i18n.t('contingencies.paxChild')" :disabled="!hasPermissions(rolePermission)"></v-checkbox></v-flex>
                <v-flex xs2><v-checkbox color="primary" v-model="contingencyPassageState.passageMoodUpset" :label="this.$i18n.t('contingencies.paxUpset')" :disabled="!hasPermissions(rolePermission)"></v-checkbox></v-flex>
                <v-flex xs2><v-checkbox color="primary" v-model="contingencyPassageState.passageMoodRiot" :label="this.$i18n.t('contingencies.paxRiot')" :disabled="!hasPermissions(rolePermission)"></v-checkbox></v-flex>
                <v-flex xs2><v-checkbox color="primary" v-model="contingencyPassageState.passageMoodDisruptive" :label="this.$i18n.t('contingencies.paxDisruptives')" :disabled="!hasPermissions(rolePermission)"></v-checkbox></v-flex>
                <v-flex xs2><v-checkbox color="primary" v-model="contingencyPassageState.passageMoodComments" :label="this.$i18n.t('contingencies.paxCommentMessages')" :disabled="!hasPermissions(rolePermission)"></v-checkbox></v-flex>
                <v-flex xs2><v-checkbox color="primary" v-model="contingencyPassageState.passageMoodOther" :label="this.$i18n.t('contingencies.other')" :disabled="!hasPermissions(rolePermission)"></v-checkbox></v-flex>
              </v-layout>
              <v-layout>
                <v-flex xs12>
                  <v-textarea :label="this.$i18n.t('contingencies.otherObservations')" v-model="contingencyPassageState.passageBehaviourComments"  box rows="2" :readonly="!hasPermissions(rolePermission)"></v-textarea>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
          <v-card-actions v-if="hasPermissions(rolePermission)">
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-container>
</template>

<script>


import ContingencyPassageState from '../model/contingencyPassageState';
import Saveable from '../../mixins/Saveable.vue';
import RolePermissions from '../../mixins/RolePermissions.vue';

export default {
  name: 'contingency-passage-state-form',
  mixins: [Saveable, RolePermissions],
  components: {

  },
  props: {
    editedContingencyPassageState: { type: ContingencyPassageState, required: true },
  },
  data() {
    return {
      contingencyPassageState: this.editedContingencyPassageState,
    };
  },
  computed: {
    rolePermission() {
      return `${this.$route.meta.role}_WR`;
    },
  },
  watch: {
    editedContingencyPassageState: {
      handler(newContingencyPassageStateProp) {
        this.contingencyPassageState = newContingencyPassageStateProp;
      },
      deep: true,
    },
  },
  methods: {
    save() {
      this.$emit('contingency-passage-state-update', this.contingencyPassageState);
    },
  },
};
</script>

<style scoped lang="scss">
.exp-header {
  background: #FF4021 !important;
  color: white !important;
  border-bottom: 1px solid white;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
}
</style>
