<template>
  <v-container grid-list-md text-xs-center fluid>

    <span style="font-weight: bold">{{$t('lostAndFound.deliveryInformation')}}</span>

    <v-layout row wrap>

      <v-flex xs2 class="inputDelivery">
        <date-input v-char-length.date v-model="delivery.date" :locale="$i18n.locale" :label="$t('common.date')" :rules="[rules.required]"></date-input>
      </v-flex>

      <v-flex xs2 class="inputDelivery">
        <v-text-field :label="$t('lostAndFound.fileNumber')" v-model="delivery.fileNumber"></v-text-field>
      </v-flex>

      <v-flex xs2 class="inputDelivery">
        <v-text-field :label="$t('lostAndFound.numberOfBags')" type="number" v-model="delivery.numberBag"></v-text-field>
      </v-flex>

      <v-flex xs2 class="inputDelivery">
        <v-text-field label="Kgs" type="number" v-model="delivery.loadBag"></v-text-field>
      </v-flex>

      <v-flex xs2 class="inputDelivery">
        <v-text-field :label="$t('common.type')" v-model="delivery.type"></v-text-field>
      </v-flex>

    </v-layout>

    <v-layout row wrap>

      <v-flex xs4 class="inputDelivery">
        <v-text-field :label="$t('lostAndFound.paxNameFileReference')" v-model="delivery.passengerName"></v-text-field>
      </v-flex>

      <v-flex xs4 class="inputDelivery">
        <v-text-field :label="$t('lostAndFound.deliveryAddres')" v-model="delivery.address"></v-text-field>
      </v-flex>

      <v-flex xs2 class="inputDelivery">
        <v-text-field :label="$t('lostAndFound.city')" v-model="delivery.city"></v-text-field>
      </v-flex>

      <v-flex xs2 class="inputDelivery">
        <v-text-field :label="$t('lostAndFound.postalCode')" v-model="delivery.postalCode"></v-text-field>
      </v-flex>

    </v-layout>

    <v-layout row wrap>

      <v-flex xs2 class="inputDelivery">
        <airport-select :label="$t('deliveries.fs')" :ref="'refComp1'" v-char-length.clearable="3" v-model="delivery.fsId" />
      </v-flex>

      <v-flex xs2 class="inputDelivery">
        <v-text-field :label="$t('deliveries.ir')" type="number" :rules="[rules.maxLenghtTwo]" v-model="delivery.ir" />
      </v-flex>

      <v-flex xs2 class="inputDelivery">
        <v-text-field :label="$t('deliveries.pnr')" v-model="delivery.pnr"/>
      </v-flex>

      <v-flex xs2 class="inputDelivery">
        <v-layout row>
          <v-flex>
            <currency-input :label="$t('deliveries.cost')" v-model="delivery.shippingCost" :currency="delivery.currency"/>
          </v-flex>
          <v-flex>
          <currency-select v-model="delivery.currency" />
          </v-flex>
        </v-layout>
      </v-flex>

      <v-flex xs2 class="inputDelivery">
        <v-text-field :label="$t('deliveries.bdo')" type="number" v-model="delivery.bdo"/>
      </v-flex>

      <v-flex xs2 class="inputDelivery">
        <v-text-field :label="$t('deliveries.provider')" v-model="delivery.provider"/>
      </v-flex>

    </v-layout>

    <v-layout row wrap>

      <v-flex xs12 class="inputDelivery">
        <v-text-field :label="$t('operations.remarksExtended')" v-model="delivery.remarks"></v-text-field>
      </v-flex>

    </v-layout>
  </v-container>
</template>
<script>
import DateInput from '../../../ui/DateInput.vue';
import RolePermissions from '../../../mixins/RolePermissions.vue';
import AirportSelect from '../../../airport/AirportSelect.vue';
import CurrencySelect from '../../../contingencyManagement/common/CurrencySelect.vue';
import CurrencyInput from '../../../currency/CurrencyInput.vue';

export default {
  name: 'delivery-common-form',
  mixins: [RolePermissions],
  components: {
    DateInput,
    AirportSelect,
    CurrencySelect,
    CurrencyInput,
  },
  props: {
    deliveryOriginal: {},
  },
  data() {
    return {
      delivery: this.deliveryOriginal,
      rules: {
        required: value => !!value || this.$i18n.t('validation.fieldRequired'),
        maxLenghtTwo: value => (!value || (value && value.length <= 2)) || this.$i18n.t('validation.twoDigits'),
      },
    };
  },
  computed: {
    rolePermission() {
      return `${this.$route.meta.role}_WR`;
    },
  },
  watch: {
    deliveryOriginal: {
      handler(newDeliveryProp) {
        this.delivery = newDeliveryProp;
      },
      deep: true,
    },
    delivery: {
      handler(newDelivery) {
        this.$emit('delivery-update', newDelivery);
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
  .v-btn-toggle .v-btn.v-btn--active.v-btn--disabled i {
    color: white !important;;
  }

  .inputDelivery {
    margin: auto 0;
  }
</style>
