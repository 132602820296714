export default class FlightPassengerActionDTO {
  constructor(flightId, id, scheduledTs, completedTs, flightTripNumber, actionId, actionName) {
    this.id = id;
    this.flightId = flightId;
    this.scheduledTs = scheduledTs;
    this.completedTs = completedTs;
    this.flightTripNumber = flightTripNumber;
    this.actionId = actionId;
    this.actionName = actionName;
  }

  static createFlightPassengerActionDTO(flightId) {
    return new FlightPassengerActionDTO(flightId);
  }
}
