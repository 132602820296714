/* eslint no-param-reassign: "off" */
import moment from 'moment/moment';
import { HTTP } from '../../../http';
import { REQUEST_ERROR, REQUEST_START, REQUEST_SUCCESS } from '../../store';
import { NOTIFICATION_SHOW } from '../notifications';
import deliveryFilter from '../../../components/lostAndFound/delivery/model/deliveryFilter';
import FlightDeliveryBaggageFactory from '../../../components/shared/model/FlightDeliveryBaggageFactory';
import { createFlightDeliveryBaggageEventFromData } from '../../../components/shared/model/flightDeliveryBaggageEvent';
import { JSUtils } from '../../../components/shared/js/JSUtils';
import i18n from '../../../plugins/i18n';

// action
export const DELIVERY_SET_GRID_API = 'DELIVERY_SET_GRID_API';

export const FIND_DELIVERIES = 'FIND_DELIVERIES';
export const PRINT_DELIVERIES = 'PRINT_DELIVERIES';
export const DELIVERIES_UPDATE = 'DELIVERIES_UPDATE';
export const DELIVERY_DO_EXTERNAL_CHANGE = 'DELIVERY_DO_EXTERNAL_CHANGE';
export const DELIVERIES_DELETE = 'DELIVERIES_DELETE';
export const DELIVERY_FIT_COLUMNS_TO_VIEW = 'DELIVERY_FIT_COLUMNS_TO_VIEW';
export const DELIVERY_FIT_COLUMNS_TO_BEST = 'DELIVERY_FIT_COLUMNS_TO_BEST';

// mutations
export const FIND_DELIVERIES_SUCCESS = 'FIND_DELIVERIES_SUCCESS';
export const CHANGE_DELIVERY_SELECTION = 'CHANGE_DELIVERY_SELECTION';
export const DELIVERY_ON_EXTERNAL_CHANGE = 'DELIVERY_ON_EXTERNAL_CHANGE ';
export const DELIVERY_SET_CHANGE = 'DELIVERY_SET_CHANGE ';

export const DELIVERY_FILTER_RESET = 'DELIVERY_FILTER_RESET ';
export const DELIVERY_FILTER_RESET_TIMEZONE = 'DELIVERY_FILTER_RESET_TIMEZONE ';
export const DELIVERY_FILTER_INCREASE_DAY = 'DELIVERY_FILTER_INCREASE_DAY ';
export const DELIVERY_FILTER_DECREASE_DAY = 'DELIVERY_FILTER_DECREASE_DAY ';
export const DELIVERY_FILTER_SET_DAY = 'DELIVERY_FILTER_SET_DAY ';

export const UPDATE_SHOW_OLD_VALUES = 'UPDATE_SHOW_OLD_VALUES ';
export const UPDATE_FLASH_ON_CHANGES = 'UPDATE_FLASH_ON_CHANGES ';
export const DELIVERY_UPDATE_FIT_COLUMNS_ON_RESIZE = 'DELIVERY_UPDATE_FIT_COLUMNS_ON_RESIZE ';

// initial state
const storeState = {
  gridApi: null,
  filter: deliveryFilter.createEmptyFilter,
  deliveries: [],
  selectedDeliveries: [],
  deliveriesChanges: [],
  showOldValues: true,
  flashOnChanges: false,
  fitColumnsOnResize: true,
};

// getters
const storeGetters = {
  hasSelectedDeliveries: state => state.selectedDeliveries.length > 0,
  isSelectedDeliveries: state => id => !!state.selectedDeliveries.find(s => s.getId() === id),
};

// actions
const storeActions = {
  [FIND_DELIVERIES]: ({ commit, state }) => {
    console.log(state.filter); // eslint-disable-line no-console
    commit(REQUEST_START);
    commit(CHANGE_DELIVERY_SELECTION, []);
    return HTTP.post('delivery-baggage/find', state.filter)
      .then((resp) => {
        commit(REQUEST_SUCCESS);
        commit(
          FIND_DELIVERIES_SUCCESS,
          FlightDeliveryBaggageFactory.mapFlightDeliveryBaggage(resp.data),
        );
        commit(CHANGE_DELIVERY_SELECTION, []);
      })
      .catch((err) => {
        commit(FIND_DELIVERIES_SUCCESS, []);
        commit(REQUEST_ERROR, err);
      });
  },
  [PRINT_DELIVERIES]: ({ state, commit }) => {
    commit(REQUEST_START);
    return HTTP.post('delivery-baggage/print', state.filter, { responseType: 'blob' })
      .then((response) => {
        commit(REQUEST_SUCCESS);
        const filename = JSUtils.getFilenameFromContentDisposition(response.headers['content-disposition']);
        JSUtils.downloadFile(response.data, filename);
      })
      .catch((err) => {
        commit(REQUEST_ERROR, err);
      });
  },
  [DELIVERIES_UPDATE]: ({ commit }, { deliveriesList, field }) => new Promise((resolve, reject) => {
    commit(REQUEST_START);
    // TODO FixMe One request
    const lastIteration = (index, array) => index === array.length - 1;

    if (deliveriesList && deliveriesList.length > 0) {
      deliveriesList.forEach((s, index, array) => {
        HTTP.put('delivery-baggage', s)
          .then((response) => {
            if (lastIteration(index, array)) {
              commit(REQUEST_SUCCESS);
              commit(NOTIFICATION_SHOW, {
                message: field ? `${i18n.t('notifications.deliveryFieldUpdate')} ${field}` : i18n.t('notifications.deliveryUpdate'),
                color: 'success',
              });
              resolve(response);
            }
          })
          .catch((err) => {
            commit(REQUEST_ERROR, err);
            reject(err);
          });
      });
    }
  }),
  [DELIVERY_DO_EXTERNAL_CHANGE]: ({ commit }, change) => {
    commit(DELIVERY_ON_EXTERNAL_CHANGE, createFlightDeliveryBaggageEventFromData(change));
  },
  [DELIVERIES_DELETE]: ({ commit, dispatch }, ids) => {
    commit(REQUEST_START);
    return HTTP.post('delivery-baggage/batch-delete', ids)
      .then(() => {
        commit(REQUEST_SUCCESS);
        dispatch(FIND_DELIVERIES);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.deliveryDelete'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
  [DELIVERY_FIT_COLUMNS_TO_VIEW]: ({ state }) => {
    if (state.gridApi) {
      state.gridApi.sizeColumnsToFit();
    }
  },
  [DELIVERY_FIT_COLUMNS_TO_BEST]: ({ state }) => {
    if (state.gridApi && state.gridApi.columnController && state.gridApi.columnController.columnApi) {
      state.gridApi.columnController.columnApi.autoSizeAllColumns();
    }
  },
};

// mutations
const storeMutations = {
  [DELIVERY_SET_GRID_API]: (state, gridApi) => {
    state.gridApi = gridApi.api;
  },
  [FIND_DELIVERIES_SUCCESS]: (state, deliveries) => {
    state.deliveries = deliveries;
  },
  [CHANGE_DELIVERY_SELECTION]: (state, selection) => {
    state.selectedDeliveries = selection;
  },
  [DELIVERY_ON_EXTERNAL_CHANGE]: (state, change) => {
    console.log(`mutations external change ${change}`); // eslint-disable-line no-console
  },
  [DELIVERY_SET_CHANGE]: (state, change) => {
    change.ts = moment();
    state.deliveriesChanges.unshift(change);
    state.deliveriesChanges = state.deliveriesChanges.slice(0, 10);
  },
  [DELIVERY_FILTER_RESET]: (state) => {
    state.filter.dateFrom = moment().startOf('day');
    state.filter.dateTo = moment().endOf('day');

    state.filter.timezoneDate = moment.defaultZone.name;
  },
  [DELIVERY_FILTER_RESET_TIMEZONE]: (state) => {
    state.filter.dateFrom = moment().startOf('day')
      .year(state.filter.dateFrom.year())
      .month(state.filter.dateFrom.month())
      .date(state.filter.dateFrom.date());
    state.filter.dateTo = moment().endOf('day')
      .year(state.filter.dateTo.year())
      .month(state.filter.dateTo.month())
      .date(state.filter.dateTo.date());

    state.filter.timezoneDate = moment.defaultZone.name;
  },
  [DELIVERY_FILTER_INCREASE_DAY]: (state) => {
    state.filter.dateFrom = moment().startOf('day')
      .year(state.filter.dateFrom.year())
      .month(state.filter.dateFrom.month())
      .date(state.filter.dateFrom.date())
      .add(1, 'days')
      .startOf('day');

    state.filter.dateTo = moment().endOf('day')
      .year(state.filter.dateTo.year())
      .month(state.filter.dateTo.month())
      .date(state.filter.dateTo.date())
      .add(1, 'days');
  },
  [DELIVERY_FILTER_DECREASE_DAY]: (state) => {
    state.filter.dateFrom = moment().startOf('day')
      .year(state.filter.dateFrom.year())
      .month(state.filter.dateFrom.month())
      .date(state.filter.dateFrom.date())
      .subtract(1, 'days');

    state.filter.dateTo = moment().endOf('day')
      .year(state.filter.dateTo.year())
      .month(state.filter.dateTo.month())
      .date(state.filter.dateTo.date())
      .subtract(1, 'days')
      .endOf('day');
  },
  [DELIVERY_FILTER_SET_DAY]: (state, dateSelected) => {
    state.filter.dateFrom = moment(dateSelected).startOf('day');

    state.filter.dateTo = moment(dateSelected).endOf('day');
  },
  [UPDATE_SHOW_OLD_VALUES]: (state, showOldValues) => {
    state.showOldValues = showOldValues;
  },
  [UPDATE_FLASH_ON_CHANGES]: (state, flashOnChanges) => {
    state.flashOnChanges = flashOnChanges;
  },
  [DELIVERY_UPDATE_FIT_COLUMNS_ON_RESIZE]: (state, fitColumnsOnResize) => {
    state.fitColumnsOnResize = fitColumnsOnResize;
  },
};

export default {
  state: storeState,
  getters: storeGetters,
  actions: storeActions,
  mutations: storeMutations,
};
