import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VLayout,{attrs:{"row":"","justify-center":""}},[_c(VDialog,{attrs:{"persistent":"","max-width":"800px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,{attrs:{"dark":this.$vuetify.dark,"color":this.$vuetify.dark ? 'bggrey_dark' : 'bggrey'}},[_c(VToolbar,{attrs:{"dark":"","color":"primary"}},[_c(VIcon,[_vm._v("link_off")]),_c(VToolbarTitle,[_vm._v("Confirm GS PLANINIG")])],1),_c(VCardText,[_vm._v(" Filters made in the table will not be taken into account in the GS Planning file. Do you what to continue? ")]),_c(VCardActions,[_c(VBtn,{attrs:{"flat":"","color":"primary"},on:{"click":_vm.reject}},[_vm._v("Cancel")]),_c(VSpacer),_c(VBtn,{attrs:{"color":"primary"},on:{"click":_vm.confirm}},[_vm._v("Confirm")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }