<template>
  <v-layout>
    <template>
      <directory-modal-edit v-if="contactDialogEdit" :dialog="contactDialogEdit" :item="itemEdit" @edit-confirm="contactEditConfirm" @edit-reject="contactEditReject" element="Gate contact" :icon="icon">
        <contact-edit-modal-content :item="itemEdit"/>
      </directory-modal-edit>
    </template>
    <template>
      <contact-modal v-if="contactModal" :dialog="contactModal" :item="item" @modal-close="contactModalClose" :icon="icon" :title="itemTitle" @modal-edit="contactEdit" @modal-delete="deleteContact" :msg="msg" :btnText="btnText" :deleteable="deleteable"/>
    </template>
    <v-flex xs12>
      <v-card>
        <v-card-title >
          <span class="headline">
            <v-icon color="primary" left large>{{ icon }}</v-icon>
            {{ title }}
          </span>
          <v-spacer></v-spacer>
          <v-text-field v-model="search" append-icon="search" label="Search" hide-details></v-text-field>
        </v-card-title>
        <v-data-table :headers="headers" :items="items" :search="search" hide-actions expand>
          <template v-slot:items="props">
            <tr v-if="props.item" @click="showItem(props.item)">
              <td>{{ props.item.code || props.item.contact.name }}</td>
              <td>{{ props.item.contact.mail }}</td>
              <td>{{ props.item.contact.phone }}</td>
              <td>{{ props.item.contact.phoneMobile }}</td>
            </tr>
          </template>
          <template v-slot:no-results>
            Your search for <b>"{{ search }}"</b> found no results.
          </template>
        </v-data-table>
      </v-card>
    </v-flex>
    <contact-panel-speed-dial v-if="speedDial" @click-new-item="addContact" :tooltipTextAdd="tooltipTextAdd" :tooltipOpenButton="tooltipOpenButton"/>
  </v-layout>
</template>

<script>
import ContactModal from './common/ContactModal.vue';
import DirectoryModalEdit from './common/DirectoryModalEdit.vue';
import ContactEditModalContent from './common/ContactEditModalContent.vue';
import ContactPanelSpeedDial from './common/ContactPanelSpeedDial.vue';

const contactObject = {
  addressCity: null,
  addressCountry: null,
  addressPostalCode: null,
  addressRegion: null,
  addressStreet: null,
  description: null,
  fax: null,
  imageData: null,
  imageIdentifier: null,
  imageUrl: null,
  mail: null,
  name: null,
  phone: null,
  phoneMobile: null,
};

export default {
  name: 'ContactPanel',
  props: {
    items: {
      type: Array,
    },
    icon: {
      type: String,
    },
    title: {
      type: String,
    },
    msg: {
      type: String,
    },
    btnText: {
      type: String,
      default: 'Add contact info',
    },
    deleteable: {
      type: Boolean,
      default: false,
    },
    speedDial: {
      type: Boolean,
      default: false,
    },
    tooltipTextAdd: String,
    tooltipOpenButton: String,
  },
  components: {
    ContactModal,
    DirectoryModalEdit,
    ContactEditModalContent,
    ContactPanelSpeedDial,
  },
  data: () => ({
    search: '',
    headers: [
      { text: 'Name', value: 'contact.name' },
      { text: 'E-mail', value: 'contact.mail' },
      { text: 'Phone number', value: 'contact.phone' },
      { text: 'Mobile phone', value: 'contact.phoneMobile' },
    ],
    contactDialogEdit: false,
    contactModal: false,
    itemEdit: null,
    item: null,
  }),
  methods: {
    showItem(item) {
      this.item = item;
      this.itemTitle = `${this.title} ${item.code || item.contact.name}`;
      this.contactModal = true;
    },
    addContact() {
      this.itemEdit = { contact: {} };
      this.contactDialogEdit = true;
    },
    deleteContact(item) {
      this.$emit('contact-delete', item.id);
      this.contactModal = false;
    },
    contactEdit(item) {
      this.itemEdit = item.contact !== null ? item : { ...item, ...{ contact: contactObject } };
      this.contactModal = false;
      this.contactDialogEdit = true;
    },
    contactEditConfirm(item) {
      this.itemEdit = null;
      this.$emit(!item.id ? 'contact-add' : 'contact-update', item);
      this.contactDialogEdit = false;
    },
    contactEditReject() {
      this.itemEdit = null;
      this.contactDialogEdit = false;
    },
    contactModalClose() {
      this.contactModal = false;
    },
  },
};
</script>
