import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListTile } from 'vuetify/lib/components/VList';
import { VListTileContent } from 'vuetify/lib/components/VList';
import { VListTileSubTitle } from 'vuetify/lib/components/VList';
import { VListTileTitle } from 'vuetify/lib/components/VList';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{staticClass:"px-0",attrs:{"grid-list-md":"","fluid":""}},[_c(VLayout,{attrs:{"row":"","wrap":"","align-start":"","justify-center":""}},_vm._l((_vm.filterTenants),function(value,key){return _c(VFlex,{key:key,staticClass:"pa-2",attrs:{"xs12":"","sm6":"","md5":"","lg2":""}},[_c(VExpansionPanel,{staticClass:"elevation-3 custom-expansion",attrs:{"value":_vm.expand,"dark":_vm.$vuetify.dark}},[_c(VExpansionPanelContent,{scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c(VIcon,{attrs:{"color":"primary"}},[_vm._v("keyboard_arrow_down")])]},proxy:true},{key:"header",fn:function(){return [_c('p',{staticClass:"title text-xs-center ma-0 font-weight-regular"},[_vm._v(_vm._s(key))])]},proxy:true}],null,true)},[_c(VCard,[_c(VList,{attrs:{"two-line":""}},_vm._l((value),function(item){return _c(VListTile,{key:item.title,on:{"click":function($event){return _vm.selectItem(item)}}},[_c(VListTileContent,[_c(VListTileTitle,[_vm._v(_vm._s(item.description))]),_c(VListTileSubTitle,[_vm._v(_vm._s(item.name))])],1)],1)}),1)],1)],1)],1)],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }