<script>
import Vue from 'vue';

export default Vue.extend({
  methods: {
    s2ab(s) {
      const buf = new ArrayBuffer(s.length);
      const view = new Uint8Array(buf);
      for (let i = 0; i !== s.length; i += 1) view[i] = s.charCodeAt(i) & 0xFF; // eslint-disable-line no-bitwise
      return buf;
    },
  },
});
</script>
