<template>
  <v-card-text class="form">
    <v-container fluid>
      <v-layout row wrap>
        <v-flex xs4 pr-2>
          <v-text-field :label="this.$i18n.t('configuration.name')" v-model="item.firstName"
            :readonly="!hasPermissions(rolePermission)" />
        </v-flex>
        <v-flex xs4 px-2>
          <v-text-field :label="this.$i18n.t('configuration.lastName')" v-model="item.lastName"
            :readonly="!hasPermissions(rolePermission)" />
        </v-flex>
        <v-flex xs4 px-2>
          <v-checkbox color="primary" :label="this.$i18n.t('configuration.activated')" v-model="item.activated"
            :disabled="!hasPermissions(rolePermission)" />
        </v-flex>
        <v-flex xs4 pr-2>
          <v-text-field :label="this.$i18n.t('configuration.email')" v-model="item.email"
            :rules="[rules.required, rules.email]" :readonly="!hasPermissions(rolePermission)" />
        </v-flex>
        <v-flex xs4 px-2>
          <handling-agent-select :label="this.$i18n.t('configuration.handlingAgent')" :value="item.agentType"
            :rules="[rules.required]" @input="changeHandlingAgent" :readonly="!hasPermissions(rolePermission)" />
        </v-flex>
        <v-flex xs4 px-2>
          <service-agent-select :label="this.$i18n.t('configuration.serviceAgent')" :value="item.serviceAgent"
            @input="changeServiceAgent" :readonly="!hasPermissions(rolePermission)" />
        </v-flex>
        <v-flex xs4 px-2>
          <v-checkbox color="primary" :label="this.$i18n.t('configuration.accountAuthenticated')"
            v-model="item.accountAuthenticated" disabled />
        </v-flex>
      </v-layout>
      <v-layout row wrap v-if="!item.authorities">
        <v-flex xs12>
          <v-alert type="error" :value="true" class="mt-3" outline>
            {{ $t('configuration.authoritiesMessage') }}
          </v-alert>
        </v-flex>
      </v-layout>
      <v-layout row wrap style="height: 40vh" v-if="item.authorities">
        <v-flex xs4>
          <v-navigation-drawer height="40vh">
            <v-list>
              <v-list-tile :class="{ 'active-menu': selectedTenant === 'all' }" key="all" @click="selectTenant('all')">
                <v-list-tile-content>
                  <v-list-tile-title><v-icon>public</v-icon>
                    {{ $t("configuration.globalAuthorities") }}</v-list-tile-title>
                </v-list-tile-content>
              </v-list-tile>
              <v-list-tile :class="{ 'active-menu': selectedTenant === tenant }" v-for="tenant in tenants"
                :key="tenant.id" @click="selectTenant(tenant)">
                <v-list-tile-content>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-list-tile-title v-on="on"><v-icon>apartment</v-icon> {{ tenant.name
                        }}-{{ tenant.description }}</v-list-tile-title>
                    </template>
                    <span>{{ tenant.name }}-{{ tenant.description }}</span>
                  </v-tooltip>
                </v-list-tile-content>
              </v-list-tile>
            </v-list>
          </v-navigation-drawer>
        </v-flex>
        <v-flex xs8>
          <v-card flat>
            <v-flex>
              <authority-table label="Authority"
                :itemAuthorities="selectedTenant === 'all' ? item.authorities : itemTenantAuthorities" />
            </v-flex>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-card-text>
</template>
<script>

import AuthoritySelect from '../../authority/AuthoritySelect.vue';
import HandlingAgentSelect from '../../handling/HandlingAgentSelect.vue';
import ServiceAgentSelect from '../../service-agent/ServiceAgentSelect.vue';
import AuthorityTable from '../../authority/AuthorityTable.vue';
import RolePermissions from '../../mixins/RolePermissions.vue';

export default {
  name: 'user-edit-card-content',
  mixins: [RolePermissions],
  props: {
    item: { type: Object, required: true },
  },
  components: {
    AuthoritySelect,
    AuthorityTable,
    HandlingAgentSelect,
    ServiceAgentSelect,
  },
  computed: {
    tenants() {
      return this.$store.getters.getTenant;
    },
    rolePermission() {
      return `${this.$route.meta.role}_WR`;
    },
    itemTenantAuthorities() {
      this.checkTenantAuthorities();
      return this.item.tenantAuthorities[this.selectedTenant.name];
    },
  },
  data() {
    return {
      selectedTenant: 'all',
      rules: {
        required: value => !!value || this.$i18n.t('common.fieldRequired'),
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || this.$i18n.t('configuration.invalidEmail');
        },
      },
    };
  },
  methods: {
    selectTenant(tenant) {
      this.selectedTenant = tenant;
    },
    changeHandlingAgent(handlingAgent) {
      this.item.agentType = { id: handlingAgent };
    },
    changeServiceAgent(serviceAgent) {
      this.item.serviceAgent = serviceAgent ? { id: serviceAgent } : null;
    },
    checkTenantAuthorities() {
      if (this.selectedTenant !== 'all') {
        const itemTenantAuthorities = this.item.tenantAuthorities;
        if (!itemTenantAuthorities[this.selectedTenant.name]) {
          itemTenantAuthorities[this.selectedTenant.name] = [];
        }
        this.item.tenantAuthorities = { ...itemTenantAuthorities };
      }
    },
  },
};
</script>
<style scoped>
.form {
  overflow-y: hidden;
}
</style>
