<template>
  <div>
    <v-tooltip bottom v-if="params.value && tooltip">
      <template slot="activator">
        <v-icon small>{{icon}}</v-icon>
      </template>
      <span>{{tooltip}}</span>
    </v-tooltip>
    <v-icon small v-if="params.value && !tooltip">{{params.icon}}</v-icon>
  </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  computed: {
    tooltip() {
      return this.params.tooltip ? this.params.tooltip(this.params) : null;
    },
    icon() {
      if (this.params.icon && typeof (this.params.icon) === 'function') {
        return this.params.icon ? this.params.icon(this.params) : null;
      }
      return this.params.icon;
    },
  },
});
</script>

<style scoped>

</style>
