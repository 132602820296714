<template>
  <v-card>
    <v-layout align-right justify-center column fill-height>
      <v-flex xs12>
        <v-card-title primary-title>
          <div class="admin-content-cards">
            <div class="headline">{{$t("administration.cleaningGhmLatamIntegrationTitle")}}</div>
            <div class="grey--text text--darken-1">{{$t("administration.cleaningGhmLatamIntegrationText")}}</div>
          </div>
        </v-card-title>
      </v-flex>
      <v-flex xs12>
        <v-layout align-center justify-center column>
          <v-flex xs6>
            <v-dialog v-model="dialog" max-width="50%" v-if="hasPermissions(rolePermission) && ghmLatamIntegration.available">
              <v-btn slot="activator" color="primary" dark>{{$t("administration.cleaningGhmLatamIntegration")}}
              </v-btn>
              <v-card :dark="this.$vuetify.dark" :color="this.$vuetify.dark ? 'bggrey_dark' : 'bggrey'">
                <v-toolbar dark color="primary">
                  <v-icon>dns</v-icon>
                  <v-toolbar-title>{{$t("administration.ghmLatamConfirmation")}}</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                  {{$t("administration.continueAlert")}}
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn flat color="primary" @click.native="cancel">{{$t("common.cancel")}}</v-btn>
                  <v-btn color="primary" depressed @click.native="confirm">{{$t("common.confirm")}}</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-btn v-else color="primary" disabled>{{$t("administration.cleaningGhmLatamIntegration")}}</v-btn>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-card>
</template>
<script>
import { ADMIN_GHM_LATAM_CLEAN } from '../../../../../store/modules/admin/ghm';
import RolePermissions from '../../../../mixins/RolePermissions.vue';


export default {
  name: 'GhmLatamIntegrationClean',
  mixins: [RolePermissions],
  components: {},
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    rolePermission() {
      return `${this.$route.meta.role}_WR`;
    },
    ghmLatamIntegration() {
      return this.$store.state.admin.ghm.ghmLatamIntegration;
    },
  },
  methods: {
    cancel() {
      this.dialog = false;
    },
    confirm() {
      this.$store.dispatch(ADMIN_GHM_LATAM_CLEAN);
      this.dialog = false;
    },
  },
};
</script>
