<template>
  <div>
    <passage-flights-list-filter @changeShowFilter="changeShowFilter" />
    <passage-flights-list-container :isFilterDisplayed="filterDisplayed" />

    <passage-flights-list-options @click-archive="archiveFlights" @click-unarchive="unarchiveFlights" />
  </div>
</template>
<script>
import PassageFlightsListFilter from '../components/passage/outbounds/PassageOutboundFlightsListFilter.vue';
import PassageFlightsListContainer from '../components/passage/outbounds/PassageOutboundFlightsListContainer.vue';
import PassageFlightsListOptions from '../components/passage/outbounds/PassageOutboundFlightsListOptions.vue';
import { PASSAGE_ARCHIVE, PASSAGE_UNARCHIVE } from '../store/modules/passageOutbound';

export default {
  components: {
    PassageFlightsListFilter,
    PassageFlightsListContainer,
    PassageFlightsListOptions,
  },
  computed: {
  },
  data() {
    return {
      filterDisplayed: false,
    };
  },
  methods: {
    archiveFlights() {
      this.$store.dispatch(PASSAGE_ARCHIVE);
    },
    unarchiveFlights() {
      this.$store.dispatch(PASSAGE_UNARCHIVE);
    },
    changeShowFilter(dispayled) {
      this.filterDisplayed = dispayled;
    },
  },
};
</script>
