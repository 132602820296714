export default class ContingencyDetail {
  constructor(
    contingencyId, id, delayReasonMaintenance, delayReasonWeather, delayReasonAircraftNeeded, delayReasonRescheduling, delayReasonOther, affectedPaxLocal,
    affectedPaxTransport, affectedPaxTotalPax, affectedPaxNoTravel, affectedPaxTotalFinal, affectedPaxOther, basicServiceFood, basicServiceHotel, basicServiceTransport,
    basicServiceHotelTransport, basicServiceOther, assistanceWchr, assistanceMaas, assistanceOther, solutionPaxCoverage, solutionPaxConnection, solutionPaxSupport,
    solutionPaxDestinationTransport, solutionPaxOther, solutionPaxCoverageDetails, solutionPaxConnectionDetails, solutionPaxSupportDetails,
    solutionPaxDestinationTransportDetails, solutionPaxOtherDetails, agentsTime, agentsNumber,
  ) {
    this.id = id;
    this.delayReasonMaintenance = delayReasonMaintenance;
    this.delayReasonWeather = delayReasonWeather;
    this.delayReasonAircraftNeeded = delayReasonAircraftNeeded;
    this.delayReasonRescheduling = delayReasonRescheduling;
    this.delayReasonOther = delayReasonOther;
    this.affectedPaxLocal = affectedPaxLocal;
    this.affectedPaxTransport = affectedPaxTransport;
    this.affectedPaxTotalPax = affectedPaxTotalPax;
    this.affectedPaxNoTravel = affectedPaxNoTravel;
    this.affectedPaxTotalFinal = affectedPaxTotalFinal;
    this.affectedPaxOther = affectedPaxOther;
    this.basicServiceFood = basicServiceFood;
    this.basicServiceHotel = basicServiceHotel;
    this.basicServiceTransport = basicServiceTransport;
    this.basicServiceHotelTransport = basicServiceHotelTransport;
    this.basicServiceOther = basicServiceOther;
    this.assistanceWchr = assistanceWchr;
    this.assistanceMaas = assistanceMaas;
    this.assistanceOther = assistanceOther;
    this.solutionPaxCoverage = solutionPaxCoverage;
    this.solutionPaxConnection = solutionPaxConnection;
    this.solutionPaxSupport = solutionPaxSupport;
    this.solutionPaxDestinationTransport = solutionPaxDestinationTransport;
    this.solutionPaxOther = solutionPaxOther;
    this.solutionPaxCoverageDetails = solutionPaxCoverageDetails;
    this.solutionPaxConnectionDetails = solutionPaxConnectionDetails;
    this.solutionPaxSupportDetails = solutionPaxSupportDetails;
    this.solutionPaxDestinationTransportDetails = solutionPaxDestinationTransportDetails;
    this.solutionPaxOtherDetails = solutionPaxOtherDetails;
    this.contingencyId = contingencyId;
    this.agentsTime = agentsTime;
    this.agentsNumber = agentsNumber;
  }

  static createEmptyContingencyDetail() {
    return new ContingencyDetail();
  }

  static createContingencyDetail(contingencyId) {
    return new ContingencyDetail(contingencyId);
  }

  setChildPropertyValue(property, value) {
    if (!property.constructor === Array) {
      throw Error('pathArray has to be an Array');
    }

    const schema = this; // a moving reference to internal objects within obj

    schema[property] = value;
  }

  getChildPropertyValue(property) {
    if (!property.constructor === Array) {
      throw Error('pathArray has to be an Array');
    }

    const schema = this; // a moving reference to internal objects within obj

    return schema[property];
  }
}
