<template>
  <v-fab-transition v-if="hasPermissions(rolePermission)">
    <v-speed-dial
      v-if="!selectionMode"
      fixed bottom right
      v-model="fabSingle"
      :key="activeSpeedDial.key"
      direction="top"
      :open-on-hover="activeSpeedDial.hover"
      :transition="activeSpeedDial.transition"
    >
      <v-btn
        v-model="fabSingle"
        slot="activator"
        color="primary"
        dark
        fab
      >
        <v-icon>add</v-icon>
        <v-icon>close</v-icon>
      </v-btn>

      <v-tooltip left>
        <v-btn
          slot="activator"
          fab
          dark
          small
          color="secondary"
          @click="clickInternalFlight">
          <v-icon>info</v-icon>
        </v-btn>
        <span>{{$t("rushBaggage.newRushBaggageInternal")}}</span>
      </v-tooltip>

      <v-tooltip left>
        <v-btn
          slot="activator"
          fab
          dark
          small
          color="secondary"
          @click="clickExternalFlight">
          <v-icon>explicit</v-icon>
        </v-btn>
        <span>{{$t("rushBaggage.newRushBaggageExternal")}}</span>
      </v-tooltip>

    </v-speed-dial>
    <v-speed-dial
      v-else-if="selectionMode"
      fixed bottom right
      v-model="fabSelection"
      :key="activeSpeedDial.key"
      direction="left"
      :open-on-hover="activeSpeedDial.hover"
      :transition="activeSpeedDial.transition"
    >
      <v-btn
        v-model="fabSelection"
        slot="activator"
        color=""
        dark
        fab
      >
        <v-icon>build</v-icon>
        <v-icon>close</v-icon>
      </v-btn>

      <v-tooltip top>
        <v-btn
          slot="activator"
          fab
          dark
          small
          color="primary"
          @click="clickDelete">
          <v-icon>delete</v-icon>
        </v-btn>
        <span>{{$t("rushBaggage.deleteSelected")}}</span>
      </v-tooltip>

    </v-speed-dial>
  </v-fab-transition>
</template>
<script>
import RolePermissions from '../../../mixins/RolePermissions.vue';

export default {
  name: 'rush-baggage-list-options',
  mixins: [RolePermissions],
  data: () => ({
    fabSingle: false,
    fabSelection: false,
  }),
  computed: {
    selectionMode() {
      return this.$store.getters.hasSelectedRushesBaggage;
    },
    activeSpeedDial() {
      return {
        key: 'single-speed-dial',
        direction: 'top',
        hover: false,
        transition: 'slide-x-reverse-transition',
      };
    },
    rolePermission() {
      return `${this.$route.meta.role}_WR`;
    },
  },
  methods: {
    clickInternalFlight() {
      this.$emit('clickInternalFlight');
    },
    clickExternalFlight() {
      this.$emit('clickExternalFlight');
    },

    clickDelete() {
      this.$emit('clickDeleteRushBaggage');
    },
  },
};
</script>
