import { VCardText } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCardText,[_c(VContainer,{staticStyle:{"margin-bottom":"0","padding-bottom":"0"},attrs:{"fluid":""}},[_c(VLayout,{staticStyle:{"margin-bottom":"0","padding-bottom":"0"},attrs:{"align-center":"","justify-space-between":"","row":""}},[_c(VFlex,{staticStyle:{"margin-right":"2ch","max-width":"15%"},attrs:{"xs6":""}},[_c('airline-select',{attrs:{"label":this.$i18n.t('common.airline'),"multiple":false,"disabled":!_vm.hasPermissions(_vm.rolePermission)},model:{value:(_vm.item.airlineId),callback:function ($$v) {_vm.$set(_vm.item, "airlineId", $$v)},expression:"item.airlineId"}})],1),_c(VFlex,{staticStyle:{"margin-right":"2ch"},attrs:{"xs3":""}},[_c('label',[_vm._v(_vm._s(this.$i18n.t('configuration.startCheckIn')))]),_c(VTextField,{attrs:{"label":` ${this.$i18n.t('configuration.minutesBeforeSelectedHour')}`,"type":"number","readonly":!_vm.hasPermissions(_vm.rolePermission)},model:{value:(_vm.item.startTime),callback:function ($$v) {_vm.$set(_vm.item, "startTime", $$v)},expression:"item.startTime"}})],1),_c(VFlex,{staticStyle:{"margin-right":"2ch"},attrs:{"xs3":""}},[_c('label',[_vm._v(_vm._s(this.$i18n.t('configuration.endCheckIn')))]),_c(VTextField,{attrs:{"label":`${this.$i18n.t('configuration.minutesBeforeSelectedHour')}`,"type":"number","readonly":!_vm.hasPermissions(_vm.rolePermission)},model:{value:(_vm.item.endTime),callback:function ($$v) {_vm.$set(_vm.item, "endTime", $$v)},expression:"item.endTime"}})],1),_c(VFlex,{attrs:{"xs3":""}},[_c(VRadioGroup,{staticClass:"radio-group",attrs:{"row":""},model:{value:(_vm.item.timeType),callback:function ($$v) {_vm.$set(_vm.item, "timeType", $$v)},expression:"item.timeType"}},[_c(VRadio,{attrs:{"label":this.$i18n.t('configuration.scheduledTime'),"color":"primary","value":_vm.timeTypes.ST}}),_c(VRadio,{attrs:{"label":this.$i18n.t('configuration.estimatedTime'),"color":"primary","value":_vm.timeTypes.ET}})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }