<template>
  <div>
    <v-radio-group :label="label" :value="value" @change="input" :rules="rules" :readonly="readonly">
      <v-radio
        :label="$t(`ticketing.${tvpTypes[0]}`)"
        :value="tvpTypes[0]"
        color="primary"></v-radio>
      <v-radio
        :label="$t(`ticketing.${tvpTypes[1]}`)"
        :value="tvpTypes[1]"
        color="primary"></v-radio>
    </v-radio-group>
  </div>
</template>

<script>
export default {
  name: 'tpv-type-selector',
  props: {
    value: { required: true },
    label: { type: String },
    charLenght: { type: Number },
    rules: { type: Array },
    disabled: { type: Boolean },
    readonly: { type: Boolean, default: false },
    multiple: {
      type: Boolean,
      default: false,
    },
    refComp: {
      type: String,
      required: false,
      default: 'refComp',
    },
  },
  data() {
    return {
      tvpTypes: ['INTERNAL', 'EXTERNAL'],
    };
  },
  methods: {
    input(event) {
      this.$emit('input', event);
    },
  },
};
</script>
<style lang="scss" scoped></style>
