<template>
  <div>
    <span>{{params.text}}  </span>
    <v-tooltip bottom v-if="params.value && tooltip">
      <template slot="activator">
        <v-icon small>{{params.icon}}</v-icon>
      </template>
      <span>{{tooltip}}</span>
    </v-tooltip>
    <v-icon small v-if="params.value && !tooltip">{{params.icon}}</v-icon>
  </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  computed: {
    tooltip() {
      return this.params.tooltip ? this.params.tooltip(this.params) : null;
    },
  },
});
</script>

<style scoped>

</style>
