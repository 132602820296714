<template>
  <v-card-text class="form">
    <v-container fluid>
      <v-layout row wrap>
        <v-flex xs4 pr-4>
          <service-agent-select :label="this.$i18n.t('configuration.serviceAgent')" v-model="item.serviceAgentId"
            :rules="[rules.required]" :readonly="!hasPermissions(rolePermission)"
            :showJustCandidates="true" :airportServiceAgentId="item.id" />
        </v-flex>
        <v-flex xs4 pr-4>
          <airport-ramp-service-select :label="this.$i18n.t('configuration.defaultInboundRampService')"
            v-model="item.defaultServiceInboundServiceId" @input-airport-service-id="setDefaultServiceInbound($event)"
            :readonly="!hasPermissions(rolePermission)" />
        </v-flex>
        <v-flex xs4>
          <airport-ramp-service-select :label="this.$i18n.t('configuration.defaultOutboundRampService')"
            v-model="item.defaultServiceOutboundServiceId" @input-airport-service-id="setDefaultServiceOutbound($event)"
            :readonly="!hasPermissions(rolePermission)" />
        </v-flex>
        <v-flex md12 lg4 pr-4 >
          <airport-ramp-service-select :label="this.$i18n.t('configuration.defaultAircraftGroundRampService')"
            v-model="item.defaultServiceAircraftGroundServiceId"
            @input-airport-service-id="setDefaultServiceAircraftGround($event)"
            :readonly="!hasPermissions(rolePermission)"
            class="fixed-height" />
        </v-flex>
        <v-flex md12 lg8 pr-4 >
          <airport-ramp-service-select :label="this.$i18n.t('common.rampServices')" :multiple=true :chip-view=true
            v-model="item.rampServices" :readonly="!hasPermissions(rolePermission)" />
        </v-flex>
      </v-layout>
      <v-layout row wrap>
        <v-flex xs12>
          <v-divider light></v-divider>
          <v-card-title secondary-title class="dispatcher">
            <div>
              <div class="headline">{{ this.$i18n.t('configuration.dispatcherPermissions') }}</div>
            </div>
          </v-card-title>
        </v-flex>
      </v-layout>
      <v-layout row wrap>
        <v-flex xs3 pr-3>
          <v-select v-model="item.dispatcherPermissions.showBaggageDistribution" :items="Object.keys(permissionTypes)"
            :label="this.$i18n.t('configuration.baggageDistribution')" :disabled="!hasPermissions(rolePermission)">
            <template slot="selection" slot-scope="{ item }">
              <span>
                {{ formatPermissionsType(item) }}
              </span>
            </template>
            <template slot="item" slot-scope="{ item }">
              <span>
                {{ formatPermissionsType(item) }}
              </span>
            </template>
          </v-select>
        </v-flex>
        <v-flex xs3 pr-3>
          <v-select v-model="item.dispatcherPermissions.showStandardMessaging" :items="Object.keys(permissionTypes)"
            :label="this.$i18n.t('configuration.standardMessaging')" :disabled="!hasPermissions(rolePermission)">
            <template slot="selection" slot-scope="{ item }">
              <span>
                {{ formatPermissionsType(item) }}
              </span>
            </template>
            <template slot="item" slot-scope="{ item }">
              <span>
                {{ formatPermissionsType(item) }}
              </span>
            </template>
          </v-select>
        </v-flex>
        <v-flex v-flex-none style="margin-right: 2ch; width: 11vw">
          <v-checkbox :label="this.$i18n.t('configuration.filterByAgent')" color="primary"
            v-model="item.dispatcherPermissions.filterByAgent" :disabled="!hasPermissions(rolePermission)" />
        </v-flex>
        <v-flex v-flex-none style="margin-right: 2ch; width: 14vw">
          <v-checkbox :label="this.$i18n.t('configuration.showBaggageSearch')" color="primary"
            v-model="item.dispatcherPermissions.showBaggageSearch" :disabled="!hasPermissions(rolePermission)" />
        </v-flex>
        <AirportActiveMilestonesEditCardContent
          :airportActiveMilestonesByAirportServiceAgent="airportActiveMilestonesByAirportServiceAgent"
          @AirportServiceMilestones-updated="updateAirportServiceMilestones" />
      </v-layout>
    </v-container>
  </v-card-text>
</template>

<script>
import RolePermissionsVue from '../../mixins/RolePermissions.vue';
import ServiceAgentSelect from '../../service-agent/ServiceAgentSelect.vue';
import AirportRampServiceSelect from '../../rampService/AirportRampServiceSelect.vue';
import { MilestonesPermissions } from '../../shared/model/milestoneTypes';
import AirportActiveMilestonesEditCardContent from './AirportActiveMilestones/AirportActiveMilestonesEditCardContent.vue';

export default {
  name: 'airport-service-agent-edit-card-content',
  mixins: [RolePermissionsVue],
  props: {
    item: { type: Object, required: true },
  },
  components: {
    ServiceAgentSelect,
    AirportRampServiceSelect,
    AirportActiveMilestonesEditCardContent,
  },
  computed: {
    rolePermission() {
      return `${this.$route.meta.role}_WR`;
    },
    showBaggageDistributionTypes() {
      return [...Array(this.permissionTypes.length).keys()];
    },
  },
  data() {
    return {
      permissionTypes: MilestonesPermissions,
      airportActiveMilestonesByAirportServiceAgent: [],
      rules: {
        required: value => !!value || this.$i18n.t('common.fieldRequired'),
      },
    };
  },
  methods: {
    updateAirportServiceMilestones(airportActiveMilestonesByAirportServiceAgent) {
      this.airportActiveMilestonesByAirportServiceAgent = airportActiveMilestonesByAirportServiceAgent;
      this.item.airportActiveMilestonesByAirportServiceAgent = airportActiveMilestonesByAirportServiceAgent;
    },
    setDefaultServiceInbound(airportRampServiceId) {
      this.item.defaultServiceInboundId = airportRampServiceId;
    },
    setDefaultServiceOutbound(airportRampServiceId) {
      this.item.defaultServiceOutboundId = airportRampServiceId;
    },
    setDefaultServiceAircraftGround(airportRampServiceId) {
      this.item.defaultServiceAircraftGroundId = airportRampServiceId;
    },
    formatPermissionsType(item) {
      return this.$i18n.t(`roles.${MilestonesPermissions[item]}`);
    },
  },
  created() {
    if (this.item.airportActiveMilestonesByAirportServiceAgent !== undefined) {
      this.airportActiveMilestonesByAirportServiceAgent = this.item.airportActiveMilestonesByAirportServiceAgent;
    }
    if (this.item.dispatcherPermissions === undefined) {
      this.item.dispatcherPermissions = {
        showBaggageDistribution: MilestonesPermissions.NONE,
        showStandardMessaging: MilestonesPermissions.NONE,
        filterByAgent: false,
        showBaggageSearch: false,
      };
    }
  },
};
</script>

<style scoped>
.form {
  overflow-y: hidden;
}
.v-card__title.dispatcher {
    padding: 0px 0px 10px 0px
}
.dispatcher .headline {
  color: #888;
}
.fixed-height {
  padding: 8px 0px;
}
</style>
