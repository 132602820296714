export default class FlightCrew {
  constructor(id, crewRemarks, crewVehicles) {
    this.id = id;
    this.crewRemarks = crewRemarks;
    this.crewVehicles = crewVehicles;
  }

  static createFlightCrew(flightId) {
    return new FlightCrew(flightId);
  }
}
