<template>
  <div>
    <v-tooltip bottom>
      <v-switch
        slot="activator"
        color="white"
        :label="label"
        v-model="filter.onlyRampUnarchived"
        @change="change"
      >
      </v-switch>
      <span>{{ tooltip }}</span>
    </v-tooltip>
  </div>
</template>
<script>
import { RAMP_FIND_FLIGHTS } from '../../../store/modules/ramp/ramp';

export default {
  name: 'ramp-flights-list-filter-archived',
  computed: {
    filter() {
      return this.$store.state.ramp.filter;
    },
    label() {
      if (this.filter.onlyRampUnarchived) {
        return this.$i18n.t('commonFilter.onlyUnarchived');
      }
      return this.$i18n.t('commonFilter.archivedAndUnarchived');
    },
    tooltip() {
      if (this.filter.onlyRampUnarchived) {
        return this.$i18n.t('commonFilter.onlyUnarchivedTooltip');
      }
      return this.$i18n.t('commonFilter.archivedAndUnarchivedTooltip');
    },
  },
  methods: {
    change() {
      this.$store.dispatch(RAMP_FIND_FLIGHTS);
    },
  },
};
</script>

<style>
/* TODO esta aplicando a este componente y al switch de desk por lo menos */
.v-input--selection-controls .v-input__slot {
  margin-top: 5px;
  margin-bottom: 0px;
}
.theme--dark.v-label {
  color: white;
}
</style>
