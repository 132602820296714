<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>

    <ag-grid-dialog-template-editor :is-dialog-open="isDialogOpen" modalMaxWidth="1500px" @save="save" @cancel="cancel" :saveDisabled="validData">

      <template v-slot:header>
        Edit Deliveries for Flight {{flightNumber}}
      </template>

      <template v-slot:default>
        <v-card>
          <v-card-text class="delivery-baggage-table">
            <ag-grid-vue
              class="ag-theme-material"
              :columnDefs="columnDefs"
              :defaultColDef="defaultColDef"
              @grid-ready="setAgGridApi"
              :gridOptions="gridOptions"
              rowSelection="multiple"
              @selection-changed="onSelectionChanged"
              :frameworkComponents="frameworkComponents"
              :rowData="rowData">
            </ag-grid-vue>
          </v-card-text>
        </v-card>
      </template>
      <template v-slot:footer>
        <v-btn v-show="hasPermissions(rolePermission)" color="secondary" flat @click="addDeliveryBaggage()"><v-icon>add</v-icon></v-btn>
        <v-btn v-show="deliveryBaggageSelected.length > 0 && hasPermissions(rolePermission)" color="error" flat @click="removeDeliveryBaggage()"><v-icon>delete</v-icon></v-btn>
      </template>
    </ag-grid-dialog-template-editor>
  </div>
</template>

<script>
import Vue from 'vue';
import { AgGridVue } from 'ag-grid-vue';
import AgGridDialogParentCellEditor from '../../mixins/AgGridDialogParentCellEditor.vue';
import AgGridDialogTemplateEditor from '../../templates/AgGridDialogTemplateEditor.vue';
import { JSUtils } from '../../../../shared/js/JSUtils';
import AgGridOptionsConfigurable from '../../../mixins/AgGridOptionsConfigurable.vue';
import { cellClassAgGridRules } from '../../../mixins/cellClassAgGridRules';
import AgGridDateTimeCellRenderer from '../../../cell-renderers/AgGridDateTimeCellRenderer.vue';
import CrudOperationsDTO from '../../../../shared/model/crudOperationsDTO';
import FlightDeliveryBaggage from '../../../../lostAndFound/delivery/model/flightDeliveryBaggage';
import AgGridDateCellEditor from '../../AgGridDateCellEditor.vue';
import AgGridNumberCellEditor from '../../AgGridNumberCellEditor.vue';

export default Vue.extend({
  mixins: [AgGridDialogParentCellEditor, AgGridOptionsConfigurable],
  components: {
    AgGridDialogTemplateEditor,
    AgGridVue,
  },
  data() {
    const that = this;
    return {
      frameworkComponents: {
        AgGridDateTimeCellRenderer,
        AgGridDateCellEditor,
        AgGridNumberCellEditor,
      },
      defaultColDef: {
        cellClassRules: cellClassAgGridRules,
      },
      gridOptions: {
        getRowClass: this.getRowClass,
      },
      rowData: [],
      validData: true,
      columnDefs: [
        {
          checkboxSelection: this.isCellEditable,
          width: 36,
          editable: this.isCellEditable,
        },
        {
          field: 'flightTripNumber',
          headerName: 'Flight',
          editable: false,
          valueGetter(params) {
            return params.data.flightNo();
          },
        },
        {
          field: 'date',
          headerName: 'Delivery date',
          with: 200,
          editable: this.isCellEditable,
          type: 'date',
          cellEditor: 'AgGridDateCellEditor',
          cellEditorCustomConfig: {
            referenceDate: this.referenceDate,
          },
          valueGetter(params) {
            return params.data.date;
          },
          valueFormatter(params) {
            return params.value ? that.$moment(params.value).format('DD/MM/YYYY') : null;
          },
          onCellValueChanged: this.validateDeliveryBaggage,
        },
        {
          field: 'fileNumber',
          headerName: 'File number',
          with: 100,
          editable: this.isCellEditable,
          onCellValueChanged: this.validateDeliveryBaggage,
        },
        {
          field: 'numberBag',
          headerName: 'Nº Bags',
          with: 50,
          editable: this.isCellEditable,
          onCellValueChanged: this.validateDeliveryBaggage,
          cellEditor: 'AgGridNumberCellEditor',
        },
        {
          field: 'loadBag',
          headerName: 'Kgs',
          with: 50,
          editable: this.isCellEditable,
          onCellValueChanged: this.validateDeliveryBaggage,
          cellEditor: 'AgGridNumberCellEditor',
        },
        {
          field: 'type',
          headerName: 'Type',
          with: 50,
          editable: this.isCellEditable,
          onCellValueChanged: this.validateDeliveryBaggage,
        },
        {
          field: 'passengerName',
          headerName: 'Passenger\'s Name / File Reference',
          width: 450,
          editable: this.isCellEditable,
          onCellValueChanged: this.validateDeliveryBaggage,
        },
        {
          field: 'address',
          headerName: 'Delivery Address',
          width: 450,
          editable: this.isCellEditable,
          onCellValueChanged: this.validateDeliveryBaggage,
        },
        {
          field: 'city',
          headerName: 'City',
          with: 100,
          editable: this.isCellEditable,
          onCellValueChanged: this.validateDeliveryBaggage,
        },
        {
          field: 'postalCode',
          headerName: 'C.P.',
          with: 75,
          editable: this.isCellEditable,
          onCellValueChanged: this.validateDeliveryBaggage,
        },
        {
          field: 'remarks',
          headerName: 'Remarks',
          width: 450,
          editable: this.isCellEditable,
          onCellValueChanged: this.validateDeliveryBaggage,
        },
      ],
      deliveryBaggageSelected: [],
      crudOperationsDto: new CrudOperationsDTO(),
    };
  },
  computed: {
    objectDeliveryBaggage() {
      return {
        flightId: this.flightId,
        flightTripNumber: this.connectedFlights.getChildPropertyValue(this.flightBound, ['flight', 'tripNumber']),
        flightDate: this.connectedFlights.getChildPropertyValue(this.flightBound, ['flight', 'flightDate']),
        airlineId: this.connectedFlights.getChildPropertyValue(this.flightBound, ['flight', 'airlineId']),
        airlineCode: this.connectedFlights.getChildPropertyValue(this.flightBound, ['flight', 'airlineCode']),
      };
    },
    referenceDate() {
      return this.connectedFlights.getChildPropertyValue(this.flightBound, ['flight', 'scheduledDate']);
    },
    rolePermission() {
      return `${this.$route.meta.role}_WR`;
    },
  },
  methods: {
    getValue() {
      if (this.isSaving) {
        this.crudOperationsDto.updates.push(...this.rowData);
        this.requestInit();
        this.$http.put(`flights/${this.flightId}/delivery-baggage`, this.crudOperationsDto)
          .then(resp => this.dialogRequestSuccessCallback(this, resp))
          .catch(error => this.requestErrorCallback(this, error));
      }

      return this.params.value;
    },
    onColumnResized(event) {
      if (event.finished) {
        this.gridApi.resetRowHeights();
      }
    },
    isCellEditable() {
      return this.hasPermissions(this.rolePermission);
    },
    addDeliveryBaggage() {
      this.rowData.push(FlightDeliveryBaggage.createFlightDeliveryBaggage(this.objectDeliveryBaggage));
      this.validateDeliveryBaggage();
    },
    removeDeliveryBaggage() {
      if (this.deliveryBaggageSelected.length > 0) {
        this.crudOperationsDto.deletes.push(...this.deliveryBaggageSelected.filter(rushBaggage => !!rushBaggage.id));

        this.deliveryBaggageSelected.forEach((msg) => {
          const index = this.rowData.indexOf(msg);
          this.rowData.splice(index, 1);
        });
        this.deliveryBaggageSelected = [];
        this.validateDeliveryBaggage();
      }
    },
    onSelectionChanged(event) {
      if (event.api.getSelectedNodes().length > 0) {
        this.deliveryBaggageSelected = event.api.getSelectedNodes().map(n => n.data);
      } else {
        this.deliveryBaggageSelected = [];
      }
    },
    validateDeliveryBaggage() {
      this.validData = false;
    },
  },
  created() {
    this.$http.get(`flights/${this.flightId}/delivery-baggage`)
      .then((resp) => {
        this.rowData = JSUtils.cast(resp.data, FlightDeliveryBaggage);
      });
  },
});
</script>
<style lang="scss">
.loads-table {
  padding: 0;
}

.radio-unit-label {
  padding-top: 1.5vh;
  font-weight: bold;
  margin-right: 1vw;
}
</style>
