<template>
  <div>
    <delays-flight-list-table :items="items" :isFilterDisplayed="this.isFilterDisplayed"></delays-flight-list-table>
  </div>
</template>
<script>
import DelaysFlightListTable from './DelaysFlightsListTable.vue';
import { DELAYS_FIND_FLIGHTS } from '../../../store/modules/delays';

export default {
  name: 'delays-flights-list-container',
  components: {
    DelaysFlightListTable,
  },
  props: {
    isFilterDisplayed: { type: Boolean, required: false, default: false },
  },
  mounted() {
    this.$store.dispatch(DELAYS_FIND_FLIGHTS);
  },
  computed: {
    items() {
      return this.$store.state.delays.connectedFlightsDelays;
    },
  },
  methods: {
  },
};
</script>
<style lang="scss">


</style>
