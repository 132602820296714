<template>
  <v-btn icon v-on:click="toggleTheme">
    <v-icon>{{ icon }}</v-icon>
  </v-btn>
</template>

<script>

import VueCookies from 'vue-cookies';

export default {
  name: 'theme-selector',
  data() {
    return {
      isDark: false,
      icon: 'light_mode',
    };
  },
  methods: {
    toggleTheme() {
      this.isDark = !this.isDark;
      this.icon = this.isDark ? 'dark_mode' : 'light_mode';
      this.$vuetify.dark = this.isDark;
      // Guardar el tema seleccionado en la cookie al cambiar
      VueCookies.set('theme', this.isDark ? 'dark-mode' : 'light-mode', 365);
    },
  },
  created() {
    const storedTheme = VueCookies.get('theme');
    if (storedTheme) {
      this.$vuetify.dark = storedTheme === 'dark-mode';
      this.isDark = storedTheme === 'dark-mode';
    }
  },
};
</script>
