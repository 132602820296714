<template>
  <v-expansion-panel v-model="panel" expand class="exp-header">

    <v-expansion-panel-content expand-icon="mdi-menu-down">
      <template v-slot:header>
        <div class="pl-4 py-3">
          <h4 class="headline font-weight-light">{{$t("administration.dusIntegrationTitle")}}</h4>
        </div>
      </template>
      <v-card>

        <v-layout align-center justify-space-around row fill-height>
          <v-flex xs4 pa-4>
            <DusIntegrationJob/>
          </v-flex>
          <v-flex xs4 pa-0>
            <DusIntegration/>
          </v-flex>
          <v-flex xs4 pa-4>
            <DusIntegrationClean/>
          </v-flex>
        </v-layout>

        <v-flex xs12 v-if="operation">
          <AdminResult :is-error="isError" :is-success="isSuccess" :loading="loading" :time="time" :traces="traces"
                       :operation="operation"/>
        </v-flex>
      </v-card>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>
<script>
import DusIntegration from './DusIntegration.vue';
import DusIntegrationJob from './DusIntegrationJob.vue';
import DusIntegrationClean from './DusIntegrationClean.vue';
import AdminResult from '../../AdminResult.vue';

export default {
  name: 'Dus',
  components: {
    DusIntegration, DusIntegrationJob, DusIntegrationClean, AdminResult,
  },
  data() {
    return {
      panel: [],
    };
  },
  computed: {
    loading() {
      return !!this.$store.state.admin.dus.dusLoading;
    },
    time() {
      return this.$store.state.admin.dus.dusLastRequestTime ? this.$moment(this.$store.state.admin.dus.dusLastRequestTime).format('DD/MM/YYYY HH:mm') : null;
    },
    isSuccess() {
      return this.$store.getters.isDusSuccess;
    },
    isError() {
      return this.$store.getters.isDusError;
    },
    traces() {
      return this.$store.state.admin.dus.dusResult;
    },
    operation() {
      return this.$store.state.admin.dus.dusOperation;
    },
  },
  methods: {},
};
</script>
