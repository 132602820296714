import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('ag-grid-dialog-template-editor',{attrs:{"is-dialog-open":_vm.isDialogOpen},on:{"save":_vm.save,"cancel":_vm.cancel},scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v(" Edit Local Passengers "+_vm._s(_vm.flightNumber)+" ")]},proxy:true},{key:"default",fn:function(){return [_c(VContainer,{attrs:{"grid-list-md":"","text-xs-center":"","fluid":""}},[_c(VLayout,{attrs:{"row":"","wrap":""}},[(_vm.isOutbound)?_c(VFlex,{attrs:{"xs12":""}},[_c(VCard,[_c(VCardText,[_c(VContainer,{attrs:{"fluid":""}},[_c(VLayout,[_c(VFlex,{attrs:{"xs3":""}},[_c(VTooltip,{attrs:{"bottom":""}},[_c(VIcon,{attrs:{"slot":"activator","x-large":"","left":""},slot:"activator"},[_vm._v("flight_takeoff")]),_c('span',[_vm._v("Outbound flight")])],1)],1),_c(VFlex,{attrs:{"xs3":""}},[_c(VTextField,{attrs:{"type":"number","label":"CKIN","readonly":!_vm.hasPermissions(_vm.rolePermission)},model:{value:(_vm.localPaxCheckIn.localPaxCheckInCounters),callback:function ($$v) {_vm.$set(_vm.localPaxCheckIn, "localPaxCheckInCounters", $$v)},expression:"localPaxCheckIn.localPaxCheckInCounters"}})],1),_c(VFlex,{attrs:{"xs3":""}},[_c(VTextField,{attrs:{"type":"number","label":"WWW","readonly":!_vm.hasPermissions(_vm.rolePermission)},model:{value:(_vm.localPaxCheckIn.localPaxCheckInWeb),callback:function ($$v) {_vm.$set(_vm.localPaxCheckIn, "localPaxCheckInWeb", $$v)},expression:"localPaxCheckIn.localPaxCheckInWeb"}})],1),_c(VFlex,{attrs:{"xs3":""}},[_c(VTextField,{attrs:{"type":"number","label":"SSK","readonly":!_vm.hasPermissions(_vm.rolePermission)},model:{value:(_vm.localPaxCheckIn.localPaxCheckInKiosks),callback:function ($$v) {_vm.$set(_vm.localPaxCheckIn, "localPaxCheckInKiosks", $$v)},expression:"localPaxCheckIn.localPaxCheckInKiosks"}})],1)],1)],1)],1)],1)],1):_vm._e()],1)],1)]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }