<template>
  <ag-grid-template-cell-renderer :params="params">
    <div v-if="params.value.length <= itemsShowed" v-for="value in params.value" :key="value" class="multiline-text">
      {{value}}
    </div>
    <div>
      <v-tooltip left v-if="params.value.length > itemsShowed">
        <div slot="activator" v-for="(value, index) in params.value.slice(0, itemsShowed + 1)" :key="value" class="multiline-text">
          <div v-if="index === itemsShowed">...</div>
          <div v-if="index !== itemsShowed">
            {{value}}
          </div>
        </div>

        <span>{{params.value.toString()}}</span>
      </v-tooltip>
    </div>
  </ag-grid-template-cell-renderer>
</template>

<script>
import Vue from 'vue';
import AgGridTemplateCellRenderer from './templates/AgGridTemplateCellRenderer.vue';

export default Vue.extend({
  data() {
    return {
      itemsShowed: 3,
    };
  },
  components: {
    AgGridTemplateCellRenderer,
  },
  computed: {},
});
</script>

<style scoped>
  .multiline-text {
    padding-top: 2px;
    font-size: 10px;
    line-height: 12px;
  }
</style>
