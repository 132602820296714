<template>
  <div>
    <v-tooltip bottom>
      <v-switch
        slot="activator"
        color="white"
        :label="label"
        v-model="filter.onlyCurrentDesk"
        @change="change">
      </v-switch>
      <span>{{ tooltip }}</span>
    </v-tooltip>
  </div>
</template>
<script>

import { OPERATION_FIND_FLIGHTS } from '../../../store/modules/operation';

export default {
  name: 'operation-flights-list-filter-desk',
  computed: {
    filter() {
      return this.$store.state.operation.filter;
    },
    label() {
      if (this.filter.onlyCurrentDesk) {
        return this.$i18n.t('operations.onlyCurrentDesk');
      }
      return this.$i18n.t('operations.allDesks');
    },
    tooltip() {
      if (this.filter.onlyCurrentDesk) {
        return this.$i18n.t('operations.onlyCurrentDeskTooltip');
      }
      return this.$i18n.t('operations.allDesksTooltip');
    },
  },
  methods: {
    change() {
      this.$store.dispatch(OPERATION_FIND_FLIGHTS);
    },
  },
};
</script>

<style>
</style>
