import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"max-width":_vm.modalMaxWidth,"persistent":"","content-class":_vm.isContentVisible ? 'modal-common' : 'modal-common hide-title'},model:{value:(_vm.isDialogOpen),callback:function ($$v) {_vm.isDialogOpen=$$v},expression:"isDialogOpen"}},[_c(VCard,{attrs:{"dark":this.$vuetify.dark,"color":this.$vuetify.dark ? 'bggrey_dark' : 'bggrey'}},[_c(VToolbar,{staticClass:"ToolbarDialogEditor",attrs:{"dark":"","color":"primary"}},[_c(VIcon,[_vm._v("flight")]),_c(VToolbarTitle,[_vm._t("header")],2),_c(VSpacer),_c(VBtn,{attrs:{"icon":""},on:{"click":_vm.toggleContentVisibility}},[_c(VIcon,[_vm._v(_vm._s(_vm.isContentVisible ? 'mdi-chevron-up' : 'mdi-chevron-down'))])],1)],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isContentVisible),expression:"isContentVisible"}]},[_c('loading-component'),_c(VCardText,{staticClass:"editSlot"},[_vm._t("default")],2),_c(VCardActions,[_vm._t("footer"),_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","flat":""},nativeOn:{"click":function($event){return _vm.$emit('cancel')}}},[_vm._v(_vm._s(_vm.$t("operations.cancel")))]),(_vm.hasPermissions(_vm.rolePermission))?_c(VBtn,{attrs:{"color":"primary","depressed":"","disabled":_vm.loadingGlobal || _vm.saveDisabled},nativeOn:{"click":function($event){return _vm.save()}}},[_vm._v(_vm._s(_vm.$t("operations.save")))]):_vm._e()],2)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }