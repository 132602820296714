<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <entity-configuration-list
      :entity-name="this.$i18n.t('configuration.serviceType')"
      entity-icon="ballot"
      :framework-components="frameworkComponents" :column-defs="columnDefs" :items="serviceTypes"
      @confirm-edit-item="editConfirm"
      @confirm-delete-item="deleteConfirm"
      @confirm-enable-disable-item="enableDisableConfirm"
    >
      <template v-slot:modal-content="{ itemEdit }">
        <service-type-edit-card-content :item="itemEdit" />
      </template>
    </entity-configuration-list>
  </div>
</template>
<script>
import {
  GET_SERVICE_TYPE,
  SERVICE_TYPE_CREATE,
  SERVICE_TYPE_DELETE,
  SERVICE_TYPE_UPDATE,
  SERVICE_TYPE_DISABLE,
  SERVICE_TYPE_ENABLE,
} from '@/store/modules/serviceTypeCatalog';
import EntityConfigurationList from '@/components/configuration/common/EntityConfigurationList.vue';
import ServiceTypeEditCardContent from './ServiceTypeEditCardContent.vue';
import AgGridIconValueOrDashCellRenderer from '../../ag-grid/cell-renderers/AgGridIconValueOrDashCellRenderer.vue';
import AgGridIconCellRenderer from '../../ag-grid/cell-renderers/AgGridIconCellRenderer.vue';

export default {
  name: 'ServiceTypeList',
  components: {
    EntityConfigurationList,
    ServiceTypeEditCardContent,
  },
  data() {
    const that = this;
    return {
      frameworkComponents: {
        AgGridIconValueOrDashCellRenderer,
        AgGridIconCellRenderer,
      },
      columnDefs: [
        {
          field: 'code',
          headerValueGetter() {
            return that.$i18n.t('common.id');
          },
        },
        {
          headerValueGetter() {
            return that.$i18n.t('configuration.name');
          },
          field: 'name',
        },
        {
          headerValueGetter() {
            return that.$i18n.t('configuration.disabled');
          },
          field: 'deleted',
          cellRendererFramework: AgGridIconCellRenderer,
          cellRendererParams: {
            icon: 'mdi-cancel',
            tooltip: params => (params.data.deleted ? 'Disabled' : null),
          },
        },
      ],
    };
  },
  computed: {
    serviceTypes() {
      return this.$store.getters.getServiceTypes;
    },
  },
  methods: {
    getServiceTypes() {
      this.$store.dispatch(GET_SERVICE_TYPE);
    },
    deleteConfirm(item) {
      this.$store.dispatch(SERVICE_TYPE_DELETE, item.id);
    },
    editConfirm(item) {
      this.$store.dispatch(item.id ? SERVICE_TYPE_UPDATE : SERVICE_TYPE_CREATE, item);
    },
    enableDisableConfirm(item) {
      this.$store.dispatch(item.deleted ? SERVICE_TYPE_ENABLE : SERVICE_TYPE_DISABLE, item.id);
    },
  },
  created() {
    this.getServiceTypes();
  },
};
</script>
