<template>
  <div class="contingencies list-filter">
    <v-card :dark="this.$vuetify.dark" :color="this.$vuetify.dark ? 'bggrey_dark' : 'bggrey'">
      <v-toolbar dark dense color="primary lighten-1">
        <v-btn v-on:click="displayedFilter" small flat>
          <v-icon>filter_list</v-icon>
          <v-icon v-if="!showFilter">keyboard_arrow_down</v-icon>
          <v-icon v-if="showFilter">keyboard_arrow_up</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-flex grow="false" shrink="true">
          <v-btn-toggle v-model="unitOfTime" class="transparent" @change="filterChanged">
            <v-tooltip left>
              <v-btn value="day" flat slot="activator">{{ $t("commonFilter.filterD") }}</v-btn>
              <span>{{ $t("commonFilter.filterDay") }}</span>
            </v-tooltip>
            <v-tooltip left>
              <v-btn value="isoWeek" flat slot="activator">{{ $t("commonFilter.filterW") }}</v-btn>
              <span>{{ $t("commonFilter.filterWeek") }}</span>
            </v-tooltip>
            <v-tooltip left>
              <v-btn value="month" flat slot="activator">{{ $t("commonFilter.filterM") }}</v-btn>
              <span>{{ $t("commonFilter.filterMonth") }}</span>
            </v-tooltip>
            <v-tooltip left>
              <v-btn value="year" flat slot="activator">{{ $t("commonFilter.filterY") }}</v-btn>
              <span>{{ $t("commonFilter.filterYear") }}</span>
            </v-tooltip>
          </v-btn-toggle>
        </v-flex>
        <ContingenciesListTableOptionsFilterButton />
      </v-toolbar>
      <v-container fluid v-if="showFilter" class="pa-2 pb-2">
        <v-layout class="filter-inline" align-center justify-start>
          <v-flex class="mr-2" align-center justify-start>
            <date-input v-model="filter.dateFrom" :locale="$i18n.locale" :label="$t('common.fromDate')" class="pb-2"
              @change="changeDateFrom"></date-input>
            <date-input v-model="filter.dateTo" :locale="$i18n.locale" :label="$t('common.toDate')"
              @change="changeDateTo"></date-input>
          </v-flex>
          <v-flex class="ml-2" grow="false" shrink="true">
            <p class="text-filter mx-0 mb-3 mt-0">{{ $t("common.filterWeek") }}</p>
            <days-of-week v-model="filter.daysOfWeek" @input="filterChanged" />
          </v-flex>
          <v-flex class="ml-5 text-no-wrap" align-center justify-start>
            <p class="text-filter mx-0 mb-3 mt-0">{{ $t("common.status") }}</p>
            <v-layout row>
              <contingency-status class="mx-2" v-model="filter.contingencyStatus" @input="filterChanged" />
              <contingency-invoice v-model="filter.contingencyInvoice" @input="filterChanged" />
            </v-layout>
          </v-flex>
          <v-spacer style="width: 35%"></v-spacer>
          <v-flex class="ml-2 text-no-wrap" align-center justify-start>
            <div align-left>
              <v-menu left offset-y>
                <template v-slot:activator="{ on }">
                  <v-btn color="secondary" small flat round v-on="on" block class="mb-4">
                    <v-icon>mdi-download</v-icon>
                    {{ $t("common.export") }}
                  </v-btn>
                </template>
                <v-list>
                  <v-list-tile-content>
                    <v-list-tile-sub-title>
                      <v-btn color="secondary" small flat v-on:click.stop="exportCSV">
                        <v-icon>mdi-download</v-icon>
                        {{ $t("common.export") }}
                      </v-btn>
                    </v-list-tile-sub-title>
                    <v-list-tile-sub-title>
                      <v-btn color="secondary" small flat v-on:click.stop="exportEXCEL">
                        <v-icon>mdi-download</v-icon>
                        {{ $t("common.export") }} Excel
                      </v-btn>
                    </v-list-tile-sub-title>
                  </v-list-tile-content>
                </v-list>
              </v-menu>
            </div>
            <v-btn color="primary" round v-on:click.stop="find" block>
              <v-icon>search</v-icon>
              {{ $t("common.find") }}
            </v-btn>

          </v-flex>
        </v-layout>
      </v-container>
    </v-card>
  </div>

</template>
<script>
import { saveAs } from 'file-saver';
import AgGridApiCsvExportable from '../../ag-grid/mixins/AgGridApiCsvExportable.vue';
import StoreSubscribable from '../../mixins/StoreSubscribable.vue';
import {
  FLIGHT_CONTINGENCIES_FIND,
  FLIGHT_CONTINGENCIES_SET_GRID_API,
  FLIGHT_CONTINGENCY_FILTER_SET_UNIT_TIME, FLIGHT_CONTINGENCY_RECALCULATE_FILTER,
  UPDATE_FLASH_ON_CHANGES,
  UPDATE_SHOW_OLD_VALUES,
} from '../../../store/modules/contingency/contingency';
import DaysOfWeek from '../../ui/DaysOfWeek.vue';
import DateInput from '../../ui/DateInput.vue';
import ContingencyStatus from '../../ui/ContingencyStatus.vue';
import ContingencyInvoice from '../../ui/ContingencyInvoice.vue';
import ContingenciesListTableOptionsFilterButton from './ContingenciesListTableOptionsFilterButton.vue';
import { REQUEST_ERROR, REQUEST_START, REQUEST_SUCCESS } from '../../../store/store';
import { NOTIFICATION_SHOW } from '../../../store/modules/notifications';
import FileSerializable from '../../mixins/FileSerializable.vue';
import { TIME_TIMEZONE_CHANGED } from '../../../store/modules/time';

export default {
  name: 'contingency-list-filter',
  mixins: [AgGridApiCsvExportable, StoreSubscribable, FileSerializable],
  components: {
    DaysOfWeek,
    DateInput,
    ContingencyStatus,
    ContingencyInvoice,
    ContingenciesListTableOptionsFilterButton,
  },
  props: [],
  data() {
    return {
      select: 50,
      showFilter: false,
      valid: true,
      queryName: null,
      dialog: false,
      showOldValues: null,
      flashOnChanges: null,
    };
  },
  computed: {
    filter() {
      return this.$store.state.contingency.filter;
    },
    unitOfTime: {
      get() {
        return this.filter.unitOfTime;
      },
      set(value) {
        this.$store.commit(FLIGHT_CONTINGENCY_FILTER_SET_UNIT_TIME, value);
      },
    },
  },
  methods: {
    find() {
      this.showLoading();
      this.$store.dispatch(FLIGHT_CONTINGENCIES_FIND);
    },
    exportCSV() {
      this.exportAgGridCsv('Contingencies');
    },
    exportEXCEL() {
      this.$store.commit(REQUEST_START);
      this.$http.post('flight-contingencies/export-excel', this.filter, {})
        .then((resp) => {
          console.log(resp); // eslint-disable-line no-console
          this.$store.commit(REQUEST_SUCCESS);
          const blob = new Blob([this.s2ab(atob(resp.data.content))], { type: resp.data.contentType });
          saveAs(blob, resp.data.name);
        }).catch((err) => {
          this.$store.commit(REQUEST_ERROR, err);
          this.$store.commit(NOTIFICATION_SHOW, {
            message: this.$i18n.t('notifications.errorNotification'),
            color: 'error',
          });
        });
    },
    updateShowOldValuesInStore() {
      this.$store.commit(UPDATE_SHOW_OLD_VALUES, this.showOldValues);
    },
    updateFlashOnChangesInStore() {
      this.$store.commit(UPDATE_FLASH_ON_CHANGES, this.flashOnChanges);
    },
    displayedFilter() {
      this.showFilter = !this.showFilter;
      this.$emit('changeShowFilter', this.showFilter);
    },
    changeDateFrom() {
      this.unitOfTime = null;
      const from = this.$moment(this.filter.dateFrom);
      const to = this.$moment(this.filter.dateTo);

      this.filter.dateFrom = from.startOf('day');
      if (from.isAfter(to)) {
        this.filter.dateTo = this.$moment(this.filter.dateFrom).endOf('day');
      }
      this.filterChanged();
    },
    changeDateTo() {
      this.unitOfTime = null;
      const from = this.$moment(this.filter.dateFrom);
      const to = this.$moment(this.filter.dateTo);

      this.filter.dateTo = to.endOf('day');
      if (to.isBefore(from)) {
        this.filter.dateFrom = this.$moment(this.filter.dateTo).startOf('day');
      }
      this.filterChanged();
    },
    filterChanged() {
      // this.find();
    },
  },
  created() {
    this.showOldValues = this.$store.state.contingency.showOldValues;
    this.flashOnChanges = this.$store.state.contingency.flashOnChanges;
  },
  mounted() {
    const that = this;
    this.subscribeMutation(FLIGHT_CONTINGENCIES_SET_GRID_API, (mutationPayload) => {
      that.setAgGridApi(mutationPayload);
    });
    this.subscribeMutation(TIME_TIMEZONE_CHANGED, () => {
      this.$store.dispatch(FLIGHT_CONTINGENCY_RECALCULATE_FILTER);
      this.find();
    });
    this.$store.dispatch(FLIGHT_CONTINGENCY_RECALCULATE_FILTER);
    this.find();
  },
};
</script>
