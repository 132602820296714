import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLabel } from 'vuetify/lib/components/VLabel';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('ag-grid-dialog-template-editor',{attrs:{"is-dialog-open":_vm.isDialogOpen},on:{"save":_vm.save,"cancel":_vm.cancel},scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t("operations.editCrewModalTitle"))+" "+_vm._s(_vm.flightNumber)+" ")]},proxy:true},{key:"default",fn:function(){return [_c(VContainer,{attrs:{"grid-list-md":"","text-xs-center":"","fluid":""}},[_c(VLayout,{attrs:{"row":"","wrap":""}},[_c(VFlex,{attrs:{"xs12":""}},[_c(VCard,[_c(VCardText,[_c(VLayout,[_c(VFlex,{staticClass:"space-label",attrs:{"xs3":""}},[_c(VLabel,[_vm._v(_vm._s(_vm.$t("operations.remarksExtended")))])],1),_c(VFlex,{attrs:{"xs8":""}},[_c(VTextarea,{attrs:{"rows":"1","auto-grow":"","readonly":!_vm.hasPermissions(_vm.rolePermission)},model:{value:(_vm.flightCrew.crewRemarks),callback:function ($$v) {_vm.$set(_vm.flightCrew, "crewRemarks", $$v)},expression:"flightCrew.crewRemarks"}})],1)],1),_c(VLayout,[_c(VFlex,{staticClass:"space-label",attrs:{"xs3":""}},[_c(VLabel,[_vm._v(_vm._s(_vm.$t("operations.crewModalVehicles")))])],1),_c(VFlex,{attrs:{"xs8":""}},[_c(VTextarea,{attrs:{"rows":"1","auto-grow":"","readonly":!_vm.hasPermissions(_vm.rolePermission)},model:{value:(_vm.flightCrew.crewVehicles),callback:function ($$v) {_vm.$set(_vm.flightCrew, "crewVehicles", $$v)},expression:"flightCrew.crewVehicles"}})],1)],1)],1)],1)],1)],1)],1)]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }