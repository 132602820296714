<template>
  <div>
    <v-autocomplete
      :value="value"
      :label="label"
      :placeholder="$t('commonForm.selectAirlineFare')"
      :items="items"
      :rules="rules"
      :disabled="disabled"
      :multiple="multiple"
      :clearable="true"
      item-text="name"
      item-value="id"
      @input="inputAirlineFare"
      @change="onChangeSelection"
      hide-details
      :ref="refComp"
    >
      <template v-slot:selection="data" v-if="chipView">
        <v-chip
          :selected="data.selected"
          close
          class="chip--select-multi"
          @input="remove(data.item)"
        >
          {{ data.item.name }}
        </v-chip>
      </template>
      <template
        slot="item"
        slot-scope="{ item }"
      >
        <v-list-tile-content>
          <v-list-tile-title >{{ item.name }}</v-list-tile-title>
          <v-list-tile-sub-title v-if="item.description">{{ item.description }}</v-list-tile-sub-title>
          <v-list-tile-sub-title>{{ item.airlineCode }}</v-list-tile-sub-title>
        </v-list-tile-content>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>

import { GET_AIRLINE_FARES } from '../../../store/modules/airlineFaresCatalog';

export default {
  name: 'airline-fare-select',
  props: {
    value: { required: true },
    label: { type: String },
    charLenght: { type: Number },
    rules: { type: Array },
    disabled: { type: Boolean },
    multiple: {
      type: Boolean,
      default: false,
    },
    refComp: {
      type: String,
      required: false,
      default: 'refComp',
    },
    chipView: {
      type: Boolean,
      default: false,
    },
    airlineFares: { type: Array },
  },
  computed: {
    items() {
      return this.airlineFares ? this.airlineFares : this.$store.getters.getEnableAndActiveAirlineFares;
    },
  },
  methods: {
    getAirlineFares() {
      this.$store.dispatch(GET_AIRLINE_FARES);
    },
    customFilter(item, queryText) {
      const textOne = item.name ? item.name.toLowerCase() : '';
      const textTwo = item.description ? item.description.toLowerCase() : '';
      const searchText = queryText.toLowerCase();

      return textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1;
    },
    inputAirlineFare($event) {
      // FIXME así no rompe al limpiar el select pero hay que validar que no se pueda guardar el tipo de mensaje como NULL
      if (typeof $event !== 'undefined') {
        this.$emit('input', $event);
      } else {
        this.$emit('input', null);
      }
    },
    onChangeSelection(selectionId) {
      const airlineFare = this.items.filter(f => f.id === selectionId)[0];
      this.$emit('change', airlineFare);
    },
  },
  mounted() {
    if (!this.airlineFares) {
      this.getAirlineFares();
    }
  },
};
</script>
