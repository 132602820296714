<template>
  <ag-grid-template-cell-renderer :params="params">
    {{ params.value && params.value !== 'CASH' ? $t(`ticketing.${params.value}`) : ''}}
  </ag-grid-template-cell-renderer>
</template>

<script>
import Vue from 'vue';
import AgGridTemplateCellRenderer from './templates/AgGridTemplateCellRenderer.vue';

export default Vue.extend({
  components: {
    AgGridTemplateCellRenderer,
  },
  computed: {

  },
});
</script>

<style scoped>

</style>
