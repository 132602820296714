<template>
  <v-container>
    <v-layout row wrap>
      <v-flex xs12>
        <v-subheader>Global</v-subheader>
      </v-flex>
      <template v-for="(section, index) in globalSections">
        <v-flex xs4 v-if="hasPermissions(section.role)" :key="index" pa-2>
          <v-card :dark="$vuetify.dark" :color="$vuetify.dark ? 'bggrey_dark' : 'bggrey'" key="{{ section.componentName }}" :to="{ name: section.componentName }">
            <v-card-title primary-title>
              <div>
                <h3 class="headline mb-0">
                  <v-avatar v-if="section.icon">
                    <v-icon large>{{ section.icon }}</v-icon>
                  </v-avatar>
                  {{ section.readableName }}
                </h3>
              </div>
            </v-card-title>
          </v-card>
        </v-flex>
      </template>
    </v-layout>
    <v-subheader>{{ $t("common.airport") }}: {{ currentTenant.name }} - {{ currentTenant.description }}</v-subheader>
    <v-layout row wrap>
      <template v-for="(section, index) in tenantSections">
        <v-flex xs4 v-if="hasPermissions(section.role)" :key="index" pa-2>
          <v-card :dark="$vuetify.dark" :color="$vuetify.dark ? 'bggrey_dark' : 'bggrey'" key="{{ section.componentName }}" :to="{ name: section.componentName }">
            <v-card-title primary-title>
              <div>
                <h3 class="headline mb-0">
                  <v-avatar v-if="section.icon">
                    <v-icon large>{{ section.icon }}</v-icon>
                  </v-avatar>
                  {{ section.readableName }}
                </h3>
              </div>
            </v-card-title>
          </v-card>
        </v-flex>
      </template>
    </v-layout>
  </v-container>
</template>
<script>
import RolePermissions from '../components/mixins/RolePermissions.vue';

export default {
  name: 'Configuration',
  mixins: [RolePermissions],
  computed: {

    globalSections() {
      if (!this.$i18n) {
        return [];
      }
      return [
        {
          componentName: 'aircraft',
          readableName: this.$i18n.t('common.aircraft'),
          icon: 'local_airport',
          role: 'ROLE_CONFIG_AIRCRAFT',
        },
        {
          componentName: 'aircraftType',
          readableName: this.$i18n.t('configuration.aircraftTypes'),
          icon: 'local_airport',
          role: 'ROLE_CONFIG_AIRCRAFT_TYPES',
        },
        {
          componentName: 'airline',
          readableName: this.$i18n.t('common.airline'),
          icon: 'local_airport',
          role: 'ROLE_CONFIG_AIRLINE',
        },
        {
          componentName: 'airport',
          readableName: this.$i18n.t('common.airport'),
          icon: 'apartment',
          role: 'ROLE_CONFIG_AIRPORT',
        },
        {
          componentName: 'delayTypes',
          readableName: this.$i18n.t('configuration.delayTypes'),
          icon: 'schedule',
          role: 'ROLE_CONFIG_DELAY_TYPES',
        },
        {
          componentName: 'rampServices',
          readableName: this.$i18n.t('common.rampServices'),
          icon: 'rv_hookup',
          role: 'ROLE_CONFIG_RAMP_SERVICES',
        },
        {
          componentName: 'users',
          readableName: this.$i18n.t('configuration.users'),
          icon: 'people',
          role: 'ROLE_CONFIG_USERS',
        },
        {
          componentName: 'handlingAgents',
          readableName: this.$i18n.t('configuration.handlingAgents'),
          icon: 'people',
          role: 'ROLE_CONFIG_HANDLING_AGENTS',
        },
        {
          componentName: 'serviceAgents',
          readableName: this.$i18n.t('configuration.serviceAgents'),
          icon: 'people',
          role: 'ROLE_CONFIG_SERVICE_AGENTS',
        },
        {
          componentName: 'avinetUsers',
          readableName: this.$i18n.t('configuration.avinetUsers'),
          icon: 'people',
          role: 'ROLE_CONFIG_AVINET_USERS',
        },
        {
          componentName: 'airlineFares',
          readableName: this.$i18n.t('configuration.airlineFares'),
          icon: 'style',
          role: 'ROLE_CONFIG_AIRLINE_FARES',
        },
        {
          componentName: 'serviceType',
          readableName: this.$i18n.t('configuration.serviceType'),
          icon: 'ballot',
          role: 'ROLE_CONFIG_SERVICE_TYPE',
        },
        {
          componentName: 'milestonesGlobal',
          readableName: this.$i18n.t('configuration.milestonesGlobal'),
          icon: 'emoji_flags',
          role: 'ROLE_CONFIG_MILESTONES',
        },
      ];
    },
    tenantSections() {
      if (!this.$i18n) {
        return [];
      }
      return [{
        componentName: 'airportBelt',
        readableName: this.$i18n.t('configuration.airportBelts'),
        icon: 'video_label',
        role: 'ROLE_CONFIG_AIRPORT_BELTS',
      },
      {
        componentName: 'airportCarousel',
        readableName: this.$i18n.t('configuration.airportCarousels'),
        icon: 'video_label',
        role: 'ROLE_CONFIG_AIRPORT_CAROUSELS',
      },
      {
        componentName: 'airportCheckInCounters',
        readableName: this.$i18n.t('configuration.airportCheckInCounters'),
        icon: 'video_label',
        role: 'ROLE_CONFIG_AIRPORT_CHECK_IN_COUNTERS',

      },
      {
        componentName: 'airportGates',
        readableName: this.$i18n.t('configuration.airportGates'),
        icon: 'video_label',
        role: 'ROLE_CONFIG_AIRPORT_GATES',
      },
      {
        componentName: 'airportParking',
        readableName: this.$i18n.t('configuration.airportParking'),
        icon: 'local_parking',
        role: 'ROLE_CONFIG_AIRPORT_PARKING',
      },
      {
        componentName: 'aicDocuments',
        readableName: this.$i18n.t('configuration.aicDocuments'),
        icon: 'library_books',
        role: 'ROLE_CONFIG_AIRPORT_AIC_DOCUMENTS',
      },
      {
        componentName: 'flightWeighting',
        readableName: this.$i18n.t('configuration.flightWeighting'),
        icon: 'tune',
        role: 'ROLE_CONFIG_AIRPORT_FLIGHT_WEIGHTING',
      },
      {
        componentName: 'airportRampServices',
        readableName: this.$i18n.t('configuration.AirportRampServices'),
        icon: 'rv_hookup',
        role: 'ROLE_CONFIG_AIRPORT_RAMP_SERVICES',
      },
      {
        componentName: 'airportRequester',
        readableName: this.$i18n.t('configuration.airportRequesters'),
        icon: 'people',
        role: 'ROLE_CONFIG_AIRPORT_REQUESTERS',
      },
      {
        componentName: 'airportServiceAgents',
        readableName: this.$i18n.t('configuration.airportServiceAgents'),
        icon: 'people',
        role: 'ROLE_CONFIG_AIRPORT_SERVICE_AGENTS',
      },
      {
        componentName: 'messageDirectory',
        readableName: this.$i18n.t('configuration.messageDirectory'),
        icon: 'people',
        role: 'ROLE_CONFIG_AIRPORT_MESSAGE_DIRECTORY',
      },
      {
        componentName: 'handlingTypes',
        readableName: this.$i18n.t('configuration.handlingTypes'),
        icon: 'ballot',
        role: 'ROLE_CONFIG_AIRPORT_HANDLING_TYPES',
      },
      {
        componentName: 'ticketingNumeration',
        readableName: this.$i18n.t('configuration.ticketingNumeration'),
        icon: 'style',
        role: 'ROLE_CONFIG_AIRPORT_TICKETING_NUMERATION',
      },
      {
        componentName: 'slaBaggageDelivery',
        readableName: this.$i18n.t('configuration.airportSLABaggageDelivery'),
        icon: 'luggage',
        role: 'ROLE_CONFIG_AIRPORT_SLA_BAGGAGE_DELIVERY',
      },
      {
        componentName: 'delayReasons',
        readableName: this.$i18n.t('configuration.delayReasons.delayReasons'),
        icon: 'share_arrival_time',
        role: 'ROLE_CONFIG_DELAY_REASONS',
      },
      {
        componentName: 'defaultBoardingTime',
        readableName: this.$i18n.t('configuration.defaultBoardingTime'),
        icon: 'safety_check',
        role: 'ROLE_CONFIG_AIRPORT_DEFAULT_BOARDING_TIME',
      },
      {
        componentName: 'defaultCheckInTime',
        readableName: this.$i18n.t('configuration.defaultCheckInTime'),
        icon: 'luggage',
        role: 'ROLE_CONFIG_AIRPORT_DEFAULT_CHECK_IN_TIME',
      },
      {
        componentName: 'airportMilestones',
        readableName: this.$i18n.t('configuration.milestonesAirport'),
        icon: 'emoji_flags',
        role: 'ROLE_CONFIG_AIRPORT_MILESTONES',
      },
      ];
    },
    isTenantSetted() {
      return this.$store.getters.isTenantSetted;
    },
    currentTenant() {
      return this.isTenantSetted ? this.$store.state.tenant.currentTenant : {};
    },
  },
};
</script>

<style>
html {
  overflow-y: hidden;
}
/* fix the height of the menu cards */
.wrap a.v-card {
  height: 100%;
}
</style>
