export default class FlightPassage {
  constructor(id, dest, airport, male, female, child, infant, paxFirstClass, paxSecondClass, paxThirdClass, padFirstClass, padSecondClass, padThirdClass, bagsFirstClass, bagsSecondClass, bagsThirdClass, totalPaxOnBoardAdult, totalPaxOnBoardInfant, totalBags, realWeightBags, wchc, wchr, wchs, wcbd, wcbw, wclb, totalWheelchairs, umnr, petc) {
    this.id = id;
    this.dest = dest;
    this.airport = airport;
    this.male = male;
    this.female = female;
    this.child = child;
    this.infant = infant;
    this.paxFirstClass = paxFirstClass;
    this.paxSecondClass = paxSecondClass;
    this.paxThirdClass = paxThirdClass;
    this.padFirstClass = padFirstClass;
    this.padSecondClass = padSecondClass;
    this.padThirdClass = padThirdClass;
    this.bagsFirstClass = bagsFirstClass;
    this.bagsSecondClass = bagsSecondClass;
    this.bagsThirdClass = bagsThirdClass;
    this.totalPaxOnBoardAdult = totalPaxOnBoardAdult;
    this.totalPaxOnBoardInfant = totalPaxOnBoardInfant;
    this.totalBags = totalBags;
    this.realWeightBags = realWeightBags;
    this.wchc = wchc;
    this.wchr = wchr;
    this.wchs = wchs;
    this.wcbd = wcbd;
    this.wcbw = wcbw;
    this.wclb = wclb;
    this.totalWheelchairs = totalWheelchairs;
    this.umnr = umnr;
    this.petc = petc;
  }

  static createFlightPassage() {
    return new FlightPassage();
  }
}
