<script>
import Vue from 'vue';
import { diff } from 'deep-diff';
import TemporalCheckable from './TemporalCheckable.vue';

export default Vue.extend({
  mixins: [TemporalCheckable],
  methods: {
    assignInboundOrOutboundIfDontExist(to, from) {
      /* eslint no-param-reassign: "off" */
      if (to.inbound && from.inbound && from.inbound.operation && to.inbound.operation === null && from.inbound.operation) {
        to.inbound.operation = from.inbound.operation;
      }

      if (to.outbound && from.outbound && from.outbound.operation && to.outbound.operation === null && from.outbound.operation) {
        to.outbound.operation = from.outbound.operation;
      }
    },
    discardSameDatesWithDifferentFormat(differencies) {
      differencies = differencies
        .filter(d => !(d.rhs && this.isTemporalObject(d.rhs) && this.$moment(d.lhs).isValid() && this.$moment(d.rhs).format('YYYYMMDDHHmmss') === this.$moment(d.lhs).format('YYYYMMDDHHmmss')));
      return differencies;
    },
    getDifferenciesAndCreateOldValuesProperties(oldConnectedFlight, newConnectedFlight) {
      let differencies = diff(oldConnectedFlight, newConnectedFlight);

      if (differencies && differencies.length) {
        differencies = this.discardSameDatesWithDifferentFormat(differencies);
        differencies.forEach((d) => {
          newConnectedFlight[`${this.joinPropertyPath(d)}_old`] = d.lhs;
        });
      }
      return differencies;
    },
    joinPropertyPath(difference) {
      return difference.path.join('.');
    },
    joinAllPropertyPath(differences) {
      return differences.map(d => this.joinPropertyPath(d));
    },
    setOldValuesDifferencesFromOldConnectedFlight(oldConnectedFlight, newConnectedFlight) {
      const propertiesOldValueFromOldConnectedFlight = this.getPropertiesOldValueFromConnectedFlight(oldConnectedFlight);
      const propertiesOldValueFromNewConnectedFlight = this.getPropertiesOldValueFromConnectedFlight(newConnectedFlight);
      if (propertiesOldValueFromOldConnectedFlight && propertiesOldValueFromOldConnectedFlight.length) {
        propertiesOldValueFromOldConnectedFlight.forEach((p) => {
          if (!propertiesOldValueFromNewConnectedFlight.includes(p)) {
            newConnectedFlight[p] = oldConnectedFlight[p];
          }
        });
      }
    },
    getPropertiesOldValueFromConnectedFlight(connectedFlight) {
      return Object.keys(connectedFlight).filter(p => p.includes('_old'));
    },
  },
});
</script>
