import FlightContingencyFactory from './flightContingencyFactory';

export default class FlightContingencyEvent {
  constructor(
    type,
    ts,
    origin,
    payload,
  ) {
    this.type = type;
    this.ts = ts;
    this.origin = origin;
    this.payload = payload;
  }
}

export const createFlightContingencyEventFromData = function createFlightContingencyEventFromData(data) {
  if (data) {
    return new FlightContingencyEvent(
      data.type,
      data.ts,
      data.origin,
      FlightContingencyFactory.createFlightContingencyFromData(data.payload),
    );
  }

  throw new Error('No data for FlightContingencyEvent');
};

export const createFlightContingencyDetailEventFromData = function createFlightContingencyDetailEventFromData(data) {
  if (data) {
    return new FlightContingencyEvent(
      data.type,
      data.ts,
      data.origin,
      FlightContingencyFactory.createFlightContingencyDetailFromData(data.payload),
    );
  }

  throw new Error('No data for FlightContingencyDetailEvent');
};

export const createFlightContingencyPassageStateEventFromData = function createFlightContingencyPassageStateEventFromData(data) {
  if (data) {
    return new FlightContingencyEvent(
      data.type,
      data.ts,
      data.origin,
      FlightContingencyFactory.createFlightContingencyPassageStateFromData(data.payload),
    );
  }

  throw new Error('No data for FlightContingencyPassageStateEvent');
};

export const createFlightContingencyInformationEventFromData = function createFlightContingencyInformationEventFromData(data) {
  if (data) {
    return new FlightContingencyEvent(
      data.type,
      data.ts,
      data.origin,
      FlightContingencyFactory.createFlightContingencyInformationFromData(data.payload),
    );
  }

  throw new Error('No data for FlightContingencyInformationEvent');
};

export const createFlightContingencyBasicServiceEventFromData = function createFlightContingencyBasicServiceEventFromData(data) {
  if (data) {
    return new FlightContingencyEvent(
      data.type,
      data.ts,
      data.origin,
      FlightContingencyFactory.createFlightContingencyBasicServiceFromData(data.payload),
    );
  }

  throw new Error('No data for FlightContingencyBasicServiceEvent');
};

export const createFlightContingencyHtlTransportEventFromData = function createFlightContingencyHtlTransporEventFromData(data) {
  if (data) {
    return new FlightContingencyEvent(
      data.type,
      data.ts,
      data.origin,
      FlightContingencyFactory.createFlightContingencyHtlTransportFromData(data.payload),
    );
  }

  throw new Error('No data for FlightContingencyHtlTransportEvent');
};
