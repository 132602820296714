<template>
  <v-layout row justify-center>
    <v-dialog v-model="dialog" persistent max-width="1370px">
      <v-card :dark="this.$vuetify.dark" :color="this.$vuetify.dark ? 'bggrey_dark' : 'bggrey'">
        <v-toolbar dark color="primary">
          <v-icon>attach_file</v-icon>
          <v-toolbar-title>{{$t("scheduling.importFlightsFromFile")}}</v-toolbar-title>
        </v-toolbar>

        <loading-component />

        <v-card-text>
          <scheduling-import-stepper @import-stepper-cancel="reject" @import-stepper-confirm="confirm" />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-layout>
</template>
<script>

import SchedulingImportStepper from './SchedulingImportStepper.vue';
import LoadingComponent from '../../layout/LoadingComponent.vue';

export default {
  name: 'scheduling-import-modal',
  components: {
    SchedulingImportStepper,
    LoadingComponent,
  },
  props: ['dialog'],
  methods: {
    reject() {
      this.$emit('import-reject');
    },
    confirm() {
      this.$emit('import-confirm', {});
    },
  },
};
</script>
<style>
</style>
