<template>
  <ag-grid-template-cell-renderer :params="params">
    <v-icon small v-if="!isServiceRequested">remove</v-icon>
    <span v-if="isServiceRequested">{{serviceName}}</span>
  </ag-grid-template-cell-renderer>
</template>

<script>
import Vue from 'vue';
import AgGridTemplateCellRenderer from './templates/AgGridTemplateCellRenderer.vue';
import { JSUtils as TimeUtils } from '../../shared/js/TimeUtils';

export default Vue.extend({
  components: {
    AgGridTemplateCellRenderer,
  },
  computed: {
    isServiceRequested() {
      return !!this.params.value.serviceRequested || !!this.params.value.units;
    },
    serviceName() {
      return this.params.value.serviceName;
    },
    formattedTime() { // TODO refactorizar cell-renderer, sacar estos metodos a mixins (ej Timeable)
      return this.params.value.serviceRequestedTs ? this.$moment(this.params.value.serviceRequestedTs).format('HH:mm') : '';
    },
    dayModifier() {
      if (!this.params.value.serviceRequestedTs || !this.params.value.referenceDate) {
        return '';
      }
      const datesDiff = TimeUtils.getDatesDiff(this.params.value.serviceRequestedTs, this.params.value.referenceDate);
      if (datesDiff > 0) {
        return '+';
      } if (datesDiff < 0) {
        return '-';
      }
      return '';
    },
  },
});
</script>

<style scoped>

</style>
