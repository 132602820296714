<template>
  <div>
    <ag-grid-vue
      class="ag-theme-material connected-flights-table operational-table"
      :columnDefs="columnDefs"
      :defaultColDef="defaultColDef"
      :rowData="items"
      :rowSelection="'multiple'"
      :suppressRowClickSelection="true"
      :rowMultiSelectWithClick="false"
      @grid-ready="onGridReady"
      @first-data-rendered="firstDataRendered"
      @selection-changed="onSelectionChanged"
      :frameworkComponents="frameworkComponents"
      :gridOptions="gridOptions"
      :multiSortKey="multiSortKey"
      :overlayNoRowsTemplate="customNoRowsOverlay"
      :overlayLoadingTemplate="customLoadingOverlay"
      v-bind:style="`width: 100%; height: calc(100vh - ${this.sizeFilter});`">
    </ag-grid-vue>
  </div>
</template>
<script>
import { AgGridVue } from 'ag-grid-vue';
import AgGridConnectedFlightsAircraftTypeSelectCellEditor
from '../../../ag-grid/cell-editors/connectedFlights/AgGridConnectedFlightsAircraftTypeSelectCellEditor.vue';
import AgGridConnectedFlightsAircraftSelectCellEditor
from '../../../ag-grid/cell-editors/connectedFlights/AgGridConnectedFlightsAircraftSelectCellEditor.vue';
import AgGridConnectedFlightsTimeCellEditor
from '../../../ag-grid/cell-editors/connectedFlights/AgGridConnectedFlightsTimeCellEditor.vue';
import AgGridConnectedFlightsStringCellEditor
from '../../../ag-grid/cell-editors/connectedFlights/AgGridConnectedFlightsStringCellEditor.vue';
import AgGridConnectedFlightsDialogLoadCellEditor
from '../../../ag-grid/cell-editors/connectedFlights/dialog/AgGridConnectedFlightsDialogLoadCellEditor.vue';
import AgGridConnectedFlightsDialogDelayCellEditor
from '../../../ag-grid/cell-editors/connectedFlights/dialog/AgGridConnectedFlightsDialogDelayCellEditor.vue';
import AgGridConnectedFlightsRemarksCellEditor
from '../../../ag-grid/cell-editors/connectedFlights/dialog/AgGridConnectedFlightsRemarksCellEditor.vue';
import AgGridConnectedFlightsDialogRampStatusCellEditor
from '../../../ag-grid/cell-editors/connectedFlights/dialog/AgGridConnectedFlightsDialogRampStatusCellEditor.vue';
import AgGridValueBaseCellRenderer from '../../../ag-grid/cell-renderers/AgGridValueBaseCellRenderer.vue';
import AgGridTimeCellRenderer from '../../../ag-grid/cell-renderers/AgGridTimeCellRenderer.vue';
import AgGridConnectedFlights from '../../../ag-grid/mixins/AgGridConnectedFlights.vue';
import FlightsDifferences from '../../../mixins/FlightsDifferences.vue';

import { FlightBound } from '../../../shared/model/flightBound';

import { cellClassAgGridRules } from '../../../ag-grid/mixins/cellClassAgGridRules';
import { WEBSOCKET_CONNECTION } from '../../../../store/modules/websocket';
import AgGridCustomService from '../../../shared/services/agGridCustomService';
import AgGridValueWithAsteriskCellRenderer
from '../../../ag-grid/cell-renderers/AgGridValueWithAsteriskCellRenderer.vue';
import AgGridConnectedFlightsAirportParkingSelectCellEditor
from '../../../ag-grid/cell-editors/connectedFlights/AgGridConnectedFlightsAirportParkingSelectCellEditor.vue';
import AgGridConnectedFlightsAirportGateSelectCellEditor
from '../../../ag-grid/cell-editors/connectedFlights/AgGridConnectedFlightsAirportGateSelectCellEditor.vue';
import AgGridConnectedFlightsAirportBeltSelectCellEditor
from '../../../ag-grid/cell-editors/connectedFlights/AgGridConnectedFlightsAirportBeltSelectCellEditor.vue';
import AgGridConnectedFlightsAirportCarouselSelectCellEditor
from '../../../ag-grid/cell-editors/connectedFlights/AgGridConnectedFlightsAirportCarouselSelectCellEditor.vue';
import agGridTimeFormatter from '../../../ag-grid/formatters/agGridTimeFormatter';
import { FisModule } from '../../../ag-grid/cell-editors/model/fisModule';
import {
  WATER_TOILET_CHANGE_FLIGHT_SELECTION,
  WATER_TOILET_FIT_COLUMNS_TO_VIEW,
  WATER_TOILET_ON_EXTERNAL_CHANGE,
  WATER_TOILET_SET_CHANGE,
  WATER_TOILET_SET_GRID_API,
} from '../../../../store/modules/ramp/waterToilet';
import AgGridConnectedFlightsPercentageCellEditor
from '../../../ag-grid/cell-editors/connectedFlights/AgGridConnectedFlightsPercentageCellEditor.vue';
import AgGridValuePercentageCellRenderer from '../../../ag-grid/cell-renderers/AgGridValuePercentageCellRenderer.vue';
import agGridTextCustomFilterComparator from '../../../ag-grid/filters/agGridTextCustomFilterComparator';
import agGridTextCustomFilterFormatter from '../../../ag-grid/filters/agGridTextCustomFilterFormatter';
import RolePermissions from '../../../mixins/RolePermissions.vue';
import AgGridConnectedFlightsDialogFlightStatusIntegrationCellEditor
from '../../../ag-grid/cell-editors/connectedFlights/dialog/AgGridConnectedFlightsDialogFlightStatusIntegrationCellEditor.vue';
import AgGridFlightStatusCellRenderer from '../../../ag-grid/cell-renderers/AgGridFlightStatusCellRenderer.vue';
import AgGridValueAndSupFieldPossibleBlockedCellRenderer from '../../../ag-grid/cell-renderers/AgGridValueAndSupFieldPossibleBlockedCellRenderer.vue';
import AgGridNoRowsOverlay from '../../../ag-grid/mixins/AgGridNoRowsOverlay.vue';

export default {
  name: 'water_toilet-flight-list-table',
  mixins: [AgGridConnectedFlights, FlightsDifferences, RolePermissions, AgGridNoRowsOverlay],
  components: {
    AgGridVue,
  },
  props: {
    items: { type: Array, required: true },
    isFilterDisplayed: { type: Boolean, required: false, default: false },
  },
  mounted() {
    this.$store.dispatch(WEBSOCKET_CONNECTION, this.$store.state.tenant.currentTenant.name.toLowerCase());
  },
  computed: {
    sizeFilter() {
      return this.isFilterDisplayed ? '250px' : '180px';
    },
    fitColumnsOnResize() {
      return this.$store.state.waterToilet.fitColumnsOnResize;
    },
    routeName() {
      return this.$route.name;
    },
  },
  watch: {
    routeName: {
      handler() {
        this.setColumnVisibleOfView();
      },
    },
  },
  data() {
    const that = this;
    const rolePermission = this.getRoles();
    return {
      dialog: false,
      gridOptions: {
        onFilterChanged: this.uncheckAllOnFilter,
        getRowNodeId: this.getRowNodeId,
        getRowClass: this.getRowClass,
        floatingFilter: true,
        groupHeaderHeight: 35,
        headerHeight: 35,
        floatingFiltersHeight: 20,
        rowHeight: 30,
        context: {
          module: FisModule.WATER_TOILET,
        },
      },
      multiSortKey: 'ctrl',
      frameworkComponents: {
        AgGridConnectedFlightsAircraftSelectCellEditor,
        AgGridConnectedFlightsAircraftTypeSelectCellEditor,
        AgGridConnectedFlightsAirportBeltSelectCellEditor,
        AgGridConnectedFlightsAirportCarouselSelectCellEditor,
        AgGridConnectedFlightsAirportGateSelectCellEditor,
        AgGridConnectedFlightsAirportParkingSelectCellEditor,
        AgGridConnectedFlightsTimeCellEditor,
        AgGridConnectedFlightsDialogDelayCellEditor,
        AgGridConnectedFlightsDialogLoadCellEditor,
        AgGridConnectedFlightsStringCellEditor,
        AgGridConnectedFlightsDialogRampStatusCellEditor,
        AgGridConnectedFlightsRemarksCellEditor,
        AgGridConnectedFlightsPercentageCellEditor,
        AgGridValuePercentageCellRenderer,
        AgGridConnectedFlightsDialogFlightStatusIntegrationCellEditor,
      },
      defaultColDef: {
        resizable: true,
        sortable: true,
        minWidth: 30,
        suppressMenu: true,
        filter: 'agTextColumnFilter',
        filterParams: {
          textCustomComparator: agGridTextCustomFilterComparator,
          textFormatter: agGridTextCustomFilterFormatter,
        },
        colCustomConfig: {
          flightBound: FlightBound.OUTBOUND,
        },
        cellClassRules: cellClassAgGridRules,
        editable: false,
      },
      columnDefs: [
        {
          headerName: '',
          children: [
            {
              headerTooltip: 'Check/uncheck all',
              hide: !this.hasPermissions(rolePermission),
              checkboxSelection: true,
              width: 21,
              minWidth: 20,
              headerCheckboxSelection: true,
              headerCheckboxSelectionFilteredOnly: true,
              suppressMenu: true,
              sortable: false,
              suppressMovable: true,
              suppressSizeToFit: true,
              filter: false,
              resizable: false,
              colCustomConfig: {
                exportCsvDisabled: true,
              },
              editable: this.editable(true, rolePermission),
            },
            {
              headerName: 'SortDate',
              field: 'sortingTime',
              hide: true,
              sort: 'asc',
            },
          ],
        },
        {
          headerValueGetter() {
            return that.$i18n.t('common.departureFlight');
          },
          children: [
            {
              colId: 'outbound.common.pkn',
              headerValueGetter() {
                return that.$i18n.t('waterAndToilet.parking');
              },
              width: 50,
              cellClass: 'aircraft-config-cell',
              comparator: this.objectWithValueComparator,
              cellEditor: 'AgGridConnectedFlightsAirportParkingSelectCellEditor',
              colCustomConfig: {
                fieldPaths: ['common.parkingId'],
                flightBound: FlightBound.OUTBOUND,
                fieldsRelated: ['outbound.common.parkingCode', 'outbound.common.parkingRemote', 'outbound.common.parkingPushback', 'outbound.blockedFieldsIntegration.manualOverwriteParking'],
              },
              valueGetter(params) {
                let superindice = params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'parkingRemote']) ? 'R' : '';
                superindice += params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'parkingPushback']) ? 'P' : '';
                return {
                  value: params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'parkingCode']),
                  sup: superindice,
                  fieldBlockedIntegration: params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['blockedFieldsIntegration', 'manualOverwriteParking']),
                };
              },
              cellRendererFramework: AgGridValueAndSupFieldPossibleBlockedCellRenderer,
            },
            {
              colId: 'outbound.common.scheduledTime',
              headerValueGetter() {
                return that.$i18n.t('common.scheduledTimeOfDeparture');
              },
              width: 50,
              cellClass: 'airport-sta-cell',
              comparator: this.objectWithValueComparator,
              cellEditor: 'AgGridConnectedFlightsTimeCellEditor',
              colCustomConfig: {
                fieldPaths: ['common.scheduledTime'],
                flightBound: FlightBound.OUTBOUND,
                fieldsRelated: ['outbound.common.scheduledTime'],
              },
              valueGetter(params) {
                return that.agGridTimeGetter(params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'scheduledTime']), params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'scheduledDate']), null, null, that.scheduledTimeRequired);
              },
              valueFormatter: agGridTimeFormatter,
              cellRendererFramework: AgGridTimeCellRenderer,
            },
            {
              colId: 'outbound.common.flightNumber',
              headerValueGetter() {
                return that.getTextResizable(that.$i18n.t('waterAndToilet.flightNumberShort'), that.$i18n.t('waterAndToilet.flightNumber'));
              },
              width: 50,
              cellClass: 'flight-number-cell separator',
              comparator: this.objectWithValueComparator,
              cellEditor: 'agGridFlightNumberCellEditor',
              colCustomConfig: {
                flightBound: FlightBound.OUTBOUND,
                fieldsRelated: ['outbound.common.flightNumber'],
              },
              valueGetter(params) {
                if (that.isAircraftGround(params)) {
                  return {
                    value: params.data.getChildPropertyValue(FlightBound.INBOUND, ['common', 'airlineCode']),
                  };
                }
                return {
                  value: params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'flightNumber']),
                  asterisk: params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'flagAirlineOperation']),
                  tooltip: params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'airlineOperationMessage']),
                };
              },
              cellRendererFramework: AgGridValueWithAsteriskCellRenderer,
            },
            {
              colId: 'outbound.common.aircraftRegisterNumber',
              headerValueGetter() {
                return that.$i18n.t('waterAndToilet.aircraftRegistration');
              },
              width: 70,
              colCustomConfig: {
                fieldPaths: ['common.aircraftId', 'common.aircraftTypeId'],
                flightBound: FlightBound.OUTBOUND,
                fieldsRelated: ['outbound.common.aircraftRegisterNumber', 'outbound.common.aircraftAcars', 'outbound.blockedFieldsIntegration.manualOverwriteAircraft'],
              },
              valueGetter(params) {
                if (that.isAircraftGround(params)) {
                  return {
                    value: params.data.getChildPropertyValue(FlightBound.INBOUND, ['common', 'aircraftRegisterNumber']),
                    sup: params.data.getChildPropertyValue(FlightBound.INBOUND, ['common', 'aircraftAcars']) ? 'A' : null,
                  };
                }
                return {
                  value: params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'aircraftRegisterNumber']),
                  sup: params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'aircraftAcars']) ? 'A' : null,
                  fieldBlockedIntegration: params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['blockedFieldsIntegration', 'manualOverwriteAircraft']),
                };
              },
              cellRendererFramework: AgGridValueAndSupFieldPossibleBlockedCellRenderer,
            },
            {
              colId: 'outbound.common.aircraftTypeCode',
              headerValueGetter() {
                return that.$i18n.t('waterAndToilet.aircraftType');
              },
              width: 60,
              cellClass: 'aircraft-type-cell',
              comparator: this.objectWithValueComparator,
              cellEditor: 'AgGridConnectedFlightsAircraftTypeSelectCellEditor',
              colCustomConfig: {
                fieldPaths: ['common.aircraftId', 'common.aircraftTypeId'],
                flightBound: FlightBound.OUTBOUND,
                fieldsRelated: ['outbound.common.aircraftTypeIataCode', 'outbound.common.aircraftTypeIataCode',
                  'outbound.common.aircraftCargo', 'outbound.common.aircraftCargo', 'outbound.blockedFieldsIntegration.manualOverwriteAircraftType'],
              },
              valueGetter(params) {
                if (that.isAircraftGround(params)) {
                  return {
                    value: params.data.getChildPropertyValue(FlightBound.INBOUND, ['common', 'aircraftTypeCode']),
                    sup: params.data.getChildPropertyValue(FlightBound.INBOUND, ['common', 'aircraftCargo']) ? 'P' : null,
                    tooltip: params.data.getChildPropertyValue(FlightBound.INBOUND, ['common', 'aircraftTypeComments']),
                  };
                }
                return {
                  value: params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'aircraftTypeCode']),
                  sup: params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'aircraftCargo']) ? 'P' : null,
                  tooltip: params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'aircraftTypeComments']),
                  fieldBlockedIntegration: params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['blockedFieldsIntegration', 'manualOverwriteAircraftType']),
                };
              },
              cellRendererFramework: AgGridValueAndSupFieldPossibleBlockedCellRenderer,
            },
            {
              colId: 'outbound.common.airport1Code',
              headerValueGetter() {
                return that.$i18n.t('common.to');
              },
              width: 50,
              cellClass: 'airport-from-cell',
              comparator: this.objectWithValueComparator,
              cellEditor: 'agGridFromCellEditor',
              colCustomConfig: {
                flightBound: FlightBound.OUTBOUND,
                fieldsRelated: ['outbound.common.airport1Code'],
              },
              valueGetter(params) {
                return {
                  value: params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'airport1Code']),
                  asterisk: params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'hasSeveralAirports']),
                  tooltip: params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'hasSeveralAirports']) ? params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'airportsIata']) : undefined,
                };
              },
              cellRendererFramework: AgGridValueWithAsteriskCellRenderer,
            },
            {
              colId: 'outbound.integration.flightStatusIntegration',
              headerValueGetter() {
                return that.getTextResizable(that.$i18n.t('operations.airportStatusShort'), that.$i18n.t('operations.airportStatus'));
              },
              headerTooltip: (() => this.$i18n.t('operations.airportStatus'))(),
              width: 48,
              minWidth: 39,
              editable: this.isOutboundAndNotAircraftGround,
              cellEditor: 'AgGridConnectedFlightsDialogFlightStatusIntegrationCellEditor',
              comparator: this.objectWithValueComparator,
              cellClass: 'airport-status-cell',
              colCustomConfig: {
                flightBound: FlightBound.OUTBOUND,
                fieldsRelated: ['outbound.integration.flightStatusIntegration'],
              },
              flight: FlightBound.OUTBOUND,
              valueGetter(params) {
                return {
                  value: params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['integration', 'statusIntegration']),
                  tooltip: params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['integration', 'statusIntegration']) != null ? that.$i18n.t(`flightStatusIntegration.${params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['integration', 'statusIntegration'])}`) : null,
                };
              },
              cellRendererFramework: AgGridFlightStatusCellRenderer,
            },
          ],
        },
        {
          headerValueGetter() {
            return that.$i18n.t('waterAndToilet.waterService');
          },
          children: [
            {
              colId: 'outbound.waterToilet.waterRequiredQuantity',
              headerValueGetter() {
                return that.$i18n.t('waterAndToilet.requiredQuantity');
              },
              width: 50,
              editable: this.editable(true, rolePermission),
              cellClass: 'aircraft-config-cell',
              comparator: this.objectWithValueComparator,
              cellEditor: 'AgGridConnectedFlightsPercentageCellEditor',
              colCustomConfig: {
                fieldPaths: ['waterToilet.waterRequiredQuantity'],
                flightBound: FlightBound.ANY,
                fieldsRelated: ['outbound.waterToilet.waterRequiredQuantity'],
              },
              valueGetter(params) {
                if (that.isAircraftGround(params)) {
                  return params.data.getChildPropertyValue(FlightBound.INBOUND, ['waterToilet', 'waterRequiredQuantity']);
                }
                return params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['waterToilet', 'waterRequiredQuantity']);
              },
              cellRendererFramework: AgGridValuePercentageCellRenderer,
            },
            {
              colId: 'outbound.waterToilet.waterRealQuantity',
              headerValueGetter() {
                return that.$i18n.t('waterAndToilet.realQuantity');
              },
              width: 50,
              editable: this.editable(true, rolePermission),
              cellClass: 'aircraft-config-cell',
              comparator: this.objectWithValueComparator,
              cellEditor: 'AgGridConnectedFlightsPercentageCellEditor',
              colCustomConfig: {
                fieldPaths: ['waterToilet.waterRealQuantity'],
                flightBound: FlightBound.ANY,
                fieldsRelated: ['outbound.waterToilet.waterRealQuantity'],
              },
              valueGetter(params) {
                if (that.isAircraftGround(params)) {
                  return params.data.getChildPropertyValue(FlightBound.INBOUND, ['waterToilet', 'waterRealQuantity']);
                }
                return params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['waterToilet', 'waterRealQuantity']);
              },
              cellRendererFramework: AgGridValuePercentageCellRenderer,
            },
            {
              colId: 'outbound.waterToilet.waterInitHourService',
              headerValueGetter() {
                return that.$i18n.t('waterAndToilet.serviceInitHour');
              },
              width: 50,
              editable: this.editable(true, rolePermission),
              cellClass: 'aircraft-config-cell',
              comparator: this.objectWithValueComparator,
              cellEditor: 'AgGridConnectedFlightsTimeCellEditor',
              colCustomConfig: {
                fieldPaths: ['waterToilet.waterInitHourService'],
                flightBound: FlightBound.ANY,
                fieldsRelated: ['outbound.waterToilet.waterInitHourService'],
                oldValueFormatter: agGridTimeFormatter,
              },
              valueGetter(params) {
                if (that.isAircraftGround(params)) {
                  return that.agGridTimeGetter(params.data.getChildPropertyValue(FlightBound.INBOUND, ['waterToilet', 'waterInitHourService']), params.data.getChildPropertyValue(FlightBound.INBOUND, ['common', 'scheduledDate']));
                }
                return that.agGridTimeGetter(params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['waterToilet', 'waterInitHourService']), params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'scheduledDate']));
              },
              cellRendererFramework: AgGridTimeCellRenderer,
            },
            {
              colId: 'outbound.waterToilet.waterOperator',
              headerValueGetter() {
                return that.$i18n.t('waterAndToilet.operator');
              },
              width: 50,
              editable: this.editable(true, rolePermission),
              cellClass: 'aircraft-config-cell',
              comparator: this.objectWithValueComparator,
              cellEditor: 'AgGridConnectedFlightsStringCellEditor',
              colCustomConfig: {
                fieldPaths: ['waterToilet.waterOperator'],
                flightBound: FlightBound.ANY,
                fieldsRelated: ['outbound.waterToilet.waterOperator'],
              },
              valueGetter(params) {
                if (that.isAircraftGround(params)) {
                  return params.data.getChildPropertyValue(FlightBound.INBOUND, ['waterToilet', 'waterOperator']);
                }
                return params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['waterToilet', 'waterOperator']);
              },
              cellRendererFramework: AgGridValueBaseCellRenderer,
            },
            {
              colId: 'outbound.waterToilet.waterEquipmentNumber',
              headerValueGetter() {
                return that.$i18n.t('waterAndToilet.equipmentNumber');
              },
              width: 50,
              editable: this.editable(true, rolePermission),
              cellClass: 'aircraft-config-cell',
              comparator: this.objectWithValueComparator,
              cellEditor: 'AgGridConnectedFlightsStringCellEditor',
              colCustomConfig: {
                fieldPaths: ['waterToilet.waterEquipmentNumber'],
                flightBound: FlightBound.ANY,
                fieldsRelated: ['outbound.waterToilet.waterEquipmentNumber'],
              },
              valueGetter(params) {
                if (that.isAircraftGround(params)) {
                  return params.data.getChildPropertyValue(FlightBound.INBOUND, ['waterToilet', 'waterEquipmentNumber']);
                }
                return params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['waterToilet', 'waterEquipmentNumber']);
              },
              cellRendererFramework: AgGridValueBaseCellRenderer,
            },
            {
              colId: 'outbound.waterToilet.waterRemark',
              headerValueGetter() {
                return that.$i18n.t('waterAndToilet.remark');
              },
              width: 50,
              editable: this.editable(true, rolePermission),
              cellClass: 'aircraft-config-cell',
              comparator: this.objectWithValueComparator,
              cellEditor: 'AgGridConnectedFlightsStringCellEditor',
              colCustomConfig: {
                fieldPaths: ['waterToilet.waterRemark'],
                flightBound: FlightBound.ANY,
                fieldsRelated: ['outbound.waterToilet.waterRemark'],
              },
              valueGetter(params) {
                if (that.isAircraftGround(params)) {
                  return params.data.getChildPropertyValue(FlightBound.INBOUND, ['waterToilet', 'waterRemark']);
                }
                return params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['waterToilet', 'waterRemark']);
              },
              cellRendererFramework: AgGridValueBaseCellRenderer,
            },

          ],
        },
        {
          headerValueGetter() {
            return that.$i18n.t('waterAndToilet.toiletService');
          },
          children: [
            {
              colId: 'outbound.waterToilet.toiletInitHour',
              headerValueGetter() {
                return that.$i18n.t('waterAndToilet.serviceInitHour');
              },
              width: 50,
              editable: this.editable(true, rolePermission),
              cellClass: 'aircraft-config-cell',
              comparator: this.objectWithValueComparator,
              cellEditor: 'AgGridConnectedFlightsTimeCellEditor',
              colCustomConfig: {
                fieldPaths: ['waterToilet.toiletInitHour'],
                flightBound: FlightBound.ANY,
                fieldsRelated: ['outbound.waterToilet.toiletInitHour'],
                oldValueFormatter: agGridTimeFormatter,
              },
              valueGetter(params) {
                if (that.isAircraftGround(params)) {
                  return that.agGridTimeGetter(params.data.getChildPropertyValue(FlightBound.INBOUND, ['waterToilet', 'toiletInitHour']), params.data.getChildPropertyValue(FlightBound.INBOUND, ['common', 'scheduledDate']));
                }
                return that.agGridTimeGetter(params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['waterToilet', 'toiletInitHour']), params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'scheduledDate']));
              },
              cellRendererFramework: AgGridTimeCellRenderer,
            },
            {
              colId: 'outbound.waterToilet.toiletOperator',
              headerValueGetter() {
                return that.$i18n.t('waterAndToilet.operator');
              },
              width: 50,
              editable: this.editable(true, rolePermission),
              cellClass: 'aircraft-config-cell',
              comparator: this.objectWithValueComparator,
              cellEditor: 'AgGridConnectedFlightsStringCellEditor',
              colCustomConfig: {
                fieldPaths: ['waterToilet.toiletOperator'],
                flightBound: FlightBound.ANY,
                fieldsRelated: ['outbound.waterToilet.toiletOperator'],
              },
              valueGetter(params) {
                if (that.isAircraftGround(params)) {
                  return params.data.getChildPropertyValue(FlightBound.INBOUND, ['waterToilet', 'toiletOperator']);
                }
                return params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['waterToilet', 'toiletOperator']);
              },
              cellRendererFramework: AgGridValueBaseCellRenderer,
            },
            {
              colId: 'outbound.waterToilet.toiletEquipmentNumber',
              headerValueGetter() {
                return that.$i18n.t('waterAndToilet.equipmentNumber');
              },
              width: 50,
              editable: this.editable(true, rolePermission),
              cellClass: 'aircraft-config-cell',
              comparator: this.objectWithValueComparator,
              cellEditor: 'AgGridConnectedFlightsStringCellEditor',
              colCustomConfig: {
                fieldPaths: ['waterToilet.toiletEquipmentNumber'],
                flightBound: FlightBound.ANY,
                fieldsRelated: ['outbound.waterToilet.toiletEquipmentNumber'],
              },
              valueGetter(params) {
                if (that.isAircraftGround(params)) {
                  return params.data.getChildPropertyValue(FlightBound.INBOUND, ['waterToilet', 'toiletEquipmentNumber']);
                }
                return params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['waterToilet', 'toiletEquipmentNumber']);
              },
              cellRendererFramework: AgGridValueBaseCellRenderer,
            },
            {
              colId: 'outbound.waterToilet.toiletRemark',
              headerValueGetter() {
                return that.$i18n.t('waterAndToilet.remark');
              },
              width: 50,
              editable: this.editable(true, rolePermission),
              cellClass: 'aircraft-config-cell',
              comparator: this.objectWithValueComparator,
              cellEditor: 'AgGridConnectedFlightsStringCellEditor',
              colCustomConfig: {
                fieldPaths: ['waterToilet.toiletRemark'],
                flightBound: FlightBound.ANY,
                fieldsRelated: ['outbound.waterToilet.toiletRemark'],
              },
              valueGetter(params) {
                if (that.isAircraftGround(params)) {
                  return params.data.getChildPropertyValue(FlightBound.INBOUND, ['waterToilet', 'toiletRemark']);
                }
                return params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['waterToilet', 'toiletRemark']);
              },
              cellRendererFramework: AgGridValueBaseCellRenderer,
            },
          ],
        },
      ],
    };
  },
  methods: {
    onGetChanges(changes) {
      const rowNode = this.gridApi.getRowNode(changes.payload.id);

      if (!rowNode) {
        return;
      }
      const oldConnectedFlight = rowNode.data.clone();
      const newConnectedFlight = changes.payload;
      this.assignInboundOrOutboundIfDontExist(newConnectedFlight, oldConnectedFlight);
      const differencies = this.getDifferenciesAndCreateOldValuesProperties(oldConnectedFlight, newConnectedFlight);

      if (differencies && differencies.length > 0) {
        rowNode.setData(newConnectedFlight);
        if (this.$store.state.waterToilet.flashOnChanges) {
          this.renderNeededDiffs(differencies, rowNode); // TODO only shows flash
        }
        differencies.forEach(dif => this.$store.commit(WATER_TOILET_SET_CHANGE, { diff: dif, connectedFlights: newConnectedFlight }));
      }
    },
    onGridReady(params) {
      this.setAgGridApi(params);
      this.showLoading();
      this.$store.commit(WATER_TOILET_SET_GRID_API, params); // TODO eliminar api del store

      this.refreshCellsOnMutation();
      this.refreshCellsOnChangeLanguage();
      const that = this;

      this.subscribeMutation(WATER_TOILET_ON_EXTERNAL_CHANGE, (mutationPayload) => {
        that.onGetChanges(mutationPayload);
      });
    },
    firstDataRendered() {
      this.$nextTick(() => {
        this.resized();
      });
    },
    resized() {
      if (this.gridApi) {
        this.gridApi.sizeColumnsToFit();
      }
    },
    sizeColumnsToFit() {
      if (this.gridApi) {
        this.$store.dispatch(WATER_TOILET_FIT_COLUMNS_TO_VIEW);
      }
    },
    onSelectionChanged(event) {
      const selectedNodes = event.api.getSelectedNodes();
      const selectedData = selectedNodes.map(node => node.data);

      this.$store.commit(WATER_TOILET_CHANGE_FLIGHT_SELECTION, selectedData);
    },
    deleteItem(item) {
      console.log(`delete ${item}`); // eslint-disable-line no-console
    },
    editItem(item) {
      console.log(`edit ${item}`); // eslint-disable-line no-console
    },
    renderNeededDiffs(differences, rowNode) {
      const { gridApi } = this.$store.state.waterToilet;

      const agGridCustomService = new AgGridCustomService(this.columnDefs); // TODO one time
      const colIds = agGridCustomService.getColIdByMatchAnyfieldsRelated(this.joinAllPropertyPath(differences));

      if (colIds && colIds.length > 0) {
        colIds.forEach((colId) => {
          if (gridApi.getColumnDef(colId)) {
            gridApi.refreshCells({
              rowNodes: [rowNode],
              columns: [colId],
              force: true,
            });

            gridApi.flashCells({
              rowNodes: [rowNode],
              columns: [colId],
              force: true,
            });
          }
        });
      }
    },
    checkBoundAndSetMsgTooltipObject(messageValue) {
      if (messageValue === null || typeof (messageValue) === 'undefined') {
        return null;
      }
      if (messageValue === 'WARNING_LDM') {
        return {
          value: messageValue,
          icon: 'warning',
          color: 'red',
          tooltip: this.$t('messages.warningMessageLDM'),
        };
      }

      return {
        value: messageValue,
        icon: messageValue !== 'EMPTY' ? 'sms' : '',
        color: messageValue === 'UNREAD' ? 'success' : 'bggreydark',
        tooltip: messageValue === 'UNREAD' ? this.$t('messages.messagesUnread') : this.$t('messages.messagesRead'),
      };
    },
    checkBoundAndSetRemTooltipObject(remarkValue) {
      if (remarkValue === null || typeof (remarkValue) === 'undefined') {
        return null;
      }

      return {
        value: remarkValue,
        icon: remarkValue !== 'EMPTY' ? 'info' : '',
        color: remarkValue === 'UNREAD' ? 'primary' : 'bggreydark',
        tooltip: remarkValue === 'UNREAD' ? 'There are remarks unread' : 'All remarks are read',
      };
    },
    isAircraftGround(params) {
      if (params.data) {
        return params.data.getChildPropertyValue(FlightBound.INBOUND, ['common', 'id']) != null && params.data.getChildPropertyValue(FlightBound.INBOUND, ['common', 'tripNumber']) == null;
      }
      return params.getChildPropertyValue(FlightBound.INBOUND, ['common', 'id']) != null && params.getChildPropertyValue(FlightBound.INBOUND, ['common', 'tripNumber']) == null;
    },
    isNotAircraftGround(params) {
      return !this.isAircraftGround(params);
    },
  },
};

</script>
