<template>
  <div>
    <entity-configuration-modal-edit v-if="dialogEdit" :dialog="dialogEdit" :item="itemEdit" @edit-reject="editReject"
      @edit-confirm="editConfirm" :icon="entityIcon" :configEntity="entityName">
      <slot name="modal-content" :itemEdit="itemEdit"></slot>
    </entity-configuration-modal-edit>
    <entity-configuration-modal-delete v-if="dialogDelete" :dialog="dialogDelete" @delete-reject="deleteReject"
      @delete-confirm="deleteConfirm" :configEntity="entityName" />
    <entity-configuration-modal-enable-disable v-if="dialogEnableDisable" :dialog="dialogEnableDisable"
      :is-enable="itemIsEnable" @enable-disable-reject="enableDisableReject"
      @enable-disable-confirm="enableDisableConfirm" :configEntity="entityName" />

    <breadcrumbs />

    <entity-configuration-list-table :entity-name="entityName" :framework-components="frameworkComponents"
      :column-defs="columnDefs" :items="items" @click-delete-item="deleteItem" @click-edit-item="editItem"
      @click-enable-disable-item="enableDisableItem"
      :importEnabled="importEnabled"
      @click-import-csv-items="importCsvItems"/>
    <entity-configuration-list-options @click-new-item="newItem" />
  </div>
</template>
<script>
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import EntityConfigurationModalEdit from './EntityConfigurationModalEdit.vue';
import EntityConfigurationModalDelete from './EntityConfigurationModalDelete.vue';
import EntityConfigurationListOptions from './EntityConfigurationListOptions.vue';
import EntityConfigurationListTable from './EntityConfigurationListTable.vue';
import EntityConfigurationModalEnableDisable from './EntityConfigurationModalEnableDisable.vue';

export default {
  name: 'entity-configuration-list',
  components: {
    EntityConfigurationModalEdit,
    EntityConfigurationModalDelete,
    EntityConfigurationModalEnableDisable,
    EntityConfigurationListOptions,
    EntityConfigurationListTable,
    Breadcrumbs,
  },
  props: {
    entityName: { type: String, required: true },
    entityIcon: { type: String },
    columnDefs: { type: Array, required: true },
    frameworkComponents: { type: Object },
    items: { type: Array, required: true },
    importEnabled: { type: Boolean, default: false },
  },
  data() {
    return {
      dialogDelete: false,
      dialogEdit: false,
      dialogEnableDisable: false,
      itemSelected: [],
      itemEdit: null,
      itemDelete: null,
      itemEnableDisable: null,
      itemIsEnable: false,
    };
  },
  methods: {
    deleteConfirm() {
      this.$emit('confirm-delete-item', this.itemDelete);
      this.dialogDelete = false;
    },
    editConfirm(item) {
      this.$emit('confirm-edit-item', item);
      this.itemEdit = null;
      this.dialogEdit = false;
    },
    enableDisableConfirm() {
      this.$emit('confirm-enable-disable-item', this.itemEnableDisable);
      this.itemEnableDisable = null;
      this.dialogEnableDisable = false;
    },
    deleteItems() {
      this.dialogDelete = true;
    },
    deleteReject() {
      this.itemDelete = null;
      this.dialogDelete = false;
    },
    newItem() {
      if (!this.itemEdit) {
        this.itemEdit = {};
      } else {
        this.itemEdit = JSON.parse(JSON.stringify(this.itemEdit));
      }
      this.dialogEdit = true;
    },
    deleteItem(item) {
      this.itemDelete = item;
      this.dialogDelete = true;
    },
    editItem(item) {
      this.itemEdit = JSON.parse(JSON.stringify(item));
      this.dialogEdit = true;
    },
    editReject() {
      this.itemEdit = null;
      this.dialogEdit = false;
    },
    enableDisableItem(item) {
      this.itemEnableDisable = JSON.parse(JSON.stringify(item));
      this.itemIsEnable = this.itemEnableDisable.deleted;
      this.dialogEnableDisable = true;
    },
    enableDisableReject() {
      this.itemEnableDisable = null;
      this.dialogEnableDisable = false;
    },
    importCsvItems(csvData) {
      this.$emit('click-import-csv-items', csvData);
    },
  },
};
</script>
