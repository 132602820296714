import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{attrs:{"pa-0":""}},[_c(VLayout,{attrs:{"row":""}},[_c(VFlex,{attrs:{"xs12":"","text-xs-center":""}},[_c('h3',{staticClass:"subheading"},[_vm._v(_vm._s(_vm.msg))]),_c(VBtn,{attrs:{"flat":"","color":"primary"},on:{"click":_vm.addItem}},[_c(VIcon,{attrs:{"left":""}},[_vm._v(_vm._s(_vm.btnIcon))]),_vm._v(_vm._s(_vm.btnText)+" ")],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }