<template>
    <v-dialog v-model="dialogValue" max-width="1370px" persistent
    :content-class="isContentVisible ? 'modal-common' : 'modal-common hide-title'">
        <v-card :dark="this.$vuetify.dark" :color="this.$vuetify.dark ? 'bggrey_dark' : 'bggrey'">
          <v-toolbar dark color="primary">
            <v-icon>flight</v-icon>
            <v-toolbar-title v-if="editedDelivery.isNew()">{{$t("deliveries.newDelivery")}}</v-toolbar-title>
            <v-toolbar-title v-else>{{$t("deliveries.editDelivery")}}</v-toolbar-title>
            <v-spacer></v-spacer>
        <!-- Botón para contraer/expandir el contenido -->
        <v-btn icon @click="toggleContentVisibility">
          <v-icon>{{ isContentVisible ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
        </v-btn>
      </v-toolbar>
      <div v-show="isContentVisible">

          <v-card-text v-if="isExternal">

            <v-form ref="form" v-model="valid">
              <delivery-external-form :delivery-original="editedDelivery" @delivery-update="updateDelivery"/>
              <delivery-common-form :delivery-original="editedDelivery" @delivery-update="updateDelivery"/>
            </v-form>

          </v-card-text>

          <v-card-text v-else>

            <v-form ref="form" v-model="valid">
              <delivery-internal-form :delivery-original="editedDelivery" @delivery-update="updateDelivery"/>
              <delivery-common-form :delivery-original="editedDelivery" @delivery-update="updateDelivery"/>
            </v-form>

          </v-card-text>

          <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" flat @click.native="close">{{$t("common.cancel")}}</v-btn>
              <v-btn color="primary" depressed @click.native="save" :disabled="!valid">{{$t("common.save")}}</v-btn>
          </v-card-actions>
      </div>
        </v-card>
    </v-dialog>
</template>
<script>
import { NOTIFICATION_SHOW } from '../../../../store/modules/notifications';
import { REQUEST_ERROR, REQUEST_START, REQUEST_SUCCESS } from '../../../../store/store';
import DeliveryInternalForm from './DeliveryInternalForm.vue';
import DeliveryExternalForm from './DeliveryExternalForm.vue';
import DeliveryCommonForm from './DeliveryCommonForm.vue';


export default {
  name: 'delivery-edit-modal',
  components: {
    DeliveryExternalForm,
    DeliveryInternalForm,
    DeliveryCommonForm,
  },
  props: {
    dialog: { type: Boolean, required: true },
    isExternal: { type: Boolean, required: true },
    editedDelivery: { },
  },
  data() {
    return {
      valid: true,
      isContentVisible: true,
    };
  },
  computed: {
    dialogValue() {
      return this.dialog;
    },
  },
  methods: {
    save() {
      if (this.$refs.form.validate()) {
        this.$store.commit(REQUEST_START);
        this.$http.put('delivery-baggage', this.editedDelivery)
          .then(() => {
            this.close({ doFind: true });
            this.$store.commit(NOTIFICATION_SHOW, {
              message: this.$i18n.t('notifications.deliverySaved'),
              color: 'success',
            });
            this.$store.commit(REQUEST_SUCCESS);
          })
          .catch((err) => {
            this.close();
            this.$store.commit(NOTIFICATION_SHOW, {
              message: this.$i18n.t('notifications.errorNotification'),
              color: 'error',
            });
            this.$store.commit(REQUEST_ERROR, err);
          });
      }
    },
    close(result) {
      this.$emit('close', result);
    },
    updateDelivery(delivery) {
      this.editedDelivery = delivery;
    },
    toggleContentVisibility() {
      this.isContentVisible = !this.isContentVisible;
    },
  },
};
</script>
<style scoped>
  .max-width-btn-toggle-header {
    max-width: 146px;
  }
  .separator-btn-header {
    background-color: red;
    width: 3px;
  }
  .v-btn-toggle .v-btn.v-btn--active.v-btn--disabled i {
    color: white !important;;
  }
</style>
