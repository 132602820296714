/* eslint no-param-reassign: "off" */
import moment from 'moment';
import GanttFlightsOptions from '../../../components/gantt/GanttFlightsOptions';
import GanttFlightsFilter from '../../../components/gantt/GanttFlightsFilter';
import ConnectedFlightCompositionOperationFactory from '../../../components/shared/model/connectedFlightsFactory';
import { REQUEST_ERROR, REQUEST_START, REQUEST_SUCCESS } from '../../store';
import { HTTP } from '../../../http';
import GanttFlightsMapping from '../../../components/gantt/GanttFlightsMapping';


// action
export const RAMP_GANTT_FIND_FLIGHTS = 'RAMP_GANTT_FIND_FLIGHTS';

// mutations
export const RAMP_GANTT_LOADING = 'RAMP_GANTT_LOADING';
export const RAMP_GANTT_FIND_FLIGHTS_SUCCESS = 'RAMP_GANTT_FIND_FLIGHTS_SUCCESS';
export const RAMP_GANTT_SET_FLIGHTS = 'RAMP_GANTT_SET_FLIGHTS';

export const RAMP_GANTT_FILTER_RESET_DATES = 'RAMP_GANTT_FILTER_RESET_DATES';
export const RAMP_GANTT_FILTER_INIT = 'RAMP_GANTT_FILTER_INIT';
export const RAMP_GANTT_FILTER_INCREASE_DAY = 'RAMP_GANTT_FILTER_INCREASE_DAY ';
export const RAMP_GANTT_FILTER_DECREASE_DAY = 'RAMP_GANTT_FILTER_DECREASE_DAY ';

// initial state
const storeState = {
  loading: false,
  options: GanttFlightsOptions.createDefault(),
  filter: GanttFlightsFilter.createEmpty(),
  flights: [],
  ganttFlights: [],
};

// getters
const storeGetters = {
  getGanttFlight: state => (state.ganttFlights && state.ganttFlights.length > 0 ? state.ganttFlights : null),
};

// actions
const storeActions = {
  [RAMP_GANTT_FIND_FLIGHTS]: ({ commit, state }) => {
    commit(REQUEST_START);
    commit(RAMP_GANTT_LOADING, true);
    commit(RAMP_GANTT_FIND_FLIGHTS_SUCCESS, []);
    return HTTP.post('operations/flights/find', state.filter)
      .then((resp) => {
        commit(REQUEST_SUCCESS);
        commit(RAMP_GANTT_FIND_FLIGHTS_SUCCESS, ConnectedFlightCompositionOperationFactory.createConnectedFlightsFromData(resp.data));
        commit(RAMP_GANTT_SET_FLIGHTS, GanttFlightsMapping.mapConnectedFlights(state.flights, state.options));
        commit(RAMP_GANTT_LOADING, false);
      })
      .catch((err) => {
        commit(REQUEST_ERROR, err);
      });
  },
};

// mutations
const storeMutations = {
  [RAMP_GANTT_LOADING]: (state, loading) => {
    state.loading = loading;
  },
  [RAMP_GANTT_FIND_FLIGHTS_SUCCESS]: (state, connectedFlights) => {
    state.flights = connectedFlights;
  },
  [RAMP_GANTT_SET_FLIGHTS]: (state, ganttFlights) => {
    state.ganttFlights = ganttFlights;
  },
  [RAMP_GANTT_FILTER_RESET_DATES]: (state) => {
    state.filter.dateFrom = moment().hours(state.filter.dateFrom.hours()).minutes(state.filter.dateFrom.minutes());
    state.filter.dateTo = moment().hours(state.filter.dateTo.hours()).minutes(state.filter.dateTo.minutes());
  },
  [RAMP_GANTT_FILTER_INIT]: (state) => {
    state.filter.dateFrom = moment().startOf('hour');
    state.filter.dateTo = moment(state.filter.dateFrom).add(1, 'hours');
  },
  [RAMP_GANTT_FILTER_INCREASE_DAY]: (state) => {
    state.filter.dateFrom = moment().startOf('day')
      .year(state.filter.dateFrom.year())
      .month(state.filter.dateFrom.month())
      .date(state.filter.dateFrom.date())
      .add(1, 'days')
      .startOf('day');

    state.filter.dateTo = moment().endOf('day')
      .year(state.filter.dateTo.year())
      .month(state.filter.dateTo.month())
      .date(state.filter.dateTo.date())
      .add(1, 'days');
  },
  [RAMP_GANTT_FILTER_DECREASE_DAY]: (state) => {
    state.filter.dateFrom = moment().startOf('day')
      .year(state.filter.dateFrom.year())
      .month(state.filter.dateFrom.month())
      .date(state.filter.dateFrom.date())
      .subtract(1, 'days');

    state.filter.dateTo = moment().endOf('day')
      .year(state.filter.dateTo.year())
      .month(state.filter.dateTo.month())
      .date(state.filter.dateTo.date())
      .subtract(1, 'days')
      .endOf('day');
  },
};

export default {
  state: storeState,
  getters: storeGetters,
  actions: storeActions,
  mutations: storeMutations,
};
