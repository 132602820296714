import { JSUtils } from '../../shared/js/JSUtils';
import TowingAdhocTask from './towingAdhocTask';

export default class TowingAdhocTaskFactory {
  static mapTowingAdhocTask(data) {
    if (data && data.constructor === Array && data.length > 0) {
      return data.map(d => this.createTowingAdhocTaskFromData(d));
    }
    return [];
  }

  static createTowingAdhocTaskFromData(data) {
    if (!data) {
      return data;
    }

    return JSUtils.cast(data, TowingAdhocTask);
  }
}
