<template>
  <v-card>
    <v-list>
      <v-list-tile>
        <v-list-tile-content>
          <v-list-tile-title>{{$t("tableOptions.tableOptionsTitle")}}</v-list-tile-title>
        </v-list-tile-content>
      </v-list-tile>
    </v-list>

    <v-divider></v-divider>

    <v-list>
      <v-list-tile>
        <v-list-tile-action>
          <v-switch color="primary" v-model="fitColumnsOnResize" @change="updateFitColumnsOnResize()"></v-switch>
        </v-list-tile-action>
        <v-list-tile-content>
          <v-list-tile-title>{{$t("tableOptions.fitColumnsWidthBrowser")}}</v-list-tile-title>
          <v-list-tile-sub-title>{{$t("tableOptions.upToMinWidth")}}</v-list-tile-sub-title>
        </v-list-tile-content>
      </v-list-tile>

      <v-list-tile>
        <v-list-tile-action>
          <v-btn flat icon color="primary" @click="fitColumnsToView">
            <v-icon>compare_arrows</v-icon>
          </v-btn>
        </v-list-tile-action>
        <v-list-tile-content>
          <v-list-tile-title>{{$t("tableOptions.fitColumnsWidth")}}</v-list-tile-title>
          <v-list-tile-sub-title>{{$t("tableOptions.upToMinWidth")}}</v-list-tile-sub-title>
        </v-list-tile-content>
      </v-list-tile>

      <v-list-tile>
        <v-list-tile-action>
          <v-btn flat icon color="primary" @click="fitColumnsToBest">
            <v-icon>swap_horiz</v-icon>
          </v-btn>
        </v-list-tile-action>
        <v-list-tile-content>
          <v-list-tile-title>{{$t("tableOptions.fitColumnsWidthBest")}}</v-list-tile-title>
          <v-list-tile-sub-title>{{$t("tableOptions.horizontalScrollAppears")}}</v-list-tile-sub-title>
        </v-list-tile-content>
      </v-list-tile>
    </v-list>
  </v-card>
</template>
<script>

import {
  RAMP_FIT_COLUMNS_TO_BEST,
  RAMP_FIT_COLUMNS_TO_VIEW,
  RAMP_UPDATE_FIT_COLUMNS_ON_RESIZE,
} from '../../../store/modules/ramp/ramp';

export default {
  name: 'RampFlightsListTableOptions',
  data() {
    return {
      fitColumnsOnResize: null,
    };
  },
  methods: {
    updateFitColumnsOnResize() {
      this.$store.commit(RAMP_UPDATE_FIT_COLUMNS_ON_RESIZE, this.fitColumnsOnResize);
    },
    fitColumnsToView() {
      this.$store.dispatch(RAMP_FIT_COLUMNS_TO_VIEW);
    },
    fitColumnsToBest() {
      this.$store.dispatch(RAMP_FIT_COLUMNS_TO_BEST);
    },
  },
  created() {
    this.fitColumnsOnResize = this.$store.state.ramp.fitColumnsOnResize;
  },

};
</script>
