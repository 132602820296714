/* eslint no-param-reassign: "off" */
/* eslint no-confusing-arrow: "off" */
/* eslint no-nested-ternary: "off" */

import { HTTP } from '../../../http';
import { REQUEST_ERROR, REQUEST_START, REQUEST_SUCCESS } from '../../store';
import { NOTIFICATION_SHOW } from '../notifications';
import i18n from '../../../plugins/i18n';

// actions names
export const GET_AIRCRAFT_TYPES = 'GET_AIRCRAFT_TYPES';
export const AIRCRAFT_TYPES_DELETE = 'AIRCRAFT_TYPES_DELETE';
export const AIRCRAFT_TYPES_CREATE = 'AIRCRAFT_TYPES_CREATE';
export const AIRCRAFT_TYPES_UPDATE = 'AIRCRAFT_TYPES_UPDATE';
export const AIRCRAFT_TYPES_DISABLE = 'AIRCRAFT_TYPES_DISABLE';
export const AIRCRAFT_TYPES_ENABLE = 'AIRCRAFT_TYPES_ENABLE';

// mutations
export const CHANGE_AIRCRAFT_TYPES_SELECTION = 'CHANGE_AIRCRAFT_TYPES_SELECTION';
export const GET_AIRCRAFT_TYPES_SUCCESS = 'GET_AIRCRAFT_TYPES_SUCCESS';
export const GET_AIRCRAFT_TYPES_REQUESTED = 'GET_AIRCRAFT_TYPES_REQUESTED';
export const UPDATE_AIRCRAFT_TYPES_SUCCESS = 'UPDATE_AIRCRAFT_TYPES_SUCCESS';

// initial state
const storeState = {
  aircraftTypes: [],
  aircraftTypesRequested: false,
  selectedAircraftTypes: [],
};

// getters
const storeGetters = {
  getAircraftTypes: state => state.aircraftTypes.sort((a, b) => ((a.code > b.code) ? 1 : ((b.code > a.code) ? -1 : 0))),
  getActiveAircraftTypes: state => state.aircraftTypes.filter(elem => !elem.deleted),
  getAircraftTypeById: state => id => state.aircraftTypes.find(a => a.id === id),
  getAircraftTypesSelected: state => state.selectedAircraftTypes,
  hasSelectedAircraftTypes: state => state.selectedAircraftTypes.length > 0,
};

// actions
const storeActions = {
  [GET_AIRCRAFT_TYPES]: ({ commit, state }) => {
    if (state.aircraftTypesRequested) {
      return;
    }
    commit(REQUEST_START);
    commit(GET_AIRCRAFT_TYPES_REQUESTED);
    HTTP.get('aircraft-types')
      .then((resp) => {
        commit(REQUEST_SUCCESS);
        commit(GET_AIRCRAFT_TYPES_SUCCESS, resp.data);
      })
      .catch((err) => {
        commit(REQUEST_ERROR, err);
      });
  },

  [AIRCRAFT_TYPES_CREATE]: ({ commit, dispatch }, gate) => {
    commit(REQUEST_START);
    return HTTP.post('aircraft-types', gate)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(UPDATE_AIRCRAFT_TYPES_SUCCESS);
        dispatch(GET_AIRCRAFT_TYPES);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.aircraftTypeCreate'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },

  [AIRCRAFT_TYPES_UPDATE]: ({ commit, dispatch }, gate) => {
    commit(REQUEST_START);
    return HTTP.put('aircraft-types', gate)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(UPDATE_AIRCRAFT_TYPES_SUCCESS);
        dispatch(GET_AIRCRAFT_TYPES);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.aircraftTypeUpdate'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
  [AIRCRAFT_TYPES_DELETE]: ({ commit, dispatch }, id) => {
    commit(REQUEST_START);
    return HTTP.delete(`aircraft-types/${id}`)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(UPDATE_AIRCRAFT_TYPES_SUCCESS);
        dispatch(GET_AIRCRAFT_TYPES);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.aircraftTypeDelete'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
  [AIRCRAFT_TYPES_DISABLE]: ({ commit, dispatch }, id) => {
    commit(REQUEST_START);
    return HTTP.patch(`/aircraft-types/disable/${id}`)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(UPDATE_AIRCRAFT_TYPES_SUCCESS);
        dispatch(GET_AIRCRAFT_TYPES);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.aircraftTypeDisable'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
  [AIRCRAFT_TYPES_ENABLE]: ({ commit, dispatch }, id) => {
    commit(REQUEST_START);
    return HTTP.patch(`/aircraft-types/enable/${id}`)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(UPDATE_AIRCRAFT_TYPES_SUCCESS);
        dispatch(GET_AIRCRAFT_TYPES);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.aircraftTypeEnable'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
};

// mutations
const storeMutations = {
  [GET_AIRCRAFT_TYPES_SUCCESS]: (state, aircraftTypes) => {
    state.aircraftTypes = aircraftTypes;
  },
  [GET_AIRCRAFT_TYPES_REQUESTED]: (state) => {
    state.aircraftTypesRequested = true;
  },
  [UPDATE_AIRCRAFT_TYPES_SUCCESS]: (state) => {
    state.aircraftTypesRequested = false;
  },
  [CHANGE_AIRCRAFT_TYPES_SELECTION]: (state, selection) => {
    state.selectedAircraftTypes = selection;
  },
};

export default {
  state: storeState,
  getters: storeGetters,
  actions: storeActions,
  mutations: storeMutations,
};
