<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>

    <ag-grid-dialog-template-show :is-dialog-open="isDialogOpen" @cancel="cancel">

      <template v-slot:header>
        {{ $t('operations.airportStatus') }} {{ flightNumber }}
      </template>

      <template v-slot:default>
        <v-container grid-list-md text-xs-center fluid>
          <v-layout row wrap>
            <v-flex xs12>
              <v-card>
                <v-card-text>
                  <v-container fluid>
                    <v-layout align-center justify-center fill-height>
                      <v-flex xs2>
                        <span style="font-weight: bold">{{ $t("operations.airportStatus") }}</span>
                      </v-flex>
                      <v-flex xs3>
                        <span>{{ $t(`flightStatusIntegration.${status}`) }}</span>
                      </v-flex>
                      <v-flex xs2>
                        <span style="font-weight: bold">{{ $t("operations.updatedAt") }}</span>
                      </v-flex>
                      <v-flex xs3>
                        <span>{{ updateAt }}</span>
                      </v-flex>
                    </v-layout>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-layout>
        </v-container>
      </template>


    </ag-grid-dialog-template-show>

  </div>
</template>

<script>
import Vue from 'vue';
import AgGridDialogParentCellEditor from '../../mixins/AgGridDialogParentCellEditor.vue';
import AgGridDialogTemplateShow from '../../templates/AgGridDialogTemplateShow.vue';
import AgGridConnectedFlightsDialogCancellationCellInfo from './AgGridConnectedFlightsDialogCancellationCellInfo.vue';

export default Vue.extend({
  mixins: [AgGridDialogParentCellEditor],
  components: {
    AgGridDialogTemplateShow,
    AgGridConnectedFlightsDialogCancellationCellInfo,
  },
  data() {
    return {
      isDialogOpen: true,
    };
  },
  computed: {
    status() {
      return this.connectedFlights.getChildPropertyValue(this.flightBound, ['integration', 'statusIntegration']);
    },
    updateAt() {
      if (this.connectedFlights.getChildPropertyValue(this.flightBound, ['integration', 'updatedAtIntegration'])) {
        return this.$moment(this.connectedFlights.getChildPropertyValue(this.flightBound, ['integration', 'updatedAtIntegration'])).format('DD/MM/YY HH:mm');
      }
      return null;
    },
  },
  methods: {
  },
  created() {

  },
});
</script>
<style lang="scss" scoped></style>
