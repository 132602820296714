<template>
  <div class="login" tabindex="0" @keyup.enter="login" ref="container">
    <v-container>
      <v-layout
        text-xs-center
        wrap
      >
        <v-flex xs12>
          <v-img
            :src="require('../assets/logo.svg')"
            class="my-3"
            contain
            height="200"
          ></v-img>
        </v-flex>
        <v-flex xs12>
          <v-btn
            large
            color="primary"
            @click="login"
          >
            <v-icon
              x-large left>mdi-google</v-icon>
            Login with Google
          </v-btn>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>

import AuthService from '@/components/shared/services/auth/auth-service';
import { AUTH_CHECK } from '@/store/modules/auth';

export default {
  components: {
  },
  methods: {
    login() {
      this.startOAuth2Login();
    },
    startOAuth2Login() {
      const that = this;
      // TODO random fis_auth_state param
      const oauth2LoginWindow = window.open('/auth/oauth2/login', '_blank', 'toolbar=0,location=0,menubar=0');
      oauth2LoginWindow.focus();
      window.finishOAuth2Login = (options) => {
        that.startJwtLogin(options.accessToken).finally(() => {
          oauth2LoginWindow.close();
        });
      };
    },
    startJwtLogin(accessToken) {
      return this.$axiosDefault.post('/auth/jwt/access', { accessToken })
        .then((resp) => {
          AuthService.setAuth(resp.data);
          this.$store.dispatch(AUTH_CHECK).then(() => {
            this.$router.push('/home');
          });
        })
        .catch((error) => {
          console.log('error', error); // eslint-disable-line no-console
          AuthService.clearAuth();
        });
    },
  },
  mounted() {
    this.$refs.container.focus();
  },
};
</script>

<style>
  .login {
    background-image: url(../assets/bg-op4.jpg);
    background-position-x: 50%;
    height: 100vh;
    background-size: cover;
  }
</style>
