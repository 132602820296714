<template>
  <div>
    <v-autocomplete v-if="dataLoaded"
      placeholder="..."
      :value="value"
      :label="label"
      :items="items"
      :filter="customFilter"
      :clearable="true"
      item-text="code"
      item-value="id"
      :rules="rules"
      :disabled="disabled"
      :multiple="multiple"
      @input="emitInput"
      :ref="refComp">
      <template slot="item" slot-scope="{ item }">
        <v-list-tile-content>
          <v-list-tile-title v-if="item.type || item.code" >{{ item.type }} - {{ item.code }}</v-list-tile-title>
          <v-list-tile-sub-title>{{ item.description }}</v-list-tile-sub-title>
        </v-list-tile-content>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import { GET_AIRPORT_SLA_BAGGAGE_DELIVERY } from '../../../store/modules/airportSLABaggageDelivery/airportSLABaggageDeliveryCatalog';

export default {
  data() {
    return {
      dataLoaded: false,
    };
  },
  props: {
    value: { required: true },
    label: { type: String },
    rules: { type: Array },
    disabled: { type: Boolean },
    multiple: {
      type: Boolean,
      default: false,
    },
    refComp: {
      type: String,
      required: false,
      default: 'refComp',
    },
    type: { type: String },
  },
  computed: {
    items: {
      get() {
        let items;
        if (this.type) {
          const that = this;
          items = this.$store.getters.getActiveAirportSLABaggageDelivery.filter(item => item.type === that.type);
        } else {
          items = this.$store.getters.getActiveAirportSLABaggageDelivery;
        }
        return [{
          id: null, code: '', type: '', description: this.$t('common.empty'),
        }, ...items];
      },
    },
  },
  methods: {
    async getAirportSLABaggageDelivery() {
      await this.$store.dispatch(GET_AIRPORT_SLA_BAGGAGE_DELIVERY);
      this.dataLoaded = true;
    },
    customFilter(item, queryText) {
      const textOne = item.type ? item.type.toLowerCase() : '';
      const textTwo = item.code ? item.code.toLowerCase() : '';
      const textThree = item.description ? item.description.toLowerCase() : '';
      const searchText = queryText.toLowerCase();

      return textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1 || textThree.indexOf(searchText) > -1;
    },
    emitInput(event) {
      if (typeof (event) !== 'undefined') {
        this.$emit('input-code', this.items.find(p => p.id === event).code);
        this.$emit('input', event);
      } else {
        this.$emit('input-code', null);
        this.$emit('input', null);
      }
    },
  },
  async mounted() {
    await this.getAirportSLABaggageDelivery();
    this.$nextTick(() => {
      this.$refs[this.refComp].focus();
    });
  },
};
</script>
