import Vue from 'vue';
import Router from 'vue-router';
import store from '../store/store';
import i18n from '../plugins/i18n';

import HomePage from '../views/Home.vue';
import HomePageMobile from '../views/mobile/HomeMobile.vue';
import InfoMobile from '../views/mobile/InfoMobile.vue';
import MobileAdditionalInfo from '../views/mobile/additionalInfo/MobileAdditionalInfo.vue';
import MobileServiceMilestones from '../views/mobile/serviceMilestones/MobileServiceMilestones.vue';
import MobileBaggageDistribution from '../views/mobile/baggageDistribution/MobileBaggageDistribution.vue';
import MobileMessages from '../views/mobile/messages/MobileMessages.vue';
import MobileBaggageSearch from '../views/mobile/baggageSearch/MobileBaggageSearch.vue';
import LoginPage from '../views/Login.vue';
import HelpPage from '../views/Help.vue';
import OperationsFlightsList from '../views/OperationsFlightsList.vue';
import AdminAvinet from '../components/admin/avinet/AdminAvinet.vue';
import AdminFileNumber from '../components/admin/fileNumber/AdminFileNumber.vue';
import AdminAirportIntegrations from '../components/admin/airportIntegrations/AdminAirportIntegrations.vue';
import AdminInform from '../components/admin/inform/AdminInform.vue';
import SchedulingList from '../views/SchedulingList.vue';
import SchedulingWeightingList from '../views/SchedulingWeightingList.vue';
import TenantList from '../views/TenantList.vue';
import Configuration from '../views/Configuration.vue';
import RampFlightsList from '../views/RampFlightsList.vue';
import RampFlightsGantt from '../views/RampFlightsGantt.vue';
import PassageOutboundsFlightsList from '../views/PassageOutboundsFlightsList.vue';
import AirportList from '../components/configuration/airport/AirportList.vue';
import AirportGatesList from '../components/configuration/airportGates/AirportGatesList.vue';
import AirportCheckInCountersList from '../components/configuration/airportCheckInCounters/AirportCheckInCountersList.vue';
import AircraftList from '../components/configuration/aircraft/AircraftList.vue';
import AircraftTypesList from '../components/configuration/aircraftTypes/AircraftTypesList.vue';
import AirportParkingList from '../components/configuration/airportParking/AirportParkingList.vue';
import AirportBeltList from '../components/configuration/airportBelt/AirportBeltList.vue';
import AirportRequesterList from '../components/configuration/airportRequester/AirportRequesterList.vue';
import AirportCarouselList from '../components/configuration/airportCarousel/AirportCarouselList.vue';
import AirlineList from '../components/configuration/airline/AirlineList.vue';
import DelayTypesList from '../components/configuration/delayTypes/DelayTypesList.vue';
import Directory from '../views/Directory.vue';
import FlightPassageBoarding from '../components/passageBoarding/FlightPassageBoarding.vue';
import FlightMessagesList from '../views/FlightMessagesList.vue';
import PassageInboundsFlightsList from '../views/PassageInboundsFlightsList.vue';
import WaterToiletFlightsList from '../views/WaterToiletFlightsList.vue';
import LostAndFoundFlightsList from '../views/LostAndFoundFlightsList.vue';
import DeliveriesList from '../views/DeliveriesList.vue';
import BillingList from '../views/BillingList.vue';
import GHSIntegration from '../views/GHSIntegration.vue';
import AICDocumentstList from '../components/configuration/aicdocuments/AICDocumentsList.vue';
import FlightWeightingList from '../components/configuration/flightWeighting/FlightWeightingList.vue';
import RushesBaggageList from '../views/RushesBaggageList.vue';
import ContingenciesList from '../views/ContingenciesList.vue';
import ContingencyReportContainer from '../components/contingencyManagement/reports/ContingencyReportContainer.vue';
import HandlingTypesList from '../components/configuration/handlingTypes/HandlingTypesList.vue';
import RampServicesList from '../components/configuration/rampServices/RampServicesList.vue';
import AirportRampServicesList from '../components/configuration/airportRampServices/AirportRampServicesList.vue';
import UserList from '../components/configuration/user/UserList.vue';
import HandlingAgentList from '../components/configuration/handlingAgent/HandlingAgentList.vue';
import ServiceAgentsList from '../components/configuration/serviceAgents/ServiceAgentsList.vue';
import AirportServiceAgentsList from '../components/configuration/airportServiceAgents/AirportServiceAgentsList.vue';
import DelaysView from '../views/DelaysView.vue';
import AvinetUserList from '../components/configuration/avinetUsers/AvinetUserList.vue';
import AirlineFaresList from '../components/configuration/airlineFares/AirlineFaresList.vue';
import TicketingNumerationList from '../components/configuration/ticketingNumeration/TicketingNumerationList.vue';
import TicketingList from '../views/TicketingList.vue';
import TicketingListMobile from '../views/TicketingListMobile.vue';
import TicketingReportContainer from '../components/ticketing/report/TicketingReportContainer.vue';
import EmployeeIncome from '../views/EmployeeIncome.vue';
import AirportIncome from '../views/AirportIncome.vue';
import TallyingCashTicketingReport from '../views/TallyingCashTicketingReport.vue';
import MessageDirectoryList from '../components/configuration/messageDirectory/MessageDirectoryList.vue';
import ServiceTypeList from '../components/configuration/serviceType/ServiceTypeList.vue';
import AdminImportRampService from '../components/billing/rampServiceIntegration/AdminImportRampService.vue';
import TowingAdhocTasksFlightsList from '../views/TowingAdhocTasksFlightsList.vue';
import TowingAdhocTasksList from '../views/TowingAdhocTasksList.vue';
import AirportSLABaggageDeliveryList from '../components/configuration/airportSLABaggageDelivery/AirportSLABaggageDeliveryList.vue';
import AirportDefaultCheckInTime from '../components/configuration/airportDefaultCheckInTime/AirportDefaultCheckInTimeList.vue';
import AirportDefaultBoardingTime from '../components/configuration/airportDefaultBoardingTime/AirportDefaultBoardingTimeList.vue';
import MilestonesGlobalList from '../components/configuration/milestonesGlobal/MilestonesGlobalList.vue';
import AirportMilestonesList from '../components/configuration/airportMilestones/AirportMilestonesList.vue';
import DelayReasonsList from '../components/configuration/delayReasons/DelayReasonsList.vue';
import AdminImports from '../components/admin/imports/AdminImports.vue';

import {
  ROLE_SCHEDULING,
  ROLE_ADMINISTRATOR_TICKETING,
  ROLE_AVINET,
  ROLE_BAGGAGE,
  ROLE_BUSES,
  ROLE_CASHIER_TICKETING,
  ROLE_CONFIG,
  ROLE_CONFIG_AIRPORT,
  ROLE_CONFIG_AIRLINE,
  ROLE_CONFIG_AIRPORT_PARKING,
  ROLE_CONFIG_AIRPORT_GATES,
  ROLE_CONFIG_AIRPORT_CHECK_IN_COUNTERS,
  ROLE_CONFIG_AIRCRAFT_TYPES,
  ROLE_CONFIG_AIRCRAFT,
  ROLE_CONFIG_DELAY_TYPES,
  ROLE_CONFIG_AIRPORT_HANDLING_TYPES,
  ROLE_CONFIG_AIRPORT_BELTS,
  ROLE_CONFIG_AIRPORT_CAROUSELS,
  ROLE_CONFIG_RAMP_SERVICES,
  ROLE_CONFIG_AIRPORT_RAMP_SERVICES,
  ROLE_CONFIG_USERS,
  ROLE_CONFIG_HANDLING_AGENTS,
  ROLE_CONFIG_SERVICE_AGENTS,
  ROLE_CONFIG_AIRPORT_SERVICE_AGENTS,
  ROLE_CONFIG_AVINET_USERS,
  ROLE_CONFIG_AIRPORT_REQUESTERS,
  ROLE_CONFIG_SERVICE_TYPE,
  ROLE_CONFIG_AIRPORT_AIC_DOCUMENTS,
  ROLE_CONFIG_AIRPORT_FLIGHT_WEIGHTING,
  ROLE_CONFIG_AIRLINE_FARES,
  ROLE_CONFIG_AIRPORT_TICKETING_NUMERATION,
  ROLE_CONFIG_AIRPORT_MESSAGE_DIRECTORY,
  ROLE_CONFIG_AIRPORT_SLA_BAGGAGE_DELIVERY,
  ROLE_CONFIG_AIRPORT_DEFAULT_CHECK_IN_TIME,
  ROLE_CONFIG_AIRPORT_DEFAULT_BOARDING_TIME,
  ROLE_CONTINGENCY,
  ROLE_DELAYS,
  ROLE_DELIVERIES,
  ROLE_DIRECTORY,
  ROLE_DISPATCHER,
  ROLE_DUTY,
  ROLE_FILE_NUMBER,
  ROLE_FLIGHTS_WEIGHT,
  ROLE_GANTT,
  ROLE_GHM,
  ROLE_IATA,
  ROLE_INBOUNDS,
  ROLE_INFORM,
  ROLE_INVOICING,
  ROLE_LOST_FOUND,
  ROLE_OPERATIONS,
  ROLE_OUTBOUNDS,
  ROLE_RUSH_BAGGAGE,
  ROLE_SUPERVISOR_TICKETING,
  ROLE_TOWING_ADHOC_TASKS_APPLICANT,
  ROLE_TOWING_ADHOC_TASKS_DISPATCHER,
  ROLE_WATER_TOILET,
  ROLE_CONFIG_MILESTONES,
  ROLE_CONFIG_AIRPORT_MILESTONES,
  ROLE_CONFIG_DELAY_REASONS,
  AIRPORT_SERVICE_AGENT_ROLE_BAGGAGE_DISTRIBUTION_WR,
  AIRPORT_SERVICE_AGENT_ROLE_BAGGAGE_DISTRIBUTION,
  AIRPORT_SERVICE_AGENT_ROLE_MAIL_PERMISSION_WR,
  AIRPORT_SERVICE_AGENT_ROLE_MAIL_PERMISSION,
  AIRPORT_SERVICE_AGENT_ROLE_BAGGAGE_SEARCH,
} from '../constants/roles';

Vue.use(Router);

const router = new Router({
  routes: [
    { path: '/', redirect: '/home' },
    {
      path: '/login',
      name: 'login',
      component: LoginPage,
    },
    {
      path: '/tenant',
      name: 'tenant',
      component: TenantList,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/mobile',
      name: 'mobile',
      component: HomePageMobile,
      props: true,
      meta: {
        requiresAuth: true,
        requiresTenant: true,
        role: ROLE_DISPATCHER,
        breadcrumbs: [
          {
            text: i18n.t('sidebar.Mobile'),
            to: '/mobile',
          },
        ],
      },
    },
    {
      path: '/mobile/info/:flightId',
      name: 'info',
      component: InfoMobile,
      props: true,
      meta: {
        requiresAuth: true,
        requiresTenant: true,
        role: ROLE_DISPATCHER,
      },
    },
    {
      path: '/mobile/additionalInfo/:flightId',
      name: 'additionalInfo',
      component: MobileAdditionalInfo,
      props: true,
      meta: {
        requiresAuth: true,
        requiresTenant: true,
        role: ROLE_DISPATCHER,
      },
    },
    {
      path: '/mobile/serviceMilestones/:flightId',
      name: 'serviceMilestones',
      component: MobileServiceMilestones,
      props: true,
      meta: {
        requiresAuth: true,
        requiresTenant: true,
        role: ROLE_DISPATCHER,
      },
    },
    {
      path: '/mobile/baggageDistribution/:flightId',
      name: 'baggageDistribution',
      component: MobileBaggageDistribution,
      props: true,
      meta: {
        requiresAuth: true,
        requiresTenant: true,
        role: [AIRPORT_SERVICE_AGENT_ROLE_BAGGAGE_DISTRIBUTION_WR, AIRPORT_SERVICE_AGENT_ROLE_BAGGAGE_DISTRIBUTION],
      },
    },
    {
      path: '/mobile/messages/:flightId',
      name: 'mobileMessages',
      component: MobileMessages,
      props: true,
      meta: {
        requiresAuth: true,
        requiresTenant: true,
        role: [AIRPORT_SERVICE_AGENT_ROLE_MAIL_PERMISSION_WR, AIRPORT_SERVICE_AGENT_ROLE_MAIL_PERMISSION],
      },
    },
    {
      path: '/mobile/baggageSearch/:flightId',
      name: 'baggageSearch',
      component: MobileBaggageSearch,
      props: true,
      meta: {
        requiresAuth: true,
        requiresTenant: true,
        role: AIRPORT_SERVICE_AGENT_ROLE_BAGGAGE_SEARCH,
      },
    },
    {
      path: '/home',
      name: 'home',
      component: HomePage,
      meta: {
        requiresAuth: true,
        requiresTenant: true,
      },
    },
    {
      path: '/scheduling',
      name: 'scheduling',
      component: SchedulingList,
      meta: {
        requiresAuth: true,
        requiresTenant: true,
        role: ROLE_SCHEDULING,
        breadcrumbs: [
          {
            text: i18n.t('sidebar.Schedule'),
            to: '/scheduling',
          },
        ],
      },
    },
    {
      path: '/scheduling/imports',
      name: 'adminImports',
      component: AdminImports,
      meta: {
        requiresAuth: true,
        role: ROLE_SCHEDULING,
        requiresTenant: true,
        breadcrumbs: [
          {
            text: i18n.t('sidebar.importAdministration'),
            to: '/scheduling/imports',
          },
        ],
      },
    },
    {
      path: '/scheduling/weighting',
      name: 'schedulingWeighting',
      component: SchedulingWeightingList,
      meta: {
        requiresAuth: true,
        requiresTenant: true,
        role: ROLE_FLIGHTS_WEIGHT,
        breadcrumbs: [
          {
            text: i18n.t('sidebar.Flights Weighting'),
            to: '/scheduling/weighting',
          },
        ],
      },
    },
    {
      path: '/operations',
      name: 'operations',
      component: OperationsFlightsList,
      meta: {
        requiresAuth: true,
        role: ROLE_OPERATIONS,
        breadcrumbs: [
          {
            text: i18n.t('sidebar.Operations'),
            to: '/operations',
          },
        ],
      },
    },
    {
      path: '/passage/outbounds',
      name: 'passageOutbounds',
      component: PassageOutboundsFlightsList,
      meta: {
        requiresAuth: true,
        role: ROLE_OUTBOUNDS,
        breadcrumbs: [
          {
            text: i18n.t('sidebar.Outbounds'),
            to: '/passage',
          },
        ],
      },
    },
    {
      path: '/passage/inbounds',
      name: 'passageInbounds',
      component: PassageInboundsFlightsList,
      meta: {
        requiresAuth: true,
        role: ROLE_INBOUNDS,
        breadcrumbs: [
          {
            text: i18n.t('sidebar.Inbounds'),
            to: '/passage',
          },
        ],
      },
    },
    {
      path: '/ramp/duty',
      name: 'rampDuty',
      component: RampFlightsList,
      meta: {
        requiresAuth: true,
        role: ROLE_DUTY,
        breadcrumbs: [
          {
            text: i18n.t('sidebar.Duty'),
            to: '/ramp',
          },
        ],
      },
    },
    {
      path: '/ramp/baggage',
      name: 'rampBaggage',
      component: RampFlightsList,
      meta: {
        requiresAuth: true,
        role: ROLE_BAGGAGE,
        breadcrumbs: [
          {
            text: i18n.t('sidebar.Baggage'),
            to: '/ramp/baggage',
          },
        ],
      },
    },
    {
      path: '/ramp/buses',
      name: 'rampBuses',
      component: RampFlightsList,
      meta: {
        requiresAuth: true,
        role: ROLE_BUSES,
        breadcrumbs: [
          {
            text: i18n.t('sidebar.Buses'),
            to: '/ramp/buses',
          },
        ],
      },
    },
    {
      path: '/gantt',
      name: 'rampGantt',
      component: RampFlightsGantt,
      meta: {
        requiresAuth: true,
        role: ROLE_GANTT,
        breadcrumbs: [
          {
            text: i18n.t('sidebar.Gantt'),
            to: '/gantt',
          },
        ],
      },
    },
    {
      path: '/passage/boarding/:idFlight',
      name: 'passageBoarding',
      component: FlightPassageBoarding,
      meta: {
        requiresAuth: true,
        requiresTenant: true,
        role: ROLE_OUTBOUNDS,
        breadcrumbs: [
          {
            text: i18n.t('operations.boarding'),
            to: '/passage/boarding',
          },
        ],
      },
    },
    {
      path: '/ramp/water-toilet',
      name: 'rampWaterToilet',
      component: WaterToiletFlightsList,
      meta: {
        requiresAuth: true,
        role: ROLE_WATER_TOILET,
        breadcrumbs: [
          {
            text: i18n.t('sidebar.Water and Toilet'),
            to: '/ramp/water-toilet',
          },
        ],
      },
    },
    {
      path: '/lost-and-found',
      name: 'lostAndFound',
      component: LostAndFoundFlightsList,
      meta: {
        requiresAuth: true,
        role: ROLE_LOST_FOUND,
        breadcrumbs: [
          {
            text: i18n.t('sidebar.Lost And Found'),
            to: '/lost-and-found',
          },
        ],
      },
    },
    {
      path: '/deliveries',
      name: 'deliveries',
      component: DeliveriesList,
      meta: {
        requiresAuth: true,
        role: ROLE_DELIVERIES,
        breadcrumbs: [
          {
            text: i18n.t('sidebar.Deliveries'),
            to: '/lost-and-found/deliveries',
          },
        ],
      },
    },
    {
      path: '/rushBaggage',
      name: 'rushBaggage',
      component: RushesBaggageList,
      meta: {
        requiresAuth: true,
        role: ROLE_RUSH_BAGGAGE,
        breadcrumbs: [
          {
            text: i18n.t('sidebar.Rush Baggage'),
            to: '/lost-and-found/rush-baggage',
          },
        ],
      },
    },
    {
      path: '/invoicing',
      name: 'invoicing',
      component: BillingList,
      meta: {
        requiresAuth: true,
        role: ROLE_INVOICING,
        breadcrumbs: [
          {
            text: i18n.t('sidebar.Invoicing'),
            to: '/invoicing',
          },
        ],
      },
    },
    {
      path: '/invoicing/integration',
      name: 'invoicingIntegration',
      component: GHSIntegration,
      meta: {
        requiresAuth: true,
        role: ROLE_INVOICING,
        breadcrumbs: [
          {
            text: i18n.t('sidebar.Invoicing'),
            to: '/invoicing',
          },
        ],
      },
    },
    {
      path: '/invoicing/ramp-service-integration',
      name: 'invoicingImportRampService',
      component: AdminImportRampService,
      meta: {
        requiresAuth: true,
        role: ROLE_INVOICING,
        requiresTenant: true,
        breadcrumbs: [
          {
            text: i18n.t('sidebar.Invoicing'),
            to: '/invoicing',
          },
        ],
      },
    },
    {
      path: '/configuration',
      name: 'configuration',
      component: Configuration,
      meta: {
        requiresAuth: true,
        role: ROLE_CONFIG,
        breadcrumbs: [
          {
            text: i18n.t('sidebar.Configuration'),
            to: '/configuration',
          },
        ],
      },
    },
    {
      path: '/admin/avinet',
      name: 'adminAvinet',
      component: AdminAvinet,
      meta: {
        requiresAuth: true,
        role: ROLE_AVINET,
        requiresTenant: true,
        breadcrumbs: [
          {
            text: i18n.t('sidebar.Administration'),
            to: '/admin/avinet',
          },
        ],
      },
    },
    {
      path: '/admin/fileNumber',
      name: 'adminFileNumber',
      component: AdminFileNumber,
      meta: {
        requiresAuth: true,
        role: ROLE_FILE_NUMBER,
        requiresTenant: true,
        breadcrumbs: [
          {
            text: i18n.t('sidebar.Administration'),
            to: '/admin/fileNumber',
          },
        ],
      },
    },
    {
      path: '/admin/airport-integrations',
      name: 'adminAirportIntegrations',
      component: AdminAirportIntegrations,
      meta: {
        requiresAuth: true,
        role: ROLE_GHM,
        requiresTenant: true,
        breadcrumbs: [
          {
            text: i18n.t('sidebar.Administration'),
            to: '/admin/airport-integrations',
          },
        ],
      },
    },
    {
      path: '/admin/inform',
      name: 'adminInform',
      component: AdminInform,
      meta: {
        requiresAuth: true,
        role: ROLE_INFORM,
        requiresTenant: true,
        breadcrumbs: [
          {
            text: i18n.t('sidebar.Administration'),
            to: '/admin/inform',
          },
        ],
      },
    },
    {
      path: '/help',
      name: 'help',
      component: HelpPage,
      meta: {
        requiresAuth: true,
        requiresTenant: true,
        breadcrumbs: [
          {
            text: i18n.t('sidebar.Help'),
            to: '/help',
          },
        ],
      },
    },
    {
      path: '/delays',
      name: 'delays',
      component: DelaysView,
      meta: {
        requiresAuth: true,
        role: ROLE_DELAYS,
        breadcrumbs: [
          {
            text: i18n.t('sidebar.Delays'),
            to: '/delays',
          },
        ],
      },
    },
    {
      path: '/messages',
      name: 'messages',
      component: FlightMessagesList,
      meta: {
        requiresAuth: true,
        role: ROLE_IATA,
        breadcrumbs: [
          {
            text: i18n.t('sidebar.Flight messages'),
            to: '/messages',
          },
        ],
      },
    },
    {
      path: '/configuration/airport',
      name: 'airport',
      component: AirportList,
      meta: {
        requiresAuth: true,
        role: ROLE_CONFIG_AIRPORT,
        breadcrumbs: [
          {
            text: i18n.t('sidebar.Configuration'),
            to: '/configuration',
          },
          {
            text: i18n.t('breadcrumbs.airports'),
            to: '/configuration/airport',
          },
        ],
      },
    },
    {
      path: '/configuration/airline',
      name: 'airline',
      component: AirlineList,
      meta: {
        requiresAuth: true,
        role: ROLE_CONFIG_AIRLINE,
        breadcrumbs: [
          {
            text: i18n.t('sidebar.Configuration'),
            to: '/configuration',
          },
          {
            text: i18n.t('breadcrumbs.airlines'),
            to: '/configuration/airline',
          },
        ],
      },
    },
    {
      path: '/configuration/parkings',
      name: 'airportParking',
      component: AirportParkingList,
      meta: {
        requiresAuth: true,
        role: ROLE_CONFIG_AIRPORT_PARKING,
        breadcrumbs: [
          {
            text: i18n.t('sidebar.Configuration'),
            to: '/configuration',
          },
          {
            text: i18n.t('breadcrumbs.airportParkings'),
            to: '/configuration/parkings',
          },
        ],
      },
    },
    {
      path: '/configuration/gates',
      name: 'airportGates',
      component: AirportGatesList,
      meta: {
        requiresAuth: true,
        role: ROLE_CONFIG_AIRPORT_GATES,
        breadcrumbs: [
          {
            text: i18n.t('sidebar.Configuration'),
            to: '/configuration',
          },
          {
            text: i18n.t('configuration.airportGates'),
            to: '/configuration/gates',
          },
        ],
      },
    },
    {
      path: '/configuration/airportCheckInCounters',
      name: 'airportCheckInCounters',
      component: AirportCheckInCountersList,
      meta: {
        requiresAuth: true,
        role: ROLE_CONFIG_AIRPORT_CHECK_IN_COUNTERS,
        breadcrumbs: [
          {
            text: i18n.t('sidebar.Configuration'),
            to: '/configuration',
          },
          {
            text: i18n.t('breadcrumbs.checkInCounters'),
            to: '/configuration/airportCheckInCounters',
          },
        ],
      },
    },
    {
      path: '/configuration/aircraft/type',
      name: 'aircraftType',
      component: AircraftTypesList,
      meta: {
        requiresAuth: true,
        role: ROLE_CONFIG_AIRCRAFT_TYPES,
        breadcrumbs: [
          {
            text: i18n.t('sidebar.Configuration'),
            to: '/configuration',
          },
          {
            text: i18n.t('configuration.aircraftTypes'),
            to: '/configuration/aircraft/type',
          },
        ],
      },
    },
    {
      path: '/configuration/aircraft',
      name: 'aircraft',
      component: AircraftList,
      meta: {
        requiresAuth: true,
        role: ROLE_CONFIG_AIRCRAFT,
        breadcrumbs: [
          {
            text: i18n.t('sidebar.Configuration'),
            to: '/configuration',
          },
          {
            text: i18n.t('breadcrumbs.aircrafts'),
            to: '/configuration/aircraft',
          },
        ],
      },
    },
    {
      path: '/configuration/delay-types',
      name: 'delayTypes',
      component: DelayTypesList,
      meta: {
        requiresAuth: true,
        role: ROLE_CONFIG_DELAY_TYPES,
        breadcrumbs: [
          {
            text: i18n.t('sidebar.Configuration'),
            to: '/configuration',
          },
          {
            text: i18n.t('configuration.delayTypes'),
            to: '/configuration/delay-types',
          },
        ],
      },
    },
    {
      path: '/configuration/handling-types',
      name: 'handlingTypes',
      component: HandlingTypesList,
      meta: {
        requiresAuth: true,
        role: ROLE_CONFIG_AIRPORT_HANDLING_TYPES,
        breadcrumbs: [
          {
            text: i18n.t('sidebar.Configuration'),
            to: '/configuration',
          },
          {
            text: i18n.t('configuration.handlingTypes'),
            to: '/configuration/handling-types',
          },
        ],
      },
    },
    {
      path: '/configuration/belts',
      name: 'airportBelt',
      component: AirportBeltList,
      meta: {
        requiresAuth: true,
        role: ROLE_CONFIG_AIRPORT_BELTS,
        breadcrumbs: [
          {
            text: i18n.t('sidebar.Configuration'),
            to: '/configuration',
          },
          {
            text: i18n.t('configuration.airportBelts'),
            to: '/configuration/belts',
          },
        ],
      },
    },
    {
      path: '/configuration/carousels',
      name: 'airportCarousel',
      component: AirportCarouselList,
      meta: {
        requiresAuth: true,
        role: ROLE_CONFIG_AIRPORT_CAROUSELS,
        breadcrumbs: [
          {
            text: i18n.t('sidebar.Configuration'),
            to: '/configuration',
          },
          {
            text: i18n.t('configuration.airportCarousels'),
            to: '/configuration/carousels',
          },
        ],
      },
    },
    {
      path: '/configuration/ramp-services',
      name: 'rampServices',
      component: RampServicesList,
      meta: {
        requiresAuth: true,
        role: ROLE_CONFIG_RAMP_SERVICES,
        breadcrumbs: [
          {
            text: i18n.t('sidebar.Configuration'),
            to: '/configuration',
          },
          {
            text: i18n.t('common.rampServices'),
            to: '/configuration/ramp-services',
          },
        ],
      },
    },
    {
      path: '/configuration/airport-ramp-services',
      name: 'airportRampServices',
      component: AirportRampServicesList,
      meta: {
        requiresAuth: true,
        role: ROLE_CONFIG_AIRPORT_RAMP_SERVICES,
        breadcrumbs: [
          {
            text: i18n.t('sidebar.Configuration'),
            to: '/configuration',
          },
          {
            text: i18n.t('configuration.AirportRampServices'),
            to: '/configuration/airport-ramp-services',
          },
        ],
      },
    },
    {
      path: '/configuration/users',
      name: 'users',
      component: UserList,
      meta: {
        requiresAuth: true,
        role: ROLE_CONFIG_USERS,
        breadcrumbs: [
          {
            text: i18n.t('sidebar.Configuration'),
            to: '/configuration',
          },
          {
            text: i18n.t('configuration.users'),
            to: '/configuration/users',
          },
        ],
      },
    },
    {
      path: '/configuration/handling-agents',
      name: 'handlingAgents',
      component: HandlingAgentList,
      meta: {
        requiresAuth: true,
        role: ROLE_CONFIG_HANDLING_AGENTS,
        breadcrumbs: [
          {
            text: i18n.t('sidebar.Configuration'),
            to: '/configuration',
          },
          {
            text: i18n.t('configuration.handlingAgents'),
            to: '/configuration/handling-agents',
          },
        ],
      },
    },
    {
      path: '/configuration/service-agents',
      name: 'serviceAgents',
      component: ServiceAgentsList,
      meta: {
        requiresAuth: true,
        role: ROLE_CONFIG_SERVICE_AGENTS,
        breadcrumbs: [
          {
            text: i18n.t('sidebar.Configuration'),
            to: '/configuration',
          },
          {
            text: i18n.t('configuration.serviceAgents'),
            to: '/configuration/service-agents',
          },
        ],
      },
    },
    {
      path: '/configuration/airport-service-agents',
      name: 'airportServiceAgents',
      component: AirportServiceAgentsList,
      meta: {
        requiresAuth: true,
        role: ROLE_CONFIG_AIRPORT_SERVICE_AGENTS,
        breadcrumbs: [
          {
            text: i18n.t('sidebar.Configuration'),
            to: '/configuration',
          },
          {
            text: i18n.t('configuration.airportServiceAgents'),
            to: '/configuration/airport-service-agents',
          },
        ],
      },
    },
    {
      path: '/configuration/avinet-users',
      name: 'avinetUsers',
      component: AvinetUserList,
      meta: {
        requiresAuth: true,
        role: ROLE_CONFIG_AVINET_USERS,
        breadcrumbs: [
          {
            text: i18n.t('sidebar.Configuration'),
            to: '/configuration',
          },
          {
            text: i18n.t('configuration.avinetUsers'),
            to: '/configuration/avinet-users',
          },
        ],
      },
    },
    {
      path: '/configuration/requesters',
      name: 'airportRequester',
      component: AirportRequesterList,
      meta: {
        requiresAuth: true,
        role: ROLE_CONFIG_AIRPORT_REQUESTERS,
        breadcrumbs: [
          {
            text: i18n.t('sidebar.Configuration'),
            to: '/configuration',
          },
          {
            text: i18n.t('configuration.airportRequesters'),
            to: '/configuration/requesters',
          },
        ],
      },
    },
    {
      path: '/configuration/serviceType',
      name: 'serviceType',
      component: ServiceTypeList,
      meta: {
        requiresAuth: true,
        role: ROLE_CONFIG_SERVICE_TYPE,
        breadcrumbs: [
          {
            text: i18n.t('sidebar.Configuration'),
            to: '/configuration',
          },
          {
            text: i18n.t('configuration.serviceType'),
            to: '/configuration/serviceType',
          },
        ],
      },
    },
    {
      path: '/contingency',
      name: 'contingencyManagement',
      component: ContingenciesList,
      meta: {
        requiresAuth: true,
        role: ROLE_CONTINGENCY,
        breadcrumbs: [
          {
            text: i18n.t('breadcrumbs.contingencyManagement'),
            to: '/contingency',
          },
        ],
      },
    },
    {
      path: '/contingency/report/:contingencyId',
      name: 'contingencyReport',
      props: true,
      component: ContingencyReportContainer,
      meta: {
        requiresAuth: true,
        role: ROLE_CONTINGENCY,
        breadcrumbs: [
          {
            text: i18n.t('breadcrumbs.contingencyReport'),
            to: '/contingency/report',
          },
        ],
      },
    },
    {
      path: '/configuration/aic-documents',
      name: 'aicDocuments',
      component: AICDocumentstList,
      meta: {
        requiresAuth: true,
        role: ROLE_CONFIG_AIRPORT_AIC_DOCUMENTS,
        breadcrumbs: [
          {
            text: i18n.t('sidebar.Configuration'),
            to: '/configuration',
          },
          {
            text: i18n.t('configuration.aicDocuments'),
            to: '/configuration/aic-documents',
          },
        ],
      },
    },
    {
      path: '/configuration/flight-weighting',
      name: 'flightWeighting',
      component: FlightWeightingList,
      meta: {
        requiresAuth: true,
        role: ROLE_CONFIG_AIRPORT_FLIGHT_WEIGHTING,
        breadcrumbs: [
          {
            text: i18n.t('sidebar.Configuration'),
            to: '/configuration',
          },
          {
            text: i18n.t('configuration.flightWeighting'),
            to: '/configuration/flight-weighting',
          },
        ],
      },
    },
    {
      path: '/configuration/airlineFares',
      name: 'airlineFares',
      component: AirlineFaresList,
      meta: {
        requiresAuth: true,
        role: ROLE_CONFIG_AIRLINE_FARES,
        breadcrumbs: [
          {
            text: i18n.t('sidebar.Configuration'),
            to: '/configuration',
          },
          {
            text: i18n.t('configuration.airlineFares'),
            to: '/configuration/airlineFares',
          },
        ],
      },
    },
    {
      path: '/configuration/ticketingNumeration',
      name: 'ticketingNumeration',
      component: TicketingNumerationList,
      meta: {
        requiresAuth: true,
        role: ROLE_CONFIG_AIRPORT_TICKETING_NUMERATION,
        breadcrumbs: [
          {
            text: i18n.t('sidebar.Configuration'),
            to: '/configuration',
          },
          {
            text: i18n.t('configuration.ticketingNumeration'),
            to: '/configuration/ticketingNumeration',
          },
        ],
      },
    },
    {
      path: '/configuration/messageDirectory',
      name: 'messageDirectory',
      component: MessageDirectoryList,
      meta: {
        requiresAuth: true,
        role: ROLE_CONFIG_AIRPORT_MESSAGE_DIRECTORY,
        breadcrumbs: [
          {
            text: i18n.t('sidebar.Configuration'),
            to: '/configuration',
          },
          {
            text: i18n.t('configuration.messageDirectory'),
            to: '/configuration/messageDirectory',
          },
        ],
      },
    },
    {
      path: '/configuration/SLABaggageDelivery',
      name: 'slaBaggageDelivery',
      component: AirportSLABaggageDeliveryList,
      meta: {
        requiresAuth: true,
        role: ROLE_CONFIG_AIRPORT_SLA_BAGGAGE_DELIVERY,
        breadcrumbs: [
          {
            text: i18n.t('sidebar.Configuration'),
            to: '/configuration',
          },
          {
            text: i18n.t('configuration.airportSLABaggageDelivery'),
            to: '/configuration/SLABaggageDelivery',
          },
        ],
      },
    },
    {
      path: '/configuration/defaultCheckInTime',
      name: 'defaultCheckInTime',
      component: AirportDefaultCheckInTime,
      meta: {
        requiresAuth: true,
        role: ROLE_CONFIG_AIRPORT_DEFAULT_CHECK_IN_TIME,
        breadcrumbs: [
          {
            text: i18n.t('sidebar.Configuration'),
            to: '/configuration',
          },
          {
            text: i18n.t('configuration.defaultCheckInTime'),
            to: '/configuration/defaultCheckInTime',
          },
        ],
      },
    },
    {
      path: '/configuration/defaultBoardingTime',
      name: 'defaultBoardingTime',
      component: AirportDefaultBoardingTime,
      meta: {
        requiresAuth: true,
        role: ROLE_CONFIG_AIRPORT_DEFAULT_BOARDING_TIME,
        breadcrumbs: [
          {
            text: i18n.t('sidebar.Configuration'),
            to: '/configuration',
          },
          {
            text: i18n.t('configuration.defaultBoardingTime'),
            to: '/configuration/defaultBoardingTime',
          },
        ],
      },
    },
    {
      path: '/directory',
      name: 'directory',
      component: Directory,
      meta: {
        requiresAuth: true,
        role: ROLE_DIRECTORY,
      },
    },
    {
      path: '/ticketing',
      name: 'ticketing',
      component: TicketingList,
      meta: {
        requiresAuth: true,
        role: [ROLE_ADMINISTRATOR_TICKETING, ROLE_CASHIER_TICKETING, ROLE_SUPERVISOR_TICKETING],
        breadcrumbs: [
          {
            text: i18n.t('sidebar.ticketing'),
            to: '/ticketing',
          },
        ],
      },
    },
    {
      path: '/ticketingMobile',
      name: 'ticketingMobile',
      component: TicketingListMobile,
      meta: {
        requiresAuth: true,
        role: [ROLE_ADMINISTRATOR_TICKETING, ROLE_CASHIER_TICKETING, ROLE_SUPERVISOR_TICKETING],
        breadcrumbs: [
          {
            text: i18n.t('sidebar.ticketingMobile'),
            to: '/ticketingMobile',
          },
        ],
      },
    },
    {
      path: '/ticketing/report/:ticketingId?',
      name: 'ticketingReport',
      props: true,
      component: TicketingReportContainer,
      meta: {
        requiresAuth: true,
        role: [ROLE_ADMINISTRATOR_TICKETING, ROLE_CASHIER_TICKETING, ROLE_SUPERVISOR_TICKETING],
        breadcrumbs: [
          {
            text: i18n.t('breadcrumbs.ticketingReport'),
            to: '/ticketing/report',
          },
        ],
      },
    },
    {
      path: '/employeesCollection',
      name: 'employeeIncomeTicketingReport',
      component: EmployeeIncome,
      meta: {
        requiresAuth: true,
        role: ROLE_ADMINISTRATOR_TICKETING,
        breadcrumbs: [
          {
            text: i18n.t('sidebar.employeesCollection'),
            to: '/employeesCollection',
          },
        ],
      },
    },
    {
      path: '/airportsCollection',
      name: 'airportIncomeTicketingReport',
      component: AirportIncome,
      meta: {
        requiresAuth: true,
        role: ROLE_ADMINISTRATOR_TICKETING,
        breadcrumbs: [
          {
            text: i18n.t('sidebar.airportsCollection'),
            to: '/airportsCollection',
          },
        ],
      },
    },
    {
      path: '/tallyingCash',
      name: 'tallyingCashTicketingReport',
      component: TallyingCashTicketingReport,
      meta: {
        requiresAuth: true,
        role: [ROLE_CASHIER_TICKETING, ROLE_ADMINISTRATOR_TICKETING],
        breadcrumbs: [
          {
            text: i18n.t('sidebar.tallyingCash'),
            to: '/tallyingCash',
          },
        ],
      },
    },
    {
      path: '/towingAdHocTask/flights',
      name: 'towingAdhocTaskFlights',
      component: TowingAdhocTasksFlightsList,
      meta: {
        requiresAuth: true,
        role: [ROLE_TOWING_ADHOC_TASKS_APPLICANT, ROLE_TOWING_ADHOC_TASKS_DISPATCHER],
        breadcrumbs: [
          {
            text: i18n.t('sidebar.towingAdHocTask'),
            to: '/towingAdHocTask',
          },
        ],
      },
    },
    {
      path: '/towingAdHocTask',
      name: 'towingAdhocTask',
      component: TowingAdhocTasksList,
      meta: {
        requiresAuth: true,
        role: [ROLE_TOWING_ADHOC_TASKS_APPLICANT, ROLE_TOWING_ADHOC_TASKS_DISPATCHER],
        breadcrumbs: [
          {
            text: i18n.t('sidebar.towingAdHocTask'),
            to: '/towingAdHocTask',
          },
        ],
      },
    },
    {
      path: '/configuration/milestones-global',
      name: 'milestonesGlobal',
      component: MilestonesGlobalList,
      meta: {
        requiresAuth: true,
        role: ROLE_CONFIG_MILESTONES,
        breadcrumbs: [
          {
            text: i18n.t('sidebar.Configuration'),
            to: '/configuration',
          },
          {
            text: i18n.t('breadcrumbs.milestonesGlobal'),
            to: '/configuration/milestones-global',
          },
        ],
      },
    },
    {
      path: '/configuration/milestones-airport',
      name: 'airportMilestones',
      component: AirportMilestonesList,
      meta: {
        requiresAuth: true,
        role: ROLE_CONFIG_AIRPORT_MILESTONES,
        breadcrumbs: [
          {
            text: i18n.t('sidebar.Configuration'),
            to: '/configuration',
          },
          {
            text: i18n.t('breadcrumbs.milestonesAirport'),
            to: '/configuration/milestones-airport',
          },
        ],
      },
    },
    {
      path: '/configuration/delay-reasons',
      name: 'delayReasons',
      component: DelayReasonsList,
      meta: {
        requiresAuth: true,
        role: ROLE_CONFIG_DELAY_REASONS,
        breadcrumbs: [
          {
            text: i18n.t('sidebar.Configuration'),
            to: '/configuration',
          },
          {
            text: i18n.t('breadcrumbs.delayReasons'),
            to: '/configuration/delay-reasons',
          },
        ],
      },
    },
  ],
});

router.beforeEach((to, from, next) => {
  function process() {
    if (to.name === 'login' && store.getters.isAuthenticated) {
      next('/');
      return;
    }
    if (to.matched.some(record => record.meta.requiresAuth)) {
      if (store.getters.isAuthenticated) {
        if (to.matched.some(record => record.meta.requiresTenant) && !store.getters.isTenantSetted) {
          next('/tenant');
        } else if (!!to.meta.role && !store.getters.hasRole(to.meta.role)) {
          next('/login');
        } else {
          next();
        }
      } else {
        next('/login');
      }
    } else {
      next();
    }
  }

  // TODO refactorizar
  if (store.state.auth.authStatus === null || store.state.auth.authStatus === 'loading') {
    const authWatcher = store.watch(state => state.auth.authStatus, (authStatus) => {
      console.log(authStatus); // eslint-disable-line no-console
      if (authStatus !== null && authStatus !== 'loading') {
        authWatcher(); // stop watching
        if (store.getters.isAuthenticated && !store.state.tenant.currentTenantRequested) {
          const tenantWatcher = store.watch(state => state.tenant.currentTenantRequested, (currentTenantRequested) => {
            if (currentTenantRequested) {
              tenantWatcher(); // stop watching
              process();
            }
          });
        } else {
          process();
        }
      }
    });
  } else {
    process();
  }
});

export default router;
