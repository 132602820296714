<template>
  <lost-and-found-flights-list-table :items="items" :isFilterDisplayed="this.isFilterDisplayed"></lost-and-found-flights-list-table>
</template>
<script>
import LostAndFoundFlightsListTable from './LostAndFoundFlightsListTable.vue';
import { LOST_AND_FOUND_FILTER_RESET, LOST_AND_FOUND_FIND_FLIGHTS } from '../../../store/modules/lostAndFound/lostAndFound';

export default {
  name: 'lost-and-found-flights-list-container',
  components: {
    LostAndFoundFlightsListTable,
  },
  props: {
    isFilterDisplayed: { type: Boolean, required: false, default: false },
  },
  mounted() {
    this.$store.commit(LOST_AND_FOUND_FILTER_RESET);
    this.$store.dispatch(LOST_AND_FOUND_FIND_FLIGHTS);
  },
  computed: {
    items() {
      return this.$store.state.lostAndFound.connectedFlightsLostAndFound;
    },
  },
};
</script>
<style lang="scss">


</style>
