<template>
  <ag-grid-template-cell-renderer :params="params">
    <span>{{params.value?(params.value + "%"):""}}</span>
  </ag-grid-template-cell-renderer>
</template>

<script>
import Vue from 'vue';
import AgGridTemplateCellRenderer from './templates/AgGridTemplateCellRenderer.vue';

export default Vue.extend({
  components: {
    AgGridTemplateCellRenderer,
  },
});
</script>
