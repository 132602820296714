<template>
  <ag-grid-vue
    class="ag-theme-material"
    :defaultColDef="defaultColDef"
    :columnDefs="columnDefs"
    :rowData="items"
    :onGridReady="onGridReady"
    :suppressHorizontalScroll="true"
    rowSelection="multiple"
    :frameworkComponents="frameworkComponents"
    :gridOptions="gridOptions"
    @selection-changed="onSelectionChanged"
    style="width: 100%; height: calc(100vh - 500px);">
  </ag-grid-vue>
</template>
<script>
import { AgGridVue } from 'ag-grid-vue';
import AgGridApiHoldable from '../../../ag-grid/mixins/AgGridApiHoldable.vue';
import AgGridTimeValueGetteable from '../../../ag-grid/mixins/AgGridTimeValueGetteable.vue';
import Resizable from '../../../mixins/Resizable.vue';

import AgGridValueComparable from '../../../ag-grid/mixins/AgGridValueComparable.vue';
import { cellClassAgGridRules } from '../../../ag-grid/mixins/cellClassAgGridRules';
import agGridTextCustomFilterComparator from '../../../ag-grid/filters/agGridTextCustomFilterComparator';
import agGridTextCustomFilterFormatter from '../../../ag-grid/filters/agGridTextCustomFilterFormatter';
import AgGridValueBaseCellRenderer from '../../../ag-grid/cell-renderers/AgGridValueBaseCellRenderer.vue';
import { FisModule } from '../../../ag-grid/cell-editors/model/fisModule';
import AgGridTimeCellRenderer from '../../../ag-grid/cell-renderers/AgGridTimeCellRenderer.vue';
import agGridTimeFormatter from '../../../ag-grid/formatters/agGridTimeFormatter';
import { FlightBound } from '../../../shared/model/flightBound';

export default {
  name: 'rush-baggage-base-grid',
  mixins: [AgGridApiHoldable, AgGridTimeValueGetteable, Resizable, AgGridValueComparable],
  components: {
    AgGridVue,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    const that = this;
    return {
      frameworkComponents: {
        AgGridValueBaseCellRenderer,
        AgGridTimeCellRenderer,
      },
      gridOptions: {
        getRowNodeId: this.getRowNodeId,
        getRowClass: this.getRowClass,
        context: {
          module: FisModule.LOST_AND_FOUND,
        },
      },
      defaultColDef: {
        cellClassRules: cellClassAgGridRules,
        resizable: true,
        sortable: true,
        filter: true,
        filterParams: {
          textCustomComparator: agGridTextCustomFilterComparator,
          textFormatter: agGridTextCustomFilterFormatter,
        },
      },
      columnDefs: [
        {
          checkboxSelection: true,
          width: 30,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          suppressMenu: true,
          sortable: false,
          suppressMovable: true,
          suppressSizeToFit: true,
          filter: false,
          resizable: false,
        },
        {
          colId: 'rushBaggage.flightNo',
          headerName: 'Flight',
          editable: false,
          cellClass: 'flight-number-cell separator',
          valueGetter(params) {
            return params.data.flightNo();
          },
          cellRendererFramework: AgGridValueBaseCellRenderer,
        },
        {
          colId: 'rushBaggage.flightDate',
          headerName: 'Date',
          field: 'flightDate',
          editable: false,
          cellClass: 'date-cell separator',
          valueFormatter(params) {
            return that.$moment(params.data.flightDate).format('DD/MM/YYYY');
          },
        },
        {
          colId: 'rushBaggage.scheduledTime',
          headerName: 'STD',
          field: 'scheduledTime',
          editable: false,
          cellClass: 'airline-from-cell',
          colCustomConfig: {
            flightBound: FlightBound.INBOUND,
            fieldsRelated: ['rushBaggage.flightDate'],
            oldValueFormatter: agGridTimeFormatter,
          },
          valueGetter(params) {
            return that.agGridTimeGetter(params.data.scheduledTime, params.data.flightDate);
          },
          valueFormatter: agGridTimeFormatter,
          cellRendererFramework: AgGridTimeCellRenderer,
        },
        {
          colId: 'rushBaggage.airport1Code',
          headerName: 'To',
          field: 'airport1Code',
          editable: false,
          cellClass: 'airport-from-cell',
          valueGetter(params) {
            return params.data.airportIata();
          },
        },
        {
          colId: 'rushBaggage.passengerName',
          field: 'passengerName',
          headerName: 'Passenger\'s Name / File Reference',
          editable: false,
        },
        {
          colId: 'rushBaggage.rushTag',
          field: 'rushTag',
          headerName: 'Rush Tag',
          editable: false,
        },
        {
          colId: 'rushBaggage.originalTag',
          field: 'originalTag',
          headerName: 'Original Tag',
          editable: false,
        },
        {
          colId: 'rushBaggage.agent',
          field: 'agent',
          headerName: 'Agent',
          editable: false,
        },
      ],
    };
  },
  methods: {
    onGridReady(params) {
      this.setAgGridApi(params);
      this.selectAll();
    },
    selectAll() {
      this.gridApi.selectAllFiltered();
    },
    onSelectionChanged() {
      const selectedNodes = this.gridApi.getSelectedNodes();
      this.$emit('selected-changed', selectedNodes.map(node => node.data));
    },
  },
};
</script>
<style>
</style>
