import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VChip } from 'vuetify/lib/components/VChip';
import { VListTileContent } from 'vuetify/lib/components/VList';
import { VListTileSubTitle } from 'vuetify/lib/components/VList';
import { VListTileTitle } from 'vuetify/lib/components/VList';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VAutocomplete,{attrs:{"value":_vm.value,"items":_vm.items,"label":_vm.label,"placeholder":_vm.placeholder,"prepend-icon":"local_airport","filter":_vm.customFilter,"disabled":_vm.disabled,"multiple":_vm.multiple,"clearable":true,"item-text":"name","item-value":"id","hide-details":"","rules":_vm.rules},on:{"input":_vm.emitInput},scopedSlots:_vm._u([(_vm.chipView)?{key:"selection",fn:function(data){return [_c(VChip,{staticClass:"chip--select-multi",attrs:{"selected":data.selected,"close":""},on:{"input":function($event){return _vm.remove(data.item)}}},[_vm._v(" "+_vm._s(data.item.name)+" ")])]}}:null,{key:"item",fn:function(data){return [_c(VListTileContent,[_c(VListTileTitle,[_vm._v(_vm._s(data.item.description))]),_c(VListTileSubTitle,[_vm._v(_vm._s(`${data.item.name} · ${data.item.country}`))])],1)]}}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }