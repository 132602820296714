/* eslint no-param-reassign: "off" */

import moment from 'moment';
import { saveAs } from 'file-saver';
import SchedulingFilter from '../../components/scheduling/model/schedulingFilter';
import ConnectedFlightsFactory from '../../components/shared/model/connectedFlightsFactory';
import { HTTP } from '../../http';
import { REQUEST_ERROR, REQUEST_START, REQUEST_SUCCESS } from '../store';
import { NOTIFICATION_SHOW } from './notifications';
import schedulingWeighting from './schedulingWeighting';
import { ACTION_SET_NO_FLIGHTS_TO_SHOW_MESSAGE } from './noFlightsToShowMessages';
import i18n from '../../plugins/i18n';
import { createFlightEventFromData } from '../../components/shared/model/flightEvent';
import PaginationResponseProperties from '../../components/ui/pagination/model/paginationResponseProperties';

// action
export const SCHED_GRID_FIT = 'SCHED_GRID_FIT';
export const SCHED_GRID_REFRESH_CELLS = 'SCHED_GRID_REFRESH_CELLS';
export const SCHED_CHANGE_GRID_API = 'SCHED_CHANGE_GRID_API';
export const SCHED_FLIGHT_CREATE = 'SCHED_FLIGHT_CREATE';
export const SCHED_FLIGHT_UPDATE = 'SCHED_FLIGHT_UPDATE';
export const SCHED_FLIGHTS_UPDATE = 'SCHED_FLIGHTS_UPDATE';
export const SCHED_FLIGHTS_WRITE_CHANGES = 'SCHED_FLIGHTS_WRITE_CHANGES';
export const SCHED_FLIGHTS_DELETE = 'SCHED_FLIGHTS_DELETE';

export const SCHED_FIND_FLIGHTS = 'SCHED_FIND_FLIGHTS';
export const SCHED_LINK_FLIGHTS = 'SCHED_LINK_FLIGHTS';
export const SCHED_UNLINK_FLIGHTS = 'SCHED_UNLINK_FLIGHTS';
export const SCHED_CANCEL_FLIGHTS = 'SCHED_CANCEL_FLIGHTS';
export const SCHED_RESTORE_FLIGHTS = 'SCHED_RESTORE_FLIGHTS';

export const GET_GS_PLANNING_CSV = 'GET_GS_PLANNING_CSV';
export const SCHED_FIT_COLUMNS_TO_VIEW = 'SCHED_FIT_COLUMNS_TO_VIEW';
export const SCHED_FIT_COLUMNS_TO_BEST = 'SCHED_FIT_COLUMNS_TO_BEST';

export const SCHED_RECALCULATE_FILTER = 'SCHED_RECALCULATE_FILTER';

export const SCHED_DO_EXTERNAL_CHANGE = 'SCHED_DO_EXTERNAL_CHANGE';
// actions pagination
export const SCHED_UPDATE_FILTER_PAGINATION = 'SCHED_UPDATE_FILTER_PAGINATION';
export const SCHED_UPDATE_FILTER_PAGINATION_PAGE_SIZE = 'SCHED_UPDATE_FILTER_PAGINATION_PAGE_SIZE';
export const SCHED_UPDATE_FILTER_PAGINATION_CURRENT_PAGE = 'SCHED_UPDATE_FILTER_PAGINATION_CURRENT_PAGE';
export const SCHED_UPDATE_FILTER_PAGINATION_SET_FIRST_PAGE = 'SCHED_UPDATE_FILTER_PAGINATION_SET_FIRST_PAGE';
// mutations
export const SCHED_FIND_FLIGHTS_SUCCESS = 'SCHED_FIND_FLIGHTS_SUCCESS';
export const SCHED_CHANGE_FLIGHT_SELECTION = 'SCHED_CHANGE_FLIGHT_SELECTION';
export const SCHED_ON_EXTERNAL_CHANGE = 'SCHED_ON_EXTERNAL_CHANGE';
export const SCHED_SET_CHANGE = 'SCHED_SET_CHANGE ';

export const SCHED_FILTER_SET = 'SCHED_FILTER_SET';
export const SCHED_FILTER_SET_UNIT_TIME = 'SCHED_FILTER_SET_UNIT_TIME';

export const GET_GS_PLANNING_CSV_SUCCESS = 'GET_GS_PLANNING_CSV_SUCCESS';
export const SCHED_UPDATE_FIT_COLUMNS_ON_RESIZE = 'SCHED_UPDATE_FIT_COLUMNS_ON_RESIZE';

export const SCHED_FILTER_RESET = 'SCHED_FILTER_RESET';
export const SCHED_FILTER_RESET_TIMEZONE = 'SCHED_FILTER_RESET_TIMEZONE';

export const UPDATE_SHOW_OLD_VALUES = 'UPDATE_SHOW_OLD_VALUES ';
export const UPDATE_FLASH_ON_CHANGES = 'UPDATE_FLASH_ON_CHANGES ';

// mutations pagination
export const SCHED_SET_FILTER_PAGINATION_PAGE_SIZE = 'SCHED_SET_FILTER_PAGINATION_PAGE_SIZE';
export const SCHED_SET_FILTER_PAGINATION_CURRENT_PAGE = 'SCHED_SET_FILTER_PAGINATION_CURRENT_PAGE';
export const SCHED_SET_PAGINATION_RESPONSE_PROPERTIES = 'SCHED_SET_PAGINATION_RESPONSE_PROPERTIES';
export const SCHED_SET_PAGINATION_RESPONSE_DEFAULT_PROPERTIES = 'SCHED_SET_PAGINATION_RESPONSE_DEFAULT_PROPERTIES';
export const SCHED_SET_FILTER_PAGINATION_FIRST_PAGE = 'SCHED_SET_FILTER_PAGINATION_FIRST_PAGE';
// modules
const storeModules = {
  schedulingWeighting,
};

// initial state
const storeState = {
  gridApi: null,
  filter: SchedulingFilter.createEmptyFilter,
  paginationResponseProperties: PaginationResponseProperties.createDefaultPaginationResponseProperties,
  flights: [],
  selectedFlights: [],
  flightsChanges: [],
  fitColumnsOnResize: true,
  showOldValues: true,
  flashOnChanges: false,
};

// getters
const storeGetters = {
  hasSelectedFlights: state => state.selectedFlights.length > 0,
  isSelectedFlights: state => id => !!state.selectedFlights.find(s => s.getId() === id),
  schedulingPaginationResponseProperties: state => state.paginationResponseProperties,
};

// actions
const storeActions = {
  [SCHED_FIND_FLIGHTS]: ({ commit, dispatch, state }) => {
    commit(REQUEST_START);
    commit(SCHED_CHANGE_FLIGHT_SELECTION, []);
    return HTTP.post('scheduling/flights/find-pageable', state.filter)
      .then((resp) => {
        commit(REQUEST_SUCCESS);
        commit(SCHED_FIND_FLIGHTS_SUCCESS, resp.data.content);
        commit(SCHED_CHANGE_FLIGHT_SELECTION, []);
        commit(SCHED_SET_PAGINATION_RESPONSE_PROPERTIES, resp.data);
        dispatch(ACTION_SET_NO_FLIGHTS_TO_SHOW_MESSAGE, resp.status);
      })
      .catch((err) => {
        dispatch(ACTION_SET_NO_FLIGHTS_TO_SHOW_MESSAGE, err.response.status);
        commit(SCHED_FIND_FLIGHTS_SUCCESS, []);
        commit(REQUEST_ERROR, err);
        commit(SCHED_SET_PAGINATION_RESPONSE_DEFAULT_PROPERTIES);
      });
  },
  [SCHED_LINK_FLIGHTS]: ({ commit, dispatch }, flights) => {
    commit(REQUEST_START);
    HTTP.post('scheduling/link', flights)
      .then(() => {
        commit(REQUEST_SUCCESS);
        dispatch(SCHED_FIND_FLIGHTS);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.flightsLinkSuccess'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(REQUEST_ERROR, err);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
      });
  },
  [SCHED_UNLINK_FLIGHTS]: ({ commit, dispatch }, flights) => {
    commit(REQUEST_START);
    HTTP.post('scheduling/unlink', flights)
      .then(() => {
        commit(REQUEST_SUCCESS);
        dispatch(SCHED_FIND_FLIGHTS);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.flightsUnlinkSuccess'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(REQUEST_ERROR, err);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
      });
  },
  [SCHED_CANCEL_FLIGHTS]: ({ commit, dispatch }, flightCancellation) => {
    commit(REQUEST_START);
    return HTTP.put('scheduling/flights/cancel', flightCancellation)
      .then(() => {
        commit(REQUEST_SUCCESS);
        dispatch(SCHED_FIND_FLIGHTS);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.flightsCancelSuccess'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
  [SCHED_RESTORE_FLIGHTS]: ({ commit, dispatch }, flightId) => {
    commit(REQUEST_START);
    return HTTP.put(`flights/${flightId}/timeAndCancellation/restore`)
      .then(() => {
        commit(REQUEST_SUCCESS);
        dispatch(SCHED_FIND_FLIGHTS);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.flightsRestoreSuccess'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
  [SCHED_GRID_FIT]: ({ state }) => {
    if (state.gridApi) {
      state.gridApi.sizeColumnsToFit();
    }
  },
  [SCHED_GRID_REFRESH_CELLS]: ({ state }) => {
    if (state.gridApi) {
      state.gridApi.refreshCells({ force: true });
    }
  },
  [SCHED_FLIGHT_CREATE]: ({ commit }, schedulingFlight) => {
    commit(REQUEST_START);
    return HTTP.post('scheduling/flights/single', schedulingFlight)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.flightCreate'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(REQUEST_ERROR, err);
      });
  },
  [SCHED_FLIGHT_UPDATE]: ({ commit }, { schedulingFlight, field }) => {
    commit(REQUEST_START);

    return HTTP.put('scheduling/flights/single', schedulingFlight)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(NOTIFICATION_SHOW, {
          message: field ? `${i18n.t('notifications.flightFieldUpdate')} ${i18n.t(field)}` : i18n.t('notifications.flightUpdate'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(REQUEST_ERROR, err);
      });
  },
  [SCHED_FLIGHTS_UPDATE]: ({ commit }, { connectedFlightsList, field }) => new Promise((resolve, reject) => {
    commit(REQUEST_START);

    HTTP.put('scheduling/flights', connectedFlightsList)
      .then((response) => {
        commit(REQUEST_SUCCESS);
        commit(NOTIFICATION_SHOW, {
          message: field ? `${i18n.t('notifications.flightFieldUpdate')} ${i18n.t(field)}` : i18n.t('notifications.flightUpdate'),
          color: 'success',
        });
        resolve(response);
      })
      .catch((err) => {
        commit(REQUEST_ERROR, err);
        reject(err);
      });
  }),
  [SCHED_FLIGHTS_WRITE_CHANGES]: ({ commit }, { flightChanges, field }) => new Promise((resolve, reject) => {
    commit(REQUEST_START);

    HTTP.put('scheduling/flights/write/changes', flightChanges)
      .then((response) => {
        commit(REQUEST_SUCCESS);
        commit(NOTIFICATION_SHOW, {
          message: field ? `${i18n.t('notifications.flightFieldUpdate')} ${i18n.t(field)}` : i18n.t('notifications.flightUpdate'),
          color: 'success',
        });
        resolve(response);
      })
      .catch((err) => {
        commit(REQUEST_ERROR, err);
        reject(err);
      });
  }),
  [SCHED_FLIGHTS_DELETE]: ({ commit, dispatch }, ids) => {
    commit(REQUEST_START);
    return HTTP.post('flights/batch-delete', ids)
      .then(() => {
        commit(REQUEST_SUCCESS);
        dispatch(SCHED_FIND_FLIGHTS);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.flightsDeleteSuccess'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
  [GET_GS_PLANNING_CSV]: ({ commit, state }) => {
    if (state.directoryRequested) return;
    commit(REQUEST_START);
    HTTP.post('scheduling/gs-planning', state.filter)
      .then((resp) => {
        commit(REQUEST_SUCCESS);
        commit(GET_GS_PLANNING_CSV_SUCCESS, resp.data);
      })
      .catch((err) => {
        commit(REQUEST_ERROR, err);
      });
  },
  [SCHED_FIT_COLUMNS_TO_VIEW]: ({ state }) => {
    if (state.gridApi) {
      state.gridApi.sizeColumnsToFit();
    }
  },
  [SCHED_FIT_COLUMNS_TO_BEST]: ({ state }) => {
    if (state.gridApi && state.gridApi.columnController && state.gridApi.columnController.columnApi) {
      state.gridApi.columnController.columnApi.autoSizeAllColumns();
    }
  },
  [SCHED_RECALCULATE_FILTER]: ({ commit, state }) => {
    if (state.filter.dateFrom === null && state.filter.dateTo === null) {
      commit(SCHED_FILTER_RESET);
    } else {
      commit(SCHED_FILTER_RESET_TIMEZONE, state.filter.unitOfTime);
    }
  },
  [SCHED_DO_EXTERNAL_CHANGE]: ({ commit }, change) => {
    commit(SCHED_ON_EXTERNAL_CHANGE, createFlightEventFromData(change));
  },
  [SCHED_UPDATE_FILTER_PAGINATION_SET_FIRST_PAGE]: ({ commit }) => {
    commit(SCHED_SET_FILTER_PAGINATION_FIRST_PAGE);
  },
  [SCHED_UPDATE_FILTER_PAGINATION_PAGE_SIZE]: ({ commit, dispatch }, newPageSize) => {
    commit(SCHED_SET_FILTER_PAGINATION_PAGE_SIZE, newPageSize);
    dispatch(SCHED_FIND_FLIGHTS);
  },
  [SCHED_UPDATE_FILTER_PAGINATION_CURRENT_PAGE]: ({ commit, dispatch }, newCurrentPage) => {
    commit(SCHED_SET_FILTER_PAGINATION_CURRENT_PAGE, newCurrentPage);
    dispatch(SCHED_FIND_FLIGHTS);
  },
};

// mutations
const storeMutations = {
  [SCHED_CHANGE_GRID_API]: (state, gridApi) => {
    state.gridApi = gridApi.api;
  },
  [SCHED_FIND_FLIGHTS_SUCCESS]: (state, flights) => {
    state.flights = ConnectedFlightsFactory.createConnectedFlightsFromData(flights); // FIXME sacar a otra factoria comun
  },
  [SCHED_CHANGE_FLIGHT_SELECTION]: (state, selection) => {
    state.selectedFlights = selection;
  },
  [SCHED_ON_EXTERNAL_CHANGE]: (state, change) => {
    console.log(`mutations external change ${change}`); // eslint-disable-line no-console
  },
  [SCHED_SET_CHANGE]: (state, change) => {
    change.ts = moment();
    if (change.diff.path.includes('inbound')) {
      change.flightNumber = change.connectedFlights.inbound.common.flightNo();
      change.bound = 'inbound';
    }
    if (change.diff.path.includes('outbound')) {
      change.flightNumber = change.connectedFlights.outbound.common.flightNo();
      change.bound = 'outbound';
    }
    state.flightsChanges.unshift(change);
    state.flightsChanges = state.flightsChanges.slice(0, 10);
  },
  [SCHED_FILTER_SET]: (state, filter) => {
    if (filter) {
      state.filter = filter;
    } else {
      state.filter = SchedulingFilter.createEmptyFilter;
    }
  },
  [SCHED_FILTER_SET_UNIT_TIME]: (state, unitOfTime) => {
    state.filter.unitOfTime = unitOfTime;
    if (unitOfTime) {
      state.filter.dateFrom = moment().startOf(unitOfTime);
      state.filter.dateTo = moment().endOf(unitOfTime);
    }
  },
  [GET_GS_PLANNING_CSV_SUCCESS]: (state, gsPlanningCsv) => {
    const blob = new Blob([gsPlanningCsv], { type: 'text/csv;charset=utf-8' });
    saveAs(blob, 'GS_PLANNING.csv');
  },
  [SCHED_UPDATE_FIT_COLUMNS_ON_RESIZE]: (state, fitColumnsOnResize) => {
    state.fitColumnsOnResize = fitColumnsOnResize;
  },
  [SCHED_FILTER_RESET]: (state) => {
    state.filter.dateFrom = moment().startOf('day');
    state.filter.dateTo = moment().endOf('day');
    state.filter.timezoneDate = moment.defaultZone.name;
    state.filter.pageRequestProperties.currentPage = 1;
  },
  [SCHED_FILTER_RESET_TIMEZONE]: (state, unitOfTime) => {
    state.filter.unitOfTime = unitOfTime != null ? unitOfTime : 'day';
    state.filter.dateFrom = moment().startOf(state.filter.unitOfTime)
      .year(state.filter.dateFrom.year())
      .month(state.filter.dateFrom.month())
      .date(state.filter.dateFrom.date());
    state.filter.dateTo = moment().endOf(state.filter.unitOfTime)
      .year(state.filter.dateTo.year())
      .month(state.filter.dateTo.month())
      .date(state.filter.dateTo.date());

    state.filter.timezoneDate = moment.defaultZone.name;
    state.filter.pageRequestProperties.currentPage = 1;
  },
  [UPDATE_SHOW_OLD_VALUES]: (state, showOldValues) => {
    state.showOldValues = showOldValues;
  },
  [UPDATE_FLASH_ON_CHANGES]: (state, flashOnChanges) => {
    state.flashOnChanges = flashOnChanges;
  },
  [SCHED_SET_FILTER_PAGINATION_PAGE_SIZE]: (state, newPageSize) => {
    state.filter.pageRequestProperties.pageSize = newPageSize;
    state.filter.pageRequestProperties.currentPage = 1;
  },
  [SCHED_SET_FILTER_PAGINATION_CURRENT_PAGE]: (state, newCurrentPage) => {
    state.filter.pageRequestProperties.currentPage = newCurrentPage;
  },
  [SCHED_SET_PAGINATION_RESPONSE_PROPERTIES]: (state, pagedResult) => {
    state.paginationResponseProperties = PaginationResponseProperties.createFromPagedResult(pagedResult);
  },
  [SCHED_SET_PAGINATION_RESPONSE_DEFAULT_PROPERTIES]: (state) => {
    state.paginationResponseProperties = PaginationResponseProperties.createDefaultPaginationResponseProperties;
  },
  [SCHED_SET_FILTER_PAGINATION_FIRST_PAGE]: (state) => {
    state.filter.pageRequestProperties.currentPage = 1;
  },
};

export default {
  modules: storeModules,
  state: storeState,
  getters: storeGetters,
  actions: storeActions,
  mutations: storeMutations,
};
