/* eslint no-param-reassign: "off" */
/* eslint no-confusing-arrow: "off" */
/* eslint no-nested-ternary: "off" */

import { HTTP } from '../../../http';
import { REQUEST_ERROR, REQUEST_START, REQUEST_SUCCESS } from '../../store';
import { NOTIFICATION_SHOW } from '../notifications';
import i18n from '../../../plugins/i18n';

// actions names
export const GET_AIRPORT_BELT = 'GET_AIRPORT_BELT';
export const AIRPORT_BELT_DELETE = 'AIRPORT_BELT_DELETE';
export const AIRPORT_BELT_CREATE = 'AIRPORT_BELT_CREATE';
export const AIRPORT_BELT_UPDATE = 'AIRPORT_BELT_UPDATE';
export const AIRPORT_BELT_DISABLE = 'AIRPORT_BELT_DISABLE';
export const AIRPORT_BELT_ENABLE = 'AIRPORT_BELT_ENABLE';


// mutations
export const CHANGE_AIRPORT_BELT_SELECTION = 'CHANGE_AIRPORT_BELT_SELECTION';
export const GET_AIRPORT_BELT_SUCCESS = 'GET_AIRPORT_BELT_SUCCESS';
export const GET_AIRPORT_BELT_REQUESTED = 'GET_AIRPORT_BELT_REQUESTED';
export const UPDATE_AIRPORT_BELT_SUCCESS = 'UPDATE_AIRPORT_BELT_SUCCESS';
export const CHANGED_TENANT_AIRPORT_BELT = 'CHANGED_TENANT_AIRPORT_BELT';

// initial state
const storeState = {
  airportBelt: [],
  airportBeltRequested: false,
  selectedAirportBelt: [],
};

// getters
const storeGetters = {
  getAirportBelts: state => state.airportBelt.sort((a, b) => (a.code > b.code) ? 1 : ((b.code > a.code) ? -1 : 0)),
  getActiveAirportBelts: state => state.airportBelt.filter(elem => !elem.deleted).sort((a, b) => (a.code > b.code) ? 1 : ((b.code > a.code) ? -1 : 0)),
  getAirportBeltSelected: state => state.selectedAirportBelt,
  hasSelectedAirportBelt: state => state.selectedAirportBelt.length > 0,
};

// actions
const storeActions = {
  [GET_AIRPORT_BELT]: ({ commit, state }) => {
    if (state.airportBeltRequested) {
      return;
    }
    commit(REQUEST_START);
    commit(GET_AIRPORT_BELT_REQUESTED);
    HTTP.get('airport-belts')
      .then((resp) => {
        commit(REQUEST_SUCCESS);
        commit(GET_AIRPORT_BELT_SUCCESS, resp.data);
      })
      .catch((err) => {
        commit(REQUEST_ERROR, err);
      });
  },

  [AIRPORT_BELT_CREATE]: ({ commit, dispatch }, belt) => {
    commit(REQUEST_START);
    return HTTP.post('airport-belts', belt)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(UPDATE_AIRPORT_BELT_SUCCESS);
        dispatch(GET_AIRPORT_BELT);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.airportBeltCreate'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },

  [AIRPORT_BELT_UPDATE]: ({ commit, dispatch }, belt) => {
    commit(REQUEST_START);
    return HTTP.put('airport-belts', belt)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(UPDATE_AIRPORT_BELT_SUCCESS);
        dispatch(GET_AIRPORT_BELT);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.airportBeltUpdate'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
  [AIRPORT_BELT_DELETE]: ({ commit, dispatch }, id) => {
    commit(REQUEST_START);
    return HTTP.delete(`airport-belts/${id}`)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(UPDATE_AIRPORT_BELT_SUCCESS);
        dispatch(GET_AIRPORT_BELT);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.airportBeltDelete'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
  [AIRPORT_BELT_DISABLE]: ({ commit, dispatch }, id) => {
    commit(REQUEST_START);
    return HTTP.patch(`/airport-belts/disable/${id}`)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(UPDATE_AIRPORT_BELT_SUCCESS);
        dispatch(GET_AIRPORT_BELT);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.airportBeltDisable'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
  [AIRPORT_BELT_ENABLE]: ({ commit, dispatch }, id) => {
    commit(REQUEST_START);
    return HTTP.patch(`/airport-belts/enable/${id}`)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(UPDATE_AIRPORT_BELT_SUCCESS);
        dispatch(GET_AIRPORT_BELT);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.airportBeltEnable'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
};

// mutations
const storeMutations = {
  [GET_AIRPORT_BELT_SUCCESS]: (state, airportBelt) => {
    state.airportBelt = airportBelt;
  },
  [GET_AIRPORT_BELT_REQUESTED]: (state) => {
    state.airportBeltRequested = true;
  },
  [UPDATE_AIRPORT_BELT_SUCCESS]: (state) => {
    state.airportBeltRequested = false;
  },
  [CHANGE_AIRPORT_BELT_SELECTION]: (state, selection) => {
    state.selectedAirportBelt = selection;
  },
  [CHANGED_TENANT_AIRPORT_BELT]: (state) => {
    state.airportBeltRequested = false;
  },
};

export default {
  state: storeState,
  getters: storeGetters,
  actions: storeActions,
  mutations: storeMutations,
};
