export const cellClassAgGridRules = { // TODO pasar a mixin
  'cell-select-multiple-editing': (params) => {
    function isValuable(p) {
      function isFunction(functionToCheck) {
        return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
      }

      if (p.value) {
        return true;
      }

      if (p.colDef.valueGetter && isFunction(p.colDef.valueGetter)) {
        return p.colDef.valueGetter(p);
      }

      if (p.colDef.valueFormatter && isFunction(p.colDef.valueFormatter)) {
        return p.colDef.valueFormatter(p);
      }

      return false;
    }

    return params.data.editing && params.colDef.isEditing && isValuable(params);
  },
  'cell-editable': params => params.colDef.editable,

  'cell-no-editable': params => !params.colDef.editable,
};
