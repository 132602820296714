<template>
<div>
  <operation-flight-list-table :items="items" :isFilterDisplayed="this.isFilterDisplayed"></operation-flight-list-table>
</div>
</template>
<script>
import OperationFlightListTable from './OperationFlightsListTable.vue';
import { OPERATION_FILTER_RESET, OPERATION_FIND_FLIGHTS } from '../../../store/modules/operation';

export default {
  name: 'operation-flights-list-container',
  components: {
    OperationFlightListTable,
  },
  props: {
    isFilterDisplayed: { type: Boolean, required: false, default: false },
  },
  mounted() {
    this.$store.commit(OPERATION_FILTER_RESET);
    this.$store.dispatch(OPERATION_FIND_FLIGHTS);
  },
  computed: {
    items() {
      return this.$store.state.operation.connectedFlightsOperation;
    },
  },
  methods: {
  },
};
</script>
<style lang="scss">


</style>
