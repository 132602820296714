<template>
  <v-card-text>
    <v-container fluid style="margin-bottom: 0; padding-bottom: 0">
      <v-layout style="margin-bottom: 0; padding-bottom: 0">
        <v-flex style="margin-right: 2ch; width: 15vw">
          <airline-select :label="this.$i18n.t('common.airline')" v-char-length.clearable="4" v-model="item.airlineId" :disabled="!hasPermissions(rolePermission)"></airline-select>
        </v-flex>

        <v-flex v-flex-none style="margin-right: 2ch; width: 30vw">
          <v-text-field :label="this.$i18n.t('configuration.link')" v-model="item.link" :readonly="!hasPermissions(rolePermission)"/>
        </v-flex>
      </v-layout>
    </v-container>
  </v-card-text>
</template>
<script>
import AirlineSelect from '../../airline/AirlineSelect.vue';
import RolePermissions from '../../mixins/RolePermissions.vue';

export default {
  name: 'AicDocumentsEditableCardContent',
  mixins: [RolePermissions],
  props: {
    item: { type: Object, required: true },
  },
  components: {
    AirlineSelect,
  },
  computed: {
    rolePermission() {
      return `${this.$route.meta.role}_WR`;
    },
  },
};
</script>
