<script>
import Vue from 'vue';
import { JSUtils } from '../../../shared/js/JSUtils';

export default Vue.extend({
  methods: {
    isParamsValueNotEmpty() {
      return this.params && this.params.value !== null && this.params.value !== undefined;
    },
    doIfParamsValue(action) {
      if (this.isParamsValueNotEmpty() && action && JSUtils.isFunction(action)) {
        action();
      }
    },
  },
});
</script>
