<template>
  <div class="justify-center">
    <v-tooltip left>
      <template v-slot:activator="{ on, attrs }">
        <v-icon
          v-if="userIsDispatcher && isPending(params.data)"
          color="secondary"
          small
          @click.stop="params.approveItem(params.data)"
          v-bind="attrs"
          v-on="on"
        >
          check
        </v-icon>
      </template>
      <span>{{$t("common.approve")}}</span>
    </v-tooltip>

    <v-tooltip left>
      <template v-slot:activator="{ on, attrs }">
        <v-icon
          v-if="userIsDispatcher && isPending(params.data)"
          color="secondary"
          small
          @click.stop="params.cancelItem(params.data)"
          v-bind="attrs"
          v-on="on"
        >
          dnd_forwardslash
        </v-icon>
      </template>
      <span>{{$t("common.cancel")}}</span>
    </v-tooltip>

    <v-tooltip left>
      <template v-slot:activator="{ on, attrs }">
        <v-icon
          v-if="userIsDispatcher"
          color="error"
          small
          @click.stop="params.deleteItem(params.data)"
          v-bind="attrs"
          v-on="on"
        >
          delete
        </v-icon>
      </template>
      <span>{{$t("common.delete")}}</span>
    </v-tooltip>
  </div>
</template>

<script>
import Vue from 'vue';
import { ROLE_TOWING_ADHOC_TASKS_DISPATCHER } from '../../../constants/roles';

export default Vue.extend({
  computed: {
    userIsDispatcher() {
      return this.$store.state.towingAdhocTask.towingAdhocTaskUserRole === ROLE_TOWING_ADHOC_TASKS_DISPATCHER;
    },
  },
  methods: {
    isPending(item) {
      return item.status === 'PENDING';
    },
  },
});
</script>
