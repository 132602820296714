<template>
  <v-flex>
    <p class="text-filter mx-0 mb-3 mt-0">{{ $t("common.states") }}</p>
    <v-layout row class="flight-states">
      <v-tooltip top>
        <template slot="activator">
          <filter-checkbox v-model="value.inbound" :label="this.$i18n.t('invoicing.in')"
            @input="$emit('input', value)" />
        </template>
        <span>{{ $t("invoicing.flightsIn") }}</span>
      </v-tooltip>
      <v-tooltip top>
        <template slot="activator">
          <filter-checkbox v-model="value.outbound" :label="this.$i18n.t('invoicing.out')"
            @input="$emit('input', value)" />
        </template>
        <span>{{ $t("invoicing.flightsOut") }}</span>
      </v-tooltip>
      <v-tooltip top>
        <template slot="activator">
          <filter-checkbox v-model="value.connected" :label="this.$i18n.t('invoicing.con')"
            @input="$emit('input', value)" />
        </template>
        <span>{{ $t("invoicing.flightsCon") }}</span>
      </v-tooltip>
      <v-tooltip top>
        <template slot="activator">
          <filter-checkbox v-model="value.delayed" :label="this.$i18n.t('invoicing.dly')"
            @input="$emit('input', value)" />
        </template>
        <span>{{ $t("invoicing.flightsDly") }}</span>
      </v-tooltip>
      <v-tooltip top>
        <template slot="activator">
          <filter-checkbox v-model="value.canceled" :label="this.$i18n.t('invoicing.can')"
            @input="$emit('input', value)" />
        </template>
        <span>{{ $t("invoicing.flightsCan") }}</span>
      </v-tooltip>
    </v-layout>
  </v-flex>
</template>

<script>
import FilterCheckbox from './FilterCheckbox.vue';

export default {
  name: 'FlightState',
  components: { FilterCheckbox },
  props: ['value'],
};
</script>

<style>
.flight-states .v-input {
  max-width: 3.4ch !important;
}
</style>
