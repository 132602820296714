<template>
  <div>
    <scheduling-edit-modal v-if="dialog" :dialog="dialog" :edited-scheduling-flight="editedSchedulingFlight" @close="close" :additionalDateRules="additionalDateRules" />

    <ramp-flights-list-filter @changeShowFilter="changeShowFilter" />
    <ramp-flights-list-container :isFilterDisplayed="filterDisplayed" />

    <ramp-flights-list-options @clickFlight="newFlight" @click-archive="archiveFlights" @click-unarchive="unarchiveFlights" @click-new-aircraft-ground="newAircraftGround"/>
    <aircraft-ground-new-modal v-if="dialogAircraftGround" :dialog="dialogAircraftGround" @aircraft-ground-new-reject="newAircraftGroundReject" @aircraft-ground-new-confirm="newAircraftGroundConfirm" @aircraft-ground-new-service="newAircraftGroundServices"/>
    <aircraft-ground-new-services-modal v-if="dialogAircraftGroundServices" :dialog="dialogAircraftGroundServices" :aircraft-ground="aircraftGround" @aircraft-ground-service-new-reject="newAircraftGroundServiceReject" @aircraft-ground-service-new-confirm="newAircraftGroundServiceConfirm"/>
  </div>
</template>
<script>
import RampFlightsListFilter from '../components/ramp/list/RampFlightsListFilter.vue';
import RampFlightsListContainer from '../components/ramp/list/RampFlightsListContainer.vue';
import RampFlightsListOptions from '../components/ramp/list/RampFlightsListOptions.vue';
import { RAMP_ARCHIVE, RAMP_UNARCHIVE, RAMP_FIND_FLIGHTS } from '../store/modules/ramp/ramp';
import ConnectedFlights from '../components/shared/model/connectedFlights';
import SchedulingEditModal from '../components/scheduling/edit/SchedulingEditModal.vue';
import AircraftGroundNewModal from '../components/aircraftGround/AircraftGroundNewModal.vue';
import AircraftGroundNewServicesModal from '../components/aircraftGround/AircraftGroundNewServicesModal.vue';

export default {
  components: {
    RampFlightsListFilter,
    RampFlightsListContainer,
    RampFlightsListOptions,
    SchedulingEditModal,
    AircraftGroundNewModal,
    AircraftGroundNewServicesModal,
  },
  computed: {
  },
  data() {
    return {
      dialog: false,
      filterDisplayed: false,
      dialogAircraftGround: false,
      dialogAircraftGroundServices: false,
      aircraftGround: null,
      editedSchedulingFlight: ConnectedFlights.createEmtpyConnectedFlights(),
      additionalDateRules: [
        (v) => {
          const value = this.$moment(v, 'DD/MM/YY').startOf('day');
          const today = this.$moment().startOf('day');
          const tomorrow = this.$moment().add(1, 'days').startOf('day');
          return (!value.isBefore(today) && !value.isAfter(tomorrow)) || this.$i18n.t('operations.unvalidDate');
        },
      ],
    };
  },
  methods: {
    archiveFlights() {
      this.$store.dispatch(RAMP_ARCHIVE);
    },
    unarchiveFlights() {
      this.$store.dispatch(RAMP_UNARCHIVE);
    },
    changeShowFilter(dispayled) {
      this.filterDisplayed = dispayled;
    },
    newFlight() {
      this.editedSchedulingFlight = ConnectedFlights.createEmtpyConnectedFlights();
      this.dialog = true;
    },
    newAircraftGround() {
      this.dialogAircraftGround = true;
    },
    newAircraftGroundReject() {
      this.dialogAircraftGround = false;
    },
    newAircraftGroundConfirm() {
      this.$store.dispatch(RAMP_FIND_FLIGHTS);
      this.dialogAircraftGround = false;
    },
    newAircraftGroundServices(aircraftGround) {
      this.dialogAircraftGround = false;
      this.aircraftGround = aircraftGround;
      this.dialogAircraftGroundServices = true;
    },
    newAircraftGroundServiceReject() {
      this.dialogAircraftGroundServices = false;
    },
    newAircraftGroundServiceConfirm() {
      this.$store.dispatch(RAMP_FIND_FLIGHTS);
      this.dialogAircraftGroundServices = false;
    },
    close(result) {
      this.dialog = false;
      if (result && result.doFind) {
        this.$store.dispatch(RAMP_FIND_FLIGHTS);
      }
    },
  },
};
</script>
