<template>
    <v-dialog v-model="dialogValue" max-width="1370px" persistent>
        <v-card :dark="this.$vuetify.dark" :color="this.$vuetify.dark ? 'bggrey_dark' : 'bggrey'">
          <v-toolbar dark color="primary">
            <v-icon>flight</v-icon>
            <v-toolbar-title v-if="isExternal">{{$t("rushBaggage.newRushBaggageExternal")}}</v-toolbar-title>
            <v-toolbar-title v-else>{{$t("rushBaggage.newRushBaggageInternal")}}</v-toolbar-title>
          </v-toolbar>

            <v-card-text v-if="isExternal">

              <v-form ref="form" v-model="valid">
                <rush-baggage-flight-external-form :rush-baggage-original="rushBaggage" @rush-baggage-update="updateRushBaggage"/>
              </v-form>

            </v-card-text>

            <v-card-text v-else>

              <v-form ref="form" v-model="valid">
                <rush-baggage-flight-internal-form :bound="bound" :rush-baggage-original="rushBaggage" @rush-baggage-update="updateRushBaggage"/>
              </v-form>

            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" flat @click.native="close">{{$t("common.close")}}</v-btn>
                <v-btn color="primary" depressed @click.native="save" :disabled="!valid">{{$t("common.save")}}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import { NOTIFICATION_SHOW } from '../../../../store/modules/notifications';
import { REQUEST_ERROR, REQUEST_START, REQUEST_SUCCESS } from '../../../../store/store';
import RushBaggage from '../model/rushBaggage';
import RushBaggageFlightInternalForm from './RushBaggageFlightInternalForm.vue';
import RushBaggageFlightExternalForm from './RushBaggageFlightExternalForm.vue';


export default {
  name: 'rush-baggage-new-modal',
  components: {
    RushBaggageFlightInternalForm,
    RushBaggageFlightExternalForm,

  },
  props: {
    dialog: { type: Boolean, required: true },
    isExternal: { type: Boolean, required: true },
  },
  data() {
    return {
      valid: true,
      rushBaggage: RushBaggage.createEmptyRushBaggage(),
      bound: 'OUTBOUND',
    };
  },
  computed: {
    dialogValue() {
      return this.dialog;
    },
  },
  methods: {
    save() {
      if (this.$refs.form.validate()) {
        this.$store.commit(REQUEST_START);
        this.$http.put('rush-baggage', this.rushBaggage)
          .then(() => {
            this.close({ doFind: true });
            this.$store.commit(NOTIFICATION_SHOW, {
              message: this.$i18n.t('notifications.rushBaggageSaved'),
              color: 'success',
            });
            this.$store.commit(REQUEST_SUCCESS);
          })
          .catch((err) => {
            this.close();
            this.$store.commit(NOTIFICATION_SHOW, {
              message: this.$i18n.t('notifications.errorNotification'),
              color: 'error',
            });
            this.$store.commit(REQUEST_ERROR, err);
          });
      }
    },
    close(result) {
      this.$emit('close', result);
    },
    updateRushBaggage(rushBaggage) {
      this.rushBaggage = rushBaggage;
    },
  },
};
</script>
<style scoped>
  .max-width-btn-toggle-header {
    max-width: 146px;
  }
  .separator-btn-header {
    background-color: red;
    width: 3px;
  }
  .v-btn-toggle .v-btn.v-btn--active.v-btn--disabled i {
    color: white !important;;
  }
</style>
