import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,[_c(VLayout,{attrs:{"row":"","wrap":""}},[_c(VFlex,{staticClass:"date-input",attrs:{"sm4":"","xs12":""}},[_c(VFlex,{staticClass:"date-input",attrs:{"xs8":""}},[_c('date-input',{ref:'refComp',attrs:{"label":this.$i18n.t('common.flightDate'),"locale":_vm.$i18n.locale,"disabled":_vm.disabledDate},on:{"input":_vm.changeDate},nativeOn:{"keydown":function($event){return _vm.keyEvent1.apply(null, arguments)}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1),(_vm.isFilter() && !_vm.disabledDate)?_c(VFlex,{staticClass:"date-input",attrs:{"xs2":""}},[_c(VBtn,{attrs:{"small":"","flat":""},on:{"click":_vm.cleanDate}},[_c(VIcon,[_vm._v("clear")])],1)],1):_vm._e()],1),_c(VFlex,{class:{'ml-0': _vm.$vuetify.breakpoint.smAndDown, 'ml-5': _vm.$vuetify.breakpoint.smAndUp},attrs:{"sm2":"","xs12":""}},[_c(VTooltip,{attrs:{"bottom":""}},[_c(VCheckbox,{attrs:{"slot":"activator","label":_vm.$t('commonForm.onlyNextFlights'),"color":"primary"},slot:"activator",model:{value:(_vm.checkbox),callback:function ($$v) {_vm.checkbox=$$v},expression:"checkbox"}}),_c('span',[_vm._v(_vm._s(_vm.$t("commonForm.onlyNextFlightsTooltip")))])],1)],1),_c(VFlex,{staticClass:"d-flex align-center",attrs:{"sm4":"","xs12":""}},[_c('flight-selector',{attrs:{"items":_vm.flights,"rules":_vm.rules},on:{"change":_vm.setFlight},model:{value:(_vm.flight),callback:function ($$v) {_vm.flight=$$v},expression:"flight"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }