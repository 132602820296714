import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VLayout,[[(_vm.contactDialogEdit)?_c('directory-modal-edit',{attrs:{"dialog":_vm.contactDialogEdit,"item":_vm.itemEdit,"element":"Gate contact","icon":_vm.icon},on:{"edit-confirm":_vm.contactEditConfirm,"edit-reject":_vm.contactEditReject}},[_c('contact-edit-modal-content',{attrs:{"item":_vm.itemEdit}})],1):_vm._e()],[(_vm.contactModal)?_c('contact-modal',{attrs:{"dialog":_vm.contactModal,"item":_vm.item,"icon":_vm.icon,"title":_vm.itemTitle,"msg":_vm.msg,"btnText":_vm.btnText,"deleteable":_vm.deleteable},on:{"modal-close":_vm.contactModalClose,"modal-edit":_vm.contactEdit,"modal-delete":_vm.deleteContact}}):_vm._e()],_c(VFlex,{attrs:{"xs12":""}},[_c(VCard,[_c(VCardTitle,[_c('span',{staticClass:"headline"},[_c(VIcon,{attrs:{"color":"primary","left":"","large":""}},[_vm._v(_vm._s(_vm.icon))]),_vm._v(" "+_vm._s(_vm.title)+" ")],1),_c(VSpacer),_c(VTextField,{attrs:{"append-icon":"search","label":"Search","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"hide-actions":"","expand":""},scopedSlots:_vm._u([{key:"items",fn:function(props){return [(props.item)?_c('tr',{on:{"click":function($event){return _vm.showItem(props.item)}}},[_c('td',[_vm._v(_vm._s(props.item.code || props.item.contact.name))]),_c('td',[_vm._v(_vm._s(props.item.contact.mail))]),_c('td',[_vm._v(_vm._s(props.item.contact.phone))]),_c('td',[_vm._v(_vm._s(props.item.contact.phoneMobile))])]):_vm._e()]}},{key:"no-results",fn:function(){return [_vm._v(" Your search for "),_c('b',[_vm._v("\""+_vm._s(_vm.search)+"\"")]),_vm._v(" found no results. ")]},proxy:true}])})],1)],1),(_vm.speedDial)?_c('contact-panel-speed-dial',{attrs:{"tooltipTextAdd":_vm.tooltipTextAdd,"tooltipOpenButton":_vm.tooltipOpenButton},on:{"click-new-item":_vm.addContact}}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }