<template>
  <v-expansion-panel v-model="panel" expand  popout class="exp-header">
    <v-expansion-panel-content expand-icon="mdi-menu-down" expandable>
      <template v-slot:header>
        <div >
          <h3 class="font-weight-light">{{$t("administration.operationSummary")}}</h3>
          <v-flex xs12 v-if="loading">
            <v-progress-linear :indeterminate="true"></v-progress-linear>
          </v-flex>
        </div>
      </template>
      <v-layout row wrap>
        <v-flex xs12>
          <v-alert
            :value="true"
            type="info"
            v-if="time"
          >
            {{operation}} {{$t("administration.operationLaunchedAt")}} {{time}}
          </v-alert>
        </v-flex>
        <v-flex xs12>
          <v-alert
            :value="true"
            type="success"
            v-if="isSuccess"
          >
            {{$t("administration.operationCompetedSuccessfully")}}
          </v-alert>
          <v-alert
            :value="true"
            type="error"
            v-if="isError"
          >
            {{$t("notifications.errorNotification")}}
          </v-alert>
        </v-flex>
        <v-flex xs12 mx-3 mb-3 class="traces-list">
          <div v-for="(trace, index) in traces" :key="index">
            {{trace}}
          </div>
        </v-flex>
      </v-layout>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>
<script>
export default {
  name: 'AdminResult',
  props: {
    isError: {},
    isSuccess: {},
    loading: {},
    time: {},
    traces: {},
    operation: {},
  },
  data() {
    return {
      panel: [{ 0: true }],
    };
  },
};
</script>
<style lang="scss" scoped>
  .traces-list {
    max-height: 30vh;
    overflow-y: auto;
  }
</style>
