<template>
  <iframe :src="manualUrl" width="100%" height="100%"></iframe>
</template>

<script>
import UsersManualUrl from '../mixins/UsersManualUrl.vue';

export default {
  name: 'UsersManual',
  mixins: [UsersManualUrl],
  props: {
    module: {
      type: String,
    },
    lang: {
      type: String,
    },
  },
};
</script>
