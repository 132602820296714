export default class FlightLoadsHolding {
  constructor(id, holdOrder, pcs, weight, weightUnit, specials, flightId, flightTripNumber) {
    this.id = id;
    this.holdOrder = holdOrder;
    this.pcs = pcs;
    this.weight = weight;
    this.weightUnit = weightUnit;
    this.specials = specials;
    this.flightId = flightId;
    this.flightTripNumber = flightTripNumber;
  }
}
