import { JSUtils } from '../../shared/js/JSUtils';

export default class FlightRemarksInformation {
  constructor(
    id,
    passageRemarks,
    operationRemarks,
    rampRemarks,
  ) {
    this.id = id;
    this.passageRemarks = passageRemarks;
    this.operationRemarks = operationRemarks;
    this.rampRemarks = rampRemarks;
  }

  static get createEmptyFlightRemarksInformation() {
    return new FlightRemarksInformation();
  }

  static createFlightRemarksInformationFromData(o) {
    if (!o) {
      return this.createEmptyFlightRemarksInformation;
    }

    return JSUtils.cast(o, FlightRemarksInformation);
  }
}
