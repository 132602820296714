/* eslint no-param-reassign: "off" */

import { HTTP } from '../../../http';
import { REQUEST_ERROR, REQUEST_START, REQUEST_SUCCESS } from '../../store';
import { NOTIFICATION_SHOW } from '../notifications';
import { AUTH_CHECK } from '../auth';
import i18n from '../../../plugins/i18n';

// actions names
export const GET_HANDLING_AGENTS = 'GET_HANDLING_AGENTS';
export const HANDLING_AGENT_DELETE = 'HANDLING_AGENT_DELETE';
export const HANDLING_AGENT_CREATE = 'HANDLING_AGENT_CREATE';
export const HANDLING_AGENT_UPDATE = 'HANDLING_AGENT_UPDATE';
export const HANDLING_AGENT_DISABLE = 'HANDLING_AGENT_DISABLE';
export const HANDLING_AGENT_ENABLE = 'HANDLING_AGENT_ENABLE';

// mutations
export const CHANGE_HANDLING_AGENTS_SELECTION = 'CHANGE_HANDLING_AGENTS_SELECTION';
export const GET_HANDLING_AGENTS_SUCCESS = 'GET_HANDLING_AGENTS_SUCCESS';
export const GET_HANDLING_AGENTS_REQUESTED = 'GET_HANDLING_AGENTS_REQUESTED';
export const UPDATE_HANDLING_AGENTS_SUCCESS = 'UPDATE_HANDLING_AGENTS_SUCCESS';

// initial state
const storeState = {
  handlingAgents: [],
  handlingAgentsRequested: false,
  selectedHandlingAgents: [],
};

// getters
const storeGetters = {
  getHandlingAgents: state => state.handlingAgents,
  getActiveHandlingAgents: state => state.handlingAgents.filter(elem => !elem.deleted),
  getHandlingAgentsSelected: state => state.selectedHandlingAgents,
  hasSelectedHandlingAgent: state => state.selectedHandlingAgents.length > 0,
  getHandlingAgentById: state => id => state.handlingAgents.find(a => a.id === id),
};

// actions
const storeActions = {
  [GET_HANDLING_AGENTS]: ({ commit, state }) => {
    if (state.handlingAgentsRequested) {
      return;
    }
    commit(REQUEST_START);
    commit(GET_HANDLING_AGENTS_REQUESTED);
    HTTP.get('handling-agent')
      .then((resp) => {
        commit(REQUEST_SUCCESS);
        commit(GET_HANDLING_AGENTS_SUCCESS, resp.data);
      })
      .catch((err) => {
        commit(REQUEST_ERROR, err);
      });
  },

  [HANDLING_AGENT_CREATE]: ({ commit, dispatch }, handlingAgent) => {
    commit(REQUEST_START);
    return HTTP.post('handling-agent', handlingAgent)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(UPDATE_HANDLING_AGENTS_SUCCESS);
        dispatch(GET_HANDLING_AGENTS);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.handlingAgentCreate'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },

  [HANDLING_AGENT_UPDATE]: ({ commit, dispatch }, handlingAgent) => {
    commit(REQUEST_START);
    return HTTP.put('handling-agent', handlingAgent)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(UPDATE_HANDLING_AGENTS_SUCCESS);
        dispatch(GET_HANDLING_AGENTS);
        dispatch(AUTH_CHECK);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.handlingAgentUpdate'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
  [HANDLING_AGENT_DELETE]: ({ commit, dispatch }, id) => {
    commit(REQUEST_START);
    return HTTP.delete(`handling-agent/${id}`)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(UPDATE_HANDLING_AGENTS_SUCCESS);
        dispatch(GET_HANDLING_AGENTS);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.handlingAgentDelete'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
  [HANDLING_AGENT_DISABLE]: ({ commit, dispatch }, id) => {
    commit(REQUEST_START);
    return HTTP.patch(`/handling-agent/disable/${id}`)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(UPDATE_HANDLING_AGENTS_SUCCESS);
        dispatch(GET_HANDLING_AGENTS);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.handlingAgentDisable'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
  [HANDLING_AGENT_ENABLE]: ({ commit, dispatch }, id) => {
    commit(REQUEST_START);
    return HTTP.patch(`/handling-agent/enable/${id}`)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(UPDATE_HANDLING_AGENTS_SUCCESS);
        dispatch(GET_HANDLING_AGENTS);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.handlingAgentEnable'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
};

// mutations
const storeMutations = {
  [GET_HANDLING_AGENTS_SUCCESS]: (state, handlingAgents) => {
    state.handlingAgents = handlingAgents;
  },
  [GET_HANDLING_AGENTS_REQUESTED]: (state) => {
    state.handlingAgentsRequested = true;
  },
  [UPDATE_HANDLING_AGENTS_SUCCESS]: (state) => {
    state.handlingAgentsRequested = false;
  },
  [CHANGE_HANDLING_AGENTS_SELECTION]: (state, selection) => {
    state.selectedHandlingAgents = selection;
  },
};

export default {
  state: storeState,
  getters: storeGetters,
  actions: storeActions,
  mutations: storeMutations,
};
