<script>
import Vue from 'vue';

export default Vue.extend({
  methods: {
    editable(value, role) {
      return value && this.$store.getters.hasRole(role);
    },
    hasPermissions(role) {
      return this.$store.getters.hasRole(role);
    },
    getRoles() {
      const roles = this.$route.meta.role;
      return Array.isArray(roles) ? roles.map(r => `${r}_WR`) : `${roles}_WR`;
    },
  },
});
</script>
