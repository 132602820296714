<template>
  <v-tooltip left>
    <v-speed-dial
      slot="activator"
      fixed bottom right
      color="primary">

      <template v-slot:activator>
        <v-btn color="primary" dark fab>
          <v-icon>edit</v-icon>
          <v-icon>close</v-icon>
        </v-btn>
      </template>

      <v-tooltip left>
        <v-btn fab dark small @click="clickAddNew" slot="activator">
          <v-icon>add</v-icon>
        </v-btn>
        <span>{{ tooltipTextAdd }}</span>
      </v-tooltip>
    </v-speed-dial>
    <span>{{ tooltipOpenButton }} </span>
  </v-tooltip>
</template>

<script>
export default {
  name: 'ContactPanelSpeedDial',
  props: {
    tooltipTextAdd: String,
    tooltipOpenButton: String,
  },
  methods: {
    clickAddNew() {
      this.$emit('click-new-item');
    },
    clickDelete() {
      this.$emit('click-delete-item');
    },
  },
};
</script>
