<template>
  <div :style="`width: 100%; height: calc(100vh - ${this.sizeFilter});`">
    <ag-grid-vue class="ag-theme-material connected-flights-table operational-table" :columnDefs="columnDefs"
      :defaultColDef="defaultColDef" :rowData="items" :rowSelection="'multiple'" :suppressRowClickSelection="true"
      :rowMultiSelectWithClick="false" @grid-ready="onGridReady" @first-data-rendered="firstDataRendered"
      @selection-changed="onSelectionChanged" :frameworkComponents="frameworkComponents" :gridOptions="gridOptions"
      :multiSortKey="multiSortKey" :overlayNoRowsTemplate="customNoRowsOverlay"
      :overlayLoadingTemplate="customLoadingOverlay"
      v-bind:style="`width: 100%; height: calc(100vh - ${this.sizeFilter});`">
    </ag-grid-vue>
  </div>
</template>

<script>
import { AgGridVue } from 'ag-grid-vue';
import AgGridValueAndSupCellRenderer from '../../ag-grid/cell-renderers/AgGridValueAndSupCellRenderer.vue';
import AgGridTimeCellRenderer from '../../ag-grid/cell-renderers/AgGridTimeCellRenderer.vue';
import AgGridDurationCellRenderer from '../../ag-grid/cell-renderers/AgGridDurationCellRenderer.vue';
import AgGridConnectedFlights from '../../ag-grid/mixins/AgGridConnectedFlights.vue';
import FlightsDifferences from '../../mixins/FlightsDifferences.vue';

import { FlightBound } from '../../shared/model/flightBound';
import { FIT_COLUMNS_TO_VIEW } from '../../../store/modules/operation';
import { cellClassAgGridRules } from '../../ag-grid/mixins/cellClassAgGridRules';
import { WEBSOCKET_CONNECTION } from '../../../store/modules/websocket';
import AgGridCustomService from '../../shared/services/agGridCustomService';
import AgGridValueWithAsteriskCellRenderer from '../../ag-grid/cell-renderers/AgGridValueWithAsteriskCellRenderer.vue';
import AgGridValueWithColorCellRenderer from '../../ag-grid/cell-renderers/AgGridValueWithColorCellRenderer.vue';
import agGridTimeFormatter from '../../ag-grid/formatters/agGridTimeFormatter';
import { FisModule } from '../../ag-grid/cell-editors/model/fisModule';
import agGridTextCustomFilterComparator from '../../ag-grid/filters/agGridTextCustomFilterComparator';
import agGridTextCustomFilterFormatter from '../../ag-grid/filters/agGridTextCustomFilterFormatter';
import {
  DELAYS_CHANGE_FLIGHT_SELECTION,
  DELAYS_ON_EXTERNAL_CHANGE,
  DELAYS_SET_CHANGE,
  DELAYS_SET_GRID_API,
} from '../../../store/modules/delays';
import AgGridIconTooltipOperationCellRenderer from '../../ag-grid/cell-renderers/AgGridIconTooltipOperationCellRenderer.vue';
import AgGridConnectedFlightsDialogDelayCellEditor from '../../ag-grid/cell-editors/connectedFlights/dialog/AgGridConnectedFlightsDialogDelayCellEditor.vue';
import RolePermissions from '../../mixins/RolePermissions.vue';
import AgGridNoRowsOverlay from '../../ag-grid/mixins/AgGridNoRowsOverlay.vue';

export default {
  name: 'delays-flight-list-table',
  mixins: [AgGridConnectedFlights, FlightsDifferences, RolePermissions, AgGridNoRowsOverlay],
  components: {
    AgGridVue,
  },
  props: {
    items: { type: Array, required: true },
    isFilterDisplayed: { type: Boolean, required: false, default: false },
  },
  mounted() {
    this.$store.dispatch(WEBSOCKET_CONNECTION, this.$store.state.tenant.currentTenant.name.toLowerCase());
  },
  computed: {
    sizeFilter() {
      return this.isFilterDisplayed ? '250px' : '180px';
    },
    fitColumnsOnResize() {
      return this.$store.state.delays.fitColumnsOnResize;
    },
  },
  data() {
    const that = this;
    const rolePermission = this.getRoles();
    return {
      dialog: false,
      gridOptions: {
        onFilterChanged: this.uncheckAllOnFilter,
        getRowNodeId: this.getRowNodeId,
        getRowClass: this.getRowClass,
        floatingFilter: true,
        groupHeaderHeight: 35,
        headerHeight: 35,
        floatingFiltersHeight: 20,
        rowHeight: 30,
        context: {
          module: FisModule.DELAYS,
        },
      },
      multiSortKey: 'ctrl',
      frameworkComponents: {
        AgGridConnectedFlightsDialogDelayCellEditor,
        AgGridDurationCellRenderer,
      },
      defaultColDef: {
        resizable: true,
        sortable: true,
        minWidth: 30,
        suppressMenu: true,
        filter: 'agTextColumnFilter',
        editable: false,
        filterParams: {
          textCustomComparator: agGridTextCustomFilterComparator,
          textFormatter: agGridTextCustomFilterFormatter,
        },
        colCustomConfig: {
          flightBound: FlightBound.ANY,
        },
        cellClassRules: cellClassAgGridRules,
      },
      columnDefs: [
        {
          headerTooltip: 'Check/uncheck all',
          hide: !this.hasPermissions(rolePermission),
          checkboxSelection: true,
          width: 21,
          minWidth: 20,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          suppressMenu: true,
          sortable: false,
          suppressMovable: true,
          suppressSizeToFit: true,
          filter: false,
          resizable: false,
          colCustomConfig: {
            exportCsvDisabled: true,
          },
          editable: this.editable(true, rolePermission),
        },
        {
          headerName: 'SortDate',
          field: 'sortingTime',
          hide: true,
          sort: 'asc',
        },
        {
          headerName: `${this.$i18n.t('common.date')}`,
          width: 60,
          type: 'date',
          valueGetter(params) {
            return params.data.getSafeValue(['common', 'scheduledDate']);
          },
          valueFormatter(params) {
            return that.$moment(params.value).format('DD/MM/YYYY');
          },
        },
        {
          colId: 'anybound.common.aircraftRegisterNumber',
          headerValueGetter() {
            return that.$i18n.t('operations.aircraftRegistration');
          },
          headerTooltip: 'Aircraft registration',
          width: 54,
          minWidth: 52,
          colCustomConfig: {
            flightBound: FlightBound.ANY,
            fieldsRelated: ['inbound.common.aircraftRegisterNumber', 'outbound.common.aircraftRegisterNumber',
              'inbound.common.aircraftAcars', 'outbound.common.aircraftAcars'],
          },
          cellClass: 'aircraft-cell',
          comparator: this.objectWithValueComparator,
          valueGetter(params) {
            return {
              value: params.data.getChildPropertyValue(FlightBound.ANY, ['common', 'aircraftRegisterNumber']),
              sup: params.data.getChildPropertyValue(FlightBound.ANY, ['common', 'aircraftAcars']) ? 'A' : null,
            };
          },
          cellRendererFramework: AgGridValueAndSupCellRenderer,
        },
        {
          colId: 'outbound.common.flightNumber',
          headerValueGetter() {
            return that.getTextResizable(that.$i18n.t('operations.flightNumberShort'), that.$i18n.t('operations.flightNumber'));
          },
          headerTooltip: 'Flight number',
          width: 47,
          minWidth: 45,
          cellClass: 'flight-number-cell',
          comparator: this.objectWithValueComparator,
          colCustomConfig: {
            flightBound: FlightBound.OUTBOUND,
            fieldsRelated: ['outbound.common.flightNumber'],
          },
          flight: FlightBound.OUTBOUND,
          valueGetter(params) {
            return {
              value: params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'flightNumber']),
              asterisk: params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'flagAirlineOperation']),
              tooltip: params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'airlineOperationMessage']),
            };
          },
          cellRendererFramework: AgGridValueWithAsteriskCellRenderer,
        },
        {
          colId: 'outbound.common.airport1Code',
          headerValueGetter() {
            return that.$i18n.t('operations.airportOfDestination');
          },
          headerTooltip: 'Airport of destination',
          width: 31,
          minWidth: 31,
          cellClass: 'airport-from-cell',
          comparator: this.objectWithValueComparator,
          colCustomConfig: {
            flightBound: FlightBound.OUTBOUND,
            fieldsRelated: ['outbound.common.airport1Code'],
          },
          flight: FlightBound.OUTBOUND,
          valueGetter(params) {
            return {
              value: params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'airport1Code']),
              asterisk: params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'hasSeveralAirports']),
              tooltip: params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'hasSeveralAirports']) ? params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'airportsIata']) : undefined,
            };
          },
          cellRendererFramework: AgGridValueWithAsteriskCellRenderer,
        },
        {
          colId: 'outbound.common.scheduledTime',
          headerValueGetter() {
            return that.$i18n.t('operations.scheduledTimeOfDeparture');
          },
          headerTooltip: 'Scheduled Time of Departure',
          width: 35,
          minWidth: 35,
          editable: this.editable(this.isOutbound, rolePermission),
          cellClass: 'airport-sta-cell',
          comparator: this.objectWithValueComparator,
          cellEditor: 'AgGridConnectedFlightsTimeCellEditor',
          colCustomConfig: {
            flightBound: FlightBound.OUTBOUND,
            fieldsRelated: ['outbound.common.scheduledTime'],
          },
          flight: FlightBound.OUTBOUND,
          valueGetter(params) {
            return that.agGridTimeGetter(params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'scheduledTime']), params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'scheduledDate']), null, null, that.scheduledTimeRequired);
          },
          valueFormatter: agGridTimeFormatter,
          cellRendererFramework: AgGridTimeCellRenderer,
        },
        {
          colId: 'outbound.times.groundTime',
          headerValueGetter() {
            return that.$i18n.t('operations.groundTime');
          },
          headerTooltip: 'Ground Time',
          width: 25,
          minWidth: 25,
          editable: false,
          comparator: this.objectWithValueComparator,
          colCustomConfig: {
            flightBound: FlightBound.OUTBOUND,
            fieldsRelated: ['inbound.times.blockTime', 'outbound.times.blockTime'],
          },
          valueGetter(params) {
            let value;
            if (!params.data.hasInbound() || !params.data.hasOutbound()) {
              value = '-';
            } else {
              const blockTimeIn = params.data.getChildPropertyValue(FlightBound.INBOUND, ['times', 'blockTime']);
              const blockTimeOut = params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['times', 'blockTime']);
              if (!blockTimeIn || !blockTimeOut) {
                value = '-';
              } else {
                value = that.$moment(blockTimeOut).diff(that.$moment(blockTimeIn), 'minutes');
              }
            }
            return {
              value,
              color: !!value && value < 0 ? 'red' : '',
            };
          },
          cellRendererFramework: AgGridValueWithColorCellRenderer,
        },
        {
          headerValueGetter() {
            return that.$i18n.t('operations.counter');
          },
          headerTooltip: 'Check In Counter',
          children: [
            {
              colId: 'outbound.delays.checkInEndTime',
              headerValueGetter() {
                return that.$i18n.t('operations.scheduledBoardingEndTime');
              },
              headerTooltip: 'Check In Counter END time',
              width: 35,
              minWidth: 35,
              cellClass: 'airport-sta-cell',
              comparator: this.objectWithValueComparator,
              colCustomConfig: {
                flightBound: FlightBound.OUTBOUND,
                fieldsRelated: ['outbound.delays.checkInEndTime'],
              },
              flight: FlightBound.OUTBOUND,
              valueGetter(params) {
                return that.agGridTimeGetter(params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['delays', 'checkInEndTime']), params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'scheduledDate']));
              },
              valueFormatter: agGridTimeFormatter,
              cellRendererFramework: AgGridTimeCellRenderer,
            },
          ],
        },
        {
          headerValueGetter() {
            return that.$i18n.t('operations.boarding');
          },
          headerTooltip: 'Boarding',
          children: [
            {
              colId: 'outbound.times.startedBoardingTime',
              headerValueGetter() {
                return that.$i18n.t('operations.scheduledBoardingStartTime');
              },
              headerTooltip: 'Real boarding STARTED time',
              width: 35,
              minWidth: 35,
              colCustomConfig: {
                flightBound: FlightBound.OUTBOUND,
                fieldsRelated: ['outbound.times.startedBoardingTime'],
              },
              cellClass: 'aircraft-type-cell',
              comparator: this.objectWithValueComparator,
              valueFormatter: agGridTimeFormatter,
              valueGetter(params) {
                return that.agGridTimeGetter(params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['times', 'startedBoardingTime']), params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'scheduledDate']));
              },
              cellRendererFramework: AgGridTimeCellRenderer,
            },
            {
              colId: 'outbound.times.completedBoardingTime',
              headerValueGetter() {
                return that.$i18n.t('operations.scheduledBoardingEndTime');
              },
              headerTooltip: 'Real boarding COMPLETED time',
              width: 35,
              minWidth: 35,
              colCustomConfig: {
                flightBound: FlightBound.OUTBOUND,
                fieldsRelated: ['outbound.times.completedBoardingTime'],
              },
              cellClass: 'aircraft-type-cell',
              comparator: this.objectWithValueComparator,
              valueFormatter: agGridTimeFormatter,
              valueGetter(params) {
                return that.agGridTimeGetter(params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['times', 'completedBoardingTime']), params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'scheduledDate']));
              },
              cellRendererFramework: AgGridTimeCellRenderer,
            },
            {
              colId: 'outbound.common.gat',
              headerValueGetter() {
                return that.$i18n.t('operations.gateExtended');
              },
              headerTooltip: 'Gate',
              width: 25,
              minWidth: 25,
              cellClass: 'aircraft-config-cell',
              comparator: this.objectWithValueComparator,
              colCustomConfig: {
                flightBound: FlightBound.OUTBOUND,
                fieldsRelated: ['outbound.common.gateCode'],
              },
              flight: FlightBound.OUTBOUND,
              valueGetter(params) {
                return {
                  value: params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'gateCode']),
                  sup: params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'gateRemote']) ? 'R' : null,
                };
              },
              cellRendererFramework: AgGridValueAndSupCellRenderer,
            },
          ],
        },
        {
          colId: 'outbound.passageSummary.totalPax',
          headerValueGetter() {
            return that.$i18n.t('operations.totalPax');
          },
          width: 50,
          colCustomConfig: {
            flightBound: FlightBound.OUTBOUND,
            fieldsRelated: ['outbound.passageSummary.totalPax'],
          },
          valueGetter(params) {
            const isRealNumber = params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['passageSummary', 'passengersNumber']) > 0 || params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['passageSummary', 'passengersChildNumber']) > 0;
            let text = params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['passageSummary', 'passengersNumber']) == null ? '' : params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['passageSummary', 'passengersNumber']);
            let tooltip = '';
            let styleContent = {};
            text += params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['passageSummary', 'passengersChildNumber']) == null ? '' : `+${params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['passageSummary', 'passengersChildNumber'])}`;
            if ((params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['passageSummary', 'passengersEstimated']) > 0) && !isRealNumber) {
              text = params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['passageSummary', 'passengersEstimated']);
              styleContent = { color: 'darkslategray', 'font-weight': 'bold', 'font-style': 'italic' };
            }
            // when handling type is P, the total of passengers must be (P)
            if (params.data.getChildPropertyValue(FlightBound.INBOUND, ['common', 'handlingTypeCode']) === 'P') {
              text = '(P)';
              tooltip = that.$i18n.t('operations.emptyFlight');
            }
            return {
              value: text,
              asterisk: params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['passageSummary', 'passengersSpecial']),
              styleObject: {
                asteriskColor: 'red',
                content: styleContent,
              },
              tooltip,
            };
          },
          cellRendererFramework: AgGridValueWithAsteriskCellRenderer,
        },
        {
          colId: 'outbound.operationExtended.loadHoldingWeight',
          headerValueGetter() {
            return that.$i18n.t('operations.flightLoad');
          },
          headerTooltip: 'Flights load',
          width: 43,
          minWidth: 43,
          cellClass: 'airport-pax-cell',
          comparator: this.objectWithValueComparator,
          colCustomConfig: {
            flightBound: FlightBound.OUTBOUND,
            fieldsRelated: ['outbound.operationExtended.load', 'outbound.operationExtended.loadSpecial'],
          },
          flight: FlightBound.OUTBOUND,
          valueGetter(params) {
            const isLoaded = (params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['operationExtended', 'loadHoldingWeight']) > 0);
            let text = '';
            let styleContent = {};
            if ((params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['operationExtended', 'loadEstimated']) > 0) && !isLoaded) {
              text = params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['operationExtended', 'loadEstimated']);
              styleContent = { color: 'darkslategray', 'font-weight': 'bold', 'font-style': 'italic' };
            } else {
              text = params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['operationExtended', 'loadHoldingWeight']);
            }

            let unit = params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['operationExtended', 'loadUnit']);
            unit = unit === 'POUNDS' ? 'Lbs' : 'Kg';
            text = text ? `${text} ${unit}` : '';

            return {
              value: text,
              asterisk: params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['operationExtended', 'loadSpecial']),
              styleObject: {
                asteriskColor: 'red',
                content: styleContent,
              },
            };
          },
          cellRendererFramework: AgGridValueWithAsteriskCellRenderer,
        },
        {
          colId: 'outbound.delays.flightDelayCode1',
          headerName: 'COD 1',
          width: 50,
          valueGetter(params) {
            return {
              value: params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['delays', 'flightDelayCode1']),
            };
          },
          cellRendererFramework: AgGridValueWithAsteriskCellRenderer,
        },
        {
          colId: 'outbound.delays.flightDelayAmount1',
          headerName: 'MIN COD 1',
          width: 50,
          valueGetter(params) {
            const amount = params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['delays', 'flightDelayAmount1']);

            return {
              value: amount || null,
            };
          },
          cellRendererFramework: AgGridDurationCellRenderer,
        },
        {
          colId: 'outbound.delays.flightDelayCode2',
          headerName: 'COD 2',
          width: 50,
          valueGetter(params) {
            return {
              value: params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['delays', 'flightDelayCode2']),
            };
          },
          cellRendererFramework: AgGridValueWithAsteriskCellRenderer,
        },
        {
          colId: 'outbound.delays.flightDelayAmount2',
          headerName: 'MIN COD 2',
          width: 50,
          valueGetter(params) {
            const amount = params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['delays', 'flightDelayAmount2']);

            return {
              value: amount || null,
            };
          },
          cellRendererFramework: AgGridDurationCellRenderer,
        },
        {
          colId: 'outbound.delays.flightDelayCode3',
          headerName: 'COD 3',
          width: 50,
          valueGetter(params) {
            return {
              value: params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['delays', 'flightDelayCode3']),
            };
          },
          cellRendererFramework: AgGridValueWithAsteriskCellRenderer,
        },
        {
          colId: 'outbound.delays.flightDelayAmount3',
          headerName: 'MIN COD 3',
          width: 50,
          valueGetter(params) {
            const amount = params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['delays', 'flightDelayAmount3']);

            return {
              value: amount || null,
            };
          },
          cellRendererFramework: AgGridDurationCellRenderer,
        },
        {
          headerValueGetter() {
            return that.$i18n.t('operations.totalDly');
          },
          width: 50,
          valueGetter(params) {
            const amount1 = params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['delays', 'flightDelayAmount1']) || 0;
            const amount2 = params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['delays', 'flightDelayAmount2']) || 0;
            const amount3 = params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['delays', 'flightDelayAmount3']) || 0;

            const totalAmount = (amount1 + amount2 + amount3) === 0 ? null : amount1 + amount2 + amount3;

            return {
              value: totalAmount || null,
            };
          },
          cellRendererFramework: AgGridDurationCellRenderer,
        },
        {
          headerValueGetter() {
            return that.$i18n.t('operations.remarksExtended');
          },
          width: 200,
          valueGetter(params) {
            const remarks = [];
            remarks.push(params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['delays', 'flightDelayRemark1']));
            remarks.push(params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['delays', 'flightDelayRemark2']));
            remarks.push(params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['delays', 'flightDelayRemark3']));

            return {
              value: remarks.filter(remark => remark != null).join(' / '),
            };
          },
          cellRendererFramework: AgGridValueWithAsteriskCellRenderer,
        },
        {
          colId: 'outbound.delays.count',
          headerValueGetter() {
            return that.getTextResizable(that.$i18n.t('operations.flightDelayShort'), that.$i18n.t('operations.flightDelay'));
          },
          headerTooltip: 'Other delays',
          width: 25,
          minWidth: 25,
          editable: true,
          cellEditor: 'AgGridConnectedFlightsDialogDelayCellEditor',
          cellClass: 'ag-grid-tooltip-cell',
          comparator: this.objectWithValueComparator,
          colCustomConfig: {
            flightBound: FlightBound.OUTBOUND,
          },
          flight: FlightBound.OUTBOUND,
          valueGetter(params) {
            const delayValue = params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['delays', 'count']);
            return {
              icon: delayValue != null && delayValue > 3 ? 'mdi-alert' : 'chevron_right',
              color: delayValue != null && delayValue > 3 ? 'warning' : 'bggreydark',
              tooltip: delayValue != null && delayValue > 3 ? 'There are more than three delays. Show delays' : 'Show delays',
            };
          },
          cellRendererFramework: AgGridIconTooltipOperationCellRenderer,
        },
      ],
    };
  },
  methods: {
    onGetChanges(changes) {
      const rowNode = this.gridApi.getRowNode(changes.payload.id);

      if (!rowNode) {
        return;
      }
      const oldConnectedFlight = rowNode.data.clone();
      const newConnectedFlight = changes.payload;
      this.assignInboundOrOutboundIfDontExist(newConnectedFlight, oldConnectedFlight);
      const differencies = this.getDifferenciesAndCreateOldValuesProperties(oldConnectedFlight, newConnectedFlight);

      this.setOldValuesDifferencesFromOldConnectedFlight(rowNode.data, newConnectedFlight);

      if (differencies && differencies.length > 0) {
        rowNode.setData(newConnectedFlight);
        if (this.$store.state.delays.flashOnChanges) {
          this.renderNeededDiffs(differencies, rowNode); // TODO only shows flash
        }
        differencies.forEach(dif => this.$store.commit(DELAYS_SET_CHANGE, { diff: dif, connectedFlights: newConnectedFlight, user: changes.origin }));
      }
    },
    onGridReady(params) {
      this.setAgGridApi(params);
      this.showLoading();
      this.sizeColumnsToFit();

      this.$store.commit(DELAYS_SET_GRID_API, params); // TODO eliminar api del store

      this.refreshCellsOnMutation();
      this.refreshCellsOnChangeLanguage();
      const that = this;
      this.subscribeMutation(DELAYS_ON_EXTERNAL_CHANGE, (mutationPayload) => {
        that.onGetChanges(mutationPayload);
      });
    },
    firstDataRendered() {
      this.$nextTick(() => {
        this.resized();
      });
    },
    resized() {
      if (this.fitColumnsOnResize) {
        this.sizeColumnsToFit();
      }
    },
    sizeColumnsToFit() {
      if (this.gridApi) {
        this.$store.dispatch(FIT_COLUMNS_TO_VIEW);
      }
    },
    onSelectionChanged(event) {
      const selectedNodes = event.api.getSelectedNodes();
      const selectedData = selectedNodes.map(node => node.data);
      this.$store.commit(DELAYS_CHANGE_FLIGHT_SELECTION, selectedData);
    },
    renderNeededDiffs(differences, rowNode) {
      const { gridApi } = this.$store.state.delays;

      const agGridCustomService = new AgGridCustomService(this.columnDefs); // TODO one time
      const colIds = agGridCustomService.getColIdByMatchAnyfieldsRelated(this.joinAllPropertyPath(differences));

      if (colIds && colIds.length > 0) {
        colIds.forEach((colId) => {
          if (gridApi.getColumnDef(colId)) {
            gridApi.refreshCells({
              rowNodes: [rowNode],
              columns: [colId],
              force: true,
            });

            gridApi.flashCells({
              rowNodes: [rowNode],
              columns: [colId],
              force: true,
            });
          }
        });
      }
    },
  },
};

</script>
