export default class Integration {
  constructor(name, enabled, available, jobAvailable) {
    this.name = name;
    this.enabled = enabled;
    this.available = available;
    this.jobAvailable = jobAvailable;
  }

  static get createEmptyIntegration() {
    return new Integration(null, false, false, false);
  }
}
