<template>
  <div>
    <contingencies-delete-modal v-if="dialogDelete" :dialog="dialogDelete" :items="selectedItems" @delete-reject="deleteReject" @delete-confirm="deleteConfirm" />
    <contingencies-close-modal v-if="dialogClose" :dialog="dialogClose" :items="selectedItems" :isClosing="closingItems" @close-reject="closeReject" @close-confirm="closeConfirm" />
    <contingencies-invoice-modal v-if="dialogInvoice" :dialog="dialogInvoice" :items="selectedItems" :isInvoicing="invoicingItems" @invoice-reject="invoiceReject" @invoice-confirm="invoiceConfirm" />
    <contingencies-list-filter @changeShowFilter="changeShowFilter"/>
    <contingencies-list-container :isFilterDisplayed="filterDisplayed" @edit-item="editItem" @delete-item="deleteItem" @open-item="openItem" @close-item="closeItem" @invoice-item="invoiceItem" @no-invoice-item="noInvoiceItem"/>
    <contingency-new-modal v-if="dialog" :dialog="dialog" :isExternal="externalContingency" @close="close"/>
    <contingencies-list-options @clickNewContingency="newContingency" @clickNewExternalContingency="newExternalContingency" @clickDeleteContingencies="deleteItems" @clickOpenContingencies="openItems" @clickCloseContingencies="closeItems" @clickInvoiceContingencies="invoiceItems" @clickNotInvoiceContingencies="notInvoiceItems"/>
  </div>
</template>
<script>
import ContingenciesDeleteModal from '../components/contingencyManagement/delete/ContingenciesDeleteModal.vue';
import Contingency from '../components/contingencyManagement/model/contingency';
import {
  FLIGHT_CONTINGENCIES_FIND,
  FLIGHT_CONTINGENCY_CLOSE,
  FLIGHT_CONTINGENCY_DELETE, FLIGHT_CONTINGENCY_INVOICE, FLIGHT_CONTINGENCY_NOT_INVOICE, FLIGHT_CONTINGENCY_OPEN,
} from '../store/modules/contingency/contingency';
import ContingenciesListFilter from '../components/contingencyManagement/list/ContingenciesListFilter.vue';
import ContingenciesListContainer from '../components/contingencyManagement/list/ContingenciesListContainer.vue';
import ContingenciesListOptions from '../components/contingencyManagement/list/ContingenciesListOptions.vue';
import ContingencyNewModal from '../components/contingencyManagement/reports/ContingencyNewModal.vue';
import ContingenciesCloseModal from '../components/contingencyManagement/reports/ContingenciesCloseModal.vue';
import ContingenciesInvoiceModal from '../components/contingencyManagement/reports/ContingenciesInvoiceModal.vue';

export default {
  components: {
    ContingencyNewModal,
    ContingenciesListContainer,
    ContingenciesListFilter,
    ContingenciesListOptions,
    ContingenciesDeleteModal,
    ContingenciesCloseModal,
    ContingenciesInvoiceModal,
  },
  data() {
    return {
      filterDisplayed: true,
      dialog: false,
      dialogDelete: false,
      dialogClose: false,
      dialogInvoice: false,
      closingItems: false,
      invoicingItems: false,
      invoicingValue: undefined,
      selectedItems: [],
      editedContingency: Contingency.createEmptyContingency(),
      externalContingency: false,
    };
  },
  computed: {
    selectedRowsItems() {
      return this.$store.state.contingency.selectedContingencies;
    },
  },
  methods: {
    changeShowFilter(dispayled) {
      this.filterDisplayed = dispayled;
    },
    newContingency() {
      this.dialog = true;
      this.externalContingency = false;
      this.editedContingency = Contingency.createEmptyContingency();
    },
    newExternalContingency() {
      this.dialog = true;
      this.externalContingency = true;
      this.editedContingency = Contingency.createEmptyContingency();
    },
    close(result) {
      this.dialog = false;
      if (result && result.doFind) {
        this.$store.dispatch(FLIGHT_CONTINGENCIES_FIND);
      }
    },
    editItem(contingency) {
      this.$router.push({ name: 'contingencyReport', params: { contingencyId: contingency.id } });
    },
    deleteItem(item) {
      this.selectedItems = [];
      this.selectedItems.push(item);
      this.dialogDelete = true;
    },
    deleteItems() {
      this.selectedItems = [];
      this.selectedItems = this.selectedRowsItems;
      this.dialogDelete = true;
    },
    deleteConfirm(ids) {
      this.$store.dispatch(FLIGHT_CONTINGENCY_DELETE, { ids })
        .finally(() => {
          this.selectedItems = [];
          this.dialogDelete = false;
        });
    },
    deleteReject() {
      this.selectedItems = [];
      this.dialogDelete = false;
    },
    closeItem(item) {
      this.selectedItems = [];
      this.selectedItems.push(item);
      this.dialogClose = true;
      this.closingItems = true;
    },
    closeItems() {
      this.selectedItems = [];
      this.selectedItems = this.selectedRowsItems;
      this.dialogClose = true;
      this.closingItems = true;
    },
    closeConfirm(ids) {
      if (this.closingItems) {
        this.$store.dispatch(FLIGHT_CONTINGENCY_CLOSE, ids)
          .finally(() => {
            this.selectedItems = [];
            this.dialogClose = false;
          });
      } else {
        this.$store.dispatch(FLIGHT_CONTINGENCY_OPEN, ids)
          .finally(() => {
            this.selectedItems = [];
            this.dialogClose = false;
          });
      }
    },
    closeReject() {
      this.selectedItems = [];
      this.dialogClose = false;
    },
    openItem(item) {
      this.selectedItems = [];
      this.selectedItems.push(item);
      this.dialogClose = true;
      this.closingItems = false;
    },
    openItems() {
      this.selectedItems = [];
      this.selectedItems = this.selectedRowsItems;
      this.dialogClose = true;
      this.closingItems = false;
    },
    invoiceItem(item) {
      this.selectedItems = [];
      this.selectedItems.push(item);
      this.dialogInvoice = true;
      this.invoicingItems = true;
    },
    noInvoiceItem(item) {
      this.selectedItems = [];
      this.selectedItems.push(item);
      this.dialogInvoice = true;
      this.invoicingItems = false;
    },
    invoiceItems() {
      this.selectedItems = [];
      this.selectedItems = this.selectedRowsItems;
      this.dialogInvoice = true;
      this.invoicingValue = -1;
      this.invoicingItems = true;
    },
    notInvoiceItems() {
      this.selectedItems = [];
      this.selectedItems = this.selectedRowsItems;
      this.dialogInvoice = true;
      this.invoicingValue = 0;
      this.invoicingItems = false;
    },
    invoiceConfirm(ids) {
      if (this.invoicingItems) {
        this.$store.dispatch(FLIGHT_CONTINGENCY_INVOICE, ids)
          .finally(() => {
            this.invoicingValue = undefined;
            this.selectedItems = [];
            this.dialogInvoice = false;
          });
      } else {
        this.$store.dispatch(FLIGHT_CONTINGENCY_NOT_INVOICE, ids)
          .finally(() => {
            this.invoicingValue = undefined;
            this.selectedItems = [];
            this.dialogInvoice = false;
          });
      }
    },
    invoiceReject() {
      this.selectedItems = [];
      this.dialogInvoice = false;
    },
  },
};
</script>
