<template>
  <v-container grid-list-md fluid class="px-0">
    <v-layout row wrap align-start justify-center>
      <v-flex
        v-for="(value, key) in filterTenants"
        :key="key"
        class="pa-2"
        xs12
        sm6
        md5
        lg2
      >
        <v-expansion-panel class="elevation-3 custom-expansion" :value="expand" :dark="$vuetify.dark">
          <v-expansion-panel-content>
            <template v-slot:actions>
              <v-icon color="primary">keyboard_arrow_down</v-icon>
            </template>
            <template v-slot:header >
              <p class="title text-xs-center ma-0 font-weight-regular" >{{ key }}</p>
            </template>
              <v-card>
                <v-list two-line>
                  <v-list-tile
                    v-for="item in value"
                    :key="item.title"
                    @click="selectItem(item)"
                  >
                    <v-list-tile-content>
                      <v-list-tile-title>{{ item.description }}</v-list-tile-title>
                      <v-list-tile-sub-title>{{ item.name }}</v-list-tile-sub-title>
                    </v-list-tile-content>
                  </v-list-tile>
                </v-list>
              </v-card>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { TENANT_CURRENT_SET, TENANT_LIST } from '../../store/modules/tenant';

export default {
  name: 'TenantSelectorList',
  data: () => ({
    select: [],
  }),
  computed: {
    items() {
      return this.$store.getters.getTenant;
    },
    filterTenants() {
      const filterTenants = {};
      this.items.map((item) => {
        if (!filterTenants[item.country]) {
          filterTenants[item.country] = [item];
        } else {
          filterTenants[item.country].push(item);
        }
        return true;
      });
      return filterTenants;
    },
    expand() {
      return (this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm) ? 1 : 0;
    },
  },
  methods: {
    getTenants() {
      return this.$store.dispatch(TENANT_LIST);
    },
    selectItem(item) {
      this.$store.dispatch(TENANT_CURRENT_SET, item).then(() => {
        this.$router.push('/home');
      });
    },
  },
  created() {
    this.getTenants();
  },
};
</script>
<style scoped>
.v-expansion-panel__container {
  border-top: 3px solid #fe0900 !important;
}
</style>
