<template>
  <div>
    <v-autocomplete
      placeholder="..."
      :value="value"
      :label="label"
      :items="items"
      :filter="customFilter"
      :clearable="!readonly"
      item-text="name"
      item-value="id"
      :rules="rules"
      :disabled="disabled"
      @input="$emit('input', $event)"
      :ref="refComp"
      :readonly="readonly"
    >
      <template
        slot="item"
        slot-scope="{ item, tile }"
      >
        <v-list-tile-content>
          <v-list-tile-title v-text="item.name"></v-list-tile-title>
          <v-list-tile-sub-title v-text="item.description"></v-list-tile-sub-title>
        </v-list-tile-content>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>

import { GET_HANDLING_AGENTS } from '../../store/modules/handlingAgent/handlingAgent';

export default {
  name: 'handling-agent-select',
  props: {
    value: { required: true },
    label: { type: String },
    charLenght: { type: Number },
    rules: { type: Array },
    disabled: { type: Boolean },
    readonly: { type: Boolean },
    refComp: {
      type: String,
      required: false,
      default: 'refComp',
    },
  },
  computed: {
    items: {
      get() {
        return this.$store.getters.getActiveHandlingAgents;
      },
    },
  },
  methods: {
    getHandlingAgents() {
      this.$store.dispatch(GET_HANDLING_AGENTS);
    },
    customFilter(item, queryText) {
      const textOne = item.name ? item.name.toLowerCase() : '';
      const textTwo = item.description ? item.description.toLowerCase() : '';
      const searchText = queryText.toLowerCase();

      return textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1;
    },
  },
  mounted() {
    this.getHandlingAgents();
  },
};
</script>
