/* eslint no-param-reassign: "off" */
import { HTTP } from '../../../http';

// action
export const ADMIN_RAMP_SERVICE_IMPORT = 'ADMIN_RAMP_SERVICE_IMPORT';

// mutations
export const ADMIN_RAMP_SERVICE_IMPORT_REQUEST = 'ADMIN_RAMP_SERVICE_IMPORT_REQUEST';
export const ADMIN_RAMP_SERVICE_IMPORT_RESPONSE = 'ADMIN_RAMP_SERVICE_IMPORT_RESPONSE';
export const ADMIN_RAMP_SERVICE_IMPORT_ERROR = 'ADMIN_RAMP_SERVICE_IMPORT_ERROR';

// initial state
const storeState = {
  rampServiceImportLoading: null,
  rampServiceImportSuccess: null,
  rampServiceImportResult: [],
  rampServiceImportLastRequestTime: null,
};

// getters
const storeGetters = {
  isRampServiceImportSuccess: state => state.bulkSuccess != null && !!state.bulkSuccess,
  isRampServiceImportError: state => state.bulkSuccess != null && !state.bulkSuccess,
};

// actions
const storeActions = {
  [ADMIN_RAMP_SERVICE_IMPORT]: ({ commit }, importSummaryData) => {
    commit(ADMIN_RAMP_SERVICE_IMPORT_REQUEST);
    return HTTP.post('ramp/services/import/confirm', importSummaryData)
      .then((resp) => {
        commit(ADMIN_RAMP_SERVICE_IMPORT_RESPONSE, resp.data);
      })
      .catch((err) => {
        commit(ADMIN_RAMP_SERVICE_IMPORT_ERROR, err.response.data);
      });
  },
};

// mutations
const storeMutations = {
  [ADMIN_RAMP_SERVICE_IMPORT_REQUEST]: (state) => {
    state.rampServiceImportLoading = true;
    state.rampServiceImportSuccess = null;
    state.rampServiceImportResult = [];
    state.rampServiceImportLastRequestTime = new Date();
  },
  [ADMIN_RAMP_SERVICE_IMPORT_RESPONSE]: (state, result) => {
    state.rampServiceImportLoading = false;
    state.rampServiceImportSuccess = result.success;
    state.rampServiceImportResult = result.traces;
  },
  [ADMIN_RAMP_SERVICE_IMPORT_ERROR]: (state, result) => {
    state.rampServiceImportLoading = false;
    state.rampServiceImportSuccess = false;
    state.rampServiceImportResult = [result.traces, result.message, result.detail];
  },
};

export default {
  state: storeState,
  getters: storeGetters,
  actions: storeActions,
  mutations: storeMutations,
};
