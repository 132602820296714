/* eslint no-param-reassign: "off" */

import { saveAs } from 'file-saver';
import { HTTP } from '../../../http';
import { REQUEST_ERROR, REQUEST_START, REQUEST_SUCCESS } from '../../store';
import FinancialSystemsFileFactory from '../../../components/shared/model/financialSystemsFileFactory';
import { NOTIFICATION_SHOW } from '../notifications';
import i18n from '../../../plugins/i18n';

// action
export const GHS_GET_INPUT_FILES = 'GHS_GET_INPUT_FILES';
export const GHS_GET_OUTPUT_FILES = 'GHS_GET_OUTPUT_FILES';
export const GHS_DOWNLOAD_FILE = 'GHS_DOWNLOAD_FILE';
export const GHS_PROCESS_FILE = 'GHS_PROCESS_FILE';

// mutations
export const GHS_GET_INPUT_FILES_START = 'GHS_GET_INPUT_FILES_START';
export const GHS_GET_INPUT_FILES_FINISH = 'GHS_GET_INPUT_FILES_FINISH';
export const GHS_GET_INPUT_FILES_SUCCESS = 'GHS_GET_INPUT_FILES_SUCCESS';
export const GHS_GET_OUTPUT_FILES_START = 'GHS_GET_OUTPUT_FILES_START';
export const GHS_GET_OUTPUT_FILES_FINISH = 'GHS_GET_OUTPUT_FILES_FINISH';
export const GHS_GET_OUTPUT_FILES_SUCCESS = 'GHS_GET_OUTPUT_FILES_SUCCESS';
export const GHS_DOWNLOAD_FILE_SUCCESS = 'GHS_DOWNLOAD_FILE_SUCCESS';

// initial state
const storeState = {
  inputFiles: [],
  inputFilesLoading: false,
  outputFiles: [],
  outputFilesLoading: false,
};

// getters
const storeGetters = {
};

// actions
const storeActions = {
  [GHS_GET_INPUT_FILES]: ({ commit }) => {
    commit(REQUEST_START);
    commit(GHS_GET_INPUT_FILES_START);
    return HTTP.get('financial-systems/files/input')
      .then((resp) => {
        commit(REQUEST_SUCCESS);
        commit(GHS_GET_INPUT_FILES_SUCCESS, resp.data);
      })
      .catch((err) => {
        commit(GHS_GET_INPUT_FILES_SUCCESS, []);
        commit(REQUEST_ERROR, err);
      })
      .finally(() => {
        commit(GHS_GET_INPUT_FILES_FINISH);
      });
  },
  [GHS_GET_OUTPUT_FILES]: ({ commit }) => {
    commit(REQUEST_START);
    commit(GHS_GET_OUTPUT_FILES_START);
    return HTTP.get('financial-systems/files/output')
      .then((resp) => {
        commit(REQUEST_SUCCESS);
        commit(GHS_GET_OUTPUT_FILES_SUCCESS, resp.data);
      })
      .catch((err) => {
        commit(GHS_GET_OUTPUT_FILES_SUCCESS, []);
        commit(REQUEST_ERROR, err);
      })
      .finally(() => {
        commit(GHS_GET_OUTPUT_FILES_FINISH);
      });
  },
  [GHS_DOWNLOAD_FILE]: ({ commit }, financialSystemsFile) => {
    commit(REQUEST_START);
    const fileData = {
      name: financialSystemsFile.name,
      path: financialSystemsFile.path,
    };
    return HTTP.post('financial-systems/files/download', fileData)
      .then((resp) => {
        commit(REQUEST_SUCCESS);
        fileData.content = resp.data;
        commit(GHS_DOWNLOAD_FILE_SUCCESS, fileData);
      })
      .catch((err) => {
        commit(REQUEST_ERROR, err);
      });
  },
  [GHS_PROCESS_FILE]: ({ commit }, financialSystemsFile) => {
    commit(REQUEST_START);
    const fileData = {
      name: financialSystemsFile.name,
      path: financialSystemsFile.path,
    };
    return HTTP.post('financial-systems/files/process', fileData)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('invoicing.ghs-integration.process-success'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(REQUEST_ERROR, err);
      });
  },
};

// mutations
const storeMutations = {
  [GHS_GET_INPUT_FILES_START]: (state) => {
    state.inputFilesLoading = true;
  },
  [GHS_GET_INPUT_FILES_FINISH]: (state) => {
    state.inputFilesLoading = false;
  },
  [GHS_GET_INPUT_FILES_SUCCESS]: (state, inputFiles) => {
    state.inputFiles = FinancialSystemsFileFactory.createFinancialSystemsFileFromData(inputFiles);
  },
  [GHS_GET_OUTPUT_FILES_START]: (state) => {
    state.outputFilesLoading = true;
  },
  [GHS_GET_OUTPUT_FILES_FINISH]: (state) => {
    state.outputFilesLoading = false;
  },
  [GHS_GET_OUTPUT_FILES_SUCCESS]: (state, outputFiles) => {
    state.outputFiles = FinancialSystemsFileFactory.createFinancialSystemsFileFromData(outputFiles);
  },
  [GHS_DOWNLOAD_FILE_SUCCESS]: (state, financialSystemsFile) => {
    const blob = new Blob([financialSystemsFile.content], { type: 'text/plain;charset=utf-8' });
    saveAs(blob, financialSystemsFile.name);
  },
};

export default {
  state: storeState,
  getters: storeGetters,
  actions: storeActions,
  mutations: storeMutations,
};
