<template>
  <div>
    <airport-ramp-service-select v-char-length.clearable="4" v-on:keydown.native="keyEvent" :label="this.$i18n.t('common.rampServices')" v-model="itemId" @input-name="setItemName($event)"></airport-ramp-service-select>
  </div>
</template>

<script>
import Vue from 'vue';
import AgGridParentCellEditor from './mixins/AgGridParentCellEditor.vue';
import AirportRampServiceSelect from '../../rampService/AirportRampServiceSelect.vue';

export default Vue.extend({
  mixins: [AgGridParentCellEditor],
  components: {
    AirportRampServiceSelect,
  },
  data() {
    return {
      itemId: null,
      itemName: null,
    };
  },
  methods: {
    getValue() {
      if (this.params.column.colDef && this.params.column.colDef.cellEditorCustomConfig && this.params.column.colDef.cellEditorCustomConfig.fieldId) {
        this.params.node.data[this.params.column.colDef.cellEditorCustomConfig.fieldId] = this.itemId;
      }
      this.beforeReturnGetValue();
      return this.itemName;
    },
    setItemName(name) {
      this.itemName = name;
    },
  },
  created() {
    if (this.params.column.colDef && this.params.column.colDef.cellEditorCustomConfig && this.params.column.colDef.cellEditorCustomConfig.fieldId) {
      this.itemId = this.params.node.data[this.params.column.colDef.cellEditorCustomConfig.fieldId];
    }
    if (this.params.column.colDef && this.params.column.colDef.cellEditorCustomConfig && this.params.column.colDef.cellEditorCustomConfig.fieldName) {
      this.itemName = this.params.node.data[this.params.column.colDef.cellEditorCustomConfig.fieldName];
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$refs.refComp) {
        this.$refs.refComp.$refs.refComp.focus();
      }
    });
  },
});
</script>

<style>

</style>
