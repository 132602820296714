<template>
  <v-container>
    <v-expansion-panel>
      <v-expansion-panel-content class="exp-header">
        <template v-slot:actions>
          <v-icon color="white">mdi-menu-down</v-icon>
        </template>
        <template v-slot:header>
          <div>
            <h4 class="headline font-weight-light"><v-icon dark left>find_in_page</v-icon>{{$t("contingencies.contingencyDetails")}}</h4>
          </div>
        </template>
        <v-card :dark="this.$vuetify.dark">
          <v-card-text>
            <v-container grid-list-md text-xs-center fluid>
              <v-layout>
                <v-flex >
                  <v-flex my-2>
                    <label class="primary--text subheading">{{$t("contingencies.delayReason")}}</label>
                  </v-flex>
                    <v-checkbox color="primary" v-model="contingencyDetail.delayReasonMaintenance" :label="this.$i18n.t('contingencies.maintenance')" :disabled="!hasPermissions(rolePermission)"></v-checkbox>
                    <v-checkbox color="primary" v-model="contingencyDetail.delayReasonWeather" :label="this.$i18n.t('contingencies.weather')" :disabled="!hasPermissions(rolePermission)"></v-checkbox>
                    <v-checkbox color="primary" v-model="contingencyDetail.delayReasonAircraftNeeded" :label="this.$i18n.t('contingencies.aircraftNeeded')" :disabled="!hasPermissions(rolePermission)"></v-checkbox>
                    <v-checkbox color="primary" v-model="contingencyDetail.delayReasonRescheduling" :label="this.$i18n.t('contingencies.reScheduling')" :disabled="!hasPermissions(rolePermission)"></v-checkbox>
                    <v-checkbox color="primary" v-model="contingencyDetail.delayReasonOther" :label="this.$i18n.t('contingencies.other')" :disabled="!hasPermissions(rolePermission)"></v-checkbox>
                </v-flex>
                <v-flex >
                  <v-flex my-2>
                    <label class="primary--text subheading">{{$t("contingencies.amountAffectedPax")}}</label>
                  </v-flex>
                    <v-text-field v-model="contingencyDetail.affectedPaxLocal" :label="this.$i18n.t('contingencies.local')"  box :readonly="!hasPermissions(rolePermission)"></v-text-field>
                    <v-text-field v-model="contingencyDetail.affectedPaxTransport" :label="this.$i18n.t('contingencies.transport')"  box :readonly="!hasPermissions(rolePermission)"></v-text-field>
                    <v-text-field v-model="contingencyDetail.affectedPaxTotalPax" :label="this.$i18n.t('contingencies.totalPax')"  box :readonly="!hasPermissions(rolePermission)"></v-text-field>
                    <v-text-field v-model="contingencyDetail.affectedPaxNoTravel" :label="this.$i18n.t('contingencies.noTravel')"  box :readonly="!hasPermissions(rolePermission)"></v-text-field>
                    <v-text-field v-model="contingencyDetail.affectedPaxTotalFinal" :label="this.$i18n.t('contingencies.finalTotal')"  box :readonly="!hasPermissions(rolePermission)"></v-text-field>
                </v-flex>
                <v-flex  >
                  <v-flex my-2>
                    <label class="primary--text subheading">{{$t("contingencies.assistance")}}</label>
                  </v-flex>
                    <v-text-field v-model="contingencyDetail.assistanceWchr" :label="this.$i18n.t('contingencies.wchr')"  box :readonly="!hasPermissions(rolePermission)"></v-text-field>
                    <v-text-field v-model="contingencyDetail.assistanceMaas" :label="this.$i18n.t('contingencies.maas')"  box :readonly="!hasPermissions(rolePermission)"></v-text-field>
                    <v-text-field v-model="contingencyDetail.assistanceOther" :label="this.$i18n.t('contingencies.other')"  box :readonly="!hasPermissions(rolePermission)"></v-text-field>
                </v-flex>
                <v-flex >
                  <v-flex my-2>
                    <label class="primary--text subheading">{{$t("contingencies.solutionsForPax")}}</label>
                  </v-flex>
                    <v-checkbox color="primary" v-model="contingencyDetail.solutionPaxCoverage" :label="this.$i18n.t('contingencies.coverage')" :disabled="!hasPermissions(rolePermission)"></v-checkbox>
                    <v-checkbox color="primary" v-model="contingencyDetail.solutionPaxConnection" :label="this.$i18n.t('contingencies.connection')" :disabled="!hasPermissions(rolePermission)"></v-checkbox>
                    <v-checkbox color="primary" v-model="contingencyDetail.solutionPaxSupport" :label="this.$i18n.t('contingencies.support')" :disabled="!hasPermissions(rolePermission)"></v-checkbox>
                    <v-checkbox color="primary" v-model="contingencyDetail.solutionPaxDestinationTransport" :label="this.$i18n.t('contingencies.destinationTransport')" :disabled="!hasPermissions(rolePermission)"></v-checkbox>
                    <v-checkbox color="primary" v-model="contingencyDetail.solutionPaxOther" :label="this.$i18n.t('contingencies.other')" :disabled="!hasPermissions(rolePermission)"></v-checkbox>
                </v-flex>
                <v-flex >
                  <v-flex my-2>
                    <label class="primary--text subheading">{{$t("contingencies.solutionsDetails")}}</label>
                  </v-flex>
                    <v-text-field v-model="contingencyDetail.solutionPaxCoverageDetails" :label="this.$i18n.t('contingencies.coverageSolution')"  box :readonly="!hasPermissions(rolePermission)"></v-text-field>
                    <v-text-field v-model="contingencyDetail.solutionPaxConnectionDetails" :label="this.$i18n.t('contingencies.connectionSolution')"  box :readonly="!hasPermissions(rolePermission)"></v-text-field>
                    <v-text-field v-model="contingencyDetail.solutionPaxSupportDetails" :label="this.$i18n.t('contingencies.supportDetails')"  box :readonly="!hasPermissions(rolePermission)"></v-text-field>
                    <v-text-field v-model="contingencyDetail.solutionPaxDestinationTransportDetails" :label="this.$i18n.t('contingencies.destinationTransportSolution')"  box :readonly="!hasPermissions(rolePermission)"></v-text-field>
                    <v-text-field v-model="contingencyDetail.solutionPaxOtherDetails" :label="this.$i18n.t('contingencies.otherDetails')"  box :readonly="!hasPermissions(rolePermission)"></v-text-field>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
        </v-card>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-container>
</template>

<script>

import ContingencyDetail from '../model/contingencyDetail';
import Saveable from '../../mixins/Saveable.vue';
import RolePermissions from '../../mixins/RolePermissions.vue';
import TimeInput from '../../ui/TimeInput.vue';

export default {
  name: 'contingency-detail-form',
  mixins: [Saveable, RolePermissions],
  components: {
    TimeInput,
  },
  props: {
    editedContingencyDetail: { type: ContingencyDetail, required: true },
  },
  data() {
    return {
      contingencyDetail: this.editedContingencyDetail,
    };
  },
  computed: {
    rolePermission() {
      return `${this.$route.meta.role}_WR`;
    },
  },
  watch: {
    editedContingencyDetail: {
      handler(newContingencyDetailProp) {
        this.contingencyDetail = newContingencyDetailProp;
      },
      deep: true,
    },
  },
  methods: {
    save() {
      this.$emit('contingency-detail-update', this.contingencyDetail);
    },
  },
};
</script>

<style scoped lang="scss">
.exp-header {
  background: #FF4021 !important;
  color: white !important;
  border-bottom: 1px solid white;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
}
.agent-time-input {
  max-width: 100% !important;
}
</style>
