/* eslint no-param-reassign: "off" */
import moment from 'moment';
import { HTTP } from '../../../http';
import AuditFilter from '../../../components/audit/model/auditFilter';
import AuditFlightChange from '../../../components/audit/model/auditFlightChange';
import { REQUEST_ERROR, REQUEST_START, REQUEST_SUCCESS } from '../../store';

// action

export const AUDIT_FIND_CHANGES = 'AUDIT_FIND_CHANGES';

// mutations
export const AUDIT_FIND_CHANGES_SUCCESS = 'AUDIT_FIND_CHANGES_SUCCESS';
export const AUDIT_FILTER_RESET = 'AUDIT_FILTER_RESET';
export const AUDIT_FILTER_RESET_TIMEZONE = 'AUDIT_FILTER_RESET_TIMEZONE';

// initial state
const storeState = {
  filter: AuditFilter.createEmptyFilter,
  changes: [],
  loading: false,
};

// getters
const storeGetters = {
};

// actions
const storeActions = {
  [AUDIT_FIND_CHANGES]: ({ commit, state }) => {
    commit(REQUEST_START);
    return HTTP.post('audit/flight/changes/find', state.filter)
      .then((resp) => {
        commit(REQUEST_SUCCESS);
        commit(AUDIT_FIND_CHANGES_SUCCESS, resp.data);
      })
      .catch((err) => {
        commit(AUDIT_FIND_CHANGES_SUCCESS, []);
        commit(REQUEST_ERROR, err);
      });
  },
};

// mutations
const storeMutations = {
  [AUDIT_FIND_CHANGES_SUCCESS]: (state, changes) => {
    state.changes = AuditFlightChange.createAuditFlightChangeFromData(changes);
  },
  [AUDIT_FILTER_RESET]: (state) => {
    state.filter.changesDateFrom = moment().startOf('hour');
    state.filter.changesDateTo = moment().endOf('hour');
    state.filter.timezoneDate = moment.defaultZone.name;
  },
  [AUDIT_FILTER_RESET_TIMEZONE]: (state) => {
    state.filter.changesDateFrom = moment().startOf('hour')
      .year(state.filter.changesDateFrom.year())
      .month(state.filter.changesDateFrom.month())
      .date(state.filter.changesDateFrom.date());
    state.filter.changesDateTo = moment().endOf('hour')
      .year(state.filter.changesDateTo.year())
      .month(state.filter.changesDateTo.month())
      .date(state.filter.changesDateTo.date());

    state.filter.timezoneDate = moment.defaultZone.name;
  },
};

export default {
  state: storeState,
  getters: storeGetters,
  actions: storeActions,
  mutations: storeMutations,
};
