export default class FlightDelays {
  constructor(flightId, id, amount, order, remarks, flightTripNumber, typeId, typeCode, typeDescription, index) {
    this.id = id;
    this.remarks = remarks;
    this.flightTripNumber = flightTripNumber;
    this.amount = amount;
    this.order = order;
    this.typeId = typeId;
    this.typeCode = typeCode;
    this.typeDescription = typeDescription;
    this.index = index;
    this.flightId = flightId;
  }

  static createFlightDelays(flightId) {
    return new FlightDelays(flightId);
  }
}
