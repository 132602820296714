<template>
  <div class="admin-scheduling-imports-body">
    <AdminSchedulingImportsByDay v-for="(day) in daysWhenSchedulingImportFilesWereImported" :key="day" :day="day"/>
  </div>
</template>
<script>
import AdminSchedulingImportsByDay from './AdminSchedulingImportsByDay.vue';

export default {
  name: 'AdminSchedulingImportsBody',
  components: {
    AdminSchedulingImportsByDay,
  },
  computed: {
    daysWhenSchedulingImportFilesWereImported() {
      return this.$store.getters.getDaysWhenSchedulingImportFilesWereImported;
    },
  },
};
</script>

<style>
  .admin-scheduling-imports-body {
    width: 100%;
    height: calc(100vh - 135px);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: flex-start;
    margin: 4px 0 0 0;
    overflow-y: scroll;
  }
</style>
