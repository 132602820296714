<template>
  <div class="scheduling-weighting-list-table">
    <ag-grid-vue
      class="ag-theme-material connected-flights-table operational-table"
      :columnDefs="columnDefs"
      :defaultColDef="defaultColDef"
      :rowData="items"
      rowSelection="multiple"
      :rowMultiSelectWithClick="true"
      :suppressRowClickSelection="true"
      :onGridReady="onGridReady"
      @first-data-rendered="firstDataRendered"
      :gridOptions="gridOptions"
      :frameworkComponents="frameworkComponents"
      :overlayNoRowsTemplate="customNoRowsOverlay"
      :overlayLoadingTemplate="customLoadingOverlay"
      v-bind:style="`width: 100%; height: calc(100vh - ${this.sizeFilter});`">
    </ag-grid-vue>
  </div>
</template>
<script>
import
{ AgGridVue } from 'ag-grid-vue';
import AgGridConnectedFlights from '../../ag-grid/mixins/AgGridConnectedFlights.vue';
import {
  SCHED_WEIGHT_CHANGE_GRID_API,
  SCHED_WEIGHT_FIT_COLUMNS_TO_VIEW,
} from '../../../store/modules/schedulingWeighting';
import { cellClassAgGridRules } from '../../ag-grid/mixins/cellClassAgGridRules';
import { FisModule } from '../../ag-grid/cell-editors/model/fisModule';
import agGridTextCustomFilterComparator from '../../ag-grid/filters/agGridTextCustomFilterComparator';
import agGridTextCustomFilterFormatter from '../../ag-grid/filters/agGridTextCustomFilterFormatter';
import agGridDateFormatter from '../../ag-grid/formatters/agGridDateFormatter';
import { WEBSOCKET_CONNECTION } from '../../../store/modules/websocket';
import AgGridNoRowsOverlay from '../../ag-grid/mixins/AgGridNoRowsOverlay.vue';

export default {
  name: 'scheduling-weighting-list-table',
  mixins: [AgGridConnectedFlights, AgGridNoRowsOverlay],
  components: {
    AgGridVue,
  },
  props: {
    items: { type: Array, required: true },
    isFilterDisplayed: { type: Boolean, required: false, default: false },
  },
  mounted() {
    this.$store.dispatch(WEBSOCKET_CONNECTION, this.$store.state.tenant.currentTenant.name.toLowerCase());
  },
  computed: {
    sizeFilter() {
      return this.isFilterDisplayed ? '250px' : '180px';
    },
    fitColumnsOnResize() {
      return this.$store.state.schedulingWeighting.fitColumnsOnResize;
    },
  },
  data() {
    const that = this;
    return {
      frameworkComponents: {
      },
      defaultColDef: {
        cellClassRules: cellClassAgGridRules,
        minWidth: 30,
        suppressMenu: true,
        sortable: true,
        resizable: true,
        filter: true,
        filterParams: {
          textCustomComparator: agGridTextCustomFilterComparator,
          textFormatter: agGridTextCustomFilterFormatter,
        },
      },
      gridOptions: {
        onFilterChanged: this.uncheckAllOnFilter,
        getRowNodeId: this.getRowNodeId,
        getRowClass: this.getRowClass,
        floatingFilter: true,
        groupHeaderHeight: 35,
        headerHeight: 35,
        floatingFiltersHeight: 20,
        rowHeight: 30,
        context: {
          module: FisModule.SCHEDULING_WEIGHTING,
        },
      },
      columnDefs: [
        {
          field: 'date',
          headerValueGetter() {
            return that.$i18n.t('flightsWeighting.dateFrom');
          },
          type: 'date',
          filter: 'agDateColumnFilter',
          valueFormatter: agGridDateFormatter,
        },
        {
          headerValueGetter() {
            return that.$i18n.t('flightsWeighting.dateTo');
          },
          field: 'dateTo',
          type: 'dateTo',
          filter: 'agDateColumnFilter',
          valueFormatter: agGridDateFormatter,
        },
        {
          headerValueGetter() {
            return that.$i18n.t('common.station');
          },
          field: 'tenant',
        },
        {
          headerValueGetter() {
            return that.$i18n.t('common.airline');
          },
          field: 'airlineCode',
          valueGetter(params) {
            return that.isAggregateByDate() ? params.data.airlineCode : '-';
          },
        },
        {
          headerValueGetter() {
            return that.$i18n.t('common.type');
          },
          field: 'airlineCode',
          valueGetter(params) {
            return that.isAggregateByAircraftTypeCode() ? params.data.aircraftTypeCode : '-';
          },
        },
        {
          headerValueGetter() {
            return that.$i18n.t('flightsWeighting.realFlights');
          },
          field: 'real',
          valueGetter(params) {
            return params.data.real ? params.data.real.toFixed(2).toString().replace('.', ',') : null;
          },
        },
        {
          headerValueGetter() {
            return that.$i18n.t('flightsWeighting.totalWeighting');
          },
          field: 'total',
          valueGetter(params) {
            return params.data.total ? params.data.total.toFixed(2).toString().replace('.', ',') : null;
          },
        },
        {
          headerValueGetter() {
            return that.$i18n.t('flightsWeighting.rampWeighting');
          },
          field: 'ramp',
          valueGetter(params) {
            return params.data.ramp ? params.data.ramp.toFixed(2).toString().replace('.', ',') : null;
          },
        },
        {
          headerValueGetter() {
            return that.$i18n.t('flightsWeighting.passageWeighting');
          },
          field: 'passage',
          valueGetter(params) {
            return params.data.passage ? params.data.passage.toFixed(2).toString().replace('.', ',') : null;
          },
        },
        {
          headerValueGetter() {
            return that.$i18n.t('flightsWeighting.operationWeighting');
          },
          field: 'operations',
          valueGetter(params) {
            return params.data.operations ? params.data.operations.toFixed(2).toString().replace('.', ',') : null;
          },
        },
      ],
    };
  },
  methods: {
    onGridReady(params) {
      this.setAgGridApi(params);
      this.showLoading();
      this.$store.commit(SCHED_WEIGHT_CHANGE_GRID_API, params);
      this.refreshCellsOnMutation();
      this.refreshCellsOnChangeLanguage();
    },
    resized() {
      if (this.gridApi) {
        this.gridApi.sizeColumnsToFit();
      }
    },
    sizeColumnsToFit() {
      if (this.gridApi) {
        this.$store.dispatch(SCHED_WEIGHT_FIT_COLUMNS_TO_VIEW);
      }
    },
    firstDataRendered() {
      this.$nextTick(() => {
        this.resized();
      });
    },
    isAggregateByDate() {
      return this.$store.state.scheduling.schedulingWeighting.filter.aggregateByAirline;
    },
    isAggregateByAircraftTypeCode() {
      return this.$store.state.scheduling.schedulingWeighting.filter.aggregateByAircraftTypeCode;
    },
  },
};
</script>
<style lang="scss">
//@import "../../../assets/scss/ag-grid-fis-theme";
.scheduling-weighting-list-table .ag-theme-material .ag-floating-filter-body input {
    margin-top: 16px;
}
</style>
