import { JSUtils } from '../../shared/js/JSUtils';

export default class FlightIntegrationInformation {
  constructor(
    id,
    flightStatusIntegration,
    updatedAtIntegration,
  ) {
    this.id = id;
    this.flightStatusIntegration = flightStatusIntegration;
    this.updatedAtIntegration = updatedAtIntegration;
  }

  static get createEmptyFlightIntegrationInformation() {
    return new FlightIntegrationInformation();
  }

  static createFlightIntegrationInformationFromData(o) {
    if (!o) {
      return this.createEmptyFlightIntegrationInformation;
    }

    return JSUtils.cast(o, FlightIntegrationInformation);
  }
}
