<template>
  <ag-grid-template-cell-renderer :params="params">
    <v-checkbox
      v-model="params.value"
      readonly
      class="toggle-cell-input"
      color="primary"
    ></v-checkbox>
  </ag-grid-template-cell-renderer>
</template>

<script>
import Vue from 'vue';
import AgGridTemplateCellRenderer from './templates/AgGridTemplateCellRenderer.vue';

export default Vue.extend({
  components: {
    AgGridTemplateCellRenderer,
  },
});
</script>

<style scoped>
.toggle-cell-input {
  margin: 0;
  padding-left: 10px;
}
</style>
