<template>
  <div class="airport-income-ticketing-report list-filter">
    <v-card :dark="this.$vuetify.dark" :color="this.$vuetify.dark ? 'bggrey_dark' : 'bggrey'">
      <v-toolbar dark dense color="primary lighten-1">
        <v-btn v-on:click="displayedFilter" small flat>
          <v-icon>filter_list</v-icon>
          <v-icon v-if="!showFilter">keyboard_arrow_down</v-icon>
          <v-icon v-if="showFilter">keyboard_arrow_up</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <AirportIncomeListTableOptionsFilterButton />
      </v-toolbar>
      <v-container fluid v-if="showFilter" class="pa-2 pb-2">
        <v-layout class="filter-inline py-0" align-center justify-start>
          <date-input v-model="filter.dateFrom" :locale="$i18n.locale" :label="$t('common.fromDate')" @change="changeDateFrom"></date-input>
          <date-input class="ml-2" v-model="filter.dateTo" :locale="$i18n.locale" :label="$t('common.toDate')" @change="changeDateTo"></date-input>
          <v-flex class="ml-2 text-no-wrap" align-center justify-start>
            <airline-select :label="$t('common.airlines')" :placeholder="$t('commonForm.selectAirline')" v-model="filter.airlines" @input="filterChanged" :multiple="true" :chip-view="true" :filter-handling-active="true"></airline-select>
          </v-flex>
          <v-flex class="ml-2 text-no-wrap" align-center justify-start>
            <tenant-selector style="width: 100%; min-width: 200px;"  :label="$t('common.airports')" v-model="filter.tenants" :multiple="true" :chip-view="true" @input="filterChanged"/>
          </v-flex>
          <v-flex class="ml-2 text-no-wrap" align-center justify-start>
            <v-btn color="secondary" flat round v-on:click.stop="exportCSV" block>
              <v-icon>mdi-download</v-icon>
              {{ $t("common.export") }}
            </v-btn>
          </v-flex>
          <v-flex class="ml-2 text-no-wrap" align-center justify-start>
          <v-btn color="primary" round v-on:click.stop="find" block>
            <v-icon>search</v-icon>
            {{ $t("common.find") }}
          </v-btn>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card>
  </div>

</template>
<script>
import AirlineSelect from '../../airline/AirlineSelect.vue';
import TenantSelector from '../../tenant/TenantSelector.vue';
import DateInput from '../../ui/DateInput.vue';
import AgGridApiCsvExportable from '../../ag-grid/mixins/AgGridApiCsvExportable.vue';
import StoreSubscribable from '../../mixins/StoreSubscribable.vue';
import { TIME_TIMEZONE_CHANGED } from '../../../store/modules/time';
import AirportIncomeListTableOptionsFilterButton
from './AirportIncomeListTableOptionsFilterButton.vue';
import FileSerializable from '../../mixins/FileSerializable.vue';
import { TENANT_CURRENT_SET_SUCCESS } from '../../../store/modules/tenant';
import {
  AIRPORT_INCOME_TICKETING_REPORT_CHANGE_GRID_API,
  AIRPORT_INCOME_TICKETING_REPORT_FILTER_SET_TENANT,
  AIRPORT_INCOME_TICKETING_REPORT_FILTER_SET_UNIT_TIME,
  AIRPORT_INCOME_TICKETING_REPORT_FIND,
  AIRPORT_INCOME_TICKETING_REPORT_RECALCULATE_FILTER, AIRPORT_INCOME_TICKETING_REPORT_RECALCULATE_TENANT_FILTER,
} from '../../../store/modules/ticketingReports/airportIncome';

export default {
  name: 'airport-income-ticketing-report-list-filter',
  mixins: [AgGridApiCsvExportable, StoreSubscribable, FileSerializable],
  components: {
    AirlineSelect,
    TenantSelector,
    DateInput,
    AirportIncomeListTableOptionsFilterButton,
  },
  props: ['value'],
  data() {
    return {
      showFilter: true,
      filterSummary: null,
    };
  },
  computed: {
    filter() {
      return this.$store.state.ticketingReports.airportIncome.filter;
    },
    unitOfTime: {
      get() {
        return this.filter.unitOfTime;
      },
      set(value) {
        this.$store.commit(AIRPORT_INCOME_TICKETING_REPORT_FILTER_SET_UNIT_TIME, value);
      },
    },
    isTenantSetted() {
      return this.$store.getters.isTenantSetted;
    },
    tenantId() {
      return this.isTenantSetted ? this.$store.state.tenant.currentTenant.id : null;
    },
  },
  methods: {
    filterChanged() {
      // this.find();
    },
    find() {
      this.showLoading();
      this.$store.dispatch(AIRPORT_INCOME_TICKETING_REPORT_FIND);
    },
    exportCSV() {
      this.exportAgGridCsv('AirportsCollection');
    },
    changeDateFrom() {
      this.unitOfTime = null;
      const from = this.$moment(this.filter.dateFrom);
      const to = this.$moment(this.filter.dateTo);

      this.filter.dateFrom = from.startOf('day');
      if (from.isAfter(to)) {
        this.filter.dateTo = this.$moment(this.filter.dateFrom).endOf('day');
      }
      this.filterChanged();
    },
    changeDateTo() {
      this.unitOfTime = null;
      const from = this.$moment(this.filter.dateFrom);
      const to = this.$moment(this.filter.dateTo);

      this.filter.dateTo = to.endOf('day');
      if (to.isBefore(from)) {
        this.filter.dateFrom = this.$moment(this.filter.dateTo).startOf('day');
      }
      this.filterChanged();
    },
    displayedFilter() {
      this.showFilter = !this.showFilter;
      this.$emit('changeShowFilter', this.showFilter);
    },
  },
  mounted() {
    const that = this;
    this.subscribeMutation(AIRPORT_INCOME_TICKETING_REPORT_CHANGE_GRID_API, (mutationPayload) => {
      that.setAgGridApi(mutationPayload);
    });
    this.subscribeMutation(TIME_TIMEZONE_CHANGED, () => {
      this.$store.dispatch(AIRPORT_INCOME_TICKETING_REPORT_RECALCULATE_FILTER);
      this.find();
    });
    this.subscribeMutation(TENANT_CURRENT_SET_SUCCESS, () => {
      this.$store.commit(AIRPORT_INCOME_TICKETING_REPORT_FILTER_SET_TENANT, this.tenantId);
      this.find();
    });
    this.$store.dispatch(AIRPORT_INCOME_TICKETING_REPORT_RECALCULATE_FILTER);
    this.$store.dispatch(AIRPORT_INCOME_TICKETING_REPORT_RECALCULATE_TENANT_FILTER, this.tenantId);
    this.find();
  },
};
</script>
<style>
  .text-filter {
    margin: 1em;
    font-weight: bold;
  }
</style>
