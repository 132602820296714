<template>
    <v-card :dark="$vuetify.theme.dark">
        <v-card-text class="loads-table">
            <v-flex sm6 d-flex pt-2 pl-2>
                {{$t("operations.loadsTableHoldingTitle")}}
            </v-flex>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text class="loads-table">
            <ag-grid-vue
                    class="ag-theme-material"
                    :columnDefs="columnDefs"
                    :defaultColDef="defaultColDef"
                    @grid-ready="setAgGridApi"
                    :gridOptions="gridOptions"
                    :frameworkComponents="frameworkComponents"
                    :rowData="rowData">
            </ag-grid-vue>
        </v-card-text>
    </v-card>
</template>
<script>
import Vue from 'vue';
import { AgGridVue } from 'ag-grid-vue';
import AgGridNumberCellEditor from '../../AgGridNumberCellEditor.vue';
import AgGridValueWithAsteriskCellRenderer from '../../../cell-renderers/AgGridValueWithAsteriskCellRenderer.vue';
import AgGridApiHoldable from '../../../mixins/AgGridApiHoldable.vue';
import AgGridOptionsConfigurable from '../../../mixins/AgGridOptionsConfigurable.vue';
import { cellClassAgGridRules } from '../../../mixins/cellClassAgGridRules';
import FlightLoadsHolding from '../../model/flightLoadsHolding';
import RolePermissions from '../../../../mixins/RolePermissions.vue';

export default Vue.extend({
  name: 'FlightLoadsTableHolding',
  mixins: [AgGridApiHoldable, AgGridOptionsConfigurable, RolePermissions],
  components: {
    AgGridVue,
  },
  props: {
    items: { type: Array, required: true },
    invalidData: { type: Boolean, required: true },
  },
  computed: {
    rolePermission() {
      return `${this.$route.meta.role}_WR`;
    },
  },
  data() {
    const that = this;
    return {
      totalLoadHolding: 0,
      frameworkComponents: {
        AgGridNumberCellEditor,
      },
      defaultColDef: {
        cellClassRules: cellClassAgGridRules,
      },
      gridOptions: {
        getRowClass: this.getRowClass,
      },
      rowData: [],
      localInvalidData: true,
      columnDefs: [
        {
          field: 'holdOrder',
          headerValueGetter() {
            return that.$i18n.t('operations.loadsTableHoldingHoldOrder');
          },
        },
        {
          field: 'weight',
          headerValueGetter() {
            return that.$i18n.t('operations.loadsTableHoldingWeight');
          },
          editable: this.isCellEditable,
          cellEditor: 'AgGridNumberCellEditor',
          onCellValueChanged: this.calcularTotal,
          valueGetter(params) {
            return {
              value: params.data.weight,
              asterisk: params.data.messageValidate.weight.errorExist,
              tooltip: params.data.messageValidate.weight.listMessage.join('\n'),
            };
          },
          cellRendererFramework: AgGridValueWithAsteriskCellRenderer,
        },
        {
          field: 'pcs',
          headerValueGetter() {
            return that.$i18n.t('operations.loadsTableHoldingPcs');
          },
          editable: this.isCellEditable,
          cellEditor: 'AgGridNumberCellEditor',
          onCellValueChanged: this.calcularTotal,
          valueGetter(params) {
            return {
              value: params.data.pcs,
              asterisk: params.data.messageValidate.pcs.errorExist,
              tooltip: params.data.messageValidate.pcs.listMessage.join('\n'),
            };
          },
          cellRendererFramework: AgGridValueWithAsteriskCellRenderer,
        },
        {
          field: 'specials',
          headerValueGetter() {
            return that.$i18n.t('operations.loadsTableHoldingSpecials');
          },
          editable: this.isCellEditable,
        },
      ],
    };
  },
  methods: {
    isCellEditable(params) {
      return !params.data.isTotalRow && this.hasPermissions(this.rolePermission);
    },
    changeInvalidData(newInvalidData) {
      this.localInvalidData = newInvalidData;
      this.$emit('update:invalidData', newInvalidData);
    },
    changeTotalLoadHolding(newData) {
      this.totalLoadHolding = newData;
      this.$emit('update:totalLoadHolding', newData);
    },
    initTotal() {
      const [totalRow] = this.rowData.filter(r => !!r.isTotalRow);
      totalRow.holdOrder = 'Total';
      this.changeInvalidData(false);

      this.columnDefs.map(colDef => colDef.field).forEach((colField) => {
        if (colField === 'holdOrder' || colField === 'specials') {
          return;
        }
        this.rowData.forEach((data) => {
          this.validateColFieldOfRow(colField, data);
        });
      });

      const totalLoadingHolding = totalRow.weight ? totalRow.weight : 0;
      this.changeTotalLoadHolding(totalLoadingHolding);
    },
    calcularTotal(params) {
      const dataLoad = this.rowData.filter(r => !r.isTotalRow);

      const totalRow = new FlightLoadsHolding();

      totalRow.holdOrder = 'Total';
      totalRow.isTotalRow = true;
      this.changeInvalidData(false);

      this.columnDefs.map(colDef => colDef.field).forEach((colField) => {
        if (colField === 'holdOrder' || colField === 'specials') {
          return;
        }
        dataLoad.forEach((data) => {
          if (data[colField] !== null && data[colField] !== '') {
            totalRow[colField] = Number(totalRow[colField] == null ? 0 : totalRow[colField]) + Number(data[colField]);
          }
          this.validateColFieldOfRow(colField, data);
        });
      });

      this.columnDefs.map(colDef => colDef.field).forEach((colField) => {
        if (colField === 'holdOrder' || colField === 'specials') {
          return;
        }

        this.validateColFieldOfRow(colField, totalRow);
      });

      if (this.rowData.filter(r => !!r.isTotalRow).length > 0 && params) {
        // eslint-disable-next-line
        this.rowData.filter(r => !!r.isTotalRow).map(item => {
          Object.assign(item, totalRow);
        });
        params.api.refreshCells({ force: true, columns: [params.column] });
      } else {
        this.rowData.push(totalRow);
      }

      const totalLoadingHolding = totalRow.weight ? totalRow.weight : 0;
      this.changeTotalLoadHolding(totalLoadingHolding);
    },
    validateColFieldOfRow(colField, rowData) {
      const data = rowData;
      const errorValidate = {};
      const messageValidate = [];

      errorValidate.errorExist = false;

      if (data[colField] < 0) {
        this.changeInvalidData(true);
        errorValidate.errorExist = true;
        messageValidate.push(`${colField} is less than 0.`);
      }

      if (data.messageValidate == null) {
        data.messageValidate = {};
      }
      errorValidate.listMessage = messageValidate;

      data.messageValidate[colField] = errorValidate;
    },
  },
  created() {
    this.rowData = this.items;
    this.initTotal();
  },
});
</script>
<style lang="scss">
  .loads-table {
    padding: 0;
  }

  .radio-unit-label {
    padding-top: 1.5vh;
    font-weight: bold;
    margin-right: 1vw;
  }
</style>
