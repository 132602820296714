import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListTileAction } from 'vuetify/lib/components/VList';
import { VListTileContent } from 'vuetify/lib/components/VList';
import { VListTileSubTitle } from 'vuetify/lib/components/VList';
import { VListTileTitle } from 'vuetify/lib/components/VList';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VAutocomplete,{ref:'refSelectFlight',attrs:{"value":_vm.value,"items":_vm.items,"label":_vm.$t('commonForm.flight'),"placeholder":_vm.$t('commonForm.selectFlight'),"prepend-icon":"local_airport","filter":_vm.customFilter,"rules":_vm.rules,"item-value":"id","clearable":true},on:{"input":function($event){return _vm.inputFlight($event)},"change":_vm.onChangeSelection},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_vm._v(_vm._s(data.item.flightNo()))]}},{key:"item",fn:function(data){return [_c(VListTileContent,[_c(VListTileTitle,{domProps:{"innerHTML":_vm._s(data.item.flightNo())}}),_c(VListTileSubTitle,{domProps:{"innerHTML":_vm._s(`Flight date: ${_vm.customFormatDate(data.item.scheduledDate())} - Airline: ${data.item.airlineCode} - From/To: ${data.item.airportsIata()}`)}})],1),_c(VListTileAction,[_c(VIcon,[_vm._v(_vm._s(_vm.getIconBound(data.item.bound)))])],1)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }