import FinancialSystemsFile from './financialSystemsFile';

export default class FinancialSystemsFileFactory {
  static createFinancialSystemsFileFromData(data) {
    if (data && data.constructor === Array && data.length > 0) {
      return data.map(d => this.mapFinancialSystemsFile(d));
    }
    return [];
  }

  static mapFinancialSystemsFile(d) {
    const financialSystemsFile = FinancialSystemsFile.createFinancialSystemsFileFromData(d);
    if (financialSystemsFile.children != null && financialSystemsFile.children.length > 0) {
      const auxChildren = [];
      financialSystemsFile.children.forEach((childFile) => {
        auxChildren.push(this.mapFinancialSystemsFile(childFile));
      });
      financialSystemsFile.children = auxChildren;
    }
    return financialSystemsFile;
  }
}
