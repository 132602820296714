export default class RushBaggageFilter {
  constructor(dateFrom, dateTo, showExternal, showInternal, timezoneDate) {
    this.dateFrom = dateFrom;
    this.dateTo = dateTo;
    this.showExternal = showExternal === true;
    this.showInternal = showInternal === true;
    this.timezoneDate = timezoneDate;
  }

  static get createEmptyFilter() {
    return new RushBaggageFilter(null, null, true, true, null);
  }
}
