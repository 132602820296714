<template>
  <div class="airportActiveMilestonesEditCardContent">
    <div class="tableMilestones">
      <v-card-title>{{ $t("configuration.availableMilestones") }}</v-card-title>
      <v-flex>
        <ag-grid-vue class="ag-theme-material" :columnDefs="columnDefMilestones" :rowData="clonedAirportActiveMilestones"
          rowSelection="multiple" :enableFilter="false" :suppressRowClickSelection="true"
          :gridOptions="gridOptionsMilestones" :style="`height: 30vh`">
        </ag-grid-vue>
      </v-flex>
    </div>
    <div class="addRemoveMilestones">
      <v-btn class="mr-2" @click="moveToRight">
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
      <v-btn @click="moveToLeft">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
    </div>
    <div class="tableAirportServiceMilestones">
      <div class="tableAirportServiceMilestonesHeader">
        <v-card-title>{{ $t("configuration.selectedMilestones") }}</v-card-title>
        <v-btn class="mr-2" @click="moveUp">
          <v-icon>mdi-chevron-up</v-icon>
        </v-btn>
        <v-btn @click="moveDown">
          <v-icon>mdi-chevron-down</v-icon>
        </v-btn>
      </div>
      <ag-grid-vue
        class="ag-theme-material"
        :columnDefs="columnDefAirportServiceMilestones"
        :defaultColDef="defaultColDefAirportServiceMilestones"
        @rowSelected="onRowAirportServiceMilestones"
        rowSelection="single"
        :suppressRowClickSelection="false"
        :gridOptions="gridOptionsAirportServiceMilestones"
        :rowData="rowDataAirportServiceMilestones"
        :frameworkComponents="frameworkComponents"
        :style="`height: 30vh`" >
      </ag-grid-vue>
    </div>
  </div>
</template>


<script>
import { GET_AIRPORT_ACTIVE_MILESTONES } from '@/store/modules/airportMilestone/airportMilestoneCatalog';
import { AgGridVue } from 'ag-grid-vue';
import RolePermissions from '../../../mixins/RolePermissions.vue';
import { mapAirportMilestonesToAirportServiceMilestones, MilestonesPermissions } from '../../../shared/model/milestoneTypes';
import { cellClassAgGridRules } from '../../../ag-grid/mixins/cellClassAgGridRules';
import AgGridMilestonesPermissionSelectCellEditor from '../../../ag-grid/cell-editors/AgGridMilestonesPermissionSelectCellEditor.vue';
import './AirportActiveMilestonesEditCardContent.css';

export default {
  name: 'airport-active-milestones-edit-card-content',
  mixins: [RolePermissions],
  rowDataActiveMilestones: [],
  props: {
    airportActiveMilestonesByAirportServiceAgent: { type: Array, required: true },
  },
  components: {
    AgGridVue,
  },
  data() {
    const rolePermission = this.getRoles();
    return {
      frameworkComponents: {
        AgGridMilestonesPermissionSelectCellEditor,
      },
      rowDataAirportServiceMilestones: this.airportActiveMilestonesByAirportServiceAgent,
      clonedAirportActiveMilestones: [],
      columnDefMilestones: [
        {
          headerName: this.$i18n.t('configuration.name'),
          field: 'name',
          tooltipField: 'description',
          checkboxSelection: true,
        },


        { headerName: this.$i18n.t('configuration.type'), field: 'type' },
      ],
      columnDefAirportServiceMilestones: [
        { headerName: this.$i18n.t('configuration.name'), field: 'name', tooltipField: 'description' },
        { headerName: this.$i18n.t('configuration.type'), field: 'type' },
        {
          field: 'permission',
          headerName: this.$i18n.t('roles.role'),
          cellEditor: 'AgGridMilestonesPermissionSelectCellEditor',
          editable: this.editable(true, rolePermission),
          cellRenderer: params => this.$i18n.t(`roles.${MilestonesPermissions[params.value]}`),
        },
      ],
      defaultColDefAirportServiceMilestones: {
        resizable: false,
        sortable: false,
        cellClassRules: cellClassAgGridRules,
        suppressMovable: true,
      },
      selectedRowsAirportServiceMilestones: [],
      gridOptionsMilestones: {
        rowHeight: 35,
        headerHeight: 35,
      },
      gridOptionsAirportServiceMilestones: {
        rowHeight: 35,
        headerHeight: 35,
      },
    };
  },
  computed: {
    airportActiveMilestones() {
      return this.$store.getters.getAirportActiveMilestones;
    },
    airportActiveMilestonesByAirportServiceAgentComputed() {
      return this.rowDataAirportServiceMilestones;
    },
  },
  methods: {
    loadAirportActiveMilestones() {
      // if the airport active milestones are already loaded
      if (this.airportActiveMilestones.length > 0) {
        this.updateAirportServiceMilestones(this.rowDataAirportServiceMilestones);
        return;
      }
      // clear store airport active milestones
      this.$store.dispatch(GET_AIRPORT_ACTIVE_MILESTONES);
      // await for the airport active milestones to be loaded
      this.$store.watch(
        (_state, getters) => getters.getAirportActiveMilestones,
        (loaded) => {
          if (loaded) {
            this.updateAirportServiceMilestones(this.rowDataAirportServiceMilestones);
          }
        },
      );
    },
    moveToRight() {
      // extract data from the selected rows
      const selectedRows = this.gridOptionsMilestones.api.getSelectedNodes().map(node => node.data);
      if (selectedRows.length === 0) {
        return;
      }
      // map the selected rows to the airport service milestones
      const airportServiceMilestones = mapAirportMilestonesToAirportServiceMilestones(selectedRows);
      // add the airport service milestones to the right table
      this.rowDataAirportServiceMilestones = [...this.rowDataAirportServiceMilestones, ...airportServiceMilestones];
      this.updateAirportServiceMilestones(this.rowDataAirportServiceMilestones);
      this.updateOrderProp();
    },
    moveToLeft() {
      // extract data from the selected rows
      const selectedRows = this.gridOptionsAirportServiceMilestones.api.getSelectedNodes().map(node => node.data);
      if (selectedRows.length === 0) {
        return;
      }
      // remove the selected rows from the right table
      this.rowDataAirportServiceMilestones = this.rowDataAirportServiceMilestones.filter(item => !selectedRows.includes(item));
      this.updateAirportServiceMilestones(this.rowDataAirportServiceMilestones);
      this.updateOrderProp();
    },
    updateAirportServiceMilestones(airportServiceMilestones) {
      // clone airportServiceMilestones
      const clonedMilestonesAux = this.airportActiveMilestones.map(item => ({ ...item }));
      // remove the cloned airport active milestones in airportServiceMilestones from airport active milestones
      this.clonedAirportActiveMilestones = clonedMilestonesAux.filter(item => !airportServiceMilestones.some(airportServiceMilestone => airportServiceMilestone.milestoneId === item.id));
      this.gridOptionsMilestones.api.setRowData(this.clonedAirportActiveMilestones);
      this.gridOptionsMilestones.api.refreshCells();
    },
    onRowAirportServiceMilestones() {
      this.selectedRowsAirportServiceMilestones = this.gridOptionsAirportServiceMilestones.api.getSelectedRows();
    },
    moveUp() {
      // get the index of the selected row
      const firstIndex = this.rowDataAirportServiceMilestones.findIndex(item => item === this.selectedRowsAirportServiceMilestones[0]);
      // move up the selected row by swapping it with the previous row
      if (firstIndex > 0) {
        const temp = this.rowDataAirportServiceMilestones[firstIndex];
        this.rowDataAirportServiceMilestones[firstIndex] = this.rowDataAirportServiceMilestones[firstIndex - 1];
        this.rowDataAirportServiceMilestones[firstIndex - 1] = temp;
        this.updateOrderProp(firstIndex - 1);
      }
    },
    moveDown() {
      // get the index of the selected row
      const lastIndex = this.rowDataAirportServiceMilestones.findIndex(item => item === this.selectedRowsAirportServiceMilestones[0]);
      // move down the selected row by swapping it with the next row
      if (lastIndex < this.rowDataAirportServiceMilestones.length - 1) {
        const temp = this.rowDataAirportServiceMilestones[lastIndex];
        this.rowDataAirportServiceMilestones[lastIndex] = this.rowDataAirportServiceMilestones[lastIndex + 1];
        this.rowDataAirportServiceMilestones[lastIndex + 1] = temp;
        this.updateOrderProp(lastIndex + 1);
      }
    },
    // update the order prop on rowDataAirportServiceMilestones
    updateOrderProp(selectedRow) {
      const auxRowDataAirportServiceMilestones = [];
      this.rowDataAirportServiceMilestones.forEach((item, index) => {
        auxRowDataAirportServiceMilestones[index] = { ...item, order: index + 1 };
      });
      this.rowDataAirportServiceMilestones = auxRowDataAirportServiceMilestones;
      this.gridOptionsAirportServiceMilestones.api.setRowData(this.rowDataAirportServiceMilestones);
      if (selectedRow) {
        this.gridOptionsAirportServiceMilestones.api.forEachNode(node => (node.rowIndex === selectedRow ? 0 : node.setSelected(true)));
      }
      this.gridOptionsAirportServiceMilestones.api.refreshCells();
      this.$emit('AirportServiceMilestones-updated', this.rowDataAirportServiceMilestones);
    },
    handleWindowSizeChange() {
      this.gridOptionsMilestones.api.sizeColumnsToFit();
      this.gridOptionsAirportServiceMilestones.api.sizeColumnsToFit();
    },
    emitValue() {
      this.$emit('AirportServiceMilestones-updated', this.rowDataAirportServiceMilestones);
    },
  },
  mounted() {
    this.loadAirportActiveMilestones();
    this.handleWindowSizeChange();
    window.addEventListener('resize', this.handleWindowSizeChange);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  },
};
</script>
