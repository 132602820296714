<template>
  <transition name="resize">
    <div v-if="show" class="mobileNewMessage">
      <v-layout row wrap class="item elevation-0">
        <v-flex xs12>
          <v-form ref="form" v-model="validForm">
            <v-list class="pa-2 full-width grey lighten-4">
              <v-list-tile-content>
                <message-type-select :label="this.$i18n.t('messages.type')" v-model="message.typeId"
                  @input-element="setType" :rules="[rules.required]"></message-type-select>
              </v-list-tile-content>
            </v-list>
            <v-divider />
            <v-list class="pa-2 full-width grey lighten-4">
              <v-list-tile-content>
                <avinet-user-selector :label="this.$i18n.t('messages.source')" v-model="message.source"
                  :rules="[rules.required]" />
              </v-list-tile-content>
            </v-list>
            <v-divider />
            <v-list class="pa-2 full-width grey lighten-4">
              <v-list-tile-content>
                <avinet-message-destination-selector :label="this.$i18n.t('messages.destination')"
                  :source-filter="message.source" :type-message-filter="message.typeId"
                  :airline-filter="flightComposition.airlineId" :airport-filter="flightComposition.airport1Id"
                  :aircraft-type-filter="flightComposition.aircraftTypeId" v-model="message.destination"
                  :rules="[rules.required]" />
              </v-list-tile-content>
            </v-list>
            <v-divider />
            <v-container fluid class="pa-2 grey lighten-4">
              <v-flex xs12>
                <h4 v-if="type">{{ type.description }}</h4>
                <v-textarea class="hide-label cell-wrap-text-multiline" :label="this.$i18n.t('messages.content')"
                  v-model="message.content" rows="5" auto-grow :rules="[rules.required]"></v-textarea>
              </v-flex>
            </v-container>
          </v-form>
        </v-flex>
      </v-layout>
      <v-card-actions class="pa-2 grey lighten-4">
        <v-spacer></v-spacer>
        <v-btn color="primary" large flat @click.native="close">
          <v-icon class="mr-2">close</v-icon>
          {{ $t("common.cancel") }}</v-btn>
        <v-btn color="primary" large depressed @click.native="send" :disabled="loadingGlobal || !validForm">
          <v-icon class="mr-2">send</v-icon>
          {{
            $t("messages.sender.send") }}</v-btn>
      </v-card-actions>
      <v-divider />
    </div>
  </transition>
</template>
<script>
import AvinetMessageDestinationSelector from '../../messages/AvinetMessageDestinationSelector.vue';
import AvinetUserDestinationSelector from '../../messages/AvinetUserDestinationSelector.vue';
import AvinetUserSelector from '../../messages/AvinetUserSelector.vue';
import MessageTypeSelect from '../../messages/MessageTypeSelect.vue';
import FlightMessage from '../../messages/model/flightMessage';
import FlightMessageTemplateEngine from '../../messages/model/flightMessageTemplateEngineMobile';
import { REQUEST_ERROR, REQUEST_START, REQUEST_SUCCESS } from '../../../store/store';
import { NOTIFICATION_SHOW } from '../../../store/modules/notifications';
import { GET_AVINET_USERS } from '../../../store/modules/avinetUser';

export default {
  name: 'mobile-new-message',
  components: {
    MessageTypeSelect,
    AvinetUserSelector,
    AvinetUserDestinationSelector,
    AvinetMessageDestinationSelector,
  },
  props: {
    visible: { type: Boolean, required: true },
    flightId: { required: true },
    flightComposition: { type: Object, required: true },
  },
  data() {
    return {
      message: null,
      type: null,
      validForm: false,
      rules: {
        required: v => !!v || this.$i18n.t('common.fieldRequired'),
      },
      destinationRules: [
        v => (!!v && v.length > 0) || this.$i18n.t('common.fieldRequired'),
        (emailList) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;
          if (typeof emailList !== 'undefined' && Array.isArray(emailList)) {
            let result = true;
            emailList.forEach((email) => {
              result = result ? pattern.test(email.user || email) : result;
            });
            return result || this.$i18n.t('configuration.invalidEmail');
          }
          return true;
        },
      ],
    };
  },
  computed: {
    show: {
      get() {
        return this.visible;
      },
      set(showObject) {
        if (!showObject.show) {
          this.$emit('close', showObject.reload);
        }
      },
    },
    isTenantSetted() {
      return this.$store.getters.isTenantSetted;
    },
    currentTenant() {
      return this.isTenantSetted ? this.$store.state.tenant.currentTenant : {};
    },
    avinetUsers() {
      return this.$store.getters.getAvinetUsers;
    },
    userAuth() {
      return this.$store.getters.user;
    },
  },
  watch: {
    avinetUsers: {
      handler() {
        this.setMessageSource();
      },
    },
  },
  methods: {
    setType(type) {
      this.type = type;
      if (this.type) {
        this.message.typeCode = type.code;
        try {
          this.message.content = FlightMessageTemplateEngine.applyTemplateToFlightMobile(this.type.template, this.flightComposition, this.currentTenant, this.userAuth);
        } catch (e) {
          console.log('error: ', e); // eslint-disable-line no-console
        }
      }
    },
    close(reload) {
      this.show = {
        show: false,
        reload,
      };
    },
    send() {
      if (this.$refs.form.validate()) {
        this.requestInit();
        this.$http.put(`messages/flights/${this.flightId}/send`, this.message)
          .then(resp => this.requestSuccessCallback(this, resp))
          .catch(error => this.requestErrorCallback(this, error));
      }
    },
    requestInit() {
      this.$store.commit(REQUEST_START);
    },
    requestSuccessCallback: (context) => {
      context.$store.commit(REQUEST_SUCCESS);
      context.$store.commit(NOTIFICATION_SHOW, {
        message: context.$i18n.t('messages.messageSentMessage'),
        color: 'success',
      });
      context.close(true);
    },
    requestErrorCallback: (context, err) => {
      context.$store.commit(REQUEST_ERROR, err);
      context.$store.commit(NOTIFICATION_SHOW, {
        message: context.$i18n.t('notifications.errorNotification'),
        color: 'error',
      });
    },
    getAvinetUsers() {
      return this.$store.dispatch(GET_AVINET_USERS);
    },
    setMessageSource() {
      this.message.source = this.avinetUsers.filter(avinetUser => avinetUser.tenantId === this.currentTenant.id && avinetUser.defaultUser).map(au => au.user).shift();
    },
  },
  created() {
    this.getAvinetUsers();
    this.message = FlightMessage.createFlightMessageFromFlightId(this.flightId);
    this.setMessageSource();
  },
};
</script>
<style>

.avinetMessageDestinationSelector .v-select-list .v-list__tile ,.avinetUserSelector .v-select-list .v-list__tile , .messageType .v-select-list .v-list__tile {
  font-size: 24px;
  font-weight: 400;
  height: 68px;
  margin: 0;
  padding: 0 16px;
}


.mobileNewMessage .v-select .v-label,
.mobileNewMessage .v-text-field .v-label {
  font-size: 18px;
  font-weight: bold;
  line-height: 18px;
  overflow: visible;
}


.mobileNewMessage .hide-label label {
  display: none;
}

.mobileNewMessage .full-width .v-list__tile__content :only-child {
  width: 100%;
  height: 50px;
}

.mobileNewMessage .v-messages__message {
  font-size: 18px;
}

.mobileNewMessage .v-list {
  height: 100px;
}

.mobileNewMessage .v-text-field .v-label--active {
  max-width: 133%;
  -webkit-transform: translateY(-18px) scale(0.75);
  transform: translateY(-18px) scale(1);
}

.mobileNewMessage input[type="text"],
.mobileNewMessage textarea,
.mobileNewMessage .v-select__slot {
  font-size: 18px;
  margin-top: -20px;
}

.mobileNewMessage .v-chip__close>.v-icon {
  color: inherit !important;
  font-size: 35px;
  cursor: pointer;
  opacity: 0.5;
}

.mobileNewMessage TEXTAREA {
  height: 350px !important;
  text-wrap: nowrap;
  margin-top: 0px;
}

.mobileNewMessage .v-autocomplete:not(.v-input--is-disabled).v-select.v-text-field input {
  margin: auto;
}

.mobileNewMessage .v-chip__content {
  padding: 0 4px 0 12px;
  font-size: 18px;
  font-weight: bold;
}

.mobileNewMessage .v-chip {
  margin-top: 10px;
}

.mobileNewMessage .v-textarea--auto-grow textarea {
  overflow: scroll;
}

.mobileNewMessage .v-textarea {
  margin-top: 0px;
}
.dark-mode .mobileNewMessage .grey.lighten-4 {
    background-color: #303030 !important;
    border-color: #303030 !important;
}

/*scroll bar dark mode*/
.dark-mode .mobileNewMessage .v-textarea--auto-grow textarea::-webkit-scrollbar {
    width: 10px;
    background-color: #444;
}
.dark-mode .mobileNewMessage .v-textarea--auto-grow textarea::-webkit-scrollbar-corner {
    background-color: #444;
}
.dark-mode .mobileNewMessage .v-textarea--auto-grow textarea::-webkit-scrollbar-thumb {
    background-color: #666;
}


/* ANIMATION */
.resize-enter-active,
.resize-leave-active {
  transition: all 0.3s ease;
}

.resize-enter,
.resize-leave-to {
  margin-top: -45%;
  transform: scaleY(0.1);
  opacity: 0;
}
</style>
