import ConnectedFlightsFactory from '../connectedFlightsFactory';
import { JSUtils } from '../../js/JSUtils';

export default class IntegrationResult {
  constructor(
    tenant,
    ts,
    success,
    traces,
  ) {
    this.tenant = tenant;
    this.ts = ts;
    this.success = success;
    this.traces = traces;
  }

  clone() {
    return ConnectedFlightsFactory.mapConnectedFlights(JSON.parse(JSON.stringify(this)));
  }

  isPropertyChanged(property, isEqualFunction) {
    const pathArray = property.split('.');
    if (!this.old || !JSUtils.reducePathArray(pathArray, this.old)) {
      return false;
    }
    const newVal = JSUtils.reducePathArray(pathArray, this);
    const oldVal = JSUtils.reducePathArray(pathArray, this.old);

    console.log(`Comparing ${newVal}:${oldVal}`); // eslint-disable-line no-console

    return isEqualFunction === undefined ? newVal !== oldVal : (newVal !== null && oldVal !== null && !isEqualFunction(newVal, oldVal));
  }

  getOldValue(property, getter) {
    if (!this.old) {
      return null;
    }

    const val = JSUtils.reducePathArray(property.split('.'), this.old);
    return getter === undefined ? val : getter(val);
  }
}
