<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div v-if="tenants">
    <entity-configuration-list
      :entity-name="this.$i18n.t('configuration.user')"
      entity-icon="person"
      :framework-components="frameworkComponents" :column-defs="columnDefs" :items="users"
      @confirm-edit-item="editConfirm"
      @confirm-delete-item="deleteConfirm"
    >
      <template v-slot:modal-content="{ itemEdit }">
        <user-edit-card-content :item="itemEdit" />
      </template>
    </entity-configuration-list>
  </div>
</template>
<script>
import EntityConfigurationList from '../common/EntityConfigurationList.vue';
import UserEditCardContent from './UserEditCardContent.vue';
import {
  GET_USERS, USER_CREATE, USER_DELETE, USER_UPDATE,
} from '../../../store/modules/user/userCatalog';
import AgGridIconCellRenderer from '../../ag-grid/cell-renderers/AgGridIconCellRenderer.vue';
import AgGridTenantsChipsCellRenderer from '../../ag-grid/cell-renderers/AgGridTenantsChipsCellRenderer.vue';
import { TENANT_LIST } from '../../../store/modules/tenant';

export default {
  name: 'user-list',
  components: {
    EntityConfigurationList,
    UserEditCardContent,
  },
  data() {
    return {
      frameworkComponents: {
        AgGridIconCellRenderer,
        AgGridTenantsChipsCellRenderer,
      },
      columnDefs: [
        {
          field: 'firstName',
          headerValueGetter: () => this.$i18n.t('configuration.name'),
        },
        {
          field: 'lastName',
          headerValueGetter: () => this.$i18n.t('configuration.lastName'),
          sort: 'asc',
        },
        {
          field: 'email',
          headerValueGetter: () => this.$i18n.t('configuration.email'),
        },
        {
          field: 'agentType',
          headerValueGetter: () => this.$i18n.t('configuration.handlingAgent'),
          valueGetter: params => params.data.agentType.name,
        },
        {
          field: 'serviceAgent',
          headerValueGetter: () => this.$i18n.t('configuration.serviceAgent'),
          valueGetter: params => (params.data.serviceAgent ? params.data.serviceAgent.name : []),
        },
        {
          field: 'tenantAuthorities',
          headerValueGetter: () => this.$i18n.t('configuration.tenantAuthorities'),
          valueGetter: (params) => {
            const tenants = Array.from(new Map(Object.entries(params.data.tenantAuthorities)).keys());
            if (params.data.authorities.length > 0) {
              tenants.unshift('GLOBAL');
            }
            return tenants;
          },
          cellRendererFramework: AgGridTenantsChipsCellRenderer,
          cellRendererParams: {
            tenants: () => this.tenants,
          },
        },
        {
          field: 'accountAuthenticated',
          headerValueGetter: () => this.$i18n.t('configuration.accountAuthenticated'),
          cellRendererFramework: AgGridIconCellRenderer,
          cellRendererParams: {
            icon: 'done',
          },
        },
      ],
    };
  },
  computed: {
    users() {
      return this.$store.getters.getUsers;
    },
    tenants() {
      return this.$store.getters.getTenant;
    },
  },
  methods: {
    getUsers() {
      this.$store.dispatch(GET_USERS);
    },
    deleteConfirm(item) {
      this.$store.dispatch(USER_DELETE, item.login);
    },
    editConfirm(item) {
      this.$store.dispatch(item.login ? USER_UPDATE : USER_CREATE, item);
    },
    getTenants() {
      this.$store.dispatch(TENANT_LIST);
    },
  },
  created() {
    this.getTenants();
    this.getUsers();
  },
};
</script>
