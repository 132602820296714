import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VLayout,{attrs:{"justify-center":""}},[_c(VDialog,{attrs:{"persistent":"","max-width":"290"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"headline"},[_vm._v("Are you sure you want to delete this?")]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"flat":"","color":"primary"},on:{"click":_vm.reject}},[_vm._v("Cancel")]),_c(VBtn,{attrs:{"color":"primary","depressed":""},on:{"click":_vm.confirm}},[_vm._v("Agree")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }