export default class Contingency {
  constructor(id, managerName, supportName, managerPosition, supportPosition, flightId, flightTripNumber, flightDate, airportOriginId, airportOriginIataCode, airportDestinationId, airportDestinationIataCode, airlineId, airlineCode, scheduledTime, bestTime, passengers, route, closedDate, status, record, invoiced) {
    this.id = id;
    this.managerName = managerName;
    this.supportName = supportName;
    this.managerPosition = managerPosition;
    this.supportPosition = supportPosition;
    this.flightId = flightId;
    this.flightTripNumber = flightTripNumber;
    this.flightDate = flightDate;
    this.airportOriginId = airportOriginId;
    this.airportOriginIataCode = airportOriginIataCode;
    this.airportDestinationId = airportDestinationId;
    this.airportDestinationIataCode = airportDestinationIataCode;
    this.airlineId = airlineId;
    this.airlineCode = airlineCode;
    this.scheduledTime = scheduledTime;
    this.bestTime = bestTime;
    this.passengers = passengers;
    this.route = route;
    this.closedDate = closedDate;
    this.status = status;
    this.record = record;
    this.invoiced = invoiced;
  }

  flightNo() {
    return this.airlineCode ? `${this.airlineCode}${this.flightTripNumber}` : '';
  }

  static createEmptyContingency() {
    return new Contingency();
  }

  setChildPropertyValue(property, value) {
    if (!property.constructor === Array) {
      throw Error('pathArray has to be an Array');
    }

    const schema = this; // a moving reference to internal objects within obj

    schema[property] = value;
  }

  getChildPropertyValue(property) {
    if (!property.constructor === Array) {
      throw Error('pathArray has to be an Array');
    }

    const schema = this; // a moving reference to internal objects within obj

    return schema[property];
  }
}
