<script>
import Vue from 'vue';
import { JSUtils } from '../../../shared/js/JSUtils';
import { REQUEST_ERROR, REQUEST_START, REQUEST_SUCCESS } from '../../../../store/store';
import { NOTIFICATION_SHOW } from '../../../../store/modules/notifications';

import Arrowable from '../../../mixins/Arrowable.vue';
import AgGridParentCellEditor from './AgGridParentCellEditor.vue';
import { TALLYING_CASH_UPDATE } from '../../../../store/modules/ticketingReports/tallyingCash';

export default Vue.extend({
  mixins: [AgGridParentCellEditor, Arrowable],
  data() {
    return {
      tallyingCash: null,
      module: null,
    };
  },
  methods: {
    getTallyingCashPropertyName() {
      return this.params.column.colId.split('.').slice(-1).join('.');
    },
    saveForValueOrAllSelectedTallyingCash(action, field, callback) {
      const selectedRows = this.getSelectedRowsOrCurrentRow();
      selectedRows.forEach((s) => {
        action(s);
      });

      this.saveTallyingCash(selectedRows, field, callback);
    },
    saveForValueOrTallyingCash(action, field, callback) {
      const row = [];
      row.push(this.params.node.data);
      row.forEach((s) => {
        action(s);
      });

      this.saveTallyingCash(row, field, callback);
    },
    saveTallyingCash(selectedRows, field, callback) {
      this.$store.dispatch(TALLYING_CASH_UPDATE, {
        tallyingCashList: selectedRows,
        field,
      }).then(() => {
        this.params.api.refreshCells({
          force: true,
          columns: [this.params.column],
        });
        if (callback && JSUtils.isFunction(callback)) {
          callback();
        }
      }).catch(error => this.requestErrorCallback(this, error));
    },
    requestInit() {
      this.$store.commit(REQUEST_START);
    },
    requestSuccessCallback: (context) => {
      const i18n = this.i18n();
      context.$store.commit(REQUEST_SUCCESS);
      context.$store.commit(NOTIFICATION_SHOW, {
        message: i18n.t('notifications.tallyingCashUpdated'),
        color: 'success',
      });
    },
    requestErrorCallback: (context, err) => {
      const i18n = this.i18n();
      context.$store.commit(REQUEST_ERROR, err);
      context.$store.commit(NOTIFICATION_SHOW, {
        message: i18n.t('notifications.errorNotification'),
        color: 'error',
      });
    },
    i18n() {
      if (this.$i18n) {
        return this.$i18n;
      }
      if (this.$root && this.$root.$i18n) {
        return this.$root.$i18n;
      }
      return this.$i18n;
    },
  },
  computed: {

  },
  created() {
    this.module = this.params.context.module;
    if (JSUtils.isFunction(this.params.node.data.clone)) {
      this.tallyingCash = this.params.node.data.clone();
    } else {
      // TODO Clone value if it's not a TallyingCash
      this.tallyingCash = this.params.node.data;
      console.warn(`The following object should be cloned: ${this.params.node.data.constructor.name}`); // eslint-disable-line no-console
    }
  },
});
</script>
