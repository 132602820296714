<template>
  <v-container>
    <v-expansion-panel v-model="expandPanel" expand>
      <v-expansion-panel-content class="exp-header" >
        <template v-slot:actions>
          <v-icon color="white">mdi-menu-down</v-icon>
        </template>
        <template v-slot:header>
          <div>
            <h4 class="headline font-weight-light"><v-icon dark left>bookmarks</v-icon>{{$t("contingencies.contingencyInfo")}}</h4>
          </div>
        </template>
        <v-card :dark="this.$vuetify.dark">
          <v-card-text>
            <v-container grid-list-md text-xs-center fluid>
              <label class="text-lg-justify font-weight-bold">{{$t("commonForm.flightInformation")}}</label>
              <v-layout row wrap justify-center>
                <v-flex xs2 v-show="contingency.flightId">
                  <span class="primary--text">{{$t("common.airline")}}</span>
                  <div>{{ contingency.airlineCode }}</div>

                  <span class="primary--text">{{$t("common.flight")}}</span>
                  <div class="mb-2">{{ contingency.flightTripNumber }}</div>
                </v-flex>

                <v-flex xs2>
                  <span class="primary--text">{{$t("contingencies.originAirport")}}</span>
                  <div class="mb-2">{{ getAirport(contingency.airportOriginIataCode) }}</div>

                  <span class="primary--text" v-show="contingency.flightId">{{$t("contingencies.destinationAirport")}}</span>
                  <div v-show="contingency.flightId">{{ getAirport(contingency.airportDestinationIataCode) }}</div>
                </v-flex>

                <v-flex xs2>
                  <span class="primary--text">{{ contingency.flightId ? $t("contingencies.flightDate") : 'Dia de apertura'}}</span>
                  <div class="mb-2">{{ this.$moment(contingency.scheduledTime ? contingency.scheduledTime : contingency.flightDate).format('DD/MM/YYYY') }}</div>

                  <span class="primary--text" v-show="contingency.flightId">{{$t("contingencies.affectedRoute")}}</span>
                  <div v-show="contingency.flightId">{{ getRoute(contingency) }}</div>
                </v-flex>

                <v-flex xs2 v-show="contingency.flightId">
                  <span v-if="contingency.airportOriginId !== -1" class="primary--text">{{$t("contingencies.departureTime")}}</span>
                  <span v-else-if="contingency.airportDestinationId !== -1" class="primary--text">{{$t("contingencies.arrivalTime")}}</span>
                  <div class="mb-2">{{ this.$moment(contingency.scheduledTime).format('HH:MM') }}</div>

                  <span v-if="contingency.airportOriginId !== -1" class="primary--text">{{$t("contingencies.realDepartureTime")}}</span>
                  <span v-else-if="contingency.airportDestinationId !== -1" class="primary--text">{{$t("contingencies.realArrivalTime")}}</span>
                  <div class="mb-2">{{ contingency.bestTime != null ? this.$moment(contingency.bestTime).format('HH:MM') : null }}</div>
                </v-flex>

                <v-flex xs2 v-show="contingency.flightId">
                  <span class="primary--text">{{$t("contingencies.pax")}}</span>
                  <div>{{ contingency.passengers }}</div>
                </v-flex>
              </v-layout>
            </v-container>
            <v-container grid-list-md text-xs-center fluid>
              <v-layout row wrap justify-center>
                <v-flex xs5>
                    <v-text-field v-model="contingency.managerName" :label="this.$i18n.t('contingencies.managerName')"  box :readonly="!hasPermissions(rolePermission)"></v-text-field>
                  <v-text-field v-model="contingency.supportName" :label="this.$i18n.t('contingencies.supportName')"  box :readonly="!hasPermissions(rolePermission)"></v-text-field>
                </v-flex>
                <v-flex xs5>
                  <v-text-field v-model="contingency.managerPosition" :label="this.$i18n.t('contingencies.managerPosition')"  box :readonly="!hasPermissions(rolePermission)"></v-text-field>
                  <v-text-field v-model="contingency.supportPosition" :label="this.$i18n.t('contingencies.supportPosition')"  box :readonly="!hasPermissions(rolePermission)"></v-text-field>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
        </v-card>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-container>
</template>

<script>

import DateInput from '../../ui/DateInput.vue';
import Saveable from '../../mixins/Saveable.vue';
import RolePermissions from '../../mixins/RolePermissions.vue';
import Contingency from '../model/contingency';

export default {
  name: 'contingency-report-form',
  mixins: [Saveable, RolePermissions],
  components: {
    DateInput,
  },
  props: {
    editedContingency: { type: Contingency, required: true },
  },
  computed: {
    rolePermission() {
      return `${this.$route.meta.role}_WR`;
    },
  },
  data() {
    return {
      expandPanel: [true],
      contingency: this.editedContingency,
    };
  },
  watch: {
    editedContingency: {
      handler(newContingency) {
        this.contingency = newContingency;
      },
      deep: true,
    },
  },
  methods: {
    save() {
      this.$emit('contingency-update', this.contingency);
    },
    getAirport(airportIata) {
      return airportIata != null ? airportIata : this.$store.state.tenant.currentTenant.name;
    },
    getRoute(contingency) {
      return `${contingency.airportOriginIataCode != null ? contingency.airportOriginIataCode : this.$store.state.tenant.currentTenant.name} ${contingency.airportDestinationIataCode != null ? contingency.airportDestinationIataCode : this.$store.state.tenant.currentTenant.name}`;
    },
    getBase() {
      return this.$store.state.tenant.currentTenant.name;
    },
  },
};
</script>

<style scoped lang="scss">
.exp-header {
  background: #FF4021 !important;
  color: white !important;
  border-bottom: 1px solid white;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  font-size: large;
}
</style>
