import { JSUtils } from '../../shared/js/JSUtils';

export default class FlightTimesInformation {
  constructor(
    id,
    estimatedTime,
    landTime,
    blockTime,
    ctotTime,
    scheduledBoardingTime,
    scheduledBoardingEndTime,
    startedBoardingTime,
    completedBoardingTime,
    bestTime,
    cancellationTime,
    tobtTime,
    tsatTime,
  ) {
    this.id = id;
    this.estimatedTime = estimatedTime;
    this.landTime = landTime;
    this.blockTime = blockTime;
    this.ctotTime = ctotTime;
    this.scheduledBoardingTime = scheduledBoardingTime;
    this.scheduledBoardingEndTime = scheduledBoardingEndTime;
    this.startedBoardingTime = startedBoardingTime;
    this.completedBoardingTime = completedBoardingTime;
    this.bestTime = bestTime;
    this.cancellationTime = cancellationTime;
    this.tobtTime = tobtTime;
    this.tsatTime = tsatTime;
  }

  static get createEmptyFlightTimesInformation() {
    return new FlightTimesInformation();
  }

  static createFlightTimesInformationFromData(o) {
    if (!o) {
      return this.createEmptyFlightTimesInformation;
    }

    return JSUtils.cast(o, FlightTimesInformation);
  }
}
