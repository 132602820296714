/* eslint no-param-reassign: "off" */
/* eslint no-confusing-arrow: "off" */
/* eslint no-nested-ternary: "off" */

import { HTTP } from '../../../http';
import { REQUEST_ERROR, REQUEST_START, REQUEST_SUCCESS } from '../../store';
import { NOTIFICATION_SHOW } from '../notifications';
import i18n from '../../../plugins/i18n';

// actions names
export const GET_AIRPORT_RAMP_SERVICE = 'GET_AIRPORT_RAMP_SERVICE';
export const AIRPORT_RAMP_SERVICE_DELETE = 'AIRPORT_RAMP_SERVICE_DELETE';
export const AIRPORT_RAMP_SERVICE_CREATE = 'AIRPORT_RAMP_SERVICE_CREATE';
export const AIRPORT_RAMP_SERVICE_UPDATE = 'AIRPORT_RAMP_SERVICE_UPDATE';
export const AIRPORT_RAMP_SERVICE_DISABLE = 'AIRPORT_RAMP_SERVICE_DISABLE';
export const AIRPORT_RAMP_SERVICE_ENABLE = 'AIRPORT_RAMP_SERVICE_ENABLE';
export const GET_AIRPORT_RAMP_SERVICE_BY_SERVICE_AGENT_OF_USER = 'GET_AIRPORT_RAMP_SERVICE_BY_SERVICE_AGENT_OF_USER';

// mutations
export const CHANGE_AIRPORT_RAMP_SERVICE_SELECTION = 'CHANGE_AIRPORT_RAMP_SERVICE_SELECTION';
export const GET_AIRPORT_RAMP_SERVICE_SUCCESS = 'GET_AIRPORT_RAMP_SERVICE_SUCCESS';
export const GET_AIRPORT_RAMP_SERVICE_REQUESTED = 'GET_AIRPORT_RAMP_SERVICE_REQUESTED';
export const UPDATE_AIRPORT_RAMP_SERVICE_SUCCESS = 'UPDATE_AIRPORT_RAMP_SERVICE_SUCCESS';
export const CHANGED_TENANT_AIRPORT_RAMP_SERVICE = 'CHANGED_TENANT_AIRPORT_RAMP_SERVICE';
export const GET_AIRPORT_RAMP_SERVICE_BY_SERVICE_AGENT_OF_USER_REQUESTED = 'GET_AIRPORT_RAMP_SERVICE_BY_SERVICE_AGENT_OF_USER_REQUESTED';
export const GET_AIRPORT_RAMP_SERVICE_BY_SERVICE_AGENT_OF_USER_SUCCESS = 'GET_AIRPORT_RAMP_SERVICE_BY_SERVICE_AGENT_OF_USER_SUCCESS';
export const CHANGED_USER = 'CHANGED_USER';

// initial state
const storeState = {
  airportRampServices: [],
  airportRampServicesByServiceAgentOfUser: [],
  airportRampServiceRequested: false,
  airportRampServiceByServiceAgentOfUserRequested: false,
  selectedAirportRampServices: [],
};

// getters
const storeGetters = {
  getAirportRampServices: state => state.airportRampServices.sort((a, b) => (a.serviceName > b.serviceName) ? 1 : ((b.serviceName > a.serviceName) ? -1 : 0)),
  getActiveAirportRampServices: state => state.airportRampServices.filter(elem => !elem.deleted && !elem.serviceDeleted).sort((a, b) => (a.serviceName > b.serviceName) ? 1 : ((b.serviceName > a.serviceName) ? -1 : 0)),
  getActiveAirportRampServicesByServiceAgentOfUser: state => state.airportRampServicesByServiceAgentOfUser.filter(elem => !elem.deleted && !elem.serviceDeleted).sort((a, b) => (a.serviceName > b.serviceName) ? 1 : ((b.serviceName > a.serviceName) ? -1 : 0)),
  getAirportRampServicesSelected: state => state.selectedAirportRampServices,
  hasSelectedAirportRampService: state => state.selectedAirportRampServices.length > 0,
  getAirportRampServiceById: state => id => state.airportRampServices.find(a => a.id === id),
};

// actions
const storeActions = {
  [GET_AIRPORT_RAMP_SERVICE]: ({ commit }) => {
    // if (state.airportRampServiceRequested) {
    //   return;
    // }
    commit(REQUEST_START);
    commit(GET_AIRPORT_RAMP_SERVICE_REQUESTED);
    HTTP.get('airport-ramp-services')
      .then((resp) => {
        commit(REQUEST_SUCCESS);
        commit(GET_AIRPORT_RAMP_SERVICE_SUCCESS, resp.data);
      })
      .catch((err) => {
        commit(REQUEST_ERROR, err);
      });
  },
  [GET_AIRPORT_RAMP_SERVICE_BY_SERVICE_AGENT_OF_USER]: ({ commit }) => {
    // if (state.airportRampServiceByServiceAgentOfUserRequested) {
    //  return;
    // }
    commit(REQUEST_START);
    commit(GET_AIRPORT_RAMP_SERVICE_BY_SERVICE_AGENT_OF_USER_REQUESTED);
    HTTP.get('airport-ramp-services/service-agent')
      .then((resp) => {
        commit(REQUEST_SUCCESS);
        commit(GET_AIRPORT_RAMP_SERVICE_BY_SERVICE_AGENT_OF_USER_SUCCESS, resp.data);
      })
      .catch((err) => {
        commit(REQUEST_ERROR, err);
      });
  },

  [AIRPORT_RAMP_SERVICE_CREATE]: ({ commit, dispatch }, airportRampServices) => {
    commit(REQUEST_START);
    return HTTP.post('airport-ramp-services', airportRampServices)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(UPDATE_AIRPORT_RAMP_SERVICE_SUCCESS);
        dispatch(GET_AIRPORT_RAMP_SERVICE);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.airportRampServicesCreate'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },

  [AIRPORT_RAMP_SERVICE_UPDATE]: ({ commit, dispatch }, airportRampServices) => {
    commit(REQUEST_START);
    return HTTP.put('airport-ramp-services', airportRampServices)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(UPDATE_AIRPORT_RAMP_SERVICE_SUCCESS);
        dispatch(GET_AIRPORT_RAMP_SERVICE);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.airportRampServicesUpdate'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
  [AIRPORT_RAMP_SERVICE_DELETE]: ({ commit, dispatch }, id) => {
    commit(REQUEST_START);
    return HTTP.delete(`airport-ramp-services/${id}`)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(UPDATE_AIRPORT_RAMP_SERVICE_SUCCESS);
        dispatch(GET_AIRPORT_RAMP_SERVICE);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.airportRampServicesDelete'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
  [AIRPORT_RAMP_SERVICE_DISABLE]: ({ commit, dispatch }, id) => {
    commit(REQUEST_START);
    return HTTP.patch(`/airport-ramp-services/disable/${id}`)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(UPDATE_AIRPORT_RAMP_SERVICE_SUCCESS);
        dispatch(GET_AIRPORT_RAMP_SERVICE);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.airportRampServicesDisable'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
  [AIRPORT_RAMP_SERVICE_ENABLE]: ({ commit, dispatch }, id) => {
    commit(REQUEST_START);
    return HTTP.patch(`/airport-ramp-services/enable/${id}`)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(UPDATE_AIRPORT_RAMP_SERVICE_SUCCESS);
        dispatch(GET_AIRPORT_RAMP_SERVICE);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.airportRampServicesEnable'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
};

// mutations
const storeMutations = {
  [GET_AIRPORT_RAMP_SERVICE_SUCCESS]: (state, airportRampServices) => {
    state.airportRampServices = airportRampServices;
  },
  [GET_AIRPORT_RAMP_SERVICE_REQUESTED]: (state) => {
    state.airportRampServiceRequested = true;
  },
  [GET_AIRPORT_RAMP_SERVICE_BY_SERVICE_AGENT_OF_USER_SUCCESS]: (state, airportRampServices) => {
    state.airportRampServicesByServiceAgentOfUser = airportRampServices;
  },
  [GET_AIRPORT_RAMP_SERVICE_BY_SERVICE_AGENT_OF_USER_REQUESTED]: (state) => {
    state.airportRampServiceByServiceAgentOfUserRequested = true;
  },
  [UPDATE_AIRPORT_RAMP_SERVICE_SUCCESS]: (state) => {
    state.airportRampServiceRequested = false;
  },
  [CHANGE_AIRPORT_RAMP_SERVICE_SELECTION]: (state, selection) => {
    state.selectedAirportRampServices = selection;
  },
  [CHANGED_TENANT_AIRPORT_RAMP_SERVICE]: (state) => {
    state.airportRampServiceRequested = false;
    state.airportRampServiceByServiceAgentOfUserRequested = false;
  },
  [CHANGED_USER]: (state) => {
    state.airportRampServiceByServiceAgentOfUserRequested = false;
  },
};

export default {
  state: storeState,
  getters: storeGetters,
  actions: storeActions,
  mutations: storeMutations,
};
