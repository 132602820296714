<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <ag-grid-dialog-template-editor :is-dialog-open="isDialogOpen" @save="save" @cancel="cancel">
      <template v-slot:header>
        {{ $t("operations.passengerTimesModal.title") }} {{ flightNumber }}
      </template>

      <template v-slot:default>
        <v-container fluid grid-list-md class="pa-0" v-if="passageTimes">
          <v-card class="mb-2">
            <v-card-title class="pa-2">
              <v-icon left class="mr-1">airplane_ticket</v-icon>
              {{ $t('operations.passengerTimesModal.checkIn') }}
            </v-card-title>
            <v-card-text class="px-3 py-0">
              <v-layout row wrap>
                <PaxExpectedAndRealTimes :title="$t('operations.passengerTimesModal.start')"
                  :referenceDate="referenceDate"
                  :agreedTime.sync="passageTimes.checkInAgreedStartTime"
                  :realTime.sync="passageTimes.checkInStartTime"
                  :breakingReason.sync="passageTimes.selectedCheckInStartTimeBreakingReason"
                  :breakingReasons="passageTimes.checkInStartTimeBreakingReasons">
                </PaxExpectedAndRealTimes>
                <PaxExpectedAndRealTimes :title="$t('operations.passengerTimesModal.end')"
                  :referenceDate="referenceDate"
                  :agreedTime.sync="passageTimes.checkInAgreedEndTime"
                  :realTime.sync="passageTimes.checkInEndTime"
                  :breakingReason.sync="passageTimes.selectedCheckInEndTimeBreakingReason"
                  :breakingReasons="passageTimes.checkInEndTimeBreakingReasons">
                </PaxExpectedAndRealTimes>
              </v-layout>
            </v-card-text>
          </v-card>

          <v-card class="mb-2">
            <v-card-title class="pa-2">
              <v-icon left class="mr-1">stairs</v-icon>
              {{ $t('operations.passengerTimesModal.boarding') }}
            </v-card-title>
            <v-card-text class="px-3 py-0">
              <v-layout row wrap>
                <PaxExpectedAndRealTimes :title="$t('operations.passengerTimesModal.start')"
                  :referenceDate="referenceDate"
                  :agreedTime.sync="passageTimes.boardingAgreedStartTime"
                  :realTime.sync="passageTimes.boardingStartTime"
                  :breakingReason.sync="passageTimes.selectedBoardingStartTimeBreakingReason"
                  :breakingReasons="passageTimes.boardingStartTimeBreakingReasons">
                </PaxExpectedAndRealTimes>
                <PaxExpectedAndRealTimes :title="$t('operations.passengerTimesModal.end')"
                  :referenceDate="referenceDate"
                  :agreedTime.sync="passageTimes.boardingAgreedEndTime"
                  :realTime.sync="passageTimes.boardingEndTime"
                  :breakingReason.sync="passageTimes.selectedBoardingEndTimeBreakingReason"
                  :breakingReasons="passageTimes.boardingEndTimeBreakingReasons">
                </PaxExpectedAndRealTimes>
              </v-layout>
            </v-card-text>
          </v-card>
          <v-card>
            <v-card-title class="pa-2">
              <v-icon left class="mr-1">person</v-icon>
              {{ $t('operations.passengerTimesModal.boardingAgents') }}
            </v-card-title>
            <v-card-text class="pa-0">
              <v-layout row wrap class="pt-0 pb-2 px-0">
                <v-flex d-flex xs12 md6 flex-column class="px-4">
                  <v-text-field id="boardingAgent1" name="boardingAgent1" hide-details
                    v-model="passageTimes.boardingAgent1" :readonly="!isEditable"
                    :label="$t('operations.passengerTimesModal.boardingAgent1')"></v-text-field>
                </v-flex>
                <v-flex d-flex xs12 md6 flex-column class="px-4">
                  <v-text-field id="boardingAgent2" name="boardingAgent2" hide-details
                    v-model="passageTimes.boardingAgent2" :readonly="!isEditable"
                    :label="$t('operations.passengerTimesModal.boardingAgent2')"></v-text-field>
                </v-flex>
                <v-flex d-flex xs12 md6 flex-column class="px-4">
                  <v-text-field id="boardingAgent3" name="boardingAgent3" hide-details
                    v-model="passageTimes.boardingAgent3" :readonly="!isEditable"
                    :label="$t('operations.passengerTimesModal.boardingAgent3')"></v-text-field>
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
        </v-container>
      </template>
    </ag-grid-dialog-template-editor>
  </div>
</template>

<script>
import Vue from 'vue';
import AgGridDialogParentCellEditor from '../../../mixins/AgGridDialogParentCellEditor.vue';
import AgGridDialogTemplateEditor from '../../../templates/AgGridDialogTemplateEditor.vue';
import AgGridOptionsConfigurable from '../../../../mixins/AgGridOptionsConfigurable.vue';
import FlightPassengerTimesDTO from '../../../model/flightPassengerTimesDTO';
import TimeInput from '../../../../../ui/TimeInput.vue';
import { GET_FLIGHT_PAX_TIMES, UPDATE_FLIGHT_PAX_TIME } from '../../../../../../store/modules/flightPaxTimes/flightPaxTimesCatalog';
import PaxExpectedAndRealTimes from './PaxExpectedAndRealTimes.vue';

export default Vue.extend({
  mixins: [AgGridDialogParentCellEditor, AgGridOptionsConfigurable],
  components: {
    AgGridDialogTemplateEditor,
    TimeInput,
    PaxExpectedAndRealTimes,
  },
  computed: {
    passageTimes() {
      return this.$store.getters.getPassageTimes;
    },
    rolePermission() {
      return `${this.$route.meta.role}_WR`;
    },
  },
  data() {
    return {
      referenceDate: FlightPassengerTimesDTO.createFlightPassengerTimesDTO(this.flightId),
    };
  },
  methods: {
    getValue() {
      if (this.isSaving) {
        this.$store.dispatch(UPDATE_FLIGHT_PAX_TIME, { flightId: this.flightId, passageTimes: this.passageTimes });
      }
      return this.params.value;
    },
    isEditable() {
      return this.hasPermissions(this.rolePermission);
    },
  },
  created() {
    this.referenceDate = this.connectedFlights.getChildPropertyValue(this.flightBound, ['flight', 'scheduledDate']);
    this.$store.dispatch(GET_FLIGHT_PAX_TIMES, this.flightId);
  },
});
</script>
