import moment from 'moment';

export default class FlightMessage {
  constructor(
    id,
    read,
    ts,
    flightId,
    flightTripNumber,
    flightNumber,
    flightDate,
    flightScheduledTime,
    typeId,
    typeCode,
    content,
    source,
    destination,
  ) {
    this.id = id;
    this.read = read;
    this.ts = ts;
    this.flightId = flightId;
    this.flightTripNumber = flightTripNumber;
    this.flightDate = flightDate;
    this.flightScheduledTime = flightScheduledTime;
    this.typeId = typeId;
    this.typeCode = typeCode;
    this.content = content;
    this.source = source;
    this.destination = destination;
  }

  static createEmptyFlightMessage() {
    const flightMessage = new FlightMessage();
    flightMessage.read = false;
    return flightMessage;
  }

  static createFlightMessageFromFlightId(flightId) {
    const flightMessage = new FlightMessage();
    flightMessage.flightId = flightId;
    flightMessage.read = false;
    return flightMessage;
  }

  static createFlightMessagesFromData(data) {
    if (data && data.constructor === Array && data.length > 0) {
      return data.map(d => this.mapFlightMessage(d)).sort((item1, item2) => moment(item1.ts) - moment(item2.ts));
    }
    return [];
  }

  static mapFlightMessage(d) {
    const flightMessage = new FlightMessage();
    flightMessage.id = d.id;
    flightMessage.read = d.read;
    flightMessage.ts = d.ts;
    flightMessage.flightId = d.flightId;
    flightMessage.flightTripNumber = d.flightTripNumber;
    flightMessage.flightNumber = d.flightNumber;
    flightMessage.flightDate = d.flightDate;
    flightMessage.flightScheduledTime = d.flightScheduledTime;
    flightMessage.typeId = d.typeId;
    flightMessage.typeCode = d.typeCode;
    flightMessage.content = d.content;
    flightMessage.source = d.source;
    flightMessage.destination = d.destination;
    return flightMessage;
  }
}
