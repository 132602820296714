<template>
    <v-container fluid grid-list-xs class="contentData">
      <v-form ref="formOptions">
      <v-layout row justify-center>
        <v-flex xs2>
          <date-input v-char-length.date v-model="importOptions.fromDate" :locale="$i18n.locale" :label="this.$i18n.t('common.fromDate')" :rules="dateFromRules"></date-input>
        </v-flex>
        <v-flex xs2>
          <date-input v-char-length.date v-model="importOptions.toDate" :locale="$i18n.locale" :label="this.$i18n.t('common.toDate')" :rules="dateToRules"></date-input>
        </v-flex>
        <v-flex xs2>
          {{$t("schedulingImport.dateMinInFile")}}
          {{minDate}}
        </v-flex>
        <v-flex xs2>
          {{$t("schedulingImport.dateMaxInFile")}}
          {{maxDate}}
        </v-flex>
        <v-flex xs2 v-if="importParsedFile && importParsedFile.airlines">
          {{$t("schedulingImport.airlinesRead")}}
          <ul>
            <li v-for="(airline, index) in importParsedFile.airlines" :key="index">
              {{ airline }}
            </li>
          </ul>
        </v-flex>
      </v-layout>
      </v-form>
      <v-flex xs12 v-if="flightsWithErrors.length > 0">
        <div>
          <v-icon color="primary">mdi-alert</v-icon>
          <span class="font-weight-bold">{{$t("schedulingImport.flightTripNumberAlert")}}</span>
        </div>
        <ul class="trip">
         <li v-for="(tripNumber, index) in flightsWithErrors" :key="index" class="tripnumber">
            {{ tripNumber }}
         </li>
        </ul>
      </v-flex>

      <v-flex xs12 v-if="connectedFlightsStdBeforeSta.length > 0">
        <div>
          <v-icon color="primary">mdi-alert</v-icon>
          <span class="font-weight-bold">{{$t("schedulingImport.connectedFlightsStdBeforeStaAlert")}}</span>
        </div>
      </v-flex>
      <v-container fluid grid-list-md class="grid-duplicate-flights" v-if="connectedFlightsStdBeforeSta.length > 0">
        <v-data-table
          class="mt-3 elevation-1"
          hide-actions
          :headers="tableHeaders"
          :items="connectedFlightsStdBeforeSta"
        >
          <template slot="items" slot-scope="props">
            <td v-for="(header, index) in headers" :key="header + index">
              {{ props.item[header] }}
            </td>
          </template>
        </v-data-table>
      </v-container>
      <v-flex xs12 v-if="duplicatedFlights.length > 0">
        <div>
          <v-icon color="primary">mdi-alert</v-icon>
          <span class="font-weight-bold">{{$t("schedulingImport.duplicateAlert")}}</span>
        </div>
      </v-flex>
        <v-container fluid grid-list-md class="grid-duplicate-flights" v-if="duplicatedFlights.length > 0">
          <v-data-iterator
            :items="duplicatedFlights"
            item-key="name"
            :rows-per-page-items="rowsPerPageItems"
            :pagination.sync="paginationDuplicatedFlights"
            content-class="layout row wrap"
          >
              <v-flex slot="item" slot-scope="props" xs4>
                <v-card>
                  <v-card-title><h4>{{ props.item.date }} - {{ props.item.airline }} {{ props.item.tripNumber }} - {{ props.item.airport }}</h4></v-card-title>
                  <v-divider></v-divider>
                  <v-list dense>
                    <v-list-tile v-for="(flight, index) in getDuplicateFlights(props.item)" :key="index">
                      <v-list-tile-content>
                          <span>
                          <v-icon v-if="flight.bound==='INBOUND'">flight_land</v-icon>
                          <v-icon v-if="flight.bound==='OUTBOUND'">flight_takeoff</v-icon>
                          {{ flight.date }} {{ getFormattedTime(flight.scheduledTime) }} - {{ flight.airline }} {{ flight.tripNumber }} - {{ flight.airport }}
                        </span>
                      </v-list-tile-content>
                    </v-list-tile>
                  </v-list>
                </v-card>
              </v-flex>
          </v-data-iterator>
        </v-container>
      <v-flex xs12 v-if="possibleSameFlights.length > 0" >
        <div>
          <v-icon color="warning">mdi-alert</v-icon>
          <span class="font-weight-bold">{{$t("schedulingImport.sameAlert")}}</span>
        </div>
      </v-flex>
      <v-container fluid grid-list-md class="grid-duplicate-flights" v-if="possibleSameFlights.length > 0">
        <v-data-iterator
          :items="possibleSameFlights"
          item-key="name"
          :rows-per-page-items="rowsPerPageItems"
          :pagination.sync="pagination"
          content-class="layout row wrap"
        >
            <v-flex slot="item" slot-scope="props" xs4>
              <v-card>
                <v-card-title><h4>{{ props.item.date }} - {{ props.item.airline }} {{ props.item.tripNumber }} - {{ props.item.airport }}</h4></v-card-title>
                <v-divider></v-divider>
                <v-list dense>
                  <v-list-tile v-for="(flight, index) in getPossibleSameFlights(props.item)" :key="index">
                    <v-list-tile-content>
                        <span>
                        <v-icon v-if="flight.bound==='INBOUND'">flight_land</v-icon>
                        <v-icon v-if="flight.bound==='OUTBOUND'">flight_takeoff</v-icon>
                        {{ flight.date }} {{ getFormattedTime(flight.scheduledTime) }} - {{ flight.airline }} {{ flight.tripNumber }} - {{ flight.airport }}
                      </span>
                    </v-list-tile-content>
                  </v-list-tile>
                </v-list>
              </v-card>
            </v-flex>
        </v-data-iterator>
      </v-container>
      <v-flex xs12 v-if="flightsWithScheduledTimeEmpty.length > 0">
        <div>
          <v-icon color="primary">mdi-alert</v-icon>
          <span class="font-weight-bold">{{$t("schedulingImport.scheduledTimeAlert")}}</span>
        </div>
      </v-flex>
      <v-container fluid grid-list-md class="grid-duplicate-flights" v-if="flightsWithScheduledTimeEmpty.length > 0">
        <v-data-iterator
          :items="flightsWithScheduledTimeEmpty"
          item-key="name"
          :rows-per-page-items="rowsPerPageItems"
          :pagination.sync="pagination"
          content-class="layout row wrap"
        >
            <v-flex slot="item" slot-scope="props" xs4>
              <v-card>
                <v-list dense>
                  <v-list-tile v-for="(flight, index) in getPossibleSameFlights(props.item)" :key="index">
                    <v-list-tile-content>
                        <span>
                        <v-icon v-if="isFlightIn(props.item)">flight_land</v-icon>
                        <v-icon v-if="!isFlightIn(props.item)">flight_takeoff</v-icon>
                        {{ flight.date }} {{ getFormattedTime(flight.scheduledTime) }} - {{ flight.airline }} {{ flight.tripNumber }} - {{ flight.airport }}
                      </span>
                    </v-list-tile-content>
                  </v-list-tile>
                </v-list>
              </v-card>
            </v-flex>
        </v-data-iterator>
      </v-container>
      <v-flex xs12 v-if="possibleSameFlights.length > 0">
        <div>
          <v-checkbox
            color="primary"
            v-model="flightsCorrect"
            :label="this.$i18n.t('schedulingImport.sameFligths')"
            @change="isValidData"
          ></v-checkbox>
        </div>
      </v-flex>

    </v-container>


</template>
<script>

import AirlineSelect from '../../airline/AirlineSelect.vue';
import DateInput from '../../ui/DateInput.vue';
import SchedulingImportOptions from '../model/schedulingImportOptions';
import SchedulingImportParsedFile from '../model/schedulingImportParsedFile';
import { JSUtils as TimeUtils } from '../../shared/js/TimeUtils';

export default {
  name: 'scheduling-import-step-options-filter',
  components: {
    AirlineSelect,
    DateInput,
  },
  props: {
    importOptions: {
      type: SchedulingImportOptions,
      required: true,
    },
    importParsedFile: {
      type: SchedulingImportParsedFile,
    },
  },
  computed: {
    flightsWithErrors() {
      return this.importParsedFile ? this.importParsedFile.tripNumberWithErrors : [];
    },
    duplicatedFlights() {
      return this.importParsedFile ? this.importParsedFile.duplicateFlights : [];
    },
    possibleSameFlights() {
      return this.importParsedFile ? this.importParsedFile.possibleSameFlights : [];
    },
    connectedFlightsStdBeforeSta() {
      return this.importParsedFile ? this.importParsedFile.connectedFlightsStdBeforeSta : [];
    },
    headers() {
      return this.importParsedFile ? this.importParsedFile.headers : [];
    },
    tableHeaders() {
      return this.importParsedFile ? this.importParsedFile.headers.map(h => ({ text: h, value: h })) : [];
    },
    minDate() {
      return this.importParsedFile && this.importParsedFile.minDate ? this.$moment(this.importParsedFile.minDate).format('DD-MM-YYYY') : null;
    },
    maxDate() {
      return this.importParsedFile && this.importParsedFile.maxDate ? this.$moment(this.importParsedFile.maxDate).format('DD-MM-YYYY') : null;
    },
    flightsWithScheduledTimeEmpty() {
      return this.importParsedFile ? this.importParsedFile.flightsWithScheduledTimeEmpty : [];
    },
  },
  watch: {
    duplicatedFlights: {
      handler() {
        this.isValidData();
      },
      deep: true,
    },
    possibleSameFlights: {
      handler() {
        this.isValidData();
      },
      deep: true,
    },
    connectedFlightsStdBeforeSta: {
      handler() {
        this.isValidData();
      },
      deep: true,
    },
    flightsWithScheduledTimeEmpty: {
      handler() {
        this.isValidData();
      },
    },
  },
  data() {
    return {
      dateFromRules: [
        v => !!v || this.$i18n.t('common.fieldRequired'),
      ],
      dateToRules: [
        v => !!v || this.$i18n.t('common.fieldRequired'),
      ],
      airlineRules: [
        v => !!v || this.$i18n.t('common.fieldRequired'),
      ],
      rowsPerPageItems: [6, 12, 18],
      paginationDuplicatedFlights: {
        rowsPerPage: 6,
      },
      pagination: {
        rowsPerPage: 6,
      },
      flightsCorrect: false,
      validData: true,
    };
  },
  methods: {
    getDuplicateFlights(key) {
      const flights = [];
      this.importParsedFile.allItems.forEach((f) => {
        if (f.DATE_IN === key.date && f.TRIP_IN === key.tripNumber && f.AIRLINE_IN === key.airline && f.FROM_01 === key.airport && f.STA === key.scheduledTime) {
          const flight = {
            date: f.DATE_IN,
            airline: f.AIRLINE_IN,
            tripNumber: f.TRIP_IN,
            airport: f.FROM_01,
            scheduledTime: f.STA,
            bound: 'INBOUND',
          };
          flights.push(flight);
        }
        if (f.DATE_OUT === key.date && f.TRIP_OUT === key.tripNumber && f.AIRLINE_OUT === key.airline && f.TO_01 === key.airport && f.STD === key.scheduledTime) {
          const flight = {
            date: f.DATE_OUT,
            airline: f.AIRLINE_OUT,
            tripNumber: f.TRIP_OUT,
            airport: f.TO_01,
            scheduledTime: f.STD,
            bound: 'OUTBOUND',
          };
          flights.push(flight);
        }
      });
      return flights;
    },
    getPossibleSameFlights(key) {
      const flights = [];
      this.importParsedFile.allItems.forEach((f) => {
        if (f.DATE_IN === key.date && f.TRIP_IN === key.tripNumber && f.AIRLINE_IN === key.airline && f.FROM_01 === key.airport) {
          const flight = {
            date: f.DATE_IN,
            airline: f.AIRLINE_IN,
            tripNumber: f.TRIP_IN,
            airport: f.FROM_01,
            scheduledTime: f.STA,
            bound: 'INBOUND',
          };
          flights.push(flight);
        }
        if (f.DATE_OUT === key.date && f.TRIP_OUT === key.tripNumber && f.AIRLINE_OUT === key.airline && f.TO_01 === key.airport) {
          const flight = {
            date: f.DATE_OUT,
            airline: f.AIRLINE_OUT,
            tripNumber: f.TRIP_OUT,
            airport: f.TO_01,
            scheduledTime: f.STD,
            bound: 'OUTBOUND',
          };
          flights.push(flight);
        }
      });
      return flights;
    },
    isFlightIn(flight) {
      const inbound = Symbol('INBOUND').toString();
      return (flight.bound && (flight.bound.toString() === inbound));
    },
    isValidData() {
      this.validData = ((this.headers.length !== 0 && this.duplicatedFlights.length === 0 && this.possibleSameFlights.length === 0 && this.connectedFlightsStdBeforeSta.length === 0 && this.flightsWithErrors.length === 0 && this.flightsWithScheduledTimeEmpty.length === 0) || (this.flightsCorrect && this.duplicatedFlights.length === 0 && this.flightsCorrect && this.flightsWithErrors.length === 0 && this.connectedFlightsStdBeforeSta.length === 0 && this.flightsWithScheduledTimeEmpty.length === 0));
      this.$emit('dataImportOptionValidation', this.validData);
    },
    getFormattedTime(time) {
      return TimeUtils.formattedTimeCsv(time);
    },
  },
  mounted() {
    this.isValidData();
  },
};
</script>
<style lang="scss" scoped>

  .grid-duplicate-flights {
    padding: 0;
  }
  .trip {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin: 10px 0;
    max-height: 20vh;
  }
  .tripnumber {
    padding: 2px;
  }
  .contentData {
    max-height: 60vh;
    overflow-y: auto;
  }
</style>
