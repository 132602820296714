<template>
  <div>
    <entity-configuration-list
      :entity-name="this.$i18n.t('configuration.messageDirectory')"
      entity-icon="people"
      :framework-components="frameworkComponents"
      :column-defs="columnDefs"
      :items="items"
      @confirm-edit-item="editConfirm"
      @confirm-delete-item="deleteConfirm"
    >
      <template v-slot:modal-content="{ itemEdit }">
        <message-directory-edit-card-content :item="itemEdit" />
      </template>
    </entity-configuration-list>
  </div>
</template>
<script>
import EntityConfigurationList from '@/components/configuration/common/EntityConfigurationList.vue';
import MessageDirectoryEditCardContent from './MessageDirectoryEditCardContent.vue';
import {
  GET_MESSAGE_DIRECTORY,
  MESSAGE_DIRECTORY_CREATE,
  MESSAGE_DIRECTORY_DELETE,
  MESSAGE_DIRECTORY_UPDATE,
} from '../../../store/modules/messageDirectory/messageDirectoryCatalog';
import { GET_AVINET_USERS } from '../../../store/modules/avinetUser';

export default {
  name: 'MessageDirectoryList',
  components: {
    EntityConfigurationList,
    MessageDirectoryEditCardContent,
  },
  data() {
    const that = this;
    return {
      frameworkComponents: { },
      columnDefs: [
        {
          valueGetter(params) {
            return params.data.airlineIcaoCode ? params.data.airlineIcaoCode : params.data.airlineIataCode;
          },
          headerValueGetter() {
            return that.$i18n.t('common.airline');
          },
          sort: 'asc',
        },
        {
          field: 'messageTypeCode',
          headerValueGetter() {
            return that.$i18n.t('messages.type');
          },
        },
        {
          field: 'airportIataCode',
          headerValueGetter() {
            return that.$i18n.t('messageDirectory.destination');
          },
        },
        {
          field: 'source',
          headerValueGetter() {
            return that.$i18n.t('messageDirectory.source');
          },
        },
        {
          field: 'aircraftTypeCode',
          headerValueGetter() {
            return that.$i18n.t('configuration.aircraftType');
          },
        },
      ],
    };
  },
  computed: {
    items() {
      return this.$store.state.messageDirectoryCatalog.messagesDirectoryList;
    },
  },
  methods: {
    getMessageDirectory() {
      this.$store.dispatch(GET_MESSAGE_DIRECTORY);
    },
    deleteConfirm(item) {
      this.$store.dispatch(MESSAGE_DIRECTORY_DELETE, item.id);
    },
    editConfirm(item) {
      /* eslint no-param-reassign: "off" */
      item.recipients = item.recipientsFormat.map(r => ({ airportMessageDirectoryId: item.id, email: r }));
      this.$store.dispatch(item.id ? MESSAGE_DIRECTORY_UPDATE : MESSAGE_DIRECTORY_CREATE, item);
    },
    getAvinetUsers() {
      return this.$store.dispatch(GET_AVINET_USERS);
    },
  },
  created() {
    this.getMessageDirectory();
    this.getAvinetUsers();
  },
};
</script>
