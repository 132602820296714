export default class FlightLoads {
  constructor(flightId, id, airportOrder, bag, cargo, mail, avih, hum, weap, oths, estimatedLoad, remarks, flightTripNumber, airportCode, messageValidate, unit) {
    this.id = id;
    this.airportOrder = airportOrder;
    this.bag = bag;
    this.cargo = cargo;
    this.mail = mail;
    this.avih = avih;
    this.hum = hum;
    this.weap = weap;
    this.oths = oths;
    this.estimatedLoad = estimatedLoad;
    this.remarks = remarks;
    this.flightTripNumber = flightTripNumber;
    this.flightId = flightId;
    this.airportCode = airportCode;
    this.messageValidate = messageValidate;
    this.unit = unit;
  }

  static createFlightLoads(flightId) {
    return new FlightLoads(flightId);
  }
}
