<template>
    <v-card v-bind:class="{ 'flight-form-disabled': disabled }">
        <v-card-text style="margin-bottom: 0; padding-bottom: 0">
            <v-container fluid style="margin-bottom: 0; padding-bottom: 0">
                <v-layout style="margin-bottom: 0; padding-bottom: 0">
                    <v-flex class="bound-icon">
                      <v-tooltip bottom>
                        <v-icon x-large left slot="activator">{{icon}}</v-icon>
                        <span>{{type}}</span>
                      </v-tooltip>
                    </v-flex>

                    <v-flex v-if="!isLongTerm" v-flex-none style="margin-right: 2ch">
                        <date-input v-char-length.date :value="scheduledDate" :locale="$i18n.locale" :label="this.$i18n.t('common.date')" :rules="dateRules" :disabled="disabled" @input="onChangeDate"></date-input>
                    </v-flex>

                    <v-flex v-flex-none>
                        <aircraft-select @change="onChangeAircraft" v-char-length.clearable="7" v-model="flight.aircraftId" :label="this.$i18n.t('common.aircraftRegistration')" :disabled="disabled"></aircraft-select>
                    </v-flex>
                    <v-flex v-flex-none>
                        <aircraft-type-select class="d-inline-flex" @change="onChangeAircraftType" v-char-length.clearable="4" v-model="flight.aircraftTypeId" :label="this.$i18n.t('common.type')" :rules="aircraftTypeRules" :disabled="disabled"></aircraft-type-select>
                    </v-flex>
                    <v-flex v-flex-none>
                        <aircraft-config-typeahead v-char-length.clearable="7" v-model="flight.aircraftConfigCode" :label="this.$i18n.t('common.config')" :disabled="disabled" @input-id="setAircraftConfigId"></aircraft-config-typeahead>
                    </v-flex>

                    <v-divider style="margin-left: 1.5ch; margin-right: 1.5ch" vertical></v-divider>

                    <v-flex v-flex-none>
                        <airline-select v-char-length.clearable="2" :label="this.$i18n.t('common.airline')" v-model="flight.airlineId" :rules="airlineRules" :disabled="disabled || isUpdating"></airline-select>
                    </v-flex>
                    <v-flex v-flex-none>
                        <v-text-field v-char-length="5" v-model="flight.tripNumber" :label="this.$i18n.t('common.flight')" :rules="tripNumberRules" :disabled="disabled || isUpdating"></v-text-field>
                    </v-flex>

                    <v-flex v-flex-none style="margin-right: 2ch">
                      <airline-select v-char-length.clearable="2" :label="this.$i18n.t('common.airlineOp')" v-model="flight.airlineOperationId" :disabled="disabled"></airline-select>
                    </v-flex>

                    <v-flex v-flex-none style="margin-right: 2ch">
                      <time-input v-model="flight.scheduledTime" :referenceDate="referenceDate" :label="labelST" :disabled="disabled" :rules="scheduledTimeRules"></time-input>
                    </v-flex>

                    <v-flex v-flex-none>
                        <airport-select v-char-length.clearable="3" v-model="flight.airport1Id" :label="labelFromTo + ' 1'" :rules="airport1Rules" :disabled="disabled"></airport-select>
                    </v-flex>
                    <v-flex v-flex-none>
                        <airport-select v-char-length.clearable="3" v-model="flight.airport2Id" :label="labelFromTo + ' 2'" :disabled="disabled"></airport-select>
                    </v-flex>
                    <v-flex v-flex-none style="margin-right: 2ch">
                        <airport-select v-char-length.clearable="3" v-model="flight.airport3Id" :label="labelFromTo + ' 3'" :disabled="disabled"></airport-select>
                    </v-flex>
                </v-layout>
            </v-container>
        </v-card-text>
        <v-card-text style="margin-top: 0; padding-top: 0">
          <v-container fluid style="margin-top: 0; padding-top: 0">
            <v-layout style="margin-top: 0; padding-top: 0">
              <v-flex v-flex-none>
                <handling-type-select class="d-inline-flex" v-char-length.clearable="4" v-model="flight.handlingTypeId" :label="this.$i18n.t('common.handlingType')" :disabled="disabled"></handling-type-select>
              </v-flex>
              <v-flex v-flex-none>
                <service-type-select v-char-length.clearable="4" v-model="flight.serviceTypeId" :label="this.$i18n.t('configuration.serviceType')" :disabled="disabled" :validate-connected-flight="true" :is-connected-flight="isConnectedFlight"></service-type-select>
              </v-flex>

              <v-flex v-flex-none style="width: 625px; margin-left: 40px">
                <v-text-field v-char-length="300" v-model="flight.comments" :label="this.$i18n.t('common.comments')" :disabled="disabled"></v-text-field>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
    </v-card>
</template>
<script>
import AircraftTypeSelect from '../../aircraft/AircraftTypeSelect.vue';
import AirlineSelect from '../../airline/AirlineSelect.vue';
import AirportSelect from '../../airport/AirportSelect.vue';
import DateInput from '../../ui/DateInput.vue';
import TimeInput from '../../ui/TimeInput.vue';
import AircraftConfigSelect from '../../aircraft/AircraftConfigSelect.vue';
import AircraftSelect from '../../aircraft/AircraftSelect.vue';
import HandlingTypeSelect from '../../handling/HandlingTypeSelect.vue';
import AircraftConfigTypeahead from '../../aircraft/AircraftConfigTypeahead.vue';
import ServiceTypeSelect from '../../ui/serviceTypeSelector/ServiceTypeSelect.vue';

export default {
  name: 'scheduling-flight-form',
  components: {
    AircraftConfigTypeahead,
    HandlingTypeSelect,
    AircraftSelect,
    AircraftConfigSelect,
    AircraftTypeSelect,
    AirlineSelect,
    AirportSelect,
    DateInput,
    TimeInput,
    ServiceTypeSelect,
  },
  props: {
    type: { type: String, required: true },
    bound: { type: String },
    editedFlight: { required: true },
    disabled: { type: Boolean, required: true },
    isLongTerm: { type: Boolean, default: false },
    scheduledTimeError: { type: Boolean, default: false },
    additionalDateRules: { type: Array, default() { return []; } },
  },
  watch: {
    editedFlight: {
      handler(newFlightProp) {
        this.flight = newFlightProp;
      },
      deep: true,
    },
    flight: {
      handler(newFlight) {
        this.$emit('flight-update', newFlight);
      },
      deep: true,
    },
    bound: {
      handler(newBound) {
        if (this.flightBound !== newBound) {
          this.flight.serviceTypeId = null;
        }
        this.flightBound = newBound;
      },
      deep: true,
    },
  },
  computed: {
    isInbound() {
      return this.type === 'INBOUND';
    },
    isOutbound() {
      return this.type === 'OUTBOUND';
    },
    isConnectedFlight() {
      return this.flightBound === 'ANY';
    },
    icon() {
      return this.type === 'INBOUND' ? 'flight_land' : 'flight_takeoff';
    },
    labelST() {
      return this.type === 'INBOUND' ? this.$i18n.t('common.scheduledTimeArrival') : this.$i18n.t('common.scheduledTimeOfDeparture');
    },
    labelFromTo() {
      return this.type === 'INBOUND' ? this.$i18n.t('common.from') : this.$i18n.t('common.to');
    },
    referenceDate() {
      return this.flight.scheduledDate();
    },
    scheduledDate() {
      return this.flight.scheduledTime ? this.flight.scheduledTime : this.flight.flightDate;
    },
    scheduledTimeRules() {
      return [() => !this.scheduledTimeError || this.$i18n.t('common.scheduledTimeError'), v => !!v || this.disabled || this.$i18n.t('common.fieldRequired')];
    },
    isUpdating() {
      return this.flight.id != null;
    },
  },
  data() {
    return {
      flight: this.editedFlight,
      flightBound: this.bound,
      dateRules: [
        ...this.additionalDateRules,
        v => !!v || this.disabled || this.$i18n.t('common.fieldRequired'),
      ],
      aircraftTypeRules: [
        v => !!v || this.disabled || this.$i18n.t('common.fieldRequired'),
      ],
      airlineRules: [
        v => !!v || this.disabled || this.$i18n.t('common.fieldRequired'),
      ],
      tripNumberRules: [
        v => !!v || this.disabled || this.$i18n.t('common.fieldRequired'),
        (flightNumber) => {
          const pattern = /^[A-Za-z1-9][A-Za-z0-9]{0,4}$/g;
          if (flightNumber !== 'undefined' && flightNumber != null) {
            return pattern.test(flightNumber) || this.$i18n.t('configuration.invalidRegisterNumber');
          }
          return true;
        },
      ],
      airport1Rules: [
        v => !!v || this.disabled || this.$i18n.t('common.fieldRequired'),
      ],
    };
  },
  methods: {
    onChangeAircraft(aircraft) {
      if (aircraft) {
        this.flight.aircraftTypeId = aircraft.typeId;
      }
    },
    onChangeAircraftType(aircraft) {
      if (aircraft) {
        this.flight.aircraftId = null;
        this.flight.aircraftRegisterNumber = null;
      }
    },
    onChangeDate(date) {
      if (date) {
        const momentDate = this.$moment(date);
        this.flight.flightDate = momentDate;
        if (this.flight.scheduledTime) {
          this.flight.scheduledTime = this.$moment(this.flight.scheduledTime).year(momentDate.year()).month(momentDate.month()).date(momentDate.date());
        }
      }
    },
    setAircraftConfigId(event) {
      if (typeof (event) !== 'undefined') {
        this.flight.aircraftConfigId = event;
      }
    },
  },
};
</script>
<style>
  .bound-icon {
    padding-top: 10px !important;
  }
  .flight-form-disabled {
    opacity: 0.4;
    box-shadow: none;
  }
</style>
