<template>
  <div>
    <div v-if="editable" class="d-flex align-center mobile-date-input">
      <v-dialog ref="dialog" v-model="modal" :return-value.sync="dateValue" persistent lazy full-width width="420px">
        <template v-slot:activator="{ on }">
          <v-text-field :name="name" :id="name" hide-details class="pa-0" v-model="dateValue" readonly
            v-on="on"></v-text-field>
        </template>
        <v-date-picker v-if="modal" v-model="dateValue" scrollable full-width class="mobile-date-input"
          first-day-of-week="1" :locale="$i18n.locale">
          <v-btn class="clearable" icon @click="$refs.dialog.save(null); emitValue(null)" v-if="clearable" large color="white">
            <v-icon color="primary">mdi-trash-can-outline</v-icon>
          </v-btn>
          <v-btn depressed large color="secondary" @click="$refs.dialog.save(getNow()); emitValue(getNow())">{{
      $t("common.today") }}</v-btn>
          <v-btn depressed large flat color="primary" @click="modal = false">{{ $t("common.cancel") }}</v-btn>
          <v-btn depressed large color="primary" @click="$refs.dialog.save(dateValue); emitValue(dateValue);">{{
      $t("common.confirm") }}</v-btn>
        </v-date-picker>
      </v-dialog>
    </div>
    <div v-if="!editable" class="d-flex align-center">
      <v-text-field :name="name" :id="name" hide-details class="pa-0" v-model="dateValue" readonly
        disabled></v-text-field>
    </div>
  </div>
</template>

<script>
import StoreSubscribable from '../../mixins/StoreSubscribable.vue';

export default {
  name: 'mobile-date-input',
  props: {
    name: {
      type: String,
      required: true,
    },
    value: {
      required: true,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [StoreSubscribable],
  data() {
    return {
      dateValue: null,
      modal: false,
    };
  },
  computed: {
    localTimezone() {
      return this.$store.state.time.timezone;
    },
  },
  watch: {
    value(newValue) {
      this.setValue(newValue);
    },
  },
  methods: {
    setValue(newValue) {
      if (this.value && !this.$moment(newValue).isValid()) {
        this.dateValue = null;
      } else {
        this.dateValue = newValue;
      }
      this.value = newValue;
    },
    getNow() {
      this.$emit('now');
      return this.$moment().tz(this.localTimezone).format('YYYY-MM-DD');
    },
    emitValue(newValue) {
      if (this.editable) {
        this.$emit('input', newValue);
      }
    },
  },
  created() {
    this.setValue(this.value);
  },
};
</script>
<style>
.mobile-date-input .v-date-picker-header__value div,
.mobile-date-input .v-date-picker-table--date th {
  font-size: 20px;
}

.mobile-date-input .v-date-picker-table .v-btn__content {
  font-size: 20px;
}

/* the last column of the table  is color red */
.mobile-date-input .v-date-picker-table--date th:nth-last-child(2),
.mobile-date-input .v-date-picker-table--date th:last-child {
  color: red;
}

/* the last column of the table  is color red */
.mobile-date-input .v-date-picker-table--date td:nth-last-child(2) .v-btn__content,
.mobile-date-input .v-date-picker-table--date td:last-child .v-btn__content {
  color: gray;
}

.mobile-date-input .v-date-picker-table--date .v-btn {
  height: 48px;
  width: 48px;
}

.mobile-date-input .v-date-picker-table {
  height: auto;
}

.mobile-date-input .v-date-picker-title__year {
  font-size: 24px;
}

.mobile-date-input .v-date-picker-years li {
  font-size: 24px;
}

.mobile-date-input .v-date-picker-years li.active {
  font-size: 30px;
  font-weight: 500;
  padding: 10px 0;
}

.mobile-date-input .v-date-picker-header .v-btn {
  padding: 20px;
}

.mobile-date-input .clearable {
  position: absolute;
  top: 30px;
  right: 30px;
}
</style>
