<template>
  <v-card :dark="this.$vuetify.dark" :color="this.$vuetify.dark ? 'bggrey_dark' : 'bggrey'">
    <v-container
      fluid
      grid-list-lg
    >
      <v-layout row wrap>
        <v-flex xs12>
          <ImportFileRampService/>
        </v-flex>
      </v-layout>
    </v-container>
  </v-card>
</template>
<script>
import ImportFileRampService from './ImportFileRampService.vue';

export default {
  name: 'AdminImportRampService',
  components: {
    ImportFileRampService,
  },

  methods: {

  },
};
</script>
