<template>
  <v-dialog v-model="dialog" persistent
    :content-class="isContentVisible ? 'entity-configuration-modal-edit modal-common' : 'entity-configuration-modal-edit modal-common hide-title'">
    <v-card :dark="this.$vuetify.dark" :color="this.$vuetify.dark ? 'bggrey_dark' : 'bggrey'">
      <v-toolbar dark color="primary" class="ToolbarDialogEditor">
        <v-icon v-if="icon">{{ icon }}</v-icon>
        <v-toolbar-title>{{ textTitle }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <!-- Botón para contraer/expandir el contenido -->
        <v-btn icon @click="toggleContentVisibility">
          <v-icon>{{ isContentVisible ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
        </v-btn>
      </v-toolbar>
      <div v-show="isContentVisible">
        <loading-component />
        <v-form ref="form" v-model="valid" class="editSlot">
          <slot>
            <v-flex xs7 offset-xs5 offset-md5 offset-lg5>
              {{ $t("configuration.noContent") }}
            </v-flex>
          </slot>
        </v-form>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn flat color="primary" @click.native="reject()">{{ $t("common.cancel") }}</v-btn>
          <v-btn v-show="hasPermissions(rolePermission)" color="error" depressed @click.native="confirm()"
            :disabled="!valid">{{ $t("common.save") }}</v-btn>
        </v-card-actions>
      </div>
    </v-card>
  </v-dialog>
</template>
<script>

import LoadingComponent from '../../layout/LoadingComponent.vue';
import RolePermissions from '../../mixins/RolePermissions.vue';

export default {
  name: 'entity-configuration-modal-edit',
  mixins: [RolePermissions],
  components: {
    LoadingComponent,
  },
  props: {
    dialog: { type: Boolean, required: true },
    item: { type: Object, required: true },
    icon: { type: String },
    configEntity: { type: String, required: true },
  },
  data() {
    return {
      textTitle: this.item.id ? this.$i18n.t('configuration.edit') + this.configEntity : this.$i18n.t('configuration.new') + this.configEntity,
      valid: true,
      isContentVisible: true,
    };
  },
  computed: {
    loading() {
      return this.$store.state.loading;
    },
    rolePermission() {
      return `${this.$route.meta.role}_WR`;
    },
  },
  methods: {
    reject() {
      this.$emit('edit-reject');
    },
    confirm() {
      this.$emit('edit-confirm', this.item);
    },
    toggleContentVisibility() {
      this.isContentVisible = !this.isContentVisible;
    },
  },
};
</script>
<style lang="scss">
.entity-configuration-modal-edit {
  .editSlot {
    overflow-y: auto;
    max-height: calc(90vh - 14em);
  }

  .ToolbarDialogEditor {
    z-index: 10;
  }

  &.v-dialog:not(.v-dialog--fullscreen) {
    max-width: 60%;
  }

  @media (max-width: 1024px) {
    .v-dialog:not(.v-dialog--fullscreen) {
      max-width: 90%;
    }
  }
}
</style>
