/* eslint no-param-reassign: "off" */

// action
export const ACTION_SET_NO_FLIGHTS_TO_SHOW_MESSAGE = 'ACTION_SET_NO_FLIGHTS_TO_SHOW_MESSAGE';

// mutations
export const MUTATION_SET_NO_FLIGHTS_TO_SHOW_MESSAGE = 'MUTATION_SET_NO_FLIGHTS_TO_SHOW_MESSAGE';
export const MUTATION_SET_NO_LOADING_MESSAGE = 'MUTATION_SET_NO_LOADING_MESSAGE';


// constants
const NO_FLIGHTS_TO_SHOW_DEFAULT_MESSAGE = 'agGrid.noDataToShow';
const LOADING_MESSAGE = 'agGrid.loading';
const NO_FLIGHTS_TO_SHOW_DUE_TO_SELECTED_DATE = 'agGrid.noFlightsToShowDueToSelectedDate';
const NO_FLIGHTS_TO_SHOW_DUE_TO_SELECTED_DATE_HTTP_CODE = 567;
// eslint-disable-next-line
const storeState = {
  noFlightsToShowMessage: NO_FLIGHTS_TO_SHOW_DEFAULT_MESSAGE,
  loadingMessage: LOADING_MESSAGE,
};

// getters
const storeGetters = {
  getNoFlightsToShowMessage: state => state.noFlightsToShowMessage,
  getLoadingMessage: state => state.loadingMessage,
};

// actions
const storeActions = {
  [ACTION_SET_NO_FLIGHTS_TO_SHOW_MESSAGE]: ({ commit }, httpStatus) => {
    // Calculate new message value
    const noFlightsToShowMessageNewValue = (httpStatus === NO_FLIGHTS_TO_SHOW_DUE_TO_SELECTED_DATE_HTTP_CODE)
      ? NO_FLIGHTS_TO_SHOW_DUE_TO_SELECTED_DATE
      : NO_FLIGHTS_TO_SHOW_DEFAULT_MESSAGE;
    // Set new message value to the message variable
    commit(MUTATION_SET_NO_FLIGHTS_TO_SHOW_MESSAGE, noFlightsToShowMessageNewValue);
  },
};

// mutations
const storeMutations = {
  [MUTATION_SET_NO_FLIGHTS_TO_SHOW_MESSAGE]: (state, message) => {
    state.noFlightsToShowMessage = message;
  },
  [MUTATION_SET_NO_LOADING_MESSAGE]: (state, message) => {
    state.loadingMessage = message;
  },
};

export default {
  state: storeState,
  getters: storeGetters,
  actions: storeActions,
  mutations: storeMutations,
};
