/* eslint no-param-reassign: "off" */
/* eslint no-confusing-arrow: "off" */
/* eslint no-nested-ternary: "off" */

import { REQUEST_ERROR, REQUEST_START, REQUEST_SUCCESS } from '../../store';
import { NOTIFICATION_SHOW } from '../notifications';
import { HTTP } from '../../../http';
import i18n from '../../../plugins/i18n';

// actions names
export const GET_AIRPORT_CHECK_IN_COUNTER = 'GET_AIRPORT_CHECK_IN_COUNTER';
export const GET_AIRPORT_CHECK_IN_COUNTER_CONTACT = 'GET_AIRPORT_CHECK_IN_COUNTER_CONTACT';
export const AIRPORT_CHECK_IN_COUNTER_DELETE = 'AIRPORT_CHECK_IN_COUNTER_DELETE';
export const AIRPORT_CHECK_IN_COUNTER_CREATE = 'AIRPORT_CHECK_IN_COUNTER_CREATE';
export const AIRPORT_CHECK_IN_COUNTER_UPDATE = 'AIRPORT_CHECK_IN_COUNTER_UPDATE';
export const AIRPORT_CHECK_IN_COUNTER_CONTACT_UPDATE = 'AIRPORT_CHECK_IN_COUNTER_CONTACT_UPDATE';
export const AIRPORT_CHECK_IN_COUNTER_DISABLE = 'AIRPORT_CHECK_IN_COUNTER_DISABLE';
export const AIRPORT_CHECK_IN_COUNTER_ENABLE = 'AIRPORT_CHECK_IN_COUNTER_ENABLE';

// mutations
export const CHANGE_AIRPORT_CHECK_IN_COUNTER_SELECTION = 'CHANGE_AIRPORT_CHECK_IN_COUNTER_SELECTION';
export const GET_AIRPORT_CHECK_IN_COUNTER_SUCCESS = 'GET_AIRPORT_CHECK_IN_COUNTER_SUCCESS';
export const GET_AIRPORT_CHECK_IN_COUNTER_REQUESTED = 'GET_AIRPORT_CHECK_IN_COUNTER_REQUESTED';
export const UPDATE_AIRPORT_CHECK_IN_COUNTER_SUCCESS = 'UPDATE_AIRPORT_CHECK_IN_COUNTER_SUCCESS';
export const CHANGED_TENANT_AIRPORT_CHECK_IN_COUNTER = 'CHANGED_TENANT_AIRPORT_CHECK_IN_COUNTER';

// initial state
const storeState = {
  airportCheckInCounters: [],
  airportCheckInCounterRequested: false,
  selectedAirportCheckInCounter: [],
};

// getters
const storeGetters = {
  getAirportCheckInCounters: state => state.airportCheckInCounters.sort((a, b) => (a.code > b.code) ? 1 : ((b.code > a.code) ? -1 : 0)),
  getActiveAirportCheckInCounters: state => state.airportCheckInCounters.filter(elem => !elem.deleted),
  getAirportCheckInCounterSelected: state => state.selectedAirportCheckInCounter,
  hasSelectedAirportCheckInCounter: state => state.selectedAirportCheckInCounter.length > 0,
};

// actions
const storeActions = {
  [GET_AIRPORT_CHECK_IN_COUNTER]: ({ commit, state }) => {
    if (state.airportCheckInCounterRequested) {
      return;
    }
    commit(REQUEST_START);
    commit(GET_AIRPORT_CHECK_IN_COUNTER_REQUESTED);
    HTTP.get('airport-check-in-counters')
      .then((resp) => {
        commit(REQUEST_SUCCESS);
        commit(GET_AIRPORT_CHECK_IN_COUNTER_SUCCESS, resp.data);
      })
      .catch((err) => {
        commit(REQUEST_ERROR, err);
      });
  },
  [GET_AIRPORT_CHECK_IN_COUNTER_CONTACT]: ({ commit, state }) => {
    if (state.airportCheckInCounterRequested) return;
    commit(REQUEST_START);
    commit(GET_AIRPORT_CHECK_IN_COUNTER_REQUESTED);
    HTTP.get('/airport-check-in-counters/contact')
      .then((resp) => {
        commit(REQUEST_SUCCESS);
        commit(GET_AIRPORT_CHECK_IN_COUNTER_SUCCESS, resp.data);
      })
      .catch((err) => {
        commit(REQUEST_ERROR, err);
      });
  },
  [AIRPORT_CHECK_IN_COUNTER_CREATE]: ({ commit, dispatch }, checkInCounter) => {
    commit(REQUEST_START);
    return HTTP.post('airport-check-in-counters', checkInCounter)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(UPDATE_AIRPORT_CHECK_IN_COUNTER_SUCCESS);
        dispatch(GET_AIRPORT_CHECK_IN_COUNTER);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.airportCheckInCountersCreate'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },

  [AIRPORT_CHECK_IN_COUNTER_UPDATE]: ({ commit, dispatch }, checkInCounter) => {
    commit(REQUEST_START);
    return HTTP.put('airport-check-in-counters', checkInCounter)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(UPDATE_AIRPORT_CHECK_IN_COUNTER_SUCCESS);
        dispatch(GET_AIRPORT_CHECK_IN_COUNTER);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.airportCheckInCountersUpdate'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
  [AIRPORT_CHECK_IN_COUNTER_CONTACT_UPDATE]: ({ commit, dispatch }, checkInCounter) => {
    commit(REQUEST_START);
    return HTTP.put('airport-check-in-counters/contact', checkInCounter)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(UPDATE_AIRPORT_CHECK_IN_COUNTER_SUCCESS);
        dispatch(GET_AIRPORT_CHECK_IN_COUNTER);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.airportCheckInCountersUpdate'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
  [AIRPORT_CHECK_IN_COUNTER_DELETE]: ({ commit, dispatch }, id) => {
    commit(REQUEST_START);
    return HTTP.delete(`airport-check-in-counters/${id}`)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(UPDATE_AIRPORT_CHECK_IN_COUNTER_SUCCESS);
        dispatch(GET_AIRPORT_CHECK_IN_COUNTER);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.airportCheckInCountersDelete'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
  [AIRPORT_CHECK_IN_COUNTER_DISABLE]: ({ commit, dispatch }, id) => {
    commit(REQUEST_START);
    return HTTP.patch(`/airport-check-in-counters/disable/${id}`)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(UPDATE_AIRPORT_CHECK_IN_COUNTER_SUCCESS);
        dispatch(GET_AIRPORT_CHECK_IN_COUNTER);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.airportCheckInCountersDisable'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
  [AIRPORT_CHECK_IN_COUNTER_ENABLE]: ({ commit, dispatch }, id) => {
    commit(REQUEST_START);
    return HTTP.patch(`/airport-check-in-counters/enable/${id}`)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(UPDATE_AIRPORT_CHECK_IN_COUNTER_SUCCESS);
        dispatch(GET_AIRPORT_CHECK_IN_COUNTER);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.airportCheckInCountersEnable'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },

};

// mutations
const storeMutations = {
  [GET_AIRPORT_CHECK_IN_COUNTER_SUCCESS]: (state, airportCheckInCounter) => {
    state.airportCheckInCounters = airportCheckInCounter.map((c) => {
      if (!c.contact) {
        c.contact = {};
      }
      return c;
    });
  },
  [GET_AIRPORT_CHECK_IN_COUNTER_REQUESTED]: (state) => {
    state.airportCheckInCounterRequested = true;
  },
  [UPDATE_AIRPORT_CHECK_IN_COUNTER_SUCCESS]: (state) => {
    state.airportCheckInCounterRequested = false;
  },
  [CHANGE_AIRPORT_CHECK_IN_COUNTER_SELECTION]: (state, selection) => {
    state.selectedAirportCheckInCounter = selection;
  },
  [CHANGED_TENANT_AIRPORT_CHECK_IN_COUNTER]: (state) => {
    state.airportCheckInCounterRequested = false;
  },
};

export default {
  state: storeState,
  getters: storeGetters,
  actions: storeActions,
  mutations: storeMutations,
};
