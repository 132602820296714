  <template>
  <v-container class="infoMobileBaggageSearch">
    <v-card :dark="this.$vuetify.dark">
      <v-card-title>
        <div class="font-weight-bold">
          <v-icon>mdi-briefcase-search</v-icon>
          {{ $t('dispatcher.baggageSearch') }}
        </div>
      </v-card-title>
      <v-divider />
      <v-list class="pa-0" v-if="!isEmptyBaggageSearch()">
        <div class="TIME">
          <v-list-tile>
            <v-list-tile-action>
              <v-icon color="secondary">mdi-alarm</v-icon>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title>{{ $t('dispatcher.notificationTime') }}</v-list-tile-title>
            </v-list-tile-content>
            <v-list-tile-action class="full-width">
              <mobile-input-type name="notificationTime" :value="flightMobileSearchData.notificationTime" typeValue="TIME" :editable="false"
                class="readonly"></mobile-input-Type>
            </v-list-tile-action>
          </v-list-tile>
        </div>
        <v-divider inset />
        <div class="NUMBER">
          <v-list-tile>
            <v-list-tile-action>
              <v-icon color="secondary">mdi-account-multiple</v-icon>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title>{{ $t('passage.missingPax') }}</v-list-tile-title>
            </v-list-tile-content>
            <v-list-tile-action class="full-width">
              <mobile-input-type name="missingPaxNumber" :value="flightMobileSearchData.missingPaxNumber" typeValue="NUMBER" :editable="false"
                class="readonly"></mobile-input-Type>
            </v-list-tile-action>
          </v-list-tile>
        </div>
        <v-divider inset />
        <div class="TEXT">
          <v-list-tile>
            <v-list-tile-action>
              <v-icon color="secondary">mdi-account-alert</v-icon>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title>{{ $t('passage.missingPaxInfo') }}</v-list-tile-title>
            </v-list-tile-content>
            <v-list-tile-action class="full-width">
              <mobile-input-type name="missingPaxInfo" :value="flightMobileSearchData.missingPaxInfo" typeValue="TEXT" :editable="false"
                :rows="8"
                class="readonly"></mobile-input-Type>
            </v-list-tile-action>
          </v-list-tile>
        </div>
      </v-list>
      <!-- no baggage search -->
      <v-card-actions v-if="isEmptyBaggageSearch()">
        <v-spacer></v-spacer>
        <div class="text-xs-center">
          <v-icon x-large color="grey lighten-1">message</v-icon>
          <div class="grey--text">{{ $t('dispatcher.noBaggageSearch') }}</div>
        </div>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
    <v-spacer class="mb-3"></v-spacer>
    <v-card :dark="this.$vuetify.dark" v-if="!isEmptyBaggageSearch()">
      <v-card-title>
        <v-icon>mdi-play</v-icon>
        <div class="font-weight-bold mx-2">
          {{ $t('dispatcher.SearchActivation') }}
        </div>
        <v-spacer></v-spacer>
        <v-chip class="clock">
          {{ displayedCurrentTime }}
        </v-chip>
      </v-card-title>
      <v-divider />
      <v-spacer class="mb-3"></v-spacer>
      <v-list class="pa-0 mb-0">
        <div class="TIME">
          <v-list-tile>
            <v-list-tile-content class="mr-3">
              <v-btn round color="green darken-1" class="full-width-btn" @click="setSearchStartTime"
                :disabled="isSearchStartTime() && !isSearchEndTime()">
                <v-icon class="mr-2">mdi-play-circle</v-icon>
                <span>{{ $t('dispatcher.searchStartTime') }}</span>
              </v-btn>
            </v-list-tile-content>
            <v-list-tile-action class="full-width">
              <mobile-input-type name="searchStartTime" :value="searchData.searchStartTime" typeValue="TIME" :editable="false"
                class="readonly"></mobile-input-Type>
            </v-list-tile-action>
          </v-list-tile>
        </div>
        <v-divider class="mt-3" />
        <v-card-title>
          <v-icon>playlist_add_check</v-icon>
          <div class="font-weight-bold mx-2">
            {{ $t('dispatcher.endingSearchReason') }}
          </div>
          <v-spacer></v-spacer>
          <v-chip class="clock lapseTime" v-if="isSearchStartTime()">
            {{ lapseTime }}
          </v-chip>
        </v-card-title>
        <v-divider />
        <v-btn-toggle class="flex-container" v-model="searchData.endingSearchReason">
          <v-btn v-for="item in searchOptions" :key="item.value" :value="item.value" flat class="flex-item"
            :disabled="!isSearchStartTime() || isSearchEndTime()">
            <v-icon class="mr-2">{{ item.icon }}</v-icon>
            <span>{{ item.text }}</span>
          </v-btn>
        </v-btn-toggle>
        <v-divider/>
      </v-list>
        <div class="TIME">
          <v-card-title class="my-0">
            <v-icon>mdi-stop</v-icon>
            <div class="font-weight-bold mx-2">
              {{ $t('dispatcher.SearchEnd') }}
            </div>
          </v-card-title>
          <v-divider />
          <v-list-tile>
            <v-list-tile-content class="mr-3">
              <v-btn round color="primary" class="full-width-btn"
                :disabled="(isSearchStartTime() && isSearchEndTime()) || (!isSearchEndTime() && !isEndingSearchReason())"
                @click="setSearchEndTime">
                <v-icon class="mr-2">mdi-stop-circle</v-icon>
                <span>{{ $t('dispatcher.searchEndTime') }}</span>
              </v-btn>
            </v-list-tile-content>
            <v-list-tile-action class="full-width">
              <mobile-input-type name="searchEndTime" :value="searchData.searchEndTime" typeValue="TIME" :editable="false"
                class="readonly"></mobile-input-Type>
            </v-list-tile-action>
          </v-list-tile>
        </div>
        <v-divider class="my-3" />
    </v-card>
  </v-container>
</template>
<script>

import FlightStatus from '../flights/FlightStatus.vue';
import { MOBILE_GET_FLIGHT_SEARCH_DATA, MOBILE_START_FLIGHT_SEARCH, MOBILE_END_FLIGHT_SEARCH } from '../../store/modules/mobile';
import MobileInputType from './mobileInputType/MobileInputType.vue';
import { TIME_TIMEZONE_CHANGED } from '../../store/modules/time';
import StoreSubscribable from '../mixins/StoreSubscribable.vue';
import './infoMobileBaggageSearch.scss';

export default {
  name: 'info-mobile-baggage-search',
  props: {
    flightId: {
      required: true,
    },
  },
  mixins: [StoreSubscribable],
  components: {
    FlightStatus,
    MobileInputType,
  },
  data() {
    return {
      currentDate: this.$moment().utc().format('HH:mm:ss'),
      lapseTime: '',
      searchData: {
        endingSearchReason: '',
        searchStartTime: '',
        searchEndTime: '',
      },
      localTimezone: this.$store.state.time.timezone,
    };
  },
  computed: {
    flightMobileSearchData() {
      return this.$store.state.mobile.flightMobileSearchData;
    },
    displayedCurrentTime() {
      return this.$moment(this.currentDate).tz(this.localTimezone).format('HH:mm:ss');
    },
    searchOptions() {
      return [
        {
          text: this.$t('dispatcher.searchFound'),
          value: 'SEARCH_FOUND',
          icon: 'luggage',
        },
        {
          text: this.$t('dispatcher.searchNotFound'),
          value: 'SEARCH_NOT_FOUND',
          icon: 'no_luggage',
        },
        {
          text: this.$t('dispatcher.searchCancelled'),
          value: 'SEARCH_CANCELLED',
          icon: 'mdi-close-circle',
        },
      ];
    },
  },
  methods: {
    updateCurrentDate() {
      this.currentDate = this.$moment().tz(this.localTimezone).utc();
      this.updateLapseTime();
    },
    setSearchData() {
      this.searchData = this.flightMobileSearchData;
    },
    isEmptyBaggageSearch() {
      let empty = true;
      if (this.flightMobileSearchData.notificationTime) {
        empty = false;
      }
      if (this.flightMobileSearchData.missingPaxNumber) {
        empty = false;
      }
      return empty;
    },
    isSearchStartTime() {
      return this.searchData.searchStartTime !== '' && this.searchData.searchStartTime !== null;
    },
    isSearchEndTime() {
      return this.searchData.searchEndTime !== '' && this.searchData.searchEndTime !== null;
    },
    isEndingSearchReason() {
      return this.searchData.endingSearchReason !== '' && this.searchData.endingSearchReason !== null && this.searchData.endingSearchReason !== undefined;
    },
    setSearchStartTime() {
      this.searchData.endingSearchReason = '';
      this.searchData.searchEndTime = '';
      this.searchData.searchStartTime = this.currentDate;
      // Save Start search
      this.$store.dispatch(
        MOBILE_START_FLIGHT_SEARCH,
        this.flightId,
      ).catch((error) => {
        console.log('error', error); // eslint-disable-line no-console
      });
    },
    setSearchEndTime() {
      this.searchData.searchEndTime = this.currentDate;
      // Save End search
      this.$store.dispatch(MOBILE_END_FLIGHT_SEARCH, {
        flightId: this.flightId,
        data: {
          reason: this.searchData.endingSearchReason,
        },
      }).catch((error) => {
        console.log('error', error); // eslint-disable-line no-console
      });
    },
    updateLapseTime() {
      if (this.isSearchStartTime()) {
        if (this.isSearchEndTime()) {
          this.lapseTime = this.$moment(this.searchData.searchEndTime).tz(this.localTimezone).diff(this.$moment(this.searchData.searchStartTime).tz(this.localTimezone), 'seconds');
        } else {
          this.lapseTime = this.$moment(this.currentDate).tz(this.localTimezone).diff(this.$moment(this.searchData.searchStartTime).tz(this.localTimezone), 'seconds');
        }
        this.lapseTime = this.$moment.utc(this.lapseTime * 1000).format('HH:mm:ss');
      } else {
        this.lapseTime = '';
      }
    },
  },
  watch: {
    flightId() {
      if (this.flightId) {
        this.$store.dispatch(MOBILE_GET_FLIGHT_SEARCH_DATA, this.flightId);
      }
    },
    flightSearchData() {
      this.setSearchData();
    },
    flightMobileSearchData() {
      this.searchData.searchStartTime = this.flightMobileSearchData.searchStartTime ? this.$moment(this.flightMobileSearchData.searchStartTime).utc() : '';
      this.searchData.searchEndTime = this.flightMobileSearchData.searchEndTime ? this.$moment(this.flightMobileSearchData.searchEndTime).utc() : '';
      this.searchData.endingSearchReason = this.flightMobileSearchData.endingSearchReason;
    },
  },
  created() {
    this.subscribeMutation(TIME_TIMEZONE_CHANGED, () => {
      this.localTimezone = this.$store.state.time.timezone;
      this.updateCurrentDate();
    });
    this.updateCurrentDate();
    setInterval(this.updateCurrentDate, 1000);
    if (this.flightId) {
      this.$store.dispatch(MOBILE_GET_FLIGHT_SEARCH_DATA, this.flightId);
    }
  },
  destroyed() {
    clearInterval(this.updateCurrentDate);
  },
};
</script>
