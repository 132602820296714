
const LOCAL_STORAGE_TENANT_KEY = 'fis-current-tenant';

export default class TenantService {
  static removeTenant() {
    localStorage.removeItem(LOCAL_STORAGE_TENANT_KEY);
  }

  static setTenant(tenant) {
    return localStorage.setItem(LOCAL_STORAGE_TENANT_KEY, JSON.stringify(tenant));
  }

  static getTenant() {
    return localStorage.getItem(LOCAL_STORAGE_TENANT_KEY) ? JSON.parse(localStorage.getItem(LOCAL_STORAGE_TENANT_KEY)) : null;
  }

  static getTenantHeader() {
    const tenant = this.getTenant();
    if (tenant) {
      return tenant.name;
    }
    return null;
  }
}
