<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <v-card>
        <v-card-title class="primary lighten-1 white--text headline">
          <div>
            <div class="headline">{{ title }}</div>
          </div>
          <v-spacer></v-spacer>
          <v-tooltip left>
            <v-btn
              slot="activator"
              icon
              :loading="loading"
              :disabled="logicDisabled"
              v-on:click.stop="refreshStorage">
              <v-icon color="white">refresh</v-icon>
            </v-btn>
            <span>{{$t("invoicing.ghs-integration.refresh")}}</span>
          </v-tooltip>
        </v-card-title>
        <v-sheet class="pa-3 primary lighten-3">
            <v-text-field
                    v-model="search"
                    :label="$t('invoicing.ghs-integration.storage-search')"
                    dark
                    flat
                    solo-inverted
                    hide-details
                    clearable
                    clear-icon="mdi-close-circle-outline"
            ></v-text-field>
            <v-checkbox
                    v-model="caseSensitive"
                    dark
                    hide-details
                    :label="$t('invoicing.ghs-integration.storage-search-case-sensitive')"
            ></v-checkbox>
        </v-sheet>

      <v-container fluid>
        <v-layout row wrap>
          <v-card-text v-if="showSubtitle">
            <h3>{{ subtitle }}</h3>
          </v-card-text>
          <v-flex md12 lg6>
            <v-card-text>
              <v-progress-circular
                v-if="loading"
                indeterminate
                color="primary"
              ></v-progress-circular>
              <v-treeview v-if="items != null && !loading"
                          :items="items"
                          item-key="path"
                          :search="search"
                          :filter="filter"
                          open-all
                          open-on-click
                          return-object
              >
                <template v-slot:prepend="{ item }">
                  <v-icon
                    v-if="item.children"
                    v-text="`mdi-${item.directory ? 'folder-network' : 'file-move' }`"
                    v-on:click="onClickItem(item)"
                  ></v-icon>
                </template>
                <template v-slot:label="{ item }">
                  <span v-on:click="onClickItem(item)">{{ item.name }}</span>
                </template>
              </v-treeview>
            </v-card-text>
          </v-flex>
          <v-flex md12 lg6>
            <v-card-text v-if="!fileSelected">
              <h3>{{ $t('invoicing.ghs-integration.select-file') }}</h3>
            </v-card-text>
            <v-card-text v-if="fileSelected">
              <h3 class="mb-2">
                {{ fileSelected.name }}
              </h3>
              <v-btn
                :loading="downloadingFile"
                :disabled="logicDisabled"
                color="blue-grey"
                class="white--text"
                v-on:click.stop="downloadFile(fileSelected)"
              >
                {{ $t('common.download') }}
                <v-icon right dark>cloud_download</v-icon>
              </v-btn>
              <v-dialog v-model="fileDialog" v-if="processingButton">
                <template v-slot:activator="{ on }">
                  <v-btn
                    :loading="processingFile"
                    :disabled="logicDisabled"
                    color="primary"
                    dark
                    v-on="on"
                  >
                    {{ $t('common.process') }}
                    <v-icon right dark>mdi-file-move</v-icon>
                  </v-btn>
                </template>

                <v-card :dark="this.$vuetify.dark" :color="this.$vuetify.dark ? 'bggrey_dark' : 'bggrey'">
                  <v-toolbar dark color="primary">
                    <v-icon>mdi-file-move</v-icon>
                    <v-toolbar-title>{{ $t('invoicing.ghs-integration.process-confirmation-title') }}</v-toolbar-title>
                  </v-toolbar>

                  <v-card-text>
                    <h3 class="mb-2">Name: {{ fileSelected.name }}</h3>
                    <h3 class="mb-2">Path: {{ fileSelected.path }}</h3>
                    {{ $t('invoicing.ghs-integration.process-confirmation-text') }}
                  </v-card-text>

                  <v-divider></v-divider>

                  <v-card-actions>
                    <v-btn flat color="primary" @click.native="cancelDialog">{{$t("common.cancel")}}</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="primary"
                      :loading="processingFile"
                      :disabled="logicDisabled"
                      @click="confirmDialog(fileSelected)">
                      {{$t("common.confirm")}}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-card-text>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card>
</template>
<script>
import { GHS_DOWNLOAD_FILE, GHS_PROCESS_FILE } from '../../../store/modules/invoicing/financialSystemsIntegration';

export default {
  name: 'StorageTree',
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: false,
    },
    items: {
      type: Array,
      required: false,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    processingButton: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    search: null,
    caseSensitive: false,
    fileSelected: null,
    downloadingFile: false,
    processingFile: false,
    refreshingStorage: false,
    fileDialog: false,
  }),
  computed: {
    filter() {
      return this.caseSensitive
        ? (item, search, textKey) => item[textKey].indexOf(search) > -1
        : undefined;
    },
    logicDisabled() {
      return this.downloadingFile || this.processingFile || this.refreshingStorage || this.loading;
    },
    isTenantSetted() {
      return this.$store.getters.isTenantSetted;
    },
    currentTenant() {
      return this.isTenantSetted ? this.$store.state.tenant.currentTenant : {};
    },
    showSubtitle() {
      return this.subtitle && this.isTenantSetted && this.currentTenant.country === 'Chile';
    },
  },
  methods: {
    onClickItem(item) {
      if (!item.directory) {
        if (this.fileSelected && this.fileSelected.name === item.name) {
          this.fileSelected = null;
        } else {
          this.fileSelected = item;
        }
      }
    },
    onClickFile(item) {
      if (!item.directory) {
        this.downloadFile(item);
      }
    },
    downloadFile(item) {
      this.downloadingFile = true;
      this.$store.dispatch(GHS_DOWNLOAD_FILE, item)
        .finally(() => {
          this.downloadingFile = false;
        });
    },
    refreshStorage() {
      // this.refreshingStorage = true;
      this.$emit('click-refresh');
    },
    cancelDialog() {
      this.fileDialog = false;
    },
    confirmDialog(item) {
      this.processingFile = true;
      this.$store.dispatch(GHS_PROCESS_FILE, item)
        .finally(() => {
          this.processingFile = false;
          this.fileDialog = false;
        });
    },
  },
};
</script>
