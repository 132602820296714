import { VChip } from 'vuetify/lib/components/VChip';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.isEmptyLink())?_c(VContainer,{staticClass:"mobileInfoConnection mobileView"},[_c(VLayout,{staticClass:"flex justify-center",attrs:{"row":"","wrap":""}},[(_vm.connectionId || _vm.originId)?_c(VFlex,{attrs:{"xs12":"","sm12":"","md12":"","lg12":"","xl12":""}},[_c('div',{staticClass:"mx-2"},[_c(VChip,{attrs:{"color":"red","dark":""},on:{"click":_vm.gotoConnection}},[_c(VIcon,{staticClass:"mx-1 hidden-xs-only"},[_vm._v("share")]),(this.connectionId)?_c('strong',{staticClass:"mx-1 hidden-xs-only"},[_vm._v(_vm._s(_vm.$t('flightFields.connection.tripNumber'))+": ")]):_vm._e(),(this.originId)?_c('strong',{staticClass:"mx-1 hidden-xs-only"},[_vm._v(_vm._s(_vm.$t('configuration.origin'))+": ")]):_vm._e(),(_vm.flightMobileConnection.bound === 'INBOUND')?_c(VIcon,[_vm._v("flight_land")]):(_vm.flightMobileConnection.bound === 'OUTBOUND')?_c(VIcon,[_vm._v("flight_takeoff")]):_vm._e(),_c('strong',{staticClass:"mx-1"},[_vm._v(" "+_vm._s(_vm.flightMobileConnection.airlineCode + _vm.flightMobileConnection.tripNumber)+" "+_vm._s(_vm.flightMobileConnection.airport1Code)+" ")]),(_vm.flightMobileConnection.scheduledTime)?_c('span',{staticClass:"label"},[_vm._v(" "+_vm._s(_vm.flightMobileConnection.scheduledTime ? _vm.formattedTime(_vm.flightMobileConnection.scheduledTime) : '-')+" ")]):_vm._e(),_c('flight-status',{staticClass:"mx-1",attrs:{"status":_vm.flightMobileConnection.status,"resizable":true}}),(_vm.flightMobileConnection.parkingCode)?_c('span',[_c('strong',{staticClass:"mx-1"},[_vm._v("P")]),_c('span',[_vm._v(_vm._s(_vm.flightMobileConnection.parkingCode))])]):_vm._e(),(_vm.flightMobileConnection.gateCode)?_c('span',[_c('strong',{staticClass:"mx-1"},[_vm._v(_vm._s(_vm.$t('dispatcher.additionalInfoGate')))]),_c('span',[_vm._v(_vm._s(_vm.flightMobileConnection.gateCode))])]):_vm._e()],1)],1)]):_vm._e()],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }