/* eslint no-param-reassign: "off" */
import { HTTP } from '../../../http';
import { REQUEST_ERROR, REQUEST_START, REQUEST_SUCCESS } from '../../store';
import { NOTIFICATION_SHOW } from '../notifications';
import i18n from '../../../plugins/i18n';
import Integration from '../../../components/shared/model/integration/integration';
import IntegrationFactory from '../../../components/shared/model/integration/integrationFactory';

// action
export const ADMIN_DUS = 'ADMIN_DUS';
export const ADMIN_DUS_INTEGRATION = 'ADMIN_DUS_INTEGRATION';
export const ADMIN_DUS_ENABLE = 'ADMIN_DUS_ENABLE';
export const ADMIN_DUS_DISABLE = 'ADMIN_DUS_DISABLE';
export const ADMIN_DUS_CLEAN = 'ADMIN_DUS_CLEAN';

// mutations
export const ADMIN_DUS_REQUEST = 'ADMIN_DUS_REQUEST';
export const ADMIN_DUS_RESPONSE = 'ADMIN_DUS_RESPONSE';
export const ADMIN_DUS_ERROR = 'ADMIN_DUS_ERROR';
export const ADMIN_DUS_RESPONSE_INTEGRATION = 'ADMIN_DUS_RESPONSE_INTEGRATION';
export const ADMIN_DUS_REQUEST_CLEAN = 'ADMIN_DUS_REQUEST_CLEAN';
export const ADMIN_DUS_RESPONSE_CLEAN = 'ADMIN_DUS_RESPONSE_CLEAN';
export const ADMIN_DUS_ERROR_CLEAN = 'ADMIN_DUS_ERROR_CLEAN';

// initial state
const storeState = {
  dusLoading: null,
  dusSuccess: null,
  dusResult: [],
  dusLastRequestTime: null,
  dusOperation: null,

  dusIntegration: Integration.createEmptyIntegration,
};

// getters
const storeGetters = {
  isDusSuccess: state => state.dusSuccess != null && !!state.dusSuccess,
  isDusError: state => state.dusSuccess != null && !state.dusSuccess,
};

// actions
const storeActions = {
  [ADMIN_DUS]: ({ commit }) => {
    commit(ADMIN_DUS_REQUEST);
    return HTTP.post('integration/live-dus')
      .then((resp) => {
        commit(ADMIN_DUS_RESPONSE, resp.data);
      })
      .catch((err) => {
        commit(ADMIN_DUS_ERROR, err.response.data);
      });
  },
  [ADMIN_DUS_INTEGRATION]: ({ commit }) => {
    commit(REQUEST_START);
    return HTTP.get('integration/live-dus')
      .then((resp) => {
        commit(REQUEST_SUCCESS);
        commit(ADMIN_DUS_RESPONSE_INTEGRATION, resp.data);
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
  [ADMIN_DUS_ENABLE]: ({ commit }) => {
    commit(REQUEST_START);
    return HTTP.patch('integration/live-dus/enable')
      .then((resp) => {
        commit(REQUEST_SUCCESS);
        commit(ADMIN_DUS_RESPONSE_INTEGRATION, resp.data);
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
  [ADMIN_DUS_DISABLE]: ({ commit }) => {
    commit(REQUEST_START);
    return HTTP.patch('integration/live-dus/disable')
      .then((resp) => {
        commit(REQUEST_SUCCESS);
        commit(ADMIN_DUS_RESPONSE_INTEGRATION, resp.data);
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
  [ADMIN_DUS_CLEAN]: ({ commit }) => {
    commit(ADMIN_DUS_REQUEST_CLEAN);
    return HTTP.patch('integration/live-dus/clean')
      .then((resp) => {
        commit(ADMIN_DUS_RESPONSE_CLEAN, resp.data);
      })
      .catch((err) => {
        commit(ADMIN_DUS_ERROR_CLEAN, err.response.data);
      });
  },
};

// mutations
const storeMutations = {
  [ADMIN_DUS_REQUEST]: (state) => {
    state.dusLoading = true;
    state.dusSuccess = null;
    state.dusResult = [];
    state.dusLastRequestTime = new Date();
    state.dusOperation = 'DUS Integration';
  },
  [ADMIN_DUS_RESPONSE]: (state, result) => {
    state.dusLoading = false;
    state.dusSuccess = result.success;
    state.dusResult = result.traces;
    state.dusOperation = 'DUS Integration';
  },
  [ADMIN_DUS_ERROR]: (state, result) => {
    state.dusLoading = false;
    state.dusSuccess = false;
    state.dusResult = result.traces;
    state.dusResult.push(result.message);
    state.dusResult.push(result.detail);
    state.dusOperation = 'DUS Integration';
  },
  [ADMIN_DUS_RESPONSE_INTEGRATION]: (state, result) => {
    state.dusIntegration = IntegrationFactory.createIntegrationFromData(result);
  },
  [ADMIN_DUS_REQUEST_CLEAN]: (state) => {
    state.dusLoading = true;
    state.dusSuccess = null;
    state.dusResult = [];
    state.dusLastRequestTime = new Date();
    state.dusOperation = 'DUS Integration Clean';
  },
  [ADMIN_DUS_RESPONSE_CLEAN]: (state, result) => {
    state.dusLoading = false;
    state.dusSuccess = result.success;
    state.dusResult = result.traces;
    state.dusOperation = 'DUS Integration Clean';
  },
  [ADMIN_DUS_ERROR_CLEAN]: (state, result) => {
    state.dusLoading = false;
    state.dusSuccess = false;
    state.dusResult = result.traces;
    state.dusResult.push(result.message);
    state.dusResult.push(result.detail);
    state.dusOperation = 'DUS Integration Clean';
  },
};

export default {
  state: storeState,
  getters: storeGetters,
  actions: storeActions,
  mutations: storeMutations,
};
