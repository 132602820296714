import { JSUtils } from '../../shared/js/JSUtils';
/* eslint no-param-reassign: "off" */
/* eslint no-return-assign: "off" */
export default class FlightTicketingInformation {
  constructor(
    id,
    status,
    user,
    ticketingDate,
    terminal,
    locator,
    quantity,
    paymentType,
    cardNumber,
    authorizationNumber,
    subtotal,
    total,
    totalAirline,
    fields,
    airlineFareId,
    airlineFareName,
    airlineFareDescription,
    airlineFareFields,
    airlineFareCost,
    airlineFareCurrency,
    airlineFareUnitsPerCost,
    airlineFareUnitsType,
    airlineFareTpyType,
    flightId,
    flightTripNumber,
    flightDate,
    airport1Id,
    airport1IataCode,
    airport1IcaoCode,
    airport1Code,
    airlineId,
    airlineCode,
    airlineIataCode,
    airlineIcaoCode,
    airlineDefaultCode,
    scheduledTime,
    comments,
    cashQuantity,
    cardQuantity,
    realQuantity,
    tenantId,
    tenantName,
    serialNumber,
    passengerFirstName,
    passengerLastName,
    passengerEmail,
    hasSerialNumber,
  ) {
    this.id = id;
    this.status = status;
    this.user = user;
    this.ticketingDate = ticketingDate;
    this.terminal = terminal;
    this.locator = locator;
    this.quantity = quantity;
    this.paymentType = paymentType || 'CASH';
    this.cardNumber = cardNumber;
    this.authorizationNumber = authorizationNumber;
    this.subtotal = subtotal;
    this.total = total;
    this.totalAirline = totalAirline;
    this.fields = fields || {};
    this.airlineFareId = airlineFareId;
    this.airlineFareName = airlineFareName;
    this.airlineFareDescription = airlineFareDescription;
    this.airlineFareFields = airlineFareFields;
    this.airlineFareCost = airlineFareCost;
    this.airlineFareCurrency = airlineFareCurrency;
    this.airlineFareUnitsPerCost = airlineFareUnitsPerCost;
    this.airlineFareUnitsType = airlineFareUnitsType;
    this.airlineFareTpyType = airlineFareTpyType;
    this.flightId = flightId;
    this.flightTripNumber = flightTripNumber;
    this.flightDate = flightDate;
    this.airport1Id = airport1Id;
    this.airport1IataCode = airport1IataCode;
    this.airport1IcaoCode = airport1IcaoCode;
    this.airport1Code = airport1Code;
    this.airlineId = airlineId;
    this.airlineCode = airlineCode;
    this.airlineIataCode = airlineIataCode;
    this.airlineIcaoCode = airlineIcaoCode;
    this.airlineDefaultCode = airlineDefaultCode;
    this.scheduledTime = scheduledTime;
    this.comments = comments;
    this.cashQuantity = cashQuantity;
    this.cardQuantity = cardQuantity;
    this.realQuantity = realQuantity;
    this.tenantId = tenantId;
    this.tenantName = tenantName;
    this.serialNumber = serialNumber;
    this.passengerFirstName = passengerFirstName;
    this.passengerLastName = passengerLastName;
    this.passengerEmail = passengerEmail;
    this.hasSerialNumber = hasSerialNumber;
  }

  flightNo() {
    if (this.airlineCode) {
      return `${this.airlineCode}${this.flightTripNumber}`;
    }
    if (this.airlineDefaultCode && (this.airlineIataCode || this.airlineIcaoCode)) {
      if (this.airlineDefaultCode === 'IATA_CODE' && this.airlineIataCode) {
        return `${this.airlineIataCode}${this.flightTripNumber}`;
      }
      if (this.airlineDefaultCode === 'ICAO_CODE' && this.airlineIcaoCode) {
        return `${this.airlineIcaoCode}${this.flightTripNumber}`;
      }
    }
    return '';
  }

  clone() {
    return FlightTicketingInformation.createFlightTicketingInformationFromData(JSON.parse(JSON.stringify(this)));
  }

  serializeTicketing() {
    const flightTicketing = this.clone();
    if (Array.isArray(flightTicketing)) {
      flightTicketing.forEach((ft) => {
        ft.fields = JSON.stringify(ft.fields);
        ft.airlineFareFields = JSON.stringify(ft.airlineFareFields);
      });
    } else {
      flightTicketing.fields = JSON.stringify(flightTicketing.fields);
      flightTicketing.airlineFareFields = JSON.stringify(flightTicketing.airlineFareFields);
    }
    return flightTicketing;
  }

  static get createEmptyFlightTicketingInformation() {
    return new FlightTicketingInformation();
  }

  static createFlightTicketingInformationFromData(o) {
    if (!o) {
      return this.createEmptyFlightTicketingInformation;
    }

    const flightTicketings = JSUtils.cast(o, FlightTicketingInformation);
    if (flightTicketings && Array.isArray(flightTicketings)) {
      flightTicketings.forEach(ft => ft.airlineFareFields = typeof ft.airlineFareFields === 'string' ? JSON.parse(ft.airlineFareFields) : ft.airlineFareFields);
      flightTicketings.forEach((ft) => {
        ft.fields = ft.fields ? ft.fields : {};
        ft.fields = typeof ft.fields === 'string' ? JSON.parse(ft.fields) : ft.fields;
      });
    } else if (flightTicketings) {
      flightTicketings.fields = flightTicketings.fields ? flightTicketings.fields : {};
      flightTicketings.fields = typeof flightTicketings.fields === 'string' ? JSON.parse(flightTicketings.fields) : flightTicketings.fields;
      flightTicketings.airlineFareFields = typeof flightTicketings.airlineFareFields === 'string' ? JSON.parse(flightTicketings.airlineFareFields) : flightTicketings.airlineFareFields;
    }

    return flightTicketings;
  }
}
