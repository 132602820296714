<template>
  <div class="input-width">
    <v-menu
      :close-on-content-click="false"
      v-model="menu"
      :nudge-right="40"
      lazy
      transition="scale-transition"
      offset-y
      full-width
      min-width="290px" :disabled="disabled"
    >
      <v-text-field
        :ref="'refTimeInput'"
        slot="activator"
        :label="label"
        prepend-icon="access_time"
        :rules="rules"
        :disabled="disabled"
        v-model="formattedValue"
        @change="inputChange"
        :return-masked-value="true"
        hide-details
        :mask="timeMask"
        v-on:keydown.native="keyEventTimeInput"
        @dblclick="dblclick"
      ></v-text-field>
      <v-time-picker
        format="24hr"
        :value="timeValue"
        @input="onInput"
        @change="timePickerChange"
        @click:minute="menu = false">
      </v-time-picker>
    </v-menu>
  </div>
</template>

<script>
import Arrowable from '../mixins/Arrowable.vue';
import TemporalCheckable from '../mixins/TemporalCheckable.vue';
import { keyEvents } from '../keyEvents';

export default {
  name: 'TimePicker',
  mixins: [Arrowable, TemporalCheckable],
  props: {
    value: { required: true },
    label: { type: String },
    rules: { type: Array },
    disabled: { type: Boolean },
  },
  data() {
    return {
      menu: false,
      timeMoment: null,
      formattedValue: '',
      timeMask: '##:##',
    };
  },
  computed: {
    timeValue() {
      return this.timeMoment ? this.timeMoment.format('HH:mm') : null;
    },
  },
  methods: {
    keyEventTimeInput($event) {
      this.keyEvent($event);

      if ($event.code === keyEvents.UP || $event.code === keyEvents.DOWN) {
        if (this.timeMoment) {
          const timeMomentAux = this.$moment(this.timeMoment);
          timeMomentAux.add($event.code === keyEvents.UP ? 1 : -1, 'minutes');
          this.onInput(timeMomentAux);
        }
      }

      if ($event.code === keyEvents.TAB) {
        this.inputChange();
      }
    },
    onInput(value) {
      this.setTimeMoment(value);
      this.setFormattedTimeFromTimeMoment();
      // this.menu = false;
      this.emitInput();
    },
    setTimeMoment(value) {
      if (this.isTemporalObject(value)) {
        this.timeMoment = this.$moment(value);
      } else {
        const timeMomentAux = this.timeMoment ? this.timeMoment : this.$moment();
        this.timeMoment = timeMomentAux
          .hours(value.split(':')[0])
          .minutes(value.split(':')[1])
          .seconds(0)
          .milliseconds(0);
      }
    },
    timePickerChange(value) {
      this.$emit('change', value);
    },
    inputChange() {
      this.timeMoment = this.formattedValue ? this.$moment(this.formattedValue, 'HH:mm') : null;
      this.emitInput();
    },
    setFormattedTimeFromTimeMoment() {
      this.formattedValue = this.timeMoment ? this.timeMoment.format('HH:mm') : '';
    },
    emitInput() {
      this.$emit('input', this.timeMoment);
    },
    dblclick() {
      if (!this.timeMoment) {
        const timeMomentAux = this.$moment();
        this.onInput(timeMomentAux);
      }
    },
  },
  mounted() {
    this.timeMoment = this.value ? this.$moment(this.value) : null;
    this.setFormattedTimeFromTimeMoment();
  },
  watch: {
    value(newValue) {
      this.timeMoment = newValue ? this.$moment(newValue) : null;
      this.setFormattedTimeFromTimeMoment();
    },
  },
};
</script>

<style scoped>
  .input-width {
    max-width: 150px;
  }
  .menuable__content__active {
    z-index: 300 !important;
  }
</style>
