<template>
  <div class="admin-scheduling-imports-by-day px-2">
    <v-toolbar flat :dark="this.$vuetify.dark" class="pa-0">
      <v-toolbar-title>{{ dayLabel }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn small icon class="admin-scheduling-imports-by-day-drop-down-button"
        :class="{ 'admin-scheduling-imports-by-day-drop-down-button-open-drop-down': !dropDownActivator }"
        @click="dropDownActivator = !dropDownActivator">
        <img height="50" :src="require(`@/assets/svg-icons/admin-imports/files-by-day-activator.svg`)">
      </v-btn>
    </v-toolbar>
    <hr color="#ad0600" class="mt-0">
    <transition name="fade">
      <div v-if="dropDownActivator" class="admin-scheduling-imports-by-day-body dropDownActivator">
        <v-container class="pa-0" fluid>
          <v-layout row wrap class="pl-2">
            <v-flex v-for="fileImported in filesImported" :key="fileImported.id" xs12 sm6 md4 lg3 class="pr-2 pb-2">
              <AdminSchedulingImportsFileCard :schedulingImportFile="fileImported" />
            </v-flex>
          </v-layout>
        </v-container>
      </div>
    </transition>
  </div>
</template>
<script>
import AdminSchedulingImportsFileCard from './AdminSchedulingImportsFileCard.vue';

export default {
  name: 'AdminSchedulingImportsByDay',
  data() {
    return {
      dropDownActivator: true,
    };
  },
  components: {
    AdminSchedulingImportsFileCard,
  },
  props: {
    day: {
      type: String,
      required: true,
    },
  },
  computed: {
    dayLabel() {
      const momentInCurrentLanguage = this.$moment(this.day).locale(this.$i18n.locale);
      const dayLabel = `${momentInCurrentLanguage.format('dddd, DD')} ${this.$i18n.t('common.of')} ${momentInCurrentLanguage.format('MMMM')} ${this.$i18n.t('common.of')} ${momentInCurrentLanguage.format('YYYY')}`;
      return `${dayLabel.charAt(0).toUpperCase()}${dayLabel.slice(1)}`;
    },
    filesImported() {
      return this.$store.getters.getSchedulingImportFilesByDay(this.day);
    },
  },
};
</script>

<style lang="scss">
.admin-scheduling-imports-by-day {
  width: 100%;
  height: fit-content;
  margin: 15px 0 0 0;


  /* Definir la animación de contraerse hacia arriba */
  .fade-enter-active, .fade-leave-active {
    transition: all 0.25s ease;
  }

  .fade-enter, .fade-leave-to /* .fade-leave-active en <2.1.8 */ {
    opacity: 0;
    transform: translateY(-50%) scaleY(0);
  }
}


.admin-scheduling-imports-by-day-drop-down-button {
  border-radius: 50%;
  padding: 0;
  max-width: 45px;
  /* TODO: fit */
  width: 45px;
  /* TODO: fit */
  min-width: 45px;
  /* TODO: fit */
  max-height: 45px;
  /* TODO: fit */
  height: 45px;
  /* TODO: fit */
  min-height: 45px;
  /* TODO: fit */
}

.admin-scheduling-imports-by-day-drop-down-icon {
  width: 100%;
  height: 100%;
  color: #fe0900;
}

.admin-scheduling-imports-by-day-drop-down-button-open-drop-down {
  transform: rotate(180deg);
  transition: transform 0.5s;
}

.admin-scheduling-imports-by-day-body {
  margin: 5px 0 0 0;
  height: fit-content;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  overflow: auto;
}
</style>
