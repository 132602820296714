<template>
  <div>
    <v-autocomplete
      placeholder="..."
      :value="value"
      :label="label"
      :items="items"
      :filter="customFilter"
      :clearable="true"
      :item-text="getItemText"
      item-value="id"
      :rules="rules"
      :disabled="disabled"
      @input="$emit('input', $event)"
      :ref="refComp"
      @change="onChangeSelection"
      hide-details
    >
      <template
        slot="item"
        slot-scope="{ item }"
      >
        <v-list-tile-content>
          <v-list-tile-title>{{ getAircraftTypeTitle(item) }}</v-list-tile-title>
          <v-list-tile-sub-title>{{ item.comments }}</v-list-tile-sub-title>
        </v-list-tile-content>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import { GET_AIRCRAFT_TYPES } from '../../store/modules/aircraftTypes/aircraftTypesCatalog';

export default {
  props: {
    value: { required: true },
    label: { type: String },
    charLenght: { type: Number },
    rules: { type: Array },
    disabled: { type: Boolean },
    refComp: {
      type: String,
      required: false,
      default: 'refComp',
    },
  },
  computed: {
    items() {
      return this.$store.getters.getActiveAircraftTypes;
    },
  },
  methods: {
    getItemText(item) {
      return item && item.code ? item.code : '';
    },
    getAircraftTypes() {
      this.$store.dispatch(GET_AIRCRAFT_TYPES);
    },
    customFilter(item, queryText) {
      const textOne = item.iataCode ? item.iataCode.toLowerCase() : '';
      const textTwo = item.icaoCode ? item.icaoCode.toLowerCase() : '';
      const searchText = queryText.toLowerCase();

      return textOne.startsWith(searchText) || textTwo.startsWith(searchText);
    },
    onChangeSelection(selectionId) {
      const aircraft = this.items.filter(a => a.id === selectionId)[0];
      this.$emit('change', aircraft);
    },
    getAircraftTypeTitle(item) {
      const codes = [];
      if (item.iataCode) {
        codes.push(item.iataCode);
      }
      if (item.icaoCode) {
        codes.push(item.icaoCode);
      }
      return codes.join(' - ');
    },
  },
  mounted() {
    this.getAircraftTypes();
  },
};
</script>
