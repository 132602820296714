<template>
  <v-card-text>
    <v-container grid-list-md>
      <v-layout wrap justify-start>
        <v-flex xs4>
          <v-text-field label="Name" v-model="item.contact.name"/>
        </v-flex>
        <v-flex xs4>
          <v-text-field label="Phone" v-model="item.contact.phone"/>
        </v-flex>
        <v-flex xs6>
          <v-text-field label="E-mail" v-model="item.contact.mail"/>
        </v-flex>
        <v-flex xs4>
          <v-text-field label="Street" v-model="item.contact.addressStreet"/>
        </v-flex>
        <v-flex xs2>
          <v-text-field label="Postal code" v-model="item.contact.addressPostalCode"/>
        </v-flex>
        <v-flex xs4>
          <v-text-field label="City" v-model="item.contact.addressCity"/>
        </v-flex>
        <v-flex xs6>
          <v-text-field label="Region" v-model="item.contact.addressRegion"/>
        </v-flex>
        <v-flex xs6>
          <v-text-field label="Country" v-model="item.contact.addressCountry"/>
        </v-flex>
        <v-flex xs12>
          <v-textarea label="Comment" hint="Hint text" v-model="item.contact.description"/>
        </v-flex>
      </v-layout>
    </v-container>
  </v-card-text>
</template>

<script>
export default {
  name: 'ContactEditModalContent',
  props: {
    item: { type: Object, required: true },
  },
};
</script>
