export const ROLE_STATES = ['NONE', 'WRITE', 'READ'];
export const ROLE_NONE = 'NONE';
export const ROLE_WRITE = 'WRITE';
export const ROLE_READ = 'READ';
export const ROLE_ADMIN = 'ROLE_ADMIN';
export const ROLE_AVINET = 'ROLE_AVINET';
export const ROLE_BAGGAGE = 'ROLE_BAGGAGE';
export const ROLE_BUSES = 'ROLE_BUSES';
export const ROLE_CONFIG = 'ROLE_CONFIG';
export const ROLE_CONFIG_AIRPORT_BELTS = 'ROLE_CONFIG_AIRPORT_BELTS';
export const ROLE_CONFIG_AIRPORT_GATES = 'ROLE_CONFIG_AIRPORT_GATES';
export const ROLE_CONFIG_AIRPORT_FLIGHT_WEIGHTING = 'ROLE_CONFIG_AIRPORT_FLIGHT_WEIGHTING';
export const ROLE_CONFIG_AIRPORT_SERVICE_AGENTS = 'ROLE_CONFIG_AIRPORT_SERVICE_AGENTS';
export const ROLE_CONFIG_AIRPORT_TICKETING_NUMERATION = 'ROLE_CONFIG_AIRPORT_TICKETING_NUMERATION';
export const ROLE_CONFIG_AIRPORT_CAROUSELS = 'ROLE_CONFIG_AIRPORT_CAROUSELS';
export const ROLE_CONFIG_AIRPORT_PARKING = 'ROLE_CONFIG_AIRPORT_PARKING';
export const ROLE_CONFIG_AIRPORT_RAMP_SERVICES = 'ROLE_CONFIG_AIRPORT_RAMP_SERVICES';
export const ROLE_CONFIG_AIRPORT_MESSAGE_DIRECTORY = 'ROLE_CONFIG_AIRPORT_MESSAGE_DIRECTORY';
export const ROLE_CONFIG_AIRPORT_SLA_BAGGAGE_DELIVERY = 'ROLE_CONFIG_AIRPORT_SLA_BAGGAGE_DELIVERY';
export const ROLE_CONFIG_AIRPORT_CHECK_IN_COUNTERS = 'ROLE_CONFIG_AIRPORT_CHECK_IN_COUNTERS';
export const ROLE_CONFIG_AIRPORT_AIC_DOCUMENTS = 'ROLE_CONFIG_AIRPORT_AIC_DOCUMENTS';
export const ROLE_CONFIG_AIRPORT_REQUESTERS = 'ROLE_CONFIG_AIRPORT_REQUESTERS';
export const ROLE_CONFIG_AIRPORT_HANDLING_TYPES = 'ROLE_CONFIG_AIRPORT_HANDLING_TYPES';
export const ROLE_CONFIG_AIRPORT_DEFAULT_CHECK_IN_TIME = 'ROLE_CONFIG_AIRPORT_DEFAULT_CHECK_IN_TIME';
export const ROLE_CONFIG_AIRPORT_DEFAULT_BOARDING_TIME = 'ROLE_CONFIG_AIRPORT_DEFAULT_BOARDING_TIME';
export const ROLE_CONFIG_AIRPORT = 'ROLE_CONFIG_AIRPORT';
export const ROLE_CONFIG_AIRLINE = 'ROLE_CONFIG_AIRLINE';
export const ROLE_CONFIG_AIRCRAFT_TYPES = 'ROLE_CONFIG_AIRCRAFT_TYPES';
export const ROLE_CONFIG_AIRCRAFT = 'ROLE_CONFIG_AIRCRAFT';
export const ROLE_CONFIG_DELAY_TYPES = 'ROLE_CONFIG_DELAY_TYPES';
export const ROLE_CONFIG_DELAY_REASONS = 'ROLE_CONFIG_DELAY_REASONS';
export const ROLE_CONFIG_RAMP_SERVICES = 'ROLE_CONFIG_RAMP_SERVICES';
export const ROLE_CONFIG_USERS = 'ROLE_CONFIG_USERS';
export const ROLE_CONFIG_HANDLING_AGENTS = 'ROLE_CONFIG_HANDLING_AGENTS';
export const ROLE_CONFIG_SERVICE_AGENTS = 'ROLE_CONFIG_SERVICE_AGENTS';
export const ROLE_CONFIG_AVINET_USERS = 'ROLE_CONFIG_AVINET_USERS';
export const ROLE_CONFIG_SERVICE_TYPE = 'ROLE_CONFIG_SERVICE_TYPE';
export const ROLE_CONFIG_AIRLINE_FARES = 'ROLE_CONFIG_AIRLINE_FARES';
export const ROLE_CONTINGENCY = 'ROLE_CONTINGENCY';
export const ROLE_DELAYS = 'ROLE_DELAYS';
export const ROLE_DELIVERIES = 'ROLE_DELIVERIES';
export const ROLE_DIRECTORY = 'ROLE_DIRECTORY';
export const ROLE_DISPATCHER = 'ROLE_DISPATCHER';
export const ROLE_DUTY = 'ROLE_DUTY';
export const ROLE_FILE_NUMBER = 'ROLE_FILE_NUMBER';
export const ROLE_FLIGHTS_WEIGHT = 'ROLE_FLIGHTS_WEIGHT';
export const ROLE_GANTT = 'ROLE_GANTT';
export const ROLE_GHM = 'ROLE_GHM';
export const ROLE_IATA = 'ROLE_IATA';
export const ROLE_IATA_WR = 'ROLE_IATA_WR';
export const ROLE_INBOUNDS = 'ROLE_INBOUNDS';
export const ROLE_INFORM = 'ROLE_INFORM';
export const ROLE_INVOICING = 'ROLE_INVOICING';
export const ROLE_LOST_FOUND = 'ROLE_LOST_FOUND';
export const ROLE_OPERATIONS = 'ROLE_OPERATIONS';
export const ROLE_OUTBOUNDS = 'ROLE_OUTBOUNDS';
export const ROLE_RAMP = 'ROLE_RAMP';
export const ROLE_RUSH_BAGGAGE = 'ROLE_RUSH_BAGGAGE';
export const ROLE_SCHEDULING = 'ROLE_SCHEDULING';
export const ROLE_WATER_TOILET = 'ROLE_WATER_TOILET';
export const ROLE_ADMINISTRATOR_TICKETING = 'ROLE_ADMINISTRATOR_TICKETING';
export const ROLE_CASHIER_TICKETING = 'ROLE_CASHIER_TICKETING';
export const ROLE_SUPERVISOR_TICKETING = 'ROLE_SUPERVISOR_TICKETING';
export const ROLE_TOWING_ADHOC_TASKS_APPLICANT = 'ROLE_TOWING_ADHOC_TASKS_APPLICANT';
export const ROLE_TOWING_ADHOC_TASKS_DISPATCHER = 'ROLE_TOWING_ADHOC_TASKS_DISPATCHER';
export const ROLE_CONFIG_MILESTONES = 'ROLE_CONFIG_MILESTONES';
export const ROLE_CONFIG_AIRPORT_MILESTONES = 'ROLE_CONFIG_AIRPORT_MILESTONES';
export const AIRPORT_SERVICE_AGENT_ROLE_BAGGAGE_DISTRIBUTION = 'AIRPORT_SERVICE_AGENT_ROLE_BAGGAGE_DISTRIBUTION';
export const AIRPORT_SERVICE_AGENT_ROLE_BAGGAGE_DISTRIBUTION_WR = 'AIRPORT_SERVICE_AGENT_ROLE_BAGGAGE_DISTRIBUTION_WR';
export const AIRPORT_SERVICE_AGENT_ROLE_MAIL_PERMISSION = 'AIRPORT_SERVICE_AGENT_ROLE_MAIL_PERMISSION';
export const AIRPORT_SERVICE_AGENT_ROLE_MAIL_PERMISSION_WR = 'AIRPORT_SERVICE_AGENT_ROLE_MAIL_PERMISSION_WR';
export const AIRPORT_SERVICE_AGENT_ROLE_BAGGAGE_SEARCH = 'AIRPORT_SERVICE_AGENT_ROLE_BAGGAGE_SEARCH';
