<template>
  <div v-if="flightMobile" class="mobileView">
    <info-mobile-flight-header :backArrow="backArrow" :flightMobile="flightMobile"
      :filterDate="flightMobile.flightDate" />
    <info-mobile-parking-header :parkingCode="flightMobile.parkingCode" :gateCode="flightMobile.gateCode" />
    <info-mobile-plane-header :flightMobile="flightMobile" />
    <v-flex>
      <template>
        <div>
          <v-layout row wrap class="flex justify-center icons-container">
            <template v-for="icon in  icons ">
              <v-flex :key="icon.id" xs12 sm6 md6 lg6 xl4 :class="icon.class" v-if="hasPermissionButton(icon.role)">
                <v-card class="icon-card" @click="icon.action">
                  <v-card-text class="text-center">
                    <v-badge v-if="icon.badge" color="red" overlap>
                      <template v-slot:badge>
                        <span>{{ icon.badgeValue }}</span>
                      </template>
                    </v-badge>
                    <v-icon size="64" :class="icon.badge ? 'icon-badge' : ''">{{ icon.icon }}</v-icon>
                    <div class="label">{{ icon.label }}</div>
                  </v-card-text>
                </v-card>
              </v-flex>
            </template>
          </v-layout>
        </div>
      </template>
    </v-flex>
    <v-layout>
      <div v-if="bottomNav === 'additionalInfo'">
        <mobileAdditionalInfo :flight-id="flightId" :filterDate="flightMobile.flightDate" />
      </div>
    </v-layout>
  </div>
</template>

<script>

import { TIME_TIMEZONE_CHANGED } from '../../store/modules/time';
import StoreSubscribable from '../../components/mixins/StoreSubscribable.vue';
import { MOBILE_GET_FLIGHT } from '../../store/modules/mobile';
import RolePermissions from '../../components/mixins/RolePermissions.vue';

import infoMobileFlightHeader from '../../components/mobile/infoMobileFlightHeader.vue';
import infoMobileParkingHeader from '../../components/mobile/infoMobileParkingHeader.vue';
import infoMobilePlaneHeader from '../../components/mobile/infoMobilePlaneHeader.vue';

// import '../../assets/scss/ag-grid-fis-theme.scss';

export default {
  name: 'mobile-info-view',
  props: {
    flightId: {
      required: false,
    },
    flightMobileProps: Object,
  },
  mixins: [StoreSubscribable, RolePermissions],
  components: {
    infoMobileFlightHeader,
    infoMobileParkingHeader,
    infoMobilePlaneHeader,
  },
  data() {
    return {
      filterDate: '',
      bottomNav: 'all',
      visibilityControl: true,
    };
  },
  computed: {
    icons() {
      return [
        {
          id: 1,
          icon: 'info',
          class: 'additionalInfo',
          label: this.$t('dispatcher.additionalInfo'),
          role: 'ROLE_DISPATCHER',
          action: this.goToInfo,
          badge: false,
          badgeValue: 0,
        },
        {
          id: 2,
          icon: 'history',
          class: 'milestonesAndServicesRecord',
          label: this.$t('dispatcher.milestonesAndServicesRecord'),
          role: 'ROLE_DISPATCHER',
          action: this.goToServiceMilestones,
          badge: false,
          badgeValue: 0,
        },
        {
          id: 3,
          icon: 'work',
          class: 'baggageDistribution',
          label: this.$t('dispatcher.baggageDistribution'),
          role: 'AIRPORT_SERVICE_AGENT_ROLE_BAGGAGE_DISTRIBUTION',
          action: this.goToBaggageDistribution,
          badge: false,
          badgeValue: 0,
        },
        {
          id: 4,
          icon: 'mail',
          class: 'messaging',
          label: this.$t('dispatcher.messaging'),
          role: 'AIRPORT_SERVICE_AGENT_ROLE_MAIL_PERMISSION',
          action: this.goToMessaging,
          badge: false,
          badgeValue: 0,
        },
        {
          id: 5,
          icon: 'mdi-briefcase-search',
          class: 'baggageSearch',
          label: this.$t('dispatcher.baggageSearch'),
          role: 'AIRPORT_SERVICE_AGENT_ROLE_BAGGAGE_SEARCH',
          action: this.goToBaggageSearch,
          badge: this.flightMobile.missingPaxAlert,
          badgeValue: this.flightMobile.missingPaxNumber,
        },
      ];
    },
    rolePermission() {
      return `${this.$route.meta.role}_WR`;
    },
    flightMobile() {
      return this.$store.getters.getFlightMobile;
    },
    user() {
      return this.$store.getters.user;
    },
  },
  methods: {

    hasPermissionButton(role) {
      if (this.hasPermissions(role)) {
        return true;
      }
      if (this.hasPermissions(`${role}_WR`)) {
        return true;
      }
      return false;
    },
    goToInfo() {
      this.$router.push({ name: 'additionalInfo', params: { flightId: this.flightId, flightMobileProps: this.flightMobile } });
    },
    goToServiceMilestones() {
      this.$router.push({ name: 'serviceMilestones', params: { flightId: this.flightId, flightMobileProps: this.flightMobile } });
    },
    goToBaggageDistribution() {
      this.$router.push({ name: 'baggageDistribution', params: { flightId: this.flightId, flightMobileProps: this.flightMobile } });
    },
    goToMessaging() {
      this.$router.push({ name: 'mobileMessages', params: { flightId: this.flightId, flightMobileProps: this.flightMobile } });
    },
    goToBaggageSearch() {
      this.$router.push({ name: 'baggageSearch', params: { flightId: this.flightId, flightMobileProps: this.flightMobile } });
    },
    backArrow() {
      this.$router.push({ name: 'mobile', params: { filterDate: this.flightMobile.flightDate } });
    },
  },
  watch: {
    // when flightMobile is loaded, check if user has permission to see the flight
    flightMobile: {
      handler() {
        if (!this.hasPermissions('AIRPORT_SERVICE_AGENT_ROLE_FILTER_BY_AGENT')) {
          if (this.flightMobile.operationAgent !== this.user.email) {
            this.$router.push({ name: 'mobile' });
          }
        }
      },
      deep: true,
    },
  },
  created() {
    this.subscribeMutation(TIME_TIMEZONE_CHANGED, () => {
      this.visibilityControl = false;
      this.visibilityControl = true;
    });
    if (!this.flightId) {
      this.$router.push({ name: 'mobile' });
    } else if (!this.flightMobileProps) {
      this.$store.dispatch(MOBILE_GET_FLIGHT, this.flightId);
    }
  },
};
</script>
