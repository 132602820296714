<template>
  <div>
    <entity-configuration-list
      :entity-name="this.$i18n.t('configuration.defaultBoardingTime')"
      entity-icon="safety_check"
      :framework-components="frameworkComponents"
      :column-defs="columnDefs"
      :items="airportDefaultBoardingTime"
      @confirm-edit-item="editConfirm"
      @confirm-enable-disable-item="enableDisableConfirm"
    >
      <template v-slot:modal-content="{ itemEdit }">
        <airport-default-boarding-time-edit-card-content :item="itemEdit" />
      </template>
    </entity-configuration-list>
  </div>
</template>
<script>
import {
  AIRPORT_DEFAULT_BOARDING_TIME_CREATE,
  AIRPORT_DEFAULT_BOARDING_TIME_UPDATE,
  GET_AIRPORT_DEFAULT_BOARDING_TIME,
  AIRPORT_DEFAULT_BOARDING_TIME_ENABLE,
  AIRPORT_DEFAULT_BOARDING_TIME_DISABLE,
} from '@/store/modules/airportDefaultBoardingTime/airportDefaultBoardingTime';
import EntityConfigurationList from '@/components/configuration/common/EntityConfigurationList.vue';
import AirportDefaultBoardingTimeEditCardContent from './AirportDefaultBoardingTimeEditCardContent.vue';
import AgGridRowActionButtonsCellRenderer from '../../ag-grid/cell-renderers/AgGridRowActionButtonsCellRenderer.vue';
import AgGridIconCellRenderer from '../../ag-grid/cell-renderers/AgGridIconCellRenderer.vue';

export default {
  name: 'AirportDefaultBoardingTimeList',
  components: {
    EntityConfigurationList,
    AirportDefaultBoardingTimeEditCardContent,
  },
  data() {
    const that = this;
    return {
      frameworkComponents: {
        AgGridRowActionButtonsCellRenderer,
        AgGridIconCellRenderer,
      },
      columnDefs: [
        {
          field: 'airlineCode',
          headerValueGetter() {
            return that.$i18n.t('common.airline');
          },
          valueGetter(params) {
            if (params.data.airlineDefaultCode === 'IATA_CODE' && params.data.airlineIataCode) {
              return params.data.airlineIataCode;
            }
            if (params.data.airlineDefaultCode === 'ICAO_CODE' && params.data.airlineIcaoCode) {
              return params.data.airlineIcaoCode;
            }

            return (params.data.airlineIataCode) ? params.data.airlineIataCode : params.data.airlineIcaoCode;
          },
          sort: 'asc',
        },
        {
          field: 'startTime',
          headerValueGetter() {
            return that.$i18n.t('configuration.startBoarding');
          },
        },
        {
          field: 'endTime',
          headerValueGetter() {
            return that.$i18n.t('configuration.endBoarding');
          },
        },
        {
          field: 'timeType',
          headerValueGetter() {
            return that.$i18n.t('configuration.flightTimeReference');
          },
          valueGetter(params) {
            if (params && params.data && params.data.basedOnScheduledTime) {
              return that.$i18n.t('configuration.scheduledTime');
            } if (params && params.data && params.data.basedOnEstimatedTime) {
              return that.$i18n.t('configuration.estimatedTime');
            }
            return null;
          },
        },
        {
          headerValueGetter() {
            return that.$i18n.t('configuration.disabled');
          },
          field: 'deleted',
          cellRendererFramework: AgGridIconCellRenderer,
          cellRendererParams: {
            icon: 'mdi-cancel',
            tooltip: params => (params.data.deleted ? this.$i18n.t('configuration.disabled') : null),
          },
        },
      ],
    };
  },
  computed: {
    airportDefaultBoardingTime() {
      return this.$store.getters.getAirportDefaultBoardingTime;
    },
  },
  methods: {
    getAirportDefaultBoardingTime() {
      this.$store.dispatch(GET_AIRPORT_DEFAULT_BOARDING_TIME);
    },
    editConfirm(item) {
      this.$store.dispatch(
        item.id
          ? AIRPORT_DEFAULT_BOARDING_TIME_UPDATE
          : AIRPORT_DEFAULT_BOARDING_TIME_CREATE,
        item,
      );
    },
    enableDisableConfirm(item) {
      this.$store.dispatch(
        item.deleted
          ? AIRPORT_DEFAULT_BOARDING_TIME_ENABLE
          : AIRPORT_DEFAULT_BOARDING_TIME_DISABLE,
        item.id,
      );
    },
  },
  created() {
    this.getAirportDefaultBoardingTime();
  },
};
</script>
