<template>
  <v-app :class="theme">
    <the-sidebar v-model="show" v-if="isAuthenticated && show" />
    <the-navbar @sidebarToggle="sidebarToggle" v-if="isAuthenticated" :dark="theme === 'dark-mode'" />
    <v-content style="padding-left: 0px" :dark="theme === 'dark-mode'">
      <div class="menu-content-toggle" v-if="show" @click="sidebarToggle"></div>
      <loading-component />
      <router-view />
      <notification-component />
    </v-content>
    <version-checker />
  </v-app>
</template>

<script>


import TheNavbar from './components/layout/TheNavbar.vue';
import TheSidebar from './components/layout/TheSidebar.vue';
import LoadingComponent from './components/layout/LoadingComponent.vue';
import NotificationComponent from './components/layout/NotificationComponent.vue';
import TimezoneConfigurable from './components/mixins/TimezoneConfigurable.vue';
import { AUTH_CHECK } from './store/modules/auth';
import VersionChecker from './components/messages/VersionChecker.vue';

// import { TENANT_CURRENT_GET } from './store/modules/tenant';

export default {
  name: 'App',
  mixins: [TimezoneConfigurable],
  components: {
    NotificationComponent, LoadingComponent, TheSidebar, TheNavbar, VersionChecker,
  },
  created() {
    this.setTheme();
    // Cargar el tema desde la cookie al inicializar el componente
    this.setGlobalTimezone('UTC');
    this.$store.dispatch(AUTH_CHECK).then(() => {
      console.log('auth request app'); // eslint-disable-line no-console
      // console.log(data); // eslint-disable-line no-console
      // TODO si importo la constante falla vuex al cargar
      this.$store.dispatch('TENANT_CURRENT_GET');
      // if (window.location.hostname !== 'localhost') {
      this.$store.dispatch('DESK_CURRENT_GET'); // TODO
      // }
    });
  },
  computed: {
    isAuthenticated() {
      return this.$store.getters.isAuthenticated;
    },
  },
  data() {
    return {
      theme: 'light-mode',
      show: false,
    };
  },
  watch: {
    '$vuetify.dark': {
      handler() {
        this.setTheme();
      },
      deep: true,
    },
  },
  methods: {
    sidebarToggle() {
      this.show = !this.show;
    },
    setTheme() {
      this.theme = this.$vuetify.dark ? 'dark-mode' : 'light-mode';
    },
  },
};
</script>
<style lang="scss">
//TODO: FIX NO LOAD IMAGES FROM THEME ag-grid-fis-theme.scss
.ag-theme-material .ag-icon-checkbox-checked:empty {
  background-image: url("./assets/baseline-check_box-24px.svg") !important;
}

.ag-theme-material .ag-icon-checkbox-unchecked {
  background-image: url("./assets/baseline-check_box_outline_blank-24px.svg") !important;
}

.menu-content-toggle {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.35);
}

/*  DARK MODE */
.dark-mode {

  .theme--light.v-icon {
      color: rgba(255,255,255,0.54);
  }
  .v-content__wrap {
    background-color: #121212;
  }
  .ag-theme-material .ag-icon-checkbox-unchecked {
    filter: invert(1);
  }
}

html {
  overflow: auto;
}

.application {
  overflow: hidden;
}
</style>
