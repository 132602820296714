/* eslint no-param-reassign: "off" */
import { HTTP } from '../../../http';

// action
export const ADMIN_FILE_NUMBER = 'ADMIN_FILE_NUMBER';

// mutations
export const ADMIN_FILE_NUMBER_REQUEST = 'ADMIN_FILE_NUMBER_REQUEST';
export const ADMIN_FILE_NUMBER_RESPONSE = 'ADMIN_FILE_NUMBER_RESPONSE';
export const ADMIN_FILE_NUMBER_ERROR = 'ADMIN_FILE_NUMBER_ERROR';

// initial state
const storeState = {
  fileNumberLoading: null,
  fileNumberSuccess: null,
  fileNumberLastRequestTime: null,
};

// getters
const storeGetters = {
  isFileNumberSuccess: state => state.fileNumberSuccess != null && !!state.fileNumberSuccess,
  isFileNumberError: state => state.fileNumberSuccess != null && !state.fileNumberSuccess,
};

// actions
const storeActions = {
  [ADMIN_FILE_NUMBER]: ({ commit }) => {
    commit(ADMIN_FILE_NUMBER_REQUEST);
    return HTTP.put('/file-number/flights')
      .then(() => {
        commit(ADMIN_FILE_NUMBER_RESPONSE);
      })
      .catch((err) => {
        commit(ADMIN_FILE_NUMBER_ERROR, err.response.data);
      });
  },
};

// mutations
const storeMutations = {
  [ADMIN_FILE_NUMBER_REQUEST]: (state) => {
    state.fileNumberLoading = true;
    state.fileNumberSuccess = null;
    state.fileNumberResult = [];
    state.fileNumberLastRequestTime = new Date();
  },
  [ADMIN_FILE_NUMBER_RESPONSE]: (state) => {
    state.fileNumberLoading = false;
    state.fileNumberSuccess = true;
  },
  [ADMIN_FILE_NUMBER_ERROR]: (state) => {
    state.fileNumberLoading = false;
    state.fileNumberSuccess = false;
  },
};

export default {
  state: storeState,
  getters: storeGetters,
  actions: storeActions,
  mutations: storeMutations,
};
