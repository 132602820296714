<script>
import Vue from 'vue';
import { WindowUtils } from '../shared/js/windowUtils';

export default Vue.extend({
  methods: {
    resized() {
      console.warn('On resize should be implemented'); // eslint-disable-line no-console
    },
    getTextResizable(textMd, textXl) {
      return (WindowUtils.isXLWidth()) ? textXl : textMd;
    },
  },
  mounted() {
    window.addEventListener('resize', this.resized);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resized);
  },

});
</script>
