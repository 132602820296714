<template>
  <div>
    <aircraft-config-typeahead :ref="'refComp'" v-on:keydown.native="keyEvent" v-char-length.clearable="7" v-model="aircraftConfigCodeUpdated" label="Config" @input-id="setAircraftConfigId"></aircraft-config-typeahead>
  </div>
</template>

<script>
import Vue from 'vue';
import AircraftConfigTypeahead from '../../../aircraft/AircraftConfigTypeahead.vue';
import AgGridParentConnectedFlightsCellEditor from '../mixins/AgGridParentConnectedFlightsCellEditor.vue';
import { FORCE_GET_AIRCRAFT_CONFIGS } from '../../../../store/modules/flightCatalogs';

export default Vue.extend({
  mixins: [AgGridParentConnectedFlightsCellEditor],
  components: {
    AircraftConfigTypeahead,
  },
  data() {
    return {
      aircraftConfigIdOriginal: null,
      aircraftConfigCodeOriginal: null,
      aircraftConfigCodeUpdated: null,
      aircraftConfigIdUpdated: null,
    };
  },
  methods: {
    getValue() {
      this.unmarkCellsSelectedAsEdit();

      if (this.aircraftConfigCodeOriginal !== this.aircraftConfigCodeUpdated) {
        const that = this;

        this.saveFlightChangesFromValue(
          (s) => {
            s.setFlightPropertyAllBound('aircraftConfigId', that.aircraftConfigIdUpdated);
            s.setFlightPropertyAllBound('aircraftConfigCode', that.aircraftConfigCodeUpdated);
          },
          'Aircraft Config',
          this.aircraftConfigIdOriginal,
          this.aircraftConfigIdUpdated,
          () => {
            if (this.aircraftConfigIdUpdated == null && this.aircraftConfigCodeUpdated != null) {
              this.$store.dispatch(FORCE_GET_AIRCRAFT_CONFIGS);
            }
          },
        );
      }

      return this.params.node.data;
    },
    setAircraftConfigId(event) {
      if (typeof (event) !== 'undefined') {
        this.aircraftConfigIdUpdated = event;
      }
    },
  },
  created() {
    const connectedFlights = this.params.node.data;
    this.aircraftConfigIdOriginal = connectedFlights.getSafeValue(['common', 'aircraftConfigId']);
    this.aircraftConfigCodeOriginal = connectedFlights.getSafeValue(['common', 'aircraftConfigCode']);
    this.aircraftConfigCodeUpdated = this.aircraftConfigCodeOriginal;

    this.markCellsSelectedAsEdit();
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$refs.refComp) {
        this.$refs.refComp.$refs.refComp.focus();
      }
    });
  },
});
</script>

<style>
.aircraft-config-cell.ag-cell-inline-editing {
  min-width: 140px;
}

</style>
