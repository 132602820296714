<template>
  <div v-if="localTimezone" class="timezone-switch">
    <div class="chip-zone">
      <v-btn icon v-on:click="toggleTimezone" class="switch mr-2">
        <v-tooltip bottom>
          <v-icon size="24" slot="activator">{{ icon }}</v-icon>
          <span>{{ currentTimezone }}</span>
        </v-tooltip>
      </v-btn>
      <div class="clock hidden-xs-only">
        <div class="timezone">
          <span v-if="currentTimezone !== 'UTC'">{{ localTimezone }}</span>
          <span v-else>UTC</span>
        </div>
        <span class="clock-time">{{ now | moment('HH:mm:ss') }}</span>
        <span class="clock-date hidden-sm-and-down">{{ now | moment('dddd DD/MM/YY') }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import VueCookies from 'vue-cookies';
import TimezoneConfigurable from '../mixins/TimezoneConfigurable.vue';
import { TIME_CHANGE_TIME_NOW } from '../../store/modules/time';

export default {
  name: 'timezone-switch',
  mixins: [TimezoneConfigurable],
  data() {
    return {
      now: new Date(),
      timerInterval: null,
      bottomNav: true,
      icon: 'access_time_filled',
      isUTC: true,
    };
  },
  computed: {
    currentTimezone: {
      get() {
        return this.$store.state.time.timezone;
      },
      set(newTimezone) {
        this.setGlobalTimezone(newTimezone);
        VueCookies.set('timezone', newTimezone, 365);
      },
    },
  },
  methods: {
    initTimer() {
      const that = this;
      if (this.timerInterval) {
        clearInterval(this.timerInterval);
        this.timerInterval = null;
      }
      this.timerInterval = setInterval(() => {
        that.now = that.$moment();
        this.$store.dispatch(TIME_CHANGE_TIME_NOW, that.now);
      }, 1000 * 1);
    },
    toggleTimezone() {
      this.isUTC = !this.isUTC;
      this.icon = this.isUTC ? 'access_time' : 'my_location';
      this.currentTimezone = this.isUTC ? 'UTC' : this.localTimezone;
      this.now = this.$moment();
      this.$store.dispatch(TIME_CHANGE_TIME_NOW, this.now);
    },
  },
  mounted() {
    const currentLang = this.$store.state.i18n.currentLanguage;
    this.$moment.locale(currentLang);
    this.initTimer();
  },
  watch: {
    // when the current language changes, update the moment locale
    '$store.state.i18n.currentLanguage': function watchCurrentLanguage(newLang) {
      this.$moment.locale(newLang);
      this.now = this.$moment();
    },
    // when the current tenant changes, update the timezone
    '$store.state.tenant.currentTenant': function watchCurrentTenant() {
      this.icon = this.isUTC ? 'access_time' : 'my_location';
      this.currentTimezone = this.isUTC ? 'UTC' : this.localTimezone;
      this.now = this.$moment();
      this.$store.dispatch(TIME_CHANGE_TIME_NOW, this.now);
    },
  },
  created() {
    this.$moment.locale('es');
    const storedTimezone = VueCookies.get('timezone');
    if (storedTimezone) {
      this.setGlobalTimezone(storedTimezone);
    } else {
      this.setGlobalTimezone('UTC');
    }
    this.isUTC = this.currentTimezone === 'UTC';
    this.icon = this.currentTimezone === 'UTC' ? 'access_time' : 'my_location';
  },
};
</script>
<style lang="scss">
@media (max-width: 600px) {
  .timezone-switch {
    .chip-zone {
      border: none !important;
      padding-right: 0 !important;
    }
  }
}

.timezone-switch {
  display: flex;
  flex-direction: column;
  align-items: center;


  .chip-zone {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 50px !important;
    border: 1px dotted #ccc;
    padding-right: 4px;

    .switch {
      margin-right: auto; // Asegura que el botón esté a la izquierda
    }

    .clock {
      line-height: 0.8em;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: #666;


      .timezone {
        min-width: 100px;
        font-size: 10px;
        text-align: center;
        font-weight: bold;
        margin-bottom: 6px;
      }

      .clock-date {
        font-size: 12px;
        white-space: nowrap;
        overflow: hidden;
      }

      .clock-time {
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 4px;
      }
    }
  }
}
</style>
