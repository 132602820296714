<template>
  <v-tooltip bottom v-if="isTenantSetted">
    <v-btn
      slot="activator"
      v-if="isTenantSetted"
      @click="selectTenant"
    >
      {{currentTenant.name}}
      <v-icon right dark class="hidden-sm-and-down">flight</v-icon>
    </v-btn>
    <span>{{currentTenant.description}}</span>
  </v-tooltip>
</template>
<script>

export default {
  name: 'current-tenant-display',
  props: [],
  computed: {
    isTenantSetted() {
      return this.$store.getters.isTenantSetted;
    },
    currentTenant() {
      return this.isTenantSetted ? this.$store.state.tenant.currentTenant : {};
    },
  },
  methods: {
    selectTenant() {
      this.$router.push('/tenant');
    },
  },
};
</script>
<style lang="scss">
</style>
