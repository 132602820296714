import { JSUtils } from '../../shared/js/JSUtils';

export default class AuditFlightChange {
  constructor(
    flightId,
    flightDate,
    flightDateLocal,
    bound,
    tripNumber,
    airlineId,
    airlineCode,
    airlineIataCode,
    airlineIcaoCode,
    airlineDefaultCode,
    airlineName,
    airport1Id,
    airport1Code,
    airport1IataCode,
    airport1IcaoCode,
    changeOrigin,
    operationType,
    changeTs,
    changeField,
    changeNewValue,
    changeOldValue,
  ) {
    this.flightId = flightId;
    this.flightDate = flightDate;
    this.flightDateLocal = flightDateLocal;
    this.bound = bound;
    this.tripNumber = tripNumber;
    this.airlineId = airlineId;
    this.airlineCode = airlineCode;
    this.airlineIataCode = airlineIataCode;
    this.airlineIcaoCode = airlineIcaoCode;
    this.airlineDefaultCode = airlineDefaultCode;
    this.airlineName = airlineName;
    this.airport1Id = airport1Id;
    this.airport1Code = airport1Code;
    this.airport1IataCode = airport1IataCode;
    this.airport1IcaoCode = airport1IcaoCode;
    this.changeOrigin = changeOrigin;
    this.operationType = operationType;
    this.changeTs = changeTs;
    this.changeField = changeField;
    this.changeNewValue = changeNewValue;
    this.changeOldValue = changeOldValue;
  }

  clone() {
    const auditFlightChangeFromData = AuditFlightChange.createAuditFlightChangeFromData(JSON.parse(JSON.stringify(this)));
    return auditFlightChangeFromData;
  }

  scheduledDate() {
    return this.scheduledTime ? this.scheduledTime : this.flightDate;
  }

  flightNo() {
    let flightNumber = this.airlineCode ? `${this.airlineCode}` : '';
    flightNumber += this.tripNumber ? `${this.tripNumber}` : '';
    return flightNumber;
  }

  get flightNumber() {
    return this.flightNo();
  }

  static get createEmptyAuditFlightChange() {
    return new AuditFlightChange();
  }

  static createAuditFlightChangeFromData(o) {
    if (!o) {
      return this.createEmptyAuditFlightChange;
    }

    return JSUtils.cast(o, AuditFlightChange);
  }
}
