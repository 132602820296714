<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>

    <ag-grid-dialog-template-editor :is-dialog-open="isDialogOpen" @save="save" @cancel="cancel">

      <template v-slot:header>
        Edit Passengers Status for Flight {{flightNumber}}
      </template>

      <template v-slot:default>
        <v-card>
          <v-card-text class="message-table">
            <ag-grid-vue
              class="ag-theme-material"
              :columnDefs="columnDefs"
              :defaultColDef="defaultColDef"
              @grid-ready="setAgGridApi"
              :gridOptions="gridOptions"
              :frameworkComponents="frameworkComponents"
              :rowData="rowData">
            </ag-grid-vue>
          </v-card-text>
        </v-card>
      </template>
    </ag-grid-dialog-template-editor>
  </div>
</template>

<script>
import Vue from 'vue';
import { AgGridVue } from 'ag-grid-vue';
import AgGridDialogParentCellEditor from '../../mixins/AgGridDialogParentCellEditor.vue';
import AgGridDialogTemplateEditor from '../../templates/AgGridDialogTemplateEditor.vue';
import AgGridTimeCellEditor from '../../AgGridTimeCellEditor.vue';
import agGridTimeFormatter from '../../../formatters/agGridTimeFormatter';
import { JSUtils } from '../../../../shared/js/JSUtils';
import FlightPassengerActionDTO from '../../model/flightPassengerActionDTO';
import { cellClassAgGridRules } from '../../../mixins/cellClassAgGridRules';
import AgGridOptionsConfigurable from '../../../mixins/AgGridOptionsConfigurable.vue';


export default Vue.extend({
  mixins: [AgGridDialogParentCellEditor, AgGridOptionsConfigurable],
  components: {
    AgGridDialogTemplateEditor,
    AgGridVue,
  },
  data() {
    return {
      referenceDate: null,
      columnDefs: null,
      frameworkComponents: {
        AgGridTimeCellEditor,
      },
      defaultColDef: {
        editable: false,
        cellClassRules: cellClassAgGridRules,
      },
      gridOptions: {
        getRowClass: this.getRowClass,
      },
      rowData: [],
    };
  },
  methods: {
    getValue() {
      if (this.isSaving) {
        this.requestInit();
        this.$http.put(`flights/${this.flightId}/passenger/actions`, this.rowData)
          .then(resp => this.dialogRequestSuccessCallback(this, resp))
          .catch(error => this.requestErrorCallback(this, error));
      }

      return this.params.value;
    },
    onColumnResized(event) {
      if (event.finished) {
        this.gridApi.resetRowHeights();
      }
    },
    setColDefs() {
      this.columnDefs = [
        {
          field: 'actionName',
          headerName: 'Action',
        },
        {
          field: 'scheduledTs',
          headerName: 'Scheduled Time',
          cellEditor: 'AgGridTimeCellEditor',
          cellEditorCustomConfig: {
            referenceDate: this.referenceDate,
          },
          valueFormatter: agGridTimeFormatter,
        },
        {
          field: 'completedTs',
          headerName: 'Completed Time',
          editable: true,
          cellEditor: 'AgGridTimeCellEditor',
          cellEditorCustomConfig: {
            referenceDate: this.referenceDate,
          },
          valueFormatter: agGridTimeFormatter,
        },
      ];
    },
  },
  created() {
    this.referenceDate = this.connectedFlights.getChildPropertyValue(this.flightBound, ['common', 'scheduledDate']);

    this.setColDefs();

    this.$http.get(`flights/${this.flightId}/passenger/actions`)
      .then((resp) => {
        this.rowData = JSUtils.cast(resp.data, FlightPassengerActionDTO);
      });
  },
});
</script>
<style lang="scss">
.message-table {
  padding: 0;
}
.newMsgCard {
  margin-top: 10px;
}
</style>
