import { VCardText } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCardText,[_c(VContainer,{attrs:{"fluid":""}},[_c(VLayout,[_c(VFlex,{attrs:{"xs4":"","pr-2":""}},[_c(VTextField,{attrs:{"label":this.$i18n.t('configuration.avinetUsers'),"readonly":!_vm.hasPermissions(_vm.rolePermission)},model:{value:(_vm.item.user),callback:function ($$v) {_vm.$set(_vm.item, "user", $$v)},expression:"item.user"}})],1),_c(VFlex,{attrs:{"xs4":"","px-2":""}},[_c(VTextField,{attrs:{"append-icon":_vm.show2 ? 'visibility' : 'visibility_off',"type":_vm.show2 ? 'text' : 'password',"label":this.$i18n.t('configuration.avinetPassword'),"readonly":!_vm.hasPermissions(_vm.rolePermission)},on:{"click:append":function($event){_vm.show2 = !_vm.show2}},model:{value:(_vm.item.password),callback:function ($$v) {_vm.$set(_vm.item, "password", $$v)},expression:"item.password"}})],1),_c(VFlex,{attrs:{"xs4":"","px-2":""}},[_c(VContainer,{attrs:{"fluid":""}},[_c(VLayout,{attrs:{"row":"","column":""}},[_c(VFlex,[_c('tenant-selector',{staticStyle:{"padding":"0"},attrs:{"label":this.$i18n.t('configuration.tenantAuthorities'),"disabled":!_vm.hasPermissions(_vm.rolePermission)},model:{value:(_vm.item.tenantId),callback:function ($$v) {_vm.$set(_vm.item, "tenantId", $$v)},expression:"item.tenantId"}})],1)],1)],1)],1),_c(VFlex,{attrs:{"xs3":"","px-2":""}},[_c(VCheckbox,{attrs:{"color":"primary","label":this.$i18n.t('configuration.defaultUser'),"disabled":!_vm.hasPermissions(_vm.rolePermission)},model:{value:(_vm.item.defaultUser),callback:function ($$v) {_vm.$set(_vm.item, "defaultUser", $$v)},expression:"item.defaultUser"}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }