<template>
  <v-card :dark="$vuetify.dark" class="flightLoads">
    <v-card-title>
      <div class="font-weight-bold">
        <v-icon>mdi-weight</v-icon>
        {{ $t('dispatcher.loads') }}
      </div>
    </v-card-title>
    <v-divider />
    <v-list class="pa-0" v-if="!isEmptyLoads()">
      <div v-for="(loadsColumn, indexAirport) in airportLoadsTable " :key="indexAirport">
        <v-card-title>
          <div class="font-weight-bold">
            <v-icon>mdi-airplane</v-icon>
            {{ flightLoads[indexAirport].airportOrder }} - {{ flightLoads[indexAirport].airportCode }}
          </div>
        </v-card-title>
        <v-divider />
        <div v-for="(column, index) in loadsColumn" :key="index" :class="column.typeValue">
          <v-list-tile :class="index % 2 === 0 ? 'highlight' : ''">
            <v-list-tile-action>
              <v-icon :color="column.color">{{ column.icon }}</v-icon>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title>
                {{ $t(column.header) }}
              </v-list-tile-title>
            </v-list-tile-content>
            <v-list-tile-action class="full-width">
              <mobile-input-type :name="column.name + '[' + indexAirport + ']'" :value="column.field" :typeValue="column.typeValue"
                :append="column.append === 'Weight' ? unitValue : column.append"
                :editable="hasPermissions('AIRPORT_SERVICE_AGENT_ROLE_BAGGAGE_DISTRIBUTION_WR')"
                :class="hasPermissions('AIRPORT_SERVICE_AGENT_ROLE_BAGGAGE_DISTRIBUTION_WR') ? 'editable' : 'readonly'"
                @input="updateLoad(indexAirport, column.name, $event)"></mobile-input-type>
            </v-list-tile-action>
          </v-list-tile>
        </div>
        <v-divider v-if="indexAirport < airportLoadsTable.length - 1"></v-divider>
      </div>
    </v-list>
    <v-list class="pa-0 totals NUMBER">
      <!-- TOTALS -->
      <v-card-title>
        <v-list-tile-content>
          {{ $t('dispatcher.total') }}
        </v-list-tile-content>
        <span class="total NUMBER full-width">
          <mobile-input-type name="total" :value="getTotalLoad()" typeValue="NUMBER" :append="unitValue"></mobile-input-type>
          <v-spacer></v-spacer>
        </span>
      </v-card-title>
      <v-divider />
      <div v-if="airportLoadsTable.length > 1">
        <v-list-tile v-if="totals.bag > 0">
          <v-list-tile-action>
            <v-icon color="red">work</v-icon>
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title>
              {{ $t('operations.loadsTableBag') }}
            </v-list-tile-title>
          </v-list-tile-content>
          <v-list-tile-action class="full-width">
            <mobile-input-type name="totalBag" :value="totals.bag" typeValue="NUMBER" :editable="false" :append="unitValue"
              class="editable"></mobile-input-type>
          </v-list-tile-action>
        </v-list-tile>
        <v-list-tile v-if="totals.estimatedLoad > 0" class="highlight">
          <v-list-tile-action>
            <v-icon color="red">schedule</v-icon>
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title>
              {{ $t('operations.loadsTableEstimatedLoad') }}
            </v-list-tile-title>
          </v-list-tile-content>
          <v-list-tile-action class="full-width">
            <mobile-input-type name="totalEstimatedLoad" :value="totals.estimatedLoad" typeValue="NUMBER" :editable="false" :append="unitValue"
              class="editable"></mobile-input-type>
          </v-list-tile-action>
        </v-list-tile>
        <v-list-tile v-if="totals.cargo > 0">
          <v-list-tile-action>
            <v-icon color="red">local_shipping</v-icon>
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title>
              {{ $t('operations.loadsTableCargo') }}
            </v-list-tile-title>
          </v-list-tile-content>
          <v-list-tile-action class="full-width">
            <mobile-input-type name="totalCargo" :value="totals.cargo" typeValue="NUMBER" :editable="false" :append="unitValue"
              class="editable"></mobile-input-type>
          </v-list-tile-action>
        </v-list-tile>
        <v-list-tile v-if="totals.mail > 0">
          <v-list-tile-action>
            <v-icon color="red">mail</v-icon>
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title>
              {{ $t('operations.loadsTableMail') }}
            </v-list-tile-title>
          </v-list-tile-content>
          <v-list-tile-action class="full-width">
            <mobile-input-type name="totalMail" :value="totals.mail" typeValue="NUMBER" :editable="false" :append="unitValue"
              class="editable"></mobile-input-type>
          </v-list-tile-action>
        </v-list-tile>
        <v-list-tile v-if="totals.avih > 0" class="highlight">
          <v-list-tile-action>
            <v-icon color="red">pets</v-icon>
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title>
              {{ $t('operations.loadsTableAVIH') }}
            </v-list-tile-title>
          </v-list-tile-content>
          <v-list-tile-action class="full-width">
            <mobile-input-type name="totalAvih" :value="totals.avih" typeValue="NUMBER" :editable="false" append="Uds"
              class="editable"></mobile-input-type>
          </v-list-tile-action>
        </v-list-tile>
        <v-list-tile v-if="totals.hum > 0" class="highlight">
          <v-list-tile-action>
            <v-icon color="red">mdi-skull</v-icon>
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title>
              {{ $t('operations.loadsTableHum') }}
            </v-list-tile-title>
          </v-list-tile-content>
          <v-list-tile-action class="full-width">
            <mobile-input-type name="totalHum" :value="totals.hum" typeValue="NUMBER" :editable="false" append="Uds"
              class="editable"></mobile-input-type>
          </v-list-tile-action>
        </v-list-tile>
        <v-list-tile v-if="totals.weap > 0" class="highlight">
          <v-list-tile-action>
            <v-icon color="red">warning</v-icon>
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title>
              {{ $t('operations.loadsTableWeap') }}
            </v-list-tile-title>
          </v-list-tile-content>
          <v-list-tile-action class="full-width">
            <mobile-input-type name="totalWeap" :value="totals.weap" typeValue="NUMBER" :editable="false" append="Uds"
              class="editable"></mobile-input-type>
          </v-list-tile-action>
        </v-list-tile>
        <v-list-tile v-if="totals.oths > 0" class="highlight">
          <v-list-tile-action>
            <v-icon color="red">directions_bike</v-icon>
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title>
              {{ $t('operations.loadsTableOths') }}
            </v-list-tile-title>
          </v-list-tile-content>
          <v-list-tile-action class="full-width">
            <mobile-input-type name="totalOths" :value="totals.oths" typeValue="NUMBER" :editable="false" append="Uds"
              class="editable"></mobile-input-type>
          </v-list-tile-action>
        </v-list-tile>

      </div>
    </v-list>
  </v-card>
</template>

<script>
import MobileInputType from '../mobileInputType/MobileInputType.vue';
import RolePermissions from '../../mixins/RolePermissions.vue';
import './MobileFlightLoads.scss';
import MobileFlightUtil from '../model/mobileFlightUtil';

export default {
  name: 'mobile-flight-loads',
  props: {
    flightLoads: {
      type: Array,
      required: true,
    },
    totals: {
      type: Object,
      required: true,
    },
  },
  mixins: [RolePermissions],
  components: {
    MobileInputType,
  },
  methods: {
    isEmptyLoads() {
      return this.flightLoads.length === 0;
    },
    flightLoadsByColumns() {
      const airportLoadsTable = [];
      if (this.isEmptyLoads()) {
        return airportLoadsTable;
      }
      // set loadColumns with all flightLoads mappet to columns properties
      this.flightLoads.forEach((load) => {
        const airportLoads = [{
          field: load.bag,
          name: 'bag',
          header: 'operations.loadsTableBag',
          icon: 'work',
          color: 'primary',
          typeValue: 'NUMBER',
          append: 'Weight',
        },
        {
          // prevision de carga
          field: load.estimatedLoad,
          name: 'estimatedLoad',
          header: 'operations.loadsTableEstimatedLoad',
          icon: 'schedule',
          color: 'secondary',
          typeValue: 'NUMBER',
          append: 'Weight',
        },
        {
          // carga
          field: load.cargo,
          name: 'cargo',
          header: 'operations.loadsTableCargo',
          icon: 'local_shipping',
          color: 'primary',
          typeValue: 'NUMBER',
          append: 'Weight',
        },
        {
          // correo
          field: load.mail,
          name: 'mail',
          header: 'operations.loadsTableMail',
          icon: 'mail',
          color: 'primary',
          typeValue: 'NUMBER',
          append: 'Weight',
        },
        {
          // Animales
          field: load.avih,
          name: 'avih',
          header: 'operations.loadsTableAVIH',
          icon: 'pets',
          color: 'secondary',
          typeValue: 'NUMBER',
          append: 'Uds',
        },
        {
          // Restos humanos
          field: load.hum,
          name: 'hum',
          header: 'operations.loadsTableHum',
          icon: 'mdi-skull',
          color: 'secondary',
          typeValue: 'NUMBER',
          append: 'Uds',
        },
        {
          // Carga peligrosa
          field: load.weap,
          name: 'weap',
          header: 'operations.loadsTableWeap',
          icon: 'warning',
          color: 'secondary',
          typeValue: 'NUMBER',
          append: 'Uds',
        },
        {
          // otros objetos (bicicletas, etc)
          field: load.oths,
          name: 'oths',
          header: 'operations.loadsTableOths',
          icon: 'directions_bike',
          color: 'secondary',
          typeValue: 'NUMBER',
          append: 'Uds',
        },
        {
          // observaciones
          field: load.remarks,
          name: 'remarks',
          header: 'operations.remarksExtended',
          icon: 'comment',
          color: 'secondary',
          typeValue: 'TEXT',
          append: '',
        }];
        airportLoadsTable.push(airportLoads);
      });
      return airportLoadsTable;
    },
    updateLoad(index, field, value) {
      this.flightLoads[index][field] = value;
      this.$emit('updateLoad', this.flightLoads[index], this.updateTotalLoad());
    },
    updateTotalLoad() {
      const totalLoad = {
        bag: 0,
        cargo: 0,
        mail: 0,
        avih: 0,
        hum: 0,
        weap: 0,
        oths: 0,
        estimatedLoad: 0,
      };
      this.flightLoads.forEach((load) => {
        totalLoad.bag += MobileFlightUtil.parseFloatZero(load.bag);
        totalLoad.cargo += MobileFlightUtil.parseFloatZero(load.cargo);
        totalLoad.mail += MobileFlightUtil.parseFloatZero(load.mail);
        totalLoad.avih += MobileFlightUtil.parseFloatZero(load.avih);
        totalLoad.hum += MobileFlightUtil.parseFloatZero(load.hum);
        totalLoad.weap += MobileFlightUtil.parseFloatZero(load.weap);
        totalLoad.oths += MobileFlightUtil.parseFloatZero(load.oths);
        totalLoad.estimatedLoad += MobileFlightUtil.parseFloatZero(load.estimatedLoad);
      });
      return totalLoad;
    },
    getTotalLoad() {
      return MobileFlightUtil.parseFloatZero(this.totals.bag) + MobileFlightUtil.parseFloatZero(this.totals.cargo) + MobileFlightUtil.parseFloatZero(this.totals.mail);
    },
    getUnit() {
      return this.totals.unit === 'KILOGRAMS' ? 'Kgs' : 'Lbs';
    },
  },
  watch: {
    totals: {
      handler() {
        this.unitValue = this.getUnit();
      },
      deep: true,
    },
  },
  data() {
    return {
      unitValue: this.getUnit(),
      airportLoadsTable: [],
    };
  },
  created() {
    this.airportLoadsTable = this.flightLoadsByColumns();
  },
};
</script>
