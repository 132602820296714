import Integration from './integration';

export default class IntegrationFactory {
  static createIntegrationFromData(data) {
    if (data && data.constructor === Array && data.length > 0) {
      return data.map(d => this.mapIntegration(d));
    }
    if (data) {
      return this.mapIntegration(data);
    }
    return [];
  }

  static mapIntegration(d) {
    return new Integration(
      d.name ? d.name : null,
      d.enabled ? d.enabled : false,
      d.available ? d.available : false,
      d.jobAvailable ? d.jobAvailable : false,
    );
  }
}
