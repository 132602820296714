import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VLayout,{attrs:{"row":"","justify-center":""}},[_c(VDialog,{attrs:{"persistent":"","max-width":"1370px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,{attrs:{"dark":this.$vuetify.dark,"color":this.$vuetify.dark ? 'bggrey_dark' : 'bggrey'}},[_c(VToolbar,{attrs:{"dark":"","color":"primary"}},[_c('img',{attrs:{"height":"30","src":require('@/assets/svg-icons/linkflights-w.svg')}}),_c(VToolbarTitle,[_vm._v(_vm._s(_vm.$t("scheduling.linkFlightsConfirmation")))])],1),_c('loading-component'),(_vm.linkItems.length === 0)?_c(VCardText,[_vm._v(" Flights are not linkable because: "),_c('ul',_vm._l((_vm.linkErrorMessages),function(message){return _c('li',{key:message},[_vm._v(" "+_vm._s(message)+" ")])}),0)]):_vm._e(),(_vm.linkItems.length === 1)?_c(VCardText,[_vm._v(" "+_vm._s(_vm.$t("scheduling.linkFlightsAlert"))+" "),_c('connected-flights-base-grid',{attrs:{"items":_vm.linkItems},on:{"selected-changed":_vm.selectedChanged}})],1):_vm._e(),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"flat":"","color":"primary"},nativeOn:{"click":function($event){return _vm.reject()}}},[_vm._v(_vm._s(_vm.$t("common.cancel")))]),_c(VBtn,{attrs:{"disabled":_vm.linkItems.length === 0,"color":"primary","depressed":""},nativeOn:{"click":function($event){return _vm.confirm()}}},[_vm._v(_vm._s(_vm.$t("common.confirm")))])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }