<template>
  <div class="scheduling-weighting list-filter">
    <v-card :dark="this.$vuetify.dark" :color="this.$vuetify.dark ? 'bggrey_dark' : 'bggrey'">
      <v-toolbar dark dense color="primary lighten-1">
        <v-btn v-on:click="displayedFilter" small flat>
          <v-icon>filter_list</v-icon>
          <v-icon v-if="!showFilter">keyboard_arrow_down</v-icon>
          <v-icon v-if="showFilter">keyboard_arrow_up</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-flex grow="false" shrink="true">
          <v-btn-toggle v-model="unitOfTime" class="transparent" @change="filterChanged">
            <v-tooltip left>
              <v-btn value="day" flat slot="activator">{{ $t("commonFilter.filterD") }}</v-btn>
              <span>{{ $t("commonFilter.filterDay") }}</span>
            </v-tooltip>
            <v-tooltip left>
              <v-btn value="isoWeek" flat slot="activator">{{ $t("commonFilter.filterW") }}</v-btn>
              <span>{{ $t("commonFilter.filterWeek") }}</span>
            </v-tooltip>
            <v-tooltip left>
              <v-btn value="month" flat slot="activator">{{ $t("commonFilter.filterM") }}</v-btn>
              <span>{{ $t("commonFilter.filterMonth") }}</span>
            </v-tooltip>
            <v-tooltip left>
              <v-btn value="year" flat slot="activator">{{ $t("commonFilter.filterY") }}</v-btn>
              <span>{{ $t("commonFilter.filterYear") }}</span>
            </v-tooltip>
          </v-btn-toggle>
        </v-flex>
        <SchedulingWeightingListTableOptionsFilterButton />
      </v-toolbar>
      <v-container fluid v-if="showFilter" class="pa-2 pb-2">
        <v-layout class="filter-inline" align-center justify-start>
          <v-flex class="mr-2" align-center justify-start>
            <date-input v-model="filter.dateFrom" :locale="$i18n.locale" :label="$t('common.fromDate')" class="pb-2"
              @change="changeDateFrom"></date-input>
            <date-input v-model="filter.dateTo" :locale="$i18n.locale" :label="$t('common.toDate')"
              @change="changeDateTo"></date-input>
          </v-flex>
          <v-flex class="text-no-wrap" align-center justify-start>
            <airline-select class="ml-2" :label="$t('common.airlineOp')" v-model="filter.airlineAndOperator"></airline-select>
            <temporal-unit-select class="ml-2" :label="this.$i18n.t('common.temporalAggregation')"
              v-model="filter.temporalAggregationUnit"></temporal-unit-select>
          </v-flex>
          <v-flex class="text-no-wrap" align-center justify-start>
            <v-switch class="ml-4 mt-0 pt-0" slot="activator" color="red"
              :label="this.$i18n.t('commonFilter.aggregateByAirline')" v-model="filter.aggregateByAirline"
               hide-details>
            </v-switch>
            <v-switch class="ml-4 mt-0 pt-0" slot="activator" color="red"
              :label="this.$i18n.t('commonFilter.aggregateByAircraftTypeCode')"
              v-model="filter.aggregateByAircraftTypeCode"  hide-details>
            </v-switch>
            <v-switch class="ml-4 mt-0 pt-0" slot="activator" color="red"
              :label="this.$i18n.t('commonFilter.excludeCancelled')" v-model="filter.excludeCancelled"
               hide-details>
            </v-switch>
          </v-flex>
          <v-spacer style="width: 45%"></v-spacer>
          <v-flex class="ml-2 text-no-wrap" align-center justify-start>
            <v-btn color="secondary" small flat round v-on:click.stop="exportCSV" block class="mb-4">
              <v-icon>mdi-download</v-icon>
              {{ $t("common.export") }}
            </v-btn>
            <v-btn color="primary" round v-on:click.stop="find" block>
              <v-icon>search</v-icon>
              {{ $t("common.find") }}
            </v-btn>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card>
  </div>

</template>
<script>
import AirlineSelect from '../../airline/AirlineSelect.vue';
import AirportSelect from '../../airport/AirportSelect.vue';
import DateInput from '../../ui/DateInput.vue';
import TemporalUnitSelect from '../../ui/temporalUnit/TemporalUnitSelect.vue';
import AgGridApiCsvExportable from '../../ag-grid/mixins/AgGridApiCsvExportable.vue';
import StoreSubscribable from '../../mixins/StoreSubscribable.vue';
import {
  SCHED_WEIGHT_FIND,
  SCHED_WEIGHT_FILTER_SET_UNIT_TIME,
  SCHED_WEIGHT_CHANGE_GRID_API, SCHED_WEIGHT_RECALCULATE_FILTER,
} from '../../../store/modules/schedulingWeighting';
import { TIME_TIMEZONE_CHANGED } from '../../../store/modules/time';
import SchedulingWeightingListTableOptionsFilterButton from './SchedulingWeightingListTableOptionsFilterButton.vue';

export default {
  name: 'scheduling-weighting-list-filter',
  mixins: [AgGridApiCsvExportable, StoreSubscribable],
  components: {
    TemporalUnitSelect,
    AirlineSelect,
    AirportSelect,
    DateInput,
    SchedulingWeightingListTableOptionsFilterButton,
  },
  props: ['value'],
  data() {
    return {
      showFilter: true,
      filterSummary: null,
    };
  },
  computed: {
    filter() {
      return this.$store.state.scheduling.schedulingWeighting.filter;
    },
    unitOfTime: {
      get() {
        return this.filter.unitOfTime;
      },
      set(value) {
        this.$store.commit(SCHED_WEIGHT_FILTER_SET_UNIT_TIME, value);
      },
    },
  },
  methods: {
    filterChanged() {
      this.find();
    },
    find() {
      this.showLoading();
      this.$store.dispatch(SCHED_WEIGHT_FIND);
    },
    exportCSV() {
      this.exportAgGridCsv('FlightsWeighting');
    },
    changeDateFrom() {
      this.unitOfTime = null;
      const from = this.$moment(this.filter.dateFrom);
      const to = this.$moment(this.filter.dateTo);

      this.filter.dateFrom = from.startOf('day');
      if (from.isAfter(to)) {
        this.filter.dateTo = this.$moment(this.filter.dateFrom).endOf('day');
      }
      // this.filterChanged();
    },
    changeDateTo() {
      this.unitOfTime = null;
      const from = this.$moment(this.filter.dateFrom);
      const to = this.$moment(this.filter.dateTo);

      this.filter.dateTo = to.endOf('day');
      if (to.isBefore(from)) {
        this.filter.dateFrom = this.$moment(this.filter.dateTo).startOf('day');
      }
      // this.filterChanged();
    },
    displayedFilter() {
      this.showFilter = !this.showFilter;
      this.$emit('changeShowFilter', this.showFilter);
    },
  },
  mounted() {
    const that = this;
    this.subscribeMutation(SCHED_WEIGHT_CHANGE_GRID_API, (mutationPayload) => {
      that.setAgGridApi(mutationPayload);
    });
    this.subscribeMutation(TIME_TIMEZONE_CHANGED, () => {
      this.$store.dispatch(SCHED_WEIGHT_RECALCULATE_FILTER);
      this.find();
    });
    this.$store.dispatch(SCHED_WEIGHT_RECALCULATE_FILTER);
    this.find();
  },
};
</script>
