<template>
  <div>
    <v-autocomplete
      :placeholder="placeholder"
      :value="value"
      :label="label"
      :items="items"
      :filter="customFilter"
      :clearable="!readonly"
      item-text="serviceName"
      item-value="serviceId"
      :rules="rules"
      :disabled="disabled"
      :readonly="readonly"
      :multiple="multiple"
      :menu-props="{zIndex:'1000'}"
      @input="emitInput"
      :ref="refComp"
    >
      <template v-slot:selection="data" v-if="chipView">
      <v-chip
        :selected="data.selected"
        close
        class="chip--select-multi"
        @input="remove(data.item)"
      >
        {{ data.item.serviceName }}
      </v-chip>
    </template>
      <template
        slot="item"
        slot-scope="{ item, tile }"
      >
        <v-list-tile-content>
          <v-list-tile-title v-text="item.serviceName"></v-list-tile-title>
          <v-list-tile-sub-title v-text="item.serviceDescription"></v-list-tile-sub-title>
        </v-list-tile-content>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>


import { GET_AIRPORT_RAMP_SERVICE_BY_SERVICE_AGENT_OF_USER } from '../../store/modules/rampServices/airportRampServicesCatalog';

export default {
  props: {
    value: { required: true },
    label: { type: String },
    placeholder: { type: String, default: '...' },
    charLenght: { type: Number },
    rules: { type: Array },
    disabled: { type: Boolean },
    readonly: { type: Boolean },
    multiple: {
      type: Boolean,
      default: false,
    },
    refComp: {
      type: String,
      required: false,
      default: 'refComp',
    },
    chipView: {
      type: Boolean,
      default: false,
    },
    idService: {},
  },
  computed: {
    items: {
      get() {
        return this.$store.getters.getActiveAirportRampServicesByServiceAgentOfUser;
      },
    },
  },
  methods: {
    getRampServices() {
      this.$store.dispatch(GET_AIRPORT_RAMP_SERVICE_BY_SERVICE_AGENT_OF_USER);
    },
    customFilter(item, queryText) {
      const textOne = item.serviceName ? item.serviceName.toLowerCase() : '';
      const textTwo = item.serviceDescription ? item.serviceDescription.toLowerCase() : '';
      const searchText = queryText.toLowerCase();

      return textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1;
    },
    emitInput($event) {
      // FIXME así no rompe al limpiar el select pero hay que validar que no se pueda guardar el tipo de mensaje como NULL
      if (typeof $event !== 'undefined') {
        if (this.multiple) {
          this.$emit('input', this.items.filter(item => $event.includes(item.serviceId)));
        } else {
          this.$emit('input-name', this.items.find(p => p.serviceId === $event).serviceName);
          this.$emit('input-airport-service-id', $event);
          this.$emit('input', $event);
          this.$emit('change', $event);
        }
      } else {
        this.$emit('input-name', null);
        this.$emit('input', null);
      }
    },
    remove(item) {
      const index = this.value.map(a => (typeof a === 'object' && a.id ? a.id : a)).indexOf(item.id);
      if (index >= 0) this.value.splice(index, 1);
      this.$emit('input', this.value);
    },
  },
  mounted() {
    this.getRampServices();
  },
};
</script>
