<template>
  <v-menu
    max-height="600px"
    max-width="650px"
    min-width="650px"
    :close-on-content-click="false"
  >
    <v-btn
      slot="activator"
      @click="findChanges"
      icon>
      <v-icon>add_alert</v-icon>
    </v-btn>

    <v-card>
      <v-subheader> {{ $i18n.t('audit.lastImportedFile') }} </v-subheader>
      <v-card-title primary-title class="py-0">
        <v-flex xs10>
          <v-layout row wrap class="p-0 ma-0">
            {{ fileStatusLabel() }}
            <router-link to="/scheduling/imports" target="_blank" >{{ $i18n.t('audit.lastImportedFileDetailsLink') }}</router-link>
          </v-layout>
        </v-flex>
        <v-flex xs10>
          <v-layout row wrap class="p-0 ma-0">
            {{ lastSchedulingImportedFileByUser.name }}
          </v-layout>
        </v-flex>
        <v-flex xs10>
          <v-layout row wrap class="p-0 ma-0">
            {{ uploadDateTimeLabel() }}
          </v-layout>
        </v-flex>
      </v-card-title>
      <v-subheader>Flight changes</v-subheader>
      <v-card-title primary-title class="py-0">
          <v-flex xs10>
            <v-layout row wrap class="p-0 ma-0">
              <v-flex xs6>
                <date-time-input v-model="filter.changesDateFrom" :locale="$i18n.locale" :label="$t('changesFilter.changeDateFrom')" :charLenght="12" @change="changeFilter"></date-time-input>
              </v-flex>
              <v-flex xs6>
                <date-time-input v-model="filter.changesDateTo" :locale="$i18n.locale" :label="$t('changesFilter.changeDateTo')" :charLenght="12" @change="changeFilter"></date-time-input>
              </v-flex>
                <v-flex xs4>
                  <date-input v-model="filter.flightDate" :locale="$i18n.locale" :label="$t('common.flightDate')" :charLenght="20" :clearable="true" @input="changeFilter" :ignoreTimezones="true"></date-input>
                </v-flex>
                <v-flex xs4>
                  <airline-select v-model="filter.airline" :label="'Airline'" :ref="'refComp'" v-on:keydown.native="keyEvent" v-char-length.clearable="4" @input="changeFilter"></airline-select>
                </v-flex>
                <v-flex xs3>
                  <v-text-field v-model="filter.tripNumber" label="Trip number" clearable @change="changeFilter"></v-text-field>
                </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs2>
            <v-btn color="primary" small flat v-on:click.stop="findChanges" :disabled="filterRequiered">
              <v-icon>search</v-icon>
              {{$t("common.find")}}
            </v-btn>
          </v-flex>
      </v-card-title>
      <v-card-text>
        <loading-component />
        <v-layout align-center justify-space-between row fill-height>
            <span v-if="noChanges">No hay registros</span>
          <v-list v-else subheader class="content">
            <v-list-tile avatar v-for="(change, index) in changes" :key="index" style="margin-bottom: .5em;">
              <v-list-tile-avatar>
                <v-icon v-if="change.bound === 'INBOUND'">flight_land</v-icon>
                <v-icon v-if="change.bound === 'OUTBOUND'">flight_takeoff</v-icon>
              </v-list-tile-avatar>
              <v-list-tile-content>
                <v-list-tile-title>{{ change.flightNumber }} <small>{{ $moment(change.scheduledTime).format('DD/MM/YYYY') }} </small><small>[{{ $t(`common.${change.operationType}`) }} {{$t("common.at")}} {{ $moment(change.changeTs).format('HH:mm DD/MM/YYYY') }}]</small></v-list-tile-title>
                <v-list-tile-sub-title v-if="change.changeField">{{ $t(`flightFields.${getFieldName(change.changeField, change.bound)}`) }}: <small>{{ getChangeValue(change.changeOldValue) }} > {{ getChangeValue(change.changeNewValue) }}</small></v-list-tile-sub-title>
                <v-list-tile-sub-title>User: {{ change.changeOrigin }}</v-list-tile-sub-title>
              </v-list-tile-content>
            </v-list-tile>
          </v-list>
        </v-layout>
      </v-card-text>
      <v-divider></v-divider>


    </v-card>
  </v-menu>
</template>
<script>
import StoreSubscribable from '../../mixins/StoreSubscribable.vue';
import AirlineSelect from '../../airline/AirlineSelect.vue';
import DateInput from '../../ui/DateInput.vue';
import DateTimeInput from '../../ui/DateTimeInput.vue';
import LoadingComponent from '../../layout/LoadingComponent.vue';
import { AUDIT_FILTER_RESET, AUDIT_FIND_CHANGES, AUDIT_FILTER_RESET_TIMEZONE } from '../../../store/modules/audit/audit';
import { GET_LAST_SCHEDULING_IMPORT_FILE_STATUS_BY_USER } from '../../../store/modules/schedulingAdminImports';
import { TIME_TIMEZONE_CHANGED } from '../../../store/modules/time';
import { JSUtils } from '../../shared/js/JSUtils';

export default {
  name: 'audit-flights-changes-list',
  mixins: [StoreSubscribable],
  components: {
    AirlineSelect,
    DateInput,
    DateTimeInput,
    LoadingComponent,
  },
  props: [],
  data() {
    return {
      filterRequiered: false,
    };
  },
  computed: {
    lastSchedulingImportedFileByUser() {
      return this.$store.getters.getLastSchedulingImportedFileByUser;
    },
    filter() {
      return this.$store.state.audit.filter;
    },
    changes() {
      return this.$store.state.audit.changes;
    },
    noChanges() {
      return this.changes.length < 1;
    },
  },
  methods: {
    findChanges() {
      this.$store.dispatch(GET_LAST_SCHEDULING_IMPORT_FILE_STATUS_BY_USER);
      this.$store.dispatch(AUDIT_FIND_CHANGES);
    },
    changeFilter() {
      if (this.filter && ((this.filter.changesDateFrom && this.filter.changesDateTo) || this.filter.flightDate || this.filter.airline || this.filter.tripNumber)) {
        this.filterRequired = false;
        this.findChanges();
      } else {
        this.filterRequired = true;
      }
    },
    getChangeValue(change) {
      if (!change) {
        return 'NULL';
      }
      if (JSUtils.isZonedDateTimeValue(change)) {
        return this.$moment(change).format('HH:mm DD/MM/YYYY');
      }
      if (change) {
        return change;
      }
      return 'NULL';
    },
    getFieldName(fieldName, bound) {
      if (fieldName && fieldName === 'scheduledTime') {
        return `${bound}.${fieldName}`;
      }
      if (fieldName && fieldName === 'estimatedTime') {
        return `${bound}.${fieldName}`;
      }
      if (fieldName && fieldName === 'landTime') {
        return `${bound}.${fieldName}`;
      }
      if (fieldName && fieldName === 'blockTime') {
        return `${bound}.${fieldName}`;
      }
      return fieldName;
    },
    getClassOperationType(operationType) {
      if (operationType === 'UPDATE') {
        return 'infoUpdate';
      }
      if (operationType === 'INSERT') {
        return 'infoInserte';
      }
      if (operationType === 'DELETE') {
        return 'infoDelete';
      }
      return null;
    },
    fileStatusLabel() {
      if (this.lastSchedulingImportedFileByUser.status === 'IMPORTED') {
        return this.$i18n.t('audit.lastImportedFileImported');
      }
      if (this.lastSchedulingImportedFileByUser.status === 'PROCESSING') {
        return this.$i18n.t('audit.lastImportedFileProcessing');
      }
      return this.$i18n.t('audit.lastImportedFileFailed'); // by default : NOT_STARTED
    },
    uploadDateTimeLabel() {
      return this.$moment(this.lastSchedulingImportedFileByUser.uploadDate).format('DD/MM/YYYY HH:mm');
    },
  },
  mounted() {
    this.subscribeMutation(TIME_TIMEZONE_CHANGED, () => {
      this.$store.commit(AUDIT_FILTER_RESET_TIMEZONE);
      this.findChanges();
    });
  },
  created() {
    this.$store.commit(AUDIT_FILTER_RESET);
  },
};
</script>
<style lang="scss" scoped>
  .content {
    overflow: auto;
    height: 30vh;
    padding: 0;
    width: 100%;
  }
  .infoInsert {
    background: #E8F5E9;
  }
  .infoDelete {
    background: #FBE9E7;
  }
  .infoUpdate {
    background: #FFF3E0;
  }
</style>
