/* eslint no-param-reassign: "off" */

import Vue from 'vue';
import Vuex from 'vuex';
import notifications from './modules/notifications';
import admin from './modules/admin/admin';
import airportContacts from './modules/airport/airportContactsCatalog';
import auth from './modules/auth';
import desk from './modules/desk';
import directory from './modules/directory';
import flightsCatalogs from './modules/flightCatalogs';
import flightMessages from './modules/flightMessages/flightMessages';
import operation from './modules/operation';
import ramp from './modules/ramp/ramp';
import passageOutbound from './modules/passageOutbound';
import passageInbound from './modules/passageInbound';
import queries from './modules/queries';
import scheduling from './modules/scheduling';
import tenant from './modules/tenant';
import time from './modules/time';
import i18n from './modules/i18n';
import websocket from './modules/websocket';
import passageBoarding from './modules/passageBoarding';
import waterToilet from './modules/ramp/waterToilet';
import waterToiletAircraftGround from './modules/ramp/waterToiletAircraftGround';
import lostAndFound from './modules/lostAndFound/lostAndFound';
import delivery from './modules/lostAndFound/delivery';
import billing from './modules/invoicing/billing';
import financialSystemsIntegration from './modules/invoicing/financialSystemsIntegration';
import rampGantt from './modules/ramp/rampGantt';
import aicDocuments from './modules/aicDocuments/aicDocumentsCatalog';
import flight from './modules/flight';
import rushBaggage from './modules/lostAndFound/rushBaggage';
import contingency from './modules/contingency/contingency';
import contingencyDetail from './modules/contingency/contingencyDetail';
import contingencyPassageState from './modules/contingency/contingencyPassageState';
import contingencyInformation from './modules/contingency/contingencyInformation';
import contingencyBasicService from './modules/contingency/contingencyBasicService';
import contingencyHtlTransport from './modules/contingency/contingencyHtlTransport';
import handlingTypes from './modules/handlingTypes/handlingTypesCatalog';
import userCatalog from './modules/user/userCatalog';
import delays from './modules/delays';
import aircraftGround from './modules/aircraftGround';
import mobile from './modules/mobile';
import handlingAgent from './modules/handlingAgent/handlingAgent';
import serviceAgent from './modules/serviceAgent/serviceAgent';
import integration from './modules/admin/integration';
import avinetUser from './modules/avinetUser';
import currency from './modules/currency';
import airlineFaresCatalog from './modules/airlineFaresCatalog';
import ticketingNumerationCatalog from './modules/ticketingNumerationCatalog';
import ticketing from './modules/ticketing';
import ticketingMobile from './modules/ticketingMobile';
import ticketingReports from './modules/ticketingReports/ticketingReports';
import messageDirectoryCatalog from './modules/messageDirectory/messageDirectoryCatalog';
import serviceTypeCatalog from './modules/serviceTypeCatalog';
import rampServiceImport from './modules/invoicing/rampServiceImport';
import flightTowingAdhocTask from './modules/towingAdhocTask/flightTowingAdhocTask';
import towingAdhocTask from './modules/towingAdhocTask/towingAdhocTask';
import airportServiceAgent from './modules/serviceAgent/airportServiceAgent';
import audit from './modules/audit/audit';
import noFlightsToShowMessage from './modules/noFlightsToShowMessages';
import airportDefaultCheckInTime from './modules/airportDefaultCheckInTime/airportDefaultCheckInTime';
import airportDefaultBoardingTime from './modules/airportDefaultBoardingTime/airportDefaultBoardingTime';
import milestoneCatalog from './modules/milestone/milestoneCatalog';
import airportMilestoneCatalog from './modules/airportMilestone/airportMilestoneCatalog';
import flightPaxTimesCatalog from './modules/flightPaxTimes/flightPaxTimesCatalog';
import versionCatalog from './modules/version/versionCatalog';
import schedulingAdminImports from './modules/schedulingAdminImports';

Vue.use(Vuex);

export const REQUEST_START = 'REQUEST_START';
export const REQUEST_SUCCESS = 'REQUEST_SUCCESS';
export const REQUEST_ERROR = 'REQUEST_ERROR';

// modules
const storeModules = {
  admin,
  airportContacts,
  auth,
  desk,
  directory,
  flightsCatalogs,
  flightMessages,
  notifications,
  operation,
  passageOutbound,
  passageInbound,
  ramp,
  rampGantt,
  queries,
  scheduling,
  tenant,
  time,
  i18n,
  websocket,
  passageBoarding,
  waterToilet,
  waterToiletAircraftGround,
  lostAndFound,
  delivery,
  billing,
  financialSystemsIntegration,
  rampServiceImport,
  aicDocuments,
  flight,
  rushBaggage,
  contingency,
  contingencyDetail,
  contingencyPassageState,
  contingencyInformation,
  contingencyBasicService,
  contingencyHtlTransport,
  handlingTypes,
  userCatalog,
  delays,
  aircraftGround,
  mobile,
  handlingAgent,
  serviceAgent,
  integration,
  avinetUser,
  currency,
  airlineFaresCatalog,
  ticketingNumerationCatalog,
  ticketing,
  ticketingMobile,
  ticketingReports,
  messageDirectoryCatalog,
  serviceTypeCatalog,
  flightTowingAdhocTask,
  towingAdhocTask,
  airportServiceAgent,
  audit,
  airportDefaultCheckInTime,
  airportDefaultBoardingTime,
  noFlightsToShowMessage,
  milestoneCatalog,
  airportMilestoneCatalog,
  flightPaxTimesCatalog,
  versionCatalog,
  schedulingAdminImports,
};

// initial state
const storeState = {
  loading: false,
};

// getters
const storeGetters = {};

// actions
const storeActions = {
};

// mutations
const storeMutations = {
  [REQUEST_START]: (state) => {
    state.loading = true;
  },
  [REQUEST_SUCCESS]: (state) => {
    state.loading = false;
  },
  [REQUEST_ERROR]: (state, error) => {
    state.loading = false;
    console.log(error); // eslint-disable-line no-console
  },
};

export default new Vuex.Store({
  modules: storeModules,
  state: storeState,
  getters: storeGetters,
  actions: storeActions,
  mutations: storeMutations,
});
