<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>

    <v-layout align-center justify-space-between>
      <h1 class="primary--text ma-1 title text-xs-left">{{ $t('operations.cancellationFlightInfo') }}</h1>
      <v-btn v-if="hasPermissions(rolePermission)" class="ma-1"
        slot="activator"
        dark
        small
        @click="restoreFlight">
        <span>{{$t("common.restoreFlight")}}</span>
      </v-btn>
    </v-layout>
    <v-card>
      <v-card-text >
        <v-container class="pa-0">
          <v-layout row wrap>
            <v-flex xs12 class="pa-0">
              <v-card flat>
                <v-card-text class="pa-0">
                  <p class="red--text mb-0">{{ $t('scheduling.cancellationFlightReason') }}</p>
                  <v-flex class="d-flex pa-0">
                    <p>{{ cancelInfo.reason }}</p>
                  </v-flex>
                </v-card-text>
              </v-card>
            </v-flex>
            <v-flex xs12 class="pa-0">
              <v-card flat>
                <v-card-text class="pa-0">
                  <p class="red--text mb-0">{{ $t('scheduling.cancellationFlightEvidence') }}</p>
                  <v-flex class="d-flex pa-0 cacellation-evidence-container">
                    <quill-editor v-model="cancelInfo.evidence" :options="quillOptions" @ready="onQuillReady"/>
                  </v-flex>
                </v-card-text>
              </v-card>
            </v-flex>
            <v-flex xs3 class="pa-0">
              <v-card flat>
                <v-card-text class="pa-0">
                  <p class="red--text mb-0">{{ $t('scheduling.cancellationFlightChannel') }}</p>
                  <v-flex class="d-flex pa-0">
                    <p>{{ cancelInfo.channel }}</p>
                  </v-flex>
                </v-card-text>
              </v-card>
            </v-flex>
            <v-flex xs3 class="pa-0">
              <v-card flat>
                <v-card-text class="pa-0">
                  <p class="red--text mb-0">{{ $t('scheduling.cancellationFlightInformant') }}</p>
                  <v-flex class="d-flex pa-0">
                    <p>{{ cancelInfo.informant }}</p>
                  </v-flex>
                </v-card-text>
              </v-card>
            </v-flex>
            <v-flex xs3 class="pa-0">
              <v-card flat>
                <v-card-text class="pa-0">
                  <p class="red--text mb-0">{{ $t('common.date') }}</p>
                  <v-flex class="d-flex pa-0">
                    <p>{{ cancelDate }}</p>
                  </v-flex>
                </v-card-text>
              </v-card>
            </v-flex>
            <v-flex xs3 class="pa-0">
              <v-card flat>
                <v-card-text class="pa-0">
                  <p class="red--text mb-0">{{ $t('common.time') }}</p>
                  <v-flex class="d-flex pa-0">
                    <p>{{ cancelTime }}</p>
                  </v-flex>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { quillEditor } from 'vue-quill-editor';
import 'quill/dist/quill.bubble.css';
import { OPERATION_RESTORE_FLIGHT } from '../../../../../store/modules/operation';
import { SCHED_RESTORE_FLIGHTS } from '../../../../../store/modules/scheduling';
import { FisModule } from '../../model/fisModule';
import RolePermissions from '../../../../mixins/RolePermissions.vue';

export default {
  name: 'AgGridConnectedFlightsDialogCancellationCellInfo',
  mixins: [RolePermissions],
  components: {
    quillEditor,
  },
  props: {
    cancelInfo: { type: Object, required: true },
    flightId: { type: Number, required: true },
    context: { required: true },
  },
  data() {
    return {
      quillOptions: this.getQuillOptions(),
    };
  },
  computed: {
    cancelDate() {
      return this.$moment(this.cancelInfo.date).format('DD/MM/YYYY');
    },
    cancelTime() {
      return this.$moment(this.cancelInfo.date).format('HH:mm');
    },
    rolePermission() {
      return `${this.$route.meta.role}_WR`;
    },
  },
  methods: {
    getQuillOptions() {
      return {
        theme: 'bubble',
        placeholder: this.$i18n.t('scheduling.cancellationFlightEvidenceEmpty'),
      };
    },
    onQuillReady(quill) {
      quill.disable();
    },
    restoreFlight() {
      if (this.context === FisModule.SCHEDULING) {
        this.$store.dispatch(SCHED_RESTORE_FLIGHTS, this.flightId);
      } else if (this.context === FisModule.OPERATION) {
        this.$store.dispatch(OPERATION_RESTORE_FLIGHT, this.flightId);
      }
      this.$emit('restore');
    },
  },
};
</script>

<style>
  .cacellation-evidence-container {
    border: 1px solid black;
    margin: 0 0 15px 0;
  }
</style>
