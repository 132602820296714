<template>
  <div>
    <v-autocomplete
      placeholder="..."
      :value="value"
      :label="label"
      :items="items"
      :filter="customFilter"
      :clearable="true"
      item-text="code"
      item-value="id"
      :rules="rules"
      :disabled="disabled"
      @input="$emit('input', $event)"
      :ref="refComp"
      hide-details
    >
      <template
        slot="item"
        slot-scope="{ item }"
      >
        <v-list-tile-content>
          <v-list-tile-title>{{ item.code }}</v-list-tile-title>
        </v-list-tile-content>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import { GET_AIRCRAFT_CONFIGS } from '../../store/modules/flightCatalogs';

export default {
  props: {
    value: { required: true },
    label: { type: String },
    charLenght: { type: Number },
    rules: { type: Array },
    disabled: { type: Boolean },
    refComp: {
      type: String,
      required: false,
      default: 'refComp',
    },
  },
  computed: {
    items: {
      get() {
        return this.$store.state.flightsCatalogs.aircraftConfigs;
      },
    },
  },
  methods: {
    getAircraftConfigs() {
      this.$store.dispatch(GET_AIRCRAFT_CONFIGS);
    },
    customFilter(item, queryText) {
      const textOne = item.code ? item.code.toLowerCase() : '';
      const searchText = queryText.toLowerCase();

      return textOne.indexOf(searchText) > -1;
    },
  },
  mounted() {
    this.getAircraftConfigs();
  },
};
</script>
