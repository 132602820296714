/* eslint no-param-reassign: 'off' */

import moment from 'moment';
import { HTTP } from '../../../http';
import { REQUEST_ERROR, REQUEST_START, REQUEST_SUCCESS } from '../../store';
import { NOTIFICATION_SHOW } from '../notifications';
import TicketingFilter from '../../../components/ticketing/model/ticketingFilter';
import i18n from '../../../plugins/i18n';

// action
export const EMPLOYEE_INCOME_TICKETING_REPORT_GRID_FIT = 'EMPLOYEE_INCOME_TICKETING_REPORT_GRID_FIT';
export const EMPLOYEE_INCOME_TICKETING_REPORT_GRID_REFRESH_CELLS = 'EMPLOYEE_INCOME_TICKETING_REPORT_GRID_REFRESH_CELLS';

export const EMPLOYEE_INCOME_TICKETING_REPORT_FIND = 'EMPLOYEE_INCOME_TICKETING_REPORT_FIND';
export const EMPLOYEE_INCOME_TICKETING_REPORT_FIT_COLUMNS_TO_VIEW = 'EMPLOYEE_INCOME_TICKETING_REPORT_FIT_COLUMNS_TO_VIEW';
export const EMPLOYEE_INCOME_TICKETING_REPORT_FIT_COLUMNS_TO_BEST = 'EMPLOYEE_INCOME_TICKETING_REPORT_FIT_COLUMNS_TO_BEST';

export const EMPLOYEE_INCOME_TICKETING_REPORT_RECALCULATE_FILTER = 'EMPLOYEE_INCOME_TICKETING_REPORT_RECALCULATE_FILTER';

// mutations
export const EMPLOYEE_INCOME_TICKETING_REPORT_FIND_SUCCESS = 'EMPLOYEE_INCOME_TICKETING_REPORT_FIND_SUCCESS';
export const EMPLOYEE_INCOME_TICKETING_REPORT_CHANGE_GRID_API = 'EMPLOYEE_INCOME_TICKETING_REPORT_CHANGE_GRID_API';
export const EMPLOYEE_INCOME_TICKETING_REPORT_FILTER_SET = 'EMPLOYEE_INCOME_TICKETING_REPORT_FILTER_SET';
export const EMPLOYEE_INCOME_TICKETING_REPORT_FILTER_SET_UNIT_TIME = 'EMPLOYEE_INCOME_TICKETING_REPORT_FILTER_SET_UNIT_TIME';
export const EMPLOYEE_INCOME_TICKETING_REPORT_UPDATE_FIT_COLUMNS_ON_RESIZE = 'EMPLOYEE_INCOME_TICKETING_REPORT_UPDATE_FIT_COLUMNS_ON_RESIZE';
export const EMPLOYEE_INCOME_TICKETING_REPORT_FILTER_RESET = 'EMPLOYEE_INCOME_TICKETING_REPORT_FILTER_RESET';
export const EMPLOYEE_INCOME_TICKETING_REPORT_FILTER_RESET_TIMEZONE = 'EMPLOYEE_INCOME_TICKETING_REPORT_FILTER_RESET_TIMEZONE';

// initial state
const storeState = {
  gridApi: null,
  filter: TicketingFilter.createEmptyFilter,
  rankingResults: [],
};

// getters
const storeGetters = {};

// actions
const storeActions = {
  [EMPLOYEE_INCOME_TICKETING_REPORT_FIND]: ({ commit, state }) => {
    commit(REQUEST_START);
    return HTTP.post('ticketing/reports/employee-income/find', state.filter)
      .then((resp) => {
        commit(REQUEST_SUCCESS);
        commit(EMPLOYEE_INCOME_TICKETING_REPORT_FIND_SUCCESS, resp.data);
        console.log('success'); // eslint-disable-line no-console
        console.log(resp.data); // eslint-disable-line no-console
      })
      .catch((err) => {
        commit(EMPLOYEE_INCOME_TICKETING_REPORT_FIND_SUCCESS, []);
        commit(REQUEST_ERROR, err);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
      });
  },
  [EMPLOYEE_INCOME_TICKETING_REPORT_CHANGE_GRID_API]: (state, gridApi) => {
    state.gridApi = gridApi.api;
  },
  [EMPLOYEE_INCOME_TICKETING_REPORT_GRID_FIT]: ({ state }) => {
    if (state.gridApi) {
      state.gridApi.sizeColumnsToFit();
    }
  },
  [EMPLOYEE_INCOME_TICKETING_REPORT_GRID_REFRESH_CELLS]: ({ state }) => {
    if (state.gridApi) {
      state.gridApi.refreshCells({ force: true });
    }
  },
  [EMPLOYEE_INCOME_TICKETING_REPORT_FIT_COLUMNS_TO_VIEW]: ({ state }) => {
    if (state.gridApi) {
      state.gridApi.sizeColumnsToFit();
    }
  },
  [EMPLOYEE_INCOME_TICKETING_REPORT_FIT_COLUMNS_TO_BEST]: ({ state }) => {
    if (state.gridApi && state.gridApi.columnController && state.gridApi.columnController.columnApi) {
      state.gridApi.columnController.columnApi.autoSizeAllColumns();
    }
  },
  [EMPLOYEE_INCOME_TICKETING_REPORT_RECALCULATE_FILTER]: ({ commit, state }) => {
    if (state.filter.dateFrom === null && state.filter.dateTo === null) {
      commit(EMPLOYEE_INCOME_TICKETING_REPORT_FILTER_RESET);
    } else {
      commit(EMPLOYEE_INCOME_TICKETING_REPORT_FILTER_RESET_TIMEZONE, state.filter.unitOfTime);
    }
  },
};

// mutations
const storeMutations = {
  [EMPLOYEE_INCOME_TICKETING_REPORT_FIND_SUCCESS]: (state, results) => {
    state.rankingResults = results;
  },
  [EMPLOYEE_INCOME_TICKETING_REPORT_CHANGE_GRID_API]: (state, gridApi) => {
    state.gridApi = gridApi.api;
  },
  [EMPLOYEE_INCOME_TICKETING_REPORT_FILTER_SET]: (state, filter) => {
    if (filter) {
      state.filter = filter;
    } else {
      state.filter = TicketingFilter.createEmptyFilter;
    }
  },
  [EMPLOYEE_INCOME_TICKETING_REPORT_FILTER_SET_UNIT_TIME]: (state, unitOfTime) => {
    state.filter.unitOfTime = unitOfTime;
    if (unitOfTime) {
      state.filter.dateFrom = moment().startOf(unitOfTime);
      state.filter.dateTo = moment().endOf(unitOfTime);
    }
  },
  [EMPLOYEE_INCOME_TICKETING_REPORT_UPDATE_FIT_COLUMNS_ON_RESIZE]: (state, fitColumnsOnResize) => {
    state.fitColumnsOnResize = fitColumnsOnResize;
  },
  [EMPLOYEE_INCOME_TICKETING_REPORT_FILTER_RESET]: (state) => {
    state.filter.dateFrom = moment().startOf('day');
    state.filter.dateTo = moment().endOf('day');
  },
  [EMPLOYEE_INCOME_TICKETING_REPORT_FILTER_RESET_TIMEZONE]: (state, unitOfTime) => {
    state.filter.unitOfTime = unitOfTime != null ? unitOfTime : 'day';
    state.filter.dateFrom = moment().startOf(state.filter.unitOfTime)
      .year(state.filter.dateFrom.year())
      .month(state.filter.dateFrom.month())
      .date(state.filter.dateFrom.date());
    state.filter.dateTo = moment().endOf(state.filter.unitOfTime)
      .year(state.filter.dateTo.year())
      .month(state.filter.dateTo.month())
      .date(state.filter.dateTo.date());
  },
};

export default {
  state: storeState,
  getters: storeGetters,
  actions: storeActions,
  mutations: storeMutations,
};
