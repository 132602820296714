<script>
import Vue from 'vue';
import { JSUtils } from '../../../shared/js/JSUtils';

import Arrowable from '../../../mixins/Arrowable.vue';

export default Vue.extend({
  mixins: [Arrowable],
  methods: {
    beforeReturnGetValue() {
      if (this.params.column.colDef && this.params.column.colDef.cellEditorCustomConfig && JSUtils.isFunction(this.params.column.colDef.cellEditorCustomConfig.beforeGetValue)) {
        this.params.column.colDef.cellEditorCustomConfig.beforeReturnGetValue(this.params);
      }
    },
    markCellsSelectedAsEdit() {
      this.params.column.colDef.isEditing = true;
      this.params.node.setSelected(true);
      const selectedRows = this.getSelectedRowsOrCurrentRow();
      selectedRows.forEach((s) => {
        /* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["s"] }] */
        s.editing = true;
      });

      this.params.api.refreshCells({ force: true, columns: [this.params.column] });
    },
    unmarkCellsSelectedAsEdit() {
      delete this.params.column.colDef.isEditing;
      const selectedRows = this.getSelectedRowsOrCurrentRow();
      selectedRows.forEach((s) => {
        /* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["s"] }] */
        delete s.editing;
      });

      this.params.api.refreshCells({ force: true, columns: [this.params.column] });
    },
    hasSelectedRows() {
      return this.params.api.getSelectedNodes().length > 0;
    },
    getSelectedRowsOrCurrentRow() {
      const selectedNodes = this.params.api.getSelectedNodes();
      if (selectedNodes.length > 0) {
        return selectedNodes.map(node => node.data);
      }
      return [this.params.node.data];
    },
  },
  created() {
    const selectedNodes = this.params.api.getSelectedNodes();
    if (!selectedNodes.includes(this.params.node)) {
      this.params.api.deselectAll();
    }
    // TODO clonar this.params.node.data?
  },
});
</script>
