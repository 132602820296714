<template>
  <v-stepper v-model="importStep" non-linear>
    <v-stepper-header>
      <v-stepper-step step="1" :complete="importStep > 1" :rules="[() => isValidFile]">
        {{$t("schedulingImport.selectFile")}}
        <small v-if="!isValidFile">{{$t("schedulingImport.incorrectFile")}}</small>
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step step="2" :complete="importStep > 2" :rules="[() => isValidOptions]">
        {{$t("schedulingImport.options")}}
        <small v-if="!isValidOptions">{{$t("schedulingImport.incorrectValues")}}</small>
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step step="3" :complete="importStep > 3" :rules="[() => isValidFileContent]">
        {{$t("schedulingImport.filePreview")}}
        <small v-if="!isValidFileContent">{{$t("schedulingImport.incorrectFileContent")}}</small>
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step step="4" :complete="importStep > 4" :rules="[() => isValidOptionsFilter]">
        {{$t("schedulingImport.contentOptions")}}
        <small v-if="!isValidOptionsFilter">{{$t("schedulingImport.incorrectValues")}}</small>
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step step="5" :complete="importStep > 5" :rules="[() => isValidFlightsUniqueKey]">
        {{$t("schedulingImport.validation")}}
        <small v-if="!isValidFlightsUniqueKey">{{$t("schedulingImport.validationError")}}</small>
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step step="6" :complete="importStep > 6" :rules="[() => isValidData]">
        {{$t("schedulingImport.validation")}}
        <small v-if="!isValidData">{{$t("schedulingImport.validationError")}}</small>
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step step="7" :complete="importStep > 7" :rules="[() => isValidData]">
        {{$t("schedulingImport.validation")}}
        <small v-if="!isValidData">{{$t("schedulingImport.validationError")}}</small>
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step step="8" :complete="importStep > 8" :rules="[() => !importError]">
        {{$t("schedulingImport.changesSummary")}}
        <small v-if="importError">{{$t("schedulingImport.serverError")}}</small>
      </v-stepper-step>
    </v-stepper-header>

    <loading-component />

    <v-stepper-items>
      <v-stepper-content step="1">
        <v-card>
          <v-card-text>
            <scheduling-import-step-file-selection v-model="importFile" />
            <v-menu offset-y style="width: 100%">
              <template v-slot:activator="{ on }">
                <v-btn flat color="primary" :disabled="loading" v-on="on"  style="margin: 0 auto"><v-icon>mdi-download</v-icon>{{$t("common.downloadTemplate")}}</v-btn>
              </template>
              <v-list two-line>
                <v-list-tile>
                  <v-list-tile-content>
                    <v-btn flat color="primary" @click="downloadTemplateDaily" :disabled="loading">{{$t("schedulingImport.flightDailySchedule")}}</v-btn>
                    <v-btn flat color="primary" @click="downloadTemplateSeasonal" :disabled="loading">{{$t("schedulingImport.flightSeasonalSchedule")}}</v-btn>
                  </v-list-tile-content>
                </v-list-tile>
              </v-list>
            </v-menu>
          </v-card-text>
          <v-card-actions>
            <v-btn flat color="primary" @click="cancel" :disabled="loading">{{$t("common.cancel")}}</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="step1Next" :disabled="loading || !isValidFile">{{$t("common.continue")}}</v-btn>
          </v-card-actions>
        </v-card>
      </v-stepper-content>

      <v-stepper-content step="2">
        <v-card>
          <v-card-text>
            <scheduling-import-step-options :import-options.sync="importOptions" />
          </v-card-text>
          <v-card-actions>
            <v-btn flat color="primary" @click="cancel" :disabled="loading">{{$t("common.cancel")}}</v-btn>
            <v-spacer></v-spacer>
            <v-btn flat color="primary" @click="step2Back" :disabled="loading">{{$t("common.back")}}</v-btn>
            <v-btn color="primary" @click="step2Next" :disabled="loading || !this.isValidOptions">{{$t("common.continue")}}</v-btn>
          </v-card-actions>
        </v-card>
      </v-stepper-content>

      <v-stepper-content step="3">
        <v-card>
          <v-card-text>
            <scheduling-import-step-file-preview :parsed-file="importParsedFile" />
          </v-card-text>
          <v-card-actions>
            <v-btn flat color="primary" @click="cancel" :disabled="loading">{{$t("common.cancel")}}</v-btn>
            <v-spacer></v-spacer>
            <v-btn flat color="primary" @click="step3Back" :disabled="loading">{{$t("common.back")}}</v-btn>
            <v-btn color="primary" @click="step3Next" :disabled="loading">{{$t("common.continue")}}</v-btn>
          </v-card-actions>
        </v-card>
      </v-stepper-content>

      <v-stepper-content step="4">
        <v-card>
          <v-card-text>
            <scheduling-import-step-options-filter :import-options.sync="importOptions" :import-parsed-file="importParsedFile" @dataImportOptionValidation="dataImportOptionValidation"/>
          </v-card-text>
          <v-card-actions>
            <v-btn flat color="primary" @click="cancel" :disabled="loading">{{$t("common.cancel")}}</v-btn>
            <v-spacer></v-spacer>
            <v-btn flat color="primary" @click="step4Back" :disabled="loading">{{$t("common.back")}}</v-btn>
            <v-btn color="primary" @click="step4Next" :disabled="loading || !(this.isValidOptionsFilter && this.importValidFlights)">{{$t("common.continue")}}</v-btn>
          </v-card-actions>
        </v-card>
      </v-stepper-content>

      <v-stepper-content step="5">
        <v-card>
          <v-card-text>
            <scheduling-import-step-flights-same-unique-key :items="importFlightsSameUniqueKey" v-if="importStep === 5"/>
          </v-card-text>
          <v-card-actions>
            <v-btn flat color="primary" @click="cancel" :disabled="loading">{{$t("common.cancel")}}</v-btn>
            <v-spacer></v-spacer>
            <v-btn flat color="primary" @click="step5Back" :disabled="loading">{{$t("common.back")}}</v-btn>
            <v-btn color="primary" @click="step5Next" :disabled="loading || !this.isValidFlightsUniqueKey">{{$t("common.continue")}}</v-btn>
          </v-card-actions>
        </v-card>
      </v-stepper-content>

      <v-stepper-content step="6">
        <v-card>
          <v-card-text>
            <scheduling-import-step-validation :items="importSummary" v-if="importStep === 6" @dataValidation="dataValidation"/>
          </v-card-text>
          <v-card-actions>
            <v-btn flat color="primary" @click="cancel" :disabled="loading">{{$t("common.cancel")}}</v-btn>
            <v-spacer></v-spacer>
            <v-btn flat color="primary" @click="step6Back" :disabled="loading">{{$t("common.back")}}</v-btn>
            <v-btn color="primary" @click="step6Next" :disabled="loading || !this.isValidData">{{$t("common.continue")}}</v-btn>
          </v-card-actions>
        </v-card>
      </v-stepper-content>

      <v-stepper-content step="7">
        <v-card>
          <v-card-text>
            <scheduling-import-step-flights-conflict :items="importPossiblySameFlights" v-if="importStep === 7" @dataValidation="dataValidation"/>
          </v-card-text>
          <v-card-actions>
            <v-btn flat color="primary" @click="cancel" :disabled="loading">{{$t("common.cancel")}}</v-btn>
            <v-spacer></v-spacer>
            <v-btn flat color="primary" @click="step7Back" :disabled="loading">{{$t("common.back")}}</v-btn>
            <v-btn color="primary" @click="step7Next" :disabled="loading || !this.isValidData">{{$t("common.continue")}}</v-btn>
          </v-card-actions>
        </v-card>
      </v-stepper-content>

      <v-stepper-content step="8">
        <v-card>
          <v-card-text>
            <scheduling-import-step-changes-summary :items="importFlightsSummary" :importOptions="importOptions" :summaryFlights="flightsTagSummary" v-if="importStep === 8" @on-grid-ready="onGridReady" />
          </v-card-text>
          <v-card-actions>
            <v-btn flat color="primary" @click="cancel" :disabled="loading">{{$t("common.cancel")}}</v-btn>
            <v-spacer></v-spacer>
            <v-btn flat color="primary" @click="exportCsv" :disabled="loading"><v-icon>mdi-download</v-icon>{{$t("common.exportCsv")}}</v-btn>
            <v-btn flat color="primary" @click="step8Back" :disabled="loading">{{$t("common.back")}}</v-btn>
            <v-btn color="primary" @click="step8Next" :disabled="loading || !importSummaryData || importSummaryData.length ==0">{{$t("common.import")}}</v-btn>
          </v-card-actions>
        </v-card>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>
<script>
import { saveAs } from 'file-saver';

import { NOTIFICATION_SHOW } from '../../../store/modules/notifications';
import { REQUEST_ERROR, REQUEST_START, REQUEST_SUCCESS } from '../../../store/store';

import LoadingComponent from '../../layout/LoadingComponent.vue';
import SchedulingImportStepFileSelection from './SchedulingImportStepFileSelection.vue';
import SchedulingImportStepOptions from './SchedulingImportStepOptions.vue';
import SchedulingImportStepOptionsFilter from './SchedulingImportStepOptionsFilter.vue';
import SchedulingImportStepFilePreview from './SchedulingImportStepFilePreview.vue';
import SchedulingImportStepValidation from './SchedulingImportStepValidation.vue';
import SchedulingImportStepChangesSummary from './SchedulingImportStepChangesSummary.vue';
import TimezoneConfigurable from '../../mixins/TimezoneConfigurable.vue';
import AgGridApiCsvExportable from '../../ag-grid/mixins/AgGridApiCsvExportable.vue';

import { SchedulingImportResult, SchedulingImportResultOperation } from '../model/schedulingImportResult';
import { SchedulingImportSummaryResult } from '../model/schedulingImportSummaryResult';
// import { SchedulingFlightsPossibleSame } from '../model/schedulingImportFlightsPossibleSame';
import SchedulingImportOptions from '../model/schedulingImportOptions';
import SchedulingImportParsedFile from '../model/schedulingImportParsedFile';
import { GET_AIRCRAFT } from '../../../store/modules/aircraft/aircraftCatalog';
import SchedulingImportStepFlightsConflict from './SchedulingImportStepFlightsConflict.vue';
import SchedulingImportStepFlightsSameUniqueKey from './SchedulingImportStepFlightsSameUniqueKey.vue';

export default {
  name: 'scheduling-import-stepper',
  mixins: [TimezoneConfigurable, AgGridApiCsvExportable],
  components: {
    LoadingComponent,
    SchedulingImportStepFileSelection,
    SchedulingImportStepOptions,
    SchedulingImportStepOptionsFilter,
    SchedulingImportStepFilePreview,
    SchedulingImportStepValidation,
    SchedulingImportStepChangesSummary,
    SchedulingImportStepFlightsConflict,
    SchedulingImportStepFlightsSameUniqueKey,
  },
  data() {
    return {
      importStep: 1,

      importFile: null,
      importOptions: null,
      importParsedFile: null,

      importError: false,
      importSummaryData: [],
      importFlightsSummaryData: [],
      importFlightsPossibleSameData: [],
      importValidData: true,
      importValidFlights: true,
      importSummaryConfirm: [],
      flightsTagSummary: {
        flightsImportSummary: [],
        flightsUpdateSummary: [],
        flightsDeleteSummary: [],
        flightsEqualSummary: [],
      },
    };
  },
  created() {
    this.importOptions = new SchedulingImportOptions();
    this.importOptions.timeZoneMode = this.globalTimezone;
  },
  computed: {
    loading() {
      return this.$store.state.loading;
    },
    isValidFile() {
      return !!this.importFile;
    },
    isValidOptions() {
      return this.importStep < 2 || this.importOptions.isValidOptionsFile();
    },
    isValidFileContent() {
      return this.importStep < 3 || !!this.importParsedFile;
    },
    isValidOptionsFilter() {
      return this.importStep < 4 || this.importOptions.isValidOptionsFilter();
    },
    isValidFlightsUniqueKey() {
      return this.importStep < 5 || !this.importFlightsSameUniqueKey || this.importFlightsSameUniqueKey.length === 0 || this.importError;
    },
    isValidData() {
      return this.importStep < 6 || this.importValidData || this.importError;
    },
    importSummary: {
      get() {
        return SchedulingImportSummaryResult.createSchedulingImportSummaryResultFromData(this.importSummaryData);
      },
      set(data) {
        this.importSummaryData = data;
      },
    },
    importFlightsSummary: {
      get() {
        return SchedulingImportResult.createSchedulingFlightsWithImportStateFromData(this.importFlightsSummaryData);
      },
      set(data) {
        this.importFlightsSummaryData = data;
      },
    },
    // importPossiblySameFlights: {
    //   get() {
    //     return this.importFlightsPossibleSameData;
    //   },
    //   set(data) {
    //     this.importFlightsPossibleSameData = data;
    //   },
    // },
    importPossiblySameFlights() {
      return this.importSummary.flightsPossiblySame;
    },
    importFlightsSameUniqueKey() {
      return this.importSummary.flightsSameUniqueKey;
    },
  },
  methods: {
    cancel() {
      this.clean();
      this.$emit('import-stepper-cancel');
    },
    confirm() {
      this.clean();
      this.$emit('import-stepper-confirm');
    },
    step1Next() {
      this.importStep = 2;
    },
    step2Back() {
      this.importStep = 1;
    },
    step2Next() {
      this.importParsedFile = SchedulingImportParsedFile.createFromFile(this.importFile.content, this.importOptions);
      this.importStep = 3;
    },
    step3Back() {
      this.importStep = 2;
    },
    step3Next() {
      if (this.importParsedFile && this.importParsedFile.airlines) {
        this.importOptions.airlines = this.importParsedFile.airlines;
      }
      this.importStep = 4;
    },
    step4Back() {
      this.importStep = 3;
    },
    step4Next() {
      this.uploadFile().then(() => {
        this.importStep = 5;
      });
    },
    step5Back() {
      this.importStep = 4;
    },
    step5Next() {
      this.importStep = 6;
    },
    step6Back() {
      this.importStep = 5;
    },
    step6Next() {
      this.importStep = 7;
      // this.getFlightsPossibleSame().then(() => {
      //   this.importStep = 7;
      // });
    },
    step7Back() {
      this.importStep = 6;
    },
    step7Next() {
      this.confirmFlightConflictChanges().then(() => {
        this.getSummaryChanges().then(() => {
          this.countFlightsTagSummary();
          this.importStep = 8;
        });
      });
    },
    step8Back() {
      this.cleanFlightsTagSummary();
      this.importStep = 7;
    },
    step8Next() {
      this.confirmChanges();
    },
    clean() {
      this.importStep = 1;
      this.importError = false;
      if (this.importFile && this.importFile.ref) {
        const input = this.importFile.ref;
        input.value = '';
        input.type = 'text';
        input.type = 'file';
      }
    },
    uploadFile() {
      const formData = new FormData();
      formData.append('file', this.importFile.ref.files[0]);
      formData.append('fileType', this.importOptions.fileType);
      formData.append('delimitierCharacter', this.importOptions.delimitierCharacter);
      formData.append('timeZoneMode', this.importOptions.timeZoneMode);
      formData.append('fromDate', this.$moment(this.importOptions.fromDate).format('YYYY-MM-DD'));
      formData.append('toDate', this.$moment(this.importOptions.toDate).format('YYYY-MM-DD')); // TODO
      formData.append('airlines', this.importOptions.airlines);

      this.$store.commit(REQUEST_START);
      this.importError = false;
      const post = this.$http.post('scheduling/import/load-file', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      post.then((resp) => {
        this.$store.commit(REQUEST_SUCCESS);
        this.importSummary = resp.data;
      }).catch((err) => {
        this.$store.commit(REQUEST_ERROR, err);
        this.importSummary = null;
        this.importError = true;
        this.$store.commit(NOTIFICATION_SHOW, {
          message: err.response.data.title || this.$i18n.t('notifications.errorNotification'),
          color: 'error',
        });
      });
      return post;
    },
    confirmFlightConflictChanges() {
      this.$store.commit(REQUEST_START);
      return this.$http.post(`scheduling/import/confirm-actions/${this.importSummary.importId}`, this.importPossiblySameFlights)
        .then(() => {
          this.$store.commit(REQUEST_SUCCESS);
        }).catch((err) => {
          this.$store.commit(REQUEST_ERROR, err);
          this.$store.commit(NOTIFICATION_SHOW, {
            message: this.$i18n.t('notifications.errorNotification'),
            color: 'error',
          });
        });
    },
    getFlightsPossibleSame() {
      this.$store.commit(REQUEST_START);
      return this.$http.get(`scheduling/import/flights-same/${this.importSummary.importId}`)
        .then((resp) => {
          this.$store.commit(REQUEST_SUCCESS);
          this.importPossiblySameFlights = resp.data;
        }).catch((err) => {
          this.$store.commit(REQUEST_ERROR, err);
          this.$store.commit(NOTIFICATION_SHOW, {
            message: this.$i18n.t('notifications.errorNotification'),
            color: 'error',
          });
        });
    },
    getSummaryChanges() {
      this.$store.commit(REQUEST_START);
      return this.$http.get(`scheduling/import/summary/${this.importSummary.importId}`)
        .then((resp) => {
          this.$store.commit(REQUEST_SUCCESS);
          this.importFlightsSummary = resp.data;
        }).catch((err) => {
          this.$store.commit(REQUEST_ERROR, err);
          this.$store.commit(NOTIFICATION_SHOW, {
            message: this.$i18n.t('notifications.errorNotification'),
            color: 'error',
          });
        });
    },
    confirmChanges() {
      this.$store.commit(REQUEST_START);
      return this.$http.post(`scheduling/import/confirm/${this.importSummary.importId}`)
        .then(() => {
          this.$store.commit(REQUEST_SUCCESS);
          this.$store.commit(NOTIFICATION_SHOW, {
            message: this.$i18n.t('schedulingImport.messageSuccess'),
            color: 'success',
          });
          this.confirm();
        }).catch((err) => {
          this.$store.commit(REQUEST_ERROR, err);
          this.$store.commit(NOTIFICATION_SHOW, {
            message: this.$i18n.t('notifications.errorNotification'),
            color: 'error',
          });
        });
    },
    onGridReady(gridApi) {
      this.setAgGridApi(gridApi);
    },
    exportCsv() {
      this.exportAgGridCsv('SchedulingfFlights_ImportResult');
    },
    downloadTemplateDaily() {
      return this.$http.get('scheduling/download/daily')
        .then((response) => {
          const blob = new Blob([response.data], { type: 'text/csv;charset=utf-8' });
          saveAs(blob, 'FLIGHT_DAILY_SCHEDULE.csv');
        }).catch(() => {

        });
    },
    downloadTemplateSeasonal() {
      return this.$http.get('scheduling/download/seasonal')
        .then((response) => {
          const blob = new Blob([response.data], { type: 'text/csv;charset=utf-8' });
          saveAs(blob, 'FLIGHT_SEASONAL_SCHEDULE.csv');
        }).catch(() => {

        });
    },
    dataValidation(data) {
      this.importValidData = data;
    },
    dataImportOptionValidation(data) {
      this.importValidFlights = data;
    },
    countFlightsTagSummary() {
      const that = this;
      this.importFlightsSummary.forEach((s) => {
        if (s.inbound != null && s.importOperationInbound === SchedulingImportResultOperation.INSERT) {
          that.flightsTagSummary.flightsImportSummary.push(s.inbound);
        }
        if (s.inbound != null && s.importOperationInbound === SchedulingImportResultOperation.DELETE) {
          that.flightsTagSummary.flightsDeleteSummary.push(s.inbound);
        }
        if (s.inbound != null && s.importOperationInbound === SchedulingImportResultOperation.MODIFY) {
          that.flightsTagSummary.flightsUpdateSummary.push(s.inbound);
        }
        if (s.inbound != null && s.importOperationInbound === SchedulingImportResultOperation.EQUAL) {
          that.flightsTagSummary.flightsEqualSummary.push(s.inbound);
        }
        if (s.outbound != null && s.importOperationOutbound === SchedulingImportResultOperation.INSERT) {
          that.flightsTagSummary.flightsImportSummary.push(s.outbound);
        }
        if (s.outbound != null && s.importOperationOutbound === SchedulingImportResultOperation.DELETE) {
          that.flightsTagSummary.flightsDeleteSummary.push(s.outbound);
        }
        if (s.outbound != null && s.importOperationOutbound === SchedulingImportResultOperation.MODIFY) {
          that.flightsTagSummary.flightsUpdateSummary.push(s.outbound);
        }
        if (s.outbound != null && s.importOperationOutbound === SchedulingImportResultOperation.EQUAL) {
          that.flightsTagSummary.flightsEqualSummary.push(s.outbound);
        }
      });
    },
    cleanFlightsTagSummary() {
      this.flightsTagSummary = {
        flightsImportSummary: [],
        flightsUpdateSummary: [],
        flightsDeleteSummary: [],
        flightsEqualSummary: [],
      };
    },
  },
  mounted() {
    this.$store.dispatch(GET_AIRCRAFT);
  },
};
</script>
<style>
</style>
