export default class FlightRampService {
  constructor(flightId, id, requestTs, requestedTs, requestedEndTs, startTs, finishTs, serviceId, serviceName, serviceDescription, units, remarks, requesterId, requesterName, orientation) {
    this.id = id;
    this.requestTs = requestTs;
    this.requestedTs = requestedTs;
    this.requestedEndTs = requestedEndTs;
    this.startTs = startTs;
    this.finishTs = finishTs;
    this.serviceId = serviceId;
    this.serviceName = serviceName;
    this.serviceDescription = serviceDescription;
    this.flightId = flightId;
    this.units = units;
    this.remarks = remarks;
    this.requesterId = requesterId;
    this.requesterName = requesterName;
    this.orientation = orientation;
  }

  static createFlightRampService(flightId) {
    return new FlightRampService(flightId);
  }

  static createFlightRampServiceFromData(flightId, data) {
    const flightRampService = new FlightRampService(flightId, null, data.requestTs, data.requestedTs, data.requestedEndTs, data.startTs, data.finishTs, data.id, data.name, data.description, data.units, data.remarks, data.requesterId, data.requesterName, data.orientation);
    return flightRampService;
  }
}
