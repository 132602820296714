<template>
  <v-container v-if="!isEmptyMilestones()" class="infoMobileMilestones">
    <v-card :dark="this.$vuetify.dark">
      <v-card-title class="py-2 px-3">
        <v-icon large>emoji_flags</v-icon>
        <h4 class="px-2">{{ $t('dispatcher.milestones') }}</h4>
        <v-spacer></v-spacer>
        <v-btn icon @click="showMilestones = !showMilestones">
          <v-icon large>{{ showMilestones ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-list class="pa-0" v-show="showMilestones">
        <div v-for="(milestone, index) in flightMobileMilestones" :key="milestone.milestoneId"
          :class="milestone.milestoneType">
          <div
            v-if="milestone.airportServiceAgentPermission === 'READ' || milestone.airportServiceAgentPermission === 'EDIT'">
            <v-list-tile>
              <v-list-tile-action>
                <v-icon :color="milestone.airportServiceAgentPermission === 'EDIT' ? 'secondary' : 'grey'">{{
    iconByType(milestone.milestoneType) }}</v-icon>
              </v-list-tile-action>
              <v-list-tile-content>
                <v-list-tile-title>{{ milestone.milestoneName }}
                </v-list-tile-title>
              </v-list-tile-content>
              <v-list-tile-action class="full-width">
                <mobile-input-type :name="milestone.milestoneName" :value="milestone.flightMilestoneValue"
                  :typeValue="milestone.milestoneType" :editable="milestone.airportServiceAgentPermission === 'EDIT'"
                  @input="saveMilestone(milestone, $event)"
                  :class="milestone.airportServiceAgentPermission === 'EDIT' ? 'editable' : 'readonly'"
                  :options="milestone.milestoneOptions"></mobile-input-Type>
              </v-list-tile-action>
            </v-list-tile>
            <v-divider inset v-if="index < flightMobileMilestones.length - 1"></v-divider>
          </div>
        </div>
      </v-list>
    </v-card>
  </v-container>
</template>

<script>

import FlightStatus from '../flights/FlightStatus.vue';
import { MOBILE_GET_FLIGHT_MILESTONES, MOBILE_UPDATE_FLIGHT_MILESTONE } from '../../store/modules/mobile';
import MobileInputType from './mobileInputType/MobileInputType.vue';
import { TIME_TIMEZONE_CHANGED } from '../../store/modules/time';
import StoreSubscribable from '../mixins/StoreSubscribable.vue';
import MobileFlightUtil from './model/mobileFlightUtil';
import { MilestoneTypes as TYPE } from '../shared/model/milestoneTypes';
import './infoMobileMilestones.scss';

export default {
  name: 'info-mobile-milestones',
  props: {
    flightId: {
      required: true,
    },
  },
  components: {
    FlightStatus,
    MobileInputType,
  },
  mixins: [StoreSubscribable],
  data() {
    return {
      localTimezone: this.$store.state.time.timezone,
      showMilestones: true,
    };
  },
  methods: {
    saveMilestone(milestone, newValue) {
      const milestoneValue = {
        milestoneType: milestone.milestoneType,
      };
      let valid = false;

      switch (milestone.milestoneType) {
        case TYPE.TEXT:
          milestoneValue.milestoneStringValue = newValue;
          valid = true;
          break;
        case TYPE.DATE:
          if (newValue === 'invalid-date' || newValue === null) {
            milestoneValue.milestoneTimeValue = null;
          } else {
            milestoneValue.milestoneDateValue = this.$moment(newValue).format('YYYY-MM-DD');
          }
          valid = true;
          break;
        case TYPE.TIME:
          milestoneValue.milestoneTimeValue = MobileFlightUtil.setTimeToBackend(newValue, this.localTimezone);
          valid = true;
          break;
        case TYPE.DATETIME:
          milestoneValue.milestoneDateTimeValue = MobileFlightUtil.setDateTimeToBackend(newValue, this.localTimezone);
          valid = true;
          break;
        case TYPE.NUMBER:
          milestoneValue.milestoneNumberValue = newValue;
          valid = true;
          break;
        case TYPE.BOOLEAN:
          milestoneValue.milestoneBooleanValue = newValue;
          valid = true;
          break;
        case TYPE.OPTIONS:
          milestoneValue.milestoneOptionsValue = Object.prototype.hasOwnProperty.call(newValue, 'id') ? newValue.id : null;
          valid = true;
          break;
        default:
          valid = false;
          break;
      }
      if (valid) {
        this.$store.dispatch(MOBILE_UPDATE_FLIGHT_MILESTONE, {
          flightId: this.flightId,
          milestoneId: milestone.milestoneId,
          value: milestoneValue,
        });
      }
    },
    isEmptyMilestones() {
      return this.flightMobileMilestones.length === 0;
    },
    iconByType(type) {
      switch (type) {
        case TYPE.TEXT:
          return 'mdi-format-text';
        case TYPE.DATE:
          return 'mdi-calendar';
        case TYPE.TIME:
          return 'mdi-clock-outline';
        case TYPE.DATETIME:
          return 'mdi-calendar-clock';
        case TYPE.NUMBER:
          return 'mdi-numeric';
        case TYPE.BOOLEAN:
          return 'mdi-checkbox-marked-outline';
        case TYPE.OPTIONS:
          return 'mdi-format-list-bulleted';
        default:
          return 'mdi-help-circle-outline';
      }
    },
  },
  computed: {
    flightMobileMilestones() {
      return this.$store.getters.getFlightMobileMilestones;
    },
  },
  watch: {
    flightId() {
      if (this.flightId) {
        this.$store.dispatch(MOBILE_GET_FLIGHT_MILESTONES, this.flightId);
      }
    },
  },
  created() {
    this.subscribeMutation(TIME_TIMEZONE_CHANGED, () => {
      this.localTimezone = this.$store.state.time.timezone;
    });
    if (this.flightId) {
      this.$store.dispatch(MOBILE_GET_FLIGHT_MILESTONES, this.flightId);
    }
  },
};
</script>
