/* eslint no-param-reassign: "off" */

import moment from 'moment';
import { HTTP } from '../../../http';
import { REQUEST_ERROR, REQUEST_START, REQUEST_SUCCESS } from '../../store';
import { NOTIFICATION_SHOW } from '../notifications';
import BillingFilter from '../../../components/billing/model/billingFilter';
import ConnectedFlightsServiceBillingFactory from '../../../components/shared/model/connectedFlightsServiceBillingFactory';
import { FlightBound } from '../../../components/shared/model/flightBound';
import i18n from '../../../plugins/i18n';
import { ACTION_SET_NO_FLIGHTS_TO_SHOW_MESSAGE } from '../noFlightsToShowMessages';
import { AircraftStatus } from '../../../components/billing/model/aircraftStatus';
import PaginationResponseProperties from '../../../components/ui/pagination/model/paginationResponseProperties';

// action
export const BILLING_GRID_FIT = 'BILLING_GRID_FIT';
export const BILLING_GRID_REFRESH_CELLS = 'BILLING_GRID_REFRESH_CELLS';
export const BILLING_CHANGE_GRID_API = 'BILLING_CHANGE_GRID_API';

export const BILLING_FIND_FLIGHTS = 'BILLING_FIND_FLIGHTS';
export const BILLING_FLIGHT_UPDATE = 'BILLING_FLIGHT_UPDATE';
export const BILLING_FLIGHTS_UPDATE = 'BILLING_FLIGHTS_UPDATE';
export const BILLING_FIT_COLUMNS_TO_VIEW = 'BILLING_FIT_COLUMNS_TO_VIEW';
export const BILLING_FIT_COLUMNS_TO_BEST = 'BILLING_FIT_COLUMNS_TO_BEST';
export const BILLING_DELETE_AIRCRAFT_GROUND = 'BILLING_DELETE_AIRCRAFT_GROUND';

export const BILLING_RECALCULATE_FILTER = 'BILLING_RECALCULATE_FILTER';

// actions pagination
export const BILLING_UPDATE_FILTER_PAGINATION = 'BILLING_UPDATE_FILTER_PAGINATION';
export const BILLING_UPDATE_FILTER_PAGINATION_PAGE_SIZE = 'BILLING_UPDATE_FILTER_PAGINATION_PAGE_SIZE';
export const BILLING_UPDATE_FILTER_PAGINATION_CURRENT_PAGE = 'BILLING_UPDATE_FILTER_PAGINATION_CURRENT_PAGE';
export const BILLING_UPDATE_FILTER_PAGINATION_SET_FIRST_PAGE = 'BILLING_UPDATE_FILTER_PAGINATION_SET_FIRST_PAGE';

// mutations
export const BILLING_FIND_FLIGHTS_SUCCESS = 'BILLING_FIND_FLIGHTS_SUCCESS';
export const BILLING_CHANGE_FLIGHT_SELECTION = 'BILLING_CHANGE_FLIGHT_SELECTION';

export const BILLING_FILTER_SET = 'BILLING_FILTER_SET';
export const BILLING_FILTER_SET_UNIT_TIME = 'BILLING_FILTER_SET_UNIT_TIME';
export const BILLING_UPDATE_FIT_COLUMNS_ON_RESIZE = 'BILLING_UPDATE_FIT_COLUMNS_ON_RESIZE';
export const BILLING_FILTER_RESET = 'BILLING_FILTER_RESET';
export const BILLING_FILTER_RESET_TIMEZONE = 'BILLING_FILTER_RESET_TIMEZONE';
export const FILTER_SHOW_ONLY_AIRCRAFT_ON_AIR = 'FILTER_SHOW_ONLY_AIRCRAFT_ON_AIR';
export const FILTER_SHOW_ONLY_AIRCRAFT_ON_GROUND = 'FILTER_SHOW_ONLY_AIRCRAFT_ON_GROUND';
export const UPDATE_FILTER_SHOW_ONLY_AIRCRAFT_BY_STATUS = 'UPDATE_FILTER_SHOW_ONLY_AIRCRAFT_BY_STATUS';
// mutations pagination
export const BILLING_SET_FILTER_PAGINATION_PAGE_SIZE = 'BILLING_SET_FILTER_PAGINATION_PAGE_SIZE';
export const BILLING_SET_FILTER_PAGINATION_CURRENT_PAGE = 'BILLING_SET_FILTER_PAGINATION_CURRENT_PAGE';
export const BILLING_SET_PAGINATION_RESPONSE_PROPERTIES = 'BILLING_SET_PAGINATION_RESPONSE_PROPERTIES';
export const BILLING_SET_PAGINATION_RESPONSE_DEFAULT_PROPERTIES = 'BILLING_SET_PAGINATION_RESPONSE_DEFAULT_PROPERTIES';
export const BILLING_SET_FILTER_PAGINATION_FIRST_PAGE = 'BILLING_SET_FILTER_PAGINATION_FIRST_PAGE';
// initial state
const storeState = {
  gridApi: null,
  filter: BillingFilter.createEmptyFilter,
  paginationResponseProperties: PaginationResponseProperties.createDefaultPaginationResponseProperties,
  flights: [],
  selectedFlights: [],
  fitColumnsOnResize: true,
};

// getters
const storeGetters = {
  hasSelectedBillingFlights: state => state.selectedFlights.length > 0,
  getStateBilling: state => state.selectedFlights,
  isSelectedBillingFlights: state => id => !!state.selectedFlights.find(s => s.getId() === id),
  hasSelectedAircraftGroundBilling: state => state.selectedFlights.map(sel => sel.connectedFlights).filter(connectedFlights => connectedFlights.getChildPropertyValue(FlightBound.INBOUND, ['common', 'id']) != null && connectedFlights.getChildPropertyValue(FlightBound.INBOUND, ['common', 'tripNumber']) == null).length > 0,
  getSelectedAircraftGroundBilling: state => state.selectedFlights.map(sel => sel.connectedFlights).filter(connectedFlights => connectedFlights.getChildPropertyValue(FlightBound.INBOUND, ['common', 'id']) != null && connectedFlights.getChildPropertyValue(FlightBound.INBOUND, ['common', 'tripNumber']) == null),
  billingPaginationResponseProperties: state => state.paginationResponseProperties,
};

// actions
const storeActions = {
  [BILLING_FIND_FLIGHTS]: ({ dispatch, commit, state }) => {
    commit(REQUEST_START);
    commit(BILLING_CHANGE_FLIGHT_SELECTION, []);
    return HTTP.post('billing/flights/find-services', state.filter)
      .then((resp) => {
        commit(REQUEST_SUCCESS);
        commit(BILLING_FIND_FLIGHTS_SUCCESS, resp.data.content);
        commit(BILLING_CHANGE_FLIGHT_SELECTION, []);
        commit(BILLING_SET_PAGINATION_RESPONSE_PROPERTIES, resp.data);
        dispatch(ACTION_SET_NO_FLIGHTS_TO_SHOW_MESSAGE, resp.status);
      })
      .catch((err) => {
        dispatch(ACTION_SET_NO_FLIGHTS_TO_SHOW_MESSAGE, err.response.status);
        commit(BILLING_SET_PAGINATION_RESPONSE_DEFAULT_PROPERTIES);
        commit(BILLING_FIND_FLIGHTS_SUCCESS, []);
        commit(REQUEST_ERROR, err);
      });
  },
  [BILLING_GRID_FIT]: ({ state }) => {
    if (state.gridApi) {
      state.gridApi.sizeColumnsToFit();
    }
  },
  [BILLING_GRID_REFRESH_CELLS]: ({ state }) => {
    if (state.gridApi) {
      state.gridApi.refreshCells({ force: true });
    }
  },
  [BILLING_FLIGHT_UPDATE]: ({ commit }, { billingFlight, field }) => {
    commit(REQUEST_START);

    return HTTP.put('billing/flights', billingFlight)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(NOTIFICATION_SHOW, {
          message: field ? `${i18n.t('notifications.flightFieldUpdate')} ${field}` : i18n.t('notifications.flightUpdate'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(REQUEST_ERROR, err);
      });
  },
  [BILLING_FLIGHTS_UPDATE]: ({ commit }, { connectedFlightsList, field }) => new Promise((resolve, reject) => {
    commit(REQUEST_START);

    HTTP.put('billing/flights', connectedFlightsList)
      .then((response) => {
        commit(REQUEST_SUCCESS);
        commit(NOTIFICATION_SHOW, {
          message: field ? `${i18n.t('notifications.flightFieldUpdate')} ${field}` : i18n.t('notifications.flightUpdate'),
          color: 'success',
        });
        resolve(response);
      })
      .catch((err) => {
        commit(REQUEST_ERROR, err);
        reject(err);
      });
  }),
  [BILLING_FIT_COLUMNS_TO_VIEW]: ({ state }) => {
    if (state.gridApi) {
      state.gridApi.sizeColumnsToFit();
    }
  },
  [BILLING_FIT_COLUMNS_TO_BEST]: ({ state }) => {
    if (state.gridApi && state.gridApi.columnController && state.gridApi.columnController.columnApi) {
      state.gridApi.columnController.columnApi.autoSizeAllColumns();
    }
  },
  [BILLING_DELETE_AIRCRAFT_GROUND]: ({ commit, dispatch }, ids) => {
    commit(REQUEST_START);
    return HTTP.post('aircraft-ground/batch-delete', ids)
      .then(() => {
        commit(REQUEST_SUCCESS);
        dispatch(BILLING_FIND_FLIGHTS);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.aircraftGroundDelete'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
  [BILLING_RECALCULATE_FILTER]: ({ commit, state }) => {
    if (state.filter.dateFrom === null && state.filter.dateTo === null) {
      commit(BILLING_FILTER_RESET);
    } else {
      commit(BILLING_FILTER_RESET_TIMEZONE, state.filter.unitOfTime);
    }
  },
  [UPDATE_FILTER_SHOW_ONLY_AIRCRAFT_BY_STATUS]: ({ commit }, aircraftStatus) => {
    if (aircraftStatus) {
      if (aircraftStatus === AircraftStatus.GROUND) {
        commit(FILTER_SHOW_ONLY_AIRCRAFT_ON_GROUND);
      }
      if (aircraftStatus === AircraftStatus.FLIGHT) {
        commit(FILTER_SHOW_ONLY_AIRCRAFT_ON_AIR);
      }
    }
  },
  [BILLING_UPDATE_FILTER_PAGINATION_SET_FIRST_PAGE]: ({ commit }) => {
    commit(BILLING_SET_FILTER_PAGINATION_FIRST_PAGE);
  },
  [BILLING_UPDATE_FILTER_PAGINATION_PAGE_SIZE]: ({ commit, dispatch }, newPageSize) => {
    commit(BILLING_SET_FILTER_PAGINATION_PAGE_SIZE, newPageSize);
    dispatch(BILLING_FIND_FLIGHTS);
  },
  [BILLING_UPDATE_FILTER_PAGINATION_CURRENT_PAGE]: ({ commit, dispatch }, newCurrentPage) => {
    commit(BILLING_SET_FILTER_PAGINATION_CURRENT_PAGE, newCurrentPage);
    dispatch(BILLING_FIND_FLIGHTS);
  },
};

// mutations
const storeMutations = {
  [BILLING_CHANGE_GRID_API]: (state, gridApi) => {
    state.gridApi = gridApi.api;
  },
  [BILLING_FIND_FLIGHTS_SUCCESS]: (state, flights) => {
    state.flights = ConnectedFlightsServiceBillingFactory.createConnectedFlightsServiceBillingFromData(flights);
    // state.flights = ConnectedFlightsFactory.createConnectedFlightsFromData(flights); // FIXME sacar a otra factoria comun
  },
  [BILLING_CHANGE_FLIGHT_SELECTION]: (state, selection) => {
    state.selectedFlights = selection;
  },
  [BILLING_FILTER_SET]: (state, filter) => {
    if (filter) {
      state.filter = filter;
    } else {
      state.filter = BillingFilter.createEmptyFilter;
    }
  },
  [BILLING_FILTER_SET_UNIT_TIME]: (state, unitOfTime) => {
    state.filter.unitOfTime = unitOfTime;
    if (unitOfTime) {
      state.filter.dateFrom = moment().startOf(unitOfTime);
      state.filter.dateTo = moment().endOf(unitOfTime);
    }
  },
  [BILLING_UPDATE_FIT_COLUMNS_ON_RESIZE]: (state, fitColumnsOnResize) => {
    state.fitColumnsOnResize = fitColumnsOnResize;
  },
  [BILLING_FILTER_RESET]: (state) => {
    state.filter.dateFrom = moment().startOf('day');
    state.filter.dateTo = moment().endOf('day');
  },
  [BILLING_FILTER_RESET_TIMEZONE]: (state, unitOfTime) => {
    state.filter.unitOfTime = unitOfTime != null ? unitOfTime : 'day';
    state.filter.dateFrom = moment().startOf(state.filter.unitOfTime)
      .year(state.filter.dateFrom.year())
      .month(state.filter.dateFrom.month())
      .date(state.filter.dateFrom.date());
    state.filter.dateTo = moment().endOf(state.filter.unitOfTime)
      .year(state.filter.dateTo.year())
      .month(state.filter.dateTo.month())
      .date(state.filter.dateTo.date());
  },
  [FILTER_SHOW_ONLY_AIRCRAFT_ON_AIR]: (state) => {
    state.filter.showFlights = true;
    state.filter.showAircraftGround = false;
  },
  [FILTER_SHOW_ONLY_AIRCRAFT_ON_GROUND]: (state) => {
    state.filter.showFlights = false;
    state.filter.showAircraftGround = true;
  },
  [BILLING_SET_FILTER_PAGINATION_PAGE_SIZE]: (state, newPageSize) => {
    state.filter.pageRequestProperties.pageSize = newPageSize;
    state.filter.pageRequestProperties.currentPage = 1;
  },
  [BILLING_SET_FILTER_PAGINATION_CURRENT_PAGE]: (state, newCurrentPage) => {
    state.filter.pageRequestProperties.currentPage = newCurrentPage;
  },
  [BILLING_SET_PAGINATION_RESPONSE_PROPERTIES]: (state, pagedResult) => {
    state.paginationResponseProperties = PaginationResponseProperties.createFromPagedResult(pagedResult);
  },
  [BILLING_SET_PAGINATION_RESPONSE_DEFAULT_PROPERTIES]: (state) => {
    state.paginationResponseProperties = PaginationResponseProperties.createDefaultPaginationResponseProperties;
  },
  [BILLING_SET_FILTER_PAGINATION_FIRST_PAGE]: (state) => {
    state.filter.pageRequestProperties.currentPage = 1;
  },
};

export default {
  state: storeState,
  getters: storeGetters,
  actions: storeActions,
  mutations: storeMutations,
};
