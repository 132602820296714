export default class RampServiceImportSummaryChanges {
  constructor(flightRampServicesImport, aircraftGroundServicesImport, aircraftGroundServicesImportThatCreateAircraftGround, servicesThatNotFoundFlight, servicesThatNotFoundServiceIdentifier, aircraftWithoutAircraftType) {
    this.flightRampServicesImport = flightRampServicesImport;
    this.aircraftGroundServicesImport = aircraftGroundServicesImport;
    this.aircraftGroundServicesImportThatCreateAircraftGround = aircraftGroundServicesImportThatCreateAircraftGround;
    this.servicesThatNotFoundFlight = servicesThatNotFoundFlight;
    this.servicesThatNotFoundServiceIdentifier = servicesThatNotFoundServiceIdentifier;
    this.aircraftWithoutAircraftType = aircraftWithoutAircraftType;
  }

  static createRampServiceImportSummaryChangesFromData(data) {
    return new RampServiceImportSummaryChanges(data.flightRampServicesImport, data.aircraftGroundServicesImport, data.aircraftGroundServicesImportThatCreateAircraftGround, data.servicesThatNotFoundFlight, data.servicesThatNotFoundServiceIdentifier, data.aircraftWithoutAircraftType);
  }
}
