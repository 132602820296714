<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div class="ramp-status-table">

    <ag-grid-dialog-template-editor :is-dialog-open="isDialogOpen" @save="save" @cancel="cancel"
      :saveDisabled="validData" modalMaxWidth="1500px">

      <template v-slot:header v-if="!isAircraftGround">
        {{ $t("rampServices.modalTitle") }} {{ flightNumber }}
      </template>

      <template v-slot:header v-else>
        {{$t("rampServices.modalTitleAircraftGround")}}
      </template>

      <template v-slot:default>
        <v-card>
          <v-card-text class="services-table ramp-status-table">
            <ag-grid-vue class="ag-theme-material" :columnDefs="columnDefs" :defaultColDef="defaultColDef"
              @grid-ready="setAgGridApi" :gridOptions="gridOptions" rowSelection="multiple"
              @selection-changed="onSelectionChanged" :frameworkComponents="frameworkComponents" :rowData="rowData">
            </ag-grid-vue>
          </v-card-text>
        </v-card>
      </template>
      <template v-slot:footer v-if="hasPermissions(rolePermission)">
        <div style="display: flex; margin-left: 8px">
          <v-btn color="secondary" @click="addFlightRampService()">{{ $t("rampServices.newServiceButton") }}</v-btn>
          <div>
            <v-menu offset-y>
              <template v-slot:activator="{ on }">
                <v-btn color="#3f88c5" v-on="on" dark style="min-width: auto">
                  <v-icon>arrow_drop_down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-tile v-for="(item, i) in activeDefaultServices" :key="i" @click="item.callback()">
                  <v-list-tile-title>{{ item.title }}</v-list-tile-title>
                </v-list-tile>
              </v-list>
            </v-menu>
          </div>
        </div>
        <v-btn v-show="rampStatusSelected.length > 0" color="error" flat
          @click="removeRampStatus()"><v-icon>delete</v-icon></v-btn>
      </template>
    </ag-grid-dialog-template-editor>
  </div>
</template>

<script>
import Vue from 'vue';
import { AgGridVue } from 'ag-grid-vue';
import AgGridDialogParentCellEditor from '../../mixins/AgGridDialogParentCellEditor.vue';
import AgGridDialogTemplateEditor from '../../templates/AgGridDialogTemplateEditor.vue';
import CrudOperationsDTO from '../../../../shared/model/crudOperationsDTO';
import { JSUtils } from '../../../../shared/js/JSUtils';
import AgGridRampServiceSelectCellEditor from '../../AgGridRampServiceSelectCellEditor.vue';
import AgGridAirportRequesterSelectCellEditor from '../../AgGridAirportRequesterSelectCellEditor.vue';
import AgGridAirportParkingSelectCellEditor from '../../AgGridAirportParkingSelectCellEditor.vue';
import FlightRampService from '../../model/flightRampService';
import AgGridDateTimeCellEditor from '../../AgGridDateTimeCellEditor.vue';
import agGridDateTimeFormatter from '../../../formatters/agGridDateTimeFormatter';
import { cellClassAgGridRules } from '../../../mixins/cellClassAgGridRules';
import AgGridOptionsConfigurable from '../../../mixins/AgGridOptionsConfigurable.vue';
import AgGridNumberCellEditor from '../../AgGridNumberCellEditor.vue';
import AgGridTextCellEditor from '../../AgGridTextCellEditor.vue';
import AgGridValueWithAsteriskCellRenderer from '../../../cell-renderers/AgGridValueWithAsteriskCellRenderer.vue';
import AgGridValueBaseWithTooltipCellRenderer from '../../../cell-renderers/AgGridValueBaseWithTooltipCellRenderer.vue';
import { FlightBound } from '../../../../shared/model/flightBound';
import AgGridValueWithBackgroundCellRenderer
from '../../../cell-renderers/AgGridValueWithBackgroundCellRenderer.vue';
import { GET_RAMP_SERVICE } from '../../../../../store/modules/rampServices/rampServicesCatalog';
import AgGridDateTimeWithBackgroundCellRenderer from '../../../cell-renderers/AgGridDateTimeWithBackgroundCellRenderer.vue';
import AgGridFlightStatusCellRenderer from '../../../cell-renderers/AgGridFlightStatusCellRenderer.vue';
import AgGridOrientationSelectCellEditor from '../../AgGridOrientationSelectCellEditor.vue';
import OperationConverter from '../../../../rampService/OrientationConverter.vue';
import AgGridFlightNumberCellEditor from '../../AgGridFlightNumberCellEditor.vue';
import { GET_AIRPORT_RAMP_SERVICE } from '../../../../../store/modules/rampServices/airportRampServicesCatalog';

export default Vue.extend({
  mixins: [AgGridDialogParentCellEditor, AgGridOptionsConfigurable, OperationConverter],
  components: {
    AgGridDialogTemplateEditor,
    AgGridVue,
  },
  data() {
    return {
      referenceDate: null,
      isAircraftGround: false,
      columnDefs: null,
      default_services: [
        { title: `${this.$i18n.t('rampServices.newGPUServiceButton')}`, name: 'GPU', callback: () => this.addFlightRampService('GPU') },
        { title: `${this.$i18n.t('rampServices.newASUServiceButton')}`, name: 'ASU', callback: () => this.addFlightRampService('ASU') },
        { title: `${this.$i18n.t('rampServices.newOTHServiceButton')}`, name: 'OTH', callback: () => this.addFlightRampService('OTH') },
        { title: `${this.$i18n.t('rampServices.newPBKServiceButton')}`, name: 'PBK', callback: () => this.addFlightRampService('Pushback') },
      ],
      frameworkComponents: {
        AgGridRampServiceSelectCellEditor,
        AgGridDateTimeCellEditor,
        AgGridNumberCellEditor,
        AgGridValueWithAsteriskCellRenderer,
        AgGridValueBaseWithTooltipCellRenderer,
        AgGridValueWithBackgroundCellRenderer,
        AgGridTextCellEditor,
        AgGridDateTimeWithBackgroundCellRenderer,
        AgGridAirportRequesterSelectCellEditor,
        AgGridAirportParkingSelectCellEditor,
        AgGridOrientationSelectCellEditor,
        AgGridFlightNumberCellEditor,
      },
      show: false,
      flightMsg: new FlightRampService(),
      defaultColDef: {
        cellClassRules: cellClassAgGridRules,
      },
      gridOptions: {
        getRowClass: this.getRowClass,
      },
      rowData: [],
      validData: true,
      rampStatusSelected: [],
      crudOperationsDto: new CrudOperationsDTO(),
      errors: {},
      flightsInfo: [],
    };
  },
  computed: {
    flightNumber() {
      const inbound = this.connectedFlights.getChildPropertyValue(FlightBound.INBOUND, ['common', 'flightNumber']);
      const outbound = this.connectedFlights.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'flightNumber']);

      const arr = [];
      if (outbound) arr.push(outbound);
      if (inbound) arr.push(inbound);

      return arr.join(' - ');
    },
    rolePermission() {
      return `${this.$route.meta.role}_WR`;
    },
    activeDefaultServices() {
      return this.default_services.filter(defaultService => this.$store.getters.getActiveAirportRampServices.find(activeAirportRampService => activeAirportRampService.serviceName === defaultService.name));
    },
  },
  methods: {
    getFlightsInfo() {
      const info = [];
      const inboundFlightNumber = this.connectedFlights.getChildPropertyValue(FlightBound.INBOUND, ['common', 'flightNumber']);
      const outboundFlightNumber = this.connectedFlights.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'flightNumber']);

      if (inboundFlightNumber) {
        const inboundFlightId = this.connectedFlights.getChildPropertyValue(FlightBound.INBOUND, ['common', 'id']);
        info.push({ id: inboundFlightId, code: inboundFlightNumber });
      }

      if (outboundFlightNumber) {
        const outboundFlightId = this.connectedFlights.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'id']);
        info.push({ id: outboundFlightId, code: outboundFlightNumber });
      }

      return info;
    },
    getFlightNumberByFlightId(flightId) {
      if (this.flightsInfo && flightId) {
        const flightNumber = this.flightsInfo.filter(flightNumberInfo => flightNumberInfo.id === flightId);
        return flightNumber.length > 0 ? flightNumber[0].code : null;
      }

      return null;
    },
    getValue() {
      if (this.isSaving) {
        this.rowData.forEach((row) => {
          if (row.requestedTs !== null && row.requestTs === null) {
            /* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["row"] }] */
            row.requestTs = this.$moment();
          }
        });
        this.crudOperationsDto.updates.push(...this.rowData);
        this.requestInit();
        if (this.isAircraftGround) {
          this.$http.put(`aircraft-ground/${this.flightId}/ramp/services`, this.crudOperationsDto)
            .then(resp => this.dialogRequestSuccessCallback(this, resp))
            .catch(error => this.requestErrorCallback(this, error));
        } else {
          this.$http.put('flights/ramp/services', this.crudOperationsDto)
            .then(resp => this.dialogRequestSuccessCallback(this, resp))
            .catch(error => this.requestErrorCallback(this, error));
        }
      }

      return this.params.value;
    },
    onColumnResized(event) {
      if (event.finished) {
        this.gridApi.resetRowHeights();
      }
    },
    addFlightRampService(defRampName = null) {
      if (!defRampName) this.rowData.push(FlightRampService.createFlightRampService(this.flightId));
      else {
        const defRampService = this.$store.getters.getDefaultRampServices.filter(defaultRampService => defaultRampService.name === defRampName)[0];
        this.rowData.push(FlightRampService.createFlightRampServiceFromData(this.flightId, defRampService));
      }
      this.validateRampStatus();
    },
    removeRampStatus() {
      if (this.rampStatusSelected.length > 0) {
        this.crudOperationsDto.deletes.push(...this.rampStatusSelected.filter(tow => !!tow.id));

        this.rampStatusSelected.forEach((msg) => {
          const index = this.rowData.indexOf(msg);
          this.rowData.splice(index, 1);
        });
      }
      this.rampStatusSelected = [];
      this.validateRampStatus();
    },
    onSelectionChanged(event) {
      if (event.api.getSelectedNodes().length > 0) {
        this.rampStatusSelected = event.api.getSelectedNodes().map(n => n.data);
      } else {
        this.rampStatusSelected = [];
      }
    },
    validateRampStatus(params) {
      this.validData = false;
      this.columnDefs.slice(1, this.columnDefs.length).map(colDef => colDef.field).forEach((colField) => {
        this.rowData.forEach((data) => {
          this.validateColFieldOfRow(colField, data);
        });

        if (params) {
          params.api.refreshCells({ force: true });
        }
      });
    },
    validateColFieldOfRow(colField, rowData) {
      const data = rowData;
      const errorValidate = {};
      const errorMessages = [];

      errorValidate.errorExist = false;
      const rampService = this.$store.getters.getRampServiceById(data.serviceId);
      if (rampService) {
        if (colField === 'units') {
          if (rampService.unitsRequired && !data.units) {
            errorValidate.errorExist = true;
            this.validData = true;
            if (!errorMessages.includes(this.$i18n.t('validation.fieldRequired'))) errorMessages.push(this.$i18n.t('validation.fieldRequired'));
          }
          if (data.units != null && data.units < 0) {
            errorValidate.errorExist = true;
            this.validData = true;
            if (!errorMessages.includes(this.$i18n.t('validation.fieldShouldBeGreaterEqualThan0'))) errorMessages.push(this.$i18n.t('validation.fieldShouldBeGreaterEqualThan0'));
          }
        }
        if (colField === 'requestTs') {
          if (rampService.requestTimeRequired && !data.requestTs) {
            errorValidate.errorExist = true;
            this.validData = true;
            if (!errorMessages.includes(this.$i18n.t('validation.fieldRequired'))) errorMessages.push(this.$i18n.t('validation.fieldRequired'));
          }
        }
        if (colField === 'requestedTs') {
          if (rampService.requestStartTimeRequired && !data.requestedTs) {
            errorValidate.errorExist = true;
            this.validData = true;
            if (!errorMessages.includes(this.$i18n.t('validation.fieldRequired'))) errorMessages.push(this.$i18n.t('validation.fieldRequired'));
          }
        }
        if (colField === 'requestedEndTs') {
          if (rampService.requestEndTimeRequired && !data.requestedEndTs) {
            errorValidate.errorExist = true;
            this.validData = true;
            if (!errorMessages.includes(this.$i18n.t('validation.fieldRequired'))) errorMessages.push(this.$i18n.t('validation.fieldRequired'));
          }

          if (data.requestedEndTs != null && data.requestedTs != null && this.$moment(data.requestedEndTs).isBefore(this.$moment(data.requestedTs))) {
            errorValidate.errorExist = true;
            this.validData = true;
            if (!errorMessages.includes(this.$i18n.t('validation.finishTimeIsBeforeThatStartTime'))) errorMessages.push(this.$i18n.t('validation.finishTimeIsBeforeThatStartTime'));
          }
        }
        if (colField === 'startTs') {
          if (rampService.startTimeRequired && !data.startTs) {
            errorValidate.errorExist = true;
            this.validData = true;
            if (!errorMessages.includes(this.$i18n.t('validation.fieldRequired'))) errorMessages.push(this.$i18n.t('validation.fieldRequired'));
          }
        }
        if (colField === 'finishTs') {
          if (rampService.finishTimeRequired && !data.finishTs) {
            errorValidate.errorExist = true;
            this.validData = true;
            if (!errorMessages.includes(this.$i18n.t('validation.fieldRequired'))) errorMessages.push(this.$i18n.t('validation.fieldRequired'));
          }
          if (data.finishTs != null && data.startTs != null && this.$moment(data.finishTs).isBefore(this.$moment(data.startTs))) {
            errorValidate.errorExist = true;
            this.validData = true;
            if (!errorMessages.includes(this.$i18n.t('validation.finishTimeIsBeforeThatStartTime'))) errorMessages.push(this.$i18n.t('validation.finishTimeIsBeforeThatStartTime'));
          }
        }
        if (colField === 'requesterName') {
          if (rampService.requesterRequired && !data.requesterName) {
            errorValidate.errorExist = true;
            this.validData = true;
            if (!errorMessages.includes(this.$i18n.t('validation.fieldRequired'))) errorMessages.push(this.$i18n.t('validation.fieldRequired'));
          }
        }
        if (colField === 'remarks') {
          if (rampService.remarksRequired && !data.remarks) {
            errorValidate.errorExist = true;
            this.validData = true;
            if (!errorMessages.includes(this.$i18n.t('validation.fieldRequired'))) errorMessages.push(this.$i18n.t('validation.fieldRequired'));
          }
        }
        if (colField === 'originCode') {
          if (rampService.originRequired && !data.originCode) {
            errorValidate.errorExist = true;
            this.validData = true;
            if (!errorMessages.includes(this.$i18n.t('validation.fieldRequired'))) errorMessages.push(this.$i18n.t('validation.fieldRequired'));
          }
        }
        if (colField === 'destinationCode') {
          if (rampService.destinationRequired && !data.destinationCode) {
            errorValidate.errorExist = true;
            this.validData = true;
            if (!errorMessages.includes(this.$i18n.t('validation.fieldRequired'))) errorMessages.push(this.$i18n.t('validation.fieldRequired'));
          }
        }
        if (colField === 'orientation') {
          if (rampService.orientationRequired && !data.orientation) {
            errorValidate.errorExist = true;
            this.validData = true;
            if (!errorMessages.includes(this.$i18n.t('validation.fieldRequired'))) errorMessages.push(this.$i18n.t('validation.fieldRequired'));
          }
        }
      } else if (colField === 'serviceName') {
        errorValidate.errorExist = true;
        this.validData = true;
        if (!errorMessages.includes(this.$i18n.t('validation.fieldRequired'))) errorMessages.push(this.$i18n.t('validation.fieldRequired'));
      }

      if (data.messageValidate == null) {
        data.messageValidate = {};
      }

      errorValidate.errorMessages = errorMessages;
      data.messageValidate[colField] = errorValidate;
    },

    setColDefs() {
      const that = this;
      this.columnDefs = [
        {
          checkboxSelection: true,
          width: 50,
          hide: !that.hasPermissions(that.rolePermission),
          editable: that.editable(true, that.rolePermission),
        },
        {
          field: 'flightNumber',
          headerValueGetter() {
            return that.$i18n.t('rampServices.flightNumber');
          },
          editable: that.editable(true, that.rolePermission),
          cellEditor: 'AgGridFlightNumberCellEditor',
          cellEditorCustomConfig: {
            fieldId: 'flightId',
            fieldValues: this.getFlightsInfo(),
          },
          valueGetter(params) {
            return {
              value: that.getFlightNumberByFlightId(params.data.flightId),
            };
          },
          cellRendererFramework: AgGridValueWithBackgroundCellRenderer,
        },
        {
          field: 'serviceName',
          headerValueGetter() {
            return that.$i18n.t('rampServices.rampServices');
          },
          editable: that.editable(true, that.rolePermission),
          cellEditor: 'AgGridRampServiceSelectCellEditor',
          cellEditorCustomConfig: {
            fieldId: 'serviceId',
            fieldName: 'serviceName',
          },
          valueGetter(params) {
            return {
              value: params.data.serviceName,
              error: params.data.messageValidate.serviceName.errorExist,
              tooltip: params.data.messageValidate.serviceName.errorMessages.join(' '),
            };
          },
          onCellValueChanged: this.validateRampStatus,
          cellRendererFramework: AgGridValueWithBackgroundCellRenderer,
        },
        {
          field: 'units',
          headerValueGetter() {
            return that.$i18n.t('rampServices.units');
          },
          editable: that.editable(true, that.rolePermission),
          cellEditor: 'AgGridNumberCellEditor',
          valueGetter(params) {
            return {
              value: params.data.units ? params.data.units : null,
              error: params.data.messageValidate.units.errorExist,
              tooltip: params.data.messageValidate.units.errorMessages.join(' '),
              warning: params.data.unitsDefault,
            };
          },
          onCellValueChanged: this.validateRampStatus,
          cellRendererFramework: AgGridValueWithBackgroundCellRenderer,
        },
        {
          field: 'requestTs',
          headerTooltip: `${this.$i18n.t('rampServices.requestTime')}`,
          headerValueGetter() {
            return that.$i18n.t('rampServices.requestTime');
          },
          cellClass: 'date-time-cell',
          editable: that.editable(true, that.rolePermission),
          cellEditor: 'AgGridDateTimeCellEditor',
          cellEditorCustomConfig: {
            referenceDate: this.referenceDate,
          },
          valueGetter(params) {
            return {
              value: params.data.requestTs,
              error: params.data.messageValidate.requestTs.errorExist,
              tooltip: params.data.messageValidate.requestTs.errorMessages.join(' '),
            };
          },
          onCellValueChanged: this.validateRampStatus,
          cellRendererFramework: AgGridDateTimeWithBackgroundCellRenderer,
          valueFormatter: agGridDateTimeFormatter,
        },
        {
          field: 'requestedTs',
          headerTooltip: `${this.$i18n.t('rampServices.requestedStartTime')}`,
          headerValueGetter() {
            return that.$i18n.t('rampServices.requestedStartTime');
          },
          cellClass: 'date-time-cell',
          editable: that.editable(true, that.rolePermission),
          cellEditor: 'AgGridDateTimeCellEditor',
          cellEditorCustomConfig: {
            referenceDate: this.referenceDate,
          },
          valueGetter(params) {
            return {
              value: params.data.requestedTs,
              error: params.data.messageValidate.requestedTs.errorExist,
              tooltip: params.data.messageValidate.requestedTs.errorMessages.join(' '),
              warning: params.data.requestedTsDefault,
            };
          },
          onCellValueChanged: this.validateRampStatus,
          cellRendererFramework: AgGridDateTimeWithBackgroundCellRenderer,
          valueFormatter: agGridDateTimeFormatter,
        },
        {
          field: 'requestedEndTs',
          headerTooltip: `${this.$i18n.t('rampServices.requestedEndTime')}`,
          headerValueGetter() {
            return that.$i18n.t('rampServices.requestedEndTime');
          },
          cellClass: 'date-time-cell',
          editable: that.editable(true, that.rolePermission),
          cellEditor: 'AgGridDateTimeCellEditor',
          cellEditorCustomConfig: {
            referenceDate: this.referenceDate,
          },
          valueGetter(params) {
            return {
              value: params.data.requestedEndTs,
              error: params.data.messageValidate.requestedEndTs.errorExist,
              tooltip: params.data.messageValidate.requestedEndTs.errorMessages.join(' '),
              warning: params.data.requestedEndTsDefault,
            };
          },
          onCellValueChanged: this.validateRampStatus,
          cellRendererFramework: AgGridDateTimeWithBackgroundCellRenderer,
          valueFormatter: agGridDateTimeFormatter,
        },
        {
          field: 'startTs',
          headerValueGetter() {
            return that.$i18n.t('rampServices.startTime');
          },
          cellClass: 'date-time-cell',
          editable: that.editable(true, that.rolePermission),
          cellEditor: 'AgGridDateTimeCellEditor',
          cellEditorCustomConfig: {
            referenceDate: this.referenceDate,
          },
          valueGetter(params) {
            return {
              value: params.data.startTs,
              error: params.data.messageValidate.startTs.errorExist,
              tooltip: params.data.messageValidate.startTs.errorMessages.join(' '),
              warning: params.data.startTsDefault,
            };
          },
          onCellValueChanged: this.validateRampStatus,
          cellRendererFramework: AgGridDateTimeWithBackgroundCellRenderer,
          valueFormatter: agGridDateTimeFormatter,
        },
        {
          field: 'originCode',
          headerValueGetter() {
            return that.$i18n.t('rampServices.origin');
          },
          editable: that.editable(true, that.rolePermission),
          cellEditor: 'AgGridAirportParkingSelectCellEditor',
          cellEditorCustomConfig: {
            fieldId: 'originId',
          },
          valueGetter(params) {
            return {
              value: params.data.originCode,
              error: params.data.messageValidate.originCode.errorExist,
              tooltip: params.data.messageValidate.originCode.errorMessages.join(' '),
            };
          },
          onCellValueChanged: this.validateRampStatus,
          cellRendererFramework: AgGridValueWithBackgroundCellRenderer,
        },
        {
          field: 'finishTs',
          headerValueGetter() {
            return that.$i18n.t('rampServices.endTime');
          },
          cellClass: 'date-time-cell',
          editable: that.editable(true, that.rolePermission),
          cellEditor: 'AgGridDateTimeCellEditor',
          cellEditorCustomConfig: {
            referenceDate: this.referenceDate,
          },
          valueGetter(params) {
            return {
              value: params.data.finishTs,
              error: params.data.messageValidate.finishTs.errorExist,
              tooltip: params.data.messageValidate.finishTs.errorMessages.join(' '),
              warning: params.data.finishTsDefault,
            };
          },
          onCellValueChanged: this.validateRampStatus,
          cellRendererFramework: AgGridDateTimeWithBackgroundCellRenderer,
          valueFormatter: agGridDateTimeFormatter,
        },
        {
          field: 'destinationCode',
          headerValueGetter() {
            return that.$i18n.t('operations.destination');
          },
          valueGetter(params) {
            return {
              value: params.data.destinationCode,
              error: params.data.messageValidate.destinationCode.errorExist,
              tooltip: params.data.messageValidate.destinationCode.errorMessages.join(' '),
            };
          },
          editable: that.editable(true, that.rolePermission),
          cellEditor: 'AgGridAirportParkingSelectCellEditor',
          cellEditorCustomConfig: {
            fieldId: 'destinationId',
          },
          onCellValueChanged: this.validateRampStatus,
          cellRendererFramework: AgGridValueWithBackgroundCellRenderer,
        },
        {
          field: 'orientation',
          headerValueGetter() {
            return that.$i18n.t('common.orientation');
          },
          editable: that.editable(true, that.rolePermission),
          cellEditor: 'AgGridOrientationSelectCellEditor',
          valueGetter(params) {
            return {
              value: that.getOrientationName(params.data.orientation),
              error: params.data.messageValidate.orientation.errorExist,
              tooltip: params.data.messageValidate.orientation.errorMessages.join(' '),
              warning: params.data.orientationDefault,
            };
          },
          onCellValueChanged: this.validateRampStatus,
          cellRendererFramework: AgGridValueWithBackgroundCellRenderer,
        },
        {
          field: 'requesterName',
          headerValueGetter() {
            return that.$i18n.t('common.requester');
          },
          editable: that.editable(true, that.rolePermission),
          cellEditor: 'AgGridAirportRequesterSelectCellEditor',
          cellEditorCustomConfig: {
            fieldId: 'requesterId',
            fieldName: 'requesterName',
          },
          valueGetter(params) {
            return {
              value: params.data.requesterName,
              error: params.data.messageValidate.requesterName.errorExist,
              tooltip: params.data.messageValidate.requesterName.errorMessages.join(' '),
            };
          },
          onCellValueChanged: this.validateRampStatus,
          cellRendererFramework: AgGridValueWithBackgroundCellRenderer,
        },
        {
          field: 'status',
          headerValueGetter() {
            return that.$i18n.t('common.status');
          },
          editable: false,
          valueGetter(params) {
            return params.data.status;
          },
          cellRendererFramework: AgGridFlightStatusCellRenderer,
        },
        {
          field: 'remarks',
          headerValueGetter() {
            return that.$i18n.t('operations.remarksExtended');
          },

          valueGetter(params) {
            return {
              value: params.data.remarks ? params.data.remarks : null,
              error: params.data.messageValidate.remarks.errorExist,
              tooltip: params.data.messageValidate.remarks.errorExist ? params.data.messageValidate.remarks.errorMessages.join(' ') : params.data.remarks,
              warning: params.data.remarksDefault,
            };
          },
          cellEditor: 'AgGridTextCellEditor',
          onCellValueChanged: this.validateRampStatus,
          cellRendererFramework: AgGridValueWithBackgroundCellRenderer,
          editable: that.editable(true, that.rolePermission),
        },
      ];
    },
  },
  created() {
    this.referenceDate = this.connectedFlights.getChildPropertyValue(this.flightBound, ['common', 'scheduledDate']);
    this.isAircraftGround = this.params.column.colDef.colCustomConfig.isAircraftGround ? this.params.column.colDef.colCustomConfig.isAircraftGround(this.connectedFlights) : false;

    this.setColDefs();
    this.$store.dispatch(GET_RAMP_SERVICE);
    this.$store.dispatch(GET_AIRPORT_RAMP_SERVICE);
    this.flightsInfo = this.getFlightsInfo();

    if (this.isAircraftGround) {
      this.$http.get(`aircraft-ground/${this.flightId}/ramp/services`)
        .then((resp) => {
          this.rowData = JSUtils.cast(resp.data, FlightRampService);
          this.validateRampStatus();
        });
    } else {
      const flightIds = this.flightsInfo.map(flightInfo => flightInfo.id);
      this.$http.post('flights/ramp/services', flightIds)
        .then((resp) => {
          this.rowData = JSUtils.cast(resp.data, FlightRampService);
          this.validateRampStatus();
        });
    }
  },
});
</script>
<style lang="scss">
.ramp-status-table {

  .services-table {
    padding: 0;
  }

  .date-time-cell.ag-cell-inline-editing {
    min-width: 250px;
  }
  .status-op {
    width: auto !important;
    max-height: auto !important;
    padding: 0 5px;
    top:10px;
  }
}
</style>
