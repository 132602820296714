export const FisModule = Object.freeze({
  OPERATION: Symbol('OPERATION'),
  PASSAGE_OUTBOUND: Symbol('PASSAGE_OUTBOUND'),
  PASSAGE_INBOUND: Symbol('PASSAGE_INBOUND'),
  RAMP: Symbol('RAMP'),
  SCHEDULING: Symbol('SCHEDULING'),
  SCHEDULING_WEIGHTING: Symbol('SCHEDULING_WEIGHTING'),
  WATER_TOILET: Symbol('WATER_TOILET'),
  LOST_AND_FOUND: Symbol('LOST_AND_FOUND'),
  BILLING: Symbol('BILLING'),
  CONTINGENCY: Symbol('CONTINGENCY'),
  DELAYS: Symbol('DELAYS'),
  TICKETING: Symbol('TICKETING'),
  TICKETING_REPORTS: Symbol('TICKETING_REPORTS'),
  TOWING_ADHOC_TASK_FLIGHT: Symbol('TOWING_ADHOC_TASK_FLIGHT'),
  TOWING_ADHOC_TASK: Symbol('TOWING_ADHOC_TASK'),
});
