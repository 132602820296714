<template>
  <div>
    <v-icon
      color="primary"
      small
      class="mr-2"
      @click.stop="openLink(params.value)"
    >
      open_in_new
    </v-icon>
  </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({

  methods: {
    openLink(value) {
      let url = value;
      if (!value.match(/^https?:\/\//i)) {
        url = `http://${value}`;
      }
      window.open(url, '_blank');
    },
  },
});
</script>

<style scoped>
  .aic_document_btn {
    margin-top: -10px !important;
  }

</style>
