export default class ContingencyPassageState {
  constructor(contingencyId, id, passageMoodChild, passageMoodUpset, passageMoodRiot, passageMoodDisruptive, passageMoodComments, passageMoodOther, passageBehaviourComments) {
    this.id = id;
    this.passageMoodChild = passageMoodChild;
    this.passageMoodUpset = passageMoodUpset;
    this.passageMoodRiot = passageMoodRiot;
    this.passageMoodDisruptive = passageMoodDisruptive;
    this.passageMoodComments = passageMoodComments;
    this.passageMoodOther = passageMoodOther;
    this.passageBehaviourComments = passageBehaviourComments;
    this.contingencyId = contingencyId;
  }

  static createEmptyContingencyPassageState() {
    return new ContingencyPassageState();
  }

  static createContingencyPassageState(contingencyId) {
    return new ContingencyPassageState(contingencyId);
  }

  setChildPropertyValue(property, value) {
    if (!property.constructor === Array) {
      throw Error('pathArray has to be an Array');
    }

    const schema = this; // a moving reference to internal objects within obj

    schema[property] = value;
  }

  getChildPropertyValue(property) {
    if (!property.constructor === Array) {
      throw Error('pathArray has to be an Array');
    }

    const schema = this; // a moving reference to internal objects within obj

    return schema[property];
  }
}
