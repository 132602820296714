<template>
  <div class="special-baggage">
    <img v-if="params.value.avih" height="15" :src="require('@/assets/svg-icons/dog.svg')" >
    <img v-if="params.value.hum" height="15" :src="require('@/assets/svg-icons/skull.svg')">
    <v-icon v-if="params.value.pex">device_thermostat</v-icon>
  </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
});
</script>

<style scoped>
.special-baggage {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
img {
  filter: invert(50%);
}
</style>
