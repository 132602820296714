import { JSUtils } from '../../shared/js/JSUtils';

export default class FlightRampExtendedInformation {
  constructor(
    id,
    file,
    buses,
    rushBaggage,
    deliveryBaggage,
    carouselId,
    carouselCode,
  ) {
    this.id = id;
    this.file = file;
    this.buses = buses;
    this.rushBaggage = rushBaggage;
    this.deliveryBaggage = deliveryBaggage;
    this.carouselId = carouselId;
    this.carouselCode = carouselCode;
  }

  static get createEmptyFlightRampExtendedInformation() {
    return new FlightRampExtendedInformation();
  }

  static createFlightRampExtendedInformationFromData(o) {
    if (!o) {
      return this.createEmptyFlightRampExtendedInformation;
    }

    return JSUtils.cast(o, FlightRampExtendedInformation);
  }
}
