<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
   <StorageTree
     :items="items"
     :title="$t('invoicing.ghs-integration.title-output')"
     :subtitle="$t('invoicing.ghs-integration.subtitle-output')"
     @click-refresh="getOutputFiles"
     :loading="itemsLoading"/>
</template>
<script>
import { GHS_GET_OUTPUT_FILES } from '@/store/modules/invoicing/financialSystemsIntegration';
import StorageTree from './StorageTree.vue';

export default {
  name: 'GHSOutputStorageTree',
  components: { StorageTree },
  computed: {
    items() {
      return this.$store.state.financialSystemsIntegration.outputFiles;
    },
    itemsLoading() {
      return this.$store.state.financialSystemsIntegration.outputFilesLoading;
    },
  },
  methods: {
    getOutputFiles() {
      this.$store.dispatch(GHS_GET_OUTPUT_FILES);
    },
  },
  created() {
    this.getOutputFiles();
  },
};
</script>
