/* eslint no-param-reassign: "off" */
/* eslint no-confusing-arrow: "off" */
/* eslint no-nested-ternary: "off" */

import { HTTP } from '../../../http';
import { REQUEST_ERROR, REQUEST_START, REQUEST_SUCCESS } from '../../store';
import { NOTIFICATION_SHOW } from '../notifications';
import i18n from '../../../plugins/i18n';
import { AirportSLABaggageDeliveryType } from '../../../components/configuration/airportSLABaggageDelivery/model/airportSLABaggageDeliveryType';

// actions names
export const GET_AIRPORT_SLA_BAGGAGE_DELIVERY = 'GET_AIRPORT_SLA_BAGGAGE_DELIVERY';
export const AIRPORT_SLA_BAGGAGE_DELIVERY_DELETE = 'AIRPORT_SLA_BAGGAGE_DELIVERY_DELETE';
export const AIRPORT_SLA_BAGGAGE_DELIVERY_CREATE = 'AIRPORT_SLA_BAGGAGE_DELIVERY_CREATE';
export const AIRPORT_SLA_BAGGAGE_DELIVERY_UPDATE = 'AIRPORT_SLA_BAGGAGE_DELIVERY_UPDATE';
export const AIRPORT_SLA_BAGGAGE_DELIVERY_DISABLE = 'AIRPORT_SLA_BAGGAGE_DELIVERY_DISABLE';
export const AIRPORT_SLA_BAGGAGE_DELIVERY_ENABLE = 'AIRPORT_SLA_BAGGAGE_DELIVERY_ENABLE';


// mutations
export const CHANGE_AIRPORT_SLA_BAGGAGE_DELIVERY_SELECTION = 'CHANGE_AIRPORT_SLA_BAGGAGE_DELIVERY_SELECTION';
export const GET_AIRPORT_SLA_BAGGAGE_DELIVERY_SUCCESS = 'GET_AIRPORT_SLA_BAGGAGE_DELIVERY_SUCCESS';
export const GET_AIRPORT_SLA_BAGGAGE_DELIVERY_REQUESTED = 'GET_AIRPORT_SLA_BAGGAGE_DELIVERY_REQUESTED';
export const UPDATE_AIRPORT_SLA_BAGGAGE_DELIVERY_SUCCESS = 'UPDATE_AIRPORT_SLA_BAGGAGE_DELIVERY_SUCCESS';
export const CHANGED_TENANT_AIRPORT_SLA_BAGGAGE_DELIVERY = 'CHANGED_TENANT_AIRPORT_SLA_BAGGAGE_DELIVERY';

// initial state
const storeState = {
  airportSLABaggageDelivery: [],
  airportSLABaggageDeliveryRequested: false,
  selectedAirportSLABaggageDelivery: [],
  airportSLABaggageDeliveryTypes: [
    AirportSLABaggageDeliveryType.Q1.description,
    AirportSLABaggageDeliveryType.QL.description,
    AirportSLABaggageDeliveryType.QP1.description,
    AirportSLABaggageDeliveryType.QP2.description,
  ],
};

// getters
const storeGetters = {
  getAirportSLABaggageDelivery: state => state.airportSLABaggageDelivery.sort((a, b) => (a.code > b.code) ? 1 : ((b.code > a.code) ? -1 : 0)),
  getActiveAirportSLABaggageDelivery: state => state.airportSLABaggageDelivery.filter(elem => !elem.deleted).sort((a, b) => (a.code > b.code) ? 1 : ((b.code > a.code) ? -1 : 0)),
  getAirportSLABaggageDeliverySelected: state => state.selectedAirportSLABaggageDelivery,
  hasSelectedAirportSLABaggageDelivery: state => state.selectedAirportSLABaggageDelivery.length > 0,
  getAirportSLABaggageDeliveryTypes: state => state.airportSLABaggageDeliveryTypes,
};

// actions
const storeActions = {
  [GET_AIRPORT_SLA_BAGGAGE_DELIVERY]: ({ commit, state }) => {
    if (state.airportSLABaggageDeliveryRequested) {
      return;
    }
    commit(REQUEST_START);
    commit(GET_AIRPORT_SLA_BAGGAGE_DELIVERY_REQUESTED);
    HTTP.get('airport-sla-baggage-delivery')
      .then((resp) => {
        commit(REQUEST_SUCCESS);
        commit(GET_AIRPORT_SLA_BAGGAGE_DELIVERY_SUCCESS, resp.data);
      })
      .catch((err) => {
        commit(REQUEST_ERROR, err);
      });
  },

  [AIRPORT_SLA_BAGGAGE_DELIVERY_CREATE]: ({ commit, dispatch }, slaBaggageDelivery) => {
    commit(REQUEST_START);
    return HTTP.post('airport-sla-baggage-delivery', slaBaggageDelivery)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(UPDATE_AIRPORT_SLA_BAGGAGE_DELIVERY_SUCCESS);
        dispatch(GET_AIRPORT_SLA_BAGGAGE_DELIVERY);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.airportSLABaggageDeliveryCreate'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },

  [AIRPORT_SLA_BAGGAGE_DELIVERY_UPDATE]: ({ commit, dispatch }, slaBaggageDelivery) => {
    commit(REQUEST_START);
    return HTTP.put('airport-sla-baggage-delivery', slaBaggageDelivery)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(UPDATE_AIRPORT_SLA_BAGGAGE_DELIVERY_SUCCESS);
        dispatch(GET_AIRPORT_SLA_BAGGAGE_DELIVERY);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.airportSLABaggageDeliveryUpdate'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
  [AIRPORT_SLA_BAGGAGE_DELIVERY_DELETE]: ({ commit, dispatch }, id) => {
    commit(REQUEST_START);
    return HTTP.delete(`airport-sla-baggage-delivery/${id}`)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(UPDATE_AIRPORT_SLA_BAGGAGE_DELIVERY_SUCCESS);
        dispatch(GET_AIRPORT_SLA_BAGGAGE_DELIVERY);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.airportSLABaggageDeliveryDelete'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
  [AIRPORT_SLA_BAGGAGE_DELIVERY_DISABLE]: ({ commit, dispatch }, id) => {
    commit(REQUEST_START);
    return HTTP.patch(`/airport-sla-baggage-delivery/disable/${id}`)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(UPDATE_AIRPORT_SLA_BAGGAGE_DELIVERY_SUCCESS);
        dispatch(GET_AIRPORT_SLA_BAGGAGE_DELIVERY);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.airportSLABaggageDeliveryDisable'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
  [AIRPORT_SLA_BAGGAGE_DELIVERY_ENABLE]: ({ commit, dispatch }, id) => {
    commit(REQUEST_START);
    return HTTP.patch(`/airport-sla-baggage-delivery/enable/${id}`)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(UPDATE_AIRPORT_SLA_BAGGAGE_DELIVERY_SUCCESS);
        dispatch(GET_AIRPORT_SLA_BAGGAGE_DELIVERY);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.airportSLABaggageDeliveryEnable'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
};

// mutations
const storeMutations = {
  [GET_AIRPORT_SLA_BAGGAGE_DELIVERY_SUCCESS]: (state, airportSLABaggageDelivery) => {
    state.airportSLABaggageDelivery = airportSLABaggageDelivery;
  },
  [GET_AIRPORT_SLA_BAGGAGE_DELIVERY_REQUESTED]: (state) => {
    state.airportSLABaggageDeliveryRequested = true;
  },
  [UPDATE_AIRPORT_SLA_BAGGAGE_DELIVERY_SUCCESS]: (state) => {
    state.airportSLABaggageDeliveryRequested = false;
  },
  [CHANGE_AIRPORT_SLA_BAGGAGE_DELIVERY_SELECTION]: (state, selection) => {
    state.selectedAirportSLABaggageDelivery = selection;
  },
  [CHANGED_TENANT_AIRPORT_SLA_BAGGAGE_DELIVERY]: (state) => {
    state.airportSLABaggageDeliveryRequested = false;
  },
};

export default {
  state: storeState,
  getters: storeGetters,
  actions: storeActions,
  mutations: storeMutations,
};
