<template>
  <div>
    <entity-configuration-list
      :entity-name="this.$i18n.t('configuration.defaultCheckInTime')"
      entity-icon="video_label"
      :framework-components="frameworkComponents"
      :column-defs="columnDefs"
      :items="airportDefaultCheckInTime"
      @confirm-edit-item="editConfirm"
      @confirm-delete-item="deleteConfirm"
      @confirm-enable-disable-item="enableDisableConfirm"
    >
      <template v-slot:modal-content="{ itemEdit }">
        <airport-default-check-in-time-edit-card-content :item="itemEdit" />
      </template>
    </entity-configuration-list>
  </div>
</template>
<script>
import {
  AIRPORT_DEFAULT_CHECK_IN_TIME_CREATE,
  AIRPORT_DEFAULT_CHECK_IN_TIME_DELETE,
  AIRPORT_DEFAULT_CHECK_IN_TIME_UPDATE,
  GET_AIRPORT_DEFAULT_CHECK_IN_TIME,
  AIRPORT_DEFAULT_CHECK_IN_TIME_ENABLE,
  AIRPORT_DEFAULT_CHECK_IN_TIME_DISABLE,
} from '@/store/modules/airportDefaultCheckInTime/airportDefaultCheckInTime';
import EntityConfigurationList from '@/components/configuration/common/EntityConfigurationList.vue';
import AirportDefaultCheckInTimeEditCardContent from './AirportDefaultCheckInTimeEditCardContent.vue';
import AgGridRowActionButtonsCellRenderer from '../../ag-grid/cell-renderers/AgGridRowActionButtonsCellRenderer.vue';
import AgGridIconCellRenderer from '../../ag-grid/cell-renderers/AgGridIconCellRenderer.vue';
import { AirportDefaultCheckInTimeTypes } from './AirportDefaultCheckInTimeTypes';

export default {
  name: 'AirportDefaultCheckInTimeList',
  components: {
    EntityConfigurationList,
    AirportDefaultCheckInTimeEditCardContent,
  },
  data() {
    const that = this;
    return {
      frameworkComponents: {
        AgGridRowActionButtonsCellRenderer,
        AgGridIconCellRenderer,
      },
      columnDefs: [
        {
          field: 'airlineCode',
          headerValueGetter() {
            return that.$i18n.t('common.airline');
          },
          sort: 'asc',
        },
        {
          field: 'startTime',
          headerValueGetter() {
            return that.$i18n.t('configuration.startCheckIn');
          },
        },
        {
          field: 'endTime',
          headerValueGetter() {
            return that.$i18n.t('configuration.endCheckIn');
          },
        },
        {
          field: 'timeType',
          headerValueGetter() {
            return that.$i18n.t('configuration.flightTimeReference');
          },
          valueGetter(params) {
            if (params && params.data && params.data.timeType === AirportDefaultCheckInTimeTypes.ST) {
              return that.$i18n.t('configuration.scheduledTime');
            } if (params && params.data && params.data.timeType === AirportDefaultCheckInTimeTypes.ET) {
              return that.$i18n.t('configuration.estimatedTime');
            }
            return null;
          },
        },
        {
          headerValueGetter() {
            return that.$i18n.t('configuration.disabled');
          },
          field: 'deleted',
          cellRendererFramework: AgGridIconCellRenderer,
          cellRendererParams: {
            icon: 'mdi-cancel',
            tooltip: params => (params.data.deleted ? this.$i18n.t('configuration.disabled') : null),
          },
        },
      ],
    };
  },
  computed: {
    airportDefaultCheckInTime() {
      return this.$store.getters.getAirportDefaultCheckInTime;
    },
  },
  methods: {
    getAirportDefaultCheckInTime() {
      this.$store.dispatch(GET_AIRPORT_DEFAULT_CHECK_IN_TIME);
    },
    deleteConfirm(item) {
      this.$store.dispatch(AIRPORT_DEFAULT_CHECK_IN_TIME_DELETE, item.id);
    },
    editConfirm(item) {
      this.$store.dispatch(
        item.id
          ? AIRPORT_DEFAULT_CHECK_IN_TIME_UPDATE
          : AIRPORT_DEFAULT_CHECK_IN_TIME_CREATE,
        item,
      );
    },
    enableDisableConfirm(item) {
      this.$store.dispatch(
        item.deleted
          ? AIRPORT_DEFAULT_CHECK_IN_TIME_ENABLE
          : AIRPORT_DEFAULT_CHECK_IN_TIME_DISABLE,
        item.id,
      );
    },
  },
  created() {
    this.getAirportDefaultCheckInTime();
  },
};
</script>
