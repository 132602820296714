<template>
  <v-card :dark="this.$vuetify.dark" :color="this.$vuetify.dark ? 'bggrey_dark' : 'bggrey'">
    <v-container
      fluid
      grid-list-lg
    >
      <v-layout row wrap>
        <v-flex xs12>
          <AvinetRead/>
        </v-flex>
      </v-layout>
    </v-container>
  </v-card>
</template>
<script>
import AvinetRead from './AvinetRead.vue';

export default {
  name: 'AdminAvinet',
  components: {
    AvinetRead,
  },
};
</script>
