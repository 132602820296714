<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>

    <ag-grid-dialog-template-editor :is-dialog-open="isDialogOpen" @save="save" @cancel="cancel" :saveDisabled="validData">

      <template v-slot:header>
        Edit Rushes for Flight {{flightNumber}}
      </template>

      <template v-slot:default>
        <v-card>
          <v-card-text class="rush-baggage-table">
            <ag-grid-vue
              class="ag-theme-material"
              :columnDefs="columnDefs"
              :defaultColDef="defaultColDef"
              @grid-ready="setAgGridApi"
              :gridOptions="gridOptions"
              rowSelection="multiple"
              @selection-changed="onSelectionChanged"
              :frameworkComponents="frameworkComponents"
              :rowData="rowData">
            </ag-grid-vue>
          </v-card-text>
        </v-card>
      </template>
      <template v-slot:footer>
        <v-btn color="secondary" flat @click="addRushBaggage()"><v-icon>add</v-icon></v-btn>
        <v-btn v-show="rushBaggageSelected.length > 0" color="error" flat @click="removeRushBaggage()"><v-icon>delete</v-icon></v-btn>
        <v-btn color="primary" @click="print()"><v-icon>print</v-icon></v-btn>
      </template>
    </ag-grid-dialog-template-editor>
  </div>
</template>

<script>
import Vue from 'vue';
import { AgGridVue } from 'ag-grid-vue';
import AgGridDialogParentCellEditor from '../../mixins/AgGridDialogParentCellEditor.vue';
import AgGridDialogTemplateEditor from '../../templates/AgGridDialogTemplateEditor.vue';
import { JSUtils } from '../../../../shared/js/JSUtils';
import AgGridOptionsConfigurable from '../../../mixins/AgGridOptionsConfigurable.vue';
import { cellClassAgGridRules } from '../../../mixins/cellClassAgGridRules';
import AgGridDateTimeCellRenderer from '../../../cell-renderers/AgGridDateTimeCellRenderer.vue';
import CrudOperationsDTO from '../../../../shared/model/crudOperationsDTO';
import RushBaggage from '../../../../lostAndFound/rushBaggage/model/rushBaggage';
import { BASE_URL } from '../../../../../http';

export default Vue.extend({
  mixins: [AgGridDialogParentCellEditor, AgGridOptionsConfigurable],
  components: {
    AgGridDialogTemplateEditor,
    AgGridVue,
  },
  data() {
    const that = this;
    return {
      frameworkComponents: {
        AgGridDateTimeCellRenderer,
      },
      defaultColDef: {
        cellClassRules: cellClassAgGridRules,
      },
      gridOptions: {
        getRowClass: this.getRowClass,
      },
      rowData: [],
      validData: true,
      columnDefs: [
        {
          checkboxSelection: true,
          width: 36,
          editable: true,
        },
        {
          field: 'flightDate',
          headerName: 'Date',
          editable: false,
          valueFormatter(params) {
            return that.$moment(params.data.value).format('DD/MM/YYYY');
          },
        },
        {
          field: 'flightTripNumber',
          headerName: 'Flight',
          editable: false,
          valueGetter(params) {
            return params.data.flightNo();
          },
        },
        {
          field: 'airport1Code',
          headerName: 'To',
          editable: false,
        },
        {
          field: 'passengerName',
          headerName: 'Passenger\'s Name / File Reference',
          editable: this.isCellEditable,
          onCellValueChanged: this.validateRushBaggage,
        },
        {
          field: 'rushTag',
          headerName: 'Rush Tag',
          editable: this.isCellEditable,
          onCellValueChanged: this.validateRushBaggage,
        },
        {
          field: 'originalTag',
          headerName: 'Original Tag',
          editable: this.isCellEditable,
          onCellValueChanged: this.validateRushBaggage,
        },
        {
          field: 'agent',
          headerName: 'Agent',
          editable: this.isCellEditable,
          onCellValueChanged: this.validateRushBaggage,
        },
        {
          field: 'timeCreated',
          headerName: 'Time created',
          editable: false,
          valueGetter(params) {
            return {
              value: params.data.timeCreated,
            };
          },
          cellRendererFramework: AgGridDateTimeCellRenderer,
        },
      ],
      rushBaggageSelected: [],
      crudOperationsDto: new CrudOperationsDTO(),
    };
  },
  computed: {
    objectRushBaggage() {
      return {
        flightId: this.flightId,
        flightTripNumber: this.connectedFlights.getChildPropertyValue(this.flightBound, ['flight', 'tripNumber']),
        flightDate: this.connectedFlights.getChildPropertyValue(this.flightBound, ['flight', 'flightDate']),
        scheduledTime: this.connectedFlights.getChildPropertyValue(this.flightBound, ['flight', 'scheduledTime']),
        airportId: this.connectedFlights.getChildPropertyValue(this.flightBound, ['flight', 'airport1Id']),
        airportCode: this.connectedFlights.getChildPropertyValue(this.flightBound, ['flight', 'airport1Code']),
        airlineId: this.connectedFlights.getChildPropertyValue(this.flightBound, ['flight', 'airlineId']),
        airlineCode: this.connectedFlights.getChildPropertyValue(this.flightBound, ['flight', 'airlineCode']),
      };
    },
  },
  methods: {
    getValue() {
      if (this.isSaving) {
        this.crudOperationsDto.updates.push(...this.rowData);
        this.requestInit();
        this.$http.put(`flights/${this.flightId}/rush-baggage`, this.crudOperationsDto)
          .then(resp => this.dialogRequestSuccessCallback(this, resp))
          .catch(error => this.requestErrorCallback(this, error));
      }

      return this.params.value;
    },
    onColumnResized(event) {
      if (event.finished) {
        this.gridApi.resetRowHeights();
      }
    },
    isCellEditable() {
      return this.hasPermissions(this.rolePermission);
    },
    addRushBaggage() {
      this.rowData.push(RushBaggage.createRushBaggage(this.objectRushBaggage));
      this.validateRushBaggage();
    },
    removeRushBaggage() {
      if (this.rushBaggageSelected.length > 0) {
        this.crudOperationsDto.deletes.push(...this.rushBaggageSelected.filter(rushBaggage => !!rushBaggage.id));

        this.rushBaggageSelected.forEach((msg) => {
          const index = this.rowData.indexOf(msg);
          this.rowData.splice(index, 1);
        });
        this.rushBaggageSelected = [];
        this.validateRushBaggage();
      }
    },
    onSelectionChanged(event) {
      if (event.api.getSelectedNodes().length > 0) {
        this.rushBaggageSelected = event.api.getSelectedNodes().map(n => n.data);
      } else {
        this.rushBaggageSelected = [];
      }
    },
    validateRushBaggage() {
      this.validData = false;
      this.rowData.forEach((data) => {
        if (data.passengerName == null || data.passengerName === '') {
          this.validData = true;
        }
        if (data.rushTag == null || data.rushTag === '') {
          this.validData = true;
        }
        if (data.originalTag == null || data.originalTag === '') {
          this.validData = true;
        }
        if (data.agent == null || data.agent === '') {
          this.validData = true;
        }
      });
    },
    print() {
      window.open(`${BASE_URL}flights/${this.flightId}/rush-baggage/print`, '_blank');
    },
  },
  created() {
    this.$http.get(`flights/${this.flightId}/rush-baggage`)
      .then((resp) => {
        this.rowData = JSUtils.cast(resp.data, RushBaggage);
      });
  },
});
</script>
<style lang="scss">
.loads-table {
  padding: 0;
}

.radio-unit-label {
  padding-top: 1.5vh;
  font-weight: bold;
  margin-right: 1vw;
}
</style>
