<template>
  <v-layout row wrap align-center>
    <v-flex xs12 md6 offset-md3 class="text-xs-center">
      <v-container fluid>
        <v-layout row column>
          <v-flex>
            <tenant-selector @input-tenant="selectItem" />
          </v-flex>
        </v-layout>
      </v-container>
    </v-flex>
    <v-flex xs12 class="text-xs-center">
      <img :src="imgSrc" alt="map" />
    </v-flex>
    <v-flex xs12 xl8 offset-xl2 class="text-xs-center">
      <tenant-selector-list />
    </v-flex>
  </v-layout>
</template>
<script>

import TenantSelector from '@/components/tenant/TenantSelector.vue';
import TenantSelectorList from '@/components/tenant/TenantSelectorList.vue';
import { TENANT_CURRENT_SET } from '../store/modules/tenant';

export default {
  components: {
    TenantSelector,
    TenantSelectorList,
  },
  data: () => ({
    imgSrc: '/img/tenant-map.svg',
  }),
  methods: {
    selectItem(item) {
      this.$store.dispatch(TENANT_CURRENT_SET, item).then(() => {
        this.$router.push('/home');
      });
    },
  },
};
</script>
<style scoped>
img {
  max-height: 450px;
}
</style>
