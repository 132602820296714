export default class ContingencyBasicService {
  constructor(contingencyId, id, quantity, provider, billingDate, bill, cost, vat, totalPrice, type, currency, duration, durationFormatTime, purchaseOrder) {
    this.id = id;
    this.quantity = quantity;
    this.provider = provider;
    this.billingDate = billingDate;
    this.bill = bill;
    this.cost = cost;
    this.vat = vat;
    this.totalPrice = totalPrice;
    this.type = type;
    this.currency = currency;
    this.duration = duration;
    this.durationFormatTime = durationFormatTime;
    this.purchaseOrder = purchaseOrder;
    this.contingencyId = contingencyId;
  }

  static createEmptyContingencyBasicService() {
    return new ContingencyBasicService();
  }

  static createContingencyBasicService(contingencyId) {
    return new ContingencyBasicService(contingencyId);
  }

  setChildPropertyValue(property, value) {
    if (!property.constructor === Array) {
      throw Error('pathArray has to be an Array');
    }

    const schema = this; // a moving reference to internal objects within obj

    schema[property] = value;
  }

  getChildPropertyValue(property) {
    if (!property.constructor === Array) {
      throw Error('pathArray has to be an Array');
    }

    const schema = this; // a moving reference to internal objects within obj

    return schema[property];
  }
}
