<template>
  <passage-inbound-flights-list-table :items="items" :isFilterDisplayed="this.isFilterDisplayed"></passage-inbound-flights-list-table>
</template>
<script>
import PassageInboundFlightsListTable from './PassageInboundFlightsListTable.vue';
import { PASSAGE_INBOUND_FILTER_RESET, PASSAGE_INBOUND_FIND_FLIGHTS } from '../../../store/modules/passageInbound';

export default {
  name: 'passage-inbound-flights-list-container',
  components: {
    PassageInboundFlightsListTable,
  },
  props: {
    isFilterDisplayed: { type: Boolean, required: false, default: false },
  },
  mounted() {
    this.$store.commit(PASSAGE_INBOUND_FILTER_RESET);
    this.$store.dispatch(PASSAGE_INBOUND_FIND_FLIGHTS);
  },
  computed: {
    items() {
      return this.$store.state.passageInbound.connectedFlightsPassage;
    },
  },
  methods: {
  },
};
</script>
<style lang="scss">


</style>
