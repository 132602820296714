import { JSUtils } from '../../shared/js/JSUtils';

export default class FlightDelayInformation {
  constructor(
    checkInEndTime,
    flightDelayCode1,
    flightDelayCode2,
    flightDelayCode3,
    flightDelayAmount1,
    flightDelayAmount2,
    flightDelayAmount3,
    flightDelayRemark1,
    flightDelayRemark2,
    flightDelayRemark3,
    count,
  ) {
    this.checkInEndTime = checkInEndTime;
    this.flightDelayCode1 = flightDelayCode1;
    this.flightDelayCode2 = flightDelayCode2;
    this.flightDelayCode3 = flightDelayCode3;
    this.flightDelayAmount1 = flightDelayAmount1;
    this.flightDelayAmount2 = flightDelayAmount2;
    this.flightDelayAmount3 = flightDelayAmount3;
    this.flightDelayRemark1 = flightDelayRemark1;
    this.flightDelayRemark2 = flightDelayRemark2;
    this.flightDelayRemark3 = flightDelayRemark3;
    this.count = count;
  }

  static get createEmptyFlightDelayInformation() {
    return new FlightDelayInformation();
  }

  static createFlightDelayInformationFromData(o) {
    if (!o) {
      return this.createEmptyFlightDelayInformation;
    }

    return JSUtils.cast(o, FlightDelayInformation);
  }
}
