<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <entity-configuration-list
      :entity-name="this.$i18n.t('configuration.airportRampService')"
      entity-icon="rv_hookup"
      :framework-components="frameworkComponents" :column-defs="columnDefs" :items="rampServices"
      @confirm-edit-item="editConfirm"
      @confirm-delete-item="deleteConfirm"
      @confirm-enable-disable-item="enableDisableConfirm"
    >
      <template v-slot:modal-content="{ itemEdit }">
        <airport-ramp-service-edit-card-content :item="itemEdit" />
      </template>
    </entity-configuration-list>
  </div>
</template>
<script>
import EntityConfigurationList from '../common/EntityConfigurationList.vue';
import AirportRampServiceEditCardContent from './AirportRampServiceEditCardContent.vue';
import AgGridIconCellRenderer from '../../ag-grid/cell-renderers/AgGridIconCellRenderer.vue';

import {
  AIRPORT_RAMP_SERVICE_CREATE,
  AIRPORT_RAMP_SERVICE_DELETE,
  AIRPORT_RAMP_SERVICE_UPDATE,
  GET_AIRPORT_RAMP_SERVICE,
  AIRPORT_RAMP_SERVICE_DISABLE,
  AIRPORT_RAMP_SERVICE_ENABLE,
} from '../../../store/modules/rampServices/airportRampServicesCatalog';


export default {
  name: 'airport-ramp-services-list',
  components: {
    EntityConfigurationList,
    AirportRampServiceEditCardContent,
    AgGridIconCellRenderer,
  },
  data() {
    const that = this;
    return {
      frameworkComponents: {
      },
      columnDefs: [
        {
          field: 'serviceName',
          headerValueGetter() {
            return that.$i18n.t('configuration.name');
          },
        },
        {
          field: 'serviceDescription',
          headerValueGetter() {
            return that.$i18n.t('configuration.description');
          },
        },
        {
          headerValueGetter() {
            return that.$i18n.t('configuration.rampServiceDisabled');
          },
          field: 'serviceDeleted',
          cellRendererFramework: AgGridIconCellRenderer,
          cellRendererParams: {
            icon: 'mdi-cancel',
            tooltip: params => (params.data.serviceDeleted ? this.$i18n.t('configuration.disabled') : null),
          },
        },
        {
          headerValueGetter() {
            return that.$i18n.t('configuration.disabled');
          },
          field: 'deleted',
          cellRendererFramework: AgGridIconCellRenderer,
          cellRendererParams: {
            icon: 'mdi-cancel',
            tooltip: params => (params.data.deleted ? this.$i18n.t('configuration.disabled') : null),
          },
        },
      ],
    };
  },
  computed: {
    rampServices() {
      return this.$store.getters.getAirportRampServices;
    },
  },
  methods: {
    getRampServices() {
      this.$store.dispatch(GET_AIRPORT_RAMP_SERVICE);
    },
    deleteConfirm(item) {
      this.$store.dispatch(AIRPORT_RAMP_SERVICE_DELETE, item.id);
    },
    editConfirm(item) {
      this.$store.dispatch(item.id ? AIRPORT_RAMP_SERVICE_UPDATE : AIRPORT_RAMP_SERVICE_CREATE, item);
    },
    enableDisableConfirm(item) {
      this.$store.dispatch(item.deleted ? AIRPORT_RAMP_SERVICE_ENABLE : AIRPORT_RAMP_SERVICE_DISABLE, item.id);
    },
  },
  created() {
    this.getRampServices();
  },
};
</script>
