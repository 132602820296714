<template>
  <div>
    <lost-and-found-flights-list-filter
      @changeShowFilter="changeShowFilter"
    ></lost-and-found-flights-list-filter>
    <lost-and-found-flights-list-container
      :isFilterDisplayed="filterDisplayed"
    ></lost-and-found-flights-list-container>
    <lost-and-found-flights-list-options
      @clickFlight="newFlight"
      @click-archive="archiveFlights"
      @click-unarchive="unarchiveFlights"
    ></lost-and-found-flights-list-options>
  </div>
</template>
<script>
import LostAndFoundFlightsListFilter from '../components/lostAndFound/list/LostAndFoundFlightsListFilter.vue';
import LostAndFoundFlightsListContainer from '../components/lostAndFound/list/LostAndFoundFlightsListContainer.vue';
import LostAndFoundFlightsListOptions from '../components/lostAndFound/list/LostAndFoundFlightsListOptions.vue';
import {
  LOST_AND_FOUND_ARCHIVE,
  LOST_AND_FOUND_UNARCHIVE,
} from '../store/modules/lostAndFound/lostAndFound';
import ConnectedFlights from '../components/shared/model/connectedFlights';

export default {
  components: {
    LostAndFoundFlightsListContainer,
    LostAndFoundFlightsListFilter,
    LostAndFoundFlightsListOptions,
  },
  data() {
    return {
      filterDisplayed: true,
    };
  },
  methods: {
    archiveFlights() {
      this.$store.dispatch(LOST_AND_FOUND_ARCHIVE);
    },
    unarchiveFlights() {
      this.$store.dispatch(LOST_AND_FOUND_UNARCHIVE);
    },
    changeShowFilter(dispayled) {
      this.filterDisplayed = dispayled;
    },
    newFlight() {
      this.editedSchedulingFlight = ConnectedFlights.createEmtpyConnectedFlights();
      this.dialog = true;
    },
  },
};
</script>
