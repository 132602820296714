<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <ag-grid-dialog-template-editor :is-dialog-open="isDialogOpen" @save="save" @cancel="cancel">
      <template v-slot:header>
        {{ $t("operations.operationAgentModalTitle") }} {{ flightNumber }}
      </template>
      <template v-slot:default>
        <v-container fluid grid-list-md>
          <v-layout row wrap>
            <v-flex d-flex xs12 sm12 md12>
              <v-autocomplete v-if="hasPermissions(rolePermission)" v-model="itemSelected" :items="users"
                item-text="firstName" item-value="email" :label="$t('operations.searchForUserAgent')" persistent-hint
                :loading="isLoading" :search-input.sync="search" :filter="customFilter"
               @input="changeOperationAgent">
                <template v-slot:selection="data">
                  <v-chip :selected="data.selected" close class="chip--select-multi" @input="remove(data.item)">
                    {{ data.item.firstName }} {{ data.item.lastName }} ({{ data.item.email }})
                  </v-chip>
                </template>
                <template slot="item" slot-scope="{ item, tile }">
                  <v-list-tile-content>
                    <v-list-tile-title>{{ item.firstName }} {{ item.lastName }} ({{ item.email }})</v-list-tile-title>
                  </v-list-tile-content>
                </template>
              </v-autocomplete>
            </v-flex>
          </v-layout>
        </v-container>
      </template>
    </ag-grid-dialog-template-editor>
  </div>
</template>

<script>
import Vue from 'vue';
import AgGridDialogParentCellEditor from '../../mixins/AgGridDialogParentCellEditor.vue';
import AgGridDialogTemplateEditor from '../../templates/AgGridDialogTemplateEditor.vue';
import { GET_USERS_FILTERED } from '../../../../../store/modules/user/userCatalog';
import { JSUtils } from '../../../../shared/js/JSUtils';

export default Vue.extend({
  mixins: [AgGridDialogParentCellEditor],
  components: {
    AgGridDialogTemplateEditor,
  },
  computed: {
    rolePermission() {
      return `${this.$route.meta.role}_WR`;
    },
    users() {
      return this.$store.getters.getUsersFiltered;
    },
  },
  data() {
    return {
      search: '',
      operationAgent: null,
      isLoading: false,
      itemSelected: null,
      oldValue: null,
    };
  },
  methods: {
    getUsersFiltered() {
      // if user has loaded the page before, we don't need to call the service again
      if (this.users.length > 0) {
        this.itemSelected = this.users.find(user => user.email === this.params.value.value);
        this.oldValue = this.params.value.value;
        return;
      }
      // load the users and set the selected user from the params
      this.$store.dispatch(GET_USERS_FILTERED);
      this.$store.watch(
        (_state, getters) => getters.getUsersFiltered,
        (loaded) => {
          if (loaded) {
            this.itemSelected = this.users.find(user => user.email === this.params.value.value);
            this.oldValue = this.params.value.value;
          }
        },
      );
    },
    remove() {
      this.itemSelected = null;
      this.operationAgent = null;
    },
    customFilter(item, queryText) {
      const fullName = `${item.firstName} ${item.lastName}`;
      return fullName.toLowerCase().includes(queryText.toLowerCase());
    },
    getValue() {
      if (this.isSaving) {
        const that = this;
        const connectedFlightPropertyName = this.getConnectedFlightsPropertyName();
        this.saveFlightChangesFromValue((s) => {
          s.setChildPropertyValueByBound(that.params.column.colDef.colCustomConfig.flightBound, connectedFlightPropertyName, that.operationAgent);
        }, JSUtils.decamelizeString(connectedFlightPropertyName, ' '), this.oldValue, this.operationAgent);
      }
      return this.operationAgent;
    },
    changeOperationAgent(newValue) {
      this.operationAgent = newValue;
    },
  },
  created() {
    this.getUsersFiltered();
  },
});
</script>
