<!-- eslint-disable no-tabs -->
<template>
  <v-flex>
    <v-toolbar class="plane-container">
      <div>
        <v-layout row wrap class="flex justify-center">
          <div class="plane-element">
            <v-icon class="plane">flight</v-icon>
            <span class="label">{{ getPlaneInfo() ? getPlaneInfo() : '-' }}</span>
          </div>
          <div class="plane-element">
            <flight-status :status="flightMobile.status" :tooltip="flightMobile.status" :resizable="false" />
            <span  class="label">
              {{ flightMobile.scheduledTime ? formattedTime(flightMobile.scheduledTime) : '-' }}
            </span>
          </div>
        </v-layout>
        <v-layout row wrap class="flex justify-center">
          <div class="plane-element">
            <v-icon>airline_seat_recline_extra</v-icon>
            <span class="label">{{ getTotalPax() ? getTotalPax() : '-' }}</span>
          </div>
          <div class="plane-element">
            <v-icon>work</v-icon>
            <span class="label">{{ flightMobile.totalBags ? flightMobile.totalBags : '-' }}</span>
          </div>
          <div class="plane-element">
            <v-icon>local_shipping</v-icon>
            <span class="label">{{ flightMobile.totalCargo ? flightMobile.totalCargo : '-' }}</span>
          </div>
        </v-layout>
      </div>
    </v-toolbar>
  </v-flex>
</template>

<script>
import FlightStatus from '../flights/FlightStatus.vue';
import { TIME_TIMEZONE_CHANGED } from '../../store/modules/time';
import StoreSubscribable from '../mixins/StoreSubscribable.vue';

export default {
  name: 'info-mobile-plane-header',
  props: {
    flightMobile: {
      required: true,
    },
  },
  mixins: [StoreSubscribable],
  components: {
    FlightStatus,
  },
  data() {
    return {
      localTimezone: this.$store.state.time.timezone,
    };
  },
  methods: {
    getTotalPax() {
      // when handling type is P, the total of passengers must be (P)
      if (this.flightMobile.handlingTypeCode === 'P') {
        return '(P)';
      }
      if (!this.flightMobile.totalPaxInf) {
        return this.flightMobile.totalPaxAdult;
      }
      return `${this.flightMobile.totalPaxAdult}+${this.flightMobile.totalPaxInf}`;
    },
    getPlaneInfo() {
      if (this.flightMobile.aircraftTypeIataCode) {
        if (this.flightMobile.aircraftRegisterNumber) {
          return `${this.flightMobile.aircraftTypeIataCode} - ${this.flightMobile.aircraftRegisterNumber}`;
        }
        return this.flightMobile.aircraftTypeIataCode;
      }
      if (this.flightMobile.aircraftRegisterNumber) {
        return this.flightMobile.aircraftRegisterNumber;
      }
      return null;
    },
    formattedTime(time) {
      let formattedTime = '';
      if (time) {
        formattedTime = this.$moment(time).tz(this.localTimezone).format('HH:mm');
      }
      return formattedTime;
    },
  },
  created() {
    this.subscribeMutation(TIME_TIMEZONE_CHANGED, () => {
      this.localTimezone = this.$store.state.time.timezone;
    });
  },
};
</script>
