/* eslint no-param-reassign: "off" */
import { HTTP } from '../../http';
import { REQUEST_ERROR, REQUEST_START, REQUEST_SUCCESS } from '../store';
import { NOTIFICATION_SHOW } from './notifications';
import i18n from '../../plugins/i18n';
import { createFlightPassageBoardingEventFromData } from '../../components/shared/model/flightPassageBoardingEvent';

// action
export const PASSAGE_BOARDING_FIND_FLIGHT = 'PASSAGE_BOARDING_FIND_FLIGHT';
export const PASSAGE_BOARDING_FLIGHT_UPDATE = 'PASSAGE_BOARDING_FLIGHT_UPDATE';
export const PASSAGE_BOARDING_FLIGHT_DO_EXTERNAL_CHANGE = 'PASSAGE_BOARDING_FLIGHT_DO_EXTERNAL_CHANGE';

// mutations
export const PASSAGE_BOARDING_FIND_FLIGHT_SUCCESS = 'PASSAGE_BOARDING_FIND_FLIGHT_SUCCESS';
export const PASSAGE_BOARDING_FLIGHT_UPDATE_SUCCESS = 'PASSAGE_BOARDING_FLIGHT_UPDATE_SUCCESS';
export const PASSAGE_BOARDING_FLIGHT_ON_EXTERNAL_CHANGE = 'PASSAGE_BOARDING_FLIGHT_ON_EXTERNAL_CHANGE';

// initial state
const storeState = {
  flightPassageBoarding: null,
};

// getters
const storeGetters = {
  getFlightPassageBoarding: state => state.flightPassageBoarding,
};

// actions
const storeActions = {
  [PASSAGE_BOARDING_FIND_FLIGHT]: ({ commit }, idFlight) => {
    commit(REQUEST_START);
    return HTTP.get(`passage/boarding/${idFlight}`)
      .then((resp) => {
        commit(REQUEST_SUCCESS);
        commit(PASSAGE_BOARDING_FIND_FLIGHT_SUCCESS, resp.data);
      })
      .catch((err) => {
        commit(REQUEST_ERROR, err);
      });
  },

  [PASSAGE_BOARDING_FLIGHT_UPDATE]: ({ commit }, passageBoarding) => {
    commit(REQUEST_START);
    return HTTP.put('passage/boarding', passageBoarding)
      .then(() => {
        commit(REQUEST_SUCCESS);
        // commit(PASSAGE_BOARDING_FLIGHT_UPDATE_SUCCESS, ConnectedFlightCompositionOperationFactory.createFlightCompositionFromData(resp.data));
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.passageBoardingUpdate'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
  [PASSAGE_BOARDING_FLIGHT_DO_EXTERNAL_CHANGE]: ({ commit }, change) => {
    commit(PASSAGE_BOARDING_FLIGHT_ON_EXTERNAL_CHANGE, createFlightPassageBoardingEventFromData(change));
    commit(PASSAGE_BOARDING_FIND_FLIGHT_SUCCESS, change.payload);
  },

};

// mutations
const storeMutations = {
  [PASSAGE_BOARDING_FIND_FLIGHT_SUCCESS]: (state, flightPassageBoarding) => {
    state.flightPassageBoarding = flightPassageBoarding;
  },
  [PASSAGE_BOARDING_FLIGHT_UPDATE_SUCCESS]: (state, flightPassageBoarding) => {
    state.flightPassageBoarding = flightPassageBoarding;
  },
  [PASSAGE_BOARDING_FLIGHT_ON_EXTERNAL_CHANGE]: (state, change) => {
    console.log(`mutations external change ${change}`); // eslint-disable-line no-console
  },
};

export default {
  state: storeState,
  getters: storeGetters,
  actions: storeActions,
  mutations: storeMutations,
};
