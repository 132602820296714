<template>
  <v-card-text>
    <v-container fluid style="margin-bottom: 0; padding-bottom: 0">
      <v-layout style="margin-bottom: 0; padding-bottom: 0">
        <v-flex style="margin-right: 2ch; width: 8vw">
          <v-text-field :label="this.$i18n.t('configuration.code')" v-model="item.code" :readonly="!hasPermissions(rolePermission)"/>
        </v-flex>

        <v-flex style="margin-right: 2ch; width: 8vw">
          <v-text-field :label="this.$i18n.t('configuration.codeInterface')" v-model="item.codeInterface" :readonly="!hasPermissions(rolePermission)"/>
        </v-flex>

        <v-flex style="margin-right: 2ch; width: 25vw">
          <v-text-field :label="this.$i18n.t('configuration.description')" v-model="item.comments" :readonly="!hasPermissions(rolePermission)"/>
        </v-flex>

        <v-flex style="margin-right: 2ch; width: 5vw">
          <v-checkbox :label="this.$i18n.t('configuration.remote')" color="primary" v-model="item.remote" :disabled="!hasPermissions(rolePermission)"/>
        </v-flex>

        <v-flex style="margin-right: 2ch; width: 5vw">
          <v-checkbox :label="this.$i18n.t('configuration.pushBack')" color="primary" v-model="item.pushback" :disabled="!hasPermissions(rolePermission)"/>
        </v-flex>

        <v-flex style="margin-right: 2ch; width: 7vw">
          <v-checkbox :label="this.$i18n.t('configuration.inop400Hz')" color="primary" v-model="item.inop400Hz" :disabled="!hasPermissions(rolePermission)"/>
        </v-flex>
      </v-layout>
    </v-container>
  </v-card-text>
</template>
<script>
import RolePermissions from '../../mixins/RolePermissions.vue';

export default {
  name: 'AirportParkingEditCardContent',
  mixins: [RolePermissions],
  props: {
    item: { type: Object, required: true },
  },
  computed: {
    rolePermission() {
      return `${this.$route.meta.role}_WR`;
    },
  },
};
</script>
