<template>
    <v-card>
        <v-layout row wrap>
          <v-flex xs12>
            <v-toolbar dark color="primary">
              <v-icon>{{icon}}</v-icon>
              <v-toolbar-title>{{flightComposition.common.flightNumber}} - {{flightComposition.common.airport1Code}} - {{flightComposition.common.flightDate | moment('DD/MM/YYYY')}}</v-toolbar-title>
              <v-spacer></v-spacer>
              <flight-status :status="flightComposition.common.status" resizable="false"></flight-status>
            </v-toolbar>

            <v-list>
              <v-list-tile v-if="isInbound">
                <v-list-tile-content>
                  <v-list-tile-title><span><label>STA:</label> {{flightComposition.common.scheduledTime | moment('HH:mm')}}</span></v-list-tile-title>
                </v-list-tile-content>

                <v-list-tile-content>
                  <v-list-tile-title><span><label>ETA:</label> {{flightComposition.times.estimatedTime | moment('HH:mm')}}</span></v-list-tile-title>
                </v-list-tile-content>

                <v-list-tile-content>
                  <v-list-tile-title><span><label>LAND:</label> {{flightComposition.times.landTime | moment('HH:mm')}}</span></v-list-tile-title>
                </v-list-tile-content>

                <v-list-tile-content>
                  <v-list-tile-title><span><label>ONB:</label> {{flightComposition.times.blockTime | moment('HH:mm')}}</span></v-list-tile-title>
                </v-list-tile-content>
              </v-list-tile>

              <v-list-tile v-if="isOutbound">
                <v-list-tile-content>
                  <v-list-tile-title><span><label>STD:</label> {{flightComposition.common.scheduledTime | moment('HH:mm')}}</span></v-list-tile-title>
                </v-list-tile-content>

                <v-list-tile-content>
                  <v-list-tile-title><span><label>ETD:</label> {{flightComposition.times.estimatedTime | moment('HH:mm')}}</span></v-list-tile-title>
                </v-list-tile-content>

                <v-list-tile-content>
                  <v-list-tile-title><span><label>OFB:</label> {{flightComposition.times.blockTime | moment('HH:mm')}}</span></v-list-tile-title>
                </v-list-tile-content>

                <v-list-tile-content>
                  <v-list-tile-title><span><label>AIR:</label> {{flightComposition.times.landTime | moment('HH:mm')}}</span></v-list-tile-title>
                </v-list-tile-content>
              </v-list-tile>

              <v-list-tile>
                <v-list-tile-content>
                  <v-list-tile-title><span><label>Reg:</label> {{flightComposition.common.aircraftRegisterNumber}}</span></v-list-tile-title>
                </v-list-tile-content>

                <v-list-tile-content>
                  <v-list-tile-title><span><label>Type:</label> {{flightComposition.common.aircraftTypeCode}}</span></v-list-tile-title>
                </v-list-tile-content>

                <v-list-tile-content>
                  <v-list-tile-title><span><label>Parking:</label> {{flightComposition.common.parkingCode}}</span></v-list-tile-title>
                </v-list-tile-content>

                <v-list-tile-content>
                  <v-list-tile-title><span><label>Gate:</label> {{flightComposition.common.gateCode}}</span></v-list-tile-title>
                </v-list-tile-content>
              </v-list-tile>
            </v-list>

          </v-flex>
        </v-layout>
    </v-card>
</template>
<script>
import FlightStatus from './FlightStatus.vue';

export default {
  name: 'FlightDetail',
  components: { FlightStatus },
  props: {
    flightComposition: { type: Object },
  },
  computed: {
    isInbound() {
      return this.flightComposition.common.bound === 'INBOUND';
    },
    isOutbound() {
      return this.flightComposition.common.bound === 'OUTBOUND';
    },
    icon() {
      if (this.isInbound) {
        return 'flight_land';
      } if (this.isOutbound) {
        return 'flight_takeoff';
      }
      return 'warning';
    },
  },
};
</script>
