<template>
  <v-breadcrumbs :items="breadcrumbs" large>
    <v-breadcrumbs-item slot="item" slot-scope="{ item }" :to="item.to">
      {{ item.text }}
    </v-breadcrumbs-item>
  </v-breadcrumbs>
</template>

<script>
export default {
  name: 'NavbarBreadcrumbs',
  computed: {
    breadcrumbs() {
      if (!this.$route.meta.breadcrumbs) {
        return [];
      }
      return [this.$route.meta.breadcrumbs[0]];
    },
  },
};
</script>
