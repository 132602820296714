<script>
import Vue from 'vue';
import { OrientationTypes } from './orientationType';

export default Vue.extend({
  methods: {
    getOrientationName(orientation) {
      if (orientation === OrientationTypes.ARRIVAL) {
        return this.$i18n.t('common.arrival');
      } if (orientation === OrientationTypes.DEPARTURE) {
        return this.$i18n.t('common.departure');
      } if (orientation === OrientationTypes.GROUND) {
        return this.$i18n.t('common.ground');
      }

      return null;
    },
  },
});
</script>
