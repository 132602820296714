import { VBreadcrumbs } from 'vuetify/lib/components/VBreadcrumbs';
import { VBreadcrumbsItem } from 'vuetify/lib/components/VBreadcrumbs';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{attrs:{"fluid":"","py-0":""}},[_c(VLayout,[_c(VFlex,{attrs:{"xs12":""}},[_c(VBreadcrumbs,{attrs:{"items":_vm.breadcrumbs},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return _c(VBreadcrumbsItem,{attrs:{"to":item.to,"exact":""}},[_vm._v(" "+_vm._s(item.text)+" ")])}}])})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }