
export default class SchedulingImportOptions {
  constructor(fileType, delimitierCharacter, timeZoneMode, fromDate, toDate, airlines, linesToPreview) {
    this.fileType = fileType || 'CSV_DAILY';
    this.delimitierCharacter = delimitierCharacter || ';';
    this.timeZoneMode = timeZoneMode || 'LOCAL';
    this.fromDate = fromDate || null;
    this.toDate = toDate || null;
    this.airlines = airlines || null;
    this.linesToPreview = linesToPreview || 10;
  }

  isValidOptionsFile() {
    return this.linesToPreview && this.linesToPreview >= 1 && this.linesToPreview <= 50;
  }

  isValidOptionsFilter() {
    return !!this.fromDate && !!this.toDate && !!this.airlines && this.airlines.length > 0;
  }
}
