import { JSUtils } from '../js/JSUtils';

export default class FinancialSystemsFile {
  constructor(
    name,
    path,
    directory,
    children,
  ) {
    this.name = name;
    this.path = path;
    this.directory = directory;
    this.children = children || [];
  }


  static createEmptyFinancialSystemsFile() {
    return new FinancialSystemsFile();
  }

  static createFinancialSystemsFileFromData(o) {
    if (!o) {
      return this.createEmptyFinancialSystemsFile();
    }
    return JSUtils.cast(o, FinancialSystemsFile);
  }
}
