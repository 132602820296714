/* eslint no-param-reassign: "off" */

import TenantService from '../../components/shared/services/tenant/tenant-service';
import { AXIOS_DEFAULT, HTTP } from '../../http';
import { REQUEST_ERROR, REQUEST_START, REQUEST_SUCCESS } from '../store';
import { CHANGED_TENANT_AIC_DOCUMENTS } from './aicDocuments/aicDocumentsCatalog';
import { CHANGED_TENANT_AIRPORT_BELT } from './airportBelt/airportBeltCatalog';
import { CHANGED_TENANT_AIRPORT_CAROUSEL } from './airportCarousel/airportCarouselCatalog';
import { CHANGED_TENANT_AIRPORT_CHECK_IN_COUNTER } from './airportCheckInCounter/airportCheckInCounterCatalog';
import { CHANGED_TENANT_AIRPORT_GATE } from './airportGate/airportGateCatalog';
import { CHANGED_TENANT_AIRPORT_PARKING } from './airportParking/airportParkingCatalog';
import { CHANGED_TENANT_FLIGHT_WEIGHTING } from './flightWeighting/flightWeightingCatalog';
import { CHANGED_TENANT_AIRPORT_RAMP_SERVICE } from './rampServices/airportRampServicesCatalog';
import { AUTH_CHECK } from './auth';
import AuthService from '../../components/shared/services/auth/auth-service';

export const TENANT_LIST = 'TENANT_LIST';
export const TENANT_CURRENT_GET = 'TENANT_CURRENT_GET';
export const TENANT_CURRENT_SET = 'TENANT_CURRENT_SET';
export const UPDATE_CATALOGS_TENANT_CURRENT = 'UPDATE_CATALOGS_TENANT_CURRENT';

export const TENANT_LIST_SUCCESS = 'TENANT_LIST_SUCCESS';
export const TENANT_CURRENT_GET_SUCCESS = 'TENANT_CURRENT_GET_SUCCESS';
export const TENANT_CURRENT_GET_REQUESTED = 'TENANT_CURRENT_GET_REQUESTED';
export const TENANT_CURRENT_SET_SUCCESS = 'TENANT_CURRENT_SET_SUCCESS';
export const TENANT_CURRENT_SET_ERROR = 'TENANT_CURRENT_SET_ERROR';

// initial state
const storeState = {
  tenantsList: [],
  currentTenant: null,
  currentTenantRequested: false,
};

// getters
const storeGetters = {
  // eslint-disable-next-line
  getTenant: state => state.tenantsList.sort((a, b) => (a.name > b.name ? 1 : (b.name > a.name ? -1 : 0))),
  isTenantSetted: state => !!state.currentTenant,
  isSCL: state => state.currentTenant && state.currentTenant.name === 'SCL',
  isPMI: state => state.currentTenant && state.currentTenant.name === 'PMI',
  isDUS: state => state.currentTenant && state.currentTenant.name === 'DUS',
};

// actions
const storeActions = {
  [TENANT_LIST]: ({ commit }) => {
    commit(REQUEST_START);
    return HTTP.get('tenants')
      .then((resp) => {
        commit(REQUEST_SUCCESS);
        commit(TENANT_LIST_SUCCESS, resp.data);
      })
      .catch((err) => {
        commit(REQUEST_ERROR, err);
      });
  },
  [TENANT_CURRENT_GET]: ({ commit }) => {
    const tenant = TenantService.getTenant();
    if (tenant) {
      commit(TENANT_CURRENT_GET_SUCCESS, tenant);
    }
    commit(TENANT_CURRENT_GET_REQUESTED);
  },
  [TENANT_CURRENT_SET]: ({ commit, dispatch }, tenant) => new Promise((resolve, reject) => {
    commit(REQUEST_START);
    commit(TENANT_CURRENT_SET_SUCCESS, null);

    const tenantData = {
      accessToken: AuthService.getAccessToken(),
      tenant: tenant.name,
    };
    AXIOS_DEFAULT.post('/auth/jwt/tenant', tenantData)
      .then((resp) => {
        AuthService.updateAccessToken(resp.data.tenantToken);
        commit(TENANT_CURRENT_SET_SUCCESS, tenant);
        dispatch(UPDATE_CATALOGS_TENANT_CURRENT);
        dispatch(AUTH_CHECK).then((data) => {
          commit(REQUEST_SUCCESS);
          resolve(data);
        }).catch((err) => {
          commit(REQUEST_ERROR, err);
          commit(TENANT_CURRENT_SET_ERROR, err);
          reject(err);
        });
      })
      .catch((err) => {
        commit(REQUEST_ERROR, err);
        commit(TENANT_CURRENT_SET_ERROR, err);
        reject(err);
      });
  }),
  [UPDATE_CATALOGS_TENANT_CURRENT]: ({ commit }) => {
    commit(CHANGED_TENANT_AIC_DOCUMENTS);
    commit(CHANGED_TENANT_AIRPORT_BELT);
    commit(CHANGED_TENANT_AIRPORT_CAROUSEL);
    commit(CHANGED_TENANT_AIRPORT_CHECK_IN_COUNTER);
    commit(CHANGED_TENANT_AIRPORT_GATE);
    commit(CHANGED_TENANT_AIRPORT_PARKING);
    commit(CHANGED_TENANT_FLIGHT_WEIGHTING);
    commit(CHANGED_TENANT_AIRPORT_RAMP_SERVICE);
  },
};

// mutations
const storeMutations = {
  [TENANT_LIST_SUCCESS]: (state, tenants) => {
    state.tenantsList = tenants;
  },
  [TENANT_CURRENT_GET_SUCCESS]: (state, tenant) => {
    state.currentTenant = tenant;
  },
  [TENANT_CURRENT_GET_REQUESTED]: (state) => {
    state.currentTenantRequested = true;
  },
  [TENANT_CURRENT_SET_SUCCESS]: (state, tenant) => {
    TenantService.setTenant(tenant);
    state.currentTenant = tenant;
  },
  [TENANT_CURRENT_SET_ERROR]: (state) => {
    TenantService.removeTenant();
    state.currentTenant = null;
  },
};

export default {
  state: storeState,
  getters: storeGetters,
  actions: storeActions,
  mutations: storeMutations,
};
