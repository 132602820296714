<template>
  <div>
    <airport-carousel-select :ref="'refComp'" v-on:keydown.native="keyEvent" v-char-length.clearable="4" v-model="itemId" @input-code="setItemCode($event)"/>
  </div>
</template>

<script>
import Vue from 'vue';
import AirportCarouselSelect from '../../../configuration/airportCarousel/AirportCarouselSelect.vue';
import AgGridParentConnectedFlightsCellEditor from '../mixins/AgGridParentConnectedFlightsCellEditor.vue';

export default Vue.extend({
  mixins: [AgGridParentConnectedFlightsCellEditor],
  components: {
    AirportCarouselSelect,
  },
  data() {
    return {
      itemId: null,
      itemIdOriginal: null,
      itemCode: null,
    };
  },
  methods: {
    getValue() {
      this.unmarkCellsSelectedAsEdit();

      if (this.itemIdOriginal !== this.itemId) {
        const that = this;

        this.saveFlightChangesFromValue((s) => {
          s.setChildPropertyValueByBound(this.params.column.colDef.colCustomConfig.flightBound, 'rampExtended.carouselId', that.itemId);
          s.setChildPropertyValueByBound(this.params.column.colDef.colCustomConfig.flightBound, 'rampExtended.carouselCode', that.itemCode);
        }, 'Carousel', this.itemIdOriginal, this.itemId);

        return that.itemCode;
      }
      return this.params.node.data;
    },
    setItemCode(code) {
      this.itemCode = code;
    },
  },
  created() {
    const connectedFlights = this.params.node.data.clone();
    this.itemIdOriginal = connectedFlights.getChildPropertyValue(this.params.column.colDef.colCustomConfig.flightBound, ['ramp', 'carouselId']);
    this.itemId = this.itemIdOriginal;

    this.markCellsSelectedAsEdit();
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$refs.refComp && this.$refs.refComp.$refs.refComp) {
        this.$refs.refComp.$refs.refComp.focus();
      }
    });
  },
});
</script>

<style>

</style>
