<template>
<div>
  <towing-adhoc-task-list-table :items="items" :isFilterDisplayed="this.isFilterDisplayed" @delete-item="deleteItem" @cancel-item="cancelItem" @approve-item="approveItem"></towing-adhoc-task-list-table>
</div>
</template>
<script>
import TowingAdhocTaskListTable from './TowingAdhocTaskListTable.vue';
import {
  TOWING_ADHOC_TASK_FILTER_RESET,
  TOWING_ADHOC_TASK_FIND,
} from '../../../store/modules/towingAdhocTask/towingAdhocTask';

export default {
  name: 'towing-adhoc-task-list-container',
  components: {
    TowingAdhocTaskListTable,
  },
  props: {
    isFilterDisplayed: { type: Boolean, required: false, default: false },
  },
  mounted() {
    this.$store.commit(TOWING_ADHOC_TASK_FILTER_RESET);
    this.$store.dispatch(TOWING_ADHOC_TASK_FIND);
  },
  computed: {
    items() {
      return this.$store.state.towingAdhocTask.towingAdhocTasks;
    },
  },
  methods: {
    deleteItem(item) {
      this.$emit('delete-item', item);
    },
    cancelItem(item) {
      this.$emit('cancel-item', item);
    },
    approveItem(item) {
      this.$emit('approve-item', item);
    },
  },
};
</script>
<style lang="scss">


</style>
