<template>
  <div class="billing list-filter">
    <v-card :dark="this.$vuetify.dark" :color="this.$vuetify.dark ? 'bggrey_dark' : 'bggrey'">
      <v-toolbar dark dense color="primary lighten-1">
        <v-btn v-on:click="displayedFilter" small flat>
          <v-icon>filter_list</v-icon>
          <v-icon v-if="!showFilter">keyboard_arrow_down</v-icon>
          <v-icon v-if="showFilter">keyboard_arrow_up</v-icon>
        </v-btn>

        <v-spacer></v-spacer>
        <v-divider vertical light style="margin-left: 30px; margin-right: 30px"></v-divider>
        <BillingListTableOptionsFilterButton />
      </v-toolbar>
      <v-container fluid v-if="showFilter" class="pa-2 pb-2">
        <v-layout class="filter-inline" align-center justify-start>
          <v-flex class="mr-2" style="max-width: 200px;">
            <p v-if="aircraftStatus === aircraftOnAir" class="aircraft-state-label selected text-no-wrap">
              <v-icon size="20" dark class="mr-1">mdi-airplane</v-icon>
              {{
      $t('common.showFlights') }}
            </p>
            <p @click="clickLabelAircraftOnAir" v-else class="aircraft-state-label text-no-wrap">
              <v-icon size="20" class="mr-1">mdi-airplane</v-icon>
              {{ $t('common.showFlights') }}
            </p>
            <p v-if="aircraftStatus === aircraftOnGround" class="aircraft-state-label selected text-no-wrap">
              <v-icon size="20" dark class="mr-1">flight</v-icon>
              {{ $t('common.showAircraftGround') }}
            </p>
            <p @click="clickLabelAircraftOnGround" v-else class="aircraft-state-label text-no-wrap">
              <v-icon size="20" class="mr-1">flight</v-icon>
              {{ $t('common.showAircraftGround') }}
            </p>
          </v-flex>
          <v-flex class="mr-2" align-center justify-start>
            <date-input v-model="filter.dateFrom" :locale="$i18n.locale" :label="$t('common.fromDate')" class="pb-2"
              @change="changeDateFrom"></date-input>
            <date-input v-model="filter.dateTo" :locale="$i18n.locale" :label="$t('common.toDate')"
              @change="changeDateTo"></date-input>
          </v-flex>
          <v-flex align-center justify-start>
            <flight-state v-model="filter.flightState" @input="filterChanged"></flight-state>
          </v-flex>
          <v-flex class="ml-2">
            <p class="text-filter mt-2 mb-0">{{ $t("common.services") }}</p>
            <v-layout row>
              <ramp-service-multiple-select icon="rv_hookup" class="mr-3" v-char-length.clearable="25"
                :label="$t('common.rampServices')" v-model="filter.rampService"
                @input="filterChanged"></ramp-service-multiple-select>
              <flight-service v-model="filter.flightService" @input="filterChanged"></flight-service>
            </v-layout>
          </v-flex>
          <v-flex class="text-no-wrap" align-center justify-start>
            <v-text-field class="ml-2 pb-2" v-model="filter.flight" :label="$t('common.flight')" placeholder="..."
              hide-details></v-text-field>
            <airline-select class="ml-2" label="Op" v-model="filter.airline" @input="filterChanged"></airline-select>
          </v-flex>
          <v-spacer></v-spacer>
          <v-flex class="ml-2">
            <airport-select icon="flight_land" :label="$t('common.from')" v-model="filter.airportFrom"
              @input="filterChanged" class="pb-2"></airport-select>
            <airport-select icon="flight_takeoff" :label="$t('common.to')" v-model="filter.airportTo"
              @input="filterChanged"></airport-select>
          </v-flex>
          <v-spacer></v-spacer>
          <v-flex class="ml-2 text-no-wrap" align-center justify-start>
            <div align-left>
              <v-menu left offset-y>
                <template v-slot:activator="{ on }">
                  <v-btn color="secondary" small flat round v-on="on" block class="mb-4">
                    <v-icon>mdi-download</v-icon>
                    {{ $t("common.export") }}
                  </v-btn>
                </template>
                <v-list>
                  <v-list-tile-content>
                    <v-list-tile-sub-title>
                      <v-btn color="secondary" small flat v-on:click.stop="exportCSV">
                        <v-icon>mdi-download</v-icon>
                        {{ $t("common.export") }}
                      </v-btn>
                    </v-list-tile-sub-title>
                    <v-list-tile-sub-title>
                      <v-btn color="secondary" small flat v-on:click.stop="exportExcel">
                        <v-icon>mdi-download</v-icon>
                        {{ $t("common.exportExcel") }}
                      </v-btn>
                    </v-list-tile-sub-title>
                    <v-list-tile-sub-title>
                      <v-btn color="secondary" small flat v-on:click.stop="exportServicesCheck">
                        <v-icon>mdi-download</v-icon>
                        {{ $t("common.exportServicesCheck") }}
                      </v-btn>
                    </v-list-tile-sub-title>
                  </v-list-tile-content>
                </v-list>
              </v-menu>
            </div>
            <v-btn color="primary" round v-on:click.stop="find" block>
              <v-icon>search</v-icon>
              {{ $t("common.find") }}
            </v-btn>

          </v-flex>
        </v-layout>

      </v-container>
    </v-card>
  </div>

</template>
<script>
import { saveAs } from 'file-saver';
import AirlineSelect from '../../airline/AirlineSelect.vue';
import AirportSelect from '../../airport/AirportSelect.vue';
import DateInput from '../../ui/DateInput.vue';
import DaysOfWeek from '../../ui/DaysOfWeek.vue';
import AgGridApiCsvExportable from '../../ag-grid/mixins/AgGridApiCsvExportable.vue';
import StoreSubscribable from '../../mixins/StoreSubscribable.vue';
import {
  BILLING_CHANGE_GRID_API,
  BILLING_FILTER_SET_UNIT_TIME,
  BILLING_FIND_FLIGHTS, BILLING_RECALCULATE_FILTER,
  UPDATE_FILTER_SHOW_ONLY_AIRCRAFT_BY_STATUS,
  BILLING_UPDATE_FILTER_PAGINATION_SET_FIRST_PAGE,
} from '../../../store/modules/invoicing/billing';
import { TIME_TIMEZONE_CHANGED } from '../../../store/modules/time';
import SchedulingFlightsSavedQueries from '../../scheduling/list/SchedulingFlightsSavedQueries.vue';
import FlightState from '../../ui/FlightState.vue';
import FlightService from '../../ui/FlightService.vue';
import BillingListTableOptionsFilterButton from './BillingListTableOptionsFilterButton.vue';
import RampServiceSelect from '../../rampService/RampServiceSelect.vue';
import RampServiceMultipleSelect from '../../rampService/RampServiceMultipleSelect.vue';
import { REQUEST_ERROR, REQUEST_START, REQUEST_SUCCESS } from '../../../store/store';
import FileSerializable from '../../mixins/FileSerializable.vue';
import { AircraftStatus } from '../model/aircraftStatus';

export default {
  name: 'billing-list-filter',
  mixins: [AgGridApiCsvExportable, StoreSubscribable, FileSerializable],
  components: {
    RampServiceSelect,
    RampServiceMultipleSelect,
    SchedulingFlightsSavedQueries,
    DaysOfWeek,
    AirlineSelect,
    AirportSelect,
    DateInput,
    FlightState,
    FlightService,
    BillingListTableOptionsFilterButton,
  },
  props: ['value'],
  data() {
    return {
      showFilter: true,
      filterSummary: null,
      aircraftOnAir: AircraftStatus.FLIGHT,
      aircraftOnGround: AircraftStatus.GROUND,
      aircraftStatus: undefined, // initialized on mounted
    };
  },
  computed: {
    filter() {
      return this.$store.state.billing.filter;
    },
    unitOfTime: {
      get() {
        return this.filter.unitOfTime;
      },
      set(value) {
        this.$store.commit(BILLING_FILTER_SET_UNIT_TIME, value);
      },
    },
  },
  methods: {
    filterChanged() {
      // this.find();
    },
    find() {
      this.showLoading();
      this.$store.dispatch(BILLING_UPDATE_FILTER_PAGINATION_SET_FIRST_PAGE);
      this.$store.dispatch(BILLING_FIND_FLIGHTS);
    },
    exportCSV() {
      this.exportAgGridCsvWithHiddenColumns('BillingFlights');
    },
    exportExcel() {
      this.$store.commit(REQUEST_START);
      this.$http.post('billing/flights/export', this.filter, {})
        .then((resp) => {
          this.$store.commit(REQUEST_SUCCESS);
          const blob = new Blob([this.s2ab(atob(resp.data.content))], { type: resp.data.contentType });
          saveAs(blob, resp.data.name);
        }).catch((err) => {
          this.$store.commit(REQUEST_ERROR, err);
        });
    },
    exportServicesCheck() {
      this.$store.commit(REQUEST_START);
      this.$http.post('daily-flights-services/flights/export', this.filter, {})
        .then((resp) => {
          this.$store.commit(REQUEST_SUCCESS);
          const blob = new Blob([this.s2ab(atob(resp.data.content))], { type: resp.data.contentType });
          saveAs(blob, resp.data.name);
        }).catch((err) => {
          this.$store.commit(REQUEST_ERROR, err);
        });
    },
    changeDateFrom() {
      this.unitOfTime = null;
      const from = this.$moment(this.filter.dateFrom);
      const to = this.$moment(this.filter.dateTo);

      this.filter.dateFrom = from.startOf('day');
      if (from.isAfter(to)) {
        this.filter.dateTo = this.$moment(this.filter.dateFrom).endOf('day');
      }
      this.filterChanged();
    },
    changeDateTo() {
      this.unitOfTime = null;
      const from = this.$moment(this.filter.dateFrom);
      const to = this.$moment(this.filter.dateTo);

      this.filter.dateTo = to.endOf('day');
      if (to.isBefore(from)) {
        this.filter.dateFrom = this.$moment(this.filter.dateTo).startOf('day');
      }
      this.filterChanged();
    },
    displayedFilter() {
      this.showFilter = !this.showFilter;
      this.$emit('changeShowFilter', this.showFilter);
    },
    changeAircraftStateFilter() {
      this.$store.dispatch(UPDATE_FILTER_SHOW_ONLY_AIRCRAFT_BY_STATUS, this.aircraftStatus);
      this.find();
    },
    clickLabelAircraftOnAir() {
      this.aircraftStatus = this.aircraftOnAir;
      this.changeAircraftStateFilter();
    },
    clickLabelAircraftOnGround() {
      this.aircraftStatus = this.aircraftOnGround;
      this.changeAircraftStateFilter();
    },
  },
  mounted() {
    const that = this;
    // initialize variable for transition
    this.aircraftStatus = this.aircraftOnAir;

    this.subscribeMutation(BILLING_CHANGE_GRID_API, (mutationPayload) => {
      that.setAgGridApi(mutationPayload);
    });
    this.subscribeMutation(TIME_TIMEZONE_CHANGED, () => {
      this.$store.dispatch(BILLING_RECALCULATE_FILTER);
      this.find();
    });
    this.$store.dispatch(BILLING_RECALCULATE_FILTER);
    this.find();
  },
};
</script>
<style lang="scss">
.billing .aircraft-state-label {
  background-color: #e2e2e2;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
  padding: 10px 10px;
  border-radius: 9px;
  margin: 3px;
  max-width: 200px;
}

.billing .aircraft-state-label.selected {
  cursor: default;
  background-color: #fc0303;
  color: white;
}

.dark-mode {
  .billing .aircraft-state-label {
    background-color: #3d3d3d;
  }
  .billing .aircraft-state-label.selected {
    background-color: #fc0303;
  }
}
</style>
