<template>
  <v-layout row justify-center>
    <v-dialog v-model="dialog" persistent max-width="1370px"
      :content-class="isContentVisible ? 'modal-common' : 'modal-common hide-title'">
      <v-card :dark="this.$vuetify.dark" :color="this.$vuetify.dark ? 'bggrey_dark' : 'bggrey'">
        <v-toolbar dark color="primary">
          <img height="30" :src="require('@/assets/svg-icons/linkflights-w.svg')">
          <v-toolbar-title>{{ $t("scheduling.linkFlightsTitle") }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <!-- Botón para contraer/expandir el contenido -->
          <v-btn icon @click="toggleContentVisibility">
            <v-icon>{{ isContentVisible ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
          </v-btn>
        </v-toolbar>
        <div v-show="isContentVisible">

          <loading-component />

          <v-card-text>
            <scheduling-link-stepper :items="items" @link-stepper-confirm="confirm" @link-stepper-cancel="reject" />
          </v-card-text>
        </div>
      </v-card>
    </v-dialog>
  </v-layout>
</template>
<script>

import SchedulingImportCsvStepper from '../import/SchedulingImportStepper.vue';
import LoadingComponent from '../../layout/LoadingComponent.vue';
import SchedulingLinkStepper from './SchedulingLinkStepper.vue';

export default {
  name: 'scheduling-link-modal',
  components: {
    SchedulingLinkStepper,
    SchedulingImportCsvStepper,
    LoadingComponent,
  },
  props: ['dialog', 'items'],
  data() {
    return {
      isContentVisible: true,
    };
  },
  methods: {
    reject() {
      this.$emit('link-reject');
    },
    confirm(linkedFlights) {
      this.$emit('link-confirm', linkedFlights);
    },
    toggleContentVisibility() {
      this.isContentVisible = !this.isContentVisible;
    },
  },
};
</script>
<style></style>
