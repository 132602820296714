import { VCardText } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCardText,[_c(VContainer,{staticStyle:{"margin-bottom":"0","padding-bottom":"0"},attrs:{"fluid":""}},[_c(VLayout,{staticStyle:{"margin-bottom":"0","padding-bottom":"0"}},[_c(VFlex,{staticStyle:{"margin-right":"2ch","width":"3vw"}},[_c(VTextField,{attrs:{"label":this.$i18n.t('configuration.code'),"readonly":!_vm.hasPermissions(_vm.rolePermission)},model:{value:(_vm.item.code),callback:function ($$v) {_vm.$set(_vm.item, "code", $$v)},expression:"item.code"}})],1),_c(VFlex,{staticStyle:{"margin-right":"2ch","width":"30vw"}},[_c(VTextField,{attrs:{"label":this.$i18n.t('configuration.description'),"readonly":!_vm.hasPermissions(_vm.rolePermission)},model:{value:(_vm.item.comments),callback:function ($$v) {_vm.$set(_vm.item, "comments", $$v)},expression:"item.comments"}})],1),_c(VFlex,{attrs:{"xs3":"","pr-2":""}},[_c(VTextField,{attrs:{"label":this.$i18n.t('configuration.weighting'),"type":"number","min":"0","readonly":!_vm.hasPermissions(_vm.rolePermission)},model:{value:(_vm.item.weighting),callback:function ($$v) {_vm.$set(_vm.item, "weighting", $$v)},expression:"item.weighting"}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }