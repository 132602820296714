/* eslint no-param-reassign: "off" */
import { HTTP } from '../../http';
import { REQUEST_START, REQUEST_SUCCESS, REQUEST_ERROR } from '../store';
import { NOTIFICATION_SHOW } from './notifications';
import i18n from '../../plugins/i18n';

// action names
export const GET_DIRECTORY = 'GET_DIRECTORY';
export const UPDATE_DIRECTORY_FILE = 'UPDATE_DIRECTORY_FILE';
export const CREATE_DIRECTORY_FILE = 'CREATE_DIRECTORY_FILE';
export const DELETE_DIRECTORY_FILE = 'DELETE_DIRECTORY_FILE';
export const UPDATE_DIRECTORY_MEMBER = 'UPDATE_DIRECTORY_MEMBER';
export const CREATE_DIRECTORY_MEMBER = 'CREATE_DIRECTORY_MEMBER';
export const DELETE_DIRECTORY_MEMBER = 'DELETE_DIRECTORY_MEMBER';


// mutations
export const GET_DIRECTORY_FILE_SUCCESS = 'GET_DIRECTORY_FILE_SUCCESS';

// initial state
const storeState = {
  directory: [],
};

// getters
const storeGetters = {
  getAirportDirectoryFile: state => state.directory.filter(file => file.order === 0)[0],
  getDirectoryFiles: state => state.directory.filter(file => file.order !== 0),
  getDirectoryById: state => id => state.directory.find(a => a.id === id),
};

// actions
const storeActions = {
  [GET_DIRECTORY]: ({ commit }) => {
    commit(GET_DIRECTORY_FILE_SUCCESS, []);
    commit(REQUEST_START);
    HTTP.get('directory-files')
      .then((resp) => {
        commit(REQUEST_SUCCESS);
        commit(GET_DIRECTORY_FILE_SUCCESS, resp.data);
      })
      .catch((err) => {
        commit(REQUEST_ERROR, err);
      });
  },
  [CREATE_DIRECTORY_FILE]: ({ commit, dispatch, state }, directoryFile) => {
    commit(REQUEST_START);
    directoryFile.order = state.directory.length;
    return HTTP.post('directory-files', directoryFile)
      .then(() => {
        commit(REQUEST_SUCCESS);
        dispatch(GET_DIRECTORY);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('messages.directoryFileSuccessCreate'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('messages.directoryFileError'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
  [UPDATE_DIRECTORY_FILE]: ({ commit, dispatch }, directoryFile) => {
    commit(REQUEST_START);
    return HTTP.put('directory-files', directoryFile)
      .then(() => {
        commit(REQUEST_SUCCESS);
        dispatch(GET_DIRECTORY);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('messages.directoryFileSuccessUpdate'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('messages.directoryFileError'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
  [DELETE_DIRECTORY_FILE]: ({ commit, dispatch }, id) => {
    commit(REQUEST_START);
    return HTTP.delete(`directory-files/${id}`)
      .then(() => {
        commit(REQUEST_SUCCESS);
        dispatch(GET_DIRECTORY);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('messages.directoryFileSuccessDelete'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('messages.directoryFileError'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
  [CREATE_DIRECTORY_MEMBER]: ({ commit, dispatch }, { directoryFile, directoryMember }) => {
    commit(REQUEST_START);
    directoryMember.order = directoryFile.members.length;
    directoryMember.fileId = directoryFile.id;

    return HTTP.post('directory-members', directoryMember)
      .then(() => {
        commit(REQUEST_SUCCESS);
        dispatch(GET_DIRECTORY);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('messages.directoryMemberSuccessCreate'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('messages.directoryMemberError'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
  [UPDATE_DIRECTORY_MEMBER]: ({ commit, dispatch }, { directoryFile, directoryMember }) => {
    commit(REQUEST_START);
    if (!directoryMember.order) {
      directoryMember.order = directoryFile.members.length;
    }
    return HTTP.put('directory-members', directoryMember)
      .then(() => {
        commit(REQUEST_SUCCESS);
        dispatch(GET_DIRECTORY);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('messages.directoryMemberSuccessUpdate'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('messages.directoryMemberError'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
  [DELETE_DIRECTORY_MEMBER]: ({ commit, dispatch }, id) => {
    commit(REQUEST_START);
    return HTTP.delete(`directory-members/${id}`)
      .then(() => {
        commit(REQUEST_SUCCESS);
        dispatch(GET_DIRECTORY);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('messages.directoryMemberSuccessDelete'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('messages.directoryMemberError'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
};

// mutations
const storeMutations = {
  [GET_DIRECTORY_FILE_SUCCESS]: (state, directory) => {
    state.directory = directory;
    if (!state.directory.contact) {
      state.directory.contact = {};
    }
    if (state.directory.members) {
      state.directory.members.forEach((member) => {
        if (!member.contact) {
          member.contact = {};
        }
      });
    }
  },
};

export default {
  state: storeState,
  getters: storeGetters,
  actions: storeActions,
  mutations: storeMutations,
};
