<template>
  <div class="admin-scheduling-imports-header">
    <v-card :dark="this.$vuetify.dark" :color="this.$vuetify.dark ? 'bggrey_dark' : 'bggrey'">
      <v-container fluid class="pa-2">
        <v-layout class="filter-inline" align-center justify-start>
          <div class="filter-item filter-month mr-2">
            <v-btn @click="displayMonthSelector = !displayMonthSelector" color="primary" class="filter-month-activator">
              <v-icon size="28" class="mr-2">event</v-icon>
              {{ selectedMonthName }}
            </v-btn>
            <v-date-picker :value="selectedMonth" v-if="displayMonthSelector" type="month" class="filter-month-selector"
              @input="displayMonthSelector = !displayMonthSelector" @change="changeSelectedMonth" />
          </div>
          <div class="filter-item filter-by-name-filter mr-2">
            <v-text-field :value="selectedFileNameOrAuthor"
              :label="$t('adminImports.adminSchedulingImports.filters.searchFile')" hide-details
              @change="changeSelectedFileNameOrAuthor"></v-text-field>
          </div>
          <div class="filter-item filter-by-status mr-2">
            <v-select :value="selectedFileStatus" menu-props="offset-y" clearable
              :label="$t('adminImports.adminSchedulingImports.filters.importStatus')" :items="fileStatus"
              :item-text="'label'" :item-value="'label'" variant="outlined" hide-details
              @change="changeSelectedFileStatus"></v-select>
          </div>
          <div class="filter-item">
            <v-btn color="primary" @click="searchSchedulingImportedFiles" round class="filter-find">
              <v-icon size="28" class="mr-2">search</v-icon>
              {{ $t('common.find') }}
            </v-btn>
          </div>
        </v-layout>
      </v-container>
    </v-card>
  </div>
</template>
<script>
import DatePicker from '../../../ui/DatePicker.vue';

import {
  GET_SCHEDULING_IMPORT_FILES_STATUS,
  MUTATE_SCHEDULING_IMPORT_FILES_FILTER_FILE_MONTH,
  MUTATE_SCHEDULING_IMPORT_FILES_FILTER_FILE_YEAR,
  MUTATE_SCHEDULING_IMPORT_FILES_FILTER_FILE_NAME_OR_AUTHOR,
  MUTATE_SCHEDULING_IMPORT_FILES_FILTER_FILE_STATUS,
} from '../../../../store/modules/schedulingAdminImports';

export default {
  name: 'AdminSchedulingImportsHeader',
  components: {
    DatePicker,
  },
  computed: {
    selectedMonthName() {
      const momentFromSelectedMonth = this.$moment(this.selectedMonth);
      const monthIndex = momentFromSelectedMonth && momentFromSelectedMonth.isValid() ? momentFromSelectedMonth.month() : 0;

      return monthIndex || monthIndex === 0 ? this.$i18n.t(this.monthNames[monthIndex]) : ''; // if monthIndex = 0 = JAN , thats why there is an or
    },
    // TODO: MERGE MONTH, NAME AND STATUS IN ONE OBJ
    selectedMonth() {
      const currentFilter = this.$store.getters.getSchedulingImportFilesFilter;
      return this.$moment(`${currentFilter.year}-${currentFilter.month}`, 'YYYY-MM').format('YYYY-MM-DD');
    },
    // TODO: MERGE MONTH, NAME AND STATUS IN ONE OBJ
    selectedFileNameOrAuthor() {
      return this.$store.getters.getSchedulingImportFilesFilter.fileNameOrFileAuthor;
    },
    // TODO: MERGE MONTH, NAME AND STATUS IN ONE OBJ
    selectedFileStatus() {
      return this.fileStatus
        .find(fileStatusToMatch => fileStatusToMatch.id === this.$store.getters.getSchedulingImportFilesFilter.status);
    },
  },
  data() {
    // TODO: extract to symbol
    const fileStatus = [
      { id: 'NOT_STARTED', label: this.$i18n.t('adminImports.adminSchedulingImports.status.NOTSTARTED') },
      { id: 'PROCESSING', label: this.$i18n.t('adminImports.adminSchedulingImports.status.PROCESSING') },
      { id: 'IMPORTED', label: this.$i18n.t('adminImports.adminSchedulingImports.status.IMPORTED') },
    ];
    // Todo: Extract monthName to an Utils class??
    return {
      monthNames: ['common.months.Jan', 'common.months.Feb', 'common.months.Mar', 'common.months.Apr', 'common.months.May', 'common.months.Jun', 'common.months.Jul', 'common.months.Aug', 'common.months.Sep', 'common.months.Oct', 'common.months.Nov', 'common.months.Dec'],
      fileStatus,
      displayMonthSelector: false,
      monthFilter: false,
    };
  },
  methods: {
    changeSelectedMonth(newSelectedDate) {
      const newSelectedDateMomentObj = this.$moment(newSelectedDate);
      this.$store.commit(MUTATE_SCHEDULING_IMPORT_FILES_FILTER_FILE_MONTH, newSelectedDateMomentObj.month());
      this.$store.commit(MUTATE_SCHEDULING_IMPORT_FILES_FILTER_FILE_YEAR, newSelectedDateMomentObj.year());
    },
    changeSelectedFileNameOrAuthor(newSelectedFileNameOrAuthor) {
      this.$store.commit(MUTATE_SCHEDULING_IMPORT_FILES_FILTER_FILE_NAME_OR_AUTHOR, newSelectedFileNameOrAuthor);
    },
    changeSelectedFileStatus(newSelectedFileStatus) {
      if (newSelectedFileStatus == null) {
        this.$store.commit(MUTATE_SCHEDULING_IMPORT_FILES_FILTER_FILE_STATUS, null);
      }
      const newFileStatus = this.fileStatus.find(fileStatusToMatch => fileStatusToMatch.label === newSelectedFileStatus).id;
      this.$store.commit(MUTATE_SCHEDULING_IMPORT_FILES_FILTER_FILE_STATUS, newFileStatus);
    },
    searchSchedulingImportedFiles() {
      this.$store.dispatch(GET_SCHEDULING_IMPORT_FILES_STATUS);
    },
  },
};
</script>

<style lang="scss">
.admin-scheduling-imports-header {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;


  .filter-item {
    flex: 1 1 0;
    width: 0;
    height: 50px;
    z-index: 1;
  }

  .filter-month {
    display: flex;
    flex-direction: column;
    min-width: 160px;
  }

  .filter-month-activator {
    width: 100%;
    height: 100%;
    margin: 0;
    position: relative;
  }

  .filter-month-selector {
    position: relative;
    margin: 5px 0 0 0;
  }

  .filter-month-selector .v-picker__body {
    width: 100% !important;
  }

  .filter-by-status {
    overflow-y: hidden;
  }

  .filter-find {
    width: 100%;
    height: 100%;
    max-width: 180px;
    margin: 0;
    float: right;
  }

}
</style>
