<template>
  <v-card-text>
    <v-container fluid>
      <v-layout row justify class="infoNewAirlines">
        <v-flex xs12 class="infoNewAirline"><span> {{$t("configuration.alertNewAirline")}}</span></v-flex>
      </v-layout>
      <v-layout row wrap>
        <v-flex xs4 px-2>
          <v-text-field :label="this.$i18n.t('configuration.iataCode')" v-model="item.iataCode" :rules="[rules.iataCode]" :readonly="!hasPermissions(rolePermission)"/>
        </v-flex>
        <v-flex xs4 px-2>
          <v-text-field :label="this.$i18n.t('configuration.icaoCode')" v-model="item.icaoCode" :rules="[rules.icaoCode]" :readonly="!hasPermissions(rolePermission)"/>
        </v-flex>
        <v-flex xs4 px-2>
          <v-text-field :label="this.$i18n.t('configuration.name')" v-model="item.name" :readonly="!hasPermissions(rolePermission)"/>
        </v-flex>
        <v-flex xs4 px-2>
          <airline-default-code-select :label="this.$i18n.t('configuration.defaultCode')" v-model="item.defaultCode" :disabled="!hasPermissions(rolePermission)"/>
        </v-flex>

        <v-flex xs12 px-2>
          <v-text-field :label="this.$i18n.t('configuration.description')" v-model="item.comments" :readonly="!hasPermissions(rolePermission)"/>
        </v-flex>

        <v-flex xs8 px-2>
          <v-text-field :label="this.$i18n.t('configuration.ghmIntegrationName')" v-model="item.ghmIntegrationName" :readonly="!hasPermissions(rolePermission)"/>
        </v-flex>

        <v-flex xs4 px-2>
          <v-text-field :label="this.$i18n.t('configuration.ghmIntegrationExtraCode')" v-model="item.ghmIntegrationExtraCode" :readonly="!hasPermissions(rolePermission)"/>
        </v-flex>
      </v-layout>
    </v-container>
  </v-card-text>
</template>
<script>

import AirlineDefaultCodeSelect from './AirlineDefaultCodeSelect.vue';
import RolePermissions from '../../mixins/RolePermissions.vue';

export default {
  name: 'AirlineEditCardContent',
  mixins: [RolePermissions],
  components: { AirlineDefaultCodeSelect },
  props: {
    item: { type: Object, required: true },
  },
  computed: {
    rolePermission() {
      return `${this.$route.meta.role}_WR`;
    },
  },
  data() {
    return {
      rules: {
        required: value => !!value || 'Required.',
        iataCode: value => (!value || value.length === 2) || this.$i18n.t('validation.iataCodeLength'),
        icaoCode: value => (!value || value.length === 3) || this.$i18n.t('validation.icaoCodeLength'),
      },
    };
  },
};
</script>
<style lang="scss">
  .infoNewAirline {
    padding: .5em;
    font-weight: bold;
  }
</style>
