<template>
  <div>
    <!--FIXME replace item-value to id-->
    <v-autocomplete
      placeholder="..."
      :value="value"
      :label="label"
      :items="delayType"
      :filter="customFilter"
      :clearable="true"
      item-text="code"
      item-value="id"
      :rules="rules"
      :disabled="disabled"
      @input="emitInput"
      :menu-props="{zIndex:'1000'}"
      :ref="refComp"
    >
      <template
        slot="item"
        slot-scope="{ item, tile }"
      >
        <v-list-tile-content>
          <v-list-tile-title v-text="item.code"></v-list-tile-title>
          <v-list-tile-sub-title v-text="item.description"></v-list-tile-sub-title>
        </v-list-tile-content>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import { JSUtils } from '../shared/js/JSUtils';
import DelayType from './delayType';
import { REQUEST_ERROR, REQUEST_SUCCESS } from '../../store/store';

export default {
  props: {
    value: { required: true },
    label: { type: String },
    charLenght: { type: Number, default: 6 },
    rules: { type: Array },
    disabled: { type: Boolean, default: false },
    refComp: {
      type: String,
      required: false,
      default: 'refComp',
    },
    flightId: { type: Number, required: true },
  },
  data() {
    return {
      delayType: [],
    };
  },
  methods: {
    customFilter(item, queryText) {
      const textOne = item.code ? item.code.toLowerCase() : '';
      const textTwo = item.description ? item.description.toLowerCase() : '';
      const searchText = queryText.toLowerCase();

      return textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1;
    },
    emitInput($event) {
      this.$emit('input-code', this.delayType.find(p => p.id === $event).code);
      this.$emit('input-description', this.delayType.find(p => p.id === $event).description);
      this.$emit('input', $event);
    },
  },
  created() {
    this.$http.get(`flights/${this.flightId}/delays/types`)
      .then((resp) => {
        this.delayType = JSUtils.cast(resp.data, DelayType);
        console.log(this.delayType); // eslint-disable-line no-console
        this.$store.commit(REQUEST_SUCCESS);
      })
      .catch((err) => {
        this.$store.commit(REQUEST_ERROR, err);
      });
  },
};
</script>

<style lang="scss">
  .v-autocomplete .v-autocomplete-list {
    /*overflow-y: auto;*/
    z-index: 1500;
  }
</style>
