<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
   <StorageTree
     :items="items"
     :title="$t('invoicing.ghs-integration.title-input')"
     :subtitle="$t('invoicing.ghs-integration.subtitle-input')"
     @click-refresh="getInputFiles"
     :loading="itemsLoading"
     :processing-button="true"/>
</template>
<script>
import { GHS_GET_INPUT_FILES } from '@/store/modules/invoicing/financialSystemsIntegration';
import StorageTree from './StorageTree.vue';

export default {
  name: 'GHSInputStorageTree',
  components: { StorageTree },
  computed: {
    items() {
      return this.$store.state.financialSystemsIntegration.inputFiles;
    },
    itemsLoading() {
      return this.$store.state.financialSystemsIntegration.inputFilesLoading;
    },
  },
  methods: {
    getInputFiles() {
      this.$store.dispatch(GHS_GET_INPUT_FILES);
    },
  },
  created() {
    this.getInputFiles();
  },
};
</script>
