import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSubheader } from 'vuetify/lib/components/VSubheader';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,[_c(VLayout,{attrs:{"row":"","wrap":""}},[_c(VFlex,{attrs:{"xs12":""}},[_c(VSubheader,[_vm._v("Global")])],1),_vm._l((_vm.globalSections),function(section,index){return [(_vm.hasPermissions(section.role))?_c(VFlex,{key:index,attrs:{"xs4":"","pa-2":""}},[_c(VCard,{key:"{{ section.componentName }}",attrs:{"dark":_vm.$vuetify.dark,"color":_vm.$vuetify.dark ? 'bggrey_dark' : 'bggrey',"to":{ name: section.componentName }}},[_c(VCardTitle,{attrs:{"primary-title":""}},[_c('div',[_c('h3',{staticClass:"headline mb-0"},[(section.icon)?_c(VAvatar,[_c(VIcon,{attrs:{"large":""}},[_vm._v(_vm._s(section.icon))])],1):_vm._e(),_vm._v(" "+_vm._s(section.readableName)+" ")],1)])])],1)],1):_vm._e()]})],2),_c(VSubheader,[_vm._v(_vm._s(_vm.$t("common.airport"))+": "+_vm._s(_vm.currentTenant.name)+" - "+_vm._s(_vm.currentTenant.description))]),_c(VLayout,{attrs:{"row":"","wrap":""}},[_vm._l((_vm.tenantSections),function(section,index){return [(_vm.hasPermissions(section.role))?_c(VFlex,{key:index,attrs:{"xs4":"","pa-2":""}},[_c(VCard,{key:"{{ section.componentName }}",attrs:{"dark":_vm.$vuetify.dark,"color":_vm.$vuetify.dark ? 'bggrey_dark' : 'bggrey',"to":{ name: section.componentName }}},[_c(VCardTitle,{attrs:{"primary-title":""}},[_c('div',[_c('h3',{staticClass:"headline mb-0"},[(section.icon)?_c(VAvatar,[_c(VIcon,{attrs:{"large":""}},[_vm._v(_vm._s(section.icon))])],1):_vm._e(),_vm._v(" "+_vm._s(section.readableName)+" ")],1)])])],1)],1):_vm._e()]})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }