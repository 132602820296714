export default class ContingencyComposition {
  constructor(contingency, information, detail, basicServ, passageState, htlTransp) {
    this.contingency = contingency;
    this.information = information;
    this.detail = detail;
    this.basicServ = basicServ;
    this.passageState = passageState;
    this.htlTransp = htlTransp;
  }

  static createEmptyContingencyComposition() {
    return new ContingencyComposition(null, null, null, null, null, null);
  }
}
