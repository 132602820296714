<template>
  <v-card-text>
    <v-container grid-list-md>
      <v-layout wrap justify-start>
        <v-flex xs12>
          <v-text-field label="Title" v-model="item.title" :rules="[v => !!v || 'Department title is required']"/>
        </v-flex>
        <v-flex xs6>
          <v-text-field label="Manager" v-model="item.subtitle"/>
        </v-flex>
        <v-flex xs6>
          <v-text-field label="Phone" v-model="item.contact.phone"/>
        </v-flex>
        <v-flex xs6>
          <v-text-field label="Mobile Phone" v-model="item.contact.phoneMobile"/>
        </v-flex>
        <v-flex xs6>
          <v-text-field label="Fax" v-model="item.contact.fax"/>
        </v-flex>
        <v-flex xs6>
          <v-text-field label="E-mail" v-model="item.contact.mail"/>
        </v-flex>
        <v-flex xs6>
          <v-text-field label="Street" v-model="item.contact.addressStreet"/>
        </v-flex>
        <v-flex xs6>
          <v-text-field label="Postal code" v-model="item.contact.addressPostalCode"/>
        </v-flex>
        <v-flex xs6>
          <v-text-field label="City" v-model="item.contact.addressCity"/>
        </v-flex>
        <v-flex xs6>
          <v-text-field label="Region" v-model="item.contact.addressRegion"/>
        </v-flex>
        <v-flex xs6>
          <v-text-field label="Country" v-model="item.contact.addressCountry"/>
        </v-flex>
        <v-flex xs12>
          <v-textarea label="Comment" hint="Hint text" v-model="item.contact.description"></v-textarea>
        </v-flex>
        <v-flex xs12>
          <directory-image-uploader @add-directory-file-image="setImage"/>
        </v-flex>
      </v-layout>
    </v-container>
  </v-card-text>
</template>

<script>
import DirectoryImageUploader from './DirectoryImageUploader.vue';

export default {
  name: 'DepartmentEditModalContent',
  props: {
    item: { type: Object, required: true },
  },
  components: {
    DirectoryImageUploader,
  },
  methods: {
    setImage(image) {
      this.item.contact.imageData = image.substr(image.indexOf('base64,') + 'base64,'.length);
    },
  },
};
</script>
