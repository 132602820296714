<template>
  <v-container>
    <v-expansion-panel v-model="resizeOnExpand">
      <v-expansion-panel-content class="exp-header">
        <template v-slot:actions>
          <v-icon color="white">mdi-menu-down</v-icon>
        </template>
        <template v-slot:header>
          <div>
            <h4 class="headline font-weight-light"><v-icon dark left>assignment</v-icon>{{$t("contingencies.basicServices")}}</h4>
          </div>
        </template>
        <ag-grid-contingency-form-template>

          <template v-slot:default>
            <v-card>
              <v-card-text class="tow-table">
                <ag-grid-vue
                  class="ag-theme-material"
                  :columnDefs="columnDefs"
                  :defaultColDef="defaultColDef"
                  @grid-ready="onGridReady"
                  :gridOptions="gridOptions"
                  :suppressHorizontalScroll="true"
                  rowSelection="multiple"
                  :frameworkComponents="frameworkComponents"
                  @selection-changed="onSelectionChanged"
                  :rowData="contingencyBasicService">
                </ag-grid-vue>
              </v-card-text>
            </v-card>
          </template>


          <template v-slot:footer  v-if="hasPermissions(rolePermission)">
            <v-btn color="secondary" flat @click="addContingencyBasicService()"><v-icon>add</v-icon></v-btn>
            <v-btn v-show="contingencyBasicServiceSelected.length > 0" color="error" flat @click="removeContingencyBasicService()"><v-icon>delete</v-icon></v-btn>
            <v-spacer></v-spacer>
          </template>
        </ag-grid-contingency-form-template>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-container>
</template>

<script>

import { AgGridVue } from 'ag-grid-vue';
import AgGridOptionsConfigurable from '../../ag-grid/mixins/AgGridOptionsConfigurable.vue';
import AgGridApiHoldable from '../../ag-grid/mixins/AgGridApiHoldable.vue';
import AgGridContingencyFormTemplate from '../common/AgGridContingencyFormTemplate.vue';
import { cellClassAgGridRules } from '../../ag-grid/mixins/cellClassAgGridRules';
import ContingencyBasicService from '../model/contingencyBasicService';
import { FLIGHT_CONTINGENCY_BASIC_SERVICE_SET_GRID_API } from '../../../store/modules/contingency/contingencyBasicService';
import AgGridDateCellEditor from '../../ag-grid/cell-editors/AgGridDateCellEditor.vue';
import AgGridTimeCellEditor from '../../ag-grid/cell-editors/AgGridTimeCellEditor.vue';
import AgGridBasicServiceTypeSelectCellEditor
from '../../ag-grid/cell-editors/AgGridBasicServiceTypeSelectCellEditor.vue';
import AgGridCurrencySelectCellEditor from '../../ag-grid/cell-editors/AgGridCurrencySelectCellEditor.vue';
import agGridTextCustomFilterComparator from '../../ag-grid/filters/agGridTextCustomFilterComparator';
import agGridTextCustomFilterFormatter from '../../ag-grid/filters/agGridTextCustomFilterFormatter';
import Saveable from '../../mixins/Saveable.vue';
import RolePermissions from '../../mixins/RolePermissions.vue';
import AgGridCurrencyCellRenderer from '../../ag-grid/cell-renderers/AgGridCurrencyCellRenderer.vue';
import AgGridCurrencyCellEditor from '../../ag-grid/cell-editors/AgGridCurrencyCellEditor.vue';
import agGridTimeFormatter from '../../ag-grid/formatters/agGridTimeFormatter';

export default {
  name: 'contingency-basic-service-form',
  mixins: [AgGridOptionsConfigurable, AgGridApiHoldable, Saveable, RolePermissions],
  components: {
    AgGridContingencyFormTemplate,
    AgGridVue,
  },
  props: {
    editedContingencyBasicService: { required: true },
  },
  computed: {
    rolePermission() {
      return `${this.$route.meta.role}_WR`;
    },
    crudOperationsDto() {
      return this.$store.state.contingencyBasicService.crudOperationsDto;
    },
  },
  data() {
    const that = this;
    const rolePermission = this.getRoles();
    return {
      resizeOnExpand: null,
      module: null,
      contingencyBasicService: this.editedContingencyBasicService,
      frameworkComponents: {
        AgGridDateCellEditor,
        AgGridBasicServiceTypeSelectCellEditor,
        AgGridCurrencySelectCellEditor,
        AgGridCurrencyCellEditor,
        AgGridTimeCellEditor,
      },
      defaultColDef: {
        resizable: true,
        filter: true,
        suppressSizeToFit: false,
        sortable: true,
        filterParams: {
          textCustomComparator: agGridTextCustomFilterComparator,
          textFormatter: agGridTextCustomFilterFormatter,
        },
        cellClassRules: cellClassAgGridRules,
        minWidth: 50,
      },
      gridOptions: {
        getRowClass: this.getRowClass,
      },
      validData: true,
      columnDefs: [
        {
          checkboxSelection: true,
          hide: !this.hasPermissions(rolePermission),
          width: 36,
          resizable: false,
          editable: this.editable(true, rolePermission),
        },
        {
          field: 'type',
          headerValueGetter() {
            return that.$i18n.t('common.type');
          },
          headerName: 'Type',
          editable: this.editable(true, rolePermission),
          cellEditor: 'AgGridBasicServiceTypeSelectCellEditor',
        },
        {
          field: 'quantity',
          headerValueGetter() {
            return that.$i18n.t('contingencies.number');
          },
          editable: this.editable(true, rolePermission),
        },
        {
          field: 'durationFormatTime',
          headerValueGetter() {
            return that.$i18n.t('contingencies.duration');
          },
          editable: this.editable(true, rolePermission),
          cellEditor: 'AgGridTimeCellEditor',
          valueFormatter: agGridTimeFormatter,
          onCellValueChanged: this.calculateDuration,
        },
        {
          field: 'provider',
          headerValueGetter() {
            return that.$i18n.t('contingencies.provider');
          },
          editable: this.editable(true, rolePermission),
        },
        {
          field: 'purchaseOrder',
          headerValueGetter() {
            return that.$i18n.t('contingencies.purchaseOrder');
          },
          width: 210,
          editable: this.editable(true, rolePermission),
        },
        {
          field: 'billingDate',
          headerValueGetter() {
            return that.$i18n.t('contingencies.billingDate');
          },
          editable: this.editable(true, rolePermission),
          cellEditor: 'AgGridDateCellEditor',
          valueFormatter(params) {
            return params.value != null ? that.$moment(params.value).format('DD/MM/YYYY') : null;
          },
        },
        {
          field: 'bill',
          headerValueGetter() {
            return that.$i18n.t('contingencies.bill');
          },
          editable: this.editable(true, rolePermission),
        },
        {
          field: 'cost',
          headerValueGetter() {
            return that.$i18n.t('contingencies.amount');
          },
          editable: this.editable(true, rolePermission),
          valueGetter(params) {
            return {
              value: params.data.cost,
              currency: params.data.currency,
            };
          },
          cellRendererFramework: AgGridCurrencyCellRenderer,
          cellEditor: 'AgGridCurrencyCellEditor',
          cellClass: 'currency-cell',
          onCellValueChanged: this.calculateTotal,
        },
        {
          field: 'vat',
          headerValueGetter() {
            return that.$i18n.t('contingencies.vat');
          },
          editable: this.editable(true, rolePermission),
          valueGetter(params) {
            return {
              value: params.data.vat,
              currency: params.data.currency,
            };
          },
          cellRendererFramework: AgGridCurrencyCellRenderer,
          cellEditor: 'AgGridCurrencyCellEditor',
          cellClass: 'currency-cell',
          onCellValueChanged: this.calculateTotal,
        },
        {
          field: 'totalPrice',
          headerValueGetter() {
            return that.$i18n.t('contingencies.total');
          },
          editable: this.editable(true, rolePermission),
          valueGetter(params) {
            return {
              value: params.data.totalPrice,
              currency: params.data.currency,
            };
          },
          cellRendererFramework: AgGridCurrencyCellRenderer,
          cellEditor: 'AgGridCurrencyCellEditor',
          cellClass: 'currency-cell',
        },
        {
          field: 'currency',
          headerValueGetter() {
            return that.$i18n.t('contingencies.currency');
          },
          editable: this.editable(true, rolePermission),
          cellEditor: 'AgGridCurrencySelectCellEditor',
          onCellValueChanged: this.checkCurrency,
        },
      ],
      contingencyBasicServiceSelected: [],
    };
  },
  methods: {
    onGridReady(params) {
      this.setAgGridApi(params);
      this.$store.commit(FLIGHT_CONTINGENCY_BASIC_SERVICE_SET_GRID_API, params); // TODO eliminar api del store
      this.refreshCellsOnChangeLanguage();
    },
    resized() {
      if (this.gridApi) {
        this.gridApi.sizeColumnsToFit();
      }
    },
    save() {
      this.gridApi.stopEditing();

      this.crudOperationsDto.updates.push(...this.contingencyBasicService);
      this.$emit('contingency-basic-service-update', this.crudOperationsDto);
    },
    onSelectionChanged(event) {
      if (event.api.getSelectedNodes().length > 0) {
        this.contingencyBasicServiceSelected = event.api.getSelectedNodes().map(n => n.data);
      } else {
        this.contingencyBasicServiceSelected = [];
      }
    },
    addContingencyBasicService() {
      const cbs = ContingencyBasicService.createContingencyBasicService(this.$route.params.contingencyId);
      if (this.contingencyBasicService.filter(basicServ => basicServ.currency != null).length > 0) {
        cbs.currency = this.contingencyBasicService.filter(basicServ => basicServ.currency != null)[0].currency;
      }
      this.contingencyBasicService.push(cbs);
    },
    removeContingencyBasicService() {
      if (this.contingencyBasicServiceSelected.length > 0) {
        this.crudOperationsDto.deletes.push(...this.contingencyBasicServiceSelected.filter(contingency => !!contingency.id));

        this.contingencyBasicServiceSelected.forEach((contingency) => {
          const index = this.contingencyBasicService.indexOf(contingency);
          this.contingencyBasicService.splice(index, 1);
        });
        this.contingencyBasicServiceSelected = [];
      }
    },
    calculateTotal(params) {
      const { data } = params;
      const total = (data.cost ? parseFloat(data.cost) : 0) + (data.vat ? parseFloat(data.vat) : 0);
      data.totalPrice = Number.isNaN(total) ? data.totalPrice : total;
      params.api.refreshCells({ force: true });
    },
    checkCurrency(params) {
    /* eslint no-param-reassign: "off" */
      this.contingencyBasicService.forEach((cbs) => {
        cbs.currency = params.newValue;
      });
      params.api.refreshCells({ force: true });
    },
    calculateDuration(params) {
      const { data } = params;
      if (data.durationFormatTime != null) {
        let duration = Number(this.$moment(data.durationFormatTime).format('H').toString()) * 60;
        duration += Number(this.$moment(data.durationFormatTime).format('m').toString());

        data.duration = duration;
      } else {
        data.duration = null;
      }
    },
  },
  watch: {
    resizeOnExpand: {
      handler() {
        if (this.expand !== null) {
          this.resized();
        }
      },
    },
    editedContingencyBasicService: {
      handler(newContingencyBasicService) {
        this.contingencyBasicService = newContingencyBasicService;
      },
      deep: true,
    },
  },
};
</script>

<style scoped lang="scss">
.exp-header {
  background: #FF4021 !important;
  color: white !important;
  border-bottom: 1px solid white;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
}
</style>
