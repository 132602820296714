import { JSUtils } from '../../js/JSUtils';

export default class FlightBlockedFieldsIntegrationInformation {
  constructor(
    id,
    manualOverwriteEstimatedTime,
    manualOverwriteParking,
    manualOverwriteBelt,
    manualOverwriteAircraft,
    manualOverwriteAircraftType,
    manualOverwriteBlockTime,
    manualOverwriteLandTime,
  ) {
    this.id = id;
    this.manualOverwriteEstimatedTime = manualOverwriteEstimatedTime;
    this.manualOverwriteParking = manualOverwriteParking;
    this.manualOverwriteBelt = manualOverwriteBelt;
    this.manualOverwriteAircraft = manualOverwriteAircraft;
    this.manualOverwriteAircraftType = manualOverwriteAircraftType;
    this.manualOverwriteBlockTime = manualOverwriteBlockTime;
    this.manualOverwriteLandTime = manualOverwriteLandTime;
  }

  static get createEmptyFlightBlockedFieldsIntegrationInformation() {
    return new FlightBlockedFieldsIntegrationInformation();
  }

  static createFlightBlockedFieldsIntegrationInformationFromData(o) {
    if (!o) {
      return this.createEmptyFlightBlockedFieldsIntegrationInformation;
    }

    return JSUtils.cast(o, FlightBlockedFieldsIntegrationInformation);
  }
}
