<template>
  <ramp-flight-list-table :items="items" :isFilterDisplayed="this.isFilterDisplayed"></ramp-flight-list-table>
</template>
<script>
import RampFlightListTable from './RampFlightsListTable.vue';
import { RAMP_FILTER_RESET, RAMP_FIND_FLIGHTS } from '../../../store/modules/ramp/ramp';

export default {
  name: 'ramp-flights-list-container',
  components: {
    RampFlightListTable,
  },
  props: {
    isFilterDisplayed: { type: Boolean, required: false, default: false },
  },
  mounted() {
    this.$store.commit(RAMP_FILTER_RESET);
    this.$store.dispatch(RAMP_FIND_FLIGHTS);
  },
  computed: {
    items() {
      if (this.$store.state.ramp.filter.onlyRemoteParkings && this.$store.getters.getRampFlightsFilterParking) {
        return this.$store.getters.getRampFlightsFilterParking.filter((f) => {
          if (f.inbound && f.inbound.common && f.inbound.common.parkingRemote) {
            return true;
          }

          if (f.outbound && f.outbound.common && f.outbound.common.parkingRemote) {
            return true;
          }

          return false;
        });
      }

      return this.$store.state.ramp.connectedFlightsRamp;
    },
  },
};
</script>
<style lang="scss">


</style>
