import { JSUtils } from '../../shared/js/JSUtils';

export default class FlightRampInformation {
  constructor(
    id,
    specialBaggage,
    crewChanged,
    loadMaster,
    firstBaggageTime,
    lastBaggageTime,
    busesEsc,
    rampServicesStatus,
    remarksStatus,
    externalAPU,
    externalAPUAircraft,
    loadPEX,
  ) {
    this.id = id;
    this.specialBaggage = specialBaggage;
    this.crewChanged = crewChanged;
    this.loadMaster = loadMaster;
    this.lastBaggageTime = lastBaggageTime;
    this.busesEsc = busesEsc;
    this.rampServicesStatus = rampServicesStatus;
    this.remarksStatus = remarksStatus;
    this.externalAPU = externalAPU;
    this.externalAPUAircraft = externalAPUAircraft;
    this.loadPEX = loadPEX;
  }

  static get createEmptyFlightRampInformation() {
    return new FlightRampInformation();
  }

  static createFlightRampInformationFromData(o) {
    if (!o) {
      return this.createEmptyFlightRampInformation;
    }

    return JSUtils.cast(o, FlightRampInformation);
  }
}
