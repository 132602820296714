<template>
  <div>
    <v-tooltip bottom>
      <v-switch
        slot="activator"
        color="white"
        :label="label"
        v-model="filter.onlyUnarchived"
        @change="change">
      </v-switch>
      <span>{{ tooltip }}</span>
    </v-tooltip>
  </div>
</template>
<script>

import { OPERATION_FIND_FLIGHTS } from '../../../store/modules/operation';

export default {
  name: 'operation-flights-list-filter-archived',
  computed: {
    filter() {
      return this.$store.state.operation.filter;
    },
    label() {
      if (this.filter.onlyUnarchived) {
        return this.$i18n.t('commonFilter.onlyUnarchived');
      }
      return this.$i18n.t('commonFilter.archivedAndUnarchived');
    },
    tooltip() {
      if (this.filter.onlyUnarchived) {
        return this.$i18n.t('commonFilter.onlyUnarchivedTooltip');
      }
      return this.$i18n.t('commonFilter.archivedAndUnarchivedTooltip');
    },
  },
  methods: {
    change() {
      this.$store.dispatch(OPERATION_FIND_FLIGHTS);
    },
  },
};
</script>

<style>
  /* TODO esta aplicando a este componente y al switch de desk por lo menos */
  .v-input--selection-controls .v-input__slot {
    margin-top: 5px;
    margin-bottom: 0 !important;
  }
  .theme--dark.v-label {
    color: white;
  }
</style>
