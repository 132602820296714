/* eslint no-param-reassign: "off" */

import { HTTP } from '../../../http';
import { REQUEST_ERROR } from '../../store';
import packageJson from '../../../../package.json';


// actions names
export const GET_VERSION = 'GET_VERSION';
export const GET_CURRENT_VERSION = 'GET_CURRENT_VERSION';

export const GET_VERSION_SUCCESS = 'GET_VERSION_SUCCESS';
export const GET_CURRENT_VERSION_SUCCESS = 'GET_CURRENT_VERSION_SUCCESS';

// initial state
const storeState = {

  version: null,
  currentVersion: null,
};

// getters
const storeGetters = {
  getVersion: state => state.version,
  getCurrentVersion: state => state.currentVersion,
};

// actions
const storeActions = {
  [GET_VERSION]: ({ commit }) => HTTP.get('acciona-flights/versions/front')
    .then((resp) => {
      commit(GET_VERSION_SUCCESS, resp.data);
    })
    .catch((err) => {
      commit(REQUEST_ERROR, err);
    }),
  [GET_CURRENT_VERSION]: ({ commit }) => new Promise((resolve) => {
    commit(GET_CURRENT_VERSION_SUCCESS, packageJson.version);
    resolve();
  }),
};
// mutations
const storeMutations = {
  [GET_VERSION_SUCCESS]: (state, version) => {
    state.version = version;
  },
  [GET_CURRENT_VERSION_SUCCESS]: (state, version) => {
    state.currentVersion = version;
  },
};

export default {
  state: storeState,
  getters: storeGetters,
  actions: storeActions,
  mutations: storeMutations,
};
