<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <v-autocomplete
      name="user"
      id="user"
      :value="value"
      :items="items"
      :label="label"
      item-value="user"
      :rules="rules"
      @input="emitInput"
      content-class="avinetUserSelector"
    >
      <template v-slot:selection="data">{{ data.item.user }}</template>
      <template v-slot:item="data">
        <v-list-tile-content>
          <v-list-tile-title>{{ data.item.user }}</v-list-tile-title>
        </v-list-tile-content>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>

import { GET_AVINET_USERS } from '../../store/modules/avinetUser';

export default {
  name: 'AvinetUserSelector',
  props: {
    value: { },
    label: { type: String },
    rules: { type: Array },
    disabled: { type: Boolean },
    refComp: {
      type: String,
      required: false,
      default: 'refComp',
    },
  },
  data: () => ({
    select: [],
  }),
  computed: {
    items() {
      return this.$store.state.avinetUser.avinetUsers;
    },
  },
  methods: {
    getAvinetUsers() {
      return this.$store.dispatch(GET_AVINET_USERS);
    },
    emitInput(event) {
      if (typeof (event) !== 'undefined') {
        this.$emit('input', event);
      } else {
        this.$emit('input', null);
      }
    },
  },
  created() {
    this.getAvinetUsers();
  },
};
</script>
