<template>
  <ag-grid-template-cell-renderer :params="params">
    <v-tooltip top>
      <template slot="activator">
        <span>{{params.value.value}}</span>
      </template>
      <span>{{params.value.tooltip}}</span>
    </v-tooltip>
  </ag-grid-template-cell-renderer>
</template>

<script>
import Vue from 'vue';
import AgGridTemplateCellRenderer from './templates/AgGridTemplateCellRenderer.vue';

export default Vue.extend({
  components: {
    AgGridTemplateCellRenderer,
  },
  computed: {

  },
});
</script>

<style scoped>

</style>
