<template>
  <div>
    <GanttFlightsToolbar @changeShowFilter="changeShowFilter" />
    <GanttFlights :isFilterDisplayed="filterDisplayed" />
  </div>
</template>
<script>
import GanttFlights from '../components/gantt/GanttFlights.vue';
import GanttFlightsToolbar from '../components/gantt/GanttFlightsToolbar.vue';

export default {
  name: 'RampFlightsGantt',
  components: {
    GanttFlightsToolbar,
    GanttFlights,
  },
  computed: {
    ganttOptions() {
      return this.$store.state.rampGantt.ganttOptions;
    },
  },
  data() {
    return {
      filterDisplayed: true,
    };
  },
  methods: {
    changeShowFilter(dispayled) {
      this.filterDisplayed = dispayled;
    },
  },
};
</script>

<style>
</style>
