<template>
  <div>
    <v-flex v-flex-none>
      <time-input :ref="refTimeInput" v-model="time" :referenceDate="referenceDate"></time-input>
    </v-flex>
  </div>
</template>

<script>
import Vue from 'vue';
import AgGridParentConnectedFlightsCellEditor from '../mixins/AgGridParentConnectedFlightsCellEditor.vue';
import TimeInput from '../../../ui/TimeInput.vue';
import { JSUtils } from '../../../shared/js/JSUtils';
import { NOTIFICATION_SHOW } from '../../../../store/modules/notifications';
import AgGridScheduledTimeCellEditorMixin from '../mixins/AgGridScheduledTimeCellEditorMixin.vue';

export default Vue.extend({
  mixins: [AgGridParentConnectedFlightsCellEditor, AgGridScheduledTimeCellEditorMixin],
  components: {
    TimeInput,
  },
  props: {
    refTimeInput: {
      type: String,
      required: false,
      default: 'refTimeInput',
    },
  },
  data() {
    return {
      time: '',
      flight: {},
    };
  },
  computed: {
    referenceDate() {
      if (this.params.value && this.params.value.referenceDate) {
        return this.params.value.referenceDate;
      }
      return null;
    },
    minTime() {
      if (this.params.value && this.params.value.minTime) {
        return this.params.value.minTime;
      }
      return null;
    },
    maxTime() {
      if (this.params.value && this.params.value.maxTime) {
        return this.params.value.maxTime;
      }
      return null;
    },
  },
  methods: {
    getValue() {
      this.unmarkCellsSelectedAsEdit();

      if (this.minTime && this.time && this.$moment(this.time).diff(this.$moment(this.minTime)) < 0) {
        this.$store.commit(NOTIFICATION_SHOW, {
          message: this.$i18n.t('validation.fieldAfterThan') + this.$moment(this.minTime).format('HH:mm'),
          color: 'error',
        });
        return null;
      }
      if (this.maxTime && this.time && this.$moment(this.time).diff(this.$moment(this.maxTime)) > 0) {
        this.$store.commit(NOTIFICATION_SHOW, {
          message: this.$i18n.t('validation.fieldBeforeThan') + this.$moment(this.maxTime).format('HH:mm'),
          color: 'error',
        });
        return null;
      }
      if (this.params.value.required && !this.time) {
        this.$store.commit(NOTIFICATION_SHOW, {
          message: this.$i18n.t('validation.fieldRequired'),
          color: 'error',
        });
        return null;
      }

      const that = this;
      const connectedFlightPropertyName = this.getConnectedFlightsPropertyName();
      const scheduledTime = that.time ? this.$moment(this.time) : null;

      if (scheduledTime && connectedFlightPropertyName === this.scheduledTimePropertyName) {
        this.setNewScheduledTime(scheduledTime);
        return this.time;
      }

      this.saveFlightChangesFromValue((s) => {
        s.setChildPropertyValueByBound(that.params.column.colDef.colCustomConfig.flightBound, connectedFlightPropertyName, scheduledTime);
      }, JSUtils.decamelizeString(connectedFlightPropertyName, ' '), this.params.value.value, scheduledTime);

      return this.time;
    },
  },
  created() {
    const currentFlight = this.params.node.data;
    this.flight = currentFlight.getBound(this.params.column.colDef.colCustomConfig.flightBound);
    if (this.params.value && this.params.value.value) {
      this.time = this.params.value.value;
    } else {
      this.time = '';
    }
    this.markCellsSelectedAsEdit();
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$refs.refTimeInput) {
        this.$refs.refTimeInput.$refs['time-input-ref'].focus();
      }
    });
  },
});
</script>

<style>

</style>
