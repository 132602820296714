/* eslint no-param-reassign: "off" */

import { HTTP } from '../../../http';
import { REQUEST_ERROR, REQUEST_START, REQUEST_SUCCESS } from '../../store';
import { NOTIFICATION_SHOW } from '../notifications';
import { AUTH_CHECK } from '../auth';
import i18n from '../../../plugins/i18n';

// actions names
export const GET_SERVICE_AGENTS = 'GET_SERVICE_AGENTS';
export const GET_AIRPORT_SERVICE_AGENTS_CANDIDATES = 'GET_AIRPORT_SERVICE_AGENTS_CANDIDATES';
export const SERVICE_AGENT_CREATE = 'SERVICE_AGENT_CREATE';
export const SERVICE_AGENT_UPDATE = 'SERVICE_AGENT_UPDATE';
export const SERVICE_AGENT_DELETE = 'SERVICE_AGENT_DELETE';
export const SERVICE_AGENT_DISABLE = 'SERVICE_AGENT_DISABLE';
export const SERVICE_AGENT_ENABLE = 'SERVICE_AGENT_ENABLE';


// mutations
export const GET_SERVICE_AGENTS_SUCCESS = 'GET_SERVICE_AGENTS_SUCCESS';
export const GET_SERVICE_AGENTS_REQUESTED = 'GET_SERVICE_AGENTS_REQUESTED';
export const GET_AIRPORT_SERVICE_AGENTS_CANDIDATES_SUCCESS = 'GET_AIRPORT_SERVICE_AGENTS_CANDIDATES_SUCCESS';
export const UPDATE_SERVICE_AGENTS_SUCCESS = 'UPDATE_SERVICE_AGENTS_SUCCESS';

// initial state
const storeState = {
  serviceAgents: [],
  airportServiceAgentCandidates: [],
  serviceAgentsRequested: false,
};

// getters
const storeGetters = {
  getServiceAgents: state => state.serviceAgents,
  getActiveServiceAgents: state => state.serviceAgents.filter(elem => !elem.deleted),
  getAirportServiceAgentCandidates: state => state.airportServiceAgentCandidates.filter(elem => !elem.deleted),
};

// actions
const storeActions = {
  [GET_SERVICE_AGENTS]: ({ commit, state }) => {
    if (state.serviceAgentsRequested) {
      return;
    }
    commit(REQUEST_START);
    commit(GET_SERVICE_AGENTS_REQUESTED);
    HTTP.get('/service-agent')
      .then((resp) => {
        commit(REQUEST_SUCCESS);
        commit(GET_SERVICE_AGENTS_SUCCESS, resp.data);
      })
      .catch((err) => {
        commit(REQUEST_ERROR, err);
      });
  },
  [GET_AIRPORT_SERVICE_AGENTS_CANDIDATES]: ({ commit }, id) => {
    if (id === undefined || id === null) {
      id = 0;
    }
    commit(REQUEST_START);
    HTTP.get(`/service-agent/candidates-to-airport-service-agent/${id}`)
      .then((resp) => {
        commit(REQUEST_SUCCESS);
        commit(GET_AIRPORT_SERVICE_AGENTS_CANDIDATES_SUCCESS, resp.data);
      })
      .catch((err) => {
        commit(REQUEST_ERROR, err);
      });
  },
  [SERVICE_AGENT_CREATE]: ({ commit, dispatch }, serviceAgent) => {
    commit(REQUEST_START);
    return HTTP.post('service-agent', serviceAgent)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(UPDATE_SERVICE_AGENTS_SUCCESS);
        dispatch(GET_SERVICE_AGENTS);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.serviceAgentCreate'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
  [SERVICE_AGENT_UPDATE]: ({ commit, dispatch }, serviceAgent) => {
    commit(REQUEST_START);
    return HTTP.put('service-agent', serviceAgent)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(UPDATE_SERVICE_AGENTS_SUCCESS);
        dispatch(GET_SERVICE_AGENTS);
        dispatch(AUTH_CHECK);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.serviceAgentUpdate'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
  [SERVICE_AGENT_DELETE]: ({ commit, dispatch }, id) => {
    commit(REQUEST_START);
    return HTTP.delete(`service-agent/${id}`)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(UPDATE_SERVICE_AGENTS_SUCCESS);
        dispatch(GET_SERVICE_AGENTS);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.serviceAgentDelete'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
  [SERVICE_AGENT_DISABLE]: ({ commit, dispatch }, id) => {
    commit(REQUEST_START);
    return HTTP.patch(`/service-agent/disable/${id}`)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(UPDATE_SERVICE_AGENTS_SUCCESS);
        dispatch(GET_SERVICE_AGENTS);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.serviceAgentDisable'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
  [SERVICE_AGENT_ENABLE]: ({ commit, dispatch }, id) => {
    commit(REQUEST_START);
    return HTTP.patch(`/service-agent/enable/${id}`)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(UPDATE_SERVICE_AGENTS_SUCCESS);
        dispatch(GET_SERVICE_AGENTS);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.serviceAgentEnable'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
};

// mutations
const storeMutations = {
  [GET_SERVICE_AGENTS_SUCCESS]: (state, serviceAgents) => {
    state.serviceAgents = serviceAgents;
  },
  [GET_SERVICE_AGENTS_REQUESTED]: (state) => {
    state.serviceAgentsRequested = true;
  },
  [GET_AIRPORT_SERVICE_AGENTS_CANDIDATES_SUCCESS]: (state, airportServiceAgentCandidates) => {
    state.airportServiceAgentCandidates = airportServiceAgentCandidates;
  },
  [UPDATE_SERVICE_AGENTS_SUCCESS]: (state) => {
    state.serviceAgentsRequested = false;
  },
};

export default {
  state: storeState,
  getters: storeGetters,
  actions: storeActions,
  mutations: storeMutations,
};
