<template>
  <div>
    <v-flex v-flex-none>
      <v-text-field :ref="refStringInput" v-model="updatedString"></v-text-field>
    </v-flex>
  </div>
</template>

<script>
import Vue from 'vue';
import AgGridParentRushBaggageCellEditor from '../mixins/AgGridParentRushBaggageCellEditor.vue';

export default Vue.extend({
  mixins: [AgGridParentRushBaggageCellEditor],
  components: {
  },
  props: {
    refStringInput: {
      type: String,
      required: false,
      default: 'refStringInput',
    },
  },
  data() {
    return {
      originalString: '',
      updatedString: '',
    };
  },
  methods: {
    getValue() {
      this.unmarkCellsSelectedAsEdit();
      if (this.originalString !== this.updatedString) {
        const that = this;
        const rushBaggagePropertyName = this.getRushBaggagePropertyName();
        this.saveForValueOrAllSelectedRushesBaggage((s) => {
          s.setChildPropertyValue(rushBaggagePropertyName, that.updatedString);
        }, rushBaggagePropertyName);
      }
      return this.updatedString;
    },
  },
  created() {
    this.originalString = this.params.value ? this.params.value : '';
    this.updatedString = this.originalString;

    this.markCellsSelectedAsEdit();
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$refs.refStringInput) {
        this.$refs.refStringInput.$refs.input.focus();
      }
    });
  },
});
</script>

<style>

</style>
