<template>
  <v-card>
    <v-layout row align-center>
      <v-flex xs6>
        <v-card-title primary-title>
          <div>
            <div class="headline">{{$t("administration.informBulkTitle")}}</div>
            <div class="grey--text text--darken-1" v-text="informBulkSubTitle"></div>
            <div class="grey--text text--darken-1">{{$t("administration.informBulkText")}}</div>
          </div>
        </v-card-title>
      </v-flex>

      <v-flex xs6>
        <v-dialog v-model="dialog" max-width="50%" v-if="hasPermissions(rolePermission)">
          <v-btn slot="activator" color="primary">{{$t("administration.informBulk")}}</v-btn>
          <v-card :dark="this.$vuetify.dark" :color="this.$vuetify.dark ? 'bggrey_dark' : 'bggrey'">
            <v-toolbar dark color="primary">
              <v-icon>dns</v-icon>
              <v-toolbar-title>{{$t("administration.informBulkConfirmation")}}</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              {{$t("administration.continueAlert")}}
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn flat color="primary" @click.native="cancel">{{$t("common.cancel")}}</v-btn>
              <v-btn color="primary" depressed @click.native="confirm">{{$t("common.confirm")}}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-btn v-else color="primary" disabled>{{$t("administration.informBulk")}}</v-btn>
      </v-flex>
    </v-layout>

    <v-divider light></v-divider>

    <v-layout row align-center>
      <v-flex xs6>
        <v-card-title primary-title>
          <div>
            <div class="headline">{{$t("administration.informBulkAircraftGroundTitle")}}</div>
            <div class="grey--text text--darken-1">{{$t("administration.informBulkAircraftGroundSubTitle")}}</div>
            <div class="grey--text text--darken-1">{{$t("administration.informBulkAircraftGroundText")}}</div>
          </div>
        </v-card-title>
      </v-flex>

      <v-flex xs6>
        <v-dialog v-model="dialogAircraftGround" max-width="50%" v-if="hasPermissions(rolePermission)">
          <v-btn slot="activator" color="primary">{{$t("administration.informBulk")}}</v-btn>
          <v-card :dark="this.$vuetify.dark" :color="this.$vuetify.dark ? 'bggrey_dark' : 'bggrey'">
            <v-toolbar dark color="primary">
              <v-icon>dns</v-icon>
              <v-toolbar-title>{{$t("administration.informBulkConfirmation")}}</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              {{$t("administration.continueAlert")}}
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn flat color="primary" @click.native="cancel">{{$t("common.cancel")}}</v-btn>
              <v-btn color="primary" depressed @click.native="confirmAircraftGround">{{$t("common.confirm")}}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-btn v-else color="primary" disabled>{{$t("administration.informBulk")}}</v-btn>
      </v-flex>
    </v-layout>

    <v-divider light></v-divider>

    <AdminResult :is-error="isError" :is-success="isSuccess" :loading="loading" :time="time" :traces="traces"/>
  </v-card>
</template>
<script>
import { ADMIN_INFORM_BULK, ADMIN_AIRCRAFT_GROUND_INFORM_BULK } from '../../../store/modules/admin/inform';
import AdminResult from '../AdminResult.vue';
import RolePermissions from '../../mixins/RolePermissions.vue';

export default {
  name: 'InformBulk',
  mixins: [RolePermissions],
  components: { AdminResult },
  data() {
    return {
      dialog: false,
      dialogAircraftGround: false,
    };
  },
  computed: {
    loading() {
      return !!this.$store.state.admin.inform.bulkLoading;
    },
    time() {
      return this.$store.state.admin.inform.bulkLastRequestTime ? this.$moment(this.$store.state.admin.inform.bulkLastRequestTime).format('DD/MM/YYYY HH:mm') : null;
    },
    isSuccess() {
      return this.$store.getters.isInformBulkSuccess;
    },
    isError() {
      return this.$store.getters.isInformBulkError;
    },
    traces() {
      return this.$store.state.admin.inform.bulkResult;
    },
    rolePermission() {
      return `${this.$route.meta.role}_WR`;
    },
    informBulkSubTitle() {
      const currentTentant = this.$store.state.tenant.currentTenant.name;
      if (currentTentant === 'PMI') {
        return this.$i18n.t('administration.pmiInformBulkSubTitle');
      } if (currentTentant === 'DUS') {
        return this.$i18n.t('administration.dusInformBulkSubTitle');
      } if (currentTentant === 'SCL') {
        return this.$i18n.t('administration.sclInformBulkSubTitle');
      }

      return this.$t('administration.informBulkText');
    },
  },
  methods: {
    cancel() {
      this.dialog = false;
      this.dialogAircraftGround = false;
    },
    confirm() {
      this.$store.dispatch(ADMIN_INFORM_BULK);
      this.dialog = false;
    },
    confirmAircraftGround() {
      this.$store.dispatch(ADMIN_AIRCRAFT_GROUND_INFORM_BULK);
      this.dialogAircraftGround = false;
    },
  },
};
</script>
