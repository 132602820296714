import DaysOfWeekModel from './daysOfWeek';
import FlightCommonInformation from './flightCommonInformation';

export default class LongTermSchedulingFlight {
  constructor(
    initDate,
    finishDate,
    scheduledOnMondays,
    scheduledOnTuesdays,
    scheduledOnWednesdays,
    scheduledOnThursdays,
    scheduledOnFridays,
    scheduledOnSaturdays,
    scheduledOnSundays,
    frequency,

    inboundTripNumber,
    inboundScheduledTime,
    inboundPositional,
    inboundComments,
    inboundAircraftId,
    inboundAircraftRegisterNumber,
    inboundAircraftTypeId,
    inboundAircraftTypeCode,
    inboundAircraftConfigId,
    inboundAircraftConfigCode,
    inboundAirlineId,
    inboundAirlineCode,
    inboundAirlineOperationId,
    inboundAirlineOperationCode,
    inboundAirport1Id,
    inboundAirport1IataCode,
    inboundAirport1IcaoCode,
    inboundAirport1Code,
    inboundAirport2Id,
    inboundAirport2IataCode,
    inboundAirport2IcaoCode,
    inboundAirport2Code,
    inboundAirport3Id,
    inboundAirport3IataCode,
    inboundAirport3IcaoCode,
    inboundAirport3Code,
    inboundHandlingTypeId,
    inboundHandlingTypeCode,

    outboundTripNumber,
    outboundScheduledTime,
    outboundPositional,
    outboundComments,
    outboundAircraftId,
    outboundAircraftRegisterNumber,
    outboundAircraftTypeId,
    outboundAircraftTypeCode,
    outboundAircraftConfigId,
    outboundAircraftConfigCode,
    outboundAirlineId,
    outboundAirlineCode,
    outboundAirlineOperationId,
    outboundAirlineOperationCode,
    outboundAirport1Id,
    outboundAirport1IataCode,
    outboundAirport1IcaoCode,
    outboundAirport1Code,
    outboundAirport2Id,
    outboundAirport2IataCode,
    outboundAirport2IcaoCode,
    outboundAirport2Code,
    outboundAirport3Id,
    outboundAirport3IataCode,
    outboundAirport3IcaoCode,
    outboundAirport3Code,
    outboundHandlingTypeId,
    outboundHandlingTypeCode,
  ) {
    this.initDate = initDate;
    this.finishDate = finishDate;
    this.scheduledOnMondays = scheduledOnMondays;
    this.scheduledOnTuesdays = scheduledOnTuesdays;
    this.scheduledOnWednesdays = scheduledOnWednesdays;
    this.scheduledOnThursdays = scheduledOnThursdays;
    this.scheduledOnFridays = scheduledOnFridays;
    this.scheduledOnSaturdays = scheduledOnSaturdays;
    this.scheduledOnSundays = scheduledOnSundays;
    this.frequency = frequency;

    this.inboundTripNumber = inboundTripNumber;
    this.inboundScheduledTime = inboundScheduledTime;
    this.inboundPositional = inboundPositional;
    this.inboundComments = inboundComments;
    this.inboundAircraftId = inboundAircraftId;
    this.inboundAircraftRegisterNumber = inboundAircraftRegisterNumber;
    this.inboundAircraftTypeId = inboundAircraftTypeId;
    this.inboundAircraftTypeCode = inboundAircraftTypeCode;
    this.inboundAircraftConfigId = inboundAircraftConfigId;
    this.inboundAircraftConfigCode = inboundAircraftConfigCode;
    this.inboundAirlineId = inboundAirlineId;
    this.inboundAirlineCode = inboundAirlineCode;
    this.inboundAirlineOperationId = inboundAirlineOperationId;
    this.inboundAirlineOperationCode = inboundAirlineOperationCode;
    this.inboundAirport1Id = inboundAirport1Id;
    this.inboundAirport1IataCode = inboundAirport1IataCode;
    this.inboundAirport1IcaoCode = inboundAirport1IcaoCode;
    this.inboundAirport1IataCode = inboundAirport1Code;
    this.inboundAirport2Id = inboundAirport2Id;
    this.inboundAirport2IataCode = inboundAirport2IataCode;
    this.inboundAirport2IcaoCode = inboundAirport2IcaoCode;
    this.inboundAirport2Code = inboundAirport2Code;
    this.inboundAirport3Id = inboundAirport3Id;
    this.inboundAirport3IataCode = inboundAirport3IataCode;
    this.inboundAirport3IcaoCode = inboundAirport3IcaoCode;
    this.inboundAirport3Code = inboundAirport3Code;
    this.inboundHandlingTypeId = inboundHandlingTypeId;
    this.inboundHandlingTypeCode = inboundHandlingTypeCode;

    this.outboundTripNumber = outboundTripNumber;
    this.outboundScheduledTime = outboundScheduledTime;
    this.outboundPositional = outboundPositional;
    this.outboundComments = outboundComments;
    this.outboundAircraftId = outboundAircraftId;
    this.outboundAircraftRegisterNumber = outboundAircraftRegisterNumber;
    this.outboundAircraftTypeId = outboundAircraftTypeId;
    this.outboundAircraftTypeCode = outboundAircraftTypeCode;
    this.outboundAircraftConfigId = outboundAircraftConfigId;
    this.outboundAircraftConfigCode = outboundAircraftConfigCode;
    this.outboundAirlineId = outboundAirlineId;
    this.outboundAirlineCode = outboundAirlineCode;
    this.outboundAirlineOperationId = outboundAirlineOperationId;
    this.outboundAirlineOperationCode = outboundAirlineOperationCode;
    this.outboundAirport1Id = outboundAirport1Id;
    this.outboundAirport1IataCode = outboundAirport1IataCode;
    this.outboundAirport1IcaoCode = outboundAirport1IcaoCode;
    this.outboundAirport1Code = outboundAirport1Code;
    this.outboundAirport2Id = outboundAirport2Id;
    this.outboundAirport2IataCode = outboundAirport2IataCode;
    this.outboundAirport2IcaoCode = outboundAirport2IcaoCode;
    this.outboundAirport2Code = outboundAirport2Code;
    this.outboundAirport3Id = outboundAirport3Id;
    this.outboundAirport3IataCode = outboundAirport3IataCode;
    this.outboundAirport3IcaoCode = outboundAirport3IcaoCode;
    this.outboundAirport3Code = outboundAirport3Code;
    this.outboundHandlingTypeId = outboundHandlingTypeId;
    this.outboundHandlingTypeCode = outboundHandlingTypeCode;
  }


  static get createEmtpyLongTermSchedulingFlight() {
    const lontTermSchedulingFlight = new LongTermSchedulingFlight(null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null);

    lontTermSchedulingFlight.addInboundFlight(FlightCommonInformation.createEmptyInboundFlight);
    lontTermSchedulingFlight.addOutboundFlight(FlightCommonInformation.createEmptyOutBoundFlight);

    return lontTermSchedulingFlight;
  }

  setDaysOfWeek(daysOfWeek) {
    if (!daysOfWeek || !(daysOfWeek instanceof DaysOfWeekModel)) {
      throw new Error('Parameter daysOfWeek is not valid DaysOfWeekModel instance');
    }

    this.scheduledOnMondays = daysOfWeek.monday;
    this.scheduledOnTuesdays = daysOfWeek.tuesday;
    this.scheduledOnWednesdays = daysOfWeek.wednesday;
    this.scheduledOnThursdays = daysOfWeek.thursday;
    this.scheduledOnFridays = daysOfWeek.friday;
    this.scheduledOnSaturdays = daysOfWeek.saturday;
    this.scheduledOnSundays = daysOfWeek.sunday;
  }

  addInboundFlight(flight) {
    if (!flight || !(flight instanceof FlightCommonInformation)) {
      throw new Error('Parameter flight is not valid Flight instance');
    }

    this.inboundTripNumber = flight.tripNumber;
    this.inboundScheduledTime = flight.scheduledTime;
    this.inboundPositional = flight.posicional;
    this.inboundComments = flight.comments;
    this.inboundAircraftId = flight.aircraftId;
    this.inboundAircraftRegisterNumber = flight.aircraftRegisterNumber;
    this.inboundAircraftTypeId = flight.aircraftTypeId;
    this.inboundAircraftTypeIataCode = flight.aircraftTypeIataCode;
    this.inboundAircraftConfigId = flight.aircraftConfigId;
    this.inboundAircraftConfigCode = flight.aircraftConfigCode;
    this.inboundAirlineId = flight.airlineId;
    this.inboundAirlineIataCode = flight.airlineIataCode;
    this.inboundAirlineOperationId = flight.airlineOperationId;
    this.inboundAirlineOperationIataCode = flight.airlineOperationIataCode;
    this.inboundAirport1Id = flight.airport1Id;
    this.inboundAirport1IataCode = flight.airport1IataCode;
    this.inboundAirport2Id = flight.airport2Id;
    this.inboundAirport2IataCode = flight.airport2IataCode;
    this.inboundAirport3Id = flight.airport3Id;
    this.inboundAirport3IataCode = flight.airport3IataCode;
    this.inboundHandlingTypeId = flight.handlingTypeId;
    this.inboundHandlingTypeCode = flight.handlingTypeCode;
  }

  addOutboundFlight(flight) {
    if (!flight || !(flight instanceof FlightCommonInformation)) {
      throw new Error('Parameter flight is not valid Flight instance');
    }

    this.outboundTripNumber = flight.tripNumber;
    this.outboundScheduledTime = flight.scheduledTime;
    this.outboundPositional = flight.posicional;
    this.outboundComments = flight.comments;
    this.outboundAircraftId = flight.aircraftId;
    this.outboundAircraftRegisterNumber = flight.aircraftRegisterNumber;
    this.outboundAircraftTypeId = flight.aircraftTypeId;
    this.outboundAircraftTypeIataCode = flight.aircraftTypeIataCode;
    this.outboundAircraftConfigId = flight.aircraftConfigId;
    this.outboundAircraftConfigCode = flight.aircraftConfigCode;
    this.outboundAirlineId = flight.airlineId;
    this.outboundAirlineIataCode = flight.airlineIataCode;
    this.outboundAirlineOperationId = flight.airlineOperationId;
    this.outboundAirlineOperationIataCode = flight.airlineOperationIataCode;
    this.outboundAirport1Id = flight.airport1Id;
    this.outboundAirport1IataCode = flight.airport1IataCode;
    this.outboundAirport2Id = flight.airport2Id;
    this.outboundAirport2IataCode = flight.airport2IataCode;
    this.outboundAirport3Id = flight.airport3Id;
    this.outboundAirport3IataCode = flight.airport3IataCode;
    this.outboundHandlingTypeId = flight.handlingTypeId;
    this.outboundHandlingTypeCode = flight.handlingTypeCode;
  }
}
