<template>
    <div class="pagination-bar-root">
      <div v-if="paginationProperties.totalResults > 0 && !paginationProperties.last" class="pagination-info">
      {{ `${$t("pagination.page-info.showing")} ${paginationProperties.offset} ${$t("pagination.page-info.to")} ${paginationProperties.currentPageNumberOfResults} ${$t("pagination.page-info.of")} ${paginationProperties.totalResults}` }}
      </div>
      <div v-else-if="paginationProperties.totalResults > 0 && paginationProperties.last" class="pagination-info">
      {{ `${$t("pagination.page-info.showing")} ${paginationProperties.offset} ${$t("pagination.page-info.of")} ${paginationProperties.totalResults}` }}
      </div>
      <v-btn :dark="this.$vuetify.dark"
        v-if="paginationProperties.totalResults > 0 && !paginationProperties.first"
        class="pagination-button"
        @click="goPrevPage()"
        :disabled="isLoading">
        <v-icon> {{ 'keyboard_arrow_left' }} </v-icon>
      </v-btn>
      <div v-for="(pageNumber, index) in numbersOfPages" :key="index">
        <a v-if="pageNumber === paginationProperties.currentPage" class="page-link active" href="#"
          @click="goToPage(pageNumber)">
          {{ pageNumber }}
        </a>
        <a v-else class="page-link" href="#" @click="goToPage(pageNumber)">
          {{ pageNumber }}
        </a>
      </div>

      <v-btn :dark="this.$vuetify.dark"
        v-if="paginationProperties.totalResults > 0 && !paginationProperties.last"
        class="pagination-button"
        @click="goNextPage()"
        :disabled="isLoading">
        <v-icon> {{ 'keyboard_arrow_right' }} </v-icon>
      </v-btn>
      <div class="pagination-settings-container">
        <v-btn class="pagination-button" :dark="this.$vuetify.dark"
          @click="changeShowPaginationSettingsModal"
          :disabled="isLoading">
          <v-icon> {{ 'settings' }} </v-icon>
        </v-btn>
        <pagination-settings class="pagination-settings-menu" v-show="showPaginationSettingsModal"
          :originalPageSize="paginationProperties.pageSize" @onChangePageSize="changePageSize" />
      </div>
  </div>
</template>
<script>
import PaginationSettings from './PaginationSettings.vue';
import './PaginationBar.scss';
import AgGridApiHoldable from '../../ag-grid/mixins/AgGridApiHoldable.vue';

export default {
  name: 'pagination-bar',
  mixins: [AgGridApiHoldable],
  props: {
    paginationProperties: { type: Object, required: true }, // Type: PaginationResponseProperties
    mutationNameToSetAgGridGridApi: { type: String, required: false, default: undefined },
  },
  components: {
    PaginationSettings,
  },
  data() {
    return {
      showPaginationSettingsModal: false,
      maxVisibleNumbersOfPages: 5,
    };
  },
  computed: {
    minVisiblePageNumber() {
      return (this.paginationProperties.currentPage - 1 > 1)
        ? this.paginationProperties.currentPage - 1
        : 1;
    },
    maxVisiblePageNumber() {
      return (this.paginationProperties.currentPage + 2 < this.paginationProperties.totalPages)
        ? this.paginationProperties.currentPage + 2
        : this.paginationProperties.totalPages;
    },
    numbersOfPages() {
      const { totalPages } = this.paginationProperties;
      const { currentPage } = this.paginationProperties;
      const numbersOfPages = Array(totalPages).fill().map((_, index) => index + 1);
      if (numbersOfPages.length > this.maxVisibleNumbersOfPages) {
        const previousVisiblePages = this.getPreviousVisiblePages(numbersOfPages, currentPage);
        const postVisiblePages = this.getPostVisiblePages(numbersOfPages, currentPage);
        previousVisiblePages.push(this.paginationProperties.currentPage);
        return previousVisiblePages.concat(postVisiblePages);
      }
      return numbersOfPages;
    },
    isLoading() {
      return this.$store.state.isLoading;
    },
  },
  methods: {
    getPreviousVisiblePages(numbersOfPages, currentPage) {
      const firstPageNumber = numbersOfPages[0];
      const secondPageNumber = numbersOfPages[1];

      const previousVisibleNumbersOfPages = numbersOfPages.filter(pageNumber => pageNumber >= this.minVisiblePageNumber && pageNumber < currentPage)
        .sort((a, b) => a - b);

      const firstVisiblePageNumber = previousVisibleNumbersOfPages[0];

      if (firstVisiblePageNumber > secondPageNumber) {
        previousVisibleNumbersOfPages.unshift('..');
        previousVisibleNumbersOfPages.unshift(firstPageNumber);
      } else if (firstVisiblePageNumber === secondPageNumber) {
        previousVisibleNumbersOfPages.unshift(firstPageNumber);
      }

      return previousVisibleNumbersOfPages;
    },
    getPostVisiblePages(numbersOfPages, currentPage) {
      const lastPageNumber = numbersOfPages.at(-1);
      const secondToLastPageNumber = numbersOfPages.at(-2);

      const postNumbersOfPages = numbersOfPages.filter(pageNumber => pageNumber > currentPage && pageNumber <= this.maxVisiblePageNumber)
        .sort((a, b) => a - b);

      const lastVisiblePageNumber = postNumbersOfPages.at(-1);

      if (lastVisiblePageNumber < secondToLastPageNumber) {
        postNumbersOfPages.push('..');
        postNumbersOfPages.push(lastPageNumber);
      } else if (lastVisiblePageNumber === secondToLastPageNumber) {
        postNumbersOfPages.push(lastPageNumber);
      }
      return postNumbersOfPages;
    },
    changeShowPaginationSettingsModal() {
      this.showPaginationSettingsModal = !this.showPaginationSettingsModal;
    },
    changePageSize(newPageSize) {
      if (this.paginationProperties.pageSize !== newPageSize) {
        this.showLoading();
        this.$emit('setNewPageSize', newPageSize);
      }
      this.changeShowPaginationSettingsModal();
    },
    changeCurrentPage() {
      this.showLoading();
      this.$emit('setNewCurrentPage', this.paginationProperties.currentPage);
    },
    goToPage(pageNumber) {
      if (pageNumber === '..' || this.isLoading) {
        return;
      }
      if (this.paginationProperties.currentPage !== pageNumber) {
        this.paginationProperties.currentPage = pageNumber;
        this.changeCurrentPage();
      }
    },
    goNextPage() {
      if (this.paginationProperties.last === false) {
        this.goToPage(this.paginationProperties.currentPage + 1);
      }
    },
    goPrevPage() {
      if (this.paginationProperties.first === false) {
        this.goToPage(this.paginationProperties.currentPage - 1);
      }
    },
  },
  mounted() {
    const that = this;
    if (this.mutationNameToSetAgGridGridApi) {
      this.subscribeMutation(this.mutationNameToSetAgGridGridApi, (mutationPayload) => {
        that.setAgGridApi(mutationPayload);
      });
    }
  },
};
</script>
