import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VLayout,{attrs:{"justify-center":""}},[_c(VDialog,{attrs:{"persistent":"","max-width":"600"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"headline"},[_c(VIcon,{staticClass:"mr-2",attrs:{"large":"","color":"primary"}},[_vm._v("info")]),_vm._v(" "+_vm._s(_vm.title))],1),(_vm.message)?_c(VCardText,{staticClass:"mt-3 mb-2 title"},[_vm._v(_vm._s(_vm.message))]):_vm._e(),_c(VCardActions,[_c(VSpacer),_c(VBtn,{staticClass:"title",attrs:{"large":"","flat":"","color":"primary"},on:{"click":_vm.reject}},[_vm._v(_vm._s(_vm.$t('common.cancel')))]),_c(VBtn,{staticClass:"title",attrs:{"large":"","color":"primary","depressed":""},on:{"click":_vm.confirm}},[_vm._v(_vm._s(_vm.$t('common.confirm')))])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }