/* eslint no-param-reassign: "error" */
import Vue from 'vue';
import { WindowUtils } from '../components/shared/js/windowUtils';

Vue.directive('flex-none', {
  bind(el, binding) {
    if (binding && el && !WindowUtils.isXSWidth()) {
      el.style.maxWidth = 'none';
      el.style.flex = 'none';
    }
  },
});
