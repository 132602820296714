<template>
  <v-dialog v-model="versionChanged" persistent max-width="600px">
    <v-card class="pa-2">
      <v-card-title>
        <h5 class="headline red--text">
          {{ $t('messages.newVersion') }}
        </h5>
      </v-card-title>
      <v-card-text class="subheading">
        {{ $t('messages.newVersionDescription') }}
        <br /><strong>v{{ newVersion }}</strong>
        <br /><br />
        <!-- if spanish-->
        <template v-if="$i18n.locale === 'es'">
          <p>Si al recargar la página no se actualiza la versión siga los siguientes pasos:</p>
          <ol>
            <li>Presione <kbd>Ctrl</kbd> + <kbd>Shift</kbd> + <kbd>R</kbd> para recargar la página forzando la
              actualización de la caché.</li>
            <li>Si el paso anterior no funciona, limpie la caché de su navegador.</li>
          </ol>
        </template>
        <!-- if english-->
        <template v-else>
          <p>If reloading the page does not update the version follow these steps:</p>
          <ol>
            <li>Press <kbd>Ctrl</kbd> + <kbd>Shift</kbd> + <kbd>R</kbd> to reload the page forcing the cache update.
            </li>
            <li>If the previous step does not work, clear your browser cache.</li>
          </ol>
        </template>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="reloadPage">{{ $t('common.reload') }}</v-btn>
        <v-btn color="primary" outline text @click="cancel">{{ $t('common.cancel') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { GET_VERSION, GET_CURRENT_VERSION } from '../../store/modules/version/versionCatalog';

export default {
  data() {
    return {
      intervalId: null,
      versionChanged: false,
      intervalTime: (5 * 60 * 1000), // 5 minutes
    };
  },
  async created() {
    await this.getCurrentVersion();
    // Check version every 5 minutes
    this.intervalId = setInterval(this.checkVersion, this.intervalTime);
    // first check
    await this.checkVersion();
  },
  beforeDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  },
  computed: {
    currentVersion() {
      return this.$store.getters.getCurrentVersion;
    },
    newVersion() {
      return this.$store.getters.getVersion;
    },
  },
  methods: {
    async getVersion() {
      await this.$store.dispatch(GET_VERSION);
    },
    async getCurrentVersion() {
      await this.$store.dispatch(GET_CURRENT_VERSION);
    },
    async checkVersion() {
      await this.getVersion();
      if (!this.newVersion) {
        // if not version available, probably the server is down
        return;
      }
      if (this.currentVersion && this.currentVersion !== this.newVersion) {
        this.versionChanged = true;
        console.log('New version available', this.newVersion, ' (old version:', this.currentVersion, ')'); // eslint-disable-line
        // Stop the interval when the modal is opened
        if (this.intervalId) {
          clearInterval(this.intervalId);
          this.intervalId = null;
        }
      }
    },
    clearCache() {
      navigator.serviceWorker.getRegistrations().then((registrations) => {
        registrations.forEach((registration) => {
          registration.unregister();
        });
      });
      caches.keys().then(cacheNames => Promise.all(cacheNames.map(cacheName => caches.delete(cacheName))));
    },
    cancel() {
      this.versionChanged = false;
      // Resume the interval when cancel is pressed
      if (!this.intervalId) {
        this.intervalId = setInterval(this.checkVersion, this.intervalTime);
      }
    },
    reloadPage() {
      this.clearCache();
      window.location.reload(true);
    },
  },
};
</script>
