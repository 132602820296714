<template>
  <div>
    <v-flex v-flex-none class="airport-from-input">
      <airport-select :ref="'refComp1'" v-on:keydown.native="keyEvent" v-char-length.clearable="3" v-model="airport1IdUpdated"></airport-select>
    </v-flex>
  </div>
</template>

<script>
import Vue from 'vue';
import AirportSelect from '../../../airport/AirportSelect.vue';
import KeyEventsUtils, { keyEvents } from '../../../keyEvents';
import AgGridParentRushBaggageCellEditor from '../mixins/AgGridParentRushBaggageCellEditor.vue';

export default Vue.extend({
  mixins: [AgGridParentRushBaggageCellEditor],
  components: {
    AirportSelect,
  },
  data() {
    return {
      airport1IdOriginal: null,
      airport1IdUpdated: null,
    };
  },
  methods: {
    getValue() {
      const airport1 = this.$store.getters.getAirportById(this.airport1IdUpdated);
      if (this.airport1IdOriginal !== this.airport1IdUpdated) {
        const that = this;
        this.saveForValueOrRushesBaggage((s) => {
          if (airport1) {
            s.setChildPropertyValue('airport1Id', that.airport1IdUpdated);
            s.setChildPropertyValue('airport1IataCode', airport1.iataCode);
            s.setChildPropertyValue('airport1IcaoCode', airport1.icaoCode);
            s.setChildPropertyValue('airport1Code', airport1.code);
          } else {
            s.setChildPropertyValue('airport1Id', null);
            s.setChildPropertyValue('airport1IataCode', '');
            s.setChildPropertyValue('airport1IcaoCode', '');
            s.setChildPropertyValue('airport1Code', '');
          }
        }, 'Airports');
      }
      return airport1.iataCode ? airport1.iataCode : '';
    },
    keyEvent($event) {
      if (KeyEventsUtils.isArrowEvent($event)) {
        $event.stopPropagation();
      }
      if ($event.code === keyEvents.TAB && $event.shiftKey) {
        $event.stopPropagation();
      }
    },
  },
  created() {
    const rushBaggage = this.params.node.data;

    this.airport1IdOriginal = rushBaggage.airport1Id;
    this.airport1IdUpdated = this.airport1IdOriginal;
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$refs.refComp1) {
        this.$refs.refComp1.$refs.refComp.focus();
      }
    });
  },
});
</script>

<style>
  .airport-from-cell.ag-cell-inline-editing {
    min-width: 290px;
  }
  .airport-from-input {
    display: inline-block;
    margin-left: 5px;
  }
</style>
