<template v-if="department.members">
  <v-container>
    <delete-confirmation-dialog v-if="showConfirmDialog" :dialog="showConfirmDialog" text="" @delete-confirm="deleteItem" @delete-reject="deleteItemReject"/>
    <v-flex v-for="(member, index) in department.members" :key="index">
      <v-card>
        <v-card-text>
          <v-container>
            <v-layout row wrap>
              <v-flex v-if="!isEmpty(member)">
                <v-avatar size="200" ma-5>
                  <v-img class="elevation-1" :src="cardImage(member)"/>
                </v-avatar>
              </v-flex>
              <v-layout column ma-0 justify-center>
                <v-layout row v-if="!isEmpty(member)">
                  <v-flex grow>
                    <h3 class="headline">{{ member.role }}</h3>
                    <span class="subheading">{{ member.contact.name }}</span>
                  </v-flex>
                  <v-flex shrink>
                    <v-menu bottom left>
                      <template v-slot:activator="{ on }">
                        <v-btn icon v-on="on">
                          <v-icon>more_vert</v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-tile @click="editItem(member)">
                          <v-list-tile-title >
                             <v-icon left>edit</v-icon>Edit
                          </v-list-tile-title>
                        </v-list-tile>
                        <v-list-tile @click="showDeleteDialog(member)">
                          <v-list-tile-title >
                            <v-icon left>delete</v-icon>Delete
                          </v-list-tile-title>
                        </v-list-tile>
                      </v-list>
                    </v-menu>
                  </v-flex>
                </v-layout>
                <v-container v-if="isEmpty(member)" fill-height>
                  <v-layout row align-center>
                    <v-flex xs12 text-xs-center>
                      <h3 class="subheading">There is no member data</h3>
                      <v-btn @click="editItem(member)" flat color="primary">
                        <v-icon left>person_add</v-icon>Edit member
                      </v-btn>
                    </v-flex>
                  </v-layout>
                </v-container>
                <member-info-content v-if="!isEmpty(member)" :item="member"/>
              </v-layout>
            </v-layout>
          </v-container>
        </v-card-text>
      </v-card>
    </v-flex>
  </v-container>
</template>

<script>
import { BASE_URL } from '@/http';
import MemberInfoContent from './common/MemberInfoContent.vue';
import DeleteConfirmationDialog from './common/DeleteConfirmationDialog.vue';

export default {
  name: 'DirectoryBoard',
  components: {
    MemberInfoContent,
    DeleteConfirmationDialog,
  },
  props: {
    department: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    showConfirmDialog: false,
    itemDelete: null,
  }),
  methods: {
    cardImage(member) {
      return `${BASE_URL}directory-members/images/${member.id}`;
    },
    editItem(item) {
      this.$emit('click-edit-item', this.department, item);
    },
    deleteItem() {
      this.showConfirmDialog = false;
      if (this.itemDelete) {
        this.$emit('click-delete-item', this.itemDelete);
        this.itemDelete = null;
      }
    },
    deleteItemReject() {
      this.itemDelete = null;
      this.showConfirmDialog = false;
    },
    showDeleteDialog(item) {
      this.itemDelete = item;
      this.showConfirmDialog = true;
    },
    deleteConfirm(item) {
      this.deleteItem(item);
    },
    isEmpty(item) {
      const clone = Object.assign({}, item);
      delete clone.title;
      delete clone.fileTitle;
      delete clone.name;
      delete clone.id;
      delete clone.fileId;
      delete clone.order;
      delete clone.imageIdentifier;
      return Object.values(clone).every(i => (i === null || i === ''));
    },
  },
};
</script>
