<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <entity-configuration-list
      :entity-name="this.$i18n.t('configuration.milestoneGlobal')"
      entity-icon="emoji_flags"
      :framework-components="frameworkComponents" :column-defs="columnDefs" :items="milestones"
      @confirm-edit-item="editConfirm"
      @confirm-delete-item="deleteConfirm"
    >
      <template v-slot:modal-content="{ itemEdit }">
        <milestone-edit-card-content :item="itemEdit" />
      </template>
    </entity-configuration-list>
  </div>
</template>
<script>
import {
  MILESTONE_CREATE,
  MILESTONE_UPDATE,
  MILESTONE_DELETE,
  GET_MILESTONES,
} from '@/store/modules/milestone/milestoneCatalog';
import EntityConfigurationList from '@/components/configuration/common/EntityConfigurationList.vue';
import MilestoneEditCardContent from './MilestoneEditCardContent.vue';
import AgGridRowActionButtonsCellRenderer from '../../ag-grid/cell-renderers/AgGridRowActionButtonsCellRenderer.vue';
import AgGridIconCellRenderer from '../../ag-grid/cell-renderers/AgGridIconCellRenderer.vue';
import { FlightBound } from '../../shared/model/flightBound';


export default {
  name: 'MilestonesGlobalList',
  components: {
    EntityConfigurationList,
    MilestoneEditCardContent,
  },
  data() {
    const that = this;
    return {
      frameworkComponents: {
        AgGridRowActionButtonsCellRenderer,
        AgGridIconCellRenderer,
      },
      columnDefs: [
        {
          field: 'name',
          headerValueGetter() {
            return that.$i18n.t('configuration.name');
          },
        },
        {
          field: 'description',
          headerValueGetter() {
            return that.$i18n.t('configuration.description');
          },
        },
        {
          field: 'flightBound',
          headerValueGetter() {
            return that.$i18n.t('configuration.flightBound');
          },
          valueGetter(params) {
            if (FlightBound.INBOUND.description === params.data.flightBound) {
              return that.$i18n.t('common.inbound');
            }
            if (FlightBound.OUTBOUND.description === params.data.flightBound) {
              return that.$i18n.t('common.outbound');
            }
            return that.$i18n.t('common.both');
          },
        },
        {
          field: 'type',
          headerValueGetter() {
            return that.$i18n.t('configuration.type');
          },
        },
      ],
    };
  },
  computed: {
    milestones() {
      return this.$store.getters.getMilestones;
    },
  },
  methods: {
    getMilestones() {
      this.$store.dispatch(GET_MILESTONES);
    },
    deleteConfirm(item) {
      if (item.rigid !== true) {
        this.$store.dispatch(MILESTONE_DELETE, item.id);
      }
    },
    editConfirm(item) {
      if (item.rigid !== true) {
        // Convert flightBound from Symbol to String
        const milestone = {
          ...item,
          flightBound: item.flightBound.description,
        };
        this.$store.dispatch(item.id ? MILESTONE_UPDATE : MILESTONE_CREATE, milestone);
      }
    },
  },
  created() {
    this.getMilestones();
  },
};
</script>
