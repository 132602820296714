<template>
  <v-layout row justify-center>
    <v-dialog v-model="dialog" persistent max-width="50%"
    :content-class="isContentVisible ? 'modal-common' : 'modal-common hide-title'">
      <v-card :dark="this.$vuetify.dark" :color="this.$vuetify.dark ? 'bggrey_dark' : 'bggrey'">
        <v-toolbar dark color="primary">
          <v-icon>delete</v-icon>
          <v-toolbar-title>{{$t("scheduling.cancellationFlightTitle")}}</v-toolbar-title>
          <v-spacer></v-spacer>
        <!-- Botón para contraer/expandir el contenido -->
        <v-btn icon @click="toggleContentVisibility">
          <v-icon>{{ isContentVisible ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
        </v-btn>
      </v-toolbar>
      <div v-show="isContentVisible">

        <loading-component />

        <v-card-text>
          <v-form ref="form" v-model="valid">
            <v-container grid-list-md>
              <v-layout wrap justify-start>
                <v-flex xs12>
                  <v-text-field v-model="reason" :label="this.$i18n.t('scheduling.cancellationFlightReason')"/>
                </v-flex>
                <v-flex xs12>
                  <v-card class="evidence-card">
                    {{ $t('scheduling.cancellationFlightEvidence') }}
                    <quill-editor
                      v-model="evidence"
                      :options="editorOptions"
                      @change="checkIfEvidenceValid"
                    ></quill-editor>
                  </v-card>
                </v-flex>
                <v-flex xs6>
                  <v-text-field v-model="channel" :label="this.$i18n.t('scheduling.cancellationFlightChannel')"/>
                </v-flex>
                <v-flex xs6>
                  <v-text-field v-model="informant" :label="this.$i18n.t('scheduling.cancellationFlightInformant')"/>
                </v-flex>
                <v-flex xs2>
                  <date-input :locale="$i18n.locale" :label="this.$i18n.t('common.date')" v-model="date" :rules="[rules.required]"/>
                </v-flex>
                <v-flex xs2>
                  <time-input :label="this.$i18n.t('common.time')" v-model="time"  :rules="[rules.required]"/>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn flat color="primary" @click.native="reject()">{{$t("common.close")}}</v-btn>
          <v-btn color="error" depressed @click.native="confirm()" :disabled="!valid || loading">
            {{$t("common.cancelFlights")}}
          </v-btn>
        </v-card-actions>
      </div>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { quillEditor } from 'vue-quill-editor';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.bubble.css';
import TimeInput from '../../ui/TimeInput.vue';
import DateInput from '../../ui/DateInput.vue';
import LoadingComponent from '../../layout/LoadingComponent.vue';

export default {
  name: 'scheduling-cancel-flights-modal',
  components: {
    TimeInput,
    DateInput,
    LoadingComponent,
    quillEditor,
  },
  props: {
    dialog: { type: Boolean, required: true },
    items: { type: Array, required: true },
  },
  data() {
    const editorOptions = this.getEditorOptions();
    return {
      reason: '',
      evidence: '',
      editorOptions,
      channel: '',
      informant: '',
      date: null,
      time: null,
      valid: true,
      rules: {
        required: value => !!value || this.$i18n.t('common.fieldRequired'),
      },
      isContentVisible: true,
    };
  },
  computed: {
    loading() {
      return this.$store.state.loading;
    },
  },
  methods: {
    getEditorOptions() {
      const editorOptions = {};
      const formats = ['background', 'bold', 'color', 'font', 'code', 'italic', 'link', 'size', 'strike', 'script', 'underline', 'blockquote', 'header', 'indent', 'list', 'align', 'direction', 'code-block', 'formula'];
      editorOptions.placeholder = this.$i18n.t('scheduling.cancellationFlightEvidences');
      editorOptions.theme = 'bubble';
      editorOptions.formats = formats;
      return editorOptions;
    },
    reject() {
      this.$emit('cancel-reject');
    },
    confirm() {
      const dateMoment = this.$moment(this.date);
      const timeMoment = this.$moment(this.time);
      dateMoment.set({
        hour: timeMoment.get('hour'),
        minute: timeMoment.get('minute'),
        second: timeMoment.get('second'),
      });
      const flightCancellation = {
        flights: this.mapToIdsArray(this.items),
        cancellation: {
          reason: this.reason,
          evidence: this.evidence,
          channel: this.channel,
          informant: this.informant,
          date: dateMoment,
        },
      };

      this.$emit('cancel-confirm', flightCancellation);
    },
    mapToIdsArray(connectedFlights) {
      const cancellable = [];
      let inboundId = null;
      let outboundId = null;
      if (connectedFlights) {
        connectedFlights.forEach((item) => {
          inboundId = (item.inbound && item.inbound.common && item.inbound.common.id) ? item.inbound.common.id : null;
          outboundId = (item.outbound && item.outbound.common && item.outbound.common.id) ? item.outbound.common.id : null;

          cancellable.push({
            inboundId,
            outboundId,
          });
        });
      }
      return cancellable;
    },
    checkIfEvidenceValid() {
      this.valid = this.evidence && this.evidence.length < 10000;
    },
    toggleContentVisibility() {
      this.isContentVisible = !this.isContentVisible;
    },
  },
};
</script>

<style lang="scss">
  .evidence-card {
    padding: 15px;
    background-color: inherit !important;
    font-size: large;
  }

  .evidence-card > .quill-editor > .ql-container {
    margin: 5px;
    max-height: 250px;
    overflow: auto;
  }

</style>
