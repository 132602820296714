import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VImg,{attrs:{"src":_vm.headerImage,"height":"300px"}},[_c(VLayout,{staticStyle:{"margin":"0 -4px 0 0"},attrs:{"column":"","fill-height":""}},[_c(VCardTitle,[_c(VSpacer),_c(VBtn,{staticClass:"card-title-background mr-3",attrs:{"dark":"","icon":""},on:{"click":_vm.editItem}},[_c(VIcon,[_vm._v("edit")])],1)],1),_c(VSpacer),_c(VCardTitle,{staticClass:"white--text card-title-background"},[_c('div',{staticClass:"pl-4"},[_c('h4',{staticClass:"headline font-weight-light pa-1",attrs:{"id":"airport-name"}},[_c(VIcon,{attrs:{"left":"","dark":""}},[_vm._v("local_airport")]),_vm._v(_vm._s(_vm.item.title))],1),(_vm.item.contact.addressRegion && _vm.item.contact.addressCountry)?_c('h6',{staticClass:"subheading font-weight-light pa-1"},[_vm._v(_vm._s(_vm.item.contact.addressRegion)+". "+_vm._s(_vm.item.contact.addressCountry))]):_vm._e(),_c('div',[_c('span',{staticClass:"caption pa-1"},[_vm._v("IATA: "+_vm._s(_vm.item.map.IATA))]),_c(VDivider,{staticClass:"mx-3",attrs:{"inset":"","vertical":""}}),_c('span',{staticClass:"caption pa-1"},[_vm._v("OACI: "+_vm._s(_vm.item.map.ICAO))])],1)])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }