<template>
  <v-list two-line>
    <v-list-tile v-if="item.subtitle">
      <v-list-tile-action>
        <v-icon color="primary">business</v-icon>
      </v-list-tile-action>

      <v-list-tile-content>
        <v-list-tile-title>{{ item.subtitle }}</v-list-tile-title>
        <v-list-tile-sub-title>Business</v-list-tile-sub-title>
      </v-list-tile-content>
    </v-list-tile>

    <v-list-tile v-if="item.contact.name">
      <v-list-tile-action>
        <v-icon color="primary">person</v-icon>
      </v-list-tile-action>

      <v-list-tile-content>
        <v-list-tile-title>{{ item.contact.name }}</v-list-tile-title>
        <v-list-tile-sub-title>Name</v-list-tile-sub-title>
      </v-list-tile-content>
    </v-list-tile>

    <v-list-tile v-if="item.contact.phone">
      <v-list-tile-action>
        <v-icon color="primary">phone</v-icon>
      </v-list-tile-action>

      <v-list-tile-content>
        <v-list-tile-title>
          <a :href="`tel:${item.contact.phone}`">{{ item.contact.phone }}</a>
        </v-list-tile-title>
        <v-list-tile-sub-title>Phone</v-list-tile-sub-title>
      </v-list-tile-content>
    </v-list-tile>

    <v-list-tile v-if="item.contact.phoneMobile">
      <v-list-tile-action>
        <v-icon color="primary">smartphone</v-icon>
      </v-list-tile-action>

      <v-list-tile-content>
        <v-list-tile-title>
          <a :href="`tel:${item.contact.phoneMobile}`">{{ item.contact.phoneMobile }}</a>
        </v-list-tile-title>
        <v-list-tile-sub-title>Mobile phone</v-list-tile-sub-title>
      </v-list-tile-content>
    </v-list-tile>

    <v-list-tile v-if="item.contact.fax">
      <v-list-tile-action>
        <v-icon color="primary">printer</v-icon>
      </v-list-tile-action>

      <v-list-tile-content>
        <v-list-tile-title>
          {{ item.contact.fax }}
        </v-list-tile-title>
        <v-list-tile-sub-title>Fax</v-list-tile-sub-title>
      </v-list-tile-content>
    </v-list-tile>

    <v-list-tile v-if="item.contact.mail">
      <v-list-tile-action>
        <v-icon color="primary">mail</v-icon>
      </v-list-tile-action>

      <v-list-tile-content>
        <v-list-tile-title>
          <a :href="`mailto:${item.contact.mail}`">{{ item.contact.mail }}</a>
        </v-list-tile-title>
        <v-list-tile-sub-title>E-mail</v-list-tile-sub-title>
      </v-list-tile-content>
    </v-list-tile>

    <v-list-tile v-if="hasAddress">
      <v-list-tile-action>
        <v-icon color="primary">location_on</v-icon>
      </v-list-tile-action>

      <v-list-tile-content>
        <v-list-tile-title>{{ item.contact.addressPostalCode }} {{ item.contact.addressStreet }} {{ item.contact.addressCity }}</v-list-tile-title>
        <v-list-tile-sub-title>{{ item.contact.addressRegion }}. {{ item.contact.addressCountry }}</v-list-tile-sub-title>
      </v-list-tile-content>
    </v-list-tile>
  </v-list>
</template>

<script>
export default {
  name: 'CardInfoSingleColumn',
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    hasAddress() {
      return this.item.contact.addressPostalCode || this.item.contact.addressStreet || this.item.contact.addressCity || this.item.contact.addressRegion || this.item.contact.addressCountry;
    },
  },
};
</script>
