<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>

    <ag-grid-dialog-template-editor :is-dialog-open="isDialogOpen" @save="save" @cancel="cancel">

      <template v-slot:header>
        Edit Local Passengers {{flightNumber}}
      </template>

      <template v-slot:default>
        <v-container grid-list-md text-xs-center fluid>
          <v-layout row wrap>
            <v-flex xs12 v-if="isOutbound">
              <v-card>
                <v-card-text >
                  <v-container fluid >
                    <v-layout>
                      <v-flex xs3>
                        <v-tooltip bottom>
                          <v-icon x-large left slot="activator">flight_takeoff</v-icon>
                          <span>Outbound flight</span>
                        </v-tooltip>
                      </v-flex>
                      <v-flex xs3>
                        <v-text-field v-model="localPaxCheckIn.localPaxCheckInCounters" type="number" label="CKIN" :readonly="!hasPermissions(rolePermission)"/>
                      </v-flex>
                      <v-flex xs3>
                        <v-text-field v-model="localPaxCheckIn.localPaxCheckInWeb" type="number" label="WWW" :readonly="!hasPermissions(rolePermission)"/>
                      </v-flex>
                      <v-flex xs3>
                        <v-text-field v-model="localPaxCheckIn.localPaxCheckInKiosks" type="number" label="SSK" :readonly="!hasPermissions(rolePermission)"/>
                      </v-flex>
                    </v-layout>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-layout>
        </v-container>
      </template>
    </ag-grid-dialog-template-editor>
  </div>
</template>

<script>
import Vue from 'vue';
import AgGridDialogParentCellEditor from '../../mixins/AgGridDialogParentCellEditor.vue';
import AgGridDialogTemplateEditor from '../../templates/AgGridDialogTemplateEditor.vue';
import { FlightBound } from '../../../../shared/model/flightBound';

export default Vue.extend({
  mixins: [AgGridDialogParentCellEditor],
  components: {
    AgGridDialogTemplateEditor,
  },
  data() {
    return {
      localPaxCheckIn: {},
    };
  },
  computed: {
    isOutbound() {
      return this.flightBound === FlightBound.OUTBOUND;
    },
    referenceDate() {
      return this.connectedFlights.getChildPropertyValue(this.flightBound, ['common', 'scheduledDate']);
    },
    rolePermission() {
      return `${this.$route.meta.role}_WR`;
    },
  },
  methods: {
    getValue() {
      if (this.isSaving) {
        this.requestInit();
        this.$http.put(`/flights/${this.flightId}/checkin`, this.localPaxCheckIn)
          .then(resp => this.dialogRequestSuccessCallback(this, resp))
          .catch(error => this.requestErrorCallback(this, error));
      }

      return this.params.value;
    },
  },
  created() {
    this.$http.get(`/flights/${this.flightId}/checkin`)
      .then((resp) => {
        this.localPaxCheckIn = {
          localPaxCheckInCounters: resp.data.localPaxCheckInCounters,
          localPaxCheckInWeb: resp.data.localPaxCheckInWeb,
          localPaxCheckInKiosks: resp.data.localPaxCheckInKiosks,
        };
      });
  },
});
</script>
