<template>
  <water-toilet-flights-list-table :items="items" :isFilterDisplayed="this.isFilterDisplayed"/>
</template>
<script>
import WaterToiletFlightsListTable from './WaterToiletFlightsListTable.vue';
import { WATER_TOILET_FILTER_RESET, WATER_TOILET_FIND_FLIGHTS } from '../../../../store/modules/ramp/waterToilet';

export default {
  name: 'water-toilet-flights-list-container',
  components: {
    WaterToiletFlightsListTable,
  },
  props: {
    isFilterDisplayed: { type: Boolean, required: false, default: false },
  },
  mounted() {
    this.$store.commit(WATER_TOILET_FILTER_RESET);
    this.$store.dispatch(WATER_TOILET_FIND_FLIGHTS);
  },
  computed: {
    items() {
      return this.$store.state.waterToilet.connectedFlightsWaterToilet;
    },
  },
  methods: {
  },
};
</script>
<style lang="scss">


</style>
