
export default class SchedulingLinkOptions {
  constructor(aircraftCriteria, aircraftTypeCriteria, airlineCriteria, airlineOperationCriteria, minGroundTime, maxGroundTime) {
    this.aircraftCriteria = aircraftCriteria || true;
    this.aircraftTypeCriteria = aircraftTypeCriteria || true;
    this.airlineCriteria = airlineCriteria || true;
    this.airlineOperationCriteria = airlineOperationCriteria || true;
    this.minGroundTime = minGroundTime || 30;
    this.maxGroundTime = maxGroundTime || (3 * 60);
  }

  isValid() {
    return parseInt(this.minGroundTime, 10) >= 0 && parseInt(this.maxGroundTime, 10) >= 0 && parseInt(this.maxGroundTime, 10) >= parseInt(this.minGroundTime, 10);
  }

  static createDefaultOptions() {
    return new SchedulingLinkOptions(true, true, true, true, 30, 3 * 60);
  }

  static createDirectLinkOptions() {
    return new SchedulingLinkOptions(true, true, false, false, 0, 24 * 60);
  }
}
