<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <entity-configuration-list
      :entity-name="this.$i18n.t('common.aircraft')"
      entity-icon="local_airport"
      :framework-components="frameworkComponents" :column-defs="columnDefs" :items="aircrafts"
      @confirm-edit-item="editConfirm"
      @confirm-delete-item="deleteConfirm"
      @confirm-enable-disable-item="enableDisableConfirm"
    >
      <template v-slot:modal-content="{ itemEdit }">
        <aircraft-edit-card-content :item="itemEdit" />
      </template>
    </entity-configuration-list>
  </div>
</template>
<script>
import {
  GET_AIRCRAFT,
  AIRCRAFT_CREATE,
  AIRCRAFT_DELETE,
  AIRCRAFT_UPDATE,
  AIRCRAFT_DISABLE,
  AIRCRAFT_ENABLE,
} from '@/store/modules/aircraft/aircraftCatalog';
import EntityConfigurationList from '@/components/configuration/common/EntityConfigurationList.vue';
import AgGridIconCheckOrIconClearCellRenderer from '@/components/ag-grid/cell-renderers/AgGridIconCheckOrIconClearCellRenderer.vue';
import AircraftEditCardContent from './AircraftEditCardContent.vue';
import AgGridIconValueOrDashCellRenderer from '../../ag-grid/cell-renderers/AgGridIconValueOrDashCellRenderer.vue';
import AgGridIconCellRenderer from '../../ag-grid/cell-renderers/AgGridIconCellRenderer.vue';

export default {
  name: 'AircraftList',
  components: {
    EntityConfigurationList,
    AircraftEditCardContent,
  },
  data() {
    const that = this;
    return {
      frameworkComponents: {
        AgGridIconValueOrDashCellRenderer,
        AgGridIconCellRenderer,
        AgGridIconCheckOrIconClearCellRenderer,
      },
      columnDefs: [
        {
          field: 'registerNumber',
          headerValueGetter() {
            return that.$i18n.t('common.aircraftRegistrationLarge');
          },
        },
        {
          headerValueGetter() {
            return that.$i18n.t('configuration.acars');
          },
          field: 'acars',
          cellRendererFramework: AgGridIconValueOrDashCellRenderer,
          valueGetter(params) {
            return (params.data.acars) ? 'A' : null;
          },
        },
        {
          headerValueGetter() {
            return that.$i18n.t('configuration.uld');
          },
          field: 'cargo',
          cellRendererFramework: AgGridIconValueOrDashCellRenderer,
          valueGetter(params) {
            return (params.data.cargo) ? 'P' : 'B';
          },
        },
        {
          headerValueGetter() {
            return that.$i18n.t('configuration.apuInoperative');
          },
          field: 'apu',
          cellRendererFramework: AgGridIconCheckOrIconClearCellRenderer,
        },
        {
          headerValueGetter() {
            return that.$i18n.t('configuration.mtow');
          },
          field: 'mtow',
        },
        {
          headerValueGetter() {
            return that.$i18n.t('configuration.description');
          },
          field: 'comments',
        },
        {
          headerValueGetter() {
            return that.$i18n.t('configuration.aircraftType');
          },
          valueGetter(params) {
            return params.data.typeId ? `${params.data.typeCode} (${params.data.typeComments})` : '';
          },
        },
        {
          headerValueGetter() {
            return that.$i18n.t('configuration.disabled');
          },
          field: 'deleted',
          cellRendererFramework: AgGridIconCellRenderer,
          cellRendererParams: {
            icon: 'mdi-cancel',
            tooltip: params => (params.data.deleted ? 'Disabled' : null),
          },
        },
      ],
    };
  },
  computed: {
    aircrafts() {
      return this.$store.getters.getAircrafts;
    },
  },
  methods: {
    getAircrafts() {
      this.$store.dispatch(GET_AIRCRAFT);
    },
    deleteConfirm(item) {
      this.$store.dispatch(AIRCRAFT_DELETE, item.id);
    },
    editConfirm(item) {
      this.$store.dispatch(item.id ? AIRCRAFT_UPDATE : AIRCRAFT_CREATE, item);
    },
    enableDisableConfirm(item) {
      this.$store.dispatch(item.deleted ? AIRCRAFT_ENABLE : AIRCRAFT_DISABLE, item.id);
    },
  },
  created() {
    this.getAircrafts();
  },
};
</script>
