/* eslint no-param-reassign: "off" */
import moment from 'moment';
import { HTTP } from '../../../http';
import { REQUEST_ERROR, REQUEST_START, REQUEST_SUCCESS } from '../../store';
import ConnectedFlightCompositionFactory from '../../../components/shared/model/connectedFlightsFactory';
import { createFlightEventFromData } from '../../../components/shared/model/flightEvent';
import FlightTowingAdhocTaskFilter from '../../../components/towingAdhocTask/flights/model/flightTowingAdhocTaskFilter';
import { NOTIFICATION_SHOW } from '../notifications';
import { FlightBound } from '../../../components/shared/model/flightBound';
import i18n from '../../../plugins/i18n';
import { ACTION_SET_NO_FLIGHTS_TO_SHOW_MESSAGE } from '../noFlightsToShowMessages';

// action
export const FLIGHT_TOWING_ADHOC_TASK_SET_GRID_API = 'FLIGHT_TOWING_ADHOC_TASK_SET_GRID_API';

export const FLIGHT_TOWING_ADHOC_TASK_FIND_FLIGHTS = 'FLIGHT_TOWING_ADHOC_TASK_FIND_FLIGHTS';
export const FLIGHT_TOWING_ADHOC_TASK_DO_EXTERNAL_CHANGE = 'TOWING_ADHOC_TASK_DO_EXTERNAL_CHANGE';

export const FLIGHT_TOWING_ADHOC_TASK_ARCHIVE = 'FLIGHT_TOWING_ADHOC_TASK_ARCHIVE';
export const FLIGHT_TOWING_ADHOC_TASK_UNARCHIVE = 'FLIGHT_TOWING_ADHOC_TASK_UNARCHIVE';

export const FIT_COLUMNS_TO_VIEW = 'FIT_COLUMNS_TO_VIEW';
export const FIT_COLUMNS_TO_BEST = 'FIT_COLUMNS_TO_BEST';

// mutations
export const FLIGHT_TOWING_ADHOC_TASK_FIND_FLIGHTS_SUCCESS = 'FLIGHT_TOWING_ADHOC_TASK_FIND_FLIGHTS_SUCCESS';
export const FLIGHT_TOWING_ADHOC_TASK_CHANGE_FLIGHT_SELECTION = 'FLIGHT_TOWING_ADHOC_TASK_CHANGE_FLIGHT_SELECTION';
export const FLIGHT_TOWING_ADHOC_TASK_ON_EXTERNAL_CHANGE = 'FLIGHT_TOWING_ADHOC_TASK_ON_EXTERNAL_CHANGE ';
export const FLIGHT_TOWING_ADHOC_TASK_SET_CHANGE = 'FLIGHT_TOWING_ADHOC_TASK_SET_CHANGE ';

export const FLIGHT_TOWING_ADHOC_TASK_FILTER_RESET = 'FLIGHT_TOWING_ADHOC_TASK_FILTER_RESET ';
export const FLIGHT_TOWING_ADHOC_TASK_FILTER_RESET_TIMEZONE = 'FLIGHT_TOWING_ADHOC_TASK_FILTER_RESET_TIMEZONE ';
export const FLIGHT_TOWING_ADHOC_TASK_FILTER_INCREASE_DAY = 'FLIGHT_TOWING_ADHOC_TASK_FILTER_INCREASE_DAY ';
export const FLIGHT_TOWING_ADHOC_TASK_FILTER_DECREASE_DAY = 'FLIGHT_TOWING_ADHOC_TASK_FILTER_DECREASE_DAY ';
export const FLIGHT_TOWING_ADHOC_TASK_FILTER_SET_DAY = 'FLIGHT_TOWING_ADHOC_TASK_FILTER_SET_DAY ';

export const UPDATE_SHOW_OLD_VALUES = 'UPDATE_SHOW_OLD_VALUES ';
export const UPDATE_FLASH_ON_CHANGES = 'UPDATE_FLASH_ON_CHANGES ';

export const UPDATE_FIT_COLUMNS_ON_RESIZE = 'UPDATE_FIT_COLUMNS_ON_RESIZE ';

// initial state
const storeState = {
  gridApi: null,
  filter: FlightTowingAdhocTaskFilter.createEmptyFilter,
  connectedFlightsTowingAdhocTask: [],
  selectedFlights: [],
  flightsChanges: [],
  showOldValues: true,
  flashOnChanges: false,
  fitColumnsOnResize: true,
};

// getters
const storeGetters = {
  hasSelectedTowingAdhocTaskFlights: state => state.selectedFlights.length > 0,
  isSelectedTowingAdhocTaskFlights: state => id => !!state.selectedFlights.find(s => s.getId() === id),
  getSelectedFlightTowingAdhocTaskFlights: state => state.selectedFlights.filter(connectedFlights => connectedFlights.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'id']) != null || (connectedFlights.getChildPropertyValue(FlightBound.INBOUND, ['common', 'id']) != null && connectedFlights.getChildPropertyValue(FlightBound.INBOUND, ['common', 'tripNumber']) != null)),
  getSelectedFlightTowingAdhocTaskAircraftGround: state => state.selectedFlights.filter(connectedFlights => connectedFlights.getChildPropertyValue(FlightBound.INBOUND, ['common', 'id']) != null && connectedFlights.getChildPropertyValue(FlightBound.INBOUND, ['common', 'tripNumber']) == null),
};

// actions
const storeActions = {
  [FLIGHT_TOWING_ADHOC_TASK_FIND_FLIGHTS]: ({ dispatch, commit, state }) => {
    console.log(state.filter); // eslint-disable-line no-console
    commit(REQUEST_START);
    commit(FLIGHT_TOWING_ADHOC_TASK_CHANGE_FLIGHT_SELECTION, []);
    return HTTP.post('towing-adhoc-task/flights/find', state.filter)
      .then((resp) => {
        commit(REQUEST_SUCCESS);
        commit(
          FLIGHT_TOWING_ADHOC_TASK_FIND_FLIGHTS_SUCCESS,
          ConnectedFlightCompositionFactory.createConnectedFlightsFromData(resp.data),
        );
        commit(FLIGHT_TOWING_ADHOC_TASK_CHANGE_FLIGHT_SELECTION, []);
        dispatch(ACTION_SET_NO_FLIGHTS_TO_SHOW_MESSAGE, resp.status);
      })
      .catch((err) => {
        dispatch(ACTION_SET_NO_FLIGHTS_TO_SHOW_MESSAGE, err.response.status);
        commit(FLIGHT_TOWING_ADHOC_TASK_FIND_FLIGHTS_SUCCESS, []);
        commit(REQUEST_ERROR, err);
      });
  },
  [FLIGHT_TOWING_ADHOC_TASK_DO_EXTERNAL_CHANGE]: ({ commit }, change) => {
    commit(FLIGHT_TOWING_ADHOC_TASK_ON_EXTERNAL_CHANGE, createFlightEventFromData(change));
  },
  [FLIGHT_TOWING_ADHOC_TASK_ARCHIVE]: ({ commit, dispatch, getters }) => {
    commit(REQUEST_START);
    return HTTP.post('towing-adhoc-task/flights/archive', getters.getSelectedFlights)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.flightArchive'),
          color: 'success',
        });
        dispatch(FLIGHT_TOWING_ADHOC_TASK_FIND_FLIGHTS);
      })
      .catch((err) => {
        commit(REQUEST_ERROR, err);
      });
  },
  [FLIGHT_TOWING_ADHOC_TASK_UNARCHIVE]: ({ commit, dispatch, getters }) => {
    commit(REQUEST_START);
    return HTTP.post('towing-adhoc-task/flights/unarchive', getters.getSelectedFlights)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.flightUnarchive'),
          color: 'success',
        });
        dispatch(FLIGHT_TOWING_ADHOC_TASK_FIND_FLIGHTS);
      })
      .catch((err) => {
        commit(REQUEST_ERROR, err);
      });
  },
  [FIT_COLUMNS_TO_VIEW]: ({ state }) => {
    if (state.gridApi) {
      state.gridApi.sizeColumnsToFit();
    }
  },
  [FIT_COLUMNS_TO_BEST]: ({ state }) => {
    if (state.gridApi && state.gridApi.columnController && state.gridApi.columnController.columnApi) {
      state.gridApi.columnController.columnApi.autoSizeAllColumns();
    }
  },
};

// mutations
const storeMutations = {
  [FLIGHT_TOWING_ADHOC_TASK_SET_GRID_API]: (state, gridApi) => {
    state.gridApi = gridApi.api;
  },
  [FLIGHT_TOWING_ADHOC_TASK_FIND_FLIGHTS_SUCCESS]: (state, connectedFlightsTowingAdhocTask) => {
    state.connectedFlightsTowingAdhocTask = connectedFlightsTowingAdhocTask;
  },
  [FLIGHT_TOWING_ADHOC_TASK_CHANGE_FLIGHT_SELECTION]: (state, selection) => {
    state.selectedFlights = selection;
  },
  [FLIGHT_TOWING_ADHOC_TASK_ON_EXTERNAL_CHANGE]: (state, change) => {
    console.log(`mutations external change ${change}`); // eslint-disable-line no-console
  },
  [FLIGHT_TOWING_ADHOC_TASK_SET_CHANGE]: (state, change) => {
    change.ts = moment();
    if (change.diff.path.includes('inbound')) {
      change.flightNumber = change.connectedFlights.inbound.common.flightNo();
      change.bound = 'inbound';
    }
    if (change.diff.path.includes('outbound')) {
      change.flightNumber = change.connectedFlights.outbound.common.flightNo();
      change.bound = 'outbound';
    }
    state.flightsChanges.unshift(change);
    state.flightsChanges = state.flightsChanges.slice(0, 10);
  },
  [FLIGHT_TOWING_ADHOC_TASK_FILTER_RESET]: (state) => {
    state.filter.dateFrom = moment().startOf('day');
    state.filter.dateTo = moment().endOf('day');
    state.filter.timezoneDate = moment.defaultZone.name;
  },
  [FLIGHT_TOWING_ADHOC_TASK_FILTER_RESET_TIMEZONE]: (state) => {
    state.filter.dateFrom = moment().startOf('day')
      .year(state.filter.dateFrom.year())
      .month(state.filter.dateFrom.month())
      .date(state.filter.dateFrom.date());
    state.filter.dateTo = moment().endOf('day')
      .year(state.filter.dateTo.year())
      .month(state.filter.dateTo.month())
      .date(state.filter.dateTo.date());

    state.filter.timezoneDate = moment.defaultZone.name;
  },
  [FLIGHT_TOWING_ADHOC_TASK_FILTER_INCREASE_DAY]: (state) => {
    state.filter.dateFrom = moment().startOf('day')
      .year(state.filter.dateFrom.year())
      .month(state.filter.dateFrom.month())
      .date(state.filter.dateFrom.date())
      .add(1, 'days')
      .startOf('day');

    state.filter.dateTo = moment().endOf('day')
      .year(state.filter.dateTo.year())
      .month(state.filter.dateTo.month())
      .date(state.filter.dateTo.date())
      .add(1, 'days');
  },
  [FLIGHT_TOWING_ADHOC_TASK_FILTER_DECREASE_DAY]: (state) => {
    state.filter.dateFrom = moment().startOf('day')
      .year(state.filter.dateFrom.year())
      .month(state.filter.dateFrom.month())
      .date(state.filter.dateFrom.date())
      .subtract(1, 'days');

    state.filter.dateTo = moment().endOf('day')
      .year(state.filter.dateTo.year())
      .month(state.filter.dateTo.month())
      .date(state.filter.dateTo.date())
      .subtract(1, 'days')
      .endOf('day');
  },
  [FLIGHT_TOWING_ADHOC_TASK_FILTER_SET_DAY]: (state, dateSelected) => {
    state.filter.dateFrom = moment(dateSelected).startOf('day');

    state.filter.dateTo = moment(dateSelected).endOf('day');
  },
  [UPDATE_SHOW_OLD_VALUES]: (state, showOldValues) => {
    state.showOldValues = showOldValues;
  },
  [UPDATE_FLASH_ON_CHANGES]: (state, flashOnChanges) => {
    state.flashOnChanges = flashOnChanges;
  },
  [UPDATE_FIT_COLUMNS_ON_RESIZE]: (state, fitColumnsOnResize) => {
    state.fitColumnsOnResize = fitColumnsOnResize;
  },
};

export default {
  state: storeState,
  getters: storeGetters,
  actions: storeActions,
  mutations: storeMutations,
};
