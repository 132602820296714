<template>
  <v-layout justify-center>
    <v-dialog v-model="dialog" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">Are you sure you want to delete this?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn flat color="primary" @click="reject">Cancel</v-btn>
          <v-btn color="primary" depressed @click="confirm">Agree</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
export default {
  name: 'DeleteConfirmationDialog',
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    confirm() {
      this.$emit('delete-confirm');
    },
    reject() {
      this.$emit('delete-reject');
    },
  },
};
</script>
