<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <entity-configuration-list
      :entity-name="this.$i18n.t('configuration.serviceAgents')"
      entity-icon="person"
      :framework-components="frameworkComponents" :column-defs="columnDefs" :items="serviceAgents"
      @confirm-edit-item="editConfirm"
      @confirm-delete-item="deleteConfirm"
      @confirm-enable-disable-item="enableDisableConfirm"
    >

    <template v-slot:modal-content="{ itemEdit }">
        <service-agent-edit-card-content :item="itemEdit" />
      </template>
    </entity-configuration-list>
  </div>
</template>

<script>
import EntityConfigurationList from '../common/EntityConfigurationList.vue';
import ServiceAgentEditCardContent from './ServiceAgentEditCardContent.vue';
import AgGridIconCellRenderer from '../../ag-grid/cell-renderers/AgGridIconCellRenderer.vue';
import {
  GET_SERVICE_AGENTS,
  SERVICE_AGENT_CREATE,
  SERVICE_AGENT_DELETE,
  SERVICE_AGENT_DISABLE,
  SERVICE_AGENT_ENABLE,
  SERVICE_AGENT_UPDATE,
} from '../../../store/modules/serviceAgent/serviceAgent';

export default {
  name: 'service-agents-list',
  components: {
    EntityConfigurationList,
    ServiceAgentEditCardContent,
  },
  data() {
    const that = this;
    return {
      frameworkComponents: {
        AgGridIconCellRenderer,
      },
      columnDefs: [
        {
          field: 'name',
          width: 55,
          headerValueGetter() {
            return that.$i18n.t('configuration.name');
          },
          sort: 'asc',
        },
        {
          field: 'description',
          width: 75,
          headerValueGetter() {
            return that.$i18n.t('configuration.description');
          },
        },
        {
          headerValueGetter() {
            return that.$i18n.t('configuration.disabled');
          },
          field: 'deleted',
          width: 35,
          cellRendererFramework: AgGridIconCellRenderer,
          cellRendererParams: {
            icon: 'mdi-cancel',
            tooltip: params => (params.data.deleted ? 'Disabled' : null),
          },
        },
      ],
    };
  },
  computed: {
    serviceAgents() {
      return this.$store.getters.getServiceAgents;
    },
  },
  methods: {
    getServiceAgents() {
      this.$store.dispatch(GET_SERVICE_AGENTS);
    },
    deleteConfirm(item) {
      this.$store.dispatch(SERVICE_AGENT_DELETE, item.id);
    },
    editConfirm(item) {
      this.$store.dispatch(item.id ? SERVICE_AGENT_UPDATE : SERVICE_AGENT_CREATE, item);
    },
    enableDisableConfirm(item) {
      this.$store.dispatch(item.deleted ? SERVICE_AGENT_ENABLE : SERVICE_AGENT_DISABLE, item.id);
    },
  },
  created() {
    this.getServiceAgents();
  },
};

</script>
