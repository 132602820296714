/* eslint no-param-reassign: "off" */

import { HTTP } from '../../http';
import { REQUEST_ERROR, REQUEST_START, REQUEST_SUCCESS } from '../store';

export const QUERY_FLIGHTS_GET = 'QUERY_FLIGHTS_GET';
export const QUERY_FLIGHTS_SAVE = 'QUERY_FLIGHTS_SAVE';
export const QUERY_FLIGHTS_DELETE = 'QUERY_FLIGHTS_DELETE';

export const QUERY_FLIGHTS_GET_SUCCESS = 'QUERY_FLIGHTS_GET_SUCCESS';

// initial state
const storeState = {
  queriesFlights: [],
};

// getters
const storeGetters = {
};

// actions
const storeActions = {
  [QUERY_FLIGHTS_GET]: ({ commit }) => {
    commit(REQUEST_START);
    return HTTP.get('filters/flights')
      .then((resp) => {
        commit(REQUEST_SUCCESS);
        commit(QUERY_FLIGHTS_GET_SUCCESS, resp.data);
      })
      .catch((err) => {
        commit(REQUEST_ERROR, err);
      });
  },
  [QUERY_FLIGHTS_SAVE]: ({ commit, dispatch }, filterParam) => {
    commit(REQUEST_START);
    return HTTP.post('filters/flights', filterParam)
      .then((resp) => {
        commit(REQUEST_SUCCESS);
        dispatch(QUERY_FLIGHTS_GET);
        return resp;
      })
      .catch((err) => {
        commit(REQUEST_ERROR, err);
      });
  },
  [QUERY_FLIGHTS_DELETE]: ({ commit, dispatch }, id) => {
    commit(REQUEST_START);
    return HTTP.delete(`filters/flights/${id}`)
      .then(() => {
        commit(REQUEST_SUCCESS);
        dispatch(QUERY_FLIGHTS_GET);
      })
      .catch((err) => {
        commit(REQUEST_ERROR, err);
      });
  },
};

// mutations
const storeMutations = {
  [QUERY_FLIGHTS_GET_SUCCESS]: (state, queries) => {
    state.queriesFlights = queries.map(f => ({
      id: f.id,
      name: f.name,
      description: f.description,
      filter: JSON.parse(f.content),
    }));
  },
};

export default {
  state: storeState,
  getters: storeGetters,
  actions: storeActions,
  mutations: storeMutations,
};
