<template>
  <div>
    <flight-passage-boarding-form :item="flight"></flight-passage-boarding-form>
  </div>
</template>
<script>

import FlightPassageBoardingForm from './FlightPassageBoardingForm.vue';
import { PASSAGE_BOARDING_FIND_FLIGHT } from '../../store/modules/passageBoarding';
import { WEBSOCKET_CONNECTION_FLIGHT_PASSAGE_BOARDING } from '../../store/modules/websocket';

export default {
  components: {
    FlightPassageBoardingForm,
  },
  computed: {
    flight() {
      return this.$store.getters.getFlightPassageBoarding;
    },
  },
  watch: {
    '$route.params.idFlight': {
      handler() {
        this.$store.dispatch(PASSAGE_BOARDING_FIND_FLIGHT, this.$route.params.idFlight);
        this.$store.dispatch(WEBSOCKET_CONNECTION_FLIGHT_PASSAGE_BOARDING, {
          tenant: this.$store.state.tenant.currentTenant.name.toLowerCase(),
          flightId: this.$route.params.idFlight,
        });
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {

    };
  },
  methods: {

  },
  mounted() {
    this.$store.dispatch(PASSAGE_BOARDING_FIND_FLIGHT, this.$route.params.idFlight);
    this.$store.dispatch(WEBSOCKET_CONNECTION_FLIGHT_PASSAGE_BOARDING, {
      tenant: this.$store.state.tenant.currentTenant.name.toLowerCase(),
      flightId: this.$route.params.idFlight,
    });
  },
};
</script>
