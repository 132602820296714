<template>
  <div>
    <v-tooltip bottom v-if="params.value && tooltip">
      <template slot="activator">
        <v-icon
          color="secondary"
          small
          class="mr-2"
          @click.stop="params.action(params.data)"
        >
          {{icon}}
        </v-icon>
      </template>
      <span>{{tooltip}}</span>
    </v-tooltip>
    <v-icon
      v-if="params.value && !tooltip"
      color="secondary"
      small
      class="mr-2"
      @click.stop="params.action(params.data)"
    >
      {{icon}}
    </v-icon>
  </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  computed: {
    icon() {
      if (this.params.icon && typeof this.params.icon === 'function') {
        return this.params.icon(this.params);
      }
      return this.params.icon;
    },
    tooltip() {
      if (this.params.tooltip && typeof this.params.tooltip === 'function') {
        return this.params.tooltip(this.params);
      }
      return this.params.tooltip;
    },
  },
});
</script>

<style scoped>

</style>
