/* eslint no-param-reassign: "off" */
import { HTTP } from '../../http';
import { REQUEST_ERROR, REQUEST_START, REQUEST_SUCCESS } from '../store';
import { NOTIFICATION_SHOW } from './notifications';
import i18n from '../../plugins/i18n';

// action
export const AIRCRAFT_GROUND_UPDATE = 'AIRCRAFT_GROUND_UPDATE';
export const AIRCRAFT_GROUND_UPDATE_V2 = 'AIRCRAFT_GROUND_UPDATE_V2';

// mutations

// initial state
const storeState = {
};

// getters
const storeGetters = {
};

// actions
const storeActions = {
  [AIRCRAFT_GROUND_UPDATE]: ({ commit }, { connectedFlightsList, field }) => new Promise((resolve, reject) => {
    commit(REQUEST_START);
    HTTP.put('aircraft-ground', connectedFlightsList)
      .then((response) => {
        commit(REQUEST_SUCCESS);
        commit(NOTIFICATION_SHOW, {
          message: field ? `${i18n.t('notifications.aircraftGroundFieldUpdate')} ${field}` : i18n.t('notifications.aircraftGroundUpdate'),
          color: 'success',
        });
        resolve(response);
      })
      .catch((err) => {
        commit(REQUEST_ERROR, err);
        reject(err);
      });
  }),
  [AIRCRAFT_GROUND_UPDATE_V2]: ({ commit }, { aircraftGroundChanges, field }) => new Promise((resolve, reject) => {
    commit(REQUEST_START);
    HTTP.put('aircraft-ground/v2', aircraftGroundChanges)
      .then((response) => {
        commit(REQUEST_SUCCESS);
        commit(NOTIFICATION_SHOW, {
          message: field ? `${i18n.t('notifications.aircraftGroundFieldUpdate')} ${field}` : i18n.t('notifications.aircraftGroundUpdate'),
          color: 'success',
        });
        resolve(response);
      })
      .catch((err) => {
        commit(REQUEST_ERROR, err);
        reject(err);
      });
  }),
};

// mutations
const storeMutations = {
};

export default {
  state: storeState,
  getters: storeGetters,
  actions: storeActions,
  mutations: storeMutations,
};
