/* eslint no-param-reassign: "off" */

import { HTTP } from '../../../http';
import { REQUEST_ERROR, REQUEST_START, REQUEST_SUCCESS } from '../../store';
import { NOTIFICATION_SHOW } from '../notifications';
import i18n from '../../../plugins/i18n';

// actions names
export const GET_AIC_DOCUMENTS = 'GET_AIC_DOCUMENTS';
export const AIC_DOCUMENTS_DELETE = 'AIC_DOCUMENTS_DELETE';
export const AIC_DOCUMENTS_CREATE = 'AIC_DOCUMENTS_CREATE';
export const AIC_DOCUMENTS_UPDATE = 'AIC_DOCUMENTS_UPDATE';

// mutations
export const CHANGE_AIC_DOCUMENTS_SELECTION = 'CHANGE_AIC_DOCUMENTS_SELECTION';
export const GET_AIC_DOCUMENTS_SUCCESS = 'GET_AIC_DOCUMENTS_SUCCESS';
export const GET_AIC_DOCUMENTS_REQUESTED = 'GET_AIC_DOCUMENTS_REQUESTED';
export const UPDATE_AIC_DOCUMENTS_SUCCESS = 'UPDATE_AIC_DOCUMENTS_SUCCESS';
export const CHANGED_TENANT_AIC_DOCUMENTS = 'CHANGED_TENANT_AIC_DOCUMENTS';

// initial state
const storeState = {
  aicDocument: [],
  aicDocumentRequested: false,
  selectedAICDocument: [],
};

// getters
const storeGetters = {
  getAICDocuments: state => state.aicDocument,
  getAICDocumentSelected: state => state.selectedAICDocument,
  hasSelectedAICDocument: state => state.selectedAICDocument.length > 0,
  getAICDocumentById: state => id => state.aicDocument.find(a => a.id === id),
};

// actions
const storeActions = {
  [GET_AIC_DOCUMENTS]: ({ commit, state }) => {
    if (state.aicDocumentRequested) {
      return;
    }
    commit(REQUEST_START);
    commit(GET_AIC_DOCUMENTS_REQUESTED);
    HTTP.get('aic-document')
      .then((resp) => {
        commit(REQUEST_SUCCESS);
        commit(GET_AIC_DOCUMENTS_SUCCESS, resp.data);
      })
      .catch((err) => {
        commit(REQUEST_ERROR, err);
      });
  },

  [AIC_DOCUMENTS_CREATE]: ({ commit, dispatch }, aicDocument) => {
    commit(REQUEST_START);
    return HTTP.post('aic-document', aicDocument)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(UPDATE_AIC_DOCUMENTS_SUCCESS);
        dispatch(GET_AIC_DOCUMENTS);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.aicDocumentsCreate'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },

  [AIC_DOCUMENTS_UPDATE]: ({ commit, dispatch }, aicDocument) => {
    commit(REQUEST_START);
    return HTTP.put('aic-document', aicDocument)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(UPDATE_AIC_DOCUMENTS_SUCCESS);
        dispatch(GET_AIC_DOCUMENTS);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.aicDocumentsUpdate'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
  [AIC_DOCUMENTS_DELETE]: ({ commit, dispatch }, id) => {
    commit(REQUEST_START);
    return HTTP.delete(`aic-document/${id}`)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(UPDATE_AIC_DOCUMENTS_SUCCESS);
        dispatch(GET_AIC_DOCUMENTS);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.aicDocumentsDelete'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
};

// mutations
const storeMutations = {
  [GET_AIC_DOCUMENTS_SUCCESS]: (state, aicDocument) => {
    state.aicDocument = aicDocument;
  },
  [GET_AIC_DOCUMENTS_REQUESTED]: (state) => {
    state.aicDocumentRequested = true;
  },
  [UPDATE_AIC_DOCUMENTS_SUCCESS]: (state) => {
    state.aicDocumentRequested = false;
  },
  [CHANGE_AIC_DOCUMENTS_SELECTION]: (state, selection) => {
    state.selectedAICDocument = selection;
  },
  [CHANGED_TENANT_AIC_DOCUMENTS]: (state) => {
    state.aicDocumentRequested = false;
  },
};

export default {
  state: storeState,
  getters: storeGetters,
  actions: storeActions,
  mutations: storeMutations,
};
