import { FlightBound } from './flightBound';
import ConnectedFlightsFactory from './connectedFlightsFactory';
import ConnectedFlights from './connectedFlights';

export default class ConnectedFlightsIds {
  constructor(
    inboundId,
    outboundId,
  ) {
    this.inboundId = inboundId;
    this.outboundId = outboundId;
  }

  static createEmtpyConnectedFlightsIds() {
    return new ConnectedFlightsIds(null, null);
  }

  static mapFromConnectedFlights(connectedFlights) {
    const connectedFlightsAux = connectedFlights instanceof ConnectedFlights ? connectedFlights : ConnectedFlightsFactory.mapConnectedFlights(connectedFlights);
    return new ConnectedFlightsIds(
      connectedFlightsAux.getChildPropertyValue(FlightBound.INBOUND, ['common', 'id']),
      connectedFlightsAux.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'id']),
    );
  }

  static mapFromConnectedFlightsList(connectedFlightsList) {
    return connectedFlightsList.map(item => this.mapFromConnectedFlights(item));
  }
}
