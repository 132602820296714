<template>
  <v-toolbar clipped-left app :dark="dark">
    <v-toolbar-title class="hidden-xs-only">
      <v-toolbar-side-icon v-if="isAuthenticated && isTenantSetted"
        @click.stop="$emit('sidebarToggle', $event)"></v-toolbar-side-icon>

      <router-link class="logo-image-toolbar" v-if="isAuthenticated && !isLoading()" :to="{ name: 'home' }" tag="img"
        :src="require('@/assets/logo-image.svg')"></router-link>

      <router-link class="loader-image-toolbar" v-if="isAuthenticated && isLoading()" :to="{ name: 'home' }">
        <loader-fis :width="48"></loader-fis>
      </router-link>
      <span class="hidden-sm-and-down">
        <span v-if="currentVersion" class="hidden-sm-and-down caption grey--text app-version">v{{ currentVersion }}</span>
        acciona flights
      </span>
    </v-toolbar-title>

    <v-toolbar-side-icon v-show="$vuetify.breakpoint.xs" v-if="isAuthenticated && isTenantSetted"
      @click.stop="$emit('sidebarToggle', $event)"></v-toolbar-side-icon>

    <navbar-breadcrumbs class="hidden-xs-only" />
    <v-spacer></v-spacer>
    <help-button :lang="user.langKey" :module="this.$route.name" class="hidden-sm-and-down" />
    <MobileView />
    <theme-selector />
    <LanguageSwitcher />
    <time-avinet />
    <timezone-switch />
    <current-tenant-display />
    <v-btn icon v-if="isAuthenticated" class="hidden-sm-and-down">
      <v-icon>notifications_none</v-icon>
    </v-btn>

    <v-menu v-if="isAuthenticated">
      <v-btn slot="activator" icon>
        <v-avatar size="32px">
          <img v-if="user.imageUrl" :src="user.imageUrl" :alt="getUserFullName()">
          <v-icon v-else>account_circle</v-icon>
        </v-avatar>
      </v-btn>

      <v-card>
        <v-list>
          <v-list-tile avatar>
            <v-list-tile-avatar>
              <img v-if="user.imageUrl" :src="user.imageUrl" :alt="getUserFullName()">
              <v-icon v-else>account_circle</v-icon>
            </v-list-tile-avatar>

            <v-list-tile-content>
              <v-list-tile-title>{{ getUserFullName() }}</v-list-tile-title>
              <v-list-tile-sub-title>{{ user.email }}</v-list-tile-sub-title>
            </v-list-tile-content>
          </v-list-tile>
        </v-list>

        <v-divider></v-divider>

        <v-card-actions>

          <v-btn icon v-if="isAuthenticated" class="hidden-md-and-up">
            <v-icon>notifications_none</v-icon>
          </v-btn>
          <v-spacer></v-spacer>

          <v-btn color="secondary" flat v-on:click.stop="logout">LOGOUT</v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>

  </v-toolbar>
</template>

<script>
import NavbarBreadcrumbs from './NavbarBreadcrumbs.vue';
import HelpButton from './HelpButton.vue';
import { AUTH_LOGOUT } from '../../store/modules/auth';
import TimezoneSwitch from './TimezoneSwitch.vue';
import LanguageSwitcher from '../LanguageSwitcher.vue';
import LoaderFis from '../ui/LoaderFis.vue';
import CurrentTenantDisplay from '../tenant/CurrentTenantDisplay.vue';
import TimeAvinet from './TimeAvinet.vue';
import mobile from '../../store/modules/mobile';
import MobileView from '../MobileView.vue';
import ThemeSelector from '../ThemeSelector.vue';
import { GET_CURRENT_VERSION } from '../../store/modules/version/versionCatalog';

export default {
  name: 'navbar',
  components: {
    CurrentTenantDisplay,
    LoaderFis,
    LanguageSwitcher,
    TimezoneSwitch,
    NavbarBreadcrumbs,
    HelpButton,
    TimeAvinet,
    mobile,
    MobileView,
    ThemeSelector,
  },
  props: {
    dark: Boolean,
  },
  created() {
    this.getVersion();
  },
  computed: {
    isAuthenticated() {
      return this.$store.getters.isAuthenticated;
    },
    currentVersion() {
      return this.$store.getters.getCurrentVersion;
    },
    user() {
      return this.$store.getters.user;
    },
    isTenantSetted() {
      return this.$store.getters.isTenantSetted;
    },
  },
  methods: {
    getUserFullName() {
      return `${this.user.firstName} ${this.user.lastName}`;
    },
    logout() {
      this.$store.dispatch(AUTH_LOGOUT);
    },
    isLoading() {
      return this.$store.state.loading;
    },
    getVersion() {
      this.$store.dispatch(GET_CURRENT_VERSION);
    },
  },
};
</script>
<style lang="scss">
.logo-image-toolbar {
  height: 45px;
  margin-bottom: -15px;
  margin-left: 5px;
  margin-right: 5px;
}

.loader-image-toolbar {
  div {
    margin-top: -40px;
    display: inline-block;

    svg {
      margin-bottom: -18px;
    }
  }
}
.app-version {
  position: absolute;
  margin-top: 30px;
  margin-left: 6px;
}
</style>
