<template>
  <div>
    <v-flex v-flex-none class="airport-from-input">
      <airport-select :ref="'refComp1'" v-on:keydown.native="keyEvent" v-char-length.clearable="3" v-model="fsIdUpdated"></airport-select>
    </v-flex>
  </div>
</template>

<script>
import Vue from 'vue';
import AirportSelect from '../../../airport/AirportSelect.vue';
import KeyEventsUtils, { keyEvents } from '../../../keyEvents';
import AgGridParentFlightDeliveryBaggageCellEditor from '../mixins/AgGridParentFlightDeliveryBaggageCellEditor.vue';

export default Vue.extend({
  mixins: [AgGridParentFlightDeliveryBaggageCellEditor],
  components: {
    AirportSelect,
  },
  data() {
    return {
      fsIdOriginal: null,
      fsIdUpdated: null,
    };
  },
  methods: {
    getValue() {
      const airport1 = this.$store.getters.getAirportById(this.fsIdUpdated);
      if (this.fsIdOriginal !== this.fsIdUpdated) {
        const that = this;
        this.saveForValueOrDeliveriesBaggage((s) => {
          if (airport1) {
            s.setChildPropertyValue('fsId', that.fsIdUpdated);
            s.setChildPropertyValue('fsIataCode', airport1.iataCode);
          } else {
            s.setChildPropertyValue('fsId', null);
            s.setChildPropertyValue('fsIataCode', '');
          }
        }, 'Airports');
      }
      return airport1.iataCode ? airport1.iataCode : '';
    },
    keyEvent($event) {
      if (KeyEventsUtils.isArrowEvent($event)) {
        $event.stopPropagation();
      }
      if ($event.code === keyEvents.TAB && $event.shiftKey) {
        $event.stopPropagation();
      }
    },
  },
  created() {
    const deliveryBaggage = this.params.node.data;

    this.fsIdOriginal = deliveryBaggage.fsId;
    this.fsIdUpdated = this.fsIdOriginal;
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$refs.refComp1) {
        this.$refs.refComp1.$refs.refComp.focus();
      }
    });
  },
});
</script>

<style>
  .airport-from-cell.ag-cell-inline-editing {
    min-width: 290px;
  }
  .airport-from-input {
    display: inline-block;
    margin-left: 5px;
  }
</style>
