<template>
  <div>
    <v-autocomplete placeholder="..." :value="value" :label="label" :items="airports" :filter="customFilter"
      :clearable="true" item-text="code" item-value="id" :rules="rules" :disabled="disabled" hide-details
      @input="$emit('input', $event)" :ref="refComp" :prepend-inner-icon="icon">
      <template slot="item" slot-scope="{ item }">
        <v-list-tile-content>
          <v-list-tile-title>{{ item.code }}</v-list-tile-title>
          <v-list-tile-sub-title>{{ item.name }}</v-list-tile-sub-title>
        </v-list-tile-content>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import { GET_AIRPORTS } from '../../store/modules/airport/airportCatalog';

export default {
  props: {
    value: { required: true },
    label: { type: String },
    charLenght: { type: Number },
    rules: { type: Array },
    disabled: { type: Boolean },
    icon: { type: String },
    refComp: {
      type: String,
      required: false,
      default: 'refComp',
    },
  },
  computed: {
    airports() {
      return this.$store.getters.getActiveAirports;
    },
  },
  methods: {
    getAirports() {
      this.$store.dispatch(GET_AIRPORTS);
    },
    customFilter(item, queryText) {
      const textOne = item.iataCode ? item.iataCode.toLowerCase() : '';
      const textTwo = item.icaoCode ? item.icaoCode.toLowerCase() : '';
      const textThree = item.name ? item.name.toLowerCase() : '';
      const searchText = queryText.toLowerCase();

      return textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1 || textThree.indexOf(searchText) > -1;
    },
  },
  mounted() {
    this.getAirports();
  },
};
</script>
