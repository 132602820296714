import FlightState from './flightState';
import FlightService from './flightService';
import PaginationRequestProperties from '../../ui/pagination/model/paginationRequestProperties';

export default class BillingFilter {
  constructor(dateFrom, dateTo, unitOfTime, airline, airportFrom, airportTo, flightState, flightService, rampService, showFlights, showAircraftGround, paginationRequestProperties) {
    this.dateFrom = dateFrom;
    this.dateTo = dateTo;
    this.unitOfTime = unitOfTime;
    this.airline = airline;
    this.airportFrom = airportFrom;
    this.airportTo = airportTo;
    this.flightState = flightState;
    this.flightService = flightService;
    this.rampService = rampService;
    this.showFlights = showFlights === true;
    this.showAircraftGround = showAircraftGround === true;
    this.pageRequestProperties = paginationRequestProperties;
  }

  static get createEmptyFilter() {
    const defaultPaginationProperties = PaginationRequestProperties.createDefaultPaginationRequestProperties;
    return new BillingFilter(null, null, 'day', null, null, null, new FlightState(), new FlightService(), null, true, true, defaultPaginationProperties);
  }

  static createFromData(json) {
    if (!json) {
      return this.createEmptyFilter;
    }
    return new BillingFilter(
      json.dateFrom,
      json.dateTo,
      json.unitOfTime,
      json.airline,
      json.airportFrom,
      json.airportTo,
      json.flightState,
      json.flightService,
      json.rampService,
    );
  }
}
