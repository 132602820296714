<template>
  <v-layout row justify-center>
    <v-dialog v-model="dialog" persistent max-width="1370px"
      :content-class="isContentVisible ? 'modal-common' : 'modal-common hide-title'">
      <v-card :dark="this.$vuetify.dark" :color="this.$vuetify.dark ? 'bggrey_dark' : 'bggrey'">
        <v-toolbar dark color="primary">
          <v-icon>confirmation_number</v-icon>
          <v-toolbar-title v-if="isExternal">{{ $t("ticketing.newExternalTicket") }}</v-toolbar-title>
          <v-toolbar-title v-else>{{ $t("ticketing.newTicket") }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <!-- Botón para contraer/expandir el contenido -->
          <v-btn icon @click="toggleContentVisibility">
            <v-icon>{{ isContentVisible ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
          </v-btn>
        </v-toolbar>
        <div v-show="isContentVisible">

          <loading-component />

          <v-card-text>
            <v-form ref="form" v-model="validTicketing">
              <v-layout row>
                <v-flex class="pl-4">
                  <v-switch color="primary" v-model="isExternal" :false-value="true" :true-value="false"
                    :label="`${isExternal ? $t('ticketing.without') : $t('ticketing.with')}  ${$t('ticketing.associatedFlight')}`" />
                </v-flex>
              </v-layout>
              <v-layout row wrap>
                <v-flex v-if="!isExternal" sm6 xs12>
                  <flight-selector-container @flightSelected="setFlight" @clearFlight="clearFlight" :bound="bound"
                    :rules="[rules.required]" :disabledDate="userIsCashier" :check-next-flights-active="true"
                    :airline-filter="flightAirlineFilter" />
                </v-flex>
                <v-flex sm5 xs12 class="d-flex">
                  <airline-fare-selector-container @airlineFareSelected="setAirlineFare"
                    @clearAirlineFare="clearAirlineFare" :tenant="currentTenant.id" :airline="fareAirlineFilter"
                    :rules="[rules.required]" />
                </v-flex>
              </v-layout>
            </v-form>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn flat color="primary" @click="reject" :disabled="loading">{{ $t("common.cancel") }}</v-btn>
            <v-btn color="primary" @click="generateTicketing" :disabled="loading || !isValidTicketing">{{
      $t("common.continue") }}</v-btn>
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>
  </v-layout>
</template>
<script>

import LoadingComponent from '../../layout/LoadingComponent.vue';
import FlightSelectorContainer from '../../ui/flightSelector/FlightSelectorContainer.vue';
import AirlineFareSelectorContainer from '../../ui/airlineFareSelector/AirlineFareSelectorContainer.vue';
import AirlineFareSelect from '../../ui/airlineFareSelector/AirlineFareSelect.vue';
import { REQUEST_ERROR, REQUEST_SUCCESS } from '../../../store/store';
import { NOTIFICATION_SHOW } from '../../../store/modules/notifications';
import FlightTicketingInformation from '../model/flightTicketingInformation';
import { FlightBound } from '../../shared/model/flightBound';
import { JSUtils } from '../../shared/js/JSUtils';
import { ROLE_CASHIER_TICKETING } from '../../../constants/roles';
import { TICKETING_FLIGHT_UPDATE_SUCCESS } from '../../../store/modules/ticketing';

export default {
  name: 'ticketing-new-modal',
  components: {
    LoadingComponent,
    FlightSelectorContainer,
    AirlineFareSelectorContainer,
    AirlineFareSelect,
  },
  props: ['dialog'],
  computed: {
    loading() {
      return this.$store.state.loading;
    },
    isValidTicketing() {
      return this.validTicketing;
    },
    isTenantSetted() {
      return this.$store.getters.isTenantSetted;
    },
    currentTenant() {
      return this.isTenantSetted ? this.$store.state.tenant.currentTenant : null;
    },
    ticketingUserRole() {
      return this.$store.state.ticketing.ticketingUserRole;
    },
    userIsCashier() {
      return this.ticketingUserRole === ROLE_CASHIER_TICKETING;
    },
  },
  data() {
    return {
      ticketing: FlightTicketingInformation.createEmptyFlightTicketingInformation,
      validTicketing: false,
      bound: FlightBound.OUTBOUND.description,
      isExternal: false,
      rules: {
        required: value => !!value || 'Required.',
      },
      flightAirlineFilter: null,
      fareAirlineFilter: null,
      isContentVisible: true,
    };
  },
  methods: {
    setFlight(flight) {
      this.ticketing.flightId = flight.id;
      this.ticketing.flightTripNumber = flight.tripNumber;
      const flightDate = new Date(flight.flightDate);
      this.ticketing.flightDate = flightDate.toISOString();
      this.ticketing.scheduledTime = flight.scheduledTime;
      this.ticketing.airport1Id = flight.airport1Id;
      this.ticketing.airport1Code = flight.airport1Code;

      this.fareAirlineFilter = flight.airlineId;
    },
    clearFlight() {
      this.ticketing.flightId = null;
      this.ticketing.flightTripNumber = null;
      this.ticketing.scheduledTime = null;
      this.ticketing.airport1Id = null;
      this.ticketing.airport1Code = null;
      this.ticketing.ticketingDate = null;

      this.fareAirlineFilter = null;

      if (this.ticketing.airlineFareId == null) {
        this.ticketing.airlineId = null;
        this.ticketing.airlineCode = null;
      }
    },
    setAirlineFare(airlineFare) {
      this.ticketing.airlineFareId = airlineFare.id;
      this.ticketing.airlineFareName = airlineFare.name;
      this.ticketing.airlineFareCost = airlineFare.cost;
      this.ticketing.airlineFareCurrency = airlineFare.currency;
      this.ticketing.airlineFareUnitsPerCost = airlineFare.unitsPerCost;
      this.ticketing.airlineFareFields = airlineFare.fields;
      this.ticketing.airlineFareUnitsType = airlineFare.unitsType;
      this.ticketing.airlineId = airlineFare.airlineId;
      this.ticketing.airlineCode = airlineFare.airlineCode;
      this.ticketing.airlineIataCode = airlineFare.airlineIataCode;
      this.ticketing.airlineIcaoCode = airlineFare.airlineIcaoCode;
      this.ticketing.airlineDefaultCode = airlineFare.airlineDefaultCode;
      this.ticketing.tpvType = airlineFare.tpvType;

      this.flightAirlineFilter = airlineFare.airlineId;
    },

    clearAirlineFare() {
      this.ticketing.airlineFareId = null;
      this.ticketing.airlineFareName = null;
      this.ticketing.airlineFareCost = null;
      this.ticketing.airlineFareCurrency = null;
      this.ticketing.airlineFareUnitsPerCost = null;
      this.ticketing.airlineFareUnitsType = null;

      this.flightAirlineFilter = null;

      if (this.ticketing.flightId == null) {
        this.ticketing.airlineId = null;
        this.ticketing.airlineCode = null;
      }
    },
    reject() {
      this.$emit('ticketing-new-reject');
    },
    confirm() {
      this.$emit('ticketing-new-confirm', {});
    },
    validTicketingForm(valid) {
      this.validTicketing = valid;
    },
    generateTicketing() {
      this.ticketing.ticketingDate = this.$moment();
      this.ticketing = this.ticketing.serializeTicketing();
      this.$http.post('flight-ticketing/generate', this.ticketing, {
      }).then((resp) => {
        this.confirm();
        this.$store.commit(REQUEST_SUCCESS);
        const ticketingSaved = JSUtils.cast(resp.data, FlightTicketingInformation);
        this.$store.commit(TICKETING_FLIGHT_UPDATE_SUCCESS, ticketingSaved);
        this.$router.push({ name: 'ticketingReport', params: { ticketingId: ticketingSaved.id } });
      }).catch((err) => {
        this.$store.commit(REQUEST_ERROR, err);
        this.$store.commit(NOTIFICATION_SHOW, {
          message: err.response.data.title || this.$i18n.t('notifications.errorNotification'),
          color: 'error',
        });
      });
    },
    toggleContentVisibility() {
      this.isContentVisible = !this.isContentVisible;
    },
  },
};
</script>
<style></style>
