<template>
  <v-menu
    max-height="600px"
  >
    <v-btn
      slot="activator"
      @click="getChanges"
      icon>
      <v-icon>add_alert</v-icon>
    </v-btn>

    <v-card>
      <v-list subheader>
        <v-subheader>{{$t("rushBaggage.rushBaggageChanges")}}</v-subheader>

        <v-text-field v-model="paramFilter" prepend-icon="filter_list" clearable @input="filterChanges"></v-text-field>
        <v-list-tile avatar v-for="(change, index) in changesFilter" :key="index">
          <v-list-tile-avatar>
            <v-icon>local_shipping</v-icon>
          </v-list-tile-avatar>
          <v-list-tile-content>
            <v-list-tile-title>{{ change.fileNumber }} <small>[{{ change.requestTs.format('HH:mm DD/MM/YYYY') }}]</small></v-list-tile-title>
            <v-list-tile-sub-title>{{ change.diff.path[change.diff.path.length - 1] }}: <small>{{ change.diff.lhs }} > {{ change.diff.rhs }}</small></v-list-tile-sub-title>
          </v-list-tile-content>
        </v-list-tile>
      </v-list>

      <v-divider></v-divider>


    </v-card>
  </v-menu>
</template>
<script>

export default {
  name: 'rush-baggage-list-changes-list',
  components: {
  },
  props: [],
  data() {
    return {
      changes: [],
      changesFilter: [],
      paramFilter: null,
    };
  },
  methods: {
    getChanges() {
      this.changes = this.$store.state.rushBaggage.rushesBaggageChanges;
      this.filterChanges();
    },
    filterChanges() {
      if (this.paramFilter != null) {
        this.changesFilter = this.changes.filter((item) => {
          const textOne = item.flightNumber ? item.flightNumber.toLowerCase() : '';
          const textTwo = item.ts ? item.ts.format('HH:mm DD/MM/YYYY') : '';
          const textThree = item.bound ? item.bound.toLowerCase() : '';
          const textFour = item.diff.path[item.diff.path.length - 1] ? item.diff.path[item.diff.path.length - 1].toLowerCase() : '';
          const textFive = item.diff.lhs ? item.diff.lhs.toLowerCase() : '';
          const textSix = item.diff.rhs ? item.diff.rhs.toLowerCase() : '';
          const textSeven = item.user ? item.user.toLowerCase() : '';
          const searchText = this.paramFilter.toLowerCase();
          return textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1 || textThree.indexOf(searchText) > -1 || textFour.indexOf(searchText) > -1 || textFive.indexOf(searchText) > -1 || textSix.indexOf(searchText) > -1 || textSeven.indexOf(searchText) > -1;
        });
      } else {
        this.changesFilter = this.changes.slice(0);
      }
    },
  },
};
</script>
