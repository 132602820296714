<template>
    <v-dialog v-model="dialogValue" max-width="1370px" persistent
    :content-class="isContentVisible ? 'modal-common' : 'modal-common hide-title'">
        <v-card :dark="this.$vuetify.dark" :color="this.$vuetify.dark ? 'bggrey_dark' : 'bggrey'">
          <v-toolbar dark color="primary">
            <v-icon>flight</v-icon>
            <v-toolbar-title v-if="editedSchedulingFlight.isNew()">{{$t("scheduling.newFlight")}}</v-toolbar-title>
            <v-toolbar-title v-if="!editedSchedulingFlight.isNew()">{{$t("scheduling.editFlight")}}</v-toolbar-title>
            <v-spacer></v-spacer>

            <v-btn-toggle class="max-width-btn-toggle-header" v-model="flightBound">
              <v-tooltip bottom>
                <v-btn flat value="ANY" justify-end="true" slot="activator" :disabled="btnBoundDisabled">
                  <v-icon>flight_land</v-icon>
                  <v-icon>flight_takeoff</v-icon>
                </v-btn>
                <span>{{$t("scheduling.scheduledINandOut")}}</span>
              </v-tooltip>
              <span class="separator-btn-header"></span>
              <v-tooltip bottom>
                <v-btn flat value="INBOUND" slot="activator" :disabled="btnBoundDisabled">
                  <v-icon>flight_land</v-icon>
                </v-btn>
                <span>{{$t("scheduling.scheduledIN")}}</span>
              </v-tooltip>
              <span class="separator-btn-header"></span>
              <v-tooltip bottom>
                <v-btn flat value="OUTBOUND" slot="activator" :disabled="btnBoundDisabled">
                  <v-icon>flight_takeoff</v-icon>
                </v-btn>
                <span>{{$t("scheduling.scheduledOUT")}}</span>
              </v-tooltip>
            </v-btn-toggle>

        <!-- Botón para contraer/expandir el contenido -->
        <v-btn icon @click="toggleContentVisibility">
          <v-icon>{{ isContentVisible ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
        </v-btn>
      </v-toolbar>
      <div v-show="isContentVisible">

            <v-card-text>

              <v-form ref="form" v-model="valid">
                <v-container grid-list-md text-xs-center fluid>
                  <v-layout row wrap>

                    <v-flex xs12>
                      <scheduling-flight-form :edited-flight="editedSchedulingFlight.inbound.common" type="INBOUND" :bound="flightBound" @flight-update="updateInboundFlight" :disabled="flightBound === 'OUTBOUND'" :scheduledTimeError="scheduledTimeError" :additionalDateRules="additionalDateRules" />
                    </v-flex>

                    <v-flex xs12>
                      <scheduling-flight-form :edited-flight="editedSchedulingFlight.outbound.common" type="OUTBOUND" :bound="flightBound" @flight-update="updateOutboundFlight" :disabled="flightBound === 'INBOUND'" :scheduledTimeError="scheduledTimeError" :additionalDateRules="additionalDateRules" />
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-form>

            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" flat @click.native="close">{{$t("common.cancel")}}</v-btn>
                <v-btn color="primary" depressed @click.native="save">{{$t("common.save")}}</v-btn>
            </v-card-actions>
        </div>
        </v-card>
    </v-dialog>
</template>
<script>
import SchedulingFlightForm from './SchedulingFlightForm.vue';
import { NOTIFICATION_SHOW } from '../../../store/modules/notifications';
import { REQUEST_ERROR, REQUEST_START, REQUEST_SUCCESS } from '../../../store/store';
import ConnectedFlights from '../../shared/model/connectedFlights';
import { FORCE_GET_AIRCRAFT_CONFIGS } from '../../../store/modules/flightCatalogs';


export default {
  name: 'scheduling-edit-modal',
  components: {
    SchedulingFlightForm,
  },
  props: {
    dialog: { type: Boolean, required: true },
    editedSchedulingFlight: { type: ConnectedFlights, required: true },
    additionalDateRules: { type: Array, default() { return []; } },
  },
  data() {
    return {
      valid: true,
      selectedBound: '',
      isContentVisible: true,
    };
  },
  computed: {
    dialogValue: {
      get() {
        return this.dialog;
      },
      set() { },
    },
    flightBound: {
      get() {
        if (this.selectedBound) {
          return this.selectedBound;
        }
        if (this.editedSchedulingFlight.isNew()) {
          return 'ANY';
        }
        if (this.editedSchedulingFlight.inbound.common.id && this.editedSchedulingFlight.outbound.common.id) {
          return 'ANY';
        }
        if (this.editedSchedulingFlight.inbound.common.id) {
          return 'INBOUND';
        }
        if (this.editedSchedulingFlight.outbound.common.id) {
          return 'OUTBOUND';
        }
        return 'ANY';
      },
      set(bound) {
        this.selectedBound = bound;
      },
    },
    btnBoundDisabled() {
      return !this.editedSchedulingFlight.isNew();
    },
    scheduledTimeError() {
      if (this.editedSchedulingFlight.inbound.common.scheduledTime && this.editedSchedulingFlight.outbound.common.scheduledTime) {
        return this.$moment(this.editedSchedulingFlight.outbound.common.scheduledTime).isBefore(this.$moment(this.editedSchedulingFlight.inbound.common.scheduledTime));
      }
      return false;
    },
  },
  methods: {
    updateInboundFlight(flight) {
      this.editedSchedulingFlight.inbound.common = flight;
      if (this.flightBound === 'ANY') {
        if (!this.editedSchedulingFlight.outbound.common.flightDate) {
          this.editedSchedulingFlight.outbound.common.flightDate = this.editedSchedulingFlight.inbound.common.flightDate;
        }
        if (!this.editedSchedulingFlight.outbound.common.aircraftId && !this.editedSchedulingFlight.outbound.common.aircraftTypeId) {
          this.editedSchedulingFlight.outbound.common.aircraftId = this.editedSchedulingFlight.inbound.common.aircraftId;
          this.editedSchedulingFlight.outbound.common.aircraftTypeId = this.editedSchedulingFlight.inbound.common.aircraftTypeId;
        }
        if (!this.editedSchedulingFlight.outbound.common.aircraftTypeId) {
          this.editedSchedulingFlight.outbound.common.aircraftTypeId = this.editedSchedulingFlight.inbound.common.aircraftTypeId;
        }
        if (!this.editedSchedulingFlight.outbound.common.aircraftConfigId) {
          this.editedSchedulingFlight.outbound.common.aircraftConfigId = this.editedSchedulingFlight.inbound.common.aircraftConfigId;
        }
        if (!this.editedSchedulingFlight.outbound.common.airlineId) {
          this.editedSchedulingFlight.outbound.common.airlineId = this.editedSchedulingFlight.inbound.common.airlineId;
        }
        if (!this.editedSchedulingFlight.outbound.common.airlineOperationId) {
          this.editedSchedulingFlight.outbound.common.airlineOperationId = this.editedSchedulingFlight.inbound.common.airlineOperationId;
        }
        if (!this.editedSchedulingFlight.outbound.common.handlingTypeId) {
          this.editedSchedulingFlight.outbound.common.handlingTypeId = this.editedSchedulingFlight.inbound.common.handlingTypeId;
        }
      }
    },
    updateOutboundFlight(flight) {
      this.editedSchedulingFlight.outbound.common = flight;
    },
    save() {
      if (this.$refs.form.validate()) {
        const item = Object.assign({}, this.editedSchedulingFlight);
        if (this.flightBound === 'INBOUND') {
          item.outbound = null;
        } else if (this.flightBound === 'OUTBOUND') {
          item.inbound = null;
        }

        const newAircraftConfig = this.checkForNewAircraftConfigs(item);

        let promise;
        this.$store.commit(REQUEST_START);
        if ((item.inbound && item.inbound.common && item.inbound.common.id) || (item.outbound && item.outbound.common && item.outbound.common.id)) {
          promise = this.update(item);
        } else {
          promise = this.create(item);
        }

        promise
          .then(() => {
            this.close({ doFind: true });
            this.$store.commit(NOTIFICATION_SHOW, {
              message: this.$i18n.t('common.messageSuccess'),
              color: 'success',
            });
            if (newAircraftConfig) {
              this.$store.dispatch(FORCE_GET_AIRCRAFT_CONFIGS);
            }
            this.$store.commit(REQUEST_SUCCESS);
          })
          .catch((err) => {
            this.close();
            this.$store.commit(NOTIFICATION_SHOW, {
              message: this.$i18n.t('notifications.errorNotification'),
              color: 'error',
            });
            this.$store.commit(REQUEST_ERROR, err);
          });
      }
    },
    create(item) {
      return this.$http.post('scheduling/flights/single', item);
    },
    update(item) {
      return this.$http.put('scheduling/flights/single', item);
    },
    checkForNewAircraftConfigs(item) {
      if (!!item.inbound && !!item.outbound) {
        return (item.inbound.common.aircraftConfigId == null && item.inbound.common.aircraftConfigCode != null) || (item.outbound.common.aircraftConfigId == null && item.outbound.common.aircraftConfigCode != null);
      } if (item.inbound) {
        return item.inbound.common.aircraftConfigId == null && item.inbound.common.aircraftConfigCode != null;
      }
      return item.outbound.common.aircraftConfigId == null && item.outbound.common.aircraftConfigCode != null;
    },
    close(result) {
      this.selectedBound = '';
      this.$emit('close', result);
    },
    toggleContentVisibility() {
      this.isContentVisible = !this.isContentVisible;
    },
  },
};
</script>
<style scoped>
  .max-width-btn-toggle-header {
    max-width: 146px;
  }
  .separator-btn-header {
    background-color: red;
    width: 3px;
  }
  .v-btn-toggle .v-btn.v-btn--active.v-btn--disabled i {
    color: white !important;;
  }
</style>
