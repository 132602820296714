<template>
  <v-container pa-0>
    <v-layout row>
      <v-flex xs12 text-xs-center>
        <h3 class="subheading">{{ msg }}</h3>
        <v-btn @click="addItem" flat color="primary">
          <v-icon left>{{ btnIcon }}</v-icon>{{ btnText }}
        </v-btn>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: 'EmptyState',
  props: {
    msg: {
      type: String,
    },
    btnIcon: {
      type: String,
    },
    btnText: {
      type: String,
    },
  },
  methods: {
    addItem() {
      this.$emit('add-item');
    },
  },
};
</script>
