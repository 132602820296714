<template>
  <ag-grid-vue
    class="ag-theme-material"
    :defaultColDef="defaultColDef"
    :columnDefs="columnDefs"
    :rowData="items"
    :onGridReady="onGridReady"
    :suppressHorizontalScroll="true"
    rowSelection="multiple"
    :frameworkComponents="frameworkComponents"
    :gridOptions="gridOptions"
    @selection-changed="onSelectionChanged"
    style="width: 100%; height: calc(100vh - 500px);">
  </ag-grid-vue>
</template>
<script>
import { AgGridVue } from 'ag-grid-vue';
import AgGridApiHoldable from '../../ag-grid/mixins/AgGridApiHoldable.vue';
import AgGridTimeValueGetteable from '../../ag-grid/mixins/AgGridTimeValueGetteable.vue';
import AgGridOptionsConfigurable from '../../ag-grid/mixins/AgGridOptionsConfigurable.vue';
import Resizable from '../../mixins/Resizable.vue';
import AgGridValueComparable from '../../ag-grid/mixins/AgGridValueComparable.vue';
import { cellClassAgGridRules } from '../../ag-grid/mixins/cellClassAgGridRules';
import agGridTextCustomFilterComparator from '../../ag-grid/filters/agGridTextCustomFilterComparator';
import agGridTextCustomFilterFormatter from '../../ag-grid/filters/agGridTextCustomFilterFormatter';

export default {
  name: 'ticketing-base-grid',
  mixins: [AgGridApiHoldable, AgGridTimeValueGetteable, Resizable, AgGridValueComparable, AgGridOptionsConfigurable],
  components: {
    AgGridVue,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    const that = this;
    return {
      frameworkComponents: {
      },
      gridOptions: {
        getRowClass: this.getRowClass,
        getRowNodeId: this.getRowNodeId,
      },
      defaultColDef: {
        cellClassRules: cellClassAgGridRules,
        resizable: true,
        sortable: true,
        filter: true,
        filterParams: {
          textCustomComparator: agGridTextCustomFilterComparator,
          textFormatter: agGridTextCustomFilterFormatter,
        },
      },
      columnDefs: [
        {
          checkboxSelection: true,
          width: 30,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          suppressMenu: true,
          sortable: false,
          suppressMovable: true,
          suppressSizeToFit: true,
          filter: false,
          resizable: false,
        },
        {
          colId: 'ticketing.ticketingDate',
          headerValueGetter() {
            return that.$i18n.t('common.date');
          },
          field: 'ticketingDate',
          cellClass: 'date-cell separator',
          valueFormatter(params) {
            return that.$moment(params.data.ticketingDate).format('DD/MM/YYYY');
          },
        },
        {
          colId: 'ticketing.status',
          headerValueGetter() {
            return that.$i18n.t('common.status');
          },
          field: 'status',
          cellClass: 'airline-from-cell',
          valueGetter(params) {
            return params.data.status;
          },
        },
        {
          colId: 'ticketing.authorizationNumber',
          headerValueGetter() {
            return that.$i18n.t('ticketing.authorizationNumber');
          },
          field: 'authorizationNumber',
        },
        {
          colId: 'ticketing.flightNo',
          headerValueGetter() {
            return that.$i18n.t('common.flight');
          },
          cellClass: 'flight-number-cell separator',
          valueGetter(params) {
            return params.data.flightNo();
          },
        },
        {
          colId: 'ticketing.airlineFareName',
          headerValueGetter() {
            return that.$i18n.t('ticketing.fare');
          },
          field: 'airlineFareName',
        },
        {
          colId: 'ticketing.subtotal',
          headerValueGetter() {
            return that.$i18n.t('ticketing.subtotal');
          },
          field: 'subtotal',
        },
        {
          colId: 'ticketing.total',
          headerValueGetter() {
            return that.$i18n.t('ticketing.total');
          },
          field: 'total',
        },
        {
          colId: 'ticketing.totalAirline',
          headerValueGetter() {
            return that.$i18n.t('ticketing.totalAirline');
          },
          field: 'totalAirline',
        },
      ],
    };
  },
  methods: {
    onGridReady(params) {
      this.setAgGridApi(params);
      this.selectAll();
    },
    selectAll() {
      this.gridApi.selectAllFiltered();
    },
    onSelectionChanged() {
      const selectedNodes = this.gridApi.getSelectedNodes();
      this.$emit('selected-changed', selectedNodes.map(node => node.data));
    },
  },
};
</script>
<style>
</style>
