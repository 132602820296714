<template>
  <div class="px-3 py-2">
    <p>{{ item.contact.description }}</p>
  </div>
</template>

<script>
export default {
  name: 'CardDescriptionText',
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
};
</script>
