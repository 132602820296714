<template>
  <div>
    <v-tooltip left>
      <template v-slot:activator="{ on }">
          <v-icon
            color="secondary"
            small
            class="mr-2"
            @click.stop="params.boardingItem(params.data)"
          >
            flight_takeoff
          </v-icon>
      </template>
      <span>Boarding Form</span>
    </v-tooltip>
  </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
});
</script>

<style scoped>

</style>
