import { VBtn } from 'vuetify/lib/components/VBtn';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VLayout,{staticClass:"flex justify-center"},[_c(VFlex,[_c(VToolbar,{attrs:{"dark":"","dense":"","color":"primary lighten-1 d-flex justify-center"}},[_c(VBtn,{attrs:{"icon":""},on:{"click":_vm.backArrow}},[_c(VIcon,[_vm._v("keyboard_backspace")])],1),_c(VSpacer),_c('h6',{staticClass:"title ma-0 text-xs-center"},[(_vm.flightMobile.bound === 'INBOUND')?_c(VIcon,[_vm._v("flight_land")]):(_vm.flightMobile.bound === 'OUTBOUND')?_c(VIcon,[_vm._v("flight_takeoff")]):_vm._e(),_vm._v(" "+_vm._s(_vm.flightMobile.airlineCode + _vm.flightMobile.tripNumber)+" "+_vm._s(_vm.flightMobile.airport1Code)+" ")],1),_c(VSpacer),_c('h6',{staticClass:"title ma-0"},[_vm._v(" "+_vm._s(_vm.getFiltererDate()))])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }