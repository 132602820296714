<script>
import Vue from 'vue';

export default Vue.extend({
  methods: {
    agGridTimeGetter(dateTime, referenceDateTime, minTime, maxTime, isRequired = false) {
      return {
        value: dateTime ? this.$moment(dateTime) : null,
        referenceDate: referenceDateTime ? this.$moment(referenceDateTime) : null,
        minTime: minTime ? this.$moment(minTime) : null,
        maxTime: maxTime ? this.$moment(maxTime) : null,
        required: isRequired,
      };
    },
  },
});
</script>
