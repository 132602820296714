import { JSUtils } from '../../../shared/js/JSUtils';

export default class AirlineFareField {
  constructor(
    name,
    type,
    required,
    relatedFields,
  ) {
    this.name = name;
    this.type = type;
    this.required = required;
    this.relatedFields = relatedFields;
  }

  clone() {
    return AirlineFareField.createAirlineFareFieldFromData(JSON.parse(JSON.stringify(this)));
  }

  static get createEmptyAirlineFareField() {
    return new AirlineFareField();
  }

  static createAirlineFareFieldFromData(o) {
    if (!o) {
      return this.createEmptyAirlineFareField;
    }

    return JSUtils.cast(o, AirlineFareField);
  }
}
