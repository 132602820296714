<template>
  <v-container grid-list-md text-xs-center fluid>

    <span style="font-weight: bold">{{$t('commonForm.flightInformation')}}</span>

    <v-layout row wrap>

      <v-flex xs1 class="inputDelivery">
        <span style="font-weight: bold">{{$t('commonForm.flight')}}:</span>
      </v-flex>
      <v-flex xs6>
        <flight-selector-container :bound="bound" :date="delivery.flightDate" :rules="[rules.required]" @flightSelected="setFlight"/>
      </v-flex>

    </v-layout>
  </v-container>
</template>
<script>
import FlightSelectorContainer from '../../../ui/flightSelector/FlightSelectorContainer.vue';
import { FlightBound } from '../../../shared/model/flightBound';
import DateInput from '../../../ui/DateInput.vue';

export default {
  name: 'delivery-internal-form',
  components: {
    FlightSelectorContainer,
    DateInput,
  },
  props: {
    deliveryOriginal: {},
  },
  data() {
    return {
      bound: FlightBound.INBOUND.description,
      delivery: this.deliveryOriginal,
      rules: {
        required: value => !!value || 'Required.',
      },
    };
  },
  watch: {
    deliveryOriginal: {
      handler(newDeliveryProp) {
        this.delivery = newDeliveryProp;
      },
      deep: true,
    },
    delivery: {
      handler(newDelivery) {
        this.$emit('delivery-update', newDelivery);
      },
      deep: true,
    },
  },
  methods: {

    setFlight(flight) {
      this.delivery.flightId = flight.id;
      this.delivery.flightTripNumber = flight.tripNumber;
      this.delivery.flightDate = flight.flightDate;
      this.delivery.airlineId = flight.airlineId;
      this.delivery.airlineCode = flight.airlineCode;
    },
  },
};
</script>
<style scoped>
  .v-btn-toggle .v-btn.v-btn--active.v-btn--disabled i {
    color: white !important;;
  }

  .inputDelivery {
    margin: auto 0;
  }
</style>
