import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VItem } from 'vuetify/lib/components/VItemGroup';
import { VItemGroup } from 'vuetify/lib/components/VItemGroup';

var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.isEmptyRemarks())?_c(VContainer,{staticClass:"infoMobileRemarks"},[_c(VCard,{attrs:{"dark":this.$vuetify.dark}},[_c(VCardText,[_c('div',{staticClass:"font-weight-bold ms-2 mb-2"},[_vm._v(" "+_vm._s(_vm.$t('operations.remarksExtended'))+" ")]),_c(VItemGroup,[(_vm.flightMobileRemarks.baggageYardRemarks)?_c(VItem,[_c('div',{staticClass:"mb-0"},[_c('div',{staticClass:"font-weight-normal"},[_c('strong',[_vm._v(_vm._s(_vm.$t('operations.remarksModalBaggageYardShort')))])]),_c('div',[_vm._v(_vm._s(_vm.flightMobileRemarks.baggageYardRemarks))])])]):_vm._e(),(_vm.flightMobileRemarks.loadRemarks)?_c(VItem,[_c('div',{staticClass:"mb-0"},[_c('div',{staticClass:"font-weight-normal"},[_c('strong',[_vm._v(_vm._s(_vm.$t('operations.remarksModalLoadShort')))])]),_c('div',[_vm._v(_vm._s(_vm.flightMobileRemarks.loadRemarks))])])]):_vm._e(),(_vm.flightMobileRemarks.operationRemarks)?_c(VItem,[_c('div',{staticClass:"mb-0"},[_c('div',{staticClass:"font-weight-normal"},[_c('strong',[_vm._v(_vm._s(_vm.$t('operations.remarksModalOperationsShort')))])]),_c('div',[_vm._v(_vm._s(_vm.flightMobileRemarks.operationRemarks))])])]):_vm._e(),(_vm.flightMobileRemarks.passageRemarks)?_c(VItem,[_c('div',{staticClass:"mb-0"},[_c('div',{staticClass:"font-weight-normal"},[_c('strong',[_vm._v(_vm._s(_vm.$t('operations.remarksModalPassengerShort')))])]),_c('div',[_vm._v(_vm._s(_vm.flightMobileRemarks.passageRemarks))])])]):_vm._e(),(_vm.flightMobileRemarks.rampRemarks)?_c(VItem,[_c('div',{staticClass:"mb-0"},[_c('div',{staticClass:"font-weight-normal"},[_c('strong',[_vm._v(_vm._s(_vm.$t('operations.remarksModalRampShort')))])]),_c('div',[_vm._v(_vm._s(_vm.flightMobileRemarks.rampRemarks))])])]):_vm._e()],1)],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }