<template>
    <v-dialog v-model="dialogValue" max-width="860px" persistent>
        <v-card :dark="this.$vuetify.dark" :color="this.$vuetify.dark ? 'bggrey_dark' : 'bggrey'">
          <v-toolbar dark color="primary">
            <v-icon>flight</v-icon>
            <v-toolbar-title>{{$t("messages.associateFlight")}}</v-toolbar-title>
          </v-toolbar>

          <v-card-text>

            <v-form ref="form" v-model="valid">
              <v-container grid-list-md text-xs-center fluid>

                <span class="titleText">{{$t('commonForm.flightInformation')}}</span>

                <v-layout row wrap text-xs-left>

                  <v-flex xs2 text-xs-right class="inputFlightMessage">
                    <span class="titleText">{{$t('commonForm.flight')}}:</span>
                  </v-flex>
                  <v-flex xs10>
                    <flight-selector-container :rules="[rules.required]" @flightSelected="setFlight"/>
                  </v-flex>

                </v-layout>

                <v-flex xs12 pb-3>
                  <span class="titleText">{{$t('messages.messageInformation')}}</span>
                </v-flex>
                <v-layout row wrap text-xs-left>

                  <v-flex xs12>
                    <v-layout row >

                      <v-flex xs2 text-xs-right class="inputFlightMessage">
                        <span class="titleText">{{$t('messages.timestamp')}}:</span>
                      </v-flex>

                      <v-flex xs3 offset-xs1 >
                        {{this.$moment(flightMessage.ts).format('DD/MM/YYYY HH:mm')}}
                      </v-flex>

                      <v-flex xs2 text-xs-right class="inputFlightMessage">
                        <span class="titleText">{{$t('messages.type')}}:</span>
                      </v-flex>

                      <v-flex xs3 offset-xs1>
                        {{flightMessage.typeCode}}
                      </v-flex>

                    </v-layout>

                  </v-flex>

                  <v-flex xs12>
                    <v-layout row >

                      <v-flex xs2 text-xs-right>
                        <span class="titleText">{{$t('messages.content')}}:</span>
                      </v-flex>
                      <v-flex xs9 offset-xs1>
                        <p class="contentMessage">{{flightMessage.content}}</p>
                      </v-flex>

                    </v-layout>

                  </v-flex>

                </v-layout>

              </v-container>
            </v-form>


          </v-card-text>

          <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" flat @click.native="close">{{$t("common.cancel")}}</v-btn>
              <v-btn color="primary" depressed @click.native="save" :disabled="!valid">{{$t("common.save")}}</v-btn>
          </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>


import { REQUEST_ERROR, REQUEST_START, REQUEST_SUCCESS } from '../../../store/store';
import { NOTIFICATION_SHOW } from '../../../store/modules/notifications';
import FlightSelectorContainer from '../../ui/flightSelector/FlightSelectorContainer.vue';
import DateInput from '../../ui/DateInput.vue';
import TimeInput from '../../ui/TimeInput.vue';

export default {
  name: 'flight-message-associate-flight-modal',
  components: {
    FlightSelectorContainer,
    DateInput,
    TimeInput,
  },
  props: {
    dialog: { type: Boolean, required: true },
    flightMessage: { },
  },
  data() {
    return {
      valid: true,
      rules: {
        required: value => !!value || 'Required.',
      },
    };
  },
  computed: {
    dialogValue() {
      return this.dialog;
    },
  },
  methods: {
    setFlight(flight) {
      this.flightMessage.flightId = flight.id;
      this.flightMessage.flightTripNumber = flight.tripNumber;
      this.flightMessage.flightDate = flight.flightDate;
      this.flightMessage.flightScheduledTime = flight.flightScheduledTime;
    },

    save() {
      if (this.$refs.form.validate()) {
        this.$store.commit(REQUEST_START);
        this.$http.put(`messages/link/${this.flightMessage.flightId}`, this.flightMessage)
          .then(() => {
            this.close({ doFind: true });
            this.$store.commit(NOTIFICATION_SHOW, {
              message: this.$i18n.t('messages.associateFlightSuccess'),
              color: 'success',
            });
            this.$store.commit(REQUEST_SUCCESS);
          })
          .catch((err) => {
            this.close();
            this.$store.commit(NOTIFICATION_SHOW, {
              message: this.$i18n.t('notifications.errorNotification'),
              color: 'error',
            });
            this.$store.commit(REQUEST_ERROR, err);
          });
      }
    },
    close(result) {
      this.$emit('close', result);
    },
  },
};
</script>
<style scoped>
  .max-width-btn-toggle-header {
    max-width: 146px;
  }
  .separator-btn-header {
    background-color: red;
    width: 3px;
  }
  .v-btn-toggle .v-btn.v-btn--active.v-btn--disabled i {
    color: white !important;;
  }
  .inputFlightMessage {
    margin: auto 0;
  }
  .titleText {
    font-weight: bold
  }
  .contentMessage {
    white-space: pre-line !important;
    line-height: normal !important;
    text-align: left !important;
    font-family: "Courier New", Courier, monospace !important;
  }
</style>
