/* eslint no-param-reassign: "error" */
import Vue from 'vue';

const MARGIN = 1;
const DATE_WIDTH_IN_CH = 15;

function addClearable(binding) {
  if (binding && binding.modifiers.clearable) {
    return 8;
  }
  return 0;
}

Vue.directive('char-length', {
  bind(el, binding) {
    if (binding && binding.modifiers.date) {
      el.style.maxWidth = `${DATE_WIDTH_IN_CH}ch`;
    }

    if (binding && binding.value && el) {
      el.style.maxWidth = `${binding.value + MARGIN + addClearable(binding)}ch`;
    }
  },
});
