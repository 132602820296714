<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>

    <ag-grid-dialog-template-show :is-dialog-open="isDialogOpen" @cancel="cancel">

      <template v-slot:header-icon>
        <v-icon>confirmation_number</v-icon>
      </template>

      <template v-slot:header>
        {{$t('ticketing.statusModalTitle')}}
      </template>

      <template v-slot:default>
        <v-card-text>
            <v-container grid-list-md>
              <v-layout>
                <v-flex xs2>
                  <date-input :locale="$i18n.locale" :label="$t('common.date')" v-model="params.cancellationDate" disabled/>
                </v-flex>
                <v-flex xs10>
                  <v-text-field :value="params.cancellationReason" :label="$t('scheduling.cancellationFlightReason')" readonly/>
                </v-flex>
              </v-layout>
            </v-container>
        </v-card-text>
      </template>


    </ag-grid-dialog-template-show>

  </div>
</template>

<script>
import Vue from 'vue';
import AgGridDialogTemplateShow from './templates/AgGridDialogTemplateShow.vue';
import AgGridDialogParentCellEditor from './mixins/AgGridDialogParentCellEditor.vue';
import DateInput from '../../ui/DateInput.vue';

export default Vue.extend({
  mixins: [AgGridDialogParentCellEditor],
  components: {
    AgGridDialogTemplateShow,
    DateInput,
  },
  data() {
    return {
      isDialogOpen: true,
    };
  },
  computed: {
    rolePermission() {
      return `${this.$route.meta.role}_WR`;
    },
  },
  methods: {
    getValue() {
      return this.params.value;
    },
  },
});
</script>
<style lang="scss" scoped>

</style>
