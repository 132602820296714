<template>
  <v-container grid-list-md text-xs-center fluid>

    <span style="font-weight: bold">{{$t('commonForm.flightInformation')}}</span>
    <v-layout row wrap>

      <v-flex xs6>
        <flight-selector-container :bound="bound" :date="rushBaggage.flightDate" :rules="[rules.required]" @flightSelected="setFlight"/>
      </v-flex>

      <v-flex xs2 class="inputFlight">
        <v-text-field :label="$t('operations.scheduledTimeOfDeparture') " readonly
                      :value="rushBaggage.scheduledTime != null ? this.$moment(rushBaggage.scheduledTime).format('HH:mm') : null"></v-text-field>
      </v-flex>

      <v-flex xs2 class="inputFlight">
        <v-text-field :label="$t('operations.airportOfDestination') " readonly v-model="rushBaggage.airport1Code"></v-text-field>
      </v-flex>

    </v-layout>

    <span style="font-weight: bold">{{$t('lostAndFound.rushBaggageInformation')}}</span>

    <v-layout row wrap>

      <v-flex xs3 class="inputFlight">
        <v-text-field :label="$t('lostAndFound.paxNameFileReference')" v-model="rushBaggage.passengerName"></v-text-field>
      </v-flex>

      <v-flex xs2 class="inputFlight">
        <v-text-field :label="$t('lostAndFound.rushTag')" v-model="rushBaggage.rushTag"></v-text-field>
      </v-flex>

      <v-flex xs2 class="inputFlight">
        <v-text-field :label="$t('lostAndFound.originalTag')" v-model="rushBaggage.originalTag"></v-text-field>
      </v-flex>

      <v-flex xs2 class="inputFlight">
        <v-text-field :label="$t('lostAndFound.agent')" v-model="rushBaggage.agent"></v-text-field>
      </v-flex>

      <v-flex xs1 class="inputFlight">
        <v-text-field :label="$t('lostAndFound.rl')" v-model="rushBaggage.rl" :rules="[rules.length2]"></v-text-field>
      </v-flex>

    </v-layout>
  </v-container>
</template>
<script>
import FlightSelectorContainer from '../../../ui/flightSelector/FlightSelectorContainer.vue';
import { FlightBound } from '../../../shared/model/flightBound';

export default {
  name: 'rush-baggage-flight-internal-form',
  components: { FlightSelectorContainer },
  props: {
    rushBaggageOriginal: {},
  },
  data() {
    return {
      bound: FlightBound.OUTBOUND.description,
      rushBaggage: this.rushBaggageOriginal,
      rules: {
        required: value => !!value || 'Required.',
        length2: value => value.length <= 2 || 'Max characters: 2',
      },
    };
  },
  watch: {
    rushBaggageOriginal: {
      handler(newRushBaggageProp) {
        this.rushBaggage = newRushBaggageProp;
      },
      deep: true,
    },
    rushBaggage: {
      handler(newRushBaggage) {
        this.$emit('rush-baggage-update', newRushBaggage);
      },
      deep: true,
    },
  },
  methods: {

    setFlight(flight) {
      this.rushBaggage.flightId = flight.id;
      this.rushBaggage.flightTripNumber = flight.tripNumber;
      this.rushBaggage.flightDate = flight.flightDate;
      this.rushBaggage.scheduledTime = flight.scheduledTime;
      this.rushBaggage.airport1Id = flight.airport1Id;
      this.rushBaggage.airport1Code = flight.airport1Code;
      this.rushBaggage.airlineId = flight.airlineId;
      this.rushBaggage.airlineCode = flight.airlineCode;
    },
  },
};
</script>
<style scoped>
  .v-btn-toggle .v-btn.v-btn--active.v-btn--disabled i {
    color: white !important;;
  }

  .inputFlight {
    margin: auto 1em;
  }
</style>
