/* eslint-disable no-continue,no-labels,no-restricted-syntax */

import moment from 'moment';
import SchedulingLinkOptions from './schedulingLinkOptions';
import ConnectedFlights from '../../shared/model/connectedFlights';
import FlightComposition from '../../shared/model/flightComposition';
import FlightCommonInformation from './flightCommonInformation';

export default class SchedulingFlightUtils {
  static getLinkableFlights(schedulingFlights, linkOptions) {
    if (schedulingFlights && !(schedulingFlights instanceof Array)) {
      throw new Error('Parameter schedulingFlights is not valid Array instance');
    }
    if (linkOptions && !(linkOptions instanceof SchedulingLinkOptions)) {
      throw new Error('Parameter linkOptions is not valid SchedulingLinkOptions instance');
    }

    const linkable = [];
    if (schedulingFlights) {
      const sortedFlights = this.sortSchedulingFlights(schedulingFlights).filter(item => !(item.hasInbound() && item.hasOutbound()));
      main: for (let i = 0; i < sortedFlights.length; i += 1) {
        const item = sortedFlights[i];
        if (item.hasInbound() && item.hasOutbound()) {
          continue;
        }
        if (item.hasInbound()) {
          for (let j = i + 1; j < sortedFlights.length; j += 1) {
            const nextItem = sortedFlights[j];
            if (!nextItem.hasOutbound()) {
              continue;
            }
            if (linkable.some(linked => linked.outbound.common.id === nextItem.outbound.common.id)) {
              continue;
            }
            if (this.areFlightsCriteriaCompliant(item.inbound, nextItem.outbound, linkOptions)) {
              linkable.push(new ConnectedFlights(item.inbound, nextItem.outbound));
              continue main;
            }
          }
        }
      }
    }
    return linkable;
  }

  static getNotLinkableFlightsValidationMessages(schedulingFlights, linkOptions) {
    if (schedulingFlights && !(schedulingFlights instanceof Array)) {
      throw new Error('Parameter schedulingFlights is not valid Array instance');
    }
    if (linkOptions && !(linkOptions instanceof SchedulingLinkOptions)) {
      throw new Error('Parameter linkOptions is not valid SchedulingLinkOptions instance');
    }

    const validationMessages = [];
    if (schedulingFlights) {
      let flightIn;
      let flightOut;
      const sortedFlights = this.sortSchedulingFlights(schedulingFlights);
      if (sortedFlights && sortedFlights.length > 0) {
        const flightOne = sortedFlights[0];
        const flightTwo = sortedFlights[1];
        if (flightOne.hasInbound() && !flightOne.hasOutbound()) {
          flightIn = flightOne.inbound;
          if (!flightTwo.hasInbound() && flightTwo.hasOutbound()) {
            flightOut = flightTwo.outbound;
          }
        }
        if (!flightIn || !flightOut) {
          flightIn = null;
          flightOut = null;
          if (flightTwo.hasInbound() && !flightTwo.hasOutbound()) {
            flightIn = flightTwo.inbound;
            if (!flightOne.hasInbound() && flightOne.hasOutbound()) {
              flightOut = flightOne.outbound;
            }
          }
        }
        if (!flightIn || !flightOut) {
          validationMessages.push('Incorrect in/out configuration');
        } else {
          this.areFlightsCriteriaCompliant(flightIn, flightOut, linkOptions, validationMessages);
        }
      }
    }
    return validationMessages;
  }

  static sortSchedulingFlights(schedulingFlights) {
    if (schedulingFlights && !(schedulingFlights instanceof Array)) {
      throw new Error('Parameter schedulingFlights is not valid Array instance');
    }

    if (!schedulingFlights) {
      return null;
    }
    return schedulingFlights.slice().sort((itemA, itemB) => {
      let flightA;
      let flightB;
      if (itemA.hasInbound()) {
        flightA = itemA.inbound;
      } else {
        flightA = itemA.outbound;
      }
      if (itemB.hasInbound()) {
        flightB = itemB.inbound;
      } else {
        flightB = itemB.outbound;
      }
      return this.getBestTimeOrDateDifference(flightA, flightB);
    });
  }

  static getBestTimeOrDateDifference(flight1, flight2) {
    if (!flight1 || !(flight1 instanceof FlightComposition)) {
      throw new Error('Parameter flight1 is not valid Flight instance');
    }
    if (!flight2 || !(flight2 instanceof FlightComposition)) {
      throw new Error('Parameter flight2 is not valid Flight instance');
    }

    const cmp1 = this.getTime(flight1);
    const cmp2 = this.getTime(flight2);
    return cmp1.diff(cmp2, 'minutes', true);
  }

  static getTime(flight) {
    if (flight.times && flight.times.bestTime) {
      return moment(flight.times.bestTime);
    }
    if (flight.common && flight.common.scheduledTime) {
      return moment(flight.common.scheduledTime);
    }
    if (flight.common) {
      return moment(flight.common.flightDate);
    }
    return null;
  }

  static areFlightsCriteriaCompliant(flightComposition1, flightComposition2, linkOptions, validationMessages) {
    if (!flightComposition1 || !(flightComposition1 instanceof FlightComposition)) {
      throw new Error('Parameter flight1 is not valid Flight instance');
    }
    if (!flightComposition2 || !(flightComposition2 instanceof FlightComposition)) {
      throw new Error('Parameter flight2 is not valid Flight instance');
    }
    if (linkOptions && !(linkOptions instanceof SchedulingLinkOptions)) {
      throw new Error('Parameter linkOptions is not valid SchedulingLinkOptions instance');
    }
    if (validationMessages && !(validationMessages instanceof Array)) {
      throw new Error('Parameter validationMessages is not valid Array instance');
    }

    let linkCriteria = linkOptions;
    if (!linkCriteria) {
      linkCriteria = SchedulingLinkOptions.createDefaultOptions();
    }
    let flight1 = flightComposition1.common;
    if (!flight1) {
      flight1 = FlightCommonInformation.createEmptyFlightCommonInformation;
    }
    let flight2 = flightComposition2.common;
    if (!flight2) {
      flight2 = FlightCommonInformation.createEmptyFlightCommonInformation;
    }
    const diff = Math.abs(this.getBestTimeOrDateDifference(flightComposition1, flightComposition2));
    if (diff < parseInt(linkCriteria.minGroundTime, 10)) {
      if (validationMessages) {
        validationMessages.push(`Scheduled times difference is less than min ground time (${linkCriteria.minGroundTime} minutes)`);
      }
      return false;
    }
    if (diff > parseInt(linkCriteria.maxGroundTime, 10)) {
      if (validationMessages) {
        validationMessages.push(`Scheduled times difference is greater than max ground time (${linkCriteria.maxGroundTime} minutes)`);
      }
      return false;
    }
    if (linkCriteria.airlineCriteria) {
      if (!flight1.airlineId || !flight2.airlineId) {
        if (validationMessages) {
          validationMessages.push('Airline criteria is enabled and some flight has not value');
        }
        return false;
      }
      if (flight1.airlineId !== flight2.airlineId) {
        if (validationMessages) {
          validationMessages.push('Airline criteria is enabled and flights have different values');
        }
        return false;
      }
    }
    if (linkCriteria.airlineOperationCriteria) {
      if (flight1.airlineOperationId && flight2.airlineOperationId && flight1.airlineOperationId !== flight2.airlineOperationId) {
        if (validationMessages) {
          validationMessages.push('Airline operation criteria is enabled and flights have different values');
        }
        return false;
      }
    }
    if (linkCriteria.aircraftTypeCriteria) {
      if (!flight1.aircraftTypeId || !flight2.aircraftTypeId) {
        if (validationMessages) {
          validationMessages.push('Aircraft type criteria is enabled and some flight has not value');
        }
        return false;
      }
      if (flight1.aircraftTypeId !== flight2.aircraftTypeId) {
        if (validationMessages) {
          validationMessages.push('Aircraft type criteria is enabled and flights have different values');
        }
        return false;
      }
    }
    if (linkCriteria.aircraftCriteria) {
      if (flight1.aircraftId !== flight2.aircraftId) {
        if (validationMessages) {
          validationMessages.push('Aircraft criteria is enabled and flights have different values');
        }
        return false;
      }
    }
    return true;
  }
}
