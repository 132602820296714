import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VForm,{ref:"formOptions"},[_c(VContainer,{attrs:{"fluid":""}},[_c(VLayout,{attrs:{"row":""}},[_c(VFlex,[_c(VRadioGroup,{attrs:{"label":this.$i18n.t('schedulingImport.fileType')},model:{value:(_vm.importOptions.fileType),callback:function ($$v) {_vm.$set(_vm.importOptions, "fileType", $$v)},expression:"importOptions.fileType"}},[_c(VRadio,{attrs:{"label":this.$i18n.t('schedulingImport.csvDaily'),"value":"CSV_DAILY"}}),_c(VRadio,{attrs:{"label":this.$i18n.t('schedulingImport.csvSeasonal'),"value":"CSV_SEASONAL"}})],1)],1),_c(VFlex,[_c(VRadioGroup,{attrs:{"label":this.$i18n.t('schedulingImport.fileDelimitier')},model:{value:(_vm.importOptions.delimitierCharacter),callback:function ($$v) {_vm.$set(_vm.importOptions, "delimitierCharacter", $$v)},expression:"importOptions.delimitierCharacter"}},[_c(VRadio,{attrs:{"label":this.$i18n.t('schedulingImport.semicolon'),"value":";"}}),_c(VRadio,{attrs:{"label":this.$i18n.t('schedulingImport.comma'),"value":","}})],1)],1),_c(VFlex,[_c(VRadioGroup,{attrs:{"label":this.$i18n.t('schedulingImport.timeZoneMode')},model:{value:(_vm.importOptions.timeZoneMode),callback:function ($$v) {_vm.$set(_vm.importOptions, "timeZoneMode", $$v)},expression:"importOptions.timeZoneMode"}},[_c(VRadio,{attrs:{"label":"UTC","value":"UTC"}}),_c(VRadio,{attrs:{"label":"LOCAL","value":_vm.localTimezone}})],1)],1),_c(VFlex,[_c(VTextField,{attrs:{"type":"number","label":this.$i18n.t('schedulingImport.linesToPreview'),"min":"1","max":"50","rules":_vm.linesToPreviewRules},model:{value:(_vm.importOptions.linesToPreview),callback:function ($$v) {_vm.$set(_vm.importOptions, "linesToPreview", $$v)},expression:"importOptions.linesToPreview"}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }