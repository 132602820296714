<template>
  <div>
    <aircraft-type-select :ref="'refComp'"  v-on:keydown.native="keyEvent" v-char-length.clearable="4" v-model="aircraftTypeIdUpdated"></aircraft-type-select>
  </div>
</template>

<script>
import Vue from 'vue';
import AircraftTypeSelect from '../../../aircraft/AircraftTypeSelect.vue';
import AgGridParentConnectedFlightsCellEditor from '../mixins/AgGridParentConnectedFlightsCellEditor.vue';
import { FlightBound } from '../../../shared/model/flightBound';

export default Vue.extend({
  mixins: [AgGridParentConnectedFlightsCellEditor],
  components: {
    AircraftTypeSelect,
  },
  data() {
    return {
      aircraftTypeIdOriginal: null,
      aircraftIdOriginal: null,
      aircraftTypeIdUpdated: null,
      flight: { aircraftTypeId: null },
    };
  },
  methods: {
    getValue() {
      this.unmarkCellsSelectedAsEdit();

      if (this.aircraftTypeIdOriginal !== this.aircraftTypeIdUpdated) {
        const aircraftType = this.$store.getters.getAircraftTypeById(this.aircraftTypeIdUpdated);
        const code = typeof (aircraftType) !== 'undefined' ? aircraftType.iataCode : undefined;

        const that = this;

        const valueCell = [
          { id: 'common.aircraftTypeId', value: this.aircraftTypeIdUpdated, initialValue: this.aircraftTypeIdOriginal },
          { id: 'common.aircraftId', value: null, initialValue: this.aircraftIdOriginal },
        ];
        this.saveFlightChangesFromCellValue((s) => {
          s.setFlightPropertyAllBound('aircraftTypeId', that.aircraftTypeIdUpdated); // FIXME implementar setter análogo a getChildPropertyvalue
          s.setFlightPropertyAllBound('aircraftTypeIataCode', code);
          s.setFlightPropertyAllBound('aircraftId', undefined);
          s.setFlightPropertyAllBound('aircraftRegisterNumber', undefined);
        }, 'Aircraft Type', valueCell, () => {
          this.params.api.refreshCells({
            force: true,
          });
        });

        return code;
      }
      return this.flight.aircraftTypeId;
    },
  },
  created() {
    const connectedFlights = this.params.node.data;
    this.aircraftTypeIdOriginal = connectedFlights.getChildPropertyValue(FlightBound.ANY, ['common', 'aircraftTypeId']);
    this.aircraftIdOriginal = connectedFlights.getChildPropertyValue(FlightBound.ANY, ['common', 'aircraftId']);
    this.aircraftTypeIdUpdated = this.aircraftTypeIdOriginal;

    this.markCellsSelectedAsEdit();
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$refs.refComp) {
        this.$refs.refComp.$refs.refComp.focus();
      }
    });
  },
});
</script>

<style>

.aircraft-type-cell.ag-cell-inline-editing {
  min-width: 95px;
}

</style>
