<template>
    <div>
      <flight-number-select :ref="'refComp'" v-on:keydown.native="keyEvent" v-char-length.clearable="4" :items="items" v-model="itemId" @input-code="setItemCode($event)"></flight-number-select>
    </div>
</template>

<script>
import Vue from 'vue';
import AgGridParentCellEditor from './mixins/AgGridParentCellEditor.vue';
import FlightNumberSelect from '../../flights/FlightNumberSelect.vue';

export default Vue.extend({
  mixins: [AgGridParentCellEditor],
  components: {
    FlightNumberSelect,
  },
  data() {
    return {
      itemId: null,
      itemCode: null,
      items: null,
    };
  },
  methods: {
    getValue() {
      if (this.params.column.colDef && this.params.column.colDef.cellEditorCustomConfig && this.params.column.colDef.cellEditorCustomConfig.fieldId) {
        this.params.node.data[this.params.column.colDef.cellEditorCustomConfig.fieldId] = this.itemId;
      }
      this.beforeReturnGetValue();
      return this.itemCode;
    },
    setItemCode(code) {
      this.itemCode = code;
    },
  },
  created() {
    if (this.params.column.colDef && this.params.column.colDef.cellEditorCustomConfig) {
      if (this.params.column.colDef.cellEditorCustomConfig.fieldId) {
        this.itemId = this.params.node.data[this.params.column.colDef.cellEditorCustomConfig.fieldId];
      }
      if (this.params.column.colDef.cellEditorCustomConfig.fieldValues) {
        this.items = this.params.column.colDef.cellEditorCustomConfig.fieldValues;
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$refs.refComp) {
        this.$refs.refComp.$refs.refComp.focus();
      }
    });
  },
});
</script>

<style lang="scss" scoped>

</style>
