import { JSUtils } from '../../shared/js/JSUtils';

export default class FlightExtendedInformation {
  constructor(
    id,
    delay,
    messageStatus,
    airport1Schengen,
    gateAgentPresence,
  ) {
    this.id = id;
    this.delay = delay;
    this.messageStatus = messageStatus;
    this.airport1Schengen = airport1Schengen;
    this.gateAgentPresence = gateAgentPresence;
  }

  static get createEmptyFlightExtendedInformation() {
    return new FlightExtendedInformation();
  }

  static createFlightExtendedInformationFromData(o) {
    if (!o) {
      return this.createEmptyFlightExtendedInformation;
    }

    return JSUtils.cast(o, FlightExtendedInformation);
  }
}
