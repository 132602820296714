<template>
  <div class="lost-and-found-flights list-filter">
    <v-card :dark="this.$vuetify.dark" :color="this.$vuetify.dark ? 'bggrey_dark' : 'bggrey'">
      <v-toolbar dark dense color="primary lighten-1">
        <v-btn v-on:click="displayedFilter" small flat>
          <v-icon>filter_list</v-icon>
          <v-icon v-if="!showFilter">keyboard_arrow_down</v-icon>
          <v-icon v-if="showFilter">keyboard_arrow_up</v-icon>
        </v-btn>

        <v-divider vertical light style="margin-left: 30px; margin-right: 30px"></v-divider>

        <lost-and-found-flights-list-filter-date-selector />

        <v-divider vertical light style="margin-left: 30px; margin-right: 30px"></v-divider>

        <lost-and-found-flights-list-filter-archived />

        <v-divider vertical light style="margin-left: 30px; margin-right: 30px"></v-divider>

        <v-spacer></v-spacer>

        <v-divider vertical light style="margin-left: 30px; margin-right: 30px"></v-divider>

        <v-flex grow="false" shrink="true">
          <audit-flights-changes-list />
        </v-flex>

        <LostAndFoundListTableOptionsFilterButton />
      </v-toolbar>
      <v-container fluid v-if="showFilter" class="pa-2 pb-2">
        <v-layout class="filter-inline py-0" align-center justify-start>
          <v-flex class="mr-2" align-center justify-start>
            <v-switch
              slot="activator"
              color="red"
              :label="this.$i18n.t('commonFilter.showOldValues')"
              v-model="showOldValues"
              hide-details
              @change="updateShowOldValuesInStore()">
            </v-switch>
          </v-flex>

          <v-flex class="mr-2" align-center justify-start>
            <v-switch
              slot="activator"
              color="red"
              :label="this.$i18n.t('commonFilter.flashOnChanges')"
              v-model="flashOnChanges"
              hide-details
              @change="updateFlashOnChangesInStore()">
            </v-switch>
          </v-flex>

          <v-spacer style="width: 45%"></v-spacer>
          <v-flex class="ml-2 text-no-wrap" align-center justify-start>
            <v-btn color="secondary" flat round v-on:click.stop="exportCSV" block>
              <v-icon>mdi-download</v-icon>
              {{ $t("common.export") }}
            </v-btn>
          </v-flex>
          <v-flex class="ml-2 text-no-wrap" align-center justify-start>
          <v-btn color="primary" round v-on:click.stop="find" block>
            <v-icon>search</v-icon>
            {{ $t("common.find") }}
          </v-btn>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card>
  </div>

</template>
<script>
import AgGridApiCsvExportable from '../../ag-grid/mixins/AgGridApiCsvExportable.vue';
import StoreSubscribable from '../../mixins/StoreSubscribable.vue';
import {
  LOST_AND_FOUND_FIND_FLIGHTS,
  LOST_AND_FOUND_SET_GRID_API,
  UPDATE_FLASH_ON_CHANGES,
  UPDATE_SHOW_OLD_VALUES,
} from '../../../store/modules/lostAndFound/lostAndFound';
import LostAndFoundFlightsListFilterArchived from './LostAndFoundFlightsListFilterArchived.vue';
import LostAndFoundFlightsListFilterDateSelector from './LostAndFoundFlightsListFilterDateSelector.vue';
import AuditFlightsChangesList from '../../audit/list/AuditFlightsChangesList.vue';
import LostAndFoundListTableOptionsFilterButton from './LostAndFoundListTableOptionsFilterButton.vue';

export default {
  name: 'ramp-flights-list-filter',
  mixins: [AgGridApiCsvExportable, StoreSubscribable],
  components: {
    LostAndFoundFlightsListFilterArchived,
    LostAndFoundFlightsListFilterDateSelector,
    AuditFlightsChangesList,
    LostAndFoundListTableOptionsFilterButton,
  },
  props: [],
  data() {
    return {
      select: 50,
      showFilter: false,
      valid: true,
      queryName: null,
      dialog: false,
      showOldValues: null,
      flashOnChanges: null,
    };
  },
  computed: {
    filter() {
      return this.$store.state.lostAndFound.filter;
    },
    date() {
      return this.$moment(this.$store.state.lostAndFound.filter.dateFrom);
    },
  },
  methods: {
    find() {
      this.showLoading();
      this.$store.dispatch(LOST_AND_FOUND_FIND_FLIGHTS);
    },
    exportCSV() {
      this.exportAgGridCsv('LostAndFoundFlights');
    },
    updateShowOldValuesInStore() {
      this.$store.commit(UPDATE_SHOW_OLD_VALUES, this.showOldValues);
    },
    updateFlashOnChangesInStore() {
      this.$store.commit(UPDATE_FLASH_ON_CHANGES, this.flashOnChanges);
    },
    displayedFilter() {
      this.showFilter = !this.showFilter;
      this.$emit('changeShowFilter', this.showFilter);
    },
  },
  created() {
    this.showOldValues = this.$store.state.lostAndFound.showOldValues;
    this.flashOnChanges = this.$store.state.lostAndFound.flashOnChanges;
  },
  mounted() {
    const that = this;
    this.subscribeMutation(LOST_AND_FOUND_SET_GRID_API, (mutationPayload) => {
      that.setAgGridApi(mutationPayload);
    });
  },
};
</script>
