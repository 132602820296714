export default class PassageOutboundFilter {
  constructor(
    dateFrom,
    dateTo,
    onlyPassageOutboundUnarchived,
    onlyCurrentDesk,
    timezoneDate,
    filterDateByEstimatedTime,
  ) {
    this.dateFrom = dateFrom;
    this.dateTo = dateTo;
    this.onlyPassageOutboundUnarchived = onlyPassageOutboundUnarchived === true;
    this.onlyCurrentDesk = onlyCurrentDesk === true;
    this.timezoneDate = timezoneDate;
    this.filterDateByEstimatedTime = filterDateByEstimatedTime;
  }

  static get createEmptyFilter() {
    return new PassageOutboundFilter(null, null, true, false, true);
  }
}
