<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :nudge-width="200"
    offset-x
  >
    <template v-slot:activator="{ on }">
      <v-tooltip left>
        <v-btn dark icon v-on="on" slot="activator">
          <v-icon>more_vert</v-icon>
        </v-btn>
        <span>{{ $t("tableOptions.tableOptionsTitle") }}</span>
      </v-tooltip>
    </template>

    <EmployeeIncomeListTableOptions />
  </v-menu>
</template>
<script>
import EmployeeIncomeListTableOptions from './EmployeeIncomeListTableOptions.vue';

export default {
  name: 'employee-income-ticketing-report-list-table-options-filter-button',
  components: { EmployeeIncomeListTableOptions },
  data() {
    return {
      menu: false,
    };
  },
};
</script>
