import { JSUtils } from '../../shared/js/JSUtils';

export default class FlightPassageDistributionInformation {
  constructor(
    id,
    male,
    female,
    child,
    infant,
    paxFirstClass,
    paxSecondClass,
    paxThirdClass,
    padFirstClass,
    padSecondClass,
    padThirdClass,
    bagsFirstClass,
    bagsSecondClass,
    bagsThirdClass,
    totalPaxOnBoardAdult,
    totalPaxOnBoardInfant,
    totalBags,
    realWeightBags,
  ) {
    this.id = id;
    this.male = male;
    this.female = female;
    this.child = child;
    this.infant = infant;
    this.paxFirstClass = paxFirstClass;
    this.paxSecondClass = paxSecondClass;
    this.paxThirdClass = paxThirdClass;
    this.padFirstClass = padFirstClass;
    this.padSecondClass = padSecondClass;
    this.padThirdClass = padThirdClass;
    this.bagsFirstClass = bagsFirstClass;
    this.bagsSecondClass = bagsSecondClass;
    this.bagsThirdClass = bagsThirdClass;
    this.totalPaxOnBoardAdult = totalPaxOnBoardAdult;
    this.totalPaxOnBoardInfant = totalPaxOnBoardInfant;
    this.totalBags = totalBags;
    this.realWeightBags = realWeightBags;
  }

  static get createEmptyFlightPassageDistributionInformation() {
    return new FlightPassageDistributionInformation();
  }

  static createFlightPassageDistributionInformationFromData(o) {
    if (!o) {
      return this.createEmptyFlightPassageDistributionInformation;
    }

    return JSUtils.cast(o, FlightPassageDistributionInformation);
  }
}
