<template>
  <v-card-text class="form">
    <v-container fluid>
      <v-layout row wrap>

        <v-flex xs4 pr-4>
          <v-text-field :label="this.$i18n.t('configuration.name')" v-model="item.name" :rules="[rules.required]" :readonly="!hasPermissions(rolePermission)"/>
        </v-flex>

        <v-flex xs8>
          <v-text-field :label="this.$i18n.t('configuration.description')" v-model="item.description" :readonly="!hasPermissions(rolePermission)"/>
        </v-flex>

        <v-flex style="margin-right: 2ch; width: 5vw">
          <v-tooltip bottom>
            <template slot="activator">
              <v-text-field :label="this.$i18n.t('configuration.fromDayRange')" type="number" v-model="item.fromDayRange" :readonly="!hasPermissions(rolePermission)"/>
            </template>
            <span>{{this.$i18n.t('configuration.fromDayRangeExtended')}}</span>
          </v-tooltip>
        </v-flex>

        <v-flex style="margin-right: 2ch; width: 5vw">
          <v-tooltip bottom>
            <template slot="activator">
              <v-text-field :label="this.$i18n.t('configuration.toDayRange')" type="number" v-model="item.toDayRange" :readonly="!hasPermissions(rolePermission)"/>
            </template>
            <span>{{this.$i18n.t('configuration.toDayRangeExtended')}}</span>
          </v-tooltip>
        </v-flex>

        <v-flex style="margin-right: 2ch; width: 5vw">
          <v-checkbox :label="this.$i18n.t('configuration.showExternalFlights')" color="primary" v-model="item.externalFlights" :disabled="!hasPermissions(rolePermission)"/>
        </v-flex>

      </v-layout>

      <v-layout row wrap style="height: 40vh">
        <v-flex xs4>
          <v-navigation-drawer height="40vh">
            <v-list>
              <v-list-tile :class="{ 'active-menu': selectedTenant === 'all'}"
                           key="all"
                           @click="selectTenant('all')"
              >
                <v-list-tile-content>
                  <v-list-tile-title><v-icon>public</v-icon> {{$t("configuration.globalAirlines")}}</v-list-tile-title>
                </v-list-tile-content>
              </v-list-tile>
              <v-list-tile :class="{ 'active-menu': selectedTenant === tenant}"
                           v-for="tenant in tenants"
                           :key="tenant.id"
                           @click="selectTenant(tenant)"
              >
                <v-list-tile-content>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-list-tile-title v-on="on"><v-icon>apartment</v-icon> {{ tenant.name }}-{{tenant.description}}</v-list-tile-title>
                  </template>
                    <span>{{ tenant.name }}-{{tenant.description}}</span>
                  </v-tooltip>
                </v-list-tile-content>
              </v-list-tile>
            </v-list>
          </v-navigation-drawer>
        </v-flex>
        <v-flex xs8>
          <v-card flat>
            <v-flex xs10 v-if="selectedTenant === 'all'">
              <airline-select label="Airline" :multiple=true :chip-view=true :value="item.airlines" @input="changeAirlines" :disabled="!hasPermissions(rolePermission)"/>
            </v-flex>
            <v-flex xs10 v-else>
              <airline-select label="Airline" :multiple=true :chip-view=true :value="getAirlines(selectedTenant)" @input="changeTenantAirlines(selectedTenant, $event)" :disabled="!hasPermissions(rolePermission)"/>
            </v-flex>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-card-text>
</template>
<script>

import AirlineSelect from '../../airline/AirlineSelect.vue';
import RolePermissions from '../../mixins/RolePermissions.vue';

export default {
  name: 'handling-agent-edit-card-content',
  mixins: [RolePermissions],
  props: {
    item: { type: Object, required: true },
  },
  components: {
    AirlineSelect,
  },
  computed: {
    tenants() {
      return this.$store.getters.getTenant;
    },
    rolePermission() {
      return `${this.$route.meta.role}_WR`;
    },
  },
  data() {
    return {
      selectedTenant: 'all',
      rules: {
        required: value => !!value || this.$i18n.t('common.fieldRequired'),
      },
    };
  },
  methods: {
    getAirlines(tenant) {
      if (this.item.tenantAirlines != null && tenant != null && tenant.name != null) {
        const tenantAirlines = new Map(Object.entries(this.item.tenantAirlines)).get(tenant.name);
        return tenantAirlines != null ? tenantAirlines : [];
      }
      return [];
    },
    changeAirlines(airlines) {
      this.item.airlines = airlines.map(a => ({ id: a }));
    },
    changeTenantAirlines(tenant, airlines) {
      if (this.item.tenantAirlines == null) {
        this.item.tenantAirlines = {};
      }
      this.item.tenantAirlines[tenant.name] = airlines.map(a => ({ id: a }));
    },
    selectTenant(tenant) {
      this.selectedTenant = tenant;
    },
  },
};
</script>
<style scoped>
  .form {
    overflow-y:hidden;
  }
</style>
