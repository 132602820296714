<template>
  <div>
    <flight-delay-select :ref="'refComp'" v-on:keydown.native="keyEvent" v-char-length.clearable="4" v-model="itemId" :flightId="delayFlightId" @input-code="setItemCode($event)" @input-description="setItemDescription($event)"></flight-delay-select>
  </div>
</template>

<script>
import Vue from 'vue';
import FlightDelaySelect from '../../flightDelayType/FlightDelaySelect.vue';
import AgGridParentCellEditor from './mixins/AgGridParentCellEditor.vue';

export default Vue.extend({
  mixins: [AgGridParentCellEditor],
  components: {
    FlightDelaySelect,
  },
  data() {
    return {
      itemId: null,
      itemCode: null,
      itemDescription: null,
      delayFlightId: null,
    };
  },
  methods: {
    getValue() {
      if (this.params.column.colDef && this.params.column.colDef.cellEditorCustomConfig && this.params.column.colDef.cellEditorCustomConfig.fieldId) {
        this.params.node.data[this.params.column.colDef.cellEditorCustomConfig.fieldId] = this.itemId;
      }
      this.beforeReturnGetValue();
      if (this.itemCode == null && this.itemDescription == null) {
        return '';
      } if (this.itemCode != null && this.itemDescription == null) {
        return this.itemCode;
      } if (this.itemCode == null && this.itemDescription != null) {
        return this.itemDescription;
      }
      return `${this.itemCode} - ${this.itemDescription}`;
    },
    setItemCode(code) {
      this.itemCode = code;
    },
    setItemDescription(description) {
      this.itemDescription = description;
    },
  },
  created() {
    if (this.params.column.colDef && this.params.column.colDef.cellEditorCustomConfig && this.params.column.colDef.cellEditorCustomConfig.fieldId) {
      this.itemId = this.params.node.data[this.params.column.colDef.cellEditorCustomConfig.fieldId];
      this.delayFlightId = this.params.node.data.flightId;
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$refs.refComp) {
        this.$refs.refComp.$refs.refComp.focus();
      }
    });
  },
});
</script>

<style>

</style>
