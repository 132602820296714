<script>
import Vue from 'vue';

export default Vue.extend({
  computed: {
    manualUrl() {
      if (this.$store.state.tenant.currentTenant && this.$store.state.tenant.currentTenant.usersManual) {
        const userLang = this.lang ? this.lang : 'en';
        let currentModule = this.module ? this.module : 'DEFAULT';

        currentModule = currentModule.toUpperCase();

        const manuals = JSON.parse(this.$store.state.tenant.currentTenant.usersManual);
        let manual = manuals.filter(man => man.module === currentModule);

        if (manual.length > 0) {
          manual = manual.filter(man => man.lang === userLang);
          if (!manual) {
            [manual] = manual.filter(man => man.lang === 'en');
          } else {
            [manual] = manual;
          }
        } else {
          manual = manuals.find(man => man.module === 'DEFAULT');
        }

        if (manual) {
          return manual.url;
        }
      }

      return '';
    },
  },
});
</script>
