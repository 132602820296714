<template>
  <div class="d-flex justify-center milestones-permission-select">
    <milestones-permission-select
      :ref="'refComp'"
      v-on:keydown.native="keyEvent"
      v-char-length.clearable="4"
      :value="params.value"
      @input="changeRol"
      >
    </milestones-permission-select>
  </div>
</template>

<script>
import Vue from 'vue';
import AgGridParentCellEditor from './mixins/AgGridParentCellEditor.vue';
import milestonesPermissionSelect from '../../milestones/MilestonePermissionSelect.vue';

export default Vue.extend({
  mixins: [AgGridParentCellEditor],
  components: {
    milestonesPermissionSelect,
  },
  data() {
    return {
      permission: null,
    };
  },
  methods: {
    getValue() {
      return this.permission;
    },
    changeRol(newValue) {
      this.permission = newValue;
      this.params.api.stopEditing(); // Stop editing to commit the change
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$refs.refComp) {
        this.$refs.refComp.$refs.refComp.focus();
      }
    });
  },
});
</script>
