<template>
  <div>
    <orientation-select  :ref="'refComp'" v-on:keydown.native="keyEvent" v-char-length.clearable="4" v-model="item" @input="setItem($event)"></orientation-select>
  </div>
</template>

<script>
import Vue from 'vue';
import OrientationSelect from '../../rampService/OrientationSelect.vue';
import AgGridParentCellEditor from './mixins/AgGridParentCellEditor.vue';

export default Vue.extend({
  mixins: [AgGridParentCellEditor],
  components: {
    OrientationSelect,
  },
  data() {
    return {
      item: null,
    };
  },
  methods: {
    getValue() {
      return this.item;
    },
    setItem(item) {
      this.item = item;
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$refs.refComp) {
        this.$refs.refComp.$refs.refComp.focus();
      }
    });
  },
});
</script>

<style lang="scss" scoped>

</style>
