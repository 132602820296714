<template>
  <v-layout row justify-center>
    <v-dialog v-model="dialog" persistent max-width="50%">
      <v-card :dark="this.$vuetify.dark" :color="this.$vuetify.dark ? 'bggrey_dark' : 'bggrey'">
        <v-toolbar dark color="primary">
          <v-icon>cancel</v-icon>
          <v-toolbar-title>{{$t("rampServices.cancelConfirmationTitle")}}</v-toolbar-title>
        </v-toolbar>

        <loading-component />

        <v-card-text>
          {{$t("rampServices.cancelConfirmationAlert")}}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn flat color="primary" @click.native="reject()">{{$t("common.close")}}</v-btn>
          <v-btn color="error" depressed @click.native="confirm()" :disabled="loading">
            {{$t("common.cancel")}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>
<script>
import LoadingComponent from '../../layout/LoadingComponent.vue';

export default {
  name: 'towing-adhoc-task-cancel-modal',
  components: {
    LoadingComponent,
  },
  props: {
    dialog: { type: Boolean, required: true },
    items: { type: Array, required: true },
  },
  data() {
    return {
    };
  },
  computed: {
    loading() {
      return this.$store.state.loading;
    },
  },
  methods: {
    reject() {
      this.$emit('cancel-reject');
    },
    confirm() {
      this.$emit('cancel-confirm', this.items);
    },
  },
};
</script>
