import getNested, { getNestedStringValue } from './getNested';
import { FlightBound } from './flightBound';
// import ConnectedFlightsFactory from './connectedFlightsFactory';
import FlightComposition from './flightComposition';
import { JSUtils } from '../js/JSUtils';
import FlightWeighting from './flightWeighting';
import FlightCommonInformation from '../../scheduling/model/flightCommonInformation';

export default class ConnectedFlights {
  constructor(
    inbound,
    outbound,
    weighting,
    sortingTime,
  ) {
    this.inbound = inbound;
    this.outbound = outbound;
    this.weighting = weighting;
    this.sortingTime = sortingTime;
    this.old = null;
  }

  static createEmtpyConnectedFlights() {
    return new ConnectedFlights(
      new FlightComposition(FlightCommonInformation.createEmptyInboundFlight),
      new FlightComposition(FlightCommonInformation.createEmptyOutBoundFlight),
      FlightWeighting.createEmpty(),
    );
  }

  isNew() {
    return !((this.inbound.common && this.inbound.common.id) || (this.outbound.common && this.outbound.common.id));
  }

  get id() {
    const conector = '-';
    return `${getNestedStringValue(this, ['inbound', 'common', 'id'])}${conector}${getNestedStringValue(this, ['outbound', 'common', 'id'])}`;
  }

  /**
   * @deprecated
   */
  get defaultBound() {
    if (this.inbound) {
      return this.inbound;
    }

    if (this.outbound) {
      return this.outbound;
    }

    return {};
  }

  /**
   * @deprecated
   */
  hasInbound() {
    return this.inbound && this.inbound.common && this.inbound.common.id;
  }

  /**
   * @deprecated
   */
  hasOutbound() {
    return this.outbound && this.outbound.common && this.outbound.common.id;
  }

  /**
   * @deprecated
   */
  setFlightPropertyAllBound(property, value) {
    if (this.inbound) {
      this.inbound.common[property] = value;
    }

    if (this.outbound) {
      this.outbound.common[property] = value;
    }
  }

  /**
   * @deprecated TODO hay que hacer un setChildPropertyValue
   */
  setFlightPropertyForBound(property, value, bound) {
    if (this.hasInbound() && (bound === FlightBound.INBOUND || bound === FlightBound.ANY)) {
      this.inbound.common[property] = value;
    }

    if (this.hasOutbound() && (bound === FlightBound.OUTBOUND || bound === FlightBound.ANY)) {
      this.outbound.common[property] = value;
    }
  }

  /**
   * @deprecated since connectedFlights
   */
  getDefaultBound() {
    return this.defaultBound.common;
  }

  /**
   * @deprecated since connectedFlights
   */
  getBound(bound) {
    if (bound) {
      if (bound === FlightBound.INBOUND) {
        if (!this.inbound) {
          console.warn('Inbound not found, return default bound'); // eslint-disable-line no-console
        }
        return this.inbound ? this.inbound.common : null;
      } if (bound === FlightBound.OUTBOUND) {
        if (!this.outbound) {
          console.warn('Outbound not found, return default bound'); // eslint-disable-line no-console
        }
        return this.outbound ? this.outbound.common : null;
      }
    }
    console.warn('Not bound provided, return default bound'); // eslint-disable-line no-console
    return this.inbound ? this.inbound.common : null;
  }

  getBoundComposition(bound) {
    if (bound) {
      if (bound === FlightBound.INBOUND) {
        return this.inbound;
      } if (bound === FlightBound.OUTBOUND) {
        return this.outbound;
      }
    }
    console.warn('Not bound provided, return default bound'); // eslint-disable-line no-console
    return this.inbound;
  }


  /**
   * @deprecated
   */
  getSafeValue(arr) {
    /* eslint no-param-reassign: "off" */
    if (typeof arr === 'string') {
      arr = ['common', arr];
    }

    if (!arr.constructor === Array) {
      throw Error('Parameter must be Array');
    }

    let val = this.getChildPropertyValue(FlightBound.INBOUND, arr);

    if (val === false) {
      const valOutbound = this.getChildPropertyValue(FlightBound.OUTBOUND, arr);
      val = valOutbound === true ? valOutbound : val;
    }

    if (val === '' || val === null || val === undefined) {
      val = this.getChildPropertyValue(FlightBound.OUTBOUND, arr);
    }

    if (val === '' || val === null || val === undefined) {
      return '';
    }
    return val;
  }

  getChildPropertyValue(bound, pathArray) {
    if (!pathArray.constructor === Array) {
      throw Error('Get nested parameters use: 1) FlightBound 2) Array with path property value');
    }

    if (bound && pathArray && pathArray.constructor === Array && pathArray.length > 0) {
      if (bound === FlightBound.ANY) {
        return this.getSafeValue(pathArray);
      }

      const value = getNested(FlightBound.INBOUND === bound ? this.inbound : this.outbound, pathArray);

      if (JSUtils.isFunction(value)) {
        if (pathArray.length === 1) {
          return value.bind(FlightBound.INBOUND === bound ? this.inbound : this.outbound)();
        }
        pathArray.pop();
        return value.bind(this.getChildPropertyValue(bound, pathArray))();
      }

      return value;
    }
    throw Error('Get nested parameters use: 1) FlightBound 2) Array with path property value');
  }

  setChildPropertyValueByBound(bound, pathArray, value) {
    const properties = Array.isArray(pathArray) ? pathArray : pathArray.split('.');

    if (this.hasInbound() && (bound === FlightBound.ANY || bound === FlightBound.INBOUND)) {
      const inboundPathArray = [...properties];
      inboundPathArray.unshift('inbound');
      this.setChildPropertyValue(inboundPathArray, value);
    }
    if (this.hasOutbound() && (bound === FlightBound.ANY || bound === FlightBound.OUTBOUND)) {
      const outboundPathArray = [...properties];
      outboundPathArray.unshift('outbound');
      this.setChildPropertyValue(outboundPathArray, value);
    }
  }

  setChildPropertyValue(pathArray, value) {
    if (!pathArray.constructor === Array) {
      throw Error('pathArray has to be an Array');
    }

    let schema = this; // a moving reference to internal objects within obj
    const len = pathArray.length;
    for (let i = 0; i < len - 1; i += 1) {
      const elem = pathArray[i];
      if (!schema[elem]) schema[elem] = {};
      schema = schema[elem];
    }

    schema[pathArray[len - 1]] = value;
  }

  clone() {
    const newInbound = this.inbound ? Object.assign(Object.create(Object.getPrototypeOf(this.inbound)), this.inbound) : null;
    const newOutbound = this.outbound ? Object.assign(Object.create(Object.getPrototypeOf(this.outbound)), this.outbound) : null;
    const newWeighting = this.weighting ? Object.assign(Object.create(Object.getPrototypeOf(this.weighting)), this.weighting) : null;

    const newConnectedFlights = new ConnectedFlights(
      newInbound,
      newOutbound,
      newWeighting,
      this.sortingTime,
    );
    newConnectedFlights.old = this.old ? this.old.clone() : null;
    return newConnectedFlights;
  }

  isPropertyChanged(property, isEqualFunction) {
    const pathArray = property.split('.');
    if (!this.old || !JSUtils.reducePathArray(pathArray, this.old)) {
      return false;
    }
    const newVal = JSUtils.reducePathArray(pathArray, this);
    const oldVal = JSUtils.reducePathArray(pathArray, this.old);

    console.log(`Comparing ${newVal}:${oldVal}`); // eslint-disable-line no-console

    return isEqualFunction === undefined ? newVal !== oldVal : (newVal !== null && oldVal !== null && !isEqualFunction(newVal, oldVal));
  }

  getOldValue(property, getter) {
    if (!this.old) {
      return null;
    }

    const val = JSUtils.reducePathArray(property.split('.'), this.old);
    return getter === undefined ? val : getter(val);
  }
}
