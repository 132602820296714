import { JSUtils } from '../js/JSUtils';
import FlightWeighting from './flightWeighting';

export default class FlightWeightingAggregated extends FlightWeighting {
  // constructor() {
  //   super();
  // }

  static createFromData(data) {
    if (!data) {
      return data;
    }

    const flightWeightingAggregated = JSUtils.cast(data, FlightWeightingAggregated);
    flightWeightingAggregated.date = data.date;
    return flightWeightingAggregated;
  }

  static createArrayFromData(data) {
    if (data && data.constructor === Array && data.length > 0) {
      return data.map(d => this.createFromData(d));
    }
    return [];
  }
}
