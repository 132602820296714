<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div v-if="tenants">
    <entity-configuration-list
      :entity-name="this.$i18n.t('configuration.airlineFares')"
      entity-icon="style"
      :framework-components="frameworkComponents" :column-defs="columnDefs" :items="airlineFares"
      @confirm-edit-item="editConfirm"
      @confirm-delete-item="deleteConfirm"
      @confirm-enable-disable-item="enableDisableConfirm"
    >
      <template v-slot:modal-content="{ itemEdit }">
        <airline-fares-edit-card-content :item="itemEdit" />
      </template>
    </entity-configuration-list>
  </div>
</template>
<script>
/* eslint no-param-reassign: "off" */
import EntityConfigurationList from '../common/EntityConfigurationList.vue';
import AirlineFaresEditCardContent from './AirlineFaresEditCardContent.vue';
import {
  AIRLINE_FARE_CREATE,
  AIRLINE_FARE_DELETE,
  AIRLINE_FARE_DISABLE,
  AIRLINE_FARE_ENABLE,
  AIRLINE_FARE_UPDATE,
  GET_AIRLINE_FARES,
} from '../../../store/modules/airlineFaresCatalog';
import AgGridTenantsChipsCellRenderer from '../../ag-grid/cell-renderers/AgGridTenantsChipsCellRenderer.vue';
import AgGridIconCellRenderer from '../../ag-grid/cell-renderers/AgGridIconCellRenderer.vue';
import AgGridIconTooltipOperationCellRenderer
from '../../ag-grid/cell-renderers/AgGridIconTooltipOperationCellRenderer.vue';
import { TENANT_LIST } from '../../../store/modules/tenant';

export default {
  name: 'airline-fares-list',
  components: {
    EntityConfigurationList,
    AirlineFaresEditCardContent,
  },
  data() {
    const that = this;
    return {
      frameworkComponents: {
        AgGridIconCellRenderer,
        AgGridTenantsChipsCellRenderer,
      },
      columnDefs: [
        {
          field: 'name',
          headerValueGetter() {
            return that.$i18n.t('configuration.name');
          },
          width: 110,
        },
        {
          field: 'description',
          headerValueGetter() {
            return that.$i18n.t('configuration.description');
          },
          width: 110,
        },
        {
          field: 'airlineCode',
          headerValueGetter() {
            return that.$i18n.t('configuration.airlineCode');
          },
          width: 110,
        },
        {
          field: 'dateFrom',
          headerValueGetter() {
            return that.$i18n.t('airlineFares.dateFrom');
          },
          valueFormatter(params) {
            return params.value ? that.$moment(params.value).format('DD/MM/YYYY') : null;
          },
          width: 70,
        },
        {
          field: 'dateTo',
          headerValueGetter() {
            return that.$i18n.t('airlineFares.dateTo');
          },
          valueFormatter(params) {
            return params.value ? that.$moment(params.value).format('DD/MM/YYYY') : null;
          },
          width: 70,
        },
        {
          field: 'enabled',
          headerValueGetter() {
            return that.$i18n.t('airlineFares.activeFare');
          },
          valueGetter(params) {
            let result = false;
            if (params.data.dateTo && params.data.dateTo != null) {
              result = that.$moment().startOf('day').isBetween(params.data.dateFrom, params.data.dateTo, undefined, '[]');
            } else {
              result = that.$moment().isAfter(params.data.dateFrom);
            }
            return {
              value: result ? 'done' : 'close',
              color: result ? 'green' : 'red',
              icon: result ? 'done' : 'close',
            };
          },
          cellRendererFramework: AgGridIconTooltipOperationCellRenderer,
          width: 70,
        },
        {
          field: 'tenants',
          headerValueGetter() {
            return that.$i18n.t('common.airport');
          },
          valueGetter(params) {
            return params.data.tenants.map(tenant => tenant.name);
          },
          cellRendererFramework: AgGridTenantsChipsCellRenderer,
          cellRendererParams: {
            tenants: () => this.tenants,
          },
        },
        {
          headerValueGetter() {
            return that.$i18n.t('configuration.disabled');
          },
          field: 'deleted',
          cellRendererFramework: AgGridIconCellRenderer,
          cellRendererParams: {
            icon: 'mdi-cancel',
            tooltip: params => (params.data.deleted ? this.$i18n.t('configuration.disabled') : null),
          },
          width: 50,
        },
      ],
    };
  },
  computed: {
    airlineFares() {
      return this.$store.getters.getAirlineFares;
    },
    tenants() {
      return this.$store.getters.getTenant;
    },
  },
  methods: {
    getAirlineFares() {
      this.$store.dispatch(GET_AIRLINE_FARES);
    },
    deleteConfirm(item) {
      this.$store.dispatch(AIRLINE_FARE_DELETE, item.id);
    },
    editConfirm(item) {
      item.fields = JSON.stringify(item.fields);
      this.$store.dispatch(item.id ? AIRLINE_FARE_UPDATE : AIRLINE_FARE_CREATE, item);
    },
    enableDisableConfirm(item) {
      this.$store.dispatch(item.deleted ? AIRLINE_FARE_ENABLE : AIRLINE_FARE_DISABLE, item.id);
    },
    getTenants() {
      this.$store.dispatch(TENANT_LIST);
    },
  },
  created() {
    this.getTenants();
    this.getAirlineFares();
  },
};
</script>
