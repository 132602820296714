<template>
  <v-icon small v-if="params.value">{{getColorByStatus(params.value)}}</v-icon>
</template>

<script>
import Vue from 'vue';
import { SchedulingImportResultOperation } from '../../scheduling/model/schedulingImportResult';

export default Vue.extend({
  methods: {
    getColorByStatus(value) {
      let icon = '';
      switch (value) {
        case SchedulingImportResultOperation.INSERT: icon = 'add'; break;
        case SchedulingImportResultOperation.DELETE: icon = 'remove'; break;
        case SchedulingImportResultOperation.EQUAL: icon = 'mdi-equal'; break;
        case SchedulingImportResultOperation.MODIFY: icon = 'mdi-not-equal-variant'; break;
        default: icon = ''; break;
      }
      return icon;
    },
  },
});
</script>

<style scoped>

</style>
