/* eslint no-param-reassign: "off" */

import { HTTP } from '../../../http';
import { REQUEST_ERROR, REQUEST_START, REQUEST_SUCCESS } from '../../store';
import { NOTIFICATION_SHOW } from '../notifications';
import { AUTH_CHECK } from '../auth';
import i18n from '../../../plugins/i18n';

// actions names
export const GET_AIRPORT_SERVICE_AGENTS = 'GET_AIRPORT_SERVICE_AGENTS';
export const GET_AIRPORT_SERVICE_AGENT_BY_USER = 'GET_AIRPORT_SERVICE_AGENTS_BY_USER';
export const AIRPORT_SERVICE_AGENT_CREATE = 'AIRPORT_SERVICE_AGENT_CREATE';
export const AIRPORT_SERVICE_AGENT_UPDATE = 'AIRPORT_SERVICE_AGENT_UPDATE';
export const AIRPORT_SERVICE_AGENT_DELETE = 'AIRPORT_SERVICE_AGENT_DELETE';
export const AIRPORT_SERVICE_AGENT_DISABLE = 'AIRPORT_SERVICE_AGENT_DISABLE';
export const AIRPORT_SERVICE_AGENT_ENABLE = 'AIRPORT_SERVICE_AGENT_ENABLE';


// mutations
export const GET_AIRPORT_SERVICE_AGENTS_SUCCESS = 'GET_AIRPORT_SERVICE_AGENTS_SUCCESS';
export const GET_AIRPORT_SERVICE_AGENT_BY_USER_SUCCESS = 'GET_AIRPORT_SERVICE_AGENT_BY_USER_SUCCESS';
export const GET_AIRPORT_SERVICE_AGENTS_REQUESTED = 'GET_AIRPORT_SERVICE_AGENTS_REQUESTED';
export const GET_AIRPORT_SERVICE_AGENT_BY_USER_REQUESTED = 'GET_AIRPORT_SERVICE_AGENT_BY_USER_REQUESTED';
export const UPDATE_AIRPORT_SERVICE_AGENTS_SUCCESS = 'UPDATE_AIRPORT_SERVICE_AGENTS_SUCCESS';

// initial state
const storeState = {
  airportServiceAgents: [],
  airportServiceAgentByUser: {},
  airportServiceAgentsRequested: false,
  airportServiceAgentByUserRequested: false,
};

// getters
const storeGetters = {
  getAirportServiceAgents: state => state.airportServiceAgents,
  getAirportServiceAgentByUser: state => state.airportServiceAgentByUser,
  getActiveAirportServiceAgents: state => state.airportServiceAgents.filter(elem => !elem.deleted),
};

// actions
const storeActions = {
  [GET_AIRPORT_SERVICE_AGENTS]: ({ commit }) => {
    commit(REQUEST_START);
    commit(GET_AIRPORT_SERVICE_AGENTS_REQUESTED);
    HTTP.get('airport-service-agent')
      .then((resp) => {
        commit(REQUEST_SUCCESS);
        commit(GET_AIRPORT_SERVICE_AGENTS_SUCCESS, resp.data);
      })
      .catch((err) => {
        commit(REQUEST_ERROR, err);
      });
  },
  [GET_AIRPORT_SERVICE_AGENT_BY_USER]: ({ commit, state }) => {
    if (state.airportServiceAgentRequested) {
      return;
    }
    commit(REQUEST_START);
    commit(GET_AIRPORT_SERVICE_AGENT_BY_USER_REQUESTED);
    HTTP.get('airport-service-agent/user')
      .then((resp) => {
        commit(REQUEST_SUCCESS);
        commit(GET_AIRPORT_SERVICE_AGENT_BY_USER_SUCCESS, resp.data);
      })
      .catch((err) => {
        commit(REQUEST_ERROR, err);
      });
  },
  [AIRPORT_SERVICE_AGENT_CREATE]: ({ commit, dispatch }, serviceAgent) => {
    commit(REQUEST_START);
    return HTTP.post('airport-service-agent', serviceAgent)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(UPDATE_AIRPORT_SERVICE_AGENTS_SUCCESS);
        dispatch(GET_AIRPORT_SERVICE_AGENTS);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.airportServiceAgentCreate'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
  [AIRPORT_SERVICE_AGENT_UPDATE]: ({ commit, dispatch }, serviceAgent) => {
    commit(REQUEST_START);
    return HTTP.put('airport-service-agent', serviceAgent)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(UPDATE_AIRPORT_SERVICE_AGENTS_SUCCESS);
        dispatch(GET_AIRPORT_SERVICE_AGENTS);
        dispatch(AUTH_CHECK);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.airportServiceAgentUpdate'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
  [AIRPORT_SERVICE_AGENT_DELETE]: ({ commit, dispatch }, id) => {
    commit(REQUEST_START);
    return HTTP.delete(`airport-service-agent/${id}`)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(UPDATE_AIRPORT_SERVICE_AGENTS_SUCCESS);
        dispatch(GET_AIRPORT_SERVICE_AGENTS);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.airportServiceAgentDelete'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
  [AIRPORT_SERVICE_AGENT_DISABLE]: ({ commit, dispatch }, id) => {
    commit(REQUEST_START);
    return HTTP.patch(`/airport-service-agent/disable/${id}`)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(UPDATE_AIRPORT_SERVICE_AGENTS_SUCCESS);
        dispatch(GET_AIRPORT_SERVICE_AGENTS);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.airportServiceAgentDisable'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
  [AIRPORT_SERVICE_AGENT_ENABLE]: ({ commit, dispatch }, id) => {
    commit(REQUEST_START);
    return HTTP.patch(`/airport-service-agent/enable/${id}`)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(UPDATE_AIRPORT_SERVICE_AGENTS_SUCCESS);
        dispatch(GET_AIRPORT_SERVICE_AGENTS);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.airportServiceAgentEnable'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
};

// mutations
const storeMutations = {
  [GET_AIRPORT_SERVICE_AGENTS_SUCCESS]: (state, airportServiceAgents) => {
    airportServiceAgents.forEach((airportServiceAgent) => {
      airportServiceAgent.airportActiveMilestonesByAirportServiceAgent.sort((airportActiveMilestoneByAirportServiceAgent1, airportActiveMilestoneByAirportServiceAgent2) => {
        const visualizationOrder1 = airportActiveMilestoneByAirportServiceAgent1.order;
        const visualizationOrder2 = airportActiveMilestoneByAirportServiceAgent2.order;

        if (visualizationOrder1 > visualizationOrder2) {
          return 1;
        }
        if (visualizationOrder2 > visualizationOrder1) {
          return -1;
        }
        return 0;
      });
    });
    state.airportServiceAgents = airportServiceAgents;
  },
  [GET_AIRPORT_SERVICE_AGENT_BY_USER_SUCCESS]: (state, airportServiceAgentByUser) => {
    state.airportServiceAgentByUser = airportServiceAgentByUser;
  },
  [GET_AIRPORT_SERVICE_AGENTS_REQUESTED]: (state) => {
    state.airportServiceAgentsRequested = true;
  },
  [GET_AIRPORT_SERVICE_AGENT_BY_USER_REQUESTED]: (state) => {
    state.airportServiceAgentByUserRequested = true;
  },
  [UPDATE_AIRPORT_SERVICE_AGENTS_SUCCESS]: (state) => {
    state.airportServiceAgentsRequested = false;
  },
};

export default {
  state: storeState,
  getters: storeGetters,
  actions: storeActions,
  mutations: storeMutations,
};
