<template>
  <v-container>
    <v-layout row>
      <v-flex xs12 v-if="showAirlineFilter">
        <airline-select
          v-char-length.clearable="2"
          :label="$t('common.airline')"
          v-model="filter.airline"
          @input="changeAirline"
        ></airline-select>
      </v-flex>

      <v-flex xs12>
        <airline-fare-select
          :label="$t('ticketing.fare')"
          :rules="rules"
          v-model="airlineFare"
          @change="setAirlineFare"
          :airlineFares="airlineFares"
        />
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import AirlineSelect from '../../airline/AirlineSelect.vue';
import AirlineFareSelect from './AirlineFareSelect.vue';
import { FIND_AIRLINE_FARES } from '../../../store/modules/airlineFaresCatalog';

export default {
  name: 'airline-fare-selector-container',
  components: {
    AirlineSelect,
    AirlineFareSelect,
  },
  props: {
    showAirlineFilter: {
      type: Boolean,
      default: false,
    },
    showTenantFilter: {
      type: Boolean,
      default: false,
    },
    airline: {},
    tenant: {},
    active: {},
    rules: { type: Array },
    airlineFareOriginal: {},
  },
  data: () => ({
    airlineFareSelected: null,
    date: null,
    flight: null,
  }),
  computed: {
    airlineFares() {
      return this.$store.getters.getEnableAndActiveFilteredAirlineFares;
    },
    filter() {
      return this.$store.state.airlineFaresCatalog.filter;
    },
  },
  methods: {
    changeAirline() {
      this.filterChanged();
      this.$emit('clearAirline');
    },
    filterChanged() {
      this.find();
    },
    find() {
      this.$store.dispatch(FIND_AIRLINE_FARES);
    },
    setAirlineFare(airlineFare) {
      if (typeof (airlineFare) !== 'undefined') {
        this.airlineFareSelected = airlineFare;
        this.$emit('airlineFareSelected', this.airlineFareSelected);
      } else {
        this.$emit('clearAirlineFare');
      }
    },
    initFilter() {
      this.filter.airline = this.airline;
      if (this.tenant) {
        this.filter.tenants = [this.tenant];
      }
    },
  },
  watch: {
    airline() {
      this.initFilter();
      this.find();
      if (this.airlineFareSelected && this.airline && this.airlineFareSelected.airlineId !== this.airline) {
        this.airlineFare = null;
        this.$emit('clearAirlineFare');
      }
    },
  },
  created() {
    this.airlineFare = this.airlineFareOriginal;
    this.initFilter();
    this.find();
  },
};
</script>
<style scoped>
.date-input {
  display: inline-block !important;
  margin: auto 0;
}
.date-input .v-btn {
  min-width: 44px;
}
</style>
