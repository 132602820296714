<template>
  <div>
    <div v-if="params.isEmitted(params)">
      <v-icon
        color="secondary"
        small
        class="mr-2"
        @click.stop="params.showItem(params.data)"
      >
        remove_red_eye
      </v-icon>
      <v-tooltip left v-if="params.isEditable && params.isCancellable(params) && !userIsCashier">
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            color="black"
            small
            class="mr-2"
            @click.stop="params.cancelItem(params.data)"
            v-bind="attrs"
            v-on="on"
          >
            dnd_forwardslash
          </v-icon>
        </template>
        <span>{{$t("ticketing.cancelTickets")}}</span>
      </v-tooltip>
    </div>
    <div v-else-if="params.isEditable">
      <v-icon
        color="secondary"
        small
        class="mr-2"
        @click.stop="params.editItem(params.data)"
      >
        edit
      </v-icon>
      <v-icon
        v-if="!userIsCashier"
        color="error"
        small
        @click.stop="params.deleteItem(params.data)"
      >
        delete
      </v-icon>
    </div>
  </div>

</template>

<script>
import Vue from 'vue';
import { ROLE_CASHIER_TICKETING } from '../../../constants/roles';

export default Vue.extend({
  computed: {
    userIsCashier() {
      return this.$store.state.ticketing.ticketingUserRole === ROLE_CASHIER_TICKETING;
    },
  },
});
</script>

<style scoped>
</style>
