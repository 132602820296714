<template>
  <v-dialog v-model="isDialogOpen" :max-width="modalMaxWidth" persistent
    :content-class="isContentVisible ? 'modal-common' : 'modal-common hide-title'">
    <v-card :dark="this.$vuetify.dark" :color="this.$vuetify.dark ? 'bggrey_dark' : 'bggrey'">
      <v-toolbar dark color="primary" class="ToolbarDialogEditor">
        <v-icon>flight</v-icon>
        <v-toolbar-title>
          <slot name="header"></slot>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <!-- Botón para contraer/expandir el contenido -->
        <v-btn icon @click="toggleContentVisibility">
          <v-icon>{{ isContentVisible ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
        </v-btn>
      </v-toolbar>
      <div v-show="isContentVisible">
      <loading-component />
      <v-card-text class="editSlot">
        <slot></slot>
      </v-card-text>

      <v-card-actions>
        <slot name="footer"></slot>
        <v-spacer></v-spacer>
        <v-btn color="primary" flat @click.native="$emit('cancel')">{{ $t("operations.cancel") }}</v-btn>
        <v-btn v-if="hasPermissions(rolePermission)" color="primary" depressed @click.native="save()"
          :disabled="loadingGlobal || saveDisabled">{{ $t("operations.save") }}</v-btn>
      </v-card-actions>
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
import LoadingComponent from '../../../layout/LoadingComponent.vue';
import RolePermissions from '../../../mixins/RolePermissions.vue';

export default {
  name: 'ag-grid-dialog-editor',
  components: {
    LoadingComponent,
  },
  mixins: [RolePermissions],
  props: {
    isDialogOpen: { type: Boolean, required: true },
    modalMaxWidth: { type: String, required: false, default: '1000px' },
    saveDisabled: { type: Boolean, required: false, default: false },
  },
  data() {
    return {
      isContentVisible: true,
    };
  },
  computed: {
    rolePermission() {
      return `${this.$route.meta.role}_WR`;
    },
  },
  methods: {
    save() {
      this.$emit('save');
    },
    toggleContentVisibility() {
      this.isContentVisible = !this.isContentVisible;
    },
  },
};
</script>
<style scoped>
.editSlot {
  overflow-y: auto;
  max-height: calc(90vh - 14em);
}

.ToolbarDialogEditor {
  z-index: 10;
}
</style>
