/* eslint-disable no-continue,no-labels,no-restricted-syntax */

import moment from 'moment';

export default class FlightMessageTemplateEngine {
  static applyTemplateToFlight(messageTemplate, flightComposition, tenant, userAuth) {
    const flight = flightComposition && flightComposition.common ? flightComposition.common : null;
    if (!messageTemplate || !flight) {
      return '';
    }
    const flightDateTime = moment(flight.scheduledTime ? flight.scheduledTime : flight.flightDate).tz('UTC');
    const message = messageTemplate
      .replace(/#FLIGHT.NUMBER#/g, this.generateFlightNumberInformation(flight))
      .replace(/#FLIGHT.DAY#/g, flightDateTime.format('DD'))
      .replace(/#FLIGHT.MONTH#/g, flightDateTime.format('MMM').toUpperCase())
      .replace(/#FLIGHT.DESTINATION#/g, flight.airport1Code)
      .replace(/#FLIGHT.AIRCRAFT.REGISTRATION#/g, flight.aircraftRegisterNumber ? flight.aircraftRegisterNumber : 'registration')
      .replace(/#FLIGHT.AIRCRAFT.CONFIG#/g, flight.aircraftConfigCode ? flight.aircraftConfigCode : 'config')
      .replace(/#TENANT.CODE#/g, tenant ? tenant.name : 'baseIata')
      .replace(/#LND#/g, flightComposition.times != null && flightComposition.times.landTime != null ? moment(flightComposition.times.landTime).tz('UTC').format('HHmm') : 'hhmm')
      .replace(/#ONB#/g, flightComposition.times != null && flightComposition.times.blockTime != null ? moment(flightComposition.times.blockTime).tz('UTC').format('HHmm') : 'hhmm')
      .replace(/#OFB#/g, flightComposition.times != null && flightComposition.times.blockTime != null ? moment(flightComposition.times.blockTime).tz('UTC').format('HHmm') : 'hhmm')
      .replace(/#AIR#/g, flightComposition.times != null && flightComposition.times.landTime != null ? moment(flightComposition.times.landTime).tz('UTC').format('HHmm') : 'hhmm')
      .replace(/#EET#/g, flightComposition.times != null && flightComposition.times.landTime != null && flightComposition.times.estimatedElapsedTime != null ? moment(moment(flightComposition.times.landTime).format('HHmm'), 'HHmm').add(flightComposition.times.estimatedElapsedTime, 'minute').tz('UTC').format('HHmm') : 'hhmm')
      .replace(/#ETD#/g, flightComposition.times != null && flightComposition.times.estimatedTime != null ? moment(flightComposition.times.estimatedTime).format('HHmm') : 'hhmm')
      .replace(/#PAX#/g, this.generatePaxInformation(flightComposition.passageDistribution))
      .replace(/#TOTAL.WEIGHT#/g, this.generateTotalWeightInformation(flightComposition.operationExtended))
      .replace(/#HOLD.DISTRIBUTION#/g, this.generateHoldDistributionInformation(flightComposition.loadHoldingDistribution))
      .replace(/#PAX.CLASS#/g, this.generatePaxClassInformation(flightComposition.passageDistribution))
      .replace(/#PAD.CLASS#/g, this.generatePadClassInformation(flightComposition.passageDistribution))
      .replace(/#BAG#/g, this.generateBagInformation(flightComposition.operationExtended, flightComposition.passageDistribution))
      .replace(/#CARGO#/g, this.generateCargoInformation(flightComposition.operationExtended))
      .replace(/#MAIL#/g, this.generateMailInformation(flightComposition.operationExtended))
      .replace(/#AVI#/g, this.generateAviInformation(flightComposition.operationExtended))
      .replace(/#HUM#/g, this.generateHumInformation(flightComposition.operationExtended))
      .replace(/#WEA#/g, this.generateWeapInformation(flightComposition.operationExtended))
      .replace(/#PX#/g, this.generateMvtADPaxInformation(flightComposition.passageSummary))
      .replace(/#DL#/g, this.generateMvtADDelayInformation(flightComposition.delays))
      .replace(/#FLIGHT.DATE#/g, flightDateTime.format('ll'))
      .replace(/#AIRLINE.CODE#/g, flight.airlineCode ? flight.airlineCode.toUpperCase() : '')
      .replace(/#TRIP.NUMBER#/g, flight.tripNumber)
      .replace(/#STD#/g, flight.scheduledTime != null ? moment(flight.scheduledTime).tz('UTC').format('HHmm') : 'hhmm')
      .replace(/#ATD#/g, flightComposition.times != null && flightComposition.times.blockTime != null ? moment(flightComposition.times.blockTime).tz('UTC').format('HHmm') : 'hhmm')
      .replace(/#DELAY.CODES#/g, this.generateDelaysCodesInformation(flightComposition.delays))
      .replace(/#DELAY.TIMES#/g, this.generateDelaysTimesInformation(flightComposition.delays))
      .replace(/#DELAY.REMARKS#/g, this.generateDelaysRemarksInformation(flightComposition.delays))
      .replace(/#PARKING#/g, this.generateParkingInformation(flight))
      .replace(/#OUT.PAX#/g, this.generateOutPaxInformation(flightComposition.passageSummary))
      .replace(/#OUT.BAG#/g, this.generateOutBagInformation(flightComposition.passageDistribution))
      .replace(/#BOARDING.AGENTS#/g, this.generateBoardingAgentsInformation(flightComposition.operation))
      .replace(/#USER.LOGGED.IN#/g, this.generateUserAuth(userAuth))
      .replace(/^\s*[\r\n]/gm, '');
    return message;
  }

  static generateUserAuth(userAuth) {
    if (userAuth && userAuth.firstName && userAuth.lastName) {
      return `${userAuth.firstName.toUpperCase()} ${userAuth.lastName.toUpperCase()}`;
    }
    if (userAuth && userAuth.firstName) {
      return userAuth.firstName.toUpperCase();
    }
    return '';
  }

  static generateFlightNumberInformation(flight) {
    let flightNumberInformation = null;

    flightNumberInformation = flight.airlineIataCode ? flight.airlineIataCode : flight.airlineIcaoCode;

    flightNumberInformation += flight.tripNumber.toString().padStart(4, '0');

    return flightNumberInformation;
  }

  static generateMvtADPaxInformation(passageSummary) {
    return passageSummary && passageSummary.passengersNumber ? `PX${passageSummary.passengersNumber}` : '';
  }

  static generateOutPaxInformation(passageSummary) {
    return passageSummary && passageSummary.passengersNumber ? `${passageSummary.passengersNumber}` : '';
  }

  static generateOutBagInformation(passageDistribution) {
    return passageDistribution && passageDistribution.totalBags ? `${passageDistribution.totalBags}` : '';
  }

  static generateParkingInformation(flight) {
    if (flight.parkingCode) {
      let parkingInformation = `${flight.parkingCode}(REMOTE=`;
      parkingInformation += flight.parkingRemote ? 'YES' : 'NO';

      return `${parkingInformation})`;
    }
    return '';
  }

  static generateBoardingAgentsInformation(operation) {
    if (operation && operation.boardingAgent1 && operation.boardingAgent2) {
      return `${operation.boardingAgent1.toUpperCase()}/${operation.boardingAgent2.toUpperCase()}`;
    }
    if (operation && operation.boardingAgent1) {
      return operation.boardingAgent1.toUpperCase();
    }
    if (operation && operation.boardingAgent2) {
      return operation.boardingAgent2.toUpperCase();
    }
    return '';
  }

  static generateMvtADDelayInformation(delays) {
    if (delays && delays.flightDelayCode1 && delays.flightDelayCode2 && delays.flightDelayCode3) {
      return `DL${delays.flightDelayCode1}/${delays.flightDelayCode2}/${delays.flightDelayCode3}/${this.getAmountDelay(delays.flightDelayAmount1)}/${this.getAmountDelay(delays.flightDelayAmount2)}/${this.getAmountDelay(delays.flightDelayAmount3)}`;
    }
    if (delays && delays.flightDelayCode1 && delays.flightDelayCode2) {
      return `DL${delays.flightDelayCode1}/${delays.flightDelayCode2}/${this.getAmountDelay(delays.flightDelayAmount1)}/${this.getAmountDelay(delays.flightDelayAmount2)}`;
    }
    if (delays && delays.flightDelayCode1) {
      return `DL${delays.flightDelayCode1}/${this.getAmountDelay(delays.flightDelayAmount1)}`;
    }

    return '';
  }

  static generateDelaysCodesInformation(delays) {
    if (delays && delays.flightDelayCode1 && delays.flightDelayCode2 && delays.flightDelayCode3) {
      return `${delays.flightDelayCode1}/${delays.flightDelayCode2}/${delays.flightDelayCode3}`;
    }
    if (delays && delays.flightDelayCode1 && delays.flightDelayCode2) {
      return `${delays.flightDelayCode1}/${delays.flightDelayCode2}`;
    }
    if (delays && delays.flightDelayCode1) {
      return `${delays.flightDelayCode1}`;
    }

    return '';
  }

  static generateDelaysTimesInformation(delays) {
    if (delays && delays.flightDelayCode1 && delays.flightDelayCode2 && delays.flightDelayCode3) {
      return `${this.getAmountDelay(delays.flightDelayAmount1)}/${this.getAmountDelay(delays.flightDelayAmount2)}/${this.getAmountDelay(delays.flightDelayAmount3)}`;
    }
    if (delays && delays.flightDelayCode1 && delays.flightDelayCode2) {
      return `${this.getAmountDelay(delays.flightDelayAmount1)}/${this.getAmountDelay(delays.flightDelayAmount2)}`;
    }
    if (delays && delays.flightDelayCode1) {
      return `${this.getAmountDelay(delays.flightDelayAmount1)}`;
    }

    return '';
  }

  static generateDelaysRemarksInformation(delays) {
    if (delays && delays.flightDelayCode1 && delays.flightDelayCode2 && delays.flightDelayCode3 && delays.flightDelayRemark1 && delays.flightDelayRemark2 && delays.flightDelayRemark3) {
      return `${delays.flightDelayRemark1} - ${delays.flightDelayRemark2} - ${delays.flightDelayRemark3} `;
    }
    if (delays && delays.flightDelayCode1 && delays.flightDelayCode2 && delays.flightDelayRemark1 && delays.flightDelayRemark2) {
      return `${delays.flightDelayRemark1} - ${delays.flightDelayRemark2} `;
    }
    if (delays && delays.flightDelayCode1 && delays.flightDelayRemark1) {
      return `${delays.flightDelayRemark1} `;
    }

    return '';
  }

  static getAmountDelay(amount) {
    if (amount) {
      const milliseconds = amount ? Number(amount) * 60000 : 0;
      let hours = (Number(moment.duration(milliseconds).days()) * 24) + Number(moment.duration(milliseconds).hours());
      if (hours === 0) {
        hours = '00';
      } else if (hours.toString().length === 1) {
        hours = `0${hours}`;
      }
      let minutes = moment.duration(milliseconds).minutes();
      if (minutes === 0) {
        minutes = '00';
      } else if (minutes.toString().length === 1) {
        minutes = `0${minutes}`;
      }
      return `${hours}${minutes}`;
    }
    return '0000';
    // return amount ? moment('0000', 'HHmm').add(amount, 'minute').format('HHmm') : moment('0000', 'HHmm');
  }

  static generatePaxInformation(passageDistribution) {
    let paxInformation = null;

    paxInformation = passageDistribution && passageDistribution.male != null ? passageDistribution.male : '0';

    paxInformation += passageDistribution && passageDistribution.female != null ? `/${passageDistribution.female}` : '/0';

    paxInformation += passageDistribution && passageDistribution.child != null ? `/${passageDistribution.child}` : '/0';

    if (passageDistribution && passageDistribution.infant != null) {
      paxInformation += `/${passageDistribution.infant}`;
    }
    return paxInformation;
  }

  static generateTotalWeightInformation(operationExtended) {
    return operationExtended == null || operationExtended.loadHoldingWeight == null ? 'T0' : `T${operationExtended.loadHoldingWeight}`;
  }

  static generateHoldDistributionInformation(loadHoldingDistribution) {
    if (loadHoldingDistribution == null || (loadHoldingDistribution.weightHolding1 == null && loadHoldingDistribution.weightHolding2 == null && loadHoldingDistribution.weightHolding3 == null && loadHoldingDistribution.weightHolding4 == null && loadHoldingDistribution.weightHolding5 == null)) {
      return '';
    }

    let holdInformation = '';

    if (loadHoldingDistribution.weightHolding1 != null) {
      holdInformation += `.1/${loadHoldingDistribution.weightHolding1}`;
    }

    if (loadHoldingDistribution.weightHolding2 != null) {
      holdInformation += `.2/${loadHoldingDistribution.weightHolding2}`;
    }

    if (loadHoldingDistribution.weightHolding3 != null) {
      holdInformation += `.3/${loadHoldingDistribution.weightHolding3}`;
    }

    if (loadHoldingDistribution.weightHolding4 != null) {
      holdInformation += `.4/${loadHoldingDistribution.weightHolding4}`;
    }

    if (loadHoldingDistribution.weightHolding5 != null) {
      holdInformation += `.5/${loadHoldingDistribution.weightHolding5}`;
    }
    return holdInformation;
  }

  static generatePaxClassInformation(passageDistribution) {
    if (this.hasPaxClassInformation(passageDistribution)) {
      let paxClassInformation = '.PAX';

      if (passageDistribution.paxFirstClass != null) {
        paxClassInformation += `/${passageDistribution.paxFirstClass}`;
      }

      if (passageDistribution.paxSecondClass != null) {
        paxClassInformation += `/${passageDistribution.paxSecondClass}`;
      }

      if (passageDistribution.paxThirdClass != null) {
        paxClassInformation += `/${passageDistribution.paxThirdClass}`;
      }

      return paxClassInformation;
    }
    return '.PAX/0';
  }

  static generatePadClassInformation(passageDistribution) {
    if (this.hasPadClassInformation(passageDistribution)) {
      let padClassInformation = '.PAD';

      if (passageDistribution.padFirstClass != null) {
        padClassInformation += `/${passageDistribution.padFirstClass}`;
      }

      if (passageDistribution.padSecondClass != null) {
        padClassInformation += `/${passageDistribution.padSecondClass}`;
      }

      if (passageDistribution.padThirdClass != null) {
        padClassInformation += `/${passageDistribution.padThirdClass}`;
      }

      return padClassInformation;
    }
    return '.PAD/0';
  }

  static generateBagInformation(operationExtended, passageDistribution) {
    if (operationExtended && operationExtended.loadBag != null && operationExtended.loadBag !== 0) {
      return `.B/${operationExtended.loadBag}`;
    }
    if (passageDistribution && passageDistribution.realWeightBags != null && passageDistribution.realWeightBags !== 0) {
      return `.B/${passageDistribution.realWeightBags}`;
    }
    return '.B/0';
  }

  static generateCargoInformation(operationExtended) {
    return operationExtended && operationExtended.loadCargo != null ? `.C/${operationExtended.loadCargo}` : '.C/0';
  }

  static generateMailInformation(operationExtended) {
    return operationExtended && operationExtended.loadMail != null ? `.M/${operationExtended.loadMail}` : '.M/0';
  }

  static generateAviInformation(operationExtended) {
    return operationExtended && operationExtended.loadAVIH != null && operationExtended.loadAVIH !== 0 ? `.AVI/${operationExtended.loadAVIH}` : '';
  }

  static generateHumInformation(operationExtended) {
    return operationExtended && operationExtended.loadHUM != null && operationExtended.loadHUM !== 0 ? `.HUM/${operationExtended.loadHUM}` : '';
  }

  static generateWeapInformation(operationExtended) {
    return operationExtended && operationExtended.loadWeap != null && operationExtended.loadWeap !== 0 ? `.WEA/${operationExtended.loadWeap}` : '';
  }

  static hasPaxClassInformation(passageDistribution) {
    return passageDistribution != null && (passageDistribution.paxFirstClass != null || passageDistribution.paxSecondClass != null || passageDistribution.paxThirdClass != null);
  }

  static hasPadClassInformation(passageDistribution) {
    return passageDistribution != null && (passageDistribution.padFirstClass != null || passageDistribution.padSecondClass != null || passageDistribution.padThirdClass != null);
  }
}
