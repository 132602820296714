<template>
  <v-card-text>
    <v-container fluid style="margin-bottom: 0; padding-bottom: 0">
      <v-layout align-center justify-space-between row style="margin-bottom: 0; padding-bottom: 0">
        <v-flex v-flex-none style="margin-right: 2ch; width: 8vw">
          <v-text-field :label="this.$i18n.t('configuration.registerNumber')" v-model="item.registerNumber" :rules="[rules.registerNumber]" :readonly="!hasPermissions(rolePermission)"/>
        </v-flex>

        <v-flex v-flex-none style="margin-right: 2ch; width: 25vw">
          <v-text-field :label="this.$i18n.t('configuration.description')" v-model="item.comments" :readonly="!hasPermissions(rolePermission)"/>
        </v-flex>

        <v-flex v-flex-none style="margin-right: 2ch; width: 6vw">
          <aircraft-type-select v-char-length.clearable="4" v-model="item.typeId" :label="this.$i18n.t('common.type')" :disabled="!hasPermissions(rolePermission)"/>
        </v-flex>

        <v-flex v-flex-none style="margin-right: 2ch; width: 8vw">
          <v-text-field :label="this.$i18n.t('configuration.mtow')" type="number" min="0" v-model="item.mtow" :rules="[rules.requiredMtow]" :readonly="!hasPermissions(rolePermission)"/>
        </v-flex>
      </v-layout>
      <v-layout style="margin-bottom: 0; padding-bottom: 0">

        <v-flex v-flex-none style="margin-right: 2ch; width: 6vw">
          <v-checkbox :label="this.$i18n.t('configuration.acars')" color="primary" v-model="item.acars" :disabled="!hasPermissions(rolePermission)"/>
        </v-flex>

        <v-flex v-flex-none style="margin-right: 2ch; width: 6vw">
          <v-checkbox :label="this.$i18n.t('configuration.uld')" color="primary" v-model="item.cargo" :disabled="!hasPermissions(rolePermission)"/>
        </v-flex>

        <v-flex v-flex-none style="margin-right: 2ch; width: 8vw">
          <v-checkbox :label="this.$i18n.t('configuration.apuInoperative')" color="primary" v-model="item.apu" :disabled="!hasPermissions(rolePermission)"/>
        </v-flex>
      </v-layout>
    </v-container>
  </v-card-text>
</template>
<script>
import AircraftTypeSelect from '../../aircraft/AircraftTypeSelect.vue';
import RolePermissions from '../../mixins/RolePermissions.vue';

export default {
  name: 'AircraftEditCardContent',
  mixins: [RolePermissions],
  props: {
    item: { type: Object, required: true },
  },
  components: {
    AircraftTypeSelect,
  },
  computed: {
    rolePermission() {
      return `${this.$route.meta.role}_WR`;
    },
  },
  data() {
    return {
      rules: {
        required: value => !!value || 'Required.',
        requiredMtow: value => (!!value || value === '0' || value === 0) || 'Required.',
        registerNumber: (value) => {
          const pattern = /^[A-Z\d]+$/i;
          return pattern.test(value) || this.$i18n.t('configuration.invalidRegisterNumber');
        },
      },
    };
  },
};
</script>
