import { JSUtils } from '../js/JSUtils';
import FlightDeliveryBaggage from '../../lostAndFound/delivery/model/flightDeliveryBaggage';

export default class FlightDeliveryBaggageFactory {
  static mapFlightDeliveryBaggage(data) {
    if (data && data.constructor === Array && data.length > 0) {
      return data.map(d => this.createFlightDeliveryBaggageFromData(d));
    }
    return [];
  }

  static createFlightDeliveryBaggageFromData(data) {
    if (!data) {
      return data;
    }

    return JSUtils.cast(data, FlightDeliveryBaggage);
  }
}
