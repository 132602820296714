<template>
  <v-card :dark="this.$vuetify.dark" :color="this.$vuetify.dark ? 'bggrey_dark' : 'bggrey'">
    <v-container
      fluid
      grid-list-lg
    >
      <v-layout v-if="isSCLorPMIorDUS()" row wrap>
        <v-flex xs12>
          <InformBulk/>
        </v-flex>
      </v-layout>
    </v-container>
  </v-card>
</template>
<script>
import InformBulk from './InformBulk.vue';

export default {
  name: 'AdminInform',
  components: {
    InformBulk,
  },

  methods: {
    isSCLorPMIorDUS() {
      return this.$store.getters.isSCL || this.$store.getters.isPMI || this.$store.getters.isDUS;
    },
  },
};
</script>
