/* eslint no-param-reassign: "off" */

export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';
export const CURRENT_LANGUAGE_CHANGED = 'CURRENT_LANGUAGE_CHANGED';

// initial state
const storeState = {
  currentLanguage: localStorage.getItem('currentLanguage') || 'en',
  languages: {
    es: { name: 'Español' },
    en: { name: 'English' },
  },
};

// getters
const storeGetters = {
  currentLanguage: state => state.currentLanguage,
  languages: state => state.languages,
};

// actions
const storeActions = {
  [CHANGE_LANGUAGE]: ({ commit }, newLanguage) => {
    commit(CURRENT_LANGUAGE_CHANGED, newLanguage);
  },
};

// mutations
const storeMutations = {
  [CURRENT_LANGUAGE_CHANGED]: (state, newLanguage) => {
    state.currentLanguage = newLanguage;
    localStorage.setItem('currentLanguage', newLanguage);
  },
};

export default {
  state: storeState,
  getters: storeGetters,
  actions: storeActions,
  mutations: storeMutations,
};
