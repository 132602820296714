import { JSUtils } from '../../shared/js/JSUtils';

export default class FlightPassageBoardingInformation {
  constructor(
    id,
    loadHum,
    pmrsWarning,
    pmrsArrival,
    passengersCheckInNumber,
    passengersCheckInChildNumber,
  ) {
    this.id = id;
    this.loadHum = loadHum;
    this.pmrsWarning = pmrsWarning;
    this.pmrsArrival = pmrsArrival;
    this.passengersCheckInNumber = passengersCheckInNumber;
    this.passengersCheckInChildNumber = passengersCheckInChildNumber;
  }

  static get createEmptyFlightPassageBoardingInformation() {
    return new FlightPassageBoardingInformation();
  }

  static createFlightPassageBoardingInformationFromData(o) {
    if (!o) {
      return this.createEmptyFlightPassageBoardingInformation;
    }

    return JSUtils.cast(o, FlightPassageBoardingInformation);
  }
}
