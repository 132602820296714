/* eslint no-param-reassign: "off" */
/* eslint no-confusing-arrow: "off" */
/* eslint no-nested-ternary: "off" */

import { REQUEST_ERROR, REQUEST_START, REQUEST_SUCCESS } from '../../store';
import { HTTP } from '../../../http';
import { NOTIFICATION_SHOW } from '../notifications';
import i18n from '../../../plugins/i18n';

// actions names
export const GET_HANDLING_TYPES = 'GET_HANDLING_TYPES';
export const HANDLING_TYPE_DELETE = 'HANDLING_TYPE_DELETE';
export const HANDLING_TYPE_UPDATE = 'HANDLING_TYPE_UPDATE';
export const HANDLING_TYPE_CREATE = 'HANDLING_TYPE_CREATE';
export const HANDLING_TYPE_DISABLE = 'HANDLING_TYPE_DISABLE';
export const HANDLING_TYPE_ENABLE = 'HANDLING_TYPE_ENABLE';

// mutations
export const CHANGE_HANDLING_TYPES_SELECTION = 'CHANGE_HANDLING_TYPES_SELECTION';
export const GET_HANDLING_TYPES_SUCCESS = 'GET_HANDLING_TYPES_SUCCESS';
export const GET_HANDLING_TYPES_REQUESTED = 'GET_HANDLING_TYPES_REQUESTED';
export const UPDATE_HANDLING_TYPES_SUCCESS = 'UPDATE_HANDLING_TYPES_SUCCESS';


// initial state
const storeState = {
  handlingTypes: [],
  handlingTypesRequested: false,
  selectedHandlingTypes: [],
};

// getters
const storeGetters = {
  getHandlingTypes: state => state.handlingTypes.sort((a, b) => (a.code > b.code) ? 1 : ((b.code > a.code) ? -1 : 0)),
  getActiveHandlingTypes: state => state.handlingTypes.filter(elem => !elem.deleted).sort((a, b) => (a.code > b.code) ? 1 : ((b.code > a.code) ? -1 : 0)),
  getHandlingTypeById: state => id => state.handlingTypes.find(a => a.id === id),
  getHandlingTypeSelected: state => state.handlingTypesRequested,
  hasSelectedHandlingType: state => state.selectedHandlingType.length > 0,
};

// actions
const storeActions = {
  [GET_HANDLING_TYPES]: ({ commit, state }) => {
    if (state.handlingTypesRequested) {
      return;
    }
    commit(REQUEST_START);
    commit(GET_HANDLING_TYPES_REQUESTED);
    HTTP.get('handling-types')
      .then((resp) => {
        commit(REQUEST_SUCCESS);
        commit(GET_HANDLING_TYPES_SUCCESS, resp.data);
      })
      .catch((err) => {
        commit(REQUEST_ERROR, err);
      });
  },
  [HANDLING_TYPE_DELETE]: ({ commit, dispatch }, id) => {
    commit(REQUEST_START);
    return HTTP.delete(`handling-types/${id}`)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(UPDATE_HANDLING_TYPES_SUCCESS);
        dispatch(GET_HANDLING_TYPES);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.handlingTypesDelete'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
  [HANDLING_TYPE_UPDATE]: ({ commit, dispatch }, handlingType) => {
    commit(REQUEST_START);
    return HTTP.put('handling-types', handlingType)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(UPDATE_HANDLING_TYPES_SUCCESS);
        dispatch(GET_HANDLING_TYPES);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.handlingTypesUpdate'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
  [HANDLING_TYPE_CREATE]: ({ commit, dispatch }, handlingType) => {
    commit(REQUEST_START);
    return HTTP.post('handling-types', handlingType)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(UPDATE_HANDLING_TYPES_SUCCESS);
        dispatch(GET_HANDLING_TYPES);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.handlingTypesCreate'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
  [HANDLING_TYPE_DISABLE]: ({ commit, dispatch }, id) => {
    commit(REQUEST_START);
    return HTTP.patch(`/handling-types/disable/${id}`)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(UPDATE_HANDLING_TYPES_SUCCESS);
        dispatch(GET_HANDLING_TYPES);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.handlingTypesDisable'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
  [HANDLING_TYPE_ENABLE]: ({ commit, dispatch }, id) => {
    commit(REQUEST_START);
    return HTTP.patch(`/handling-types/enable/${id}`)
      .then(() => {
        commit(REQUEST_SUCCESS);
        commit(UPDATE_HANDLING_TYPES_SUCCESS);
        dispatch(GET_HANDLING_TYPES);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.handlingTypesEnable'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
};

// mutations
const storeMutations = {
  [GET_HANDLING_TYPES_SUCCESS]: (state, handlingTypes) => {
    state.handlingTypes = handlingTypes;
  },
  [GET_HANDLING_TYPES_REQUESTED]: (state) => {
    state.handlingTypesRequested = true;
  },
  [UPDATE_HANDLING_TYPES_SUCCESS]: (state) => {
    state.handlingTypesRequested = false;
  },
  [CHANGE_HANDLING_TYPES_SELECTION]: (state, selection) => {
    state.selectedHandlingType = selection;
  },
};

export default {
  state: storeState,
  getters: storeGetters,
  actions: storeActions,
  mutations: storeMutations,
};
