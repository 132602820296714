/* eslint-disable no-use-before-define,no-unused-vars,func-names,prefer-const */
/* eslint no-param-reassign: "off" */
import SockJS from 'sockjs-client';
import Stomp from 'webstomp-client';
import { SCHED_DO_EXTERNAL_CHANGE } from './scheduling';
import { OPERATION_DO_EXTERNAL_CHANGE } from './operation';
import { RAMP_DO_EXTERNAL_CHANGE } from './ramp/ramp';
import { INTEGRATION_DO_EXTERNAL_CHANGE } from './admin/integration';
import { PASSAGE_DO_EXTERNAL_CHANGE } from './passageOutbound';
import { PASSAGE_INBOUND_DO_EXTERNAL_CHANGE } from './passageInbound';
import { LOST_AND_FOUND_DO_EXTERNAL_CHANGE } from './lostAndFound/lostAndFound';
import { FLIGHT_TOWING_ADHOC_TASK_DO_EXTERNAL_CHANGE } from './towingAdhocTask/flightTowingAdhocTask';
import { PASSAGE_BOARDING_FLIGHT_DO_EXTERNAL_CHANGE } from './passageBoarding';
import AuthService from '../../components/shared/services/auth/auth-service';


// action
export const WEBSOCKET_CONNECTION = 'WEBSOCKET_CONNECTION';
export const WEBSOCKET_CONNECTION_INTEGRATION = 'WEBSOCKET_CONNECTION_INTEGRATION';
export const WEBSOCKET_CONNECTION_FLIGHT_TOWING_ADHOC_TASK = 'WEBSOCKET_CONNECTION_FLIGHT_TOWING_ADHOC_TASK';
export const WEBSOCKET_CONNECTION_FLIGHT_PASSAGE_BOARDING = 'WEBSOCKET_CONNECTION_FLIGHT_PASSAGE_BOARDING';

// mutation
export const WEBSOCKET_FLIGHTS_REQUESTED = 'WEBSOCKET_FLIGHTS_REQUESTED';
export const WEBSOCKET_INTEGRATION_REQUESTED = 'WEBSOCKET_INTEGRATION_REQUESTED';
export const WEBSOCKET_FLIGHT_TOWING_ADHOC_TASK_REQUESTED = 'WEBSOCKET_FLIGHT_TOWING_ADHOC_TASK_REQUESTED';
export const WEBSOCKET_FLIGHT_PASSAGE_BOARDING_REQUESTED = 'WEBSOCKET_FLIGHT_PASSAGE_BOARDING_REQUESTED';
export const WEBSOCKET_REQUESTED_TENANT = 'WEBSOCKET_REQUESTED_TENANT';
export const WEBSOCKET_FLIGHT_PASSAGE_BOARDING_FLIGHT_ID_REQUESTED = 'WEBSOCKET_FLIGHT_PASSAGE_BOARDING_FLIGHT_ID_REQUESTED';

// initial state
const storeState = {
  websocketFlightsStompClient: null,
  websocketIntegrationStompClient: null,
  websocketFlightTowingAdhocTaskStompClient: null,
  websocketFlightPassageBoardingStompClient: null,
  websocketTenant: null,
  websocketFlightPassageBoardingFlightId: null,
};

// getters
const storeGetters = {
};

// actions
const storeActions = {
  [WEBSOCKET_CONNECTION]: ({ commit, state, dispatch }, tenant) => {
    // if vue-cli-service serve --mode dev
    if (process.env.NODE_ENV === 'development') {
      return;
    }

    if (state.websocketTenant === tenant && state.websocketFlightsStompClient && !!state.websocketFlightsStompClient.connected) {
      return;
    }

    const stompClient = stompConnect((stompClientCallback) => {
      commit(WEBSOCKET_FLIGHTS_REQUESTED, stompClientCallback);
      stompClientCallback.subscribe(`/topic/${tenant}/flights`, (data) => {
        [OPERATION_DO_EXTERNAL_CHANGE, RAMP_DO_EXTERNAL_CHANGE, PASSAGE_DO_EXTERNAL_CHANGE, PASSAGE_INBOUND_DO_EXTERNAL_CHANGE, LOST_AND_FOUND_DO_EXTERNAL_CHANGE, SCHED_DO_EXTERNAL_CHANGE]
          .forEach(action => dispatch(action, JSON.parse(data.body)));
      }, { Authorization: AuthService.getAuthHeader() });
    }, state.websocketFlightsStompClient);

    commit(WEBSOCKET_REQUESTED_TENANT, tenant);
  },
  [WEBSOCKET_CONNECTION_INTEGRATION]: ({ commit, state, dispatch }, tenant) => {
    // if vue-cli-service serve --mode dev
    if (process.env.NODE_ENV === 'development') {
      return;
    }

    if (state.websocketTenant === tenant && state.websocketIntegrationStompClient && !!state.websocketIntegrationStompClient.connected) {
      return;
    }

    const stompClient = stompConnect((stompClientCallback) => {
      commit(WEBSOCKET_INTEGRATION_REQUESTED, stompClientCallback);
      stompClientCallback.subscribe(`/topic/integration/${tenant}`, (data) => {
        dispatch(INTEGRATION_DO_EXTERNAL_CHANGE, JSON.parse(data.body));
      }, { Authorization: AuthService.getAuthHeader() });
    }, state.websocketIntegrationStompClient);

    commit(WEBSOCKET_REQUESTED_TENANT, tenant);
  },
  [WEBSOCKET_CONNECTION_FLIGHT_TOWING_ADHOC_TASK]: ({ commit, state, dispatch }, tenant) => {
    // if vue-cli-service serve --mode dev
    if (process.env.NODE_ENV === 'development') {
      return;
    }

    if (state.websocketTenant === tenant && state.websocketFlightTowingAdhocTaskStompClient && !!state.websocketFlightTowingAdhocTaskStompClient.connected) {
      return;
    }

    const stompClient = stompConnect((stompClientCallback) => {
      commit(WEBSOCKET_FLIGHT_TOWING_ADHOC_TASK_REQUESTED, stompClientCallback);
      stompClientCallback.subscribe(`/topic/towing-adhoc-task/flights/${tenant}`, (data) => {
        [FLIGHT_TOWING_ADHOC_TASK_DO_EXTERNAL_CHANGE]
          .forEach(action => dispatch(action, JSON.parse(data.body)));
      }, { Authorization: AuthService.getAuthHeader() });
    }, state.websocketFlightTowingAdhocTaskStompClient);

    commit(WEBSOCKET_REQUESTED_TENANT, tenant);
  },
  [WEBSOCKET_CONNECTION_FLIGHT_PASSAGE_BOARDING]: ({ commit, state, dispatch }, { tenant, flightId }) => {
    // if vue-cli-service serve --mode dev
    if (process.env.NODE_ENV === 'development') {
      return;
    }
    if (state.websocketTenant === tenant
      && state.websocketFlightPassageBoardingFlightId === flightId
      && state.websocketFlightPassageBoardingStompClient
      && !!state.websocketFlightPassageBoardingStompClient.connected) {
      return;
    }

    const stompClient = stompConnect((stompClientCallback) => {
      commit(WEBSOCKET_FLIGHT_PASSAGE_BOARDING_REQUESTED, stompClientCallback);
      stompClientCallback.subscribe(`/topic/${tenant}/flight/passage/boarding/${flightId}`, (data) => {
        [PASSAGE_BOARDING_FLIGHT_DO_EXTERNAL_CHANGE]
          .forEach(action => dispatch(action, JSON.parse(data.body)));
      }, { Authorization: AuthService.getAuthHeader() });
    }, state.websocketFlightPassageBoardingStompClient);

    commit(WEBSOCKET_REQUESTED_TENANT, tenant);
    commit(WEBSOCKET_FLIGHT_PASSAGE_BOARDING_FLIGHT_ID_REQUESTED, flightId);
  },
};

// mutations
const storeMutations = {
  [WEBSOCKET_FLIGHTS_REQUESTED]: (state, stompClient) => {
    state.websocketFlightsStompClient = stompClient;
  },
  [WEBSOCKET_INTEGRATION_REQUESTED]: (state, stompClient) => {
    state.websocketIntegrationStompClient = stompClient;
  },
  [WEBSOCKET_FLIGHT_TOWING_ADHOC_TASK_REQUESTED]: (state, stompClient) => {
    state.websocketFlightTowingAdhocTaskStompClient = stompClient;
  },
  [WEBSOCKET_FLIGHT_PASSAGE_BOARDING_REQUESTED]: (state, stompClient) => {
    state.websocketFlightPassageBoardingStompClient = stompClient;
  },
  [WEBSOCKET_REQUESTED_TENANT]: (state, tenant) => {
    state.websocketTenant = tenant;
  },
  [WEBSOCKET_FLIGHT_PASSAGE_BOARDING_FLIGHT_ID_REQUESTED]: (state, flightId) => {
    state.websocketFlightPassageBoardingFlightId = flightId;
  },
};

function stompSuccessCallback(stompClient, subscriptionCallback) {
  console.log('STOMP: Connection successful'); // eslint-disable-line no-console
  if (subscriptionCallback) {
    subscriptionCallback(stompClient);
  }
}

function stompFailureCallback(stompClient, subscriptionCallback, error) {
  console.log('STOMP Error'); // eslint-disable-line no-console
  console.log(error); // eslint-disable-line no-console
  setTimeout(stompConnect, 3000, subscriptionCallback, stompClient);
  console.log('STOMP: Reconnecting in 3 seconds'); // eslint-disable-line no-console
}

function stompConnect(subscriptionCallback, oldStompClient) {
  if (oldStompClient && oldStompClient.connected) {
    oldStompClient.disconnect();
  }

  const loc = window.location;
  const url = `//${loc.host}${loc.pathname}websocket/tracker`;
  console.log(`start websocket connection with url ${url}`); // eslint-disable-line no-console
  console.log('STOMP: Attempting connection'); // eslint-disable-line no-console
  const socket = new SockJS(url);
  const stompClient = Stomp.over(socket);

  stompClient.connect(
    { Authorization: AuthService.getAuthHeader() },
    () => {
      stompSuccessCallback(stompClient, subscriptionCallback);
    },
    (error) => {
      stompFailureCallback(stompClient, subscriptionCallback, error);
    },
  );
  return stompClient;
}

export default {
  state: storeState,
  getters: storeGetters,
  actions: storeActions,
  mutations: storeMutations,
};
