/* eslint no-param-reassign: "off" */
import { HTTP } from '../../../http';
import { REQUEST_ERROR, REQUEST_START, REQUEST_SUCCESS } from '../../store';
import { NOTIFICATION_SHOW } from '../notifications';
import i18n from '../../../plugins/i18n';
import Integration from '../../../components/shared/model/integration/integration';
import IntegrationFactory from '../../../components/shared/model/integration/integrationFactory';

// action
export const ADMIN_GHM_LATAM = 'ADMIN_GHM_LATAM';
export const ADMIN_GHM_LATAM_CLEAN = 'ADMIN_GHM_LATAM_CLEAN';
export const ADMIN_GHM_SAT = 'ADMIN_GHM_SAT';
export const ADMIN_GHM_SAT_CLEAN = 'ADMIN_GHM_SAT_CLEAN';
export const ADMIN_GHM_LATAM_INTEGRATION = 'ADMIN_GHM_LATAM_INTEGRATION';
export const ADMIN_GHM_LATAM_ENABLE = 'ADMIN_GHM_LATAM_ENABLE';
export const ADMIN_GHM_LATAM_DISABLE = 'ADMIN_GHM_LATAM_DISABLE';
export const ADMIN_GHM_SAT_INTEGRATION = 'ADMIN_GHM_SAT_INTEGRATION';
export const ADMIN_GHM_SAT_ENABLE = 'ADMIN_GHM_SAT_ENABLE';
export const ADMIN_GHM_SAT_DISABLE = 'ADMIN_GHM_SAT_DISABLE';

// mutations
export const ADMIN_GHM_LATAM_REQUEST = 'ADMIN_GHM_LATAM_REQUEST';
export const ADMIN_GHM_LATAM_RESPONSE = 'ADMIN_GHM_LATAM_RESPONSE';
export const ADMIN_GHM_LATAM_ERROR = 'ADMIN_GHM_LATAM_ERROR';
export const ADMIN_GHM_LATAM_REQUEST_CLEAN = 'ADMIN_GHM_LATAM_REQUEST_CLEAN';
export const ADMIN_GHM_LATAM_RESPONSE_CLEAN = 'ADMIN_GHM_LATAM_RESPONSE_CLEAN';
export const ADMIN_GHM_LATAM_ERROR_CLEAN = 'ADMIN_GHM_LATAM_ERROR_CLEAN';
export const ADMIN_GHM_SAT_REQUEST = 'ADMIN_GHM_SAT_REQUEST';
export const ADMIN_GHM_SAT_RESPONSE = 'ADMIN_GHM_SAT_RESPONSE';
export const ADMIN_GHM_SAT_ERROR = 'ADMIN_GHM_SAT_ERROR';
export const ADMIN_GHM_SAT_REQUEST_CLEAN = 'ADMIN_GHM_SAT_REQUEST_CLEAN';
export const ADMIN_GHM_SAT_RESPONSE_CLEAN = 'ADMIN_GHM_SAT_RESPONSE_CLEAN';
export const ADMIN_GHM_SAT_ERROR_CLEAN = 'ADMIN_GHM_SAT_ERROR_CLEAN';
export const ADMIN_GHM_LATAM_RESPONSE_INTEGRATION = 'ADMIN_GHM_LATAM_RESPONSE_INTEGRATION';
export const ADMIN_GHM_SAT_RESPONSE_INTEGRATION = 'ADMIN_GHM_SAT_RESPONSE_INTEGRATION';

// initial state
const storeState = {
  ghmLatamLoading: null,
  ghmLatamSuccess: null,
  ghmLatamResult: [],
  ghmLatamLastRequestTime: null,
  ghmLatamOperation: null,

  ghmSatLoading: null,
  ghmSatSuccess: null,
  ghmSatResult: [],
  ghmSatLastRequestTime: null,
  ghmSatOperation: null,

  ghmLatamIntegration: Integration.createEmptyIntegration,
  ghmSatIntegration: Integration.createEmptyIntegration,
};

// getters
const storeGetters = {
  isGhmLatamSuccess: state => state.ghmLatamSuccess != null && !!state.ghmLatamSuccess,
  isGhmLatamError: state => state.ghmLatamSuccess != null && !state.ghmLatamSuccess,
  isGhmLatamSuccessClean: state => state.ghmLatamSuccessClean != null && !!state.ghmLatamSuccessClean,
  isGhmLatamErrorClean: state => state.ghmLatamSuccessClean != null && !state.ghmLatamSuccessClean,
  isGhmSatSuccess: state => state.ghmSatSuccess != null && !!state.ghmSatSuccess,
  isGhmSatError: state => state.ghmSatSuccess != null && !state.ghmSatSuccess,
  isGhmSatSuccessClean: state => state.ghmSatSuccessClean != null && !!state.ghmSatSuccessClean,
  isGhmSatErrorClean: state => state.ghmSatSuccessClean != null && !state.ghmSatSuccessClean,
};

// actions
const storeActions = {
  [ADMIN_GHM_LATAM]: ({ commit }) => {
    commit(ADMIN_GHM_LATAM_REQUEST);
    return HTTP.post('integration/ghm/latam')
      .then((resp) => {
        commit(ADMIN_GHM_LATAM_RESPONSE, resp.data);
      })
      .catch((err) => {
        commit(ADMIN_GHM_LATAM_ERROR, err.response.data);
      });
  },
  [ADMIN_GHM_LATAM_CLEAN]: ({ commit }) => {
    commit(ADMIN_GHM_LATAM_REQUEST_CLEAN);
    return HTTP.patch('integration/ghm/latam/clean')
      .then((resp) => {
        commit(ADMIN_GHM_LATAM_RESPONSE_CLEAN, resp.data);
      })
      .catch((err) => {
        commit(ADMIN_GHM_LATAM_ERROR_CLEAN, err.response.data);
      });
  },
  [ADMIN_GHM_SAT]: ({ commit }) => {
    commit(ADMIN_GHM_SAT_REQUEST);
    return HTTP.post('integration/ghm/sat')
      .then((resp) => {
        commit(ADMIN_GHM_SAT_RESPONSE, resp.data);
      })
      .catch((err) => {
        commit(ADMIN_GHM_SAT_ERROR, err.response.data);
      });
  },
  [ADMIN_GHM_SAT_CLEAN]: ({ commit }) => {
    commit(ADMIN_GHM_SAT_REQUEST_CLEAN);
    return HTTP.patch('integration/ghm/sat/clean')
      .then((resp) => {
        commit(REQUEST_SUCCESS);
        commit(ADMIN_GHM_SAT_RESPONSE_CLEAN, resp.data);
      })
      .catch((err) => {
        commit(ADMIN_GHM_SAT_ERROR_CLEAN, err.response.data);
      });
  },
  [ADMIN_GHM_LATAM_INTEGRATION]: ({ commit }) => {
    commit(REQUEST_START);
    return HTTP.get('integration/ghm/latam')
      .then((resp) => {
        commit(REQUEST_SUCCESS);
        commit(ADMIN_GHM_LATAM_RESPONSE_INTEGRATION, resp.data);
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
  [ADMIN_GHM_LATAM_ENABLE]: ({ commit }) => {
    commit(REQUEST_START);
    return HTTP.patch('integration/ghm/latam/enable')
      .then((resp) => {
        commit(REQUEST_SUCCESS);
        commit(ADMIN_GHM_LATAM_RESPONSE_INTEGRATION, resp.data);
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
  [ADMIN_GHM_LATAM_DISABLE]: ({ commit }) => {
    commit(REQUEST_START);
    return HTTP.patch('integration/ghm/latam/disable')
      .then((resp) => {
        commit(REQUEST_SUCCESS);
        commit(ADMIN_GHM_LATAM_RESPONSE_INTEGRATION, resp.data);
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
  [ADMIN_GHM_SAT_INTEGRATION]: ({ commit }) => {
    commit(REQUEST_START);
    return HTTP.get('integration/ghm/sat')
      .then((resp) => {
        commit(REQUEST_SUCCESS);
        commit(ADMIN_GHM_SAT_RESPONSE_INTEGRATION, resp.data);
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
  [ADMIN_GHM_SAT_ENABLE]: ({ commit }) => {
    commit(REQUEST_START);
    return HTTP.patch('integration/ghm/sat/enable')
      .then((resp) => {
        commit(REQUEST_SUCCESS);
        commit(ADMIN_GHM_SAT_RESPONSE_INTEGRATION, resp.data);
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
  [ADMIN_GHM_SAT_DISABLE]: ({ commit }) => {
    commit(REQUEST_START);
    return HTTP.patch('integration/ghm/sat/disable')
      .then((resp) => {
        commit(REQUEST_SUCCESS);
        commit(ADMIN_GHM_SAT_RESPONSE_INTEGRATION, resp.data);
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
};

// mutations
const storeMutations = {
  [ADMIN_GHM_LATAM_REQUEST]: (state) => {
    state.ghmLatamLoading = true;
    state.ghmLatamSuccess = null;
    state.ghmLatamResult = [];
    state.ghmLatamLastRequestTime = new Date();
    state.ghmLatamOperation = 'GHM Latam Integration';
  },
  [ADMIN_GHM_LATAM_RESPONSE]: (state, result) => {
    state.ghmLatamLoading = false;
    state.ghmLatamSuccess = result.success;
    state.ghmLatamResult = result.traces;
    state.ghmLatamOperation = 'GHM Latam Integration';
  },
  [ADMIN_GHM_LATAM_ERROR]: (state, result) => {
    state.ghmLatamLoading = false;
    state.ghmLatamSuccess = false;
    state.ghmLatamResult = result.traces;
    state.ghmLatamResult.push(result.message);
    state.ghmLatamResult.push(result.detail);
    state.ghmLatamOperation = 'GHM Latam Integration';
  },
  [ADMIN_GHM_LATAM_REQUEST_CLEAN]: (state) => {
    state.ghmLatamLoading = true;
    state.ghmLatamSuccess = null;
    state.ghmLatamResult = [];
    state.ghmLatamLastRequestTime = new Date();
    state.ghmLatamOperation = 'GHM Latam Integration Clean';
  },
  [ADMIN_GHM_LATAM_RESPONSE_CLEAN]: (state, result) => {
    state.ghmLatamLoading = false;
    state.ghmLatamSuccess = result.success;
    state.ghmLatamResult = result.traces;
    state.ghmLatamOperation = 'GHM Latam Integration Clean';
  },
  [ADMIN_GHM_LATAM_ERROR_CLEAN]: (state, result) => {
    state.ghmLatamLoading = false;
    state.ghmLatamSuccess = false;
    state.ghmLatamResult = result.traces;
    state.ghmLatamResult.push(result.message);
    state.ghmLatamResult.push(result.detail);
    state.ghmLatamOperation = 'GHM Latam Integration Clean';
  },
  [ADMIN_GHM_SAT_REQUEST]: (state) => {
    state.ghmSatLoading = true;
    state.ghmSatSuccess = null;
    state.ghmSatResult = [];
    state.ghmSatLastRequestTime = new Date();
    state.ghmSatOperation = 'GHM Sat Integration';
  },
  [ADMIN_GHM_SAT_RESPONSE]: (state, result) => {
    state.ghmSatLoading = false;
    state.ghmSatSuccess = result.success;
    state.ghmSatResult = result.traces;
    state.ghmSatOperation = 'GHM Sat Integration';
  },
  [ADMIN_GHM_SAT_ERROR]: (state, result) => {
    state.ghmSatLoading = false;
    state.ghmSatSuccess = false;
    state.ghmSatResult = result.traces;
    state.ghmSatResult.push(result.message);
    state.ghmSatResult.push(result.detail);
    state.ghmSatOperation = 'GHM Sat Integration';
  },
  [ADMIN_GHM_SAT_REQUEST_CLEAN]: (state) => {
    state.ghmSatLoadingClean = true;
    state.ghmSatSuccessClean = null;
    state.ghmSatResultClean = [];
    state.ghmSatLastRequestTimeClean = new Date();
    state.ghmSatOperation = 'GHM Sat Integration Clean';
  },
  [ADMIN_GHM_SAT_RESPONSE_CLEAN]: (state, result) => {
    state.ghmSatLoading = false;
    state.ghmSatSuccess = result.success;
    state.ghmSatResult = result.traces;
    state.ghmSatOperation = 'GHM Sat Integration Clean';
  },
  [ADMIN_GHM_SAT_ERROR_CLEAN]: (state, result) => {
    state.ghmSatLoading = false;
    state.ghmSatSuccess = false;
    state.ghmSatResult = result.traces;
    state.ghmSatResult.push(result.message);
    state.ghmSatResult.push(result.detail);
    state.ghmSatOperation = 'GHM Sat Integration';
  },
  [ADMIN_GHM_LATAM_RESPONSE_INTEGRATION]: (state, result) => {
    state.ghmLatamIntegration = IntegrationFactory.createIntegrationFromData(result);
  },
  [ADMIN_GHM_SAT_RESPONSE_INTEGRATION]: (state, result) => {
    state.ghmSatIntegration = IntegrationFactory.createIntegrationFromData(result);
  },
};

export default {
  state: storeState,
  getters: storeGetters,
  actions: storeActions,
  mutations: storeMutations,
};
