<template>
  <div>
    <entity-configuration-list
      :entity-name="this.$i18n.t('configuration.airportGate')"
      entity-icon="video_label"
      :framework-components="frameworkComponents" :column-defs="columnDefs" :items="airportGates"
      @confirm-edit-item="editConfirm"
      @confirm-delete-item="deleteConfirm"
      @confirm-enable-disable-item="enableDisableConfirm"
    >
      <template v-slot:modal-content="{ itemEdit }">
      <airport-gate-edit-card-content :item="itemEdit" />
      </template>
    </entity-configuration-list>
  </div>
</template>
<script>

import {
  GET_AIRPORT_GATE,
  AIRPORT_GATE_CREATE,
  AIRPORT_GATE_DELETE,
  AIRPORT_GATE_UPDATE,
  AIRPORT_GATE_DISABLE,
  AIRPORT_GATE_ENABLE,
} from '@/store/modules/airportGate/airportGateCatalog';
import EntityConfigurationList from '@/components/configuration/common/EntityConfigurationList.vue';
import AgGridToggleCellEditor from '../../ag-grid/cell-editors/AgGridToggleCellEditor.vue';
import AgGridRowActionButtonsCellRenderer from '../../ag-grid/cell-renderers/AgGridRowActionButtonsCellRenderer.vue';

import AirportGateEditCardContent from './AirportGateEditCardContent.vue';
import AgGridIconValueOrDashCellRenderer from '../../ag-grid/cell-renderers/AgGridIconValueOrDashCellRenderer.vue';
import AgGridIconCellRenderer from '../../ag-grid/cell-renderers/AgGridIconCellRenderer.vue';


export default {
  name: 'AirportGatesList',
  components: {
    EntityConfigurationList,
    AirportGateEditCardContent,
  },
  data() {
    const that = this;
    return {
      frameworkComponents: {
        AgGridIconValueOrDashCellRenderer,
        AgGridToggleCellEditor,
        AgGridRowActionButtonsCellRenderer,
        AgGridIconCellRenderer,
      },
      columnDefs: [
        {
          field: 'code',
          headerValueGetter() {
            return that.$i18n.t('configuration.code');
          },
        },
        {
          field: 'codeInterface',
          headerValueGetter() {
            return that.$i18n.t('configuration.codeInterface');
          },
        },
        {
          field: 'comments',
          headerValueGetter() {
            return that.$i18n.t('configuration.description');
          },
        },
        {
          headerValueGetter() {
            return that.$i18n.t('configuration.remote');
          },
          cellRendererFramework: AgGridIconValueOrDashCellRenderer,
          valueGetter(params) {
            return (params.data.remote) ? 'R' : null;
          },
        },
        {
          headerValueGetter() {
            return that.$i18n.t('configuration.boardingGate');
          },
          cellRendererFramework: AgGridIconValueOrDashCellRenderer,
          valueGetter(params) {
            return (params.data.boardingGate) ? that.$i18n.t('configuration.yes') : that.$i18n.t('configuration.no');
          },
        },
        {
          headerValueGetter() {
            return that.$i18n.t('configuration.deboardingGate');
          },
          cellRendererFramework: AgGridIconValueOrDashCellRenderer,
          valueGetter(params) {
            return (params.data.unboardingGate) ? that.$i18n.t('configuration.yes') : that.$i18n.t('configuration.no');
          },
        },
        {
          headerValueGetter() {
            return that.$i18n.t('configuration.disabled');
          },
          field: 'deleted',
          cellRendererFramework: AgGridIconCellRenderer,
          cellRendererParams: {
            icon: 'mdi-cancel',
            tooltip: params => (params.data.deleted ? this.$i18n.t('configuration.disabled') : null),
          },
        },
      ],
    };
  },
  computed: {
    airportGates() {
      return this.$store.getters.getAirportGates;
    },
  },
  methods: {
    getAirportGates() {
      this.$store.dispatch(GET_AIRPORT_GATE);
    },
    deleteConfirm(item) {
      this.$store.dispatch(AIRPORT_GATE_DELETE, item.id);
    },
    editConfirm(item) {
      this.$store.dispatch(item.id ? AIRPORT_GATE_UPDATE : AIRPORT_GATE_CREATE, item);
    },
    enableDisableConfirm(item) {
      this.$store.dispatch(item.deleted ? AIRPORT_GATE_ENABLE : AIRPORT_GATE_DISABLE, item.id);
    },
  },
  created() {
    this.getAirportGates();
  },
};
</script>
