export default class FlightPassageBoardingEvent {
  constructor(
    type,
    ts,
    origin,
    payload,
  ) {
    this.type = type;
    this.ts = ts;
    this.origin = origin;
    this.payload = payload;
  }
}

export const createFlightPassageBoardingEventFromData = function createFlightPassageBoardingEventFromData(data) {
  if (data) {
    return new FlightPassageBoardingEvent(
      data.type,
      data.ts,
      data.origin,
      data.payload,
    );
  }

  throw new Error('No data for FlightPassageBoardingEvent');
};
