<template>
  <v-container fluid class="contentData">
    <v-layout row justify class="infoNewCatalogs" v-if="!validData && items.aircraftAircraftTypeCatalogMismatch && items.aircraftAircraftTypeCatalogMismatch.length>0">
      <v-flex xs6>
        <div><v-icon color="primary">mdi-alert</v-icon><span> {{$t("schedulingImport.mismatchAlert")}}</span></div>
      </v-flex>
    </v-layout>
    <v-layout row justify-center v-if="!validData && items.aircraftAircraftTypeCatalogMismatch && items.aircraftAircraftTypeCatalogMismatch.length>0">
      <v-flex xs6>
        <ul>
          <li v-for="(item, index) in items.aircraftAircraftTypeCatalogMismatch" :key="index">
            {{ item.aircraftRegisterNumberImport }} - {{ item.aircraftTypeImport}}, {{$t("schedulingImport.aircraftTypeInCatalog")}} {{ item.aircraftTypeSystem }}
          </li>
        </ul>
      </v-flex>
    </v-layout>
    <v-layout row justify class="infoNewCatalogs" v-if="!validData && items.newHandlingTypes && items.newHandlingTypes.length>0">
      <v-flex xs6>
        <div><v-icon color="primary">mdi-alert</v-icon><span> {{$t("schedulingImport.handlingTypeAlert")}}</span></div>
      </v-flex>
    </v-layout>
    <v-layout row justify-center v-if="!validData && items.newHandlingTypes && items.newHandlingTypes.length>0">
      <v-flex xs6>
        <ul>
          <li v-for="(item, index) in items.newHandlingTypes" :key="index">
            {{ item }}
          </li>
        </ul>
      </v-flex>
    </v-layout>
    <v-layout row justify class="infoNewCatalogs" v-if="!validData && items.newServiceTypes && items.newServiceTypes.length>0">
      <v-flex xs6>
        <div><v-icon color="primary">mdi-alert</v-icon><span> {{$t("schedulingImport.serviceTypeAlert")}}</span></div>
      </v-flex>
    </v-layout>
    <v-layout row justify-center v-if="!validData && items.newServiceTypes && items.newServiceTypes.length>0">
      <v-flex xs6>
        <ul>
          <li v-for="(item, index) in items.newServiceTypes" :key="index">
            {{ item }}
          </li>
        </ul>
      </v-flex>
    </v-layout>
    <v-layout row justify class="infoNewCatalogs" v-if="!validData && items.serviceTypeMismatches && items.serviceTypeMismatches.length>0">
      <v-flex xs6>
        <div><v-icon color="primary">mdi-alert</v-icon><span> {{$t("schedulingImport.incorrectServiceTypes")}}</span></div>
      </v-flex>
    </v-layout>
    <v-layout row justify-center v-if="!validData && items.serviceTypeMismatches && items.serviceTypeMismatches.length>0">
      <v-flex xs6>
        <ul>
          <li v-for="(item, index) in items.serviceTypeMismatches" :key="index">
            {{ `${item.flightIdentifier.tripNumber + item.flightIdentifier.airlineIataCode} (${item.flightIdentifier.flightDate})` }}
            {{$t("schedulingImport.serviceTypeImported")}} <b style="color: red;">{{ formatServiceType(item.serviceTypeImport) }}</b>
            {{$t("schedulingImport.serviceTypeSuggested")}} <b style="color: green;">{{ formatServiceType(item.serviceTypeSystem) }}</b>
          </li>
        </ul>
      </v-flex>
    </v-layout>
    <v-layout row justify class="infoNewCatalogs" v-if="!validData">
      <v-flex xs12>
        <div v-if="!validData"><span class="font-weight-bold">{{$t("schedulingImport.configurationModuleError")}}</span></div>
      </v-flex>
    </v-layout>
    <v-layout row justify class="infoNewAirlines">
      <v-flex xs8>
        <div v-if="!this.isNewCatalogsEmpty() && items.newAirlines && items.newAirlines.length>0"><v-icon color="warning">mdi-alert</v-icon><span> {{$t("schedulingImport.newAirlinesAlert")}}</span></div>

      </v-flex>
    </v-layout>
    <v-layout row justify class="infoNewCatalogs">
      <v-flex xs6>
        <div v-if="this.isNewCatalogsEmpty()"><span>{{$t("schedulingImport.emptyNewCatalogs")}}</span></div>
        <div v-else><v-icon color="warning">mdi-alert</v-icon><span> {{$t("schedulingImport.newElementsAlert")}}</span></div>

      </v-flex>
    </v-layout>
    <v-layout row justify-center v-if="!this.isNewCatalogsEmpty()">
      <v-flex xs2 v-if="items.newAircrafts && items.newAircrafts.length>0">
        {{$t("schedulingImport.aircraftsNew")}}
        <ul>
          <li v-for="(item, index) in items.newAircrafts" :key="index">
            {{ item }}
          </li>
        </ul>
      </v-flex>
      <v-flex xs2 v-if="items.newAircraftConfigs && items.newAircraftConfigs.length>0">
        {{$t("schedulingImport.aircraftsConfigNew")}}
        <ul>
          <li v-for="(item, index) in items.newAircraftConfigs" :key="index">
            {{ item }}
          </li>
        </ul>
      </v-flex>
      <v-flex xs2 v-if="items.newAircraftTypes && items.newAircraftTypes.length>0">
        {{$t("schedulingImport.aircraftsTypeNew")}}
        <ul>
          <li v-for="(item, index) in items.newAircraftTypes" :key="index">
            {{ item }}
          </li>
        </ul>
      </v-flex>
      <v-flex xs2 v-if="items.newAirlines && items.newAirlines.length>0">
        {{$t("schedulingImport.airlinesNew")}}
        <ul>
          <li v-for="(item, index) in items.newAirlines" :key="index">
            {{ item }}
          </li>
        </ul>
      </v-flex>
      <v-flex xs2 v-if="items.newAirports && items.newAirports.length>0">
        {{$t("schedulingImport.airportsNew")}}
        <ul>
          <li v-for="(item, index) in items.newAirports" :key="index">
            {{ item }}
          </li>
        </ul>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>

export default {
  name: 'scheduling-import-step-validation',
  components: {

  },
  props: {
    items: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      validData: true,
    };
  },
  methods: {
    isNewCatalogsEmpty() {
      return !(
        (this.items.newAircrafts && this.items.newAircrafts.length !== 0)
        || (this.items.newAircraftTypes && this.items.newAircraftTypes.length !== 0)
        || (this.items.newAircraftConfigs && this.items.newAircraftConfigs.length !== 0)
        || (this.items.newAirlines && this.items.newAirlines.length !== 0)
        || (this.items.newAirports && this.items.newAirports.length !== 0)
      );
    },
    inconsistentData() {
      return this.inconsistentAircraftType()
        || this.inconsistentServiceType();
    },
    inconsistentAircraftType() {
      return this.items.aircraftAircraftTypeCatalogMismatch !== null
        && this.items.aircraftAircraftTypeCatalogMismatch.length > 0;
    },
    inconsistentServiceType() {
      return this.items.serviceTypeMismatches !== null
        && this.items.serviceTypeMismatches.length > 0;
    },
    missingData() {
      return this.missingHandlingTypes()
      || this.missingServiceTypes();
    },
    missingHandlingTypes() {
      return this.items.newHandlingTypes !== null && this.items.newHandlingTypes.length > 0;
    },
    missingServiceTypes() {
      return this.items.newServiceTypes !== null && this.items.newServiceTypes.length > 0;
    },
    isValidData() {
      this.validData = (!this.missingData() && !this.inconsistentData());
      this.$emit('dataValidation', this.validData);
    },
    formatServiceType(serviceType) {
      return (!serviceType || serviceType === '') ? 'null' : serviceType;
    },
  },
  mounted() {
    this.isValidData();
  },
};
</script>
<style lang="scss" scoped>
//@import "../../../assets/scss/ag-grid-fis-theme";
  .infoNewCatalogs {
    padding: 2em;
    font-weight: bold;
  }
  .infoNewAirlines {
    padding: .5em 2em;
    font-weight: bold;
  }
.contentData {
  max-height: 60vh;
  overflow-y: auto;
}
</style>
