/* eslint no-param-reassign: "off" */
import { HTTP } from '../../../http';
import { REQUEST_ERROR, REQUEST_START, REQUEST_SUCCESS } from '../../store';
import { NOTIFICATION_SHOW } from '../notifications';
import i18n from '../../../plugins/i18n';
import Integration from '../../../components/shared/model/integration/integration';
import IntegrationFactory from '../../../components/shared/model/integration/integrationFactory';

// action
export const ADMIN_SFTP = 'ADMIN_SFTP';
export const ADMIN_SFTP_INTEGRATION = 'ADMIN_SFTP_INTEGRATION';
export const ADMIN_SFTP_ENABLE = 'ADMIN_SFTP_ENABLE';
export const ADMIN_SFTP_DISABLE = 'ADMIN_SFTP_DISABLE';
export const ADMIN_SFTP_CLEAN = 'ADMIN_SFTP_CLEAN';

// mutations
export const ADMIN_SFTP_REQUEST = 'ADMIN_SFTP_REQUEST';
export const ADMIN_SFTP_RESPONSE = 'ADMIN_SFTP_RESPONSE';
export const ADMIN_SFTP_ERROR = 'ADMIN_SFTP_ERROR';
export const ADMIN_SFTP_RESPONSE_INTEGRATION = 'ADMIN_SFTP_RESPONSE_INTEGRATION';
export const ADMIN_SFTP_REQUEST_CLEAN = 'ADMIN_SFTP_REQUEST_CLEAN';
export const ADMIN_SFTP_RESPONSE_CLEAN = 'ADMIN_SFTP_RESPONSE_CLEAN';
export const ADMIN_SFTP_ERROR_CLEAN = 'ADMIN_SFTP_ERROR_CLEAN';

// initial state
const storeState = {
  sftpLoading: null,
  sftpSuccess: null,
  sftpResult: [],
  sftpLastRequestTime: null,
  sftpOperation: null,

  sftpIntegration: Integration.createEmptyIntegration,
};

// getters
const storeGetters = {
  isSftpSuccess: state => state.sftpSuccess != null && !!state.sftpSuccess,
  isSftpError: state => state.sftpSuccess != null && !state.sftpSuccess,
};

// actions
const storeActions = {
  [ADMIN_SFTP]: ({ commit }) => {
    commit(ADMIN_SFTP_REQUEST);
    return HTTP.post('integration/scl-sftp')
      .then((resp) => {
        commit(ADMIN_SFTP_RESPONSE, resp.data);
      })
      .catch((err) => {
        commit(ADMIN_SFTP_ERROR, err.response.data);
      });
  },
  [ADMIN_SFTP_INTEGRATION]: ({ commit }) => {
    commit(REQUEST_START);
    return HTTP.get('integration/scl-sftp')
      .then((resp) => {
        commit(REQUEST_SUCCESS);
        commit(ADMIN_SFTP_RESPONSE_INTEGRATION, resp.data);
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
  [ADMIN_SFTP_ENABLE]: ({ commit }) => {
    commit(REQUEST_START);
    return HTTP.patch('integration/scl-sftp/enable')
      .then((resp) => {
        commit(REQUEST_SUCCESS);
        commit(ADMIN_SFTP_RESPONSE_INTEGRATION, resp.data);
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
  [ADMIN_SFTP_DISABLE]: ({ commit }) => {
    commit(REQUEST_START);
    return HTTP.patch('integration/scl-sftp/disable')
      .then((resp) => {
        commit(REQUEST_SUCCESS);
        commit(ADMIN_SFTP_RESPONSE_INTEGRATION, resp.data);
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
  [ADMIN_SFTP_CLEAN]: ({ commit }) => {
    commit(ADMIN_SFTP_REQUEST_CLEAN);
    return HTTP.patch('integration/scl-sftp/clean')
      .then((resp) => {
        commit(ADMIN_SFTP_RESPONSE_CLEAN, resp.data);
      })
      .catch((err) => {
        commit(ADMIN_SFTP_ERROR_CLEAN, err.response.data);
      });
  },
};

// mutations
const storeMutations = {
  [ADMIN_SFTP_REQUEST]: (state) => {
    state.sftpLoading = true;
    state.sftpSuccess = null;
    state.sftpResult = [];
    state.sftpLastRequestTime = new Date();
    state.sftpOperation = 'SFTP Integration';
  },
  [ADMIN_SFTP_RESPONSE]: (state, result) => {
    state.sftpLoading = false;
    state.sftpSuccess = result.success;
    state.sftpResult = result.traces;
    state.sftpOperation = 'SFTP Integration';
  },
  [ADMIN_SFTP_ERROR]: (state, result) => {
    state.sftpLoading = false;
    state.sftpSuccess = false;
    state.sftpResult = result.traces;
    state.sftpResult.push(result.message);
    state.sftpResult.push(result.detail);
    state.sftpOperation = 'SFTP Integration';
  },
  [ADMIN_SFTP_RESPONSE_INTEGRATION]: (state, result) => {
    state.sftpIntegration = IntegrationFactory.createIntegrationFromData(result);
  },
  [ADMIN_SFTP_REQUEST_CLEAN]: (state) => {
    state.sftpLoading = true;
    state.sftpSuccess = null;
    state.sftpResult = [];
    state.sftpLastRequestTime = new Date();
    state.sftpOperation = 'SFTP Integration Clean';
  },
  [ADMIN_SFTP_RESPONSE_CLEAN]: (state, result) => {
    state.sftpLoading = false;
    state.sftpSuccess = result.success;
    state.sftpResult = result.traces;
    state.sftpOperation = 'SFTP Integration Clean';
  },
  [ADMIN_SFTP_ERROR_CLEAN]: (state, result) => {
    state.sftpLoading = false;
    state.sftpSuccess = false;
    state.sftpResult = result.traces;
    state.sftpResult.push(result.message);
    state.sftpResult.push(result.detail);
    state.sftpOperation = 'SFTP Integration Clean';
  },
};

export default {
  state: storeState,
  getters: storeGetters,
  actions: storeActions,
  mutations: storeMutations,
};
