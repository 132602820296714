<template>
    <v-form ref="form" v-model="valid">
      <v-text-field class="delivery-baggage-number-input" :ref="refNumberInput" type="number" v-model="updatedNumber" :rules="rules"></v-text-field>
    </v-form>
</template>

<script>
import Vue from 'vue';
import AgGridParentFlightDeliveryBaggageCellEditor from '../mixins/AgGridParentFlightDeliveryBaggageCellEditor.vue';

export default Vue.extend({
  mixins: [AgGridParentFlightDeliveryBaggageCellEditor],
  components: {
  },
  props: {
    refNumberInput: {
      type: String,
      required: false,
      default: 'refNumberInput',
    },
  },
  data() {
    return {
      originalNumber: '',
      updatedNumber: '',
      valid: true,
      rules: [],
    };
  },
  methods: {
    getValue() {
      this.unmarkCellsSelectedAsEdit();
      if (this.$refs.form.validate()) {
        if (this.originalNumber !== this.updatedNumber) {
          const that = this;
          const deliveryPropertyName = this.getFlightDeliveryBaggagePropertyName();
          this.saveForValueOrAllSelectedDeliveries((s) => {
            s.setChildPropertyValue(deliveryPropertyName, that.updatedNumber);
          }, deliveryPropertyName);
        }
        return this.updatedNumber;
      }
      return this.originalNumber;
    },
  },
  created() {
    this.originalNumber = this.params.value ? this.params.value : '';
    this.updatedNumber = this.originalNumber;
    this.rules = this.params.column.colDef.colCustomConfig.rules ? this.params.column.colDef.colCustomConfig.rules : [];

    this.markCellsSelectedAsEdit();
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$refs.refNumberInput) {
        this.$refs.refNumberInput.$refs.input.focus();
      }
    });
  },
});
</script>

<style lang="scss" scoped>
  .delivery-baggage-number-cell.ag-cell-inline-editing {
    width: auto !important;
  }
  .delivery-baggage-number-input .v-input__control {
    flex-direction: column-reverse;
  }
  .delivery-baggage-number-input .v-text-field__details {
    margin-right: 5px;
  }
  .delivery-baggage-number-input {
    padding: 0;
  }
</style>
