<template>
  <div>
    <vue-dropzone ref="myVueDropzone" id="dropzone" :options="dropzoneOptions" @vdropzone-success="uploadFile"/>
  </div>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';

export default {
  name: 'DirectoryImageUploader',
  components: {
    vueDropzone: vue2Dropzone,
  },
  data: () => ({
    dropzoneOptions: {
      thumbnailWidth: 250,
      url: 'https://httpbin.org/post',
      acceptedFiles: 'image/*',
      maxFilesize: 1,
      headers: { 'My-Awesome-Header': 'header value' },
      dictDefaultMessage: 'Drop files here to upload image',
    },
    file: null,
  }),
  methods: {
    uploadFile(file) {
      if (file.accepted) {
        this.$emit('add-directory-file-image', file.dataURL);
      }
    },
  },
};
</script>

<style>
#fileInput {
  display: none;
}
</style>
