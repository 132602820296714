import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('ag-grid-dialog-template-show',{attrs:{"is-dialog-open":_vm.isDialogOpen},on:{"cancel":_vm.cancel},scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('operations.airportStatus'))+" "+_vm._s(_vm.flightNumber)+" ")]},proxy:true},{key:"default",fn:function(){return [_c(VContainer,{attrs:{"grid-list-md":"","text-xs-center":"","fluid":""}},[_c(VLayout,{attrs:{"row":"","wrap":""}},[_c(VFlex,{attrs:{"xs12":""}},[_c(VCard,[_c(VCardText,[_c(VContainer,{attrs:{"fluid":""}},[_c(VLayout,{attrs:{"align-center":"","justify-center":"","fill-height":""}},[_c(VFlex,{attrs:{"xs2":""}},[_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(_vm.$t("operations.airportStatus")))])]),_c(VFlex,{attrs:{"xs3":""}},[_c('span',[_vm._v(_vm._s(_vm.$t(`flightStatusIntegration.${_vm.status}`)))])]),_c(VFlex,{attrs:{"xs2":""}},[_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(_vm.$t("operations.updatedAt")))])]),_c(VFlex,{attrs:{"xs3":""}},[_c('span',[_vm._v(_vm._s(_vm.updateAt))])])],1)],1)],1)],1)],1)],1)],1)]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }