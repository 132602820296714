<template>
  <div>
    <v-flex v-flex-none>
      <handling-type-select :ref="'refComp'" v-on:keydown.native="keyEvent" v-char-length.clearable="4" v-model="handlingTypeIdUpdated"></handling-type-select>
    </v-flex>
  </div>
</template>

<script>
import Vue from 'vue';
import HandlingTypeSelect from '../../../handling/HandlingTypeSelect.vue';
import AgGridParentConnectedFlightsCellEditor from '../mixins/AgGridParentConnectedFlightsCellEditor.vue';

export default Vue.extend({
  mixins: [AgGridParentConnectedFlightsCellEditor],
  components: {
    HandlingTypeSelect,
  },
  data() {
    return {
      handlingTypeIdOriginal: null,
      handlingTypeIdUpdated: null,
      flight: {},
    };
  },
  methods: {
    getValue() {
      this.unmarkCellsSelectedAsEdit();
      if (this.handlingTypeIdOriginal !== this.handlingTypeIdUpdated) {
        const handlingType = this.$store.getters.getHandlingTypeById(this.handlingTypeIdUpdated);
        const code = typeof (handlingType) !== 'undefined' ? handlingType.code : null;

        this.saveFlightChangesFromValue((s) => {
          s.setChildPropertyValueByBound(this.params.column.colDef.colCustomConfig.flightBound, 'common.handlingTypeId', this.handlingTypeIdUpdated);
          s.setChildPropertyValueByBound(this.params.column.colDef.colCustomConfig.flightBound, 'common.handlingTypeCode', code);
        }, 'Handling Type', this.handlingTypeIdOriginal, this.handlingTypeIdUpdated);
      }
      return this.params.node.data;
    },
  },
  created() {
    const schedulingFlight = this.params.node.data;
    this.flight = schedulingFlight.getBound(this.params.column.colDef.colCustomConfig.flightBound);
    this.handlingTypeIdOriginal = this.flight.handlingTypeId;
    this.handlingTypeIdUpdated = this.handlingTypeIdOriginal;

    this.markCellsSelectedAsEdit();
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$refs.refComp) {
        this.$refs.refComp.$refs.refComp.focus();
      }
    });
  },
});
</script>

<style>
  .handling-type-cell.ag-cell-inline-editing {
    min-width: 100px;
  }
</style>
