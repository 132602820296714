<template>
        <div>

          <v-container fluid class="containerTitleSummary">
            <v-layout>
              <v-flex xs12 class="containerInfoSummary">
                <span v-if="summaryRampServices.flightRampServicesImport && summaryRampServices.flightRampServicesImport.length > 0">{{summaryRampServices.flightRampServicesImport.length}} {{$t("rampServiceImport.importFlightRampServiceLengthInfo")}}</span>
                <span v-else>{{$t("rampServiceImport.importFlightRampServiceEmptySummaryInfo")}}</span>
              </v-flex>
              <v-flex xs12 class="containerInfoSummary">
                <span v-if="summaryRampServices.aircraftGroundServicesImport && summaryRampServices.aircraftGroundServicesImport.length > 0 && summaryRampServices.aircraftGroundServicesImportThatCreateAircraftGround && summaryRampServices.aircraftGroundServicesImportThatCreateAircraftGround.length > 0">{{summaryRampServices.aircraftGroundServicesImport.length + summaryRampServices.aircraftGroundServicesImportThatCreateAircraftGround.length}} {{$t("rampServiceImport.importAircraftGroundRampServiceLengthInfo")}}</span>
                <span v-else-if="summaryRampServices.aircraftGroundServicesImport && summaryRampServices.aircraftGroundServicesImport.length > 0">{{summaryRampServices.aircraftGroundServicesImport.length}} {{$t("rampServiceImport.importAircraftGroundRampServiceLengthInfo")}}</span>
                <span v-else-if="summaryRampServices.aircraftGroundServicesImportThatCreateAircraftGround && summaryRampServices.aircraftGroundServicesImportThatCreateAircraftGround.length > 0">{{summaryRampServices.aircraftGroundServicesImportThatCreateAircraftGround.length}} {{$t("rampServiceImport.importAircraftGroundRampServiceLengthInfo")}}</span>
                <span v-else>{{$t("rampServiceImport.importAircraftGroundRampServiceEmptySummaryInfo")}}</span>
              </v-flex>
            </v-layout>
          </v-container>
          <ag-grid-vue
            class="ag-theme-material"
            :defaultColDef="defaultColDef"
            :columnDefs="columnDefs"
            :rowData="items"
            :onGridReady="onGridReady"
            :suppressHorizontalScroll="true"
            style="width: 100%; height: calc(100vh - 500px);">
          </ag-grid-vue>
        </div>

</template>
<script>
import
{ AgGridVue } from 'ag-grid-vue';
import TimezoneConfigurable from '../../mixins/TimezoneConfigurable.vue';
import AgGridDateTimeWithBackgroundCellRenderer from '../../ag-grid/cell-renderers/AgGridDateTimeWithBackgroundCellRenderer.vue';
import RampServiceImportOptions from './model/rampServiceImportOptions';
import AgGridValueComparable from '../../ag-grid/mixins/AgGridValueComparable.vue';
import AgGridTimeCellRenderer from '../../ag-grid/cell-renderers/AgGridTimeCellRenderer.vue';
import AgGridIconCellRenderer from '../../ag-grid/cell-renderers/AgGridIconCellRenderer.vue';
import { cellClassAgGridRules } from '../../ag-grid/mixins/cellClassAgGridRules';
import agGridTextCustomFilterComparator from '../../ag-grid/filters/agGridTextCustomFilterComparator';
import agGridTextCustomFilterFormatter from '../../ag-grid/filters/agGridTextCustomFilterFormatter';
import agGridDateTimeFormatter from '../../ag-grid/formatters/agGridDateTimeFormatter';

export default {
  name: 'ramp-service-import-step-changes-summary',
  mixins: [TimezoneConfigurable, AgGridValueComparable],
  components: {
    AgGridVue,
    AgGridDateTimeWithBackgroundCellRenderer,
    AgGridTimeCellRenderer,
    AgGridIconCellRenderer,
  },
  props: {
    importOptions: {
      type: RampServiceImportOptions,
    },
    summaryRampServices: {
      type: Object,
      required: true,
    },
  },
  data() {
    const that = this;
    return {
      defaultColDef: {
        cellClassRules: cellClassAgGridRules,
        resizable: true,
        sortable: true,
        filterParams: {
          textCustomComparator: agGridTextCustomFilterComparator,
          textFormatter: agGridTextCustomFilterFormatter,
        },
      },
      columnDefs: [
        {
          headerTooltip: (() => this.$i18n.t('common.flightDate'))(),
          headerValueGetter() {
            return that.$i18n.t('common.flightDate');
          },
          width: 55,
          filter: true,
          comparator: this.objectWithValueComparator,
          valueGetter(params) {
            return params.data.flightInformationRampServiceImportEmbeddable.flightDate;
          },
        },
        {
          headerTooltip: (() => this.$i18n.t('operations.flightNumber'))(),
          headerValueGetter() {
            return that.$i18n.t('operations.flightNumber');
          },
          width: 50,
          comparator: this.objectWithValueComparator,
          valueGetter(params) {
            if (params.data.flightInformationRampServiceImportEmbeddable.tripNumber != null) {
              return params.data.flightInformationRampServiceImportEmbeddable.airlineCode + params.data.flightInformationRampServiceImportEmbeddable.tripNumber;
            }
            return params.data.flightInformationRampServiceImportEmbeddable.airlineCode;
          },
        },
        {
          headerTooltip: (() => this.$i18n.t('operations.messagesModalTableBound'))(),
          headerValueGetter() {
            return that.$i18n.t('operations.messagesModalTableBound');
          },
          width: 37,
          comparator: this.objectWithValueComparator,
          valueGetter(params) {
            return params.data.flightInformationRampServiceImportEmbeddable.bound;
          },
          cellRendererFramework: AgGridIconCellRenderer,
          cellRendererParams: {
            // eslint-disable-next-line no-nested-ternary
            icon: params => (params.value ? params.value === 'INBOUND' ? 'flight_land' : params.value === 'OUTBOUND' ? 'flight_takeoff' : null : null),
            // eslint-disable-next-line no-nested-ternary
            tooltip: params => (params.value ? params.value === 'INBOUND' ? this.$i18n.t('common.arrivalFlight') : params.value === 'OUTBOUND' ? this.$i18n.t('common.departureFlight') : null : null),
          },
        },
        {
          headerTooltip: (() => this.$i18n.t('common.aircraftRegistration'))(),
          headerValueGetter() {
            return that.$i18n.t('common.aircraftRegistration');
          },
          width: 55,
          comparator: this.objectWithValueComparator,
          valueGetter(params) {
            return params.data.flightInformationRampServiceImportEmbeddable.aircraftRegisterNumber;
          },
        },
        {
          headerTooltip: (() => this.$i18n.t('rampServices.rampServices'))(),
          headerValueGetter() {
            return that.$i18n.t('rampServices.rampServices');
          },
          width: 100,
          valueGetter(params) {
            return params.data.rampServiceImportEmbeddable.rampServiceName;
          },
        },
        {
          headerTooltip: (() => this.$i18n.t('rampServices.identifier'))(),
          headerValueGetter() {
            return that.$i18n.t('rampServices.identifier');
          },
          width: 30,
          valueGetter(params) {
            return params.data.rampServiceImportEmbeddable.rampServiceIdentifier;
          },
        },
        {
          headerTooltip: (() => this.$i18n.t('rampServices.units'))(),
          headerValueGetter() {
            return that.$i18n.t('rampServices.units');
          },
          width: 30,
          valueGetter(params) {
            return params.data.rampServiceImportEmbeddable.units;
          },
        },
        {
          headerTooltip: (() => this.$i18n.t('rampServices.requestTime'))(),
          headerValueGetter() {
            return that.$i18n.t('rampServices.requestTime');
          },
          width: 90,
          valueGetter(params) {
            return params.data.rampServiceImportEmbeddable.requestTs;
          },
          cellClass: 'date-time-cell',
          valueFormatter: agGridDateTimeFormatter,
        },
        {
          headerTooltip: (() => this.$i18n.t('rampServices.requestedStartTime'))(),
          headerValueGetter() {
            return that.$i18n.t('rampServices.requestedStartTime');
          },
          width: 90,
          valueGetter(params) {
            return params.data.rampServiceImportEmbeddable.requestedTs;
          },
          cellClass: 'date-time-cell',
          valueFormatter: agGridDateTimeFormatter,
        },
        {
          headerTooltip: (() => this.$i18n.t('rampServices.requestedEndTime'))(),
          headerValueGetter() {
            return that.$i18n.t('rampServices.requestedEndTime');
          },
          width: 90,
          valueGetter(params) {
            return params.data.rampServiceImportEmbeddable.requestedEndTs;
          },
          cellClass: 'date-time-cell',
          valueFormatter: agGridDateTimeFormatter,
        },
        {
          headerTooltip: (() => this.$i18n.t('rampServices.startTime'))(),
          headerValueGetter() {
            return that.$i18n.t('rampServices.startTime');
          },
          width: 90,
          valueGetter(params) {
            return params.data.rampServiceImportEmbeddable.startTs;
          },
          cellClass: 'date-time-cell',
          valueFormatter: agGridDateTimeFormatter,
        },
        {
          headerTooltip: (() => this.$i18n.t('rampServices.endTime'))(),
          headerValueGetter() {
            return that.$i18n.t('rampServices.endTime');
          },
          width: 90,
          valueGetter(params) {
            return params.data.rampServiceImportEmbeddable.finishTs;
          },
          cellClass: 'date-time-cell',
          valueFormatter: agGridDateTimeFormatter,
        },
        {
          headerTooltip: (() => this.$i18n.t('common.requester'))(),
          headerValueGetter() {
            return that.$i18n.t('common.requester');
          },
          width: 60,
          valueGetter(params) {
            return params.data.rampServiceImportEmbeddable.requesterName;
          },
        },
        {
          headerTooltip: (() => this.$i18n.t('operations.remarksExtended'))(),
          headerValueGetter() {
            return that.$i18n.t('operations.remarksExtended');
          },
          width: 90,
          valueGetter(params) {
            return params.data.rampServiceImportEmbeddable.remarks;
          },
        },
      ],
    };
  },
  computed: {
    selectedTimezone() {
      return this.importOptions && this.importOptions.timeZoneMode ? this.importOptions.timeZoneMode : this.globalTimezone;
    },
    items() {
      if (this.summaryRampServices.flightRampServicesImport && this.summaryRampServices.flightRampServicesImport.length > 0 && this.summaryRampServices.aircraftGroundServicesImport && this.summaryRampServices.aircraftGroundServicesImport.length > 0 && this.summaryRampServices.aircraftGroundServicesImportThatCreateAircraftGround && this.summaryRampServices.aircraftGroundServicesImportThatCreateAircraftGround.length > 0) {
        return this.summaryRampServices.flightRampServicesImport.concat(this.summaryRampServices.aircraftGroundServicesImport).concat(this.summaryRampServices.aircraftGroundServicesImportThatCreateAircraftGround);
      } if (this.summaryRampServices.flightRampServicesImport && this.summaryRampServices.flightRampServicesImport.length > 0 && this.summaryRampServices.aircraftGroundServicesImport && this.summaryRampServices.aircraftGroundServicesImport.length > 0) {
        return this.summaryRampServices.flightRampServicesImport.concat(this.summaryRampServices.aircraftGroundServicesImport);
      } if (this.summaryRampServices.flightRampServicesImport && this.summaryRampServices.flightRampServicesImport.length > 0 && this.summaryRampServices.aircraftGroundServicesImportThatCreateAircraftGround && this.summaryRampServices.aircraftGroundServicesImportThatCreateAircraftGround.length > 0) {
        return this.summaryRampServices.flightRampServicesImport.concat(this.summaryRampServices.aircraftGroundServicesImportThatCreateAircraftGround);
      } if (this.summaryRampServices.aircraftGroundServicesImport && this.summaryRampServices.aircraftGroundServicesImport.length > 0 && this.summaryRampServices.aircraftGroundServicesImportThatCreateAircraftGround && this.summaryRampServices.aircraftGroundServicesImportThatCreateAircraftGround.length > 0) {
        return this.summaryRampServices.aircraftGroundServicesImport.concat(this.summaryRampServices.aircraftGroundServicesImportThatCreateAircraftGround);
      } if (this.summaryRampServices.flightRampServicesImport && this.summaryRampServices.flightRampServicesImport.length > 0) {
        return this.summaryRampServices.flightRampServicesImport;
      } if (this.summaryRampServices.aircraftGroundServicesImport && this.summaryRampServices.aircraftGroundServicesImport.length > 0) {
        return this.summaryRampServices.aircraftGroundServicesImport;
      } if (this.summaryRampServices.aircraftGroundServicesImportThatCreateAircraftGround && this.summaryRampServices.aircraftGroundServicesImportThatCreateAircraftGround.length > 0) {
        return this.summaryRampServices.aircraftGroundServicesImportThatCreateAircraftGround;
      }
      return [];
    },
  },
  methods: {
    onGridReady(params) {
      this.$emit('on-grid-ready', params);
    },
    getMoment(string) {
      return this.$moment.tz(string, this.selectedTimezone);
    },
  },
};
</script>
<style lang="scss">
.containerTitleSummary {
  padding: 0 0 1em 0;
}
  .containerInfoSummary {
    margin: 0 2em;
    padding: 1em;
    font-weight: bold;
    text-align:center;
  }
.containerInfoSummary>span {
  display:inline-block;
  vertical-align:middle;
}
</style>
