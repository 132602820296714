/* eslint no-param-reassign: "off" */
import moment from 'moment';
import { HTTP } from '../../../http';
import { REQUEST_ERROR, REQUEST_START, REQUEST_SUCCESS } from '../../store';
import FlightMessagesFilter from '../../../components/messages/model/flightMessagesFilter';
import FlightMessage from '../../../components/messages/model/flightMessage';

// action
export const MESSAGES_SET_GRID_API = 'MESSAGES_SET_GRID_API';

export const MESSAGES_FIND_FLIGHTS = 'MESSAGES_FIND_FLIGHTS';

export const FIT_COLUMNS_TO_VIEW = 'FIT_COLUMNS_TO_VIEW';
export const FIT_COLUMNS_TO_BEST = 'FIT_COLUMNS_TO_BEST';

// mutations
export const MESSAGES_FIND_FLIGHTS_SUCCESS = 'MESSAGES_FIND_FLIGHTS_SUCCESS';
export const MESSAGES_CHANGE_FLIGHT_SELECTION = 'MESSAGES_CHANGE_FLIGHT_SELECTION';

export const MESSAGES_FILTER_RESET = 'MESSAGES_FILTER_RESET ';
export const MESSAGES_FILTER_RESET_TIMEZONE = 'MESSAGES_FILTER_RESET_TIMEZONE ';
export const MESSAGES_FILTER_INCREASE_DAY = 'MESSAGES_FILTER_INCREASE_DAY ';
export const MESSAGES_FILTER_DECREASE_DAY = 'MESSAGES_FILTER_DECREASE_DAY ';

// initial state
const storeState = {
  gridApi: null,
  filter: FlightMessagesFilter.createEmptyFilter,
  messages: [],
  selectedMessages: [],
};

// getters
const storeGetters = {
  hasSelectedMessages: state => state.selectedMessages.length > 0,
};

// actions
const storeActions = {
  [MESSAGES_FIND_FLIGHTS]: ({ commit, state }) => {
    console.log(state.filter); // eslint-disable-line no-console
    commit(REQUEST_START);
    commit(MESSAGES_CHANGE_FLIGHT_SELECTION, []);
    return HTTP.post('messages/find', state.filter)
      .then((resp) => {
        commit(REQUEST_SUCCESS);
        commit(
          MESSAGES_FIND_FLIGHTS_SUCCESS,
          FlightMessage.createFlightMessagesFromData(resp.data),
        );
        commit(MESSAGES_CHANGE_FLIGHT_SELECTION, []);
      })
      .catch((err) => {
        commit(MESSAGES_FIND_FLIGHTS_SUCCESS, []);
        commit(REQUEST_ERROR, err);
      });
  },
  [FIT_COLUMNS_TO_VIEW]: ({ state }) => {
    if (state.gridApi) {
      state.gridApi.sizeColumnsToFit();
    }
  },
  [FIT_COLUMNS_TO_BEST]: ({ state }) => {
    if (state.gridApi && state.gridApi.columnController && state.gridApi.columnController.columnApi) {
      state.gridApi.columnController.columnApi.autoSizeAllColumns();
    }
  },
};

// mutations
const storeMutations = {
  [MESSAGES_SET_GRID_API]: (state, gridApi) => {
    state.gridApi = gridApi.api;
  },
  [MESSAGES_FIND_FLIGHTS_SUCCESS]: (state, messages) => {
    state.messages = messages;
  },
  [MESSAGES_CHANGE_FLIGHT_SELECTION]: (state, selection) => {
    state.selectedFlights = selection;
  },
  [MESSAGES_FILTER_RESET]: (state) => {
    state.filter.dateFrom = moment().startOf('day');
    state.filter.dateTo = moment().endOf('day');

    state.filter.timezoneDate = moment.defaultZone.name;
  },
  [MESSAGES_FILTER_RESET_TIMEZONE]: (state) => {
    state.filter.dateFrom = moment().startOf('day')
      .year(state.filter.dateFrom.year())
      .month(state.filter.dateFrom.month())
      .date(state.filter.dateFrom.date());
    state.filter.dateTo = moment().endOf('day')
      .year(state.filter.dateTo.year())
      .month(state.filter.dateTo.month())
      .date(state.filter.dateTo.date());

    state.filter.timezoneDate = moment.defaultZone.name;
  },
  [MESSAGES_FILTER_INCREASE_DAY]: (state) => {
    state.filter.dateFrom = moment().startOf('day')
      .year(state.filter.dateFrom.year())
      .month(state.filter.dateFrom.month())
      .date(state.filter.dateFrom.date())
      .add(1, 'days')
      .startOf('day');

    state.filter.dateTo = moment().endOf('day')
      .year(state.filter.dateTo.year())
      .month(state.filter.dateTo.month())
      .date(state.filter.dateTo.date())
      .add(1, 'days');
  },
  [MESSAGES_FILTER_DECREASE_DAY]: (state) => {
    state.filter.dateFrom = moment().startOf('day')
      .year(state.filter.dateFrom.year())
      .month(state.filter.dateFrom.month())
      .date(state.filter.dateFrom.date())
      .subtract(1, 'days');

    state.filter.dateTo = moment().endOf('day')
      .year(state.filter.dateTo.year())
      .month(state.filter.dateTo.month())
      .date(state.filter.dateTo.date())
      .subtract(1, 'days')
      .endOf('day');
  },
};

export default {
  state: storeState,
  getters: storeGetters,
  actions: storeActions,
  mutations: storeMutations,
};
