<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-autocomplete :value="value" :items="items" :label="label" :placeholder="placeholder" prepend-icon="local_airport"
    :filter="customFilter" :disabled="disabled" :multiple="multiple" :clearable="true" item-text="name" item-value="id"
    hide-details @input="emitInput" :rules="rules">
    <template v-slot:selection="data" v-if="chipView">
      <v-chip :selected="data.selected" close class="chip--select-multi" @input="remove(data.item)">
        {{ data.item.name }}
      </v-chip>
    </template>
    <template v-slot:item="data">
      <v-list-tile-content>
        <v-list-tile-title>{{ data.item.description }}</v-list-tile-title>
        <v-list-tile-sub-title>{{ `${data.item.name} · ${data.item.country}` }}</v-list-tile-sub-title>
      </v-list-tile-content>
    </template>
  </v-autocomplete>
</template>

<script>
import { TENANT_LIST } from '../../store/modules/tenant';

export default {
  name: 'TentantSelector',
  props: {
    value: {},
    label: {
      type: String,
      default() {
        this.$t('common.airport');
      },
    },
    disabled: { type: Boolean },
    rules: { type: Array },
    multiple: {
      type: Boolean,
      default: false,
    },
    chipView: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default() {
        this.$i18n.t('commonForm.selectAirport');
      },
    },
  },
  data: () => ({
    that: this,
    select: [],
  }),
  computed: {
    items() {
      return this.$store.state.tenant.tenantsList;
    },
  },
  methods: {
    getTenants() {
      return this.$store.dispatch(TENANT_LIST);
    },
    customFilter(item, queryText) {
      const description = item.description ? item.description.toLowerCase() : '';
      const name = item.name ? item.name.toLowerCase() : '';
      const country = item.country ? item.country.toLowerCase() : '';
      const searchText = queryText.toLowerCase();

      return description.indexOf(searchText) > -1 || name.indexOf(searchText) > -1 || country.indexOf(searchText) > -1;
    },
    emitInput(event) {
      if (typeof (event) !== 'undefined') {
        this.$emit('input-tenant', this.items.find(tenant => tenant.id === event));
        this.$emit('input', event);
      } else {
        this.$emit('input-tenant', null);
        this.$emit('input', null);
      }
    },
    remove(item) {
      const index = this.value.indexOf(item.id);
      if (index >= 0) this.value.splice(index, 1);
      this.$emit('input', this.value);
    },
  },
  created() {
    this.getTenants();
  },
};
</script>
