<template>
  <v-card-text>
    <v-container fluid style="margin-bottom: 0; padding-bottom: 0">
      <v-layout style="margin-bottom: 0; padding-bottom: 0">
        <v-flex style="margin-right: 2ch; width: 8vw">
          <v-text-field :label="this.$i18n.t('configuration.code')" v-model="item.code" :readonly="!hasPermissions(rolePermission)"/>
        </v-flex>

        <v-flex style="margin-right: 2ch; width: 60vw">
          <v-text-field :label="this.$i18n.t('configuration.description')" v-model="item.description" :readonly="!hasPermissions(rolePermission)"/>
        </v-flex>

      </v-layout>
      <v-layout style="margin-bottom: 0; padding-bottom: 0">

        <v-flex style="margin-right: 2ch; width: 8vw">
          <airline-select v-char-length.clearable="2" :label="this.$i18n.t('common.airline')" v-model="item.airlineId" :disabled="!hasPermissions(rolePermission)"></airline-select>
        </v-flex>

        <v-flex class="delay-edit-card-tenant-selector">
          <tenant-selector v-char-lenght.clearable="2" :label="this.$i18n.t('common.airport')" v-model="item.tenantId" :disabled="!hasPermissions(rolePermission)" :placeholder="'...'"></tenant-selector>
        </v-flex>

        <v-flex  style="margin-right: 2ch; width: 25vw">
          <responsible-type-select v-char-length.clearable="2" :label="this.$i18n.t('configuration.responsibleType')" v-model="item.responsibleType" :disabled="!hasPermissions(rolePermission)"></responsible-type-select>
        </v-flex>

        <v-flex style="margin-right: 2ch; width: 25vw">
          <responsible-department-select v-char-length.clearable="2" :label="this.$i18n.t('configuration.responsibleDepartment')" v-model="item.responsibleDepartment" :disabled="!hasPermissions(rolePermission)"></responsible-department-select>
        </v-flex>

      </v-layout>
    </v-container>
  </v-card-text>
</template>
<script>

import AirlineSelect from '../../airline/AirlineSelect.vue';
import TenantSelector from '../../tenant/TenantSelector.vue';
import ResponsibleTypeSelect from './ResponsibleTypeSelect.vue';
import ResponsibleDepartmentSelect from './ResponsibleDepartmentSelect.vue';
import RolePermissions from '../../mixins/RolePermissions.vue';

export default {
  name: 'DelayTypeEditCardContent',
  mixins: [RolePermissions],
  props: {
    item: { type: Object, required: true },
  },
  components: {
    AirlineSelect,
    TenantSelector,
    ResponsibleTypeSelect,
    ResponsibleDepartmentSelect,
  },
  computed: {
    rolePermission() {
      return `${this.$route.meta.role}_WR`;
    },
  },
};
</script>
<style scoped>
.delay-edit-card-tenant-selector > div{
  max-width: fit-content !important;
  margin-right: 2ch;
  padding: 0;
}
</style>
