<template>
  <div>
    <v-card :dark="this.$vuetify.dark" :color="this.$vuetify.dark ? 'bggrey_dark' : 'bggrey'">
      <v-toolbar dark dense color="primary lighten-1">
        <v-btn v-on:click="displayedFilter" small flat>
          <v-icon>filter_list</v-icon>
          <v-icon v-if="!showFilter">keyboard_arrow_down</v-icon>
          <v-icon v-if="showFilter">keyboard_arrow_up</v-icon>
        </v-btn>

        <v-divider vertical light style="margin-left: 30px; margin-right: 30px"></v-divider>

        <operation-flights-list-filter-date-selector />

        <v-divider vertical light style="margin-left: 30px; margin-right: 30px"></v-divider>

        <operation-flights-list-filter-archived />

        <v-divider vertical light style="margin-left: 30px; margin-right: 30px"></v-divider>

        <operation-flights-list-filter-desk />

        <v-spacer></v-spacer>

        <operation-flights-list-desk-selector />

        <v-divider vertical light style="margin-left: 30px; margin-right: 30px"></v-divider>

        <v-flex grow="false" shrink="true">
          <audit-flights-changes-list />
        </v-flex>

        <OperationFlightsListTableOptionsFilterButton />
      </v-toolbar>
      <v-container fluid v-if="showFilter" class="pa-2 pb-2">
        <v-layout class="filter-inline" align-center justify-start>
          <v-flex class="text-no-wrap" align-center justify-start>
            <v-switch class="ml-2 mt-0 pt-0" slot="activator" color="red"
              :label="this.$i18n.t('commonFilter.showOldValues')" v-model="showOldValues" hide-details
              @change="updateShowOldValuesInStore()">
            </v-switch>
            <v-switch class="ml-2 mt-0 pt-0" slot="activator" color="red"
              :label="this.$i18n.t('commonFilter.flashOnChanges')" v-model="flashOnChanges" hide-details
              @change="updateFlashOnChangesInStore()">
            </v-switch>
          </v-flex>

          <v-flex class="text-no-wrap" align-center justify-start>
            <v-switch class="ml-2 mt-0 pt-0" slot="activator" color="red"
              :label="this.$i18n.t('operations.showFlights')" v-model="filter.showFlights" hide-details
              >
            </v-switch>
            <v-switch class="ml-2 mt-0 pt-0" slot="activator" color="red"
              :label="this.$i18n.t('operations.showAircraftsGround')" v-model="filter.showAircraftGround" hide-details
              >
            </v-switch>
          </v-flex>
          <v-spacer style="width: 45%"></v-spacer>
          <v-flex class="ml-2 text-no-wrap" align-center justify-start>
            <div align-left>
              <v-menu left offset-y>
                <template v-slot:activator="{ on }">
                  <v-btn color="secondary" small flat round v-on="on" block class="mb-4">
                    <v-icon>mdi-download</v-icon>
                    {{ $t("common.export") }}
                  </v-btn>
                </template>
                <v-list>
                  <v-list-tile-content>
                    <v-list-tile-sub-title>
                      <v-btn color="secondary" small flat v-on:click.stop="exportExcel">
                        <v-icon>mdi-download</v-icon>
                        {{ $t("common.exportExcel") }}
                      </v-btn>
                    </v-list-tile-sub-title>
                    <v-list-tile-sub-title>
                      <v-btn color="secondary" small flat v-on:click.stop="exportCSV">
                        <v-icon>mdi-download</v-icon>
                        {{ $t("common.export") }}
                      </v-btn>
                    </v-list-tile-sub-title>
                  </v-list-tile-content>
                </v-list>
              </v-menu>
            </div>
            <v-btn color="primary" round v-on:click.stop="find" block>
              <v-icon>search</v-icon>
              {{ $t("common.find") }}
            </v-btn>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card>
  </div>

</template>
<script>
import { saveAs } from 'file-saver';
import AuditFlightsChangesList from '../../audit/list/AuditFlightsChangesList.vue';
import OperationFlightsListFilterDateSelector from './OperationFlightsListFilterDateSelector.vue';
import OperationFlightsListFilterArchived from './OperationFlightsListFilterArchived.vue';
import OperationFlightsListFilterDesk from './OperationFlightsListFilterDesk.vue';
import OperationFlightsListDeskSelector from './OperationFlightsListDeskSelector.vue';
import OperationFlightsListTableOptionsFilterButton from './OperationFlightsListTableOptionsFilterButton.vue';
import AgGridApiCsvExportable from '../../ag-grid/mixins/AgGridApiCsvExportable.vue';
import StoreSubscribable from '../../mixins/StoreSubscribable.vue';
import FileSerializable from '../../mixins/FileSerializable.vue';
import {
  OPERATION_FIND_FLIGHTS,
  OPERATION_SET_GRID_API,
  UPDATE_FLASH_ON_CHANGES,
  UPDATE_SHOW_OLD_VALUES,
} from '../../../store/modules/operation';
import { REQUEST_ERROR, REQUEST_START, REQUEST_SUCCESS } from '../../../store/store';
import { NOTIFICATION_SHOW } from '../../../store/modules/notifications';

export default {
  name: 'operation-flights-list-filter',
  mixins: [AgGridApiCsvExportable, StoreSubscribable, FileSerializable],
  components: {
    OperationFlightsListTableOptionsFilterButton,
    OperationFlightsListDeskSelector,
    OperationFlightsListFilterDesk,
    OperationFlightsListFilterArchived,
    OperationFlightsListFilterDateSelector,
    AuditFlightsChangesList,
  },
  props: [],
  data() {
    return {
      select: 50,
      showFilter: false,
      valid: true,
      queryName: null,
      dialog: false,
      showOldValues: null,
      flashOnChanges: null,
    };
  },
  computed: {
    filter() {
      return this.$store.state.operation.filter;
    },
    date() {
      return this.$moment(this.$store.state.operation.filter.dateFrom);
    },
  },
  methods: {
    find() {
      this.showLoading();
      this.$store.dispatch(OPERATION_FIND_FLIGHTS);
    },
    exportCSV() {
      this.exportAgGridCsv('OperationFlights');
    },
    exportExcel() {
      this.$store.commit(REQUEST_START);
      this.$http.post('operations/flights/export-view-excel', this.filter, {})
        .then((resp) => {
          this.$store.commit(REQUEST_SUCCESS);
          const blob = new Blob([this.s2ab(atob(resp.data.content))], { type: resp.data.contentType });
          saveAs(blob, resp.data.name);
        }).catch((err) => {
          this.$store.commit(REQUEST_ERROR, err);
          this.$store.commit(NOTIFICATION_SHOW, {
            message: err.response.data.title || this.$i18n.t('notifications.errorNotification'),
            color: 'error',
          });
        });
    },
    updateShowOldValuesInStore() {
      this.$store.commit(UPDATE_SHOW_OLD_VALUES, this.showOldValues);
    },
    updateFlashOnChangesInStore() {
      this.$store.commit(UPDATE_FLASH_ON_CHANGES, this.flashOnChanges);
    },
    displayedFilter() {
      this.showFilter = !this.showFilter;
      this.$emit('changeShowFilter', this.showFilter);
    },
  },
  created() {
    this.showOldValues = this.$store.state.operation.showOldValues;
    this.flashOnChanges = this.$store.state.operation.flashOnChanges;
  },
  mounted() {
    const that = this;
    this.subscribeMutation(OPERATION_SET_GRID_API, (mutationPayload) => {
      that.setAgGridApi(mutationPayload);
    });
  },
};
</script>
