<template>
  <div>
    <entity-configuration-list
      :entity-name="this.$i18n.t('configuration.airportRequester')"
      entity-icon="video_label"
      :framework-components="frameworkComponents" :column-defs="columnDefs" :items="airportRequesters"
      @confirm-edit-item="editConfirm"
      @confirm-delete-item="deleteConfirm"
      @confirm-enable-disable-item="enableDisableConfirm"
    >
      <template v-slot:modal-content="{ itemEdit }">
        <airport-requester-edit-card-content :item="itemEdit" />
      </template>
    </entity-configuration-list>
  </div>
</template>
<script>
import {
  AIRPORT_REQUESTER_CREATE,
  AIRPORT_REQUESTER_DELETE,
  AIRPORT_REQUESTER_UPDATE,
  GET_AIRPORT_REQUESTER,
  AIRPORT_REQUESTER_ENABLE,
  AIRPORT_REQUESTER_DISABLE,
} from '@/store/modules/airportRequester/airportRequesterCatalog';
import EntityConfigurationList from '@/components/configuration/common/EntityConfigurationList.vue';
import AirportRequesterEditCardContent from './AirportRequesterEditCardContent.vue';
import AgGridRowActionButtonsCellRenderer from '../../ag-grid/cell-renderers/AgGridRowActionButtonsCellRenderer.vue';
import AgGridIconCellRenderer from '../../ag-grid/cell-renderers/AgGridIconCellRenderer.vue';

export default {
  name: 'AirportRequesterList',
  components: {
    EntityConfigurationList,
    AirportRequesterEditCardContent,
  },
  data() {
    const that = this;
    return {
      frameworkComponents: {
        AgGridRowActionButtonsCellRenderer,
        AgGridIconCellRenderer,
      },
      columnDefs: [
        {
          field: 'name',
          headerValueGetter() {
            return that.$i18n.t('configuration.name');
          },
          sort: 'asc',
        },
        {
          field: 'comments',
          headerValueGetter() {
            return that.$i18n.t('common.comments');
          },
        },
        {
          headerValueGetter() {
            return that.$i18n.t('configuration.disabled');
          },
          field: 'deleted',
          cellRendererFramework: AgGridIconCellRenderer,
          cellRendererParams: {
            icon: 'mdi-cancel',
            tooltip: params => (params.data.deleted ? this.$i18n.t('configuration.disabled') : null),
          },
        },
      ],
    };
  },
  computed: {
    airportRequesters() {
      return this.$store.getters.getAirportRequesters;
    },
  },
  methods: {
    getAirportRequesters() {
      this.$store.dispatch(GET_AIRPORT_REQUESTER);
    },
    deleteConfirm(item) {
      this.$store.dispatch(AIRPORT_REQUESTER_DELETE, item.id);
    },
    editConfirm(item) {
      this.$store.dispatch(item.id ? AIRPORT_REQUESTER_UPDATE : AIRPORT_REQUESTER_CREATE, item);
    },
    enableDisableConfirm(item) {
      this.$store.dispatch(item.deleted ? AIRPORT_REQUESTER_ENABLE : AIRPORT_REQUESTER_DISABLE, item.id);
    },
  },
  created() {
    this.getAirportRequesters();
  },
};
</script>
