<template>
  <div class="contingency-report-container">
    <div class="header">
      <v-toolbar dark color="primary lighten-1">
        <v-toolbar-title>
          <v-icon>flight</v-icon>
          {{ contingency.flightNo() }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-title>
          {{ $t("contingencies.contingencyInform") }} - ID. {{ contingency.id }} {{ contingency.record ? `- Exp.
          ${contingency.record}` : '' }} ({{ this.$store.state.tenant.currentTenant.name }})
          <v-chip light>{{ contingency.status }}</v-chip>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-title>{{ contingency.closedDate != null ? this.$moment(contingency.closedDate).format('DD/MM/YYYY') :
          null }}</v-toolbar-title>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn flat icon v-on="on" v-on:click.stop="print"><v-icon>print</v-icon></v-btn>
          </template>
          <span>{{ $t("contingencies.printContingency") }}</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn v-if="hasPermissions(rolePermission)" flat icon v-on="on" v-on:click.stop="saveAll"
              :disabled="loading"><v-icon>save</v-icon></v-btn>
          </template>
          <span>{{ $t("contingencies.saveAllChanges") }}</span>
        </v-tooltip>
      </v-toolbar>
      <div class="backButton"><v-btn color="primary" flat
          @click.native="close"><v-icon>keyboard_backspace</v-icon></v-btn></div>
    </div>
    <div class="content">

      <contingency-report-form :edited-contingency="contingency" @contingency-update="updateContingency" />
      <contingency-information-form :edited-contingency-information="contingencyInformation"
        @contingency-information-update="updateContingencyInformation" />
      <contingency-detail-form :edited-contingency-detail="contingencyDetail"
        @contingency-detail-update="updateContingencyDetail" />
      <contingency-basic-service-form :edited-contingency-basic-service="contingencyBasicService"
        @contingency-basic-service-update="updateContingencyBasicService" />
      <contingency-passage-state-form :edited-contingency-passage-state="contingencyPassageState"
        @contingency-passage-state-update="updateContingencyPassageState" />
      <contingency-htl-transport-form :edited-contingency-htl-transport="contingencyHtlTransport"
        @contingency-htl-transport-update="updateContingencyHtlTransport" />
    </div>
  </div>
</template>

<script>
import ContingencyReportForm from './ContingencyReportForm.vue';
import ContingencyDetailForm from './ContingencyDetailForm.vue';
import ContingencyPassageStateForm from './ContingencyPassageStateForm.vue';
import ContingencyInformationForm from './ContingencyInformationForm.vue';
import ContingencyBasicServiceForm from './ContingencyBasicServiceForm.vue';
import ContingencyHtlTransportForm from './ContingencyHtlTransportForm.vue';
import { FLIGHT_CONTINGENCY_BASIC_SERVICE_FIND } from '../../../store/modules/contingency/contingencyBasicService';
import { FLIGHT_CONTINGENCY_DETAIL_FIND } from '../../../store/modules/contingency/contingencyDetail';
import { FLIGHT_CONTINGENCY_HTL_TRANSPORT_FIND } from '../../../store/modules/contingency/contingencyHtlTransport';
import { FLIGHT_CONTINGENCY_INFORMATION_FIND } from '../../../store/modules/contingency/contingencyInformation';
import { FLIGHT_CONTINGENCY_PASSAGE_STATE_FIND } from '../../../store/modules/contingency/contingencyPassageState';
import {
  CONTINGENCY_COMPOSITION_UPDATE,
  FLIGHT_CONTINGENCY_FIND,
  PRINT_FLIGHT_CONTINGENCY,
} from '../../../store/modules/contingency/contingency';
import RolePermissions from '../../mixins/RolePermissions.vue';
import Resizable from '../../mixins/Resizable.vue';
import ContingencyDetail from '../model/contingencyDetail';
import ContingencyPassageState from '../model/contingencyPassageState';
import ContingencyComposition from '../model/contingencyComposition';

export default {
  name: 'contingency-report-container',
  mixins: [Resizable, RolePermissions],
  data: () => ({
    saving: false,
    formContingencySaved: false,
    formInformationSaved: false,
    formDetailSaved: false,
    formBasicServiceSaved: false,
    formPassageStateSaved: false,
    formHtlTransportSaved: false,
    contingencyComposition: ContingencyComposition.createEmptyContingencyComposition(),
  }),
  components: {
    ContingencyReportForm,
    ContingencyDetailForm,
    ContingencyPassageStateForm,
    ContingencyInformationForm,
    ContingencyBasicServiceForm,
    ContingencyHtlTransportForm,
  },
  computed: {
    contingency() {
      return this.$store.state.contingency.contingencyEdited;
    },
    contingencyInformation() {
      return this.$store.state.contingencyInformation.contingencyInformation;
    },
    contingencyDetail() {
      return this.$store.state.contingencyDetail.contingencyDetail.length > 0 ? this.$store.state.contingencyDetail.contingencyDetail[0] : ContingencyDetail.createContingencyDetail(this.contingency.id);
    },
    contingencyBasicService() {
      return this.$store.state.contingencyBasicService.contingencyBasicService;
    },
    contingencyPassageState() {
      return this.$store.state.contingencyPassageState.contingencyPassageState.length > 0 ? this.$store.state.contingencyPassageState.contingencyPassageState[0] : ContingencyPassageState.createContingencyPassageState(this.contingency.id);
    },
    contingencyHtlTransport() {
      return this.$store.state.contingencyHtlTransport.contingencyHtlTransport;
    },
    allTablesSaved() {
      return this.formContingencySaved && this.formInformationSaved && this.formDetailSaved && this.formBasicServiceSaved && this.formPassageStateSaved && this.formHtlTransportSaved;
    },
    rolePermission() {
      return `${this.$route.meta.role}_WR`;
    },
    loading() {
      return this.$store.state.loading;
    },
  },
  watch: {
    '$route.params.contingencyId': {
      handler() {
        this.getFlightContingency();
      },
      deep: true,
      immediate: true,
    },
    allTablesSaved: {
      handler(value) {
        if (value) {
          this.saveContingency();
        }
      },
      deep: true,
    },
  },
  methods: {
    print() {
      this.contingency.language = this.$store.getters.currentLanguage;
      this.$store.dispatch(PRINT_FLIGHT_CONTINGENCY, this.contingency);
    },
    saveAll() {
      this.$root.$emit('saveAll');
    },
    saveContingency() {
      this.$store.dispatch(CONTINGENCY_COMPOSITION_UPDATE, this.contingencyComposition);
      this.resetFormsSaved();
    },
    resetFormsSaved() {
      this.formContingencySaved = false;
      this.formInformationSaved = false;
      this.formDetailSaved = false;
      this.formBasicServiceSaved = false;
      this.formPassageStateSaved = false;
      this.formHtlTransportSaved = false;
    },
    close() {
      this.$router.push({ name: 'contingencyManagement' });
    },
    getFlightContingency() {
      this.$store.dispatch(FLIGHT_CONTINGENCY_FIND, this.$route.params.contingencyId);
      this.$store.dispatch(FLIGHT_CONTINGENCY_BASIC_SERVICE_FIND, this.$route.params.contingencyId);
      this.$store.dispatch(FLIGHT_CONTINGENCY_DETAIL_FIND, this.$route.params.contingencyId);
      this.$store.dispatch(FLIGHT_CONTINGENCY_HTL_TRANSPORT_FIND, this.$route.params.contingencyId);
      this.$store.dispatch(FLIGHT_CONTINGENCY_INFORMATION_FIND, this.$route.params.contingencyId);
      this.$store.dispatch(FLIGHT_CONTINGENCY_PASSAGE_STATE_FIND, this.$route.params.contingencyId);
    },
    updateContingency(contingency) {
      this.contingencyComposition.contingency = contingency;
      this.formContingencySaved = true;
    },
    updateContingencyInformation(contingencyInformation) {
      this.contingencyComposition.information = contingencyInformation;
      this.formInformationSaved = true;
    },
    updateContingencyDetail(contingencyDetail) {
      this.contingencyComposition.detail = contingencyDetail;
      this.formDetailSaved = true;
    },
    updateContingencyBasicService(contingencyBasicService) {
      this.contingencyComposition.basicServ = contingencyBasicService;
      this.formBasicServiceSaved = true;
    },
    updateContingencyPassageState(contingencyPassageState) {
      this.contingencyComposition.passageState = contingencyPassageState;
      this.formPassageStateSaved = true;
    },
    updateContingencyHtlTransport(contingencyHtlTransport) {
      this.contingencyComposition.htlTransp = contingencyHtlTransport;
      this.formHtlTransportSaved = true;
    },
  },
  mounted() {
    this.getFlightContingency();
  },
};
</script>

<style lang="scss">

.contingency-report-container .header {
  position: fixed;
  width: 100%;
  z-index: 1;
}

.contingency-report-container .content {
  overflow: auto;
  padding-top: 7vh;
  min-height: max-content;
}

.contingency-report-container .backButton {
  height: 0 !important;
}
</style>
