/* eslint no-param-reassign: "off" */

import { HTTP } from '../../../http';
import { REQUEST_ERROR, REQUEST_START, REQUEST_SUCCESS } from '../../store';
import { NOTIFICATION_SHOW } from '../notifications';
import i18n from '../../../plugins/i18n';
import MessageDirectory from '../../../components/configuration/messageDirectory/model/messageDirectory';

// actions names
export const GET_MESSAGE_DIRECTORY = 'GET_MESSAGE_DIRECTORY';
export const MESSAGE_DIRECTORY_DELETE = 'MESSAGE_DIRECTORY_DELETE';
export const MESSAGE_DIRECTORY_CREATE = 'MESSAGE_DIRECTORY_CREATE';
export const MESSAGE_DIRECTORY_UPDATE = 'MESSAGE_DIRECTORY_UPDATE';

// mutations
export const GET_MESSAGE_DIRECTORY_SUCCESS = 'GET_MESSAGE_DIRECTORY_SUCCESS';

// initial state
const storeState = {
  messagesDirectoryList: [],
};

// getters
const storeGetters = {};

// actions
const storeActions = {
  [GET_MESSAGE_DIRECTORY]: ({ commit }) => {
    commit(REQUEST_START);
    HTTP.get('airport-message-directory')
      .then((resp) => {
        commit(REQUEST_SUCCESS);
        commit(GET_MESSAGE_DIRECTORY_SUCCESS, resp.data);
      })
      .catch((err) => {
        commit(REQUEST_ERROR, err);
      });
  },

  [MESSAGE_DIRECTORY_CREATE]: ({ commit, dispatch }, gate) => {
    commit(REQUEST_START);
    return HTTP.post('airport-message-directory', gate)
      .then(() => {
        commit(REQUEST_SUCCESS);
        dispatch(GET_MESSAGE_DIRECTORY);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.messageDirectoryCreate'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },

  [MESSAGE_DIRECTORY_UPDATE]: ({ commit, dispatch }, gate) => {
    commit(REQUEST_START);
    return HTTP.put('airport-message-directory', gate)
      .then(() => {
        commit(REQUEST_SUCCESS);
        dispatch(GET_MESSAGE_DIRECTORY);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.messageDirectoryUpdate'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
  [MESSAGE_DIRECTORY_DELETE]: ({ commit, dispatch }, id) => {
    commit(REQUEST_START);
    return HTTP.delete(`airport-message-directory/${id}`)
      .then(() => {
        commit(REQUEST_SUCCESS);
        dispatch(GET_MESSAGE_DIRECTORY);
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.messageDirectoryDelete'),
          color: 'success',
        });
      })
      .catch((err) => {
        commit(NOTIFICATION_SHOW, {
          message: i18n.t('notifications.errorNotification'),
          color: 'error',
        });
        commit(REQUEST_ERROR, err);
      });
  },
};

// mutations
const storeMutations = {
  [GET_MESSAGE_DIRECTORY_SUCCESS]: (state, messageDirectoryList) => {
    state.messagesDirectoryList = MessageDirectory.createMessageDirectoryFromData(messageDirectoryList);
  },
};

export default {
  state: storeState,
  getters: storeGetters,
  actions: storeActions,
  mutations: storeMutations,
};
