export default class FlightTimes {
  constructor() {
    this.estimatedTime = null;
    this.landTime = null;
    this.blockTime = null;
    this.estimatedElapsedTime = null;
    this.estimatedRealTime = null;
    this.manualOverwriteEstimatedTime = null;
    this.manualOverwriteBlockTime = null;
    this.manualOverwriteLandTime = null;
  }
}
