<template>
  <div>
    <ag-grid-vue
      class="ag-theme-material connected-flights-table operational-table"
      :columnDefs="columnDefs"
      :defaultColDef="defaultColDef"
      :rowData="items"
      :rowSelection="'multiple'"
      :suppressRowClickSelection="true"
      :rowMultiSelectWithClick="false"
      @grid-ready="onGridReady"
      @first-data-rendered="firstDataRendered"
      @selection-changed="onSelectionChanged"
      :suppressHorizontalScroll="true"
      :frameworkComponents="frameworkComponents"
      :gridOptions="gridOptions"
      :multiSortKey="multiSortKey"
      :overlayNoRowsTemplate="customNoRowsOverlay"
      v-bind:style="`width: 100%; height: calc(100vh - ${this.sizeFilter});`">
    </ag-grid-vue>
  </div>
</template>
<script>
import { AgGridVue } from 'ag-grid-vue';
import AgGridValueBaseCellRenderer from '../../../ag-grid/cell-renderers/AgGridValueBaseCellRenderer.vue';
import AgGridConnectedFlights from '../../../ag-grid/mixins/AgGridConnectedFlights.vue';
import FlightsDifferences from '../../../mixins/FlightsDifferences.vue';

import { FlightBound } from '../../../shared/model/flightBound';
import { cellClassAgGridRules } from '../../../ag-grid/mixins/cellClassAgGridRules';
import { WEBSOCKET_CONNECTION } from '../../../../store/modules/websocket';
import { FisModule } from '../../../ag-grid/cell-editors/model/fisModule';
import agGridTextCustomFilterComparator from '../../../ag-grid/filters/agGridTextCustomFilterComparator';
import agGridTextCustomFilterFormatter from '../../../ag-grid/filters/agGridTextCustomFilterFormatter';
import {
  CHANGE_RUSHES_BAGGAGE_SELECTION,
  RUSH_BAGGAGE_FIT_COLUMNS_TO_VIEW,
  RUSH_BAGGAGE_SET_GRID_API,
} from '../../../../store/modules/lostAndFound/rushBaggage';
import agGridTimeFormatter from '../../../ag-grid/formatters/agGridTimeFormatter';
import AgGridTimeCellRenderer from '../../../ag-grid/cell-renderers/AgGridTimeCellRenderer.vue';
import AgGridDateTimeCellRenderer from '../../../ag-grid/cell-renderers/AgGridDateTimeCellRenderer.vue';
import AgGridRushBaggageDateCellEditor
from '../../../ag-grid/cell-editors/rushBaggage/AgGridRushBaggageDateCellEditor.vue';
import AgGridRushBaggageTimeCellEditor
from '../../../ag-grid/cell-editors/rushBaggage/AgGridRushBaggageTimeCellEditor.vue';
import AgGridRushBaggageStringCellEditor
from '../../../ag-grid/cell-editors/rushBaggage/AgGridRushBaggageStringCellEditor.vue';
import AgGridRushBaggageAirportSelectCellEditor
from '../../../ag-grid/cell-editors/rushBaggage/AgGridRushBaggageAirportSelectCellEditor.vue';
import AgGridIconCellRenderer from '../../../ag-grid/cell-renderers/AgGridIconCellRenderer.vue';
import AgGridRushBaggageFlightNumberCellEditor
from '../../../ag-grid/cell-editors/rushBaggage/AgGridRushBaggageFlightNumberCellEditor.vue';
import AgGridRowActionDeleteButtonCellRenderer
from '../../../ag-grid/cell-renderers/AgGridRowActionDeleteButtonCellRenderer.vue';
import AgGridCustomService from '../../../shared/services/agGridCustomService';
import RolePermissions from '../../../mixins/RolePermissions.vue';
import AgGridNoRowsOverlay from '../../../ag-grid/mixins/AgGridNoRowsOverlay.vue';

export default {
  name: 'rush-baggage-list-table',
  mixins: [AgGridConnectedFlights, FlightsDifferences, RolePermissions, AgGridNoRowsOverlay],
  components: {
    AgGridVue,
  },
  props: {
    items: { type: Array, required: true },
    isFilterDisplayed: { type: Boolean, required: false, default: false },
  },
  mounted() {
    this.$store.dispatch(WEBSOCKET_CONNECTION, this.$store.state.tenant.currentTenant.name.toLowerCase());
  },
  computed: {
    sizeFilter() {
      return this.isFilterDisplayed ? '250px' : '180px';
    },
    fitColumnsOnResize() {
      return this.$store.state.rushBaggage.fitColumnsOnResize;
    },
  },
  data() {
    const that = this;
    const rolePermission = this.getRoles();
    return {
      dialog: false,
      gridOptions: {
        onFilterChanged: this.uncheckAllOnFilter,
        getRowNodeId: this.getRowNodeId,
        getRowClass: this.getRowClass,
        floatingFilter: true,
        groupHeaderHeight: 35,
        headerHeight: 35,
        floatingFiltersHeight: 20,
        rowHeight: 30,
        context: {
          module: FisModule.LOST_AND_FOUND,
        },
      },
      multiSortKey: 'ctrl',
      frameworkComponents: {
        AgGridRushBaggageDateCellEditor,
        AgGridRushBaggageTimeCellEditor,
        AgGridRushBaggageStringCellEditor,
        AgGridRushBaggageAirportSelectCellEditor,
        AgGridRushBaggageFlightNumberCellEditor,
      },
      defaultColDef: {
        resizable: true,
        sortable: true,
        minWidth: 30,
        suppressMenu: true,
        filter: 'agTextColumnFilter',
        filterParams: {
          textCustomComparator: agGridTextCustomFilterComparator,
          textFormatter: agGridTextCustomFilterFormatter,
        },
        colCustomConfig: {
          flightBound: FlightBound.ANY,
        },
        cellClassRules: cellClassAgGridRules,
        editable: false,
      },
      columnDefs: [
        {
          headerName: '',
          children: [
            {
              headerTooltip: 'Check/uncheck all',
              hide: !this.hasPermissions(rolePermission),
              checkboxSelection: true,
              width: 21,
              minWidth: 20,
              headerCheckboxSelection: true,
              headerCheckboxSelectionFilteredOnly: true,
              suppressMenu: true,
              sortable: false,
              suppressMovable: true,
              suppressSizeToFit: true,
              filter: false,
              resizable: false,
              colCustomConfig: {
                exportCsvDisabled: true,
              },
              editable: this.editable(true, rolePermission),
            },
            {
              headerName: 'SortDate',
              field: 'rushBaggage.timeCreated',
              hide: true,
              sort: 'asc',
            },
          ],
        },
        {
          headerValueGetter() {
            return that.$i18n.t('commonForm.flightInformation');
          },
          children: [
            {
              colId: 'rushBaggage.flightId',
              headerName: '',
              width: 30,
              field: 'flightId',
              cellClass: 'flight-number-cell separator',
              valueGetter(params) {
                return !params.data.flightId;
              },
              cellRendererFramework: AgGridIconCellRenderer,
              cellRendererParams: {
                icon: 'explicit',
                tooltip: params => (params.data.flightId ? 'Internal' : 'External'),
              },
            },
            {
              colId: 'rushBaggage.flightNo',
              headerValueGetter() {
                return that.$i18n.t('common.flight');
              },
              editable: this.editable(true, rolePermission),
              cellClass: 'flight-number-cell separator',
              cellEditor: 'AgGridRushBaggageFlightNumberCellEditor',
              valueGetter(params) {
                return params.data.flightNo();
              },
              cellRendererFramework: AgGridValueBaseCellRenderer,
            },
            {
              colId: 'rushBaggage.flightDate',
              headerValueGetter() {
                return that.$i18n.t('common.date');
              },
              field: 'flightDate',
              editable: this.editable(this.isExternal, rolePermission),
              cellEditor: 'AgGridRushBaggageDateCellEditor',
              cellClass: 'date-cell separator',
              valueFormatter(params) {
                return that.$moment(params.data.flightDate).format('DD/MM/YYYY');
              },
            },
            {
              colId: 'rushBaggage.scheduledTime',
              headerValueGetter() {
                return that.$i18n.t('common.scheduledTimeOfDeparture');
              },
              field: 'scheduledTime',
              editable: this.editable(this.isExternal, rolePermission),
              cellEditor: 'AgGridRushBaggageTimeCellEditor',
              cellClass: 'airline-from-cell',
              colCustomConfig: {
                flightBound: FlightBound.INBOUND,
                fieldsRelated: ['rushBaggage.flightDate'],
                oldValueFormatter: agGridTimeFormatter,
              },
              valueGetter(params) {
                return that.agGridTimeGetter(params.data.scheduledTime, params.data.flightDate);
              },
              valueFormatter: agGridTimeFormatter,
              cellRendererFramework: AgGridTimeCellRenderer,
            },
            {
              colId: 'rushBaggage.airport1Code',
              headerValueGetter() {
                return that.$i18n.t('common.to');
              },
              field: 'airport1Code',
              editable: this.editable(this.isExternal, rolePermission),
              cellClass: 'airport-from-cell',
              valueGetter(params) {
                return params.data.airportIata();
              },
              cellEditor: 'AgGridRushBaggageAirportSelectCellEditor',
            },
          ],
        },
        {
          headerValueGetter() {
            return that.$i18n.t('lostAndFound.rushBaggageInformation');
          },
          children: [
            {
              colId: 'rushBaggage.passengerName',
              field: 'passengerName',
              headerValueGetter() {
                return that.$i18n.t('lostAndFound.paxNameFileReference');
              },
              editable: this.editable(true, rolePermission),
              cellEditor: 'AgGridRushBaggageStringCellEditor',
            },
            {
              colId: 'rushBaggage.rushTag',
              field: 'rushTag',
              headerValueGetter() {
                return that.$i18n.t('lostAndFound.rushTag');
              },
              editable: this.editable(true, rolePermission),
              cellEditor: 'AgGridRushBaggageStringCellEditor',
            },
            {
              colId: 'rushBaggage.originalTag',
              field: 'originalTag',
              headerValueGetter() {
                return that.$i18n.t('lostAndFound.originalTag');
              },
              editable: this.editable(true, rolePermission),
              cellEditor: 'AgGridRushBaggageStringCellEditor',
            },
            {
              colId: 'rushBaggage.agent',
              field: 'agent',
              headerValueGetter() {
                return that.$i18n.t('lostAndFound.agent');
              },
              editable: this.editable(true, rolePermission),
              cellEditor: 'AgGridRushBaggageStringCellEditor',
            },
            {
              colId: 'rushBaggage.rl',
              field: 'rl',
              headerValueGetter() {
                return that.$i18n.t('lostAndFound.rl');
              },
              editable: this.editable(true, rolePermission),
              cellEditor: 'AgGridRushBaggageStringCellEditor',
            },
            {
              colId: 'rushBaggage.timeCreated',
              headerValueGetter() {
                return that.$i18n.t('rushBaggage.timeCreated');
              },
              valueGetter(params) {
                return {
                  value: params.data.timeCreated,
                };
              },
              cellRendererFramework: AgGridDateTimeCellRenderer,
            },
          ],
        },
        {
          headerName: '',
          hide: !this.hasPermissions(rolePermission),
          width: 35,
          cellClass: 'text-center',
          field: 'id',
          suppressMenu: true,
          sortable: false,
          suppressMovable: true,
          suppressSizeToFit: true,
          filter: false,
          resizable: false,
          suppressNavigable: true,
          colCustomConfig: {
            exportCsvDisabled: true,
          },
          cellRendererFramework: AgGridRowActionDeleteButtonCellRenderer,
          cellRendererParams: {
            deleteItem: this.deleteItem,
          },
        },
      ],
    };
  },
  methods: {
    onGridReady(params) {
      this.setAgGridApi(params);
      this.showLoading();
      this.$store.commit(RUSH_BAGGAGE_SET_GRID_API, params); // TODO eliminar api del store

      this.refreshCellsOnMutation();
      this.refreshCellsOnChangeLanguage();
    },
    resized() {
      if (this.gridApi) {
        this.gridApi.sizeColumnsToFit();
      }
    },
    onSelectionChanged(event) {
      const selectedNodes = event.api.getSelectedNodes();
      const selectedData = selectedNodes.map(node => node.data);
      this.$store.commit(CHANGE_RUSHES_BAGGAGE_SELECTION, selectedData);
    },
    isExternal(params) {
      return params.data.flightId == null;
    },
    deleteItem(item) {
      this.$emit('delete-item', item);
    },
    editItem(item) {
      console.log(`edit ${item}`); // eslint-disable-line no-console
    },
    renderNeededDiffs(differences, rowNode) {
      const { gridApi } = this.$store.state.rushBaggage;

      const agGridCustomService = new AgGridCustomService(this.columnDefs); // TODO one time
      const colIds = agGridCustomService.getColIdByMatchAnyfieldsRelated(this.joinAllPropertyPath(differences));

      if (colIds && colIds.length > 0) {
        colIds.forEach((colId) => {
          if (gridApi.getColumnDef(colId)) {
            gridApi.refreshCells({
              rowNodes: [rowNode],
              columns: [colId],
              force: true,
            });

            gridApi.flashCells({
              rowNodes: [rowNode],
              columns: [colId],
              force: true,
            });
          }
        });
      }
    },
    sizeColumnsToFit() {
      if (this.gridApi) {
        this.$store.dispatch(RUSH_BAGGAGE_FIT_COLUMNS_TO_VIEW);
      }
    },
    firstDataRendered() {
      this.$nextTick(() => {
        this.resized();
      });
    },
  },
};

</script>
