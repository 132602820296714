import i18n from '../../../plugins/i18n';

export default function agGridPaymentTypeFilterComparator(filter, value, filterText) {
  const filterTextLowerCase = filterText.toLowerCase();
  const translatedValueLowerCase = i18n.t(`ticketing.${value.toString().toUpperCase()}`).toString().toLowerCase();
  switch (filter) {
    case 'contains':
      return translatedValueLowerCase.indexOf(filterTextLowerCase) >= 0;
    case 'notContains': {
      return translatedValueLowerCase.indexOf(filterTextLowerCase) === -1;
    }
    case 'equals': {
      return translatedValueLowerCase === filterTextLowerCase;
    }
    case 'notEqual': {
      return translatedValueLowerCase !== filterTextLowerCase;
    }
    case 'startsWith': {
      return translatedValueLowerCase.indexOf(filterTextLowerCase) === 0;
    }
    case 'endsWith': {
      const index = translatedValueLowerCase.lastIndexOf(filterTextLowerCase);
      return index >= 0 && index === (translatedValueLowerCase.length - filterTextLowerCase.length);
    }
    default:
      // should never happen
      console.warn(`invalid filter type ${filter}`); // eslint-disable-line no-console
      return false;
  }
}
