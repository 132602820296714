import { JSUtils } from '../../../shared/js/JSUtils';

export default class FlightWaterToiletInformation {
  constructor(
    id,
    waterRequiredQuantity,
    waterRealQuantity,
    waterInitHourService,
    waterOperator,
    waterEquipmentNumber,
    waterRemark,
    toiletInitHour,
    toiletOperator,
    toiletEquipmentNumber,
    toiletRemark,
  ) {
    this.id = id;
    this.waterRequiredQuantity = waterRequiredQuantity;
    this.waterRealQuantity = waterRealQuantity;
    this.waterInitHourService = waterInitHourService;
    this.waterOperator = waterOperator;
    this.waterEquipmentNumber = waterEquipmentNumber;
    this.waterRemark = waterRemark;
    this.toiletInitHour = toiletInitHour;
    this.toiletOperator = toiletOperator;
    this.toiletEquipmentNumber = toiletEquipmentNumber;
    this.toiletRemark = toiletRemark;
  }

  static get createEmptyFlightWaterToiletInformation() {
    return new FlightWaterToiletInformation();
  }

  static createFlightWaterToiletInformationFromData(o) {
    if (!o) {
      return this.createEmptyFlightWaterToiletInformation;
    }

    return JSUtils.cast(o, FlightWaterToiletInformation);
  }
}
