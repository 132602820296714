import FlightState from './FlightState';

export default class FlightTowingAdhocTaskFilter {
  constructor(dateFrom, dateTo, onlyCurrentDesk, flightState, showAircraftGround, timezoneDate) {
    this.dateFrom = dateFrom;
    this.dateTo = dateTo;
    this.onlyCurrentDesk = onlyCurrentDesk === true;
    this.flightState = flightState;
    this.showAircraftGround = showAircraftGround === true;
    this.timezoneDate = timezoneDate;
  }

  static get createEmptyFilter() {
    return new FlightTowingAdhocTaskFilter(null, null, false, new FlightState(true, true, false, false, false), true, null);
  }
}
