export default class ContingencyHtlTransport {
  constructor(contingencyId, id, passengerName, hotelAddress) {
    this.id = id;
    this.passengerName = passengerName;
    this.hotelAddress = hotelAddress;
    this.contingencyId = contingencyId;
  }

  static createEmptyContingencyHtlTransport() {
    return new ContingencyHtlTransport();
  }

  static createContingencyHtlTransport(contingencyId) {
    return new ContingencyHtlTransport(contingencyId);
  }

  setChildPropertyValue(property, value) {
    if (!property.constructor === Array) {
      throw Error('pathArray has to be an Array');
    }

    const schema = this; // a moving reference to internal objects within obj

    schema[property] = value;
  }

  getChildPropertyValue(property) {
    if (!property.constructor === Array) {
      throw Error('pathArray has to be an Array');
    }

    const schema = this; // a moving reference to internal objects within obj

    return schema[property];
  }
}
