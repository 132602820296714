<template>
  <div>
    <ticketing-mobile-list-filter/>
    <ticketing-mobile-list-table @show-item="showItem" @edit-item="editItem" @delete-item="deleteItem" @cancel-item="cancelItem"/>
    <ticketing-flights-list-options @click-new-ticketing="newTicketing" @click-delete-ticketing="deleteTicketing" @click-cancel-ticketing="cancelTicketing"/>
    <ticketing-new-modal v-if="dialogTicketing" :dialog="dialogTicketing" @ticketing-new-reject="newTicketingReject" @ticketing-new-confirm="newTicketingConfirm" />
    <ticketing-delete-modal v-if="dialogDeleteTicketing" :dialog="dialogDeleteTicketing" :items="selectedItemsToDelete" @delete-reject="deleteTicketingReject" @delete-confirm="deleteTicketingConfirm" />
    <ticketing-cancel-modal v-if="dialogCancelTicketing" :dialog="dialogCancelTicketing" :items="selectedItemsToCancel" @cancel-reject="cancelTicketingReject" @cancel-confirm="cancelTicketingConfirm"/>
  </div>
</template>
<script>
import Breadcrumbs from '@/components/Breadcrumbs.vue';

import TicketingMobileListFilter from '../components/ticketing/mobile/TicketingMobileListFilter.vue';
import TicketingMobileListTable from '../components/ticketing/mobile/TicketingMobileListTable.vue';
import TicketingFlightsListOptions from '../components/ticketing/list/TicketingFlightsListOptions.vue';
import TicketingNewModal from '../components/ticketing/edit/TicketingNewModal.vue';
import TicketingDeleteModal from '../components/ticketing/delete/TicketingDeleteModal.vue';
import { TICKETING_PREVIOUS_PAGE_SET_MOBILE } from '../store/modules/ticketing';
import TicketingCancelModal from '../components/ticketing/cancel/TicketingCancelModal.vue';
import {
  TICKETING_MOBILE_FLIGHTS_CANCEL,
  TICKETING_MOBILE_FLIGHTS_DELETE,
  TICKETING_MOBILE_FLIGHTS_FIND,
} from '../store/modules/ticketingMobile';

export default {
  components: {
    TicketingMobileListFilter,
    TicketingMobileListTable,
    TicketingCancelModal,
    TicketingFlightsListOptions,
    TicketingNewModal,
    TicketingDeleteModal,
    Breadcrumbs,
  },
  data() {
    return {
      filterDisplayed: true,
      selectedItemsToDelete: [],
      dialogTicketing: false,
      dialogDeleteTicketing: false,
      dialogAircraftGroundServices: false,
      aircraftGround: null,
      dialogCancelTicketing: false,
      selectedItemsToCancel: [],
    };
  },
  computed: {
    items() {
      // eslint-disable-next-line
      return this.$store.state.ticketing.flightsTicketing;
    },
    selectedRowsTicketing() {
      return this.$store.getters.getSelectedTicketing;
    },
  },
  methods: {
    changeShowFilter(dispayled) {
      this.filterDisplayed = dispayled;
    },
    newTicketing() {
      this.dialogTicketing = true;
    },
    newTicketingReject() {
      this.dialogTicketing = false;
    },
    newTicketingConfirm() {
      this.$store.dispatch(TICKETING_MOBILE_FLIGHTS_FIND);
      this.dialogTicketing = false;
    },
    deleteTicketing() {
      this.selectedItemsToDelete = [];
      this.selectedItemsToDelete = this.selectedRowsTicketing;
      this.dialogDeleteTicketing = this.selectedItemsToDelete.length > 0;
    },
    deleteTicketingReject() {
      this.dialogDeleteTicketing = false;
    },
    deleteTicketingConfirm(ids) {
      this.selectedItemsToDelete = [];
      this.$store.dispatch(TICKETING_MOBILE_FLIGHTS_DELETE, { ids });
      this.dialogDeleteTicketing = false;
    },
    deleteItem(item) {
      this.selectedItemsToDelete = [];
      this.selectedItemsToDelete.push(item);
      this.dialogDeleteTicketing = true;
    },
    showItem(item) {
      this.$router.push({ name: 'ticketingReport', params: { ticketingId: item.id } });
    },
    editItem(item) {
      this.$router.push({ name: 'ticketingReport', params: { ticketingId: item.id } });
    },
    cancelItem(item) {
      this.selectedItemsToCancel = [];
      this.selectedItemsToCancel.push(item);
      this.dialogCancelTicketing = true;
    },
    cancelTicketing() {
      this.selectedItemsToCancel = [];
      this.selectedItemsToCancel = this.selectedRowsTicketing;
      this.dialogCancelTicketing = true;
    },
    cancelTicketingReject() {
      this.dialogCancelTicketing = false;
    },
    cancelTicketingConfirm(items) {
      this.selectedItemsToCancel = [];
      this.$store.dispatch(TICKETING_MOBILE_FLIGHTS_CANCEL, items);
      this.dialogCancelTicketing = false;
    },
  },
  created() {
    this.$store.dispatch(TICKETING_PREVIOUS_PAGE_SET_MOBILE);
  },
};
</script>
