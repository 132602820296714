export default class FlightOperationStatusInformationDTO {
  constructor(flightId, id, doorsCloseEstimated, doorsCloseCompleted, loadingInstructionEstimated, loadingInstructionCompleted, loadSheetEstimated, loadSheetCompleted) {
    this.id = id;
    this.flightId = flightId;
    this.doorsCloseEstimated = doorsCloseEstimated;
    this.doorsCloseCompleted = doorsCloseCompleted;
    this.loadingInstructionEstimated = loadingInstructionEstimated;
    this.loadingInstructionCompleted = loadingInstructionCompleted;
    this.loadSheetEstimated = loadSheetEstimated;
    this.loadSheetCompleted = loadSheetCompleted;
  }

  static createFlightOperationStatusInformationDTO(flightId) {
    return new FlightOperationStatusInformationDTO(flightId);
  }
}
