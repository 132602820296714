<script>
import Vue from 'vue';
import KeyEventsUtils from '../keyEvents';

export default Vue.extend({
  methods: {
    keyEvent($event) {
      if (KeyEventsUtils.isArrowEvent($event)) {
        $event.stopPropagation();
      }
    },
  },
});
</script>
