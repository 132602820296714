<template>
  <v-card-text>
    <v-container class="container-fields">
    <v-layout>
      <v-flex xs4 class="input-airline-fares">
        <v-text-field :label="this.$i18n.t('configuration.name')" v-model="item.name" :readonly="!hasPermissions(rolePermission)" :rules="[rules.required]"></v-text-field>
      </v-flex>

      <v-flex xs2 class="input-airline-fares">
        <airline-select v-char-length.clearable="2" :label="this.$i18n.t('common.airline')" v-model="item.airlineId" :disabled="!hasPermissions(rolePermission)" :rules="[rules.required]"></airline-select>
      </v-flex>

      <v-flex xs6 class="input-airline-fares">
        <tenant-multiple-selector :value="item.tenants" @input="selectTenant" :disabled="!hasPermissions(rolePermission)" :rules="[rules.requiredMulti]"/>
      </v-flex>

    </v-layout>
    <v-layout>

      <v-flex xs2 class="input-airline-fares">
        <date-input v-model="item.dateFrom" :locale="$i18n.locale" :label="this.$i18n.t('airlineFares.dateFrom')" :charLenght="0" :disabled="!hasPermissions(rolePermission)" :rules="[rules.required]"/>
      </v-flex>

      <v-flex xs2 class="input-airline-fares">
        <date-input class="ml-2" v-model="item.dateTo" :locale="$i18n.locale" :label="this.$i18n.t('airlineFares.dateTo')" :charLenght="0" :disabled="!hasPermissions(rolePermission)" :rules="[rules.required]" @input="setDateToEndOfDay"/>
      </v-flex>

      <v-flex xs2 class="input-airline-fares">
        <v-text-field :label="formatUnitsType(item.unitsType) + this.$i18n.t('airlineFares.unitsPerCost')" v-model="item.unitsPerCost" :readonly="!hasPermissions(rolePermission)" type="number"/>
      </v-flex>

      <v-flex xs2 class="input-airline-fares">
        <v-select
          v-model="item.unitsType"
          :items="Object.keys(items)"
          :label="this.$i18n.t('airlineFares.unitsType')"
          :disabled="!hasPermissions(rolePermission)"
        >
          <template
            slot="selection"
            slot-scope="{ item }"
          >
            <span>
              {{formatUnitsType(item)}}
            </span>
          </template>
          <template
            slot="item"
            slot-scope="{ item }"
          >
            <span>
              {{formatUnitsType(item)}}
            </span>
          </template>
        </v-select>
      </v-flex>

      <v-flex xs2 class="input-airline-fares">
        <currency-input :label="$t('configuration.cost')" v-model="item.cost" :currency="item.currency" :readonly="!hasPermissions(rolePermission)" :rules="[rules.required]"/>
      </v-flex>

      <v-flex xs2 class="input-airline-fares">
        <currency-select :label="$t('configuration.currency')" v-model="item.currency" :disabled="!hasPermissions(rolePermission)" :rules="[rules.required]"/>
      </v-flex>
    </v-layout>
    <v-layout>
      <v-flex xs12 class="input-airline-fares">
        <v-text-field :label="this.$i18n.t('configuration.description')" v-model="item.description" :readonly="!hasPermissions(rolePermission)"></v-text-field>
      </v-flex>
    </v-layout>
    <v-layout>
      <v-flex xs12 class="input-airline-fares">
        <tpv-type-selector v-model="item.tpvType" :label="$t('ticketing.tpvType')" :rules="[rules.required]" :readonly="!hasPermissions(rolePermission)"/>
      </v-flex>
    </v-layout>
    </v-container>


      <v-subheader inset>
        <v-flex xs10><span>Fields</span></v-flex>
        <v-flex xs2>
          <v-btn color="secondary" flat @click="addField()">
            <v-icon>add</v-icon>
          </v-btn>
        </v-flex>
      </v-subheader>

      <v-divider/>

    <div class="container-fields">
        <div v-if="item.fields && item.fields.length > 0">
          <v-card v-for="(field, index) in item.fields" :key="index" class="card-field">
            <v-container class="container-fields">
              <v-layout row>
                <v-icon class="delete-field" @click="removeField(index)">close</v-icon>
                <v-flex xs5 class="input-airline-fares">
                  <v-text-field :label="$t('configuration.name')" v-model="field.name" :readonly="!hasPermissions(rolePermission)" :rules="[rules.required]"></v-text-field>
                </v-flex>

                <v-flex xs3 class="input-airline-fares">
                  <type-field-select :label="$t('configuration.type')" v-model="field.type" :readonly="!hasPermissions(rolePermission)" :rules="[rules.required]"/>
                </v-flex>

                <v-flex xs2 class="input-airline-fares">
                  <v-checkbox color="primary" :label="$t('configuration.required')" v-model="field.required" :readonly="!hasPermissions(rolePermission)"></v-checkbox>
                </v-flex>
              </v-layout>


              <v-expansion-panel >
                <v-expansion-panel-content class="panel-related-field">
                  <template v-slot:header>
                    <v-flex xs10><span>{{$t("configuration.relatedFields")}}</span></v-flex>
                    <v-flex xs2>
                      <v-btn color="secondary" flat @click.native.stop="addRelatedField(field)">
                        <v-icon>add</v-icon>
                      </v-btn>
                    </v-flex>
                  </template>
                  <div class="">
                    <div v-for="(relatedField, indexRelatedField) in field.relatedFields" :key="indexRelatedField">
                        <v-container class="container-related-fields">
                          <v-layout row>
                            <v-flex xs5 class="input-airline-fares">
                              <v-text-field :label="$t('configuration.name')" v-model="relatedField.name" :readonly="!hasPermissions(rolePermission)" :rules="[rules.required]"></v-text-field>
                            </v-flex>

                            <v-flex xs3 class="input-airline-fares">
                              <type-field-select :label="$t('configuration.type')" v-model="relatedField.type" :readonly="!hasPermissions(rolePermission)" :rules="[rules.required]"/>
                            </v-flex>

                            <v-flex xs2 class="input-airline-fares">
                              <v-checkbox color="primary" :label="$t('configuration.required')" v-model="relatedField.required" :readonly="!hasPermissions(rolePermission)"></v-checkbox>
                            </v-flex>

                            <v-flex xs1 class="input-airline-fares">
                              <v-btn color="error" flat @click="removeRelatedField(field, indexRelatedField)">
                                <v-icon>delete</v-icon>
                              </v-btn>
                            </v-flex>
                          </v-layout>

                        </v-container>
                    </div>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-container>
          </v-card>
        </div>
        <div v-else> No fields</div>


    </div>
  </v-card-text>
</template>
<script>
/* eslint no-param-reassign: "off" */
import RolePermissions from '../../mixins/RolePermissions.vue';
import AirlineSelect from '../../airline/AirlineSelect.vue';
import AirlineFareField from './model/airlineFareField';
import CurrencySelect from '../../contingencyManagement/common/CurrencySelect.vue';
import CurrencyInput from '../../currency/CurrencyInput.vue';
import TypeFieldSelect from './TypeFieldSelect.vue';
import DateInput from '../../ui/DateInput.vue';
import TenantMultipleSelector from '../../tenant/TenantMultipleSelector.vue';
import { AirlineFareUnitTypes, i18nValue } from '../../shared/model/airlineFareUnitTypes';
import TpvTypeSelector from '../../ticketing/TpvTypeSelector.vue';

export default {
  name: 'AirlineFaresEditCardContent',
  mixins: [RolePermissions],
  props: {
    item: { type: Object, required: true },
  },
  components: {
    AirlineSelect,
    CurrencySelect,
    CurrencyInput,
    TypeFieldSelect,
    DateInput,
    TenantMultipleSelector,
    TpvTypeSelector,
  },
  data() {
    return {
      rules: {
        required: value => !!value || 'Required.',
        requiredMulti: value => !!value.length > 0 || 'Required.',
      },
      items: AirlineFareUnitTypes,
    };
  },
  computed: {
    rolePermission() {
      return `${this.$route.meta.role}_WR`;
    },
    unitTypes() {
      return [...Array(this.items.length).keys()];
    },
  },
  methods: {
    addField() {
      if (!this.item.fields) {
        this.item.fields = [];
      }
      this.item.fields.push(AirlineFareField.createEmptyAirlineFareField);
    },
    removeField(index) {
      this.item.fields.splice(index, 1);
    },
    addRelatedField(field) {
      if (field.relatedFields == null) {
        field.relatedFields = [];
      }
      field.relatedFields.push(AirlineFareField.createEmptyAirlineFareField);
    },
    removeRelatedField(field, index) {
      field.relatedFields.splice(index, 1);
    },
    selectTenant(event) {
      this.item.tenants = event;
    },
    setDateToEndOfDay(event) {
      this.item.dateTo = this.$moment(event).endOf('day');
    },
    formatUnitsType(item) {
      return i18nValue(item);
    },
  },
};
</script>
<style lang="scss" scoped>
  .input-airline-fares {
    margin-right: 2ch;
  }

  .delete-field {
    position: absolute;
    top: 0;
    right: 0;
    padding: .2em;
  }

  .container-fields {
    overflow-y: auto;
    max-height: 36em;
    padding: .4em;
  }

  .card-field {
    margin: .7em auto;
    /*background: #f5f5f5;*/
  }
  .panel-related-field{
    /*background: #f5f5f5;*/
  }

  .container-related-fields {
    margin: 0 auto;
    padding: .4em;
  }
</style>
