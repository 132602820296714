<template>
  <ag-grid-template-cell-renderer :params="params">
    <span class="mr-2" v-show="inbound"><sup>IN</sup>{{inbound}}</span>
    <span v-show="outbound"><sup>OUT</sup>{{outbound}}</span>
  </ag-grid-template-cell-renderer>
</template>

<script>
import Vue from 'vue';
import AgGridTemplateCellRenderer from './templates/AgGridTemplateCellRenderer.vue';

export default Vue.extend({
  components: {
    AgGridTemplateCellRenderer,
  },
  computed: {
    inbound() {
      return this.params.value && this.params.value.value && Array.isArray(this.params.value.value) && this.params.value.value[0] ? this.params.value.value[0] : null;
    },
    outbound() {
      return this.params.value && this.params.value.value && Array.isArray(this.params.value.value) && this.params.value.value[1] ? this.params.value.value[1] : null;
    },
  },
});
</script>

<style scoped>

</style>
