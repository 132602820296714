import moment from 'moment';
import { JSUtils } from '../js/JSUtils';
import Contingency from '../../contingencyManagement/model/contingency';
import ContingencyDetail from '../../contingencyManagement/model/contingencyDetail';
import ContingencyPassageState from '../../contingencyManagement/model/contingencyPassageState';
import ContingencyInformation from '../../contingencyManagement/model/contingencyInformation';
import ContingencyBasicService from '../../contingencyManagement/model/contingencyBasicService';
import ContingencyHtlTransport from '../../contingencyManagement/model/contingencyHtlTransport';
import ContingencyComposition from '../../contingencyManagement/model/contingencyComposition';

export default class FlightContingencyFactory {
  static mapFlightContingency(data) {
    if (data && data.constructor === Array && data.length > 0) {
      return data.map(d => this.createFlightContingencyFromData(d));
    }
    return [];
  }

  static createFlightContingencyFromData(data) {
    if (!data) {
      return data;
    }

    return JSUtils.cast(data, Contingency);
  }

  static createFlightContingencyDetailFromData(data) {
    if (!data) {
      return data;
    }

    return JSUtils.cast(data, ContingencyDetail);
  }

  static createFlightContingencyPassageStateFromData(data) {
    if (!data) {
      return data;
    }

    return JSUtils.cast(data, ContingencyPassageState);
  }

  static createFlightContingencyInformationFromData(data) {
    if (!data) {
      return data;
    }

    return JSUtils.cast(data, ContingencyInformation);
  }

  static createFlightContingencyBasicServiceFromData(data) {
    if (!data) {
      return data;
    }

    const contingencyBasicServiceList = JSUtils.cast(data, ContingencyBasicService);

    /* eslint no-param-reassign: "off" */
    contingencyBasicServiceList.forEach((contingencyBasicService) => {
      contingencyBasicService.durationFormatTime = contingencyBasicService.duration != null ? moment('0000', 'HHmm').add(contingencyBasicService.duration, 'minute') : null;
    });

    return contingencyBasicServiceList;
  }

  static createFlightContingencyHtlTransportFromData(data) {
    if (!data) {
      return data;
    }

    return JSUtils.cast(data, ContingencyHtlTransport);
  }

  static createContingencyCompositionFromData(data) {
    if (!data) {
      return data;
    }

    return JSUtils.cast(data, ContingencyComposition);
  }
}
