import { VCard } from 'vuetify/lib/components/VCard';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VExpansionPanel,{staticClass:"exp-header",attrs:{"expand":""},model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}},[_c(VExpansionPanelContent,{attrs:{"expand-icon":"mdi-menu-down"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"pl-4 py-3"},[_c('h4',{staticClass:"headline font-weight-light"},[_vm._v(_vm._s(_vm.$t("administration.dusIntegrationTitle")))])])]},proxy:true}])},[_c(VCard,[_c(VLayout,{attrs:{"align-center":"","justify-space-around":"","row":"","fill-height":""}},[_c(VFlex,{attrs:{"xs4":"","pa-4":""}},[_c('DusIntegrationJob')],1),_c(VFlex,{attrs:{"xs4":"","pa-0":""}},[_c('DusIntegration')],1),_c(VFlex,{attrs:{"xs4":"","pa-4":""}},[_c('DusIntegrationClean')],1)],1),(_vm.operation)?_c(VFlex,{attrs:{"xs12":""}},[_c('AdminResult',{attrs:{"is-error":_vm.isError,"is-success":_vm.isSuccess,"loading":_vm.loading,"time":_vm.time,"traces":_vm.traces,"operation":_vm.operation}})],1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }