/* eslint no-param-reassign: "off" */
import { HTTP } from '../../../http';
import { REQUEST_ERROR, REQUEST_START, REQUEST_SUCCESS } from '../../store';


// action
export const ADMIN_AVINET_READ = 'ADMIN_AVINET_READ';
export const AVINET_FIND_TIME_LAST_EXECUTION = 'AVINET_FIND_TIME_LAST_EXECUTION';


// mutations
export const ADMIN_AVINET_READ_REQUEST = 'ADMIN_AVINET_READ_REQUEST';
export const ADMIN_AVINET_READ_RESPONSE = 'ADMIN_AVINET_READ_RESPONSE';
export const ADMIN_AVINET_READ_ERROR = 'ADMIN_AVINET_READ_ERROR';

export const AVINET_TIME_LAST_EXECUTED = 'AVINET_TIME_LAST_EXECUTED';


// initial state
const storeState = {
  readLoading: null,
  readSuccess: null,
  readResult: [],
  readLastRequestTime: null,
  readLastExecuted: null,
  avinetUsers: [],
  avinetUsersRequested: false,
  selectedavinetUsers: [],
};

// getters
const storeGetters = {
  isAvinetReadSuccess: state => state.readSuccess != null && !!state.readSuccess,
  isAvinetReadError: state => state.readSuccess != null && !state.readSuccess,

};

// actions
const storeActions = {
  [ADMIN_AVINET_READ]: ({ commit }) => {
    commit(ADMIN_AVINET_READ_REQUEST);
    return HTTP.post('integration/avinet/read')
      .then((resp) => {
        commit(ADMIN_AVINET_READ_RESPONSE, resp.data);
      })
      .catch((err) => {
        commit(ADMIN_AVINET_READ_ERROR, err.response.data);
      });
  },

  [AVINET_FIND_TIME_LAST_EXECUTION]: ({ commit }) => {
    // if vue-cli-service serve --mode dev
    if (process.env.NODE_ENV === 'development') {
      return null;
    }
    commit(REQUEST_START);
    return HTTP.get('integration/result/avinet')
      .then((resp) => {
        commit(REQUEST_SUCCESS);
        commit(AVINET_TIME_LAST_EXECUTED, resp.data);
      })
      .catch((err) => {
        commit(REQUEST_ERROR, err);
      });
  },
};

// mutations
const storeMutations = {
  [ADMIN_AVINET_READ_REQUEST]: (state) => {
    state.readLoading = true;
    state.readSuccess = null;
    state.readResult = [];
    state.readLastRequestTime = new Date();
  },
  [ADMIN_AVINET_READ_RESPONSE]: (state, result) => {
    state.readLoading = false;
    state.readSuccess = result.success;
    state.readResult = result.traces;
    state.readLastExecuted = result.success ? result.ts : state.readLastExecuted;
  },
  [ADMIN_AVINET_READ_ERROR]: (state, result) => {
    state.readLoading = false;
    state.readSuccess = false;
    state.readResult = [result.traces, result.message, result.detail];
  },
  [AVINET_TIME_LAST_EXECUTED]: (state, timeLastMessage) => {
    state.readLastExecuted = timeLastMessage;
  },
};

export default {
  state: storeState,
  getters: storeGetters,
  actions: storeActions,
  mutations: storeMutations,
};
