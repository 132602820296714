import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.isEmptyLoads())?_c(VContainer,{staticClass:"infoMobileLoads py-0"},[_c(VLayout,{attrs:{"row":"","wrap":""}},[_c(VFlex,{staticClass:"pb-2",attrs:{"xs8":""}},[_c(VRadioGroup,{staticClass:"large-radio ml-2",attrs:{"name":"unitOptions","id":"unitOptions","dark":_vm.$vuetify.dark,"row":""},model:{value:(_vm.flightMobileLoads.totalLoad.unit),callback:function ($$v) {_vm.$set(_vm.flightMobileLoads.totalLoad, "unit", $$v)},expression:"flightMobileLoads.totalLoad.unit"}},_vm._l((_vm.unitOptions),function(option,index){return _c(VRadio,{key:index,staticClass:"large-radio",attrs:{"name":option.text,"label":option.text,"value":option.value,"disabled":!_vm.hasPermissions('AIRPORT_SERVICE_AGENT_ROLE_BAGGAGE_DISTRIBUTION_WR')}})}),1)],1),(_vm.hasPermissions('AIRPORT_SERVICE_AGENT_ROLE_BAGGAGE_DISTRIBUTION_WR'))?_c(VFlex,{staticClass:"py-2 text-xs-right",attrs:{"xs4":""}},[_c(VBtn,{staticClass:"mx-0",attrs:{"large":"","color":"primary","disabled":!_vm.saveButton},on:{"click":_vm.saveFlightLoads}},[_vm._v(" "+_vm._s(_vm.$t('common.save'))+" ")])],1):_vm._e()],1),_c('mobile-flight-loads',{attrs:{"flightLoads":_vm.flightMobileLoads.loads,"totals":_vm.flightMobileLoads.totalLoad,"unit":_vm.getUnit()},on:{"updateLoad":_vm.updateLoad}}),_c(VSpacer,{staticClass:"pb-2"}),_c('mobile-flight-loads-holding',{attrs:{"flightLoads":_vm.flightMobileLoads,"updateFlightLoadHolding":_vm.updateFlightLoadHolding},on:{"enableSave":_vm.enableSave}}),_c(VLayout,{attrs:{"row":"","wrap":""}},[(_vm.hasPermissions('AIRPORT_SERVICE_AGENT_ROLE_BAGGAGE_DISTRIBUTION_WR'))?_c(VFlex,{staticClass:"py-0 text-xs-center",attrs:{"xs12":""}},[_c(VBtn,{staticClass:"mx-0",attrs:{"large":"","color":"primary","disabled":!_vm.saveButton},on:{"click":_vm.saveFlightLoads}},[_vm._v(" "+_vm._s(_vm.$t('common.save'))+" ")])],1):_vm._e()],1),_c(VSpacer,{staticClass:"pb-5"}),_c('scroll-buttons')],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }