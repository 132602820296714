<template>
  <ag-grid-vue
    class="ag-theme-material"
    :defaultColDef="defaultColDef"
    :columnDefs="columnDefs"
    :rowData="items"
    :onGridReady="onGridReady"
    :suppressHorizontalScroll="true"
    rowSelection="multiple"
    :frameworkComponents="frameworkComponents"
    :gridOptions="gridOptions"
    @selection-changed="onSelectionChanged"
    style="width: 100%; height: calc(100vh - 500px);">
  </ag-grid-vue>
</template>
<script>
import { AgGridVue } from 'ag-grid-vue';
import AgGridApiHoldable from '../../../ag-grid/mixins/AgGridApiHoldable.vue';
import AgGridTimeValueGetteable from '../../../ag-grid/mixins/AgGridTimeValueGetteable.vue';
import Resizable from '../../../mixins/Resizable.vue';

import AgGridValueComparable from '../../../ag-grid/mixins/AgGridValueComparable.vue';
import { cellClassAgGridRules } from '../../../ag-grid/mixins/cellClassAgGridRules';
import agGridTextCustomFilterComparator from '../../../ag-grid/filters/agGridTextCustomFilterComparator';
import agGridTextCustomFilterFormatter from '../../../ag-grid/filters/agGridTextCustomFilterFormatter';
import AgGridValueBaseCellRenderer from '../../../ag-grid/cell-renderers/AgGridValueBaseCellRenderer.vue';
import { FisModule } from '../../../ag-grid/cell-editors/model/fisModule';

export default {
  name: 'deliveries-base-grid',
  mixins: [AgGridApiHoldable, AgGridTimeValueGetteable, Resizable, AgGridValueComparable],
  components: {
    AgGridVue,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    const that = this;
    return {
      frameworkComponents: {
        AgGridValueBaseCellRenderer,
      },
      gridOptions: {
        getRowNodeId: this.getRowNodeId,
        getRowClass: this.getRowClass,
        context: {
          module: FisModule.LOST_AND_FOUND,
        },
      },
      defaultColDef: {
        cellClassRules: cellClassAgGridRules,
        resizable: true,
        sortable: true,
        filter: true,
        filterParams: {
          textCustomComparator: agGridTextCustomFilterComparator,
          textFormatter: agGridTextCustomFilterFormatter,
        },
      },
      columnDefs: [
        {
          checkboxSelection: true,
          width: 30,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          suppressMenu: true,
          sortable: false,
          suppressMovable: true,
          suppressSizeToFit: true,
          filter: false,
          resizable: false,
        },
        {
          colId: 'flight.flightTripNumber',
          headerName: 'Flight',
          editable: false,
          cellClass: 'flight-number-cell separator',
          valueGetter(params) {
            return params.data.flightNo();
          },
          cellRendererFramework: AgGridValueBaseCellRenderer,
        },
        {
          colId: 'flight.flightDate',
          headerName: 'Date',
          field: 'flightDate',
          editable: false,
          cellClass: 'date-cell separator',
          valueFormatter(params) {
            return that.$moment(params.data.date).format('DD/MM/YYYY');
          },
        },
        {
          colId: 'flight.airlineCode',
          headerName: 'Code',
          field: 'airlineCode',
          editable: false,
          cellClass: 'airline-from-cell',
          cellRendererFramework: AgGridValueBaseCellRenderer,
        },
        {
          colId: 'delivery.deliveryDate',
          headerName: 'Date',
          field: 'date',
          editable: false,
          cellClass: 'delivery-date-cell separator',
          valueFormatter(params) {
            return that.$moment(params.data.value).format('DD/MM/YYYY');
          },
        },
        {
          colId: 'delivery.fileNumber',
          headerName: 'File number',
          field: 'fileNumber',
          editable: false,
          cellClass: 'file-number-cell separator',
          cellRendererFramework: AgGridValueBaseCellRenderer,
        },
        {
          colId: 'delivery.numberBag',
          headerName: 'Number bag',
          field: 'numberBag',
          editable: false,
          cellClass: 'number-bag-cell',
          cellRendererFramework: AgGridValueBaseCellRenderer,
        },
        {
          colId: 'delivery.loadBag',
          headerName: 'Kgs',
          field: 'loadBag',
          editable: false,
          cellClass: 'load-bag-cell',
          cellRendererFramework: AgGridValueBaseCellRenderer,
        },
        {
          colId: 'delivery.type',
          headerName: 'Type',
          field: 'type',
          editable: false,
          cellClass: 'type-cell',
          cellRendererFramework: AgGridValueBaseCellRenderer,
        },
        {
          colId: 'delivery.passengerName',
          headerName: 'Passenger\'s Name / File Reference',
          field: 'passengerName',
          width: 350,
          editable: false,
          cellClass: 'passenger-name-cell',
          cellRendererFramework: AgGridValueBaseCellRenderer,
        },
        {
          colId: 'delivery.address',
          headerName: 'Delivery address',
          width: 450,
          field: 'address',
          editable: false,
          cellClass: 'address-cell',
          cellRendererFramework: AgGridValueBaseCellRenderer,
        },
        {
          colId: 'delivery.city',
          headerName: 'City',
          field: 'city',
          editable: false,
          cellClass: 'city-cell separator',
          cellRendererFramework: AgGridValueBaseCellRenderer,
        },
        {
          colId: 'delivery.postalCode',
          headerName: 'P.C.',
          field: 'postalCode',
          editable: false,
          cellClass: 'postal-code-cell',
          cellRendererFramework: AgGridValueBaseCellRenderer,
        },
        {
          colId: 'delivery.remarks',
          headerName: 'Remarks',
          field: 'remarks',
          width: 250,
          editable: false,
          cellClass: 'remarks-cell separator',
          cellRendererFramework: AgGridValueBaseCellRenderer,
        },
      ],
    };
  },
  methods: {
    onGridReady(params) {
      this.setAgGridApi(params);
      this.selectAll();
    },
    selectAll() {
      this.gridApi.selectAllFiltered();
    },
    onSelectionChanged() {
      const selectedNodes = this.gridApi.getSelectedNodes();
      this.$emit('selected-changed', selectedNodes.map(node => node.data));
    },
  },
};
</script>
<style>
</style>
