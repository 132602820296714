import { JSUtils } from '../../shared/js/JSUtils';

export default class FlightOperationInformation {
  constructor(
    id,
    operationDesk,
    operationAgent,
    tow,
    operationActionsStatus,
    passengersActionsStatus,
    remarksStatus,
    boardingAgent1,
    boardingAgent2,
  ) {
    this.id = id;
    this.operationDesk = operationDesk;
    this.operationAgent = operationAgent;
    this.tow = tow;
    this.operationActionsStatus = operationActionsStatus;
    this.passengersActionsStatus = passengersActionsStatus;
    this.remarksStatus = remarksStatus;
    this.boardingAgent1 = boardingAgent1;
    this.boardingAgent2 = boardingAgent2;
  }

  static get createEmptyFlightOperationInformation() {
    return new FlightOperationInformation();
  }

  static createFlightOperationInformationFromData(o) {
    if (!o) {
      return this.createEmptyFlightOperationInformation;
    }

    return JSUtils.cast(o, FlightOperationInformation);
  }
}
