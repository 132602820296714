<template>
  <div>
    <operation-flights-list-filter @changeShowFilter="changeShowFilter"></operation-flights-list-filter>
    <operation-flights-list-container :isFilterDisplayed="filterDisplayed"></operation-flights-list-container>
    <scheduling-cancel-flights-modal v-if="dialogCancelFlights" :dialog="dialogCancelFlights"
      :items="selectedItemsToCancel" @cancel-reject="cancelFlightsReject" @cancel-confirm="cancelFlightsConfirm" />
    <scheduling-edit-modal v-if="dialog" :dialog="dialog" :edited-scheduling-flight="editedSchedulingFlight"
      @close="close" />
    <scheduling-link-modal v-if="dialogLink" :dialog="dialogLink" :items="selectedItemsToLink" @link-reject="linkReject"
      @link-confirm="linkConfirm" />
    <scheduling-link-direct-modal v-if="dialogLinkDirect" :dialog="dialogLinkDirect" :items="selectedItemsToLinkDirect"
      @link-reject="linkDirectReject" @link-confirm="linkConfirm" />
    <scheduling-unlink-modal v-if="dialogUnlink" :dialog="dialogUnlink" :items="selectedItemsToUnlink"
      @unlink-reject="unlinkReject" @unlink-confirm="unlinkConfirm" />

    <operation-flights-list-options @clickFlight="newFlight" @clickLinkFlights="linkFlights"
      @clickUnlinkFlights="unlinkFlights" @click-archive="archiveFlights" @click-unarchive="unarchiveFlights"
      @click-cancel="cancelFlights" @click-new-aircraft-ground="newAircraftGround"
      @click-delete-aircraft-ground="deleteAircraftGround" />
    <aircraft-ground-new-modal v-if="dialogAircraftGround" :dialog="dialogAircraftGround"
      @aircraft-ground-new-reject="newAircraftGroundReject" @aircraft-ground-new-confirm="newAircraftGroundConfirm"
      @aircraft-ground-new-service="newAircraftGroundServices" />
    <aircraft-ground-new-services-modal v-if="dialogAircraftGroundServices" :dialog="dialogAircraftGroundServices"
      :aircraft-ground="aircraftGround" @aircraft-ground-service-new-reject="newAircraftGroundServiceReject"
      @aircraft-ground-service-new-confirm="newAircraftGroundServiceConfirm" />
    <aircraft-ground-delete-modal v-if="dialogDeleteAircraftGround" :dialog="dialogDeleteAircraftGround"
      :items="selectedItemsToDelete" @delete-reject="deleteAircraftGroundReject"
      @delete-confirm="deleteAircraftGroundConfirm" />
  </div>
</template>
<script>
import OperationFlightsListFilter from '../components/operations/list/OperationFlightsListFilter.vue';
import OperationFlightsListContainer from '../components/operations/list/OperationFlightsListContainer.vue';
import OperationFlightsListOptions from '../components/operations/list/OperationFlightsListOptions.vue';
import {
  OPERATION_ARCHIVE,
  OPERATION_CANCEL_FLIGHTS,
  OPERATION_DELETE_AIRCRAFT_GROUND,
  OPERATION_FIND_FLIGHTS,
  OPERATION_LINK_FLIGHTS,
  OPERATION_UNARCHIVE,
  OPERATION_UNLINK_FLIGHTS,
} from '../store/modules/operation';

import SchedulingCancelFlightsModal from '../components/scheduling/cancel/SchedulingCancelFlightsModal.vue';
import AircraftGroundNewModal from '../components/aircraftGround/AircraftGroundNewModal.vue';
import AircraftGroundDeleteModal from '../components/aircraftGround/AircraftGroundDeleteModal.vue';
import AircraftGroundNewServicesModal from '../components/aircraftGround/AircraftGroundNewServicesModal.vue';
import ConnectedFlights from '../components/shared/model/connectedFlights';
import SchedulingEditModal from '../components/scheduling/edit/SchedulingEditModal.vue';
import SchedulingLinkModal from '../components/scheduling/link/SchedulingLinkModal.vue';
import SchedulingLinkDirectModal from '../components/scheduling/link/SchedulingLinkDirectModal.vue';
import SchedulingUnlinkModal from '../components/scheduling/link/SchedulingUnlinkModal.vue';

export default {
  components: {
    OperationFlightsListFilter,
    OperationFlightsListContainer,
    OperationFlightsListOptions,
    AircraftGroundNewModal,
    AircraftGroundDeleteModal,
    SchedulingCancelFlightsModal,
    AircraftGroundNewServicesModal,
    SchedulingEditModal,
    SchedulingLinkModal,
    SchedulingLinkDirectModal,
    SchedulingUnlinkModal,
  },
  computed: {
    selectedRowsItems() {
      return this.$store.state.operation.selectedFlights;
    },
    selectedRowsFlights() {
      return this.$store.getters.getSelectedFlights;
    },
    selectedRowsAircraftGround() {
      return this.$store.getters.getSelectedAircraftGround;
    },
  },
  data() {
    return {
      dialog: false,
      dialogCancelFlights: false,
      filterDisplayed: false,
      selectedItemsToCancel: [],
      selectedItemsToDelete: [],
      selectedItemsToLink: [],
      selectedItemsToLinkDirect: [],
      selectedItemsToUnlink: [],
      dialogLink: false,
      dialogLinkDirect: false,
      dialogUnlink: false,
      dialogAircraftGround: false,
      dialogDeleteAircraftGround: false,
      dialogAircraftGroundServices: false,
      aircraftGround: null,
      editedSchedulingFlight: ConnectedFlights.createEmtpyConnectedFlights(),
      additionalDateRules: [
        (v) => {
          const value = this.$moment(v, 'DD/MM/YY').startOf('day');
          const today = this.$moment().startOf('day');
          const tomorrow = this.$moment().add(1, 'days').startOf('day');
          return (!value.isBefore(today) && !value.isAfter(tomorrow)) || this.$i18n.t('operations.unvalidDate');
        },
      ],
    };
  },
  methods: {
    archiveFlights() {
      this.$store.dispatch(OPERATION_ARCHIVE);
    },
    unarchiveFlights() {
      this.$store.dispatch(OPERATION_UNARCHIVE);
    },
    changeShowFilter(dispayled) {
      this.filterDisplayed = dispayled;
    },
    cancelFlights() {
      this.selectedItemsToCancel = [];
      this.selectedItemsToCancel = this.selectedRowsFlights;
      this.dialogCancelFlights = this.selectedItemsToCancel.length > 0;
    },
    cancelFlightsReject() {
      this.dialogCancelFlights = false;
    },
    cancelFlightsConfirm(flightsCancellation) {
      this.selectedItemsToCancel = [];
      this.$store.dispatch(OPERATION_CANCEL_FLIGHTS, flightsCancellation);
      this.dialogCancelFlights = false;
    },
    linkFlights() {
      if (this.selectedRowsItems.length === 2) {
        this.selectedItemsToLinkDirect = [];
        this.selectedItemsToLinkDirect = this.selectedRowsItems;
        this.dialogLinkDirect = true;
      } else {
        this.selectedItemsToLink = [];
        this.selectedItemsToLink = this.selectedRowsItems;
        this.dialogLink = true;
      }
    },
    unlinkFlights() {
      this.selectedItemsToUnlink = [];
      this.selectedItemsToUnlink = this.selectedRowsItems;
      this.dialogUnlink = true;
    },
    linkDirectReject() {
      this.selectedItemsToLinkDirect = [];
      this.dialogLinkDirect = false;
    },
    linkReject() {
      this.selectedItemsToLink = [];
      this.dialogLink = false;
    },
    unlinkReject() {
      this.selectedItemsToUnlink = [];
      this.dialogUnlink = false;
    },
    linkConfirm(linkedFlights) {
      this.dialogLink = false;
      this.dialogLinkDirect = false;
      this.selectedItemsToLink = [];
      this.selectedItemsToLinkDirect = [];
      this.$store.dispatch(OPERATION_LINK_FLIGHTS, linkedFlights);
    },
    unlinkConfirm(flights) {
      this.dialogUnlink = false;
      this.selectedItemsToUnlink = [];
      this.$store.dispatch(OPERATION_UNLINK_FLIGHTS, flights);
    },
    newFlight() {
      this.editedSchedulingFlight = ConnectedFlights.createEmtpyConnectedFlights();
      this.dialog = true;
    },
    newAircraftGround() {
      this.dialogAircraftGround = true;
    },
    newAircraftGroundReject() {
      this.dialogAircraftGround = false;
    },
    newAircraftGroundConfirm() {
      this.$store.dispatch(OPERATION_FIND_FLIGHTS);
      this.dialogAircraftGround = false;
    },
    newAircraftGroundServices(aircraftGround) {
      this.dialogAircraftGround = false;
      this.aircraftGround = aircraftGround;
      this.dialogAircraftGroundServices = true;
    },
    newAircraftGroundServiceReject() {
      this.dialogAircraftGroundServices = false;
    },
    newAircraftGroundServiceConfirm() {
      this.$store.dispatch(OPERATION_FIND_FLIGHTS);
      this.dialogAircraftGroundServices = false;
    },
    deleteAircraftGround() {
      this.selectedItemsToDelete = [];
      this.selectedItemsToDelete = this.selectedRowsAircraftGround;
      this.dialogDeleteAircraftGround = this.selectedItemsToDelete.length > 0;
    },
    deleteAircraftGroundReject() {
      this.dialogDeleteAircraftGround = false;
    },
    deleteAircraftGroundConfirm(ids) {
      this.selectedItemsToDelete = [];
      this.$store.dispatch(OPERATION_DELETE_AIRCRAFT_GROUND, { ids });
      this.dialogDeleteAircraftGround = false;
    },
    close(result) {
      this.dialog = false;
      if (result && result.doFind) {
        this.$store.dispatch(OPERATION_FIND_FLIGHTS);
      }
    },
  },
};
</script>
