/* eslint no-param-reassign: "off" */
/* eslint no-confusing-arrow: "off" */
/* eslint no-nested-ternary: "off" */

import { HTTP } from '../../../http';
import { REQUEST_ERROR, REQUEST_START, REQUEST_SUCCESS } from '../../store';
import { NOTIFICATION_SHOW } from '../notifications';
import i18n from '../../../plugins/i18n';

// action names
export const GET_FLIGHT_PAX_TIMES = 'GET_FLIGHT_PAX_TIMES';
export const UPDATE_FLIGHT_PAX_TIME = 'UPDATE_FLIGHT_PAX_TIME';

// mutations
export const GET_FLIGHT_PAX_TIMES_REQUESTED = 'GET_FLIGHT_PAX_TIMES_REQUESTED';
export const GET_FLIGHT_PAX_TIMES_SUCCESS = 'GET_FLIGHT_PAX_TIMES_SUCCESS';
export const UPDATE_FLIGHT_PAX_TIMES_SUCCESS = 'UPDATE_FLIGHT_PAX_TIMES_SUCCESS';

const storeState = {
  passageTimes: {},
  passageTimesRequested: false,
};

const storeGetters = {
  getPassageTimes: state => state.passageTimes,
};

const successResponse = (commit, dispatch, notification) => {
  commit(REQUEST_SUCCESS);
  commit(UPDATE_FLIGHT_PAX_TIMES_SUCCESS);
  commit(NOTIFICATION_SHOW, {
    message: i18n.t(notification),
    color: 'success',
  });
};
const errorResponse = (commit, err) => {
  commit(NOTIFICATION_SHOW, {
    message: i18n.t('notifications.errorNotification'),
    color: 'error',
  });
  commit(REQUEST_ERROR, err);
};

const storeActions = {
  [GET_FLIGHT_PAX_TIMES]: ({ commit }, flightId) => {
    commit(REQUEST_START);
    commit(GET_FLIGHT_PAX_TIMES_REQUESTED);
    HTTP.get(`flights/${flightId}/passenger/times`)
      .then((resp) => {
        commit(REQUEST_SUCCESS);
        commit(GET_FLIGHT_PAX_TIMES_SUCCESS, resp.data);
      })
      .catch((err) => {
        commit(REQUEST_ERROR, err);
      });
  },
  [UPDATE_FLIGHT_PAX_TIME]: ({ commit, dispatch }, { flightId, passageTimes }) => {
    commit(REQUEST_START);
    HTTP.put(`flights/${flightId}/passenger/times`, passageTimes)
      .then(() => {
        successResponse(commit, dispatch, 'notifications.passageTimesUpdate');
      })
      .catch((err) => {
        errorResponse(commit, err);
      });
  },
};

const storeMutations = {
  [GET_FLIGHT_PAX_TIMES_SUCCESS]: (state, data) => {
    state.passageTimes = data;
  },
  [GET_FLIGHT_PAX_TIMES_REQUESTED]: (state) => {
    state.passageTimesRequested = true;
  },
  [UPDATE_FLIGHT_PAX_TIMES_SUCCESS]: (state) => {
    state.passageTimesRequested = false;
  },
};

export default {
  state: storeState,
  getters: storeGetters,
  actions: storeActions,
  mutations: storeMutations,
};
