<!-- eslint-disable no-tabs -->
<template>
<v-flex>
	<v-toolbar class="gate-container" dark dense color="primary lighten-1 d-flex justify-center">
		<v-layout row wrap class="flex justify-center">
			<div class="gate-element">
				<v-icon>local_parking</v-icon>
				<span class="label">{{ parkingCode ? parkingCode : '-' }}</span>
			</div>
			<div class="gate-element">
				<span class="balloon">{{ $t("dispatcher.additionalInfoGate") }}</span>
				<span class="label">{{ gateCode ? gateCode : '-' }}</span>
			</div>
		</v-layout>
	</v-toolbar>
</v-flex>
</template>

<script>
export default {
  name: 'info-mobile-parking-header',
  props: {
    parkingCode: {
      type: String,
    },
    gateCode: {
      type: String,
    },
  },
};
</script>
