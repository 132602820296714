<template>
<div>
  <towing-adhoc-task-flight-list-table :items="items" :isFilterDisplayed="this.isFilterDisplayed" @click-request-ramp-service="addService" ></towing-adhoc-task-flight-list-table>
</div>
</template>
<script>
import TowingAdhocTaskFlightListTable from './TowingAdhocTaskFlightsListTable.vue';
import {
  FLIGHT_TOWING_ADHOC_TASK_FILTER_RESET,
  FLIGHT_TOWING_ADHOC_TASK_FIND_FLIGHTS,
} from '../../../../store/modules/towingAdhocTask/flightTowingAdhocTask';

export default {
  name: 'towing-adhoc-task-flights-list-container',
  components: {
    TowingAdhocTaskFlightListTable,
  },
  props: {
    isFilterDisplayed: { type: Boolean, required: false, default: false },
  },
  mounted() {
    this.$store.commit(FLIGHT_TOWING_ADHOC_TASK_FILTER_RESET);
    this.$store.dispatch(FLIGHT_TOWING_ADHOC_TASK_FIND_FLIGHTS);
  },
  computed: {
    items() {
      return this.$store.state.flightTowingAdhocTask.connectedFlightsTowingAdhocTask;
    },
  },
  methods: {
    addService(flightInformation) {
      this.$emit('click-request-ramp-service', flightInformation);
    },
  },
};
</script>
<style lang="scss">


</style>
