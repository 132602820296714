import PaginationRequestProperties from '../../ui/pagination/model/paginationRequestProperties';
import DayOfWeek from './daysOfWeek';

export default class SchedulingFilter {
  constructor(dateFrom, dateTo, unitOfTime, daysOfWeek, airline, airportFrom, airportTo, status, filterDateByEstimatedTime, paginationRequestProperties) {
    this.dateFrom = dateFrom;
    this.dateTo = dateTo;
    this.unitOfTime = unitOfTime;
    this.daysOfWeek = daysOfWeek;
    this.airline = airline;
    this.airportFrom = airportFrom;
    this.airportTo = airportTo;
    this.status = status;
    this.filterDateByEstimatedTime = filterDateByEstimatedTime;
    this.pageRequestProperties = paginationRequestProperties;
  }

  static get createEmptyFilter() {
    const defaultPaginationProperties = PaginationRequestProperties.createDefaultPaginationRequestProperties;
    return new SchedulingFilter(null, null, 'day', new DayOfWeek(), null, null, null, null, true, defaultPaginationProperties);
  }
}
