import { VCardText } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCardText,[_c(VContainer,{staticStyle:{"margin-bottom":"0","padding-bottom":"0"},attrs:{"fluid":""}},[_c(VLayout,{staticStyle:{"margin-bottom":"0","padding-bottom":"0"}},[_c(VFlex,{staticStyle:{"margin-right":"2ch","width":"15vw"}},[_c('airline-select',{directives:[{name:"char-length",rawName:"v-char-length.clearable",value:(4),expression:"4",modifiers:{"clearable":true}}],attrs:{"label":this.$i18n.t('common.airline'),"disabled":!_vm.hasPermissions(_vm.rolePermission)},model:{value:(_vm.item.airlineId),callback:function ($$v) {_vm.$set(_vm.item, "airlineId", $$v)},expression:"item.airlineId"}})],1),_c(VFlex,{directives:[{name:"flex-none",rawName:"v-flex-none"}],staticStyle:{"margin-right":"2ch","width":"30vw"}},[_c(VTextField,{attrs:{"label":this.$i18n.t('configuration.link'),"readonly":!_vm.hasPermissions(_vm.rolePermission)},model:{value:(_vm.item.link),callback:function ($$v) {_vm.$set(_vm.item, "link", $$v)},expression:"item.link"}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }