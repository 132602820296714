<template>
  <div>
    <ag-grid-vue
      class="ag-theme-material connected-flights-table operational-table"
      :columnDefs="columnDefs"
      :defaultColDef="defaultColDef"
      :rowData="items"
      :rowSelection="'multiple'"
      :suppressRowClickSelection="true"
      :rowMultiSelectWithClick="false"
      @grid-ready="onGridReady"
      @first-data-rendered="firstDataRendered"
      @selection-changed="onSelectionChanged"
      :frameworkComponents="frameworkComponents"
      :gridOptions="gridOptions"
      :multiSortKey="multiSortKey"
      :overlayNoRowsTemplate="customNoRowsOverlay"
      :overlayLoadingTemplate="customLoadingOverlay"
      v-bind:style="`width: 100%; height: calc(100vh - ${this.sizeFilter});`">
    </ag-grid-vue>
  </div>
</template>
<script>
import { AgGridVue } from 'ag-grid-vue';
import AgGridFlightStatusCellRenderer from '../../ag-grid/cell-renderers/AgGridFlightStatusCellRenderer.vue';
import AgGridIconTooltipOperationCellRenderer
from '../../ag-grid/cell-renderers/AgGridIconTooltipOperationCellRenderer.vue';
import AgGridConnectedFlightsCheckInCountersCellEditor
from '../../ag-grid/cell-editors/connectedFlights/AgGridConnectedFlightsCheckInCountersCellEditor.vue';
import AgGridConnectedFlightsTimeCellEditor
from '../../ag-grid/cell-editors/connectedFlights/AgGridConnectedFlightsTimeCellEditor.vue';
import AgGridConnectedFlightsStringCellEditor
from '../../ag-grid/cell-editors/connectedFlights/AgGridConnectedFlightsStringCellEditor.vue';
import AgGridConnectedFlightsDialogPassageCellEditor
from '../../ag-grid/cell-editors/connectedFlights/dialog/AgGridConnectedFlightsDialogPassageCellEditor.vue';
import AgGridConnectedFlightsDialogDelayCellEditor
from '../../ag-grid/cell-editors/connectedFlights/dialog/AgGridConnectedFlightsDialogDelayCellEditor.vue';
import AgGridValueAndSupCellRenderer from '../../ag-grid/cell-renderers/AgGridValueAndSupCellRenderer.vue';
import AgGridValueBaseCellRenderer from '../../ag-grid/cell-renderers/AgGridValueBaseCellRenderer.vue';
import AgGridTimeCellRenderer from '../../ag-grid/cell-renderers/AgGridTimeCellRenderer.vue';
import AgGridConnectedFlights from '../../ag-grid/mixins/AgGridConnectedFlights.vue';
import FlightsDifferences from '../../mixins/FlightsDifferences.vue';
import AgGridConnectedFlightsDialogRealPaxCellEditor
from '../../ag-grid/cell-editors/connectedFlights/dialog/AgGridConnectedFlightsDialogRealPaxCellEditor.vue';

import { FlightBound } from '../../shared/model/flightBound';
import {
  FIT_COLUMNS_TO_VIEW,
  PASSAGE_CHANGE_FLIGHT_SELECTION,
  PASSAGE_ON_EXTERNAL_CHANGE,
  PASSAGE_SET_CHANGE,
  PASSAGE_SET_GRID_API,
} from '../../../store/modules/passageOutbound';
import { cellClassAgGridRules } from '../../ag-grid/mixins/cellClassAgGridRules';
import { WEBSOCKET_CONNECTION } from '../../../store/modules/websocket';
import AgGridCustomService from '../../shared/services/agGridCustomService';
import AgGridValueWithAsteriskCellRenderer
from '../../ag-grid/cell-renderers/AgGridValueWithAsteriskCellRenderer.vue';
import { FisModule } from '../../ag-grid/cell-editors/model/fisModule';
import AgGridRowActionBoardingButtonCellRenderer
from '../../ag-grid/cell-renderers/AgGridRowActionBoardingButtonCellRenderer.vue';
import agGridTextCustomFilterComparator from '../../ag-grid/filters/agGridTextCustomFilterComparator';
import agGridTextCustomFilterFormatter from '../../ag-grid/filters/agGridTextCustomFilterFormatter';
import RolePermissions from '../../mixins/RolePermissions.vue';
import AgGridConnectedFlightsDialogMessageCellEditor
from '../../ag-grid/cell-editors/connectedFlights/dialog/AgGridConnectedFlightsDialogMessageCellEditor.vue';
import AgGridConnectedFlightsDialogFlightStatusIntegrationCellEditor from '../../ag-grid/cell-editors/connectedFlights/dialog/AgGridConnectedFlightsDialogFlightStatusIntegrationCellEditor.vue';
import AgGridConnectedFlightsAirportCarouselSelectCellEditor from '../../ag-grid/cell-editors/connectedFlights/AgGridConnectedFlightsAirportCarouselSelectCellEditor.vue';
import AgGridConnectedFlightsToggleCellEditor from '../../ag-grid/cell-editors/connectedFlights/AgGridConnectedFlightsToggleCellEditor.vue';
import AgGridConnectedFlightsAirportParkingSelectCellEditor from '../../ag-grid/cell-editors/connectedFlights/AgGridConnectedFlightsAirportParkingSelectCellEditor.vue';
import AgGridConnectedFlightsAirportGateSelectCellEditor
from '../../ag-grid/cell-editors/connectedFlights/AgGridConnectedFlightsAirportGateSelectCellEditor.vue';
import AgGridConnectedFlightsNumberCellEditor from '../../ag-grid/cell-editors/connectedFlights/AgGridConnectedFlightsNumberCellEditor.vue';
import AgGridValueAndSupFieldPossibleBlockedCellRenderer from '../../ag-grid/cell-renderers/AgGridValueAndSupFieldPossibleBlockedCellRenderer.vue';
import AgGridValueAndIconAndSupFieldPossibleBlockedCellRenderer from '../../ag-grid/cell-renderers/AgGridValueAndIconAndSupFieldPossibleBlockedCellRenderer.vue';
import AgGridTimeFieldPossibleBlockedCellRenderer from '../../ag-grid/cell-renderers/AgGridTimeFieldPossibleBlockedCellRenderer.vue';
import AgGridNoRowsOverlay from '../../ag-grid/mixins/AgGridNoRowsOverlay.vue';

export default {
  mixins: [AgGridConnectedFlights, FlightsDifferences, RolePermissions, AgGridNoRowsOverlay],
  components: {
    AgGridVue,
  },
  props: {
    items: { type: Array, required: true },
    isFilterDisplayed: { type: Boolean, required: false, default: false },
  },
  mounted() {
    this.$store.dispatch(WEBSOCKET_CONNECTION, this.$store.state.tenant.currentTenant.name.toLowerCase());
  },
  computed: {
    sizeFilter() {
      return this.isFilterDisplayed ? '250px' : '180px';
    },
    routeName() {
      return this.$route.name;
    },
  },
  data() {
    const that = this;
    const rolePermission = this.getRoles();
    return {
      dialog: false,
      gridOptions: {
        onFilterChanged: this.uncheckAllOnFilter,
        getRowNodeId: this.getRowNodeId,
        getRowClass: this.getRowClass,
        floatingFilter: true,
        groupHeaderHeight: 35,
        headerHeight: 35,
        floatingFiltersHeight: 20,
        rowHeight: 30,
        context: {
          module: FisModule.PASSAGE_OUTBOUND,
        },
      },
      multiSortKey: 'ctrl',
      frameworkComponents: {
        AgGridConnectedFlightsCheckInCountersCellEditor,
        AgGridConnectedFlightsTimeCellEditor,
        AgGridConnectedFlightsDialogDelayCellEditor,
        AgGridConnectedFlightsStringCellEditor,
        AgGridRowActionBoardingButtonCellRenderer,
        AgGridConnectedFlightsDialogRealPaxCellEditor,
        AgGridConnectedFlightsDialogPassageCellEditor,
        AgGridConnectedFlightsDialogMessageCellEditor,
        AgGridConnectedFlightsDialogFlightStatusIntegrationCellEditor,
        AgGridConnectedFlightsAirportCarouselSelectCellEditor,
        AgGridConnectedFlightsToggleCellEditor,
        AgGridConnectedFlightsAirportParkingSelectCellEditor,
        AgGridConnectedFlightsAirportGateSelectCellEditor,
        AgGridConnectedFlightsNumberCellEditor,
      },
      defaultColDef: {
        resizable: true,
        sortable: true,
        minWidth: 30,
        suppressMenu: true,
        filter: 'agTextColumnFilter',
        filterParams: {
          textCustomComparator: agGridTextCustomFilterComparator,
          textFormatter: agGridTextCustomFilterFormatter,
        },
        colCustomConfig: {
          flightBound: FlightBound.OUTBOUND,
        },
        cellClassRules: cellClassAgGridRules,
        comparator: this.objectWithValueComparator,
        editable: false,
      },
      columnDefs: [
        {
          headerName: '',
          children: [
            {
              headerTooltip: this.$i18n.t('common.checkUnCheck'),
              hide: !this.hasPermissions(rolePermission),
              checkboxSelection: true,
              width: 21,
              minWidth: 20,
              headerCheckboxSelection: true,
              headerCheckboxSelectionFilteredOnly: true,
              suppressMenu: true,
              sortable: false,
              suppressMovable: true,
              suppressSizeToFit: true,
              filter: false,
              resizable: false,
              colCustomConfig: {
                exportCsvDisabled: true,
              },
              editable: this.editable(true, rolePermission),
            },
            {
              headerName: 'SortDate',
              field: 'sortingTime',
              hide: true,
              sort: 'asc',
            },
          ],
        },
        {
          headerValueGetter() {
            return that.$i18n.t('commonForm.flightInformation');
          },
          headerTooltip: this.$i18n.t('commonForm.flightInformation'),
          children: [
            {
              colId: 'outbound.common.flightNumber',
              headerValueGetter() {
                return that.getTextResizable(that.$i18n.t('operations.flightNumberShort'), that.$i18n.t('operations.flightNumber'));
              },
              headerTooltip: this.$i18n.t('operations.flightNumber'),
              width: 50,
              colCustomConfig: {
                flightBound: FlightBound.OUTBOUND,
                fieldsRelated: ['outbound.common.flightNumber', 'outbound.common.flagAirlineOperation'],
              },
              valueGetter(params) {
                return {
                  value: params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'flightNumber']),
                  asterisk: params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'flagAirlineOperation']),
                  tooltip: params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'airlineOperationMessage']),
                };
              },
              cellRendererFramework: AgGridValueWithAsteriskCellRenderer,
            },
            {
              colId: 'outbound.common.airlineOperation',
              headerValueGetter() {
                return that.$i18n.t('flightInformation.airlineOperation');
              },
              headerTooltip: this.$i18n.t('flightInformation.airlineOperation'),
              width: 40,
              colCustomConfig: {
                flightBound: FlightBound.OUTBOUND,
                fieldsRelated: ['outbound.common.airlineOperationIataCode'],
              },
              valueGetter(params) {
                return params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'airlineOperationIataCode']);
              },
            },
            {
              colId: 'outbound.common.aircraftRegisterNumber',
              headerValueGetter() {
                return that.$i18n.t('operations.aircraftRegistration');
              },
              headerTooltip: this.$i18n.t('operations.aircraftRegistration'),
              width: 70,
              colCustomConfig: {
                flightBound: FlightBound.OUTBOUND,
                fieldsRelated: ['outbound.common.aircraftRegisterNumber', 'outbound.common.aircraftAcars', 'outbound.blockedFieldsIntegration.manualOverwriteAircraft'],
              },
              valueGetter(params) {
                return {
                  value: params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'aircraftRegisterNumber']),
                  sup: params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'aircraftAcars']) ? 'A' : null,
                  fieldBlockedIntegration: params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['blockedFieldsIntegration', 'manualOverwriteAircraft']),
                };
              },
              cellRendererFramework: AgGridValueAndSupFieldPossibleBlockedCellRenderer,
            },
            {
              colId: 'outbound.common.status',
              headerValueGetter() {
                return that.getTextResizable(that.$i18n.t('operations.statusShort'), that.$i18n.t('operations.status'));
              },
              headerTooltip: this.$i18n.t('operations.status'),
              width: 51,
              minWidth: 45,
              cellClass: 'status-cell',
              comparator: this.objectWithValueComparator,
              colCustomConfig: {
                flightBound: FlightBound.OUTBOUND,
                fieldsRelated: ['outbound.common.status'],
              },
              valueGetter(params) {
                return params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'status']);
              },
              cellRendererFramework: AgGridFlightStatusCellRenderer,
            },
            {
              colId: 'outbound.integration.flightStatusIntegration',
              headerValueGetter() {
                return that.getTextResizable(that.$i18n.t('operations.airportStatusShort'), that.$i18n.t('operations.airportStatus'));
              },
              headerTooltip: (() => this.$i18n.t('operations.airportStatus'))(),
              width: 48,
              minWidth: 39,
              editable: this.isOutboundAndNotAircraftGround,
              cellEditor: 'AgGridConnectedFlightsDialogFlightStatusIntegrationCellEditor',
              comparator: this.objectWithValueComparator,
              cellClass: 'airport-status-cell',
              colCustomConfig: {
                flightBound: FlightBound.OUTBOUND,
                fieldsRelated: ['outbound.integration.flightStatusIntegration'],
              },
              flight: FlightBound.OUTBOUND,
              valueGetter(params) {
                return {
                  value: params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['integration', 'statusIntegration']),
                  tooltip: params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['integration', 'statusIntegration']) != null ? that.$i18n.t(`flightStatusIntegration.${params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['integration', 'statusIntegration'])}`) : null,
                };
              },
              cellRendererFramework: AgGridFlightStatusCellRenderer,
            },
            {
              colId: 'outbound.common.airport1Code',
              headerValueGetter() {
                return that.$i18n.t('operations.airportOfDestination');
              },
              headerTooltip: this.$i18n.t('operations.airportOfDestination'),
              width: 50,
              colCustomConfig: {
                flightBound: FlightBound.OUTBOUND,
                fieldsRelated: ['outbound.common.airport1Code', 'outbound.common.hasSeveralAirports'],
              },
              valueGetter(params) {
                return {
                  value: params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'airport1Code']),
                  asterisk: params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'hasSeveralAirports']),
                  tooltip: params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'hasSeveralAirports']) ? params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'airportsIata']) : undefined,
                };
              },
              cellRendererFramework: AgGridValueWithAsteriskCellRenderer,
            },
            {
              colId: 'outbound.common.scheduledTime',
              headerValueGetter() {
                return that.$i18n.t('operations.scheduledTimeOfDeparture');
              },
              headerTooltip: this.$i18n.t('operations.scheduledTimeOfDeparture'),
              width: 50,
              colCustomConfig: {
                flightBound: FlightBound.OUTBOUND,
                fieldsRelated: ['outbound.common.scheduledTime'],
              },
              valueGetter(params) {
                return that.agGridTimeGetter(params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'scheduledTime']), params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'scheduledDate']), null, null, that.scheduledTimeRequired);
              },
              cellRendererFramework: AgGridTimeCellRenderer,
            },
            {
              colId: 'outbound.times.estimatedTime',
              headerName: 'ETD',
              headerValueGetter() {
                return that.$i18n.t('flightInformation.estimatedTimeDeparture');
              },
              headerTooltip: this.$i18n.t('flightInformation.estimatedTimeDeparture'),
              width: 50,
              colCustomConfig: {
                flightBound: FlightBound.OUTBOUND,
                fieldsRelated: ['outbound.times.estimatedTime', 'outbound.blockedFieldsIntegration.manualOverwriteEstimatedTime'],
              },
              valueGetter(params) {
                const value = that.agGridTimeGetter(params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['times', 'estimatedTime']), params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'scheduledDate']));
                value.fieldBlockedIntegration = params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['blockedFieldsIntegration', 'manualOverwriteEstimatedTime']);
                return value;
              },
              cellRendererFramework: AgGridTimeFieldPossibleBlockedCellRenderer,
            },
            {
              colId: 'outbound.extended.delay',
              headerValueGetter() {
                return that.getTextResizable(that.$i18n.t('operations.flightDelayShort'), that.$i18n.t('operations.flightDelay'));
              },
              headerTooltip: this.$i18n.t('operations.flightDelay'),
              width: 50,
              editable: this.editable(true, rolePermission),
              cellEditor: 'AgGridConnectedFlightsDialogDelayCellEditor',
              colCustomConfig: {
                flightBound: FlightBound.OUTBOUND,
                fieldsRelated: ['outbound.extended.delay'],
              },
              valueGetter(params) {
                const delayValue = params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['extended', 'delay']);
                return {
                  icon: delayValue ? 'chevron_right' : '',
                  color: delayValue ? 'bggreydark' : '',
                  tooltip: delayValue ? 'There are delays' : 'There are not delays',
                };
              },
              cellRendererFramework: AgGridIconTooltipOperationCellRenderer,
            },
            {
              colId: 'outbound.extended.msg',
              headerValueGetter() {
                return that.$i18n.t('operations.messages');
              },
              headerTooltip: this.$i18n.t('operations.messages'),
              width: 50,
              editable: true,
              cellEditor: 'AgGridConnectedFlightsDialogMessageCellEditor',
              colCustomConfig: {
                flightBound: FlightBound.OUTBOUND,
                fieldsRelated: ['outbound.extended.messageStatus'],
              },
              valueGetter(params) {
                const messageValue = params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['extended', 'messageStatus']);
                return that.checkBoundAndSetMsgTooltipObject(messageValue);
              },
              cellRendererFramework: AgGridIconTooltipOperationCellRenderer,
            },
          ],
        },
        {
          headerValueGetter() {
            return that.$i18n.t('passage.passengersInfo');
          },
          headerTooltip: this.$i18n.t('passage.passengersInfo'),
          children: [
            {
              colId: 'outbound.passageSummary.localPaxExpected',
              headerValueGetter() {
                return that.$i18n.t('operations.prevPax');
              },
              headerTooltip: this.$i18n.t('operations.prevPax'),
              width: 50,
              cellClass: 'separator',
              editable: this.editable(true, rolePermission),
              cellEditor: 'AgGridConnectedFlightsNumberCellEditor',
              colCustomConfig: {
                fieldPaths: ['passageSummary.localPaxExpected'],
                flightBound: FlightBound.OUTBOUND,
                fieldsRelated: ['outbound.passageSummary.localPaxExpected'],
              },
              valueGetter(params) {
                return params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['passageSummary', 'localPaxExpected']);
              },
              cellRendererFramework: AgGridValueBaseCellRenderer,
            },
            {
              colId: 'outbound.passageSummary.localPaxCheckInCounters',
              headerValueGetter() {
                return that.$i18n.t('passage.realPax');
              },
              headerTooltip: this.$i18n.t('passage.realPax'),
              width: 50,
              editable: true,
              cellEditor: 'AgGridConnectedFlightsDialogRealPaxCellEditor',
              colCustomConfig: {
                flightBound: FlightBound.OUTBOUND,
                fieldsRelated: ['outbound.passageSummary.localPaxCheckInCounters'],
              },
              valueGetter(params) {
                return params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['passageSummary', 'localPaxCheckInCounters']);
              },
              cellRendererFramework: AgGridValueBaseCellRenderer,
            },
            {
              colId: 'outbound.passageSummary.connectionPax',
              headerValueGetter() {
                return that.getTextResizable(that.$i18n.t('passage.connectionPaxShort'), that.$i18n.t('passage.connectionPax'));
              },
              headerTooltip: this.$i18n.t('passage.connectionPax'),
              width: 50,
              colCustomConfig: {
                flightBound: FlightBound.OUTBOUND,
                fieldsRelated: ['outbound.passageSummary.connectionPax'],
              },
              valueGetter(params) {
                return params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['passageSummary', 'connectionPax']);
              },
              cellRendererFramework: AgGridValueBaseCellRenderer,
            },
            {
              colId: 'outbound.passageSummary.totalPax',
              headerValueGetter() {
                return that.$i18n.t('operations.totalPax');
              },
              headerTooltip: this.$i18n.t('operations.totalPax'),
              width: 50,
              editable: true,
              cellEditor: 'AgGridConnectedFlightsDialogPassageCellEditor',
              colCustomConfig: {
                flightBound: FlightBound.OUTBOUND,
                fieldsRelated: ['outbound.passageSummary.passengersNumber', 'outbound.passageSummary.passengersChildNumber', 'outbound.passageSummary.passengersSpecial'],
              },
              valueGetter(params) {
                const isRealNumber = params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['passageSummary', 'passengersNumber']) > 0 || params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['passageSummary', 'passengersChildNumber']) > 0;
                let text = params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['passageSummary', 'passengersNumber']) == null ? '' : params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['passageSummary', 'passengersNumber']);
                let tooltip = '';
                let styleContent = {};
                text += params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['passageSummary', 'passengersChildNumber']) == null ? '' : `+${params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['passageSummary', 'passengersChildNumber'])}`;
                if ((params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['passageSummary', 'passengersEstimated']) > 0) && !isRealNumber) {
                  text = params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['passageSummary', 'passengersEstimated']);
                  styleContent = { color: 'darkslategray', 'font-weight': 'bold', 'font-style': 'italic' };
                }
                // when handling type is P, the total of passengers must be (P)
                if (params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'handlingTypeCode']) === 'P') {
                  text = '(P)';
                  tooltip = that.$i18n.t('operations.emptyFlight');
                }
                return {
                  value: text,
                  asterisk: params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['passageSummary', 'passengersSpecial']),
                  styleObject: {
                    asteriskColor: 'red',
                    content: styleContent,
                  },
                  tooltip,
                };
              },
              cellRendererFramework: AgGridValueWithAsteriskCellRenderer,
            },
            {
              colId: 'outbound.rampExtended.carousel',
              headerValueGetter() {
                return that.$i18n.t('ramp.carousel');
              },
              width: 45,
              editable: this.editable(true, rolePermission),
              cellEditor: 'AgGridConnectedFlightsAirportCarouselSelectCellEditor',
              cellClass: 'aircraft-type-cell',
              comparator: this.objectWithValueComparator,
              colCustomConfig: {
                fieldPaths: ['rampExtended.carouselId'],
                flightBound: FlightBound.OUTBOUND,
                fieldsRelated: ['outbound.rampExtended.carouselCode'],
              },
              valueGetter(params) {
                return params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['rampExtended', 'carouselCode']);
              },
              cellRendererFramework: AgGridValueBaseCellRenderer,
            },
            {
              colId: 'outbound.common.pkn',
              headerValueGetter() {
                return that.$i18n.t('operations.parking');
              },
              headerTooltip: this.$i18n.t('operations.parking'),
              width: 55,
              minWidth: 55,
              cellClass: 'aircraft-config-cell',
              comparator: this.objectWithValueComparator,
              editable: this.editable(true, rolePermission),
              cellEditor: 'AgGridConnectedFlightsAirportParkingSelectCellEditor',
              colCustomConfig: {
                fieldPaths: ['common.parkingId'],
                flightBound: FlightBound.OUTBOUND,
                fieldsRelated: ['outbound.common.parkingCode', 'outbound.common.parkingRemote', 'outbound.common.parkingPushback', 'outbound.common.parkingInop400Hz', 'outbound.blockedFieldsIntegration.manualOverwriteParking'],
              },
              valueGetter(params) {
                let superindice = params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'parkingRemote']) ? 'R' : '';
                superindice += params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'parkingPushback']) ? 'P' : '';
                return {
                  value: params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'parkingCode']),
                  sup: superindice,
                  fieldBlockedIntegration: params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['blockedFieldsIntegration', 'manualOverwriteParking']),
                  icon: params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'parkingInop400Hz']) ? 'flash_off' : null,
                  colorIcon: params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'parkingInop400Hz']) ? 'red' : null,
                };
              },
              cellRendererFramework: AgGridValueAndIconAndSupFieldPossibleBlockedCellRenderer,
            },
            {
              colId: 'outbound.common.gate',
              headerValueGetter() {
                return that.$i18n.t('operations.gate');
              },
              headerTooltip: this.$i18n.t('operations.gate'),
              width: 50,
              cellClass: 'aircraft-config-cell',
              comparator: this.objectWithValueComparator,
              editable: this.editable(true, rolePermission),
              cellEditor: 'AgGridConnectedFlightsAirportGateSelectCellEditor',
              colCustomConfig: {
                fieldPaths: ['common.gateId'],
                flightBound: FlightBound.OUTBOUND,
                fieldsRelated: ['outbound.common.gateCode', 'outbound.common.gateRemote'],
              },
              valueGetter(params) {
                return {
                  value: params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'gateCode']),
                  sup: params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'gateRemote']) ? 'R' : null,
                };
              },
              cellRendererFramework: AgGridValueAndSupCellRenderer,
            },
          ],
        },
        {
          colId: 'outbound.passageCheckIn.cki',
          headerValueGetter() {
            return that.$i18n.t('operations.checkInCounters');
          },
          headerTooltip: this.$i18n.t('operations.checkInCounters'),
          width: 60,
          cellClass: 'separator cki-flight-cell',
          editable: this.editable(true, rolePermission),
          cellEditor: 'AgGridConnectedFlightsCheckInCountersCellEditor',
          colCustomConfig: {
            fieldPaths: ['passageCheckIn.checkInCounterFirstId', 'passageCheckIn.checkInCounterLastId'],
            flightBound: FlightBound.OUTBOUND,
            fieldsRelated: ['outbound.passageCheckIn.checkInCounterFirstCode', 'outbound.passageCheckIn.checkInCounterLastCode'],
          },
          valueGetter(params) { // TODO
            let val = '';
            if (params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['passageCheckIn', 'checkInCounterFirstCode']) != null) {
              val += params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['passageCheckIn', 'checkInCounterFirstCode']);
            }
            if (params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['passageCheckIn', 'checkInCounterLastCode']) != null) {
              val += val !== '' ? '-' : '';
              val += params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['passageCheckIn', 'checkInCounterLastCode']);
            }
            return val;
          },
        },
        {
          headerValueGetter() {
            return that.$i18n.t('operations.passengerTimesModal.checkInStart');
          },
          headerTooltip: this.$i18n.t('operations.passengerTimesModal.checkInStart'),
          children: [
            {
              colId: 'outbound.passageOutbound.checkInExpectedStartTime',
              headerValueGetter() {
                return that.$i18n.t('passage.expected');
              },
              headerTooltip: this.$i18n.t('passage.expected'),
              width: 45,
              minWidth: 45,
              editable: this.editable(true, rolePermission),
              cellEditor: 'AgGridConnectedFlightsTimeCellEditor',
              colCustomConfig: {
                fieldPaths: ['passageOutbound.checkInExpectedStartTime'],
                flightBound: FlightBound.OUTBOUND,
                fieldsRelated: ['outbound.passageOutbound.checkInExpectedStartTime'],
              },
              valueGetter(params) {
                return that.agGridTimeGetter(params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['passageOutbound', 'checkInExpectedStartTime']), params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'scheduledDate']));
              },
              cellRendererFramework: AgGridTimeCellRenderer,
            },
            {
              colId: 'outbound.passageOutbound.checkInStartTime',
              headerValueGetter() {
                return that.$i18n.t('passage.real');
              },
              headerTooltip: this.$i18n.t('passage.real'),
              width: 40,
              minWidth: 40,
              editable: this.editable(true, rolePermission),
              cellEditor: 'AgGridConnectedFlightsTimeCellEditor',
              colCustomConfig: {
                fieldPaths: ['passageOutbound.checkInStartTime'],
                flightBound: FlightBound.OUTBOUND,
                fieldsRelated: ['outbound.passageOutbound.checkInStartTime'],
              },
              valueGetter(params) {
                return that.agGridTimeGetter(params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['passageOutbound', 'checkInStartTime']), params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'scheduledDate']));
              },
              cellRendererFramework: AgGridTimeCellRenderer,
            },
          ],
        },
        {
          headerValueGetter() {
            return that.$i18n.t('operations.passengerTimesModal.checkInEnd');
          },
          headerTooltip: this.$i18n.t('operations.passengerTimesModal.checkInEnd'),
          children: [
            {
              colId: 'outbound.passageOutbound.checkInExpectedEndTime',
              headerValueGetter() {
                return that.$i18n.t('passage.expected');
              },
              headerTooltip: this.$i18n.t('passage.expected'),
              width: 45,
              minWidth: 45,
              editable: this.editable(true, rolePermission),
              cellEditor: 'AgGridConnectedFlightsTimeCellEditor',
              colCustomConfig: {
                fieldPaths: ['passageOutbound.checkInExpectedEndTime'],
                flightBound: FlightBound.OUTBOUND,
                fieldsRelated: ['outbound.passageOutbound.checkInExpectedEndTime'],
              },
              valueGetter(params) {
                return that.agGridTimeGetter(params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['passageOutbound', 'checkInExpectedEndTime']), params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'scheduledDate']));
              },
              cellRendererFramework: AgGridTimeCellRenderer,
            },
            {
              colId: 'outbound.passageOutbound.checkInEndTime',
              headerValueGetter() {
                return that.$i18n.t('passage.real');
              },
              headerTooltip: this.$i18n.t('passage.real'),
              width: 40,
              minWidth: 40,
              editable: this.editable(true, rolePermission),
              cellEditor: 'AgGridConnectedFlightsTimeCellEditor',
              colCustomConfig: {
                fieldPaths: ['passageOutbound.checkInEndTime'],
                flightBound: FlightBound.OUTBOUND,
                fieldsRelated: ['outbound.passageOutbound.checkInEndTime'],
              },
              valueGetter(params) {
                return that.agGridTimeGetter(params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['passageOutbound', 'checkInEndTime']), params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'scheduledDate']));
              },
              cellRendererFramework: AgGridTimeCellRenderer,
            },
          ],
        },
        {
          headerValueGetter() {
            return that.$i18n.t('sidebar.Passage');
          },
          headerTooltip: this.$i18n.t('sidebar.Passage'),
          children: [
            {
              colId: 'outbound.passageOutbound.jumpseats',
              headerValueGetter() {
                return that.$i18n.t('passage.jmp');
              },
              headerTooltip: this.$i18n.t('passage.jmp'),
              width: 30,
              cellClass: 'separator',
              editable: this.editable(true, rolePermission),
              cellEditor: 'AgGridConnectedFlightsNumberCellEditor',
              colCustomConfig: {
                fieldPaths: ['passageOutbound.jumpseats'],
                flightBound: FlightBound.OUTBOUND,
                fieldsRelated: ['outbound.passageOutbound.jumpseats'],
              },
              valueGetter(params) {
                return params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['passageOutbound', 'jumpseats']);
              },
              cellRendererFramework: AgGridValueBaseCellRenderer,
            },
            {
              colId: 'outbound.passageSummary.paxUMNR',
              headerValueGetter() {
                return that.$i18n.t('passage.umnr');
              },
              headerTooltip: this.$i18n.t('passage.umnr'),
              width: 30,
              colCustomConfig: {
                flightBound: FlightBound.OUTBOUND,
                fieldsRelated: ['outbound.passageSummary.paxUMNR'],
              },
              valueGetter(params) {
                return params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['passageSummary', 'paxUMNR']);
              },
              cellRendererFramework: AgGridValueBaseCellRenderer,
            },
            {
              colId: 'outbound.passageOutbound.paxTotalPetc',
              headerValueGetter() {
                return that.$i18n.t('passage.petc');
              },
              headerTooltip: this.$i18n.t('passage.petc'),
              width: 30,
              colCustomConfig: {
                flightBound: FlightBound.OUTBOUND,
                fieldsRelated: ['outbound.passageOutbound.paxTotalPetc'],
              },
              valueGetter(params) {
                return params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['passageOutbound', 'paxTotalPetc']);
              },
              cellRendererFramework: AgGridValueBaseCellRenderer,
            },
            {
              colId: 'outbound.passageOutbound.paxTotalWCH',
              headerValueGetter() {
                return that.$i18n.t('passage.wch');
              },
              headerTooltip: this.$i18n.t('passage.wch'),
              width: 30,
              colCustomConfig: {
                flightBound: FlightBound.OUTBOUND,
                fieldsRelated: ['outbound.passageOutbound.paxTotalWCH'],
              },
              valueGetter(params) {
                return params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['passageOutbound', 'paxTotalWCH']);
              },
              cellRendererFramework: AgGridValueBaseCellRenderer,
            },
            {
              colId: 'outbound.passageOutbound.lastMinuteChangesPax',
              headerValueGetter() {
                return that.$i18n.t('passage.lmcPax');
              },
              headerTooltip: this.$i18n.t('passage.lmcPax'),
              width: 30,
              cellEditor: 'AgGridConnectedFlightsNumberCellEditor',
              colCustomConfig: {
                fieldPaths: ['passageOutbound.lastMinuteChangesPax'],
                flightBound: FlightBound.OUTBOUND,
                fieldsRelated: ['outbound.passageOutbound.lastMinuteChangesPax'],
              },
              valueGetter(params) {
                return params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['passageOutbound', 'lastMinuteChangesPax']);
              },
              cellRendererFramework: AgGridValueBaseCellRenderer,
            },
            {
              colId: 'outbound.passageOutbound.lastMinuteChangesBag',
              headerValueGetter() {
                return that.$i18n.t('passage.lmcBag');
              },
              headerTooltip: this.$i18n.t('passage.lmcBag'),
              width: 30,
              cellEditor: 'AgGridConnectedFlightsNumberCellEditor',
              colCustomConfig: {
                fieldPaths: ['passageOutbound.lastMinuteChangesBag'],
                flightBound: FlightBound.OUTBOUND,
                fieldsRelated: ['outbound.passageOutbound.lastMinuteChangesBag'],
              },
              valueGetter(params) {
                return params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['passageOutbound', 'lastMinuteChangesBag']);
              },
              cellRendererFramework: AgGridValueBaseCellRenderer,
            },
            {
              colId: 'outbound.remarks.passageRemarks',
              headerValueGetter() {
                return that.$i18n.t('passage.passageRemarks');
              },
              headerTooltip: this.$i18n.t('passage.passageRemarks'),
              width: 140,
              editable: this.editable(true, rolePermission),
              cellEditor: 'AgGridConnectedFlightsStringCellEditor',
              colCustomConfig: {
                fieldPaths: ['remarks.passageRemarks'],
                flightBound: FlightBound.OUTBOUND,
                fieldsRelated: ['outbound.remarks.passageRemarks'],
              },
              valueGetter(params) {
                return params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['remarks', 'passageRemarks']);
              },
              cellRendererFramework: AgGridValueBaseCellRenderer,
            },
          ],
        },
        {
          headerValueGetter() {
            return that.$i18n.t('passage.boarding');
          },
          headerTooltip: this.$i18n.t('passage.boarding'),
          children: [
            {
              colId: 'outbound.extended.gateAgentPresence',
              headerValueGetter() {
                return that.getTextResizable(that.$i18n.t('operations.presenceShort'), that.$i18n.t('operations.presence'));
              },
              headerTooltip: this.$i18n.t('operations.presence'),
              width: 50,
              editable: this.editable(true, rolePermission),
              cellEditor: 'AgGridConnectedFlightsTimeCellEditor',
              colCustomConfig: {
                fieldPaths: ['extended.gateAgentPresence'],
                flightBound: FlightBound.OUTBOUND,
                fieldsRelated: ['outbound.extended.gateAgentPresence'],
              },
              valueGetter(params) {
                return that.agGridTimeGetter(params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['extended', 'gateAgentPresence']), params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['flight', 'scheduledDate']));
              },
              cellRendererFramework: AgGridTimeCellRenderer,

            },
            {
              colId: 'outbound.times.scheduledBoardingTime',
              headerValueGetter() {
                return that.$i18n.t('operations.scheduledBoardingTime');
              },
              headerTooltip: this.$i18n.t('operations.scheduledBoardingTime'),
              width: 50,
              editable: this.editable(true, rolePermission),
              cellEditor: 'AgGridConnectedFlightsTimeCellEditor',
              colCustomConfig: {
                fieldPaths: ['times.scheduledBoardingTime'],
                flightBound: FlightBound.OUTBOUND,
                fieldsRelated: ['outbound.times.scheduledBoardingTime'],
              },
              valueGetter(params) {
                return that.agGridTimeGetter(params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['times', 'scheduledBoardingTime']), params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'scheduledDate']));
              },
              cellRendererFramework: AgGridTimeCellRenderer,
            },
            {
              colId: 'outbound.times.completedBoardingTime',
              headerValueGetter() {
                return that.$i18n.t('operations.scheduledBoardingEndTime');
              },
              headerTooltip: this.$i18n.t('operations.scheduledBoardingEndTime'),
              width: 50,
              editable: this.editable(true, rolePermission),
              cellEditor: 'AgGridConnectedFlightsTimeCellEditor',
              colCustomConfig: {
                fieldPaths: ['times.completedBoardingTime'],
                flightBound: FlightBound.OUTBOUND,
                fieldsRelated: ['outbound.times.completedBoardingTime'],
              },
              valueGetter(params) {
                return that.agGridTimeGetter(params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['times', 'completedBoardingTime']), params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'scheduledDate']));
              },
              cellRendererFramework: AgGridTimeCellRenderer,

            },
            {
              colId: 'outbound.passageOutbound.crewChanges',
              headerValueGetter() {
                return that.$i18n.t('passage.cch');
              },
              headerTooltip: this.$i18n.t('passage.cch'),
              width: 50,
              editable: this.editable(true, rolePermission),
              cellEditor: 'AgGridConnectedFlightsToggleCellEditor',
              colCustomConfig: {
                fieldPaths: ['passageOutbound.crewChanges'],
                flightBound: FlightBound.OUTBOUND,
                fieldsRelated: ['outbound.passageOutbound.crewChanges'],
              },
              valueGetter(params) {
                const crewChanges = !!params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['passageOutbound', 'crewChanges']);
                return {
                  value: crewChanges,
                  color: crewChanges ? 'grey' : '',
                  icon: crewChanges ? 'check' : '',
                };
              },
              cellRendererFramework: AgGridIconTooltipOperationCellRenderer,

            },
            {
              colId: 'outbound.passageOutbound.boardingRefuelingRequested',
              headerValueGetter() {
                return that.$i18n.t('passage.alert');
              },
              headerTooltip: this.$i18n.t('passage.alert'),
              width: 50,
              editable: this.editable(true, rolePermission),
              cellEditor: 'AgGridConnectedFlightsToggleCellEditor',
              colCustomConfig: {
                fieldPaths: ['passageOutbound.boardingRefuelingRequested'],
                flightBound: FlightBound.OUTBOUND,
                fieldsRelated: ['outbound.passageOutbound.boardingRefuelingRequested'],
              },
              valueGetter(params) {
                const boardingRefuelingRequested = !!params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['passageOutbound', 'boardingRefuelingRequested']);
                return {
                  value: boardingRefuelingRequested,
                  color: boardingRefuelingRequested ? 'grey' : '',
                  icon: boardingRefuelingRequested ? 'check' : '',
                };
              },
              cellRendererFramework: AgGridIconTooltipOperationCellRenderer,

            },
            {
              colId: 'outbound.times.blockTime',
              headerValueGetter() {
                return that.$i18n.t('passage.blockTimeDeparture');
              },
              headerTooltip: this.$i18n.t('passage.blockTimeDeparture'),
              width: 50,
              cellClass: 'separator',
              editable: this.editable(true, rolePermission),
              cellEditor: 'AgGridConnectedFlightsTimeCellEditor',
              colCustomConfig: {
                fieldPaths: ['times.blockTime'],
                flightBound: FlightBound.OUTBOUND,
                fieldsRelated: ['outbound.times.blockTime', 'outbound.blockedFieldsIntegration.manualOverwriteBlockTime'],
              },
              valueGetter(params) {
                const value = that.agGridTimeGetter(params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['times', 'blockTime']), params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'scheduledDate']));
                value.fieldBlockedIntegration = params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['blockedFieldsIntegration', 'manualOverwriteBlockTime']);
                return value;
              },
              cellRendererFramework: AgGridTimeFieldPossibleBlockedCellRenderer,
            },
          ],
        },
        {
          headerValueGetter() {
            return that.$i18n.t('passage.missingPax');
          },
          headerTooltip: this.$i18n.t('passage.missingPax'),
          children: [
            {
              colId: 'outbound.passageOutbound.missingPaxBagRequestedTime',
              headerValueGetter() {
                return that.$i18n.t('passage.notificationTime');
              },
              headerTooltip: this.$i18n.t('passage.notificationTime'),
              width: 40,
              minWidth: 40,
              editable: this.editable(true, rolePermission),
              cellEditor: 'AgGridConnectedFlightsTimeCellEditor',
              colCustomConfig: {
                fieldPaths: ['passageOutbound.missingPaxBagRequestedTime'],
                flightBound: FlightBound.OUTBOUND,
                fieldsRelated: ['outbound.passageOutbound.notificationTime'],
              },
              valueGetter(params) {
                return that.agGridTimeGetter(params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['passageOutbound', 'missingPaxBagRequestedTime']), params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['common', 'scheduledDate']));
              },
              cellRendererFramework: AgGridTimeCellRenderer,
            },
            {
              colId: 'outbound.passageOutbound.missingPaxNumber',
              headerValueGetter() {
                return that.$i18n.t('passage.missingPaxNumber');
              },
              headerTooltip: this.$i18n.t('passage.missingPaxNumber'),
              width: 60,
              minWidth: 60,
              editable: this.editable(true, rolePermission),
              cellEditor: 'AgGridConnectedFlightsNumberCellEditor',
              colCustomConfig: {
                fieldPaths: ['passageOutbound.missingPaxNumber'],
                flightBound: FlightBound.OUTBOUND,
                fieldsRelated: ['outbound.passageOutbound.missingPaxNumber'],
              },
              valueGetter(params) {
                return params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['passageOutbound', 'missingPaxNumber']);
              },
              cellRendererFramework: AgGridValueBaseCellRenderer,

            },
            {
              colId: 'outbound.passageOutbound.missingPaxInfo',
              headerValueGetter() {
                return that.$i18n.t('passage.missingPaxInfo');
              },
              headerTooltip: this.$i18n.t('passage.missingPaxInfo'),
              width: 50,
              editable: this.editable(true, rolePermission),
              cellEditor: 'AgGridConnectedFlightsStringCellEditor',
              colCustomConfig: {
                fieldPaths: ['passageOutbound.missingPaxInfo'],
                flightBound: FlightBound.OUTBOUND,
                fieldsRelated: ['outbound.passageOutbound.missingPaxInfo'],
              },
              valueGetter(params) {
                return params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['passageOutbound', 'missingPaxInfo']);
              },
              cellRendererFramework: AgGridValueBaseCellRenderer,
            },
            {
              colId: 'outbound.passageOutbound.paxDataRealTime',
              headerValueGetter() {
                return that.$i18n.t('passage.paxDataRealTime');
              },
              headerTooltip: this.$i18n.t('passage.paxDataRealTime'),
              width: 60,
              minWidth: 60,
              editable: this.editable(true, rolePermission),
              cellEditor: 'AgGridConnectedFlightsStringCellEditor',
              colCustomConfig: {
                fieldPaths: ['passageOutbound.paxDataRealTime'],
                flightBound: FlightBound.OUTBOUND,
                fieldsRelated: ['outbound.passageOutbound.paxDataRealTime'],
              },
              valueGetter(params) {
                return params.data.getChildPropertyValue(FlightBound.OUTBOUND, ['passageOutbound', 'paxDataRealTime']);
              },
              cellRendererFramework: AgGridValueBaseCellRenderer,
            },
          ],
        },
        {
          headerValueGetter() {
            return that.getTextResizable(that.$i18n.t('common.actionsShort'), that.$i18n.t('common.actions'));
          },
          headerTooltip: this.$i18n.t('common.actions'),
          width: 50,
          cellClass: 'text-center',
          field: 'outbound.common.id',
          suppressMenu: true,
          sortable: false,
          suppressMovable: true,
          suppressSizeToFit: true,
          filter: false,
          resizable: false,
          suppressNavigable: true,
          colCustomConfig: {
            exportCsvDisabled: true,
          },
          cellRendererFramework: AgGridRowActionBoardingButtonCellRenderer,
          cellRendererParams: {
            boardingItem: this.boardingItem,
          },
        },
      ],
    };
  },
  methods: {
    onGetChanges(changes) {
      const rowNode = this.gridApi.getRowNode(changes.payload.id);

      if (!rowNode) {
        return;
      }
      const oldConnectedFlight = rowNode.data.clone();
      const newConnectedFlight = changes.payload;
      this.assignInboundOrOutboundIfDontExist(newConnectedFlight, oldConnectedFlight);
      const differencies = this.getDifferenciesAndCreateOldValuesProperties(oldConnectedFlight, newConnectedFlight);

      if (differencies && differencies.length > 0) {
        rowNode.setData(newConnectedFlight);
        if (this.$store.state.passageOutbound.flashOnChanges) {
          this.renderNeededDiffs(differencies, rowNode); // TODO only shows flash
        }
        differencies.forEach(dif => this.$store.commit(PASSAGE_SET_CHANGE, { diff: dif, connectedFlights: newConnectedFlight }));
      }
    },
    onGridReady(params) {
      this.setAgGridApi(params);
      this.showLoading();
      this.$store.commit(PASSAGE_SET_GRID_API, params); // TODO eliminar api del store

      this.refreshCellsOnMutation();
      this.refreshCellsOnChangeLanguage();
      const that = this;
      this.subscribeMutation(PASSAGE_ON_EXTERNAL_CHANGE, (mutationPayload) => {
        that.onGetChanges(mutationPayload);
      });
    },
    firstDataRendered() {
      this.$nextTick(() => {
        this.resized();
      });
    },
    resized() {
      if (this.gridApi) {
        this.gridApi.sizeColumnsToFit();
      }
    },
    sizeColumnsToFit() {
      if (this.gridApi) {
        this.$store.dispatch(FIT_COLUMNS_TO_VIEW);
      }
    },
    onSelectionChanged(event) {
      const selectedNodes = event.api.getSelectedNodes();
      const selectedData = selectedNodes.map(node => node.data);
      this.$store.commit(PASSAGE_CHANGE_FLIGHT_SELECTION, selectedData);
    },
    deleteItem(item) {
      console.log(`delete ${item}`); // eslint-disable-line no-console
    },
    editItem(item) {
      console.log(`edit ${item}`); // eslint-disable-line no-console
    },
    isInbound(params) {
      return params.data.hasInbound();
    },
    isOutbound(params) {
      return params.data.hasOutbound();
    },
    bestTimeGetter(bestTime, scheduledTime, referenceDateTime, flightStatus) {
      const result = this.agGridTimeGetter(bestTime, referenceDateTime);
      const scheduledTimeAux = scheduledTime ? this.$moment(scheduledTime) : null;
      if (result.value && result.value.isValid()) {
        if (scheduledTimeAux && scheduledTimeAux.isValid() && result.value.startOf('minute').isAfter(scheduledTimeAux.startOf('minute'))) {
          result.color = 'red';
        }
      }
      if (flightStatus) {
        result.cssClass = `status-op status-${flightStatus}`;
        result.tooltip = flightStatus;
      }
      return result;
    },
    renderNeededDiffs(differences, rowNode) {
      const { gridApi } = this.$store.state.operation;

      const agGridCustomService = new AgGridCustomService(this.columnDefs); // TODO one time
      const colIds = agGridCustomService.getColIdByMatchAnyfieldsRelated(this.joinAllPropertyPath(differences));

      if (colIds && colIds.length > 0) {
        colIds.forEach((colId) => {
          if (gridApi.getColumnDef(colId)) {
            gridApi.refreshCells({
              rowNodes: [rowNode],
              columns: [colId],
              force: true,
            });

            gridApi.flashCells({
              rowNodes: [rowNode],
              columns: [colId],
              force: true,
            });
          }
        });
      }
    },
    checkBoundAndSetMsgTooltipObject(messageValue) {
      if (messageValue === null || typeof (messageValue) === 'undefined') {
        return null;
      }
      if (messageValue === 'WARNING_LDM') {
        return {
          value: messageValue,
          icon: 'warning',
          color: 'red',
          tooltip: this.$t('messages.warningMessageLDM'),
        };
      }

      return {
        value: messageValue,
        icon: messageValue !== 'EMPTY' ? 'sms' : '',
        color: messageValue === 'UNREAD' ? 'success' : 'bggreydark',
        tooltip: messageValue === 'UNREAD' ? this.$t('messages.messagesUnread') : this.$t('messages.messagesRead'),
      };
    },

    boardingItem(item) {
      this.$router.push({ name: 'passageBoarding', params: { idFlight: item.outbound.common.id } });
    },
  },
};

</script>
